/**
 * Created by sashab on 04.07.17.
 */
import csv from 'csv'
import PropTypes from 'prop-types'
import React from 'react'
import { getFullUri, objectNameFromPathByType } from '../../helpers'
import { getOrganizationDataset } from '../../helpers/api'
import {
  createEmptyObject,
  getDefaultValue,
  getSpecFieldOrdinal,
  getSpecRecords,
  nonCSCompare
} from '../../helpers/index'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './ImportDataDialog.scss'

export class ImportDataDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      importFileType: 'CSV',
      importingDatasetFile: '',
      importSettings: {
        delimiter: ',',
        quote: '"',
        escape: '"',
        replaceData: false
      },

      platforms: [],
      types: {},
      typeJson: -1,
      typeSystem: -1
    })
  }

  getObjectType() {
    return 'dataset'
  }

  componentDidMount() {
    // Load platforms list from organization we are in.
    const organization = objectNameFromPathByType(getFullUri(this.props.majorObject), 'organizations')
    getOrganizationDataset('Platform', organization).then((result) => {
      this.setState({
        platforms: getSpecRecords(result).map((row) => row.values[0])
      })
    })
    getOrganizationDataset('Type', organization).then((result) => {
      this.setState({
        types: result,
        typeSystem: getSpecFieldOrdinal(result, 'Type'),
        typeJson: getSpecFieldOrdinal(result, 'RESTfull-API')
      })
    })
  }

  /**
   * Get initial value of parameter
   * @param {object} [props]
   * @returns {*}
   */
  getInitial = (props) => {
    props = props || this.props
    if (this.props.dataType === 'records') {
      let dataset = props.majorObject
      return dataset ? dataset.data : createEmptyObject('dataset').data
    } else if (this.props.dataType === 'fields') {
      let dataset = props.majorObject
      return dataset ? dataset.structure : createEmptyObject('dataset').structure
    }
    return {}
  }

  /**
   * Change state of dataset
   * @param {object} - new state of object
   * @returns {*} true - it is updated, false - no changes
   */
  ChangeData = (file) => {
    if (this.state.change) {
      //console.log("ImportDataDialog:Changedataset", dataset, this.state);
      if (this.state.change) {
        this.state.change(file !== this.state.importingDatasetFile, '', '', '')
      }

      //console.log("ImportDataDialog:Changedataset", curr, next);
      if (file !== this.state.importingDatasetFile) {
        this.setState({ importingDatasetFile: file })
        return true
      }
    }
    return false
  }

  importData = (ds, closeDialog, onSent) => {
    console.log('ImportDataDialog:importData', ds)

    let inputError = ''
    let inputWarning = ''
    let fr = new FileReader()
    fr.onload = (str) => {
      try {
        console.log('ImportDataDialog:importData:FILE', fr.result)
        let text = fr.result

        csv.parse(text, this.state.importSettings, (err, data) => {
          console.log('ImportDataDialog:importData:DATA', fr.result, data, err)

          if (err) {
            inputError += (inputError.length > 0 ? '\n' : '') + err
          } else if (data.length === 0) {
            inputError += (inputError.length > 0 ? '\n' : '') + 'Data file has 0 lines!'
          }

          let newData = null
          if (this.props.dataType === 'records') {
            newData = this.importRecords(ds, data, inputError, inputWarning)
          } else if (this.props.dataType === 'fields') {
            newData = this.importFields(ds, data, inputError, inputWarning)
          }

          if (inputError.length > 0) {
            if (this.state.change) {
              this.state.change(true, '', '', inputError)
            }
            return true
          }

          if (inputWarning.length > 0) {
            console.warn(inputWarning)
          }

          if (this.state.change) {
            this.state.change(true, 'Data send to the service...', '', '')
          }

          console.log('ImportDataDialog:importData:SAVE', newData)
          this.props.onSave(newData, closeDialog, this.onSent.bind(this))
          return true
        })
      } catch (e) {
        console.error(e)
      }
    }
    fr.readAsText(this.state.importingDatasetFile)
  }

  importRecords = (ds, data, inputError, inputWarning) => {
    console.log('ImportDataDialog:importRecords', ds, data, inputError, inputWarning)

    let newData = null
    let colCount = ds.structure.fields.length

    if (data[0].length !== colCount) {
      inputWarning +=
        (inputWarning.length > 0 ? '\n' : '') +
        'Incorrect column count for data: found ' +
        data[0].length +
        ', but dataset has ' +
        colCount
      data = data.map((r, i) => {
        let ret = []
        for (let i = 0; i < colCount; i++) {
          ret[i] = i < r.length ? r[i] : getDefaultValue(ds.structure.fields[i].type.name)
        }
        return ret
      })
    }

    if (inputError) {
      return null
    }

    // Clear header if requeried.
    if (this.state.importSettings.skipHeader) {
      data = data.filter((r, i) => i !== 0)
    }

    // Generate new records
    let maxIndex = 0
    if (!this.state.importSettings.replaceData) {
      ds.data.records.map((r, i) => (maxIndex = Math.max(maxIndex, r.index)))
    }
    newData = data.map((r, i) => {
      return { index: i + maxIndex + 1, values: r }
    })
    console.log('ImportDataDialog:importRecords:maxIndex', maxIndex, newData)

    // Concatinate if we don't replace
    if (!this.state.importSettings.replaceData) {
      newData = ds.data.records.concat(newData)
    }
    return newData
  }

  importFields = (ds, data, inputError, inputWarning) => {
    console.log('ImportDataDialog:importFields', ds, data, inputError, inputWarning)

    let newData = null

    if (inputError) {
      return null
    }

    // Clear header if requeried.
    let header = null
    if (this.state.importSettings.skipHeader) {
      header = data[0].map((name) => {
        return name.toLowerCase()
      })
      data = data.filter((r, i) => i !== 0)
    }

    // Generate new records
    let maxIndex = 0
    if (!this.state.importSettings.replaceData) {
      ds.structure.fields.map((field) => (maxIndex = Math.max(maxIndex, parseInt(field.order))))
    }

    if (!header) {
      header = [
        'order',
        'name',
        'description',
        'usage',
        'count',
        'type',
        'reference',
        'subscription',
        'size',
        'precision',
        'scale',
        'optional',
        'value',
        'format',
        'locale',
        'access',
        'keys',
        'privace',
        'rules',
        'properies'
      ]
    }

    newData = data.map((r) => {
      let field = { identity: {} }
      header.forEach((name, i) => {
        //let's skip fields witho
        switch (name) {
          case 'order':
            field[name] = parseInt(r[i]) + maxIndex
            break
          case 'name':
            field.identity[name] = r[i]
            break
          case 'description':
            field.identity[name] = r[i]
            break
          case 'optional':
            field[name] = r[i]
              ? r[i] === false || nonCSCompare(r[i], 'false') || parseInt(r[i]) === 0
                ? false
                : true
              : false
            break
          case 'access': {
            if (r[i] && r[i] !== '0' && parseInt(r[i]) !== 0) {
              field[name] = r[i]
                ? r[i] === false || nonCSCompare(r[i], 'false') || parseInt(r[i]) === 0
                  ? false
                  : r[i]
                : false
            }
            break
          }
          default: {
            if (r[i]) {
              field[name] = r[i]
              break
            }
          }
        }
      })
      return field
    })
    console.log('ImportDataDialog:importFields:maxIndex', maxIndex, newData)

    // Concatinate if we don't replace
    if (!this.state.importSettings.replaceData) {
      // Current array.
      let fields = ds.structure.fields

      // Rename fields if we have duplicates
      // eslint-disable-next-line array-callback-return
      newData.map((field) => {
        for (let i = 1; i < 1000; i++) {
          let value = fields.find((item) => item.identity.name === field.identity.name)
          if (!value) return field

          field.identity.name = field.identity.name + i
        }
      })

      newData = fields.concat(newData)
    }
    return newData
  }

  /**
   * Save changes
   * @param {boolean} [closeDialog] Do we need to close dialog
   */
  saveData = (closeDialog) => {
    let inputError = ''
    // console.log("ImportDataDialog:onSave", close, inputError);
    if (inputError.length > 0) {
      if (this.state.change) {
        this.state.change(true, '', '', inputError)
      }
      return true
    }

    if (this.props.onSave) {
      console.log('ImportDataDialog:onSave', this.state.dataType)
      this.importData(this.props.majorObject, closeDialog, this.onSent.bind(this))
      return true // not to do anything here.
    }
  }

  // when JSON nodes in dialog are expanded, it should check height of inner components and expand itself if needed
  checkDialogHeight = () => {
    //console.log("ExampleDialog::checkDialogHeight");
    if (document.querySelector('.ImportDataDialog').offsetHeight > 700)
      this.onAdjust(0, document.querySelector('.ImportDataDialog').offsetHeight - this.state.height + 100)
  }

  /**
   * Render content of dataset editor dialog
   */
  renderEditor = (editState) => {
    // get supported schema types
    const importDatasetTypeOptions = ['CSV']

    return (
      <div className="ImportDataDialog" onClick={this.checkDialogHeight}>
        <div className="ImportDataDialog__block_file clearfix">
          <label className="ImportDataDialog__label">{'Import file: '}</label>
          <div className="value_right">
            <input
              type="file"
              onChange={(e) => {
                let files = []

                if (e.dataTransfer) {
                  files = e.dataTransfer.files
                } else if (e.target) {
                  files = e.target.files
                }
                files = [].slice.call(files)
                this.ChangeData(files[0])
              }}
            />
          </div>
        </div>
        <div className="ImportDataDialog__block_type clearfix">
          <label className="ImportDataDialog__label">{'File type: '}</label>
          <div className="value_right">
            <EditableEntity
              data={this.state.importFileType}
              dataType={{ name: 'radio' }}
              dataProps={Object.assign({
                onChange: (e) => {
                  this.setState({ importFileType: e.target.value })
                },
                options: importDatasetTypeOptions
              })}
              isEditable
              inEditMode
            />
          </div>
        </div>

        <div className="ImportDataDialog__block_file clearfix">
          <label className="ImportDataDialog__label">{'Parameters: '}</label>
        </div>

        <div className="row ">
          <div className="col-xs-2">
            <div className="ImportDataDialog__parameters">Skip header:</div>
          </div>
          <div className="col-xs-6">
            <EditableEntity
              data={this.state.importSettings.skipHeader}
              dataType={{
                name: 'enum',
                options: [
                  { id: 1, label: 'false', value: false },
                  { id: 2, label: 'true', value: true }
                ]
              }}
              dataProps={Object.assign({
                onChange: (e) =>
                  this.setState({
                    importSettings: Object.assign({}, this.state.importSettings, { skipHeader: e.target.value })
                  }),
                activeOptions: this.state.importSettings.skipHeader ? 2 : 1,
                placeholder: '',
                hideCheckBox: true,
                multi: false
              })}
              isEditable
              inEditMode
            />
          </div>
        </div>

        <div className="row ">
          <div className="col-xs-2">
            <div className="ImportDataDialog__parameters">Delimiter:</div>
          </div>
          <div className="col-xs-6">
            <EditableEntity
              data={this.state.importSettings.delimiter}
              dataType={{ name: 'string' }}
              dataProps={{
                placeholder: '',
                onChange: (e) =>
                  this.setState({
                    importSettings: Object.assign({}, this.state.importSettings, { delimiter: e.target.value })
                  })
              }}
              isEditable
              inEditMode
            />
          </div>
        </div>

        <div className="row ">
          <div className="col-xs-2">
            <div className="ImportDataDialog__parameters">Quote:</div>
          </div>
          <div className="col-xs-6">
            <EditableEntity
              data={this.state.importSettings.quote}
              dataType={{ name: 'string' }}
              dataProps={{
                placeholder: '',
                onChange: (e) =>
                  this.setState({
                    importSettings: Object.assign({}, this.state.importSettings, { quote: e.target.value })
                  })
              }}
              isEditable
              inEditMode
            />
          </div>
        </div>

        <div className="row ">
          <div className="col-xs-2">
            <div className="ImportDataDialog__parameters">Escape:</div>
          </div>
          <div className="col-xs-6">
            <EditableEntity
              data={this.state.importSettings.escape}
              dataType={{ name: 'string' }}
              dataProps={{
                placeholder: '',
                onChange: (e) =>
                  this.setState({
                    importSettings: Object.assign({}, this.state.importSettings, { escape: e.target.value })
                  })
              }}
              isEditable
              inEditMode
            />
          </div>
        </div>

        <div className="row ">
          <div className="col-xs-2">
            <div className="ImportDataDialog__parameters">Replace existing data:</div>
          </div>
          <div className="col-xs-6">
            <EditableEntity
              data={this.state.importSettings.replaceData}
              dataType={{
                name: 'enum',
                options: [
                  { id: 1, label: 'false', value: false },
                  { id: 2, label: 'true', value: true }
                ]
              }}
              dataProps={Object.assign({
                onChange: (e) =>
                  this.setState({
                    importSettings: Object.assign({}, this.state.importSettings, { replaceData: e.target.value })
                  }),
                activeOptions: this.state.importSettings.replaceData ? 2 : 1,
                placeholder: '',
                hideCheckBox: true,
                multi: false
              })}
              isEditable
              inEditMode
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    console.log('ImportDataDialog:render', this.props, this.state)

    return (
      <EditorDialog
        objectType="importdata"
        modalTitle={'Import ' + this.props.dataType}
        confirmText={'Import'}
        editContent={this.renderEditor}
        editHeight={this.state.height}
        isEditable={this.props.isEditable}
        isVisible={this.props.isVisible}
        onClose={this.props.onClose}
        onSave={this.saveData}
        onEdit={this.startEdit}
        onCancel={this.cancelEdit}
        hideFullscreenButton
      />
    )
  }
}

ImportDataDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  dataType: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
