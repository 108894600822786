export const getIssue = (message, attached, trackers, versionedObjectUri) => {
  if (message.subject && message.message && !message.issue) {
    return {
      identity: {
        description: message.subject
      },
      attached: {
        name: attached
      },
      notes: message.description,
      trackers: trackers
    }
  } else if (message.issue && message.message) {
    return {
      identity: message.issue.identity,
      attached: {
        name: versionedObjectUri
      }
    }
  }
}

export const getPayload = (message, attached, trackers, versionedObjectUri, newVersion, objectType, alias) => {
  return {
    class: 'Message',
    action: objectType === 'subscription' ? 'FinalizeSubscription' : 'FinalizeVersion',
    type: 'VersionApproval',
    attached: {
      name: versionedObjectUri + '/versions/' + newVersion
    },
    sender: {
      id: localStorage['currentUserId'],
      description: alias
    },
    text: message.message,
    issue: getIssue(message, attached, trackers, versionedObjectUri)
  }
}
