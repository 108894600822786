/* eslint-disable no-restricted-globals */
import PropTypes from 'prop-types'
import { Component } from 'react'
import { track } from '../../helpers/analytics'
import { API, idAPI } from '../../helpers/api'
import { refreshCognitoToken } from '../../helpers/auth-cognito'
import logger from '../../helpers/logger'
import './ErrorWindow.scss'

function parseJsonOrNot(json) {
  try {
    return JSON.parse(json)
  } catch (e) {
    return json
  }
}

export class ErrorWindow extends Component {
  componentDidMount() {
    API.on('error', (error, parameters) => {
      //logger.log('ErrorWindow:componentDidMount', error, parameters);
      if (sessionStorage.isLoggingOut) {
        return
      }
      if (error.status === 401) {
        // Unauthorized
        if (window.AUTH_URL === 'disabled') {
          this.props.history.push('/auth')
        } else if (window.AUTH_URL === '/cognitologin') {
          //this.props.history.push('/cognitologin' + window.location.hash);
          refreshCognitoToken(true)
          return
        } else if (!window.location.hash.includes('login') || !window.location.hash.split('=')[1].length) {
          if (!localStorage['loginReturnUrl']) {
            localStorage['loginReturnUrl'] = location.pathname + location.hash
          }

          localStorage['currentUserToken'] = ''
          localStorage['unauthorizedCount'] = parseInt(localStorage['unauthorizedCount']) + 1

          if (parseInt(localStorage['unauthorizedCount']) > 5) {
            this.props.history.push('/autherror')
          } else {
            this.props.history.push('/login')
          }

          return
        } else {
          window.getToken()
          return
        }
      }

      track(parameters ? parameters.url : '', 'api_error')
      logger.error('API Error', error, parameters)

      this.props.actions.setError({
        request: error,
        status: error.status,
        url: parameters ? parameters.url : '',
        responseJson: parseJsonOrNot(error.response),
        method: parameters ? parameters.method : null,
        requestJson: parseJsonOrNot(parameters ? parameters.data : null),
        time: new Date()
      })
    })

    idAPI.on('error', (error, parameters) => {
      //logger.log('ErrorWindow:componentDidMount:API', error, parameters);
      if (sessionStorage.isLoggingOut) {
        return
      }
      if (error.status === 401) {
        // Unauthorized
        if (window.AUTH_URL === 'disabled') {
          this.props.history.push('/auth')
        } else if (window.AUTH_URL === '/cognitologin') {
          //this.props.history.push('/cognitologin' + window.location.hash);
          refreshCognitoToken(true)
          return
        } else if (!window.location.hash.includes('login') || !window.location.hash.split('=')[1].length) {
          if (!localStorage['loginReturnUrl']) {
            localStorage['loginReturnUrl'] = location.pathname + location.hash
          }

          localStorage['currentUserToken'] = ''
          localStorage['unauthorizedCount'] = parseInt(localStorage['unauthorizedCount']) + 1

          if (parseInt(localStorage['unauthorizedCount']) > 5) {
            this.props.history.push('/autherror')
          } else {
            this.props.history.push('/login')
          }

          return
        } else {
          window.getToken()
          return
        }
      }

      track(parameters ? parameters.url : '', 'api_error')
      logger.error('idAPI Error', error, parameters)

      this.props.actions.setError({
        request: error,
        status: error.status,
        url: parameters ? parameters.url : '',
        responseJson: parseJsonOrNot(error.response),
        method: parameters ? parameters.method : null,
        requestJson: parseJsonOrNot(parameters ? parameters.data : null),
        time: new Date()
      })
    })
  }

  closeError() {
    this.props.actions.setError(null)
  }

  render() {
    return null
  }
}

ErrorWindow.propTypes = {
  history: PropTypes.object,
  actions: PropTypes.object
}
