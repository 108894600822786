/**
 * Created by mailf on 22.08.2016.
 */
import React from 'react'

import logger from '../../helpers/logger'
import { Messages } from '../Messages/Messages'
import FilteredTabbedTable from '../FilteredTabbedTable/FilteredTabbedTable'
import { MajorObjectVersioned } from '../MajorObjectVersioned/MajorObjectVersioned'
import { TopologyDialog } from '../Environment/TopologyDialog'
import { ObjectHistoryTable } from '../ObjectHistoryTable/ObjectHistoryTable'
//import DatasetPublication from './DatasetPublication';
import { DatasetRefDialog } from './DatasetRefDialog'
import { InterfacePublication } from './InterfacePublication'
import { LayoutDialog } from '../LayoutDialog/LayoutDialog'
import {
  columnsToType,
  getObjectById,
  pathByType,
  objectNameFromPathByType,
  majorObjectSort,
  pluralTypeForms
} from '../../helpers'
import { API, idAPI, getObjectNew, getObjectsWithConnected, getRequestFromPath, sendObjectNew } from '../../helpers/api'
import { deepCopy, getFullUri, editableState } from '../../helpers/index'
import { getObjectByName, getObjectByPath, getTopologies, getObjects } from '../../helpers/data'
import { getTailButtonLabel } from '../../helpers/helperComponents'
import { ApplicationTabsEnum } from '../Application/Application'
import { Tab } from '../FilteredTabbedTable/TabType'

export class Publication extends MajorObjectVersioned {
  constructor(props) {
    super(props)

    // @ts-ignore
    this.state = {
      editingChild: false,
      editingChildType: '',
      editingChildId: null,
      editingChildMode: false,

      objectType: 'publication',
      objectName: this.getObjectName('publication')
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const objectType = this.getObjectType(props)
    if (!this.props.userState.profile && props.userState.profile) {
      this.loadUserRole(props)
    }

    if (
      !props.majorObject &&
      this.props.majorObject &&
      this.props.majorObject.identity.id !== 0 &&
      props.location.pathname !== this.props.location?.pathname
    ) {
      //console.log("Publication::componentWillReceiveProps new request", props.majorObject, this.props.majorObject);

      const newState = {
        objectType: 'publication',
        objectName: props.match.params[this.getObjectType(props) + 'Name'],
        recordsToDelete: null
      }

      this.clearTabsContentLoaded(['datasets', 'subscriptions', 'interfaces']) // show Loader for object table

      this.setState(newState, () => {
        getObjectsWithConnected(
          this.getDataRequestQuery(props),
          this.state.objectType,
          this.state.objectName,
          this.props.actions
        )
      })
    }
  }

  componentDidMount() {
    const publication = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    if (publication && publication.identity.id) {
      this.props.actions.updateMajorObject(publication.identity.id, pluralTypeForms.get(this.state.objectType), {
        isFetching: true
      })
    }

    this.clearTabsContentLoaded(['datasets', 'subscriptions']) // show Loader for object table
    getObjectsWithConnected(
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  getDataRequestQuery(props, version?) {
    if (props.match.params.applicationName) {
      return this.getDataRequestQueryApp(props, version)
    } else {
      return this.getDataRequestQuerySys(props, version)
    }
  }

  getDataRequestQueryApp(props, version) {
    //logger.info("Publication:getDataRequestQuery", { props, version}, this.state, this.props);

    const pubApiEndpoint = version
      ? API.organizations(props.match.params.organizationName)
          .systems(props.match.params.systemName)
          .applications(props.match.params.applicationName)
          .publications(this.state.objectName)
          .versions(version)
      : API.organizations(props.match.params.organizationName)
          .systems(props.match.params.systemName)
          .applications(props.match.params.applicationName)
          .publications(this.state.objectName)

    const connectedObjectsEndpoint = version
      ? API.organizations(props.match.params.organizationName)
          .systems(props.match.params.systemName)
          .applications(props.match.params.applicationName)
          .publications(this.state.objectName)
      : pubApiEndpoint

    return {
      objectRequest: pubApiEndpoint,
      endpoint: API.organizations(props.match.params.organizationName)
        .systems(props.match.params.systemName)
        .applications(props.match.params.applicationName)
        .publications(this.state.objectName),
      objectRequestCallback: (publication, errorStatus) => {
        this.setState({ errorStatus: errorStatus })
        publication.datasets.map((ds) => {
          // We will not request datasets. We will request it only when opened. SashaB
          //console.log("Publication::getDataRequestQuery.objectRequestCallback", ds.identity.name);
          //getObjectNew(idAPI.datasets(ds.identity.id), 'dataset', this.props.actions);
        })
        return true
      },
      connectedObjectsRequests: [
        {
          objectType: 'dataset',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .applications(props.match.params.applicationName)
            .datasets(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded(['datasets'])
        },
        {
          objectType: 'interface',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .applications(props.match.params.applicationName)
            .interfaces(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded(['interfaces'])
        },
        {
          objectType: 'version',
          isObjectProperty: true,
          majorObjectType: 'publication',
          propertyName: 'versions',
          request: connectedObjectsEndpoint.versions(),
          connectedObjectsRequestCallback: () => {
            if (this.state.loadingCheckpoints) {
              this.requestCheckpoints().then(() => {
                this.setState({ loadingCheckpoints: false })
              })
            }
          }
        },
        /*
        {
          'objectType': 'issue',
          clearList: true,
          request: connectedObjectsEndpoint.issues()

        },
        */
        {
          objectType: 'subscription',
          clearList: true,
          request: connectedObjectsEndpoint.subscriptions(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded(['subscriptions'])
        },
        {
          objectType: 'user',
          majorObjectType: 'publication',
          isObjectProperty: true,
          propertyName: 'users',
          request: connectedObjectsEndpoint.users(),
          connectedObjectsRequestCallback: this.checkUserRole(props)
        }
        /*
        ,{
          majorObjectType: 'publication',
          isObjectProperty: true,
          propertyName: 'layouts',
          request: connectedObjectsEndpoint.layouts()
        }
        */
      ]
    }
  }

  getDataRequestQuerySys(props, version) {
    const pubApiEndpoint = version
      ? API.organizations(props.match.params.organizationName)
          .systems(props.match.params.systemName)
          .publications(this.state.objectName)
          .versions(version)
      : API.organizations(props.match.params.organizationName)
          .systems(props.match.params.systemName)
          .publications(this.state.objectName)

    const connectedObjectsEndpoint = version
      ? API.organizations(props.match.params.organizationName)
          .systems(props.match.params.systemName)
          .publications(this.state.objectName)
      : pubApiEndpoint
    //logger.info("Publication:getDataRequestQuery", { props, version, pubApiEndpoint, connectedObjectsEndpoint }, this.state, this.props);

    return {
      objectRequest: pubApiEndpoint,
      endpoint: API.organizations(props.match.params.organizationName)
        .systems(props.match.params.systemName)
        .publications(this.state.objectName),
      objectRequestCallback: (publication, errorStatus) => {
        this.setState({ errorStatus: errorStatus })
        return true
      },
      connectedObjectsRequests: [
        {
          objectType: 'version',
          isObjectProperty: true,
          majorObjectType: 'publication',
          propertyName: 'versions',
          request: connectedObjectsEndpoint.versions(),
          connectedObjectsRequestCallback: () => {
            if (this.state.loadingCheckpoints) {
              this.requestCheckpoints().then(() => {
                this.setState({ loadingCheckpoints: false })
              })
            }
          }
        },
        /*
        {
          'objectType': 'issue',
          clearList: true,
          request: connectedObjectsEndpoint.issues()

        },
        */
        {
          objectType: 'subscription',
          clearList: true,
          request: connectedObjectsEndpoint.subscriptions(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded(['subscriptions'])
        },
        {
          objectType: 'user',
          majorObjectType: 'publication',
          isObjectProperty: true,
          propertyName: 'users',
          request: connectedObjectsEndpoint.users(),
          connectedObjectsRequestCallback: this.checkUserRole(props)
        }
        /*
        ,{
          majorObjectType: 'publication',
          isObjectProperty: true,
          propertyName: 'layouts',
          request: connectedObjectsEndpoint.layouts()
        }
        */
      ]
    }
  }

  closeEditDialog = () => {
    this.setState(
      {
        editingChild: false,
        childObjectType: '',
        activeMinorObject: null
      },
      () => {}
    )
  }

  /**
   * Save interface with new or updated layout
   * @param {object} layout - new or updated layout
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   */
  saveLayout = (layout, closeDialog, onSent) => {
    // console.log("Interface:saveParameter", layout, closeDialog);

    const newPublication = Object.assign({}, { identity: this.getObject().identity, layouts: [layout] })

    this.savePublication(newPublication, closeDialog, onSent)
  }

  /**
   * Delete layout from dataset
   * @param {id} layout - layout to delete
   */
  deleteLayout = (layoutId) => {
    // console.log("Dataset:deleteLayout", layoutId);

    const newPublication = Object.assign({}, this.getObject())

    newPublication.layouts = newPublication.layouts.filter((layout) => layout.identity.id !== layoutId)

    this.savePublication(newPublication)
  }

  /**
   * Save publication update using patch action
   * @param {object} [newDataset] - new dataset with changes only
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   */
  savePublication = (newPublication, closeDialog?, onSent?) => {
    // console.log("Dataset:saveDataset", newDataset);

    return sendObjectNew(
      idAPI.publications(newPublication.identity.id),
      'patch',
      this.props.actions,
      this.getObject(),
      newPublication
    ).then(
      (result) => {
        if (onSent) onSent(closeDialog)
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  sendPublication = (newPublication, closeDialog, onSent) => {
    // console.log("Dataset:saveDataset", newDataset);

    return sendObjectNew(
      idAPI.publications(newPublication.identity.id),
      'put',
      this.props.actions,
      this.getObject(),
      newPublication
    ).then(
      (result) => {
        if (onSent) onSent(closeDialog)
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  pasteChild = (params, closeDialog, onSent) => {
    const ds = this.getObject()

    let newObject = JSON.parse(localStorage.clipboard)
    newObject.identity.name = params.objectName

    if (localStorage.clipboardType === 'layout') {
      const newLayouts = this.props.majorObject.layouts.slice()
      const req = getRequestFromPath(getFullUri(this.getObject()))
      newLayouts.push(newObject)
      this.savePublication({ identity: ds.identity, layouts: newLayouts }, closeDialog, onSent)
    }
  }

  interfacesChange(new_interfaces) {
    this.props.actions.updateInterfaces(this.state.id, pluralTypeForms.get(this.state.objectType), new_interfaces)
  }

  openDatasetWindow(id, editMode) {
    const publication = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    if (editMode)
      this.setState({
        editingDatasetPublication: true,
        editingDatasetPublicationId: id
      })
    else
      this.setState({
        viewingDatasetPublication: true,
        viewingDatasetPublicationId: id
      })
  }

  getTabsProps = () => {
    const publication = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable = this.checkEditable()

    let tabs: Tab[] = []

    if (this.props.match.params.applicationName) {
      tabs.push({
        title: 'datasets',
        filters: [
          { name: 'versions', width: 100 },
          { name: 'layouts', width: 200 },
          { name: 'search', width: 300 },
          { name: 'tags', width: 200 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('datasetName'),
            displayName: 'Dataset name',
            onCellClick: (value, rowIndex) => {
              this.setState({
                editingChild: true,
                editingChildType: 'dataset',
                editingChildId: value.id,
                editingChildMode: false
              })
            },
            width: 200
          },
          {
            name: 'version',
            type: columnsToType.getType('string'),
            width: 100
          },
          {
            name: 'layouts',
            type: columnsToType.getType('layouts'),
            width: 200
          },
          { name: 'fields', type: columnsToType.getType('fields'), width: 640 },
          {
            name: 'pubsub',
            displayName: 'Sub',
            type: columnsToType.getType('pubsub'),
            width: 44
          }
        ],
        onCancel: () => {
          this.setState({ datasetEditMode: false })
        },
        tailButtons: !isEditable
          ? [
            {
              label: getTailButtonLabel('View'),
              onClick: (obj) => {
                this.setState({
                  editingChild: true,
                  editingChildType: 'dataset',
                  editingChildId: obj.identity.id,
                  editingChildMode: false
                })
              }
            }
          ]
          : [
            {
              label: getTailButtonLabel('Edit'),
              onClick: (obj) => {
                this.setState({
                  editingChild: true,
                  editingChildType: 'dataset',
                  editingChildId: obj.identity.id,
                  editingChildMode: true
                })
              }
            },
            {
              label: getTailButtonLabel('Delete'),
              onClick: (obj) => {
                this.setState({
                  deleteChildObject: {
                    obj: obj,
                    delete: () => this.deletePublicationDataset(obj)
                  }
                })
              }
            },
            {
              label: getTailButtonLabel('Link'),
              onClick: (obj) => {
                const publicationUri = getFullUri(this.getObject())
                const applicationUri = publicationUri.substr(0, publicationUri.indexOf('/publications'))
                const datasetUri = applicationUri + '/datasets/' + obj.name
                logger.info('Publication:getTabsProps:Link', obj, publicationUri, applicationUri, datasetUri)
                this.props.history?.push('/view' + datasetUri)
              }
            }
          ],
        bottomButtons: !isEditable
          ? []
          : [
            {
              label: '+ Add dataset',
              onClick: () => {
                this.setState({
                  editingChild: true,
                  editingChildType: 'dataset',
                  editingChildId: null,
                  editingChildMode: true
                })
              }
            }
          ],
        topButtons: !isEditable
          ? []
          : [
            {
              label: '+ Add',
              onClick: () => {
                this.setState({
                  editingChild: true,
                  editingChildType: 'dataset',
                  editingChildId: null,
                  editingChildMode: true
                })
              }
            }
          ],
        emptyText: (
          <div className="MajorObject__emptyOuter">
            <div>
              No datasets found
              <br />
              Add datasets you want to publish
            </div>
          </div>
        )
      })

      tabs.push({
        title: 'interfaces',
        filters: [
          { name: 'versions', width: 100 },
          { name: 'layouts', width: 200 },
          { name: 'search', width: 300 },
          { name: 'tags', width: 200 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('datasetName'),
            displayName: 'Interface name',
            onCellClick: (obj) => {
              //console.log("Publication:onCellClickGenerator", columnProps, value, rowIndex);
              this.setState({
                editingChild: true,
                editingChildType: 'interface',
                editingChildId: obj.id,
                editingChildMode: false
              })
            },
            width: 200
          },
          {
            name: 'version',
            type: columnsToType.getType('string'),
            width: 100
          },
          {
            name: 'layouts',
            type: columnsToType.getType('layouts'),
            width: 200
          },
          {
            name: 'operations',
            type: columnsToType.getType('operations'),
            width: 640
          },
          {
            name: 'pubsub',
            displayName: 'Sub',
            type: columnsToType.getType('pubsub'),
            width: 44
          }
        ],
        onCancel: () => {
          this.closeChildEditor()
        },
        onSave: this.interfacesChange.bind(this),
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (obj) => {
                  this.setState({
                    editingChild: true,
                    editingChildType: 'interface',
                    editingChildId: obj.identity.id,
                    editingChildMode: false
                  })
                }
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (obj) => {
                  this.setState({
                    editingChild: true,
                    editingChildType: 'interface',
                    editingChildId: obj.identity.id,
                    editingChildMode: true
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (obj) => {
                  this.setState({
                    deleteChildObject: {
                      obj: obj,
                      delete: () => this.deletePublicationInterface(obj)
                    }
                  })
                }
              },
              {
                label: getTailButtonLabel('Link'),
                onClick: (obj) => {
                  const publicationUri = getFullUri(this.getObject())
                  const applicationUri = publicationUri.substr(0, publicationUri.indexOf('/publications'))
                  const interfaceUri = applicationUri + '/interfaces/' + obj.name
                  logger.info('Publication:getTabsProps:Link', obj, publicationUri, applicationUri, interfaceUri)
                  this.props.history?.push('/view' + interfaceUri)
                }
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add interface',
                onClick: () => {
                  this.setState({
                    editingChild: true,
                    editingChildType: 'interface',
                    editingChildId: null,
                    editingChildMode: true
                  })
                }
              }
          ],
         topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  this.setState({
                    editingChild: true,
                    editingChildType: 'interface',
                    editingChildId: null,
                    editingChildMode: true
                  })
                }
              }
            ],
        emptyText: (
          <div className="MajorObject__emptyOuter">
            <div>
              No interfaces found
              <br />
              Add interfaces you want to publish
            </div>
          </div>
        )
      })

      tabs.push({
        title: 'layouts',
        filters: [
          { name: '', width: 250 - 205 },
          { name: 'search', width: 290 },
          { name: '', width: 110 },
          { name: 'platform', width: 250 } /*, 'type'*/
        ],
        columns: [
          {
            name: 'name',
            displayName: 'Layout name',
            type: columnsToType.getType('minorObjectName'),
            onCellClick: (value, rowIndex) => {
              this.setState({
                editingChild: true,
                childObjectType: 'layout',
                editingChildType: 'layout',
                activeMinorObject: (publication.layouts || []).find((f) => f.identity.name === value.identity.name),
                activeMinorObjectEditable: this.checkEditable(),
                childInEditMode: false
              })
            },
            width: 250
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 400
          },
          {
            name: 'platform',
            type: columnsToType.getType('string'),
            width: 250
          },
          { name: 'unit', type: columnsToType.getType('string'), width: 285 }
        ],
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (value, rowIndex) => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'layout',
                    editingChildType: 'layout',
                    activeMinorObject: (publication.layouts || []).find((f) => f.identity.name === value.identity.name),
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: false
                  })
                }
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (value, rowIndex) => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'layout',
                    editingChildType: 'layout',
                    activeMinorObject: (publication.layouts || []).find((f) => f.identity.name === value.identity.name),
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: true
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (value) => this.deleteLayout(value.identity.id)
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add layout',
                onClick: () => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'layout',
                    editingChildType: 'layout',
                    activeMinorObjectEditable: true,
                    childInEditMode: true
                  })
                }
              }
            ],
        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'layout',
                    editingChildType: 'layout',
                    activeMinorObjectEditable: true,
                    childInEditMode: true
                  })
                }
              }
            ],
        contextMenuButtons: [
          {
            label: 'Copy layout',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              // console.log("Dataset::Copy field", data);
              const layout = publication.layouts.reduce(
                (p, c) => (c.identity.name === data.identity.name ? c : p),
                null
              )
              // console.log("Found field", field);
              localStorage.clipboard = JSON.stringify(layout)
              localStorage.clipboardType = 'layout'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste layout',
                  data: { action: 'paste' },
                  onClick: () => {
                    //console.log("Paste dataset");

                    if (!localStorage.clipboard) {
                      alert('No layout copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'layout') {
                      alert('No layout copied')
                      return
                    }

                    this.setState({
                      pastingChild: true,
                      pastingChildName: JSON.parse(localStorage.clipboard).identity.name
                    })
                  },
                  // @ts-ignore
                  showInBottom: true
                }
              ]
        )
      })
    } else {
      // Topology.
      tabs.push({
        title: 'topologies',
        filters: [
          { name: 'versions', width: 100 },
          { name: 'search', width: 300 },
          { name: 'tags', width: 200 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('datasetName'),
            displayName: 'Topology name',
            onCellClick: (value, rowIndex) => {
              this.setState({
                editingChild: true,
                editingChildType: 'topology',
                editingChildId: value.id,
                editingChildMode: false
              })
            },
            width: 200
          },
          {
            name: 'version',
            type: columnsToType.getType('string'),
            width: 100
          },
          {
            name: 'instances',
            type: columnsToType.getType('string'),
            width: 840
          },
          {
            name: 'pubsub',
            displayName: 'Sub',
            type: columnsToType.getType('pubsub'),
            width: 44
          }
        ],
        onCancel: () => {
          this.setState({ datasetEditMode: false })
        },
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (obj) => {
                  this.setState({
                    editingChild: true,
                    editingChildType: 'topology',
                    editingChildId: obj.identity.id,
                    editingChildMode: false
                  })
                }
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (obj) => {
                  this.setState({
                    editingChild: true,
                    editingChildType: 'topology',
                    editingChildId: obj.identity.id,
                    editingChildMode: true
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (obj) => {
                  this.setState({
                    deleteChildObject: {
                      obj: obj,
                      delete: () => this.deletePublicationTopology(obj)
                    }
                  })
                }
              },
              {
                label: getTailButtonLabel('Link'),
                onClick: (obj) => {
                  const publicationUri = getFullUri(this.getObject())
                  const systemUri = publicationUri.substr(0, publicationUri.indexOf('/publications'))
                  const topologyUri = systemUri + '/topologies/' + obj.name
                  logger.info('Publication:getTabsProps:Link', obj, publicationUri, systemUri, topologyUri)
                  this.props.history?.push('/view' + topologyUri)
                }
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add topology',
                onClick: () => {
                  this.setState({
                    editingChild: true,
                    editingChildType: 'topology',
                    editingChildId: null,
                    editingChildMode: true
                  })
                }
              }
          ],
        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  this.setState({
                    editingChild: true,
                    editingChildType: 'topology',
                    editingChildId: null,
                    editingChildMode: true
                  })
                }
              }
            ],
        emptyText: (
          <div className="MajorObject__emptyOuter">
            <div>
              No topologies found
              <br />
              Add topology you want to publish
            </div>
          </div>
        )
      })
    }

    tabs.push({
      title: 'subscriptions',
      filters: [
        { name: '', width: 20 },
        { name: 'search', width: 220 },
        { name: 'tags', width: 150 }
      ],
      columns: [
        {
          name: 'name',
          type: columnsToType.getType('objectName'),
          displayName: 'Subscription name',
          width: 220
        },
        {
          name: 'description',
          displayName: this.renderDescriptionColumnHeader(),
          type: this.getDescriptionType(),
          width: 248 + 150
        },
        {
          name: 'uri',
          type: columnsToType.getType('path'),
          width: 450,
          displayName: 'Application URI'
        },
        { name: 'version', type: columnsToType.getType('version'), width: 100 }
        //{name: 'status', type: columnsToType.getType('status'), width: 110},
        //{name: 'approvedBy', type: columnsToType.getType('approvedBy'), displayName: "Approved By", width: 140},
        //{name: 'approvedDate', type: columnsToType.getType('approvedDate'), displayName: "Approved Date", width: 150}
      ],
      //@ts-ignore
      editable: isEditable
    })

    tabs.push({
      title: 'history',
      filters: [],
      columns: [],
      tableComponent: ObjectHistoryTable,
      parentObject: publication
    })

    return tabs
  }

  getData = () => {
    const publication = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    let id = publication.identity.id

    return this.getTabsProps().map((block) => {
      if (block.title === 'subscriptions') {
        //console.log("block=subscriptions");
        block.data = getObjects(this.props.appState, 'subscriptions').map((o) => {
          return Object.assign({}, o, {
            name: {
              name: o.identity.name,
              objectType: o.title,
              path: o.object.parent.name + '/subscriptions/' + o.identity.name
            },
            description: o.identity.description,
            // architects: o.userrole ? o.userrole.filter((el) => el.role === "architect") : [],
            tags: o.object.tags ? o.object.tags.map((tag) => tag.name) : [],
            uri: pathByType(o.object.parent.name.substring(1).split('/'), 'applications', true),
            system: {
              name: objectNameFromPathByType(o.object.parent.name, 'systems')
            },
            application: {
              name: objectNameFromPathByType(o.object.parent.name, 'applications')
            }
          })
        })
        //console.log("block data", block.data);
        block.data.sort(majorObjectSort)
        return block
      }
      if (block.title === 'layouts') {
        block.data = publication.layouts
          ? publication.layouts.map((layout) => {
              const newName = {
                name: layout.identity.name,
                objectType: 'layout',
                path: layout.path
              }
              layout.description = layout.identity.description
              layout = Object.assign({}, layout, { name: newName })
              //console.log("Publication::getData layout", layout);

              return layout
            })
          : []
        //console.log("Publication::getData layout block", block);
        block.data.sort(majorObjectSort)
        return block
      }

      if (block.title === 'history') {
        block.data = [
          {
            versions: publication.versions,
            checkpoints: publication.checkpoints,
            loadingCheckpoints: this.state.loadingCheckpoints,
            restore: this.restoreFromCheckpoint
          }
        ]
        return block
      }

      if (block.title !== 'layouts') {
        if (publication[block.title]) {
          block.data = publication[block.title]
            .map((ds) => {
              if (block.title === 'datasets') {
                return this.getPublishedDataset(this.props.appState, ds, id)
              } else if (block.title === 'interfaces') {
                return this.getPublishedInterface(this.props.appState, ds, id)
              } else if (block.title === 'topologies') {
                return this.getPublishedTopology(this.props.appState, ds, id)
              } else {
                let ret = getObjectById(this.props.appState, block.title, ds)
                if (ret.identity) {
                  ret.id = ret.identity.id
                  ret.name = ret.identity.name
                  ret.description = ret.identity.description
                }
                return ret
              }
            })
            .filter((ret) => ret !== null)
        } else {
          block.data = []
        }
        block.data.sort(majorObjectSort)
        return block
      }

      block.data.sort(majorObjectSort)
      return block
    })
  }

  getPublishedDataset(appState, dataset, parentId) {
    let ret = deepCopy(dataset)
    if (ret.identity) {
      ret.id = ret.identity.id
      ret.name = ret.identity.name
      ret.description = ret.identity.description
    }

    let fieldNames = ret.fieldNames

    if (ret.schemaOnly || fieldNames.length === 0) ret.fields = ['As structure']
    else ret.fields = fieldNames

    ret.version = ret.version.major + '.*.*'
    ret.layouts = ret.layoutNames

    return ret
  }

  getPublishedInterface(appState, inter, parentId) {
    let ret = deepCopy(inter)
    if (ret.identity) {
      ret.id = ret.identity.id
      ret.name = ret.identity.name
      ret.description = ret.identity.description
    }
    ret.version = ret.version.major + '.*.*'
    ret.layouts = ret.layoutNames ? ret.layoutNames.toString() : ''
    ret.operations = ret.operationNames ? ret.operationNames.toString() : ''

    return ret
  }

  getPublishedTopology(appState, top, parentId) {
    let ret = deepCopy(top)
    if (ret.identity) {
      ret.id = ret.identity.id
      ret.name = ret.identity.name
      ret.description = ret.identity.description
    }
    ret.version = ret.version.major + '.*.*'
    ret.instances = ret.instanceNames ? ret.instanceNames.toString() : ''
    //logger.info("Publication:getPublishedTopology", { appState, top, parentId, ret }, this.state, this.props);

    return ret
  }

  closeChildEditor() {
    this.setState({
      editingChild: false,
      editingChildType: '',
      editingChildId: null,
      editingChildMode: false
    })
  }

  /**
   * Check if dataset is child of some other dataset, so it cannot be edited or deleted.
   * We only need to check 1st level of children because each child dataset always has its parent added to publication
   * @param datasetId
   */
  isDatasetChild(datasetId) {
    if (!datasetId) return false

    const publication = this.getObject()

    const dataset = getObjectById(this.props.appState, 'datasets', datasetId)
    //console.log("isDatasetChild "+datasetId, dataset);

    if (!dataset || !getFullUri(dataset)) return false

    let found = false

    publication.datasets.map((d) => {
      let ds = getObjectById(this.props.appState, 'datasets', d.identity.id)
      if (!ds.structure) return
      //console.log("Checking for " + dataset.path + " in ", ds);
      ds.structure.fields
        .filter((f) => f.type === 'Structure')
        .map((f) => f.reference)
        .map((ref) => {
          if (ref === getFullUri(dataset)) {
            found = true
          }
        })
    })

    return found
  }

  /**
   * Resolves with dataset taken from state or loaded from server
   * @param {string} path - dataset path
   * @returns {Promise}
   */
  getDataset = (path) => {
    return new Promise((resolve, reject) => {
      // try to find dataset in state
      const dataset = getObjectByPath(this.props.appState, 'dataset', path)

      // return if found
      if (dataset) {
        resolve(dataset)
        return dataset
      } else {
        // request if not found
        console.log('Publication:getDataset new request', path)
        getObjectNew(getRequestFromPath(path), 'dataset', this.props.actions).then(
          (ds) => {
            resolve(ds)
          },
          (err) => {
            reject({
              err,
              data: [path]
            })
          }
        )
      }
    })
  }

  /**
   * Finds structure fields and returns array of references
   * @param {object} ds - dataset
   * @returns {Array}
   */
  getRefsFromDs = (ds) => {
    console.log('getRefsFromDs', ds, !ds || !ds.structure)
    if (!ds || !ds.structure) return []

    return ds.structure.fields.filter((field) => field.type === 'Structure').map((field) => field.reference)
  }

  /**
   * Recursively load datasets with its referenced structures
   * @param ds
   * @param known
   * @param loaded
   * @returns {Promise}
   */
  getDsStructs = (ds, known = [], loaded = []) => {
    return new Promise((resolve, reject) => {
      // get references of dataset structures
      // @ts-ignore
      const refs = this.getRefsFromDs(ds).filter((ref) => !known.includes(ref))

      console.log('getDsStructs', ds, loaded.slice(), refs)

      if (refs.length > 0) {
        // remember refs of requested datasets
        known = known.concat(refs)

        // collect promises of all datasets
        const datasetLoaders = refs.map(this.getDataset)

        Promise.all(datasetLoaders)
          .then((datasets) => {
            console.log('getDsStructs:gotStructs', datasets, loaded.slice())
            Array.prototype.push.apply(loaded, datasets)

            const innerDatasetLoaders = datasets.map((ds) => {
              return this.getDsStructs(ds)
            })

            Promise.all(innerDatasetLoaders).then((innerDatasets) => {
              console.log('getDsStructs:gotStructsStructs', innerDatasets, loaded.slice())
              Array.prototype.push.apply(loaded, Array.prototype.concat(...innerDatasets))
              resolve(loaded)
            })
          })
          .catch((err) => {
            err.data.push(getFullUri(ds))
            reject(err)
          })
      } else {
        resolve(loaded)
      }
    })
  }

  collectDatasets = (datasetReference) => {
    const datasets = []

    return new Promise((resolve, reject) => {
      // find datasets that are referenced from datasetReference and not yet included in publication
      console.log('Publication:saveDatasetPublication:REF', datasetReference)

      let dataset = getObjectById(this.props.appState, 'datasets', datasetReference.identity.id)
      console.log('Publication:saveDatasetPublication:DATASET', dataset)

      // find fields that are included in datasetReference and are structures
      let addDatasets = dataset.structure.fields
        .filter((f) => f.type === 'Structure')
        .filter((f) => datasetReference.fieldNames.includes(f.identity.name))
        .map((f) => f.reference)
      addDatasets = addDatasets.filter((ref, index) => addDatasets.indexOf(ref) === index) // unique

      // addDatasets - list of references that will be added to Publications as child datasets
      // checkDatasets - list of references, that need to be checked. recursion. child dataset's fields may be structures.
      const dsPromises: Promise<any>[] = []
      for (let i = 0; i < addDatasets.length; i++) {
        dsPromises.push(this.getDataset(addDatasets[i]))
      }

      Promise.all(dsPromises).then((flDss) => {
        console.log('Publication:saveDatasetPublication:gotFLRefs', flDss)
        const dsPromises = []
        for (let i = 0; i < flDss.length; i++) {
          // @ts-ignore
          dsPromises.push(this.getDsStructs(flDss[i]))
        }

        Promise.all(dsPromises)
          .then((something) => {
            console.log('structSuccess', something)
            resolve(something)
          })
          .catch((err) => {
            reject(err)
          })
      })
    })
  }

  saveDatasetPublication(datasetReference, close, onSent) {
    const publication = this.getObject()
    console.log('Publication:saveDatasetPublication:REF', publication, datasetReference)

    idAPI
      .publications(publication.identity.id)
      .patch(
        Object.assign({}, publication, {
          datasets: [datasetReference]
        })
      )
      .then(
        () => {
          // this.props.actions.updatePublicationDataset(publication.identity.id, datasetRefsToPublish);
          getObjectNew(idAPI.publications(publication.identity.id), 'publication', this.props.actions, true)
          onSent(close)
        },
        (err) => {
          this.props.actions.setError(null)
          onSent(close, err)
        }
      )
  }

  deletePublicationDataset(datasetToDelete) {
    const publication = this.getObject()
    console.log('Publication:deletePublicationDataset', datasetToDelete, publication)

    if (this.isDatasetChild(datasetToDelete.identity.id)) {
      //let dataset = publication.datasets.find((dataset) => dataset.identity.id === datasetId);
      alert('Cannot delete dataset ' + datasetToDelete.identity.name + ', it is required by other published dataset')
      return false
    }

    sendObjectNew(
      idAPI.publications(publication.identity.id),
      'put',
      this.props.actions,
      Object.assign(publication, {
        datasets: publication.datasets.filter((dataset) => dataset.identity.id !== datasetToDelete.identity.id)
      })
    ).then(() => {
      this.setState({ editingChildMode: false, deleteChildObject: null })
    })
  }

  saveInterfacePublication(interfaceReference, close, onSent) {
    const publication = this.getObject()

    // load interfaces of application.
    let interfaces = getObjects(this.props.appState, ApplicationTabsEnum.INTERFACES, publication.object.parent.id)
    console.log('Publication:saveInterfacePublication', interfaces, interfaceReference)

    idAPI
      .publications(publication.identity.id)
      .patch(
        Object.assign(publication.identity, {
          interfaces: [interfaceReference]
        })
      )
      .then(
        () => {
          this.props.actions.updatePublicationInterface(publication.identity.id, interfaceReference)
          getObjectNew(idAPI.publications(publication.identity.id), 'publication', this.props.actions, true)
          onSent(close)
        },
        (err) => {
          this.props.actions.setError(null)
          onSent(close, err)
        }
      )
  }

  deletePublicationInterface(interfaceToDelete) {
    const publication = this.getObject()
    console.log('Publication:deletePublicationInterface', this.state, interfaceToDelete)

    sendObjectNew(
      idAPI.publications(publication.identity.id),
      'put',
      this.props.actions,
      Object.assign(publication, {
        interfaces: publication.interfaces.filter((inter) => inter.identity.id !== interfaceToDelete.identity.id)
      })
    ).then(() => {
      this.setState({ editingChildMode: false, deleteChildObject: null })
    })
  }

  saveTopologyPublication(topologyReference, close, onSent) {
    const publication = this.getObject()

    // load interfaces of application.
    let topologies = getTopologies(this.props.appState, publication.object.parent.id)
    logger.info('Publication:saveTopologyPublication', topologies, topologyReference, this.state, this.props)

    let promises = topologies.map((top) =>
      getObjectNew(idAPI.interfaces(top.identity.id), 'topology', this.props.actions)
    )

    Promise.all(promises).then(() => {
      idAPI
        .publications(publication.identity.id)
        .patch(
          Object.assign(publication.identity, {
            topologies: [topologyReference]
          })
        )
        .then(
          () => {
            this.props.actions.updatePublicationTopology(publication.identity.id, topologyReference)
            getObjectNew(idAPI.publications(publication.identity.id), 'publication', this.props.actions, true)
            onSent(close)
          },
          (err) => {
            this.props.actions.setError(null)
            onSent(close, err)
          }
        )
    })
  }

  deletePublicationTopology(topologyToDelete) {
    const publication = this.getObject()

    console.log('Publication:deletePublicationTopology', this.state, topologyToDelete)

    sendObjectNew(
      idAPI.publications(publication.identity.id),
      'put',
      this.props.actions,
      Object.assign(publication, {
        topologies: publication.topologies.filter((top) => top.identity.id !== topologyToDelete.identity.id)
      })
    ).then(() => {
      this.setState({ editingChildMode: false, deleteChildObject: null })
    })
  }

  restoreFromCheckpoint = (data) => {
    this.sendPublication(
      Object.assign({}, this.getObject(), data, {
        checkpoints: [],
        versions: []
      }),
      null,
      (tmp, error) => {
        if (!error) alert('Publication was restored!')
        else alert(error)
      }
    )
  }

  render() {
    const publication = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    //logger.info("Publication:render", publication, this.state, this.props);

    if (!publication || publication.isFetching) {
      //console.log("publication: ", publication);
      return this.renderLoading()
    } else {
      const isEditable = this.checkEditable()
      let editingRow: any = null

      let editMode =
        this.checkEditable() && this.state.editingChildMode ? editableState.EDITING : editableState.EDITABLE
      if (!this.checkEditable()) editMode = editableState.BROWSABLE

      if (this.state.editingChild) {
        switch (this.state.editingChildType) {
          case 'dataset': {
            let datasetReference = this.state.editingChildId
              ? deepCopy(publication.datasets.filter((dataset) => dataset.identity.id === this.state.editingChildId)[0])
              : null
            //console.log("Publication:render", this.state, publication, datasetReference, );
            editingRow = (
              <DatasetRefDialog
                appState={this.props.appState}
                actions={this.props.actions}
                type={'dataset reference'}
                publication={publication}
                majorObject={publication}
                datasetReference={datasetReference}
                isVisible={this.state.editingChild}
                isEditable={editMode}
                onSave={this.saveDatasetPublication.bind(this)}
                onClose={this.closeChildEditor.bind(this)}
              />
            )
            break
          }
          case 'interface': {
            let interfaceReference = this.state.editingChildId
              ? deepCopy(publication.interfaces.filter((intr) => intr.identity.id === this.state.editingChildId)[0])
              : null
            console.log('Publication:render', this.state, publication, interfaceReference)
            editingRow = (
              <InterfacePublication
                appState={this.props.appState}
                actions={this.props.actions}
                publication={publication}
                majorObject={publication}
                interfaceReference={interfaceReference}
                isVisible={this.state.editingChild}
                isEditable={editMode}
                onSave={this.saveInterfacePublication.bind(this)}
                onClose={this.closeChildEditor.bind(this)}
              />
            )
            break
          }
          case 'topology': {
            let topologyReference = this.state.editingChildId
              ? deepCopy(
                  publication.topologies.filter((topology) => topology.identity.id === this.state.editingChildId)[0]
                )
              : null
            //logger.info("Publication:render", this.state, publication, topologyReference, );
            editingRow = (
              <TopologyDialog
                appState={this.props.appState}
                actions={this.props.actions}
                type={'topology'}
                isVisible={this.state.editingChild}
                isEditable={editMode}
                majorObject={publication}
                topologyReference={topologyReference}
                referenceState={'Local'}
                onSave={this.saveTopologyPublication.bind(this)}
                onClose={this.closeChildEditor.bind(this)}
              />
            )
            break
          }
          case 'layout': {
            editingRow = (
              <LayoutDialog
                appState={this.props.appState}
                actions={this.props.actions}
                isEditable={this.state.childInEditMode ? 2 : this.checkEditable() ? 1 : 0}
                isVisible={this.state.editingChild}
                onSave={this.saveLayout}
                onClose={this.closeEditDialog}
                layout={this.state.activeMinorObject}
                majorObject={publication}
              />
            )
            break
          }
          default:
            editingRow = null
        }
      }

      return (
        <div className="MajorObject__outer">
          <section className={'MajorObjectView MajorObjectView_' + this.state.objectType}>
            {this.renderHeader()}
            {this.renderInfo()}

            <div className="MajorObjectView__connections">
              {editingRow}
              <div className="row">
                <div className="col-xs-12">
                  {this.renderBackArrowTabs()}
                  <FilteredTabbedTable
                    /*
                    // @ts-ignore */
                    tablesData={this.getData()}
                    theme={this.state.objectType}
                    tabsLoaded={this.state.tabsLoaded}
                    parentUri={getFullUri(publication)}
                    onTabChange={this.onTabChange}
                  />
                  {this.renderFinalizedMessage()}
                </div>
              </div>
            </div>
          </section>
          {publication && publication.identity && publication.identity.id ? (
            <Messages
              ref="messages"
              appState={this.props.appState}
              userState={this.props.userState}
              actions={this.props.actions}
              object={publication}
              objectReload={this.reload.bind(this)}
              objectType={this.state.objectType}
              objectChain={this.props.match.params}
              currentUserRole={this.state.currentUserRole}
            />
          ) : null}
        </div>
      )
    }
  }
}
