const Integration = {
  identity: {
    id: '21b57173-96aa-4cf3-b9e9-82af7ddbd1a9',
    name: 'Integration',
    description: 'Information for integration with external source control system..'
  },
  version: { major: 1, minor: 0, revision: 0 },
  object: {
    contact: { identity: { description: '' }, url: '', email: '' },
    parent: {
      id: '2ba8ee11-e532-442b-8b0d-b6eea3fb4031',
      name: '/organizations/Apdax/systems/DifHub/applications/System'
    },
    tags: [],
    documents: [],
    type: 'DataSet',
    usage: 'Structure',
    access: 'External',
    picture: '',
    properties: [],
    elements: [],
    history: {
      created: '2018-01-16T06:49:20.357',
      createdby: 'sashaberger@hotmail.com',
      updated: '9999-12-30T00:00:00',
      updatedby: 'sashaberger@hotmail.com',
      completion: [
        {
          status: 'Finalized',
          completed: '2018-01-23T06:07:24.507',
          completedby: 'sashaberger@hotmail.com',
          completedbymessage: '/organizations/Apdax/systems/DifHub/applications/System/issues/2629/messages/2'
        },
        {
          status: 'Approved',
          completed: '2018-01-23T06:07:38.48',
          completedby: 'sashaberger@hotmail.com',
          completedbymessage: '/organizations/Apdax/systems/DifHub/applications/System/issues/2629/messages/2'
        }
      ]
    },
    publicationCount: 0,
    subscriptionCount: 0
  },
  subscription: {},
  external: true,
  structure: {
    fields: [
      {
        identity: {
          id: 'b066b134-a066-4d6e-ad79-decf684917df',
          name: 'URL',
          description:
            'URL for root of organization mirroring including .git file. \nExample: https://github.com/superorganization/superorganization-branch.git'
        },
        order: 1,
        usage: 'Property',
        count: 1,
        type: 'String',
        optional: true,
        privacy: 'low'
      },
      {
        identity: {
          id: '3b2811ee-96e9-47a3-9a85-48ac8ba8a1cf',
          name: 'User',
          description:
            'User name or access token to Git repository. If user name is specified then password must be provided.\nAlternative to user name is a token.\nA token can be generated using this instructions: https://help.github.com/articles/creating-a-personal-access-token-for-the-command-line/\n\nWhen token specified instead of user name - no password needed and GitPassword property should be empty.'
        },
        order: 2,
        usage: 'Property',
        count: 1,
        type: 'String',
        optional: true,
        privacy: 'low'
      },
      {
        identity: {
          id: 'b0799ccc-fc73-4e10-887b-f609e36527ca',
          name: 'Password',
          description:
            'Password for access to URL for root of organization mirroring. Only applicable when user User property is actual user name. If it is an access token then password should be empty.'
        },
        order: 3,
        usage: 'Property',
        count: 1,
        type: 'String',
        optional: true,
        privacy: 'low'
      },
      {
        identity: {
          id: '51221389-b4a9-459f-a5f0-a12bec90d7ee',
          name: 'Integration',
          description:
            'Flag to define integration  behavior. \nWhen not set - approvals on each version will produce a JSON file with version in the name.\nWhen set any approved updates on objects will produce JSON file with no version in the name containing the last approved version. The versioned file is produced as well in this case.'
        },
        order: 4,
        usage: 'Property',
        count: 1,
        type: 'Boolean',
        optional: true,
        value: 'false',
        privacy: 'low'
      },
      {
        identity: {
          id: '9b0843e5-5ac3-4265-a466-9278a3d1ab41',
          name: 'Status',
          description: 'Status of integration with external source management system.'
        },
        order: 5,
        usage: 'Property',
        count: 1,
        type: 'String',
        optional: true,
        privacy: 'low'
      }
    ],
    keys: []
  },
  layouts: [],
  data: { records: [], examples: [] },
  _path: '/organizations/Apdax/systems/DifHub/applications/System/datasets/Integration'
}

export default Integration
