import { combineReducers } from 'redux'
import userAppState from './user'
import appState from './appState'
import { uiReducer } from './uiReducer'

const rootReducer = combineReducers({
  userAppState,
  appState,
  ui: uiReducer
})

export default rootReducer
