/* eslint-disable react/no-typos */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  fieldTypeToString,
  getDatasetRequestFromReference,
  getDefaultValue,
  objectNameFromPathByType,
  objectPropertyCI,
  setObjectPropertyCI
} from '../../helpers'
import { getObjectNew } from '../../helpers/api'
import { getDatasetPathFromReference, nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import { LoadableContent } from './LoadableContent'
import { TypedTable } from './TypedTable'

const CHILD_MARGIN = 32

/**
 * PropertyTable is used for Object Properties and Object Advanced Settings
 * It uses properties[] {identity: {name}, type, reference, value}
 * PropertyTable auto-loads enums when mounted
 * PropertyTable expands structures into child PropertyTables and handles changes:
 * when a field in child structure is edited, PropertyTable triggers onChange with entire updated property
 *
 * PropertyTable's state only contains loaded enums and expanded row indices
 * It is externally controlled
 */
export class PropertyTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      enums: {}, // key is reference, value is array of string (enum's options)
      expandedRows: props.expandedRows || []
    }
  }

  /**
   *  loads names of options from dataset object into plain array
   * */
  getOptionsFromEnumData(enumData, usage) {
    let ret = []
    usage = usage || ''
    //console.log("getOptionsFromEnumData", enumData);

    let nameIndex = -1
    let valueIndex = -1
    for (let i = 0; i < enumData.structure.fields.length; i++)
      if (enumData.structure.fields[i].usage && enumData.structure.fields[i].usage.toLowerCase() === 'name')
        nameIndex = i

    for (let i = 0; i < enumData.structure.fields.length; i++)
      if (
        enumData.structure.fields[i].usage &&
        enumData.structure.fields[i].usage.toLowerCase() === usage.toLowerCase()
      )
        valueIndex = i

    if (nameIndex === -1) nameIndex = 0
    if (valueIndex === -1) valueIndex = nameIndex

    if (['value', 'value1', 'value2', 'code'].indexOf(usage.toLowerCase()) === -1) valueIndex = nameIndex

    console.log('getOptionsFromEnumData', enumData.identity.name, usage, nameIndex, valueIndex)

    // special case
    if (this.props.objectType && enumData.identity.name === 'Usage') {
      let objectIndex = 0
      for (let i = 0; i < enumData.structure.fields.length; i++)
        if (
          enumData.structure.fields[i].identity.name &&
          enumData.structure.fields[i].identity.name.toLowerCase() === 'object'
        )
          objectIndex = i

      ret = enumData.data.records
        .filter(
          (rec) =>
            rec.values[objectIndex] && rec.values[objectIndex].toLowerCase() === this.props.objectType.toLowerCase()
        )
        .map((rec) => {
          return { label: rec.values[nameIndex], value: rec.values[valueIndex] }
        })
    } else {
      ret = enumData.data.records.map((rec) => {
        return { label: rec.values[nameIndex], value: rec.values[valueIndex] }
      })
    }

    return ret
  }

  loadNewEnums() {
    this.props.properties.map((prop) => {
      //console.log("PropertyTable::componentDidMount", prop);
      if (prop && prop.type && prop.type.toLowerCase() === 'enum' && prop.reference) {
        if (prop.reference === undefined || prop.reference.indexOf('undefined') > -1)
          console.error('some broken enum', prop)
        const request = getDatasetRequestFromReference(
          getDatasetPathFromReference(prop.reference, this.props.objectPath),
          this.props.objectPath
        )
        getObjectNew(request, 'dataset', null).then((enumData) => {
          logger.info('PropertyTable:cdm::gotEnum', enumData, prop.reference)

          let newEnums = Object.assign({}, this.state.enums)
          if (!newEnums[prop.reference]) newEnums[prop.reference] = {}
          newEnums[prop.reference][prop.usage] = this.getOptionsFromEnumData(enumData, prop.usage)
          this.setState({ enums: newEnums })
        })
      }
    })
  }

  /**
   * loads enums' options into local state
   */
  componentDidMount() {
    ///*
    this.loadNewEnums()
    //*/
  }

  /**
   * generates list of KascodeSelect-style options for enum by reference
   * */
  getEnumOptionsByReference(reference, usage) {
    if (this.state.enums[reference] && this.state.enums[reference][usage]) {
      return this.state.enums[reference][usage].map((item, index) => {
        return {
          id: index,
          label: item.label,
          value: item.value
        }
      })
    }
    return []
  }

  toggleExpandedRow(index) {
    return () => {
      let newExpandedRows = this.state.expandedRows.slice()
      newExpandedRows[index] = !newExpandedRows[index]
      this.setState({ expandedRows: newExpandedRows })
    }
  }

  /**
   * Creates new property object and passes it to onChange handler
   * @param prop
   * @param newRow
   */
  updateRow(prop, newRow) {
    // old value for onChange events
    const oldRow = prop.value
    const oldChildObject = { name: prop.identity.name, value: oldRow }
    // new value
    const newChildObject = { name: prop.identity.name, value: newRow }
    this.props.onChange(prop.index, oldChildObject, newChildObject)
    setTimeout(() => {
      this.loadNewEnums()
    }, 0)
  }

  /**
   * render child Property Table for array-type property
   */
  renderChildArray(prop, isExpanded, groupIndex) {
    const { level, isEditable, editableValues, parentObject, colWidths } = this.props

    //console.log("array property", prop);

    // convert value to array if it is not
    const arrayVal =
      (typeof prop.value === 'object' && prop.value && prop.value.length ? prop.value : [prop.value]) || []

    return (
      <div>
        {!isExpanded ? null : (
          <div className="PropertyTable__childRow">
            <PropertyTable
              properties={arrayVal.map((value, valueIndex) => {
                return {
                  identity: {
                    name: prop.identity.name + '[' + valueIndex + ']'
                  },
                  type: nonCSCompare(prop.type, 'reference') ? 'Structure' : prop.type,
                  reference: nonCSCompare(prop.type, 'reference')
                    ? '/organizations/' +
                      objectNameFromPathByType(prop.reference, 'organizations') +
                      '/datasets/identity'
                    : prop.reference,
                  subscription: nonCSCompare(prop.type, 'reference') ? null : prop.subscription,
                  count: 1,
                  value: value,
                  readOnly: prop.readOnly,
                  usage: prop.usage
                }
              })}
              level={(level || 0) + 1}
              isEditable={isEditable && !prop.readOnly}
              editableValues={editableValues}
              onChange={(childRowIndex, oldChildRow, newChildRow) => {
                const newRow =
                  childRowIndex >= arrayVal.length
                    ? arrayVal.concat(newChildRow.value)
                    : arrayVal.map((oldValue, index) => (index === childRowIndex ? newChildRow.value : oldValue))

                //console.log("array::onChange", childRowIndex, oldChildRow, newChildRow, newRow);
                this.updateRow(prop, newRow)
              }}
              showDelete={!prop.readOnly}
              showAdd={!prop.readOnly}
              onDelete={(obj, index) => {
                const newRow = arrayVal.filter((oldValue, oldIndex) => index !== oldIndex)
                this.updateRow(prop, newRow)
              }}
              onAdd={() => {
                const newRow = arrayVal.concat(getDefaultValue(prop.type.name))
                this.updateRow(prop, newRow)
              }}
              parentObject={parentObject}
              colWidths={colWidths.map((width, index) => (index === 0 ? width : width))}
            />
          </div>
        )}
      </div>
    )
  }

  renderChildStructure(prop, isExpanded, groupIndex) {
    const { level, isEditable, editableValues, objectPath, parentObject, colWidths } = this.props

    return (
      <div>
        <div className="PropertyTable__childRow">
          <LoadableContent
            visible={isExpanded}
            path={getDatasetPathFromReference(prop.reference, objectPath)}
            dataPropertyName="properties"
            dataPropertyName2="parentObject"
            dataTransform={(structureObject) => {
              //console.log("PropertyTable::dataTransform", structureObject);

              // dataset is transformed into property array
              if (!structureObject || !structureObject.structure || !structureObject.structure.fields) return null

              return structureObject.structure.fields.map((field) => {
                return {
                  identity: {
                    name: field.identity.name,
                    description: field.identity.description
                  },
                  type: nonCSCompare(field.type, 'reference') ? 'Structure' : field.type,
                  reference: nonCSCompare(field.type, 'reference')
                    ? '/organizations/' +
                      objectNameFromPathByType(field.reference, 'organizations') +
                      '/datasets/identity'
                    : field.reference,
                  subscription: nonCSCompare(field.type, 'reference') ? null : field.subscription,
                  count: field.count,
                  value: objectPropertyCI(prop.value, field.identity.name, field.type),
                  readOnly: prop.readOnly,
                  usage: field.usage,
                  privacy: field.privacy
                }
              })
            }}
          >
            <PropertyTable
              level={(level || 0) + 1}
              isEditable={isEditable && !prop.readOnly}
              editableValues={editableValues}
              onChange={(childRowIndex, oldChildRow, newChildRow) => {
                // update corresponding field in dataObject.
                // group[0].value is old value of structure
                // oldChildRow.name is name of the changed field
                // newChildRow.value is new value
                const oldRow = prop.value
                const newRow = setObjectPropertyCI(prop.value, oldChildRow.name, newChildRow.value)
                //console.log("Child PropertyTable onChange",prop,childRowIndex, oldChildRow, newChildRow,oldRow,newRow);

                const oldChildObject = {
                  name: prop.identity.name,
                  type: {
                    name: prop.type,
                    reference: prop.reference,
                    subscription: prop.subscription
                  },
                  value: oldRow
                }
                const newChildObject = {
                  name: prop.identity.name,
                  type: {
                    name: prop.type,
                    reference: prop.reference,
                    subscription: prop.subscription
                  },
                  value: newRow
                }
                //console.log("newChildObject", newChildObject);

                // send whole updated structure to the parent table
                this.props.onChange(prop.index, oldChildObject, newChildObject)
                setTimeout(() => {
                  this.loadNewEnums()
                }, 0)
              }}
              objectPath={prop.reference}
              colWidths={colWidths.map((width, index) => (index === 0 ? width : width))}
            />
          </LoadableContent>
        </div>
      </div>
    )
  }

  render() {
    const {
      properties,
      isEditable,
      editableNames,
      editableTypes,
      editableValues,
      showDelete,
      showAdd,
      colWidths,
      parentObject,
      objectPath,
      structures,
      showCopyPaste,
      inEditMode
    } = this.props
    let level = this.props.level || 0

    console.log('PropertyTable:render', properties)

    // properties are split into groups: every structure-type property is a group itself, and normal properties are joined
    // separate tables are renderer for structure-type properties; consequent normal properties are rendered in one table
    let propertyGroups = [[]]

    properties.map((prop, index) => {
      prop.index = index
      if (prop.reference || prop.count === 0 || prop.count > 1) {
        propertyGroups.push([prop])
        propertyGroups.push([])
      } else {
        propertyGroups[propertyGroups.length - 1].push(prop)
      }
    })

    propertyGroups = propertyGroups.filter((group) => group.length > 0)

    if (propertyGroups.length === 0) {
      propertyGroups.push([])
    }

    let rowIndex = 0

    return (
      <div>
        {propertyGroups.map((group, groupIndex) => {
          let currentRowIndex = rowIndex
          rowIndex += group.length

          let expandedBody = ''
          let expandButton = ''
          let isReadOnly = false

          //console.log("PropertyTable:group", group);

          // for array we render expand button and child property table with row for each array item

          // arrays and structures are the only prop in their group, so we check only group[0]

          if (group.length > 0) {
            if (group[0].count === 0 || group[0].count > 1) {
              expandedBody = this.renderChildArray(group[0], this.state.expandedRows[groupIndex], groupIndex)
            }
            // for structures we render expand button, and for expanded structures we render child Property Tables
            else if (group[0].reference && group[0].type.toLowerCase() === 'structure' /*&& !(level > 0)*/) {
              expandedBody = this.renderChildStructure(group[0], this.state.expandedRows[groupIndex], groupIndex)
            }

            if (group[0].readOnly) isReadOnly = true
          }

          if (expandedBody) {
            expandButton = (
              <div
                className={
                  'PropertyTable__expandButton ' +
                  (this.state.expandedRows[groupIndex] ? 'PropertyTable__expandButton_active' : '')
                }
                onClick={this.toggleExpandedRow(groupIndex)}
              >
                {this.state.expandedRows[groupIndex] ? '-' : '+'}
              </div>
            )
          }

          const hide = group.length > 0 && group[0].hide

          // header is rendered only for the first top-level table
          return (
            <div key={groupIndex} className={level === 0 ? 'PropertyTable__container' : ''}>
              <TypedTable
                hideHeader={groupIndex > 0 || level > 0}
                columns={[
                  // nothing is actually rendered in expandButton column, it is used as margin. absolutely-positioned expandButtons are rendered over it

                  {
                    name: 'expandButton',
                    displayName: ' ',
                    type: { name: 'data' },
                    frozen: true,
                    width: CHILD_MARGIN
                  },

                  editableNames
                    ? {
                        name: 'name',
                        displayName: 'Name',
                        type: { name: 'string' },
                        frozen: !editableNames,
                        width: (colWidths && colWidths[0] ? colWidths[0] : 310) - level * CHILD_MARGIN - CHILD_MARGIN,
                        inEditMode: inEditMode
                      }
                    : {
                        name: 'identity',
                        displayName: 'Name',
                        type: { name: 'propertyName' },
                        frozen: !editableNames,
                        width: (colWidths && colWidths[0] ? colWidths[0] : 310) - level * CHILD_MARGIN - CHILD_MARGIN,
                        inEditMode: inEditMode
                      },

                  {
                    name: 'type',
                    type: { name: 'typeReference' },
                    frozen: !editableTypes,
                    width: colWidths && colWidths[1] ? colWidths[1] : 294,
                    inEditMode: inEditMode
                  },
                  {
                    name: 'value',
                    frozen: !editableValues,
                    inEditMode: (!editableNames && !editableTypes) || inEditMode,
                    width: colWidths && colWidths[2] ? colWidths[2] : 428
                  }
                ]}
                data={
                  hide
                    ? []
                    : group.map((prop) => {
                        let typeName
                        if (prop.type) typeName = prop.type
                        else typeName = 'string' // string is default type
                        let type = typeName
                        if (prop.reference) type += ' ' + prop.reference

                        //let fullType = {displayType: type, name: typeName, reference: prop.reference};
                        // todo: use fieldTypeToString to display the type in correct format

                        //console.log("PropertyTable:prop", prop, parentObject);

                        let fullType = {
                          displayType: fieldTypeToString(
                            {
                              identity: prop.identity,
                              type: prop.type || 'string',
                              count: prop.count,
                              size: prop.size,
                              reference: prop.reference ? prop.reference.replace('$:', '') : null,
                              subscription: prop.reference ? prop.subscription : null,
                              usage: prop.usage
                            },
                            parentObject
                          ),
                          datasetReference: !prop.reference
                            ? ''
                            : getDatasetPathFromReference(prop.reference, objectPath),
                          label: prop.type || 'string'
                        }

                        const row = {
                          name: prop.identity.name,
                          identity: prop.identity,
                          field: prop.field,
                          type: fullType,
                          value: typeof prop.value === 'object' ? JSON.stringify(prop.value) : prop.value,
                          expandButton: expandButton,
                          readOnly: prop.readOnly
                        }
                        //console.log("PropertyTable:row", row);
                        return row
                      })
                }
                getType={(colIndex, rowIndex) => {
                  /* the function will only be called for Value column; Name and Type have defined type. */
                  //console.log("PropertyTable::getType",group[rowIndex]);
                  let ret = null
                  if (group[rowIndex].type) ret = { name: group[rowIndex].type.toLowerCase() }
                  else ret = { name: 'string' }

                  if (ret.name === 'enum') {
                    ret.options = this.getEnumOptionsByReference(group[rowIndex].reference, group[rowIndex].usage)
                    ret.hideCheckBox = true
                  }

                  ret.size = group[rowIndex].size

                  return ret
                }}
                isEditable={isEditable && !isReadOnly && !expandedBody}
                onChangeRow={(index, data, newrow) => {
                  this.props.onChange(index, data, newrow)
                  setTimeout(() => {
                    this.loadNewEnums()
                  }, 0)
                }}
                tailButtons={(showDelete ? [{ label: 'Delete', onClick: this.props.onDelete }] : []).concat(
                  showCopyPaste ? [{ label: 'Copy', onClick: this.props.onCopy }] : []
                )}
                bottomButtons={
                  showAdd && groupIndex === propertyGroups.length - 1
                    ? [{ label: '+ Add element', onClick: this.props.onAdd }]
                    : []
                }
                bottom={expandedBody}
                firstRowIndex={currentRowIndex}
              />
            </div>
          )
        })}
      </div>
    )
  }
}

PropertyTable.propTypes = {
  properties: PropTypes.array, // {idenity: {name } , type: enum, reference, value, (opt.) count, (opt). readOnly, (opt) description
  isEditable: PropTypes.boolean,
  editableNames: PropTypes.boolean, // property names can be edited
  editableTypes: PropTypes.boolean, // property types can be edited
  editableValues: PropTypes.boolean, // property values can be edited
  showAdd: PropTypes.boolean, // can add properties
  showDelete: PropTypes.boolean, // can delete properties
  onChange: PropTypes.func, // (rowIndex, oldRow, newRow)
  onAdd: PropTypes.func, // ()=>{}
  onDelete: PropTypes.func, // (prop)=>{}
  level: PropTypes.number, // integer, optional
  colWidths: PropTypes.array, // optional
  objectType: PropTypes.string, // used to filter Usage enum values
  parentObject: PropTypes.object, // used to display relative type references
  showCopyPaste: PropTypes.boolean,
  onCopy: PropTypes.func,
  inEditMode: PropTypes.boolean
}
