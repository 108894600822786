import PropTypes from 'prop-types'

import React, { Component } from 'react'
import logger from '../../helpers/logger'

/**
 * PagedContent passes to child slice of "data" array to its "dataPropertyName" propertyge
 * and renders page selector below
 * Page size is defined by pageSize property
 */
let timer: any = null

type PagedContentProps = {
  defaultPage: number
  pageSize: number
  data: any[]
  scrollToTop: boolean
  onPageChange: Function
  dataPropertyName: string
  children: JSX.Element
}

type PagedContentState = {
  editingPage: number
  page: number
}

export class PagedContent extends Component<PagedContentProps, PagedContentState> {
  constructor(props) {
    super(props)
    this.state = {
      editingPage: -1,
      page: -1
    }
  }

  componentDidMount() {
    // Initiate first page
    //logger.log('PagedContent:componentDidMount', this.props.defaultPage, this.state, this.props);
    this.goToPage(this.props.defaultPage)()
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.data.length > this.props.data.length) {
      if (this.props.data.length < 100) this.setState({page:0,editingPage:1})
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    /*
    const {dataPropertyName, data, pageSize, children} = newProps;
    const pageCount = Math.ceil(data.length / pageSize);
    if (this.state.page >= pageCount) {
      //this.setState({page: pageCount - 1});
      this.goToPage(pageCount - 1, false)();
    }
    */
    // run goToPage func only when we get new page index
    if (
      this.props.defaultPage !== newProps.defaultPage ||
      this.props.pageSize !== newProps.pageSize ||
      this.props.data.length !== newProps.data.length
    ) {
      //logger.log('PagedContent:componentWillReceiveProps', newProps.defaultPage);
      if (newProps.defaultPage >= 0) {
        this.goToPage(newProps.defaultPage, true, newProps)()
      }
    }
  }

  goToPage =
    (index, needScroll = true, props = this.props) =>
    () => {
      const { data, pageSize } = props || this.props
      const pageCount = Math.ceil(data.length / pageSize)
      if (isNaN(index) || index < 0 || index >= pageCount) {
        index = 0
      }

      // Check if we are here already.
      if (this.state.page === index) {
        return index
      }

      //logger.info('PagedContent:goToPage', { index, pageCount, needScroll }, this.state, this.props);

      // Set new state for the page.
      this.setState({ page: index, editingPage: index + 1 }, () => {
        if (this.props.scrollToTop && needScroll) {
          window.scrollTo(0, 0)
        }
        if (this.props.onPageChange)
          this.props.onPageChange(
            index,
            data.slice(index * pageSize, (index + 1) * pageSize),
            data.slice(0, index * pageSize),
            data.slice((index + 1) * pageSize)
          )
      })
      return index
    }

  editPage = (event) => {
    const page = parseInt(event.target.value) - 1
    //logger.info('PagedContent:editPage', { event, page }, this.state, this.props);

    // Set editing page from event.
    this.setState({ editingPage: event.target.value })
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      this.goToPage(page, false)()
    }, 500)
  }

  renderNav = () => {
    const { data, pageSize } = this.props
    const pageCount = Math.ceil(data.length / pageSize)

    if (pageCount > 1) {
      return (
        <div className="PagedContent__nav">
          <div className="PagedContent__pageHeader" />

          {this.state.page < pageCount - 1 ? (
            <div
              className={
                'PagedContent__page PagedContent__page__last ' +
                (this.state.page === pageCount - 1 ? 'PagedContent__page_active' : '')
              }
              onClick={this.goToPage(pageCount - 1)}
            />
          ) : null}
          {this.state.page < pageCount - 1 ? (
            <div
              className={
                'PagedContent__page PagedContent__page__next ' +
                (this.state.page === pageCount - 1 ? 'PagedContent__page_active' : '')
              }
              onClick={this.goToPage(this.state.page + 1)}
            />
          ) : null}
          <div className={'PagedContent__page '}>
            <div className="PagedContent__totalPages">
              <input className="PagedContent__pageInput" type="text" size={4} value={'/ ' + pageCount} readOnly />
            </div>
            <input
              className="PagedContent__pageInput"
              type="text"
              size={4}
              value={this.state.editingPage}
              onChange={this.editPage}
            />
          </div>
          {this.state.page > 0 ? (
            <div
              className={
                'PagedContent__page PagedContent__page__prev ' +
                (this.state.page === 0 ? 'PagedContent__page_active' : '')
              }
              onClick={this.goToPage(this.state.page - 1)}
            />
          ) : null}
          {this.state.page > 0 ? (
            <div
              className={
                'PagedContent__page PagedContent__page__first ' +
                (this.state.page === 0 ? 'PagedContent__page_active' : '')
              }
              onClick={this.goToPage(0)}
            />
          ) : null}
        </div>
      )
    }

    return null
  }

  render() {
    // dataPropertyName can be cellValues
    const { dataPropertyName, data, pageSize, children } = this.props
    const { page } = this.state

    let pageData = data.slice(page * pageSize, (page + 1) * pageSize)
    //logger.info("PagedContent:render", data, page * pageSize, pageSize, pageData);

    return (
      <div className="PagedContent">
        {React.cloneElement(children, { [dataPropertyName]: pageData })}
        {this.renderNav()}
      </div>
    )
  }
}
