/**
 * Created by mailf on 08.06.2016.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './ExportSchemaDialog.scss'

export class ExportSchemaDialog extends EditorDialogBase {
  getObjectType() {
    return 'exportParams'
  }

  /**
   * Returns minimum height for dialog
   */
  getMinHeight() {
    return 400
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load datasry with parameters metadata
    this.loadMetadata(
      '/organizations/Apdax/systems/Difhub/applications/Schema/datasets/exportConfiguration',
      '/organizations/Apdax/systems/Difhub/applications/Schema/views/ExportSchemaDialog'
    )

    // Load default parameters for schema of object type.
    let params = this.getDefaultSchemaParams()
    this.Change(params)

    logger.info('ExportSchemaDialog:open', params, this.state, this.props)
  }

  /**
   * Get Default Schema Params base on type of object
   */
  getDefaultSchemaParams = () => {
    let defaultSchemaParams
    if (nonCSCompare(this.props.majorObject.object.type, 'dataset')) {
      defaultSchemaParams = {
        schemaType: 'JSON7', // Type of schema
        useApprovedVersion: true, // Use last approve minor version for specified major version.
        hideFutureFields: true, // Hide field with prefix FUTURE:
        hideOptionalFields: false, // Hide optional fields,
        flattenInheritedFields: true, // Flatten object mark as inherit.
        alignNames: 'LOWER', // Use upper/lower case for first latter for name in schema and remove spaces.
        alignEnums: 'PRESERVE', // Use upper/lower case and replace spaces with underscores for values in enums.
        minimizeSchemaSize: false // Remove not esential data from schema.
      }
    } else {
      defaultSchemaParams = {
        schemaType: 'OPENAPI', // Type of schema
        useApprovedVersion: true, // Use last approve minor version for specified major version.
        hideFutureFields: true, // Hide field with prefix FUTURE:
        hideOptionalFields: false, // Hide optional fields,
        flattenInheritedFields: true, // Flatten object mark as inherit.
        alignNames: 'LOWER', // Use upper/lower case for first latter for name in schema and remove spaces.
        alignEnums: 'UPPER', // Use upper/lower case and replace spaces with underscores for values in enums.
        minimizeSchemaSize: false // Remove not esential data from schema.
      }
    }
    return defaultSchemaParams
  }

  onFilterOptions = (field, options) => {
    if (field !== 'schemaType' || !options || options.length === 0) return options

    // Type of object.
    let type = this.props.majorObject.object.type
    let result = options.filter((option) => option.object['Objects'].find((o) => nonCSCompare(o, type)))

    //logger.info("ExportSchemaDialog:onFilterOptions", type, field, options, result);
    return result
  }

  /**
   * Render content of layout editor dialog
   */
  renderEditor = (editState) => {
    //logger.info("ExportSchemaDialog:renderEditor", editState, view, this.state, this.props);

    return <div className="ExportSchemaDialog">{this.renderView(editState)}</div>
  }
}

ExportSchemaDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  exportParams: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
