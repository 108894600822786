import { Box } from '@material-ui/core'

import DashboardIcon from '@material-ui/icons/Dashboard'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import AppsIcon from '@material-ui/icons/Apps'
import PublishIcon from '@material-ui/icons/Publish'
import GetAppIcon from '@material-ui/icons/GetApp'

import React from 'react'
import { Side } from './Sidebar'

import './WithSideBar.scss'

export const mainListItems = [
  {
    route: '/',
    title: 'Organizations',
    icon: <DashboardIcon />
  },
  {
    route: '/',
    title: 'Systems',
    icon: <SettingsApplicationsIcon />
  },
  {
    route: '/',
    title: 'Applications',
    icon: <AppsIcon />
  },
  {
    route: '/',
    title: 'Publications',
    icon: <PublishIcon />
  },
  {
    route: '/',
    title: 'Subscriptions',
    icon: <GetAppIcon />
  }
]

export const WithSideBar = ({ children, ...rest }) => {
  return (
    <Box display="flex">
      <Side mainListItems={mainListItems} {...rest} />
      <Box flexGrow={1} className="MainContainer">
        {children}
      </Box>
    </Box>
  )
}
