/**
 * Created by kascode on 14.04.16.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditButton } from '../EditButton/EditButton'
import './style.scss'

export class ObjectDescription extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      textareaValue: props.text
    }
  }

  edit() {
    this.setState({ isEditing: true })
  }

  onChange(event) {
    this.setState({
      textareaValue: event.target.value
    })
  }

  save() {
    if (this.props.onChange) {
      this.props.onChange(this.state.textareaValue)
    }
    this.setState({ isEditing: false })
  }

  cancel() {
    this.setState({
      textareaValue: this.props.text,
      isEditing: false
    })
  }

  render() {
    let text = this.props.text
    return (
      <div className="ObjectDescription">
        <div className="ObjectDescription__control">
          {this.props.isEditable ? (
            <EditButton
              inEditMode={this.state.isEditing}
              onSave={this.save.bind(this)}
              onEdit={this.edit.bind(this)}
              onCancel={this.cancel.bind(this)}
            />
          ) : null}
          <h2
            className={'ObjectDescription__title ' + 'ObjectDescription__title__editable'}
            onClick={this.edit.bind(this)}
          >
            {this.props.descriptionTitle ? this.props.descriptionTitle : 'Description:'}
          </h2>
        </div>
        <div className="ObjectDescription__content">
          <EditableEntity
            dataProps={{
              className: 'ObjectDescription__text',
              maxLineCount: '3',
              title: this.props.title,
              defaultLineHeight: '19',
              onChange: this.onChange.bind(this),
              onBlur: this.save.bind(this)
            }}
            data={text ? text : 'No value'}
            dataType={{ name: 'text' }}
            inEditMode={this.state.isEditing}
            isEditable={this.props.isEditable}
            onEntityClick={() => {
              if (this.props.isEditable) this.setState({ isEditing: true })
            }}
          />
        </div>
      </div>
    )
  }
}

ObjectDescription.propTypes = {
  text: PropTypes.string,
  locale: PropTypes.string,
  title: PropTypes.string,
  descriptionTitle: PropTypes.string,
  onChange: PropTypes.func,
  isEditable: PropTypes.bool
}
