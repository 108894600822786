import React, { Component } from 'react'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import { LinkedKeyTables } from '../LinkedKeyTables/LinkedKeyTables'
import logger from '../../helpers/logger'

import './style.scss'

export class PasteDialogOld extends Component {
  constructor(props) {
    super(props)

    const newObject = JSON.parse(localStorage.clipboard)

    let pastingChildNameValue

    if (newObject && newObject.identity) {
      pastingChildNameValue = newObject.identity.name
    } else if (newObject && newObject.field && newObject.field.identity) {
      pastingChildNameValue = newObject.field.identity.name
    } else {
      pastingChildNameValue = ''
    }

    this.state = {
      // pastingChildName: newObject && newObject.identity ? newObject.identity.name : (newObject && newObject.field && newObject.field.identity ? newObject.field.identity.name : ''),
      pastingChildName: pastingChildNameValue,
      pastingFields: [],
      restoreObject: false,
      renameObject: false
    }
  }
  onFieldChange = (newSelected) => {
    logger.log('onFieldChange', newSelected)
    this.setState({
      pastingFields: newSelected
    })
  }

  onRestore = (e) => {
    this.setState({
      restoreObject: e.target.checked
    })
  }

  onRename = (e) => {
    this.setState({
      renameObject: e.target.checked
    })
  }

  onSubmitPaste = () => {
    let name = this.state.pastingChildName

    if (this.state.restoreObject) {
      name = this.state.pastingChildName + ' $restore'
    } else if (this.state.renameObject) {
      name = this.state.renameObject + ' $rename'
    }

    this.props.pasteChild(name, this.state.pastingFields)
  }

  render() {
    const newObject = JSON.parse(localStorage.clipboard)
    const newObjectType = localStorage.clipboardType

    return (
      <ModalWindow
        className="MajorObject__pasteDialog"
        header="Enter object name"
        footer={
          <div>
            <button className="ButtonBlue ButtonBlue_big" onClick={this.props.onClose}>
              Cancel
            </button>
            <button className="ButtonBlue ButtonBlue_big" onClick={() => this.onSubmitPaste()}>
              Paste
            </button>
          </div>
        }
        isOpen
        onClose={this.props.onClose}
      >
        <div className="row">
          <div className="col-xs-12">
            {this.props.replacingObject ? (
              <div className="col-xs-12">
                You are replacing {localStorage.clipboardType} {this.props.replacingObject}
              </div>
            ) : null}
            <b>New object name:</b>
            <input
              className="TextInput ObjectCreator__input"
              type="text"
              value={this.state.pastingChildName}
              onChange={(e) => {
                logger.log('MajorObject:pasteDialog:onChange', this.state)
                this.setState({ pastingChildName: e.target.value })
              }}
            />
          </div>
          <div className="col-xs-12">
            <input type="checkbox" name="restore" id="restore" onChange={(e) => this.onRestore(e)} />
            <label htmlFor="restore">Restore object</label>
          </div>
          <div className="col-xs-12">
            <input type="checkbox" name="rename" id="rename" onChange={(e) => this.onRename(e)} />
            <label htmlFor="rename">Rename object</label>
          </div>
        </div>
        {newObjectType === 'dataset' ? (
          <div className="row">
            <div className="col-xs-12">
              <b>Fields:</b>
              <br />
              <LinkedKeyTables
                columns={['NN', 'Name']}
                selected={this.state.pastingFields}
                items={newObject.structure.fields.map((field, index) => [index, field.identity.name])}
                onChange={this.onFieldChange}
                editable
              />
            </div>
          </div>
        ) : null}
      </ModalWindow>
    )
  }
}
