import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { columnsToType, deploymentChildTypes, getFullUri } from '../../helpers'
import { singularType } from '../../helpers/api'
import { FilterableContent } from '../FilterableContent/FilterableContent'
import { NestedTable } from '../NewTable/NestedTable'

export class PackageViewer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showSearch: false, // Search filter
      filterMapped: false // Mapped filter
    }
  }

  toggleSearchVisible = () => {
    this.setState((prevState) => ({
      ...prevState,
      showSearch: !prevState.showSearch
    }))

    // it needed for update mapping draw
    if (this.props.onTableExpand) {
      this.props.onTableExpand()
    }
  }

  toggleFilterMapped = () => {
    this.setState((prevState) => ({
      ...prevState,
      filterMapped: !prevState.filterMapped
    }))
  }

  onTableExpand = () => {
    if (this.props.onTableExpand) {
      this.props.onTableExpand()
    }
  }

  onItemScroll = () => {
    if (this.props.onItemScroll) {
      this.props.onItemScroll()
    }
  }

  onPackageChildClick = (id, childRef, appRef, deployment, packageData) => {
    // logger.log("InstanceViewer::onInstanceClick", { id, childRef, appRef, deployment, packageData });

    if (this.props.onItemClick) {
      const selectedItem = {
        application: appRef,
        deployment: deployment,
        childRef: childRef,
        packageData: packageData
      }

      this.props.onItemClick(id, childRef.type, selectedItem)
    }
  }

  onPackageClick = (id, type, appRef, deployment, packageData) => {
    // logger.log("InstanceViewer::onInstanceClick", { id, childRef, appRef, deployment, packageData });

    if (this.props.onItemClick) {
      const selectedItem = {
        application: appRef,
        deployment: deployment,
        packageData: packageData
      }

      this.props.onItemClick(id, type, selectedItem)
    }
  }

  render() {
    const { deployment, packageData, selectedItemId, selectedItemType, environment } = this.props
    const { filterMapped, showSearch } = this.state

    // divId is used to process field clicks
    const divId = 'deployment_' + deployment.identity.name + '_package_' + packageData.identity.name

    const data = packageData.applications.map((appRef) => {
      const myId = divId + '_' + appRef.identity.name
      return {
        name: (
          <div
            id={myId}
            className={myId === selectedItemId ? 'selectedItem' : ''}
            onClick={() => this.onPackageClick(myId, 'application', appRef, deployment, packageData)}
          >
            {appRef.identity.name}
          </div>
        ),
        children: deploymentChildTypes
          .reduce(
            (list, type) =>
              list.concat((appRef[type] || []).map((childRef) => Object.assign({}, childRef, { type: type }))),
            []
          )
          .map((childRef) => {
            const myId = divId + '_' + appRef.identity.name + '_' + childRef.type + '_' + childRef.identity.name
            return {
              name: (
                <div
                  id={myId}
                  className={myId === selectedItemId ? 'selectedItem' : ''}
                  onClick={() => {
                    if (selectedItemType === 'unit') {
                      // Add line
                    }
                    this.onPackageChildClick(myId, childRef, appRef, deployment, packageData)
                  }}
                >
                  {childRef.identity.name}
                </div>
              ),
              type: singularType(childRef.type),
              children: !childRef.layoutInstances
                ? null
                : childRef.layoutInstances.map((layout) => {
                    const myId =
                      divId +
                      '_' +
                      appRef.identity.name +
                      '_' +
                      childRef.type +
                      '_' +
                      childRef.identity.name +
                      '_' +
                      layout.layoutName
                    return {
                      name: (
                        <div
                          id={myId}
                          className={myId === selectedItemId ? 'selectedItem' : ''}
                          onClick={() => {
                            this.onPackageChildClick(myId, layout, appRef, deployment, packageData)
                          }}
                        >
                          {layout.layoutName}
                        </div>
                      ),
                      type: singularType(layout.unit)
                    }
                  })
            }
          })
      }
    })

    // logger.log('PackageViewer::render', this.props);

    return (
      <div id={divId} className={'table'} onScroll={() => this.onItemScroll()}>
        <table>
          <thead>
            <tr>
              <td className="title" colSpan="2" style={{ backgroundColor: 'rgb(4, 193, 180)' }}>
                <div className="ObjectPicture" style={{ color: 'transparent' }}>
                  x
                </div>
                <span className="table_header">{packageData.identity.name}</span>
                <div
                  className={'EnvironmentChart__search ' + (showSearch ? 'EnvironmentChart__search__active' : '')}
                  onClick={this.toggleSearchVisible}
                />
                <div
                  className={'EnvironmentChart__filter ' + (filterMapped ? 'EnvironmentChart__filter__active' : '')}
                  onClick={this.toggleFilterMapped}
                />
              </td>
            </tr>
          </thead>
        </table>
        <FilterableContent
          data={data}
          dataFilters={showSearch ? [{ name: 'search', width: 389 }] : []}
          parentUri={getFullUri(environment) + '/Chart/packages/' + packageData.identity.name}
          onChangeFilter={() => {
            setTimeout(() => {
              this.forceUpdate()
            }, 100)
          }}
          noLocalStorage
        >
          <NestedTable
            columns={[
              {
                displayName: ' ',
                name: 'expandButton',
                type: { name: 'data' },
                frozen: true,
                width: 30
              },
              {
                displayName: ' ',
                name: 'name',
                type: columnsToType.getType('data'),
                width: 411 - 30 - 80 - 50
              },
              {
                displayName: ' ',
                name: 'type',
                type: columnsToType.getType('string'),
                width: 130
              }
            ]}
            onExpand={() => this.onTableExpand()}
          />
        </FilterableContent>
      </div>
    )
  }
}

PackageViewer.propTypes = {
  editState: PropTypes.number,
  deployment: PropTypes.object,
  packageData: PropTypes.object,
  reference: PropTypes.string,
  selectedItemId: PropTypes.string,
  selectedItemType: PropTypes.string,
  environment: PropTypes.string,
  onItemClick: PropTypes.func,
  onTableExpand: PropTypes.func,
  onItemScroll: PropTypes.func
}
