import * as types from '../constants/ActionTypes'

export function updateMajorObject(id, objectType, payload) {
  return {
    type: types.UPDATE_MAJOR_OBJECT,
    id,
    objectType,
    payload
  }
}

export function updateName(id, objectType, name, locale) {
  return {
    type: types.UPDATE_MAJOR_OBJECT_NAME,
    id,
    objectType,
    name,
    locale
  }
}

export function updateDescription(id, objectType, text, locale) {
  return {
    type: types.UPDATE_MAJOR_OBJECT_DESCRIPTION,
    id,
    objectType,
    text,
    locale
  }
}

export function updateUsers(id, objectType, users) {
  return {
    type: types.UPDATE_MAJOR_OBJECT_USERS,
    id,
    objectType,
    users
  }
}

export function updateProperties(id, objectType, properties) {
  return {
    type: types.UPDATE_MAJOR_OBJECT_PROPERTIES,
    id,
    objectType,
    properties
  }
}

export function updateAdvancedSettings(id, objectType, settings) {
  return {
    type: types.UPDATE_MAJOR_OBJECT_ADVANCED_SETTINGS,
    id,
    objectType,
    settings
  }
}

export function updateTags(id, objectType, tags) {
  return {
    type: types.UPDATE_MAJOR_OBJECT_TAGS,
    id,
    objectType,
    tags
  }
}

export function updateParent(objectType, objectName, parentIdentity) {
  return {
    type: types.UPDATE_OBJECT_PARENT,
    parentIdentity,
    objectName,
    objectType
  }
}

export function updateMajorObjectDocuments(id, objectType, docs) {
  return {
    type: types.UPDATE_MAJOR_OBJECT_DOCUMENTS,
    id,
    objectType,
    docs
  }
}

export function requestObject(type, id) {
  return {
    type: types.REQUEST_OBJECT,
    objType: type,
    id
  }
}

export function receiveObject(type, id, obj) {
  return {
    type: types.RECEIVE_OBJECT,
    objType: type,
    obj,
    id,
    receivedAt: Date.now()
  }
}

export function receiveObjectMerged(type, id, obj) {
  return {
    type: types.RECEIVE_OBJECT_MERGED,
    objType: type,
    obj,
    id,
    receivedAt: Date.now()
  }
}

export function receiveObjectList(type, objectList) {
  return {
    type: types.RECEIVE_OBJECT_LIST,
    objType: type,
    objectList,
    receivedAt: Date.now()
  }
}

export function receiveErrorObject(type, name, error) {
  return {
    type: types.RECEIVE_ERROR_OBJECT,
    objType: type,
    name,
    error
  }
}

export function addMessage(id, objectType, user, message) {
  return {
    type: types.MAJOR_OBJECT_ADD_MESSAGE,
    id,
    objectType,
    user,
    message
  }
}

export function updateField(id, objectType, new_props) {
  return {
    type: types.UPDATE_FIELD,
    id,
    objectType,
    new_props
  }
}

export function updateInterfaces(id, objectType, data) {
  return {
    type: types.UPDATE_PUBLICATION_INTERFACES,
    id,
    objectType,
    payload: data
  }
}

export function updatePublicationDataset(id, dataset) {
  return {
    type: types.UPDATE_PUBLICATION_DATASET,
    id,
    payload: dataset
  }
}

export function deletePublicationDataset(id, index) {
  return {
    type: types.DELETE_PUBLICATION_DATASET,
    id,
    payload: index
  }
}

export function deletePublicationInterface(id, index) {
  return {
    type: types.DELETE_PUBLICATION_INTERFACE,
    id,
    payload: index
  }
}

export function updatePublicationInterface(id, intfc) {
  return {
    type: types.UPDATE_PUBLICATION_INTERFACE,
    id,
    payload: intfc
  }
}

export function updatePublicationTopology(id, top) {
  return {
    type: types.UPDATE_PUBLICATION_TOPOLOGY,
    id,
    payload: top
  }
}

export function updateSubscriptionDataset(id, dataset) {
  return {
    type: types.UPDATE_SUBSCRIPTION_DATASET,
    id,
    payload: dataset
  }
}

export function deleteSubscriptionDataset(id, index) {
  return {
    type: types.DELETE_SUBSCRIPTION_DATASET,
    id,
    payload: index
  }
}

export function createObject(type, name) {
  return {
    type: types.CREATE_MAJOR_OBJECT,
    objectType: type,
    name
  }
}

export function deleteObject(type, name) {
  return {
    type: types.DELETE_OBJECT,
    objectType: type,
    name
  }
}

export function clearObjectList(type) {
  return {
    type: types.CLEAR_OBJECT_LIST,
    objectType: type
  }
}

export function receiveObjectListIntoProperty(objectList, majorObject, majorObjectType, propertyName) {
  return {
    type: types.RECEIVE_OBJECT_LIST_INTO_PROPERTY,
    objType: majorObjectType,
    objectList,
    receivedAt: Date.now(),
    majorObject,
    propertyName
  }
}

export function deleteMajorObject(objectType, objectId) {
  return {
    type: types.DELETE_MAJOR_OBJECT,
    objectType,
    objectId
  }
}

export function receiveSettingsIntoProperty(value, majorObject, majorObjectType, propertyName) {
  return {
    type: types.RECEIVE_SETTINGS_INTO_PROPERTY,
    objType: majorObjectType,
    value,
    receivedAt: Date.now(),
    majorObject,
    propertyName
  }
}

export function updateIssueTasks(id, tasks) {
  return {
    type: types.UPDATE_ISSUE_TASKS,
    id,
    tasks
  }
}

export type MajorObjectActions = {
  updateMajorObject
  updateName
  updateDescription
  updateUsers
  updateProperties
  updateAdvancedSettings
  updateTags
  updateParent
  updateMajorObjectDocuments
  requestObject
  receiveObject
  receiveObjectMerged
  receiveObjectList
  receiveErrorObject
  addMessage
  updateField
  updateInterfaces
  updatePublicationDataset
  deletePublicationDataset
  deletePublicationInterface
  updatePublicationInterface
  updatePublicationTopology
  updateSubscriptionDataset
  deleteSubscriptionDataset
  createObject
  deleteObject
  clearObjectList
  receiveObjectListIntoProperty
  deleteMajorObject
  receiveSettingsIntoProperty
  updateIssueTasks
}
