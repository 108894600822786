import PropTypes from 'prop-types'
import React from 'react'
import { createEmptyObject, deepCopy, nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './LayoutDialog.scss'
import { LayoutView } from './LayoutView'

const POLICY_HEIGHT = 60
const COUNTER_HEIGHT = 60
const RULE_HEIGHT = 60

export class LayoutDialog extends EditorDialogBase {
  getObjectType() {
    return 'layout'
  }

  getElementsHeight() {
    // We have in parallel rules and properties
    return {
      __group: {
        policies: POLICY_HEIGHT,
        counters: COUNTER_HEIGHT,
        rules: RULE_HEIGHT
      }
    }
  }

  /**
   * Returns initial object for dialog
   */
  getInitial(props) {
    //logger.info("LayoutDialog:getInitial", props, this.state, this.props);
    props = props || this.props
    return deepCopy(props[this.getObjectType()]) || createEmptyObject('layout')
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load platforms list from organization we are in.
    this.loadPlatform(this.state.instance ? this.state.instance.platform : null)

    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/System/datasets/Layout',
      '/organizations/Apdax/systems/DifHub/applications/System/views/LayoutDialog'
    )

    logger.info('LayoutDialog:open', this.state, this.props)
  }

  /**
   * Get types
   * @param {string} [type] - system type
   * @param {string} [platform] - platform we need type for
   * @returns {*}
   */
  getTypes = (type, platform) => {
    // Types from main type file.
    if (!this.state.types || !this.state.typeOptions) return null
    //logger.info("LayoutDialog:getTypes", type, platform, this.state, this.props);

    // Record with types maped to base type.
    let typeOption = this.state.typeOptions.find((t) => nonCSCompare(t.label, type))
    let typePlatform = platform
    if (typePlatform === 'RESTfull-API') {
      typePlatform = 'RESTful-API'
    }
    let platformTo = typePlatform + 'To'

    // Platform loaded and exist.
    let platformType = null
    if (this.state.platform === platform && this.state[platformTo]) {
      platformType = this.state[platformTo].find((t) => nonCSCompare(t.label, type)).value
    } else {
      platformType = typeOption.object[platform]
    }

    //logger.info("LayoutDialog:getTypes:RESULT", platform, type, typeOption.object['RESTfull-API'], platformType);
    return {
      type: type,
      schema: typeOption.object['RESTfull-API'],
      platform: platformType
    }
  }

  /*
   * Check property before save
   * @param {object} settings - new state of object
   * @returns {string} - string with error text
   */
  checkInputErrors(layout) {
    let inputError = ''
    const condition = this.props.majorObject.layouts
    if (!layout.identity || !layout.identity.name || layout.identity.name.length === 0) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify layout name!'
    }
    if (!layout.platform || layout.platform.length === 0) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify layout platform!'
    }
    if (!layout.unit || layout.unit.length === 0) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify layout unit!'
    } else if (condition && layout.identity && layout.identity.name) {
      const checkLayoutName = condition.find(
        (e) => nonCSCompare(e.identity.name, layout.identity.name) && !nonCSCompare(e.identity.id, layout.identity.id)
      )
      if (checkLayoutName) {
        inputError += 'Element name already exist'
      }
    }
    return inputError
  }

  /**
   * Validate value of changed field
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @return true - change approved
   */
  onValidateChange = (element, field, value, layout, option) => {
    logger.info('LayoutDialog:onValidateChange', { element, field, value, layout }, this.state, this.props)
    return layout
  }

  /**
   * Save changes
   * @param {object} layout - layout to save
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   */
  updateLayout = (layout, closeDialog, onSent) => {
    this.Change(layout)
    if (onSent) onSent(closeDialog)
  }

  // when JSON nodes in dialog are expanded, it should check height of inner components and expand itself if needed
  checkDialogHeight = () => {
    //logger.info("ExampleDialog::checkDialogHeight");
    if (document.querySelector('.LayoutDialog').offsetHeight > 500)
      this.onAdjust(0, document.querySelector('.LayoutDialog').offsetHeight - this.state.height + 200)
  }

  renderEditor = (editState) => {
    //logger.info("LayoutDialog:renderEditor", editState, layout, this.state, this.props);

    return (
      <div className="LayoutDialog" onClick={this.checkDialogHeight}>
        {this.renderView(editState, (element) => {
          switch (element) {
            default:
              return null
            case 'location':
              return <div></div>
            case 'format':
              return <div></div>
            case 'schedule':
              return <div></div>
          }
        })}

        <div>
          <LayoutView
            appState={this.props.appState}
            actions={this.props.actions}
            isEditable={editState}
            dialogObject={this}
            majorObject={this.props.majorObject}
            layout={this.state.layout}
            change={this.state.change}
            types={this.state.types ? this.getTypes.bind(this) : null}
            onChangeLayout={this.updateLayout}
            isSkipSchema={this.props.isSkipSchema}
            isShowCounters={this.props.isShowCounters}
            createSchema={this.createSchema}
            clearSchema={this.clearSchema}
          />
        </div>
      </div>
    )
  }
}

LayoutDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  instance: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
