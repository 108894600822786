import PropTypes from 'prop-types'
import React from 'react'
import { API, getObjectNew } from '../../helpers/api'
import { editableState, nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { TagList } from './TagList'
import './TagsDialog.scss'

const TAG_HEIGHT = 60

export class TagsDialog extends EditorDialogBase {
  /**
   * Returns object type for dialog, for example "activity"
   */
  getObjectType() {
    return 'tags'
  }

  /**
   * Returns minimum height for dialog
   */
  getMinHeight() {
    return 300
  }

  /**
   * Returns list of adjustments of height based on object elements.
   * Name - is a name of parameter to use,
   * Value - adjustment for element.
   */
  getElementsHeight() {
    // We have lines of settings on settings array
    return { __line: TAG_HEIGHT }
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/system/datasets/object',
      '/organizations/Apdax/systems/DifHub/applications/System/views/TagsDialog'
    )

    // Load tags from organization if we not in organization
    this.loadTags()

    //logger.info("TagsDialog:open", this.state, this.props);
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  loadTags = () => {
    // Load tags from organization if we not in organization
    //logger.info("TagsDialog:loadTags", this.state, this.props);
    if (
      !this.state.objectBase &&
      this.state.organization &&
      (!nonCSCompare(this.state.path, '/organizations/' + this.state.organization) || this.props.issue)
    ) {
      getObjectNew(API.organizations(this.state.organization), 'organization', this.props.actions).then((org) => {
        if (org.object) {
          this.setState({ objectBase: org.object.tags })
        }
        //logger.info("TagsDialog:open:ORG", org, this.state, this.props);
      })
    }
  }

  /**
   * Override the method in child dialogs, to handle changeEditable() event: opening and closing the dialog
   */
  onChangeEditable = (isEditable) => {
    //logger.info("EditorDialogBase:onChangeEditable", isEditable, this.props);
    this.loadTags()
  }

  saveTags = (tags, closeDialog, onSent) => {
    onSent(closeDialog)
    logger.info('TagsDialog:saveTags', tags, this.state)

    this.Change(tags)
  }

  /**
   * Render one item in list of the items when dialog is closed
   */
  renderItem(item, index) {
    let tag = item
    if (!tag) {
      return null
    }

    //console.log("DocsDialog:renderItem", this.state, this.props);
    return <span>{index ? ', ' + item.name : item.name}</span>
  }

  renderEditor = (editState) => {
    let dialog = this.getDialogRef()
    //console.log("TagsDialog:renderEditor", this.state, this.props);
    return (
      <div className="TagsDialog">
        <TagList
          appState={this.props.appState}
          actions={this.props.actions}
          isVisible
          isEditable={editState >= editableState.EDITABLE ? editState : editableState.BROWSABLE}
          majorObject={this.props.majorObject}
          parentDialog={this}
          type={'tag'}
          items={this.state.tags}
          base={this.state.objectBase}
          onSave={this.saveTags}
          isFullscreen={dialog && dialog.state ? dialog.state.isFullscreen : false}
        />
      </div>
    )
  }
}

TagsDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  isFullscreen: PropTypes.bool,
  isViewingOnly: PropTypes.bool,
  isItems: PropTypes.number,
  maxItems: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  issue: PropTypes.object,
  tags: PropTypes.object,
  base: PropTypes.object,
  version: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
