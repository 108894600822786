/**
 * Created by mailf on 20.07.2016.
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import { apiURL, API, idAPI, getApiResource, sendObjectNew, getObjectNew, getRequestFromPath } from '../../helpers/api'
import { checkObjectName } from '../../helpers'
import Table from '../Table/Table'
import { BigButton } from '../BigButton/BigButton'
import { ObjectSelector } from '../ObjectSearch/ObjectSelector'

import './ObjectCreator.scss'
import { getFullUri, getPublicationReference, nonCSCompare } from '../../helpers/index'
import { track } from '../../helpers/analytics'
import logger from '../../helpers/logger'

class ObjectCreator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      objectName: '',
      error: '',
      nameError: '',
      [props.childObjectType]: props.childObject ? Object.assign({}, props.childObject) : {},
      objectSelectByStep: false,
      new_docs: [],
      uploaded_files: [],
      deleted_files: []
    }
  }

  componentDidMount() {
    if (
      this.state[this.props.childObjectType] &&
      this.state[this.props.childObjectType] !== 'none' &&
      this.state[this.props.childObjectType].path
    ) {
      const req = this.getRequestFromPath(this.state[this.props.childObjectType].path)
      getObjectNew(
        req,
        this.state[this.props.childObjectType].type ? this.state[this.props.childObjectType].type : 'issue',
        this.props.actions
      ).then((obj) => {
        this.setState({
          [this.props.childObjectType]: obj
        })
      })
    }

    track(this.props.childObjectType, 'object_creator')
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.parentObject && this.state.targetObjectId !== newProps.parentObject.identity.id) {
      this.setState({
        targetObjectId: newProps.parentObject.identity.id,
        targetObjectType: newProps.parentObjectType,
        targetObjectPath: newProps.parentObjectPath
      })
    }
  }

  getRequestFromPath = (path) => {
    //console.log("ObjectCreator:getRequestFromPath", path, this.state, this.props);
    const splitPath = path.split('/')
    let req = API
    let i

    for (i = 1; i < splitPath.length - 1; i += 2) {
      // //console.log("OC:getRequestFromPath", path, splitPath, splitPath[i]);
      req = req[splitPath[i]](splitPath[i + 1])
    }

    if (i < splitPath.length) {
      req = req[splitPath[i]]
      // //console.log("spspsp", splitPath, i, splitPath[i], splitPath[i+1], req.url());
    }

    return req
  }

  getObjectType = () => {
    const childObjectType = this.props.childObjectType

    switch (childObjectType) {
      case 'dataset':
      case 'platform':
        return 'dataset'
      default:
        return childObjectType
    }
  }

  isPropertyValueEquals = (prop, val) => {
    if (!this.state[this.props.childObjectType]) return false

    let obj = this.state[this.props.childObjectType]

    if (prop.indexOf('.') > -1) {
      const objLevels = prop.split('.')
      let i = 0

      while (i <= objLevels.length - 2) {
        if (!obj[objLevels[i]]) return false

        obj = obj[objLevels[i++]]
      }

      return obj[objLevels[i]] === val || obj[objLevels[i]] === val.replace(/\s+/g, '')
    }

    return obj[prop] === val || obj[prop] === val.replace(/\s+/g, '')
  }

  onNameChange = (e) => {
    this.setState(
      {
        objectName: e.target.value
      },
      () => {
        const error = checkObjectName(this.state.objectName)
        if (error === 'characters') {
          this.setState({
            nameError: 'Only letters, digits, spaces, hyphens, colons and underscores are allowed in object names'
          })
        } else if (error === 'reserved') {
          this.setState({
            nameError: 'This object name is reserved and cannot be used'
          })
        } else {
          this.setState({ nameError: '' })
        }
      }
    )
  }

  onDescriptionChange = (e) => {
    this.setState({
      objectDescription: e.target.value
    })
  }

  onChildSelect = (child) => {
    // //console.log("onChildSelect", child);
    this.setState({
      childObject: child
    })
  }

  onClose = () => {
    this.setState({
      error: ''
    })
    this.props.onCancel()
  }

  onSave = () => {
    this.props.onSave(this.state.objectName)
  }

  getObjectRequest = () => {
    // //console.log("getRequest");
    return getRequestFromPath(getFullUri(this.props.parentObject))
  }

  getRequest = () => {
    let targetObjectId
    let targetObjectType

    if (this.props.parentObject || this.state.targetObject) {
      targetObjectId = this.props.childObject
        ? this.state[this.props.childObjectType].identity.id
        : this.props.parentObject.identity.id
      targetObjectType = this.props.childObject ? this.props.childObjectType : this.state.targetObjectType
    }

    //console.log("OSM:getRequest", targetObjectId, targetObjectType, this.state, this.props);

    if (this.props.childObject) {
      return this.getObjectRequest()[getApiResource(targetObjectType)](this.props.childObject.identity.name)
    }

    return this.getObjectRequest()
  }

  getTargetPath = () => {
    if (this.state.path) return this.state.path

    return this.props.parentObject.path
  }

  checkFields = () => {
    ////console.log("checkFields ");

    if (this.state.nameError) {
      return false
    }
    if (this.state.objectName.length === 0) {
      this.setState({
        error: 'Please specify unique name for the entity!',
        isWaiting: false
      })
      return false
    }
    return true
  }

  createObject = (request) => {
    logger.log('ObjectCreator:createObject', request, this.state, this.props)
    return () => {
      //console.log("Try to create object", request.url(), this.state.objectName, this.state.issue);
      if (!request || !(this.state.objectName.length === 0 || !this.state.issue || this.state.issue.identity)) return

      let payload =
        this.props.childObjectType === 'field'
          ? [{ identity: { name: this.state.objectName } }]
          : {
              identity: {
                name: this.state.objectName,
                description: this.state.objectDescription
              }
            }

      if (this.props.childObjectType === 'subscription') {
        if (!this.state.publication) {
          this.setState({
            error: 'Please specify publication to subscribe!'
          })

          this.setState({
            isWaiting: false
          })
          return false
        }
        payload.publication = getPublicationReference(this.state.publication)
        payload.datasets = this.state[this.props.childObjectType] ? this.state[this.props.childObjectType].datasets : []
        payload.interfaces = this.state[this.props.childObjectType]
          ? this.state[this.props.childObjectType].interfaces
          : []
        payload.version = {
          major: 1,
          minor: 0,
          revision: 0
        }
      }

      if (this.props.childObjectType === 'view') {
        payload.locale = this.props.childObjectLocale
      }

      let save_documents = []

      if (payload.identity && payload.identity.id) {
        payload.identity = {
          id: payload.identity.id,
          name: payload.identity.name,
          description: payload.identity.description
        }
      }

      if (payload.object && payload.object.history) {
        let newO = Object.assign({}, payload.object)
        delete newO.history
        payload.object = newO
      }

      if (this.props.childObjectUsage) {
        payload.object = Object.assign({}, payload.object, {
          usage: this.props.childObjectUsage
        })
      }

      //console.log("sendObject", payload);

      payload.type = this.props.childObjectType

      //console.log("before sendObject",request,payload);
      const childResource = getApiResource(this.props.childObjectType)
      const childRequest = request[childResource]
      logger.log(
        'ObjectCreator:createObject:SEND',
        childResource,
        childRequest,
        this.props.childObjectType,
        payload,
        request
      )
      sendObjectNew(
        childRequest,
        this.props.childObject ? 'put' : 'post',
        this.props.actions,
        payload,
        null,
        true
      ).then(
        (result) => {
          logger.log('ObjectCreator::created', result)
          this.setState({
            error: '',
            isWaiting: false
          })
          this.props.onSuccess(
            Object.assign({}, result, {
              _path: childRequest(result.identity.name).url()
            })
          )
        },
        (error) => {
          if (this.props.onFail) this.props.onFail(error)
          logger.error('error', error)
          this.setState({
            error: error.status === 409 ? JSON.parse(error.response).error.message : error.status,
            isWaiting: false
          })
        }
      )
    }
  }

  renderObjectSettings = (objectType, objectPath) => {
    switch (objectType) {
      // SUBSCRIPTION
      case 'subscription': {
        // We need system path as subscription can be only to other application.
        const splitPath = getFullUri(this.props.parentObject).substring(1).split('/')
        const sysPath =
          splitPath.length < 4 ? '' : '/' + splitPath[0] + '/' + splitPath[1] + '/' + splitPath[2] + '/' + splitPath[3]

        const selectorPath = this.state.currentPublicationSelectPath || sysPath
        //logger.log("ObjectCreator:renderObjectSettings", { objectType, objectPath, splitPath, sysPath, selectorPath }, this.state, this.props);

        return (
          <span>
            <div className="ObjectCreator__label">Publication:</div>
            <ObjectSelector
              appState={this.props.appState}
              actions={this.props.actions}
              startObjectPath={selectorPath}
              targetObjectPath={'/organizations/*/systems/*/*/*/publications/*/'}
              targetObjectType={'publications'}
              childrenObjectByType={{
                organizations: ['systems'],
                systems: ['applications', 'publications'],
                applications: ['publications']
              }}
              onSelect={(obj, objectType, objectPath) => {
                // We not expect version after publication as we want subscribe to last approved.
                // if we want version targetObjectPath={'/organizations/*/systems/*/*/*/*/*/versions'}
                // annd add chiled: 'publications': ['versions']
                logger.info(
                  'ObjectCreator:ObjectSelector:OnSelect',
                  { obj, objectType, objectPath },
                  this.state,
                  this.props
                )
                const stateUpdate = {
                  currentPublicationSelectPath: getFullUri(obj)
                }

                if (nonCSCompare(objectType, 'publications') || nonCSCompare(objectType, 'publication')) {
                  stateUpdate.publication = obj
                }

                this.setState(stateUpdate)
              }}
              showByStep={this.state.objectSelectByStep}
              title="Find publication to subscribe"
              selectedType="publications"
            />
          </span>
        )
      }
    }
  }

  render() {
    const objectName = this.state.objectName
    return (
      <ModalWindow
        className={'ObjectCreator '}
        isOpen={this.props.isOpen}
        header={!this.props.childObject ? 'Create ' + this.props.childObjectType : 'Edit ' + this.props.childObjectType}
        upper={
          <div>
            <div className="ObjectCreator__block">
              <label className="ObjectCreator__input__label">Name:</label>
              <input
                type="text"
                className="TextInput ObjectCreator__input"
                placeholder={
                  this.props.childObjectType.charAt(0).toUpperCase() + this.props.childObjectType.slice(1) + ' name'
                }
                value={objectName}
                onChange={this.onNameChange}
                maxLength="255"
              />
              <label className="ObjectCreator__input__label">Description:</label>
              <div className="ObjectCreator__input__value">
                <textarea
                  className="TextArea"
                  name="description"
                  defaultValue={this.state.objectDescription}
                  placeholder={
                    this.props.childObjectType.charAt(0).toUpperCase() +
                    this.props.childObjectType.slice(1) +
                    ' description'
                  }
                  onChange={this.onDescriptionChange}
                  onKeyUp={(event) => event.stopPropagation()}
                  onKeyPress={(event) => event.stopPropagation()}
                  onKeyDown={(event) => event.stopPropagation()}
                  onBlur={() => {
                    this.setState({
                      editingDescription: false
                    })
                  }}
                />
              </div>
            </div>
          </div>
        }
        footer={
          <div>
            <div className="ObjectCreator__error">{this.state.error || this.state.nameError}</div>
            <BigButton
              className="EditorDialog__cancelButton"
              text="Cancel"
              onClick={this.onClose}
              status={this.state.isWaiting ? 'hidden' : ''}
            />
            <BigButton
              className="EditorDialog__saveButton"
              onClick={() => {
                if (this.checkFields()) {
                  this.setState({
                    isWaiting: true
                  })
                  if (this.props.customCreateObject)
                    this.props.customCreateObject(
                      {
                        identity: {
                          name: this.state.objectName,
                          description: this.state.objectDescription
                        }
                      },
                      (error) => {
                        if (this.props.onFail) this.props.onFail(error)
                        logger.error('error', error)
                        this.setState({
                          error: error.status === 409 ? JSON.parse(error.response).error.message : error.status,
                          isWaiting: false
                        })
                      }
                    )
                  else this.createObject(this.getRequest())()
                }
              }}
              text={this.props.childObject ? 'Update' : 'Create'}
              status={this.state.isWaiting ? 'loader' : ''}
            />
          </div>
        }
        onClose={this.onClose}
      >
        <div className="ObjectCreator__block">
          {this.renderObjectSettings(this.props.childObjectType, this.props.childObjectPath)}
        </div>
      </ModalWindow>
    )
  }
}

ObjectCreator.propTypes = {
  isOpen: PropTypes.bool,
  parentObjectType: PropTypes.string.isRequired,
  parentObjectId: PropTypes.string,
  childObjectType: PropTypes.string.isRequired,
  childObjectPath: PropTypes.string,
  childObject: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  attachedObjectId: PropTypes.string,
  onSave: PropTypes.func,
  onFail: PropTypes.func,
  parentObject: PropTypes.object,
  users: PropTypes.array,
  customCreateObject: PropTypes.func
}

function mapSateToProps(state) {
  return {
    appState: state.appState,
    userState: state.userAppState
  }
}

export default connect(mapSateToProps)(ObjectCreator)
