/**
 * Created by mailf on 14.06.2016.
 */
import React from 'react'
import cancelIcon from '../../resources/images/cancel-2x.png'
import cancelIconDialog from '../../resources/images/cancel-icon-2x.png'
import editIconDark from '../../resources/images/edit-dark-2x.png'
import editIconDialog from '../../resources/images/edit-icon-2x.png'
import noeditIconDialog from '../../resources/images/noedit-2x.png'
import saveIcon from '../../resources/images/save-2x.png'
import saveIconDialog from '../../resources/images/save-icon-2x.png'
import './style.scss'

type EditButtonProps = {
  onEdit: () => void
  onSave?: () => void
  onCancel?: () => void
  inEditMode?: boolean
  hideSave?: boolean
  editText?: string
  saveText?: string
  theme?: string
  noIcon?: boolean
  className?: string
  isDialog?: boolean
  cannotEdit?: boolean
  cannotEditHint?: string
}

export const EditButton = ({
  onEdit,
  onSave,
  onCancel,
  inEditMode,
  editText,
  saveText,
  theme,
  noIcon,
  className,
  hideSave,
  isDialog,
  cannotEdit,
  cannotEditHint
}: EditButtonProps) => {
  // @ts-ignore
  let editIcon = theme === 'dark' ? editIcon : editIconDark
  if (isDialog) editIcon = editIconDialog
  if (cannotEdit) editIcon = noeditIconDialog

  return (
    <div className={'EditButton' + (theme ? ' EditButton_' + theme : '') + ' ' + (className ? className : '')}>
      {inEditMode ? (
        <div>
          {!hideSave ? (
            <span className="EditButton__btn EditButton__save" onClick={onSave}>
              {!noIcon ? <img className="EditButton__icon" src={isDialog ? saveIconDialog : saveIcon} alt="" /> : null}
              {saveText ? saveText : 'Save'}
            </span>
          ) : null}
          <span className="EditButton__btn EditButton__cancel" onClick={onCancel}>
            {!noIcon ? (
              <img className="EditButton__icon" src={isDialog ? cancelIconDialog : cancelIcon} alt="" />
            ) : null}
            Cancel
          </span>
        </div>
      ) : (
        <div
          className={'EditButton__btn EditButton__edit ' + (cannotEdit ? ' EditButton_noedit' : '')}
          onClick={cannotEdit ? undefined : onEdit}
          title={cannotEdit ? cannotEditHint : ''}
        >
          {!noIcon ? <img className="EditButton__icon" src={editIcon} alt="" /> : null}
          {editText ? editText : 'Edit'}
        </div>
      )}
    </div>
  )
}
