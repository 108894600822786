/**
 * Created by kascode on 03.05.17.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { getFullUri, objectFieldIsArray, objectNameFromPathByType } from '../../helpers'
import {
  formatEnumName,
  formatObjectName,
  getOrganizationDataset,
  getRequestFromPath,
  requestVersionedObjectByURI
} from '../../helpers/api'
import {
  datasetToEnumOptions,
  deepCopy,
  editableState,
  monokaiJsonTheme,
  nonCSCompare,
  orderSort,
  pathByType,
  pathForMajorVersion
} from '../../helpers/index'
import logger from '../../helpers/logger'
import { JSONTreeCopy } from '../JSONTreeCopy/JSONTreeCopy'
import { Loader } from '../Loader/Loader'
import { ExportSchemaDialog } from './ExportSchemaDialog'
import './SchemaView.scss'

export class SchemaView extends Component {
  constructor(props) {
    super(props)

    const organization = objectNameFromPathByType(getFullUri(this.props.majorObject), 'organizations')

    this.state = {
      organization: organization,
      types: this.props.types,

      loadingSchema: false,

      editingSchemaParams: false,
      schemaParams: {}
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ types: newProps.types })
  }

  /**
   * Get types
   * @param {string} [type] - system type
   * @param {string} [platform] - platform we need type for
   * @returns {*}
   */
  getTypes = (type, platform) => {
    return this.state.types ? this.state.types(type, platform) : null
  }

  /**
   * Load map for Avro schema.
   * @return
   */
  loadAvroMap = async () => {
    // Load map from DifHub types to Avro types
    getOrganizationDataset('To:Avro', this.state.organization).then(
      (map) => {
        logger.info('SchemaView:loadAvroMap:MAP', map)
        this.setState({
          avroMap: datasetToEnumOptions(map)
        })
      },
      (error) => {
        logger.info('SchemaView:loadAvroMap:ERROR', error)
      }
    )
  }

  /**
   * Create schema for identity dataset for specific platform. Current
   * schema comply with Swagger 2.0 speification.
   * @param ds - publication specification.
   * @param platform - platform for which specification created..
   * @param params - parameters of schema generation.
   * @param schema - schema to add specification to. Null mean schema will be created.
   * @param define - definitions structure for datasets to collect.
   * @return
   */
  createIdentitySchema = async (ds, platform, params, schema, define) => {
    if (!ds) {
      return
    }

    const dsSchema = schema ? schema : {}
    const dsPromises = []
    const dsDefine = define ? define : { definitions: {}, schemas: {}, names: {} }

    if (!schema) {
      //logger.info("SchemaView:createIdentitySchema", ds, platform, params);
      if (this.props.change) {
        this.props.change(true, 'Create schema for selected platform...', '', '')
      }
    }

    let identityUri = '/organizations/' + this.state.organization + '/datasets/identity'

    // Field simulation.
    let field = {}
    field['type'] = 'Reference'
    field['reference'] = identityUri

    // Type for property.
    let type = this.getTypes(field.type, platform)
    let item = {}

    this.defineItemType(ds, field, type.schema, type.platform, platform, params, item, dsDefine, dsPromises, true)

    await Promise.all(dsPromises)
    logger.info('SchemaView:createIdentitySchema', dsSchema, dsDefine, type)

    let version = dsDefine.definitions['Identity']['x-version'].split('.')[0]
    let path = dsDefine.definitions['Identity']['x-path']

    let identityUriVersion = identityUri + '/versions/' + version

    dsDefine.schemas[identityUriVersion] = dsDefine.schemas[identityUri]
    dsDefine.names[identityUriVersion] = 'Identity'
    logger.info('SchemaView:createIdentitySchema:RESULT', dsSchema, dsDefine, version, path)
  }

  /**
   * Create dataset schema based on schema parameters.
   * @param ds - dataset specification.
   * @param platform - platform for which schema created.
   * @param params - parameters of schema generation.
   * @param schema - schema to add specification to. Null mean schema will be created.
   * @param define - definitions structure for datasets to collect inforation in.
   * @param inherit - inheritance from object: object properties, not object itself.
   * @return - generated schema .
   */
  createDatasetSchema = async (ds, platform, params, schema, define, inherit = false) => {
    if (!ds || !ds.structure) {
      return
    }

    const dsSchema = schema ? schema : {}
    const dsPromises = []
    const dsDefine = define ? define : { definitions: {}, schemas: {}, names: {} }

    if (!schema) {
      //logger.info("SchemaView:createDatasetSchema", ds, platform);
      if (this.props.change) {
        this.props.change(true, 'Create schema for selected platform...', '', '')
      }

      // Order map if type of scema is avro
      if (nonCSCompare(params.schemaType, 'AVRO1')) {
        await this.loadAvroMap()
      }

      // Order identity structure.
      await this.createIdentitySchema(ds, platform, params, dsSchema, dsDefine)
    }

    // We need fields array in order of fields.
    let fields = ds.structure.fields.sort(orderSort)
    let oneof = false

    // Schema properties. If we have inheritance, we need properties only
    if (!inherit) {
      dsSchema['title'] = ds.identity.name

      dsSchema['description'] = params.minimizeSchemaSize ? '' : ds.identity.description
      dsSchema['type'] = 'object'
      dsSchema['x-version'] = ds.version.major + '.' + ds.version.minor + '.' + ds.version.revision
      dsSchema['x-path'] = getFullUri(ds)

      // Usage of the structure
      dsSchema['x-data-type'] = ds.object.usage
      if (nonCSCompare(ds.object.usage, 'Union')) {
        oneof = true
        dsSchema['oneof'] = []
      }

      // Structure properties
      dsSchema['properties'] = {}
      //logger.info("SchemaView:createDatasetSchema:DATASET", ds, dsSchema);
    }

    // We need to iterate all fields of structure and create fields.
    for (let j in fields) {
      const field = fields[j]
      let item

      // Include optional properties in not requiered.
      if (field.optional === true) {
        if (params.hideOptionalFields === true) continue
      }

      // Get name from identity if we not hide field.
      let name = field.identity.name
      if (name.toUpperCase().startsWith('FUTURE:')) {
        if (params.hideFutureFields === true) continue

        name = name.substring(7)
      }

      // Do we flatten this field
      let flatten = false
      if (
        params.flattenInheritedFields &&
        !(field.count === 0 || field.count > 1) &&
        name.toUpperCase().startsWith('INHERIT:')
      ) {
        flatten = true

        name = name.substring(8)
      }

      // Get name from identity or from field reference.
      name = formatObjectName(name, params.alignNames)
      if (!name) continue
      //logger.info("SchemaView:createDatasetSchema", name, flatten, inherit);

      if (flatten) {
        // For inherited schema we are not create new object
        item = dsSchema
      } else {
        // Schema for array of items of current object.
        item = dsSchema['properties'][name] = {
          description: params.minimizeSchemaSize ? '' : field.identity.description
        }

        // For union we mark it as one of
        if (oneof) {
          dsSchema['oneof'].push(name)
        }

        // Mark what field is optional
        if (field.optional === true) {
          dsSchema['properties'][name]['x-optional'] = true
        } else {
          if (!dsSchema['required']) dsSchema['required'] = []
          dsSchema['required'].push(name)
        }
      }

      // Type for property.
      let type = this.getTypes(field.type, platform)
      //logger.info("SchemaView:createDatasetSchema:TYPE", type, field.reference, reference);

      this.defineItemType(ds, field, type.schema, type.platfom, platform, params, item, dsDefine, dsPromises)
    }

    return Promise.all(dsPromises).then(
      (result) => {
        if (!schema) {
          let layout = deepCopy(this.props.layout)
          if (dsSchema) {
            // Convert to string
            layout.schema = this.generateDatasetSchema(params, dsSchema, dsDefine)
            logger.info('SchemaView:createDatasetSchema:RESULT', params, dsSchema, dsDefine)

            // Update layout
            this.props.onChangeLayout(layout)
          }
          this.setState({ loadingSchema: false })
        }
      },
      (err) => {
        console.error('SchemaView:createDatasetSchema:ERROR', err)
        if (this.props.change) {
          this.props.change(true, '', '', 'Error: ' + err.message)
        }
        this.setState({ loadingSchema: false })
      }
    )
  }

  /**
   * Fill type data into item
   * @param ds - dataset specification.
   * @param field - field information to extract data for type.
   * @param type - type of the field for JSON.
   * @param ptype - type of the field for platform.
   * @param platform - platform for which specification created..
   * @param params - parameters of schema generation.
   * @param item - item to add type to.
   * @param dsDefine - definitions structure for datasets to collect.
   * @param schema - boolean id need schema for simple type.
   * @param list - boolean mean we need convert type to array.
   * @return schema, ref or value
   */
  defineItemType = (ds, field, type, ptype, platform, params, item, dsDefine, dsPromises, schema, list) => {
    // Type for property. If schema required we created intermidiate item.
    let schemaitem = schema || objectFieldIsArray(field) || list ? {} : item
    let schemaitemref = {}

    const approved = params.useApprovedVersion
    let reference = field.reference ? pathForMajorVersion(field.reference) : ''
    //logger.info("SchemaView:defineItemType", field, item, schema, schemaitem);

    if (nonCSCompare(field.type, 'Structure') && field.reference) {
      // We are processing structure, lets see do we need process inheritance
      let inherit = false
      if (
        params.flattenInheritedFields &&
        !(field.count === 0 || field.count > 1) &&
        field.identity &&
        field.identity.name.toUpperCase().startsWith('INHERIT:')
      ) {
        inherit = field.identity.name.substring(8)
        schemaitem = item
        //logger.info("SchemaView:defineItemType:INHERIT", inherit, field, JSON.stringify(schemaitem));
      }

      if (inherit || !this.createRefSchema(params, schemaitem, schemaitemref, dsDefine, reference, schema)) {
        // We go into recursion if we on reference.
        dsPromises.push(
          requestVersionedObjectByURI(reference, approved).then(
            (obj) => {
              //logger.info("SchemaView:defineItemType:OBJECT", obj, dsPromises.length, dsPromises);
              return this.createDatasetSchema(obj, platform, params, schemaitem, dsDefine, inherit)
            },
            (err) => {
              console.error('SchemaView:defineItemType:DATASET:ERROR', err, reference, ds)
              return false
            }
          )
        )
      }
    } else if (nonCSCompare(field.type, 'Enum') && field.reference) {
      // Setup regular field custom schema properties
      if (field.value && field.value.length > 0) {
        let value = formatEnumName(field.value, field.usage, params.alignEnums)

        schemaitem['default'] = value
      }
      if (field.format && field.format.length > 0) {
        schemaitem['format'] = field.format
      }

      if (!this.createRefSchema(params, schemaitem, schemaitemref, dsDefine, reference, schema)) {
        // We go into recursion if we on reference.
        dsPromises.push(
          requestVersionedObjectByURI(reference, approved).then(
            (obj) => {
              //logger.info("SchemaView:createDatasetSchema:ENUM", obj, dsPromises.length, dsPromises);
              return this.createEnumSchema(obj, platform, params, schemaitem, field.usage)
            },
            (err) => {
              console.error('SchemaView:createDatasetSchema:ERROR', err)
              return false
            }
          )
        )
      }
    } else if (nonCSCompare(field.type, 'Reference') && field.reference) {
      // Get organization path.
      let identityUri = '/organizations/' + this.state.organization + '/datasets/Identity'

      if (!this.createRefSchema(params, schemaitem, schemaitemref, dsDefine, identityUri, schema)) {
        // We go into recursion if we need reference structure.
        dsPromises.push(
          getRequestFromPath(identityUri)
            .get()
            .then(
              (obj) => {
                logger.info('SchemaView:createDatasetSchema:REFERENCE', obj)
                return this.createDatasetSchema(obj, platform, params, schemaitem, dsDefine)
              },
              (err) => {
                console.error('SchemaView:createDatasetSchema:IDENTITY:ERROR', err)
                return false
              }
            )
        )
      }
    } else {
      // simple type.
      schemaitem['type'] = type
      schemaitem['x-data-type'] = field.type

      // Additional properties of field:
      if (field.precision) schemaitem['x-data-precision'] = field.precision
      if (field.scale) schemaitem['x-data-scale'] = field.scale
      if (field.size) schemaitem['x-data-size'] = field.size

      // Setup regular field custom schema properties
      if (field.value && field.value.length > 0) {
        schemaitem['default'] = field.value
      }
      if (field.format && field.format.length > 0) {
        schemaitem['format'] = field.format
      }
      if (ptype && ptype.length > 0 && type !== ptype) {
        schemaitem['x-platform-type'] = type[ptype]
      }
      //logger.info("SchemaView:defineItemType:FIELD", field, refitem, type, ptype);
    }
    let currItem = schemaitemref['$ref'] ? schemaitemref : schemaitem
    if (list) {
      if (schema) {
        // Write schema reference in right lavel of schema.
        item['schema'] = !objectFieldIsArray(field)
          ? { type: 'array', items: currItem }
          : { type: 'array', items: { type: 'array', items: currItem } }
        return 'schema'
      } else if (objectFieldIsArray(field)) {
        item['type'] = 'array'
        item['items'] = { type: 'array', items: currItem }
      } else {
        item['type'] = 'array'
        item['items'] = currItem
      }
    } else {
      if (schema) {
        // Write schema reference in right lavel of schema.
        item['schema'] = !objectFieldIsArray(field) ? currItem : { type: 'array', items: currItem }
        return 'schema'
      } else if (objectFieldIsArray(field)) {
        item['type'] = 'array'
        item['items'] = currItem
      } else {
        if (schemaitemref['$ref']) {
          item['$ref'] = schemaitemref['$ref']
          return 'ref'
        }
      }
    }
    //logger.info("SchemaView:defineItemType:RESULT", item, schemaitem, schemaitemref, field, schema);
    return 'value'
  }

  /**
   * For uri decide if it already exist and simply can return reference or need
   * to generate right URI and request dataset.
   * @param item - item with datatset information.
   * @param refitem - item to set reference for dataset if it already loaded.
   * @param define - definitions structure for datasets to collect.
   * @param uri - URI for dataset we want reference to..
   * @param ref - use reference even for first time.
   * @return true - schema created for new dataset, false - schema already created.
   */
  createRefSchema = (params, item, refitem, define, datasetUri, ref) => {
    let uri = datasetUri.toLowerCase()
    if (uri.startsWith('/organizations/apdax/datasets/')) {
      const pathSplit = uri.substring(1).split('/')
      const dsURI = pathByType(pathSplit, 'datasets', false, true)

      uri = '/organizations/' + this.state.organization + dsURI
    }

    //logger.info("SchemaView:createRefSchema", item, ref, define, datasetUri, uri);

    if (ref || define.schemas[uri]) {
      // Schema loaded already.
      const swaggerSchema = params.schemaType === 'SWAGGER'

      let fLoaded = !!define.schemas[uri]
      let ref = swaggerSchema ? '#/definitions/' : '#/components/schemas/'

      // Use existing stracture
      if (!define.names[uri]) {
        // We are first time use this reference
        let refname = objectNameFromPathByType(datasetUri, 'datasets')

        // We need unique name for definition structure
        if (define.definitions[refname]) {
          let number = 0
          let unique = refname + number

          while (define.definitions[unique]) {
            number++
            unique = refname + number
          }
          refname = unique
        }
        // Create definition for this structure. If we already load this item, use loaded item.
        define.names[uri] = refname
        define.definitions[refname] = ref && !fLoaded ? item : define.schemas[uri]
        define.schemas[uri] = { $ref: ref + define.names[uri] }
      }
      // Change current and previous schema to reference
      refitem['$ref'] = ref + define.names[uri]
      //logger.info("SchemaView:createRefSchema:REFERENCE", ref, item, refitem, fLoaded, uri, define.schemas[uri], define);
      return fLoaded
    } else {
      // Remember reference object.
      define.schemas[uri] = item
      //logger.info("SchemaView:createRefSchema:SCHEMA", uri, define.schemas[uri], item, define);
      return false
    }
  }

  /**
   * Create schema for enumerator for specific platform. Current
   * schema comply with Swagger 2.0 speification.
   * @param ds - publication specification.
   * @param platform - platform for which specification created..
   * @param params - parameters of schema generation.
   * @param schema - schema to add specification to. Null mean schema will be created.
   * @param usage - usage defined to use value for collection or name.
   * @return
   */
  createEnumSchema = (ds, platform, params, schema, usage) => {
    if (!ds || !ds.structure) {
      return
    }

    const dsSchema = schema

    // Type for property. When property usage value/value1/value2/code, we use value as enum data, overwise it is name.
    let usage_value =
      nonCSCompare(usage, 'value') ||
      nonCSCompare(usage, 'value1') ||
      nonCSCompare(usage, 'value2') ||
      nonCSCompare(usage, 'code')
    let field = ds.structure.fields.find((f) => nonCSCompare(usage_value ? usage : 'name', f.usage))
    if (!field) field = ds.structure.fields[0]
    if (!field) return

    let type = this.getTypes(field.type, platform)

    // Schema properties parameters.
    dsSchema['title'] = ds.identity.name
    dsSchema['description'] = params.minimizeSchemaSize ? '' : ds.identity.description
    dsSchema['type'] = type.schema
    dsSchema['x-version'] = ds.version.major + '.' + ds.version.minor + '.' + ds.version.revision
    dsSchema['x-path'] = getFullUri(ds)
    dsSchema['x-data-type'] = field.type
    if (type.platform) {
      dsSchema['x-paltform-type'] = type.platform
    }

    // We will define enum is it not empty.
    if (ds.data && ds.data.records && ds.data.records.length > 0) {
      dsSchema['enum'] = []

      // We need to iterate all fields of structure and create fields.
      let dest_schema = dsSchema['enum']

      for (let j in ds.data.records) {
        const record = ds.data.records[j]
        let value = formatEnumName(record.values[field.order - 1], usage, params.alignEnum)

        // Don't add duplicate elements into enums
        if (dest_schema.indexOf(value) !== -1) continue

        //logger.info("SchemaView:createEnumSchema:RECORD", record, ds, field);
        dest_schema.push(value)
      }
    }
    //logger.info("SchemaView:createEnumSchema:SCHEMA", dsSchema);
  }

  /**
   * Generate schema based on collection of dataset in Define structure.
   * @param params - parameters of schema generation.
   * @param dsSchema - schema generated.
   * @param dsDefine - definition structure of datasets
   * @return
   */
  generateDatasetSchema = (params, dsSchema, dsDefine) => {
    if (!dsSchema || !dsDefine.definitions || Object.keys(dsDefine.definitions).length === 0) return {}

    let schemas = null

    // Schema type.
    if (nonCSCompare(params.schemaType, 'JSON7') || nonCSCompare(params.schemaType, 'SWAGGER')) {
      // Actual JSON schema
      if (!dsSchema['definitions']) dsSchema['definitions'] = {}
      schemas = dsSchema['definitions']
    } else if (nonCSCompare(params.schemaType, 'AVRO1')) {
      // AVRO schema
      return this.generateAvroSchema(params, null, null, dsSchema, dsDefine)
    } else {
      // Open API datasets
      if (!dsSchema['components']) dsSchema['components'] = {}
      if (!dsSchema['components']['schemas']) dsSchema['components']['schemas'] = {}
      schemas = dsSchema['components']['schemas']
    }

    for (let object in dsDefine.definitions) {
      let schema = dsDefine.definitions[object]

      if (!schemas[object]) schemas[object] = {}

      if (schema['title']) schemas[object]['title'] = object
      if (schema['description']) schemas[object]['description'] = schema['description']
      if (schema['type']) schemas[object]['type'] = schema['type']
      if (schema['x-version']) schemas[object]['x-version'] = schema['x-version']
      if (schema['x-path']) schemas[object]['x-path'] = schema['x-path']
      if (schema['x-data-type']) schemas[object]['x-data-type'] = schema['x-data-type']
      if (schema['x-platform-type']) schemas[object]['x-platform-type'] = schema['x-platform-type']
      if (schema['enum']) schemas[object]['enum'] = schema['enum']
      if (schema['properties']) schemas[object]['properties'] = schema['properties']
      if (schema['required']) schemas[object]['required'] = schema['required']
    }
    logger.info('SchemaView:generateDatasetSchema:RESULT', dsSchema, dsDefine)
    return dsSchema
  }

  /**
   * Generate avro schema based on collection of dataset in Define structure.
   * @param params - parameters of schema generation.
   * @param dsSchema - schema generated.
   * @param dsDefine - definition structure of datasets
   * @return
   */
  generateAvroSchema = (params, object, schemas, schema, define) => {
    //const complexTypes = ['record', 'enum', 'array', 'map, union', 'fixed'];

    // This is root of the object
    if (!object) {
      // Init object root
      object = schema['title']
      schemas = {}
    } else {
      // Init current object
      if (!schemas) schemas = {}
    }

    // If we on reference object
    if (!schema['type'] && schema['$ref']) {
      // Extract schema from definision.
      schema = define.definitions[schema['$ref'].substring(21)]
      //logger.info("SchemaView:generateAvroSchema:REF", schema);
    }

    //logger.info("SchemaView:generateAvroSchema", params, object, schemas, schema, define);

    // Add object to schema.
    if (schema['type'] === 'object' && schema['x-data-type'] === 'Union') {
      // Rrecord of avro
      schemas['name'] = object

      if (schema['description']) schemas['doc'] = schema['description']
      if (schema['x_alias']) schemas['aliases'] = schema['x_alias']

      // Iterate other object properties to create fields
      if (Object.keys(schema.properties).length > 0) {
        schemas['type'] = []

        for (let prop in schema.properties) {
          let field = this.generateAvroSchema(params, prop, null, schema.properties[prop], define)
          schemas['type'].push(field)
        }
      }
    } else if (schema['type'] === 'object') {
      // Rrecord of avro
      schemas['name'] = object
      schemas['type'] = 'record'

      if (schema['description']) schemas['doc'] = schema['description']
      if (schema['x_alias']) schemas['aliases'] = schema['x_alias']

      // Iterate other object properties to create fields
      if (Object.keys(schema.properties).length > 0) {
        schemas['fields'] = []

        for (let prop in schema.properties) {
          let field = this.generateAvroSchema(params, prop, null, schema.properties[prop], define)
          schemas['fields'].push(field)
        }
      }
    } else if (schema['type'] === 'array') {
      // Rrecord of array
      schemas['name'] = object
      schemas['type'] = 'array'
      //logger.info("SchemaView:generateAvroSchema:ARRAY", object, schemas, schema);

      let item = this.generateAvroSchema(params, null, null, schema['items'], define)
      schemas['items'] = item
    } else if (schema['enum']) {
      // Rrecord of enum
      schemas['name'] = object
      schemas['type'] = 'enum'

      if (schema['description']) schemas['doc'] = schema['description']
      if (schema['x_alias']) schemas['aliases'] = schema['x_alias']
      if (schema['default']) schemas['default'] = schema['default']

      schemas['symbols'] = schema['enum']
    } else {
      // Primitive types.
      schemas['name'] = object

      // Fimd Avro type based on map
      let dtype = schema['x-data-type']
      let atype = this.state.avroMap ? this.state.avroMap.find((t) => nonCSCompare(t.label, dtype)) : null
      //logger.info("SchemaView:generateAvroSchema:TYPEMAP", dtype, atype, schema, this.state);

      if (atype) {
        // Extract size if we have fixed format.
        if (atype.object['Platform'].startsWith('fixed')) {
          schemas['type'] = 'fixed'
          schemas['size'] = atype.object['Platform'].substring(5)
        } else {
          schemas['type'] = atype.object['Platform']
        }

        if (atype.object['Attribute']) {
          schemas['logicalType'] = atype.object['Attribute']
          if (schemas['logicalType'] === 'decimal') {
            schemas['precision'] = schema['x-data-precision'] ? schema['x-data-precision'] : '16'
            schemas['scale'] = schema['x-data-scale'] ? schema['x-data-scale'] : '4'
          }
        }
      } else {
        schemas['type'] = schema['type']
      }

      if (schema['description']) schemas['doc'] = schema['description']
      if (schema['default']) schemas['default'] = schema['default']
    }
    //logger.info("SchemaView:generateAvroSchema:RESULT", schemas);
    return schemas
  }

  /**
   * Create schema for interface for specific platform. Current
   * schema comply with Swagger 2.0 speification.
   * @param ds - publication specification.
   * @param platform - platform for which specification created..
   * @param params - parameters of schema generation.
   * @param schema - schema to add specification to. Null mean schema will be created.
   * @param define - definitions structure for datasets to collect.
   * @return
   */
  createInterfaceSchema = async (ds, platform, params, schema, define) => {
    if (!ds || !ds.operations) {
      return
    }

    const dsSchema = schema ? schema : {}
    const dsPromises = []
    const dsDefine = define ? define : { definitions: {}, schemas: {}, names: {} }

    const swaggerSchema = params.schemaType === 'SWAGGER'

    if (!schema) {
      logger.info('SchemaView:createInterfaceSchema', ds, platform)
      if (this.props.change) {
        this.props.change(true, 'Create schema for selected platform...', '', '')
      }

      // Schema properties.
      this.createCommonSchema(ds, platform, params, dsSchema, dsDefine)

      await this.createIdentitySchema(ds, platform, params, dsSchema, dsDefine)
    }

    if (!dsSchema['paths']) dsSchema['paths'] = {}
    let paths = dsSchema['paths']
    let splitPath = ds.path.substring(1).split('/')
    let schemaName = schema ? ds.identity.name + '-' : ''

    // We need to iterate all operation of the interface.
    for (let j in ds.operations) {
      const operation = ds.operations[j]

      let path = ''
      let pathList = []

      let pathNewElems = []
      let pathSkip = false
      for (let iElem in splitPath) {
        let pathElem = splitPath[splitPath.length - iElem - 1]

        if (pathElem.startsWith('{')) {
          // We want skip every not used parameter but only until first existing.
          if (operation.parameters.findIndex((p) => '{' + p.name + '}' === pathElem) === -1) {
            pathSkip = true
          } else {
            pathNewElems.push(pathElem)
            pathSkip = false
          }
        } else if (!pathSkip || pathNewElems.length === 0) {
          pathNewElems.push(pathElem)
        }
        //logger.info("SchemaView:createInterfaceSchema:SKIP0", pathElem, pathSkip);
      }

      path = ''
      for (let iNewElem in pathNewElems) {
        path += '/' + pathNewElems[pathNewElems.length - iNewElem - 1]
      }
      pathList.push(path)
      //logger.info("SchemaView:createInterfaceSchema:PATH", splitPath, path);

      // For get operation with list properties.
      if (operation.action.toLowerCase() === 'get') {
        let pathElems = path.substring(1).split('/')
        let pathParams = pathElems.filter(
          (item) =>
            item.startsWith('{') &&
            operation.parameters.findIndex(
              (p) =>
                '{' + p.name + '}' === item &&
                ds.parameters.find((pr) => pr.field.identity.name === p.name).field.optional
            ) !== -1
        )
        // eslint-disable-next-line no-unused-vars
        for (let iParam in pathParams) {
          let pathNewElems = []
          let pathSkip = false
          let pathSkiped = false
          for (let iElem in pathElems) {
            let pathElem = pathElems[pathElems.length - iElem - 1]

            if (pathElem.startsWith('{')) {
              // We want skip only first time we see the optional parameter.
              if (
                !pathSkiped &&
                ds.parameters.find((p) => '{' + p.field.identity.name + '}' === pathElem).field.optional
              ) {
                // We start skiping path elements after it.
                pathSkip = true
                pathSkiped = true
              } else {
                // We stop skiping on next parameter.
                pathNewElems.push(pathElem)
                pathSkip = false
              }
            } else if (!pathSkip || pathNewElems.length === 0) {
              pathNewElems.push(pathElem)
            }
            //logger.info("SchemaView:createInterfaceSchema:SKIP1", pathElem, pathSkip);
          }

          path = ''
          for (let iNewElem in pathNewElems) {
            path += '/' + pathNewElems[pathNewElems.length - iNewElem - 1]
          }
          pathList.push(path)
          pathElems = path.substring(1).split('/')
        }
        //logger.info("SchemaView:createInterfaceSchema:LIST", pathParams, pathList);
      }

      for (let iList in pathList) {
        let opr = {}
        let prm = []
        let rsp = {}
        let path_prm = []
        let ref = '#/'

        ref = swaggerSchema ? '#/' : '#/components/'

        opr['tags'] = []
        opr['tags'].push(ds.identity.name)
        if (ds.object.tags) {
          for (let iTag in ds.object.tags) {
            let tag = ds.object.tags[iTag]
            opr['tags'].push(tag.name)
          }
        }

        // Get name from identity.
        let summary = formatObjectName(operation.identity.name, params.alignNames)
        let operationid = formatObjectName(
          ds.identity.name + operation.identity.name + (iList > 0 ? iList : ''),
          params.alignNames
        )

        opr['summary'] = summary
        opr['description'] = params.minimizeSchemaSize ? null : operation.identity.description
        opr['operationId'] = operationid
        opr['deprecated'] = operation.deprecated ? true : false
        opr['parameters'] = []
        opr['responses'] = {}

        path = pathList[iList]
        prm = opr['parameters']
        rsp = opr['responses']

        // We need to iterate all parameters of operation.
        for (let iParam in operation.parameters) {
          const reference = operation.parameters[iParam]
          let parameter = ds.parameters.find((p) => nonCSCompare(p.field.identity.name, reference.name))
          if (!parameter) {
            console.error('SchemaView:createInterfaceSchema:RESPONSE:ERROR', operation)
            let error =
              'Error: ' +
              'Parameter ' +
              reference.name +
              ' of operation ' +
              operation.identity.name +
              ' not exist in interface ' +
              ds.identity.name +
              '!'
            if (this.props.change) {
              this.props.change(true, '', '', error)
            }
            throw error
          }
          let param = {}

          param['name'] = parameter.field.identity.name
          if (swaggerSchema || !parameter.location || parameter.location.toLowerCase() !== 'body') {
            param['in'] = parameter.location
              ? parameter.location.toLowerCase() === 'body'
                ? 'query'
                : parameter.location.toLowerCase()
              : 'querty'

            //logger.info("SchemaView:createInterfaceSchema:PARAM", reference.name, parameter, param);

            // We use properties only when it not path or it exist in path.
            if (nonCSCompare(param['in'], 'path')) {
              if (path.indexOf('{' + param['name'] + '}') !== -1) {
                path_prm.push({
                  $ref: ref + 'parameters/' + schemaName + param['name']
                })
              }
            } else {
              prm.push({
                $ref: ref + 'parameters/' + schemaName + param['name']
              })
            }
          } else {
            opr['requestBody'] = {
              $ref: ref + 'requestBodies/' + schemaName + param['name']
            }
          }
        }

        // We need to iterate all responses of operation.
        for (let iResp in operation.responses) {
          const reference = operation.responses[iResp]
          let response = ds.responses.find((p) => nonCSCompare(p.field.identity.name, reference.name))
          if (!response) {
            console.error('SchemaView:createInterfaceSchema:RESPONSE:ERROR', operation)
            let error =
              'Error: ' +
              'Response ' +
              reference.name +
              ' of operation ' +
              operation.identity.name +
              ' not exist in interface ' +
              ds.identity.name +
              '!'
            if (this.props.change) {
              this.props.change(true, '', '', error)
            }
            throw error
          }

          if (iList > 0 && response.code === '200') {
            let type = this.getTypes(response.field.type, platform)
            let resp = {}

            let schemaState = this.defineItemType(
              ds,
              response.field,
              type.schema,
              type.platform,
              platform,
              params,
              resp,
              dsDefine,
              dsPromises,
              true,
              iList
            )
            if (swaggerSchema || schemaState !== 'schema') {
              resp['description'] = params.minimizeSchemaSize ? '' : response.field.identity.description

              rsp[response.code] = resp
            } else {
              rsp[response.code] = {}
              rsp[response.code]['description'] = params.minimizeSchemaSize ? '' : response.field.identity.description
              rsp[response.code]['content'] = {}
              rsp[response.code]['content']['application/json'] = resp
            }
          } else {
            rsp[response.code] = {
              $ref: ref + 'responses/' + schemaName + reference.name
            }
          }
        }

        if (!paths[path]) paths[path] = {}
        if (path_prm && path_prm.length > 0) paths[path]['parameters'] = path_prm
        paths[path][operation.action.toLowerCase()] = opr
      }
    }

    let components = dsSchema

    // Schema properties.
    if (swaggerSchema) {
      if (!dsSchema['parameters']) dsSchema['parameters'] = {}
      if (!dsSchema['responses']) dsSchema['responses'] = {}
    } else {
      if (!dsSchema['components']) dsSchema['components'] = {}
      components = dsSchema['components']
      if (!components['schemas']) components['schemas'] = {}
      if (!components['parameters']) components['parameters'] = {}
      if (!components['responses']) components['responses'] = {}
    }

    // Define reusable parameters for interface.
    for (let iParam in ds.parameters) {
      let parameter = ds.parameters[iParam]

      // Type for property.
      let type = this.getTypes(parameter.field.type, platform)
      let param = {}

      let schemaType = swaggerSchema ? type.schema === 'object' : true

      //logger.info("SchemaView:createInterfaceSchema:PARAMETER", param, defs);
      if (swaggerSchema || !parameter.location || parameter.location.toLowerCase() !== 'body') {
        param['name'] = parameter.field.identity.name
        param['in'] = parameter.location ? parameter.location.toLowerCase() : 'path'
        param['description'] = params.minimizeSchemaSize ? '' : parameter.field.identity.description
        param['required'] = parameter.field.optional && param['in'] !== 'path' ? false : true

        // eslint-disable-next-line no-unused-vars
        let schemaState = this.defineItemType(
          ds,
          parameter.field,
          type.schema,
          type.platform,
          platform,
          params,
          param,
          dsDefine,
          dsPromises,
          schemaType
        )

        components['parameters'][schemaName + param['name']] = param
      } else {
        let schemaState = this.defineItemType(
          ds,
          parameter.field,
          type.schema,
          type.platform,
          platform,
          params,
          param,
          dsDefine,
          dsPromises,
          schemaType
        )

        if (!components['requestBodies']) components['requestBodies'] = {}
        components['requestBodies'][schemaName + parameter.field.identity.name] = {}
        components['requestBodies'][schemaName + parameter.field.identity.name]['description'] =
          params.minimizeSchemaSize ? '' : parameter.field.identity.description
        components['requestBodies'][schemaName + parameter.field.identity.name]['content'] = {}
        if (schemaState === 'schema') {
          components['requestBodies'][schemaName + parameter.field.identity.name]['content']['application/json'] = param
        } else {
          components['requestBodies'][schemaName + parameter.field.identity.name]['content']['application/json'] = {}
          components['requestBodies'][schemaName + parameter.field.identity.name]['content']['application/json'][
            'schema'
          ] = param
        }
        components['requestBodies'][schemaName + parameter.field.identity.name]['required'] = parameter.field.optional
          ? false
          : true
      }
    }

    // We need to iterate all responses.
    for (let iResp in ds.responses) {
      let response = ds.responses[iResp]

      // Type for property.
      let type = this.getTypes(response.field.type, platform)
      let resp = {}

      let schemaType = swaggerSchema
      let schemaState = this.defineItemType(
        ds,
        response.field,
        type.schema,
        type.platform,
        platform,
        params,
        resp,
        dsDefine,
        dsPromises,
        schemaType
      )

      if (swaggerSchema || schemaState === 'ref') {
        resp['description'] = params.minimizeSchemaSize ? '' : response.field.identity.description

        components['responses'][schemaName + response.field.identity.name] = resp
      } else {
        components['responses'][schemaName + response.field.identity.name] = {}
        components['responses'][schemaName + response.field.identity.name]['description'] = params.minimizeSchemaSize
          ? ''
          : response.field.identity.description
        components['responses'][schemaName + response.field.identity.name]['content'] = {}
        if (schemaState === 'schema') {
          components['responses'][schemaName + response.field.identity.name]['content']['application/json'] = resp
        } else {
          components['responses'][schemaName + response.field.identity.name]['content']['application/json'] = {}
          components['responses'][schemaName + response.field.identity.name]['content']['application/json']['schema'] =
            resp
        }
      }
      //logger.info("SchemaView:createInterfaceSchema:RESPONSE", resp, defs);
    }

    return Promise.all(dsPromises).then(
      (result) => {
        if (!schema) {
          let layout = deepCopy(this.props.layout)
          if (dsSchema) {
            // Convert to string
            layout.schema = this.generateDatasetSchema(params, dsSchema, dsDefine)
            logger.info('SchemaView:createInterfaceSchema:RESULT', dsSchema, dsDefine)

            // Update layout
            this.props.onChangeLayout(layout)
          }
          this.setState({ loadingSchema: false })
        }
      },
      (err) => {
        console.error('SchemaView:createDatasetSchema:ERROR', err)
        if (this.props.change) {
          this.props.change(true, '', '', 'Error: ' + err.message)
        }
        this.setState({ loadingSchema: false })
      }
    )
  }

  /**
   * Create schema for publication for specific platform. Current
   * schema comply with Swagger 2.0 speification.
   * @param ds - publication specification.
   * @param platform - platform for which specification created..
   * @param params - parameters of schema generation.
   * @param schema - schema to add specification to. Null mean schema will be created.
   * @param define - definitions structure for datasets to collect.
   * @return
   */
  createPublicationSchema = async (ds, platform, params, schema, define) => {
    if (!ds || (!ds.datasets && !ds.interfaces)) {
      return
    }

    const dsSchema = schema ? schema : {}
    const dsPromises = []
    const dsDefine = define ? define : { definitions: {}, schemas: {}, names: {} }

    if (!schema) {
      logger.info('SchemaView:createPublicationSchema', ds, platform, this.state)
      if (this.props.change) {
        this.props.change(true, 'Create schema for selected platform...', '', '')
      }

      // Schema properties.
      this.createCommonSchema(ds, platform, params, dsSchema, dsDefine)

      // Initialize Identity structure to not have it too many times.
      await this.createIdentitySchema(ds, platform, params, dsSchema, dsDefine)
    }

    /*
    // We need to iterate all datasets of publication.
    for (let j in ds.datasets) {
      const dtst = ds.datasets[j];

      // Get organization path.
      let ver = dtst.version.major;// + '.' + dtst.version.minor + '.' + dtst.version.revision;
      let uri = this.props.majorObject.object.parent.name + '/datasets/' + dtst.identity.name + '/versions/' + ver;

      dsPromises.push(
        getRequestFromPath(uri).get()
        .then((obj) => {
            logger.info("SchemaView:createPublicationSchema:DATASET", uri, obj, dsPromises.length, dsPromises);
            return this.createDatasetSchema(obj, platform, dsSchema, dsDefine);
        }, (err) => {
            console.error("SchemaView:createPublicationSchema:ERROR", err);
            return false;
        })
      );
    }
    */

    // We need to iterate interfaces of publication.
    for (let j in ds.interfaces) {
      const intf = ds.interfaces[j]

      // Get organization path.
      const approved = params.useApprovedVersion

      let ver = intf.version.major // + '.' + intf.version.minor + '.' + intf.version.revision;
      let uri = this.props.majorObject.object.parent.name + '/interfaces/' + intf.identity.name + '/versions/' + ver

      dsPromises.push(
        requestVersionedObjectByURI(uri, approved).then(
          (obj) => {
            logger.info('SchemaView:createPublicationSchema:INTERF', uri, obj, dsPromises.length, dsPromises)
            return this.createInterfaceSchema(obj, platform, params, dsSchema, dsDefine)
          },
          (err) => {
            console.error('SchemaView:createPublicationSchema:INTERF:ERROR', err)
            return false
          }
        )
      )
    }

    return Promise.all(dsPromises).then(
      (result) => {
        if (!schema) {
          let layout = deepCopy(this.props.layout)
          if (dsSchema) {
            // Convert to string
            layout.schema = this.generateDatasetSchema(params, dsSchema, dsDefine)
            logger.info('SchemaView:createPublicationSchema:RESULT', dsSchema, dsDefine)

            // Update layout
            this.props.onChangeLayout(layout)
          }
          this.setState({ loadingSchema: false })
        }
      },
      (err) => {
        console.error('SchemaView:createPublicationSchema:ERROR', err)
        if (this.props.change) {
          this.props.change(true, '', '', 'Error: ' + err.message)
        }
        this.setState({ loadingSchema: false })
      }
    )
  }

  /**
   * Create schema for common swagger properties. Current
   * schema comply with Swagger 2.0 and 3.0 speification.
   * @param ds - publication specification.
   * @param platform - platform for which specification created..
   * @param params - parameters of schema generation.
   * @param schema - schema to add specification to. Null mean schema will be created.
   * @param define - definitions structure for datasets to collect.
   * @return
   */
  createCommonSchema = async (ds, platform, params, schema, define) => {
    if (!ds) return

    const dsSchema = schema ? schema : {}

    const swaggerSchema = params.schemaType === 'SWAGGER'

    logger.info('SchemaView:createCommonSchema', ds, platform, params)

    // Schema properties.
    if (swaggerSchema) {
      dsSchema['swagger'] = '2.0'
    } else {
      dsSchema['openapi'] = '3.0.0'
    }

    dsSchema['info'] = {}
    dsSchema['info']['title'] = ds.identity.name
    dsSchema['info']['description'] = params.minimizeSchemaSize ? '' : ds.identity.description
    dsSchema['info']['version'] = ds.version.major + '.' + ds.version.minor + '.' + ds.version.revision
    dsSchema['info']['termsOfService'] = ds.termsOfService
    if (ds.object.contact && ds.object.contact.identity && ds.object.contact.identity.name) {
      dsSchema['info']['contact'] = {}
      dsSchema['info']['contact']['name'] = ds.object.contact.identity.name

      if (ds.object.contact && ds.object.contact.url) {
        dsSchema['info']['contact']['url'] = ds.object.contact.url
      }
      if (ds.object.contact && ds.object.contact.email) {
        dsSchema['info']['contact']['email'] = ds.object.contact.email
      }
    }
    if ((ds.licenseName && ds.licenseName.length > 0) || (ds.licenseURL && ds.licenseURL.length > 0)) {
      dsSchema['info']['license'] = {}
      if (ds.licenseName && ds.licenseName.length > 0) dsSchema['info']['license']['name'] = ds.licenseName
      if (ds.licenseURL && ds.licenseURL.length > 0) dsSchema['info']['license']['url'] = ds.licenseURL
    }

    if (swaggerSchema) {
      dsSchema['schemes'] = []
      dsSchema['schemes'].push('https')

      let appName = objectNameFromPathByType(ds.object.parent.name, 'applications')

      if (ds.servers && ds.servers[0] && ds.servers[0].url) {
        dsSchema['host'] = ds.servers[0].url
        if (ds.servers[0].variables && ds.servers[0].variables.length > 0) {
          for (let iVariable in ds.servers[0].variables) {
            let variable = ds.servers[0].variables[iVariable]
            if (variable.name === 'basePath') {
              dsSchema['basePath'] = variable.default
              dsSchema['host'] = dsSchema['host'].replace('/{' + variable.name + '}', '')
            } else {
              dsSchema['host'] = dsSchema['host'].replace('{' + variable.name + '}', variable.default)
            }
          }
        }
      } else {
        dsSchema['host'] = appName
        dsSchema['basePath'] = '/v' + ds.version.major
      }
    } else if (ds.servers) {
      dsSchema['servers'] = []

      for (let iServer in ds.servers) {
        let cServer = ds.servers[iServer]
        let server = {}
        server['url'] = cServer.url
        if (cServer.description) server['description'] = params.minimizeSchemaSize ? '' : cServer.description
        if (cServer.variables && cServer.variables.length > 0) server['variables'] = {}

        for (let iVariable in cServer.variables) {
          let cVariabe = cServer.variables[iVariable]
          server['variables'][cVariabe.name] = {}
          if (cVariabe.description)
            server['variables'][cVariabe.name]['description'] = params.minimizeSchemaSize ? '' : cVariabe.description
          if (cVariabe.values) server['variables'][cVariabe.name]['enum'] = cVariabe.values
          if (cVariabe.default) server['variables'][cVariabe.name]['default'] = cVariabe.default
        }
        dsSchema['servers'].push(server)
      }
    }
  }

  /**
   * Create right schema based on schema parameters
   * @param {object} params - set of parameters to create the schema
   */
  createSchema = (params) => {
    logger.info('SchemaView:createSchema', params, this.props, this.state)
    this.setState({ editingSchemaParams: false, schemaParams: params, loadingSchema: true }, () => {
      if (nonCSCompare(this.props.majorObject.type, 'dataset')) {
        this.createDatasetSchema(this.props.majorObject, this.props.layout.platform, params)
      } else if (nonCSCompare(this.props.majorObject.type, 'interface')) {
        this.createInterfaceSchema(this.props.majorObject, this.props.layout.platform, params)
      } else if (nonCSCompare(this.props.majorObject.type, 'publication')) {
        this.createPublicationSchema(this.props.majorObject, this.props.layout.platform, params)
      }
    })
  }

  /**
   * Clear schema in layout
   */
  clearSchema = () => {
    let layout = deepCopy(this.props.layout)
    layout.schema = null

    this.props.onChangeLayout(layout)
  }

  /**
   * Render dataset of specific type
   * @param {Number} editState - state of editing for dialog
   */
  renderSchemaParams(editState) {
    return (
      <ExportSchemaDialog
        appState={this.props.appState}
        actions={this.props.actions}
        modalTitle={'Specify parameters for schema generation'}
        confirmText={'Generate'}
        isVisible
        isEditable={editState}
        majorObject={this.props.majorObject}
        exportParams={this.state.schemaParams}
        onClose={() => this.setState({ editingSchemaParams: false })}
        onSave={this.createSchema}
      />
    )
  }

  /**
   * Render schema of layout major object
   * @param {Number} editState - state of editing for dialog
   */
  renderSchema(editState) {
    let schema = this.props.layout.Schema || this.props.layout.schema || {} // why uppercase "Schema" was used?
    //logger.info("SchemaView:renderSchema", this.props, this.state);

    if (typeof schema === 'string') schema = JSON.parse(schema)

    return (
      <div className="SchemaView" onClick={() => this.onCheckDialogHeight('.LayoutDialog')}>
        {editState > editableState.EDITABLE ? (
          <div>
            {this.state.types ? (
              <div className="SchemaView__buttons">
                <button className=" btn btn_blue" onClick={() => this.setState({ editingSchemaParams: true })}>
                  Generate
                </button>
                <button className=" btn btn_blue" onClick={this.clearSchema}>
                  Clear
                </button>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        ) : null}
        {this.state.loadingSchema ? (
          <Loader />
        ) : (
          <JSONTreeCopy data={schema} shouldExpandNode={() => false} theme={monokaiJsonTheme} />
        )}
      </div>
    )
  }

  render() {
    // State editing of data in dialog
    let editState = this.props.isEditable
    //logger.info("SchemaView:render", editState, this.state, this.props);

    return (
      <div className="SchemaView">
        {this.renderSchema(editState)}
        {this.state.editingSchemaParams ? this.renderSchemaParams(editState) : null}
      </div>
    )
  }
}

SchemaView.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isEditable: PropTypes.number, // State of editing in dialog
  majorObject: PropTypes.object.isRequired, // Magor object we work against
  layout: PropTypes.object.isRequired, // Layout we handling
  change: PropTypes.func, // Callback function to report changes in data
  types: PropTypes.func, // Function to get types available for execution
  onChangeLayout: PropTypes.func.isRequired // Report change in layout information
}
