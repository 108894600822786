/**
 * Created by kascode on 07.04.16.
 */
import * as types from '../constants/ActionTypes'

const initialState = {
  name: 'Test',
  surname: 'Markovich'
}

function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

function mergeDeep(target, source) {
  let output = Object.assign({}, target)
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] })
        else output[key] = mergeDeep(target[key], source[key])
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }
  return output
}

export default function userAppState(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_USER_STATE:
      return mergeDeep(state, action.payload)
    default:
      return state
  }
}
