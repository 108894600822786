/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import '../../styles/ModalWindow/ModalWindow.scss'
import { ScrollArea } from './ScrollArea.tsx'

export class ModalWindow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      node: null,
      normalHeight: 0,
      scrolledToBottom: false
    }

    this.mainRef = React.createRef()
  }

  componentDidMount() {
    let myNode = this.mainRef.current
    this.setNewNode(myNode)
    this.setPageBlur(this.props.isOpen)

    window.addEventListener('mousewheel', (event) => {
      if (this.props.isOpen) {
        //console.log(this);
        //event.preventDefault();
      }
    })
  }

  setNewNode(myNode) {
    this.setState({ node: myNode })
  }

  setNormalHeight(scrollNode) {
    this.setState({ normalHeight: scrollNode.offsetHeight })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.isOpen === false) {
      //window.removeEventListener('mousewheel');
    }
  }

  shouldComponentUpdate() {
    return true
  }

  componentDidUpdate() {
    ////console.log("ModalWindow componentDidUpdate");
    this.setPageBlur(this.props.isOpen)
  }

  componentWillUnmount() {
    setTimeout(() => {
      this.setPageBlur(false)
    }, 0)
    //window.removeEventListener('mousewheel');
  }

  setPageBlur(isBlur) {
    if (this.props.level > 0) return
    let node = this.state.node
    if (!node) return

    const isNoScroll = document.querySelectorAll('.ModalWindow__root__open').length > 0

    //console.log("setPageBlur",isBlur,isNoScroll);

    if (isNoScroll) {
      let x = window.scrollX
      let y = window.scrollY
      document.body.style.overflow = 'hidden'
      document.body.style.height = '100%'
      document.body.style.paddingRight = '16px'
      document.body.classList.add('overflow_hidden')
      window.scrollTo(x, y)
    } else {
      document.body.style.overflow = 'auto'
      document.body.style.height = 'auto'
      document.body.style.paddingRight = '0'
      document.body.classList.remove('overflow_hidden')
    }

    let ua = window.navigator.userAgent
    let msie = ua.indexOf('MSIE ')
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
      this.mainRef.current.style.height = this.props.defaultHeight ? this.props.defaultHeight : '95%'
    }

    const changeClass = (element) => {
      if (isBlur) {
        element.classList ? element.classList.add('ModalWindow__blur') : ''
      } else {
        element.classList ? element.classList.remove('ModalWindow__blur') : ''
      }
    }

    do {
      let parent = node.parentNode
      if (!parent) {
        break
      }
      ;[].slice
        .call(parent.childNodes)
        // eslint-disable-next-line no-loop-func
        .map((element) => (element !== node ? changeClass(element) : 0))

      node = parent
    } while (node)
  }

  preventClick(event) {
    event.stopPropagation()
  }

  onBackgroundClick() {
    if (this.props.canClose) {
      this.props.onClose()
    }
  }

  render() {
    this.setPageBlur(this.props.isOpen)
    let style = { height: this.props.level ? this.props.level * 116 : 'auto' }

    // Frame position and size.
    let mstyle = {}
    //width for translation bottom btn
    if (this.props.width) {
      mstyle = {
        width: this.props.width
      }
    }
    if (this.props.frame && this.props.frame.position) {
      // Style from frame specification of modal dialog
      let pos = this.props.frame.position
      const padding = pos.top
      mstyle = {
        paddingTop: padding,
        paddingLeft: padding,
        height: pos.height,
        width: pos.width
      }
    }

    // Scroll area size
    let id = 'srollarea'

    // logger.log("ModalWindow:render", {style, mstyle, id}, this.state, this.props);
    return (
      <div
        className={
          'ModalWindow__root ' + (this.props.isOpen ? 'ModalWindow__root__open' : '') + ' ' + this.props.className
        }
      >
        <div className="ModalWindow__background" onClick={this.onBackgroundClick.bind(this)}>
          <div
            id={id}
            className="ModalWindow__main"
            ref={this.mainRef}
            style={mstyle}
            onClick={this.preventClick.bind(this)}
            data-iscontainer="true"
          >
            <div className="ModalWindow__level ModalWindow__flex00" style={style} />
            {this.props.header ? (
              <div className="ModalWindow__windowHead ModalWindow__flex00">
                <div className="ModalWindow__closeButton" onClick={this.props.onClose} />
                <div className="ModalWindow__title">{this.props.header}</div>
                {this.props.headerContent ? (
                  <div className="ModalWindowHeader__content">{this.props.headerContent}</div>
                ) : null}
              </div>
            ) : (
              ''
            )}
            {this.props.width ? (
              <div
                style={mstyle}
                className={
                  'ModalWindow__upper ModalWindow__flex00' + (!this.props.upper ? ' ModalWindow__upper_empty' : '')
                }
              >
                {this.props.upper ? this.props.upper : ''}
              </div>
            ) : (
              <div
                className={
                  'ModalWindow__upper ModalWindow__flex00' + (!this.props.upper ? ' ModalWindow__upper_empty' : '')
                }
              >
                {this.props.upper ? this.props.upper : ''}
              </div>
            )}

            <ScrollArea>
              <div className="ModalWindow__body" data-iscontainer="true">
                {this.props.header ? (
                  ''
                ) : (
                  <div
                    className="ModalWindow__closeButton ModalWindow__closeButtonInText"
                    onClick={this.props.onClose}
                  />
                )}
                {this.props.children}
              </div>
            </ScrollArea>

            {this.props.width ? (
              <div style={mstyle} className="ModalWindow__footer ModalWindow__flex00">
                {this.props.footer ? this.props.footer : ''}
              </div>
            ) : (
              <div className="ModalWindow__footer ModalWindow__flex00">
                {this.props.footer ? this.props.footer : ''}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

ModalWindow.propTypes = {
  isOpen: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  upper: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // displayed at the top of the window; not scrolled
  footer: PropTypes.oneOfType([
    // not scrolled
    PropTypes.string,
    PropTypes.object
  ]),
  headerContent: PropTypes.node,
  onClose: PropTypes.func,
  level: PropTypes.number,
  canClose: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array]), // are placed in the scrolled area
  height: PropTypes.number, // are hight of the scrolled area
  defaultHeight: PropTypes.string // used in IE only. workaround for flex+max-height
}
