/**
 * Created by sashaberger on 07.02.2017.
 *
 * Dialog to add or edit organization in organization list.
 *
 */
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { API, getApiResource } from '../../helpers/api'
import { getUTCTime, nonCSCompare } from '../../helpers/index'
import { getJsonSpecFields, getJsonSpecRecords, getPropertyOptions } from '../../helpers/md'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import './JoinDialog.scss'
import { renderAgreement } from '../../helpers/agreement'

const messageActions = getJsonSpecRecords('messageAction')
const messageActionFields = getJsonSpecFields('messageAction')

export class JoinDialog extends Component {
  constructor(props) {
    super(props)

    //console.log("JoinDialog:constructor", props);

    let messageAction = this.getMessageAction(this.props.action)

    console.log('messageAction', messageAction)

    this.state = {
      messageAction: messageAction,

      change: null, // Method to report or cancel modification.

      organization: '',
      department: '',
      message: '',
      accepted: false,
      role: 'Analyst'
    }
  }

  /**
   * Get current action for execution
   * @param action to find
   * @return Action array of values
   */
  getMessageAction(action) {
    //console.log("JoinDialog:getMessageAction", action, messageActions);

    let messageActionIndex = messageActions.findIndex((item) => nonCSCompare(action, item.values[0]))
    if (messageActionIndex > -1) {
      //console.log("found",messageActionIndex,messageActions[messageActionIndex].values);
      return messageActions[messageActionIndex].values
    }
    return {}
  }

  /**
   * Get current action value
   * @param name of field we need from current action
   * @return value
   */
  getMessageActionValue(name, action = this.state.messageAction) {
    const nameIndex = messageActionFields.findIndex((field) => nonCSCompare(field.identity.name, name))
    // console.log("JoinDialog:getMessageActionValue", name, nameIndex, action, messageActionFields);

    return nameIndex > -1 && action ? action[nameIndex] : ''
  }

  /**
   * Execute activity when dialog closing
   * @return
   */
  onClose = () => {
    //console.log("JoinDialog:onClose");
    if (this.props.onClose) this.props.onClose()
  }

  /**
   * Execute edit in the dialog
   * @param
   * @return
   */
  onEdit = (change) => {
    //console.log("JoinDialog:onEdit", this.state.isEditable);

    this.setState({
      change: change
    })
  }

  /**
   * Execute activity when message send to service
   * @param error return from service if any.
   * @return
   */
  onSent = (close, error) => {
    //console.log("JoinDialog:onSent", close, error);

    // Report complete of save with error or not
    if (this.state.change) {
      this.state.change(error)
    }

    if (close && !error) {
      setTimeout(() => {
        this.onClose()
      }, 1000)
    }
  }

  /**
   * Execute activity when save button pushed in dialog
   * @param error return from service if any.
   * @return
   */
  onSave = (close) => {
    let inputError = ''
    if (!this.state.organization || this.state.organization.length === 0) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify organization you want to join!'
    }
    if (!this.state.role || this.state.role.length === 0) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify role you will play in organization!'
    }
    if (!this.state.message || this.state.message.length === 0) {
      inputError +=
        (inputError.length > 0 ? '\n' : '') + 'Please type message to organization architect with reason for access!'
    }
    if (this.state.accepted === false) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please accept license agreement!'
    }
    //console.log("JoinDialog:onSave", close, inputError);
    if (inputError.length > 0) {
      if (this.state.change) {
        this.state.change(true, '', '', inputError)
      }
      return true
    }

    if (this.state.change) {
      this.state.change(true, 'Data send to the service...', '', '')
    }

    const orgMessageRequest = API[getApiResource('organization')](this.state.organization)[getApiResource('message')]
    orgMessageRequest
      .post({
        class: 'Message',
        type: 'AccessApproval',
        issue: {
          status: 'Opened',
          identity: {
            description:
              'Request from ' +
              (this.props.user.profile.firstname || this.props.user.profile.lastname
                ? this.props.user.profile.firstname + ' ' + this.props.user.profile.lastname
                : this.props.user.profile.alias) +
              ' to join organization as ' +
              this.state.role
          },
          attached: {
            name: '/organizations/' + this.state.organization
          },
          notes:
            this.getMessageActionValue('Info') +
            '\nUser: ' +
            this.props.user.profile.identity.name +
            '       Role: ' +
            this.state.role
        },
        attached: {
          name: '/organizations/' + this.state.organization
        },
        sender: {
          id: localStorage.currentUserId,
          description: this.props.user.profile.alias
        },
        text: this.state.message,
        time: moment(getUTCTime()).format('YYYY-MM-DDTHH:mm:ss'),
        action: this.props.action,
        properties: [
          {
            identity: { name: 'organization' },
            type: 'String',
            value: this.state.organization
          },
          {
            identity: { name: 'department' },
            type: 'String',
            value: this.state.department
          },
          {
            identity: { name: 'role' },
            type: 'Enum',
            reference: '/Role',
            value: this.state.role
          },
          {
            identity: { name: 'user' },
            type: 'String',
            value: this.props.user.profile.identity.name
          },
          {
            identity: { name: 'id' },
            type: 'String',
            value: localStorage.currentUserId
          }
        ]
      })
      .then(
        () => {
          if (this.props.onSave) {
            this.props.onSave(this.state.organization, this.state.role, close, this.onSent)
          }
          this.onSent(close)
        },
        (err) => {
          this.props.actions.setError(null)
          this.onSent(close, err)
        }
      )

    // Delay to close on save.
    return true
  }

  /**
   * Cancel editing in the dialog
   * @param close - cancel execution and close dialog.
   * @return
   */
  onCancel = (close) => {
    //console.log("JoinDialog:onCancel", close);

    // Cancel save mode
    if (this.state.change) this.state.change(false)

    this.setState({
      change: null,
      organization: '',
      department: '',
      message: '',
      accepted: false,
      role: 'Analyst'
    })
  }

  /**
   * Change of organization name by typing it
   * @param e - organization name
   */
  onOrganizationChange = (e) => {
    let org = e.target.value
    //console.log("JoinDialog:onOrganizationChange", this.state.organization, org);

    // Indicate save mode
    if (this.state.change && org.length > 0) this.state.change(true, '', '', '')

    this.setState({
      organization: org
    })
  }

  /**
   * Change of department by typing it
   * @param e - department
   */
  onDepartmentChange = (e) => {
    let org = e.target.value
    //console.log("JoinDialog:onDepartmentChange", this.state.department, org);

    // Indicate save mode
    if (this.state.change && this.state.department.length > 0 && org.length > 0) this.state.change(true, '', '', '')

    this.setState({
      department: org
    })
  }

  /**
   * Change of role by selecting it
   * @param e - role
   */
  onRoleChange = (e) => {
    let role = e.target.value
    //console.log("JoinDialog:onRoleChange", this.state.role, role);

    // Indicate save mode
    if (this.state.change) this.state.change(true, '', '', '')

    this.setState({
      role: role
    })
  }

  /**
   * Change of message by typing it
   * @param e - message
   */
  onMessageChange = (e) => {
    let message = e.target.value
    //console.log("JoinDialog:onMessageChange", this.state.message, message);

    // Indicate save mode
    if (this.state.change && this.state.department.length > 0 && message.length > 0) this.state.change(true, '', '', '')

    this.setState({
      message: message
    })
  }

  /**
   * Change of licince acceptence
   * @param role - updated role
   */
  onAcceptedChange = (e) => {
    let state = e.target.value
    //console.log("JoinDialog:onAcceptedChange", this.state.accepted, state);

    // Indicate save mode
    if (this.state.change && this.state.organization.length > 0) this.state.change(true, '', '', '')

    this.setState({
      accepted: state
    })
  }

  /**
   * Render actual body of dialog
   * @return
   */
  renderFooterData = (editState) => {
    //console.log("JoinDialog:renderFooterData", this.state, editState);
    return (
      <div className="JoinDialog">
        <div className="row JoinDialog__acceptRow">
          <div className="col-xs-12">
            <label className="JoinDialog__label">
              <EditableEntity
                data={this.state.accepted}
                dataType={{ name: 'boolean' }}
                dataProps={{ onChange: this.onAcceptedChange.bind(this) }}
                isEditable
                inEditMode
              />
              I accept
            </label>
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render actual body of dialog
   * @return
   */
  renderUpperData = (editState) => {
    let userState = this.props.user
    let subject =
      'Request from ' +
      (userState.profile.firstname || userState.profile.lastname
        ? userState.profile.firstname + ' ' + userState.profile.lastname
        : userState.profile.alias) +
      ' to join organization as ' +
      this.state.role
    let info = this.getMessageActionValue('Info')
    let options = getPropertyOptions('role')

    //console.log("JoinDialog:renderUpperData", this.state, editState);
    return (
      <div className="JoinDialog">
        <div className="row JoinDialog__subjectRow">
          <div className="col-xs-3">Subject:</div>
          <div className="col-xs-9">
            <div className="JoinDialog__subjectValue">{subject}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3">Organization:</div>
          <div className="col-xs-9">
            <EditableEntity
              data={this.state.organization}
              dataType={{ name: 'string' }}
              dataProps={{
                onChange: this.onOrganizationChange.bind(this),
                placeholder: 'Type organization name'
              }}
              isEditable
              inEditMode
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3">Department:</div>
          <div className="col-xs-9">
            <EditableEntity
              data={this.state.department}
              dataType={{ name: 'string' }}
              dataProps={{
                onChange: this.onDepartmentChange.bind(this),
                placeholder: 'Type optional department name'
              }}
              isEditable
              inEditMode
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3">Role:</div>
          <div className="col-xs-9">
            <EditableEntity
              dataProps={Object.assign({
                onChange: this.onRoleChange.bind(this),
                placeholder: 'Role',
                hideCheckBox: true,
                multi: false
              })}
              dataType={{ name: 'enum', options: options }}
              data={this.state.role}
              isEditable
              inEditMode
            />
          </div>
        </div>
        <div className="row">
          <div className="JoinDialog__info">{info}</div>
          <div className="JoinDialog__text col-xs-12">
            <textarea
              className="TextArea"
              name="text"
              value={this.state.message}
              placeholder="Type message text"
              onChange={this.onMessageChange.bind(this)}
              onKeyUp={(event) => event.stopPropagation()}
              onKeyPress={(event) => event.stopPropagation()}
              onKeyDown={(event) => event.stopPropagation()}
            />
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render scrollable area of dialog
   * @return
   */
  renderData = (editState) => {
    //console.log("JoinDialog:renderData", this.state, editState);
    return (
      <div className="JoinDialog">
        <div className="JoinDialog__label">License agreement</div>
        <div className="row JoinDialog__scrollingRow">
          <div className="col-xs-12">{renderAgreement()}</div>
        </div>
      </div>
    )
  }

  /**
   *
   * Render function of dialog
   * @return
   */
  render() {
    let caption = this.getMessageActionValue('Caption')
    let button = caption.split(' ')[0]

    //console.log("JoinDialog:render", this.props, this.state);

    return (
      <div className="JoinDialog">
        <EditorDialog
          objectType="data"
          modalTitle={caption}
          confirmText={button}
          upperContent={this.renderUpperData.bind(this)}
          editContent={this.renderData.bind(this)}
          footerContent={this.renderFooterData.bind(this)}
          isVisible={this.props.isVisible}
          isEditable={this.props.isEditable}
          onClose={this.onClose.bind(this)}
          onSave={this.onSave.bind(this)}
          onEdit={this.onEdit.bind(this)}
          onCancel={this.onCancel.bind(this)}
        />
      </div>
    )
  }
}

JoinDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object,
  action: PropTypes.string, // RequestAccess
  user: PropTypes.object, // userState or sender
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}

JoinDialog.defaultProps = {}
