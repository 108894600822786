/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import React from 'react'
import { Link } from 'react-router-dom'
import { poolData } from '../../helpers/auth-cognito'

import { Divider, List, ListItem, ListItemIcon, Tooltip } from '@material-ui/core'

import SettingsIcon from '@material-ui/icons/Settings'
import HomeIcon from '@material-ui/icons/Home'
import InputIcon from '@material-ui/icons/Input'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom'

export const LoginBlock = ({ width, userName, userSurname, onLogin }) => {
  const loggedIn = typeof userName !== 'undefined'

  // Let's safe original hash and path for login
  if (window.location && window.location.hash) {
    localStorage['currentUserRefreshLocation'] = window.location.pathname
  }

  return (
    <>
      <Divider />
      <List>
        <Link to="/select">
          <Tooltip title="Home page" placement="right">
            <ListItem button>
              <ListItemIcon style={{ color: 'white' }}>
                <HomeIcon />
              </ListItemIcon>
            </ListItem>
          </Tooltip>
        </Link>

        {loggedIn && (
          <Link to="">
            <Tooltip title="Settings" placement="right">
              <ListItem button onClick={onLogin}>
                <ListItemIcon style={{ color: 'white' }}>
                  <SettingsIcon />
                </ListItemIcon>
              </ListItem>
            </Tooltip>
          </Link>
        )}

        {loggedIn ? (
          <Link
            to=""
            onClick={() => {
              if (window.AUTH_URL === 'disabled') {
                localStorage['currentUserId'] = ''
                localStorage['currentUserToken'] = ''
                location.href = '/auth'
              } else if (window.AUTH_URL === '/cognitologin') {
                try {
                  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
                  const cognitoUser = userPool.getCurrentUser()
                  cognitoUser.getSession((err, session) => {
                    cognitoUser.signOut()
                    localStorage['currentUserId'] = ''
                    localStorage['currentUserToken'] = ''
                    localStorage['currentUserTokenTime'] = ''
                    localStorage['currentUserRefreshToken'] = ''
                    location.href = '/cognitologin'
                  })
                } catch (ex) {
                  console.error(ex)
                  localStorage['currentUserId'] = ''
                  localStorage['currentUserToken'] = ''
                  localStorage['currentUserTokenTime'] = ''
                  localStorage['currentUserRefreshToken'] = ''
                  location.href = '/cognitologin'
                }
              } else {
                window.releaseToken()
              }
              return false
            }}
          >
            <Tooltip title="Logout" placement="right">
              <ListItem button>
                <ListItemIcon style={{ color: 'white' }}>
                  <MeetingRoomIcon />
                </ListItemIcon>
              </ListItem>
            </Tooltip>
          </Link>
        ) : (
          <Link to="">
            <Tooltip title="Login" placement="right">
              <ListItem button>
                <ListItemIcon style={{ color: 'white' }}>
                  <InputIcon />
                </ListItemIcon>
              </ListItem>
            </Tooltip>
          </Link>
        )}
      </List>
      <Divider />
    </>
  )
}
