import React, { useEffect, useState } from 'react'
import { Divider, List, ListItem, ListItemIcon, Tooltip } from '@material-ui/core'
import { AdaptiveImage } from 'components/AdaptiveImage/AdaptiveImage'
import { fetchDataUrlImmediate } from 'helpers/api'

import defaultUserPicture from '../../resources/images/face-2x.png'

import './Sidebar.scss'
import { getUserPhotoUrl } from 'helpers'
import { Link } from 'react-router-dom'
import { track } from 'helpers/analytics'
import { LoginBlock } from 'components/LoginBlock/LoginBlockNew'
import { Profile } from 'components/Profile/Profile'

export const Side: React.FC<any> = ({ mainListItems, userState, actions, noApiCalls }) => {
  const [profileOpen, setProfileOpen] = useState(false)

  const [photoUrl, setPhotoUrl] = useState<any>()

  useEffect(() => {
    if (userState?.identity?.id) {
      // TODO: check if user have a avatar
      const userPhoto = userState?.identity ? fetchDataUrlImmediate(getUserPhotoUrl(userState.identity.id)) : ''
      const style = {
        backgroundImage: `url('${userPhoto}'), url('${defaultUserPicture}')`
      }
      setPhotoUrl(style)
    }
  }, [userState.identity])

  useEffect(() => {
    if (sessionStorage['temp_password']) {
      setProfileOpen(true)
    }
  }, [])

  return (
    <>
      <aside className="Side">
        <div className="Side__top">
          <div className="Side__logo">
            <AdaptiveImage src={'new-logo-symbol'} />
          </div>
          <Divider />
          <List>
            {mainListItems.map((item, idx) => (
              <Link key={idx} to={item.route} onClick={() => track(item.route, 'topmenu')}>
                <Tooltip title={item.title} placement="right">
                  <ListItem button>
                    <ListItemIcon style={{ color: 'white' }}>{item.icon}</ListItemIcon>
                  </ListItem>
                </Tooltip>
              </Link>
            ))}
          </List>
          <Divider />
        </div>

        <div className="Side__bottom">
          <LoginBlock
            width=""
            userName={userState.profile ? userState.profile.firstname : ''}
            userSurname={userState.profile ? userState.profile.lastname : ''}
            onLogin={() => setProfileOpen(true)}
          />
          <Tooltip title="Settings" placement="right">
            <div style={photoUrl} className="Side__user-photo" onClick={() => setProfileOpen((prev) => !prev)} />
          </Tooltip>
        </div>
      </aside>
      {!noApiCalls && (
        <Profile isOpen={profileOpen} userState={userState} actions={actions} onClose={() => setProfileOpen(false)} />
      )}
    </>
  )
}
