/**
/**
 * Created by mailf on 07.06.2016.
 */
import RestClient from 'another-rest-client'
import { getObjectByName } from './data'
import { nonCSCompare, objCompare, verCompare } from './index'
import { findByPath } from '../resources/lib/metadata'

import {
  versionToStr,
  createEmptyObject,
  objectNameFromPathByType,
  getFullUri,
  getFullUriVersioned,
  pathByType
} from './index'
import { checkAndRefreshCognitoToken } from './auth-cognito'
import moment from 'moment'
import logger from './logger'
import { Actions } from '../actions'
import { RequestQuery } from '../components/Application/Application'

// export const apiURL = window.API_URL ? window.API_URL.api : API_URL.api
// export const idApiURL = window.API_URL ? window.API_URL.idApi : API_URL.idApi

export const apiURL = process.env.REACT_APP_API
export const idApiURL = process.env.REACT_APP_ID_API

logger.info('init api', apiURL, idApiURL)

const api = new RestClient(apiURL)
const idApi = new RestClient(idApiURL)

//logger.info("api, idApi", api, idApi);

const API_CACHE_LIFETIME = 600000
const API_IMAGE_CACHE_LIFETIME = API_CACHE_LIFETIME * 10
const API_ISSUE_CACHE_LIFETIME = API_CACHE_LIFETIME / 2
const API_ORG_DATASET_CACHE_LIFETIME = API_CACHE_LIFETIME * 10

const API_QUEUE_ACTIVE_REQUEST_LIMIT = 3

window.requestCount = {
  network: 0,
  cached: 0
}

window.apiCache = {
  // Request queue
  queue: {},
  requestCount: 0,
  requestFirst: null,
  requestLast: null,

  /**
   * Request object from cache
   * @param {string} key - key of cached object
   * @returns {Object} object if found and null if not
   */
  printQueue: function (func, key) {
    logger.info(
      'apiCache:printQueue:' + func + ' ' + key + ' count:',
      Object.keys(this.queue).length,
      ' queue:',
      this.requestCount,
      this.requestFirst,
      this.requestLast
    )
    Object.keys(this.queue).map((k, i) => logger.info('apiCache:printQueue:Item i=', i, k, this.queue[k]))
  },

  /**
   * Request object from cache
   * @param {string} key - key of cached object
   * @returns {Object} object if for key exist outstending request
   */
  getRequest: function (key) {
    const cachedObject = this.queue[key]

    //this.printQueue("getRequest", key);
    return cachedObject
  },

  /**
   * Adds object to cache or replaces existing
   * @param {string} key - key in store (object path)
   * @param {object} request - request to get object
   * @returns {Object} promise
   */
  setRequest: function (key, request) {
    if (!request.type) {
      logger.error('Set request without type', request)
    }

    let queueObject: any = {
      request: request
    }

    if (this.requestCount < API_QUEUE_ACTIVE_REQUEST_LIMIT) {
      // We below limit, lets directly execute request.
      queueObject.promise = queueObject.request.function(request)
      this.requestCount++
    } else {
      // We create wait promise and put request in queue
      queueObject.promise = new Promise((resolve, reject) => {
        queueObject.resolve = resolve
        queueObject.reject = reject
      })

      // Put request as last in queue if queue already have object, or first and last.
      if (!this.requestFirst) {
        this.requestFirst = queueObject
      }
      if (this.requestLast) {
        this.requestLast.requesNext = queueObject
      }
      this.requestLast = queueObject
    }

    this.queue[key] = queueObject
    //this.printQueue("setRequest", key);
    return queueObject.promise
  },

  /* Remove reqest from queuee
   * @param { string } key - key of cached object
   */
  deleteRequest: function (key, obj, err) {
    let queueObject = this.queue[key]
    if (queueObject) {
      // When we executed real request.
      if (queueObject.resolve && obj) {
        queueObject.resolve(obj)
      } else if (queueObject.reject && !obj) {
        queueObject.reject(err)
      }

      // Remove executed request.
      delete this.queue[key]
      this.requestCount--

      // Create request if we have queue
      if (this.requestFirst) {
        // Get first from queue. Manage if it is last too.
        queueObject = this.requestFirst
        if (queueObject.requesNext) {
          this.requestFirst = queueObject.requesNext
        } else {
          this.requestFirst = null
          this.requestLast = null
        }

        // add real request
        if (queueObject && queueObject.request && queueObject.request.function) {
          try {
            queueObject.requestPromise = queueObject.request.function(queueObject.request)
          } catch (e) {
            logger.error('Go to request', queueObject, e)
          }
        }
        this.requestCount++
      }
    }

    // New state of queue after delete.
    //this.printQueue("deleteRequest", key);
  },

  // Object Cache
  store: {},
  objectCount: 0,

  /**
   * Request object from cache
   * @param {string} key - key of cached object
   * @returns {Object} object if found and null if not
   */
  printStore: function (key) {
    logger.info('apiCache:printStore count =', Object.keys(this.store).length, key, this.store[key])
    Object.keys(this.store).map((k, i) => logger.info('apiCache:printStore:Item i=', i, k, key === k))
  },

  /**
   * Request object from cache
   * @param {string} key - key of cached object
   * @returns {Object} object if found and null if not
   */
  getObject: function (key) {
    const cachedObject = this.store[key]
    //logger.info("apiCache:getObject ========>>>>", key, cachedObject, this.objectCount, Object.keys(this.store).length);
    //logger.info("apiCache:getObject:TRACE", Error().stack);
    //this.printStore(key);

    if (cachedObject) {
      if (this.isValid(cachedObject)) {
        window.requestCount.cached++
        return cachedObject
      } else {
        this.deleteObject(key)
      }
    }
    return null
  },

  /**
   * Adds object to cache or replaces existing
   * @param {object} obj - object to store
   * @param {string} key - key in store (object path)
   * @param lifetime - optional lifetime for object (ms)
   */
  setObject: function (obj, key, lifetime = API_CACHE_LIFETIME) {
    const objToCache = Object.assign({}, obj, {
      cacheTimestamp: moment().format(),
      lifetime: lifetime
    })

    //logger.info("apiCache:setObject ========^^^^", obj, objToCache, this.objectCount, Object.keys(this.store).length);

    if (obj.type !== 'user') this.clearObjectCache(obj, key)

    if (!this.store[key]) {
      this.objectCount++
    }

    this.store[key] = objToCache
  },

  /* Request object from cache
   * @param { string } key - key of cached object
   */
  deleteObject: function (key) {
    const cachedObjectList = this.store[key]
    if (cachedObjectList) {
      this.objectCount -= cachedObjectList.cacheData ? cachedObjectList.cacheData.length : 1
      delete this.store[key]
      //logger.info("apiCache:deleteObject:DELETED ========XXXX", key, cachedObjectList, this.objectCount);
    }

    // When we really clean object, we need to delete everything belong to it,
    // we expect new version and it means all collections of this object
    // can be not good
    if (cachedObjectList && !cachedObjectList.cacheData) {
      for (let k in this.store) {
        // We look in every list witht this path inside and clean it.
        if (k.indexOf(key) !== 0) continue
        const cachedObjectList = this.store[k]
        if (cachedObjectList) {
          this.objectCount -= cachedObjectList.cacheData ? cachedObjectList.cacheData.length : 1
          delete this.store[k]
          //logger.info("apiCache:deleteObject ======== NESTED", k, k.indexOf(key), cachedObjectList, this.objectCount);
        }
        //this.printStore(key);
      }
    }

    //logger.info("apiCache:deleteObject ========XXXX", key, cachedObjectList, this.objectCount);
    //logger.info("apiCache:deleteObject:TRACE", Error().stack);
    //this.printStore(key);
  },

  /**
   * Remove all occurrences of the object from cache
   */
  clearObjectCache: function (obj, objKey) {
    //logger.info("apiCache:clearObjectCache ========****", obj, objKey, this.objectCount, Object.keys(this.store).length);
    //logger.info("apiCache:clearObjectCache:TRACE", Error().stack);

    // We look for same object with different version and need clean it too
    // but 2 objects don't have identity: users and settings, lets ignore it
    if (!obj.identity) {
      let key: any = null
      if (obj.object && nonCSCompare(obj.object.type, 'user')) {
        // Use object key if it was specified, or use path.
        key = objKey ? objKey : obj._path
      } else if (obj.object && nonCSCompare(obj.object.type, 'settings')) {
        // Use object key or generate key.
        key = objKey ? objKey : obj.object.parent.name + '/settings'
      }
      //logger.info("apiCache:clearObjectCache:OBJ ========####00", key, this.objectCount, Object.keys(this.store).length);
      if (key) this.deleteObject(key)
      return
    }

    // Look for objets in the cache containing object we want to clear and not same.
    const storeKeys = Object.keys(this.store).map((key) => {
      let cachedObject = this.store[key]
      if (cachedObject.cacheData) {
        let objindex = cachedObject.cacheData.findIndex(
          (cachedListObject) => objCompare(cachedListObject, obj) && !verCompare(cachedListObject, obj)
        )
        if (-1 !== objindex) {
          //logger.info("apiCache:clearObjectCache:LIST ========####11", key, objindex, obj, cachedObject, this.objectCount, Object.keys(this.store).length);
          return key
        }
      } else if (objCompare(cachedObject, obj) && !verCompare(cachedObject, obj)) {
        // We look for same object with different version and need clean it too
        // but 2 objects don't have identity: users and settings, lets ignore it
        //logger.info("apiCache:clearObjectCache:OBJ ========####22", key, obj, cachedObject, this.objectCount, Object.keys(this.store).length);
        return key
      }
      return null
    })

    // Remove entries for compromized key
    //logger.info("apiCache:clearObjectCache:LIST ========####", storeKeys, this.objectCount, Object.keys(this.store).length);
    storeKeys.forEach((key) => {
      if (key) this.deleteObject(key)
    })
  },

  /**
   * Request object list from cache
   * @param {string} key - key of cached object
   * @returns {Object} object list if found and null if not
   */
  getObjectList: function (key) {
    const cachedObjectList = this.store[key]
    //logger.info("apiCache:getObjectList ========>>>>", key, cachedObjectList, this.objectCount, Object.keys(this.store).length);
    //logger.info("apiCache:getObjectList:TRACE", Error().stack);
    //this.printStore(key);

    if (!cachedObjectList) return null

    if (this.isValid(cachedObjectList)) {
      window.requestCount.cached++
      return cachedObjectList.cacheData
    } else {
      this.deleteObject(key)
      return null
    }
  },

  /**
   * Adds object list to cache or replaces existing
   * @param {object} list - object listto store
   * @param {string} key - key in store (object path)
   * @param lifetime - optional lifetime for object (ms)
   */
  setObjectList: function (list, key, lifetime = API_CACHE_LIFETIME) {
    this.store[key] = {
      cacheTimestamp: moment().format(),
      lifetime: lifetime,
      cacheData: list.slice()
    }

    this.objectCount += list.length
    //logger.info("apiCache:setObjectList", key, this.store[key], this.objectCount, Object.keys(this.store).length);
    //this.printStore(key);
  },

  /**
   * Check if entry in store still valid
   * @param {object} obj - object to store
   * @returns {boolean} true if object still valid
   */
  isValid: function (cachedObject) {
    const time = moment(cachedObject.cacheTimestamp)
      .add(cachedObject.lifetime, 'milliseconds')
      .subtract(moment().valueOf(), 'milliseconds')
      .format('x')

    //logger.info("apiCache:isValid????", cachedObject, time);
    // @ts-ignore
    return time > 0
  },

  /**
   * Clear cavhe from invalid entries
   */
  clearExpired: function () {
    //logger.info("apiCache:clearExpired", this.objectCount, Object.keys(this.store).length);
    Object.keys(this.store).forEach((key) => {
      let cachedObjectList = this.store[key]
      if (cachedObjectList && !this.isValid(cachedObjectList)) {
        this.deleteObject(key)
      }
    })
  }
}

// api.res([{
//   org: ['sys', 'ds']
// }, 'app', 'sys', 'ds']);
api.res('users').res('password')
api
  .res('organizations')
  .res('systems')
  .res(['applications', 'deployments', 'topologies', 'environments', 'datasets', 'users'])
api
  .res('organizations')
  .res('systems')
  .res('applications')
  .res('interfaces')
  .res(['versions', 'issues', 'users', 'messages', 'operations'])
api.res('organizations').res('systems').res('applications').res('interfaces').res('issues').res('messages')
api.res('organizations').res('systems').res('applications').res('interfaces').res('versions').res('checkpoints')
api.res('organizations').res('systems').res('applications').res('interfaces').res('checkpoints')
api
  .res('organizations')
  .res('systems')
  .res('applications')
  .res('datasets')
  .res(['fields', 'layouts', 'messages', 'issues'])
api.res('organizations').res('systems').res('applications').res('datasets').res('issues').res('messages')
api.res('organizations').res('systems').res('applications').res('datasets').res('versions').res('checkpoints')
api.res('organizations').res('systems').res('applications').res('datasets').res('checkpoints')
api
  .res('organizations')
  .res('systems')
  .res('applications')
  .res('publications')
  .res(['issues', 'subscriptions', 'users', 'messages', 'versions', 'layouts'])
api.res('organizations').res('systems').res('applications').res('publications').res('issues').res('messages')
api
  .res('organizations')
  .res('systems')
  .res('applications')
  .res('publications')
  .res('versions')
  .res(['layouts', 'checkpoints'])
  api
  .res('organizations')
  .res('systems')
  .res('applications')
  .res('publications')
  .res('checkpoints')
api
  .res('organizations')
  .res('systems')
  .res('applications')
  .res('subscriptions')
  .res(['issues', 'users', 'messages', 'versions'])
api.res('organizations').res('systems').res('applications').res('subscriptions').res('versions').res('checkpoints')
api.res('organizations').res('systems').res('applications').res('subscriptions').res('checkpoints')
api.res('organizations').res('systems').res('applications').res('subscriptions').res('issues').res('messages')
api
  .res('organizations')
  .res('systems')
  .res('applications')
  .res('subscriptions')
  .res('datasets')
  .res('versions')
  .res('checkpoints')
api
  .res('organizations')
  .res('systems')
  .res('applications')
  .res('pipelines')
  .res(['issues', 'users', 'messages', 'versions'])
api.res('organizations').res('systems').res('applications').res('pipelines').res('versions').res('checkpoints')
api.res('organizations').res('systems').res('applications').res('pipelines').res('checkpoints')
api
  .res('organizations')
  .res('systems')
  .res('applications')
  .res('views')
  .res(['versions', 'issues', 'users', 'messages'])
api.res('organizations').res('systems').res('applications').res('views').res('versions').res('checkpoints')
api.res('organizations').res('systems').res('applications').res('views').res('checkpoints')
api.res('organizations').res('systems').res('environments').res(['versions', 'users', 'messages', 'issues'])
api.res('organizations').res('datasets').res(['fields', 'layouts', 'versions', 'messages', 'users', 'issues'])
api.res('organizations').res(['users', 'profiles'])
api.res('organizations').res('issues')
api.res('organizations').res('messages')
api.res('organizations').res('systems').res(['issues', 'messages'])
api.res('organizations').res('systems').res('applications').res(['issues', 'messages'])
api.res('organizations').res('systems').res('applications').res('issues').res('messages')
api.res('organizations').res('systems').res('applications').res('datasets').res(['issues', 'users', 'messages'])
api.res('organizations').res('systems').res('applications').res('datasets').res('issues').res('messages')
api.res('organizations').res('systems').res('applications').res('pipelines').res('issues').res('messages')
api
  .res('organizations')
  .res('systems')
  .res('publications')
  .res(['issues', 'subscriptions', 'users', 'messages', 'versions', 'layouts'])
api.res('organizations').res('systems').res('publications').res('issues').res('messages')
api.res('organizations').res('systems').res('publications').res('versions').res(['layouts', 'checkpoints'])
api.res('organizations').res('systems').res('publications').res('checkpoints')
api.res('organizations').res('systems').res('subscriptions').res(['issues', 'users', 'messages', 'versions'])
api.res('organizations').res('systems').res('subscriptions').res('versions').res('checkpoints')
api.res('organizations').res('systems').res('subscriptions').res('checkpoints')
api.res('organizations').res('systems').res('subscriptions').res('issues').res('messages')
api.res('organizations').res('systems').res('subscriptions').res('datasets').res('versions').res('checkpoints')
api.res('organizations').res('systems').res('subscriptions').res('datasets').res('checkpoints')
api.res('organizations').res('systems').res('settings').res('versions')
api.res('organizations').res('settings')
api.res('organizations').res('systems').res('applications').res('users')
api.res('organizations').res('systems').res('applications').res('settings').res('versions')
api.res('organizations').res('systems').res('topologies').res(['settings', 'issues', 'messages', 'users', 'versions'])
api.res('organizations').res('systems').res('deployments').res(['settings', 'issues', 'messages', 'users', 'versions'])
api.res('organizations').res('systems').res('environments').res(['settings', 'issues', 'messages', 'users', 'versions'])
api.res('resources')
api.res('getcurrentuser')
api.res('register')
api.res('status')

// var dsAPI = appAPI.res('datasets');
// appAPI.res('publications');
// appAPI.res('subscriptions');
// dsAPI.res('fields');
idApi.res('users')
idApi.res('systems').res(['applications', 'settings', 'issues', 'messages', 'users'])
idApi
  .res('applications')
  .res(['datasets', 'publications', 'subscriptions', 'settings', 'issues', 'messages', 'users', 'interfaces'])
idApi.res('datasets').res(['fields', 'layouts', 'issues', 'messages', 'users', 'messages', 'versions'])
idApi.res('applications')
idApi.res('topologies').res(['versions', 'issues', 'messages', 'users'])
idApi.res('deployments').res(['versions', 'issues', 'messages', 'users'])
idApi.res('environments').res(['versions', 'issues', 'messages', 'users'])
idApi.res('pipelines').res('issues')
idApi.res('organizations').res(['systems', 'issues', 'settings', 'messages', 'users'])
idApi.res('publications').res('versions')
idApi.res('subscriptions').res('versions')
idApi.res('issues')
idApi.res('issues').res('messages')
idApi.res('messages')
idApi.res('layouts')
idApi.res('interfaces').res(['issues', 'users', 'versions', 'operations'])
idApi.res('views').res(['issues', 'users', 'messages'])

api.on('request', function (xhr) {
  if (window.AUTH_URL === 'disabled') xhr.setRequestHeader('Authorization', localStorage['currentUserId'])
  else xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage['currentUserToken'])
})
idApi.on('request', function (xhr) {
  if (window.AUTH_URL === 'disabled') xhr.setRequestHeader('Authorization', localStorage['currentUserId'])
  else xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage['currentUserToken'])
})

export function getApiResource(objType) {
  switch (objType) {
    case 'application':
    case 'applications':
      return 'applications'
    case 'dataset':
    case 'datasets':
      return 'datasets'
    case 'deployment':
    case 'deployments':
      return 'deployments'
    case 'environment':
    case 'environments':
      return 'environments'
    case 'field':
    case 'fields':
      return 'fields'
    case 'interface':
    case 'interfaces':
      return 'interfaces'
    case 'issues':
    case 'issue':
      return 'issues'
    case 'layout':
    case 'layouts':
      return 'layouts'
    case 'message':
    case 'messages':
      return 'messages'
    case 'organization':
    case 'organizations':
      return 'organizations'
    case 'pipeline':
    case 'pipelines':
      return 'pipelines'
    case 'publication':
    case 'publications':
      return 'publications'
    case 'subscription':
    case 'subscriptions':
      return 'subscriptions'
    case 'system':
    case 'systems':
      return 'systems'
    case 'topology':
    case 'topologys':
      return 'topologies'
    case 'user':
    case 'users':
      return 'users'
    case 'version':
    case 'versions':
      return 'versions'
    case 'views':
    case 'view':
      return 'views'
    default:
      return ''
  }
}

/**
 * Generate request object from URI of the object in service.
 * @param {string} path - URI of object in service
 * @return {object} - request object we can use in requests to the service
 */
export function getRequestFromPath(path) {
  if (!path) return ''
  //logger.info("getRequestFromPath", path);
  const splitPath = path.split('/')
  let req = api
  let i

  for (i = 1; i < splitPath.length - 1; i += 2) {
    //logger.info("getRequestFromPath", path, splitPath, splitPath[i], typeof req[splitPath[i]]);
    if (splitPath[i] !== 'versions') {
      if (typeof req[splitPath[i]] !== 'function') {
        console.error('getRequestFromPath not found req for', splitPath[i], 'in path', path)
        return false
      }

      req = req[splitPath[i]](splitPath[i + 1])
    }
  }

  if (i < splitPath.length) {
    req = req[splitPath[i]]
    //logger.info("spspsp", splitPath, i, splitPath[i], splitPath[i+1], req.url());
  }

  //logger.info("getRequestFromPath:RETURN", path, req);
  return req
}

/**
 * Generate request for version of the object from URI of the object in service.
 * @param {string} path - URI of object in service
 * @param {boolean} major - to use highest minor version of major version of object
 * @param {boolean} prod - to use production service for data even if you in integration
 * @return {object} - request object we can use in requests to the service
 */
export function getRequestFromPathVersioned(path, major, prod) {
  //logger.info("API:getRequestFromPathVersioned", path, major, prod, api, apiProd);
  const splitPath = path.split('/')
  let req = api //prod ? apiProd : api;
  let i

  for (i = 1; i < splitPath.length - 1; i += 2) {
    //logger.info("API:getRequestFromPath:SPLIT", req, path, splitPath, splitPath[i], typeof req[splitPath[i]]);
    if (typeof req[splitPath[i]] !== 'function') {
      //logger.warn("API:getRequestFromPathVersioned not found req for", splitPath[i], "in path", path);
      return false
    }

    // Path based on version
    if (splitPath[i] === 'versions') {
      req = req[splitPath[i]](major ? splitPath[i + 1].split('.')[0] : splitPath[i + 1])
    } else {
      req = req[splitPath[i]](splitPath[i + 1])
    }
  }

  if (i < splitPath.length) {
    req = req[splitPath[i]]
  }
  //logger.info("API:getRequestFromPathVersioned:RESULT", api, apiProd, req, req.url());

  return req
}

/**
 * Make object ready for store in cache and use by UI after load from service.
 * @param {object} object - load from service
 * @param {string} objectType - type of object to process
 * @return {object} - object prepared for use in UI
 */
export function prepareObjectToStore(obj, objectType, path) {
  if (Array.isArray(obj)) {
    //logger.info("API:prepareObjectListToStore:OBJECT", objList);
    return prepareObjectListToStore(obj, objectType, path)
  }

  if (objectType === 'settings' || objectType === 'setting') return obj

  //logger.info("prepareObjectToStore", obj, objectType);
  if (objectType === 'user') {
    obj._path = path
  } else {
    //logger.info("API:prepareObjectListToStore:PATH", obj, objectType);
    obj._path = getFullUri(Object.assign({}, obj, { type: objectType }))
  }

  if (objectType !== 'issue' && objectType !== 'message') {
    obj.type = objectType
    if (!obj.type) {
      logger.error('Store object without type', obj)
    }
  }

  return obj
}

/**
 * Make object list ready for store in cache and use by UI after load from service.
 * @param {object} object - load from service
 * @param {string} objectType - type of object to process
 * @return {object} - object prepared for use in UI
 */
function prepareObjectListToStore(objList, objectType, path) {
  if (!Array.isArray(objList)) {
    //logger.info("API:prepareObjectListToStore:OBJECT", objList);
    return prepareObjectToStore(objList, objectType, path)
  }

  //logger.info("API:prepareObjectListToStore:ARRAY", objList, objectType, path);
  return objList.map((obj) => {
    if (objectType === 'version') obj._path = path + '/' + versionToStr(obj.version)
    else if (objectType === 'user') obj._path = path + '/' + (obj.user ? obj.user.id : '')
    else if (path) {
      // This logic very important for datasets from subscriptions. You can't based on
      // path of collection request in this case. We need to use same logic we have for
      // objects if it posible. Compatability problem: some time we have type not match objects inside.
      if (obj.identity && obj.object && obj.object.parent && obj.object.parent.name) {
        // This is object. Let's culculate path.
        obj._path = getFullUri(Object.assign({}, obj, { type: objectType }))
      } else {
        // This is case of object user. This is compatability state.
        obj._path = path + '/' + (obj.identity ? obj.identity.name : obj.user ? obj.user.id : '')
      }
    }

    if (objectType !== 'issue' && objectType !== 'message') {
      obj.type = objectType
      if (!obj.type) {
        logger.error('Store object without type', obj)
      }
    }

    return obj
  })
}

/**
 * Make object load into store in cache and use by UI after load from service.
 * @param {string} path - path for the object
 * @param {object} object - load from service
 * @param {string} objectType - type of object to process
 * @param {object} actions - actions to call on load object from service
 * @return {object} - object prepared for use in UI
 */
export function loadObjectToStore(path, obj, objectType, actions) {
  if (Array.isArray(obj)) {
    //logger.info("API:loadObjectToStore:OBJECTLIST", objectType, obj, path);
    return loadObjectListToStore(path, obj, objectType, actions)
  }

  // This is special code to increase lifetime of dataset in organization.
  let lifetime = API_CACHE_LIFETIME
  if (objectType === 'dataset' && path.split('/').length < 5) {
    lifetime = API_ORG_DATASET_CACHE_LIFETIME
  } else if (objectType === 'issue' || objectType === 'message') {
    lifetime = API_ISSUE_CACHE_LIFETIME
  }

  // Execute action for object loading
  if (actions && objectType !== 'setting' && objectType !== 'settings') {
    if (!obj || !obj.identity || !obj.identity.id) {
      return false
    }
    //logger.info("API:loadObjectToStore", objectType, obj, path);
    actions.receiveObject(objectType, obj.identity.id, obj)
  }

  // Register object in cash based on requested path.
  window.apiCache.setObject(obj, path, lifetime)
  let vpath = getFullUriVersioned(obj)
  if (vpath && path !== vpath) {
    // Register object base on object path if it different from query path.
    // This can happen if we arequest object based on partial version
    window.apiCache.setObject(obj, vpath, lifetime)
  }
  //logger.info("API:loadObjectToStore", objectType, path, obj, lifetime);
  //window.apiCache.printStore(path);
  return true
}

/**
 * Make object list load into store in cache and use by UI after load from service.
 * @param {string} key - key for the object
 * @param {object} objList - load from service
 * @param {string} objectType - type of object to process
 * @param {object} actions - actions to call on load object from service
 * @return {object} - object prepared for use in UI
 */
export function loadObjectListToStore(key, objList, objectType, actions) {
  if (!Array.isArray(objList)) {
    //logger.info("API:loadObjectListToStore:OBJECT", objectType, objList, key);
    return loadObjectToStore(key, objList, objectType, actions)
  }

  // This is special code to increase lifetime of dataset in organization.
  let lifetime = API_CACHE_LIFETIME
  if (objectType === 'dataset' && key.split('/').length < 5) {
    lifetime = API_ORG_DATASET_CACHE_LIFETIME
  } else if (objectType === 'issue' || objectType === 'message') {
    lifetime = API_ISSUE_CACHE_LIFETIME
  }

  // Execute action for object list loading
  if (actions) {
    //logger.info("API:loadObjectListToStore", objectType, objList, key);
    actions.receiveObjectList(objectType, objList)
  }

  // Register object in cash based on requested path.
  window.apiCache.setObjectList(objList, key, lifetime)
  //logger.info("API:loadObjectListToStore", objectType, key, objList, lifetime);
  //window.apiCache.printStore(path);

  return true
}

/**
 * Load object from service based on path. We will use cache if allowed and check first
 * if obect exist here. When not found, object will be requested, cahced and put to appState.
 * @param {object} apiEndpointObject
 * @param {string} objectType
 * @param {object} [actions]
 * @param {boolean} [forceApiCall] - ignore cache if true
 * @return {Promise}
 */
export function getObjectNew(apiEndpointObject, objectType?, actions?: Actions | null, forceApiCall?) {
  //logger.info("API:getObjectNew", objectType, apiEndpointObject.url(), actions, forceApiCall);
  //logger.info("API:getObjectNew:TRACE", Error().stack);

  // Check for path
  if (!apiEndpointObject.url()) {
    return new Promise((resolve, reject) => {
      reject(new Error('API:getObjectNew Path not specify in request'))
    })
  }

  // Name of the object.
  const objectPath = apiEndpointObject.url()
  const objectName = objectPath.split('/').pop()

  // Let's check what in the cache first
  const cachedObject = forceApiCall ? false : window.apiCache.getObject(objectPath)
  if (cachedObject) {
    // We already have object and can use it
    return new Promise((resolve) => {
      if (actions && objectType !== 'setting' && objectType !== 'settings') {
        actions.receiveObject(objectType, objectName, cachedObject)
      }
      resolve(cachedObject)
    })
  }

  //logger.info("getObjectNew:NOTINCACHE", objectPath, objectType, apiEndpointObject, forceApiCall);
  //window.apiCache.printStore(objectPath);

  // Let's check if we have it requested already
  const cachedRequest = window.apiCache.getRequest(objectPath)
  if (cachedRequest) {
    //logger.info("getObjectNew:CACHEDREQEST", objectPath, cachedRequest);
    // We already have requested object
    return cachedRequest.promise
  }

  //logger.info("getObjectNew:REQUEST", objectPath, actions);
  return window.apiCache.setRequest(objectPath, {
    function: requestObject,
    endpoint: apiEndpointObject,
    type: objectType,
    name: objectName,
    actions: actions
  })
}

/**
 * Reqest object from service.
 * @param {object} request - requested generated to get object
 * @return {Promise}
 */
export function requestObject(request) {
  const apiEndpointObject = request.endpoint
  const objectType = request.type
  const objectName = request.name
  const objectPath = apiEndpointObject.url()

  const actions = request.actions

  return new Promise((resolve, reject) => {
    checkAndRefreshCognitoToken().then(() => {
      //logger.info("API:requestObject:REQUEST", objectPath, request);
      //window.apiCache.printStore(path);
      window.apiCache.clearExpired()
      window.apiCache.deleteObject(objectPath)

      // We have good token, lets request object
      if (actions) actions.createObject(objectType, objectName)

      apiEndpointObject.get().then(
        (result) => {
          //logger.info("API:requestObject:RECIVED", objectPath, result);
          try {
            if (result !== '') {
              const obj = prepareObjectToStore(result, objectType, objectPath)

              window.requestCount.network++

              //logger.info("API:requestObject:OBJECT", obj, objectType, objectPath);
              if (!loadObjectToStore(objectPath, obj, objectType, actions)) {
                reject(
                  Error(
                    'API:requestObject:IDENTITY No identity.id in received object: ' +
                      JSON.stringify(obj) +
                      ' at uri=' +
                      objectPath
                  )
                )
              }

              window.apiCache.deleteRequest(objectPath, result)
              resolve(result)
            }
          } catch (e) {
            logger.warn('API:requestObject:ERROR', e)
            window.apiCache.deleteRequest(objectPath, null, e)
            reject(e)
          }
        },
        (error) => {
          logger.warn('API:requestObject:REQUESTERROR', error)
          window.apiCache.deleteRequest(objectPath, null, error)
          reject(error)
        }
      )
    })
  })
}

/**
 * Load object from service with connected content
 * @param {object} apiEndpointObject
 * @param {string} objectType
 * @param {object} [actions]
 * @param {boolean} [forceUpdate] - ignore cache if true
 * @param {object} [requestParams] - parameters ehich define what we want from list
 * @return {Promise}
 */
export function getObjectsWithConnected(
  queryBatches: RequestQuery,
  objectType: string,
  objectName: string,
  actions: Actions,
  forceUpdate?
) {
  logger.info('API:getObjectsWithConnected', objectType)
  if (typeof forceUpdate === 'object') {
    throw new Error('Invalid parameter forceUpdate for getObjectsWithConnected')
  }

  actions.clearAppState()

  const getObjectFunction = getObjectNew
  getObjectFunction(queryBatches.objectRequest, objectType, actions, forceUpdate).then(
    (majorObject) => {
      //objectRequestCallback should be run only once, for the major object
      if (queryBatches.objectRequestCallback)
        if (!queryBatches.objectRequestCallback(majorObject, false))
          // if objectRequestCallback returns false, skip all connected requests
          return

      if (queryBatches.connectedObjectsRequests)
        queryBatches.connectedObjectsRequests.forEach((query) => {
          //logger.info("getObjectsWithConnected:Get", query.objectType, query, majorObject);

          if (query.isObjectProperty) {
            getObjectListNew(query.request, query.objectType, actions, forceUpdate, query.requestParameters).then(
              (connectedObjects) => {
                // Need action for prperties to have it managed.
                actions.receiveObjectListIntoProperty(
                  connectedObjects,
                  majorObject,
                  query.majorObjectType,
                  query.propertyName
                )
                //logger.info("getObjectsWithConnected:Get:PROPS", query.objectType, query, majorObject, connectedObjects);

                if (query.connectedObjectsRequestCallback) {
                  query.connectedObjectsRequestCallback(majorObject, connectedObjects)
                }
              },
              () => {
                //logger.info("getObjectsWithConnected:Get:PROPS", query.objectType, query, majorObject);
                if (query.connectedObjectsRequestCallback) {
                  query.connectedObjectsRequestCallback(majorObject, [])
                }
              }
            )
          } else if (query.isObjectSettings) {
            // @ts-ignore
            getObjectNew(query.request, query.objectType, actions, forceUpdate, query.requestParameters).then(
              (connectedObject) => {
                //logger.info("API:getObjectsWithConnected:SETTINGS", objectType, connectedObject, forceUpdate);
                actions.receiveSettingsIntoProperty(
                  connectedObject,
                  majorObject,
                  query.majorObjectType,
                  query.propertyName
                )
              },
              (error) => {
                logger.info('API:getObjectsWithConnected:SETTINGS-ERROR', error)
                //actions.receiveSettingsIntoProperty(query.defaultValue, majorObject, query.majorObjectType, query.propertyName);
              }
            )
          } else {
            if (query.clearList) {
              actions.clearObjectList(query.objectType)
            }

            getObjectListNew(query.request, query.objectType, actions, forceUpdate, query.requestParameters).then(
              (connectedObjects) => {
                if (query.connectedObjectsRequestCallback) {
                  query.connectedObjectsRequestCallback(majorObject, connectedObjects)
                }
                // connectedObjects.forEach(cobj => {
                //   actions.updateParent(query.objectType, cobj.identity.name, majorObject.identity);
                // });
              },
              () => {
                if (query.connectedObjectsRequestCallback) {
                  query.connectedObjectsRequestCallback(majorObject, [])
                }
              }
            )
          }
        })
    },
    (response) => {
      if (queryBatches.objectRequestCallback) {
        queryBatches.objectRequestCallback(createEmptyObject(objectType), response ? response.status : null, response)
      }
    }
  )
}

/**
 * Load object from service based on path. We will use cache if allowed and check first
 * if obect exist here. When not found, object will be requested, cahced and put to appState.
 * @param {object} apiEndpointObject
 * @param {string} objectType
 * @param {object} [actions]
 * @param {boolean} [forceApiCall] - ignore cache if true
 * @param {object} [requestParams] - parameters ehich define what we want from list
 * @return {Promise}
 */
export function getObjectListNew(
  apiEndpointObject,
  objectType,
  actions?: Actions | null,
  forceApiCall?,
  requestParams?
) {
  // logger.info('API:getObjectListNew', objectType, apiEndpointObject, forceApiCall, requestParams)

  // Check for path
  if (!apiEndpointObject.url()) {
    return new Promise((resolve, reject) => {
      reject(new Error('API:getObjectListNew Path not specify in request'))
    })
  }

  // Name of the object.
  const objectPath = apiEndpointObject.url()
  const objectKey = objectPath + (requestParams ? '?' + JSON.stringify(requestParams) : '')

  // Let's check what in the cache first
  const cachedObject = forceApiCall ? false : window.apiCache.getObjectList(objectKey)
  if (cachedObject) {
    // We already have object and can use it
    return new Promise((resolve) => {
      if (actions) {
        actions.receiveObjectList(objectType, cachedObject)
      }
      resolve(cachedObject)
    })
  }

  // Let's check if we have it requested already
  const cachedRequest = window.apiCache.getRequest(objectKey)
  if (cachedRequest) {
    //logger.info("getObjectListNew:CACHEDREQEST", objectKey, cachedRequest);
    // We already have requested object
    return cachedRequest.promise
  }

  //logger.info("getObjectNew:REQUEST", objectPath);
  return window.apiCache.setRequest(objectKey, {
    function: requestObjectList,
    endpoint: apiEndpointObject,
    type: objectType,
    key: objectKey,
    actions: actions,
    params: requestParams
  })
}

/**
 * Reqest object list from service.
 * @param {object} request - requested generated to get object
 * @return {Promise}
 */
export function requestObjectList(request) {
  const apiEndpointObject = request.endpoint
  const objectType = request.type
  const objectPath = apiEndpointObject.url()
  const objectKey = request.key

  const actions = request.actions
  const params = request.params

  return new Promise((resolve, reject) => {
    checkAndRefreshCognitoToken().then(() => {
      //logger.info("API:requestObjectList:REQUEST", objectPath);
      //window.apiCache.printStore(objectKey);
      window.apiCache.clearExpired()
      window.apiCache.deleteObject(objectKey)

      apiEndpointObject.get(params).then(
        (result) => {
          //logger.info("API:requestObjectList:RECIVED", objectPath, result);
          try {
            const obj = prepareObjectListToStore(result || [], objectType, objectPath)

            window.requestCount.network++

            //logger.info("API:requestObjectList:OBJECT", obj, objectType, objectPath);
            if (!loadObjectListToStore(objectKey, obj, objectType, actions)) {
              reject(
                Error(
                  'API:requestObject:IDENTITY No identity.id in received object: ' +
                    JSON.stringify(obj) +
                    ' at uri=' +
                    objectPath
                )
              )
            }

            window.apiCache.deleteRequest(objectKey, result)
            resolve(result)
          } catch (e) {
            //logger.info("API:requestObject:ERROR", e);
            window.apiCache.deleteRequest(objectKey, null, e)
            reject(e)
          }
        },
        (error) => {
          //logger.info("API:requestObject:TOKENERROR", error);
          window.apiCache.deleteRequest(objectKey, null, error)
          reject(error)
        }
      )
    })
  })
}

/**
 * Load objects of specific type and usage. Work asynchronously
 * @param {string} path - path of object to understand application path
 * @param {string} type - type of the objects we are looking for
 * @param {string} usage - usage of the object we are looking for
 * @param {object} actions - actions to use for request
 * @param {boolean} local - include objects only from current application or system
 * @return {Promise}
 */
export function getObjectListByUsage(path, type, usage, actions, local = false) {
  // Find path to use for request
  let org = !local
  const pathSplit = path.substring(1).split('/')
  let appPath = pathByType(pathSplit, 'applications')
  const orgPath = org ? pathByType(pathSplit, 'organizations') : null

  type = type.toLowerCase()

  if (!appPath) {
    const sysPath = pathByType(pathSplit, 'systems')
    if (type !== 'interface') {
      appPath = sysPath
    } else {
      appPath = ''
    }
  }

  // We for now have only datasets in organization.
  if (type !== 'dataset') {
    org = false
  }

  // Generate request to organization and application for required entities
  const appRequest = appPath ? getRequestFromPath(appPath)[getApiResource(type)] : null
  const orgRequest = org ? getRequestFromPath(orgPath)[getApiResource(type)] : null

  // Request parameters for type and usage
  const appQueryParams: any = local ? null : { subscriptions: true }

  if (usage && usage.length > 0) {
    appQueryParams.usage = usage
  }

  //logger.info("API:getObjectListByUsage", { type, usage, appPath, orgPath, appRequest, orgRequest });

  return new Promise((resolve, reject) => {
    Promise.all([
      appRequest ? getObjectListNew(appRequest, type, actions, false, appQueryParams) : null, // force api call
      orgRequest ? getObjectListNew(orgRequest, type, actions, false) : null // force api call
    ]).then(
      (results) => {
        // Merge datasets from organization and application
        let result = []

        // Set result from first dataset
        if (results && results[0]) {
          // we have only app result.
          result = results[0]
        }

        // Add result from second dataset
        if (results && results[1]) {
          // We need to filter usage
          if (usage && usage.length > 0) {
            result = result.concat(results[1].filter((el) => nonCSCompare(el.object.usage, usage)))
          } else {
            result = result.concat(results[1])
          }
        }
        //logger.info("API:getObjectListByUsage:RESULT", type, usage, result, results);
        resolve(result)
      },
      (error) => {
        reject(error)
      }
    )
  })
}

/*
export function sendObject(request, objectType, actions, path) {
  return new Promise((resolve, reject) => {
    request.then(result => {

      window.apiCache.deleteObject(path);

      window.requestCount.network++;

      if (!result) {            // empty response
        resolve(result);
        return;
      }
      if (!isMinorObject(objectType))
        result._path = path + "/" + result.identity.name;
      const name = result.identity ? result.identity.name : result[0].identity.name;

      if (objectType === "issue") {
        result = result.issue;
        resolve(result);
        return;
      }

      if (actions) actions.receiveObject(objectType, name, result);
      resolve(result);
    }, error => {
      // //logger.info("ERROR: sendObject", error, "object:", objectType);
      reject(error);
    });
  });
}
*/

/**
 * Sends object to server and then loads object from server, to keep history updated; adds history.updated to patch requests
 * If error is returned during put/patch or get, restores original object. So you can update the object in AppState before calling sendObjectNew (optimistic approach)
 * @param apiEndpointObject
 * @param method    put or patch
 * @param actions
 * @param fullObject      full object
 * @param objectData  (optional) object part to be sent (in case of PATCH request); if parameter not set, full object will be sent
 * @param skipObjectReload  prevents GetObjectNew request after updating the object
 * @returns {Promise}     resolves to new full object
 */
export function sendObjectNew(
  apiEndpointObject,
  method,
  actions,
  fullObject,
  objectData: any = null,
  skipObjectReload = false
) {
  method = method.toLowerCase()
  if (method !== 'put' && method !== 'patch' && method !== 'post')
    throw new Error('sendObjectNew method not put or patch: ' + method)
  if (!fullObject.type) {
    logger.error('sendObjectNew object without type', fullObject)
    throw new Error('sendObjectNew object without type')
  }
  window.apiCache.clearExpired()
  let path = apiEndpointObject.url ? apiEndpointObject.url() : ''
  window.apiCache.deleteObject(path)
  const lastSlash = path.lastIndexOf('/')
  const listKey = nonCSCompare(method, 'post') ? path : path.substr(0, lastSlash)
  window.apiCache.deleteObject(listKey)
  //logger.log("SendObjectNew:delete", listKey);

  let objectDataUpdated = !objectData
    ? null
    : Object.assign({}, objectData, {
        object: Object.assign({}, objectData.object || {}, {
          history: { updated: fullObject.object.history.updated }
        })
      })
  return new Promise((resolve, reject) => {
    checkAndRefreshCognitoToken().then(() => {
      apiEndpointObject[method](objectDataUpdated || fullObject).then(
        (updateResult) => {
          //window.apiCache.deleteObject(getFullUri(fullObject));
          if (skipObjectReload) {
            resolve(updateResult)
            return
          }

          getObjectNew(apiEndpointObject, fullObject.type, actions, true).then(
            (result) => {
              window.requestCount.network++
              resolve(result)
            },
            (error) => {
              if (actions && fullObject && fullObject.identity)
                actions.receiveObject(fullObject.type, fullObject.identity.name, fullObject)
              reject(error)
            }
          )
        },
        (error) => {
          if (actions && fullObject && fullObject.identity)
            actions.receiveObject(fullObject.type, fullObject.identity.name, fullObject)
          reject(error)
        }
      )
    })
  })
}

export function deleteMajorObject(appState, actions: Actions, objectType, objectName, onError) {
  const majorObject = getObjectByName(appState, objectType, objectName)

  idAPI[getApiResource(objectType)](majorObject.identity.id)
    .delete()
    .then(
      () => {
        window.requestCount.network++
        //logger.info("delete request success, before delete action");
        window.apiCache.clearObjectCache(majorObject)
        actions.deleteMajorObject(objectType, majorObject.identity.id)
      },
      (error) => {
        logger.info('DeleteMajorObject Error', error)
        const errorJson = JSON.parse(error.responseText)
        logger.info('DeleteMajorObject ErrorJ', errorJson)
        if (onError) onError(errorJson)
      }
    )
}

//import md from './md';

/**
 * Sends object to server and then loads object from server, to keep history updated; adds history.updated to patch requests
 * If error is returned during put/patch or get, restores original object. So you can update the object in AppState before calling sendObjectNew (optimistic approach)
 * @param uri - URI for object in difhub, it can be reference to object in field or any other reference to object.
 * @param objectType - type of the object to retrive
 * @param actions - action to register object if requested
 * @param forceApiCall - force call to service
 * @param organization - organization request come from
 * @param production - data request from production service
 * @returns {Promise}     resolves to new full object
 */
export function getObjectNewOrJson(uri, objectType, actions, forceApiCall, organization, production) {
  let isRequestedNotFromApdax = forceApiCall || !uri || !uri.startsWith('/organizations/Apdax')
  let isRequestedByApdax = forceApiCall || !organization || organization === 'Apdax'
  return new Promise((resolve, reject) => {
    let ds = !isRequestedNotFromApdax && !isRequestedByApdax ? findByPath(getRequestFromPath(uri).url()) : null
    //logger.info("API:getObjectOrJson", { uri, objectType, organization, production, forceApiCall, isRequestedNotFromApdax, isRequestedByApdax }, getRequestFromPath(uri).url(), getDatasetMetadata(getRequestFromPath(uri).url()), ds, { apiURL, apiURLProd, idApiURL, api, apiProd, idApi });
    if (ds) {
      // We found object in metadata.
      resolve(ds)
    } else {
      // We will try to load object from service
      getObjectNew(getRequestFromPathVersioned(uri, true, production), objectType, actions, forceApiCall).then(
        (success) => {
          resolve(success)
        },
        (error) => {
          // For Apdax access and not found error let's try metadata any way.
          logger.warn(
            'API:getObjectOrJson:ERROR',
            {
              uri,
              objectType,
              organization,
              production,
              forceApiCall,
              isRequestedNotFromApdax,
              isRequestedByApdax
            },
            getRequestFromPath(uri).url()
          )
          ds = !isRequestedNotFromApdax && isRequestedByApdax ? findByPath(getRequestFromPath(uri).url()) : null
          if (ds) {
            // We found object in metadata.
            resolve(ds)
          } else {
            reject(error)
          }
        }
      )
    }
  })
}

/**
 * Request dataset from organization
 * @param {string} datasetName
 * @param {string} [orgName='Apdax']
 * @return {Promise}
 */
export function getOrganizationDataset(datasetName, orgName = 'Apdax') {
  if (!datasetName)
    return new Promise((resolve, reject) => {
      reject()
    })

  return getObjectNew(API.organizations(orgName).datasets(datasetName), 'dataset')
}

export const API = api
export const idAPI = idApi

/**
 * Same as window.fetch, but adds Authorization: Bearer header with current user token
 * @param url
 * @returns {Promise}
 */
export function authFetch(url) {
  let headers = new Headers()
  headers.append('Authorization', 'Bearer ' + localStorage['currentUserToken'])
  return fetch(url, { headers: headers })
}

/**
 * Loads object using authFetch and converts to data URL; objects are cached using window.apiCache (key is object url, id is object url)
 * @param url
 * @returns {Promise}
 */
export function fetchDataUrl(url) {
  return new Promise((resolve, reject) => {
    // Check if object was already loaded in cache
    let obj = window.apiCache.getObject(url)
    if (obj) {
      resolve(obj.dataUrl)
      return
    }

    // FileReader is used to convert to data url
    let fr = new FileReader()
    fr.onload = () => {
      // save in cache
      window.apiCache.setObject({ identity: { id: url }, dataUrl: fr.result }, url, API_IMAGE_CACHE_LIFETIME)
      resolve(fr.result)
    }
    authFetch(url).then((response) => {
      //logger.info("authFetch",url,response);
      if (response.status === 200) {
        response.blob().then((blob) => {
          fr.readAsDataURL(blob)
        })
      } else {
        window.apiCache.setObject({ identity: { id: url }, dataUrl: '' }, url, API_IMAGE_CACHE_LIFETIME)
        resolve('')
      }
    })
  })
}

/**
 * Returns data url if it was loaded, and empty string if not; initiates loading
 * @param url
 * @param callback      optional function to be called when data url is loaded (and not called if loading was not needed) - put component.forceUpdate() here
 * @returns {string}
 */
export function fetchDataUrlImmediate(url, callback: any = null) {
  return url + (url.indexOf('?') !== -1 ? '&' : '?') + 'token=' + localStorage['currentUserToken']
}

/**
 * Loads url with authorization header and creates temporary <A> element to force download of data url
 * @param filename
 * @param url
 */
export function authDownload(filename, url) {
  fetchDataUrl(url).then((dataUrl) => {
    // Construct the a element
    let link: any = document.createElement('a')
    link.download = filename
    link.target = '_blank'

    // Construct the uri
    link.href = dataUrl
    document.body.appendChild(link)
    link.click()

    // Cleanup the DOM
    document.body.removeChild(link)
  })
}

/**
 * Request dataset or interface by URI
 * @param uri - URI for dataset we want reference to..
 * @param approved - use aproved version.
 * @return true - schema created for new dataset, false - schema already created.
 */
export function requestVersionedObjectByURI(uri, approved = true) {
  //logger.info("API:requestObject", uri, approved);

  let objVersion = objectNameFromPathByType(uri, 'versions')
  let objRequest = getRequestFromPath(uri)
  //console.log("uri", uri, "objVersion", objVersion, "objRequest", objRequest);

  if (!uri) return new Promise((resolve, reject) => reject('requestVersionedObjectByURI: no URI'))

  if (approved === true) {
    let objQueryParams: any = {}
    //logger.info("API:requestObject:APPROVED", uri, approved, objVersion);

    objQueryParams.status = 'approved'
    return objVersion ? objRequest.versions(objVersion).get(objQueryParams) : objRequest.get(objQueryParams)
  }
  return objVersion ? objRequest.versions(objVersion).get() : objRequest.get()
}

/**
 * Normalize names.
 * @param name - name to normilize.
 * @param option - options for normalizations.
 * @return
 */
export function formatObjectName(name, option) {
  if (!option) {
    return name
  } else if (nonCSCompare(option, 'upper')) {
    if (name.charAt(1).toUpperCase() === name.charAt(1)) {
      return (name.charAt(0).toUpperCase() + name.substr(1)).replace(/ /g, '')
    }
  } else {
    // @ts-ignore
    if (nonCSCompare(option) === 'lower') {
      //logger.info("API:formatObjectName:LOWER", name.charAt(0).toLowerCase() + name.substr(1));
      if (name.charAt(1).toLowerCase() === name.charAt(1)) {
        return (name.charAt(0).toLowerCase() + name.substr(1)).replace(/ /g, '')
      }
    }
  }
  return name
}

/**
 * Normalize enum names.
 * @param name - name to normilize.
 * @param option - options for normalizations.
 * @return
 */
export function formatEnumName(name, usage, option) {
  // We format only name and properties not values and code.
  if (
    !option ||
    usage.toLowerCase() === 'value' ||
    usage.toLowerCase() === 'value1' ||
    usage.toLowerCase() === 'value2' ||
    usage.toLowerCase() === 'code'
  ) {
    return name
  } else if (nonCSCompare(option, 'upper')) {
    return name.toUpperCase().replace(/ /g, '_').replace(/,/g, '')
  } else if (nonCSCompare(option, 'lower')) {
    return name.toLowerCase().replace(/ /g, '_').replace(/,/g, '')
  }
  return name
}

export function singularType(pluralType) {
  if (!pluralType) return pluralType

  if (pluralType.substr(pluralType.length - 3, 3) === 'ies') {
    return pluralType.substr(0, pluralType.length - 3) + 'y'
  }

  return pluralType[pluralType.length - 1] === 's' ? pluralType.slice(0, pluralType.length - 1) : pluralType
}
