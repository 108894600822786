import PropTypes from 'prop-types'

import React from 'react'
import logger from '../../helpers/logger'
// import Tabs from 'react-simpletabs'

import { Tabs, TabPanel } from '../ReactSimpletabs'

import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { getRequestFromPath, getObjectListNew } from '../../helpers/api'
import {
  getFullUri,
  singularTypeForms,
  deploymentChildTypes,
  getVersionStatus,
  versionSort,
  listOfMajorVersionsToEnumOptions
} from '../../helpers'
import { deepCopy, nonCSCompare, editableState } from '../../helpers/index'

import { VersionedObjectReferenceList } from './VersionedObjectReferenceList'
import './PackageApplicationDialog.scss'

const ITEM_HEIGHT = 70

export class PackageApplicationDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      tabsLoaded: {},
      loadingChildVersions: {},
      childVersions: {}
    })

    deploymentChildTypes.map((type) => (this.state[type] = []))
  }

  getObjectType() {
    return 'applicationReference'
  }

  getElementsHeight() {
    return {
      __group: {
        datasets: ITEM_HEIGHT,
        interfaces: ITEM_HEIGHT,
        pipelines: ITEM_HEIGHT,
        views: ITEM_HEIGHT,
        publications: ITEM_HEIGHT,
        subscriptions: ITEM_HEIGHT
      }
    }
  }

  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifGate/applications/System/datasets/Package',
      '/organizations/Apdax/systems/DifGate/applications/System/views/PackageApplicationDialog'
    )

    //logger.info("PackageApplicationDialog:open", deploymentChildTypes, this.state, this.props);

    if (this.props.isEditable > editableState.EDITABLE) {
      //deploymentChildTypes.map(type => this.loadDataOptions(type));
    }
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  loadDataOptions = (type) => {
    // Check if we already have it
    if ((this.state[type] && this.state[type].length > 0) || !this.props.applicationReference.reference) {
      return
    }

    const appUri = this.props.applicationReference.reference
    const stype = singularTypeForms.get(type)
    //logger.info("PackageApplicationDialog:loadDataOptions", { type, stype, appUri }, this.state, this.props);

    getObjectListNew(getRequestFromPath(appUri)[type], stype).then(
      (objs) => {
        //logger.info("PackageApplicationDialog:loadDataOptions:OBJECT", { type, stype, appUri, objs }, this.state, this.props);
        let newObjs = deepCopy(objs)
        let promises = []

        const currAppItems = this.state.applicationReference ? this.state.applicationReference[type] || [] : []
        const oldAppItems = this.props.oldApplicationReference ? this.props.oldApplicationReference[type] || [] : []

        if (this.props.onReport) {
          this.props.onReport('Request ' + this.props.type + ' from service...')
        }

        // eslint-disable-next-line array-callback-return
        newObjs.map((tobj) => {
          let currAppItem = currAppItems.find((item) => nonCSCompare(item.identity.name, tobj.identity.name))
          if (!currAppItem) {
            // eslint-disable-next-line array-callback-return
            return
          }
          //logger.info("PackageApplicationDialog:loadDataOptions:VERSIONS", tobj.identity.name, { type, appUri, objs, tobj }, this.state, this.props);

          promises.push(
            getObjectListNew(getRequestFromPath(getFullUri(tobj)).versions, stype).then(
              (result) => {
                // Version options
                let oldAppItem = oldAppItems.find((item) => nonCSCompare(item.identity.name, tobj.identity.name))
                let oldVersion = oldAppItem ? oldAppItem.version : { major: 0, minor: 0, revision: 0 }

                let options = listOfMajorVersionsToEnumOptions(
                  result,
                  (obj) => versionSort(obj.version, oldVersion) < 0 && getVersionStatus(obj) === 'approved',
                  versionSort
                )
                //logger.info("PackageApplicationDialog:loadDataOptions:options", tobj.identity.name, { result, options, tobj, newObjs, oldAppItem }, this.state, this.props);

                tobj['versionOptions'] = options
                return true
              },
              (error) => {
                logger.error('PackageApplicationDialog:open:VERSIONS:ERROR', tobj, error)
                return false
              }
            )
          )
        })

        //logger.info("PackageApplicationDialog:loadDataOptions", promises, this.state, this.props);

        Promise.all(promises).then(
          () => {
            //logger.info("PackageApplicationDialog:loadDataOptions:APPS", { type, appUri, newObjs }, this.state, this.props);
            this.setState({ [type]: newObjs })
            if (this.props.onReport) {
              this.props.onReport()
            }
          },
          (error) => {
            logger.info('PackageApplicationDialog:open:VERSIONS:ERROR', error)
            if (this.props.onReport) {
              this.props.onReport(error)
            }
          }
        )
      },
      (error) => {
        logger.info('PackageApplicationDialog:open:APPS:ERROR', error)
      }
    )

    //logger.info("PackageApplicationDialog:loadDataOptions", appUri, this.state, this.props);
  }

  /**
   * Override the method in child dialogs, to handle changeEditable() event: opening and closing the dialog
   */
  onChangeEditable = (isEditable) => {
    // Lets load common options for edit.
    if (isEditable > editableState.EDITABLE) {
      deploymentChildTypes.map((type) => this.loadDataOptions(type))
    }
  }

  /**
   * Save items changed in list
   */
  saveItems = (title) => (items, closeDialog, onSent) => {
    let newAppRef = deepCopy(this.state.applicationReference)
    logger.log('PackageApplicationDialog:saveItems', newAppRef, items, this.state, this.props)

    newAppRef[title] = items

    this.Change(newAppRef)
    if (onSent) onSent(closeDialog)
  }

  renderEditor = (editState) => {
    const app = this.state.applicationReference
    const dialog = this.getDialogRef()
    const oldApp = this.props.oldApplicationReference
    //logger.info("PackageApplicationDialog:renderEditorList", { app, oldApp, deploymentChildTypes }, this.state, this.props);

    return (
      <div className="PackageApplicationDialog" onClick={() => this.onCheckDialogHeight('.PackageApplicationDialog')}>
        <Tabs className="tabs-list">
          {deploymentChildTypes.map((title, key) => {
            return (
              <TabPanel title={title} key={key}>
                <div className="PackageApplicationDialog">
                  <VersionedObjectReferenceList
                    appState={this.props.appState}
                    actions={this.props.actions}
                    isVisible
                    isEditable={editState >= editableState.EDITABLE ? editState : editableState.BROWSABLE}
                    isInstance={this.props.isInstance}
                    majorObject={this.props.majorObject}
                    oldDeployment={this.props.oldDeployment}
                    type={title}
                    items={app[title]}
                    oldItems={oldApp ? oldApp[title] : []}
                    base={this.state[title]}
                    onReport={this.onReport}
                    onAdjust={(w, h) => this.onAdjust(w, h, '.PackageApplicationDialog')}
                    onSave={this.saveItems(title)}
                    isFullscreen={dialog && dialog.state ? dialog.state.isFullscreen : false}
                  />
                </div>
              </TabPanel>
            )
          })}
        </Tabs>
      </div>
    )
  }
}

PackageApplicationDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number, // State of editing in dialog
  isInstance: PropTypes.boolean, // Mark of editing only layout instance from environment
  majorObject: PropTypes.object.isRequired, // Major object we work against
  applicationReference: PropTypes.object, // Resource object
  oldApplicationReference: PropTypes.object // Resource old object
}
