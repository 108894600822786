import PropTypes from 'prop-types'
import React, { Component } from 'react'
import iArrowDownClose from '../../resources/images/arrow-down-close.png'
// import '../../styles/TreeBlock/TreeBlock.scss';
import '../../styles/ApplicationBlock/ApplicationBlock.scss'
import '../../styles/CollapsableList/CollapsableList.scss'
import '../../styles/TreeElementsList/TreeElementsList.scss'
import { Loader } from '../Loader/Loader'

export class CollapsableList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: props.neverCollapse ? false : true
      //fullCollapsed: false
      //sortOrder: 'asc'
    }
  }

  sortAsc(a, b) {
    return a.title > b.title ? 1 : a.title < b.title ? -1 : 0
  }
  sortDesc(a, b) {
    return a.title < b.title ? 1 : a.title > b.title ? -1 : 0
  }

  setSort(event) {
    this.setState({ sortOrder: event.target.value })
  }
  showMore() {
    this.setState({ collapsed: false, fullCollapsed: false })
  }
  showLess() {
    this.setState({ collapsed: false, fullCollapsed: true })
  }
  displayedItems() {
    let count = this.props.items.length
    if (!this.props.neverCollapse) {
      if (this.state.collapsed) count = 3
    }
    if (this.props.fullCollapsed) count = 0
    let sortFunc =
      this.props.sortOrder === 'asc' ? this.sortAsc : this.props.sortOrder === 'desc' ? this.sortDesc : null
    //.sort(sortFunc)
    let res = this.props.items.slice(0, count)
    if (sortFunc) return res.sort(sortFunc)
    else return res
  }
  showMoreVisible() {
    if (this.props.neverCollapse) return 0
    if (
      (this.state.collapsed && this.props.items.length > 3) ||
      (this.props.fullCollapsed && this.props.items.length > 0)
    )
      return 1
    else return 0
  }

  renderTreeElementsList(elements, totalNum, className) {
    //////console.log("renderTreeElementsList", elements, totalNum, className);
    return totalNum ? (
      <div
        className={
          'TreeCol TreeElementsList ' +
          (elements.length === 0 ? 'leftTreeClearLine ' : '') +
          (className ? ' ' + className : '')
        }
      >
        <div className="row TreeElementsList__control"></div>
        {elements.map((el, index) => {
          const isLast = index === elements.length - 1
          return this.renderTreeEl(
            el,
            el.body.length,
            isLast,
            index,
            className ? className.substr(0, className.length - 4) + 'Block' : false
          )
        })}
      </div>
    ) : (
      <div className="TreeCol TreeElementsList TreeElementsList__empty">
        {!this.props.loadFinished ? (
          <div className="loader_center">
            <Loader />
          </div>
        ) : null}
      </div>
    )
  }

  renderTreeEl(el, isLeaf, isLast, index, className) {
    //////console.log(el.label, 'body', el.body, el.body.length, isLeaf, el.label.type.name, 'props', el.body.props, this.props.className);
    return (
      <div className="TreeCol_double TreeElement" key={index}>
        <div className="row">
          <div
            className={
              'TreeCol ' +
              (this.props.isTopLevel ? '' : ' leftTreeLine ') +
              (isLast && !this.showMoreVisible() ? ' leftTreeLineLast ' : '') +
              (!isLeaf ? '_double' : null)
            }
          >
            <div className={'TreeCol' + (!isLeaf ? '_double' : null)}>
              <div
                className={
                  'TreeCol ' +
                  (el.body !== '' && el.body.props && el.body.props.items.length > 0 ? 'rightTreeLine' : '')
                }
              >
                {el.label}
              </div>
              {el.body ? <div className="TreeCol">{el.body}</div> : null}
            </div>
          </div>
        </div>
        {this.showMoreVisible() && isLast ? (
          <div className="TreeCol leftTreeLine leftTreeLineLast">
            <div
              onClick={this.showMore.bind(this)}
              className={
                'CollapsableList__label CollapsableList__more MajorObjectBlock MajorObjectBlock__more' +
                (className ? ' ' + className : '') +
                (el.label.props.className
                  ? ' ' + el.label.props.className + ' ' + el.label.props.className + '__more'
                  : ' PublicationBlock PublicationBlock__more')
              }
            >
              <span>
                <img src={iArrowDownClose} alt="icon" />
              </span>
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  render() {
    return (
      <div className="CollapsableList" key={this.props.index}>
        {this.renderTreeElementsList(this.displayedItems(), this.props.items.length, this.props.className)}
      </div>
    )
  }
}

CollapsableList.propTypes = {
  items: PropTypes.array,
  isTopLevel: PropTypes.bool,
  className: PropTypes.string,
  index: PropTypes.number,
  neverCollapse: PropTypes.bool,
  loadFinished: PropTypes.bool,
  sortOrder: PropTypes.string,
  fullCollapsed: PropTypes.bool
}
