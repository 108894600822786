/**
 * Created by sashab on 03.07.2020.
 *
 * Enviroment implementation. Envoroment represent
 * State of topologies and deployment in specific environment.
 */
import React, { RefObject } from 'react'

import { MajorObjectVersioned } from '../MajorObjectVersioned/MajorObjectVersioned'
import { ObjectHistoryTable } from '../ObjectHistoryTable/ObjectHistoryTable'
import FilteredTabbedTable from '../FilteredTabbedTable/FilteredTabbedTable'
import { PackageApplicationDialog } from '../Deployment/PackageApplicationDialog'
import { Messages } from '../Messages/Messages'
import {
  API,
  idAPI,
  getObjectNew,
  getObjectsWithConnected,
  sendObjectNew,
  getRequestFromPath,
  singularType
} from '../../helpers/api'
import {
  nonCSCompare,
  identitySort,
  pluralTypeForms,
  editableState,
  deploymentChildTypes,
  deepCopy,
  versionCompare
} from '../../helpers/index'
import { columnsToType, isDark } from '../../helpers'
import { getObjectByName } from '../../helpers/data'
import { TopologyDialog } from './TopologyDialog'
import { DeploymentDialog } from './DeploymentDialog'
import { EnvironmentChart } from './EnvironmentChart'

import './Environment.scss'
import logger from '../../helpers/logger'
import { getTailButtonLabel } from '../../helpers/helperComponents'
import { Tab } from '../FilteredTabbedTable/TabType'

export class Environment extends MajorObjectVersioned {
  filteredtabbedtableRef: RefObject<any>

  constructor(props) {
    super(props)

    // @ts-ignore
    this.state = {
      editingChild: null,
      editingChildMode: editableState.EDITING,
      editingChildType: '',
      topologies: [],
      deployments: [],
      applications: [],
      childrenLoaded: false
    }

    // @ts-ignore
    this.state.objectType = 'environment'
    // @ts-ignore
    this.state.objectName = this.getObjectName(this.state.objectType)
    // @ts-ignore
    this.state.showChart = false

    this.filteredtabbedtableRef = React.createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!this.props.userState.profile && props.userState.profile) {
      this.loadUserRole(props)
    }

    if (!props.majorObject && this.props.majorObject && this.props.majorObject.identity.id !== 0) {
      //console.log("Publication::componentWillReceiveProps new request", props.majorObject, this.props.majorObject);

      const newState = {
        objectType: 'environment',
        objectName: props.match.params[this.getObjectType(props) + 'Name'],
        recordsToDelete: null
      }

      this.clearTabsContentLoaded([]) // show Loader for object table

      this.setState(newState, () => {
        getObjectsWithConnected(
          this.getDataRequestQuery(props),
          this.state.objectType,
          this.state.objectName,
          this.props.actions
        )
      })
    }
  }

  componentDidMount() {
    const env = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    if (env && env.identity.id) {
      this.props.actions.updateMajorObject(env.identity.id, pluralTypeForms.get(this.state.objectType), {
        isFetching: true
      })
    }

    getObjectsWithConnected(
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  getDataRequestQuery(props) {
    return {
      endpoint: API.organizations(props.match.params.organizationName)
        .systems(props.match.params.systemName)
        .environments(props.match.params.environmentName),
      objectRequest: API.organizations(props.match.params.organizationName)
        .systems(props.match.params.systemName)
        .environments(props.match.params.environmentName),
      objectRequestCallback: (obj, errorStatus) => {
        this.setState({ errorStatus: errorStatus })
        return true
      },
      connectedObjectsRequests: [
        {
          objectType: 'version',
          isObjectProperty: true,
          majorObjectType: 'environment',
          propertyName: 'versions',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .environments(props.match.params.environmentName)
            .versions(),
          connectedObjectsRequestCallback: () => {
            if (this.state.loadingCheckpoints) {
              this.requestCheckpoints().then(() => {
                this.setState({ loadingCheckpoints: false })
              })
            }
          }
        },

        {
          objectType: 'user',
          majorObjectType: 'environment',
          isObjectProperty: true,
          propertyName: 'users',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .environments(props.match.params.environmentName)
            .users()
        }
      ]
    }
  }

  expandAllStructure = () => {
    if (this.filteredtabbedtableRef.current && this.filteredtabbedtableRef.current.refs.table_definitions) {
      this.filteredtabbedtableRef.current.refs.table_definitions.toggleAllRows()
      setTimeout(() => {
        this.forceUpdate()
      }, 0)
    }
  }

  isExpandAllStructure = () => {
    if (this.filteredtabbedtableRef.current && this.filteredtabbedtableRef.current.refs.table_definitions) {
      const rows = this.filteredtabbedtableRef.current.refs.table_definitions.state.expandedRows
      //console.log("Dataset:isExpandAllStructure", rows.length, this.filteredtabbedtableRef.current.refs.table_definitions.props.data.length, rows.reduce((all,cur) => all && cur, true) );
      if (
        rows.length === this.filteredtabbedtableRef.current.refs.table_definitions.props.data.length &&
        rows.reduce((all, cur) => all && cur, true)
      )
        return true
      else return false
    } else return false
  }

  toggleExpandTexts = () => {
    this.setState({ expandTexts: !this.state.expandTexts })
  }

  renderTextColumnHeader() {
    return (
      <span>
        Text&nbsp;
        <a
          className={
            'MajorObject__expandButton ' +
            (isDark(this.state.objectType) ? ' MajorObject__expandButton__dark ' : '') +
            (this.state.expandTexts ? 'MajorObject__expandButton__active' : '')
          }
          onClick={this.toggleExpandTexts}
        ></a>
      </span>
    )
  }

  getTextType = () => {
    return { name: this.state.expandTexts ? 'text_expanded' : 'text' }
  }

  getObjectStatus() {
    const env = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const objectHistory = env.object ? env.object.history : false

    if (!env.object || !objectHistory) return false

    if (objectHistory.approved) return 'approved'
    else if (objectHistory.finalized) return 'finalized'
    else return 'draft'
  }

  getTabsProps = (): Tab[] => {
    const isEditable = this.checkEditable()

    return [
      {
        title: 'topologies',
        filters: [
          { name: '', width: 60 },
          { name: 'search', width: 350 }
        ],
        columns: [
          {
            name: 'name',
            displayName: 'Topology Name',
            type: columnsToType.getType('minorObjectName'),
            width: 258,
            onCellClick: (value) => {
              console.log('Environment:getTabsProps:CLICK', value)
              this.setState({
                editingChild: true,
                editingChildType: 'topology',
                activeMinorObject: this.getTopology(value.identity.name),
                activeMinorObjectEditable: this.checkEditable(),
                childInEditMode: false
              })
            }
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 350
          },
          {
            name: 'version',
            type: columnsToType.getType('version'),
            width: 70
          },
          {
            name: 'instances',
            type: columnsToType.getType('string'),
            width: 500
          }
        ],
        tailButtons: !isEditable
          ? []
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (obj) => {
                  logger.info('Environment:getTabsProps:EDIT', obj)
                  this.setState({
                    editingChild: true,
                    editingChildType: 'topology',
                    activeMinorObject: this.getTopology(obj.identity.name),
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: this.checkEditable()
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (obj) => {
                  //this.deleteElement(obj.identity.id)
                  console.log('Delete', obj)
                  this.showDeleteTopology(obj.identity.id, obj.identity.name)
                }
              }
            ],
        contextMenuButtons: [
          {
            label: 'Copy topology',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              // console.log("Dataset::Copy field", data);
              const topology = this.getTopology(data.identity.name)
              localStorage.clipboard = JSON.stringify(topology)
              localStorage.clipboardType = 'topology'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste topology',
                  data: { action: 'paste' },
                  onClick: () => {
                    if (!localStorage.clipboard) {
                      alert('No topology copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'topology') {
                      alert('No topology copied')
                      return
                    }

                    this.setState({
                      pastingChild: true,
                      pastingChildName: JSON.parse(localStorage.clipboard).identity.name
                    })
                  },
                  //@ts-ignore
                  showInBottom: true
                }
              ]
        ),
        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () =>
                  this.setState({
                    editingChild: true,
                    activeMinorObject: this.createTopology(),
                    editingChildType: 'topology',
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: true
                  })
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add topology',
                onClick: () =>
                  this.setState({
                    editingChild: true,
                    activeMinorObject: this.createTopology(),
                    editingChildType: 'topology',
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: true
                  })
              }
            ]
      },
      {
        title: 'deployments',
        filters: [
          { name: '', width: 60 },
          { name: 'search', width: 350 }
        ],
        columns: [
          {
            name: 'name',
            displayName: 'Deployment Name',
            type: columnsToType.getType('minorObjectName'),
            width: 258,
            onCellClick: (value) => {
              this.setState({
                editingChild: true,
                editingChildType: 'deployment',
                activeMinorObject: this.getDeployment(value.identity.name),
                activeMinorObjectEditable: this.checkEditable(),
                childInEditMode: false
              })
            }
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 350
          },
          {
            name: 'version',
            type: columnsToType.getType('version'),
            width: 70
          },
          {
            name: 'packages',
            type: columnsToType.getType('string'),
            width: 500
          }
        ],
        tailButtons: !isEditable
          ? []
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (obj) => {
                  console.log('Environment:getTabsProps:EDIT', obj)
                  this.setState({
                    editingChild: true,
                    editingChildType: 'deployment',
                    activeMinorObject: this.getDeployment(obj.identity.name),
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: this.checkEditable()
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (obj) => {
                  //this.deleteElement(obj.identity.id)
                  console.log('Delete', obj)
                  this.showDeleteDeployment(obj.identity.id, obj.identity.name)
                }
              }
            ],
        contextMenuButtons: [
          {
            label: 'Copy deployment',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              // console.log("Dataset::Copy field", data);
              const deploynment = this.getDeployment(data.identity.name)
              localStorage.clipboard = JSON.stringify(deploynment)
              localStorage.clipboardType = 'deployment'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste deployment',
                  data: { action: 'paste' },
                  onClick: () => {
                    if (!localStorage.clipboard) {
                      alert('No deployment copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'deployment') {
                      alert('No deployment copied')
                      return
                    }

                    this.setState({
                      pastingChild: true,
                      pastingChildName: JSON.parse(localStorage.clipboard).identity.name
                    })
                  },
                  //@ts-ignore
                  showInBottom: true
                }
              ]
        ),
        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () =>
                  this.setState({
                    editingChild: true,
                    activeMinorObject: this.createDeployment(),
                    editingChildType: 'deployment',
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: true
                  })
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add deployment',
                onClick: () =>
                  this.setState({
                    editingChild: true,
                    activeMinorObject: this.createDeployment(),
                    editingChildType: 'deployment',
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: true
                  })
              }
            ]
      },
      {
        title: 'applications',
        filters: [
          { name: '', width: 60 },
          { name: 'search', width: 350 }
        ],
        columns: [
          {
            name: 'name',
            displayName: 'Application Name',
            type: columnsToType.getType('minorObjectName'),
            width: 258,
            onCellClick: (value) => {
              this.setState({
                editingChild: true,
                editingChildType: 'application',
                activeMinorObject: this.getApplication(value.identity.name),
                activeMinorObjectEditable: this.checkEditable(),
                childInEditMode: false
              })
            }
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 350
          },
          {
            name: 'version',
            type: columnsToType.getType('version'),
            width: 70
          },
          {
            name: 'mapped',
            displayName: 'Map',
            type: columnsToType.getType('visibility'),
            width: 40
          },
          {
            name: 'elements',
            type: columnsToType.getType('string'),
            width: 460
          }
        ],
        tailButtons: !isEditable
          ? []
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (obj) => {
                  console.log('Environment:getTabsProps:EDIT', obj)
                  this.setState({
                    editingChild: true,
                    editingChildType: 'application',
                    activeMinorObject: this.getApplication(obj.identity.name),
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: this.checkEditable()
                  })
                }
              }
            ]
      },
      {
        title: 'history',
        filters: [],
        columns: [],
        tableComponent: ObjectHistoryTable,
        parentObject: this.getObject()
      }
    ]
  }

  getData = () => {
    const env = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    //console.log("Pipeline:getData", pipeline, this.props);

    return this.getTabsProps().map((block) => {
      block.data = []

      switch (block.title) {
        case 'topologies':
          block.data = env.topologies
            ? env.topologies.map((top) => ({
                ...top,
                name: {
                  name: top.identity.name,
                  objectType: 'topology',
                  path: top.path
                },
                description: top.identity.description,
                instances: top.instanceNames ? top.instanceNames.join(', ') : ''
              }))
            : []
          block.data.sort(identitySort)
          //console.log("Deployment:getData:PACKAGES", block);

          return block
        case 'deployments':
          block.data = env.deployments
            ? env.deployments.map((top) => ({
                ...top,
                name: {
                  name: top.identity.name,
                  objectType: 'deployment',
                  path: top.path
                },
                description: top.identity.description,
                packages: top.packageNames ? top.packageNames.join(', ') : ''
              }))
            : []
          block.data.sort(identitySort)

          //console.log("Deployment:getData:PACKAGES", block);

          return block
        case 'applications':
          block.data = env.applications
            ? env.applications.map((app) => ({
                ...app,
                name: {
                  name: app.identity.name,
                  objectType: 'deployment',
                  path: app.path
                },
                description: app.identity.description,
                mapped: this.isApplicationMapped(app),
                elements: this.getApplicationElements(app).join(', ')
              }))
            : []
          block.data.sort(identitySort)

          //console.log("Deployment:getData:PACKAGES", block);

          return block

        default:
          return block
      }
    })
  }

  /**
   * Get topology name from environment
   * @param {string} topologyName - name of the topology we looking for
   * @return {object} topology
   */
  getTopology(topologyName) {
    const env = this.getObject()

    return env.topologies.find((f) => nonCSCompare(f.identity.name, topologyName))
  }

  /**
   * Create new topology for editing
   * @return {object} empty element
   */
  createTopology = () => {
    return { identity: { name: '' }, version: {} }
  }

  onSaveTopology = (topology, closeDialog, onSent) => {
    const env = this.getObject()

    if (this.state.activeMinorObject && env.topologies) {
      const newTopologies = env.topologies
        .filter((top) => !nonCSCompare(top.identity.name, topology.identity.name))
        .concat(topology)
      const newEnvironment = { ...env, topologies: newTopologies }

      this.sendEnvironment(newEnvironment, closeDialog, onSent)
    } else {
      const newEnvironment = Object.assign({}, { identity: this.getObject().identity, topologies: [topology] })

      this.saveEnvironment(newEnvironment, closeDialog, onSent)
    }
  }

  onSaveDeployment = (deployment, closeDialog, onSent) => {
    const env = this.getObject()

    if (this.state.activeMinorObject && env.deployments) {
      const newDeployments = env.deployments
        .filter((top) => !nonCSCompare(top.identity.name, deployment.identity.name))
        .concat(deployment)
      const newEnvironment = { ...env, deployments: newDeployments }

      this.sendEnvironment(newEnvironment, closeDialog, onSent)
    } else {
      const newEnvironment = Object.assign({}, { identity: this.getObject().identity, deployments: [deployment] })

      this.saveEnvironment(newEnvironment, closeDialog, onSent)
    }
  }

  /**
   * Delete Topology
   * @param {string} id - id of the instance we looking for
   * @param {string} name - name of the instance we looking for
   * @return {object} empty element
   */
  deleteTopology = (id, name = '') => {
    const newEnv = Object.assign({}, this.getObject())

    const oldTopologies = this.getObject().topologies
    if (id) {
      newEnv.topologies = oldTopologies.filter((inst) => inst.identity.id !== id)
    } else if (name) {
      newEnv.topologies = oldTopologies.filter((inst) => !nonCSCompare(inst.identity.name, name))
    }
    console.log('Environment:deleteTopology', id, name, oldTopologies, newEnv.topologies)

    this.sendEnvironment(newEnv, null, null)
  }

  /**
   * Show topology delete dialog
   * @param {string} id - id of the instance we looking for
   * @param {string} elementName - name of the instance we looking for
   * @return {object} instance
   */
  showDeleteTopology = (id, elementName) => {
    this.setState({
      deleteChildObject: {
        objectType: 'topology',
        obj: { identity: { name: elementName } },
        delete: () => {
          this.setState({ deleteChildObject: false })
          this.deleteTopology(id, elementName)
        }
      }
    })
  }

  /**
   * Get deployment name from environment
   * @param {string} deploymentName - name of the topology we looking for
   * @return {object} topology
   */
  getDeployment(deploymentName) {
    const env = this.getObject()

    return env.deployments.find((f) => nonCSCompare(f.identity.name, deploymentName))
  }

  /**
   * Create new deployment for editing
   * @return {object} empty element
   */
  createDeployment = () => {
    return { identity: { name: '' }, version: {} }
  }

  /**
   * Delete deployment
   * @param {string} id - id of the instance we looking for
   * @param {string} name - name of the instance we looking for
   * @return {object} empty element
   */
  deleteDeployment = (id, name = '') => {
    const newEnv = Object.assign({}, this.getObject())

    const oldDeployments = this.getObject().deployments
    if (id) {
      newEnv.deployments = oldDeployments.filter((inst) => inst.identity.id !== id)
    } else if (name) {
      newEnv.deployments = oldDeployments.filter((inst) => !nonCSCompare(inst.identity.name, name))
    }
    console.log('Environment:deleteDeployment', id, name, oldDeployments, newEnv.deployments)

    this.sendEnvironment(newEnv, null, null)
  }

  /**
   * Show deployment delete dialog
   * @param {string} id - id of the instance we looking for
   * @param {string} elementName - name of the instance we looking for
   * @return {object} instance
   */
  showDeleteDeployment = (id, elementName) => {
    this.setState({
      deleteChildObject: {
        objectType: 'deployment',
        obj: { identity: { name: elementName } },
        delete: () => {
          this.setState({ deleteChildObject: false })
          this.deleteDeployment(id, elementName)
        }
      }
    })
  }

  /**
   * Get deployment name from environment
   * @param {string} applicationName - name of the app we looking for
   * @return {object} topology
   */
  getApplication(applicationName) {
    const env = this.getObject()

    return env.applications.find((f) => nonCSCompare(f.identity.name, applicationName))
  }

  /**
   * Close package  dialog
   * @return
   */
  closeEditDialog = () => {
    this.setState(
      {
        editingChild: false,
        editingChildType: '',
        activeMinorObject: null
      },
      () => {}
    )
  }

  /**
   * Load data and create map.
   * @return premis to wait got load
   */
  loadAllChildren = () => {
    let promises: Promise<any>[] = []
    let childPromises: Promise<any>[] = []

    const env = this.getObject()

    logger.log('Environment::loadAllChildren', env)
    ;(env.topologies || []).forEach((topologyReference) => {
      promises.push(
        new Promise<void>((resolve, reject) => {
          getObjectNew(getRequestFromPath(topologyReference.reference), 'topology').then((topology) => {
            this.setState(
              {
                topologies: Object.assign({}, this.state.topologies, {
                  [topologyReference.reference]: topology
                })
              },
              resolve
            )
          }, reject)
        })
      )
    })
    ;(env.deployments || []).forEach((deploymentReference) => {
      promises.push(
        new Promise<void>((resolve, reject) => {
          logger.log('Environment:loadAllChildren:DEPLOYMENT', deploymentReference, this.state)
          getObjectNew(getRequestFromPath(deploymentReference.reference), 'deployment').then((deployment) => {
            this.setState(
              {
                deployments: Object.assign({}, this.state.deployments, {
                  [deploymentReference.reference]: deployment
                })
              },
              resolve
            )
          }, reject)
        })
      )
    })

    Promise.all(promises).then(() => {
      logger.log('Environment:loadAllChildren:NOCHILDLOADED--->>', childPromises, this.state)
      // load applications and child objects
      ;(env.topologies || []).forEach((topologyReference) => {
        ;(topologyReference.instanceNames || []).forEach((instanceName) => {
          ;(env.deployments || []).forEach((deploymentReference) => {
            const deployment = this.state.deployments[deploymentReference.reference]
            ;(deployment.packages || []).forEach((packageData) => {
              packageData.applications.forEach((appRef) => {
                //logger.log("Appref", appRef.reference);
                childPromises.push(
                  new Promise<void | null>((res, rej) => {
                    getObjectNew(getRequestFromPath(appRef.reference), 'application').then(
                      (app) => {
                        this.setState(
                          {
                            applications: Object.assign({}, this.state.applications, { [appRef.reference]: app })
                          },
                          res
                        )
                      },
                      () => res(null)
                    )
                  })
                )
                deploymentChildTypes.forEach((type) => {
                  ;(appRef[type] || []).forEach((childRef) => {
                    //logger.log("childRef", childRef.reference, type);
                    if (childRef.reference)
                      childPromises.push(
                        new Promise((res, rej) => {
                          getObjectNew(getRequestFromPath(childRef.reference), singularType(type)).then(
                            (child) => {
                              this.setState(
                                // @ts-ignore
                                {
                                  [type]: Object.assign({}, this.state[type], {
                                    [childRef.reference]: child
                                  })
                                },
                                res
                              )
                            },
                            () => res(null)
                          )
                        })
                      )
                  })
                })
              })
            })
          })
        })
      })

      logger.log('Environment:loadAllChildren:NOCHILDLOADED---', childPromises, this.state)
      if (childPromises.length === 0) {
        logger.log('Environment:loadAllChildren:NOCHILDLOADED', this.state)

        return new Promise((resolve) => {
          resolve(true)
        })
      }
    })
    Promise.all(childPromises).then(() => {
      this.setState({
        childrenLoaded: true
      })

      logger.log('Environment:loadAllChildren:CHILDLOADED', this.state)

      return new Promise((resolve) => {
        resolve(true)
      })
    })
  }

  /**
   * Load data of environment components.
   * @return premis to wait got load
   */
  loadEnvironment = (env) => {
    let promises: Promise<any>[] = []
    logger.log('Environment::loadEnvironment', env)
    ;(env.topologies || []).forEach((topologyReference) => {
      promises.push(
        new Promise<void>((resolve, reject) => {
          getObjectNew(getRequestFromPath(topologyReference.reference), 'topology').then((topology) => {
            this.setState(
              {
                topologies: Object.assign({}, this.state.topologies, {
                  [topologyReference.reference]: topology
                })
              },
              resolve
            )
          }, reject)
        })
      )
    })
    ;(env.deployments || []).forEach((deploymentReference) => {
      promises.push(
        new Promise<void>((resolve, reject) => {
          logger.log('Environment:loadEnvironment:DEPLOYMENT', deploymentReference, this.state)
          getObjectNew(getRequestFromPath(deploymentReference.reference), 'deployment').then((deployment) => {
            this.setState(
              {
                deployments: Object.assign({}, this.state.deployments, {
                  [deploymentReference.reference]: deployment
                })
              },
              resolve
            )
          }, reject)
        })
      )
    })

    return new Promise((resolve, reject) => {
      Promise.all(promises).then(
        () => {
          logger.log('Environment:loadEnvironment:LOADED', promises, this.state)

          resolve(true)
        },
        (error) => {
          logger.log('Environment:loadEnvironment:ERROR', promises, error, this.state)
          reject(error)
        }
      )
    })
  }

  // Map topology instance for layout
  mapInstance = (env, layoutInstance) => {
    if (!layoutInstance.platform || !layoutInstance.unit) {
      // Not enogth info to map
      logger.warn('Environment:autoMap:BAD-INSTANCE', { env, layoutInstance }, this.state)
      return false
    }

    for (let top of env.topologies) {
      if (!top.reference) {
        // Topology not in good state. It don't reference real topolofy.
        logger.warn('Environment:autoMap:NO-TOPOLOGY-REFRENCE', { env, top }, this.state)
      }

      const topology = this.state.topologies[top.reference]
      if (!topology) {
        logger.warn('Environment:autoMap:NO-TOPOLOGY', { env, top, topology }, this.state)
        return
      }
      //logger.log("Environment:autoMap:TOPOLOGY", { env, layoutInstance, top, topology }, this.state);

      for (let instanceName of top.instanceNames) {
        // Find instance in topology
        const instance = topology.instances.find((inst) => nonCSCompare(inst.identity.name, instanceName))
        //logger.log("Environment:autoMap:INSTANCE", instanceName, topology, instance, layoutInstance, instance.platform, layoutInstance.platform);

        if (!instance || !nonCSCompare(instance.platform, layoutInstance.platform)) {
          // Instance incorrect or platform don't match.
          continue
        }
        //logger.log("Environment:autoMap:INSTANCE-UNITS", instance.units, layoutInstance.unit);

        const units =
          typeof instance.units === 'string' ? instance.units.split(',').map((unit) => unit.trim()) : instance.units
        let unit = units.find((item) => nonCSCompare(item, layoutInstance.unit))
        if (unit) {
          // We found topology instance and now can map.
          layoutInstance.reference = top.reference
          layoutInstance.subscription = top.subscription
          layoutInstance.instance = instanceName
          logger.log(
            'Environment:autoMap:INSTANCE-MAPPED',
            units,
            unit,
            instanceName,
            topology,
            instance,
            layoutInstance
          )
          return true
        }
      }
    }
    return false
  }

  // For now do nothing as we have bugs and it failed
  autoMap = (env) => {
    // Created list of applications deployed in environment.
    let envApps: any = []

    // Let's load deployment.
    for (let dep of env.deployments) {
      if (!dep.reference) {
        logger.warn('Environment:AutoMap:DEPLOYMENT_NO_REF', { env, dep }, this.state, this.props)
        continue
      }

      const deployment = this.state.deployments[dep.reference]
      logger.info('Environment:AutoMap:DEPLOYMENT', { env, dep, deployment }, this.state, this.props)

      if (!deployment || !deployment.packages) {
        logger.warn('Environment:AutoMap:DEPLOYMENT_NO_PAC', { env, dep, deployment }, this.state, this.props)
        continue
      }

      // Let's traverse packages
      for (let pac of deployment.packages) {
        if (!pac || !pac.applications) {
          logger.warn('Environment:AutoMap:PACKAGE_NO_APP', { env, dep, pac, deployment }, this.state, this.props)
          continue
        }

        // Let's accumulate applications from all packages.
        pac.applications.forEach((app) => {
          let curAppRef = envApps.find((item) => nonCSCompare(app.identity.name, item.identity.name))
          let newAppRef = deepCopy(app)
          logger.info('Environment:AutoMap:APP', { env, dep, pac, app, curAppRef }, this.state, this.props)

          // We filter object not from current deployment.
          deploymentChildTypes.forEach((type) => {
            if (newAppRef[type]) {
              newAppRef[type] = newAppRef[type].filter((item) => versionCompare(item.deployed, dep.version))
            }
          })

          if (!curAppRef) {
            envApps.push(newAppRef)
          } else {
            // We concatinate objects from different packages.
            deploymentChildTypes.forEach((type) => {
              curAppRef[type] = curAppRef[type].concat(newAppRef[type])
            })
          }
        })
      }
    }

    // Map all application objects.
    for (let app of envApps) {
      for (let type of deploymentChildTypes) {
        if (!app[type]) {
          // No artifct of this type in package.
          continue
        }

        app[type].forEach((child) => {
          logger.info('Environment:AutoMap:LAYOUT', app, type, child)

          // Check if we have valid artifact with instances to map.
          if (child && child.reference && child.layoutInstances) {
            child.layoutInstances.forEach((inst) => {
              this.mapInstance(env, inst)
            })
          }
        })
      }
    }

    return Object.assign({}, env, { applications: envApps })
  }

  // Check if application properly mapped
  isApplicationMapped = (app) => {
    // Check all application objects if they have proper map from
    // layout unit to instance unit.
    for (let type of deploymentChildTypes) {
      if (!app[type]) continue

      for (let child of app[type]) {
        logger.info('Environment:isApplicationMapped:LAYOUT', child)

        // Nothing to map
        if (!child.layoutNames || child.layoutNames.length === 0) continue

        // No instances, nothing maped
        if (!child.layoutInstances) {
          return false
        }

        // Let's check we have all maped.
        for (let name of child.layoutNames) {
          let instance = child.layoutInstances.find((inst) => inst.layoutName === name)
          if (!instance || !instance.reference) {
            return false
          }
        }
      }
    }
    return true
  }

  // List of application object deployed
  getApplicationElements = (app) => {
    let list: any = []

    // Check all application objects if they have proper map from
    // layout unit to instance unit.
    for (let type of deploymentChildTypes) {
      if (!app[type]) continue

      for (let child of app[type]) {
        if (!child || !child.identity || !child.reference) {
          continue
        }
        list.push(child.identity.name)
        logger.info('Environment:getApplicationElements:LAYOUT', child)
      }
    }
    return list
  }

  // We will map units from objects layouts to units in toologies instances.
  autoMapOld = (env) => {
    // Check if we have object loaded
    if (!this.state.childrenLoaded) {
      throw new Error('Children not loaded in Environment')
    }

    let topologyApplications = {}
    let topologyDeployments = {}

    let newApplications: any = []

    ;(env.topologies || [])
      .filter((ref) => ref.reference)
      .forEach((topologyReference) => {
        const topology = this.state.topologies[topologyReference.reference]
        if (!topology) {
          return
        }
        logger.log('Environment:autoMap:TOPOLOGY', topologyReference, topology, this.state)

        topologyApplications[topologyReference.reference] = []
        topologyDeployments[topologyReference.reference] = []
        ;(topologyReference.instanceNames || []).forEach((instanceName) => {
          const instance = topology.instances.find((top) => nonCSCompare(top.identity.name, instanceName))
          logger.log('Environment:autoMap:INSTANCE', instanceName, topology, instance)
          if (!instance) {
            return
          }

          const units =
            typeof instance.units === 'string' ? instance.units.split(',').map((unit) => unit.trim()) : instance.units
          ;(env.deployments || [])
            .filter((ref) => ref.reference)
            .forEach((deploymentReference) => {
              const deployment = this.state.deployments[deploymentReference.reference]
              if (!deployment) {
                return
              }
              deployment.packages.forEach((packageData) => {
                packageData.applications
                  .filter((ref) => ref.reference)
                  .forEach((appRef) => {
                    const application = this.state.applications[appRef.reference]
                    if (!application) {
                      return
                    }
                    let newAppRef = deepCopy(appRef)
                    logger.log('AutoMap:checking application', appRef)
                    deploymentChildTypes.forEach((type) => {
                      ;(appRef[type] || [])
                        .filter((ref) => ref.reference)
                        .forEach((childRef) => {
                          const child = this.state[type][childRef.reference]
                          logger.log('AutoMap:checking childRef', childRef, child)
                          if (!child) {
                            return
                          }
                          if (child.layouts) {
                            child.layouts.forEach((layout) => {
                              units.forEach((unit) => {
                                logger.log('AutoMap:compare unit', unit, layout)
                                if (nonCSCompare(layout.unit, unit)) {
                                  const newChildRef = newAppRef[type].find(
                                    (childR) => childR.reference === childRef.reference
                                  )
                                  newChildRef.layoutInstance = {
                                    reference: topologyReference.reference,
                                    instance: instance.identity.name
                                  }
                                  logger.log('AutoMap:saving topology reference', newChildRef)
                                }
                              })
                            })
                          }
                        })
                    })

                    newApplications.push(newAppRef)
                  })
              })
            })
        })
      })
    return Object.assign({}, env, { applications: newApplications })
  }

  /**
   * Save environment update using patch action
   * @param {object} [newEnvironment] - new Pipeline with changes only
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   */
  saveEnvironment = (newEnvironment, closeDialog, onSent) => {
    this.loadEnvironment(newEnvironment).then(
      () => {
        newEnvironment = this.autoMap(newEnvironment)

        return sendObjectNew(
          idAPI.environments(newEnvironment.identity.id),
          'patch',
          this.props.actions,
          this.getObject(),
          newEnvironment
        ).then(
          (result) => {
            if (onSent) onSent(closeDialog)
          },
          (error) => {
            this.props.actions.setError(null)
            if (onSent) onSent(closeDialog, error)
          }
        )
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  /**
   * Send new state of Pipeline by using put action
   * @param {object} [newPipeline] - new Pipeline with all data
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {function} [onSent] - call back function to report status of update
   */
  sendEnvironment = (newEnvironment, closeDialog, onSent) => {
    this.loadEnvironment(newEnvironment).then(
      () => {
        // Map and save.
        newEnvironment = this.autoMap(newEnvironment)

        return sendObjectNew(
          idAPI.environments(newEnvironment.identity.id),
          'put',
          this.props.actions,
          newEnvironment
        ).then(
          (result) => {
            if (onSent) onSent(closeDialog)
          },
          (error) => {
            this.props.actions.setError(null)
            if (onSent) onSent(closeDialog, error)
          }
        )
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  /**
   * render Chart view button and Chart view window
   * @returns {XML[]}
   */
  renderRightMenu() {
    const env = this.getObject()
    return (
      <React.Fragment>
        {this.renderShowJson()}
        <br />
        {this.renderDownloadPDF()}
        <br />
        <span>
          {env && env.object ? (
            <div className="MajorObjectView__showChart">
              <span>
                <a className="" onClick={() => this.setState({ showChart: true })}>
                  &nbsp;Chart&nbsp;
                </a>
              </span>
            </div>
          ) : null}
        </span>
      </React.Fragment>
    )
  }

  renderChart() {
    const env = this.getObject()
    return <EnvironmentChart environment={env} onClose={() => this.setState({ showChart: false })} />
  }

  render() {
    const env = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    if (!env || env.isFetching) {
      return this.renderLoading()
    } else {
      //logger.log("Environment:render:TOPOLOGY", env, this.state, this.props);

      return (
        <div className="MajorObject__outer">
          <section className={'MajorObjectView MajorObjectView_' + this.state.objectType}>
            {this.renderHeader()}
            {this.renderInfo()}
            {this.state.showChart ? this.renderChart() : null}
            {this.state.editingChild && this.state.editingChildType === 'topology' ? (
              <TopologyDialog
                appState={this.props.appState}
                actions={this.props.actions}
                modalTitle={
                  this.state.activeMinorObject &&
                  this.state.activeMinorObject.identity &&
                  this.state.activeMinorObject.identity.name
                    ? 'Edit topology'
                    : 'Add topology'
                }
                isVisible
                isEditable={
                  this.state.childInEditMode
                    ? editableState.EDITING
                    : this.checkEditable()
                    ? editableState.EDITABLE
                    : editableState.BROWSABLE
                }
                majorObject={env}
                topologyReference={this.state.activeMinorObject}
                onSave={this.onSaveTopology}
                onClose={this.closeEditDialog}
              />
            ) : null}

            {this.state.editingChild && this.state.editingChildType === 'deployment' ? (
              <DeploymentDialog
                appState={this.props.appState}
                actions={this.props.actions}
                modalTitle={
                  this.state.activeMinorObject &&
                  this.state.activeMinorObject.identity &&
                  this.state.activeMinorObject.identity.name
                    ? 'Edit deployment'
                    : 'Add deployment'
                }
                isVisible
                isEditable={
                  this.state.childInEditMode
                    ? editableState.EDITING
                    : this.checkEditable()
                    ? editableState.EDITABLE
                    : editableState.BROWSABLE
                }
                deploymentReference={this.state.activeMinorObject}
                majorObject={env}
                onSave={this.onSaveDeployment}
                onClose={this.closeEditDialog}
              />
            ) : null}

            {this.state.editingChild && this.state.editingChildType === 'application' ? (
              <PackageApplicationDialog
                appState={this.props.appState}
                actions={this.props.actions}
                modalTitle={'Application deployment'}
                isVisible
                isEditable={
                  this.state.childInEditMode
                    ? editableState.EDITING
                    : this.checkEditable()
                    ? editableState.EDITABLE
                    : editableState.BROWSABLE
                }
                isInstance
                applicationReference={this.state.activeMinorObject}
                majorObject={env}
                /*
                // @ts-ignore */
                onSave={this.onSaveApplication}
                onClose={this.closeEditDialog}
              />
            ) : null}

            <div className="MajorObjectView__connections">
              <div className="row">
                <div className="col-xs-12">
                  {this.renderBackArrowTabs()}
                  <FilteredTabbedTable
                    ref={this.filteredtabbedtableRef}
                    /*
                    // @ts-ignore */
                    tablesData={this.getData()}
                    theme={this.state.objectType}
                    tabsLoaded={this.state.tabsLoaded}
                    onTabChange={this.onTabChange}
                  />
                  {this.renderFinalizedMessage()}
                </div>
              </div>
            </div>
          </section>
          {env && env.identity && env.identity.id ? (
            <Messages
              appState={this.props.appState}
              userState={this.props.userState}
              actions={this.props.actions}
              object={env}
              objectType={this.state.objectType}
              currentUserRole={this.state.currentUserRole}
            />
          ) : null}
        </div>
      )
    }
  }
}
