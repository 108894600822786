// https://kevinsimper.medium.com/react-newline-to-break-nl2br-a1c240ba746

import React, { Fragment } from 'react'

export const nl2br = (rawText: string) => {
  return (
    <Fragment>
      {rawText &&
        rawText.split('\n').map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          )
        })}
    </Fragment>
  )
}
