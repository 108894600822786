import React, { useCallback, useEffect } from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import { OrganizationsTable } from './OrganizationsTable'
import { WithSideBar } from 'layouts/WithSideBar/WithSideBar'

import { idAPI } from 'helpers/api'
import { useDispatch } from 'react-redux'
import { updateUserState } from 'actions/userActions'

export const Organizations: React.FC<any> = (props) => {
  const dispatch = useDispatch()

  const getCurrentUser = useCallback(async () => {
    const user = await idAPI.users(localStorage.currentUserId).get()
    dispatch(updateUserState(user))
  }, [dispatch])

  useEffect(() => {
    getCurrentUser()
  }, [getCurrentUser])

  return (
    <WithSideBar {...props}>
      <Box display="flex" minHeight="100vh">
        {/* <Grid item xs={2}>
          <Box
            bgcolor="white"
            height="100%"
            p={2}
            style={{
              boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 2px 0 rgb(63 63 68 / 15%)',
              borderColor: 'rgba(0, 0, 0, 0.12)',
              boxSizing: 'border-box'
            }}
          >
            Organization List
          </Box>
        </Grid> */}
        <Grid item xs>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <OrganizationsTable {...props} />
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Box>
    </WithSideBar>
  )
}
