import PropTypes from 'prop-types'

import React, { Component } from 'react'

import { TypedTable } from './TypedTable'

// how much child table is shifted to the right (in pixels)
const CHILD_MARGIN = 32

/**
  NestedTable is like TypedTable but can have nested tables
  They are passed in "children" property of rows, and have same columns as parent table
  Unlike StructureTable or PropertyTable nothing is loaded, all data is passed in props
 */
export class NestedTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expandedRows: this.props.expandedRows || []
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.state.expandedRows.length === 0 && newProps.expandedByDefault) {
      this.setState({
        expandedRows: (newProps.data || []).map((row, index) => true)
      })
    }
  }

  toggleAllRows() {
    const isExpanded = this.state.expandedRows.reduce((p, c) => (c ? c : p), false)
    let newExpandedRows = this.props.data.map((row) => !isExpanded)
    this.setState({ expandedRows: newExpandedRows }, this.props.onExpand ? this.props.onExpand : () => {})
  }

  toggleExpandedRow(index) {
    return () => {
      let newExpandedRows = this.state.expandedRows.slice()
      newExpandedRows[index] = !newExpandedRows[index]
      this.setState({ expandedRows: newExpandedRows }, this.props.onExpand ? this.props.onExpand : () => {})
    }
  }

  getExpandButtonText = () => {
    return '+'
  }

  render() {
    let {
      columns,
      data,
      level,
      objectPath,
      tailButtons,
      contextMenuButtons,
      bottomButtons,
      className,
      filters,
      dataFilters,
      topButtons,
      expandedByDefault,
      headBgColor,
      headFontColor,
      onExpand
    } = this.props
    level = level || 0

    // As in PropertyTable, the fields are divided into groups, structures and enums are placed into separate child tables
    let recordGroups = [[]]

    data.map((record, index) => {
      record.index = index
      if (record.children) {
        recordGroups.push([record])
        recordGroups.push([])
      } else {
        recordGroups[recordGroups.length - 1].push(record)
      }
    })

    recordGroups = recordGroups.filter((group) => group.length > 0)

    if (recordGroups.length === 0) recordGroups.push([])

    let rowIndex = 0

    return (
      <div>
        {recordGroups.map((group, groupIndex) => {
          let currentRowIndex = rowIndex
          rowIndex += group.length
          let expandButton = ''
          let expandedBody = ''

          if (group.length > 0 && group[0].children) {
            let widthFound = false
            const newTableMargin =
              columns.reduce((w, col) => w + (col.hiddenInChildTable ? col.width : 0), 0) + CHILD_MARGIN
            const newColumns = columns
              .filter((col) => !col.hiddenInChildTable)
              .map((col) => {
                if (!widthFound && col.width > CHILD_MARGIN) {
                  widthFound = true
                  return Object.assign({}, col, {
                    width: col.width - CHILD_MARGIN
                  })
                }
                return col
              })

            expandButton = (
              <div
                className={
                  'StructureTable__expandButton ' +
                  (level > 0 ? 'StructureTable__expandButton__nested ' : ' ') +
                  (this.state.expandedRows[groupIndex] ? 'StructureTable__expandButton_active' : '')
                }
                onClick={this.toggleExpandedRow(groupIndex)}
              >
                {this.state.expandedRows[groupIndex] ? '-' : this.getExpandButtonText()}
              </div>
            )

            expandedBody = !this.state.expandedRows[groupIndex] ? null : (
              <div>
                <div className="StructureTable__childRow" style={{ marginLeft: newTableMargin + 'px' }}>
                  <NestedTable
                    columns={newColumns}
                    data={group[0].children}
                    className={className}
                    tailButtons={tailButtons}
                    contextMenuButtons={contextMenuButtons}
                    level={level + 1}
                    expandedByDefault={expandedByDefault}
                    onExpand={onExpand}
                  />
                </div>
              </div>
            )
          }
          return (
            <div key={groupIndex}>
              <TypedTable
                ref={'typedtable' + groupIndex}
                className={className + ' NestedTable'}
                hideHeader={groupIndex > 0 || level > 0}
                columns={columns}
                data={group.map((g) => Object.assign({}, g, { expandButton: expandButton }))}
                tailButtons={tailButtons}
                contextMenuButtons={contextMenuButtons}
                bottomButtons={groupIndex === recordGroups.length - 1 && level === 0 ? bottomButtons : []}
                bottom={expandedBody}
                filters={groupIndex === 0 ? filters : false}
                dataFilters={groupIndex === 0 ? dataFilters : false}
                topButtons={groupIndex === 0 ? topButtons : false}
                firstRowIndex={currentRowIndex}
                headBgColor={headBgColor}
                headFontColor={headFontColor}
              />
            </div>
          )
        })}
      </div>
    )
  }
}

NestedTable.propTypes = {
  level: PropTypes.number,
  columns: PropTypes.array, // same as in TypedTable
  objectPath: PropTypes.string,
  className: PropTypes.string,
  headBgColor: PropTypes.string,
  headFontColor: PropTypes.string,
  data: PropTypes.array, // same as in TypedTable
  tailButtons: PropTypes.array, // array of {label, onClick}
  contextMenuButtons: PropTypes.array,
  bottomButtons: PropTypes.array, // display at table bottom
  filters: PropTypes.array, // array of string/component from FilterableContent
  dataFilters: PropTypes.array, // array from FilterableContent
  topButtons: PropTypes.array,
  expandedRows: PropTypes.array,
  expandedByDefault: PropTypes.bool,
  onExpand: PropTypes.func
}
