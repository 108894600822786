/* eslint-disable react/no-multi-comp */
import React from 'react'
import ScrollArea from 'react-scrollbar'
import { AdaptiveImage } from '../AdaptiveImage/AdaptiveImage'
import { JSONDiffTable } from '../JSONDiffTable/JSONDiffTable'
import './InterfaceExampleControl.scss'

export const InterfaceExampleControl = (props) => {
  const { status, onClick, toggleDiff, exampleId, resultJson, expectedJson, showDiff } = props

  const handleClick = () => {
    onClick(exampleId)
  }

  const handleToggle = () => {
    toggleDiff(exampleId)
  }

  const checkBox = () => (
    <label className="InterfaceExampleControl__checkbox">
      <input type="checkbox" onClick={handleClick} />
      <div className="check" />
    </label>
  )

  const resultCompare = () => {
    const responseDiff = JSONDiffTable(expectedJson, resultJson)

    return (
      <div className="InterfaceExampleControl__compare">
        <div className="InterfaceExampleControl__header">
          <div className="InterfaceExampleControl__close" onClick={handleToggle}>
            <AdaptiveImage src="close" />
          </div>
        </div>
        <div className="InterfaceExampleControl__body">
          <ScrollArea>{responseDiff}</ScrollArea>
        </div>
      </div>
    )
  }

  const getContent = () => {
    switch (status) {
      case 'success':
        return (
          <div>
            {checkBox()}
            <div className="InterfaceExampleControl__status">
              <AdaptiveImage src="exute-good" />
            </div>
          </div>
        )
      case 'failure':
        return (
          <div>
            {checkBox()}
            <div className="InterfaceExampleControl__status">
              <AdaptiveImage src="exute-bad" onClick={handleToggle} />
            </div>
            {showDiff && resultCompare()}
          </div>
        )
      default:
        return checkBox()
    }
  }

  return <div className="InterfaceExampleControl">{getContent()}</div>
}
