import * as metadata from '../resources/lib/index'
import { nonCSCompare } from './nonCSCompare'
const Jsons = require('difhub-md')

// console.log('MD', metadata)

const objectJsons = {
  organization: metadata.metadata.apdax.systems.difhub.applications.system.datasets.organization,
  application: metadata.metadata.apdax.systems.difhub.applications.system.datasets.application,
  dataset: metadata.metadata.apdax.systems.difhub.applications.dataset.datasets.dataset,
  cardinality: metadata.metadata.apdax.systems.difhub.applications.dataset.datasets.cardinality,
  system: metadata.metadata.apdax.systems.difhub.applications.system.datasets.system,
  publication: metadata.metadata.apdax.systems.difhub.applications.system.datasets.publication,
  subscription: metadata.metadata.apdax.systems.difhub.applications.system.datasets.subscription,
  // 'field':  Jsons.apdax.systems.difhub.applications.system.datasets.Field,
  field: metadata.metadata.apdax.systems.difhub.applications.system.datasets.field,
  layout: metadata.metadata.apdax.systems.difhub.applications.system.datasets.layout,
  object: metadata.metadata.apdax.systems.difhub.applications.system.datasets.object,
  contact: metadata.metadata.apdax.systems.difhub.applications.system.datasets.contact,
  identity: metadata.metadata.apdax.systems.difhub.applications.organization.datasets.identity,
  // 'usage': Jsons.apdax.systems.difhub.applications.organization.datasets.Usage,
  usage: metadata.metadata.apdax.systems.difhub.applications.organization.datasets.usage,
  type: metadata.metadata.apdax.systems.difhub.applications.organization.datasets.type,
  role: metadata.metadata.apdax.systems.difhub.applications.organization.datasets.role,
  // 'locale': Jsons.apdax.systems.difhub.applications.organization.datasets.Locale,
  locale: metadata.metadata.apdax.systems.difhub.applications.organization.datasets.locale,
  function: metadata.metadata.apdax.systems.difhub.applications.organization.datasets.function,
  privacy: metadata.metadata.apdax.systems.difhub.applications.organization.datasets.privacy,
  permission: metadata.metadata.apdax.systems.difhub.applications.organization.datasets.permission,
  messageType: metadata.metadata.apdax.systems.difhub.applications.messaging.datasets.messagetype,
  messageAction: metadata.metadata.apdax.systems.difhub.applications.messaging.datasets.messageaction,
  messageProperty: metadata.metadata.apdax.systems.difhub.applications.messaging.datasets.messageproperty,
  location: metadata.metadata.apdax.systems.difhub.applications.interface.datasets.location,
  interface: metadata.metadata.apdax.systems.difhub.applications.interface.datasets.interface,
  parameter: metadata.metadata.apdax.systems.difhub.applications.interface.datasets.parameter,
  response: metadata.metadata.apdax.systems.difhub.applications.interface.datasets.response,
  operation: metadata.metadata.apdax.systems.difhub.applications.interface.datasets.operation,
  actions: metadata.metadata.apdax.systems.difhub.applications.interface.datasets.action,
  align: metadata.metadata.apdax.systems.difhub.applications.view.datasets.align,
  interfaceReference: metadata.metadata.apdax.systems.difhub.applications.system.datasets.interfacereference
}

export function getJsonSpecFields(objectType) {
  if (!objectType || !objectJsons[objectType]) return []

  return objectJsons[objectType].structure.fields
}

/**
 * Returns array of Record objects
 * @param objectType
 * @returns {Record[]}
 */
export function getJsonSpecRecords(objectType) {
  if (!objectType || !objectJsons[objectType]) return []

  return objectJsons[objectType].data.records ? objectJsons[objectType].data.records : []
}

export function getJsonSpecFieldOrdinal(objectType, fieldName) {
  if (!objectType || !objectJsons[objectType]) return -1

  const fields = objectJsons[objectType].structure.fields
    ? objectJsons[objectType].structure.fields
    : objectJsons[objectType].structure.field
  return fields.findIndex((f) => f.identity.name === fieldName)
}

/**
 * Checks if usage is applicable to field
 * @param {Record} usageRecord
 * @param {String} type - type to check for
 * @return {Boolean}
 */
export function isUsageForType(usageRecord, type) {
  return nonCSCompare(usageRecord.values[3], type)
}

/**
 * Get collection of enumerators values for property
 * @param name of property we need options for
 * @return list of options for enum
 */
export function getPropertyOptions(name) {
  return getJsonSpecRecords(name.toLowerCase()).map(enumRecordToOption)
}

function enumRecordToOption(record, index) {
  return {
    id: index,
    label: record.values[0],
    value: record.values[0]
  }
}

export function castValueToType(field) {
  if (!field.value) return field
  if (!field.type || !field.type.name) {
    field.type = {
      name: 'string'
    }
  }

  if (field.type && field.type.name) {
    switch (field.type.name) {
      case 'boolean':
        field.value = nonCSCompare(field.value, 'true')
        break
      case 'string':
        field.value = '' + field.value ? field.value : ''
        break
      case 'number':
      case 'integer':
      case 'decimal':
      case 'double':
      case 'float':
      case 'byte':
      case 'small':
      case 'short':
      case 'long':
      case 'huge':
      case 'unsigned short':
      case 'unsigned integer':
      case 'unsigned long':
      case 'unsigned huge':
        field.value = parseFloat(field.value)
        break
      default:
        return field
    }
  }

  return field
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  repo: metadata,
  jsons: Jsons
}
