/**
 * Created by mailf on 08.06.2016.
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import logger from '../../helpers/logger'
import { InputDialog } from './InputDialogNew'
import { nonCSCompare, columnsToType, displayReferenceUri } from '../../helpers/index'

import './InputList.scss'
import { EditorListBase } from '../EditorDialog/EditorListBase'

export class InputList extends EditorListBase {
  constructor(props) {
    super(props)

    //logger.info("InputList:constructor", this.props, this.state);
  }

  /**
   * Create new rule for editing
   * @return {object} empty element
   */
  createItem = (name) => {
    return {
      identity: { name: name || '' },
      inputMode: nonCSCompare(this.props.activity.type, 'operation') ? 'Operation' : 'Dataset',
      component: {}
    } //createEmptyObject('input');
  }

  /**
   * Return filters requered for item list
   */
  getFilters = () => {
    return [{ name: 'search', width: 220 }]
  }

  /**
   * Return columns requered for item list
   */
  getColumns = () => {
    return [
      {
        name: 'name',
        type: columnsToType.getType('minorObjectName'),
        width: 120,
        onCellClick: (value) => {
          this.onViewItem(value)
        }
      },
      {
        name: 'mode',
        type: columnsToType.getType('string'),
        frozen: true,
        width: 80
      },
      {
        name: 'reference',
        type: columnsToType.getType('string'),
        frozen: true,
        width: 220
      },
      { name: 'components', type: { name: 'text' } }
    ]
  }

  /**
   * Return item data to render in table
   * @param {object} obj - item to render
   */
  getItem = (obj) => {
    //logger.info("EditorListBase:renderResoueceList:DATA", type, objs);
    return Object.assign({}, obj, {
      name: {
        name: obj.identity ? obj.identity.name : '',
        objectType: 'input'
      },
      mode: obj.inputMode,
      reference: displayReferenceUri(
        obj.component ? obj.component.reference : '',
        obj.component ? obj.component.subscription : ''
      ),
      components:
        obj.component && obj.component.components
          ? Array.isArray(obj.component.components)
            ? obj.component.components.join(', ')
            : obj.component.components
          : ''
    })
  }

  /**
   * Render dialog for item entry
   */
  renderItemDialog(editState, item, base, isEditable) {
    //logger.info("InputList:renderItemDialog", editState, item, base, isEditable, this.props, this.state);

    return (
      <InputDialog
        appState={this.props.appState}
        actions={this.props.actions}
        isVisible
        isEditable={isEditable}
        majorObject={this.props.majorObject}
        parentDialog={this.props.parentDialog}
        input={item}
        activity={this.props.activity}
        activities={this.props.activities}
        datasets={this.props.datasets}
        interfaces={this.props.interfaces}
        onEditableChange={this.onEditableChange}
        onClose={this.closeItem}
        onSave={(obj, closeDialog, onSent) => this.saveItem(obj, base, closeDialog, onSent)}
      />
    )
  }
}

InputList.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isEditable: PropTypes.number, // State of editing in dialog
  majorObject: PropTypes.object.isRequired, // Major object we work against
  type: PropTypes.string.isRequired, // Type of object
  resource: PropTypes.object.isRequired // Resource object array
}
