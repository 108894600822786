import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../actions/index'

class App extends Component {
  componentDidMount() {}

  render() {
    ////console.log("App actions",actions);
    return (
      <>
        {React.cloneElement(this.props.children, {
          appState: this.props.appState,
          userState: this.props.userState,
          actions: this.props.actions
        })}
      </>
    )
  }
}

App.propTypes = {
  children: PropTypes.element,
  appState: PropTypes.object,
  userState: PropTypes.object,
  actions: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    userState: state.userAppState,
    appState: state.appState
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
