/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  buildPathDetailed,
  columnsToType,
  demoRandom,
  getUTCTime,
  majorObjectSort,
  objectPictureUrl
} from '../../helpers'
import { API, getApiResource, getObjectListNew, getRequestFromPath, idAPI } from '../../helpers/api'
import { editableState, getFullUri } from '../../helpers/index'
import { ErrorWindow } from '../ErrorWindow/ErrorWindow'
import { HeaderPath } from '../HeaderPath/HeaderPath'
import { JoinDialog } from '../JoinDialog/JoinDialog'
import { Loader } from '../Loader/Loader'
import { MajorObjectLink } from '../MajorObjectLink/MajorObjectLink'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import { NestedTable } from '../NewTable/NestedTable'
import { PagedContent } from '../NewTable/PagedContent'
import ObjectCreator from '../ObjectCreator/ObjectCreator'
import { ObjectPicture } from '../ObjectPicture/ObjectPicture'
import { MainLayout } from '../../layouts/MainLayout/MainLayout'
import './OrganizationList.scss'
import { getTailButtonLabel } from '../../helpers/helperComponents'

// import iWarning from '../../resources/images/warning_2x.png'

export class OrganizationList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isJoining: false,
      isAdding: false,
      addInProgress: false,
      organization: null,
      department: '',
      organizationName: '',
      joinError: '',
      addError: '',
      defaultOrganization: localStorage.getItem('defaultOrganization'),
      currentOrganization: localStorage.getItem('currentOrganization'),
      deletingOrganizationIndex: null,
      deleteOrganizationError: ''
    }

    this.tableRef = React.createRef()
  }

  componentDidMount() {
    this.getOrganizationList()
  }

  getOrganizationList(forceReload = false) {
    this.props.actions.clearObjectList('organization')
    getObjectListNew(API.organizations, 'organization', this.props.actions, forceReload).then((orglist) => {
      orglist.forEach((org) => {
        // TODO: optimize users loading. Too many state updates
        if (!org.userRoleApproved)
          getObjectListNew(API.organizations(org.identity.name).users, 'user', null).then((users) => {
            this.props.actions.receiveObjectListIntoProperty(users, org, 'organization', 'users')
          })
      })
      //this.expandAllStructure();
    })
  }

  getSortedOrgs(sourceOrgs) {
    //return sourceOrgs;

    const parentOrgsIds = []
    sourceOrgs = sourceOrgs.slice() || []
    sourceOrgs.forEach((sOrg) => {
      if (sOrg.object.parent && !parentOrgsIds.includes(sOrg.object.parent.id))
        parentOrgsIds.push(sOrg.object.parent.id)
    })
    let tempList = []

    // Sort orgs to be near parent
    parentOrgsIds.forEach((poi) => {
      const parentIndex = sourceOrgs.findIndex((sorg) => sorg.identity.id === poi)
      tempList.push(sourceOrgs[parentIndex])

      //console.log(sourceOrgs, parentIndex);
      if (parentIndex !== -1) sourceOrgs.splice(parentIndex, 1)

      const childrenIds = []
      sourceOrgs.forEach((so) => {
        if (so.object.parent && so.object.parent.id === poi) childrenIds.push(so.identity.id)
      })
      const children = sourceOrgs.filter((so) => childrenIds.includes(so.identity.id))
      tempList = tempList.concat(children)
      sourceOrgs = sourceOrgs.filter((so) => !childrenIds.includes(so.identity.id))
    })

    if (sourceOrgs.length) tempList = tempList.concat(sourceOrgs)

    return tempList
  }

  selectOrganization(object) {
    this.setState({ organization: object })
  }

  changeDepartment(event) {
    this.setState({ department: event.target.value })
  }

  changeAcceptLicense(event) {
    this.setState({ acceptLicense: event.target.value })
  }

  changeOrganizationName(event) {
    this.setState({ organizationName: event.target.value })
  }

  changeDepartmentName(event) {
    this.setState({
      departmentName: event.target.value
    })
  }

  selectSubscription(id) {
    return (event) => {
      this.setState({ selectedSubscription: event.target.value ? id : 0 }, () => {
        this.forceUpdate()
      })
    }
  }

  openJoin() {
    this.setState({ isJoining: true, acceptLicense: false })
  }

  closeJoin() {
    this.setState({ isJoining: false })
  }

  join() {
    if (this.state.organizationName === '') {
      this.setState({ joinError: 'name' })
    } else if (this.state.acceptLicense === false) {
      this.setState({ joinError: 'accept' })
    } else {
      const orgMessageRequest = API[getApiResource('organization')](this.state.organizationName)[
        getApiResource('message')
      ]

      orgMessageRequest
        .post({
          class: 'Message',
          type: 'AccessApproval',
          issue: {
            status: 'Opened'
          },
          sender: {
            id: localStorage.currentUserId,
            description: this.props.userState.profile.alias
          },
          text: 'Please add me to organization',
          time: moment(getUTCTime()).format('YYYY-MM-DDTHH:mm:ss'),
          action: 'RequestAccess'
        })
        .then(
          function joinSuccess() {
            //console.log("Join success");
          },
          function joinError(err) {
            //console.log("Join error", err);
          }
        )
    }
  }

  openAdd() {
    this.setState({ isAdding: true, parentOrgId: null, acceptLicense: false })
  }

  closeAdd() {
    this.setState({
      isAdding: false,
      isAddingDepartment: false,
      parentOrgId: null,
      addError: ''
    })
  }

  onAddOrganization(organizationIdentity, onError) {
    if (this.state.parentOrgId) {
      const parentOrg = this.props.appState.organizations.find((org) => org.identity.id === this.state.parentOrgId)
      organizationIdentity.identity.name = parentOrg.identity.name + ':' + organizationIdentity.identity.name
    }
    API.organizations
      .post(
        Object.assign(
          {},
          organizationIdentity,
          !this.state.parentOrgId ? {} : { object: { parent: { id: this.state.parentOrgId } } }
        )
      )
      .then(
        (result) => {
          //onSent(close);
          this.setState({ isAdding: false, isAddingDepartment: false }, () => {
            //window.apiCache.store = {};
            this.getOrganizationList(true)
          })
        },
        (err) => {
          //this.props.actions.setError(null);
          onError(err)
        }
      )
  }

  cancelDeleteOrganization() {
    this.setState({ deletingOrganizationId: null })
  }

  realDeleteOrganization() {
    const id = this.state.deletingOrganizationId
    const org = this.props.appState.organizations.find((org) => org.identity.id === id)
    let orgId = org.identity.id
    let orgName = org.identity.name

    // console.log("real delete", orgId, orgName);

    idAPI
      .organizations(orgId)
      .delete()
      .then(
        (result) => {
          //API.organizations(orgName).delete().then((result) => {
          console.log('realDeleteOrganization res', result)
          this.props.actions.deleteObject('organizations', orgName)
          this.setState({ deletingOrganizationId: null })
          this.getOrganizationList(true)
        },
        (error) => {
          this.setState({ deleteOrganizationError: error })
          console.log('realDeleteOrganization', error)
        }
      )
    return false
  }

  /**
   * Set deleting org id
   * @param {Object} org record from table
   */
  deleteOrganization(org) {
    console.log('deleteOrganization', org)

    const targetOrg = this.props.appState.organizations.find((o) => o.identity.id === org.id)

    API.organizations(targetOrg.identity.name)
      .users.get()
      .then((users) => {
        if (users && users.length > 1)
          this.setState({
            deletingOrganizationRoleId: org.id,
            deleteOrganizationError: ''
          })
        else
          this.setState({
            deletingOrganizationId: org.id,
            deleteOrganizationError: ''
          })
      })
  }

  deleteOrganizationRole = () => {
    const targetOrg = this.props.appState.organizations.find(
      (o) => o.identity.id === this.state.deletingOrganizationRoleId
    )

    getRequestFromPath(getFullUri(targetOrg))
      .users(this.props.userState.profile.identity.name)
      .delete()
      .then(
        () => {
          this.getOrganizationList(true)
        },
        (error) => {
          this.setState({ deleteOrganizationError: error })
          console.log('deleteOrganizationRole', error)
        }
      )
  }

  cancelDeleteOrganizationRole = () => {
    this.setState({ deletingOrganizationRoleId: null })
  }

  onEditCell(rowIndex, oldRow, newRow) {
    //console.log("onEditCell", rowIndex, oldRow, newRow);
    if (newRow.isDefault) {
      localStorage.setItem('defaultOrganization', newRow.id)
      this.setState({ defaultOrganization: newRow.id })
    }
  }

  onDefaultClick = (row) => {
    //console.log("onDefaultClick",a,b,c);
    if (row.isDefault) {
      localStorage.setItem('defaultOrganization', -1)
      this.setState({ defaultOrganization: -1 })
    } else {
      localStorage.setItem('defaultOrganization', row.id)
      this.setState({ defaultOrganization: row.id })
    }
  }

  onAddDepartmentClick = (orgId) => {
    //console.log("onAddDepartmentClick", orgId);
    this.setState({
      isAddingDepartment: true,
      parentOrgId: orgId
    })
  }

  onCloseDepartmentClick = () => {
    this.setState({
      isAddingDepartment: false,
      parentOrgId: null,
      departmentName: ''
    })
  }

  renderJoin() {
    return (
      <JoinDialog
        actions={this.props.actions}
        action="RequestAccess"
        user={this.props.userState}
        isEditable={editableState.EDITING}
        isVisible={this.state.isJoining}
        onSave={this.getOrganizationList.bind(this)}
        onClose={this.closeJoin.bind(this)}
      />
    )
  }

  renderAdd() {
    return (
      <ObjectCreator
        isOpen={this.state.isAdding}
        childObjectType="organization"
        onSuccess={this.closeAdd.bind(this)}
        onCancel={this.closeAdd.bind(this)}
        customCreateObject={this.onAddOrganization.bind(this)}
        actions={this.props.actions}
      />
    )
  }

  renderAddDepartment() {
    return (
      <ObjectCreator
        parentObjectId={this.state.parentOrgId}
        isOpen={this.state.isAddingDepartment}
        childObjectType="organization"
        onSuccess={this.closeAdd.bind(this)}
        onCancel={this.closeAdd.bind(this)}
        customCreateObject={this.onAddOrganization.bind(this)}
        actions={this.props.actions}
      />
    )
  }

  toggleExpandDescriptions = () => {
    this.setState({ expandDescriptions: !this.state.expandDescriptions })
  }

  renderDescriptionColumnHeader = () => {
    return (
      <span>
        Description&nbsp;
        <a
          className={
            'MajorObject__expandButton ' + (this.state.expandDescriptions ? 'MajorObject__expandButton__active' : '')
          }
          onClick={this.toggleExpandDescriptions}
        ></a>
      </span>
    )
  }

  getDescriptionType = () => {
    return { name: this.state.expandDescriptions ? 'text_expanded' : 'text' }
  }

  expandAllStructure = () => {
    if (this.tableRef.current) {
      this.tableRef.current.toggleAllRows()
      setTimeout(() => {
        this.forceUpdate()
      }, 0)
    }
  }

  isExpandAllStructure = () => {
    if (this.tableRef.current) {
      const rows = this.tableRef.current.state.expandedRows

      if (rows.length === this.tableRef.current.props.data.length && rows.reduce((all, cur) => all && cur, true))
        return true
      else return false
    } else return false
  }

  render() {
    //console.log("OrganizationList:render", this.props, this.state);
    let defaultOrg = null
    const targetOrgId =
      this.state.deletingOrganizationId || this.state.deletingOrganizationRoleId
        ? this.state.deletingOrganizationId || this.state.deletingOrganizationRoleId
        : null
    let targetOrg = this.props.appState.organizations
      ? this.props.appState.organizations.find((org) => org.identity.id === targetOrgId)
      : null

    let orgList = (this.props.appState.organizations || []).map((org) => org)

    const parentOrgsIds = []
    orgList.forEach((selOrg) => {
      if (selOrg && selOrg.object && selOrg.object.parent && !parentOrgsIds.includes(selOrg.object.parent.id))
        parentOrgsIds.push(selOrg.object.parent.id)
    })

    orgList = this.props.appState.organizations
      ? orgList
          .filter((org) => org && org.identity)
          .sort(majorObjectSort)
          .map((org) => {
            let newName = (
              <MajorObjectLink object={org} type="organizations">
                {org.identity.name}
              </MajorObjectLink>
            )
            let logo = (
              <ObjectPicture
                name={org.identity.name}
                img={org.object ? objectPictureUrl(getFullUri(org), org.object.picture) : null}
              />
            )

            let days = Math.floor(1 + demoRandom(org.identity.name) * 16)
            let isDefault = this.state.defaultOrganization === org.identity.id

            if (isDefault) {
              newName = <span className="OrganizationList__defaultOrganizationName">{newName}</span>
            }
            if (this.state.currentOrganization === org.identity.id) {
              newName = (
                <span>
                  {newName}
                  <br />
                  <span className="currentOrgLabel">Current</span>
                </span>
              )
              defaultOrg = org
            }
            let userRole = ''
            let userStatus = 'Pending'
            if ('userRoleApproved' in org) {
              if (org.userRoleApproved !== '') userStatus = 'Approved'
              else userStatus = 'Pending'
            }
            if (org.users)
              org.users.forEach((u) => {
                if (u.user.id === localStorage.currentUserId) {
                  userRole = u

                  // Define status of user access based on completion
                  try {
                    for (let i = 0; i < userRole.history.completions.length; i++) {
                      const completion = userRole.history.completions[i]

                      if (completion.status === 'Approved' && completion.completed) {
                        userStatus = 'Approved'
                        break
                      }

                      if (completion.status === 'Finalized' && !completion.completed) {
                        userStatus = 'Declined'
                        break
                      }
                    }
                  } catch (e) {
                    console.error(userRole) // eslint-disable-line no-console
                  }
                }
              })

            const orgType = parentOrgsIds.includes(org.identity.id)
              ? 'parent'
              : org.object && org.object.parent
              ? 'department'
              : null
            // console.log("org", org);

            return {
              id: org.identity.id,
              department_icon: orgType,
              description: org.identity.description,
              name: (
                <span className={'OrganizationList__name_' + orgType}>
                  {logo}
                  {newName}
                </span>
              ),
              rowName: org.identity.name,
              your_status: userStatus,
              isDefault: isDefault,
              parentId: org.object && org.object.parent ? org.object.parent.id : -1,
              status: <span className="OrganizationList__statusArchitect">{userRole.role}</span>,
              custom_profile: org && org.object && !org.object.parent ? org : null,
              subscription_status:
                demoRandom(org.identity.name) < 0.5
                  ? {
                      status: 'Enterprise',
                      color: days > 5 ? 'yellow' : 'red',
                      daysRemaining: days,
                      text: '!'
                    }
                  : {
                      status: 'Free',
                      color: 'green',
                      text: '0'
                    }
            }
          })
      : []

    // convert orgList to Nested structure
    orgList = orgList
      .map((org) => {
        // We need special sort for children of the hierarchical elements
        if (org.parentId !== -1) {
          let parentElement = orgList.find((curr) => curr.id === org.parentId)

          if (parentElement) {
            parentElement.children = (parentElement.children || []).concat(org).sort(majorObjectSort)
            return null
          } else {
            //console.warn("Incorrect reference to parent element", element, parentElement);
            return org
          }
        } else {
          return org
        }
      })
      .filter((org) => org)

    return (
      <>
        {this.state.isJoining ? this.renderJoin() : null}
        {this.state.isAdding ? this.renderAdd() : null}
        {this.state.isAddingDepartment ? this.renderAddDepartment() : null}
        <MainLayout userState={this.props.userState} appState={this.props.appState} actions={this.props.actions}>
          <ErrorWindow actions={this.props.actions} history={this.props.history} />
          {defaultOrg ? <HeaderPath path={buildPathDetailed(getFullUri(defaultOrg))} /> : null}
          <div className="OrganizationList">
            <div className="OrganizationList__upper">ORGANIZATIONS</div>
            <div className="OrganizationList__content">
              <div className="row">
                <div className="col-xs-12">
                  {1 || orgList.length ? (
                    <PagedContent data={orgList} dataPropertyName="data" pageSize="20">
                      <NestedTable
                        className="OrganizationList__table"
                        ref={this.tableRef}
                        expandedByDefault
                        columns={[
                          {
                            displayName: (
                              <span>
                                <a
                                  className={
                                    'MajorObject__expandStructureButton ' +
                                    (this.isExpandAllStructure() ? 'MajorObject__expandStructureButton__active' : '')
                                  }
                                  onClick={this.expandAllStructure}
                                ></a>
                              </span>
                            ),
                            name: 'expandButton',
                            type: { name: 'data' },
                            frozen: true,
                            width: 30
                          },
                          {
                            name: 'name',
                            type: columnsToType.getType('data'),
                            displayName: <span className="OrganizationList__nameColumnLabel">Name</span>,
                            frozen: true,
                            width: 299
                          },
                          {
                            name: 'description',
                            displayName: this.renderDescriptionColumnHeader(),
                            type: this.getDescriptionType(),
                            frozen: true,
                            width: 314
                          },
                          {
                            name: 'your_status',
                            type: columnsToType.getType('your_status'),
                            displayName: 'Status',
                            frozen: true,
                            width: 70
                          },
                          {
                            name: 'isDefault',
                            type: columnsToType.getType('isDefault'),
                            displayName: 'Default',
                            width: 80,
                            onCellClick: this.onDefaultClick
                          },
                          {
                            name: 'status',
                            type: columnsToType.getType('status'),
                            displayName: 'Role',
                            frozen: true,
                            width: 80
                          },
                          {
                            name: 'custom_profile',
                            type: columnsToType.getType('custom_profile'),
                            displayName: 'Custom profile',
                            width: 160,
                            frozen: true
                          },
                          {
                            name: 'subscription_status',
                            type: columnsToType.getType('subscription_status'),
                            displayName: 'Subscription Status',
                            frozen: true,
                            width: 150
                          }
                        ]}
                        tailButtons={[
                          {
                            label: getTailButtonLabel('Add'),
                            onClick: (org) => this.onAddDepartmentClick(org.id)
                          },
                          {
                            label: getTailButtonLabel('Delete'),
                            onClick: this.deleteOrganization.bind(this)
                          }
                        ]}
                        theme="organization"
                        isEditable
                        inEditMode
                        onChangeRow={this.onEditCell.bind(this)}
                        bottomButtons={[
                          {
                            label: '~ Join organization',
                            onClick: this.openJoin.bind(this)
                          },
                          {
                            label: '+ Add organization',
                            onClick: this.openAdd.bind(this)
                          }
                        ]}
                      />
                    </PagedContent>
                  ) : (
                    <div className="loader_center">
                      <loader />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {this.state.deletingOrganizationId !== undefined ? (
            <ModalWindow
              header="Delete organization"
              canClose
              isOpen
              className="OrganizationList__deleteModal"
              onClose={this.cancelDeleteOrganization.bind(this)}
              footer={
                <div className="OrganizationList__deleteButtons">
                  <button className="btn_big" onClick={this.cancelDeleteOrganization.bind(this)}>
                    No
                  </button>
                  <button className="btn_big" onClick={this.realDeleteOrganization.bind(this)}>
                    Yes
                  </button>
                </div>
              }
            >
              {targetOrg ? (
                <div className="OrganizationList__deleteMessage">
                  <p>
                    <img src="images/warning_2x.png" alt="delete icon" />
                  </p>
                  <p>Do you really want to delete {targetOrg.identity.name}?</p>
                  <p>By deleting your organization, all your {targetOrg.identity.name} data will be lost.</p>

                  <div className="EditorDialog__error">
                    {!this.state.deleteOrganizationError
                      ? ''
                      : JSON.parse(this.state.deleteOrganizationError.response).error.message}
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </ModalWindow>
          ) : null}
          {this.state.deletingOrganizationRoleId !== undefined ? (
            <ModalWindow
              header="Delete organization"
              canClose
              isOpen
              className="OrganizationList__deleteModal"
              onClose={this.cancelDeleteOrganizationRole}
              footer={
                <div className="OrganizationList__deleteButtons">
                  <button className="btn_big" onClick={this.cancelDeleteOrganizationRole}>
                    No
                  </button>
                  <button className="btn_big" onClick={this.deleteOrganizationRole}>
                    Yes
                  </button>
                </div>
              }
            >
              {targetOrg ? (
                <div className="OrganizationList__deleteMessage">
                  <p>
                    <img src="images/warning_2x.png" alt="delete icon" />
                  </p>
                  <p>Do you really want to remove yourself from {targetOrg.identity.name}?</p>
                  <p>You will loose access to this organization.</p>

                  <div className="EditorDialog__error">
                    {!this.state.deleteOrganizationError
                      ? ''
                      : JSON.parse(this.state.deleteOrganizationError.response).error.message}
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </ModalWindow>
          ) : null}
        </MainLayout>
      </>
    )
  }
}

OrganizationList.propTypes = {
  actions: PropTypes.object,
  appState: PropTypes.object,
  userState: PropTypes.object,
  history: PropTypes.object
}
