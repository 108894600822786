import React from 'react'
import { Link } from 'react-router-dom'

import { KascodeSelect } from '../components/KascodeSelect/KascodeSelect'
import TextareaAutosize from 'react-autosize-textarea'
import TypeSelector from '../components/TypeSelector/TypeSelector'
import { KascodeSelectGroup } from '../components/KascodeSelectGroup/KascodeSelectGroup'
import { MultilineText } from '../components/MultilineText/MultilineText'
import { EditableEntity } from '../components/EditableEntity/EditableEntity'
import FontEditor from '../components/FontEditor/FontEditor'
import { InterfaceExampleControl } from '../components/Interface/InterfaceExampleControl'
import { checkFieldPrivacy, getFullUriFromShort, nonCSCompare, renderURI, strToStars, versionToStr } from './index'
import { ObjectRating } from '../components/ObjectRating/ObjectRating'
import { MajorObjectBlock } from '../components/MajorObjectBlock/MajorObjectBlock'
import KeyIcon from '../components/KeyIcon/KeyIcon'
import { ObjectPicture } from '../components/ObjectPicture/ObjectPicture'
import { Person } from '../components/Person/Person'
import LocalProfile from '../components/LocalProfile/LocalProfile'
import { SimpleTooltip } from '../components/SimpleTooltip/SimpleTooltip'
import { HttpMethodBadge } from '../components/Badge/HttpMethodBadge'
import { fetchDataUrlImmediate } from './api'

import iColor from '../resources/images/color-select.png'
import { getDatasetData, getDatasetMetadata } from '../resources/lib'
import defaultUserPicture from '../resources/images/face-2x.png'
import iBorder from '../resources/images/border-2x.png'
import iText from '../resources/images/text-2x.png'

let nextRadioGroupId = 0

const KEY_BACKSPACE = 8

export const interfaceFunctions = [
  { id: 1, value: '1', label: 'Some function' },
  { id: 2, value: '2', label: 'Function 02' },
  { id: 3, value: '3', label: 'Function 03' },
  { id: 4, value: '4', label: 'Function 04' },
  { id: 5, value: '5', label: 'Function 05' },
  { id: 6, value: '6', label: 'Function 06' },
  { id: 7, value: '7', label: 'Function 07' },
  { id: 8, value: '8', label: 'Function 08' },
  { id: 9, value: '9', label: 'Function 09' },
  { id: 10, value: '10', label: 'Lorem Ipsum' },
  { id: 11, value: '11', label: 'Layout Name 01' },
  { id: 12, value: '12', label: 'Application' },
  { id: 13, value: '13', label: 'Layout Name 02' },
  { id: 14, value: '14', label: 'Layout Name 03' },
  { id: 15, value: '15', label: 'Function 15' },
  { id: 16, value: '16', label: 'sadas sadas' },
  { id: 17, value: '17', label: 'Function 17' },
  { id: 18, value: '18', label: 'Lorem Ipsum 18' }
]

function objectFormat(data, props) {
  return data ? JSON.stringify(data) : 'No data'
}

function stringFormat(data, props) {
  return <span className="StringData">{data}</span>
}

export function getValueRenderer(valueType) {
  if (!formatByType[valueType]) {
    //logger.warn(`Renderer for value type ${valueType} is not implemented`);
    return stringFormat
  }
  return formatByType[valueType]
}
function integerFormat(data, props) {
  //logger.info("format integerFormat", data, props);
  return <span className="StringData">{data + ''}</span>
}

function floatFormat(data, props) {
  return <span className="StringData">{data + ''}</span>
}

export const formatByType = {
  rating: (data) => {
    return <ObjectRating rating={data} showNumbers />
  },
  counter: (data) =>
    data ? <span className={data.length === 0 ? 'zero' : 'Counter Counter_subscriptions'}>{data.length}</span> : '',
  pubSubCounter: (data) =>
    !data ? null : (
      <span
        className={
          !data || (data.subscriptions === 0 && data.publications === 0)
            ? 'Counter'
            : data.subscriptions > 0
            ? 'Counter Counter_subscriptions'
            : 'Counter Counter_publications'
        }
      >
        {data && data.subscriptions > 0 ? data.subscriptions : data.publications}
      </span>
    ),
  string: (data, props, type) => {
    const displayStars = checkFieldPrivacy(type)

    if (typeof data === 'object' && data && !data.length) {
      if (data[props.locale]) {
        return (
          <span className="StringData" title={displayStars ? strToStars(data) : data}>
            {displayStars ? strToStars(data) : data}
          </span>
        )
      } else {
        return <span className="StringData">No data</span>
      }
    } else {
      return (
        <span
          className="StringData"
          title={data ? (displayStars ? strToStars(data) : data) : props ? props.placeholder : ''}
        >
          {data ? (displayStars ? strToStars(data) : data) : props ? props.placeholder : ''}
        </span>
      )
    }
  },
  datasetName: (data, props) => {
    if (typeof data === 'object' && !data.length) {
      if (data[props.locale]) return <span className="StringData">{data}</span>
      else return <span className="StringData">No data</span>
    } else {
      return (
        <MajorObjectBlock
          objectData={{ name: data }}
          type={data.objectType}
          clickHandler={props ? props.clickHandler : false}
          isMinorObject
        />
      )
      //return <span className="StringData DatasetName">{data}</span>;
    }
  },
  boolean: (data, props) => {
    //return <span className="StringData">{data ? 'yes' : 'no'}</span>;
    //return editByType.boolean(data, {onChange: (event) => {event.preventDefault()}, onBlur: ()=>{}});
    return (
      <span
        className={
          'CheckBox CheckBox__noedit' +
          (data === true || nonCSCompare(data, 'true') ? ' CheckBox_checked' : '') +
          (props.className ? ' ' + props.className : '')
        }
      />
    )
    //return editByType.boolean(data, props);
  },
  booleanLight: (data, props) => {
    if (data) return <span className="checkmark" />
  },
  float: floatFormat,
  double: floatFormat,
  decimal: floatFormat,
  integer: integerFormat,
  byte: integerFormat,
  small: integerFormat,
  short: integerFormat,
  long: integerFormat,
  huge: integerFormat,
  'unsigned short': integerFormat,
  'unsigned integer': integerFormat,
  'unsigned long': integerFormat,
  'unsigned huge': integerFormat,
  date: stringFormat,
  time: stringFormat,
  datetime: stringFormat,
  timespan: stringFormat,
  array: (data, props) => {
    return (
      <MultilineText
        text={data && data.map ? data.join('\n') : ''}
        maxLineCount="2"
        defaultLineHeight="19"
        {...props}
        title={props.columnName ? props.columnName : props.title}
      />
    )
  },
  key: (data) => <KeyIcon keyType={data} />,
  default: (data) => {
    return data
  },
  enum: (data, props) => {
    if (data) return <span className="StringData">{data}</span>
    else return <span className="StringData">No data</span>
  },
  objectBlock: (data, props) => {
    return !data ? (
      'No data'
    ) : (
      <MajorObjectBlock objectData={data} type={data.objectType} clickHandler={props ? props.clickHandler : false} />
    )
  },
  minorObjectBlock: (data, props) => {
    return (
      <MajorObjectBlock
        objectData={data}
        type={data.objectType}
        clickHandler={props ? props.clickHandler : false}
        isMinorObject
      />
    )
  },
  minorObjectNameNoMultiline: (data, props) => {
    return (
      <MajorObjectBlock
        objectData={data}
        type={data.objectType}
        clickHandler={props ? props.clickHandler : false}
        isMinorObject
        noMultiLine
      />
    )
  },
  objectName: (data, props) => {
    let str = '' //JSON.stringify(data);
    if (data) str = data
    else str = 'No data'
    return (
      <div>
        <ObjectPicture name={str} img={data.picture} className="ObjectPicture__inline" />
        {str}
      </div>
    )
  },
  text: (data, props) => {
    return (
      <MultilineText
        text={data}
        maxLineCount="2"
        defaultLineHeight="19"
        {...props}
        title={props.columnName ? props.columnName : props.title}
      />
    )
  },
  text_expanded: (data, props) => {
    return (
      <MultilineText
        text={data}
        {...props}
        title={props.columnName ? props.columnName : props.title}
        maxLineCount="5"
        defaultLineHeight="19"
      />
    )
  },
  text_updatable: (data, props) => {
    return (
      <MultilineText
        text={data}
        {...props}
        title={props.columnName ? props.columnName : props.title}
        maxLineCount="5"
        defaultLineHeight="19"
      />
    )
  },
  text_updatable_autosize: (data, props) => {
    return (
      <MultilineText
        text={data}
        {...props}
        title={props.columnName ? props.columnName : props.title}
        maxLineCount="5"
        defaultLineHeight="19"
      />
    )
  },
  text_fullsize: (data, props) => {
    return data
  },
  person_list: (data) => {
    //console.log("formatByType:person_list");
    return data.map((person) => <Person user={person} />)
  },
  person: (person) => <Person user={person} />,
  type: (type) => {
    ////console.log("formatByType type", type);
    // let res = '';
    // if (type && type.structName) {
    //   res += type.name + ': ' + type.structName;
    // } else if (type) {
    //   res += type.name;
    // }
    //
    // if (type.count) {
    //   if (type.count > 1)
    //     res += '[' + type.count + ']';
    //   else if (type.count === 0)
    //     res += '[]';
    // }

    return type.displayType
  },
  check_mark: (data, props) => {
    return editByType.boolean(data, {
      onChange: (event) => {
        event.preventDefault()
      }
    })
  },
  subscription_status: (data, props) => {
    return (
      <div className="row OrganizationList__subscriptionStatusOuter">
        <div className="col">
          <span className="OrganizationList__subscriptionStatus">{data.status}</span>
          {/*<span className="OrganizationList__subscriptionDaysRemaining">{data.daysRemaining} {data.daysRemaining > 0 ? ("day"+(data.daysRemaining != 1 ? "s" : "")+" remaining") : null}</span>*/}
          <span className="OrganizationList__subscriptionDaysRemaining">Free trial</span>
        </div>
        {/*<div className="col">*/}
        {/*<div className={"OrganizationList__subscriptionStatusBlock "+data.color}>{data.text ? data.text : ""}</div>*/}
        {/*</div>*/}
      </div>
    )
  },
  functions: (data, props) => {
    //console.log("formatByType function",data);
    return (
      <div className="FunctionData">
        {data && data.input ? (
          <div className="FunctionData__row">
            {data.input.map((func) => (
              <span className="FunctionData__item">
                {interfaceFunctions.filter((interfaceFunction) => interfaceFunction.id === func.id)[0].label}
              </span>
            ))}
          </div>
        ) : null}
        {data && data.output ? (
          <div className="FunctionData__row">
            {data.output.map((func) => (
              <span className="FunctionData__item">
                {interfaceFunctions.filter((interfaceFunction) => interfaceFunction.id === func.id)[0].label}
              </span>
            ))}
          </div>
        ) : null}
      </div>
    )
  },
  layouts: (data, props) => {
    //return JSON.stringify(data);
    //*
    //console.log("formatByType layouts", data);
    //   return (data || []).map((item, index) => <LayoutFields key={index} item={item} />);
    return !data ? '' : typeof data === 'object' ? data.map((s) => s.trim()).join(', ') : data
    //*/
  },
  data: (data, props) => {
    return data
  },
  '': () => '',
  reference: stringFormat,
  version: (data, props) => {
    if (!data) {
      return <span className="Version">{'1.0.0'}</span>
    } else if (data.current) {
      const versionStr = data.approved
        ? versionToStr(data.current) + ' A'
        : data.completion === 'finalized'
        ? versionToStr(data.current) + ' F'
        : versionToStr(data.current) + ' D'
      return (
        <span className="Version">
          {data.approved ? <span className="Version__Approved">{versionStr}</span> : <span>{versionStr}</span>}
        </span>
      )
    }
    if (data.last || data.approved) {
      const showDataLast = data.last && (!data.approved || versionToStr(data.last) != versionToStr(data.approved))
      return (
        <span className="Version">
          {showDataLast ? versionToStr(data.last) + (data.completion == 'finalized' ? ' F' : ' D') : ''}
          {showDataLast ? <br /> : ''}
          <span className="Version__Approved">{data.approved ? versionToStr(data.approved) + ' A' : ''}</span>
        </span>
      )
    } else
      return (
        <span className="Version">
          {typeof data == 'object' ? data.major + '.' + data.minor + '.' + data.revision : data}
        </span>
      )
  },
  alias: (data, props) => {
    // //console.log("ALias", data);
    let style = {}
    style.backgroundImage = 'url(' + data.picture + '), url(' + defaultUserPicture + ')'
    return (
      <div className="Alias">
        <div className="row">
          <div className="col Alias__photoCol">
            <div className="Alias__photo" style={style}></div>
          </div>
          <div className="col Alias__nameCol">{data.name}</div>
        </div>
      </div>
    )
  },
  uri: (data, props) => renderURI(data),
  taskName: (data, props) => (
    <span className={'StringData ' + (!data ? 'StringData__placeholder' : '')}>{data ? data : 'New task'}</span>
  ),
  tagName: (data, props) => (
    <span className={'StringData ' + (!data ? 'StringData__placeholder' : '')}>{data ? data : 'New tag'}</span>
  ),
  typeReference: (data, props) => {
    if (data.datasetReference) {
      let typeOuter = ''
      let typeInner = data.displayType
      let label = ''

      // todo: pass reference and type name and do not use regex to split them
      const re = new RegExp('(' + data.label + '([[0-9]*]:|:))')
      if ((label = data.displayType.match(re))) {
        typeOuter = label[1]
        typeInner = data.displayType.replace(label[1], '')
      }

      //logger.info("format typeReference", data, typeInner);
      return (
        <span className="TypeReference">
          {typeOuter} <Link to={'/view' + data.datasetReference.replace('$:', '')}>{renderURI(typeInner)}</Link>
        </span>
      )
    }

    //logger.info("format display type", data, data.displayType);

    return data.displayType
  },
  //'structure': (data, props) => typeof(data) === 'object' ? <JSONTree  data={data} shouldExpandNode={()=>true} /> : (data ? data : '{}'),
  structure: (data, props) => (
    <MultilineText
      text={typeof data === 'object' ? JSON.stringify(data) : data ? data : '{}'}
      maxLineCount="2"
      defaultLineHeight="18"
    />
  ),
  mixed: (data, props) => {
    if (!data || !data.type || !data.type.name || !'value' in data) return '' + data

    //console.log("formatByType mixed: type=",data.type,data.value);

    let typeName = data.type.name.toLowerCase()

    if (!(typeName in formatByType)) typeName = 'string'

    return formatByType[typeName](data.value, props, data.type)
  },
  custom_profile: (data, props) => {
    return <LocalProfile organization={data} />
  },
  department: (data, props) => <span className="DepartmentName">{data}</span>,
  nameAndDescription: (data, props) =>
    typeof data === 'object' ? <SimpleTooltip title={data.description}>{data.name}</SimpleTooltip> : data,
  propertyName: (data, props) => {
    if (typeof data === 'object') {
      if (data.description)
        return (
          <div className="DataDialog__propertyName">
            <div className="DataDialog__propertyNameText">
              <MultilineText text={data.name} maxLineCount={1} {...props} defaultLineHeight={20} />
            </div>
            <SimpleTooltip tipClassName={props.tipClassName ? props.tipClassName : ''} title={data.description}>
              <div className={(props.tipClassName ? props.tipClassName : 'DataDialog') + '__infoIcon'}></div>
            </SimpleTooltip>
          </div>
        )
      else return data.name
    } else return data
  },
  code: (data, props) => JSON.stringify(data),
  list: (data, props) => {
    //console.log("editable entity list", data, Array.isArray(data));
    if (!Array.isArray(data)) return null

    return <span>{data.join(', ')}</span>
  },
  /**
   *  Display badge with HTTP Method
   *  @param {String} data ["GET", "POST", "PUT", "PATCH", "DELETE"]
   */
  httpMethod: (data) => {
    return <HttpMethodBadge method={data} />
  },
  showExpandButton: (data) => {
    return (
      <div className={'TableRefExpander TableRefExpander_active'}>
        <div className="TableRefExpander__btn">
          <span>&ndash;</span>
        </div>
      </div>
    )
  },
  pathLink: (data) => {
    const fullUri = getFullUriFromShort(data)
    return (
      <span>
        <Link to={'/view' + fullUri}>{data}</Link>
      </span>
    )
  },
  guid: (data) => {
    return data
  },
  elementName: (data, props) => {
    return (
      <MajorObjectBlock
        objectData={data}
        type={data.objectType}
        clickHandler={props ? props.clickHandler : false}
        isMinorObject
        isGray={data.isGrayed}
      />
    )
    //return <div className={"View__elementName " + (data.isGrayed ? "View__elementName__gray" : "")}>{data.name}</div>;
  },
  department_icon: (data) => {
    if (data == 'parent') {
      return <div className="OrganizationList__departmentIconParent">&ndash;</div>
    } else if (data == 'department') {
      return <div className="OrganizationList__departmentIconDepartment"></div>
    } else {
      return <div className="OrganizationList__departmentIconNone"></div>
    }
  },
  radio: (data, props) => {
    nextRadioGroupId++

    return (
      <div className="Radio_container">
        {props.options.map((option) => {
          const optionId = 'Radio_' + nextRadioGroupId + '_' + option

          return (
            <span>
              <input
                type="radio"
                className="Input_radio"
                id={optionId}
                onChange={() => false}
                checked={data == option}
              />
              <label htmlFor={optionId}>{option}</label>
            </span>
          )
        })}
      </div>
    )
  },
  style_frame: (data, props) => {
    return data ? (
      <div className="View__StyleFrame">
        <a onClick={data.onFrameClick}>
          <img src={iBorder} />
        </a>
        <a onClick={data.onStyleClick}>
          <img src={iText} />
        </a>
      </div>
    ) : (
      ''
    )
  },
  border: objectFormat,
  background: objectFormat,
  color: objectFormat,
  lineStyle: objectFormat,
  font: objectFormat,
  money: stringFormat,
  preview: (data, props) => {
    if (data) return <img src={fetchDataUrlImmediate(data)} className="ImagePreview" />
    else return ''
  },
  exampleExecute: ({ onClick, toggleDiff, exampleId, status, responseJson, expectedJson, showDiff }) => {
    return (
      <InterfaceExampleControl
        status={status}
        exampleId={exampleId}
        onClick={onClick}
        toggleDiff={toggleDiff}
        resultJson={responseJson}
        expectedJson={expectedJson}
        showDiff={showDiff}
      />
    )
  },
  activityType: (data, props) => {
    return data ? (
      data === 'OPR' ? (
        <div className="ActivityType ActivityType__operation" title="Operation"></div>
      ) : data === 'TRN' ? (
        <div className="ActivityType ActivityType__transformation" title="Transformation"></div>
      ) : (
        ''
      )
    ) : (
      ''
    )
  }
}

function intKeypress(event) {
  let key = event.keyCode || event.which
  key = String.fromCharCode(key)
  let regex = /[0-9-]/
  if (!regex.test(key) && key != KEY_BACKSPACE) {
    event.returnValue = false
    if (event.preventDefault) event.preventDefault()
  }
}

function floatKeypress(event) {
  let key = event.keyCode || event.which
  key = String.fromCharCode(key)
  let regex = /[0-9.,-]/
  if (!regex.test(key) && key != KEY_BACKSPACE) {
    event.returnValue = false
    if (event.preventDefault) event.preventDefault()
  }
}

function integerEdit(data, props) {
  //logger.info("integerEdit", data, props);
  return (
    <input
      className={'TextInput' + (props.className ? ' ' + props.className : '')}
      type="text"
      value={data === null || data === undefined || data === false ? '' : data}
      autoFocus={props.autoFocus}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onKeyPress={intKeypress}
      placeholder={props.placeholder}
    />
  )
}

function floatEdit(data, props) {
  return (
    <input
      className={'TextInput' + (props.className ? ' ' + props.className : '')}
      type="text"
      value={data === null || data === undefined || data === false ? '' : data}
      autoFocus={props.autoFocus}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onKeyPress={floatKeypress}
      placeholder={props.placeholder}
    />
  )
}

function stringEdit(data, props) {
  //logger.info("stringEdit", data, props);
  return (
    <input
      className={'TextInput' + (props.className ? ' ' + props.className : '')}
      type="text"
      value={data === null || data === undefined || data === false ? '' : data}
      autoFocus={props.autoFocus}
      onChange={props.onChange}
      onBlur={props.onBlur}
      placeholder={props.placeholder}
    />
  )
}

export function getValueInput(valueType) {
  if (!editByType[valueType]) {
    //logger.warn(`Editor for value type ${valueType} is not implemented`);
    return stringEdit
  }
  return editByType[valueType]
}

export const editByType = {
  enum: (data, props, type) => {
    return (
      <KascodeSelect
        options={type.options}
        data={data}
        placeholder={props.placeholder ? props.placeholder : 'Choose option'}
        onValuesChange={props.onChange}
        onBlur={props.onBlur}
        onOpen={props.onOpen}
        hideCheckBox={/*props.hideCheckBox*/ !props.multi}
        multi={props.multi}
        isEditable={props.isEditable}
        activeOptions={props.activeOptions}
      />
    )
  },
  string: (data, props, type) => {
    //logger.info("Index:editByType:string", { data, props, type });
    return type.size === 0 ? (
      <textarea
        className={'TextArea' + (props.className ? ' ' + props.className : '')}
        value={data === null || data === undefined || data === false ? '' : data}
        autoFocus={props.autofocus}
        rows="3"
        onKeyUp={(event) => event.stopPropagation()}
        onKeyPress={(event) => event.stopPropagation()}
        onKeyDown={(event) => event.stopPropagation()}
        onChange={props.onChange}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
      ></textarea>
    ) : (
      <input
        className={'TextInput' + (props.className ? ' ' + props.className : '')}
        type={checkFieldPrivacy(type) ? 'password' : 'text'}
        value={data === null || data === undefined || data === false ? '' : data}
        autoFocus={props.autofocus}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={(event) => (props.onFocus ? props.onFocus(event) : null)}
        onKeyDown={(event) => (props.onKeyDown ? props.onKeyDown(event) : null)}
        placeholder={props.placeholder}
      />
    )
  },
  taskName: (data, props) => {
    return (
      <input
        className={'TextInput' + (props.className ? ' ' + props.className : '')}
        type="text"
        value={data}
        autoFocus="true"
        onChange={props.onChange}
        onBlur={props.onBlur}
        placeholder="New task"
        maxlength="255"
        onKeyPress={(event) => {
          if (event.keyCode == 13) props.onAdd()
        }}
      />
    )
  },
  tagName: (data, props) => {
    return (
      <input
        className={'TextInput' + (props.className ? ' ' + props.className : '')}
        type="text"
        value={data}
        autoFocus={props.autoFocus}
        onChange={props.onChange}
        onBlur={props.onBlur}
        placeholder="New tag"
        maxlength="255"
        onKeyPress={(event) => {
          if (event.keyCode == 13) props.onAdd()
        }}
      />
    )
  },
  //todo: 'int' must check number format
  integer: integerEdit,
  byte: integerEdit,
  small: integerEdit,
  short: integerEdit,
  long: integerEdit,
  huge: integerEdit,
  'unsigned short': integerEdit,
  'unsigned integer': integerEdit,
  'unsigned long': integerEdit,
  'unsigned huge': integerEdit,
  float: floatEdit,
  double: floatEdit,
  decimal: floatEdit,
  date: stringEdit,
  time: stringEdit,
  datetime: stringEdit,
  timespan: stringEdit,
  guid: stringEdit,
  boolean: (data, props) => {
    //console.log('editByType function BOOLEAN', data);
    return (
      <span
        className={
          'CheckBox' +
          (data === true || nonCSCompare(data, 'true') ? ' CheckBox_checked' : '') +
          (props.className ? ' ' + props.className : '')
        }
        onClick={() => {
          if (props.onChange)
            props.onChange({
              target: {
                //checked: !data
                value: data === true || nonCSCompare(data, 'true') ? false : true
              }
            })
        }}
      ></span>
    )
  },
  text: (data, props) => {
    return (
      <textarea
        autoFocus={props.autoFocus}
        className={'TextArea' + (props.className ? ' ' + props.className : '')}
        name=""
        id=""
        placeholder={props.placeholder ? props.placeholder : ''}
        defaultValue={data === null || data === undefined || data === false ? '' : data}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onKeyUp={(event) => event.stopPropagation()}
        onKeyPress={(event) => event.stopPropagation()}
        onKeyDown={(event) => event.stopPropagation()}
      />
    )
  },
  text_expanded: (data, props) => {
    return (
      <textarea
        autoFocus="true"
        className={'TextArea' + (props.className ? ' ' + props.className : '')}
        name=""
        id=""
        placeholder={props.placeholder ? props.placeholder : ''}
        defaultValue={data === null || data === undefined || data === false ? '' : data}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onKeyUp={(event) => event.stopPropagation()}
        onKeyPress={(event) => event.stopPropagation()}
        onKeyDown={(event) => event.stopPropagation()}
      />
    )
  },
  text_updatable: (data, props) => {
    return (
      <textarea
        autoFocus={props.autoFocus}
        className={'TextArea' + (props.className ? ' ' + props.className : '')}
        name=""
        id=""
        placeholder={props.placeholder ? props.placeholder : ''}
        value={data === null || data === undefined || data === false ? '' : data}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onKeyUp={(event) => event.stopPropagation()}
        onKeyPress={(event) => event.stopPropagation()}
        onKeyDown={(event) => event.stopPropagation()}
      />
    )
  },
  text_updatable_autosize: (data, props) => {
    return (
      <TextareaAutosize
        maxRows={10}
        autoFocus={props.autoFocus}
        className={'TextArea' + (props.className ? ' ' + props.className : '')}
        name=""
        id=""
        placeholder={props.placeholder ? props.placeholder : ''}
        value={data === null || data === undefined || data === false ? '' : data}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onKeyUp={(event) => event.stopPropagation()}
        onKeyPress={(event) => event.stopPropagation()}
        onKeyDown={(event) => event.stopPropagation()}
      />
    )
  },
  default: (data) => {
    return <input type="text" value={data} />
  },
  type: (data, props, type) => {
    return (
      <TypeSelector
        options={[
          { id: 1, label: 'boolean', value: { name: 'boolean' } },
          { id: 2, label: 'small', value: { name: 'small' } },
          { id: 3, label: 'short', value: { name: 'short' } },
          { id: 4, label: 'integer', value: { name: 'integer' } },
          { id: 5, label: 'long', value: { name: 'long' } },
          { id: 6, label: 'huge', value: { name: 'huge' } },
          { id: 7, label: 'byte', value: { name: 'byte' } },
          { id: 8, label: 'unsigned short', value: { name: 'unsigned short' } },
          {
            id: 9,
            label: 'unsigned integer',
            value: { name: 'unsigned integer' }
          },
          { id: 10, label: 'unsigned long', value: { name: 'unsigned long' } },
          { id: 11, label: 'unsigned huge', value: { name: 'unsigned huge' } },
          { id: 12, label: 'float', value: { name: 'float' } },
          { id: 13, label: 'double', value: { name: 'double' } },
          { id: 14, label: 'decimal', value: { name: 'decimal' } },
          { id: 15, label: 'string', value: { name: 'string' } },
          { id: 16, label: 'text', value: { name: 'text' } },
          { id: 17, label: 'date', value: { name: 'date' } },
          { id: 18, label: 'time', value: { name: 'time' } },
          { id: 19, label: 'DateTime', value: { name: 'datetime' } },
          { id: 20, label: 'TimeSpan', value: { name: 'timespan' } },
          { id: 21, label: 'guid', value: { name: 'guid' } },
          { id: 22, label: 'enum', value: { name: 'enum' } },
          { id: 23, label: 'field', value: { name: 'field' } },
          { id: 24, label: 'structure', value: { name: 'structure' } }
        ]}
        autoFocus={props.autoFocus}
        placeholder={'Select type'}
        onSelect={props.onChange}
        onBlur={props.onBlur}
        parentObjectType={props.parentObjectType}
        parentObjectId={props.parentObjectId}
        actions={props.actions}
        structName={props.structName}
      />
    )
  },
  typeReference: (data, props, type) => {
    return (
      <TypeSelector
        options={[
          { id: 1, label: 'boolean', value: { name: 'boolean' } },
          { id: 2, label: 'small', value: { name: 'small' } },
          { id: 3, label: 'short', value: { name: 'short' } },
          { id: 4, label: 'integer', value: { name: 'integer' } },
          { id: 5, label: 'long', value: { name: 'long' } },
          { id: 6, label: 'huge', value: { name: 'huge' } },
          { id: 7, label: 'byte', value: { name: 'byte' } },
          { id: 8, label: 'unsigned short', value: { name: 'unsigned short' } },
          {
            id: 9,
            label: 'unsigned integer',
            value: { name: 'unsigned integer' }
          },
          { id: 10, label: 'unsigned long', value: { name: 'unsigned long' } },
          { id: 11, label: 'unsigned huge', value: { name: 'unsigned huge' } },
          { id: 12, label: 'float', value: { name: 'float' } },
          { id: 13, label: 'double', value: { name: 'double' } },
          { id: 14, label: 'decimal', value: { name: 'decimal' } },
          { id: 15, label: 'string', value: { name: 'string' } },
          { id: 16, label: 'text', value: { name: 'text' } },
          { id: 17, label: 'date', value: { name: 'date' } },
          { id: 18, label: 'time', value: { name: 'time' } },
          { id: 19, label: 'DateTime', value: { name: 'datetime' } },
          { id: 20, label: 'TimeSpan', value: { name: 'timespan' } },
          { id: 21, label: 'guid', value: { name: 'guid' } },
          { id: 22, label: 'enum', value: { name: 'enum' } },
          { id: 23, label: 'field', value: { name: 'field' } },
          { id: 24, label: 'structure', value: { name: 'structure' } }
        ]}
        autoFocus={props.autoFocus}
        placeholder={'Select type'}
        onSelect={props.onChange}
        onBlur={props.onBlur}
        parentObjectType={props.parentObjectType}
        parentObjectId={props.parentObjectId}
        actions={props.actions}
        structName={props.structName}
      />
    )
  },
  minorObjectBlock: formatByType.minorObjectBlock,
  reference: (data, props, type) => {
    return <TypeSelector options={type.options} onSelect={props.onChange} autoFocus={props.autoFocus} />
  },
  functions: (data, props) => {
    ////console.log('editByType function', data);
    return (
      <div>
        <KascodeSelectGroup
          childrenProps={[
            {
              fieldName: 'input',
              options: interfaceFunctions,
              activeOptions: data.input.map((func) => func.id),
              multiColumn: true,
              placeholder: data.input.length > 0 ? data.input.map((func) => func.label).join(', ') : 'Select function',
              multi: true
            },
            {
              fieldName: 'output',
              options: interfaceFunctions,
              activeOptions: data.output.map((func) => func.id),
              multiColumn: true,
              placeholder:
                data.output.length > 0 ? data.output.map((func) => func.label).join(', ') : 'Select function',
              multi: true
            }
          ]}
          onValuesChange={props.onChange}
          onBlur={props.onBlur}
        />
      </div>
    )
  },
  layouts: (data, props) => {
    ////console.log("editByType layouts",data);
    //return JSON.stringify(data);
    //*
    return data ? (
      <div>
        {data.map((item, index) =>
          item ? (
            <div className="row EditableEntity__layoutRow" key={index}>
              <div className="col-xs-2">{item.identity.name}</div>
              <div className="col-xs-10">
                {item.fields && item.fields.length > 0
                  ? item.fields.map((field, index) => {
                      return <span key={index}>{field.name}&nbsp;</span>
                    })
                  : ''}
              </div>
            </div>
          ) : null
        )}
      </div>
    ) : (
      ''
    )
    //*/
  },
  structure: (data, props, type) => {
    return typeof data === 'object' ? (
      <MultilineText text={JSON.stringify(data)} maxLineCount="2" defaultLineHeight="18" />
    ) : data ? (
      data
    ) : (
      '{}'
    )
    // editing of structures is now handled by PropertyTable
    //return <StructureValueEditor data={data} dataProps={props} type={type}/>;
  },
  mixed: (data, props) => {
    if (!data || !data.type || !('value' in data)) return ''

    //console.log("formatByType mixed: type=",data.type,data.value);
    let typeName = data.type.name.toLowerCase()
    if (typeName.indexOf('enum') === 0) typeName = 'enum'

    if (!(typeName in editByType)) typeName = 'string'

    return editByType[typeName](
      data.value,
      Object.assign({}, props, {
        onChange: (event) => {
          props.onChange({
            target: { value: { type: data.type, value: event.target.value } }
          })
        }
      }),
      data.type
    )
  },
  radio: (data, props) => {
    nextRadioGroupId++

    return (
      <div className="Radio_container">
        {props.options.map((option) => {
          const optionId = 'Radio_' + nextRadioGroupId + '_' + option

          return (
            <span>
              <input
                name={'Radio_' + nextRadioGroupId}
                type="radio"
                className="Input_radio"
                id={optionId}
                onChange={() => props.onChange({ target: { value: option } })}
                checked={data == option}
              />
              <label htmlFor={optionId}>{option}</label>
            </span>
          )
        })}
      </div>
    )
  },
  border: (data, props) => {
    return (
      <div className="row">
        <div className="col-xs-12">
          <label className={'EditableEntity_color_label' + (props.className ? ' ' + props.className : '')}>
            Color:{' '}
          </label>
          <div className={'EditableEntity_color_value_right' + (props.className ? ' ' + props.className : '')}>
            <EditableEntity
              data={data ? data.color : ''}
              dataType={{ name: 'color' }}
              dataProps={{
                onChange: (e) =>
                  props.onChange({
                    target: {
                      value: Object.assign({}, data || {}, {
                        color: e.target.value
                      })
                    }
                  })
              }}
              isEditable
              inEditMode
            />
          </div>
        </div>
        <div className="col-xs-12">
          <label className={'EditableEntity_style_label' + (props.className ? ' ' + props.className : '')}>
            Line style:{' '}
          </label>
          <div className={'EditableEntity_style_value_right' + (props.className ? ' ' + props.className : '')}>
            <EditableEntity
              className="Border__inputLineStyle"
              data={data && data.style ? data.style : ''}
              dataType={{ name: 'lineStyle' }}
              dataProps={{
                placeholder: 'Choose line style',
                onChange: (e) =>
                  props.onChange({
                    target: {
                      value: Object.assign({}, data || {}, {
                        style: e.target.value
                      })
                    }
                  })
              }}
              isEditable
              inEditMode
            />
          </div>
        </div>
        <div className="col-xs-12">
          <label className={'EditableEntity_width_label' + (props.className ? ' ' + props.className : '')}>
            Width:{' '}
          </label>
          <div className={'EditableEntity_width_value_right' + (props.className ? ' ' + props.className : '')}>
            <EditableEntity
              className="Border__inputWidth"
              data={data ? data.width : ''}
              dataType={{ name: 'integer' }}
              dataProps={{
                placeholder: 'Choose width',
                onChange: (e) =>
                  props.onChange({
                    target: {
                      value: Object.assign({}, data || {}, {
                        width: e.target.value
                      })
                    }
                  })
              }}
              isEditable
              inEditMode
            />
          </div>
          <div className={'EditableEntity_width_value_size' + (props.className ? ' ' + props.className : '')}>px</div>
        </div>
      </div>
    )
  },
  background: (data, props) => {
    return (
      <div className="row">
        <div className="col-xs-12">
          <label className={'EditableEntity_color_label' + (props.className ? ' ' + props.className : '')}>
            Color:{' '}
          </label>
          <div className={'EditableEntity_color_value_right' + (props.className ? ' ' + props.className : '')}>
            <EditableEntity
              data={data ? data.color : ''}
              dataType={{ name: 'color' }}
              dataProps={{
                onChange: (e) => {
                  //console.log("background::onChange",e,e.target.value,data);
                  props.onChange({
                    target: {
                      value: Object.assign({}, data || {}, {
                        color: e.target.value
                      })
                    }
                  })
                }
              }}
              isEditable
              inEditMode
            />
          </div>
        </div>
      </div>
    )
  },
  color: (data, props) => {
    return (
      <div className="ColorInput">
        <input type="text" value={data} onChange={props.onChange} />
        <input
          className="ColorInput__colorPicker"
          type="color"
          value={data}
          onChange={props.onChange}
          placeholder="color number"
        />
        <img src={iColor} onClick={(e) => e.target.parentNode.querySelector('.ColorInput__colorPicker').click()} />
      </div>
    )
  },
  lineStyle: (data, props) => {
    //md.repo.metadata.apdax.systems.difhub.applications.view.datasets.linestyle
    const lineStyleOptions = getDatasetData(
      getDatasetMetadata('/organizations/Apdax/systems/Difhub/applications/View/datasets/LineStyle')
    )
    //console.log("lineStyleOptions", lineStyleOptions);
    return (
      <KascodeSelect
        options={lineStyleOptions.map((linestyle) => {
          return {
            id: linestyle.Code,
            label: linestyle.Style,
            value: linestyle.Style
          }
        })}
        placeholder={data ? data : 'Line style'}
        onValuesChange={props.onChange}
        onBlur={props.onBlur}
        hideCheckBox
        activeOptions={props.activeOptions}
      />
    )
  },
  font: (data, props) => {
    return <FontEditor data={data} props={props} />
  },
  money: stringEdit,
  exampleExecute: ({ interfaceExampleId, onClick }) => {
    return <InterfaceExampleControl exampleState={''} onClick={onClick} />
  }
}
