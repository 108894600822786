/**
 * Created by sashab on 15.05.2020.
 *
 * Component to manage output of pipeline activity.
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import logger from '../../helpers/logger'
import {
  deepCopy,
  nonCSCompare,
  referenceSort,
  findByIdentity,
  displayReferenceUri,
  storageDatasetUri,
  storageSubscriptionUri,
  editableState,
  datasetToEnumOptions,
  listOfMinorObjectsToEnumOptions
} from '../../helpers/index'
import { getObjectNew, getRequestFromPath, getRequestFromPathVersioned } from '../../helpers/api'
import md from '../../helpers/md'

import './OutputsDialog.scss'
import './OutputDialog.scss'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'

export class OutputDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    //logger.info("OutputDialog", this.state, this.props);
  }

  getObjectType() {
    return 'output'
  }

  /**
   * Returns minimum height for dialog
   */
  getMinHeight() {
    return 500
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/Pipeline/datasets/Output',
      '/organizations/Apdax/systems/DifHub/applications/Pipeline/views/OutputDialog'
    )

    //logger.info("OutputDialog:open", this.state, this.props);

    // Let's get types and remove operations from transform activities
    let objectTypes = this.getEntityMetadata(
      'dataset',
      '/organizations/Apdax/systems/DifHub/applications/Pipeline/datasets/OutputMode'
    )
    this.setState({
      objectTypes: nonCSCompare(this.props.activity.type, 'operation')
        ? datasetToEnumOptions(objectTypes).filter((op) => nonCSCompare(op.label, 'response'))
        : datasetToEnumOptions(objectTypes).filter((op) => !nonCSCompare(op.label, 'response'))
    })

    // Lets load common options for edit.
    if (this.props.isEditable > editableState.BROWSABLE) {
      this.loadStructure(this.state.output)
      //this.onAdjust(0, 565);
    }
  }

  /**
   * Load object stracture
   */
  loadStructure = (output) => {
    // Load current object
    //logger.info("OutputDialog:loadStructure", output, this.state, this.props);

    // Generate options for operation case. Here always only one operation to select.
    if (output && nonCSCompare(output.outputMode, 'response')) {
      if (this.props.majorObject && this.props.majorObject.activities && this.props.activity) {
        const activity = this.props.activity
        const path = activity.operation.reference
        getObjectNew(getRequestFromPathVersioned(path), 'inteface').then(
          (result) => {
            //logger.info("OutputDialog:loadStructure:INT", result);
            const outputOperation = result.operations.find((opr) =>
              nonCSCompare(opr.identity.name, activity.operation.components[0])
            )
            if (outputOperation) {
              let outputResponseOptions = outputOperation.responses.map((res, index) => {
                return {
                  id: index,
                  label: res.name,
                  value: res
                }
              })
              this.setState({
                objectOptions: outputResponseOptions,
                objectStructure: result
              })
            }
          },
          (error) => {
            logger.error('OutputDialog:loadStructure:ERROR', error)
          }
        )
      }
    }
  }

  /**
   * Get initial value of parameter
   * @param {object} [props]
   * @returns {*}
   */
  getInitial(props) {
    props = props || this.props
    //logger.info("OutputDialog:getInitialoutput", props);
    if (!props.output) {
      return {
        identity: {},
        outputMode: nonCSCompare(this.props.activity.type, 'operation') ? 'Response' : 'Dataset',
        component: {}
      } //createEmptyObject('output');
    }
    return deepCopy(props.output || {})
  }

  checkInputErrors = (output) => {
    let inputError = ''
    if (!output.identity || !output.identity.name) {
      inputError += 'Please specify output name!'
    } else if (
      this.props.activity.outputs &&
      !this.props.output &&
      this.props.activity.outputs.find((el) => nonCSCompare(el.identity ? el.identity.name : '', output.identity.name))
    ) {
      inputError += 'Output name must be unique inside activity!'
    } else if (!output || !output.outputMode) {
      inputError += 'Please select output mode!'
    } else if (!output.component || !output.component.reference) {
      inputError += 'Please select output!'
    } else if (!nonCSCompare(output.outputMode, 'operation') && !output.component.components) {
      inputError += 'Please select output components!'
    }
    return inputError
  }

  /**
   * Validate value of changed field
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @return true - change approved
   */
  onValidateChange = (element, field, value, output, option) => {
    logger.info('OutputDialog:onValidateChange', { element, field, value, output, option }, this.state, this.props)

    if (element === 'mode' && value) {
      output['component'] = { reference: '', subscription: '', components: [] }
      this.loadStructure(output)
    } else if (element === 'layout' && value) {
      // This is change of reference of major object we can delete components
      if (output.component && output.component.components && option && option.object.object) {
        delete output.component.components
      }
      // We need to convert it to array.
      if (output.component && output.component.layouts && !Array.isArray(output.component.layouts)) {
        output.component.layouts = [output.component.layouts]
      }
    } else if (element === 'output' && value) {
      if (output.component && output.component.components && !Array.isArray(output.component.components)) {
        output.component.components = [output.component.components]
      }
      this.loadStructure(output)
    } else if (element === 'response' && value) {
      if (output.component && output.component.components && !Array.isArray(output.component.components)) {
        output.component.components = [output.component.components]
      }
      this.loadStructure(output)
    } else if (element === 'fields' && value) {
      if (output.component && output.component.components && !Array.isArray(output.component.components)) {
        output.component.components = [output.component.components]
      }
    }
    return output
  }

  renderEditor = (editState) => {
    let output = this.state.output
    let isDataset = output.outputMode && nonCSCompare(output.outputMode, 'Dataset')
    let isOutput = output.outputMode && nonCSCompare(output.outputMode, 'Output')
    let isOperation = output.outputMode && nonCSCompare(output.outputMode, 'Response')
    //logger.info("OutputDialog:renderEditor", { editState, output, isDataset, isOutput }, this.state, this.props);

    return (
      <div className="OutputDialog" onClick={() => this.onCheckDialogHeight('.OutputDialog')}>
        {this.renderView(editState, (element) => {
          switch (element) {
            default:
              return null
            case 'layout':
              return !isDataset ? <div></div> : null
            case 'output':
              return !isOutput ? <div></div> : null
            case 'operation':
              return !isOperation ? <div></div> : null
            case 'fields':
              return !isDataset ? <div></div> : null
            //case 'operation':      return !output.type || !nonCSCompare(output.type, 'Output') ? (<div></div>) : null;
          }
        })}
      </div>
    )
  }
}

OutputDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  activity: PropTypes.object,
  output: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
