const scrollBlockKeys = {}
let allowedElement = null

function isDescendant(parent, child) {
  let node = child.parentNode

  while (node != null) {
    if (node === parent) {
      return true
    }
    node = node.parentNode
  }

  return false
}

function preventDefault(e) {
  e = e || window.event

  if (e.target && allowedElement && isDescendant(allowedElement, e.target)) {
    e.stopPropagation()
    return
  }

  if (e.preventDefault) e.preventDefault()
  e.returnValue = false
}

function preventDefaultForScrollKeys(e) {
  if (e.target && allowedElement && isDescendant(allowedElement, e.target)) return

  if (scrollBlockKeys[e.keyCode]) {
    preventDefault(e)
    return false
  }
}

export function disableScroll(elem) {
  allowedElement = elem
  if (window.addEventListener)
    // older FF
    window.addEventListener('DOMMouseScroll', preventDefault, false)

  window.onwheel = preventDefault // modern standard
  window.onmousewheel = document.onmousewheel = preventDefault // older browsers, IE
  window.ontouchmove = preventDefault // mobile
  document.onkeydown = preventDefaultForScrollKeys
}

export function enableScroll() {
  if (window.removeEventListener) window.removeEventListener('DOMMouseScroll', preventDefault, false)

  window.onmousewheel = document.onmousewheel = null
  window.onwheel = null
  window.ontouchmove = null
  document.onkeydown = null
}
