// import { ParamsType } from '../../paramsType'

export const getUrl = (params: any) => {
  if (params.applicationName) {
    return `/view/organizations/${params.organizationName}/systems/${params.systemName}/applications/${params.applicationName}`
  } else if (params.systemName) {
    return `/view/organizations/${params.organizationName}/systems/${params.systemName}`
  } else if (params.organizationName) {
    return `/view/organizations/${params.organizationName}`
  }
}
