import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ObjectSearch from '../ObjectSearch/ObjectSearch'

/* used only to test ObjectSearch component */

class SearchView extends Component {
  render() {
    return (
      <div className="row">
        <div
          className="col-xs-6 col-xs-offset-3"
          style={{
            marginTop: '40px'
          }}
        >
          <ObjectSearch types={['datasets', 'applications']} appState={this.props.appState} />
        </div>
      </div>
    )
  }
}

SearchView.propTypes = {
  appState: PropTypes.object
}

export default SearchView
