import cloneDeep from 'lodash/cloneDeep'
import uniqBy from 'lodash/uniqBy'
import { createEmptyObject } from '../../helpers/objectHelper'

export const normalizeTrackers = (trackers) => {
  return uniqBy(trackers, 'name')
}

export const clearIssue = (issue) => {
  const dirtyIssue = cloneDeep(issue)
  const toDelete = ['fullPriority', 'fullStatus', 'fullType', '_path']
  toDelete.forEach((property) => delete dirtyIssue[property])

  dirtyIssue.trackers = normalizeTrackers(dirtyIssue.trackers)

  return dirtyIssue
}

type Log = {
  name: string
  oldValue: any
  newValue: any
}

export const createLog = (prevIssue, cur): Log[] => {
  const prev = prevIssue || { identity: {}, assigned: {} } // previous object
  const log: Log[] = []

  //identity.description : string
  //tags, documents, properties, tasks : array of {identity.name}
  //type, priority, status, notes, resolution: string
  //assigned.name : string

  if (!cur) return log

  if (cur.identity && prev.identity.description !== cur.identity.description)
    log.push({
      name: 'subject',
      oldValue: prev.identity.description,
      newValue: cur.identity.description
    })
  const stringFields = ['type', 'priority', 'status', 'notes', 'resolution']
  for (let i in stringFields)
    if (prev[stringFields[i]] !== cur[stringFields[i]])
      log.push({
        name: stringFields[i],
        oldValue: prev[stringFields[i]],
        newValue: cur[stringFields[i]]
      })

  let preva = (normalizeTrackers(prev.trackers) || []).map((item: any) => item.name)
  let cura = (normalizeTrackers(cur.trackers) || []).map((item: any) => item.name)
  let added = cura.filter((item) => preva.indexOf(item) === -1 && item !== '')
  let deleted = preva.filter((item) => cura.indexOf(item) === -1)
  if (added.length > 0 || deleted.length > 0) {
    log.push({
      name: 'trackers',
      oldValue: deleted.join(', '),
      newValue: added.join(', ')
    })
  }

  const arrayFields = ['tags']

  for (let i in arrayFields) {
    const preva = ((prev.object && prev.object[arrayFields[i]]) || []).map((item) => item.name)
    const cura = ((cur.object && cur.object[arrayFields[i]]) || []).map((item) => item.name)
    const added = cura.filter((item) => preva.indexOf(item) === -1 && item !== '')
    const deleted = preva.filter((item) => cura.indexOf(item) === -1)
    if (added.length > 0 || deleted.length > 0) {
      log.push({
        name: arrayFields[i],
        oldValue: deleted.join(', '),
        newValue: added.join(', ')
      })
    }
  }

  const otherArrayFields = ['documents', 'properties']

  for (let i in otherArrayFields) {
    const preva = ((prev.object && prev.object[otherArrayFields[i]]) || []).map((item) => item.identity.name)
    const cura = ((cur.object && cur.object[otherArrayFields[i]]) || []).map((item) => item.identity.name)
    const added = cura.filter((item) => preva.indexOf(item) === -1 && item !== '')
    const deleted = preva.filter((item) => cura.indexOf(item) === -1)
    if (added.length > 0 || deleted.length > 0) {
      log.push({
        name: otherArrayFields[i],
        oldValue: deleted.join(', '),
        newValue: added.join(', ')
      })
    }
  }

  preva = (prev.tasks || []).map((item) => item.identity.description)
  cura = (cur.tasks || []).map((item) => item.identity.description)
  added = cura.filter((item) => preva.indexOf(item) === -1 && item !== '')
  deleted = preva.filter((item) => cura.indexOf(item) === -1)
  if (added.length > 0)
    log.push({
      name: 'tasks',
      oldValue: deleted.join(', '),
      newValue: added.join(', ')
    })
  const unchanged = cura.filter((item) => preva.indexOf(item) !== -1 && item !== '') // items that were not added or delete
  ////console.log("createLog() tasks unchanged=", unchanged);
  const checked = unchanged.filter(
    (itemName) =>
      (prev.tasks || []).filter((item) => item.identity.description === itemName)[0].check === false &&
      (cur.tasks || []).filter((item) => item.identity.description === itemName)[0].check === true
  )
  ////console.log("createLog() tasks checked=", checked);
  // checked - items that were not checked and now are checked
  const unchecked = unchanged.filter(
    (itemName) =>
      (prev.tasks || []).filter((item) => item.identity.description === itemName)[0].check === true &&
      (cur.tasks || []).filter((item) => item.identity.description === itemName)[0].check === false
  )
  // unchecked - items that were checked and now are unchecked
  ////console.log("createLog() tasks unchecked=", unchecked);
  if (checked.length > 0)
    log.push({
      name: 'Checked tasks',
      oldValue: '',
      newValue: checked.join(', ')
    })
  if (unchecked.length > 0)
    log.push({
      name: 'Unchecked tasks',
      oldValue: '',
      newValue: unchecked.join(', ')
    })

  if (cur.assigned && prev.assigned?.name !== cur.assigned?.name)
    log.push({
      name: 'assigned',
      oldValue: prev.assigned.name,
      newValue: cur.assigned.name
    })

  //console.log("createLog", log);

  return log
}

/**
 * Create document uri based on issue.identity.name(number) for editing issues
 * @param issueIdentity
 * @param fileName
 * @returns {string}
 */
export const getDocumentUri = (issueIdentity, fileName?: string) => {
  if (!fileName) {
    return issueIdentity && issueIdentity.name ? '/issues/' + issueIdentity.name + '/' : '/'
  }

  const issueUri = issueIdentity && issueIdentity.name ? '/issues/' + issueIdentity.name : ''
  return issueUri + (fileName.startsWith('/') ? fileName : '/' + fileName)
}

export const prepareMessage = (curIssue, prevIssue, user, message) => {
  if (!curIssue.attached) {
    curIssue.attached = {
      id: '3012c11f-23b3-4c59-9eef-e2b6dc6079a4',
      name: '/organizations/Apdax'
    }
  }

  const sender = {
    id: user.identity.id,
    name: user.identity.name,
    description: user.profile.alias
  }

  const objectToSend = Object.assign({}, message, {
    sender,
    issue: curIssue,
    log: { field: createLog(prevIssue, curIssue) }
  })
  delete objectToSend.receivers

  if (!prevIssue) {
    if (!objectToSend.attached && objectToSend.attached) objectToSend.attached = curIssue.attached
  }

  if (curIssue && curIssue.object && curIssue.object.documents) {
    const emptyDoc = createEmptyObject('document')
    // const emptyDoc = {};
    curIssue.object.documents = curIssue.object.documents.map((doc) => {
      return Object.assign({}, emptyDoc, doc, {
        uri: doc.uri || getDocumentUri(curIssue.identity, doc.identity.name)
      })
    })
  }

  return objectToSend
}
