export interface OrganizationItem {
  encryption?: string
  identity?: OrganizationIdentity
  object?: OrganizationObject
  userProfile?: string
  userRole?: string
  userRoleApproved?: string
  users?: [
    {
      user: {
        id: string
      }
    }
  ]
}

export interface OrganizationIdentity {
  id: string
  name: string
  description: string
}

export interface OrganizationObject {
  alias: string
  parent?: { id: string }
  tags: [
    {
      name: string
      description?: string
    }
  ]
  history: {
    completions: []
  }
  documents: []
}

export interface OrganizationsColumn {
  field: string
  headerName: string
  width?: string | number
}

export enum ActionEnum {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
  ADD = 'ADD'
}

export enum OrgModalActionEnum {
  CANCEL = 'CANCEL',
  DELETE = 'DELETE'
}

export type TableActionInterface = {
  action: ActionEnum
  title: string
  icon: any
}

export type ActionHandlerInterface = {
  [x in ActionEnum]: (item: any) => void
}

export type TableEventInterface = {
  action: ActionEnum
  data: any
}

export interface OrganizationCardData {
  name: string
  description: string
  status: string
  default: string
  role: string
  customProfile: string
  subStatus: string
  users?: any[]
  child?: OrganizationCardData[]
}

export interface OrganizationCardInterface {
  organization: OrganizationCardData
  onAction: (item: any) => void
  cardActions: any
}
