import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from 'reducers/rootState'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { clearObjectList, receiveObjectList, receiveObjectListIntoProperty } from 'actions/majorObjectActions'
import { editableState } from 'helpers'

import './Organizations.scss'

import {
  ActionEnum,
  ActionHandlerInterface,
  OrganizationCardData,
  OrganizationItem,
  OrgModalActionEnum,
  TableActionInterface,
  TableEventInterface
} from './OrganizationsTypes'

import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'

import { OrganizationCard } from './OrganizationCard'
import { Box, Button, ButtonGroup, CircularProgress, Typography } from '@material-ui/core'

import './OrganizationsTable.scss'
import { ModalDialog } from './ModalDialog'
import ObjectCreator from 'components/ObjectCreator/ObjectCreator'
import { JoinDialog } from 'components/JoinDialog/JoinDialog'
import { httpClient } from '../../helpers/httpClient'
import { createFormattedOrganization } from './OrganizationsTableHelper'
import { TreeItem, TreeView } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
})

export const OrganizationsTable: React.FC<any> = ({ actions, userState }) => {
  const classes = useStyles()
  const [org, setOrg] = useState<OrganizationCardData[]>([])
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [orgForModal, setOrgForModal] = useState<any>(null)
  const [addOpen, setAddOpen] = useState(false)
  const [addDepartmentOpen, setAddDepartmentOpen] = useState(false)
  const [parentOrgId, setParentOrgId] = useState(null)
  const [isJoinOpen, setIsJoinOpen] = useState(false)

  const dispatch = useDispatch()

  const { organizations } = useSelector((state: IRootState) => state.appState)
  const { deleteObject } = actions

  useEffect(() => {
    const createListForTable = (orgList: Array<OrganizationItem>) => {
      const formattedOrganizations = createFormattedOrganization(orgList)
      setOrg(formattedOrganizations)
    }

    organizations && createListForTable(organizations)
  }, [organizations])

  const getOrganizations = useCallback(
    async (forceReload = false) => {
      setLoading(true)
      dispatch(clearObjectList('organization'))

      const orgList = await httpClient.get<any[]>('/organizations').then(({ data }) => data)
      dispatch(receiveObjectList('organization', orgList))

      orgList.forEach((org) => {
        if (!org.userRoleApproved) {
          httpClient
            .get(`/organizations/${org.identity.name}/users`)
            .then(({ data }) => dispatch(receiveObjectListIntoProperty(data, org, 'organization', 'users')))
        }
      })

      setLoading(false)
    },
    [dispatch, actions]
  )

  const deleteOrg = useCallback(
    async (orgId, orgName) => {
      httpClient.delete(`/id/organizations/${orgId}`).then(
        (result) => {
          dispatch(deleteObject('organizations', orgName))
          getOrganizations()
        },
        (error) => {
          console.log('realDeleteOrganization', error)
        }
      )
    },
    [getOrganizations, deleteObject]
  )

  useEffect(() => {
    getOrganizations()
  }, [getOrganizations])

  const findOrgByName = (orgName) => {
    return organizations.find((org) => org.identity.name === orgName)
  }

  const orgActionHandlers: Partial<ActionHandlerInterface> = {
    [ActionEnum.ADD]: (item) => {
      setParentOrgId(findOrgByName(item.name)?.identity.id)
      setAddDepartmentOpen(true)
    },
    [ActionEnum.DELETE]: (item) => {
      const orgForRemove = findOrgByName(item.name)

      if (orgForRemove) {
        const orgName = orgForRemove?.identity.name
        const orgId = orgForRemove?.identity.id

        setOrgForModal({
          orgId,
          orgName
        })
        setModalTitle(`Delete: ${orgName}`)
        setModalContent(`You really want delete ${orgName}?`)
        setModalOpen(true)
      } else {
        console.log('org not exist')
      }
    }
  }

  const orgModalActionHandlers = {
    [OrgModalActionEnum.CANCEL]: (data) => {
      handleClose()
    },
    [OrgModalActionEnum.DELETE]: ({ orgId, orgName }) => {
      deleteOrg(orgId, orgName)
      setModalOpen(false)
    }
  }

  const handleCardEvent = (event: TableEventInterface) => {
    // @ts-ignore
    orgActionHandlers[event.action](event.data)
  }

  const handleModalEvent = (e) => {
    orgModalActionHandlers[e.action](e.data)
  }

  const cardActions: TableActionInterface[] = [
    {
      action: ActionEnum.ADD,
      title: 'Add',
      icon: <AddIcon />
    },
    {
      action: ActionEnum.DELETE,
      title: 'Delete',
      icon: <DeleteIcon />
    }
  ]

  const modalActions = [
    {
      title: 'Cancel',
      color: 'primary',
      action: OrgModalActionEnum.CANCEL,
      autoFocus: false
    },
    {
      title: 'Delete',
      color: 'primary',
      action: OrgModalActionEnum.DELETE,
      autoFocus: false
    }
  ]

  const handleClose = () => {
    setModalOpen(false)
  }

  const closeAdd = () => {
    setParentOrgId(null)
    setAddOpen(false)
    setAddDepartmentOpen(false)
  }

  const onAddOrganization = (organizationIdentity, onError) => {
    if (parentOrgId) {
      const parentOrg = organizations?.find((org) => org.identity.id === parentOrgId)
      organizationIdentity.identity.name = parentOrg.identity.name + ':' + organizationIdentity.identity.name
    }

    httpClient
      .post(
        '/organizations',
        Object.assign(
          {},
          organizationIdentity,
          parentOrgId
            ? {
                object: {
                  parent: {
                    id: parentOrgId
                  }
                }
              }
            : {}
        )
      )
      .then(
        (result) => {
          getOrganizations(true)
          setAddDepartmentOpen(false)
          setAddOpen(false)
        },
        (error) => {
          onError(error)
        }
      )
  }

  const closeJoin = () => {
    setIsJoinOpen(false)
  }

  return (
    <>
      <Box py={2} className="org__head">
        <Typography component="h2" variant="h6" gutterBottom>
          Organization List
        </Typography>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button onClick={() => setAddOpen(true)}>+ Add</Button>
          <Button onClick={() => setIsJoinOpen(true)}>~ Join</Button>
        </ButtonGroup>
      </Box>

      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {org.map((organization, index) => (
          <TreeItem
            nodeId={String(index)}
            label={
              <OrganizationCard
                key={index}
                organization={organization}
                onAction={handleCardEvent}
                cardActions={cardActions}
              />
            }
          >
            {organization?.child?.map((organization, index) => (
              <TreeItem
                nodeId={String(index)}
                label={
                  <OrganizationCard
                    key={index}
                    organization={organization}
                    onAction={handleCardEvent}
                    cardActions={cardActions}
                  />
                }
              ></TreeItem>
            ))}
          </TreeItem>
        ))}
      </TreeView>

      {loading && (
        <div className="org__center">
          <CircularProgress />
        </div>
      )}
      {modalOpen && (
        <ModalDialog
          onClose={handleClose}
          open={modalOpen}
          title={modalTitle}
          text={modalContent}
          object={orgForModal}
          actions={modalActions}
          onAction={handleModalEvent}
        />
      )}
      {addOpen && (
        <ObjectCreator
          isOpen={addOpen}
          childObjectType="organization"
          onSuccess={closeAdd}
          onCancel={closeAdd}
          customCreateObject={onAddOrganization}
          actions={actions}
        />
      )}
      {addDepartmentOpen && (
        <ObjectCreator
          parentObjectId={parentOrgId}
          isOpen={addDepartmentOpen}
          childObjectType="organization"
          onSuccess={closeAdd}
          onCancel={closeAdd}
          customCreateObject={onAddOrganization}
          actions={actions}
        />
      )}
      {isJoinOpen && (
        <JoinDialog
          actions={actions}
          action="RequestAccess"
          user={userState}
          isEditable={editableState.EDITING}
          isVisible={isJoinOpen}
          onSave={getOrganizations}
          onClose={closeJoin}
        />
      )}
      <Box pb={2} className="org__head">
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button onClick={() => setAddOpen(true)}>+ Add</Button>
          <Button onClick={() => setIsJoinOpen(true)}>~ Join</Button>
        </ButtonGroup>
      </Box>
    </>
  )
}
