import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { SimpleTooltip } from '../SimpleTooltip/SimpleTooltip'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import { Link } from 'react-router-dom'
import { API, idAPI } from '../../helpers/api'
import { Loader } from '../Loader/Loader'
import { getUserPhotoUrl } from '../../helpers'
import { fetchDataUrlImmediate } from '../../helpers/api'

import defaultUserPicture from '../../resources/images/face-2x.png'

import '../../styles/Person/Person.scss'
import { formatAlias } from '../../helpers/index'

export class Person extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModal: false
    }
  }

  openModal = () => {
    this.setState({ isModal: true })
    if (this.props.userId) {
      this.setState({ isLoading: true })
      idAPI
        .users(this.props.userId)
        .get()
        .then((user) => {
          this.setState({ user: user, isLoading: false })
        })
    }
  }

  closeModal() {
    this.setState({ isModal: false })
  }

  getFullName = () => {
    //if (this._fullName)
    //  return this._fullName;

    let res = ''
    let profile = this.props.user.profile
    if (this.props.userId && !this.state.isLoading) profile = this.state.user.profile

    ////console.log("Person getFullName profile", profile);

    if (profile.firstname) res += profile.firstname
    if (profile.lastname) res += ' ' + profile.lastname

    this._fullName = res

    return res
  }

  handleClick = () => {
    //console.log("Person:handleClick");
    if (this.props.onClick) this.props.onClick()
    else this.openModal()
  }

  renderModal(user) {
    let style = {}
    style.backgroundImage =
      'url(' +
      fetchDataUrlImmediate(getUserPhotoUrl(this.props.userId), this.forceUpdate.bind(this)) +
      '), url(' +
      defaultUserPicture +
      ')'

    return (
      <ModalWindow isOpen header="Information" onClose={this.closeModal.bind(this)} canClose className="Person__modal">
        <div className="row">
          <div className="col-xs-3">
            <div className="Person__picture" style={style}></div>
          </div>
          {this.state.isLoading ? (
            <Loader />
          ) : (
            <div className="col-xs-9">
              {'@' + user.profile.alias}
              <h1>{this.getFullName()}</h1>
              <div className="Person__role">
                {user.profile.position ? <div>{user.profile.position}</div> : ''}
                {user.employer ? <div>{user.employer}</div> : ''}
              </div>
              {user.profile.phone ? (
                <div>
                  <div className="Person__fieldName">Tel:</div>
                  {user.profile.phone}
                </div>
              ) : (
                ''
              )}
              {user.profile.email ? (
                <div>
                  <div className="Person__fieldName">Email:</div>
                  {user.profile.email}
                </div>
              ) : (
                ''
              )}
              {user.linkedin ? (
                <Link to={user.linkedin}>
                  <b>in</b> {user.linkedinText}
                </Link>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </ModalWindow>
    )
  }

  render() {
    let user = this.props.user
    if (this.state.isModal && this.props.userId && !this.state.isLoading) user = this.state.user

    return (
      <div className="Person__outer">
        {this.props.isExtended ? (
          <SimpleTooltip title={user.email}>
            <div className="Person" onClick={this.openModal}>
              {user.profile.alias
                ? formatAlias(user.profile.alias)
                : this.getFullName()
                ? this.getFullName()
                : 'Anonymous'}
            </div>
          </SimpleTooltip>
        ) : (
          <div className="Person Person__clickable" onClick={this.handleClick}>
            {user.profile && user.profile.alias
              ? formatAlias(user.profile.alias)
              : user.name
              ? user.name
              : user.profile && user.profile.alias
              ? user.profile.alias
              : 'Anonymous'}
          </div>
        )}
        {this.state.isModal ? this.renderModal(user) : ''}
      </div>
    )
  }
}

Person.propTypes = {
  user: PropTypes.object.isRequired,
  isExtended: PropTypes.bool,
  userId: PropTypes.string,
  onClick: PropTypes.func
}
