/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ObjectPicture } from '../ObjectPicture/ObjectPicture'
import { SimpleTooltip } from '../SimpleTooltip/SimpleTooltip'
import { MajorObjectLink } from '../MajorObjectLink/MajorObjectLink'
import { Loader } from '../Loader/Loader'

import '../../styles/Matrix/Matrix.scss'

import iArrowDownClose from '../../resources/images/arrow-down-close.png'
import iIcon from '../../resources/images/i-small-2x.png'
import iIconDark from '../../resources/images/i-dark-blue-2x.png'
import vUp from '../../resources/images/v-up-white-2x.png'
import vDown from '../../resources/images/v-down-white-2x.png'

export class Matrix extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sortOrder: 'asc',
      childVisibleItems: [],
      visibleItems: props.initialVisibleItems
    }
  }

  childHeightChange(index, count) {
    ////console.log("childHeightChange ",index,count);
    let cvi = this.state.childVisibleItems
    //for (let i = 0; i < this.props.items.length; i++)
    //  cvi[i] = count;
    //const dis = this.displayedItems ();
    //let k = dis.filter((x) => x.index === index)[0];
    cvi[index] = count
    if (index > 0) cvi[index - 1] = cvi[index - 1] ? Math.max(count, cvi[index - 1]) : count
    if (index < this.props.items.length - 1) cvi[index + 1] = cvi[index + 1] ? Math.max(count, cvi[index + 1]) : count
    //cvi[dis[k]]
    this.setState({ childVisibleItems: cvi })
  }

  sortAsc(a, b) {
    return a.title > b.title ? 1 : a.title < b.title ? -1 : 0
  }
  sortDesc(a, b) {
    return a.title < b.title ? 1 : a.title > b.title ? -1 : 0
  }

  displayedItems() {
    let sortFunc = this.state.sortOrder === 'asc' ? this.sortAsc : this.sortDesc
    let vi = this.props.showControls ? this.state.visibleItems : this.props.initialVisibleItems
    if (vi === -1) {
      return this.props.items.sort(sortFunc)
    } else {
      return this.props.items.sort(sortFunc).slice(0, vi)
    }
  }

  heightChangeUpdate(h) {
    if (this.props.showControls) {
      this.setState({ visibleItems: h })
    } else {
      if (!h) return
      if (this.props.onHeightChange) this.props.onHeightChange(this.props.index, h === -1 ? this.props.items.length : h)
    }
  }

  setSort(event) {
    this.setState({ sortOrder: event.target.value })
  }
  showMore() {
    this.heightChangeUpdate(-1)
  }

  showLess() {
    this.heightChangeUpdate(this.props.showControls ? this.props.initialVisibleItems : 3)
  }

  hideAll() {
    this.heightChangeUpdate(0)
  }

  renderMatrixControls() {
    if (this.props.fullCollapsable) {
      return (
        <div>
          {this.state.visibleItems > 0 && this.props.items.length > 0 ? (
            <a onClick={this.hideAll.bind(this)} className="btn_collapse">
              <img src={vUp} alt="" />
            </a>
          ) : null}
          {this.state.visibleItems <= 0 && this.props.items.length > 0 ? (
            <a onClick={this.showLess.bind(this)} className="btn_collapse">
              <img src={vDown} alt="" />
            </a>
          ) : null}
        </div>
      )
    }

    return null
  }

  render() {
    return (
      <div className="row">
        <div className={'Matrix ' + this.props.parentClass}>
          <div className="Matrix__header row">
            <ObjectPicture name={this.props.text} />
            <h1 className="Matrix__label">
              {this.props.headerLinkType !== '' ? (
                <MajorObjectLink object={this.props.headerItem} type={this.props.headerLinkType}>
                  {this.props.text}
                </MajorObjectLink>
              ) : (
                this.props.text
              )}
            </h1>
            <div className={'Matrix__controls' + (this.props.showControls ? '' : ' small')}>
              {this.props.showControls ? (
                <div className="Matrix__control-buttons">
                  <select onChange={this.setSort.bind(this)} className="select">
                    <option value="asc">Sort by A-Z</option>
                    <option value="desc">Sort by Z-A</option>
                  </select>
                  {this.state.visibleItems !== 0 ? (
                    <button onClick={this.hideAll.bind(this)} className="btn">
                      Close all
                    </button>
                  ) : (
                    <button onClick={this.showMore.bind(this)} className="btn">
                      Show all
                    </button>
                  )}
                </div>
              ) : (
                ''
              )}
              {this.props.description ? (
                <SimpleTooltip className="ToolTipWidth300" title={this.props.description}>
                  <img src={this.props.icon === 'dark' ? iIconDark : iIcon} className="InfoIcon" alt="icon" />
                </SimpleTooltip>
              ) : (
                ''
              )}
              {this.renderMatrixControls()}
            </div>
          </div>
          {this.displayedItems().length ? (
            <div className="Matrix__body row">
              {this.displayedItems().map((item, index) => {
                let itemFix = this.state.childVisibleItems[index]
                  ? { initialVisibleItems: this.state.childVisibleItems[index] }
                  : {}
                ////console.log(index,itemFix);
                itemFix = Object.assign(itemFix, {
                  onHeightChange: this.childHeightChange.bind(this),
                  index: index
                })
                return (
                  <div key={index} className={'Matrix__item ' + this.props.childClass}>
                    {React.cloneElement(item.body, itemFix)}
                  </div>
                )
              })}
            </div>
          ) : !this.props.loadFinished ? (
            <div className="loader_center">
              <Loader />
            </div>
          ) : null}
          {this.state.visibleItems !== 0 && this.displayedItems().length < this.props.items.length ? (
            <div className="Matrix__footer">
              {this.props.showControls ? (
                <div>Show more {this.props.items.length - this.displayedItems().length} items</div>
              ) : (
                ''
              )}
              <button onClick={this.showMore.bind(this)} className="Matrix__more">
                {' '}
                <span>
                  <img src={iArrowDownClose} alt="icon" />
                </span>
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }
}

Matrix.propTypes = {
  text: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.object,
  headerLinkType: PropTypes.string,
  initialVisibleItems: PropTypes.array,
  showControls: PropTypes.bool,
  fullCollapsable: PropTypes.bool,
  minVisibleItems: PropTypes.number,
  parentClass: PropTypes.string,
  childClass: PropTypes.string,
  onHeightChange: PropTypes.string,
  index: PropTypes.number,
  headerItem: PropTypes.object,
  icon: PropTypes.string,
  loadFinished: PropTypes.bool
}
