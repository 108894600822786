// https://github.com/andriyor/react-beautiful-dnd/blob/master/stories/src/reorder.js#L26-L26

// a little function to help us with reordering the result
const kanbanHelper = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const reorderMap = ({ cards, source, destination }: any): any => {
  const current: any[] = [...cards[source.droppableId].issues]
  const next: any[] = [...cards[destination.droppableId].issues]
  const target: any = current[source.index]

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered: any[] = kanbanHelper(current, source.index, destination.index)
    return {
      ...cards,
      [source.droppableId]: reordered
    }
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1)
  // insert into next
  next.splice(destination.index, 0, target)

  return {
    ...cards,
    [source.droppableId]: {
      ...cards[source.droppableId],
      issues: current
    },
    [destination.droppableId]: {
      ...cards[destination.droppableId],
      issues: next
    }
  }
}

export const defaultOrder = {
  priorityOrder: 'unorderedPriority',
  timeOrder: 'unorderedTime'
}

export const message = {
  class: 'Message', // bad idea to name property with reserved word in many languages
  type: 'Regular',
  text: ''
}
