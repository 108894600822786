/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

export const Header = () => (
  <div className="wrapper  header  wrapper_light">
    <section className="row">
      <div className="logo-container">
        <div className="logo-background" id="logo-background" />
        <a href="http://apdaxco.com/index.html" className="logo header__logo_white" id="logo">
          Metucat
        </a>
      </div>
      <ul className="nav header__nav">
        <li className="nav__link header__navEl active">
          <a href="http://apdaxco.com/index.html" className="header__navLinkDark">
            Product
          </a>
        </li>

        <div className="nav__submenu submenu_product">
          <ul>
            <li className="nav__sublink">
              <a href="http://apdaxco.com/index.html#metucat">Metucat</a>
            </li>
            <li className="nav__sublink">
              <a href="http://apdaxco.com/use_cases.html">Use cases</a>
            </li>
          </ul>
        </div>

        <li className="nav__link header__navEl">
          <a href="http://apdaxco.com/features2.html" className="header__navLinkDark">
            Features
          </a>
        </li>
        <div className="nav__submenu">
          <ul>
            <li className="nav__sublink">
              <a href="http://apdaxco.com/features2.html">Architecture</a>
            </li>
            <li className="nav__sublink">
              <a href="http://apdaxco.com/features.html#organizations">Design components</a>
            </li>
            <div className="nav__submenu_level2">
              <ul>
                <li className="nav__sublink">
                  <a href="http://apdaxco.com/features.html#organizations">Organizations</a>
                </li>
                <li className="nav__sublink">
                  <a href="http://apdaxco.com/features.html#systems">Systems</a>
                </li>
                <li className="nav__sublink">
                  <a href="http://apdaxco.com/features.html#applications">Applications</a>
                </li>

                <li className="nav__sublink">
                  <a href="http://apdaxco.com/features.html#publications">Publications</a>
                </li>
                <li className="nav__sublink">
                  <a href="http://apdaxco.com/features.html#subscriptions">Subscriptions</a>
                </li>
                <li className="nav__sublink">
                  <a href="http://apdaxco.com/features.html#collaboration">Collaboration</a>
                </li>
                <li className="nav__sublink">
                  <a href="http://apdaxco.com/features.html#json">JSON documents</a>
                </li>
              </ul>
            </div>
            <li className="nav__sublink">
              <a href="http://apdaxco.com/new_features.html#schema">JSON schemas</a>
            </li>
            <li className="nav__sublink">
              <a href="http://apdaxco.com/new_features.html#swagger">Export to Swagger</a>
            </li>
            <li className="nav__sublink">
              <a href="http://apdaxco.com/new_features.html#git">Git integration</a>
            </li>
            <li className="nav__sublink">
              <a href="http://apdaxco.com/new_features.html">New features</a>
            </li>
          </ul>
        </div>
        <li className="nav__link header__navEl">
          <a href="http://apdaxco.com/solutions.html" className="header__navLinkDark">
            Solutions
          </a>
        </li>
        <li className="nav__link header__navEl">
          <a href="http://apdaxco.com/about.html" className="header__navLinkDark">
            About Us
          </a>
        </li>
        <li className="nav__link header__navEl">
          <a href="http://apdaxco.com/contacts.html" className="header__navLinkDark">
            Support
          </a>
        </li>
        <div className="nav__submenu submenu_support">
          <ul>
            <li className="nav__sublink">
              <a href="http://apdaxco.com/contacts.html">Contacts</a>
            </li>
            <li className="nav__sublink">
              <a href="https://www.youtube.com/channel/UC7dpSfDdB1xxcrXPSiVHuAA" target="_blank" rel="noreferrer">
                Training videos
              </a>
            </li>
            <li className="nav__sublink">
              <a href="http://apdaxco.com/getting_started.html">Getting Started - Metucat</a>
            </li>
            <li className="nav__sublink">
              <a href="http://apdaxco.com/blog.html">Blog</a>
            </li>
          </ul>
        </div>
      </ul>
      <div className="header__buttons">
        <div className="header__buttonCol"></div>
        <div className="header__buttonCol  header__loginLink">
          <a href="http://apdaxco.com/demo.html" className="">
            Demo
          </a>
        </div>
      </div>
      <div className="header__menuButton"></div>
      <div className="header__menuBackground"></div>
      <div className="header__menu_outer">
        <div className="header__menu">
          <div className="header__menuLeft">
            <div className="header__menuItem ">
              <a className="arrow header__productButton">
                <img src="images/dark_arrow.svg" alt="icon" />
              </a>
              <a className="">Product</a>
            </div>
            <div className="header__menuItem">
              <a className="arrow header__featureButton">
                <img src="images/dark_arrow.svg" alt="icon" />
              </a>
              <a href="features2.html">Features</a>
            </div>
            <div className="header__menuItem">
              <a href="http://apdaxco.com/solutions.html">Solutions</a>
            </div>
            <div className="header__menuItem">
              <a href="http://apdaxco.com/about.html">About us</a>
            </div>
            <div className="header__menuItem">
              <a className="arrow header__supportButton">
                <img src="images/dark_arrow.svg" alt="icon" />
              </a>
              <a href="support.html">Support</a>
            </div>
            <div className="header__menuItem">
              <a href="/cognitologin">Login / Register</a>
            </div>
          </div>
        </div>

        <div className="header__menuProduct">
          <div className="header__menuLeft">
            <div className="header__menuItem header__menuItem__active">
              <a className="header__productButtonHide">Product</a>
              <a className="arrow arrow_right arrow_active header__productButtonHide">
                <img src="images/dark_arrow.svg" alt="icon" />
              </a>
            </div>
            <div className="header__menuItem">
              <a href="http://apdaxco.com/index.html#metucat">Metucat</a>
            </div>
            <div className="header__menuItem">
              <a href="http://apdaxco.com/use_cases.html">Use Cases</a>
            </div>
            <div className="header__menuItem">
              <a href="/cognitologin" target="_blank">
                Login / Register
              </a>
            </div>
          </div>
        </div>

        <div className="header__menuFeatures">
          <div className="header__menuRight">
            <a href="http://apdaxco.com/features2.html">
              <div className="header__menuSubItem">
                <div className="header__menuSubItemText">Architecture</div>
              </div>
            </a>
            <a href="http://apdaxco.com/features.html">
              <div className="header__menuSubItem">
                <div className="header__menuSubItemText">Design components</div>
              </div>
            </a>
            <a href="http://apdaxco.com/new_features.html#schema">
              <div className="header__menuSubItem">
                <div className="header__menuSubItemText">JSON schemas</div>
              </div>
            </a>
            <a href="http://apdaxco.com/new_features.html#swagger">
              <div className="header__menuSubItem">
                <div className="header__menuSubItemText">Export to Swagger</div>
              </div>
            </a>
            <a href="http://apdaxco.com/new_features.html#git">
              <div className="header__menuSubItem">
                <div className="header__menuSubItemText">Git integration</div>
              </div>
            </a>
            <a href="http://apdaxco.com/new_features.html">
              <div className="header__menuSubItem">
                <div className="header__menuSubItemText">New features</div>
              </div>
            </a>
          </div>
        </div>

        <div className="header__menuSupport">
          <div className="header__menuRight">
            <a href="http://apdaxco.com/contacts.html">
              <div className="header__menuSubItem">
                <div className="header__menuSubItemText">Contacts</div>
              </div>
            </a>
            <a href="https://www.youtube.com/channel/UC7dpSfDdB1xxcrXPSiVHuAA" target="_blank" rel="noreferrer">
              <div className="header__menuSubItem">
                <div className="header__menuSubItemText">Training videos</div>
              </div>
            </a>
            <a href="http://apdaxco.com/getting_started.html">
              <div className="header__menuSubItem">
                <div className="header__menuSubItemText">Getting Started - Metucat</div>
              </div>
            </a>
            <a href="http://apdaxco.com/blog.html">
              <div className="header__menuSubItem">
                <div className="header__menuSubItemText">Blog</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
)
