import React, { Component } from 'react'
import { HeaderPath } from '../HeaderPath/HeaderPath'
import SystemsView from '../SystemsView/SystemsView'
import { ErrorWindow } from '../ErrorWindow/ErrorWindow'
import { MainLayout } from '../../layouts/MainLayout/MainLayout'

import { buildPathDetailed } from '../../helpers'

import { getFullUri } from '../../helpers/index'

export class SystemsViewPage extends Component {
  render() {
    const appState = this.props.appState
    let defaultOrg = null
    if (appState.organizations)
      appState.organizations.forEach((org, index) => {
        if (!localStorage.getItem('currentOrganization') && index === 0) {
          defaultOrg = org
        }
        if (localStorage.getItem('currentOrganization') === org.identity.id) {
          defaultOrg = org
        }
      })

    return (
      <div className="SystemsView__root">
        <MainLayout userState={this.props.userState} appState={this.props.appState} actions={this.props.actions}>
          <ErrorWindow actions={this.props.actions} history={this.props.history} />

          <div className="SelectView__upper">SYSTEMS</div>
          {defaultOrg ? <HeaderPath path={buildPathDetailed(getFullUri(defaultOrg))} /> : null}
          <SystemsView userState={this.props.userState} appState={this.props.appState} actions={this.props.actions} />
        </MainLayout>
      </div>
    )
  }
}
