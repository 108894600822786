/**
 * Created by sashab on 05.10.2020.
 *
 * List of keys of dataset as a table to manage it.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { columnsToType, orderSort } from '../../helpers/index'
import { EditorListBase } from '../EditorDialog/EditorListBase'
import { KeyDialog } from './KeyDialog'
import './KeyList.scss'

export class KeyList extends EditorListBase {
  /**
   * Create new rule for editing
   * @return {object} empty element
   */
  createItem = (name) => {
    let objs = this.props.items || []

    let order = 0
    if (objs) {
      objs.forEach((f) => {
        order = order < f.order ? f.order : order
      })
    }
    order += 1
    return {
      identity: { name: name || '' },
      order: order,
      primary: true,
      fields: []
    }
  }

  /**
   * Return filters requered for item list
   */
  getFilters = () => {
    return [{ name: 'search', width: 220 }]
  }

  /**
   * Return columns requered for item list
   */
  getColumns = () => {
    return [
      {
        displayName: '#',
        name: 'order',
        type: columnsToType.getType('id'),
        frozen: true,
        width: 40,
        hiddenInChildTable: true
      },
      {
        name: 'name',
        displayName: 'Key Name',
        type: columnsToType.getType('minorObjectName'),
        width: 170,
        onCellClick: (value) => {
          this.onViewItem(value)
        }
      },
      {
        name: 'description',
        displayName: this.renderDescriptionColumnHeader(),
        type: this.getDescriptionType(),
        width: 230
      },
      {
        name: 'key',
        type: columnsToType.getType('key'),
        frozen: true,
        width: 40
      },
      { name: 'fields', type: { name: 'text' }, frozen: true, width: 285 }
    ]
  }

  /**
   * Return item data to render in table
   * @param {object} obj - item to render
   */
  getItem = (obj) => {
    //logger.info("EditorListBase:renderResoueceList:DATA", type, objs);
    return Object.assign({}, obj, {
      name: {
        name: obj.identity ? obj.identity.name : '',
        objectType: 'key'
      },
      description: obj.identity ? obj.identity.description : '',
      fields: obj.fields ? (Array.isArray(obj.fields) ? obj.fields.join(', ') : obj.fields) : '',
      key: obj.primary ? 'PRIMARY' : 'SECONDARY'
    })
  }

  /**
   * Return sorted items list to render in table
   * @param {object} items - items to render
   */
  sortItems = (items) => {
    //logger.info("DocList:sortItems", items);
    return items.sort(orderSort)
  }

  /**
   * Render dialog for item entry
   */
  renderItemDialog(editState, item, base, isEditable) {
    //logger.info("KeyList:renderItemDialog", editState, item, base, isEditable, this.props, this.state);

    return (
      <KeyDialog
        appState={this.props.appState}
        actions={this.props.actions}
        isVisible
        isEditable={isEditable}
        type={'key'}
        majorObject={this.props.majorObject}
        dkey={item}
        onEditableChange={this.onEditableChange}
        onClose={this.closeItem}
        onSave={(obj, closeDialog, onSent) => this.saveItem(obj, base, closeDialog, onSent)}
      />
    )
  }
}

KeyList.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isEditable: PropTypes.number, // State of editing in dialog
  majorObject: PropTypes.object.isRequired, // Major object we work against
  type: PropTypes.string.isRequired, // Type of object
  resource: PropTypes.object.isRequired // Resource object array
}
