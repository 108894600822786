import React from 'react'
import { ModalWindow } from '../ModalWindow/ModalWindow'

export const ErrorReportDialog = ({ onClose }) => {
  let data = ''

  let messageList = localStorage.messageList ? JSON.parse(localStorage.messageList) || [] : []

  data = messageList.join('\n')

  if (!data) {
    data = 'No debug data available'
  }

  return (
    <ModalWindow
      isOpen
      header={'Debug data for error report'}
      onClose={onClose}
      className="EditorDialog"
      footer={
        <div className="OrganizationList__deleteButtons">
          <button
            className="btn_big EditorDialog__button"
            onClick={() => {
              const area = document.getElementsByClassName('ErrorReportDialog__textarea')[0]
              area.select()
              document.execCommand('copy')
            }}
          >
            Copy
          </button>
          <button className="btn_big EditorDialog__button" onClick={onClose}>
            Close
          </button>
        </div>
      }
    >
      <textarea
        className="ErrorReportDialog__textarea"
        style={{
          width: '100%',
          height: '300px'
        }}
      >
        {data}
      </textarea>
    </ModalWindow>
  )
}
