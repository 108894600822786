import * as types from '../constants/ActionTypes'

export function addMessage(idIssue, message) {
  return {
    type: types.ADD_MESSAGE,
    id: idIssue,
    payload: message
  }
}

export type MessageActions = {
  addMessage
}
