/**
 * Created by kascode on 03.05.17.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { createEmptyObject, editableState, updateLocalObject, nonCSCompare } from '../../helpers/index'
import { getJsonSpecRecords } from '../../helpers/md'
import { Badge } from '../Badge/Badge'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { LocaleSelect } from '../LocaleSelect/LocaleSelect'
import './OperationDialog.scss'
import './ParameterDialog.scss'

export class OperationDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      addingParameter: false,
      addingResponse: false
    })
  }

  getObjectType() {
    return 'operation'
  }

  UNSAFE_componentWillReceiveProps() {}

  /**
   * Get initial value of parameter
   * @param {object} [props]
   * @returns {*}
   */
  getInitial(props) {
    props = props || this.props
    return props.operation ? props.operation : createEmptyObject('operation')
  }

  onNameChange = (event) => {
    let data = event.target.value
    let curr = this.getInitial(this.props).identity.name
    //console.log("ResponseDialog:onNameChange", data, this.state.operation);

    // Indicate save mode
    if (this.state.change && data !== curr) {
      this.state.change(true, '', '', '')
    }
    this.setState(
      updateLocalObject(
        Object.assign({}, this.state.operation, {
          identity: Object.assign({}, this.state.operation.identity, {
            name: data
          })
        })
      )
    )
  }

  onDescriptionChange = (event) => {
    let data = event.target.value
    let curr = this.getInitial(this.props).identity.description
    //console.log("ResponseDialog:onDescriptionChange", data, this.state.operation);

    // Indicate save mode
    if (this.state.change && data !== curr) {
      this.state.change(true, '', '', '')
    }
    this.setState(
      updateLocalObject(
        Object.assign({}, this.state.operation, {
          identity: Object.assign({}, this.state.operation.identity, {
            description: data
          })
        })
      )
    )
  }

  onDeprecatedChange = (event) => {
    let data = event.target.value
    let curr = this.getInitial(this.props).deprecated
    //console.log("ResponseDialog:onDeprecatedChange", data);

    // Indicate save mode
    if (this.state.change && data !== curr) {
      this.state.change(true, '', '', '')
    }
    this.setState(updateLocalObject(Object.assign({}, this.state.operation, { deprecated: data })))
  }

  setAction = (event) => {
    let data = event.target.value
    let curr = this.getInitial(this.props).action
    //console.log("ResponseDialog:setAction", data);

    // Indicate save mode
    if (this.state.change && data !== curr) {
      this.state.change(true, '', '', '')
    }
    this.setState(updateLocalObject(Object.assign({}, this.state.operation, { action: data })))
  }

  checkInputErrors(operation) {
    let inputError = ''
    const condition = this.props.majorObject.operations
    if (condition && operation.identity && operation.identity.name) {
      const checkOperationName = condition.find(
        (e) =>
          nonCSCompare(e.identity.name, operation.identity.name) && !nonCSCompare(e.identity.id, operation.identity.id)
      )
      if (checkOperationName) {
        inputError += 'Operation name already exist. '
      }
    }
    if (!operation.identity || !operation.identity.name || operation.identity.name.length === 0) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify operation name!'
    }
    if (
      this.props.majorObject &&
      this.props.majorObject.operations &&
      this.props.majorObject.object &&
      this.props.majorObject.object.usage === 'RESTful API'
    ) {
      let operations = this.props.majorObject.operations.filter(
        (op) => operation.action === op.action && operation.identity.id !== op.identity.id
      )

      if (operations && operations.length > 0) {
        inputError +=
          (inputError.length > 0 ? '\n' : '') +
          'Action "' +
          operation.action +
          '" already used. Please use other action for operation!'
      }
    }
    if (!operation.action) {
      inputError += 'Please select an action!'
    }
    return inputError
  }

  toggleParameterAdd = () => {
    let height = this.props.parameters.length * 35
    //console.log("OperationDialog:toggleParameterAdd", this.state, this.props, height);
    this.setState((prevState) => {
      return {
        height: prevState.height + (prevState.addingParameter ? -height : height),
        addingParameter: !prevState.addingParameter
      }
    })
  }

  toggleResponseAdd = () => {
    let height = this.props.parameters.length * 35
    //console.log("OperationDialog:toggleResponseAdd", this.state, this.props, height);
    this.setState((prevState) => {
      return {
        height: prevState.height + (prevState.addingResponse ? -height : height),
        addingResponse: !prevState.addingResponse
      }
    })
  }

  addParameter = (parameterName) => {
    const parameters = this.state.operation.parameters ? this.state.operation.parameters : []
    const parameter = this.props.parameters.find((param) => param.field.identity.name === parameterName)

    if (this.state.change && parameter) {
      this.state.change(true, '', '', '')
      parameters.push(parameter.field.identity)

      /*
      this.setState({
        operation: Object.assign({}, this.state.operation, {parameters})
      });
      */
      this.setState(updateLocalObject(Object.assign({}, this.state.operation, { parameters })))
    }
  }

  addResponse = (responseName) => {
    const responses = this.state.operation.responses ? this.state.operation.responses : []
    const response = this.props.responses.find(
      (resp) => resp && resp.field && resp.field.identity && resp.field.identity.name === responseName
    )

    if (this.state.change && response) {
      this.state.change(true, '', '', '')
      responses.push(response.field.identity)

      /*
      this.setState({
        operation: Object.assign({}, this.state.operation, {responses})
      });
      */
      this.setState(updateLocalObject(Object.assign({}, this.state.operation, { responses })))
    }
  }

  removeParameter = (parameterName) => {
    const parameters = this.state.operation.parameters.filter((param) => param.name !== parameterName)

    if (this.state.change) {
      this.state.change(true, '', '', '')

      /*
      this.setState({
        operation: Object.assign({}, this.state.operation, {parameters})
      });
      */
      this.setState(updateLocalObject(Object.assign({}, this.state.operation, { parameters })))
    }
  }

  removeResponse = (responseName) => {
    const responses = this.state.operation.responses.filter((resp) => resp.name !== responseName)

    if (this.state.change) {
      this.state.change(true, '', '', '')

      /*
      this.setState({
        operation: Object.assign({}, this.state.operation, {responses})
      });
      */
      this.setState(updateLocalObject(Object.assign({}, this.state.operation, { responses })))
    }
  }

  renderParameter = (parameter) => {
    return (
      <Badge className="Badge OperationChildBadge">
        <span className="Badge__text">{parameter.name}</span>
        <span
          className="Badge__cross"
          onClick={() => {
            this.removeParameter(parameter.name)
          }}
        >
          x
        </span>
      </Badge>
    )
  }

  renderResponse = (response) => {
    return (
      <Badge className="Badge OperationChildBadge">
        <span className="Badge__text">{response.name}</span>
        <span
          className="Badge__cross"
          onClick={() => {
            this.removeResponse(response.name)
          }}
        >
          x
        </span>
      </Badge>
    )
  }

  renderParameters = () => {
    //console.log("OperationDialog:renderParameters", this.state.operation.parameters, this.props.parameters);

    const parameterOptions = this.props.parameters
      .filter((param) => {
        let found = false
        let length = this.state.operation.parameters ? this.state.operation.parameters.length : 0

        for (let i = 0; i < length; i++) {
          //console.log("OperationDialog:renderParameters:OPTIONS", this.state.operation.parameters[i], param);
          if (this.state.operation.parameters[i].name === param.field.identity.name) {
            found = true
            break
          }
        }

        return !found
      })
      .map((param) => param.field.identity.name)

    return (
      <span className="OperationChildList">
        {this.state.operation.parameters ? this.state.operation.parameters.map(this.renderParameter) : null}
        <Badge
          className="Badge_add"
          color={this.state.addingParameter ? 'green_light' : 'green'}
          onClick={this.toggleParameterAdd}
        >
          +
        </Badge>
        <br />
        {this.state.addingParameter ? (
          <LocaleSelect
            options={parameterOptions}
            currentOption="Add parameter"
            onClick={this.addParameter}
            autoClose
            theme="classic"
          />
        ) : null}
      </span>
    )
  }

  renderResponses = () => {
    const responseOptions = this.props.responses
      .filter((resp) => {
        if (!resp.field) return false

        let found = false
        let length = this.state.operation.responses ? this.state.operation.responses.length : 0

        for (let i = 0; i < length; i++) {
          if (this.state.operation.responses[i].name === resp.field.identity.name) {
            found = true
            break
          }
        }

        return !found
      })
      .map((resp) => resp.field.identity.name)

    return (
      <span className="OperationChildList">
        {this.state.operation.responses ? this.state.operation.responses.map(this.renderResponse) : null}
        <Badge
          className="Badge_add"
          color={this.state.addingResponse ? 'green_light' : 'green'}
          onClick={this.toggleResponseAdd}
        >
          +
        </Badge>
        <br />
        {this.state.addingResponse ? (
          <LocaleSelect
            options={responseOptions}
            currentOption="Add response"
            onClick={this.addResponse}
            autoClose
            theme="classic"
          />
        ) : null}
      </span>
    )
  }

  /**
   * Render content of parameter editor dialog
   */
  renderEditor = (editState) => {
    // get action names
    const actions = getJsonSpecRecords('actions').map((action, i) => {
      return {
        id: action.values[1],
        label: action.values[0],
        value: action.values[0]
      }
    })

    const activeOption = actions.find((l) => l.value === this.state.operation.action)

    return (
      <div className="OperationDialog">
        <div className="EditorDialog__block clearfix">
          <label className="OperationDialog__label">Name:</label>
          <div className="value_right">
            <EditableEntity
              data={this.state.operation.identity.name}
              dataType={{ name: 'string' }}
              dataProps={{
                placeholder: 'Enter name',
                onChange: this.onNameChange
              }}
              isEditable
              inEditMode={editState > editableState.EDITABLE}
            />
          </div>
        </div>
        <div className="EditorDialog__block clearfix">
          <label className="OperationDialog__label">Description:</label>
          <div className="value_right">
            <EditableEntity
              data={this.state.operation.identity.description}
              dataType={{ name: 'text_updatable' }}
              dataProps={{
                placeholder: 'Enter description',
                onChange: this.onDescriptionChange
              }}
              isEditable
              inEditMode={editState > editableState.EDITABLE}
            />
          </div>
        </div>
        <div className="EditorDialog__block clearfix">
          <label className="OperationDialog__label">Deprecated:</label>
          <div className="value_right">
            <EditableEntity
              data={this.state.operation.deprecated}
              dataType={{ name: 'boolean' }}
              dataProps={{ onChange: this.onDeprecatedChange.bind(this) }}
              isEditable
              inEditMode={editState > editableState.EDITABLE}
            />
          </div>
        </div>
        <div className="EditorDialog__block clearfix">
          <label className="OperationDialog__label">Action:</label>
          <div className="value_right">
            <EditableEntity
              data={this.state.operation.action}
              dataType={{ name: 'enum', options: actions }}
              dataProps={Object.assign({
                onChange: this.setAction.bind(this),
                activeOptions: activeOption ? activeOption.id : null,
                placeholder: 'Choose action',
                hideCheckBox: true,
                multi: false
              })}
              isEditable
              inEditMode={editState > editableState.EDITABLE}
            />
          </div>
        </div>
        <div className="EditorDialog__block clearfix">
          <label className="OperationDialog__label">Parameters:</label>
          <div className="value_right">{this.renderParameters()}</div>
        </div>
        <div className="EditorDialog__block clearfix">
          <label className="OperationDialog__label">Responses:</label>
          <div className="value_right">{this.renderResponses()}</div>
        </div>
        {/*<div className="EditorDialog__block clearfix">*/}
        {/*<label className="label_left">Examples:</label>*/}
        {/*</div>*/}
      </div>
    )
  }
}

OperationDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  operation: PropTypes.object,
  parameters: PropTypes.arrayOf(PropTypes.object),
  responses: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
