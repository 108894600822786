/**
 * Created by mailf on 14.06.2016.
 */

import React from 'react'

import './style.scss'

type EditDialogButtonProps = {
  onEdit: () => void
  onSave: () => void
  onCancel: () => void
  inEditMode: boolean
  hideSave?: boolean
  editText?: string
  saveText: string
  theme?: string
  noIcon?: boolean
  className?: string
  cannotEdit?: boolean
  cannotEditHint?: any
}

export const EditDialogButton = ({
  onEdit,
  onSave,
  onCancel,
  inEditMode,
  editText,
  saveText,
  theme,
  noIcon,
  className,
  hideSave,
  cannotEdit,
  cannotEditHint
}: EditDialogButtonProps) => {
  let body: JSX.Element | string = ''
  if (inEditMode) {
    let save: JSX.Element | string = ''
    let cancel: JSX.Element | string = ''
    if (!hideSave) {
      save = (
        <span className="EditDialogButton__btn EditButton__save" onClick={onSave}>
          {!noIcon ? <span className="EditDialogButton__icon EditDialogButton__save"></span> : null}
          <span className="EditDialogButton__iconText">{saveText ? saveText : 'Save'}</span>
        </span>
      )
      cancel = (
        <span className="EditDialogButton__btn EditButton__cancel" onClick={onCancel}>
          {!noIcon ? <span className="EditDialogButton__icon EditDialogButton__cancel"></span> : null}
          <span className="EditDialogButton__iconText">Cancel</span>
        </span>
      )
    }
    body = (
      <div>
        {save}
        {cancel}
      </div>
    )
  } else {
    if (cannotEdit) {
      body = (
        <div className={'EditDialogButton__btn EditButton__edit  EditButton_noedit'} title={cannotEditHint}>
          {!noIcon ? <span className="EditDialogButton__icon EditDialogButton__noedit"></span> : null}
          <span className="EditDialogButton__iconText">{editText ? editText : 'Edit'}</span>
        </div>
      )
    } else {
      body = (
        <div className={'EditDialogButton__btn EditButton__edit'} onClick={onEdit}>
          {!noIcon ? <span className="EditDialogButton__icon EditDialogButton__edit"></span> : null}
          <span className="EditDialogButton__iconText">{editText ? editText : 'Edit'}</span>
        </div>
      )
    }
  }

  return (
    <div className={'EditButton' + (theme ? ' EditButton_' + theme : '') + ' ' + (className ? className : '')}>
      {body}
    </div>
  )
}
