/**
 * Created by Vlad Vovk on 18.06.2020.
 *
 * Viewer for dataset data. View hierarchical representation of dialog fields.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { dequal } from 'dequal'

import { getObjectNew, getRequestFromPathVersioned } from '../../helpers/api'
import {
  columnsToType,
  deepCopy,
  displayDatasetUri,
  fieldState,
  formatForStructureTable,
  nonCSCompare,
  usageIconUris
} from '../../helpers/index'
import logger from '../../helpers/logger'
import { FilterableContent } from '../FilterableContent/FilterableContent'
import { Loader } from '../Loader/Loader'
import { StructureTable } from '../NewTable/StructureTable'
import './DatasetViewer.scss'

export class DatasetViewer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      viewerDataset: null, // Dataset for view, this is dataset with filtered fields base on content spec.
      viewerPage: -1, // Current page for viewer to present. This is what is was setup when page change
      presentPage: -1, // Page we want to be show because of field need to be present. Negative means we don't have any.
      showSearch: false, // Search filter
      filterMapped: false, // Mapped filter
      fieldsMode: null, // State of rendered dataset's field
      fieldsState: {} // State of rendered dataset's field
    }

    this.checkedDatasetHeight = [] // list of dataset heights (dataset height is checked once to adjust dialog height)
    this.pageSize = 10 // pageSize

    this.changingPage = -1 // We want to prevent to many events changing page to create mess.
    // Right now structure in page content create lot of page change events
  }

  componentDidMount() {
    // Load required dataset
    this.loadDataset(this.props)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !dequal(nextProps, this.props) || !dequal(nextState, this.state)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Select page base on field need to be presented
    //logger.info("DatasetViewer:componentWillReceiveProps", { nextProps }, this.state, this.props);
    if (!dequal(nextProps.components, this.props.components)) {
      this.loadDataset(nextProps)
    } else {
      if (nextProps.presentField) {
        this.findPage(nextProps.presentField)
      }

      if (nextProps.fieldsState && !dequal(this.props.fieldsState, nextProps.fieldsState)) {
        this.setFieldsState(nextProps.fieldsState)
      }
    }
  }

  /**
   * set new fields state if it needed
   * @param fieldsState - array with fields state (HIDDEN, SELECTED etc)
   */
  setFieldsState = (fieldsState) => {
    const prefix = this.props.mode + '_' + this.props.object.identity.name + '/'

    //  clear all selected path and set new
    this.setState((prevState) => {
      let newFieldsState = this.props.selectMode === 'singleselect' ? {} : prevState.fieldsState
      logger.info('DatasetViewer:setFieldsState', { fieldsState, newFieldsState }, this.state, this.props)

      // transform data from array to newFieldState object
      Object.keys(fieldsState).forEach((field) => (newFieldsState[prefix + field] = fieldsState[field]))

      return {
        ...prevState,
        fieldsState: newFieldsState
      }
    })
  }

  /**
   * Requests dataset if it is not found
   */
  loadDataset = (props) => {
    // Load Datast for viewer to present if not loaded yet.
    const prefix = this.props.mode + '_' + this.props.object.identity.name + '/'

    let components = props.components || []
    let state = deepCopy(props.fieldsState)
    let mode = {}

    // Let's see if we have hierarchical components.
    let count = 0
    components.forEach((field) => {
      let el = field.split('.')
      let key = el[0]
      mode[prefix + key] = fieldState.ENABLED
      for (let i = 1, length = el.length; i < length; i++) {
        key += '.' + el[i]
        mode[prefix + key] = fieldState.ENABLED
        count++
      }
    })

    // We will need to specify mode structure if we have hierarchical
    // component specification.
    if (count === 0) {
      mode = null
    }

    //logger.info("DatasetViewer:loadDataset", props.reference, { props, components }, this.state, props);
    if (props.dataset) {
      let dataset = deepCopy(props.dataset)
      dataset.structure.fields = dataset.structure.fields.filter(
        (field) => components.findIndex((el) => nonCSCompare(el.split('.')[0], field.identity.name)) !== -1
      )
      logger.info('DatasetViewer:loadDataset:FILTER', { dataset, state, mode }, this.state, props)

      // Set it as an object for viewer.
      this.setState({ viewerDataset: dataset, fieldsState: state, fieldsMode: mode }, () => {
        this.findPage(props ? props.presentField : '')
      })
    } else if (props.reference) {
      getObjectNew(getRequestFromPathVersioned(props.reference), 'dataset').then(
        (object) => {
          //Filter fields we need to represent on first level
          let dataset = deepCopy(object)
          dataset.structure.fields = dataset.structure.fields.filter(
            (field) => components.findIndex((el) => nonCSCompare(el.split('.')[0], field.identity.name)) !== -1
          )
          logger.info('DatasetViewer:loadDataset:LOADED', { props, object, dataset, state, mode }, this.state, props)

          // Set it as an object for viewer.
          this.setState({ viewerDataset: dataset, fieldsState: state, fieldsMode: mode }, () => {
            this.findPage(props ? props.presentField : '')
          })
        },
        (error) => {
          logger.warn('DatasetViewer:loadDataset:ERROR', { props, error }, this.state, props)
          this.setState({ viewerDataset: {} })
        }
      )
    }
  }

  /**
   * Find number of page, when click on line
   * @param {string} field - field we want to present
   */
  findPage = (field) => {
    //logger.info("DatasetViewer:findPage", { field }, this.state, this.props);
    //logger.info("DatasetViewer:findPage:TRACE", Error().stack);
    if (
      !field ||
      !this.state.viewerDataset ||
      !this.state.viewerDataset.structure ||
      !this.state.viewerDataset.structure.fields
    ) {
      return
    }

    // Lets setup right page.
    const fields = this.state.viewerDataset.structure.fields
    const fieldPosition = fields.findIndex((item) => nonCSCompare(item.identity.name, field))
    //logger.info("DatasetViewer:findPage:POSITION", { fields, field, fieldPosition }, this.state, this.props);
    if (fieldPosition !== -1) {
      const pageIndex = Math.floor(fieldPosition / this.pageSize)
      if (this.state.presentPage !== pageIndex) {
        logger.info('DatasetViewer:findPage:PAGE', { field, fieldPosition, pageIndex }, this.state, this.props)
        this.setState({ presentPage: pageIndex })
      }
    }
  }

  /**
   * Report change of page in the content of dataset viewer
   * @returns {*}
   * @param pageIndex
   * @param visiblePages
   * @param beforePages
   * @param afterPages
   * @param divId
   */
  onPageChange = (pageIndex, visiblePages, beforePages, afterPages, divId) => {
    //Check if we already here and don't need to react to page change.
    if (this.state.viewerPage === pageIndex) {
      return
    }

    // Let's block chaning too many page changes
    if (this.changingPage === pageIndex) {
      return
    }
    this.changingPage = pageIndex

    logger.info(
      'DatasetViewer:onPageChange',
      { pageIndex, visiblePages, beforePages, afterPages, divId },
      this.state,
      this.props
    )

    this.setState(
      {
        viewerPage: pageIndex
      },
      () => {
        if (this.changingPage === pageIndex) {
          this.changingPage = -1
        }
      }
    )

    // Propogate page change
    if (this.props.onPageChange) {
      this.props.onPageChange(
        this.props.mode,
        this.props.object,
        pageIndex,
        visiblePages,
        beforePages,
        afterPages,
        divId
      )
    }
  }

  /**
   * When user click on field in structured table
   * @param editState
   * @param divId - id  for dataset viewer
   * @param mode - mode of dataset viewer
   * @param object
   * @param path
   * @param field
   * @param cell
   */
  onFieldClick = (editState, divId, mode, object, path, field, cell) => {
    logger.log('DatasetViewer::onFieldClick', { divId, mode, object, path, field, cell }, this.state, this.props)

    if (this.props.onFieldClick) {
      // We will tagle selection if we have selection mode
      if (this.props.selectMode) {
        if (cell === 0) {
          // We not change selection when click on expend only propogate
          this.props.onFieldClick(editState, mode, object, path, field, cell)
          return
        }

        this.setState(
          (prev) => {
            // check if we select this field already, or not and taggle
            const newFieldState = prev.fieldsState[path] === fieldState.SELECTED ? 0 : fieldState.SELECTED

            if (this.props.selectMode === 'multiselect') {
              // We support multiselect mode.
              return {
                ...prev,
                fieldsState: {
                  ...prev.fieldsState,
                  [path]: newFieldState
                }
              }
            } else if (this.props.selectMode === 'singleselect') {
              // We support single select mode and select different field, need unselect if something was
              // selected before.
              let newState = {}
              // eslint-disable-next-line array-callback-return
              Object.entries(prev.fieldsState).map((key) => {
                if (key[1] !== fieldState.SELECTED) {
                  newState[key[0]] = key[1]
                }
              })
              if (newFieldState) {
                newState[path] = newFieldState
              }

              return {
                ...prev,
                fieldsState: newState
              }
            }
          },
          () => {
            // this callback we need, because setState is async, and we need always latest state, before we send it in this.props.onFieldClick

            // transform this.state.fieldsState object to array, and then filter only selected fields
            const onlySelectedFields = Object.keys(this.state.fieldsState).filter(
              (key) => this.state.fieldsState[key] === fieldState.SELECTED
            )

            this.props.onFieldClick(editState, mode, object, path, field, cell, onlySelectedFields)
          }
        )
      } else {
        // Propogate event even if we not support selection.
        this.props.onFieldClick(editState, mode, object, path, field, cell)
      }
    }
  }

  /**
   * After child table was expanded, adjust height
   * @param divId
   * @param mode
   * @param object
   * @param timeout
   */
  onTableExpand = (divId, mode, object, timeout) => {
    // logger.log('DatasetViewer::onTableExpand', { divId, mode, object, timeout, prevHeight }, this.state, this.props);

    if (this.props.onPageExpand) {
      this.props.onPageExpand(mode, object, this.state.viewerPage, divId)
    }
  }

  /**
   * After child dataset is loaded propogate event
   * @param divId
   * @param mode
   * @param object
   * @param timeout
   */
  onTableLoad = (divId, mode, object, timeout) => {
    // logger.log('DatasetViewer::onTableExpand', { divId, mode, object, timeout}, this.state, this.props);

    if (this.props.onPageLoad) {
      this.props.onPageLoad(mode, object, this.state.viewerPage, divId)
    }
  }

  /**
   * After child table was scrolled
   * @param divId
   * @param mode
   * @param object
   * @param timeout
   */
  onTableScroll = (divId, mode, object, timeout) => {
    // logger.log('DatasetViewer::onTableScroll', { divId, mode, object, timeout}, this.state, this.props);

    if (this.props.onPageExpand) {
      this.props.onPageScroll(mode, object, this.state.viewerPage, divId)
    }
  }

  /**
   * Converts path (example Person.name) to DOM element id
   * @param mode
   * @param path
   * @returns {string}
   */
  pathToElementId = (mode, path) => {
    return mode + '_' + path
  }

  /**
   * Toggle search control
   */
  toggleSearchVisible = () => {
    let newShowSearch = this.state.showSearch
    newShowSearch = !newShowSearch

    this.setState({ showSearch: newShowSearch })

    // it needed for update mapping draw
    if (this.props.onPageExpand) {
      this.props.onPageExpand()
    }
  }

  /**
   * Select or unselected all fields
   */
  toggleSelected = () => {
    this.setState(
      (prevState) => {
        let newFieldsState = prevState.fieldsState
        const components = this.props.components
        const prefix = this.props.mode + '_' + this.props.object.identity.name + '/'

        const isAnySelected = Object.values(newFieldsState).length
          ? Object.values(newFieldsState).some((field) => field === fieldState.SELECTED)
          : false
        logger.log(
          'DatasetViewer::toggleSelected',
          { newFieldsState, components, prefix, isAnySelected },
          this.state,
          this.props
        )

        if (isAnySelected) {
          // if we have any selected items, then we must unselected it
          Object.keys(newFieldsState).forEach((item) => {
            if (newFieldsState[item] === fieldState.SELECTED) newFieldsState[item] = 0
          })
        } else {
          // else we select all components we have
          components.forEach((item) => {
            if (!newFieldsState[prefix + item]) newFieldsState[prefix + item] = fieldState.SELECTED
          })
        }

        //logger.log('DatasetViewer::toggleSelected:TAGGLE', { newFieldsState, components, prefix, isAnySelected }, this.state, this.props);

        return {
          ...prevState,
          fieldsState: newFieldsState
        }
      },
      () => {
        if (this.props.selectMode === 'multiselect') {
          const fieldsStateArray = Object.keys(this.state.fieldsState).filter(
            (key) => this.state.fieldsState[key] === fieldState.SELECTED
          )
          this.props.onFieldClick(true, true, true, true, true, 1, fieldsStateArray)
        }
      }
    )
  }

  /**
   * Displays dataset (StructureTable with onClick handler)
   * @returns {*}
   */
  renderDataset = () => {
    let { editState, mode, object, level = 0, path = '' } = this.props
    let data = this.state.viewerDataset

    // if dataset was not loaded yet, show Loader
    if (!data) {
      return <Loader />
    }

    // divId is used to process field clicks
    const divId = 'dataset_' + mode + '_' + object.identity.name

    // set initial path for root level datasets
    if (path === '') {
      path += mode + '_' + object.identity.name + '/'
    }

    const backgroundImage =
      'url("/resources/images/usage/24/' + usageIconUris[data.object?.usage.toLowerCase()] + '.png"'
    // logger.info("DatasetViewer:renderDataset", { editState, reference, mode, object, data, level, path, divId }, this.state, this.props);

    if (!data.object) {
      return <Loader />
    }

    return (
      <div
        id={divId}
        className={
          (level > 0 ? 'DatasetViewer table_' + level : 'DatasetViewer table') +
          (' table_' + mode) +
          (this.props.editState > 1 ? ' DatasetViewer--editing' : '')
        }
        onScroll={() => this.onTableScroll(divId, mode, object, 0)}
      >
        <div className="DatasetViewer__container">
          <table>
            <thead>
              <tr>
                <td className="title" colSpan="2" style={{ backgroundColor: 'rgb(4, 193, 180)' }}>
                  <div
                    className="ObjectPicture"
                    style={{
                      backgroundImage: backgroundImage,
                      backgroundSize: '24px 24px',
                      backgroundPosition: '-2px -2px',
                      backgroundRepeat: 'no-repeat',
                      color: 'transparent'
                    }}
                  >
                    x
                  </div>
                  <span className="table_header">
                    {object.identity.name + ' [' + data.object ? displayDatasetUri(data) : '' + ']'}
                  </span>
                  <div
                    className={
                      'DatasetViewer__search ' + (this.state.showSearch ? 'DatasetViewer__search__active' : '')
                    }
                    onClick={this.toggleSearchVisible}
                  />
                  <div
                    className={
                      'DatasetViewer__filter' + (this.state.filterMapped ? 'DatasetViewer__filter__active' : '')
                    }
                    onClick={this.toggleSelected}
                  />
                </td>
              </tr>
            </thead>
          </table>
          {/* <ScrollArea
        onScroll={() => this.onTableScroll(divId, mode, object, 0)}
        > */}
          <FilterableContent
            data={formatForStructureTable(data)}
            dataFilters={this.state.showSearch ? [{ name: 'search', width: 389 }] : []}
            parentUri={this.props.path + '/Map/datasets/' + data.identity.name + '/' + mode}
            onChangeFilter={() => {
              //, {name: 'not_mapped', displayName: 'Not Mapped', width: 110}
              //console.log("div.table::onChangeFilter");
              setTimeout(() => {
                this.forceUpdate()
              }, 100)
            }}
            noLocalStorage
          >
            <StructureTable
              columns={[
                {
                  displayName: ' ',
                  name: 'expandButton',
                  type: { name: 'data' },
                  frozen: true,
                  width: 30
                },
                {
                  displayName: ' ',
                  name: 'name',
                  type: columnsToType.getType('minorObjectNameNoMultiline'),
                  width: 411 - 30 - 80 - 50
                },
                {
                  displayName: ' ',
                  name: 'type',
                  type: columnsToType.getType('typeReference'),
                  width: 130
                }
              ]}
              hideHeader
              parentUri={this.props.path}
              tablePath={path}
              fieldMode={this.state.fieldsMode}
              fieldState={this.state.fieldsState}
              onFieldClick={(rowPath, rowData, cellIndex) =>
                this.onFieldClick(editState, divId, mode, object, rowPath, rowData, cellIndex)
              }
              onExpand={() => this.onTableExpand(divId, mode, object, 300)}
              onLoad={() => this.onTableLoad(divId, mode, object, 300)}
              onPageChange={this.onPageChange}
              pageSize={this.pageSize}
              defaultPage={this.state.presentPage}
            />
          </FilterableContent>
          {/* </ScrollArea> */}
        </div>
      </div>
    )
  }

  render() {
    return <React.Fragment>{this.renderDataset()}</React.Fragment>
  }
}

DatasetViewer.propTypes = {
  path: PropTypes.string,
  mode: PropTypes.string, // Mode is object viewer presented for, for now we support input/output
  level: PropTypes.number, // Level viewer represents. For now only define style
  object: PropTypes.object, // Object viewer presented for.
  dataset: PropTypes.object, // Dataset object
  editState: PropTypes.number, // State of component for editing
  reference: PropTypes.string, // Full reference URI for dataset it present
  components: PropTypes.array, // Components which selected from viewer and need to be filtered.
  selectMode: PropTypes.string, // When specify field will be selected in multiselect or singleselect mode
  fieldsState: PropTypes.object, // Set fields state (HIDDEN, VISIBLE etc)
  presentField: PropTypes.string, // Present page containing this field.
  onAdjust: PropTypes.func, // Adjust height when we have some changes
  fieldClick: PropTypes.func, // Process click on dataset field
  onPageLoad: PropTypes.func, // Report change of page in the content of dataset viewer
  onPageChange: PropTypes.func, // Report change of page in the content of dataset viewer
  onPageScroll: PropTypes.func, // Report change of page position inside dataset viewer
  onPageExpand: PropTypes.func, // Report change of page in the content of dataset viewer
  onFieldClick: PropTypes.func // Callback for field click. Used for structure tables.
}
