import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

export const ModalDialog: React.FC<any> = ({ open, onClose, title, text, object, actions, onAction }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="responsive-dialog-title">
      {title && <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>}
      {text && (
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
      )}
      {actions.length && (
        <DialogActions>
          {actions.map((item, idx) => (
            <Button
              key={idx}
              autoFocus={item.autoFocus}
              onClick={() => onAction && onAction({ action: item.action, data: object })}
              color={item.color}
            >
              {item.title}
            </Button>
          ))}
        </DialogActions>
      )}
    </Dialog>
  )
}
