import md from './md'
import Integration from './_integration'

let Jsons = md.jsons
let Repo = md.repo.metadata

//console.log("METADATA", Repo, Jsons);

export const objectJsons = {
  organization: Repo.apdax.systems.difhub.applications.system.datasets.organization,
  application: Jsons.apdax.systems.DifHub.applications.System.datasets.Application,
  dataset: Jsons.apdax.systems.DifHub.applications.Dataset.datasets.Dataset,
  cardinality: Jsons.apdax.systems.DifHub.applications.Dataset.datasets.Cardinality,
  system: Repo.apdax.systems.difhub.applications.system.datasets.system,
  publication: Repo.apdax.systems.difhub.applications.system.datasets.publication,
  subscription: Jsons.apdax.systems.DifHub.applications.System.datasets.Subscription,
  pipeline: Repo.apdax.systems.difhub.applications.pipeline.datasets.pipeline,
  view: Repo.apdax.systems.difhub.applications.view.datasets.view,
  field: Jsons.apdax.systems.DifHub.applications.System.datasets.Field,
  layout: Jsons.apdax.systems.DifHub.applications.System.datasets.Layout,
  object: Repo.apdax.systems.difhub.applications.system.datasets.object,
  contact: Jsons.apdax.systems.DifHub.applications.System.datasets.Contact,
  identity: Jsons.apdax.systems.DifHub.applications.Organization.datasets.Identity,
  usage: Jsons.apdax.systems.DifHub.applications.Organization.datasets.Usage,
  type: Jsons.apdax.systems.DifHub.applications.Organization.datasets.Type,
  role: Jsons.apdax.systems.DifHub.applications.Organization.datasets.Role,
  locale: Jsons.apdax.systems.DifHub.applications.Organization.datasets.Locale,
  function: Jsons.apdax.systems.DifHub.applications.Organization.datasets.Function,
  privacy: Jsons.apdax.systems.DifHub.applications.Organization.datasets.Privacy,
  permission: Jsons.apdax.systems.DifHub.applications.Organization.datasets.Permission,
  messageType: Jsons.apdax.systems.DifHub.applications.Messaging.datasets.MessageType,
  messageAction: Jsons.apdax.systems.DifHub.applications.Messaging.datasets.MessageAction,
  messageProperty: Jsons.apdax.systems.DifHub.applications.Messaging.datasets.MessageProperty,
  location: Jsons.apdax.systems.DifHub.applications.Interface.datasets.Location,
  interface: Repo.apdax.systems.difhub.applications.interface.datasets.interface,
  parameter: Jsons.apdax.systems.DifHub.applications.Interface.datasets.Parameter,
  response: Jsons.apdax.systems.DifHub.applications.Interface.datasets.Response,
  operation: Jsons.apdax.systems.DifHub.applications.Interface.datasets.Operation,
  actions: Jsons.apdax.systems.DifHub.applications.Interface.datasets.Action,
  interfaceReference: Jsons.apdax.systems.DifHub.applications.System.datasets.InterfaceReference,
  interfaceExample: Jsons.apdax.systems.DifHub.applications.System.datasets.InterfaceExample,
  data: Jsons.apdax.systems.DifHub.applications.Dataset.datasets.data_1_0_0,
  example: Repo.apdax.systems.difhub.applications.system.datasets.example,
  rule: Jsons.apdax.systems.DifHub.applications.System.datasets.rule_1_0_0,
  ruleType: Jsons.apdax.systems.DifHub.applications.System.datasets.ruletype_1_0_0,
  document: Jsons.apdax.systems.DifHub.applications.System.datasets.document_1_0_0,
  documentType: Repo.apdax.systems.difhub.applications.system.datasets.documenttype,
  // hardcoded integration definition
  integration: Integration,
  frame: Repo.apdax.systems.difhub.applications.view.datasets.frame,
  style: Repo.apdax.systems.difhub.applications.view.datasets.style,
  position: Repo.apdax.systems.difhub.applications.view.datasets.position,
  box: Repo.apdax.systems.difhub.applications.view.datasets.box,
  border: Repo.apdax.systems.difhub.applications.view.datasets.border,
  background: Repo.apdax.systems.difhub.applications.view.datasets.background,
  font: Repo.apdax.systems.difhub.applications.view.datasets.font,
  // fontStyle: Repo.apdax.systems.difhub.applications.view.datasets.fontStyle,
  line: Repo.apdax.systems.difhub.applications.view.datasets.line,
  element: Repo.apdax.systems.difhub.applications.view.datasets.element,
  align: Repo.apdax.systems.difhub.applications.view.datasets.align,
  deployment: Repo.apdax.systems.difgate.applications.system.datasets.deployment,
  topology: Repo.apdax.systems.difgate.applications.system.datasets.topology,
  environment: Repo.apdax.systems.difgate.applications.system.datasets.environment
}
