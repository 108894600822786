/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/**
 * Created by sashaberger on 12.05.2019.
 * Base class for list of items in editor dialog
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  columnsToType,
  deepMerge,
  editableState,
  nonCSCompare,
  propertyTypeToString,
  propertyValueToString
} from '../../helpers/index'
import logger from '../../helpers/logger'
import { FilterableContent } from '../FilterableContent/FilterableContent'
import { TypedTable } from '../NewTable/TypedTable'
import { EditorDialog } from './EditorDialog'
import './EditorListBase.scss'
import { getTailButtonLabel } from '../../helpers/helperComponents'

export class EditorListBase extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectItem: true, // Show org tags to select from
      activeItem: null,
      activeBase: null,
      activeEdit: false
    }
  }

  /**
   * Create new Item for editing
   * @return {object} empty element
   */
  createItem = (name) => {
    return { identity: { name: name || '' }, type: '' }
  }

  /**
   * Add Item when add button pushed
   */
  onAddItem = () => {
    logger.info('EditorListBase:onAddItem', this.props, this.state)
    this.setState({
      activeItem: this.createItem(),
      activeBase: null,
      activeEdit: true
    })
  }

  /**
   * View Item when requested dialog
   * @param {Object} value - selected record in table
   */
  onViewItem = (value) => {
    logger.info('EditorListBase:onViewItem', value, this.state, this.props)

    this.setState({
      activeItem: value._item.obj,
      activeBase: value._item.base,
      activeEdit: false
    })
  }

  /**
   * Edit Item dialog
   * @param {Object} obj - object to edit
   * @param {Number} index - index of record to edit
   */
  onEditItem = (obj, index) => {
    //logger.info("EditorListBase:onEditItem", obj, index, this.state);

    this.setState({
      activeItem: obj._item.obj,
      activeBase: obj._item.base,
      activeEdit: true
    })
  }

  /**
   * Select tag item to be part of list from base tags
   * @param {Object} obj - object to edit
   * @param {Number} index - index of record to edit
   */
  onSelectItem = (obj, index) => {
    logger.info('EditorListBase:onSelectItem', obj, index, this.state)

    if (obj._item.obj && obj._item.base) {
      // Remove item from list of tags of current object
      this.onDeleteItem(obj, index)
    } else if (!obj._item.obj && obj._item.base) {
      // Add item to list of tags of current object
      this.saveItem(this.createItem(this.getItemName(obj._item.base)), null, false, () => {})
    }
  }

  /**
   * Delete Item from list
   * @param {Object} obj - object to edit
   * @param {Number} index - index of record to edit
   */
  onDeleteItem = (obj, deletedIndex) => {
    //logger.info("EditorListBase:onDeleteItem before check", obj, this.state, this.props);

    if (!obj._item.obj) {
      // We can't delete item from base layer
      return
    }
    let objs = this.props.items || []
    let items = objs.filter((tobj) => !nonCSCompare(this.getItemName(tobj), this.getItemName(obj)))
    logger.info('EditorListBase:onDeleteItem', obj, items, this.state, this.props)

    this.props.onSave(items, false, () => {})
  }

  /**
   * On change editable status of child dialogs of list.
   */
  onEditableChange = (state) => {
    // Propogate event of change editable status of child dialog
    if (this.props.onEditableChange) {
      this.props.onEditableChange(state)
    }
  }

  /**
   * Return item name
   */
  getItemName = (item) => {
    let name =
      (item.identity ? item.identity.name : item.name && typeof item.name === 'object' ? item.name.name : item.name) ||
      ''

    return name.startsWith('$$$$') ? name.substring(4) : name
  }

  /**
   * Return item description
   */
  getItemDesc = (item) => {
    return (
      (item.identity
        ? item.identity.description
        : item.name && typeof item.name === 'object'
        ? item.name.description
        : item.description) || ''
    )
  }

  /**
   * Return name of property for list for items with properties
   */
  getPropertyName = (property) => {
    let name = property && property.identity ? property.identity.name : ''

    return name && name.startsWith('$$$$') ? name.substring(4) : name
  }

  /**
   * Return description type
   */
  getDescriptionType = () => {
    return { name: this.state.expandDescriptions ? 'text_expanded' : 'text' }
  }

  /**
   * Taggale description type
   */
  toggleExpandDescriptions = () => {
    this.setState({ expandDescriptions: !this.state.expandDescriptions })
  }

  /**
   * Select base items for editing
   * @return {object} empty element
   */
  onSelectItems = () => {
    logger.info('EditorListBase:onSelectItem', this.props, this.state)
    this.setState({ selectItem: !this.state.selectItem })
  }

  /**
   * Save Item in layout
   * @param {object} obj - item to save
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   * @return
   */
  saveItem = (obj, base, closeDialog, onSent) => {
    let item = base ? deepMerge(base, obj) : obj
    let objs = this.props.items || []
    console.log('EditorListBase:saveItem', obj, base, item, objs, this.state, this.props)

    let items = objs.filter((tobj) => !nonCSCompare(this.getItemName(tobj), this.getItemName(item)))
    items.push(item)
    if (this.props.onSave) {
      this.props.onSave(items, closeDialog, onSent)
    }
  }

  /**
   * Close Item in layout
   * @return
   */
  closeItem = () => {
    if (this.props.onClose && this.props.onClose()) {
      return
    }

    this.setState({ activeItem: null, activeBase: null, activeEdit: false })
  }

  /**
   * Return filters requered for item list
   */
  getFilters = () => {
    return [
      { name: 'search', width: 260 },
      { name: 'type', width: 100 }
    ]
  }

  /**
   * Return columns requered for item list
   */
  getColumns = () => {
    return [
      {
        name: 'name',
        displayName: 'Property Name',
        type: columnsToType.getType('minorObjectName'),
        width: 210,
        onCellClick: (value) => {
          this.onViewItem(value)
        }
      },
      {
        name: 'description',
        displayName: this.renderDescriptionColumnHeader(),
        type: this.getDescriptionType(),
        width: 260
      },
      {
        name: 'type',
        type: columnsToType.getType('string'),
        frozen: true,
        width: 100
      },
      {
        name: 'value',
        type: columnsToType.getType('text'),
        frozen: true,
        width: 195
      }
    ]
  }

  /**
   * Return items list to render in table
   * @param {object} items - items to render
   * @param {object} base - items from base layer to render
   */
  getItems = (items, base, editState) => {
    //logger.info("EditorListBase:getItems", items, base);
    let objs = items.map((item) => {
      let baseObj = base ? base.find((obj) => nonCSCompare(this.getItemName(obj), this.getItemName(item))) : null
      return this._getItem(item, baseObj, editState)
    })

    if (base && this.state.selectItem && editState > editableState.EDITABLE) {
      let baseObjs = base
        .filter((obj) => !items.find((item) => nonCSCompare(this.getItemName(obj), this.getItemName(item))))
        .map((obj) => {
          return this._getItem(null, obj)
        })
      objs = objs.concat(baseObjs)
      //logger.info("EditorListBase:getItems:BASE", baseObjs, objs);
    }
    return this.sortItems(objs)
  }

  /**
   * Return sorted items list to render in table
   * @param {object} items - items to render
   */
  sortItems = (items) => {
    let objs = items.sort((a, b) => {
      let nameA = this.getItemName(a).toLowerCase()
      let nameB = this.getItemName(b).toLowerCase()

      if (nameA > nameB) {
        return 1
      } else if (nameA < nameB) {
        return -1
      }
      return 0
    })
    //logger.info("EditorListBase:sortItems", items, objs);
    return objs
  }

  /**
   * Return item data to render in table
   * @param {object} obj - item to render
   * @param {object} base - item from base layer to render
   * @param {number} editState - edit state on dialog
   */
  _getItem(obj, base, editState) {
    let item = this.getItem(obj, base, editState)
    item['_item'] = { obj: obj, base: base }

    return item
  }

  /**
   * Return item data to render in table
   * @param {object} obj - item to render
   * @param {object} base - item from base layer to render
   * @param {number} editState - edit state on dialog
   */
  getItem = (obj, base, editState) => {
    let currObj = obj ? (base ? deepMerge(base, obj) : obj) : base
    if (!currObj) {
      return {}
    }
    //logger.info("EditorListBase:getItem", obj, base, currObj, this.state, this.props);

    // Prepare object data
    return Object.assign({}, currObj, {
      icon: this.getIcon(obj, base),
      name: {
        name: this.getItemName(currObj),
        objectType: this.props.type,
        objectState: obj === null
      },
      type: propertyTypeToString(currObj.type),
      description: this.getItemDesc(currObj),
      value: propertyValueToString(currObj.type, currObj.value)
    })
  }

  /**
   * Return icon for the line table
   * @param {object} obj - item to render
   * @param {object} base - item from base layer to render
   */
  getIcon = (obj, base) => {
    //logger.info("EditorListBase:renderResoueceList:DATA", type, objs);
    let currObj = obj ? (base ? deepMerge(base, obj) : obj) : base
    if (!currObj) {
      return {}
    }

    let iconName = obj ? 'included' : 'excluded'

    // Prepare object data
    return <span className={'EditorListBase__icon EditorListBase__icon_' + iconName}></span>
  }

  /**
   * Define rendering of tail buttons
   * @param {number} editState - edit state on dialog
   * @return - array of rendered buttons
   */
  getTailButtons(editState) {
    const canAdd = editState > editableState.EDITABLE
    if (!canAdd) {
      return [
        {
          label: getTailButtonLabel('View'),
          onClick: (obj, index) => this.onViewItem(obj, index)
        },
        { label: '', onClick: (obj, index) => this.onViewItem(obj, index) }
      ]
    }

    return (obj) => {
      let buttons = []
      //logger.info("TagList:getTailButtons", obj, this.props, this.state);
      if (!obj || !obj._item) {
        return buttons
      }

      buttons.push({
        label: getTailButtonLabel('Edit'),
        onClick: (obj, index) => this.onEditItem(obj, index)
      })
      buttons.push({
        label: getTailButtonLabel('Delete'),
        onClick: (obj, index) => this.onDeleteItem(obj, index)
      })

      return buttons
    }
  }

  /**
   * Define rendering of top buttons
   * @param {number} editState - edit state on dialog
   * @return - array of rendered buttons
   */
  getTopButtons(editState) {
    const canAdd = editState > editableState.EDITABLE

    return canAdd
      ? [
          {
            label: '+ Add',
            onClick: () => this.onAddItem()
          }
        ]
      : []
  }

  /**
   * Define rendering of bottom buttons
   * @param {number} editState - edit state on dialog
   * @return - array of rendered buttons
   */
  getBottomButtons(editState) {
    const canAdd = editState > editableState.EDITABLE
    let type = this.props.type

    return canAdd
      ? [
          {
            label: '+ Add ' + type,
            onClick: () => this.onAddItem()
          }
        ]
      : []
  }

  /**
   * Render item list in form of table
   */
  renderItemList(editState) {
    let objs = this.props.items || []
    //logger.info("EditorListBase:renderResoueceList", editState, type, objs);

    return (
      <div className="EditorListList">
        <FilterableContent
          dataFilters={this.getFilters()}
          data={this.getItems(objs, this.props.base, editState)}
          title="ItemList"
        >
          <TypedTable
            columns={this.getColumns()}
            topButtons={this.getTopButtons(editState)}
            bottomButtons={this.getBottomButtons(editState)}
            tailButtons={this.getTailButtons(editState)}
            isFullscreen={this.props.isFullscreen}
            widthBeforeFullscreen={764}
          />
        </FilterableContent>
      </div>
    )
  }

  /**
   * Render description column header as it contains expansion icon
   */
  renderDescriptionColumnHeader() {
    return (
      <span>
        Description&nbsp;
        <a
          className={
            'MajorObject__expandButton ' +
            ' MajorObject__expandButton__dark ' +
            (this.state.expandDescriptions ? 'MajorObject__expandButton__active' : '')
          }
          onClick={this.toggleExpandDescriptions}
        ></a>
      </span>
    )
  }

  /**
   * Render dialog for item entry
   */
  renderItemDialog(editState, item, base, isEditable) {
    return (
      <EditorDialog
        appState={this.props.appState}
        actions={this.props.actions}
        isVisible
        isEditable={isEditable}
        item={item}
        majorObject={this.props.majorObject}
        onClose={this.closeItem}
        onSave={(obj, closeDialog, onSent) => this.saveItem(obj, base, closeDialog, onSent)}
      />
    )
  }

  /**
   * Render additional content
   */
  renderContent = (editState) => {
    return null
  }

  render() {
    // State editing of data in dialog
    let editState = this.props.isEditable
    let editType = this.props.type
    //logger.info("EditorListBase:render", this.state, this.props);

    return (
      <div className={'EditorListBase ' + (this.props.className || '')}>
        {this.renderItemList(editState)}
        {(!this.state.activeItem && !this.state.activeBase) || !editType || editType.length < 1
          ? null
          : this.renderItemDialog(
              editState,
              this.state.activeItem
                ? this.state.activeItem
                : this.createItem(
                    this.state.activeBase && this.state.activeBase.identity ? this.state.activeBase.identity.name : ''
                  ),
              this.state.activeBase,
              editState <= editableState.EDITABLE
                ? editState
                : this.state.activeEdit
                ? editableState.EDITING
                : editableState.EDITABLE
            )}
        {this.renderContent(editState)}
      </div>
    )
  }
}

EditorListBase.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  // eslint-disable-next-line react/no-typos
  isFullscreen: PropTypes.boolean,
  isEditable: PropTypes.number, // State of editing in dialog
  majorObject: PropTypes.object.isRequired, // Major object we work against
  parentDialog: PropTypes.object, // Reference to parent dialog when exist.
  type: PropTypes.string.isRequired, // Type of object
  items: PropTypes.object, // List of items to show as object array
  base: PropTypes.object, // List of items from base layer
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
