/**
 * Created by kascode on 03.05.17.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { createEmptyObject, deepCopy, editableState, updateLocalObject, nonCSCompare } from '../../helpers/index'
import { Badge } from '../Badge/Badge'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { FieldDialog } from '../FieldEditor/FieldDialog'
import { FieldEditor } from '../FieldEditor/FieldEditor'
import './ResponseDialog.scss'

export class ResponseDialog extends EditorDialogBase {
  constructor(props) {
    super(props)
    this.state.editingHeader = false
  }

  getObjectType() {
    return 'response'
  }

  UNSAFE_componentWillReceiveProps() {}

  /**
   * Update value of field property in parameter
   * @param newField
   */
  updateField = (newField) => {
    //console.log("ResponseDialog:updateField", newField);
    if (this.state.change) {
      this.setState(updateLocalObject(Object.assign({}, this.state.response, { field: newField })))
      this.state.change(true, '', '', '')
    }
  }

  setCode = (event) => {
    let data = event.target.value
    let curr = this.getInitial().code
    //console.log("ResponseDialog:setCode", data);

    // Indicate save mode
    if (this.state.change && data !== curr) {
      this.setState(updateLocalObject(Object.assign({}, this.state.response, { code: data })))
      this.state.change(true, '', '', '')
    }
  }

  checkInputErrors(response) {
    let inputError = ''
    const condition = this.props.majorObject.responses
    if (condition && response.field && response.field.identity && response.field.identity.name) {
      const checkResponseName = condition.find(
        (e) =>
          nonCSCompare(e.field.identity.name, response.field.identity.name) &&
          !nonCSCompare(e.field.identity.id, response.field.identity.id)
      )
      if (checkResponseName) {
        inputError += 'Response name already exist. '
      }
    }
    if (
      !response.field ||
      !response.field.identity ||
      !response.field.identity.name ||
      response.field.identity.name.length === 0
    ) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify response name!'
    }
    if (!response.code || response.code === 0) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify response code!'
    } else if (this.props.majorObject && this.props.majorObject.responses) {
      let responses = this.props.majorObject.responses.filter(
        (param) => response.code === param.code && response.field.identity.id !== param.field.identity.id
      )

      if (responses && responses.length > 0) {
        // We are allow responses with same code.
        //inputError += ((inputError.length > 0) ? "\n" : "") + 'Code "' + response.code + '" already exist. Please specify unique response code!';
      }
    }
    return inputError
  }

  /**
   * Get initial value of response
   * @param {object} [props]
   * @returns {*}
   */
  getInitial(props) {
    props = props || this.props
    if (props.response) {
      return JSON.parse(JSON.stringify(props.response))
    } else {
      let response = createEmptyObject('response')
      let order = 0

      if (props.majorObject) {
        // Count current max order and have next as order for new field
        props.majorObject.responses.forEach((p) => {
          order = order < p.field.order ? p.field.order : order
        })
      }
      response.field.order = order + 1
      return response
    }
  }

  /**
   * Construct handler of header edit start
   * @param {object} header
   * @returns {function()}
   */
  getStartHeaderEditHandler = (header) => {
    return () => {
      //console.log("ResponseEditor:startEditHeader", header);
      this.setState({
        editingHeader: header
      })
    }
  }

  /**
   * Update header value or add new one
   * @param {object} header
   * @param {boolean} close - should we close window
   */
  setHeader = (header, close, onSent) => {
    //console.log("ResponseDialog:setHeader", header, close);

    // Indicate save mode
    if (this.state.change && header) {
      this.state.change(true, '', '', '')

      const newResponse = deepCopy(this.state.response)

      // replace existing header if it is there
      if (newResponse.headers) {
        newResponse.headers = newResponse.headers.filter((h) => header.identity.name !== h.identity.name)
      } else {
        newResponse.headers = []
      }

      newResponse.headers.push(header)

      this.setState({
        response: newResponse
      })
    }

    // We notify end of save data.
    if (onSent) onSent(close)
  }

  /**
   * Renders individual header badge
   * @param {object} header
   * @param {number} i
   */
  renderHeaderBadge = (header, i) => (
    <Badge key={header.identity.name + i} className="HeaderBadge" onClick={this.getStartHeaderEditHandler(header)}>
      {header.identity.name}
    </Badge>
  )

  renderHeaderEditor = (header) => {
    header = Object.assign({}, createEmptyObject('field'), header)

    return (
      <div className="EditorDialog__block">
        <h4>{header.identity.name ? 'Edit ' + header.identity.name + ' header' : 'Create header'}</h4>
      </div>
    )
  }

  /**
   * Renders all headers' badges
   * @returns {XML}
   */
  renderHeaders = () => {
    const headers = this.state.response.headers

    return (
      <div>
        <span>
          {headers ? headers.map((header, i) => this.renderHeaderBadge(header, i)) : null}
          <Badge className="HeaderBadge HeaderBadge_add" color="green" onClick={this.getStartHeaderEditHandler(null)}>
            +
          </Badge>
        </span>
      </div>
    )
  }

  /**
   * Render content of response editor dialog
   */
  renderEditor = (editState) => {
    return (
      <div className="ResponseDialog">
        <FieldEditor
          field={this.state.response.field}
          inEditMode={editState > editableState.EDITABLE}
          onChange={this.updateField}
          majorObject={this.props.majorObject}
          actions={this.props.actions}
        />
        {this.state.response.code ? (
          <div className="ParameterDialog__block row">
            <div className="col-xs-3">Code:</div>
            <div className={editState > editableState.EDITABLE ? 'col-xs-9' : 'col-xs-12'}>
              <EditableEntity
                dataProps={Object.assign({
                  onChange: this.setCode.bind(this),
                  placeholder: 'Type code'
                })}
                dataType={{ name: 'string' }}
                data={this.state.response.code}
                isEditable
                inEditMode={editState > editableState.EDITABLE}
              />
            </div>
          </div>
        ) : null}
        {this.props.isEditable ? (
          <div className="EditorDialog__block">
            <label>Headers:&nbsp;</label>
            {this.renderHeaders()}
          </div>
        ) : null}
        {this.state.editingHeader !== false ? (
          <FieldDialog
            childObjectType="header"
            parentObjectType="interface"
            onSave={this.setHeader.bind(this)}
            onClose={() => {
              this.setState({ editingHeader: false })
            }}
            actions={this.props.actions}
            field={this.state.editingHeader}
            isEditable={editState}
            isVisible
            majorObject={this.props.majorObject}
          />
        ) : null}
      </div>
    )
  }
}

ResponseDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  response: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
