/**
 * Created by mailf on 17.08.2016.
 */

import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { dequal } from 'dequal'

import { LocaleSelect } from '../LocaleSelect/LocaleSelect'
import ObjectItemSearch from '../ObjectSearch/ObjectItemSearch'
import { nonCSCompare, childrenByType, getFullUri, singularTypeForms, pluralTypeForms } from '../../helpers/index'

import './ObjectSearchMultistep.scss'

class ObjectSearchMultistep extends Component {
  constructor(props) {
    super(props)

    this.state = {
      path: '',
      pathItems: [],
      pathIndex: 0,
      pathType: '',
      editHeight: 0
    }
  }

  componentDidMount() {
    this.setNewState()
  }

  setNewState() {
    this.setState(this.getState(this.props.parentPath))
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.path !== nextProps.parentPath) {
      //logger.info("ObjectSearchMultistep:componentWillReceiveProps", nextProps, this.state);
      this.setState(this.getState(nextProps.parentPath + '/'))
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !dequal(nextProps, this.props) || !dequal(nextState, this.state)
  }

  /**
   * Get state from new path
   * @return
   */
  getState = (path) => {
    if (!path || path.length < 1) return false

    const splitPath = path.substring(1).split('/')
    const pathIndex =
      splitPath.length > 0
        ? splitPath.length > 1 && splitPath[splitPath.length - 1].length === 0
          ? splitPath.length - 2
          : splitPath.length - 1
        : 0
    const pathData =
      pathIndex === 0 ? '/organizations' : path[path.length - 1] === '/' ? path.slice(0, path.length - 1) : path
    const prevType = pathIndex < 2 ? null : pathIndex & 1 ? splitPath[pathIndex - 3] : splitPath[pathIndex - 2]
    const pathType = pathIndex & 1 ? splitPath[pathIndex - 1] : splitPath[pathIndex]
    const pathTypes = this.getTypes(pathIndex & 1 ? pathType : prevType)

    //logger.info("ObjectSearchMultistep:getState", { path, splitPath, pathIndex, pathData, prevType, pathType, pathTypes }, Error().stack, this.state, this.props);

    let state = Object.assign({}, this.state)

    state.path = pathData
    state.pathIndex = pathIndex
    state.pathItems = []
    state.pathType = pathType
    state.typeItems = pathTypes
    state.editHeight = 50 + ((pathIndex + 1) / 2) * 45

    for (let i = 0; i < pathIndex; i += 2) {
      // This is object we show up
      state.pathItems.push(Object.assign({}, null, { type: splitPath[i], name: splitPath[i + 1] }))
    }

    //logger.info("ObjectSearchMultistep:getState:STATE", path, state);

    // Adjust size for new lines in list.
    if (this.props.onAdjust) {
      this.props.onAdjust(0, state.editHeight - this.state.editHeight, 'ObjectSearchMultistep')
    }
    return state
  }

  /**
   * Get children types from parent
   * @returns {array}
   */
  getTypes(type) {
    if (!type) {
      return ['organizations']
    }
    let types = this.getChildrenByType()[type]

    //logger.info("ObjectSearchMultistep:getTypes", type, types, this.getChildrenByType());
    return typeof types === 'object' ? types : types ? [types] : []
  }

  /**
   * Get singular type name from type
   * @return type
   */
  singularType = (type) => {
    return type[type.length - 1] === 's' ? singularTypeForms.get(type) : type
  }

  /**
   * Get children type structure
   * @return types
   */
  getChildrenByType = () => {
    return this.props.childrenByType || childrenByType
  }

  /**
   * Get path type from list
   * @return type
   */
  getTargetType = (type, types) => {
    if (!types) {
      return null
    }

    // Selected type is in the types list. This is after we selected it.
    if (type && type.length > 0 && types.indexOf(type) !== -1) {
      return type
    }

    // Target type is in the types list. This is desired, let's select it.
    let targetType = this.props.targetType
    if (targetType && targetType.length > 0 && types.indexOf(targetType) !== -1) {
      return targetType
    }

    // We have target path and we select type from target.
    if (this.props.targetPath && this.props.targetPath.length > 0) {
      const splitPath = this.props.targetPath.substring(1).split('/')
      const targetIndex =
        splitPath.length > 0
          ? splitPath.length > 1 && splitPath[splitPath.length - 1].length === 0
            ? splitPath.length - 2
            : splitPath.length - 1
          : 0
      if (targetIndex > this.state.pathIndex) {
        const pathIndex = this.state.pathIndex + 1
        const pathType = pathIndex & 1 ? splitPath[pathIndex - 1] : splitPath[pathIndex]
        return pathType
      }
    }
    return types[0]
  }

  /**
   * Object selected in item search
   * @param objectType
   */
  onObjectSelect = (obj, type) => {
    const path = getFullUri(obj)
    //logger.info("ObjectSearchMultistep:onObjectSelect", type, obj, path);

    if (this.props.onSelect && this.props.onSelect(obj, type)) return true

    // Reset state from new path if parent not blocked it.
    this.setState(this.getState(path))
  }

  /**
   * Reset selection to clicked type
   * @param objectType
   */
  onResetPath = (type) => {
    // Calculate new path from type.
    let path = ''
    let current = -1
    this.state.pathItems.map((item, index) => {
      if (type === item.type) {
        current = index
        path += '/' + item.type
      }
      if (current < 0) path += '/' + item.type + '/' + item.name
    })

    //logger.info("ObjectSearchMultistep:onResetPath", type, current, path, this.state);

    if (this.props.onSelect && this.props.onSelect({ _path: path }, type)) return true

    // Reset state from new path.
    this.setState(this.getState(path))
  }

  /**
   * When selected new type
   * @param objectType
   */
  onSelectType(value) {
    // Calculate new path from type.
    let type = pluralTypeForms.get(value)

    let path = ''
    let current = -1
    this.state.pathItems.map((item, index) => {
      if (item.name && item.name.length > 0) {
        current = index
        path += '/' + item.type + '/' + item.name
      }
    })

    // Add new type
    path += '/' + type

    // Add object if it in tartget;
    if (this.props.targetPath && this.props.targetPath.length > 0) {
      // Calculate current path index.
      const pathIndex = 2 * (current + 1)

      const splitTarget = this.props.targetPath.substring(1).split('/')
      const targetIndex =
        splitTarget && splitTarget.length > 0
          ? splitTarget.length > 1 && splitTarget[splitTarget.length - 1].length === 0
            ? splitTarget.length - 2
            : splitTarget.length - 1
          : 0

      //logger.info("ObjectSearchMultistep:onSelectType", pathIndex, targetIndex, splitTarget);

      // Check if we have target value for
      if (
        pathIndex < targetIndex &&
        splitTarget[pathIndex + 1].length > 0 &&
        nonCSCompare(splitTarget[pathIndex], type)
      ) {
        path += '/' + splitTarget[pathIndex + 1]
      }
    }

    //logger.info("ObjectSearchMultistep:onSelectType", type, current, path, this.state);

    if (this.props.onSelect && this.props.onSelect({ _path: path }, type)) return true

    // Reset state from new path.
    this.setState(this.getState(path))
  }

  renderType(type) {
    let types = this.state.typeItems

    //logger.info("ObjectSearchMultistep:renderType", type, types);
    if (types && types.length > 1) {
      return (
        <LocaleSelect
          options={types.map((item) => this.singularType(item))}
          currentOption={this.singularType(type)}
          onClick={this.onSelectType.bind(this)}
          autoClose
        />
      )
    } else {
      return types && types.length === 1 ? types[0] + ':' : type + ':'
    }
  }

  renderItem = (type, name, target) => {
    const singularType = this.singularType(type)

    //logger.info("ObjectSearchMultistep:renderItem", { singularType, type, name, target }, this.state, this.props);
    return (
      <div className={'ObjectSearchMultistep__item'}>
        <label className="ObjectSearchMultistep__labelItem">
          <span className="ObjectSearchMultistep__labelType">{singularType}:</span>
          <span className="ObjectSearchMultistep__itemName" onClick={this.onResetPath.bind(this, type)}>
            {name}
          </span>
        </label>
      </div>
    )
  }

  renderSelect = (type) => {
    const targetType = this.getTargetType(type, this.state.typeItems)
    if (!targetType) {
      // We are done here
      return
    }

    if (this.props.targetPath && this.props.targetPath.length > 1) {
      const splitTarget = this.props.targetPath.substring(1).split('/')
      //logger.info("ObjectSearchMultistep:renderSelect", { type, targetType, splitTarget }, splitTarget.indexOf(targetType), this.state, this.props);
      if (this.state.pathIndex & 1 && this.state.pathType === this.props.targetType) {
        // We already on target.
        return
      }
    }

    return (
      <div className={'ObjectSearchMultistep__select'}>
        <label className="ObjectSearchMultistep__label">
          <span className="ObjectSearchMultistep__labelType">{this.renderType(targetType)}</span>
        </label>
        <ObjectItemSearch
          className="ObjectSearchMultistep__input"
          appState={this.props.appState}
          actions={this.props.actions}
          path={this.state.path}
          type={targetType}
          query={''}
          onSelect={this.onObjectSelect.bind(this)}
          onAdjust={this.props.onAdjust}
          keepObjectsWithoutPath
        />
      </div>
    )
  }

  render() {
    let items = this.state.pathItems

    //logger.info("ObjectSearchMultistep:render", this.props, this.state);
    return (
      <div className="ObjectSearchMultistep">
        {this.props.title ? <h3>{this.props.title}</h3> : null}
        {items.map((item) => this.renderItem(item.type, item.name))}
        {this.state.pathType ? this.renderSelect(this.state.pathType) : null}
      </div>
    )
  }
}

ObjectSearchMultistep.propTypes = {
  appState: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  parentPath: PropTypes.string,
  targetPath: PropTypes.string,
  targetType: PropTypes.string, // Target type specify object we target select
  childrenByType: PropTypes.object,
  onSuccess: PropTypes.func,
  onSelect: PropTypes.func,
  onAdjust: PropTypes.func
}

function mapStateToProps(state) {
  return {
    appState: state.appState
  }
}

export default connect(mapStateToProps, null)(ObjectSearchMultistep)
