import { OrganizationItem } from './OrganizationsTypes'
import { demoRandom, majorObjectSort } from '../../helpers'

const getUserData = (org: OrganizationItem) => {
  let userStatus = 'Pending'
  if ('userRoleApproved' in org) {
    if (org.userRoleApproved !== '') {
      userStatus = 'Approved'
    } else {
      userStatus = 'Pending'
    }
  }

  let userRole: any = {}
  if (org.users) {
    org.users.forEach((u) => {
      if (u.user.id === localStorage.currentUserId) {
        userRole = u

        // Define status of user access based on completion
        try {
          for (let i = 0; i < userRole.history.completions.length; i++) {
            const completion = userRole.history.completions[i]
            if (completion.status === 'Approved' && completion.completed) {
              userStatus = 'Approved'
              break
            }
            if (completion.status === 'Finalized' && !completion.completed) {
              userStatus = 'Declined'
              break
            }
          }
        } catch (e) {
          console.error(userRole) // eslint-disable-line no-console
        }
      }
    })
  }

  return { userStatus, userRole }
}

const mapToFormatted = (org: OrganizationItem, userStatus, userRole) => {
  return {
    id: org.identity?.id || '',
    name: org.identity?.name || '',
    description: org.identity?.description || '',
    status: userStatus,
    users: org.users,
    default: localStorage.getItem('defaultOrganization') === org.identity?.id ? 'default' : '',
    role: userRole.role,
    customProfile: org.userProfile || '',
    subStatus: demoRandom(org.identity?.name) < 0.5 ? 'Enterprise' : 'Free',
    object: org.object
  }
}

export const createFormattedOrganization = (orgList: Array<OrganizationItem>) => {
  const newOrgList = orgList?.filter((org) => org && org.identity).sort(majorObjectSort)

  const organizations: any[] = []
  for (let org of newOrgList) {
    const { userStatus, userRole } = getUserData(org)
    const mappedOrg = mapToFormatted(org, userStatus, userRole)

    if (org.object?.parent?.id) {
      const parent = organizations.find((organization) => organization.id === org.object?.parent?.id)
      if (parent) {
        if (parent.child) {
          parent.child = [...parent?.child, mappedOrg]
        } else {
          parent.child = [mappedOrg]
        }
      }
    } else {
      organizations.push(mappedOrg)
    }
  }

  return organizations
}
