import PropTypes from 'prop-types'
import React, { Component } from 'react'
import PoliciesStructure from '../PoliciesStructure/PoliciesStructure'

/* used to test PoliciesStructure component */

class PoliciesStructureView extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        <PoliciesStructure appState={this.props.appState} />
      </div>
    )
  }
}

PoliciesStructureView.propTypes = {}

export default PoliciesStructureView
