/**
 * Created by mailf on 20.07.2016.
 */
import PropTypes from 'prop-types'
import React from 'react'
import {
  columnsToType,
  createEmptyObject,
  deepCopy,
  editableState,
  getFullUri,
  itemState,
  pathByType,
  nonCSCompare
} from '../../helpers/index'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import RulesDialog from '../LayoutDialog/RulesDialog'
import { SettingsDialog } from '../SettingDialog/SettingsDialog'
import './FieldDialog.scss'
import { FieldEditor } from './FieldEditor'
import './FieldView.scss'

const RULE_HEIGHT = 20
const SETTING_HEIGHT = 20

export class FieldDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      rulesDialogOpened: false,
      messageSent: false,
      editingBlock: -1
    })

    //logger.log('FieldDialog:constructor', props, this.state);
  }

  getObjectType() {
    return 'field'
  }

  getMinHeight() {
    return 900
  }

  /**
   * Get initial value of field
   * @param {object} [props] - if called from constructor
   * @returns {object} field = field initialized based on input.
   */
  getInitial(props) {
    props = props || this.props
    let field = props.field
    if (!field) {
      field = createEmptyObject('field')
      let order = 0
      if (props.majorObject && props.majorObject.structure && props.majorObject.structure.fields) {
        // Count current max order and have next as order for new field
        props.majorObject.structure.fields.forEach((f) => {
          order = order < f.order ? f.order : order
        })
      }
      field.order = order + 1
    }
    return deepCopy(field || {})
  }

  /**
   * Returns list of adjustments of height based on object elements.
   * Name - is a name of parameter to use,
   * Value - adjustment for element.
   */
  getElementsHeight() {
    // We have in parallel rules and properties
    return { __group: { rules: RULE_HEIGHT, properties: SETTING_HEIGHT } }
  }

  /*
   * Change state of field
   * @param {object} rule - new state of object
   * @returns {boolean} true - it is updated, false - no changes
   */
  ChangeField = (field) => {
    // Change state of the field.
    //logger.log('FieldDialog:ChangeField', field, this.state, this.props);
    return this.Change(field)
  }

  /*
   * Check property before save
   * @param {object} field - new state of object
   * @returns {string} - string with error text
   */
  checkInputErrors(field) {
    let inputError = ''
    const condition = this.props.majorObject.structure.fields
    if (!field.identity || !field.identity.name) {
      inputError += 'Please specify property name! '
    } else if (!field.type) {
      inputError += 'Please specify property type! '
    } else if (condition && field.identity && field.identity.name) {
      const checkFieldName = condition.find(
        (e) => nonCSCompare(e.identity.name, field.identity.name) && !nonCSCompare(e.identity.id, field.identity.id)
      )
      if (checkFieldName) {
        inputError += 'Field name already exist'
      }
    }

    return inputError
  }

  /**
   * Change data for the property
   * @param propertyName - property with changed value.
   * @return
   */
  onPropertiesChange = (propertyName) => {
    return (event) => {
      let field = deepCopy(this.state.field)

      // logger.info("FieldDialog:onPropertiesChange", propertyName, event.target);
      let val = ''

      if (columnsToType.getType(propertyName).name === 'boolean') {
        val = event.target.value
      } else if (propertyName === 'reference') {
        val = this.storageUri(event.target.label, event.target.value)
      } else if (propertyName === 'access') {
        let text = event.target.value.reduce((prev, cur) => {
          return prev === '' ? prev + cur.label : prev + ', ' + cur.label
        }, '')
        val = this.getAccessValue(text)
      } else {
        val = event.target.value
      }

      field[propertyName] = val

      this.ChangeField(field)
    }
  }

  onRulesSave = (rules, closeDialog, onSent) => {
    let field = deepCopy(this.state.field)
    field.rules = rules

    this.ChangeField(field)
    if (onSent) {
      onSent(closeDialog)
    }
  }

  onPropertiesSave = (properties, closeDialog, onSent) => {
    let field = deepCopy(this.state.field)
    field.properties = properties.map((property) => {
      return Object.assign({}, property, {
        value: typeof property.value === 'object' ? JSON.stringify(property.value) : property.value
      })
    })

    this.ChangeField(field)
    if (onSent) {
      onSent(closeDialog)
    }
  }

  onSelect = (index) => {
    this.setState({
      editingBlock: index
    })
  }

  onBlur = (index) => {
    return (event) => {
      if (event.target.value) {
        // logger.info("FieldDialog:onBlur", event.target.value);
      }
      this.setState({
        editingBlock: -1
      })
    }
  }

  /**
   * Returns display version for reference URI
   * @param uri - full uri of reference object
   * @param ds - dataset object
   * @return storageURI - storage version of reference URI
   */
  storageUri = (uri, ds) => {
    let storageUri = ''
    let objectUri = getFullUri(this.props.majorObject)

    const objectSplit = objectUri.substring(1).split('/')

    if (uri && uri.length > 0) {
      if (uri.startsWith('/organizations/')) {
        // We are already have full path and dont need to do anything.
        storageUri = uri
      } else if (uri.startsWith('/')) {
        // We are on organization datatset
        storageUri = pathByType(objectSplit, 'organizations') + '/datasets' + uri
      } else if (uri.startsWith('../')) {
        const subSplit = uri.substring(1).split('/')

        if (subSplit && subSplit.length === 5) {
          storageUri =
            pathByType(objectSplit, 'applications') + '/subscriptions/' + subSplit[2] + '/datasets/' + subSplit[4]
        }
      } else {
        storageUri = pathByType(objectSplit, 'applications') + '/datasets/' + uri
      }
    } else if (ds) {
      if (ds.subscription && ds.subscription.identity.name) {
        storageUri =
          pathByType(objectSplit, 'applications') +
          '/subscriptions/' +
          ds.subscription.identity.name +
          '/datasets/' +
          ds.identity.name
      } else {
        storageUri = getFullUri(ds)
      }
    }
    // logger.info("FieldDialog:storageUri", uri, ds, objectUri, storageUri);

    return storageUri
  }

  renderEditor = (editState) => {
    let isEditable = this.props.isEditable > editableState.BROWSABLE ? editState : editableState.BROWSABLE
    return (
      <div className="FieldDialog__content">
        <FieldEditor
          field={this.state.field}
          inEditMode={this.state.change !== null}
          onChange={this.ChangeField}
          majorObject={this.props.majorObject}
        />
        <div className="FieldCreator__advancedOptions row">
          <div className="col-xs-6">
            <RulesDialog
              buttonTitle={'Rules'}
              isItems={itemState.VERTICAL}
              sortItems
              isVisible
              isEditable={editState > editableState.EDITING ? editableState.EDITING : editState}
              rules={this.state.field ? this.state.field.rules : []}
              majorObject={this.props.majorObject}
              onEditableChange={this.changeEditable}
              onClose={() => this.setState({ editingRule: false })}
              onSave={this.onRulesSave}
            />
          </div>
          <div className="col-xs-6">
            <SettingsDialog
              buttonTitle={'Settings'}
              isItems={itemState.VERTICAL}
              sortItems
              isVisible
              isEditable={isEditable > editableState.EDITING ? editableState.EDITING : isEditable}
              settings={this.state.field.properties}
              majorObject={this.props.majorObject}
              onEditableChange={this.changeEditable}
              onClose={() => this.setState({ editingSetting: false })}
              onSave={this.onPropertiesSave}
            />
          </div>
        </div>
      </div>
    )
  }
}

FieldDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  childObjectType: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  field: PropTypes.object,
  majorObject: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}
