/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { RefObject } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import logger from '../../helpers/logger'
import { MajorObjectVersioned } from '../MajorObjectVersioned/MajorObjectVersioned'
import { ObjectHistoryTable } from '../ObjectHistoryTable/ObjectHistoryTable'
import FilteredTabbedTable from '../FilteredTabbedTable/FilteredTabbedTable'
import { Messages } from '../Messages/Messages'
import { ElementDialog } from './ElementDialog'
import { FrameDialog } from './FrameDialog'
import { StyleDialog } from './StyleDialog'
import { LayoutDialog } from '../LayoutDialog/LayoutDialog'
import { NestedTable } from '../NewTable/NestedTable'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import ObjectSearch from '../ObjectSearch/ObjectSearch'
import md from '../../helpers/md'
import { checkResourceUrl, deepCopy, deepMerge, elementSort, nonCSCompare, pluralTypeForms } from '../../helpers/index'
import { API, authDownload, getObjectsWithConnected, idAPI, sendObjectNew } from '../../helpers/api'
import { columnsToType, createEmptyObject, datasetToObjectArray, isDark } from '../../helpers'
import { getObjectByName } from '../../helpers/data'
import { defaultLocale, defaultLocaleCode, defaultLocaleId } from '../../resources/lib/metadata'
import cloneDeep from 'lodash/cloneDeep'

import './View.scss'
import { getTailButtonLabel } from '../../helpers/helperComponents'
import { Tab } from '../FilteredTabbedTable/TabType'

export class View extends MajorObjectVersioned {
  filteredtabbedtableRef: RefObject<any>

  constructor(props) {
    super(props)

    // @ts-ignore
    this.state.currentLocale = defaultLocale['Locale id']
    // @ts-ignore
    this.state.objectType = 'view'
    // @ts-ignore
    this.state.objectName = this.getObjectName(this.state.objectType)
    // @ts-ignore
    this.state.expandTexts = false
    // @ts-ignore
    this.state.defaultLocale = defaultLocale

    this.filteredtabbedtableRef = React.createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    const objectType = this.getObjectType(props)
    if (objectType === this.state.objectType) return
    if (!this.props.userState.profile && props.userState.profile) this.loadUserRole(props)
    //getObjectsWithConnected(this.getDataRequestQuery(props), this.state.objectType, this.state.objectName, this.props.actions);
    if (
      !props.majorObject &&
      this.props.majorObject &&
      this.props.majorObject.identity.id !== 0 &&
      props.location.pathname !== this.props.location?.pathname
    ) {
      const newState = {
        objectType: 'view',
        objectName: props.match.params[this.getObjectType(props) + 'Name']
      }

      // console.log("Dataset::componentWillReceiveProps will reload");

      //console.log("newState", newState);

      this.setState(newState, () => {
        getObjectsWithConnected(
          this.getDataRequestQuery(props),
          this.state.objectType,
          this.state.objectName,
          this.props.actions
        )
      })

      //if (props.majorObject && this.props.majorObject && props.majorObject.identity.id === this.props.majorObject.identity.id)
      //  return;

      //this.clearTabsContentLoaded(['structure']);          // show Loader for object table
    }
  }

  componentDidMount() {
    const view = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    if (view && view.identity.id) {
      this.props.actions.updateMajorObject(view.identity.id, pluralTypeForms.get(this.state.objectType), {
        isFetching: true
      })
    }

    getObjectsWithConnected(
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  getViewApiRequest = (props = this.props) => {
    return API.organizations(props.match.params.organizationName)
      .systems(props.match.params.systemName)
      .applications(props.match.params.applicationName)
      .views(props.match.params.viewName)
  }

  getDataRequestQuery(props, version?) {
    const viewApiEndpoint = version ? this.getViewApiRequest(props).versions(version) : this.getViewApiRequest(props)

    return {
      endpoint: this.getViewApiRequest(props),
      objectRequest: viewApiEndpoint,
      objectRequestCallback: (obj, errorStatus) => {
        this.setState({ errorStatus: errorStatus })
        /*
        getObjectNew(API.organizations(props.match.params.organizationName).systems(props.match.params.systemName), 'system').then(system => {
          const locales = datasetToObjectArray(md.repo.metadata.apdax.systems.difhub.applications.organization.datasets.locale);
          console.log("View.system", system, locales);
          const systemDefaultLocale = locales.reduce((found, cur) => cur["Locale id"] === system.locale ? cur : found, defaultLocale);
          this.setState({defaultLocale: systemDefaultLocale, currentLocale: systemDefaultLocale["Locale Code"]})
        });
        */
        return true
      },
      connectedObjectsRequests: [
        {
          objectType: 'user',
          majorObjectType: 'view',
          isObjectProperty: true,
          propertyName: 'users',
          request: viewApiEndpoint.users(),
          connectedObjectsRequestCallback: this.checkUserRole(props)
        },
        {
          objectType: 'version',
          isObjectProperty: true,
          majorObjectType: 'view',
          propertyName: 'versions',
          request: viewApiEndpoint.versions(),
          connectedObjectsRequestCallback: () => {
            if (this.state.loadingCheckpoints) {
              this.requestCheckpoints().then(() => {
                this.setState({ loadingCheckpoints: false })
              })
            }
          }
        }
      ]
    }
  }

  expandAllStructure = () => {
    if (this.filteredtabbedtableRef.current && this.filteredtabbedtableRef.current.refs.table_definitions) {
      this.filteredtabbedtableRef.current.refs.table_definitions.toggleAllRows()
      setTimeout(() => {
        this.forceUpdate()
      }, 0)
    }
  }

  isExpandAllStructure = () => {
    if (this.filteredtabbedtableRef.current && this.filteredtabbedtableRef.current.refs.table_definitions) {
      const rows = this.filteredtabbedtableRef.current.refs.table_definitions.state.expandedRows
      //console.log("Dataset:isExpandAllStructure", rows.length, this.filteredtabbedtableRef.current.refs.table_definitions.props.data.length, rows.reduce((all,cur) => all && cur, true) );
      if (
        rows.length === this.filteredtabbedtableRef.current.refs.table_definitions.props.data.length &&
        rows.reduce((all, cur) => all && cur, true)
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  toggleExpandTexts = () => {
    this.setState({ expandTexts: !this.state.expandTexts })
  }

  renderTextColumnHeader() {
    return (
      <span>
        Text&nbsp;
        <a
          className={
            'MajorObject__expandButton ' +
            (isDark(this.state.objectType) ? ' MajorObject__expandButton__dark ' : '') +
            (this.state.expandTexts ? 'MajorObject__expandButton__active' : '')
          }
          onClick={this.toggleExpandTexts}
        ></a>
      </span>
    )
  }

  getTextType = () => {
    return { name: this.state.expandTexts ? 'text_expanded' : 'text' }
  }

  getTabsProps = (): Tab[] => {
    const view = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable = this.checkEditable()

    return [
      {
        title: 'definitions',
        tableComponent: NestedTable,
        filters: [
          { name: '', width: 97 },
          { name: 'search', width: 300 },
          { name: '', width: 10 },
          { name: 'visibility', width: 92 },
          { name: '', width: 428 }
        ],
        columns: [
          {
            displayName: 'Ord.',
            name: 'order',
            type: columnsToType.getType('id'),
            frozen: true,
            width: 42,
            hiddenInChildTable: true
          },
          {
            name: 'expandButton',
            displayName: (
              <span>
                <a
                  className={
                    'MajorObject__expandStructureButton ' +
                    (isDark(this.state.objectType) ? ' MajorObject__expandStructureButton__dark ' : '') +
                    (this.isExpandAllStructure() ? 'MajorObject__expandStructureButton__active' : '')
                  }
                  onClick={this.expandAllStructure}
                ></a>
              </span>
            ),
            type: { name: 'data' },
            frozen: true,
            width: 32
          },
          {
            name: 'element',
            displayName: 'Element Name',
            type: columnsToType.getType('elementName'),
            width: 225,
            onCellClick: (value) => {
              this.setState({
                editingChild: true,
                childObjectType: 'element',
                activeMinorObject: this.activeOrDefaultElement(value.identity.name),
                activeMinorObjectEditable: this.checkEditable(),
                childInEditMode: false
              })
            }
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 355
          },
          {
            name: 'visible',
            displayName: 'Vis.',
            type: columnsToType.getType('visibility'),
            width: 40
          },
          {
            name: 'preview',
            type: columnsToType.getType('preview'),
            width: 70
          },
          {
            name: 'text',
            displayName: this.renderTextColumnHeader(),
            type: this.getTextType(),
            width: 1184 - 245 - 295 - 90 - 100 - 200 - 150 + 200 - 32 - 100 - 200 - 50 + 40
          },
          {
            name: 'style_frame',
            displayName: 'Style',
            type: columnsToType.getType('style_frame'),
            width: 50
          }
        ],
        tailButtons: !isEditable
          ? []
          : [
            {
              label: '+ Add',
              onClick: (obj) => {
                console.log('tailButtons onClick', obj)
                this.setState({
                  editingChild: true,
                  activeMinorObject: this.createChildElement(obj),
                  childObjectType: 'element',
                  activeMinorObjectEditable: this.checkEditable(),
                  childInEditMode: this.checkEditable()
                })
              }
            },
            {
              label: getTailButtonLabel('Edit'),
              onClick: (obj) => {
                console.log('Edit', obj)
                this.setState({
                  editingChild: true,
                  childObjectType: 'element',
                  activeMinorObject: this.activeOrDefaultElement(obj.element.name),
                  activeMinorObjectEditable: this.checkEditable(),
                  childInEditMode: this.checkEditable()
                })
              }
            },
            {
              label: getTailButtonLabel('Delete'),
              onClick: (obj) => {
                //this.deleteElement(obj.identity.id)
                console.log('Delete', obj)
                this.showDeleteElement(obj.identity.id, obj.element.name)
              }
            }
          ],
        contextMenuButtons: [
          {
            label: 'Copy element',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              const view = this.getObject()

              let newDefinitions: any[] = []
              view.definitions.forEach((def) => {
                const newElement = def.elements.filter((elem) => nonCSCompare(elem.identity.name, data.identity.name))

                newDefinitions.push({
                  locale: def.locale,
                  element: newElement ? newElement[0] : null
                })
              })

              localStorage.clipboard = JSON.stringify(newDefinitions)
              localStorage.clipboardType = 'element'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
              {
                label: 'Paste element',
                data: { action: 'paste' },
                onClick: () => {
                  //console.log("Paste dataset");

                  if (!localStorage.clipboard) {
                    alert('No element copied')
                    return
                  }
                  if (localStorage.clipboardType !== 'element') {
                    alert('No element copied')
                    return
                  }

                  this.setState({
                    pastingChild: true,
                    pastingChildName: JSON.parse(localStorage.clipboard)[0].element?.identity.name
                  })
                },
                // @ts-ignore
                showInBottom: true
              },
              {
                label: 'Download picture',
                data: { action: 'download' },
                onClick: (e, data, t) => {
                  const element = this.activeOrDefaultElement(data.identity.name).element
                  console.log('download', element)
                  authDownload(element.identity.name + '.png', checkResourceUrl(element.image))
                }
              },
              {
                label: 'Download all pictures',
                data: { action: 'download_all' },
                onClick: (e, data, t) => {
                  const elements = this.getActiveDefinitionElements()
                  elements.forEach((element) => {
                    console.log('download', element)
                    authDownload(element.identity.name + '.png', checkResourceUrl(element.image))
                  })
                }
              },
              {
                label: 'Reorder elements',
                data: { action: 'reorder' },
                onClick: () => {
                  const view = this.getObject()
                  let newDefinitions = deepCopy(view.definitions)

                  const defLocale = this.getLocaleById(this.state.currentLocale)
                  const localeId = defLocale['Locale id']
                  const localeCode = defLocale['Locale Code']

                  newDefinitions.forEach((def) => {
                    if (nonCSCompare(def.locale, localeCode) || nonCSCompare(def.locale, localeId)) {
                      def.elements = def.elements
                        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
                        .map((field, index) => {
                          return Object.assign({}, field, {
                            order: index + 1
                          })
                        })
                    }
                  })

                  this.sendView(Object.assign({}, view, { definitions: newDefinitions }))
                }
              },
              {
                label: 'Reorder by 10 step',
                data: { action: 'reorder' },
                onClick: () => {
                  const view = this.getObject()
                  let newDefinitions = deepCopy(view.definitions)

                  const defLocale = this.getLocaleById(this.state.currentLocale)
                  const localeId = defLocale['Locale id']
                  const localeCode = defLocale['Locale Code']

                  newDefinitions.forEach((def) => {
                    if (nonCSCompare(def.locale, localeCode) || nonCSCompare(def.locale, localeId)) {
                      let delta = 1
                      def.elements = def.elements
                        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
                        .map((field, index, arr) => {
                          return Object.assign({}, field, {
                            order: index === 0 ? delta : (delta += 10)
                          })
                        })
                    }
                  })

                  this.sendView(Object.assign({}, view, { definitions: newDefinitions }))
                }
              }
            ]
        ),
        topButtons: !isEditable
          ? []
          : [
            {
              label: '+ Add',
              onClick: () =>
                this.setState({
                  editingChild: true,
                  activeMinorObject: this.createElement(),
                  childObjectType: 'element',
                  activeMinorObjectEditable: this.checkEditable(),
                  childInEditMode: true
                })
            }
          ],
        bottomButtons: !isEditable
          ? []
          : [
            {
              label: '+ Add element',
              onClick: () =>
                this.setState({
                  editingChild: true,
                  activeMinorObject: this.createElement(),
                  childObjectType: 'element',
                  activeMinorObjectEditable: this.checkEditable(),
                  childInEditMode: true
                })
            }
          ]
      },
      {
        title: 'layouts',
        filters: [
          { name: '', width: 250 - 200 },
          { name: 'tags', width: 250 },
          { name: '', width: 150 },
          { name: 'platform', width: 250 } /*, 'type'*/
        ],
        columns: [
          {
            name: 'name',
            displayName: 'Layout name',
            type: columnsToType.getType('minorObjectName'),
            onCellClick: (value, rowIndex) => {
              this.setState({
                editingChild: true,
                childObjectType: 'layout',
                activeMinorObject: view.layouts.find((f) => f.identity.name === value.identity.name),
                activeMinorObjectEditable: this.checkEditable(),
                childInEditMode: false
              })
            },
            width: 250
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 400
          },
          {
            name: 'platform',
            type: columnsToType.getType('string'),
            width: 250
          },
          { name: 'unit', type: columnsToType.getType('string'), width: 285 }
        ],
        bottomButtons: !isEditable
          ? []
          : [
            {
              label: '+ Add layout',
              onClick: () => {
                this.setState({
                  editingChild: true,
                  childObjectType: 'layout',
                  activeMinorObjectEditable: true,
                  childInEditMode: true
                })
              }
            }
          ],
        topButtons: !isEditable
          ? []
          : [
            {
              label: '+ Add',
              onClick: () => {
                this.setState({
                  editingChild: true,
                  childObjectType: 'layout',
                  activeMinorObjectEditable: true,
                  childInEditMode: true
                })
              }
            }
          ],
        tailButtons: !isEditable
          ? []
          : [
            {
              label: getTailButtonLabel('Edit'),
              onClick: (value, rowIndex) => {
                this.setState({
                  editingChild: true,
                  childObjectType: 'layout',
                  activeMinorObject: view.layouts.find((f) => f.identity.name === value.identity.name),
                  activeMinorObjectEditable: this.checkEditable(),
                  childInEditMode: this.checkEditable()
                })
              }
            },
            {
              label: getTailButtonLabel('Delete'),
              onClick: (value) => this.deleteLayout(value.identity.id)
            }
          ]
      },
      {
        title: 'history',
        filters: [],
        columns: [],
        tableComponent: ObjectHistoryTable,
        parentObject: view
      }
    ]
  }

  onStyleClick = (element) => {
    console.log('View:onStyleClick', element)
    this.setState({
      editingChild: true,
      childObjectType: 'style',
      activeMinorObject: this.activeOrDefaultElement(element.identity.name),
      childInEditMode: this.checkEditable()
    })
  }

  onFrameClick = (element) => {
    this.setState({
      editingChild: true,
      childObjectType: 'frame',
      activeMinorObject: this.activeOrDefaultElement(element.identity.name),
      childInEditMode: this.checkEditable()
    })
  }

  /**
   * Prepare for view element using base element data if exist
   * @param {object} element to prepare.
   * @return {object} data for view
   */
  prepareElement = (element, base?) => {
    if (!element) return

    let gray = base ? true : false
    let merge = base && base !== true && base !== false ? base : null

    let style =
      merge && merge?.style ? deepMerge(element?.style, merge?.style) : element?.style || createEmptyObject('style')
    let frame =
      merge && merge?.frame ? deepMerge(element?.frame, merge?.frame) : element?.frame || createEmptyObject('frame')

    return {
      identity: element.identity,
      parent: element.parent && element.parent.name ? element.parent : merge ? merge.parent : element.parent,
      element: { name: element.identity.name, isGrayed: gray },
      order: element.order ? element.order : merge ? merge.order : element.order,
      description: element.identity.description
        ? element.identity.description
        : merge
          ? merge.identity.description
          : '',
      visible: element.visibility
        ? element.visibility !== 'Hidden' && element.visibility !== 'Collapse'
        : merge
          ? merge.visibility !== 'Hidden' && merge.visibility !== 'Collapse'
          : false,
      preview: checkResourceUrl(element.image ? element.image : merge ? merge.image : element.image),
      text: element.text ? element.text : merge ? merge.text : '',
      style_frame: {
        style: style,
        frame: frame,
        onStyleClick: this.onStyleClick.bind(null, element),
        onFrameClick: this.onFrameClick.bind(null, element)
      }
    }
  }

  /**
   * Prepare for view element when it have base data of view default locale
   * @param {object} element to prepare.
   * @return {object} data for view
   */
  prepareElementMerged = (element) => {
    const viewElements = this.getDefinitionElementsByLocale()

    let oldElement = viewElements.find((elem) => nonCSCompare(elem.identity.name, element.identity.name))
    let newElement = this.prepareElement(element, oldElement)

    console.log('View:prepareElementMerged', newElement, oldElement)
    return newElement
  }

  getData = () => {
    const view = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    const viewDef = this.getDefinitionElementsByLocale()
    const currDef = this.getActiveDefinitionElements()

    return this.getTabsProps().map((block) => {
      switch (block.title) {
        case 'definitions':
          block.data = []

          if (currDef === viewDef) {
            block.data = block.data.concat(viewDef.map((element) => this.prepareElement(element)))
          } else {
            block.data = block.data.concat(currDef.map((element) => this.prepareElementMerged(element)))
            block.data = block.data.concat(
              viewDef
                .filter((elem) => currDef.findIndex((el) => nonCSCompare(el.identity.name, elem.identity.name)) < 0)
                .map((element) => this.prepareElement(element, true))
            )
          }

          block.data = block.data
            .map((element) => {
              // We need special sort for children of the hierarchical elements
              if (element.parent) {
                let parentElement = block.data.find(
                  (curr) =>
                    nonCSCompare(curr.identity.id, element.parent.id) ||
                    nonCSCompare(curr.identity.name, element.parent.name)
                )

                if (parentElement) {
                  parentElement.children = (parentElement.children || []).concat(element).sort(elementSort)
                  return null
                } else {
                  //console.warn("Incorrect reference to parent element", element, parentElement);
                  return element
                }
              } else {
                return element
              }
            })
            .filter((element) => element)
            .sort(elementSort)

          return block

        case 'layouts':
          block.data = view.layouts
            ? view.layouts.map((layout) => {
              const newName = {
                name: layout.identity.name,
                objectType: 'layout',
                path: layout.path
              }
              layout.description = layout.identity.description
              layout = Object.assign({}, layout, { name: newName })

              return layout
            })
            : []
          return block
        case 'history':
          block.data = [
            {
              versions: view.versions,
              checkpoints: view.checkpoints,
              loadingCheckpoints: this.state.loadingCheckpoints,
              restore: this.restoreFromCheckpoint
            }
          ]
          return block

        default:
          return block
      }
    })
  }

  restoreFromCheckpoint = (data) => {
    this.sendView(Object.assign({}, this.getObject(), data), null, (tmp, error) => {
      if (!error) alert('View was restored!')
      else alert(error)
    })
  }

  showDeleteElement = (id, elementName) => {
    this.setState({
      deleteChildObject: {
        objectType: 'element',
        obj: { identity: { name: elementName } },
        delete: () => {
          this.setState({ deleteChildObject: false })
          this.deleteElement(id, elementName)
        }
      }
    })
  }

  deleteElement = (id, name = '') => {
    const newView = Object.assign({}, this.getObject())

    const oldElements = this.getActiveDefinitionElements()
    if (id) {
      newView.definitions = this.updateDefinitions({
        locale: this.state.currentLocale,
        elements: oldElements.filter((el) => el.identity.id !== id)
      })
    } else if (name) {
      newView.definitions = this.updateDefinitions({
        locale: this.state.currentLocale,
        elements: oldElements.filter((el) => !nonCSCompare(el.identity.name, name))
      })
    }
    console.log('deleteElement', oldElements, newView.definitions)

    this.sendView(newView, null, null)
  }

  saveElement = (element, closeDialog, onSent) => {
    const view = this.getObject()

    const newView = Object.assign(
      {},
      {
        identity: view.identity,
        definitions: this.updateDefinitionElement(element)
      }
    )

    if (this.state.activeMinorObject && this.state.activeMinorObject.base) {
      if (this.state.activeMinorObject.base.identity.name !== element.identity.name) {
        const oldName = this.state.activeMinorObject.base.identity.name
        const newName = element.identity.name
        newView.definitions.forEach((definition) => {
          definition.elements.forEach((element) => {
            if (element.identity.name === oldName) {
              element.identity.name = newName
            }
          })
        })
      }
    }

    this.saveView(newView, closeDialog, onSent)
  }

  pasteChild = (params, closeDialog, onSent) => {
    const view = this.getObject()

    const newObject = JSON.parse(localStorage.clipboard)

    if (localStorage.clipboardType === 'element') {
      let newElemOrder: any = null

      const newDefinitions = view.definitions.map((def) => {
        const newDefinition = cloneDeep(def)

        const highestIndex = Math.max(...newDefinition.elements.map((elem) => elem.order)) + 1

        const definition = newObject.find((d) => nonCSCompare(d.locale, def.locale))

        if (definition.element) {
          definition.element.identity.name = params.objectName
          definition.element.identity.id = ''

          if (highestIndex) {
            definition.element.order = highestIndex
            newElemOrder = highestIndex
          }

          newDefinition.elements.push(definition.element)
        }

        return newDefinition
      })

      const newView = Object.assign(
        {},
        {
          identity: view.identity,
          definitions: newDefinitions
        }
      )

      // post new parent and find children
      this.saveView(newView, closeDialog, onSent).then((data: any) => {
        const newParent = data.definitions[0].elements.find((elem) => elem.order === newElemOrder)
        const view = this.getObject()

        const newDefinitions = view.definitions.map((def) => {
          const newDefinition = deepCopy(def)

          const childrenForElement = newDefinition.elements
            .filter((elem) => elem.parent.id === newObject[0].element.identity.id)
            .map((elem, idx) => {
              const newElem = deepCopy(elem)

              newElem.identity.id = ''
              newElem.identity.name = newParent.identity.name + '_' + newElem.identity.name

              newElem.parent.id = newParent.identity.id
              newElem.parent.name = newParent.identity.name

              newElem.order = newParent.order + idx + 1

              return newElem
            })

          if (childrenForElement.length) {
            newDefinition.elements.push(...childrenForElement)
          }

          return newDefinition
        })

        const newView = Object.assign(
          {},
          {
            identity: view.identity,
            definitions: newDefinitions
          }
        )

        this.saveView(newView, closeDialog, onSent)
      })
    }
  }

  /**
   * Get locale object by locale id or code
   * @param {string} locale - Id or Code for required locale. Locale of view if not specified.
   * @return {object} locale object for id or code specified
   */
  getLocaleById = (locale?) => {
    // When locale not specified use view locale or english.
    if (!locale) {
      const view = this.getObject()
      if (!view.locale || view.locale === defaultLocaleId || view.locale === defaultLocaleCode) {
        return defaultLocale
      }
      locale = view.locale
    }

    const locales = datasetToObjectArray(
      md.repo.metadata.apdax.systems.difhub.applications.organization.datasets.locale
    )
    return locales.find(
      (lc) => nonCSCompare(lc['Locale id'], locale) || nonCSCompare(lc['Locale Code'], locale.toString())
    )
  }

  /**
   * Is local a view locale
   * @param {string} locale - Id or Code for required locale. Locale of view if not specified.
   * @return {boolean} locale is a default
   */
  isViewLocale = (locale?) => {
    // Get view locale
    const viewLocale = this.getLocaleById()

    // When locale not specified it is current.
    if (!locale) {
      locale = this.state.currentLocale
    }

    const localeId = viewLocale['Locale id']
    const localeCode = viewLocale['Locale Code']

    return locale === localeId || locale === localeCode
  }

  /**
   * Get definision of view for specified locale id or code
   * @param {string} locale - Id or Code for required definition.
   * @return {object} definision for specified locale
   */
  getDefinitionByLocale = (locale) => {
    const view = this.getObject()

    const defLocale = this.getLocaleById(locale)

    const localeId = defLocale['Locale id']
    const localeCode = defLocale['Locale Code']

    return (view.definitions || []).find(
      (def) => nonCSCompare(def.locale, localeCode) || nonCSCompare(def.locale, localeId)
    )
  }

  /**
   * Get definision elements of view for specified locale id or code
   * @param {string} locale - Id or Code for required definition.
   * @return {array} array of elements from definision for specified locale
   */
  getDefinitionElementsByLocale = (locale?) => {
    const viewDef = this.getDefinitionByLocale(locale)
    return viewDef ? viewDef.elements || [] : []
  }

  /**
   * Get definision for current locale
   * @return {object} definision for current locale
   */
  getActiveDefinition() {
    return this.getDefinitionByLocale(this.state.currentLocale)
  }

  /**
   * Get definision elements array  for current locale
   * @return {array} definision elements for current locale
   */
  getActiveDefinitionElements() {
    return this.getDefinitionElementsByLocale(this.state.currentLocale)
  }

  /**
   * Get active and default element data
   * @return {object} definision for current locale
   */
  activeOrDefaultElement(elementName) {
    const currElements = this.getActiveDefinitionElements()
    const baseElements = this.getDefinitionElementsByLocale()

    let curr = currElements.find((elem) => nonCSCompare(elem.identity.name, elementName))
    let base = baseElements.find((elem) => nonCSCompare(elem.identity.name, elementName))
    console.log('View:activeOrDefaultElement', elementName, curr, base, currElements, baseElements)
    if (!curr) {
      curr = this.createElement(base).element
      curr.identity.name = elementName
    }
    return { element: curr, base: this.isViewLocale() ? null : base }
  }

  /**
   * Create new element for editing
   * @param {object} base - element from default view locale we create translation for
   * @return {object} empty element
   */
  createElement = (base?) => {
    // createEmptyObject('element')
    let order = base ? base.order : 0
    if (!order) {
      this.getActiveDefinitionElements().forEach((f) => {
        order = order < f.order ? f.order : order
      })
      this.getDefinitionElementsByLocale().forEach((f) => {
        order = order < f.order ? f.order : order
      })
      order += 1
    }
    return base
      ? { element: { identity: { name: '' } }, base: base }
      : { element: { identity: { name: '' }, order: order } }
  }

  createChildElement = (obj) => {
    let element: any = this.createElement().element
    element.parent = {
      id: obj.identity.id,
      name: obj.identity.name
    }
    return { element: element, base: null }
  }

  updateDefinitionElement(element) {
    const definition = this.getActiveDefinition()

    // Let's remove element id as it can lead to update of wrong element.
    if (definition && element.identity && element.identity.id) {
      const elementIndex = definition.elements.findIndex((e) => e.identity.id === element.identity.id)
      if (elementIndex < 0) {
        delete element.identity.id
      }
    }

    const defLocale = definition ? definition.locale : this.state.currentLocale

    let locale = this.getLocaleById(defLocale)
    if (!locale) {
      locale = defaultLocale
    }

    // Code and id of locale
    const localeId = locale['Locale id']
    const localeCode = locale['Locale Code']

    // We need to keep compatability. If definision have id as locale,
    // let's keep it this way, or we will have two definisions for same locale.
    let definitions = [
      {
        locale: definition && definition.locale === localeId ? localeId : localeCode,
        elements: [element]
      }
    ]
    console.log('View:updateDefinitionElement', element, definitions)

    return definitions
  }

  /**
   * Change definision in difinision view of the list
   * @param {object} definision - definision with new state
   * @return {array} - list of updated definisions
   */
  updateDefinitions(definition) {
    const view = this.getObject()
    const defLocale = definition.locale

    let locale = this.getLocaleById(defLocale)
    if (!locale) {
      locale = defaultLocale
    }

    // Code and id of locale
    const localeId = locale['Locale id']
    const localeCode = locale['Locale Code']

    definition.locale = localeCode
    let definisions = view.definitions
      .filter((def) => !(nonCSCompare(def.locale, localeId) || nonCSCompare(def.locale, localeCode)))
      .concat(definition)

    logger.info(
      'View:updateDefinitions',
      { view, definition, locale, localeId, localeCode, definisions },
      this.state,
      this.props
    )
    return definisions
  }

  saveFrame = (frame, closeDialog, onSent) => {
    const view = this.getObject()
    const newElement = Object.assign({}, this.state.activeMinorObject.element, {
      frame: frame
    })

    const newView = Object.assign(
      {},
      {
        identity: view.identity,
        definitions: this.updateDefinitionElement(newElement)
      }
    )

    this.saveView(newView, closeDialog, onSent)
  }

  saveStyle = (style, closeDialog, onSent) => {
    const view = this.getObject()
    const newElement = Object.assign({}, this.state.activeMinorObject.element, {
      style: style
    })

    const newView = Object.assign(
      {},
      {
        identity: view.identity,
        definitions: this.updateDefinitionElement(newElement)
      }
    )
    console.log('View:saveStyle', style, newView)

    this.saveView(newView, closeDialog, onSent)
  }

  /**
   * Delete layout from view
   * @param {id} layout - layout to delete
   */
  deleteLayout = (layoutId) => {
    const newView = Object.assign({}, this.getObject())

    newView.layouts = newView.layouts.filter((layout) => layout.identity.id !== layoutId)

    this.sendView(newView)
  }

  /**
   * Save view with new or updated layout
   * @param {object} layout - new or updated layout
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   */
  saveLayout = (layout, closeDialog, onSent) => {
    // console.log("Interface:saveParameter", layout, closeDialog);
    const newView = Object.assign({}, { identity: this.getObject().identity, layouts: [layout] })

    this.saveView(newView, closeDialog, onSent)
  }

  /**
   * Save veiw update using patch action
   * @param {object} [newView] - new view with changes only
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   */
  saveView = (newView, closeDialog?, onSent?) => {
    const newViewIdent = Object.assign({}, { identity: this.getObject().identity }, newView)
    console.log('View:saveView=============>', newView, newViewIdent)

    return sendObjectNew(
      idAPI.views(newView.identity.id),
      'patch',
      this.props.actions,
      this.getObject(),
      newViewIdent
    ).then(
      (result) => {
        if (onSent) onSent(closeDialog)
        return result
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  /**
   * Send veiw update using put action
   * @param {object} [newView] - new view with changes only
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   */
  sendView = (newView, closeDialog?, onSent?) => {
    const newViewIdent = Object.assign({}, { identity: this.getObject().identity }, newView)
    console.log('View:sendView', newView, newViewIdent)

    return sendObjectNew(
      idAPI.views(newView.identity.id),
      'put',
      this.props.actions,
      this.getObject(),
      newViewIdent
    ).then(
      (result) => {
        if (onSent) onSent(closeDialog)
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  closeEditDialog = () => {
    this.setState(
      {
        editingChild: false,
        childObjectType: '',
        activeMinorObject: null
      },
      () => {}
    )
  }

  openLocaleWindow() {
    //console.log("View::openLocaleWindow");
    this.setState({ creatingLocale: true })
  }

  selectLocale = (event) => {
    console.log('selectLocale', event.target.value)
    this.setState({
      currentLocale: event.target.value
    })
  }

  // @ts-ignore
  renderLocale = () => {
    const locales =
      datasetToObjectArray(md.repo.metadata.apdax.systems.difhub.applications.organization.datasets.locale) || []
    const localeCodes = (this.getObject().definitions || [])
      .map((definition) => definition.locale)
      .filter((locale) => locale)
    //let active = localeCodes.indexOf(this.state.currentLocale);
    let currentLocales = locales.filter(
      (locale) => localeCodes.indexOf(locale['Locale id']) !== -1 || localeCodes.indexOf(locale['Locale Code']) !== -1
    )
    //console.log("View:renderLocale", localeCodes, this.state.currentLocale, active);
    let options = [this.state.defaultLocale].concat(
      currentLocales.filter((locale) => locale && locale['Locale Code'] !== this.state.defaultLocale['Locale Code'])
    )
    let active = 0
    options.forEach((option, optionIndex) => {
      if (option['Locale Code'] === this.state.currentLocale || option['Locale id'] === this.state.currentLocale) {
        // active = optionIndex + 1
        active = option['Locale Code']
      }
    })
    //console.log('View:renderLocale options', options, active)

    return (
      <div className="MajorObject__locale">
        <div>
          <a onClick={() => this.openLocaleWindow()}>Create locale</a>
        </div>
        <div className="row">
          <strong>Locale: </strong>
          <div className="col-xs-6">
            <FormControl variant="outlined" fullWidth={true}>
              <Select value={active} onChange={this.selectLocale} autoWidth={true} className="">
                {options.map((locale, index) => (
                  <MenuItem value={locale['Locale Code']} key={`${locale['Locale Code']}__${index}`}>
                    {locale['Locale id'] ? locale['Locale id'] + ':  ' + locale['Locale Code'] : locale['Locale Code']}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    )
  }

  renderLocaleEditor = () => {
    const locales = datasetToObjectArray(
      md.repo.metadata.apdax.systems.difhub.applications.organization.datasets.locale
    )

    let currentLocaleCodes = (this.getObject().definitions || []).map((definition) => definition.locale)

    let placeholder = 'Search locale'
    locales.forEach((locale) => {
      if (locale['Locale id'] === this.state.creatingLocale || locale['Locale Code'] === this.state.creatingLocale) {
        placeholder = locale['Locale Name']
      }
    })

    return (
      <ModalWindow
        level={1}
        className="DraftWindow LocaleCreateWindow"
        isOpen
        onClose={() => this.setState({ creatingLocale: false })}
        header={'Create locale'}
        footer={
          <div>
            <button className="btn_big ActionWindow__button" onClick={() => this.setState({ creatingLocale: false })}>
              Close
            </button>
            <button className={'btn_big ActionWindow__button'} onClick={this.onCreateLocale}>
              Save
            </button>
          </div>
        }
      >
        <div className="DraftWindow__text LocaleCreateWindow__text">
          <div className="DraftWindow__label">Select locale to create:</div>
          <div className="row ">
            <ObjectSearch
              className="col-xs-12"
              onSelect={(item) => {
                this.setState({ creatingLocale: item.code })
              }}
              placeholder={placeholder}
              options={locales
                .filter((locale) => currentLocaleCodes.indexOf(locale['Locale id']) === -1)
                .map((locale) => {
                  return {
                    code: locale['Locale id'] ? locale['Locale id'] : locale['Locale Code'],
                    displayName: locale['Locale Name'],
                    identity: {
                      name: locale['Locale Name'],
                      description: locale['Locale Code']
                    },
                    type: 'locale'
                  }
                })}
              filter={(item, type, query) =>
                item.displayName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                item.code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                item.identity.description.toLowerCase().indexOf(query.toLowerCase()) !== -1
              }
            />
          </div>
        </div>
      </ModalWindow>
    )
  }

  onCreateLocale = () => {
    const view = this.getObject()

    let newView = Object.assign({}, view, {})
    newView.definitions = this.updateDefinitions({
      locale: this.state.creatingLocale,
      elements: []
    })

    this.saveView(newView)

    this.setState({
      creatingLocale: false,
      currentLocale: this.state.creatingLocale
    })
  }

  render() {
    const view = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    if (!view || view.isFetching) {
      ////console.log("application: ", app);
      return this.renderLoading()
    } else {
      //console.log("View:render:ELEMENT", this.state.activeMinorObject, view, this.state.currentLocale);

      return (
        <div className="MajorObject__outer">
          <section className={'MajorObjectView MajorObjectView_' + this.state.objectType}>
            {this.renderHeader()}
            {this.renderInfo()}

            {this.state.childObjectType === 'element' ? (
              <ElementDialog
                appState={this.props.appState}
                actions={this.props.actions}
                isEditable={this.state.childInEditMode ? 2 : this.checkEditable() ? 1 : 0}
                isVisible={this.state.editingChild}
                onSave={this.saveElement}
                onClose={this.closeEditDialog}
                element={this.state.activeMinorObject.element}
                base={this.state.activeMinorObject.base}
                locale={this.state.currentLocale}
                majorObject={view}
                getActiveDefinition={this.getActiveDefinition.bind(this)}
              />
            ) : null}
            {this.state.childObjectType === 'frame' ? (
              <FrameDialog
                appState={this.props.appState}
                actions={this.props.actions}
                buttonTitle={'Frame'}
                isVisible={this.state.editingChild}
                isEditable={this.state.childInEditMode ? 2 : this.checkEditable() ? 1 : 0}
                onSave={this.saveFrame}
                onClose={this.closeEditDialog}
                frame={this.state.activeMinorObject.element.frame}
                base={this.state.activeMinorObject.base ? this.state.activeMinorObject.base.frame : null}
                majorObject={view}
              />
            ) : null}
            {this.state.childObjectType === 'style' ? (
              <StyleDialog
                appState={this.props.appState}
                actions={this.props.actions}
                buttonTitle={'Style'}
                isVisible={this.state.editingChild}
                isEditable={this.state.childInEditMode ? 2 : this.checkEditable() ? 1 : 0}
                onSave={this.saveStyle}
                onClose={this.closeEditDialog}
                style={this.state.activeMinorObject.element.style}
                base={this.state.activeMinorObject.base ? this.state.activeMinorObject.base.style : null}
                majorObject={view}
              />
            ) : null}
            {this.state.childObjectType === 'layout' ? (
              <LayoutDialog
                appState={this.props.appState}
                actions={this.props.actions}
                isEditable={this.state.childInEditMode ? 2 : this.checkEditable() ? 1 : 0}
                isVisible={this.state.editingChild}
                onSave={this.saveLayout}
                onClose={this.closeEditDialog}
                layout={this.state.activeMinorObject}
                majorObject={view}
                isSkipSchema
              />
            ) : null}
            {this.state.creatingLocale ? this.renderLocaleEditor() : null}

            <div className="MajorObjectView__connections">
              <div className="row">
                <div className="col-xs-12">
                  {this.renderBackArrowTabs()}
                  <FilteredTabbedTable
                    ref={this.filteredtabbedtableRef}
                    /*
                    // @ts-ignore */
                    tablesData={this.getData()}
                    theme={this.state.objectType}
                    tabsLoaded={this.state.tabsLoaded}
                    onTabChange={this.onTabChange}
                  />
                  {this.renderFinalizedMessage()}
                </div>
              </div>
            </div>
          </section>

          {view && view.identity && view.identity.id ? (
            <Messages
              appState={this.props.appState}
              userState={this.props.userState}
              actions={this.props.actions}
              object={view}
              objectType={this.state.objectType}
              currentUserRole={this.state.currentUserRole}
            />
          ) : null}
        </div>
      )
    }
  }
}
