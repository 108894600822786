/**
 * Created by mailf on 11.05.2016.
 */
import PropTypes from 'prop-types'

import React from 'react'
import './style.scss'

import iKey from '../../resources/images/key-blue-2x.png'
import iKeySec from '../../resources/images/secondary-key-blue-2x.png'

const KeyIcon = ({ keyType }) => {
  if (!keyType) return null

  return (
    <div className="KeyIcon">
      {keyType === 'NONE' ? '' : <img src={keyType === 'PRIMARY' ? iKey : iKeySec} alt="icon" />}
    </div>
  )
}

KeyIcon.propTypes = {
  keyType: PropTypes.string
}

export default KeyIcon
