/**
 * Created by kascode on 26.06.17.
 */
import * as React from 'react'
// eslint-disable-next-line import/no-duplicates
import { Component } from 'react'
import * as PropTypes from 'prop-types'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import ObjectItemSearch from '../ObjectSearch/ObjectItemSearch'
import { LinkedKeyTables } from '../LinkedKeyTables/LinkedKeyTables'
import { getObjectNew, idAPI, getRequestFromPath } from '../../helpers/api'
import {
  deepCopy,
  getParentUri,
  versionSort,
  versionToStr,
  editableState,
  majorObjectSort,
  updateLocalObject,
  clearLocalObject,
  getFullUri
} from '../../helpers/index'

import './InterfacePublication.scss'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { renderRestore } from '../../helpers/helperComponents'

export class InterfacePublication extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      interfaceObject: null,
      interfaceLayouts: null,
      interfaceVersions: []
    })
  }

  getObjectType() {
    return 'interfaceReference'
  }

  UNSAFE_componentWillReceiveProps() {}

  componentDidMount() {
    if (this.props.interfaceReference)
      this.getInterfaceData(this.props.interfaceReference.identity.id, this.props.interfaceReference.identity.name)
  }

  /**
   * Get initial state
   * @param {object} [props]
   * @returns {*}
   */
  getInitial(props) {
    props = props || this.props
    // console.log("InrefaceSubscription:getInitialState", props.interfaceReference, props.publication);
    if (props.interfaceReference) {
      return JSON.parse(JSON.stringify(props.interfaceReference))
    } else {
      return {
        identity: { name: '', description: '' },
        version: { major: 0, minor: 0, revision: 0 },
        layoutNames: [],
        operationNames: []
      }
      //createEmptyObject('interfacereference');
    }
  }

  /**
   * Change state of layout
   * @param {object} - new state of object
   * @returns {*} true - it is updated, false - no changes
   */
  ChangeInterfaceReference = (reference) => {
    return this.Change(reference)
  }

  /**
   * Get data for provided interface
   * @param {string} id - uid of interface
   * @param {string} name - interface name
   * @param {boolean} updateReference - whether we need to update reference
   */
  getInterfaceData = (id, name, updateReference) => {
    let uri = getParentUri(this.props.publication, 'application') + '/interfaces/' + name
    let height =
      this.state.height -
      (this.state.interfaceObject && this.state.interfaceObject.operations
        ? this.state.interfaceObject.operations.length * 40
        : 0)

    getObjectNew(idAPI.interfaces(id), 'interface', this.props.actions).then(
      (obj) => {
        const interfaceReference = {
          identity: obj.identity,
          version: obj.object && obj.object.lastApprovedVersion ? obj.object.lastApprovedVersion : obj.version,
          layoutNames: [],
          operationNames: []
        }

        this.setState(
          {
            height: height + (obj.operations ? obj.operations.length : 0) * 40,

            interfaceObject: obj,
            interfaceLayouts: this.getLayoutOptions(obj),
            interfaceVersions: this.getVersionOptions(obj)
          },
          () => {
            if (updateReference) this.ChangeInterfaceReference(interfaceReference)
          }
        )
      },
      (err) => {
        //console.log("InrefacePublication:getInterfaceData:ERROR", err);
      }
    )
  }

  onSelectInterface(intrfc) {
    // console.log("InterfacePublication:onSelectInterface", intrfc);

    // Indicate save mode
    if (this.state.change && intrfc) {
      this.getInterfaceData(intrfc.identity.id, intrfc.identity.name, true)
    }
  }

  getVersionOptions(object) {
    const currentVersion = object.version ? object.version : { major: 0, minor: 0, revision: 0 }
    const approvedVersion =
      object.object && object.object.lastApprovedVersion ? object.object.lastApprovedVersion : null

    if (approvedVersion && versionSort(currentVersion, approvedVersion) !== 0) {
      return [
        {
          id: 1,
          label: versionToStr(currentVersion),
          value: versionToStr(currentVersion)
        },
        {
          id: 2,
          label: versionToStr(approvedVersion),
          value: versionToStr(approvedVersion)
        }
      ]
    } else {
      return [
        {
          id: 1,
          label: versionToStr(currentVersion),
          value: versionToStr(currentVersion)
        }
      ]
    }
  }

  onVersionChange(e) {
    let version
    if (e.target.value) {
      const values = e.target.value.split('.').map((e) => parseInt(e))
      version = {
        major: values[0],
        minor: values[1],
        revision: values[2]
      }
    } else {
      version = this.getVersionOptions()[0].value
    }

    let interfaceReference = deepCopy(this.state.interfaceReference)
    interfaceReference.version = version
    this.ChangeInterfaceReference(interfaceReference)
  }

  getLayoutOptions(object) {
    if (object && object.layouts && object.layouts.length > 0) {
      return object.layouts.map((element, index) => {
        return {
          id: index,
          label: element.identity.name,
          value: element.identity.name
        }
      })
    } else {
      return []
    }
  }

  onLayoutChange(e) {
    let layouts = e.target.value

    let interfaceReference = deepCopy(this.state.interfaceReference)
    interfaceReference.layoutNames = layouts.map((layout) => layout.value)

    this.ChangeInterfaceReference(interfaceReference)
  }

  onOperationChange(index) {
    return (newSelected) => {
      let allOperations = this.state.interfaceObject.operations
      let interfaceReference = deepCopy(this.state.interfaceReference)

      //console.log("InterfacePublication:onOperationChange", index, newSelected, allOperations);

      interfaceReference.operationNames = []
      for (let i in newSelected) {
        let f = allOperations[newSelected[i]]
        interfaceReference.operationNames.push(f.identity.name)
      }

      this.ChangeInterfaceReference(interfaceReference)
    }
  }

  /**
   * Filter string for item in the list
   * @return default filter string
   */
  itemFilter = (item, type, query) => {
    //console.log("InterfacePublication:itemFilter", item, type, query, this.props);
    // Check if interface already used in publication
    if (this.props.publication.interfaces.findIndex((inter) => item.identity.name === inter.identity.name) > -1)
      return false

    if (!query || query.length === 0) {
      return true
    } else {
      return item.identity.name && item.identity.name.toLowerCase().startsWith(query)
    }
  }

  renderOperations(editState) {
    let interfaceOperations = this.state.interfaceObject ? this.state.interfaceObject.operations : null
    if (
      interfaceOperations &&
      this.state.interfaceReference &&
      this.state.interfaceReference.operationNames.lengh > 0
    ) {
      interfaceOperations = interfaceOperations.filter(
        (op) => this.state.interfaceReference.operationNames.indexOf(op.identity.name) !== -1
      )
    }

    //console.log("InterfacePublication:renderOperations", this.state, interfaceOperations);

    if (!interfaceOperations) {
      return null
    }

    let selectedoperationNames = this.state.interfaceReference.operationNames
    let selectedOperations = selectedoperationNames.map((opName) =>
      interfaceOperations.reduce((p, c, i) => (c.identity.name === opName ? i : p), -1)
    )

    console.log(
      'InterfacePublication:renderOperations',
      interfaceOperations,
      selectedOperations,
      this.state.interfaceReference
    )
    return (
      <div>
        <div>
          <div className="row InterfacePublication__field">
            <div className="col-xs-1">
              <span className="InterfacePublication__label">Operations:</span>
            </div>
          </div>
          <div className="row InterfacePublication__row">
            <LinkedKeyTables
              columns={['NN', 'Name', 'Action']}
              selected={selectedOperations}
              items={interfaceOperations.map((intrfc, index) => [index, intrfc.identity.name, intrfc.action])}
              onChange={this.onOperationChange(0).bind(this)}
              editable={editState > editableState.EDITABLE}
              onSrcFieldChange={() => {}}
              canAddField={(index) => {
                return true
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render layout for publication or subscription.
   */
  renderLayout = (editState) => {
    let layoutoptions = this.state.interfaceLayouts ? this.state.interfaceLayouts : []
    let layoutNames =
      this.state.interfaceReference && this.state.interfaceReference.layoutNames
        ? this.state.interfaceReference.layoutNames
        : []

    const allLayouts = layoutoptions.map((option) => option.value)

    //console.log("InterfacePublication:renderLayout", editState, layoutoptions, layoutNames, allLayouts);

    return (
      <div>
        <div className="row InterfacePublication__row">
          <div className="col-xs-1">
            <span className="InterfacePublication__label">Layouts:</span>
          </div>
          <div className="col-xs-9">
            {editState > editableState.EDITABLE && layoutoptions.length > 0 ? (
              <EditableEntity
                data={layoutNames.length > 0 ? layoutNames.toString() : 'Select Layouts'}
                dataType={{ name: 'enum', options: layoutoptions }}
                isEditable
                inEditMode
                dataProps={{
                  hideCheckBox: false,
                  multi: true,
                  onChange: this.onLayoutChange.bind(this),
                  activeOptions: layoutNames.map((name) => allLayouts.indexOf(name))
                }}
              />
            ) : (
              <div className="InterfacePublication__subjectValue">{layoutNames}</div>
            )}
          </div>
          <div className="col-xs-8">
            <div className="InterfacePublication__infoIcon" />
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render interface for publication.
   */
  renderInterface = (editState) => {
    let description =
      this.state.interfaceReference.identity &&
      this.state.interfaceReference.identity.description &&
      this.state.interfaceReference.identity.description.length > 0
        ? this.state.interfaceReference.identity.description
        : ''
    let name =
      this.state.interfaceReference.identity &&
      this.state.interfaceReference.identity.name &&
      this.state.interfaceReference.identity.name.length > 0
        ? this.state.interfaceReference.identity.name
        : 'Select to publish'

    let versionOptions = this.state.interfaceVersions
    let version = versionToStr(this.state.interfaceReference.version)
    let uri = getParentUri(this.props.publication, 'application')

    const inEditMode = editState > editableState.EDITABLE
    return (
      <div>
        <div className={'NewSettingsBlock'}>
          <div className="row InterfacePublication__row">
            <div className="col-xs-1">
              <span className="InterfacePublication__label">Interface:</span>
            </div>
            <div className="col-xs-6">
              {inEditMode ? (
                <ObjectItemSearch
                  className="InterfacePublication__input"
                  appState={this.props.appState}
                  actions={this.props.actions}
                  path={uri}
                  type={'interfaces'}
                  onSelect={this.onSelectInterface.bind(this)}
                  placeholder={name}
                  query={''}
                  filter={this.itemFilter.bind(this)}
                  onAdjust={this.onAdjust.bind(this)}
                  keepObjectsWithoutPath
                  sortFunction={(type, list) => {
                    return list.sort((a, b) => majorObjectSort(a, b))
                  }}
                />
              ) : (
                <EditableEntity
                  data={name}
                  dataType={{ name: 'string' }}
                  dataProps={{ placeholder: '' }}
                  isEditable={false}
                  inEditMode={false}
                />
              )}
            </div>
            <div className="col-xs-3">
              <span className="InterfacePublication__label">Version:</span>
            </div>
            <div className="col-xs-4">
              {inEditMode ? (
                <EditableEntity
                  data={version}
                  dataType={{ name: 'enum', options: versionOptions }}
                  isEditable
                  inEditMode
                  dataProps={{
                    hideCheckBox: true,
                    multi: false,
                    onChange: this.onVersionChange.bind(this)
                  }}
                />
              ) : (
                <div className="InterfacePublication__subjectVersion">
                  {this.state.interfaceReference.version.major + '.*.*'}
                </div>
              )}
            </div>
            <div className="col-xs-8">
              <div className="InterfacePublication__infoIcon" />
            </div>
          </div>
        </div>
        <div className={'NewSettingsBlock'}>
          <div className="row InterfacePublication__row">
            <div className="col-xs-1">
              <div className="InterfacePublication__subjectLabel">Description:</div>
            </div>
            <div className="col-xs-7">
              <div className="InterfacePublication__subjectValue">{description}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render scrollable area of dialog
   * @return
   */
  renderData = (editState) => {
    //console.log("InterfacePublication:renderData", this.state, editState);
    return (
      <div className="InterfacePublication">
        {this.renderInterface(editState)}
        {this.renderLayout(editState)}
        {this.renderOperations(editState)}
      </div>
    )
  }

  render() {
    let caption = 'Interface to publish'

    // console.log("InterfacePublication:render", this.props, this.state);
    return (
      <div className="InterfacePublication">
        <EditorDialog
          objectType="data"
          modalTitle={caption}
          editContent={this.renderData.bind(this)}
          headerContent={renderRestore(this, () => {
            let appUri = getFullUri(this.props.publication)
            appUri = appUri.substr(0, appUri.indexOf('/publications'))
            getObjectNew(
              getRequestFromPath(appUri + '/interfaces/' + this.state.interfaceReference.identity.name)
            ).then((intf) => {
              this.getInterfaceData(intf.identity.id, intf.identity.name)
            })
          })}
          editHeight={this.state.height}
          isVisible={this.props.isVisible}
          isEditable={this.props.isEditable}
          onClose={this.props.onClose}
          onSave={this.save.bind(this)}
          onEdit={this.startEdit}
          onCancel={this.cancelEdit}
        />
      </div>
    )
  }
}

InterfacePublication.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  interfaceReference: PropTypes.object,
  publication: PropTypes.object,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}
