import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { experimentalDesignAction } from '../../actions/uiActions'
import { IRootState } from '../../reducers/rootState'

import './ExperimentalDesign.scss'

export const ExperimentalDesign = () => {
  const dispatch = useDispatch()

  const experimentalDesign = useSelector((state: IRootState) => state.ui.experimentalDesign)

  const toggleExperimentalDesign = useCallback(() => dispatch(experimentalDesignAction(!experimentalDesign)), [
    dispatch,
    experimentalDesign
  ])

  return (
    <div className="ExperimentalDesign">
      <label htmlFor="EXPERIMENTAL_DESIGN" className="ExperimentalDesign__label">
        EXPERIMENTAL DESIGN:
      </label>
      <input
        type="checkbox"
        name="EXPERIMENTAL_DESIGN"
        id="EXPERIMENTAL_DESIGN"
        className="ExperimentalDesign__input"
        checked={experimentalDesign}
        onChange={() => toggleExperimentalDesign()}
      />
    </div>
  )
}
