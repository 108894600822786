/**
 * Created by mailf on 14.06.2016.
 */
import PropTypes from 'prop-types'

import React from 'react'
import { getLocalObject, restoreLocalObject } from '../../helpers/index'

import './EditorDialogButton.scss'

export const EditorDialogButton = ({
  dialogData,
  onEdit,
  onSave,
  onCancel,
  inEditMode,
  editText,
  saveText,
  theme,
  noIcon,
  className,
  hideSave,
  cannotEdit,
  cannotEditHint
}) => {
  let body = ''
  let showResore = dialogData ? getLocalObject(dialogData) : null

  if (inEditMode) {
    let restore = ''
    let save = ''
    let cancel = ''

    // Save and Cancel
    if (hideSave) {
      // Restore button if we don't have new data but have data in store
      if (showResore) {
        restore = (
          <span
            className="EditorDialogButton__btn EditorButton__restore"
            onClick={() => restoreLocalObject(dialogData)}
          >
            {!noIcon ? <span className="EditorDialogButton__icon EditorDialogButton__restore"></span> : null}
            <span className="EditorDialogButton__iconText">Restore</span>
          </span>
        )
      }
    } else {
      // Save button if we have new data
      save = (
        <span className="EditorDialogButton__btn EditorButton__save" onClick={onSave}>
          {!noIcon ? <span className="EditorDialogButton__icon EditorDialogButton__save"></span> : null}
          <span className="EditorDialogButton__iconText">{saveText ? saveText : 'Save'}</span>
        </span>
      )
    }

    // Cancel when we in edit mode
    cancel = (
      <span className="EditorDialogButton__btn EditorButton__cancel" onClick={onCancel}>
        {!noIcon ? <span className="EditorDialogButton__icon EditorDialogButton__cancel"></span> : null}
        <span className="EditorDialogButton__iconText">Cancel</span>
      </span>
    )

    // This is what we show
    body = (
      <div>
        {restore}
        {save}
        {cancel}
      </div>
    )
  } else {
    if (cannotEdit) {
      body = (
        <div
          className={'EditorDialogButton__btn EditorButton__edit  EditorButton_noedit'}
          onClick={null}
          title={cannotEditHint}
        >
          {!noIcon ? <span className="EditorDialogButton__icon EditorDialogButton__noedit"></span> : null}
          <span className="EditorDialogButton__iconText">{editText ? editText : 'Edit'}</span>
        </div>
      )
    } else {
      body = (
        <div className={'EditorDialogButton__btn EditorButton__edit'} onClick={onEdit}>
          {!noIcon ? <span className="EditorDialogButton__icon EditorDialogButton__edit"></span> : null}
          <span className="EditorDialogButton__iconText">{editText ? editText : 'Edit'}</span>
        </div>
      )
    }
  }

  return (
    <div className={'EditorButton' + (theme ? ' EditorButton_' + theme : '') + ' ' + (className ? className : '')}>
      {body}
    </div>
  )
}

EditorDialogButton.propTypes = {
  dialogData: PropTypes.object,
  inEditMode: PropTypes.bool,
  hideSave: PropTypes.bool,
  editText: PropTypes.string,
  saveText: PropTypes.string,
  theme: PropTypes.string,
  noIcon: PropTypes.bool,
  className: PropTypes.string,
  cannotEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
}
