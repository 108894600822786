/**
 * Created by mailf on 08.06.2016.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Tabs, TabPanel } from '../ReactSimpletabs'
import { editableState } from '../../helpers'
import logger from '../../helpers/logger'
import { SettingList } from '../SettingDialog/SettingList'
import { CounterList } from './CounterList'
import './LayoutView.scss'
import { RuleList } from './RuleList'
import { SchemaView } from './SchemaView'

export class LayoutView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTabIndex: 1,

      stateOptions: []
    }
  }

  componentDidMount() {}

  /**
   * Change selection of tab in view
   * @param index of tab selected
   */
  onTabChange = (index) => {
    // Change state.
    logger.info('LayoutView:onTabChange', this.state.activeTabIndex, index)
    this.setState({ activeTabIndex: index })
  }

  /**
   * Save resource in layout
   * @param {object} obj - rule to save
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   * @return
   */
  saveResource = (type, mtype, objs, closeDialog, onSent) => {
    let layout = this.props.layout
    logger.log('LayoutView:saveResource', type, mtype, objs, this.state)

    layout[mtype] = objs
    this.props.onChangeLayout(layout, closeDialog, onSent)
  }

  render() {
    // State editing of data in dialog
    let editState = this.props.isEditable
    let activeTabIndex = this.props.isSkipSchema ? this.state.activeTabIndex + 1 : this.state.activeTabIndex
    //logger.info("LayoutView:render", { editState, activeTabIndex }, this.state, this.props);

    return (
      <div className="LayoutView">
        <Tabs className="tabs-list" onAfterChange={this.onTabChange}>
          {[
            this.props.isSkipSchema ? null : (
              <TabPanel title="Schema" key={1}>
                <SchemaView
                  appState={this.props.appState}
                  actions={this.props.actions}
                  isVisible
                  isEditable={editState >= editableState.EDITABLE ? editState : editableState.BROWSABLE}
                  majorObject={this.props.majorObject}
                  layout={this.props.layout}
                  change={this.props.change}
                  types={this.props.types}
                  onChangeLayout={this.props.onChangeLayout}
                />
              </TabPanel>
            ),
            <TabPanel title="Location" key={2}>
              {activeTabIndex === 2 ? this.props.dialogObject.renderElement(editState, 'location') : null}
            </TabPanel>,
            <TabPanel title="Format" key={3}>
              {activeTabIndex === 3 ? this.props.dialogObject.renderElement(editState, 'format') : null}
            </TabPanel>,
            <TabPanel title="Schedule" key={4}>
              {activeTabIndex === 4 ? this.props.dialogObject.renderElement(editState, 'schedule') : null}
            </TabPanel>,
            <TabPanel title="Policies" key={5}>
              {activeTabIndex !== 5 ? null : (
                <SettingList
                  appState={this.props.appState}
                  actions={this.props.actions}
                  isVisible
                  isEditable={editState >= editableState.EDITABLE ? editState : editableState.BROWSABLE}
                  majorObject={this.props.majorObject}
                  type={'policy'}
                  usage={'Policy'}
                  items={this.props.layout ? this.props.layout['policies'] : null}
                  onEditableChange={this.props.dialogObject.changeEditable}
                  onClose={() => this.setState({ activeResource: false })}
                  onSave={(objs, closeDialog, onSent) =>
                    this.saveResource('poplicy', 'policies', objs, closeDialog, onSent)
                  }
                />
              )}
            </TabPanel>,
            !this.props.isShowCounters ? null : (
              <TabPanel title="Counters" key={6}>
                {activeTabIndex !== 6 ? null : (
                  <CounterList
                    appState={this.props.appState}
                    actions={this.props.actions}
                    isVisible
                    isEditable={editState >= editableState.EDITABLE ? editState : editableState.BROWSABLE}
                    majorObject={this.props.majorObject}
                    type={'counter'}
                    items={this.props.layout ? this.props.layout['counters'] : null}
                    onEditableChange={this.props.dialogObject.changeEditable}
                    onClose={() => this.setState({ activeResource: false })}
                    onSave={(objs, closeDialog, onSent) =>
                      this.saveResource('counter', 'counters', objs, closeDialog, onSent)
                    }
                  />
                )}
              </TabPanel>
            ),
            <TabPanel title="Rules" key={7}>
              {activeTabIndex !== 7 ? null : (
                <RuleList
                  appState={this.props.appState}
                  actions={this.props.actions}
                  isVisible
                  isEditable={editState >= editableState.EDITABLE ? editState : editableState.BROWSABLE}
                  majorObject={this.props.majorObject}
                  type={'rule'}
                  items={this.props.layout ? this.props.layout['rules'] : null}
                  onEditableChange={this.props.dialogObject.changeEditable}
                  onClose={() => this.setState({ activeResource: false })}
                  onSave={(objs, closeDialog, onSent) => this.saveResource('rule', 'rules', objs, closeDialog, onSent)}
                />
              )}
            </TabPanel>
          ].filter((tabPanel) => tabPanel)}
        </Tabs>
      </div>
    )
  }
}

LayoutView.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isEditable: PropTypes.number, // State of editing in dialog
  dialogObject: PropTypes.object, // Dialog object
  majorObject: PropTypes.object.isRequired, // Magor object we work against
  isSkipSchema: PropTypes.bool, // Render schema
  layout: PropTypes.object.isRequired, // Layout we handling
  change: PropTypes.func, // Callback function to report changes in data
  types: PropTypes.func, // Function to get types available for execution
  onChangeLayout: PropTypes.func.isRequired // Report change in layout information
}
