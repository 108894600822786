import moment from 'moment'
import React, { Component, RefObject } from 'react'
import { connect } from 'react-redux'

import { Tabs, TabPanel } from '../ReactSimpletabs'
import { track } from '../../helpers/analytics'
import { API, getObjectListNew, getObjectNew, getRequestFromPath, idAPI, sendObjectNew } from '../../helpers/api'
import { getObjectByName } from '../../helpers/data'
import {
  deepCopy,
  editableState,
  getFullUri,
  getIssueAbsoluteDeadline,
  getIssuePropByName,
  issueResolutionColor,
  nonCSCompare,
  objectNameFromPathByType,
  objectTypeFromPath,
  objectTypeIndex,
  pathForMajorObject
} from '../../helpers/index'
import logger from '../../helpers/logger'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { IssueDialog } from '../IssueDialog/IssueDialog'
import { IssuesView } from '../IssuesView/IssuesView'
import { JSONTreeCopy } from '../JSONTreeCopy/JSONTreeCopy'
import { ErrorReportDialog } from './ErrorReportDialog'
import './Messages.scss'

import defaultUserPicture from '../../resources/images/face-2x.png'
import { Actions } from '../../actions'

// TODO: check usage

type MessagesProps = {
  object: any
  actions: Actions
  userState: any
  appState: any

  tabClassName?: string
  currentUserRole?: any
  parent?: any
  objectReload?: Function
  objectType?: string
  objectChain?: any

  // TODO: remove as unused???
  error?: any
  onAddMessage?: Function
}

type Filter = {
  assigned?: boolean
  priority: number[]
  type: []
  deadline: string[]
  status: string[]
  tag: any[]
  search?: string
}

type MessagesState = {
  isFixed: boolean
  messagesHeight: number
  showAll: boolean
  addingIssue: boolean
  addingMessage: boolean
  addingMessageText: string
  loadingIssues: boolean
  loadingMessages: boolean
  issueCreatedBy: any
  issueFilters: Filter
  organizationUsers: any[]
  loadingOrganizationUsers: boolean
  recentlyEditedIssues: any[]
  objectRoles: any
  users: any[]
  setIssueFromHash: boolean
  issues: any[]
  addingReport: boolean
  minHeight: number | boolean
  minHeightStyle: any
  messageTabs: any[]
  messages: any[]
  skippedLoadIssueMessages: boolean
  pageNumber: number
  loadedIssues: any[]
  editingIssue: any
  defaultIssueText: string
  editingIssueParent: any
  editingIssueParentType: any
}

class MessagesComponent extends Component<MessagesProps, MessagesState> {
  _loadingObjectRoles: any
  mainTabsRef: RefObject<any>
  issuesviewRef: RefObject<any>
  fixedTabsRef: RefObject<any>
  rootRef: RefObject<any>
  _propsReceived: boolean

  constructor(props) {
    super(props)
    this.state = {
      isFixed: false,
      messagesHeight: 0,
      showAll: false,
      addingIssue: false,
      addingMessage: false,
      addingMessageText: '',
      loadingIssues: true,
      loadingMessages: false,
      issueCreatedBy: null,
      issueFilters: {
        assigned: false,
        priority: [0, 1, 2, 3, 4],
        type: [],
        deadline: ['red', 'yellow', 'green'],
        status: ['Opened', 'Assigned', 'Investigated', 'Triaged', 'Resolved'],
        tag: []
      },
      organizationUsers: [],
      loadingOrganizationUsers: false,
      recentlyEditedIssues: [],
      objectRoles: {},
      users: [],
      setIssueFromHash: false,
      issues: [],
      addingReport: false,
      minHeight: 0,
      minHeightStyle: {},
      messageTabs: [],
      messages: [],
      skippedLoadIssueMessages: false,
      pageNumber: 1,
      loadedIssues: [],
      editingIssue: undefined,
      defaultIssueText: '',
      editingIssueParent: undefined,
      editingIssueParentType: undefined
    }
    this._loadingObjectRoles = {}
    this._propsReceived = false

    this.mainTabsRef = React.createRef()
    this.issuesviewRef = React.createRef()
    this.fixedTabsRef = React.createRef()
    this.rootRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false)
    window.addEventListener('resize', this.onScroll, false)
    setTimeout(() => {
      this.updateMessagesHeight(this.onScroll)
    }, 0)

    if (localStorage.issueFilters) {
      // //console.log("M:cdm::restore filters", JSON.parse(localStorage.issueFilters));
      try {
        let filters = JSON.parse(localStorage.issueFilters)
        //console.log("Messages::componentDidMount", window.location.hash.indexOf("#issues/") !== -1, filters);
        this.setNewFilters(filters)
      } catch (e) {
        localStorage.issueFilters = ''
        this.setDefaultFilters(() => {
          this.requestIssues().then(() => {
            this.loadIssues()
          })
        })
      }
    } else {
      this.setDefaultFilters(() => {
        this.requestIssues().then(() => {
          this.loadIssues()
        })
      })
    }
  }

  /**
   * Updates organization users
   * @param {*} newProps
   */
  UNSAFE_componentWillReceiveProps(newProps) {
    this._propsReceived = true
    this.setState({ loadingIssues: true })
    this.loadIssues()
    if (
      (this.state.organizationUsers.length === 0 || newProps.object.identity.id !== this.props.object.identity.id) &&
      !this.state.loadingOrganizationUsers
    ) {
      if (newProps.object.type === 'organization')
        this.setState({
          organizationUsers: newProps.object.users ? newProps.object.users.slice() : [],
          loadingOrganizationUsers: false
        })
      else {
        //console.log("Messages::componentWillReceiveProps", newProps.object.identity.id, this.props.object.identity.id, this.state.loadingOrganizationUsers, this.state.organizationUsers.length);
        this.setState({ loadingOrganizationUsers: true }, () => {
          //API.organizations(objectNameFromPathByType(getFullUri(newProps.object), 'organizations')).users.get().then(result => {
          getObjectListNew(
            API.organizations(objectNameFromPathByType(getFullUri(newProps.object), 'organizations')).users,
            'user',
            null
          ).then((result) => {
            this.setState({
              organizationUsers: result,
              loadingOrganizationUsers: false
            })
          })
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this._propsReceived) {
      this.updateMessagesHeight(this.onScroll)
      this._propsReceived = false
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false)
    window.removeEventListener('resize', this.onScroll, false)
  }

  setNewFilters(filters) {
    this.setState({ issueFilters: filters }, () => {
      this.requestIssues().then(() => {
        this.loadIssues()
      })
    })
  }

  /**
   * Requests issues list and puts it to state
   * @param {boolean} forceUpdate - ignore cached list
   * @return {Promise}
   */
  requestIssues = (forceUpdate = false) => {
    let objUri = this.props.object.object && this.props.object.object.parent ? getFullUri(this.props.object) : null
    if (this.props.object.type === 'organization') objUri = '/organizations/' + this.props.object.identity.name
    const req = getRequestFromPath(objUri ? objUri : getFullUri(this.props.object)).issues
    const issuePromises: any[] = []

    this.props.actions.clearObjectList('issue')

    const params: any = {}

    // we need to load all issues, because there can be hash-parameter with id of someone else's issue
    // and we need to know, if issue from hash-parameter is our or not
    // and switch filters in case it is someone else's issue (in IssuesView.componentDidMount)
    // if (this.state.loadedAllOnce || window.location.hash.indexOf("#issues/") === -1) {
    if (this.state.issueFilters.assigned) {
      params.assignedid = localStorage['currentUserId']
    }
    if (this.state.issueFilters.status.length) {
      params.status = {
        value: this.state.issueFilters.status.reduce((prev, cur) => prev + '&status=' + cur),
        noEncode: true
      }
    }
    params.pageSize = {
      value: 15,
      noEncode: true
    }
    params.pageNumber = {
      value: this.state.pageNumber,
      noEncode: true
    }

    this.setState({
      loadingIssues: true
    })

    //console.log("Messages::requestIssues params", params);

    issuePromises.push(
      new Promise((resolve, reject) => {
        getObjectListNew(req, 'issue', null, forceUpdate, params).then(
          (issueList) => {
            resolve(issueList)
          },
          (error) => {
            reject(error)
          }
        )
      })
    )

    if (window.location.hash.indexOf('#issues/') !== -1 && window.location.hash.indexOf('approve') === -1) {
      const issueNumber = parseInt(window.location.hash.replace('#issues/', ''))
      issuePromises.push(getObjectNew(req(issueNumber), 'issue', null, true))
    }

    return new Promise((resolve, reject) => {
      Promise.all(issuePromises).then((results) => {
        const issueList = results[0].slice()

        if (results.length > 1 && !issueList.find((issue) => issue.identity.name === results[1].identity.name))
          issueList.push(results[1])

        this.setState({ loadedIssues: issueList }, this.loadIssues)
        resolve(issueList)
      })
    })
  }

  showMore = () => {
    this.setState(
      {
        showAll: true
      },
      this.updateMessagesHeight
    )
  }

  updateMessagesHeight(done?) {
    try {
      let myNode = this.rootRef.current
      if (!myNode) {
        return
      }

      let messagesHeight = 0

      let panels = myNode.getElementsByClassName('tab-panel')
      for (let i = 0; i < panels.length; i++) {
        let tab_panel = panels[i]
        if (!tab_panel) return
        messagesHeight = Math.max(messagesHeight, tab_panel.clientHeight)
      }

      let errorBlock = document.querySelector('.Messages__errors')
      if (errorBlock) messagesHeight = Math.max(messagesHeight, errorBlock.clientHeight)

      this.setState(
        {
          messagesHeight: this.state.minHeight
            ? this.state.minHeight > messagesHeight
              ? Number(this.state.minHeight) - 48
              : messagesHeight
            : messagesHeight
        },
        done
      )
    } catch (e) {}
  }

  setDefaultFilters = (cb) => {
    this.setState(
      {
        issueFilters: {
          priority: [0, 1, 2, 3, 4],
          type: [],
          deadline: ['red', 'yellow', 'green'],
          status: ['Opened', 'Assigned', 'Investigated', 'Triaged', 'Resolved'],
          tag: []
        }
      },
      cb ? cb() : null
    )
  }

  get_scroll_y() {
    return window.pageYOffset || document.body.scrollTop
  }

  onScroll = (event) => {
    // Position of message root
    let messages = document.querySelector('.Messages__root')
    if (!messages) return

    const messagesY = messages ? messages.getBoundingClientRect().top : 0
    const isFixed = messagesY > window.innerHeight

    //logger.log("Messages:onScroll old", this.state.isFixed, "new", isFixed);

    if (this.state.isFixed !== isFixed) {
      this.setState({ isFixed })
      if (!isFixed && this.state.skippedLoadIssueMessages) {
        this.loadIssueMessages(this.state.skippedLoadIssueMessages)
      }
    }
  }

  handleAddIssueClick = () => {
    // when other tab was selected, IssueView was not rendered, causing this.issuesviewRef.current to be undefined
    // so it could not be updated, and issue dialog was not closed
    this.mainTabsRef.current.setState({ tabActive: 1 })

    this.setState({
      addingIssue: !this.state.addingIssue
    })
  }

  handleAddReportClick = () => {
    this.setState({
      addingReport: !this.state.addingReport
    })
  }

  handleFiltersChange = (filters) => {
    const oldFilters = deepCopy(this.state.issueFilters)
    //logger.info("Messages:handleFiltersChange", filters, oldFilters, this.state, this.props);
    return new Promise<void>((resolve, reject) => {
      this.setState(
        {
          issueFilters: filters,
          recentlyEditedIssues: []
        },
        () => {
          this.saveFilters(oldFilters, filters)
          resolve()
        }
      )
    })
  }

  areFiltersStricter = (oldFilters, newFilters) => {
    if (oldFilters.status && newFilters.status && oldFilters.status.length > newFilters.status.length) return true
    if (oldFilters.priority && newFilters.priority && oldFilters.priority.length > newFilters.priority.length)
      return true
    if (oldFilters.deadline && newFilters.deadline && oldFilters.deadline.length > newFilters.deadline.length)
      return true
    if (oldFilters.type && newFilters.type && oldFilters.type.length > newFilters.type.length) return true
    if (oldFilters.tags && newFilters.tags && oldFilters.tags.length > newFilters.tags.length) return true
    if (
      (!oldFilters.search && newFilters.search) ||
      (oldFilters.search && oldFilters.search.length < newFilters.search.length)
    )
      return true
    if (!oldFilters.assigned && newFilters.assigned) return true

    return false
  }

  saveFilters(oldFilters, newFilters) {
    localStorage.issueFilters = JSON.stringify(Object.assign({}, newFilters, { search: '' }))
    //logger.info("Messages:saveFilters", oldFilters, newFilters, localStorage.issueFilters);

    if (!this.areFiltersStricter(oldFilters, newFilters)) {
      //logger.info("Messages:saveFilters:REQUEST", oldFilters, newFilters);
      this.requestIssues()
    }
  }

  isHashIssue = (issue) => {
    if (window.location.hash.indexOf('#issues/') !== -1) {
      //console.log("cwu:2");
      const hashName = window.location.hash.replace('#issues/', '')
      return issue.identity.name === hashName ? true : false
    }

    return false
  }

  /**
   * Returns list of filters that do not allow issue to be shown; empty string for visible issues.
   * Use !filterIssue(issue) to get only visible issues.
   * @param issue         full issue object
   * @returns {string}
   */
  filterIssue = (issue: any) => {
    let issueFilters = this.state.issueFilters
    let blockingFilters: string[] = []
    //const issueFromOtherOrg = objectNameFromPathByType(issue.attached.name, 'organizations') !== objectNameFromPathByType(getFullUri(this.props.object), 'organizations');
    //logger.info("Messages:filterIssue", issue.identity.name, issue, issueFilters, issueFromOtherOrg, this.props, this.state);

    if (issueFilters.type && issueFilters.type.length > 0) {
      //if (this.state.issueFilters.type.indexOf(issue.type.type) === -1)
      ////console.log("index ",this.state.issueFilters.type.indexOf(issue.type));
      // @ts-ignore
      if (issueFilters.type.indexOf(issue.type) === -1) blockingFilters.push('type')
    }

    if (issueFilters.assigned) {
      let currentUser = localStorage['currentUserId']
      let assignedUser = issue.assigned ? issue.assigned.id : ''
      let trackerUsers = issue.trackers ? issue.trackers : []

      // Issue assigned to current user and current user not in trackers keep in list.
      if (
        !nonCSCompare(currentUser, assignedUser) &&
        trackerUsers.findIndex((user) => nonCSCompare(currentUser, user.id)) === -1
      ) {
        // && !issueFromOtherOrg) {
        //logger.info("Messages:filterIssue:ASSIGNED", currentUser, assignedUser);
        blockingFilters.push('assigned')
      }
    } else {
      //logger.info("Messages:filterIssue:ORG", issue, objectNameFromPathByType(issue.attached.name, 'organizations'),  objectNameFromPathByType(getFullUri(this.props.object), 'organizations'));
      // Filter issues currently attached to different organization.
      // Based on Visa request this filter removed. Visa want continiue to see issues assigned to
      // other organization but origineted in current organization.
      //if (issueFromOtherOrg ) {
      //  blockingFilters.push('assigned');
      //}
    }

    if (issueFilters.search) {
      const searchFor = issueFilters.search.toLowerCase()
      // const alias = !issue.assigned || !issue.assigned.id ? "" : this.state.organizationUsers.reduce((p,usr)=>usr.profile.identity.id === issue.assigned.id ? usr.profile.alias : p, "").toLowerCase();
      const userAssignee =
        !issue.assigned || !issue.assigned.id
          ? ''
          : this.state.organizationUsers.find((user) => user.profile.identity.id === issue.assigned.id)
      const assigneeAlias = userAssignee && userAssignee.profile.alias ? userAssignee.profile.alias.toLowerCase() : ''
      // console.log("find "+searchFor+" in "+issue.identity.name.toLowerCase()+" and "+issue.identity.description.toLowerCase()+" and alias="+assigneeAlias);

      if (
        issue.identity.name.toLowerCase().indexOf(searchFor) === -1 &&
        (!issue.identity.description || issue.identity.description.toLowerCase().indexOf(searchFor) === -1) &&
        (!assigneeAlias || assigneeAlias.indexOf(searchFor) === -1)
      ) {
        blockingFilters.push('search')
      }
    }
    if (issueFilters.priority.length > 0) {
      ////console.log("priority filter", this.state.issueFilters.priority.indexOf(issue.fullPriority.code) );
      if (!issue.fullPriority || issueFilters.priority.indexOf(parseInt(issue.fullPriority.code)) === -1) {
        blockingFilters.push('priority')
      }
    }
    if (issueFilters.deadline && issueFilters.deadline.length > 0) {
      if (issueFilters.deadline.indexOf(issueResolutionColor(getIssueAbsoluteDeadline(issue))) === -1)
        blockingFilters.push('deadline')
    }
    if (issueFilters.status.length > 0) {
      if (!issue.fullStatus || issueFilters.status.indexOf(issue.fullStatus.name) === -1) blockingFilters.push('status')
    }
    if (issueFilters.tag && issueFilters.tag.length > 0) {
      if (
        !issue.object ||
        !issue.object.tags.find((tag) => issueFilters.tag.find((issueTag) => issueTag.label === tag.name))
      )
        blockingFilters.push('tag')
    }

    //logger.info("Messages:filterIssue", issue.identity.name, blockingFilters);
    return blockingFilters.join(', ')
  }

  /**
   * Expand some issue properties (priority, status, type)
   */
  convertIssue = (rawIssue) => {
    if (!rawIssue.object) return null
    let fullType = getIssuePropByName(rawIssue.type, 'type')
    return Object.assign(rawIssue, {
      fullType: fullType
        ? fullType
        : {
            type: 'Discussion',
            description: 'Discussion of object design.',
            code: 0,
            period: 1.0
          },
      fullPriority: getIssuePropByName(rawIssue.priority, 'priority'),
      fullStatus: getIssuePropByName(rawIssue.status, 'status'),
      time: rawIssue.object.history.created,
      assigned: rawIssue.assigned,
      object: {
        parent: rawIssue.object && rawIssue.object.parent ? rawIssue.object.parent : {},
        tags: rawIssue.object && rawIssue.object.tags ? rawIssue.object.tags : [],
        documents: rawIssue.object && rawIssue.object.documents ? rawIssue.object.documents : [],
        properties: rawIssue.object && rawIssue.object.properties ? rawIssue.object.properties : [],
        history: rawIssue.object ? rawIssue.object.history : {}
      }
    })
  }

  /**
   * Converts loaded issues and handles window hash
   * @param {*} done
   */
  loadIssues(done?) {
    if (!this.state.loadedIssues) return

    let issues = this.state.loadedIssues.filter((issue) => issue.attached && issue.attached.name)
    //.filter((issue)=>issue.object && issue.object.parent && issue.object.parent.id === this.props.object.identity.id);

    if (window.location.hash.indexOf('#issues/') !== -1) {
      let hashName = window.location.hash.replace('#issues/', '')
      if (hashName && hashName.indexOf('/') !== -1) hashName = hashName.substr(0, hashName.indexOf('/'))

      if (hashName && !this.state.setIssueFromHash) {
        // eslint-disable-next-line array-callback-return
        issues.map((issue) => {
          if (issue.identity.name === hashName)
            this.setState({
              setIssueFromHash: true,
              recentlyEditedIssues: [issue.identity.id]
            })
        })
      }
      if (hashName === 'approve' && !this.state.setIssueFromHash) {
        let lastIssue = issues
          .filter((issue) => issue)
          .reduce((prev, issue) => (!prev || issue.identity.name > prev.identity.name ? issue : prev), null)
        if (lastIssue) {
          this.setState({
            setIssueFromHash: true,
            recentlyEditedIssues: [lastIssue.identity.id]
          })
        }
      }
    }

    let convertedIssues = issues.map(this.convertIssue).filter((issue) => issue !== null)
    //this.props.actions.receiveObjectList('issue', convertedIssues);
    this.setState({ issues: convertedIssues, loadingIssues: false }, () => {
      //this.setState({issues: [], loadingIssues: true}, ()=> {
      if (done) done()
    })
  }

  loadIssue = (issueId) => {
    return new Promise((resolve, reject) => {
      getObjectNew(idAPI.issues(issueId), 'issue', null, true).then(
        (ret) => {
          let newRawIssueList = this.state.loadedIssues.map((issue) =>
            issue.identity.id === ret.identity.id ? ret : issue
          )
          let newIssuesList = this.state.issues.map((issue) =>
            issue.identity.id === ret.identity.id ? this.convertIssue(ret) : issue
          )
          this.setState(
            {
              loadedIssues: newRawIssueList,
              issues: newIssuesList
            },
            () => {
              resolve(ret)
            }
          )
        },
        (error) => reject(error)
      )
    })
  }

  /**
   * Loads messages for issue with provided id
   * @param {string} selectedIssueId - issue id
   * @returns {Promise}
   */
  loadIssueMessages = (selectedIssueId) => {
    if (this.state.isFixed && window.location.hash.indexOf('#issues/') === -1) {
      return new Promise((resolve, reject) => {
        // We skiped loading messages and issue as it is not visible
        this.setState({
          skippedLoadIssueMessages: selectedIssueId
        })
        reject(null)
      })
    }

    // Lets load now messages and issue
    this.setState({
      skippedLoadIssueMessages: false
    })
    //console.trace("Messages:loadIssueMessages", selectedIssueId);

    let issue = this.state.issues.filter((issue) => issue.identity.id === selectedIssueId)[0]
    // console.log('loadIssueMessages for', issue, selectedIssueId)
    if (!issue) {
      // Selected issue not in our issue list. Let's skip loading
      return new Promise<void>((resolve, reject) => {
        // console.log('Messages::loadingMessages set false 1')
        this.setState(
          {
            messages: [],
            loadingMessages: false
          },
          () => {
            resolve()
          }
        )
      })
    } else {
      // commented to prevent error for messages that not visible
      // if (this.state.loadingMessages) {
      //   logger.info('Messages::loadingMessages:LOADING')
      //   return new Promise((resolve, reject) => {
      //     // We skiped loading messages and issue as it is not visible
      //     this.setState({
      //       skippedLoadIssueMessages: selectedIssueId
      //     })
      //     reject(new Error('Loading message! Please wait'))
      //   })
      // }

      if (issue.tasks === undefined) {
        getObjectNew(idAPI.issues(selectedIssueId), 'issue', null).then((ret) => {
          let newIssueList = this.state.loadedIssues.map((issue) =>
            issue.identity.id === ret.identity.id ? ret : issue
          )
          this.setState({ loadedIssues: newIssueList }, () => {
            this.loadIssues()
            // logger.debug(
            //   'Messages:loadIssueMessages:LOADISSUE',
            //   { selectedIssueId, issue, ret, newIssueList },
            //   this.state,
            //   this.props
            // )
          })
        })
        //return;
      }

      // console.log('Messages::loadingMessages set true')
      this.setState({ loadingMessages: true })

      return new Promise((resolve, reject) => {
        //idAPI.issues(issue.identity.id).messages.get()
        getObjectListNew(idAPI.issues(issue.identity.id).messages, 'message', null, true).then((messages) => {
          const messagesToSave = messages
            .filter((message) => message)
            .map((message) => {
              //console.log("loadMessages, message=", message);
              return {
                identity: message.identity,
                type: message.type,
                name: message.text,
                log: message.log,
                sender: message.sender.description
                  ? message.sender.description
                  : message.sender.name /*: userNames.filter((user)=>user.id === message.sender.id)[0].name,*/,
                senderId: message.sender.id,
                time: message.time,
                action: message.action,
                properties: message.properties,
                attached: message.attached && message.attached.name ? message.attached.name : issue.attached.name
              }
            })

          //console.log("Messages::loadingMessages set false 2");
          this.setState(
            {
              loadingMessages: false,
              issueCreatedBy: messages.length > 0 ? messages[0].sender : null,
              messages: messagesToSave,
              messageTabs: messages.map(() => 0)
            },
            () => {
              this.loadRolesForMessages(issue, messages).then((messages) => {
                resolve(messages)
              })
            }
          )
        })
      })
    }
  }

  /**
   * Find all messages, that have an action
   * these messages display some Buttons depending on user role
   * so we have to request user roles for all objects that have attached messages with actions
   * @param {array} messages
   * @returns {Promise}
   */
  loadRolesForMessages = (issue, messages) => {
    return new Promise((resolve, reject) => {
      const userId =
        this.props.userState.profile && this.props.userState.profile.identity
          ? this.props.userState.profile.identity.name
          : null
      let attachedToUris: string[] = []
      let attachedToUrisMessage: string[] = []
      messages.map((message) => {
        if (!message.action) {
          // We have no actions for this message, no need for roles.
          return null
        }

        // Let's see what path we need role for.
        let path = message.attached.name
        attachedToUrisMessage.push(path)
        if (message.action === 'FinalizeSubscription') {
          // We need to switch from subscription to applicatation or better publication.
          // For publication we need access to subscription or have property of message
          // to tell what publication it was for.
          path = issue.attached.name
        }
        attachedToUris.push(path)
        //logger.info("Messages:loadRolesForMessages:PATH", { issue, messages, attachedToUris, userId, message, path }, this.state, this.props);
        return path
      })
      attachedToUris = attachedToUris.filter((o, i) => attachedToUris.indexOf(o) === i) // unique
      //logger.info("Messages:loadRolesForMessages", { issue, messages, attachedToUris, userId }, this.state, this.props);

      let promises = attachedToUris
        .filter((uri) => !(uri in this.state.objectRoles) && !(uri in this._loadingObjectRoles))
        .map((uri) => {
          this._loadingObjectRoles[uri] = true
          return getObjectNew(getRequestFromPath(pathForMajorObject(uri)).users(userId), 'user')
        })
      //console.log("promises: ", promises);
      Promise.all(promises).then((results) => {
        console.log('loadRolesForMessages results:', results)
        let objectRoles = this.state.objectRoles || {}
        // eslint-disable-next-line array-callback-return
        attachedToUrisMessage.map((uri, index) => {
          if (results[index] && results[index].role) objectRoles[uri] = results[index].role
        })
        //console.log("objectRoles", objectRoles);
        this.setState({ objectRoles: objectRoles })
        resolve(messages)
      })
    })
  }

  arrowDirection = () => {
    //return this.state.isFixed;
    // Position of message root
    const messages = document.querySelector('.Messages__root')
    const messagesY = messages ? messages.getBoundingClientRect().top : 0
    //
    // // Bottom of object name
    const header = document.querySelector<HTMLElement>('.Header')
    const headerY = header ? header.offsetHeight : 0
    //
    ////console.log("arrowDirection: messagesY="+messagesY+", headerY="+headerY, messagesY - headerY < headerY/3);
    if (messagesY - headerY < headerY / 3) return 0
    //
    return 1
  }

  arrowScroll = () => {
    return () => {
      if (this.arrowDirection() === 0) {
        window.scrollTo(0, 0)
      } else {
        this.tabChange()
      }
    }
  }

  tabChange = () => {
    this.updateMessagesHeight(() => {
      this.setState({ isFixed: false, showAll: false }, () => {
        this.updateMessagesHeight()
        const footerHeight = document.querySelector('.Footer') ? document.querySelector('.Footer')?.clientHeight : 0
        // FOOTER HEIGHT = 200
        // MESSAGES TAB ROW HEIGHT = 55
        // HEADER HEIGHT = 115
        let yPos =
          document.body.scrollHeight -
          this.state.messagesHeight -
          (footerHeight ? footerHeight + 15 : Number(footerHeight)) -
          58 -
          115 //window.innerHeight;
        //console.log("Scroll messages", yPos);
        // //console.log("yPos="+yPos+"="+document.body.scrollHeight+"-"+this.state.messagesHeight+"-200");
        window.scrollTo(0, yPos)
        const tabTitles = ['issues', 'messages', 'reviews', 'notifications']
        const tabIndex = this.fixedTabsRef.current
          ? this.fixedTabsRef.current.state.tabActive
          : this.mainTabsRef.current
          ? this.mainTabsRef.current.state.tabActive
          : 1
        track(tabTitles[tabIndex - 1], 'bottom_tabs')
      })
    })
  }

  fixedTabChange = () => {
    this.mainTabsRef.current.setState({ tabActive: this.fixedTabsRef.current.state.tabActive }, this.tabChange)
  }

  getRequestFromPath = (path) => {
    const splitPath = path.split('/')
    let req = API

    // eslint-disable-next-line no-var
    for (var i = 1; i < splitPath.length - 1; i += 2) {
      req = req[splitPath[i]](splitPath[i + 1])
    }

    if (i < splitPath.length) {
      // //console.log("spspsp", splitPath, i, splitPath[i+1]);
      req = req[splitPath[i]]
    }

    return req
  }

  editIssue = (issue) => {
    return () => {
      const issuePathSplit = issue._path.split('/')

      const issueParentTypePlural =
        issuePathSplit[
          objectTypeIndex(issue._path, [
            'Initiatives',
            'issues',
            'Features',
            'Discussions',
            'WorkItems',
            'Support Requests',
            'Design Suggestions',
            'Design Problems',
            'Code Defects'
          ]) - 2
        ]
      const issueParentType = issueParentTypePlural
        ? issueParentTypePlural.slice(0, issueParentTypePlural.length - 1)
        : 'organization'
      //const req = this.getRequestFromPath(issuePathSplit.slice(0, issuePathSplit.length-2).join('/'));

      this.setState({
        editingIssue: issue,
        defaultIssueText: '',
        editingIssueParent: {
          identity: { id: issue.attached.id },
          _path: issue.attached.name
        },
        editingIssueParentType: issueParentType
      })
    }
  }

  addMessage = () => {
    this.setState({
      addingMessage: true
    })
    return false
  }
  cancelAddMessage = () => {
    this.setState({
      addingMessage: false,
      addingMessageText: ''
    })
    return false
  }
  saveAddMessage = () => {
    // //console.log("saveAddMessage", this.state.addingMessageText);
    this.props.onAddMessage && this.props.onAddMessage(this.state.addingMessageText)
    this.setState({
      addingMessage: false,
      addingMessageText: ''
    })
    return false
  }
  changeAddMessage = (event) => {
    this.setState({
      addingMessageText: event.target.value
    })
  }
  sendIssueMessage = (message, issue, done, action = null) => {
    logger.log('sendIssueMessage', message, issue)
    let issueCopy: any = {
      identity: {
        id: issue.identity.id
      },
      object: issue.object
      /*
      object: {
        history: {
          updated: issue.object.history.updated
        }
      }*/
    }

    if (action === 'DenyAccess' || action === 'GrantAccess') issueCopy.status = 'Closed'

    const messageObject = {
      class: 'Message',
      type: 'Regular',
      issue: issueCopy,
      attached: {
        id: message.attached ? message.attached.id : undefined,
        name: message.attached ? message.attached.name : undefined
      },
      sender: {
        id: localStorage.currentUserId,
        description: this.props.userState.profile.alias
      },
      text: message.text,
      receivers: message.receivers,
      log: {}, // todo: use message.log
      time: moment(issue.time).format('YYYY-MM-DDTHH:mm:ss'),
      action: action
    }

    logger.log('sendIssueMessage messageObject', messageObject)

    this.sendMessage(messageObject, issue, done, action)
  }

  sendConfirm = (button, issue, text, done, user) => {
    if (user) {
      // We send message by generating it based on text
      this.sendIssueMessage({ text: text, senderId: user.profile.id, role: user.role }, issue, done, button)
    } else {
      // We send already generated message
      this.sendMessage(text, issue, done, button)
    }
  }

  sendMessage(message, issue, done, action) {
    //idAPI.issues(issue.identity.id).messages.post(message)
    sendObjectNew(idAPI.issues(issue.identity.id).messages, 'post', null, message, null, true).then(
      () => {
        if ((action === 'ApproveVersion' || action === 'RejectVersion') && this.props.objectReload) {
          console.log('Messages:sendMessage:RELOAD', action, message, this.props)
          this.props.objectReload(message.attached.name)
        }
        // todo: fix when actual attached will be returned
        else if (message.attached && typeof message.attached !== 'object') {
          getObjectNew(
            getRequestFromPath(message.attached),
            objectTypeFromPath(message.attached),
            this.props.actions,
            true
          )
        }
        done()
        getObjectNew(idAPI.issues(issue.identity.id), 'issue', null, true).then((fullIssue) => {
          let newIssueList = this.state.loadedIssues.map((issue) =>
            issue.identity.id === fullIssue.identity.id ? this.convertIssue(fullIssue) : issue
          )
          this.setState({ loadedIssues: newIssueList }, () => {
            this.loadIssues()
            this.loadIssueMessages(issue.identity.id) // why was the line commented out?
            // messages are not refreshed automatically
          })
        })
      },
      (error) => {
        this.props.actions.setError(null) // cancel default error handler
        done(error)
        getObjectNew(idAPI.issues(issue.identity.id), 'issue', null, true).then(() => {
          this.loadIssues()
          //alert("The issue has been modified. Please review your message and send again");
        })
      }
    )
  }

  scrollToTopIssue() {
    this.issuesviewRef.current.scrollToTopIssue()
    this.tabChange()
  }

  onCloseIssueCreator = () => {
    this.setState({
      addingIssue: false,
      defaultIssueText: '',
      editingIssue: false,
      editingIssueParent: false,
      editingIssueParentType: ''
    })
  }

  renderIssueCreator() {
    return (
      <IssueDialog
        appState={this.props.appState}
        actions={this.props.actions}
        users={(this.props.object.users || []).concat(this.state.organizationUsers)}
        user={this.props.userState}
        parent={this.state.editingIssueParent ? this.state.editingIssueParent : this.props.object}
        issue={this.state.editingIssue}
        defaultText={this.state.defaultIssueText}
        issueCreatedBy={this.state.editingIssue && this.state.messages ? this.state.issueCreatedBy : null}
        architect={this.state.organizationUsers.reduce((p, c) => (c.role.toLowerCase() === 'architect' ? c : p), null)}
        isEditable={editableState.EDITING}
        isVisible={this.state.addingIssue || this.state.editingIssue}
        onClose={this.onCloseIssueCreator}
        onSave={this.issueCreateSuccess}
      />
    )
  }

  issueCreateSuccess = (result) => {
    if (!result) result = this.state.editingIssue
    logger.log('ObjectCreator::onSuccess', result)
    this.issuesviewRef.current._stopMessageLoading = true
    getObjectNew(idAPI.issues(result.issue.identity.id), 'issue', null, true).then((fullIssue) => {
      let newIssueList = this.state.loadedIssues.concat(fullIssue)
      this.setState({ loadedIssues: newIssueList }, () => {
        this.requestIssues(true).then(() =>
          this.loadIssues(() => {
            this.issuesviewRef.current._stopMessageLoading = false
            let newIssues = this.state.issues.map((issue) =>
              issue.identity.id === fullIssue.identity.id ? this.convertIssue(fullIssue) : issue
            )
            this.issuesviewRef.current.setSelectedIssueId(result.identity.id, this.convertIssue(fullIssue))
            let newRecentlyEditedIssues = (this.state.recentlyEditedIssues || []).slice().concat(result.identity.id)
            this.setState({
              recentlyEditedIssues: newRecentlyEditedIssues,
              issues: newIssues
            })
          })
        )
      })
    })
  }

  getVisibleIssueCount() {
    return !this.state.issues
      ? 0
      : this.state.issues.filter(
          (issue) =>
            !this.filterIssue(issue) ||
            this.isHashIssue(issue) ||
            this.state.recentlyEditedIssues.includes(issue.identity.id)
        ).length
  }

  getVisibleNotificationCount() {
    return this.props.error ? this.props.error.length : 0
  }

  handleIssueToggleCollapsed = (newHeight) => {
    //console.log("M:handleIssueToggleCollapsed", newHeight);
    if (newHeight && this.state.minHeight !== newHeight) {
      this.setState({
        minHeight: 300 + newHeight,
        minHeightStyle: { minHeight: 300 + newHeight }
      })
    } else if (this.state.minHeight) {
      this.setState({
        minHeight: false,
        minHeightStyle: { minHeight: 'none' }
      })
    }
  }

  onCloseReport = () => {
    this.setState({ addingReport: false })
  }

  onClickIssuesTab = () => {
    this.requestIssues(true).then(() => {
      window.history.replaceState(null, '', '#issues')
      this.setState({ recentlyEditedIssues: [] }, this.loadIssues)
    })
  }

  onCheckObject = (path) => {
    // load object by uri if we don't have it in appState
    // but we do not know object type
    //console.log("mesasge checkobject path",path);
    const type = objectTypeFromPath(path)
    //console.log("mesasge checkobject type",type);
    const name = objectNameFromPathByType(path, type)
    //console.log("mesasge checkobject name",name);
    const object = getObjectByName(this.props.appState, type, name)
    //console.log("mesasge checkobject object",object);
    if (!object) {
      getObjectNew(getRequestFromPath(path), type, null, true)
    }
  }

  onPageChange = (page) => {
    this.setState(
      {
        pageNumber: page
      },
      () => {
        this.requestIssues().then(() => {
          this.loadIssues()
        })
      }
    )
  }

  render() {
    let reviews = this.props.object && this.props.object.reviews ? this.props.object.reviews : []
    let messages = this.props.object && this.props.object.messages ? this.props.object.messages : []
    const arrowPictureClass = this.arrowDirection() === 0 ? 'Messages__arrowPictureUp' : 'Messages__arrowPictureDown'
    const issueCreateButton = (
      <div className="Messages__newIssueButton boldOnHover" onClick={this.handleAddIssueClick}>
        Add issue
      </div>
    )
    const reportCreateButton = window.localInstallation ? (
      <div className="Messages__newIssueButton boldOnHover" onClick={this.handleAddReportClick}>
        Debug data
      </div>
    ) : null
    //logger.info("Messages:render", this.props.object, this.state, this.props);

    return (
      <div className={'Messages__root'} style={this.state.minHeightStyle} ref={this.rootRef}>
        {this.props.object ? (
          <div className="Messages__rowCreate">
            <div className="Messages__rowCreateInner">
              {issueCreateButton}
              {reportCreateButton}
              <div className="Messages__scrollArrows">
                <div className={'Messages__arrowFloat ' + arrowPictureClass} onClick={this.arrowScroll()}></div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.addingIssue || this.state.editingIssue ? this.renderIssueCreator() : null}
        {this.state.addingReport ? <ErrorReportDialog onClose={this.onCloseReport} /> : null}
        {this.props.object ? (
          <Tabs
            ref={this.mainTabsRef}
            className={'Messages__tabs tabs-list ' + this.props.tabClassName}
            onAfterChange={this.tabChange}
          >
            <TabPanel
              title={
                <div className="Messages__issueTab">
                  <div className="Messages__reloadIssuesButton" onClick={this.onClickIssuesTab}></div>
                  Issues
                  <div className="Messages__issuesCount">{this.getVisibleIssueCount()}</div>
                </div>
              }
              className="Messages__tabIssuesView"
            >
              <IssuesView
                ref={this.issuesviewRef}
                object={this.props.object}
                issues={
                  this.state.issues
                    ? this.state.issues.filter(
                        (issue) =>
                          !this.filterIssue(issue) ||
                          this.isHashIssue(issue) ||
                          this.state.recentlyEditedIssues.includes(issue.identity.id)
                      )
                    : []
                }
                allIssues={this.state.issues}
                users={this.props.object.users}
                messages={this.state.messages}
                objectRoles={this.state.objectRoles}
                currentUserRole={this.props.currentUserRole}
                loadingMessages={this.state.loadingMessages}
                loadingIssues={this.state.loadingIssues}
                filters={this.state.issueFilters}
                messageTabs={this.state.messageTabs}
                recentlyEditedIssues={this.state.recentlyEditedIssues}
                loadMessages={this.loadIssueMessages}
                loadIssue={this.loadIssue}
                handleFiltersChange={this.handleFiltersChange}
                filterIssue={this.filterIssue}
                isHashIssue={this.isHashIssue}
                checkObject={this.onCheckObject}
                onIssueEdit={this.editIssue}
                onSendMessage={this.sendIssueMessage}
                onConfirm={this.sendConfirm}
                onTabChange={this.tabChange}
                createIssue={this.handleAddIssueClick}
                handleIssueCollapseButton={this.handleIssueToggleCollapsed}
                userState={this.props.userState}
                actions={this.props.actions}
                isFixed={this.state.isFixed && window.location.hash.indexOf('#issues/') === -1}
                pageNumber={this.state.pageNumber}
                pageChange={this.onPageChange}
              />
            </TabPanel>

            <TabPanel title="Messages">
              {this.state.addingMessage ? (
                <div className="row">
                  <div className="col-xs-12">
                    <span className="Messages__link" onClick={this.saveAddMessage}>
                      Send
                    </span>
                    &nbsp;&nbsp;
                    <span className="Messages__link" onClick={this.cancelAddMessage}>
                      Cancel
                    </span>
                  </div>
                  <div className="col-xs-12">
                    {/*// @ts-ignore*/}
                    <EditableEntity
                      dataProps={{
                        className: 'ObjectDescription__text',
                        maxLineCount: '3',
                        title: '',
                        defaultLineHeight: '19',
                        onChange: this.changeAddMessage
                      }}
                      data={this.state.addingMessageText}
                      dataType={{ name: 'text' }}
                      inEditMode
                      isEditable
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-xs-12">
                    <span className="Messages__link" onClick={this.addMessage}>
                      + Add Comment
                    </span>
                  </div>
                </div>
              )}
              {messages.slice(0, this.state.showAll ? messages.length : 2).map((message, i) => {
                return (
                  <div className="Messages__row row" key={i}>
                    <div className="col-xs-2 row Messages__leftCol">
                      <div className="col">
                        <img
                          src={message.photo ? message.photo : defaultUserPicture}
                          className="Messages__userImage"
                          alt="user icon"
                        />
                      </div>
                      <div className="col">
                        {message.date}
                        <br />
                        {message.name}
                      </div>
                    </div>
                    <div className="col-xs-2">
                      <div className="Messages__buttons">
                        <button className="btn_blue btn">Approve</button>
                        <button className="btn_blue btn">Decline</button>
                      </div>
                    </div>
                    <div className="col-xs-8">{message.text}</div>
                  </div>
                )
              })}
              {messages.length > 2 && !this.state.showAll ? (
                <div className="Messages__row row">
                  <div className="col-xs-12">
                    <button className="Messages__showMore" onClick={this.showMore}>
                      Show more
                    </button>
                  </div>
                </div>
              ) : (
                ''
              )}
            </TabPanel>
            <TabPanel title="Reviews">
              <div className="Messages__row row">
                <div className="col-xs-2 row">{reviews.length !== 1 ? reviews.length + ' reviews' : '1 review'}</div>
                <div className="col-xs-10">
                  <button className="btn btn_blue">+ Add Review</button>
                </div>
              </div>
              {reviews.slice(0, this.state.showAll ? reviews.length : 2).map((review, i) => {
                return (
                  <div className="Messages__row row" key={i}>
                    <div className="col-xs-2 row Messages__leftCol">
                      <div className="col">
                        <img
                          src={review.photo ? review.photo : defaultUserPicture}
                          className="Messages__userImage"
                          alt="user icon"
                        />
                      </div>
                      <div className="col">
                        {review.date}
                        <br />
                        {review.name}
                      </div>
                    </div>
                    <div className="col-xs-10">{review.text}</div>
                  </div>
                )
              })}
              {reviews.length > 2 && !this.state.showAll ? (
                <div className="Messages__row row">
                  <div className="col-xs-12">
                    <button className="Messages__showMore" onClick={this.showMore}>
                      Show more
                    </button>
                  </div>
                </div>
              ) : (
                ''
              )}
            </TabPanel>
            <TabPanel
              title={
                <div className="Messages__issueTab">
                  Notifications
                  {this.getVisibleNotificationCount() > 0 ? (
                    <div className="Messages__issuesCount Messages__notificationCount">
                      {this.getVisibleNotificationCount()}
                    </div>
                  ) : null}
                </div>
              }
            >
              <div className="Messages__errors ">
                <div className="Messages__errorButton row">
                  <button className="btn btn_blue" onClick={this.props.actions.clearError}>
                    Clear
                  </button>
                </div>
                {this.props.error && this.props.error.length
                  ? this.props.error.map((e, errorIndex) => {
                      const fullErrorText =
                        'Error code: ' +
                        e.status +
                        '\n' +
                        'Time: ' +
                        e.time.toString() +
                        '\n' +
                        'Request: ' +
                        e.method +
                        ' ' +
                        e.url +
                        '\n' +
                        JSON.stringify(e.requestJson) +
                        '\n' +
                        'Response:' +
                        '\n' +
                        JSON.stringify(e.responseJson)
                      return (
                        <div className="Messages__error row" key={errorIndex}>
                          <div className="col-xs-12">
                            <button
                              className=" btn btn_blue Messages__copyErrorButton"
                              onClick={() => {
                                const area = document.getElementById(
                                  'Messages__error_' + errorIndex
                                ) as HTMLInputElement
                                area.select()
                                document.execCommand('copy')
                              }}
                            >
                              Copy All
                            </button>
                            <button
                              className=" btn btn_blue Messages__copyErrorButton"
                              onClick={() => {
                                const area = document.getElementById('Messages__error_' + errorIndex)
                                const text = area?.innerHTML || ''
                                this.setState({ defaultIssueText: text })
                                this.handleAddIssueClick()
                              }}
                            >
                              Create Issue
                            </button>
                            Error code: {e.status}
                            <br />
                            Time: {e.time.toString()}
                            <br />
                            Request: <br />
                            {e.method} {e.url}
                            <br />
                            <JSONTreeCopy data={e.requestJson} copyButtonLabel="Copy request" shouldExpand />
                            Response: <br />
                            <JSONTreeCopy data={e.responseJson} copyButtonLabel="Copy response" shouldExpand />
                            <textarea
                              className="JSONTreeCopy__copyJsonTextarea"
                              id={'Messages__error_' + errorIndex}
                              defaultValue={fullErrorText}
                            ></textarea>
                          </div>
                        </div>
                      )
                    })
                  : null}
              </div>
            </TabPanel>
          </Tabs>
        ) : null}
        {this.state.isFixed ? (
          <div className={'Messages__fixed'}>
            {this.props.object ? (
              <div className="Messages__rowCreate">
                <div className="Messages__rowCreateInner">
                  <div className="Messages__rowCreateFixed">
                    <div className="Messages__rowCreateFixedInner">
                      {' '}
                      {issueCreateButton} {reportCreateButton}
                    </div>
                  </div>
                  <div className="Messages__scrollArrows">
                    <div className={'Messages__arrowFixed ' + arrowPictureClass} onClick={this.arrowScroll()}></div>
                  </div>
                </div>
              </div>
            ) : null}
            <Tabs
              ref={this.fixedTabsRef}
              className={'Messages__tabs tabs-list ' + this.props.tabClassName}
              onAfterChange={this.fixedTabChange}
            >
              <TabPanel
                title={
                  <div className="Messages__issueTab">
                    Issues
                    <div className="Messages__issuesCount">{this.getVisibleIssueCount()}</div>
                  </div>
                }
                className="Messages__tabIssuesView"
              />
              <TabPanel title="Messages" />
              <TabPanel title="Reviews" />
              <TabPanel
                title={
                  <div className="Messages__issueTab">
                    Notifications
                    {this.getVisibleNotificationCount() > 0 ? (
                      <div className="Messages__issuesCount Messages__notificationCount">
                        {this.getVisibleNotificationCount()}
                      </div>
                    ) : null}
                  </div>
                }
              />
            </Tabs>
          </div>
        ) : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    userState: state.userAppState,
    error: state.appState.error
  }
}

export const Messages = connect(mapStateToProps, null)(MessagesComponent)
// withRef parameter is needed because we access Messages component with refs in MajorObjectVersioned
