/**
 * Created by mailf on 03.05.2016.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ScrollArea from 'react-scrollbar'
import '../../styles/KascodeSelect/KascodeSelect.scss'
import { EditableEntity } from '../EditableEntity/EditableEntity'

import vDown from '../../resources/images/v-down-2x.png'
import iCheck from '../../resources/images/check-2x.png'
import logger from 'helpers/logger'

const OPTION_HEIGHT = 23
const MAX_DISPLAYED_OPTIONS = 8

export class KascodeSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeOptions:
        props.activeOptions && typeof props.activeOptions !== 'number'
          ? props.activeOptions.slice()
          : props.multi
          ? []
          : props.activeOptions,

      opened: false,
      direction: 'down',
      maxOptionsHeight: 1000,
      editText: ''
    }

    this.rootRef = React.createRef()

    this.KascodeSelectRef = React.createRef()
  }

  UNSAFE_componentWillReceiveProps(new_props) {
    if (this.props.externalControl) {
      this.setState({
        activeOptions: new_props.activeOptions
          ? new_props.multi
            ? new_props.activeOptions.slice()
            : new_props.activeOptions
          : new_props.multi
          ? []
          : null
      })
    }
  }

  isValueSelected(id) {
    let ids = this.state.activeOptions

    if (this.props.multi) {
      for (let i of ids) {
        if (i === id) {
          return true
        }
      }
    } else return id === ids
  }

  onValuesChange() {
    // logger.log('KascodeSelect::onValuesChange', this.state.activeOptions)
    if (this.props.onValuesChange) {
      const changedObj = {
        target: {
          value: this.props.multi
            ? this.props.options.filter((el) => {
                return this.state.activeOptions.filter((ao) => ao === el.id).length > 0
              })
            : this.state.activeOptions === null
            ? null
            : this.props.options.filter((el) => el.id === this.state.activeOptions)[0].value,
          label: this.props.multi
            ? null
            : this.state.activeOptions === null
            ? null
            : this.props.options.filter((el) => el.id === this.state.activeOptions)[0].label
        }
      }

      this.props.onValuesChange(changedObj)
    }
    if (!this.props.multi) this.close()
  }

  onOptionClick(option) {
    //console.log("KascodeSelect::onOptionClick", option);
    let ids = this.props.multi ? this.state.activeOptions.filter((el) => true) : this.state.activeOptions

    if (this.props.multi) {
      if (option.selected) {
        if (this.props.transEnum || this.props.transView) {
          return ids
        }
        ids = ids.filter((v) => {
          return v !== option.id
        })
      } else {
        this.props.transEnum || this.props.transView ? (ids = [option.id]) : ids.push(option.id)
      }
      this.setState(
        {
          activeOptions: ids
        },
        this.onValuesChange.bind(this)
      )
    } else {
      if (option.selected) {
        ids = null
        this.setState(
          {
            activeOptions: ids
          },
          this.onValuesChange.bind(this)
        )
      } else {
        ids = option.id
        this.setState(
          {
            activeOptions: ids
          },
          this.onValuesChange.bind(this)
        )
      }
    }
  }

  visibleOptionCount = () => {
    //this.props.options.length
    return this.props.options.filter(
      (el) => !this.state.editText || el.label.toLowerCase().indexOf(this.state.editText) === 0
    ).length
  }

  checkDirection = () => {
    const myNode = this.rootRef.current || this.KascodeSelectRef.current

    if (!myNode) {
      return
    }
    let node = myNode

    // find parent in chain that has data-isContainer=true
    do {
      node = node.parentNode
    } while (node && (!node.dataset || !node.dataset.iscontainer))

    //let bodyRect = document.body.getBoundingClientRect();
    let myRect = myNode.getBoundingClientRect()
    let myOptionListHeight = this.visibleOptionCount() * OPTION_HEIGHT
    let h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

    //console.log("bottom", myRect.bottom, "list height", myOptionListHeight);

    if (h - myRect.bottom - myOptionListHeight < 0 && myRect.top > myOptionListHeight) {
      this.setState({ direction: 'up', maxOptionsHeight: myRect.top })
      return
    }

    if (!node) {
      this.setState({ direction: 'down', maxOptionsHeight: h - myRect.bottom })
      return
    }

    let parentRect = node.getBoundingClientRect()
    //console.log("my", myRect, "parent", parentRect);
    if (
      -myRect.bottom + parentRect.bottom < myOptionListHeight &&
      -myRect.bottom + parentRect.bottom < myRect.top - parentRect.top
    ) {
      this.setState({
        direction: 'up',
        maxOptionsHeight: -parentRect.top + myRect.top
      })
    } else {
      this.setState({
        direction: 'down',
        maxOptionsHeight: parentRect.bottom - myRect.bottom
      })
    }
  }

  toggleOpened() {
    this.setState(
      {
        opened: !this.state.opened,
        editText: ''
      },
      () => {
        this.checkDirection()
        if (this.props.onOpen) this.props.onOpen()
      }
    )
  }

  close() {
    //console.log("KascodeSelect::close");
    if (this.state.editText && this.props.onEditText) this.props.onEditText(this.state.editText)
    this.setState({
      opened: false,
      editText: ''
    })
  }

  isChildNode(node) {
    const myNode = this.rootRef.current

    do {
      if (node === myNode) return true
      node = node.parentNode
    } while (node)
    return false
  }

  onBlur() {
    //console.log("KascodeSelect:onBlur");
    setTimeout(() => {
      //console.log("KascodeSelect::Blur in Timeout");

      // this is a workaround
      // when scrollarea is scrolled, contents are repainted
      // and focus is lost from KascodeSelect, which causes it to close
      // this is undesired behavior and when focus is transferred to .scrollarea, KascodeSelect should not close
      //console.log("KascodeSelect:willClose", document.activeElement.tagName, document.activeElement.className);

      // added isChildNode() check, because when focus was transferred from one KascodeSelect to another, first one was not closed
      if (
        (document.activeElement.className.indexOf('KascodeSelect') !== -1 &&
          this.isChildNode(document.activeElement)) ||
        document.activeElement.className.indexOf('scrollarea') !== -1
      ) {
        return
      } else {
        if (this.props.onBlur) {
          const e = {
            target: {
              value: this.props.multi
                ? this.props.options.filter((el) => this.isValueSelected(el.id))
                : this.props.options.filter((el) => el.id === this.state.activeOptions)[0]
                ? this.props.options.filter((el) => el.id === this.state.activeOptions)[0].value
                : undefined
            }
          }

          this.props.onBlur(e)
        }

        this.close()
      }
      // timeout was added to make KascodeSelect work in Table in DataDialog in ObjectProperties
      // with no timeout, blur was immediate and unchanged data was saved
    }, 200)
  }

  renderOption({ id, label, value, selected, description }, index) {
    // //console.log("KascodeSelect:renderOption", label, value);
    return (
      <div
        className={'KascodeSelect__option ' + (this.props.multiColumn ? 'KascodeSelect__multiCol' : '')}
        data-value={value}
        key={index}
        onClick={this.onOptionClick.bind(this, { id, label, value, selected })}
        ref={this.rootRef}
      >
        <div className="KascodeSelect__label">
          {description ? (
            <EditableEntity
              className="KascodeSelect__tooltip"
              dataType={{ name: 'propertyName' }}
              data={{ name: label, description: description }}
              dataProps={{ tipClassName: 'KascodeSelect' }}
            />
          ) : (
            label
          )}
        </div>
        {!this.props.hideCheckBox ? (
          <div className="KascodeSelect__checkbox">
            {selected ? (
              <div className="KascodeSelect__check">
                <img src={iCheck} alt="" />
              </div>
            ) : null}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }

  getDisplayOptions = () => {
    const { options } = this.props
    const { editText } = this.state

    const rawOptions = options.filter((option) => {
      if (!editText) {
        return true
      }

      const lowerCaseLabel = option.label.toLowerCase()

      return lowerCaseLabel.includes(editText.toLowerCase())
    })
    //console.log("KascodeSelect:getDisplayOptions", rawOptions, this.state, this.props);

    return rawOptions.map((rawOption, index) => {
      const { id } = rawOption

      rawOption.selected = this.isValueSelected(id)

      return this.renderOption(rawOption, index)
    })
  }

  renderOptions(options) {
    //console.log("KascodeSelect:renderOptions", options, this.state, this.props);
    return (
      <ScrollArea
        style={{
          maxHeight: Math.min(this.state.maxOptionsHeight, OPTION_HEIGHT * MAX_DISPLAYED_OPTIONS),
          width: '100%'
        }}
        className={
          'KascodeSelect__options ' + (options?.length > MAX_DISPLAYED_OPTIONS ? 'KascodeSelect__optionsMany' : '')
        }
      >
        {this.getDisplayOptions()}
      </ScrollArea>
    )
  }

  renderValues() {
    return (
      <div className="KascodeSelect__values">
        {this.state.activeOptions.map((val, index) => {
          return (
            <div className="KascodeSelect__value" key={index}>
              {val}
            </div>
          )
        })}
      </div>
    )
  }

  getActiveOption = () => {}

  onKeyDown(event) {
    if (this.props.noKeyboardFilter) {
      return true
    }

    let code = null

    if (event.keyCode !== undefined) {
      code = event.keyCode
    }

    //console.log("KascodeSelect::onKeyDown", code);

    if (code === 8) {
      // BACKSPACE
      this.setState(
        (prevState) => ({
          ...prevState,
          editText: prevState.editText.slice(0, prevState.editText.length - 1)
        }),
        this.checkDirection()
      )
      event.preventDefault()
    } else if (code >= ' '.charCodeAt(0) && code <= 'z'.charCodeAt(0)) {
      this.setState(
        (prevState) => ({
          ...prevState,
          editText: prevState.editText + String.fromCharCode(code).toLowerCase()
        }),
        this.checkDirection()
      )
    }
  }

  renderDataOrPlaceholder = () => {
    const { props, state } = this
    const { activeOptions, editText } = state
    const { multi, options, data, placeholder } = props
    //logger.info("KascodeSelect:renderDataOrPlaceholder", this.state, this.props);

    if (editText) {
      return <div className="KascodeSelect__textdata">{editText}</div>
    }

    if (multi) {
      if (data) {
        return <div className="KascodeSelect__textdata">{data}</div>
      }

      return <div className="KascodeSelect__placeholder">{placeholder}</div>
    }

    if (activeOptions) {
      let activeOption = options.filter((el) => el.id === activeOptions)
      if (activeOption.length > 0) {
        return <div className="KascodeSelect__textdata">{activeOption[0].label}</div>
      }
    }

    if (data) {
      return <div className="KascodeSelect__textdata">{data}</div>
    }

    return <div className="KascodeSelect__placeholder">{placeholder}</div>
  }

  renderList = () => {
    let className = 'KascodeSelect__list'
    const { direction, maxOptionsHeight, opened } = this.state
    const { openTop, options } = this.props

    if (opened) {
      className = `${className} KascodeSelect__list_opened`
    }

    if (openTop) {
      className = `${className} KascodeSelect__list_opened-top`
    }

    const style = {}

    if (direction === 'up') {
      style.top = -Math.min(maxOptionsHeight, OPTION_HEIGHT * Math.min(MAX_DISPLAYED_OPTIONS, options.length))
    }

    style.maxHeight = Math.min(maxOptionsHeight, OPTION_HEIGHT * MAX_DISPLAYED_OPTIONS)
    //console.log("KascodeSelect:renderList", className, style, this.state);

    return (
      <div
        className={className}
        onScroll={(e) => {
          //console.log("KascodeSelect:renderList:SCROLL", e, this.state);
          e.preventDefault()
        }}
        onWheel={(e) => {
          //console.log("KascodeSelect:renderList:WHEEL", e, this.state);
        }}
        style={style}
      >
        {this.renderOptions(this.props.options)}
      </div>
    )
  }

  render() {
    let mainStyle = {}

    if (this.state.direction === 'up') {
      mainStyle.height = OPTION_HEIGHT + 'px'
    }
    return (
      <div
        className={'KascodeSelect ' + (this.props.className || '')}
        style={mainStyle}
        tabIndex="0"
        onBlur={this.onBlur.bind(this)}
        onKeyDown={this.onKeyDown.bind(this)}
        ref={this.KascodeSelectRef}
      >
        <div className="KascodeSelect__input" onClick={this.toggleOpened.bind(this)}>
          {this.renderDataOrPlaceholder()}

          <div className={'KascodeSelect__toggle' + (this.state.opened ? ' KascodeSelect__toggle_rotated' : '')}>
            <img src={vDown} alt="" />
          </div>
        </div>
        {this.state.opened ? this.renderList() : null}
      </div>
    )
  }
}

KascodeSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.element])
    })
  ).isRequired,
  isEditable: PropTypes.number, // State of editor
  data: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  multi: PropTypes.bool,
  onValuesChange: PropTypes.func,
  onBlur: PropTypes.func,
  onOpen: PropTypes.func,
  openTop: PropTypes.bool,
  activeOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  multiColumn: PropTypes.bool,
  externalControl: PropTypes.bool, // if true, does not store selected options in internal state
  hideCheckBox: PropTypes.bool, // Display checkboxes in options list
  noKeyboardFilter: PropTypes.bool
}
