import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { history } from '../index'

export const poolData = {
  // UserPoolId: window.AmazonCognitoUserPoolId || 'us-east-2_DVwWFfSPl',
  // ClientId: window.AmazonCognitoClientId || '142v32rlfobp27f5599u02okac'
  UserPoolId: process.env.REACT_APP_AMAZON_COGNITO_USER_POOL_ID || 'us-east-2_DVwWFfSPl',
  ClientId: process.env.REACT_APP_AMAZON_COGNITO_CLIENT_ID || '142v32rlfobp27f5599u02okac'
}

// Cognito's token lifetime is fixed at 1 hour, we will update token every 30 minutes
const COGNITO_REFRESH_INTERVAL = 30 * 60 * 1000
const COGNITO_CHECK_INTERVAL = 1 * 10 * 1000
let cognitoRefreshTimer = null
let cognitoWaitingRequestList = []

/**
 * initializes refreshing Cognito token. Is called after cognito sign in
 */
export function initCognitoRefresh() {
  //console.log("initCognitoRefresh");
  if (cognitoRefreshTimer) clearTimeout(cognitoRefreshTimer)
  cognitoRefreshTimer = setTimeout(() => {
    //console.log("cognitoRefreshTimer", cognitoRefreshTimer);
    if (new Date().getTime() - localStorage.currentUserTokenTime > COGNITO_REFRESH_INTERVAL) {
      refreshCognitoToken()
    }
    initCognitoRefresh()
  }, COGNITO_CHECK_INTERVAL) // 1 minute

  //console.log("initCognitoRefresh set timer", cognitoRefreshTimer);

  // additional refresh on window/tab focus
  window.onfocus = () => {
    //console.log("window.onfocus");
    if (new Date().getTime() - localStorage.currentUserTokenTime > COGNITO_REFRESH_INTERVAL) {
      refreshCognitoToken(true) // with page reload after success
    }
  }
}

/**
 * Is called after cognito token refresh
 * @param {*} error
 * @param {*} result
 */
export function onRefreshSuccess(error, result) {
  console.log('onRefreshSuccess result', error, result)

  // go to login page if could not refresh token
  if (error) {
    history.push('/cognitologin' + window.location.hash)
    return false
  }

  const idToken = result.idToken.jwtToken

  localStorage.setItem('currentUserToken', idToken)
  localStorage.setItem('currentUserTokenTime', new Date().getTime())

  console.log('onRefreshSuccess: result refresh', result.refreshToken)
  localStorage.setItem('currentUserRefreshToken', result.refreshToken.token)

  localStorage.refreshStarted = ''

  if (cognitoWaitingRequestList) {
    cognitoWaitingRequestList.map((done) => (done ? done(null, null) : false))
    cognitoWaitingRequestList = []
  }

  return true
}

/**
 * Update token and reload current page (is called when page had errors caused by expired token)
 * @param {*} error
 * @param {*} result
 */
function onRefreshSuccessAndReload(error, result) {
  if (onRefreshSuccess(error, result)) {
    // eslint-disable-next-line no-restricted-globals
    location.reload()
  }
}

/**
 * Is called every 30 minutes or after failed API call
 * @param {*} wasError if true, will reload page
 * @param {*} done if specified, function will be called instead of onRefreshSuccess/onRefreshSuccessAndReload functions
 */
export function refreshCognitoToken(wasError = false, done = null) {
  const userName = localStorage.currentUserLogin
  console.log('refreshCognitoToken', wasError, localStorage.refreshStarted, localStorage.currentUserRefreshToken)

  // We are already refreshing the token, no need to call twice
  if (new Date().getTime() - localStorage.refreshStarted < COGNITO_CHECK_INTERVAL) {
    cognitoWaitingRequestList.push(done)
    return
  }

  if (window.location.href.indexOf('/cognitologin') !== -1) {
    return
  }

  // if no token present, go to login page
  if (!localStorage.currentUserRefreshToken) {
    history.push('/cognitologin' + window.location.hash)
    return
  }

  try {
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
    const userData = {
      Username: userName,
      Pool: userPool
    }
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
    const token = { getToken: () => localStorage.currentUserRefreshToken }

    console.log('Refreshing', cognitoUser, token)

    let onDone = done

    if (!onDone) {
      onDone = wasError ? onRefreshSuccessAndReload : onRefreshSuccess
    }

    localStorage.refreshStarted = new Date().getTime()
    cognitoUser.refreshSession(token, onDone)
  } catch (e) {
    history.push('/cognitologin' + window.location.hash)
    return
  }
}

export function checkAndRefreshCognitoToken() {
  return new Promise((resolve, reject) => {
    if (window.AUTH_URL !== '/cognitologin') {
      resolve()
      return
    }
    if (
      !localStorage.currentUserRefreshToken ||
      (localStorage.currentUserTokenTime &&
        new Date().getTime() - localStorage.currentUserTokenTime > COGNITO_REFRESH_INTERVAL)
    ) {
      refreshCognitoToken(false, (error, result) => {
        if (error || result) onRefreshSuccess(error, result)
        resolve()
      })
    } else {
      resolve()
    }
  })
}
