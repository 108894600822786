/**
 * Created by mailf on 08.06.2016.
 */
import React, { Component } from 'react'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import './GenerateParamsDialog.scss'

export class GenerateParamsDialog extends Component {
  constructor(props) {
    super(props)

    this.state = { schemaParams: props.match.params }
  }

  render() {
    //this.props.createSchema

    let params = this.state.schemaParams
    let options = {
      schemaVersion: [
        { id: 1, label: '2.0', value: '2.0' },
        { id: 2, label: '3.0', value: '3.0' }
      ],
      nameOption: [
        { id: 1, label: 'lower', value: 'lower' },
        { id: 2, label: 'upper', value: 'upper' },
        {
          id: 3,
          label: 'none',
          value: 'none'
        }
      ],
      enumOption: [
        { id: 1, label: 'lower', value: 'lower' },
        { id: 2, label: 'upper', value: 'upper' },
        {
          id: 3,
          label: 'none',
          value: 'none'
        }
      ],
      hideFuture: [
        { id: 1, label: 'false', value: false },
        { id: 2, label: 'true', value: true }
      ],
      hideOptional: [
        { id: 1, label: 'false', value: false },
        { id: 2, label: 'true', value: true }
      ],
      approvedVersion: [
        { id: 1, label: 'false', value: false },
        { id: 2, label: 'true', value: true }
      ]
    }

    console.log('GenerateParamsDialog:renderSchemaParams', this.state.schemaParams)

    /*
     schemaVersion: '3.0',     // Version of Swagger schema
     nameOption: 'lower',      // Use upper/lower case for first latter for name in schema and remove spaces.
     enumOption: 'upper',      // Use upper/lower case and replace spaces with underscores for values in enums.
     hideFuture: true,         // Hide field with prefix FUTURE:.
     hideOptional: false,      // Hide optional and keep only required field.
     approvedVersion: true     // Use last approve minor version for specified major version.
     */
    return (
      <ModalWindow
        level={1}
        className="GenerateParamsDialog"
        isOpen
        onClose={this.props.onClose}
        header={'Generate schema'}
        footer={
          <div>
            <button className="btn_big ActionWindow__button" onClick={this.props.onClose}>
              Cancel
            </button>
            <button
              className={'btn_big ActionWindow__button'}
              onClick={() => this.props.onGenerate(this.state.schemaParams)}
            >
              Generate
            </button>
          </div>
        }
      >
        <div className="GenerateParamsDialog">
          <div className="row ">
            {params.schemaVersion !== undefined ? (
              <div className="GenerateParamsDialog__block_platform clearfix">
                <label className="">Version of Swagger schema:</label>
                <div className="GenerateParamsDialog__block_value_right">
                  <EditableEntity
                    data={params.schemaVersion}
                    dataType={{ name: 'enum', options: options.schemaVersion }}
                    dataProps={Object.assign({
                      onChange: (e) => {
                        let newParams = Object.assign({}, this.state.schemaParams, { schemaVersion: e.target.value })
                        this.setState({ schemaParams: newParams })
                      },
                      activeOptions: options['schemaVersion'].reduce(
                        (p, c) => (c.value === params.schemaVersion ? c.id : p),
                        null
                      ),
                      placeholder: 'Choose schema version',
                      hideCheckBox: true,
                      multi: false
                    })}
                    isEditable
                    inEditMode
                  />
                </div>
              </div>
            ) : null}

            {params.nameOption !== undefined ? (
              <div className="GenerateParamsDialog__block_platform clearfix">
                <label className="">Use upper/lower case for first latter for name in schema and remove spaces:</label>
                <div className="GenerateParamsDialog__block_value_right">
                  <EditableEntity
                    data={params.nameOption}
                    dataType={{ name: 'enum', options: options.nameOption }}
                    dataProps={Object.assign({
                      onChange: (e) => {
                        let newParams = Object.assign({}, this.state.schemaParams, { nameOption: e.target.value })
                        this.setState({ schemaParams: newParams })
                      },
                      activeOptions: options['nameOption'].reduce(
                        (p, c) => (c.value === params.nameOption ? c.id : p),
                        null
                      ),
                      placeholder: 'Choose name option',
                      hideCheckBox: true,
                      multi: false
                    })}
                    isEditable
                    inEditMode
                  />
                </div>
              </div>
            ) : null}

            {params.enumOption !== undefined ? (
              <div className="GenerateParamsDialog__block_platform clearfix">
                <label className="">
                  Use upper/lower case and replace spaces with underscores for values in enums:
                </label>
                <div className="GenerateParamsDialog__block_value_right">
                  <EditableEntity
                    data={params.enumOption}
                    dataType={{ name: 'enum', options: options.enumOption }}
                    dataProps={Object.assign({
                      onChange: (e) => {
                        let newParams = Object.assign({}, this.state.schemaParams, { enumOption: e.target.value })
                        this.setState({ schemaParams: newParams })
                      },
                      activeOptions: options['enumOption'].reduce(
                        (p, c) => (c.value === params.enumOption ? c.id : p),
                        null
                      ),
                      placeholder: 'Choose option',
                      hideCheckBox: true,
                      multi: false
                    })}
                    isEditable
                    inEditMode
                  />
                </div>
              </div>
            ) : null}

            {params.hideFuture !== undefined ? (
              <div className="GenerateParamsDialog__block_platform clearfix">
                <label className="">Hide field with prefix FUTURE:</label>
                <div className="GenerateParamsDialog__block_value_right">
                  <EditableEntity
                    data={params.hideFuture}
                    dataType={{ name: 'enum', options: options.hideFuture }}
                    dataProps={Object.assign({
                      onChange: (e) => {
                        let newParams = Object.assign({}, this.state.schemaParams, { hideFuture: e.target.value })
                        this.setState({ schemaParams: newParams })
                      },
                      activeOptions: options['hideFuture'].reduce(
                        (p, c) => (c.value === params.hideFuture ? c.id : p),
                        null
                      ),
                      placeholder: 'Choose option',
                      hideCheckBox: true,
                      multi: false
                    })}
                    isEditable
                    inEditMode
                  />
                </div>
              </div>
            ) : null}

            {params.hideOptional !== undefined ? (
              <div className="GenerateParamsDialog__block_platform clearfix">
                <label className="">Hide optional fields</label>
                <div className="GenerateParamsDialog__block_value_right">
                  <EditableEntity
                    data={params.hideOptional}
                    dataType={{ name: 'enum', options: options.hideOptional }}
                    dataProps={Object.assign({
                      onChange: (e) => {
                        let newParams = Object.assign({}, this.state.schemaParams, { hideOptional: e.target.value })
                        this.setState({ schemaParams: newParams })
                      },
                      activeOptions: options['hideOptional'].reduce(
                        (p, c) => (c.value === params.hideOptional ? c.id : p),
                        null
                      ),
                      placeholder: 'Choose option',
                      hideCheckBox: true,
                      multi: false
                    })}
                    isEditable
                    inEditMode
                  />
                </div>
              </div>
            ) : null}

            {params.approvedVersion !== undefined ? (
              <div className="GenerateParamsDialog__block_platform clearfix">
                <label className="">Use last approved minor version for specified major version:</label>
                <div className="GenerateParamsDialog__block_value_right">
                  <EditableEntity
                    data={params.approvedVersion}
                    dataType={{
                      name: 'enum',
                      options: options.approvedVersion
                    }}
                    dataProps={Object.assign({
                      onChange: (e) => {
                        let newParams = Object.assign({}, this.state.schemaParams, { approvedVersion: e.target.value })
                        this.setState({ schemaParams: newParams })
                      },
                      activeOptions: options['approvedVersion'].reduce(
                        (p, c) => (c.value === params.approvedVersion ? c.id : p),
                        null
                      ),
                      placeholder: 'Choose option',
                      hideCheckBox: true,
                      multi: false
                    })}
                    isEditable
                    inEditMode
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </ModalWindow>
    )
  }
}
