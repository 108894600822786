/**
 * Created by mailf on 23.05.2016.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { API } from '../../helpers/api'
import {
  deepMerge,
  editableState,
  getFullUri,
  objectNameFromPathByType,
  strToVersion,
  versionToStr
} from '../../helpers/index'
import { DraftWindow } from '../DraftWindow/DraftWindow'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { FinalizeWindow } from '../FinalizeWindow/FinalizeWindow'
import { LocaleSelect } from '../LocaleSelect/LocaleSelect'
import { PropertyTable } from '../NewTable/PropertyTable'
import './style.scss'
import { formatByType } from '../../helpers/editByType'

class ObjectAdvancedSettings extends Component {
  constructor(props) {
    super(props)

    let settings = this.getInitialSettings(props)
    let height = this.InitHeight(props)

    this.state = {
      change: null, // Method to report or cancel modification.
      origin: JSON.stringify(settings).toLowerCase(),

      base: settings.base,
      merge: settings.base ? deepMerge(settings.base, settings.data) : settings.data,
      settings: settings.data,

      height: height,

      editMode: false,
      isDeleting: false,
      oldVersion: props.version
        ? props.version
        : {
            major: 0,
            minor: 0,
            revision: 0
          },
      newVersion: props.version
        ? {
            major: props.version.major,
            minor: props.version.minor,
            revision: props.version.revision + 1
          }
        : {
            major: 0,
            minor: 0,
            revision: 1
          },
      versions: [],
      isVersionSelecting: false,
      isFinalizing: false,
      isSelectingDraftVersion: false,
      isEdited: false,
      structures: []
    }

    this.state.versions = this.getVersionOptions() // eslint-disable-line react/no-direct-mutation-state

    this.rootRef = React.createRef()
  }

  /**
   * Update version in local state if new version is passed through props
   * @param newProps
   */
  UNSAFE_componentWillReceiveProps(newProps) {
    //console.log("ObjectAdvancedSettings componentWillReceiveProps", newProps);
    this.setState({
      versions: [],
      settings: newProps.settings && newProps.settings.map ? newProps.settings : [],
      origin: newProps.settings ? JSON.stringify(newProps.settings).toLowerCase() : ''
    })
    if (newProps.version) {
      this.setState(
        {
          oldVersion: newProps.version
            ? newProps.version
            : {
                major: 0,
                minor: 0,
                revision: 0
              },
          newVersion: newProps.version
            ? {
                major: newProps.version.major,
                minor: newProps.version.minor,
                revision: newProps.version.revision
              }
            : {
                major: 0,
                minor: 0,
                revision: 1
              }
        },
        () => {
          this.setState({
            versions: this.getVersionOptions()
          })
        }
      )
    }
    if (this.state.editMode) {
      // load version list for this object
      if (this.props.loadVersionOptions) this.props.loadVersionOptions().then(this.parseVersionOptions)
    }
  }

  /**
   * Get initial value of parameter
   * @param {object} [props]
   * @returns {*}
   */
  getInitialSettings = (props) => {
    props = props || this.props
    return { data: props.settings ? props.settings : [], base: props.base }
  }

  /**
   * Init dialog height
   * @param user - selected user
   */
  InitHeight = (props) => {
    props = props || this.props
    // console.log("ObjectAdvancedSettings:InitHeight", props, window.innerHeight);

    let height = 300

    if (height < window.innerHeight - 248) height = window.innerHeight - 248

    return height
  }

  /**
   * get list of existing settings versions
   * @returns {{id: number, label: string, value: string}[]}
   */
  getVersionOptions() {
    return this.state.versions.map((version, index) => {
      return {
        id: index,
        label: versionToStr(version),
        value: versionToStr(version)
      }
    })
  }

  /**
   * loads structure of object's parent organizations to display them in TypeSelector
   */
  loadOrganizationStructures() {
    const organizationName = objectNameFromPathByType(getFullUri(this.props.object), 'organizations')
    API.organizations(organizationName)
      .datasets.get({ usage: 'Structure' })
      .then((structs) => {
        structs = structs.map((st) => {
          let found = false
          if (this.props.appState.datasets) {
            this.props.appState.datasets.forEach((ds) => {
              if (ds.identity && ds.identity.id === st.identity.id) found = ds
            })
          }
          return found ? found : st
        })
        this.setState({ structures: structs })
        this.props.actions.receiveObjectList('dataset', structs)
      })
  }

  /**
   * Change state of settings
   * @param {object} - new state of object
   * @returns {*} true - it is updated, false - no changes
   */
  ChangeSettings = (settings) => {
    if (this.state.change) {
      let curr = JSON.stringify(this.state.settings).toLowerCase()
      let next = JSON.stringify(settings).toLowerCase()

      console.log('ObjectAdvancedSettings:ChangeSettings', settings, curr, next)

      if (this.state.change) {
        this.state.change(this.state.origin !== next, '', '', '')
      }

      if (curr !== next) {
        let merge = this.state.base ? deepMerge(this.state.base, settings) : settings
        console.log('ObjectAdvancedSettings:ChangeSettings:MERGE', merge, settings)

        this.setState({ settings: settings, merge: merge })
        return true
      }
    }
    return false
  }

  /**
   * Execute activity when dialog closing
   * @return
   */
  onClose = () => {
    this.setState({ editMode: false })
  }

  /**
   * Set edit mode on
   */
  startEdit = (change) => {
    this.setState(
      {
        change: change
      },
      () => {
        if (this.state.editMode) {
          // load version list for this object
          if (this.props.loadVersionOptions) this.props.loadVersionOptions().then(this.parseVersionOptions)
        }
      }
    )
  }

  /**
   * Execute activity when message send to service
   * @param error return from service if any.
   * @return
   */
  onSent = (close, error) => {
    console.log('ObjectAdvancedSettings:onSent', close, error)

    // Report complete of save with error or not
    if (this.state.change) {
      this.state.change(error)
    }

    if (close && !error) {
      setTimeout(() => {
        this.onClose()
      }, 1000)
    }
  }

  /**
   * Cancel changes
   * @param {boolean} [closeDialog] Do we need to close dialog
   */
  cancelEdit = (closeDialog) => {
    // console.log("ObjectAdvancedSettings:onCancel", close);

    // Cancel save mode
    if (this.state.change) this.state.change(false)

    let settings = this.getInitialSettings()

    this.setState({
      change: null,
      merge: settings.base ? deepMerge(settings.base, settings.data) : settings.data,
      settings: settings.data
    })
  }

  /**
   * extract versions from version objects returned from API
   * @param versions
   */
  parseVersionOptions = (versions) => {
    this.setState({
      versions: versions.map((versionObject) => {
        return versionObject.version
      })
    })
  }

  /**
   * show or hide main window
   */
  toggleEditMode() {
    this.setState(
      {
        editMode: !this.state.editMode,
        isEdited: false
      },
      () => {
        if (this.state.editMode) {
          // load version list for this object
          if (this.props.loadVersionOptions) this.props.loadVersionOptions().then(this.parseVersionOptions)

          // load org structures for type edit
          if (this.state.structures.length === 0) this.loadOrganizationStructures()
        }
      }
    )
  }

  /**
   * show or hide Finalization window
   */
  toggleFinalize() {
    this.setState({ isFinalizing: !this.state.isFinalizing })
  }

  /**
   * show or hide Draft Version Selection window
   */
  toggleCreateDraft() {
    this.setState({
      isSelectingDraftVersion: !this.state.isSelectingDraftVersion
    })
  }

  /**
   * Update settings based on update in table
   */
  onChangeSettings = (rowIndex, oldSetting, newSetting) => {
    let newSettings = this.state.settings.slice()
    //console.log("ObjectAdvancedSettings::onChangeSettings", rowIndex, newSetting, newSettings);

    newSettings[rowIndex] = {
      identity: { name: newSetting.name },
      type: newSetting.type.name,
      reference: newSetting.type.reference,
      value: newSetting.value
    }

    this.ChangeSettings(newSettings)
  }

  /**
   * Delete settings based on update in table
   */
  onDeleteSettings = (deletedSetting) => {
    let newSettings = this.state.settings.filter((setting) => setting.identity.name !== deletedSetting.name)

    // console.log("ObjectAdvancedSettings::onDeletSettings", newSettings);

    this.ChangeSettings(newSettings)
  }

  /**
   * Add settings based on update in table
   */
  onAddSettings = (deletedSetting) => {
    let newSettings = this.state.settings.concat({
      identity: { name: '' },
      type: 'string',
      reference: '',
      value: ''
    })

    //console.log("ObjectAdvancedSettings::onAddSettings", newSettings);

    this.ChangeSettings(newSettings)

    setTimeout(() => {
      // focus name of added property
      let myNode = this.rootRef.current
      //console.log("onAddSettings", myNode)
      if (!myNode) {
        return
      }
      let inputs = myNode.getElementsByTagName('input')
      //console.log("onAddSettings", inputs)
      //console.log("onAddSettingsa sdfdas"  , inputs[inputs.length - 2])

      if (inputs.length >= 2) inputs[inputs.length - 2].focus()
    }, 0)
  }

  /**
   * Save changes
   * @param {boolean} [closeDialog] Do we need to close dialog
   */
  saveSettings = (closeDialog) => {
    let inputError = ''
    // console.log("ObjectAdvancedSettings:onSave", close, inputError);
    if (inputError.length > 0) {
      if (this.state.change) {
        this.state.change(true, '', '', inputError)
      }
      return true
    }

    if (this.state.change) {
      this.state.change(true, 'Data send to the service...', '', '')
    }

    if (this.props.onChange) {
      console.log('ObjectAdvancedSettings:onSave', this.state.settings)
      this.props.onChange(this.state.settings, closeDialog, this.onSent.bind(this))
      return true // not to do anything here.
    }
  }

  /**
   * render view of component when window is closed
   * @returns {*}
   */
  renderSettings() {
    //console.log("ObjectAdvancedSetting:renderSettings", this.props, this.state);
    let merge = this.state.merge

    return merge.map((s, i) => (
      <div className="ObjectAdvancedSetting row" key={i}>
        <div className="ObjectAdvancedSetting__name col">{s.identity.name + ':'}</div>
        <div className="ObjectAdvancedSetting__value col">
          {formatByType[(s.type ? s.type.name : 'string') || 'string'](s.value)}
        </div>
      </div>
    ))
  }

  /**
   * render title of window, for versioned and non-versioned objects
   * @returns {*}
   */
  renderTitle() {
    if (!this.props.versioned) {
      return 'Advanced Settings'
    } else {
      return (
        <div>
          <div className="ObjectAdvancedSettings__title">Advanced Settings version</div>
          <LocaleSelect
            options={this.getVersionOptions().map((version) => version.label)}
            currentOption={versionToStr(this.state.newVersion)}
            onClick={this.props.onVersionChange}
          />
        </div>
      )
    }
  }

  /**
   * render EditorDialog contents - property table
   * @param editState
   * @returns {XML}
   */
  renderContent = (editState) => {
    return (
      <PropertyTable
        properties={this.state.settings}
        editableNames
        editableTypes
        editableValues
        showAdd={editState > 1}
        showDelete={editState > 1}
        isEditable={editState > 1}
        inEditMode={editState > 1}
        onChange={this.onChangeSettings}
        onDelete={this.onDeleteSettings}
        onAdd={this.onAddSettings}
        parentObject={this.props.object}
      />
    )
  }

  /**
   * render window for editing advanced settings
   * @returns {XML}
   */
  renderDialog() {
    let editState = this.props.isEditable ? editableState.EDITABLE : editableState.BROWSABLE
    if (this.props.isEditable) editState = editableState.EDITING
    if (this.state.isEdited) editState = editableState.EDITED
    // eslint-disable-next-line no-unused-vars
    const record = this.state.settings.map((prop) =>
      typeof prop.value === 'object' ? JSON.stringify(prop.value || {}) : prop.value
    )

    return (
      <EditorDialog
        objectType="settings"
        modalTitle={this.renderTitle()}
        confirmText={this.props.settings ? 'Update' : 'Save'}
        editContent={this.renderContent}
        editHeight={this.state.height}
        isEditable={editState}
        isVisible={this.state.editMode}
        onClose={this.onClose}
        onSave={this.saveSettings}
        onEdit={this.startEdit}
        onCancel={this.cancelEdit}
        actions={this.props.actions}
        footerContent={() => (
          <div>
            {this.props.status === 'draft' ? (
              <button className="btn_big EditorDialog__button" onClick={this.toggleFinalize.bind(this)}>
                Finalize
              </button>
            ) : null}
            {this.props.status === 'approved' ? (
              <button className="btn_big EditorDialog__button" onClick={this.toggleCreateDraft.bind(this)}>
                Create draft
              </button>
            ) : null}
          </div>
        )}
      />
    )
  }

  render() {
    return (
      <div className="ObjectAdvancedSettings" ref={this.rootRef}>
        <div className="ObjectAdvancedSettings__button" onClick={this.toggleEditMode.bind(this)}>
          <div className="ObjectAdvancedSettings__buttonImg"></div>Advanced Settings
        </div>
        <div className="ObjectAdvancedSettings__list">
          {this.renderSettings()}
          {this.state.editMode ? this.renderDialog() : null}
          {this.state.isFinalizing ? (
            <FinalizeWindow
              actions={this.props.actions}
              organization={this.props.object ? getFullUri(this.props.object).split('/')[2] : null}
              object={Object.assign({}, this.props.object, {
                type: 'settings'
              })}
              version={versionToStr(this.props.version)}
              isEditable={editableState.EDITING}
              isVisible={this.state.isFinalizing}
              onFinalize={(message, version, close, data) => {
                this.props.onFinalize(message, version, close, data, this.props.settings)
                this.setState({ isFinalizing: false })
              }}
              onClose={this.toggleFinalize.bind(this)}
            />
          ) : null}
          {this.state.isSelectingDraftVersion ? (
            <DraftWindow
              object={this.props.object}
              version={strToVersion(this.props.version)}
              onCreate={(version, done) => {
                this.props.createDraft(strToVersion(version), done, this.props.settings)
                this.setState({ isSelectingDraftVersion: false })
              }}
              onClose={() => this.setState({ isSelectingDraftVersion: false })}
            />
          ) : null}
        </div>
      </div>
    )
  }
}

ObjectAdvancedSettings.propTypes = {
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.object,
      value: PropTypes.any
    })
  ),
  base: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.object,
      value: PropTypes.any
    })
  ),
  status: PropTypes.string,
  isEditable: PropTypes.bool,
  className: PropTypes.string,
  hideFinalize: PropTypes.bool,
  versioned: PropTypes.bool,
  dark: PropTypes.bool,
  version: PropTypes.object,
  onChange: PropTypes.func,
  onFinalize: PropTypes.func,
  onVersionChange: PropTypes.func,
  loadVersionOptions: PropTypes.func
}

ObjectAdvancedSettings.defaultProps = {
  versioned: true
}

//export default ObjectAdvancedSettings;

function mapStateToProps(state) {
  return {
    appState: state.appState
  }
}

export default connect(mapStateToProps, null)(ObjectAdvancedSettings)
