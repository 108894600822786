/**
 * Created by mailf on 23.09.2016.
 */
import React, { RefObject } from 'react'

import logger from '../../helpers/logger'
import { SettingsDialog } from '../SettingDialog/SettingsDialog'
import { MajorObject } from '../MajorObject/MajorObject'
import { ObjectHeader } from '../ObjectHeader/ObjectHeader'
import { idAPI, getApiResource, getObjectsWithConnected, sendObjectNew, getRequestFromPath } from '../../helpers/api'
import { getObjectByName } from '../../helpers/data'
import {
  nonCSCompare,
  editableState,
  itemState,
  deepCopy,
  pathByType,
  getFullUri,
  versionCompare,
  versionToStr,
  strToVersion,
  pluralTypeForms,
  objectPictureUrl,
  objectNameFromPathByType,
  capitalize
} from '../../helpers/index'
import { getPayload } from './majorObjectVersionedHelper'

export class MajorObjectVersioned extends MajorObject {
  objectheaderRef: RefObject<any>

  constructor(props) {
    super(props)

    this.objectheaderRef = React.createRef()
  }

  onVersionChange(version) {
    const newVersion = version.replace('draft', '').replace('approved', '').replace('finalized', '').trim()
    // @ts-ignore
    const dataRequestQuery = this.getDataRequestQuery(this.props)
    dataRequestQuery.objectRequest = dataRequestQuery.objectRequest.versions(newVersion)
    logger.info('MajorObjectVersioned:onVersionChange', newVersion, dataRequestQuery, this.props)

    getObjectsWithConnected(dataRequestQuery, this.state.objectType, this.state.objectName, this.props.actions)
  }

  /**
   * Detect object version status
   * @param {object} [objectHistory]
   * @returns {string}
   */
  static getObjectStatus(objectHistory?) {
    let dataset
    if (!objectHistory) {
      // @ts-ignore
      dataset = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    }
    //console.log("getObjectStatus", dataset);
    objectHistory = objectHistory || (dataset.object ? dataset.object.history : false)

    if (!objectHistory || !objectHistory.completions) return false

    //console.log("getObjectStatus", this.state.objectType, this.state.objectName);

    if (objectHistory.completions.reduce((p, c, i) => (c.status === 'Approved' ? true : p), false)) return 'approved'
    else if (
      objectHistory.completions.reduce(
        (p, c, i) => (c.status === 'Finalized' && c.completed !== undefined ? true : p),
        false
      )
    )
      return 'finalized'
    else return 'draft'
  }

  onFinalize(message, newVersion, close, done) {
    const versionedObject = this.getObject()
    const versionedObjectUri = getFullUri(versionedObject)

    logger.info('MajorObjectVersioned:onFinalize', message, newVersion, versionedObject, versionedObjectUri, this.props)

    if (versionedObject && newVersion && !nonCSCompare(versionToStr(versionedObject.version), newVersion)) {
      // This is happens when user decide change version in time of finalization.
      // @ts-ignore
      this.getDataRequestQuery(this.props)
        .endpoint.patch({
          identity: versionedObject.identity,
          version: strToVersion(newVersion)
        })
        .then(() => {
          // Send message to finalize object
          if (message.message) {
            this.sendFinalize(message, newVersion, close, done)
          }
        })
    } else if (message.message) {
      this.sendFinalize(message, newVersion, close, done)
    }
  }

  sendFinalize(message, newVersion, close, done) {
    const versionedObject = this.getObject()
    const versionedObjectUri = getFullUri(versionedObject)
    const paths = versionedObjectUri.substring(1).split('/')
    const app = pathByType(paths, 'applications') // Issue must be attached to application.
    const sys = pathByType(paths, 'systems') // Issue must be attached to system if no app here.

    console.log(
      'MajorObjectVersioned:sendFinalize',
      message,
      newVersion,
      versionedObject,
      versionedObjectUri,
      this.props
    )

    let attached = app ? app : sys
    let trackers: any = []
    if (this.state.objectType === 'subscription') {
      const publicationPath = versionedObject.publication.identity.name
      attached = publicationPath.substr(0, publicationPath.indexOf('/publications/'))
      console.log('Subscription Finalize', publicationPath, attached, versionedObject, message, this.props.userState)

      trackers = [
        {
          id: localStorage['currentUserId'],
          name: this.props.userState.identity.name,
          description: '/organizations/' + objectNameFromPathByType(publicationPath, 'organizations')
        }
      ]

      console.log('trackers', trackers)
    }

    if (message.message) {
      // @ts-ignore
      const objectApiEndpoint = this.getDataRequestQuery(this.props).endpoint
      const objectMessageApiEndpoint = objectApiEndpoint.messages

      const payload = getPayload(
        message,
        attached,
        trackers,
        versionedObjectUri,
        newVersion,
        this.state.objectType,
        this.props.userState.profile.alias
      )

      // to avoid data duplication we need to delete previous from state
      //this.props.actions.deleteMajorObject(this.state.objectType, this.props.majorObject.identity.id);

      sendObjectNew(objectMessageApiEndpoint, 'post', this.props.actions, payload, null, true).then(
        (result) => {
          // We need to load new state of object we take action on to show his right state
          console.log('MajorObjectVersioned:sendFinalize:COMPLETE', payload, objectApiEndpoint, this.props)
          this.reload(true)
          done(close)

          // invalidate issues list of application
          // Misha: this is not needed, we called reload(true)
          window.apiCache.deleteObject(app + '/issues')
        },
        (error) => {
          done(close, error)
        }
      )
    }
  }

  reload = (forceReload = false) => {
    const dataset = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    // @ts-ignore
    const query = this.getDataRequestQuery(this.props)
    const request = query.endpoint || query.objectRequest
    const versionRequest = request.versions().get()
    // this.props.actions.deleteMajorObject(this.state.objectType, dataset.identity.id);
    // deleteMajorObject now causes reloading of object automatically (triggering WillReceiveProps of versioned major objects)
    // so we don't need to reload it using getObjectMerged
    // some of the requests are not really needed (like obj/users) but they are made in WillReceiveProps
    logger.info(
      'MajorObjectVersioned:reload=====>>>',
      { forceReload, dataset, query, request, versionRequest },
      this.state,
      this.props
    )

    getObjectsWithConnected(
      // @ts-ignore
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions,
      true
    )
  }

  onCreateDraft = (version, close, done) => {
    const dataset = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const newVersion = typeof version === 'object' ? strToVersion(version.version) : strToVersion(version)

    let newObject = Object.assign({}, dataset, { version: newVersion })
    newObject.object.history.completions = []
    // @ts-ignore
    const query = this.getDataRequestQuery(this.props)
    const request = query.endpoint || query.objectRequest
    //logger.info("MajorObjectVersioned:onCreateDraft", { version, close, newObject }, this.state, this.props);

    //request.put(newObject)
    sendObjectNew(request, 'put', this.props.actions, newObject).then(
      (result) => {
        done(close)
        if (this.props.match.params.versionName) {
          // @ts-ignore
          this.props.history.push('/view' + getFullUri(dataset))
          this.reload(true)
        } else {
          this.reload(true)
        }
      },
      (error) => {
        logger.warn('MajorObjectVersioned:onCreateDraft', error, done)
        done(close, error)
      }
    )
  }

  onSettingsChange = (settings, closeDialog, onSent) => {
    const object1 = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const object = this.getObject()
    console.log('MajorObjectVersioned:onSettingsChange', settings, object, object1, this.state, this.props)

    let apiResource = idAPI[getApiResource(this.state.objectType)](object.identity.id)

    let newObject = deepCopy(object)
    newObject.object.properties = settings

    console.log('MajorObjectVersioned:onSettingsChange:SEND', newObject)
    sendObjectNew(apiResource, 'put', this.props.actions, newObject).then(
      (result) => {
        if (result) {
          //this.props.actions.updateMajorObject(app.identity.id, this.state.objectType+'s', result);
        } else {
          //return getObjectNew(idAPI[getApiResource(this.state.objectType)](app.identity.id), this.state.objectType, this.props.actions, true);
        }
        if (onSent) onSent(closeDialog)
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  onTabChange = (index) => {
    // @ts-ignore
    const tabs = this.getTabsProps()

    //console.log("Dataset::onTabChange", index, tabs);

    if (!tabs || tabs.length < index) return

    try {
      const tabName = tabs[index - 1] ? tabs[index - 1].title : ''
      //console.log("Dataset::onTabChange tabName", tabName);

      if (tabName === 'history') {
        // History
        const dataset = this.getObject()

        //console.log("MajorObjectVersioned::onTabChange History", dataset);

        if (!dataset || !dataset.object) {
          // still loading
          this.setState({ loadingCheckpoints: true })
          return
        }

        //console.log("Dataset request checkpoints", dataset.checkpoints, this.state.loadingCheckpoints);

        if (/*!dataset.checkpoints && */ !this.state.loadingCheckpoints) {
          this.setState({ loadingCheckpoints: true })
          this.requestCheckpoints().then(() => {
            this.setState({ loadingCheckpoints: false })
          })
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  requestCheckpoints = () => {
    console.log('Dataset::requestCheckpoints')

    return new Promise<void>((resolve, reject) => {
      const dataset = this.getObject()

      let promises: Promise<any>[] = []
      let messageUris: any = []
      promises.push(getRequestFromPath(getFullUri(dataset)).checkpoints.get());
      dataset.versions.forEach((version, index) => {
        // we really do not need to load the versions here
        //promises.push(getRequestFromPath(getFullUri(dataset)).versions(versionStr).get());
        //promises.push(new Promise((resolve, reject) => resolve(null)))
               let messageUri: any = (
          version.object && version.object.history ? version.object.history.completions : []
        ).reduce((p, c) => (c.completedbymessage ? c.completedbymessage : p), null)
        //console.log("RequestCheckpoints: messageUri", messageUri);
        promises.push(
          messageUri ? getRequestFromPath(messageUri).get() : new Promise((resolve, reject) => resolve(null))
        )
        messageUris[index] = messageUri
      })

      Promise.all(promises).then(
        (responseData) => {
          //console.log("Promise all", responseData);

          let newDataset = Object.assign({}, dataset)
          newDataset.checkpoints = []
          //newDataset.versions.map((version, index) => {
          for (let index = dataset.versions.length - 1; index >= 0; index--) {
            const version = dataset.versions[index]
            const versionStr = Object.values(version.version).join('.')
            const respVers = responseData[0].filter(el => el.versionName === versionStr)
            newDataset.versions[index] = Object.assign({}, version)
            newDataset.versions[index].checkpoints = 
              [Object.assign({}, respVers[0], { version: version })]
            newDataset.versions[index].message = responseData[index+1]
            newDataset.versions[index].messageUri = messageUris[index]
          }

          this.props.actions.updateMajorObject(
            dataset.identity.id,
            pluralTypeForms.get(this.state.objectType),
            newDataset
          )

          resolve()
        },
        (error) => {
          reject(error)
        }
      )
    })
  }

  checkEditable() {
    const dataset = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable =
      !dataset.object ||
      !dataset.object.history ||
      !dataset.object.history.completions ||
      (!nonCSCompare(MajorObjectVersioned.getObjectStatus(dataset.object.history), 'Finalized') &&
        !nonCSCompare(MajorObjectVersioned.getObjectStatus(dataset.object.history), 'Approved'))

    //console.log("MajorObjectVersioned::checkEditable", dataset.object ,dataset.object.history , dataset.object.history.completions,MajorObjectVersioned.getObjectStatus(dataset.object.history));

    return isEditable
  }

  renderObjectHeader() {
    const dataset = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable = this.checkEditable()
    //logger.info("MajorObjectVersioned:renderObjectHeader", dataset.identity.name + ' ' + versionToStr(dataset.version), { dataset, isEditable }, this.state, this.props);

    return (
      <ObjectHeader
        ref={this.objectheaderRef}
        title={dataset.identity.name}
        deployment={dataset.deployment}
        objectUsers={dataset.users ? dataset.users : []}
        picture={dataset.object ? objectPictureUrl(getFullUri(dataset), dataset.object.picture) : ''}
        onObjectUsersChange={this.onObjectUsersChange.bind(this)}
        onChangePicture={this.onPictureChange.bind(this)}
        onVersionChange={this.onVersionChange.bind(this)}
        className={this.state.objectType}
        version={dataset.version}
        versions={
          // This is optimization to have versions if it here already
          // And use of current object in version array to avoid backend
          // problems with version list.
          dataset.versions
            ? dataset.versions.map((ds) => (versionCompare(ds.version, dataset.version) ? dataset : ds))
            : null
        }
        objectStatus={MajorObjectVersioned.getObjectStatus.bind(this)()}
        isDraft={isEditable}
        isEditable={isEditable}
        onFinalize={this.onFinalize.bind(this)}
        object={dataset}
        onAddUser={this.onAddUser.bind(this)}
        onDeleteUser={this.onDeleteUser.bind(this)}
        onUserRoleChange={this.onUserRoleChange.bind(this)}
        showApproveVersionIssue={this.showApproveVersionIssue}
        onCreateDraft={this.onCreateDraft}
        appState={this.props.appState}
        userState={this.props.userState}
        actions={this.props.actions}
        renderLocale={this.renderLocale}
      />
    )
  }

  renderFinalizedMessage() {
    const isEditable = this.checkEditable()
    const objectStatus = MajorObjectVersioned.getObjectStatus.bind(this)()
    const action = objectStatus === 'finalized' ? 'Approve' : 'Create draft'

    if (!isEditable && this.objectheaderRef.current) {
      return (
        <div className="MajorObjectVersioned__finalizeMessage">
          {capitalize(this.state.objectType)} is {objectStatus}. &nbsp;
          <div
            className="MajorObjectVersioned__finalizeMessageAction"
            onClick={this.objectheaderRef.current.versionAction}
          >
            {action}
          </div>{' '}
          to edit.
        </div>
      )
    }
  }

  renderAdvancedSettings() {
    const majorObject = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const properties = majorObject.object && majorObject.object.properties ? majorObject.object.properties : []
    const isEditable = this.checkEditable()
    //console.log("MajorObjectVersioned:renderAdvancedSettings", majorObject, properties, this.props, this.state);

    return (
      <SettingsDialog
        appState={this.props.appState}
        actions={this.props.actions}
        buttonTitle={'Advanced Settings'}
        isVisible
        isEditable={isEditable ? editableState.EDITING : editableState.BROWSABLE}
        isItems={itemState.VERTICAL}
        sortItems
        maxItems={5}
        settings={properties}
        majorObject={majorObject}
        onClose={() => this.setState({ editingSetting: false })}
        onSave={this.onSettingsChange}
      />
    )
  }

  render() {
    return <div></div>
  }
}
