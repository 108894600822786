import PropTypes from 'prop-types'

import logger from '../../helpers/logger'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SearchInput from '../SearchInput/SearchInput'
import { LoginBlock } from '../LoginBlock/LoginBlock'
import { Profile } from '../Profile/Profile'
import { getUserPhotoUrl } from '../../helpers'
import { AdaptiveImage } from '../AdaptiveImage/AdaptiveImage'
import { fetchDataUrlImmediate } from '../../helpers/api'
import defaultUserPicture from '../../resources/images/face-2x.png'
import { track } from '../../helpers/analytics'

import './HeaderOld.scss'

const menuButtons = [
  {
    image: 'org-blue',
    label: 'Organizations',
    height: 24,
    url: '/organizations'
  },
  { image: 'sys-blue', label: 'Systems', height: 22, url: '/systems' },
  {
    image: 'app-blue',
    label: 'Applications',
    height: 22,
    url: '/applications'
  },
  {
    image: 'pub-blue',
    label: 'Publications',
    height: 25,
    url: '/publications'
  },
  { image: 'sub-blue', label: 'Subscriptions', height: 27, url: '/select' }
]

export class HeaderOld extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profileOpen: false,
      userRole: '',
      roleTip: ''
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.userState) localStorage['unauthorizedCount'] = 0
  }

  componentDidMount() {
    if (sessionStorage['temp_password']) {
      this.profileOpen()
    }
    if (this.props.userState) {
      this.props.userState.onUserRole = this.onUserRole.bind(this)
    }
  }

  profileOpen() {
    this.setState({ profileOpen: true })
  }
F
  profileClose() {
    this.setState({ profileOpen: false })
  }

  onUserRole(role, tip) {
    //logger.info('HeaderOld:onUserRole', role, tip, this.props, this.state)
    if (this.state.userRole !== role) this.setState({ userRole: role, roleTip: tip })
  }

  render() {
    const { userState, hideSearch, noApiCalls } = this.props
    const userPhoto =
      userState && userState.identity
        ? fetchDataUrlImmediate(getUserPhotoUrl(userState.identity.id), this.forceUpdate.bind(this))
        : ''

    const style = {
      backgroundImage: `url('${userPhoto}'), url('${defaultUserPicture}')`
    }

    const invertLogo = window.location.hostname === 'localhost' && !window.localInstallation
    const logoStyle = invertLogo ? { backgroundColor: '#FF5479' } : null

    return (
      <div className="Header__outer">
        {!noApiCalls ? (
          <Profile
            isOpen={this.state.profileOpen}
            userState={this.props.userState}
            actions={this.props.actions}
            onClose={this.profileClose.bind(this)}
          />
        ) : null}
        <div className="HeaderOld">
          <div className="HeaderOld__topRow">
            <div className="row">
              <div className="HeaderOld__menuButtons">
                {this.props.hideTopMenu ||
                  menuButtons.map((button, index) => (
                    <Link key={index} to={button.url} onClick={() => track(button.url, 'topmenu')}>
                      <div className="HeaderOld__menuButton">
                        <div className="HeaderOld__menuButtonLabel">{button.label}</div>
                        <AdaptiveImage
                          src={button.image}
                          style={button.height ? { height: button.height + 'px' } : {}}
                        />
                      </div>
                    </Link>
                  ))}
              </div>

              <div className="col-xs-3 middle-xs HeaderOld__title">
                <Link to="/select">
                  <h1 className="HeaderOld__logo" style={logoStyle}>
                    <AdaptiveImage src={'logo-main'} />
                  </h1>
                  <div className="HeaderOld__logoBetaText">beta</div>
                </Link>
              </div>
              <div className="col-xs-6 middle-xs HeaderOld__user">
                <div className="HeaderOld__userInner">
                  <div className="row">
                    <div className="col">
                      <div className="HeaderOld__userPhoto" style={style}></div>
                    </div>
                    <div className="col">
                      <div className="HeaderOld__userName">
                        {userState.profile ? userState.profile.firstname : ''}{' '}
                        {userState.profile ? userState.profile.lastname : ''}
                      </div>
                      <div className="HeaderOld__userRoleInHeader" title={this.state.roleTip}>
                        {this.state.userRole}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <LoginBlock
                width="col-xs-3 middle-xs"
                userName={userState.profile ? userState.profile.firstname : ''}
                userSurname={userState.profile ? userState.profile.lastname : ''}
                onLogin={this.profileOpen.bind(this)}
              />
            </div>
          </div>
          <div className="HeaderOld__bottomRow">
            <div className="row">
              <div className="col-xs-6 HeaderOld__path"></div>
              <div className="col-xs-6 HeaderOld__search">
                {!hideSearch ? <SearchInput color="dark" actions={this.props.actions} /> : null}
              </div>
            </div>
          </div>
        </div>
        <div className="HeaderOld__after"></div>
      </div>
    )
  }
}

HeaderOld.propTypes = {
  title: PropTypes.string.isRequired,
  userState: PropTypes.object.isRequired,
  hideSearch: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  hideTopMenu: PropTypes.bool,
  noApiCalls: PropTypes.bool
}
