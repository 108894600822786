import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { getObjectListNew, getObjectNewOrJson, getRequestFromPath } from '../../helpers/api'
import { extractParamsFromPath, getDatasetRequestFromReference, getFullUri } from '../../helpers/index'
import { Loader } from '../Loader/Loader'

/**
 * LoadableContent receives object path in "path" property
 * When LoadableContent becomes "visible", it shows Loader and sends network request
 * When the object is received, LoadableContent's children are rendered
 * The loaded object is passed using property defined in "dataPropertyName"
 * with optional transformation by "dataTransform" function;
 * unchanged object is passed into "dataPropertyName2" property
 * dataTransform can return null, and no child object will be rendered
 */
export class LoadableContent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      loaded: false,
      error: false,
      loadedObjectPath: '',
      object: null,
      objectArray: null
    }
  }

  /*
    Send the object request if the object has changed
   */
  load(path) {
    if (path === this.state.loadedObjectPath) return

    // logger.info("LoadableContent::loading path", path);
    this.setState({ loaded: false, loading: true, object: null }, () => {
      // normal dataset
      if (path.indexOf('?') === -1) {
        const request = getDatasetRequestFromReference(path)

        getObjectNewOrJson(request.url(), 'dataset', null).then(
          (ret) => {
            this.setState(
              {
                loaded: true,
                loading: false,
                object: ret,
                objectArray: null,
                loadedObjectPath: path
              },
              this.props.onLoad
            )
          },
          (error) => {
            this.setState({ error: error, loading: false })
          }
        )
      } else {
        // ?Usage=someUsage
        const pathWithoutParams = getRequestFromPath(path.substr(0, path.indexOf('?') - 1))
        const params = extractParamsFromPath(path)
        //console.log("LoadableContent pathWithoutParams ",pathWithoutParams, params);
        getObjectListNew(pathWithoutParams, 'dataset', null, false, params).then(
          (datasets) => {
            //console.log("LoadableContent dataasets", datasets);
            const datasetPaths = datasets.map((ds) => getFullUri(ds))
            //console.log("LoadableContent datasetPaths", datasetPaths);
            const datasetRequests = datasetPaths.map((p) => getRequestFromPath(p))
            //console.log("LoadableContent datasetRequests", datasetRequests);
            Promise.all(datasetRequests.map((dsp) => getObjectNewOrJson(dsp.url(), 'dataset'))).then(
              (fullDatasets) => {
                this.setState(
                  {
                    loaded: true,
                    loading: false,
                    object: null,
                    objectArray: fullDatasets,
                    loadedObjectPath: path
                  },
                  this.props.onLoad
                )
              },
              (error) => {
                this.setState({ error: error, loading: false })
              }
            )
          },
          (error) => {
            this.setState({ error: error, loading: false })
          }
        )
      }
    })
  }

  componentDidMount() {
    if (this.props.visible) this.load(this.props.path)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.path !== this.props.path || (newProps.visible && !this.props.visible)) this.load(newProps.path)
  }

  // default transform function
  noTransform(data) {
    return data
  }

  render() {
    let { children, dataPropertyName, dataTransform, visible, dataPropertyName2 } = this.props

    if (!dataPropertyName) dataPropertyName = 'data'
    if (!dataPropertyName2) dataPropertyName2 = 'rawData'

    if (this.state.error)
      return <div className="LoadableContent LoadableContent__error">{JSON.stringify(this.state.error)}</div>

    let transform = dataTransform ? dataTransform : this.noTransform

    let childObject = null

    if (!visible) return null

    if (!this.state.loaded) {
      return (
        <div className="LoadableContent">
          <Loader />
        </div>
      )
    } else if (this.state.objectArray) {
      if (this.state.objectArray.length === 0) return <div className="LoadableContent">No items found</div>
      else
        return (
          <div className="LoadableContent">
            {this.state.objectArray.map((rawObj, idx) => {
              const transformedObj = transform(rawObj)
              console.log('LoadableCOntent next obhject', rawObj, transformedObj)
              return (
                <div key={idx}>
                  <h2>{rawObj.identity.name}</h2>
                  {React.cloneElement(children, {
                    [dataPropertyName]: transformedObj,
                    [dataPropertyName2]: rawObj
                  })}
                </div>
              )
            })}
          </div>
        )
    } else {
      // object was loaded - transform it and pass to child component
      childObject = transform(this.state.object)
      if (!childObject) return <div className="LoadableContent"></div>
      //console.log("LoadableContent::childOjbect", childObject);
      return (
        <div className="LoadableContent">
          {React.cloneElement(children, {
            [dataPropertyName]: childObject,
            [dataPropertyName2]: this.state.object
          })}
        </div>
      )
    }
  }
}

LoadableContent.propTypes = {
  path: PropTypes.string, // path of object to load
  dataPropertyName: PropTypes.string, // property to put loaded object
  dataTransform: PropTypes.func,
  visible: PropTypes.bool,
  dataPropertyName2: PropTypes.string, // property for raw (non transformed) loaded object
  onLoad: PropTypes.func
}
