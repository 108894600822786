/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'

export const Footer = () => (
  <div>
    <div className="auth_footer" />

    <div className="wrapper wrapper_dark footer">
      <section className="row">
        <div className="footer__social col-xs-3">
          <h2 className="footer__heading">SOCIAL MEDIA</h2>
          <a className="footer__socialLink footer__socialLink_fb" href="https://www.facebook.com/ApdaxCo/"></a>
          <a className="footer__socialLink footer__socialLink_in" href="https://www.linkedin.com/company/apdax"></a>
          <a className="footer__socialLink footer__socialLink_tw" href="https://twitter.com/Apdaxco"></a>
        </div>

        <div className="footer__contact col-xs-3">
          <h2 className="footer__heading">CONTACT</h2>
          <a href="http://apdaxco.com/demo.html" className="footer__el">
            Request a Demo
          </a>
          <span className="footer__el">
            Phone: <a href="tel://+14255051506">+1&nbsp;(425)&nbsp;505&nbsp;1506</a>
            <br />
            E-mail:{' '}
            <a href="mailto:info@apdaxco.com" title="Apdaxco e-mail">
              info@apdaxco.com
            </a>
          </span>
        </div>

        <div className="footer__location col-xs-3">
          <h2 className="footer__heading">LOCATION</h2>
          <span className="footer__el">
            17703 NE 26<sup>th</sup> St,
            <br />
            Redmond, <br />
            WA 98052, United States
          </span>
        </div>

        <div className="footer__links col-xs-3">
          <h2 className="footer__heading">LINKS</h2>
          <a href="http://apdaxco.com/getting_started.html" className="footer__el" target="_blank" rel="noreferrer">
            Getting Started
          </a>
        </div>
      </section>
    </div>
  </div>
)
