/**
 * Created by Sasha Berger on 05.03.20.
 *
 * Component to edit reference to topology inside environment.
 * Component render reference to instances of the topology inside environment.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { deepCopy, editableState } from '../../helpers/index'
import logger from '../../helpers/logger'
import { getElementMetadata } from '../../resources/lib/metadata'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { LayoutInstanceList } from './LayoutInstanceList'
import './VersionedObjectReferenceDialog.scss'

export class VersionedObjectReferenceDialog extends EditorDialogBase {
  getObjectType() {
    return 'objectReference'
  }

  /**
   * Init dialog height
   * @param props - set of initialization properties
   */
  InitHeight(props) {
    // Don't need logic here.
    return 445
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/System/datasets/' + this.props.objectType + 'Reference',
      '/organizations/Apdax/systems/DifHub/applications/System/views/LayoutRefDialog',
      null,
      (view) => {
        // Let's update element to have local state for instances.
        // For it we need to change metadata for view in instance element.
        let currView = deepCopy(view)
        let dataElement = getElementMetadata(currView, 'layouts')
        if (dataElement && dataElement.control && dataElement.control.value) {
          dataElement.control.value['referenceType'] = this.props.objectType
        }
        return currView
      }
    )
    //logger.info("VersionedObjectReferenceDialog:open", this.state, this.props);
  }

  checkInputErrors(objectReference) {
    let inputError = ''
    if (!objectReference.reference) {
      inputError += 'Please select reference!'
    } else if (!objectReference.layoutNames) {
      inputError += 'Please select object layouts!'
    }
    return inputError
  }

  /**
   * Validate value of changed field
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @return true - change approved
   */
  onValidateChange = (element, field, value, reference, option, structure) => {
    //logger.info("VersionedObjectReferenceDialog:onValidateChange", { element, field, value, reference, option, structure }, this.state, this.props);
    if (element === 'layouts' && value && reference && structure) {
      // We initialize instance information for reference mapping in environment
      reference.layoutInstances = (value.layoutNames || []).map((val) => {
        let layout = structure.layouts.find((item) => item.identity.name, val)
        return {
          layoutName: val,
          platform: layout ? layout.platform : '',
          unit: layout ? layout.unit : '',
          properties: []
        }
      })
    }
    logger.info(
      'VersionedObjectReferenceDialog:onValidateChange:RETURN',
      { element, field, value, reference, option, structure },
      this.state,
      this.props
    )
    return reference
  }

  onInstancesSave = (instances, closeDialog, onSent) => {
    let objectReference = deepCopy(this.state.objectReference)
    //logger.info("VersionedObjectReferenceDialog:onInstancesSave", { instances, closeDialog, objectReference }, this.state, this.props);

    objectReference.layoutInstances = instances

    this.Change(objectReference)
    if (onSent) onSent(closeDialog)
  }

  renderEditor = (editState) => {
    let objectReference = this.state.objectReference
    let isInstance = this.props.isInstance

    const data = '.' // Need data here to disable use of array data.
    //logger.info("VersionedObjectReferenceDialog:renderEditor", { editState, objectReference, isInstance }, this.state, this.props);

    return (
      <div
        className="VersionedObjectReferenceDialog"
        onClick={() => this.onCheckDialogHeight('.VersionedObjectReferenceDialog')}
      >
        {this.renderView(editState, (element) => {
          switch (element) {
            default:
              return null
            case 'layouts':
              return isInstance ? this.renderElement(editableState.BROWSABLE, element) : null
            case 'instances': {
              //logger.info("VersionedObjectReferenceDialog:renderEditor:INSTANCES", { element }, this.state, this.props);

              return (
                <div>
                  {this.renderElement(editableState.BROWSABLE, element, null, data)}
                  <div className="PackageDialog_borderGray"></div>
                  <LayoutInstanceList
                    className="LayoutInstanceList"
                    appState={this.props.appState}
                    actions={this.props.actions}
                    isVisible
                    isEditable={editState >= editableState.EDITABLE ? editState : editableState.BROWSABLE}
                    majorObject={this.props.majorObject}
                    type={'instance'}
                    items={objectReference.layoutInstances}
                    onReport={this.onReport}
                    onAdjust={this.onAdjust}
                    onClose={() => this.setState({ activeResource: false })}
                    onSave={this.onInstancesSave}
                  />
                </div>
              )
            }
          }
        })}
      </div>
    )
  }
}

VersionedObjectReferenceDialog.propTypes = {
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  isInstance: PropTypes.boolean, // Mark of editing only layout instance from environment
  majorObject: PropTypes.object.isRequired,
  typeReference: PropTypes.string,
  objectReference: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
