/**
 * Created by kascode on 15.06.17.
 */
import * as React from 'react'
import PropTypes from 'prop-types'

import './List.scss'

/**
 * Component for display of list of items
 * @param {string} title - title if needed
 * @param {array} items - array of elements to display
 * @param {function} onTitleClick - title click handler
 * @param {number} defaultDisplayCount - max number of items to display by default
 * @param {boolean} inline - inline list or not
 * @return {element}
 */
export const List = ({ title, items, onTitleClick, defaultDisplayCount, inline }) => {
  return (
    <div className={'List' + (inline ? ' List_inline' : '')}>
      {title ? (
        <span className={'List__title' + (onTitleClick ? ' List__title_active' : '')} onClick={onTitleClick}>
          {title}
        </span>
      ) : null}
      {items}
    </div>
  )
}

List.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.element),
  onTitleClick: PropTypes.func,
  defaultDisplayCount: PropTypes.number,
  inline: PropTypes.bool
}
