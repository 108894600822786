export const convertWidth = (w, isFullscreen?: boolean, widthBeforeFullscreen?) => {
  if (typeof w !== 'number') {
    //console.log("TypedTable:convertWidth", { w, isFullscreen, widthBeforeFullscreen }, this.state, this.props);
    return w
  } else if (isFullscreen) {
    const p = (w * 100.0) / widthBeforeFullscreen
    return p + '%'
  } else {
    return w + 'px'
  }
}

export const defaultCompare = (a, b) => {
  if (a > b) {
    return 1
  } else if (a < b) {
    return -1
  } else {
    return 0
  }
}
