import React, { Component } from 'react'
import './react-simpletabs.css'

class TabPanel extends Component {
  render() {
    return <div>{this.props.children}</div>
  }
}

export default TabPanel
