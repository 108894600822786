/**
 * Created by mailf on 18.05.2016.
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { deepCopy } from '../../helpers'
import './style.scss'
import { getValueInput, getValueRenderer } from '../../helpers/editByType'

type EditableEntityProps = {
  data: any
  dataType: {
    name: string
    options?: any[]
  }
  className?: string
  dataProps: any
  isEditable: boolean
  inEditMode: boolean
  onEditStart?: () => void
  onEditEnd?: () => void
  onEntityClick?: (name: string, mode?: string) => void // invoked when non-editable entity is clicked
}

export const EditableEntity = ({
  data,
  dataType,
  dataProps,
  isEditable,
  onEditStart,
  inEditMode,
  onEntityClick,
  className,
  //@ts-ignore
  link,
  //@ts-ignore
  viewData,
  //@ts-ignore
  enumData,
  //@ts-ignore
  colIndex
}: EditableEntityProps) => {
  // if type of data is not provided roll back to string type
  if (!dataType) {
    console.error('EditableEntity::formatByType dataType is empty', dataType, data, dataProps)
  }

  if (!dataType.name) dataType.name = 'string'

  const valueRenderer = getValueRenderer(dataType.name)

  // Remove place holder if we not in edit mode.
  let props = deepCopy(dataProps)
  if (!isEditable || !inEditMode) {
    props.placeholder = null
  }

  const renderTransEntity = (data,colIndex) => {
  if (colIndex === 2&&viewData) {
    return <div>
      <span className="StringData"><span className='translationTopicName'>Description</span>: {data ? data.Description : "No data"}</span>
      <span className="StringData"><span className='translationTopicName'>Text</span>: {data ? data.Text : "No data"}</span>
      <span className="StringData"><span className='translationTopicName'>Control</span>: {data ? JSON.stringify(data.Control) : {}}</span>
    </div >
  }
  if (colIndex === 3&&viewData) {
    return <div>
      <span className="StringData"><span className='translationTopicName'>Description</span>: {data && data[0] ? data[0].Description : "No data"}</span>
      <span className="StringData"><span className='translationTopicName'>Text</span>: {data &&data[0] ? data[0].Text : "No data"}</span>
        <span className="StringData"><span className='translationTopicName'>Control</span>: {data &&data[0]? JSON.stringify(data[0].Control) : "No data"}</span>
    </div >
  }
    if (colIndex === 3 && enumData) {
      return <div>
        <span className="StringData"><span className='translationTopicName'>Name</span>: {data[0] ? data[0].Name : "No data"}</span>
      <span className="StringData"><span className='translationTopicName'>Description</span>: {data[0] ? data[0].Description : "No data"}</span>
    </div >
  }
}
  

  // if this entity isn't editable just render formatted data
  if (!isEditable) {
    return (
      <span
        className={'EditableEntity ' + (className || '') + (' EditableEntity_' + dataType.name)}
        onClick={onEntityClick ? () => onEntityClick(data.name) : () => {}}
      >
        {//link to structure in translation modal (bottom btn) @ts-ignore
          link ?
            (<Link to={'/view' + link}>{valueRenderer(data, props)}</Link>) :
            // @ts-ignore
            viewData?
              renderTransEntity(viewData, colIndex) :
              enumData ?
                renderTransEntity(enumData, colIndex) :
            valueRenderer(data, props)
        }
      </span>
    )
  }
  // if entity is editable render edit control or formatted data based on edit state
  else {
    const valueInput = getValueInput(dataType.name)
    //console.log("EditableEntity", dataType);
    return (
      <span
        className={'EditableEntity ' + (className || '') + (' EditableEntity_' + dataType.name)}
        onClick={
          onEntityClick && isEditable
            ? () => {
                onEntityClick(data.name, 'edit')
              }
            : onEditStart
        }
      >
        {inEditMode ? valueInput(data, dataProps, dataType) : valueRenderer(data, props)}
      </span>
    )
  }
}
