import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { KascodeSelect } from '../KascodeSelect/KascodeSelect'

export class KascodeSelectGroup extends Component {
  constructor(props) {
    super(props)
    let activeOptions = {}
    // eslint-disable-next-line array-callback-return
    this.props.childrenProps.map((childrenProp) => {
      activeOptions[childrenProp.fieldName] = childrenProp.activeOptions.map(
        (id) => childrenProp.options.filter((option) => option.id === id)[0]
      )
    })
    this.state = {
      activeOptions: activeOptions
    }
  }

  childValuesChange(index) {
    return (event) => {
      let newActiveOptions = Object.assign({}, this.state.activeOptions)
      newActiveOptions[this.props.childrenProps[index].fieldName] = event.target.value.slice()
      this.setState({ activeOptions: newActiveOptions }, () => {
        if (this.props.onValuesChange)
          this.props.onValuesChange({
            target: { value: Object.assign({}, this.state.activeOptions) }
          })
      })
    }
  }

  childBlur(index) {
    return (event) => {
      let newActiveOptions = Object.assign({}, this.state.activeOptions)
      newActiveOptions[this.props.childrenProps[index].fieldName] = event.target.value.slice()
      this.setState({ activeOptions: newActiveOptions }, () => {
        if (this.props.onBlur)
          this.props.onBlur({
            target: { value: Object.assign({}, this.state.activeOptions) }
          })
      })
    }
  }

  render() {
    return (
      <div>
        {this.props.childrenProps.map((childrenProp, index) => (
          <KascodeSelect
            key={index}
            {...childrenProp}
            onValuesChange={this.childValuesChange(index).bind(this)}
            onBlur={this.childBlur(index).bind(this)}
          />
        ))}
      </div>
    )
  }
}

KascodeSelectGroup.propTypes = {
  childrenProps: PropTypes.array,
  onValuesChange: PropTypes.func,
  onBlur: PropTypes.func
}
