/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu'
import { fieldTypeToString, objectNameFromPathByType, usageIconUris } from '../../helpers'
import { getObjectNew, getRequestFromPath } from '../../helpers/api'
import { downloadSelectorAsPDF } from '../../helpers/pdfDownloader'
import { displayReferenceUri, editableState, nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import { ObjectEditor } from '../DataDialog/ObjectEditor'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { ActivityDialog } from './ActivityDialog'
import './ActivityDialog.scss'
import './TransformationChartDialog.scss'

const types = [
  { id: 1, label: 'boolean', value: { name: 'boolean' } },
  { id: 2, label: 'small', value: { name: 'small' } },
  { id: 3, label: 'short', value: { name: 'short' } },
  { id: 4, label: 'integer', value: { name: 'integer' } },
  { id: 5, label: 'long', value: { name: 'long' } },
  { id: 6, label: 'huge', value: { name: 'huge' } },
  { id: 7, label: 'byte', value: { name: 'byte' } },
  { id: 8, label: 'unsigned short', value: { name: 'unsigned short' } },
  { id: 9, label: 'unsigned integer', value: { name: 'unsigned integer' } },
  { id: 10, label: 'unsigned long', value: { name: 'unsigned long' } },
  { id: 11, label: 'unsigned huge', value: { name: 'unsigned huge' } },
  { id: 12, label: 'float', value: { name: 'float' } },
  { id: 13, label: 'double', value: { name: 'double' } },
  { id: 14, label: 'decimal', value: { name: 'decimal' } },
  { id: 15, label: 'string', value: { name: 'string' } },
  { id: 16, label: 'text', value: { name: 'text' } },
  { id: 17, label: 'date', value: { name: 'date' } },
  { id: 18, label: 'time', value: { name: 'time' } },
  { id: 19, label: 'DateTime', value: { name: 'datetime' } },
  { id: 20, label: 'TimeSpan', value: { name: 'timespan' } },
  { id: 21, label: 'guid', value: { name: 'guid' } },
  { id: 22, label: 'enum', value: { name: 'enum' } },
  { id: 23, label: 'field', value: { name: 'field' } },
  { id: 24, label: 'structure', value: { name: 'structure' } }
]

const typesXML =
  '<datatypes db="mysql"><group color="rgb(238,238,170)">' +
  types.reduce(
    (prev, type) => prev + '<type label="' + type.label + '" quote="" sql="' + type.label + '" length="0" />',
    ''
  ) +
  '</group></datatypes>'

/**
 * Displays Transformation chart for Pipeline with type=Data Flow
 *
 */
export class TransformationChartDialog extends Component {
  constructor(props) {
    super(props)

    let height = this.InitHeight(props)

    this.state = {
      change: null, // Method to report or cancel modification.
      //origin: JSON.stringify(props.activity),

      height: height,
      editMode: false,
      application: null,
      selectedActivity: null,
      selectedArrow: null,
      positions: this.initPositions(this.props.pipeline),
      selectedInput: null,
      selectedOutput: null,
      datasets: {},
      fullScreen: false
    }
  }

  /**
   * context menu click handler
   * @param e     - event
   * @param data  - context menu item data
   */
  handleClick = (e, data) => {
    logger.info('TransformationChart:handleClick', e, data, data[0] === 'c')
    let tt = window.SQL.lastSelectedTable
    //logger.log("tt", tt);
    if (!tt) return

    // color change
    if (data.action === 'color') {
      document.getElementById('colorpicker').value = tt.borderColor
      document.getElementById('colorpicker').oninput = document.getElementById('colorpicker').onchange = () => {
        //logger.log("colorpicker onchange", document.getElementById('colorpicker').value, tt);
        tt.borderColor = document.getElementById('colorpicker').value
        tt.redraw()
        if (typeof window.SQL.onTableMove === 'function') window.SQL.onTableMove(tt)
        document.getElementById('colorpicker').oninput = document.getElementById('colorpicker').onchange = null
      }
      document.getElementById('colorpicker').click()
    }
  }

  /**
   * right-click on non-disabled table shows context menu at correct position
   * @param e
   * @returns {boolean}
   */
  onTableRightClick = (e) => {
    logger.info('TransformationChart:onTableRightClick', e)
    let tt = window.SQL.lastSelectedTable
    if (!tt) return
    if (tt.x < 0) return
    //logger.log("onTableRightClick", e);
    //logger.log('element', document.getElementsByClassName('react-contextmenu-wrapper')[0]);

    const x = e.clientX || (e.touches && e.touches[0].pageX)
    const y = e.clientY || (e.touches && e.touches[0].pageY)

    logger.log('onTableRightClick', this, this.refs)

    let dialog = this.getDialogRef()
    if (dialog) {
      dialog.refs.menu.handleShow(
        Object.assign(
          {},
          {
            detail: { id: dialog.refs.menu.props.id, position: { x: x, y: y } }
          }
        )
      )
    }
    //logger.log("event dispatched");
    e.preventDefault()
    e.stopPropagation()

    return false
  }

  componentDidMount() {
    getObjectNew(getRequestFromPath(this.props.pipeline.object.parent.name)).then((app) => {
      this.setState({ application: app }, () => {
        this.update()
      })
      this.setState({ positions: this.initPositions(this.props.pipeline) })
    })

    window.usageIcons = {}
    Object.keys(usageIconUris).forEach((key) => {
      window.usageIcons[key] = '/images/usage/24/' + usageIconUris[key] + '.png'
    })

    window.sqldesigner = new window.SQL.DesignerConstructor()
    window.SQL.onTableMove = this.onTableMove.bind(this)
    window.SQL.onTableRightClick = this.onTableRightClick.bind(this)
    window.SQL.onTableEnable = () => {}
    window.SQL.onChangeFilter = () => {
      // redraw all tables
      window.sqldesigner.tables.map((table) => table.redraw())
    }
    window.SQL.filterTable = (table) => {
      return true
    }
    window.SQL.chartModeDataFlow = true

    window.SQL.onInputArrowClick = (tableInput, tableOutput, table2, table1, relation) => {
      this.setState({
        selectedInput: {
          tableInput: tableInput,
          tableOutput: tableOutput
        },
        selectedOutput: null,
        selectedActivity: null
      })
      this.deselectArrow()
      this.selectArrow(relation)
    }
    window.SQL.onOutputArrowClick = (tableInput, tableOutput, table2, table1, relation) => {
      this.setState({
        selectedOutput: {
          tableInput: tableInput,
          tableOutput: tableOutput
        },
        selectedInput: null,
        selectedActivity: null
      })
      this.deselectArrow()
      this.selectArrow(relation)
    }
    window.SQL.onAreaClick = () => {
      this.setState({
        selectedInput: null,
        selectedOutput: null,
        selectedActivity: null
      })
    }

    window.SQL.preventTableMove = true
  }

  deselectArrow = () => {
    if (window.SQL.highlightRelation) {
      window.SQL.highlightRelation.dom[0].setAttribute('stroke-width', 2)
      window.SQL.highlightRelation = null
    }
  }

  selectArrow = (relation) => {
    window.SQL.highlightRelation = relation
    window.SQL.highlightRelation.dom[0].setAttribute('stroke-width', 4)
  }

  /**
   * Select activity in the pipeline
   * @param activity - selected activity
   * @param position - new position
   */
  selectActivity = (activity, position) => {
    logger.log('TransformationChartDialog::selectActivity', activity)
    this.deselectArrow()

    if (activity !== this.state.selectedActivity) {
      this.setState({
        selectedActivity: activity,
        selectedInput: null,
        selectedOutput: null
      })

      if (
        (!activity.type || nonCSCompare(activity.type, 'transformation')) &&
        activity.transformation &&
        activity.transformation.reference &&
        activity.transformation.reference.length > 0
      ) {
        getObjectNew(getRequestFromPath(activity.transformation.reference), 'dataset').then((object) =>
          this.setState({ selectedActivityStructure: object })
        )
      } else if (
        activity &&
        nonCSCompare(activity.type, 'operation') &&
        activity.operation &&
        activity.operation.reference &&
        activity.operation.reference.length > 0
      ) {
        getObjectNew(getRequestFromPath(activity.operation.reference), 'interface').then((object) =>
          this.setState({ selectedActivityStructure: object })
        )
      }
    }
  }

  onTableMove = (table) => {
    // Change of position.
    let newPositions = this.state.positions.filter((position) => position.table !== table.path)
    let oldPosition = this.state.positions.find((position) => position.table === table.path)
    let position = {
      table: table.path,
      x: table.x,
      y: table.y,
      color: table.borderColor,
      w: table.newWidth,
      h: table.newHeight
    }

    logger.info('TransformationChart:onTableMove', table, table.selected)

    // New powition deteted.
    if (
      !oldPosition ||
      Math.abs(oldPosition.x - position.x) > 3 ||
      Math.abs(oldPosition.y - position.y) > 3 ||
      Math.abs((oldPosition.w || 0) - position.w) > 3 ||
      Math.abs((oldPosition.h || 0) - position.h) > 3
    ) {
      newPositions.push(position)
      this.setState({ positions: newPositions })

      if (this.state.change) {
        this.state.change(true, '', '', '')
      }
    }

    // Selected new table
    if (table.selected) {
      logger.log('TransformationChart::table selected', table, table.data.title)
      /*
      this.props.pipeline.activities.map(activity => {
        //logger.log("selectedActivity", activity, table.data.title, table);
        if (activity.identity.name === table.data.title) {
          this.selectActivity(activity, position);
        }
      });
      */
      const structure = this.getStructure()
      const cols = structure.cols
      cols.forEach((col) => {
        if (col.text === table.data.title) {
          if (col.type === 'activity') {
            this.selectActivity(col.activity, position)
          } else if (col.type === 'dataset') {
            logger.log('col type dataset', col)
            if (col.input) {
              logger.log('select input', col.input)
              let tableInput = col.text
              let tableOutput = col.arrowsOut.length > 0 ? cols[col.arrowsOut[0].toCol].text : null
              logger.log('tableInput, tableInput', tableInput, tableOutput)
              this.setState({
                selectedInput: {
                  tableInput: tableInput,
                  tableOutput: tableOutput
                },
                selectedOutput: null,
                selectedActivity: null
              })
            } else if (col.output) {
              logger.log('select output', col.output)
              let tableInput = col.arrowsIn.length > 0 ? cols[col.arrowsIn[0].fromCol].text : null
              let tableOutput = col.text
              logger.log('tableInput, tableInput', tableInput, tableOutput)
              this.setState({
                selectedOutput: {
                  tableInput: tableInput,
                  tableOutput: tableOutput
                },
                selectedInput: null,
                selectedActivity: null
              })
            }
          }
        }
      })
    } else {
      this.setState({
        selectedInput: null,
        selectedOutput: null,
        selectedActivity: null
      })
    }
  }

  initPositions = (pipeline) => {
    let newPositions = (pipeline.object.elements || []).map((element) => {
      logger.log('initPositions', element)

      return {
        table: element.identity.name,
        x: element.frame.positions.left,
        y: element.frame.positions.top,
        w: element.frame.positions.width,
        h: element.frame.positions.height,
        color:
          element.style && element.style.border && element.style.border && element.style.border.left
            ? element.style.border.left.color
            : false
      }
    })
    logger.log('initPositions', newPositions)
    return newPositions
  }

  /**
   * Init dialog height
   * @param user - selected user
   */
  InitHeight = (props) => {
    props = props || this.props

    let height = 600

    if (height < window.innerHeight - 248) height = window.innerHeight - 248

    return height
  }

  /**
   * close dialog
   */
  onClose() {
    this.setState({
      editMode: false
    })
    if (this.props.onClose) this.props.onClose()
  }

  /**
   * Adjust size of scroll area in dialog
   * @param width - increase or reduction in control width.
   * @param height - increase or reduction in control height.
   * @return
   */
  onAdjust = (width, height) => {
    this.setState((prevState) => {
      return { height: prevState.height + height }
    })
  }

  /**
   * Set edit mode on
   */
  startEdit = (change) => {
    this.setState({
      change: change
    })
    window.SQL.preventTableMove = false
  }

  /**
   * Execute activity when message send to service
   * @param {boolean} close - flag to close dialog
   * @param {*} error - return from service if any.
   * @return
   */
  onSent = (close, error) => {
    // Report complete of save with error or not
    if (this.state.change) {
      this.state.change(error)
    }

    if (close && !error) {
      setTimeout(() => {
        this.onClose()
      }, 1000)
    }
  }

  /**
   * Cancel changes
   * @param {boolean} [closeDialog] Do we need to close dialog
   */
  cancelEdit = (closeDialog) => {
    // Cancel save mode
    if (this.state.change) this.state.change(false)

    this.setState({
      change: null,
      inputs: this.props.inputs || []
    })

    window.SQL.preventTableMove = true
  }

  /**
   * show or hide main window
   */
  toggleEditMode() {
    this.setState({
      editMode: !this.state.editMode,
      isEdited: false
    })
  }

  savePipeline = (closeDialog) => {
    if (this.state.change) {
      this.state.change(true, 'Data send to the service...', '', '')
    }

    if (this.props.onSave) {
      this.props.onSave(this.state.positions, closeDialog, this.onSent.bind(this))
      return true // not to do anything here.
    }
  }

  saveActivity = (activity, closeDialog, onSent) => {
    logger.log('TransformationChartDialog::saveActivity', activity)

    const newPipeline = Object.assign({}, { identity: this.props.pipeline.identity, activities: [activity] })

    this.props.onSavePipeline(newPipeline, closeDialog, onSent)
    return true
  }

  /**
   * initiate activity dialog
   * @param
   */
  activityEdit = () => {
    this.setState({ activityEdit: true })
  }

  /**
   * Check if output from outputActivity matches input (check if arrow is needed between input and output)
   * @param input
   * @param output
   * @param outputActivity
   * @returns {boolean}
   */
  inputForOutput = (input, output, outputActivity) => {
    if (input.inputMode === 'Dataset') {
      return input.component.reference === output.component.reference
    } else if (input.inputMode === 'Output') {
      //logger.log("inputForOutput", input, output, outputActivity);
      return (
        outputActivity.identity.name === input.component.reference &&
        output.identity.name === input.component.components[0]
      )
    }
  }

  /**
   * Checks if activity has at least one input that matches the output parameter
   * @param output
   * @param activity
   * @param outputActivity
   * @returns {*}
   */
  outputForActivity = (output, activity, outputActivity) => {
    return (activity.inputs || []).reduce(
      (found, input) => (this.inputForOutput(input, output, outputActivity) ? input : found),
      false
    )
  }

  /**
   * Checks if activity has at least one output that matches the input parameter
   * @param input
   * @param activity
   * @returns {*}
   */
  inputForActivity = (input, activity) => {
    return (activity.outputs || []).reduce(
      (found, output) => (this.inputForOutput(input, output, activity) ? output : found),
      false
    )
  }

  datasetDisplayName = (reference, subscription) => {
    if (!subscription) return objectNameFromPathByType(reference, 'datasets')
    else
      return (
        objectNameFromPathByType(subscription, 'subscriptions') + '/' + objectNameFromPathByType(reference, 'datasets')
      )
  }

  getStructure() {
    const application = this.state.application
    const pipeline = this.props.pipeline

    if (!pipeline || !application) return null

    const LAST_COL = -1

    //logger.log("renderStructure", pipeline, application);

    // structure is full description of displayed items and arrows between them
    let structure = {
      columns: [application.identity.name],
      arrows: []
    }

    //logger.log("pipeline.type", pipeline.type);

    // Activities of pipeline are sorted from input to output, so that first activities provide data to next activities
    let activities = pipeline.activities || []
    //logger.log("activities", activities);
    let used = activities.map((a) => 0)
    let sorted = []

    let cols = []

    const dfs = (activity, index) => {
      used[index] = true
      ;(activity.outputs || []).forEach((output) => {
        activities.forEach((nextActivity, nextIndex) => {
          if (!used[nextIndex] && this.outputForActivity(output, nextActivity, activity)) dfs(nextActivity, nextIndex)
        })
      })
      sorted.push(activity)
    }

    activities.forEach((activity, index) => {
      if (!used[index]) dfs(activity, index)
    })

    sorted = sorted.reverse()
    //logger.log("sorted", sorted);

    // arrows are added to connect activities that use each other's output data
    sorted.forEach((activity, activityIndex) => {
      structure.columns.push(
        <span>
          Activity {activity.identity.name}
          <br />
          Transformation:{' '}
          {activity.transformation && activity.transformation.identity ? activity.transformation.identity.name : 'none'}
        </span>
      )
      // arrows from App to activity
      ;(activity.inputs || []).forEach((input) => {
        let inputFound = false
        let output = false
        sorted.forEach((nextActivity, nextIndex) => {
          output = this.inputForActivity(input, nextActivity)
          if (output) {
            inputFound = true
          }
        })
        if (!inputFound) {
          //logger.log("Add arrow to new dataset", cols.length, 'activity', activity.identity.name, 'dataset', this.datasetDisplayName(input.component.reference, input.component.subscription));
          cols.push({
            index: cols.length,
            name: this.datasetDisplayName(input.component.reference, input.component.subscription),
            text:
              input.identity.name +
              ' [' +
              this.datasetDisplayName(input.component.reference, input.component.subscription) +
              ']',
            type: 'dataset',
            input: input,
            arrowsIn: [],
            arrowsOut: [],
            level: 0,
            indexOnLevel: cols.length,
            reference: input.component.reference,
            dataset: this.state.datasets[input.component.reference]
              ? this.state.datasets[input.component.reference]
              : [],
            usage:
              this.state.datasets[input.component.reference] && this.state.datasets[input.component.reference].object
                ? this.state.datasets[input.component.reference].object.usage
                : ''
          })
          let datasetColIndex = cols.length - 1

          structure.arrows.push({
            toCol: 'A' + activityIndex,
            fromCol: datasetColIndex,
            input: input,
            text: this.datasetDisplayName(input.component.reference, input.component.subscription)
          })
        }
        // }
      })

      // arrows between activities and from activity  to App
      ;(activity.outputs || []).forEach((output) => {
        let inputFound = false
        sorted.forEach((nextActivity, nextIndex) => {
          let input = this.outputForActivity(output, nextActivity, activity)
          if (input) {
            inputFound = true
            //let nextColIndex = nextIndex + 1;
            let nextColIndex = 'A' + nextIndex
            //logger.log("Add arrow to activity", nextColIndex, "A"+activityIndex, 'activity', activity.identity.name, nextActivity.identity.name);
            structure.arrows.push({
              toCol: nextColIndex,
              fromCol: 'A' + activityIndex,
              output: output,
              input: input,
              text: this.datasetDisplayName(output.component.reference, output.component.subscription)
            })
          }
        })
        if (!inputFound) {
          /*
          structure.arrows.push({
            toCol: LAST_COL,
            fromCol: cols.length,
            text: objectNameFromPathByType(output.component.reference, 'datasets')
          });
          */
          //logger.log("Add arrow to new dataset", cols.length, 'activity', activity.identity.name, 'dataset', this.datasetDisplayName(output.component.reference, output.component.subscription));
          cols.push({
            index: cols.length,
            name: this.datasetDisplayName(output.component.reference, output.component.subscription),
            text:
              output.identity.name +
              ' [' +
              this.datasetDisplayName(output.component.reference, output.component.subscription) +
              ']',
            type: 'dataset',
            output: output,
            arrowsIn: [],
            arrowsOut: [],
            level: 0,
            indexOnLevel: cols.length,
            reference: output.component.reference,
            dataset: this.state.datasets[output.component.reference]
              ? this.state.datasets[output.component.reference]
              : [],
            usage:
              this.state.datasets[output.component.reference] && this.state.datasets[output.component.reference].object
                ? this.state.datasets[output.component.reference].object.usage
                : ''
          })
          let datasetColIndex = cols.length - 1

          structure.arrows.push({
            fromCol: 'A' + activityIndex,
            toCol: datasetColIndex,
            output: output,
            text: this.datasetDisplayName(output.component.reference, output.component.subscription)
          })
        }
      })

      let usage = ''
      let picture = ''
      if (activity.transformation && activity.transformation.identity) {
        if (
          this.state.datasets[activity.transformation.reference] &&
          this.state.datasets[activity.transformation.reference].object
        ) {
          usage = this.state.datasets[activity.transformation.reference].object.usage
          picture = this.state.datasets[activity.transformation.reference].object.picture
        } else {
          usage = 'Transform'
        }
      }

      cols.push({
        index: cols.length,
        //text: <span>{activity.identity.name} {(activity.transformation ? (<span><br />Transformation: {activity.transformation.identity.name}</span>) : "")}</span>,
        text: activity.identity.name,
        description:
          activity.transformation && activity.transformation.identity
            ? 'Transformation: ' + activity.transformation.identity.name
            : '',
        usage: usage,
        picture: picture,
        name: activity.identity.name,
        type: 'activity',
        activity: activity,
        level: 0,
        arrowsIn: [],
        arrowsOut: []
      })
    })

    structure.arrows.forEach((arrow) => {
      if (arrow.toCol === LAST_COL) arrow.toCol = cols.length
      if (arrow.toCol.toString().substr(0, 1) === 'A') {
        let findToCol = -1
        let activityIndex = arrow.toCol.replace('A', '')
        cols.forEach((col) => {
          if (col.name === activities[activityIndex].identity.name) findToCol = col.index
        })
        arrow.toCol = findToCol
      }
      if (arrow.fromCol.toString().substr(0, 1) === 'A') {
        let findToCol = -1
        let activityIndex = arrow.fromCol.replace('A', '')
        cols.forEach((col) => {
          if (col.name === activities[activityIndex].identity.name) findToCol = col.index
        })
        arrow.fromCol = findToCol
      }
    })
    /*
    structure.columns.push('Application ' + application.identity.name + '  ');
    cols.push({
      index: cols.length,
      text: 'Application ' + application.identity.name + '  ',
      name: application.identity.name + '  ',
      type: 'application',
      level: 0,
      arrowsIn: [],
      arrowsOut: []
    });
    */

    //logger.log("cols", cols);

    structure.arrows.forEach((arrow, index) => {
      //logger.log("arrow,index", arrow, index);
      cols[arrow.fromCol].arrowsOut.push(arrow)
      cols[arrow.toCol].arrowsIn.push(arrow)
    })

    let maxLevel = 0

    cols.forEach((col) => {
      let maxIncomingLevel = -1
      col.arrowsIn.forEach((arrow) => {
        let inCol = cols[arrow.fromCol]
        //logger.log("col", col, "arow", arrow, "inCol", inCol);
        maxIncomingLevel = Math.max(maxIncomingLevel, inCol.level)
      })
      col.level = maxIncomingLevel + 1
      maxLevel = Math.max(maxLevel, col.level)
    })

    let levels = []
    for (let i = 0; i <= maxLevel; i++) levels.push(i)

    let maxIndexOnLevel = 0

    cols.forEach((col, index) => {
      //let countOnLevel = 0;
      //cols.slice(0, index - 1).map(loopCol => loopCol.level === col.level ? countOnLevel++ : 0);
      //logger.log("countOnLevel", col, cols.slice(0, index));
      let countOnLevel = cols
        .slice(0, index)
        .reduce((count, loopCol) => (loopCol.level === col.level ? count + 1 : count), 0)
      col.indexOnLevel = countOnLevel
      maxIndexOnLevel = Math.max(maxIndexOnLevel, col.indexOnLevel)
    })

    structure.arrows.forEach((arrow) => {
      arrow.fromLevel = cols.filter((col) => col.index === arrow.fromCol)[0].level
      arrow.toLevel = cols.filter((col) => col.index === arrow.toCol)[0].level
    })

    const W = 260 // Horizontal distance
    const Ws = 230 // Item width
    const H = 250 // Vertical distance
    const Hs = 50
    //logger.log("structure.arrows", structure.arrows);
    //logger.log("cols", cols);

    cols.forEach((col, index) => {
      let position = this.state.positions.reduce((found, cur) => (cur.table === col.text ? cur : found), null)
      if (position) {
        col.x = position.x
        col.y = position.y
        col.color = position.color
        col.w = position.w || Ws
        col.h = position.h || Hs
      } else {
        col.x = col.indexOnLevel * W
        col.y = col.level * H
        col.w = Ws
        col.h = Hs
      }

      col.displayName = col.text
      col.usage = col.usage || ''
      col.picture = col.picture || ''
      col.path = col.text
    })

    structure.cols = cols
    return structure
  }

  // we have to pass it as string to child component because it will be xml-coded
  renderType(fld, ds) {
    return JSON.stringify({
      displayType: fieldTypeToString(fld, ds),
      label: fld.type,
      datasetReference: fld.subscription || fld.reference
    })
    /*
     let elem = formatByType['typeReference']({displayType: fieldTypeToString(fld, ds), label: fld.type, datasetReference: fld.subscription || fld.reference});
     logger.log(elem);
     if (typeof(elem) === "string")
     return elem;
     let div = document.createElement('div');
     logger.log(div);
     ReactDOM.render(elem, div);
     return div.innerHTML;
     */
  }

  updateDisplay() {
    const borderColorList = {
      activity: '#6CD2DC',
      application: '#007DC5',
      subscription: '#FFC107'
      /*
       '#4CAF6D',
       '#B63455',
       '#8F70CB',
       '#005588',
       '#04C1B4',
       '#9BE620',
       '#FF9717',
       '#BE569F',
       '#565CCD' */
    }
    const structure = this.getStructure()

    logger.log('updateDisplay', structure)

    let xml = structure.cols.map((col, index) => {
      //let borderColor = borderColorList[index % borderColorList.length];
      let borderColor = borderColorList[col.type]
      if (!borderColor) borderColor = '#565CCD'
      if (col.color) borderColor = col.color

      let relations = col.arrowsOut.map((arrow) => {
        let relation =
          '<relation table="' +
          structure.cols[arrow.toCol].displayName +
          '" row="' +
          'row' +
          '" type="constraint" cardinality="' +
          'One-To-Many' +
          '" />' +
          '\n'
        return relation
      })
      let arrow_xml =
        '<row name="' +
        'row' +
        '" key="' +
        '' +
        '"><datatype>' +
        JSON.stringify({ displayType: 'boolean', label: 'boolean' }) +
        '</datatype><count>' +
        0 +
        '</count><default>' +
        (col.description || '') +
        '</default>' +
        relations.join('') +
        '</row>' +
        '\n'

      //logger.log("col", col, "dataset", col.dataset);
      if (col.dataset && col.dataset.structure && col.dataset.structure.fields) {
        //logger.log("fields", col.dataset.structure.fields);
        col.h = col.dataset.structure.fields.length * 25 + 50
        col.dataset.structure.fields.slice(0, 100).forEach((fld) => {
          let typeName = this.renderType(fld, col.dataset)
          let count = fld.count

          arrow_xml +=
            '<row name="' +
            fld.identity.name +
            '" key="' +
            '"><datatype>' +
            typeName +
            '</datatype><count>' +
            count +
            '</count><default>' +
            fld.value +
            '</default>' +
            '</row>'
        })
        if (col.dataset.structure.fields.length > 100) {
          //arrow_xml += '<row name="Showing first 100 fields of ' + col.dataset.structure.fields.length + '"><datatype>boolean</datatype>datatype><count>0</count></row>';
          arrow_xml += '<comment>Showing first 100 fields of ' + col.dataset.structure.fields.length + '</comment>'
        }
      }
      //logger.log('arrow_xml', arrow_xml);

      return (
        '<table x="' +
        col.x +
        '" y="' +
        col.y +
        '" ' +
        'w="' +
        col.w +
        '" ' +
        'h="' +
        col.h +
        '" ' +
        'borderColor="' +
        borderColor +
        '" ' +
        'name="' +
        col.displayName +
        '" ' +
        'usage="' +
        col.usage +
        '" ' +
        'path="' +
        col.path +
        '"> ' +
        arrow_xml +
        '</table>' +
        '\n'
      )
    })

    // merge xml records and output them into component
    let xmlString = '<?xml version="1.0" encoding="utf-8" ?><sql>' + typesXML + xml.join('') + '</sql>'
    window.savedChartXmlString = xmlString
    logger.log('xmlString', xmlString)

    document.getElementById('textarea').value = xmlString
    document.getElementById('clientload').click()
  }

  renderStructureNew() {}

  loadDatasets = (done) => {
    const structure = this.getStructure()
    logger.log(
      'loadDatasets()',
      structure.cols.filter((col) => col.type === 'dataset')
    )
    const datasetsReferences = structure.cols
      .filter((col) => col.type === 'dataset')
      .map((dataset) => dataset.reference)
    logger.log('loadDatasets re', datasetsReferences)

    const transfomationReferences = this.props.pipeline.activities
      .map((activity) => (activity.transformation ? activity.transformation.reference : null))
      .filter((ref) => ref)

    logger.log('load transformations', transfomationReferences)

    const promises = datasetsReferences
      .concat(transfomationReferences)
      .map((reference) => getObjectNew(getRequestFromPath(reference), 'dataset'))
    Promise.all(promises).then((datasets) => {
      logger.log('loadDatasets', datasets)

      let obj = {}
      datasets.forEach((dataset, index) => {
        const reference = datasetsReferences[index]
        obj[reference] = dataset
      })
      logger.log('obj', obj)
      this.setState({ datasets: obj }, () => {
        logger.log('loaded datasets')
        done()
      })
    })
  }

  update() {
    this.loadDatasets(this.updateDisplay.bind(this))
  }

  downloadPDF = () => {
    const area =
      document.querySelector('.EditorDialog_pipeline_chart .scrollarea-content ') ||
      document.querySelector('.EditorDialog_pipeline_chart_fullscreen .scrollarea-content ')
    downloadSelectorAsPDF(area, this.props.pipeline.identity.name + ' Chart', 'div.table')
  }

  toggleFullScreen = () => {
    this.setState({ fullScreen: !this.state.fullScreen })
  }

  renderEditor = (editState) => {
    return (
      <div className="PipelineEditor">
        <div className="EditorDialog_pipeline_chart__additionalButtons">
          <div className="EditorDialog_pipeline_chart__headerControlAdditional" onClick={this.toggleFullScreen}>
            <span className={!this.state.fullScreen ? 'Button_FullScreen' : 'Button_Window'} />
            {this.state.fullScreen ? 'Window mode' : 'Full screen'}
          </div>
          <div className="EditorDialog_pipeline_chart__headerControlPdf">
            <a className="" onClick={this.downloadPDF}>
              <span>{'{PDF}'}</span>
            </a>
          </div>
        </div>

        {this.renderStructureNew()}
        {this.renderArea(editState >= editableState.EDITING)}
        <input type="color" id="colorpicker" style={{ display: 'none' }} value="" />

        <ContextMenuTrigger id="some_unique_identifier">
          <div className="well" />
        </ContextMenuTrigger>

        <ContextMenu id="some_unique_identifier" ref="menu">
          <MenuItem data={{ action: 'color' }} onClick={this.handleClick}>
            Change color
          </MenuItem>
        </ContextMenu>
      </div>
    )
  }

  renderFooter = (editState) => {
    // Render selected activity
    let pipeline = this.props.pipeline
    logger.info('TransformationChart:renderFooter', editState, this.state, this.props)

    if (this.state.selectedActivity) {
      let activity = this.state.selectedActivity

      return (
        <div className="TransformationChart__footer">
          {this.state.activityEdit ? (
            <ActivityDialog
              appState={this.props.appState}
              actions={this.props.actions}
              isEditable={this.state.activityEdit && editState >= editableState.EDITING ? 2 : 1}
              isVisible={this.state.activityEdit}
              onSave={this.saveActivity}
              onClose={() => this.setState({ activityEdit: false })}
              parentObjectType={'pipeline'}
              childObjectType={'activity'}
              activity={activity}
              majorObject={pipeline}
            />
          ) : (
            <div className="ActivityDialog__editBlock clearfix">
              <button
                className="btn btn_blue ActivityDialog__editActivityButton"
                onClick={this.activityEdit.bind(this)}
              >
                {editState >= editableState.EDITING ? 'Edit Activity' : 'View Activity'}
              </button>
            </div>
          )}

          <div className="ActivityDialog__block clearfix">
            <label className="ActivityDialog__label">Activity name:</label>
            <div className="value_right">
              <EditableEntity
                data={activity && activity.identity && activity.identity.name ? activity.identity.name : ''}
                dataType={{ name: 'string' }}
                dataProps={{ placeholder: '', onChange: () => {} }}
                isEditable={false}
              />
            </div>
          </div>

          <div className="ActivityDialog__block_description clearfix">
            <label className="ActivityDialog__label">Description:</label>
            <div className="value_right">
              <EditableEntity
                data={
                  activity && activity.identity && activity.identity.description ? activity.identity.description : ''
                }
                dataType={{ name: 'text_updatable' }}
                dataProps={{
                  placeholder: '',
                  onChange: () => {},
                  maxLineCount: 3
                }}
                isEditable={false}
              />
            </div>
          </div>

          <div className="ActivityDialog__block_text clearfix">
            <label className="ActivityDialog__label">Transform:</label>
            <div className="value_right">
              {activity.transformation && activity.transformation.identity ? (
                <ObjectEditor
                  header={activity.transformation.identity.name}
                  noSchema
                  excludeRoot
                  notStructureEditor
                  isVisible
                  isEditable={editState > editableState.EDITABLE ? editState : editableState.BROWSABLE}
                  majorObject={this.props.majorObject}
                  dataset={this.state.selectedActivityStructure}
                  data={activity.transformation.value}
                  onChange={() => {}}
                />
              ) : null}
            </div>
          </div>
        </div>
      )
    }

    if (this.state.selectedInput) {
      const structure = this.getStructure()
      const tableNames = structure.cols.map((col) => col.text)
      const fromIndex = tableNames.indexOf(this.state.selectedInput.tableInput)
      const toIndex = tableNames.indexOf(this.state.selectedInput.tableOutput)
      //logger.log("render selectedInput", tableNames, this.state.selectedInput, fromIndex, toIndex);
      const arrow = structure.arrows.find((arrow) => arrow.fromCol === fromIndex && arrow.toCol === toIndex)
      //logger.log("arrow", arrow, structure.arrows);

      if (!arrow) return null

      let input = arrow.input
      let name = displayReferenceUri(
        input && input.component ? input.component.reference : '',
        input && input.component ? input.component.subscription : ''
      )
      let layoutNames = input && input.component && input.component.layouts ? input.component.layouts : []
      logger.info('TransformationChart:renderFooter:INPUT', input, structure, tableNames, fromIndex, toIndex, arrow)

      return !input ? null : (
        <div className="TransformationChart__footer">
          <div className="TransformationChart__block clearfix">
            <label className="TransformationChart__label">Input name:</label>
            <div className="value_right">
              <EditableEntity
                data={input && input.identity && input.identity.name ? input.identity.name : ''}
                dataType={{ name: 'string' }}
                dataProps={{ placeholder: '', onChange: () => {} }}
                isEditable={false}
              />
            </div>
          </div>

          <div className="TransformationChart__block clearfix">
            <label className="TransformationChart__label">Description:</label>
            <div className="value_right">
              <EditableEntity
                data={input && input.identity && input.identity.description ? input.identity.description : ''}
                dataType={{ name: 'text_updatable' }}
                dataProps={{
                  placeholder: '',
                  onChange: () => {},
                  maxLineCount: 3
                }}
                isEditable={false}
              />
            </div>
          </div>

          <div className="TransformationChart__block clearfix">
            <label className="TransformationChart__label">Mode:</label>
            <div className="value_right">
              <EditableEntity
                data={input.inputMode}
                dataType={{ name: 'enum', options: [] }}
                dataProps={{ placeholder: '', onChange: () => {} }}
                isEditable={false}
              />
            </div>
          </div>

          {nonCSCompare(input.inputMode, 'dataset') ? (
            <div>
              <div className="TransformationChart__block clearfix">
                <label className="TransformationChart__label">Dataset:</label>
                <div className="value_right">
                  <EditableEntity
                    data={name}
                    dataType={{ name: 'enum', options: [] }}
                    dataProps={{ placeholder: '', onChange: () => {} }}
                    isEditable={false}
                  />
                </div>
              </div>

              <div className="TransformationChart__block clearfix">
                <label className="TransformationChart__label">Layout:</label>
                <div className="value_right">
                  <EditableEntity
                    data={layoutNames && layoutNames.length > 0 ? layoutNames.toString() : ' '}
                    dataType={{ name: 'enum', options: [] }}
                    dataProps={{ placeholder: '', onChange: () => {} }}
                    isEditable={false}
                  />
                </div>
              </div>

              <div className="TransformationChart__block clearfix">
                <label className="TransformationChart__label">Filter:</label>
                <div className="value_right_filter">
                  <EditableEntity
                    data={input && input.filter ? input.filter : ''}
                    dataType={{ name: 'text_updatable' }}
                    dataProps={{
                      placeholder: '',
                      onChange: () => {},
                      maxLineCount: 3
                    }}
                    isEditable={false}
                  />
                </div>
              </div>

              <div className="TransformationChart__block clearfix">
                <label className="TransformationChart__label">Start:</label>
                <div className="value_right">
                  <EditableEntity
                    data={input && input.start ? input.start : ''}
                    dataType={{ name: 'string' }}
                    dataProps={{ placeholder: '', onChange: () => {} }}
                    isEditable={false}
                  />
                </div>
              </div>
              <div className="TransformationChart__block clearfix">
                <label className="TransformationChart__label">End:</label>
                <div className="value_right">
                  <EditableEntity
                    data={input && input.end ? input.end : ''}
                    dataType={{ name: 'string' }}
                    dataProps={{ placeholder: '', onChange: () => {} }}
                    isEditable={false}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {nonCSCompare(input.inputMode, 'output') && input.component && input.component.reference ? (
            <div className="TransformationChart__block clearfix">
              <label className="TransformationChart__label">Output:</label>
              <div className="value_right">
                <EditableEntity
                  data={input.component.components ? input.component.components[0] : ''}
                  dataType={{ name: 'enum', options: [] }}
                  dataProps={{ placeholder: '', onChange: () => {} }}
                  isEditable={false}
                />
              </div>
            </div>
          ) : null}
        </div>
      )
    }

    if (this.state.selectedOutput) {
      const structure = this.getStructure()
      const tableNames = structure.cols.map((col) => col.text)
      const fromIndex = tableNames.indexOf(this.state.selectedOutput.tableInput)
      const toIndex = tableNames.indexOf(this.state.selectedOutput.tableOutput)
      const arrow = structure.arrows.find((arrow) => arrow.fromCol === fromIndex && arrow.toCol === toIndex)

      if (!arrow) return null

      let output = arrow.output
      let name = displayReferenceUri(
        output && output.component ? output.component.reference : '',
        output && output.component ? output.component.subscription : ''
      )
      let layoutNames = output && output.component && output.component.layouts ? output.component.layouts : []
      logger.info('TransformationChart:renderFooter:OUTPUT', output, structure, tableNames, fromIndex, toIndex, arrow)

      return !output ? null : (
        <div className="TransformationChart__footer">
          <div className="TransformationChart__block clearfix">
            <label className="TransformationChart__label">Output name:</label>
            <div className="value_right">
              <EditableEntity
                data={output && output.identity && output.identity.name ? output.identity.name : ''}
                dataType={{ name: 'string' }}
                dataProps={{ placeholder: '', onChange: () => {} }}
                isEditable={false}
              />
            </div>
          </div>

          <div className="TransformationChart__block clearfix">
            <label className="TransformationChart__label">Description:</label>
            <div className="value_right">
              <EditableEntity
                data={output && output.identity && output.identity.description ? output.identity.description : ''}
                dataType={{ name: 'text_updatable' }}
                dataProps={{
                  placeholder: '',
                  onChange: () => {},
                  maxLineCount: 3
                }}
                isEditable={false}
              />
            </div>
          </div>

          <div className="TransformationChart__block clearfix">
            <label className="TransformationChart__label">Mode:</label>
            <div className="value_right">
              <EditableEntity
                data={output.outputMode}
                dataType={{ name: 'enum', options: [] }}
                dataProps={{ placeholder: '', onChange: () => {} }}
                isEditable={false}
              />
            </div>
          </div>

          {nonCSCompare(output.outputMode, 'dataset') ? (
            <div>
              <div className="TransformationChart__block clearfix">
                <label className="TransformationChart__label">Dataset:</label>
                <div className="value_right">
                  <EditableEntity
                    data={name}
                    dataType={{ name: 'enum', options: [] }}
                    dataProps={{ placeholder: '', onChange: () => {} }}
                    isEditable={false}
                  />
                </div>
              </div>

              <div className="TransformationChart__block clearfix">
                <label className="TransformationChart__label">Layout:</label>
                <div className="value_right">
                  <EditableEntity
                    data={layoutNames && layoutNames.length > 0 ? layoutNames.toString() : ' '}
                    dataType={{ name: 'enum', options: [] }}
                    dataProps={{ placeholder: '', onChange: () => {} }}
                    isEditable={false}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )
    }

    return null
  }

  render() {
    return (
      <EditorDialog
        level="0"
        hideFullscreenButton
        ref="dialog"
        objectType={this.state.fullScreen ? 'pipeline_chart_fullscreen' : 'pipeline_chart'}
        modalTitle={'Pipeline diagram: ' + (this.props.pipeline ? this.props.pipeline.identity.name : '')}
        confirmText={this.props.pipeline ? 'Update' : 'Create'}
        editContent={this.renderEditor}
        footerContent={this.renderFooter}
        editHeight={this.state.height}
        isEditable={this.props.isEditable}
        isVisible
        onClose={this.onClose.bind(this)}
        onSave={this.savePipeline}
        onEdit={this.startEdit}
        onCancel={this.cancelEdit}
      />
    )
  }

  /**
   * Render HTML structure required by SQLWebDesigner library
   * @returns {XML}
   */
  renderArea(isEditing) {
    //{!isEditing ? <div id="SQLDesigner__areaBlocker"></div> : null}
    return (
      <div className="SQLDesigner__root">
        <div id="area" />
        <div id="controls">
          <div id="bar">
            <div id="toggle" />
            <input type="button" id="saveload" />

            <hr />

            <input type="button" id="addtable" />
            <input type="button" id="edittable" />
            <input type="button" id="tablekeys" />
            <input type="button" id="removetable" />
            <input type="button" id="aligntables" />
            <input type="button" id="cleartables" />

            <hr />

            <input type="button" id="addrow" />
            <input type="button" id="editrow" />
            <input type="button" id="uprow" className="small" />
            <input type="button" id="downrow" className="small" />
            <input type="button" id="foreigncreate" />
            <input type="button" id="foreignconnect" />
            <input type="button" id="foreigndisconnect" />
            <input type="button" id="removerow" />

            <hr />

            <input type="button" id="options" />
            <a href="https://github.com/ondras/wwwsqldesigner/wiki" target="_blank" rel="noreferrer">
              <input type="button" id="docs" value="" />
            </a>
          </div>

          <div id="rubberband" />

          <div id="minimap" />

          <div id="background" />

          <div id="window">
            <div id="windowtitle">
              <img id="throbber" src="images/throbber.gif" alt="" title="" />
            </div>
            <div id="windowcontent" />
            <input type="button" id="windowok" />
            <input type="button" id="windowcancel" />
          </div>
        </div>

        <div id="opts">
          <table>
            <tbody>
              <tr>
                <td>
                  * <label id="language" htmlFor="optionlocale" />
                </td>
                <td>
                  <select id="optionlocale">
                    <option />
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  * <label id="db" htmlFor="optiondb" />
                </td>
                <td>
                  <select id="optiondb">
                    <option />
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label id="snap" htmlFor="optionsnap" />
                </td>
                <td>
                  <input type="text" size="4" id="optionsnap" />
                  <span className="small" id="optionsnapnotice" />
                </td>
              </tr>
              <tr>
                <td>
                  <label id="pattern" htmlFor="optionpattern" />
                </td>
                <td>
                  <input type="text" size="6" id="optionpattern" />
                  <span className="small" id="optionpatternnotice" />
                </td>
              </tr>
              <tr>
                <td>
                  <label id="hide" htmlFor="optionhide" />
                </td>
                <td>
                  <input type="checkbox" id="optionhide" />
                </td>
              </tr>
              <tr>
                <td>
                  * <label id="vector" htmlFor="optionvector" />
                </td>
                <td>
                  <input type="checkbox" id="optionvector" />
                </td>
              </tr>
              <tr>
                <td>
                  * <label id="showsize" htmlFor="optionshowsize" />
                </td>
                <td>
                  <input type="checkbox" id="optionshowsize" />
                </td>
              </tr>
              <tr>
                <td>
                  * <label id="showtype" htmlFor="optionshowtype" />
                </td>
                <td>
                  <input type="checkbox" id="optionshowtype" />
                </td>
              </tr>
            </tbody>
          </table>
          <hr />* <span className="small" id="optionsnotice" />
        </div>

        <textarea id="textarea" rows="1" cols="1" />
        <input type="button" id="clientload" />

        <div id="io">
          <table>
            <tbody>
              <tr>
                <td style={{ width: '60%' }}>
                  <fieldset>
                    <legend id="client" />
                    <div id="singlerow">
                      <input type="button" id="clientsave" />
                      <input type="button" id="clientload" />
                    </div>
                    <div id="singlerow">
                      <input type="button" id="clientlocalsave" />
                      <input type="button" id="clientlocalload" />
                      <input type="button" id="clientlocallist" />
                    </div>
                    <div id="singlerow">
                      <input type="button" id="dropboxsave" />
                      <input type="button" id="dropboxload" />
                      <input type="button" id="dropboxlist" />
                    </div>
                    <hr />
                    <input type="button" id="clientsql" />
                  </fieldset>
                </td>
                <td style={{ width: '40%' }}>
                  <fieldset>
                    <legend id="server" />
                    <label htmlFor="backend" id="backendlabel" />{' '}
                    <select id="backend">
                      <option />
                    </select>
                    <hr />
                    <input type="button" id="serversave" />
                    <input type="button" id="quicksave" />
                    <input type="button" id="serverload" />
                    <input type="button" id="serverlist" />
                    <input type="button" id="serverimport" />
                  </fieldset>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <fieldset>
                    <legend id="output" />
                    <textarea id="textarea" rows="1" cols="1" />
                  </fieldset>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="keys">
          <fieldset>
            <legend id="keyslistlabel" />
            <select id="keyslist">
              <option />
            </select>
            <input type="button" id="keyadd" />
            <input type="button" id="keyremove" />
          </fieldset>
          <fieldset>
            <legend id="keyedit" />
            <table>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="keytype" id="keytypelabel" />
                    <select id="keytype">
                      <option />
                    </select>
                  </td>
                  <td />
                  <td>
                    <label htmlFor="keyname" id="keynamelabel" />
                    <input type="text" id="keyname" size="10" />
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="keyfields" id="keyfieldslabel" />
                    <br />
                    <select id="keyfields" size="5" multiple="multiple">
                      <option />
                    </select>
                  </td>
                  <td>
                    <input type="button" id="keyleft" value="&lt;&lt;" />
                    <br />
                    <input type="button" id="keyright" value="&gt;&gt;" />
                    <br />
                  </td>
                  <td>
                    <label htmlFor="keyavail" id="keyavaillabel" />
                    <br />
                    <select id="keyavail" size="5" multiple="multiple">
                      <option />
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </div>

        <div id="table">
          <table>
            <tbody>
              <tr>
                <td>
                  <label id="tablenamelabel" htmlFor="tablename" />
                </td>
                <td>
                  <input id="tablename" type="text" />
                </td>
              </tr>
              <tr>
                <td>
                  <label id="tablecommentlabel" htmlFor="tablecomment" />
                </td>
                <td>
                  <textarea rows="5" cols="40" id="tablecomment" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
