import React from 'react'
const jsdiff = require('diff')

export const JSONDiffTable = (leftJson = {}, rightJson = {}) => {
  if (!leftJson && !rightJson) {
    return ''
  }

  console.log('JSONDiffTable', leftJson, rightJson)

  const leftJsonString = JSON.stringify(leftJson, null, 4)
  const rightJsonString = JSON.stringify(rightJson, null, 4)
  const changes = jsdiff.diffLines(leftJsonString, rightJsonString)

  let leftLineIndex = 1
  let rightLineIndex = 1

  let prev = null
  let next = null

  const result = changes.map((change, changeIndex) => {
    const lineCount = change.value.split('\n').length
    const nextLeftLineIndex = leftLineIndex + (change.removed ? 0 : lineCount)
    const nextRightLineIndex = rightLineIndex + (change.added ? 0 : lineCount)

    if (changeIndex > 0) {
      // if previous change wasn't change
      if (!changes[changeIndex - 1].added && !changes[changeIndex - 1].removed) {
        const prevChangeLines = changes[changeIndex - 1].value.split('\n')
        let prevChangeLineMaxIndex = prevChangeLines.length - 1

        // why?
        while (prevChangeLineMaxIndex > 0 && prevChangeLines[prevChangeLineMaxIndex].indexOf('{') === -1)
          prevChangeLineMaxIndex--

        const prevChangeData = prevChangeLines.slice(prevChangeLineMaxIndex)

        prev = (
          <div className="row DatasetHistoryTable__jsonRow">
            <div className="col-xs-1 DatasetHistoryTable__rowNumber">{leftLineIndex - prevChangeData.length}</div>
            <div className="col-xs-1 DatasetHistoryTable__rowNumber">{rightLineIndex - prevChangeData.length}</div>
            <div className="col-xs-10 DatasetHistoryTable__json">
              <pre>{prevChangeData.join('\n')}</pre>
            </div>
          </div>
        )
      }
    }

    // if not last
    if (changeIndex < changes.length - 1) {
      // if next change isn't change
      if (!changes[changeIndex + 1].added && !changes[changeIndex + 1].removed) {
        next = (
          <div className="row DatasetHistoryTable__jsonRow">
            <div className="col-xs-1 DatasetHistoryTable__rowNumber">{nextLeftLineIndex}</div>
            <div className="col-xs-1 DatasetHistoryTable__rowNumber">{nextRightLineIndex}</div>
            <div className="col-xs-10 DatasetHistoryTable__json">
              <pre>{changes[changeIndex + 1].value.split('\n').slice(0, 3).join('\n')}</pre>
            </div>
          </div>
        )
      }
    }

    const jsonRowClass = change.added ? 'DatasetHistoryTable__jsonRowBlue' : 'DatasetHistoryTable__jsonRowRed'

    let changeResult = (change.added || change.removed) && [
      <React.Fragment key={changeIndex}>
        {prev}
        <div className={`row DatasetHistoryTable__jsonRow ${jsonRowClass}`}>
          <div className="col-xs-1 DatasetHistoryTable__rowNumber">{leftLineIndex}</div>
          <div className="col-xs-1 DatasetHistoryTable__rowNumber">{rightLineIndex}</div>
          <div className="col-xs-10 DatasetHistoryTable__json">
            <pre>{change.value}</pre>
          </div>
        </div>
        {next}
      </React.Fragment>
    ]

    leftLineIndex = nextLeftLineIndex
    rightLineIndex = nextRightLineIndex

    return changeResult || null
  })

  if (result.filter((r) => r).length === 0) return 'No changes detected'
  else return result
}
