/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import { jsPDF } from 'jspdf'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  columnsToType,
  downloadFile,
  fieldTypeToString,
  formatForStructureTable,
  nonCSCompare,
  objectPictureUrl,
  pathByType,
  PromiseAllOrFail
} from '../../helpers'
import { track } from '../../helpers/analytics'
import { API, getObjectListNew, getObjectNew, getRequestFromPath } from '../../helpers/api'
import { getFullUri } from '../../helpers/index'
// and after webpack build, it cannot find png and zlib libraries and does not work
// actually the libraries are included in jspdf.debug.js
import iDocDownload from '../../resources/images/doc-download-2x.png'
import '../../styles/SystemBlock/SystemBlock.scss'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { ErrorWindow } from '../ErrorWindow/ErrorWindow'
import { HeaderOld } from '../Header/HeaderOld'
import { Loader } from '../Loader/Loader'
import { StructureTable } from '../NewTable/StructureTable'
import { TypedTable } from '../NewTable/TypedTable'
import { ObjectPicture } from '../ObjectPicture/ObjectPicture'
import './DocumentationView.scss'

let loadingObjectCount = 0

const getDataUrl = function (img) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  canvas.width = img.width
  canvas.height = img.height
  ctx.drawImage(img, 0, 0)

  // If the image is not png, the format
  // must be specified here
  return canvas.toDataURL()
}

function getDataUrlByPath(path) {
  return new Promise((resolve, reject) => {
    let img = document.createElement('img')
    img.onload = () => {
      resolve(getDataUrl(img))
    }
    img.src = path
  })
}

function replaceImageWithDataUrl(img) {
  return new Promise((resolve, reject) => {
    let path = img.style.backgroundImage.replace('url("', '').replace('")', '')
    console.log('replaceImageWithDataUrl', img, img.style.backgroundImage, path)
    getDataUrlByPath(path).then((dataUrl) => {
      console.log('resolve', dataUrl)
      img.style.backgroundImage = 'url("' + dataUrl + '")'
      resolve()
    })
  })
}

export class DocumentationView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      view: 'tree',
      currentOrganizationId: localStorage.getItem('currentOrganization'),
      loadFinished: false,
      sortOrder: ['asc', 'asc', 'asc'],
      fullCollapsed: [false, false, false],
      usageFilter: [false, false, false],
      tagFilter: [false, false, false],
      childTypeFilter: [],
      enumsLoaded: false,
      orgDatasets: []
    }
  }

  componentDidMount() {
    this.getAllObjects()
    track('DocumentationView', 'component')
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.searchQuery && newProps.searchQuery !== this.props.searchQuery) {
      this.setState({ search: ['', '', newProps.searchQuery] })
    }
  }

  getApplicationData(id) {
    return (this.props.appState.applications || []).find((el) => el.identity.id === id)
  }

  getDatasetData(id) {
    return (this.props.appState.datasets || []).find((el) => el.identity.id === id)
  }

  getInterfaceData(id) {
    return (this.props.appState.interfaces || []).find((el) => el.identity.id === id)
  }

  getPipelineData(id) {
    return (this.props.appState.pipelines || []).find((el) => el.identity.id === id)
  }

  getViewData(id) {
    return (this.props.appState.views || []).find((el) => el.identity.id === id)
  }

  viewMatrix() {
    this.setState({ view: 'matrix' })
  }

  viewTree() {
    this.setState({ view: 'tree' })
  }

  sliceCurrentOrg(orglist) {
    let defaultIndex = 0
    if (localStorage.getItem('currentOrganization')) {
      orglist.map((org, index) => {
        if (org.identity.id === localStorage.getItem('currentOrganization')) defaultIndex = index
      })
    }
    //console.log("sliceCurrentOrg defaultIndex",defaultIndex);
    //console.log("will load organization ",orglist[defaultIndex]);
    return [orglist[defaultIndex]]
  }

  loadingObjectAdd(count, objectType) {
    loadingObjectCount += count
    //console.log("added "+count+" " +objectType+" to " + (loadingObjectCount - count) + " result " + loadingObjectCount);
    if (document.getElementById('SystemsView__loadingObjectCount'))
      document.getElementById('SystemsView__loadingObjectCount').innerHTML =
        'Loading ' + loadingObjectCount + ' objects'
  }

  loadingObjectRemove(count, objectType) {
    loadingObjectCount -= count
    //console.log("deleted "+count+" " + objectType + " from " + (loadingObjectCount + count) + " result " + loadingObjectCount);
    if (document.getElementById('SystemsView__loadingObjectCount'))
      document.getElementById('SystemsView__loadingObjectCount').innerHTML =
        'Loading ' + loadingObjectCount + ' objects'
    if (loadingObjectCount === 0) {
      setTimeout(() => {
        this.setState({
          loadFinished: true
        })
        if (document.getElementById('SystemsView__loadingObjectCount'))
          document.getElementById('SystemsView__loadingObjectCount').innerHTML = ''
      }, 50) // timeout is needed to render final object count
    }
  }

  getAllEnums() {
    this.setState({
      loadFinished: false
    })

    let datasetPromises = []

    this.props.appState.datasets.map((ds) => {
      if (ds.object && ds.object.usage === 'Enum') {
        this.loadingObjectAdd(1, 'ds')
        datasetPromises.push(getObjectNew(getRequestFromPath(getFullUri(ds)), 'dataset', this.props.actions))
      }
    })

    PromiseAllOrFail(datasetPromises).then((datasets) => {
      this.loadingObjectRemove(datasets.length)
      this.props.actions.receiveObjectList('dataset', datasets)
      this.setState({ enumsLoaded: true })
    })
  }

  getAllObjects() {
    this.setState({
      loadFinished: false
    })
    getObjectListNew(API.organizations, 'organization', this.props.actions).then((orglist) => {
      this.sliceCurrentOrg(orglist).map((org) => {
        this.loadingObjectAdd(1, 'org')
        //console.log("after slice org",org);

        let orgds = []

        this.loadingObjectAdd(1, 'dslist')
        getObjectListNew(API.organizations(org.identity.name).datasets, 'dataset', this.props.actions).then(
          (dslist) => {
            PromiseAllOrFail(
              dslist.map((ds) => {
                this.loadingObjectAdd(1, 'ds')
                return new Promise((resolve, reject) => {
                  getObjectNew(
                    API.organizations(org.identity.name).datasets(ds.identity.name),
                    'dataset',
                    this.props.actions
                  ).then((ds) => {
                    this.loadingObjectRemove(1, 'ds')
                    resolve(ds)
                  }, reject)
                })
              })
            ).then((allds) => {
              orgds = allds
              this.setState({ orgDatasets: orgds })
              this.loadingObjectRemove(1, 'dslist')
            })
          }
        )

        getObjectListNew(API.organizations(org.identity.name).systems, 'system', this.props.actions).then((syslist) => {
          let syslistFiltered = syslist.filter((sys) => {
            //console.log('orgid = ' + org.identity.id + ', sys', sys);
            if (!sys.identity.name) return false
            if (!sys.object || !sys.object.parent || sys.object.parent.id !== org.identity.id) return false

            return true
          })
          syslistFiltered = this.filterSystems(syslistFiltered)
          this.loadingObjectAdd(syslistFiltered.length, 'sys')
          this.props.actions.updateMajorObject(org.identity.id, 'organizations', {
            systems: syslist.map((sys) => sys.identity.id)
          })
          syslistFiltered.map((sys) => {
            getObjectNew(getRequestFromPath(getFullUri(sys)), 'system', this.props.actions).then(() => {
              getObjectListNew(
                API.organizations(org.identity.name).systems(sys.identity.name).applications,
                'application',
                this.props.actions
              ).then((applist) => {
                //console.log('applist', applist);
                applist = this.filterApplications(applist)
                this.loadingObjectAdd(applist.length, 'app')
                this.props.actions.updateMajorObject(sys.identity.id, 'systems', {
                  applications: applist.map((app) => app.identity.id)
                })
                applist.map((app) => {
                  //console.log('make request for app',app);
                  // get all Datasets of Application
                  getObjectNew(getRequestFromPath(getFullUri(app)), 'application').then((appFull) => {
                    //console.log("app before promise");

                    const appDatasetsPromise = new Promise((resolve, reject) => {
                      // request dataset list
                      getObjectListNew(
                        API.organizations(org.identity.name).systems(sys.identity.name).applications(app.identity.name)
                          .datasets,
                        'dataset'
                      ).then((dsist) => {
                        app.datasets = dsist.map((ds) => ds.identity.id)
                        PromiseAllOrFail(
                          dsist.map((ds) => {
                            return getObjectNew(
                              API.organizations(org.identity.name)
                                .systems(sys.identity.name)
                                .applications(app.identity.name)
                                .datasets(ds.identity.name),
                              'dataset',
                              this.props.actions
                            )
                          })
                        ).then(resolve)

                        //resolve();
                      })
                    })

                    const appInterfacesPromise = new Promise((resolve, reject) => {
                      // request dataset list
                      getObjectListNew(
                        API.organizations(org.identity.name).systems(sys.identity.name).applications(app.identity.name)
                          .interfaces,
                        'interface'
                      ).then((dsist) => {
                        app.interfaces = dsist.map((ds) => ds.identity.id)
                        PromiseAllOrFail(
                          dsist.map((ds) => {
                            return getObjectNew(
                              API.organizations(org.identity.name)
                                .systems(sys.identity.name)
                                .applications(app.identity.name)
                                .interfaces(ds.identity.name),
                              'interface',
                              this.props.actions
                            )
                          })
                        ).then(resolve)

                        //resolve();
                      })
                    })

                    /*
                   const appInterfacesPromise = new Promise((resolve, reject) => {
                   // request dataset list
                   getObjectListNew(API.organizations(org.identity.name).systems(sys.identity.name).applications(app.identity.name).interfaces,'interface',null)
                   .then((appInts)=>{
                   interfaces = appInts;
                   app.interfaces = appInts.map((intrfc)=>intrfc.identity.id);
                   resolve();
                   });
                   });

                   const appPipelinesPromise = new Promise((resolve, reject) => {
                   // request dataset list
                   getObjectListNew(API.organizations(org.identity.name).systems(sys.identity.name).applications(app.identity.name).pipelines,'pipeline',null)
                   .then((appPipelines)=>{
                   pipelines = appPipelines;
                   app.pipelines = appPipelines.map((pp)=>pp.identity.id);
                   resolve();
                   });
                   });

                   const appViewsPromise = new Promise((resolve, reject) => {
                   // request dataset list
                   getObjectListNew(API.organizations(org.identity.name).systems(sys.identity.name).applications(app.identity.name).views,'view',null)
                   .then((appViews)=>{
                   views = appViews;
                   app.views = appViews.map((v)=>v.identity.id);
                   resolve();
                   });
                   });
                   */

                    //console.log("app afer promise");
                    this.loadingObjectAdd(1, 'appchild')

                    //, appInterfacesPromise, appPipelinesPromise, appViewsPromise
                    PromiseAllOrFail([appDatasetsPromise, appInterfacesPromise]).then(() => {
                      //console.log("app in promise all", datasets);
                      //this.props.actions.receiveObjectList('dataset', datasets);
                      /*
                     this.props.actions.receiveObjectList('interface', interfaces);
                     this.props.actions.receiveObjectList('pipeline', pipelines);
                     this.props.actions.receiveObjectList('view', views);
                     */
                      this.loadingObjectRemove(1, 'appchild')
                      this.props.actions.receiveObject(
                        'application',
                        app.identity.id,
                        Object.assign({}, appFull, { datasets: app.datasets })
                      )
                      this.props.actions.receiveObject(
                        'application',
                        app.identity.id,
                        Object.assign({}, appFull, {
                          interfaces: app.interfaces
                        })
                      )
                    })
                  })
                }) // applist map
                this.loadingObjectRemove(applist.length, 'app')

                // system loaded

                this.loadingObjectRemove(1, 'sys')
              })
            })
          })
          this.loadingObjectRemove(1, 'org')
        })
      })
    })
  }

  downloadHTML = () => {
    let head = document.querySelector('head').innerHTML
    //<div className="DocumentationView__body row">' +
    //document.querySelector('.DocumentationView__body').innerHTML + '</div>
    let html = '<html>' + head + '<body>' + document.querySelector('body').innerHTML + '</body></html>'

    const el = document.createElement('html')
    el.innerHTML = html
    el.querySelector('.Header__outer').innerHTML = ''
    el.querySelector('.SelectView__upper').style.display = 'none'
    el.querySelector('.DocumentationView__tree').style.top = '0px'
    el.querySelector('.DocumentationView__download').style.display = 'none'
    /*
    let images = el.querySelectorAll('img');
    for (let i = 0; i < images.length; i++) {
      let img = images[i];
      img.src = getDataUrl(img);
      console.log("convert image", img.src);
    }
    */

    let promises = []
    let images = el.querySelectorAll('.ObjectPicture__picture')
    for (let i = 0; i < images.length; i++) {
      let img = images[i]
      promises.push(replaceImageWithDataUrl(img))
    }

    PromiseAllOrFail(promises).then(() => {
      downloadFile(el.innerHTML.replace(/\u200B/g, ''), 'Documentation.html')
    })

    return false
  }

  downloadPDF = () => {
    //downloadSelectorAsPDF(document.querySelector('.SystemsView__body'), org.identity.name + ' Systems View', '.MajorObjectBlock');
    let pdf = new jsPDF({ orientation: 'landscape', format: 'a4' })
    console.log('downloadPDF', pdf)

    pdf.fromHTML(document.querySelector('.DocumentationView__body'), 0, 0, { width: 1200 }, function (dispose) {
      console.log('downloadPDF fromHTML')
      pdf.save('Documentation.pdf')
    })

    return false
  }

  anchorByPath = (path) => {
    const hashname = path.split('/').join('_').split(' ').join('_').split('%20').join('_')
    return 'docs_' + hashname
  }

  anchor = (obj) => {
    if (!obj) return ''
    const path = getFullUri(obj)
    return this.anchorByPath(path)
  }

  getDataTabWidth = (field, dataset, width) => {
    let length = dataset.structure && dataset.structure.fields ? dataset.structure.fields.length : 1

    if (length === 1) {
      return width
    }

    let index =
      dataset.structure && dataset.structure.fields
        ? dataset.structure.fields.findIndex((field) => nonCSCompare(field.usage, 'description'))
        : -1
    let column = index === -1 ? ((width - 100) / length) | 0 : ((width - 100) / (length + 1)) | 0
    column = Math.max(100, column)

    if (!field) {
      // Index column
      return width - (index === -1 ? length * column : (length + 1) * column)
    } else if (nonCSCompare(field.usage, 'description')) {
      // We are calculating for description
      return 2 * column
    } else {
      // We are calculating for any other field
      return column
    }
  }

  renderDatasets = (datasets, org = false) => {
    return datasets.length === 0
      ? null
      : [
          <div key={-1} className="DocumentationView__datasetsBlock">
            <div className="DocumentationView__blockHeader">Datasets:</div>
          </div>
        ].concat(
          datasets
            .filter((ds) => ds)
            .map((dsId) => {
              let ds = this.getDatasetData(dsId)
              if (!ds) return null
              console.log('RenderDataset', ds)
              return (
                <div key={dsId} className="DocumentationView__datasetBlock">
                  <a
                    name={
                      org
                        ? this.anchorByPath('/organizations/' + org.identity.name + '/datasets/' + ds.identity.name)
                        : this.anchor(ds)
                    }
                  ></a>
                  <div className="DocumentationView__objectName">
                    {' '}
                    <ObjectPicture
                      name={ds.identity.name}
                      img={objectPictureUrl(getFullUri(ds), ds.object.picture)}
                      usage={ds.object.usage}
                    />
                    {ds.identity.name}
                  </div>
                  <div className="DocumentationView__objectDescription">{ds.identity.description}</div>
                  <StructureTable
                    data={formatForStructureTable(ds)}
                    isFullscreen
                    widthBeforeFullscreen={1184}
                    columns={[
                      {
                        displayName: null,
                        name: 'key',
                        type: columnsToType.getType('key'),
                        frozen: true,
                        width: 59 + 5,
                        hiddenInChildTable: true
                      },
                      {
                        displayName: 'Ord.',
                        name: 'order',
                        type: columnsToType.getType('id'),
                        frozen: true,
                        width: 42,
                        hiddenInChildTable: true,
                        customDisplayInChildTable: true
                      },
                      {
                        displayName: (
                          <span>
                            <a className={'MajorObject__expandStructureButton '} onClick={this.expandAllStructure}></a>
                          </span>
                        ),
                        name: 'expandButton',
                        type: { name: 'data' },
                        frozen: true,
                        width: 30
                      },
                      {
                        name: 'name',
                        displayName: 'Field name',
                        type: columnsToType.getType('minorObjectName'),
                        width: 226 + 7 + 28 - 5 - 5
                      },
                      {
                        name: 'description',
                        displayName: 'Description',
                        type: { name: 'text_fullsize' },
                        width: 316 + 70
                      },
                      {
                        name: 'usage',
                        type: columnsToType.getType('string'),
                        frozen: true,
                        width: 100
                      },
                      {
                        name: 'required',
                        displayName: 'Req.',
                        type: columnsToType.getType('required'),
                        frozen: true,
                        width: 40
                      },
                      {
                        name: 'type',
                        type: columnsToType.getType('typeReference'),
                        frozen: true,
                        width: 282 - 27 + 18
                      }
                    ]}
                  />
                  {ds.data && ds.data.records && ds.data.records.length > 0 ? (
                    <div>
                      <div className="DocumentationView__datasetDataHeader">Data</div>
                      <TypedTable
                        columns={[
                          {
                            name: '_index',
                            displayName: '#',
                            type: { name: 'integer' },
                            width: this.getDataTabWidth(null, ds, 50)
                          }
                        ].concat(
                          ds.structure && ds.structure.fields
                            ? ds.structure.fields
                                .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
                                .map((f) => {
                                  if (!f || !f.identity) return { name: '' }
                                  let displayName = f.identity.name
                                  let type = 'String' // remove this quick dirty fix
                                  if (f.type)
                                    type = Object.assign(
                                      {},
                                      columnsToType.getType(f.type.toLowerCase()),
                                      f.reference ? { reference: f.reference } : {}
                                    )
                                  return {
                                    name: f.identity.name,
                                    displayName: displayName,
                                    type: type,
                                    width: this.getDataTabWidth(f, ds, 1184)
                                  }
                                })
                            : []
                        )}
                        data={ds.data.records
                          .sort((a, b) => (a.index > b.index ? 1 : a.index < b.index ? -1 : 0))
                          .map((record, index) => {
                            const res = {
                              id: index
                            }
                            for (let i = 0; i < record.values.length; i++) {
                              if (ds.structure.fields[i] && ds.structure.fields[i].identity) {
                                let field = ds.structure.fields[i].identity.name
                                res[field] = record.values[i]
                              }
                            }
                            res._index = record.index
                            return res
                          })}
                      />
                    </div>
                  ) : null}

                  {ds.data && ds.data.examples && ds.data.examples.length > 0 ? (
                    <div>
                      <div className="DocumentationView__datasetDataHeader">Examples</div>
                      <TypedTable
                        columns={[
                          {
                            name: 'name',
                            displayName: 'Example name',
                            type: columnsToType.getType('minorObjectName'),
                            width: 250
                          },
                          {
                            name: 'description',
                            type: columnsToType.getType('text_fullsize'),
                            width: 350
                          },
                          {
                            name: 'format',
                            type: columnsToType.getType('string'),
                            width: 100
                          },
                          {
                            name: 'text',
                            type: columnsToType.getType('text_fullsize'),
                            width: 485
                          }
                        ]}
                        data={
                          ds.data && ds.data.examples
                            ? ds.data.examples.map((example) => {
                                const newName = {
                                  name: example.identity ? example.identity.name : '',
                                  objectType: 'example',
                                  path: example.path
                                }
                                example.description = example.identity ? example.identity.description : ''
                                example = Object.assign({}, example, {
                                  name: newName
                                })

                                return example
                              })
                            : []
                        }
                      />
                    </div>
                  ) : null}
                </div>
              )
            })
        )
  }

  formatForFieldTable = (inter, list) => {
    //console.log("Interface:formatForFieldTable", inter, list);
    return list
      ? list
          .map((object) => {
            let field = object.field
            if (field) {
              if (!field.type) field.type = 'String'
              if (!field.identity) return null
              const newName = {
                name: field.identity.name,
                objectType: 'field',
                path: field.path
              }
              field = Object.assign({}, field, { name: newName })
              field.description = field.identity.description
            } else {
              if (!object.identity) return null
              const newName = {
                name: object.identity.name,
                objectType: 'field',
                path: getFullUri(object)
              }
              field = Object.assign({}, { name: newName })
              field.type = 'String'
              field.identity = object.identity
              field.description = object.identity.description
            }

            for (let prop in field) {
              if (typeof field[prop] === 'number') field[prop] = field[prop] + ''
            }

            let type = field.type.toLowerCase()
            // if (type === 'structure' || type === 'enum' || type === 'reference') {
            //   //console.log("Dataset:formatForStructureTable:URI", field.reference, dataset, dataset.path);
            // }

            if (type === 'reference') {
              const appSplit = inter.object.parent.name.substring(1).split('/')
              const orgURI = pathByType(appSplit, 'organizations')

              field.reference = orgURI + '/datasets/Identity'
              //console.log("Dataset:formatForStructureTable:REFERENCE", field.reference);
            }

            field.type = {
              displayType: fieldTypeToString(field, inter),
              label: field.type,
              datasetReference: type === 'structure' || type === 'reference' ? field.reference : undefined
            }
            field.id = field.identity.id
            field.object = object
            field.location = object.location
            field.code = object.code
            field.dep = object.deprecated ? 'Yes' : ''
            field.action = object.action
            field.headers = field.headers ? field.headers.map((h) => h.identity.name) : []
            field.parameters = object.parameters ? object.parameters.map((parameter) => parameter.name) : []
            field.responses = object.responses ? object.responses.map((response) => response.name) : []
            field.examples = object.examples ? object.examples.map((ex) => ex.identity.name) : []
            field.required = !field.optional

            return field
          })
          .filter((field) => field)
          .sort((a, b) => {
            const ao = parseInt(a.order)
            const bo = parseInt(b.order)

            if (ao > bo) return 1
            else if (bo > ao) return -1

            const an = a.identity ? a.identity.name : ''
            const bn = b.identity ? b.identity.name : ''
            if (an > bn) return 1
            else if (bn > an) return -1
            return 0
          })
      : []
  }

  renderInterfaces = (interfaces) => {
    return interfaces.length === 0
      ? null
      : [
          <div key={-1} className="DocumentationView__interfacesBlock">
            <div className="DocumentationView__blockHeader">Interfaces:</div>
          </div>
        ].concat(
          interfaces.map((inId) => {
            let intf = this.getInterfaceData(inId)
            console.log('RenderInterface', intf)
            if (!intf) return null
            return (
              <div key={inId} className="DocumentationView__interfaceBlock">
                <div className="DocumentationView__blockHeader">Interface:</div>
                <a name={this.anchor(intf)}></a>
                <div className="DocumentationView__objectName">
                  <ObjectPicture
                    name={intf.identity.name}
                    img={objectPictureUrl(getFullUri(intf), intf.object.picture)}
                    usage={intf.object.usage}
                  />
                  {intf.identity.name}
                </div>
                <div className="DocumentationView__objectDescription">{intf.identity.description}</div>
                <h3>Operations</h3>
                {intf.operations.map((operation) => {
                  console.log('Operation', operation)
                  const paramIds = operation.parameters.map((param) => param.name)
                  const responseIds = operation.responses.map((resp) => resp.name)

                  return (
                    <div className="DocumentationView__operation" key={operation.identity.id}>
                      <EditableEntity dataType={{ name: 'httpMethod' }} data={operation.action} dataProps={{}} />
                      <div className="DocumentationView__operationName">{operation.identity.name}</div>
                      <div>{operation.identity.description}</div>
                      {paramIds.length === 0 ? null : (
                        <div>
                          <div className="DocumentationView__datasetDataHeader">Parameters: </div>
                          <StructureTable
                            isFullscreen
                            widthBeforeFullscreen={1184}
                            columns={[
                              {
                                displayName: 'Ord.',
                                name: 'order',
                                type: columnsToType.getType('id'),
                                frozen: true,
                                width: 32 + 28 - 25,
                                hiddenInChildTable: true
                              },
                              {
                                displayName: ' ',
                                name: 'expandButton',
                                type: { name: 'data' },
                                frozen: true,
                                width: 25
                              },
                              {
                                name: 'name',
                                displayName: 'Parameter name',
                                type: columnsToType.getType('minorObjectName'),
                                width: 200
                              },
                              {
                                name: 'description',
                                displayName: 'Description',
                                type: columnsToType.getType('text_fullsize'),
                                width: 436 - 20
                              },
                              {
                                name: 'location',
                                type: columnsToType.getType('string'),
                                width: 80
                              },
                              {
                                name: 'required',
                                displayName: 'Req.',
                                type: columnsToType.getType('required'),
                                frozen: true,
                                width: 40
                              },
                              {
                                name: 'type',
                                type: columnsToType.getType('typeReference'),
                                frozen: true,
                                width: 236
                              },
                              {
                                name: 'examples',
                                type: columnsToType.getType('list'),
                                width: 150
                              }
                            ]}
                            data={this.formatForFieldTable(
                              intf,
                              intf.parameters.filter(
                                (param) => param && param.field && paramIds.indexOf(param.field.identity.name) !== -1
                              )
                            )}
                            objectPath={getFullUri(intf)}
                            parentObject={intf}
                          />
                        </div>
                      )}
                      {responseIds.length === 0 ? null : (
                        <div>
                          <div className="DocumentationView__datasetDataHeader">Responses: </div>
                          <StructureTable
                            isFullscreen
                            widthBeforeFullscreen={1184}
                            columns={[
                              {
                                displayName: 'Ord.',
                                name: 'order',
                                type: columnsToType.getType('id'),
                                frozen: true,
                                width: 32
                              },
                              {
                                displayName: ' ',
                                name: 'expandButton',
                                type: { name: 'data' },
                                frozen: true,
                                width: 28
                              },
                              {
                                name: 'name',
                                displayName: 'Response name',
                                type: columnsToType.getType('minorObjectName'),
                                width: 200
                              },
                              {
                                name: 'description',
                                type: columnsToType.getType('text_fullsize'),
                                width: 436
                              },
                              {
                                name: 'code',
                                type: columnsToType.getType('string'),
                                width: 80
                              },
                              {
                                name: 'type',
                                type: columnsToType.getType('type'),
                                frozen: true,
                                width: 236
                              },
                              {
                                name: 'examples',
                                type: columnsToType.getType('list'),
                                width: 170
                              }
                            ]}
                            data={this.formatForFieldTable(
                              intf,
                              intf.responses.filter(
                                (resp) => resp && resp.field && responseIds.indexOf(resp.field.identity.name) !== -1
                              )
                            )}
                            objectPath={getFullUri(intf)}
                            parentObject={intf}
                          />
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            )
          })
        )
  }

  renderOrganizationDatasets = (org) => {
    //getDatasets(this.props.appState, localStorage.getItem("currentOrganization"))
    return this.renderDatasets(
      this.state.orgDatasets.filter((ds) => ds && ds.object && ds._path && ds.identity).map((ds) => ds.identity.id),
      org
    )
  }

  renderApplications = (applications) => {
    /*
     {app.interfaces.length > 0 ?  <h2>Interfaces</h2> : null}
     {this.renderInterfaces(app.interfaces)}

     */
    console.log('renderApplications', applications)
    return applications.map((appId) => {
      let app = this.getApplicationData(appId)
      return (
        <div key={appId} className="DocumentationView__applicationBlock">
          <div className="DocumentationView__blockHeader">Application:</div>
          <a name={this.anchor(app)}></a>
          <ObjectPicture
            name={app.identity.name}
            img={objectPictureUrl(getFullUri(app), app.object.picture)}
            usage={app.object.usage}
          />
          {app.identity.name}
          <div className="DocumentationView__objectDescription">{app.identity.description}</div>
          {this.renderDatasets(app.datasets.filter((ds) => ds) || [])}
          {this.renderInterfaces(app.interfaces.filter((intf) => intf) || [])}
        </div>
      )
    })
  }

  renderSystems = (systems) => {
    console.log('renderSystems', systems)
    return systems.map((system) => {
      return !system.identity.name ? null : (
        <div key={system.identity.id} className="DocumentationView__systemBlock">
          <div className="DocumentationView__blockHeader">System:</div>
          <a name={this.anchor(system)}></a>
          <ObjectPicture
            name={system.identity.name}
            img={objectPictureUrl(getFullUri(system), system.object.picture)}
            usage={system.object.usage}
          />{' '}
          {system.identity.name}
          <div className="DocumentationView__objectDescription">{system.identity.description}</div>
          {this.renderApplications(this.filterApplicationIds(system.applications.filter((app) => app) || []))}
        </div>
      )
    })
  }

  renderTree = (systems) => {
    return (
      <div className="DocumentationView__tree">
        {(systems || []).map((system) => {
          return !system.identity.name ? null : (
            <div className="DocumentationView__tree_system" key={system.identity.id}>
              sys/<a href={'#' + this.anchor(system)}>{system.identity.name}</a>
              {this.filterApplicationIds(system.applications || []).map((appId) => {
                let app = this.getApplicationData(appId)
                if (!app) return null
                return (
                  <div className="DocumentationView__tree_application" key={appId}>
                    app/
                    <a href={'#' + this.anchor(app)}>{app.identity.name}</a>
                    {(app.datasets || []).map((dsId) => {
                      let ds = this.getDatasetData(dsId)
                      if (!ds) return null
                      return (
                        <div className="DocumentationView__tree_dataset" key={dsId}>
                          ds/
                          <a href={'#' + this.anchor(ds)}>{ds.identity.name}</a>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }

  filterSystems = (systems) => {
    if (!this.props.match.params.systemName) return systems
    else return systems.filter((system) => system.identity.name === this.props.match.params.systemName)
  }

  filterApplicationIds = (appIds) => {
    if (!this.props.match.params.applicationName) return appIds
    else
      return appIds.filter((appId) => {
        let app = this.getApplicationData(appId)
        return app.identity.name === this.props.match.params.applicationName
      })
  }

  filterApplications = (applications) => {
    if (!this.props.match.params.applicationName) return applications
    else
      return applications.filter((app) => {
        return app.identity.name === this.props.match.params.match.applicationName
      })
  }

  updateLinks = () => {
    const links = document.body.getElementsByTagName('a')
    for (let i = 0; i < links.length; i++) {
      const link = links[i]
      if (link.href.indexOf('/view/') !== -1) {
        let url = link.href.substr(link.href.indexOf('/view/')).replace('/view/', '')
        if (url.indexOf('/versions') !== -1) url = url.substr(0, url.indexOf('/versions'))
        const hashname = this.anchorByPath(url)
        console.log('link', link, 'old url', url, 'new url', hashname)
        link.href = '#' + hashname
        link.addEventListener('click', (event) => event.stopPropagation())
      }
    }
  }

  render() {
    const appState = this.props.appState
    let defaultOrg = null
    if (appState.organizations)
      appState.organizations.map((org, index) => {
        if (!localStorage.getItem('currentOrganization') && index === 0) {
          defaultOrg = org
        }
        if (localStorage.getItem('currentOrganization') === org.identity.id) {
          defaultOrg = org
        }
      })

    if (this.props.hidden) return null

    setTimeout(this.updateLinks, 0)

    //<div id="SystemsView__loadingObjectCount"></div>
    return (
      <div className="SystemsView__root">
        <HeaderOld
          title="DataHub"
          userState={this.props.userState}
          appState={this.props.appState}
          actions={this.props.actions}
        />
        <ErrorWindow actions={this.props.actions} history={this.props.history} />

        <div className="SelectView__upper">DOCUMENTATION</div>

        <div className="DocumentationView">
          {!this.state.loadFinished ? (
            <div className="SystemsView__loader">
              <Loader />
            </div>
          ) : (
            <div>
              <div className="DocumentationView__body row">
                <div className="col-xs-3">
                  <div className="DocumentationView__download ">
                    <a href="#" onClick={this.downloadHTML}>
                      Download
                      <img src={iDocDownload} alt="icon" />
                    </a>
                    {/*<a href="#" onClick={this.downloadPDF}>Download PDF</a>*/}
                  </div>

                  {this.renderTree(this.filterSystems(this.props.appState.systems))}
                </div>
                <div className="col-xs-9">
                  {!this.props.match.params.systemName ? this.renderOrganizationDatasets(defaultOrg) : null}
                  {this.renderSystems(this.filterSystems(this.props.appState.systems))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

DocumentationView.propTypes = {
  appState: PropTypes.object,
  userState: PropTypes.object,
  actions: PropTypes.object
}
