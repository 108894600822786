/**
 * Created by kascode on 28.04.17.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { Badge } from './Badge'

const HttpMethods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE']

export const HttpMethodBadge = ({ method }) => {
  let color

  switch (method) {
    case 'GET':
      color = 'blue'
      break
    case 'POST':
      color = 'green'
      break
    case 'PUT':
    case 'PATCH':
      color = 'yellow'
      break
    case 'DELETE':
      color = 'RED'
      break
    default:
      color = 'gray'
  }

  return <Badge color={color}>{method}</Badge>
}

HttpMethodBadge.propTypes = {
  method: PropTypes.oneOf(HttpMethods).isRequired
}
