/**
 * Created by mailf on 29.04.2016.
 */
import PropTypes from 'prop-types'

import React from 'react'

import '../../styles/ObjectPicture/ObjectPicture.scss'
import { usageIconUris, isCapitalLetter, require_usage_image } from '../../helpers'
import { fetchDataUrlImmediate } from '../../helpers/api'

export const ObjectPicture = ({ name, img, className, usage }) => {
  return <div className={'ObjectPicture' + (className ? ' ' + className : '')}>{imgOrDiv(name, img, usage)}</div>
}

ObjectPicture.propTypes = {
  name: PropTypes.string,
  img: PropTypes.string,
  className: PropTypes.string
}

function imgOrDiv(name, img, usage) {
  //console.log("imgOrDiv", typeof(img), img, name);

  if (!img && usage) {
    if (usage.toLowerCase() in usageIconUris) {
      let imageData = require_usage_image(usageIconUris[usage.toLowerCase()])
      let imageUrl = window.matchMedia('@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)').matches
        ? imageData.retina
        : imageData.normal
      let style = {
        backgroundImage: "url('" + imageUrl + "')",
        backgroundSize: '34px 34px',
        backgroundPosition: '-2px -2px'
      }
      return <div className="ObjectPicture__picture" style={style}></div>
    }
  }

  if (img && typeof img === 'string') {
    let style = { backgroundImage: "url('" + fetchDataUrlImmediate(img) + "')" }
    return <div className="ObjectPicture__picture" style={style}></div>
  } else if (img && typeof img === 'object') {
    let style = { backgroundImage: 'url(' + img.preview + ')' }
    return <div className="ObjectPicture__picture" style={style}></div>
  } else if (name)
    return (
      <span className={'ObjectPicture__name ' + (isCapitalLetter(name.charAt(0)) ? 'ObjectPicture__nameUpper' : '')}>
        {name.charAt(0)}
      </span>
    )
  else return null
}
