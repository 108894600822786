/**
 * Created by mailf on 12.05.2016.
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../actions/index'
import { getObjectByName } from '../../helpers/data'
import { DataEditorTable } from '../NewTable/DataEditorTable'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import {
  editableState,
  strToStars,
  checkFieldPrivacy,
  getFullUri,
  objectTypeFromPath,
  objectNameFromPathByType,
  getDatasetRequestFromReference,
  deepCopy
} from '../../helpers/index'
import metadata from '../../helpers/md'
import logger from '../../helpers/logger'

import './style.scss'

class ObjectProperties extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: props.fields,
      values: props.values,
      encryptedValues: props.values,
      isOpen: false,
      isEditing: false,
      isDeleting: false,
      isEdited: false,
      loadingEnumDataset: false,
      errorText: '',
      apdaxObject: null
    }
  }

  componentDidMount() {
    const objectName = 'object'
    //const ds = Jsons.apdax.sys.DifHub.app.System.ds[objectName+"_1_0_0"] || Jsons.apdax.sys.DifHub.app.System.ds[objectName.toLowerCase()+"_1_0_0"];

    //this.setState({apdaxObject: ds});
    // logger.info("objectProperties:componentDidMount", getDatasetRequestFromReference('$:/apdax/systems/DifHub/applications/System/datasets/Object'));

    getDatasetRequestFromReference('$:/apdax/systems/DifHub/applications/System/datasets/Object')
      .get()
      .then((objectDataset) => {
        this.setState({ apdaxObject: objectDataset })
      })
  }

  UNSAFE_componentWillReceiveProps(props) {
    /*
    this.setState({
      properties: props.properties ? props.properties.map((p, index) => {
        p.id = index;
        return p;
      }) : []
    });
    */
    this.setState({
      fields: props.fields,
      values: props.values,
      encryptedValues: props.values
    })
  }

  toggleIsOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      isEdited: false,
      errorText: ''
    })
  }

  renderProperty(prop, i) {
    let value = this.state.values[prop.identity.name]
    if (value === '""' || value === '{}' || value === '' || !value) return null
    //console.log("renderProperty", prop, i);
    if (typeof value === 'object') {
      //console.log("renderProperty object", prop);
      if (value.identity && value.identity.name) {
        const uri = value.identity.name
        //console.log("renderProperty uri", uri);
        value = <a href={'/view' + uri}>{objectNameFromPathByType(uri, objectTypeFromPath(uri))}</a>
      } else return null
    }

    return (
      <div className="ObjectProperty" key={i}>
        <div className="ObjectProperty__name">{prop.identity.name + ':'}</div>
        <div className="ObjectProperty__value">{checkFieldPrivacy(prop.type) ? strToStars(value) : value}</div>
      </div>
    )
  }

  renderProperties() {
    return (
      this.state.fields
        //.filter(p => p.value !== "\"\"" && p.value !== "{}" && p.value !== "" && p.value)
        .map((p, i) => this.renderProperty(p, i))
        .filter((s) => s)
        .slice(0, 4)
    )
  }

  renderContent = (editState) => {
    return this.state.apdaxObject ? (
      <DataEditorTable
        fields={this.state.fields}
        values={this.state.values}
        encryptedValues={this.state.encryptedValues}
        colWidths={[32, 198, 216, 306]}
        editableValues={editState > 1}
        isEditable={editState > 1}
        onChange={(changedValue) => {
          if (editState === editableState.BROWSABLE) return false

          let newValues = Object.assign({}, this.state.values, {
            [changedValue.name]: changedValue.value
          })
          let newEncryptedValues = Object.assign({}, this.state.encryptedValues || this.state.values, {
            [changedValue.name]: changedValue.encryptedValue
          })

          //console.log("ObjectProperties::onChange", changedValue, deepCopy(this.state.encryptedValues), deepCopy(newEncryptedValues));

          this.setState({
            values: newValues,
            encryptedValues: newEncryptedValues,
            isEdited: true
          })
        }}
        objectPath={this.state.apdaxObject ? getFullUri(this.state.apdaxObject) : ''}
        objectType={this.props.className}
        parentObject={this.state.apdaxObject}
        majorObjectType={this.props.object.type}
      />
    ) : null
  }

  render() {
    let editState = this.props.isEditable ? editableState.EDITABLE : editableState.BROWSABLE
    if (this.props.isEditable) editState = editableState.EDITING
    if (this.state.isEdited) editState = editableState.EDITED

    return (
      <div className={'ObjectProperties ' + this.props.className}>
        <div className="ObjectProperties__title" onClick={this.toggleIsOpen.bind(this)}>
          <div className="ObjectProperties__titleImage"></div>Properties
        </div>
        <div className="ObjectProperties__list">
          {this.state.fields && this.state.values ? this.renderProperties() : null}
        </div>
        {this.state.isOpen ? (
          <EditorDialog
            modalTitle="Properties"
            editContent={this.renderContent}
            isVisible
            isEditable={editState}
            objectType={this.props.className}
            actions={this.props.actions}
            errorText={this.state.errorText}
            messageText=""
            onClose={this.toggleIsOpen.bind(this)}
            onCancel={() => {}}
            onSave={() => {
              //          console.log("ObjectProperties:render:SAVE", this.state.fields, this.state.values, this.state.encryptedValues);
              this.props.onChange(this.state.encryptedValues).then(
                (successText) => {
                  this.setState({ successText: successText, isEdited: false })
                },
                (errorText) => {
                  this.setState({ errorText: errorText })
                }
              )
            }}
          />
        ) : null}
      </div>
    )
  }
}

ObjectProperties.propTypes = {
  isEditable: PropTypes.bool,
  className: PropTypes.string,
  fields: PropTypes.array,
  values: PropTypes.object,
  dark: PropTypes.bool,
  tags: PropTypes.array,
  onChange: PropTypes.func,
  onDatasetLoad: PropTypes.func
}

//export default ObjectProperties;

function mapStateToProps(state) {
  return {
    actions: state.actions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjectProperties)

//export default TableRow;
