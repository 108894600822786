/**
 * Created by kascode on 08.04.16.
 */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { Messages } from '../Messages/Messages'
import { Loader } from '../Loader/Loader'
import { API, getObjectNew, getObjectListNew } from '../../helpers/api'
import { buildPathDetailed } from '../../helpers'
import { ErrorWindow } from '../ErrorWindow/ErrorWindow'
import { Footer } from '../Footer/Footer'
import { HeaderPath } from '../HeaderPath/HeaderPath'

import './SelectView.scss'
import { getFullUri } from '../../helpers/index'
import logger from '../../helpers/logger'
import OrgIcon from '../../resources/images/org-2x.png'
import SysIcon from '../../resources/images/sys-2x.png'
import AppIcon from '../../resources/images/app-2x.png'
import PubIcon from '../../resources/images/pub-2x.png'
import SubIcon from '../../resources/images/sub-2x.png'
import { MainLayout } from '../../layouts/MainLayout/MainLayout'
import { Actions } from '../../actions'
import { History } from 'history'

const views = [
  {
    to: 'organizations',
    color: 'blue',
    title: 'Organizations',
    icon: OrgIcon
  },
  {
    to: 'systems',
    color: 'green',
    title: 'Systems',
    icon: SysIcon
  },
  {
    to: 'applications',
    color: 'yellow',
    title: 'Applications',
    icon: AppIcon
  },
  {
    to: 'publications',
    color: 'violet',
    title: 'Publications',
    icon: PubIcon
  },
  {
    to: 'select',
    color: 'red',
    title: 'Subscriptions',
    icon: SubIcon
  }
]

type MessagesProps = {
  location?: Location
  history?: History
  match?: any

  actions: Actions
  appState: any
  userState: any
}

type SelectViewState = {
  currentUserRole: any
  backendVersion: any
  loading: boolean
}

export class SelectView extends Component<MessagesProps, SelectViewState> {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      backendVersion: '',
      currentUserRole: undefined
    }
  }

  componentDidMount() {
    this.getOrganizationList()
    API.status()
      .get()
      .then((statusInfo) => {
        logger.info('API backend status', statusInfo)
        if (statusInfo.Assembly.indexOf('MetaService') !== -1) {
          let backendVersion = statusInfo.Assembly
          if (backendVersion.indexOf('Culture') !== -1) {
            backendVersion = backendVersion.substr(0, backendVersion.indexOf('Culture') - 2)
          }
          this.setState({
            backendVersion: backendVersion
          })
        }
      })
  }

  getOrganizationList() {
    this.setState({ loading: true })
    //this.props.actions.clearObjectList('organizations');

    if (!localStorage.currentUserId) {
      if (window.AUTH_URL === '/cognitologin') {
        this.props.history?.push('/cognitologin' + window.location.hash)
        return
      }
    }

    // reset token error count
    localStorage['unauthorizedCount'] = 0

    getObjectListNew(API.organizations, 'organization', this.props.actions).then((list) => {
      // //console.log("org list", list);
      let defaultIndex = 0
      list.forEach((org, i) => {
        if (org.identity.id === localStorage.getItem('currentOrganization')) defaultIndex = i
      })
      let org = list[defaultIndex]

      localStorage.setItem('currentOrganization', org.identity.id)
      this.setState({ loading: false })
      getObjectNew(API.organizations(org.identity.name), 'organization', this.props.actions).then(() => {
        // //console.log("getOrganizationList org as parent", org);
        //getObjectList(API.organizations(org.identity.name).issues.get(),'issue', this.props.actions, API.organizations(org.identity.name).issues.url(), org);
        getObjectListNew(API.organizations(org.identity.name).users, 'user').then((users) => {
          this.props.actions.receiveObjectListIntoProperty(users, org, 'organization', 'users')
        })

        this.checkUserRole(this.props)
      })
    })
  }

  checkUserRole = (props) => (ds, users) => {
    const userId =
      props.userState.profile && props.userState.profile.identity ? props.userState.profile.identity.name : null
    if (!userId) return
    // if user not found in object.users, request the user directly to get the role
    if (users.reduce((p, c) => p || c.profile.identity.name === userId, false) === false) {
      // @ts-ignore
      this.loadUserRole(props)
    } else {
      // get role from object.users
      this.setState({
        currentUserRole: users.reduce((p, c) => p || (c.profile.identity.name === userId ? c.role : false), false)
      })
    }
  }

  renderUserRole(org) {
    return org.users
      ? org.users.reduce(
          (p, c) => (c.user.id === localStorage.currentUserId ? c.role : p),
          this.state.currentUserRole || ''
        )
      : this.state.currentUserRole || ''
  }

  render() {
    const appState = this.props.appState
    let defaultOrg = null

    if (appState.organizations) {
      appState.organizations.forEach((org, index) => {
        if (!localStorage.getItem('currentOrganization') && index === 0) {
          defaultOrg = org
        }
        if (localStorage.getItem('currentOrganization') === org.identity.id) {
          defaultOrg = org
        }
      })
    }

    return (
      <>
        <MainLayout
          userState={this.props.userState}
          appState={this.props.appState}
          actions={this.props.actions}
          userRole={defaultOrg ? this.renderUserRole(defaultOrg) : null}
        >
          <ErrorWindow actions={this.props.actions} history={this.props.history} />
          {defaultOrg ? <HeaderPath path={buildPathDetailed(getFullUri(defaultOrg))} /> : null}
          <div className="SelectView">
            <div className="SelectView__upper">MAIN VIEW</div>
            <div className="SelectView__content">
              <div className="row">
                <div className="col-xs-10 col-xs-offset-1">
                  {!this.state.loading ? (
                    <div className="row">
                      {views.map((view) => (
                        <Link to={view.to} className={`ViewButton ViewButton_${view.color}`}>
                          <img className="ViewButton__icon" src={view.icon} />
                          <span className="ViewButton__label">{view.title}</span>
                        </Link>
                      ))}
                    </div>
                  ) : (
                    <div className="row">
                      <div className="loader_center">
                        <Loader />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {defaultOrg ? (
            <Messages
              appState={this.props.appState}
              userState={this.props.userState}
              actions={this.props.actions}
              object={defaultOrg}
              tabClassName="SelectView__tabs-navigation"
              objectType="organization"
            />
          ) : null}
          <Footer backendVersion={this.state.backendVersion} />
        </MainLayout>
      </>
    )
  }
}
