import { ParamsType } from '../../paramsType'

export const getObjectMessageApiEndpoint = (params: ParamsType, theme, tabName, name) => {
  const tab = theme === 'application' ? tabName : 'datasets'
  return `/organizations/${params.organizationName}/systems/${params.systemName}/applications/${params.applicationName}/${tab}/${name}/messages`
}

export const isFinalizable = (params: ParamsType) => {
  return params.organizationName && params.systemName && params.applicationName && !params.datasetName
}

export const defaultMessage = {
  description: 'Version Finalized and frozen for changes.',
  message: 'Default message',
  subject: 'Default subject'
}
