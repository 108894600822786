import moment from 'moment'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import { shortnames } from '../../constants/index'
import { track, trackObjectSet, trackUserSet } from '../../helpers/analytics'
import {
  API,
  apiURL,
  deleteMajorObject,
  getApiResource,
  getObjectListNew,
  getObjectNew,
  getObjectsWithConnected,
  getRequestFromPath,
  idAPI,
  sendObjectNew,
  singularType
} from '../../helpers/api'
import { getObjectByName } from '../../helpers/data'
import {
  buildPathDetailed,
  capitalize,
  columnsToType,
  datasetToObjectArray,
  deepCopy,
  editableState,
  getDefaultValue,
  getFullUri,
  getFullVersion,
  getObjectBackgroundColor,
  getParentLeftArrowImage,
  getParentObjectType,
  getUTCTime,
  getVersionStatus,
  isDark,
  itemState,
  majorObjectTypesProperties,
  nonCSCompare,
  objectNameFromPathByType,
  objectPictureUrl,
  objectPropertyCIWithDefault,
  objectTypeFromPath,
  pluralTypeForms,
  storeChildObjectLink,
  strToVersion,
  tableForwardClick,
  versionToStr
} from '../../helpers/index'
import logger from '../../helpers/logger'
import { getJsonSpecFields, getJsonSpecRecords } from '../../helpers/md'
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog'
import { DataDialogNew } from '../DataDialog/DataDialogNew'
import { DocumentList } from '../DocumentList/DocumentList'
import { ErrorWindow } from '../ErrorWindow/ErrorWindow'
import { HeaderPath } from '../HeaderPath/HeaderPath'
import { JSONTreeCopy } from '../JSONTreeCopy/JSONTreeCopy'
import { Loader } from '../Loader/Loader'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import { ObjectDescription } from '../ObjectDescription/ObjectDescription'
import { ObjectHeader } from '../ObjectHeader/ObjectHeader'
import { PasteDialogOld } from '../PasteDialog/PasteDialog'
import { SettingsDialog } from '../SettingDialog/SettingsDialog'
import { DocsDialog } from './DocsDialog'
import './MajorObject.scss'
import { PasteDialog } from './PasteDialog'
import { TagsDialog } from './TagsDialog'

//import ObjectProperties from '../ObjectProperties/ObjectProperties';

import iArrowDownClose from '../../resources/images/arrow-down-close.png'

import i404 from '../../resources/images/404.png'
import { downloadSelectorAsPDF } from '../../helpers/pdfDownloader'
import { getTailButtonLabel } from '../../helpers/helperComponents'
import { History } from 'history'
import { Version } from '../../helpers/indexNew'
import { setRef } from '@material-ui/core'
import { Actions } from '../../actions'
import { getRoleInfo, OrganizationRoleEnum } from './MajorObjectHelper'
import { Tab } from '../FilteredTabbedTable/TabType'

const excludedFields = [
  'identity',
  'object',
  'userrole',
  'tags',
  'documents',
  'structure',
  'layout',
  'data',
  'parent',
  'picture',
  'properties',
  'users',
  'version',
  'user',
  'versions',
  'datasets',
  'document',
  'property',
  'type',
  'publicationcount',
  'subscriptioncount',
  'lastapprovedversion',
  'layouts',
  'elements',
  'operations',
  'parameters',
  'responses',
  'examples',
  'exaples',
  'interfaces',
  'gitintegration',
  'gitpassword',
  'giturl',
  'gituser',
  'subscription',
  'checkpoints',
  'activities'
]

// TODO: extend from redux store

type MajorObjectProps = {
  location?: Location
  history?: History
  match?: any
  majorObject: any
  appState?: any
  userState?: any
  actions: Actions

  childObjectType?: string

  onEdit?: any
  handleIssueCollapseButton?: any
}

type DeleteMajorObject = {
  objectType: any
  obj: any
  error?: any
}

type Settings = {
  type?: string
  version?: Version
}

type MajorObjectState = {
  collapsed: boolean // like this
  editingTags: boolean
  editingDocuments: boolean
  addingChild: boolean
  pastingChild: boolean
  pastingChildName: string
  expandDescriptions: boolean
  showCannotDelete: boolean
  parentObjectRole: boolean
  updateExistObject: boolean
  snackIsOpen: boolean
  updateExistObjectName: string
  showUpdateExistObjectDialog: boolean
  childObjectType: string
  deleteMajorObject?: DeleteMajorObject | null

  objectType: string
  objectName: string
  currentUserRole: string
  id: any
  editingProperties: boolean
  editingSetting: boolean
  jsonOpen: boolean
  locale: any
  uploadingChildObjectType: any
  uploadingError: any
  uploadingErrorJson: string
  uploadingJson: any
  updateExistObjectDialog: boolean
  errorStatus: any
  tabsLoaded: any
  replacingObject: any
  replacingChild: boolean
  explicitOrganizationRole: OrganizationRoleEnum
  explicitParentRole: OrganizationRoleEnum
  deleteChildObject: any
  organizationClassObject: any

  // Application
  defaultLocale: string
  importingDataset: boolean
  replacingChildName: any
  showSQLDesigner: boolean
  fullSizeSQLDesigner: boolean

  // org
  childObjectUsage: string

  loadingCheckpoints: boolean
  expandTexts: boolean
  editingChild: boolean | null
  activeMinorObject: any
  activeMinorObjectEditable: boolean
  childInEditMode: boolean
  showChart: boolean

  //Dataset
  keySettingsOpen?: boolean
  importingData?: boolean | string
  loadingDataError?: string
  importSettings?: {
    delimiter: string
    quote: string
    escape: string
    replaceData: boolean
  }
  recordToEdit: any[]
  recordToEditId?: any
  recordsToDelete?: any

  //View
  currentLocale?: any
  creatingLocale?: any

  //Interface
  editingChildMode?: number | boolean
  editingChildType?: any
  selectedExamples?: any
  examplesRunResults?: any

  editingChildId?: null | string
  datasetEditMode?: boolean

  // Publication
  editingDatasetPublication?: boolean
  editingDatasetPublicationId?: string
  viewingDatasetPublication?: boolean
  viewingDatasetPublicationId?: string

  //Environment
  topologies: any[]
  deployments: any[]
  applications: any[]
  childrenLoaded?: boolean

  //Subscription
  editingDatasetSubscription?: boolean
  editingInterfaceSubscription?: boolean
  editingInterfaceSubscriptionId?: number
  editingDatasetSubscriptionId?: number
  editingTopologySubscription?: boolean
  editingTopologySubscriptionId?: number
  viewingInterfaceSubscription?: boolean
  viewingInterfaceSubscriptionId?: string
  viewingTopologySubscription?: boolean
  viewingTopologySubscriptionId?: string
  viewingDatasetSubscription?: boolean
  viewingDatasetSubscriptionId?: string
  interfaceEditMode?: boolean
  topologyEditMode?: boolean

  previousVersion?: any
  packageOld?: any
}

export class MajorObject extends Component<MajorObjectProps, MajorObjectState> {
  _loadingParentUserRole: boolean
  viewOrEdit: any
  // getDataRequestQuery: any // ???
  _loadingOrganizationUserRole = false
  _loadingOrganizationClassObject = false

  constructor(props) {
    super(props)

    this.state = {
      collapsed: false,
      editingTags: false,
      editingDocuments: false,
      addingChild: false,
      pastingChild: false,
      pastingChildName: '',
      expandDescriptions: false,
      showCannotDelete: false,
      parentObjectRole: false,
      updateExistObject: false,
      snackIsOpen: false,
      updateExistObjectName: '',

      showUpdateExistObjectDialog: false,
      childObjectType: '',
      objectType: '',
      objectName: '',
      deleteMajorObject: undefined,
      currentUserRole: '',
      id: undefined,
      editingProperties: false,
      editingSetting: false,
      jsonOpen: false,
      locale: undefined,
      uploadingChildObjectType: undefined,
      uploadingError: undefined,
      uploadingErrorJson: '',
      uploadingJson: undefined,
      updateExistObjectDialog: false,
      errorStatus: undefined,
      tabsLoaded: undefined,
      replacingObject: undefined,
      replacingChild: false,
      explicitOrganizationRole: OrganizationRoleEnum.NOROLE,
      explicitParentRole: OrganizationRoleEnum.NOROLE,
      deleteChildObject: undefined,
      organizationClassObject: undefined,

      // Application
      defaultLocale: '',
      importingDataset: false,
      replacingChildName: undefined,
      showSQLDesigner: false,
      fullSizeSQLDesigner: false,
      childObjectUsage: '',

      loadingCheckpoints: false,
      expandTexts: false,
      editingChild: false,
      activeMinorObject: undefined,
      activeMinorObjectEditable: false,
      childInEditMode: false,
      showChart: false,
      recordToEdit: [],

      topologies: [],
      deployments: [],
      applications: []
    }
    // if (this.props.match.params) localStorage.currentOrganizationName = this.props.match.params.organizationName
    if (this.getObjectType(props) !== 'issue') {
      track(this.getObjectType(props), 'component')
      trackObjectSet(this.getObjectType(props))
    }

    this._loadingParentUserRole = false
  }

  getObjectType(props) {
    if (this.state.objectType) return this.state.objectType

    if (props.majorObject) {
      const mo = props.majorObject
      return mo.type
    }
    let index = this.props.location?.pathname.indexOf('/' + this.state.objectType + '/')
    if (index === -1) index = this.props.location?.pathname.length
    const path = this.props.location?.pathname.substr(0, index)
    if (path) {
      const stepsFromEnd = path[path.length - 1] === '/' ? 3 : 2
      const splitPath = path.split('/')
      const objectTypePlural = splitPath[splitPath.length - stepsFromEnd]
      return objectTypePlural.slice(0, objectTypePlural.length - 1)
    }
  }

  /**
   * If there's no objectName in state it sets it. Always returns object name
   * type: unused
   * props: pass other props
   * @returns {*}
   */
  getObjectName = (type = '', props = this.props) => {
    if (!this.state.objectName) {
      const objectName = props.majorObject
        ? props.majorObject.identity.name
        : props.match?.params[this.getObjectType(props) + 'Name']
      this.setState({
        objectName: objectName
      })
      return objectName
    } else {
      return this.state.objectName
    }
  }

  /**
   * Returns current object used by component
   * @returns {*}
   */
  getObject() {
    return getObjectByName(this.props.appState, this.state.objectType, this.getObjectName())
  }

  formatValue = (value) => {
    //((typeof(obj[propName] || obj.object[propName]) === 'object') ? JSON.stringify(obj[propName] ? obj[propName] : (obj.object && obj.object[propName] ? obj.object[propName] : "")): ())
    if (typeof value === 'string') return value
    else if (typeof value === 'boolean') return value + ''
    else if (typeof value === 'object')
      //return JSON.stringify(value);
      return value
    else if (typeof value === 'undefined') return ''
    else return value + ''
  }

  /**
   * Returns dataset with fields for properties of major object and major object.object
   */
  getObjectProperties = () => {
    const obj = this.getObject()
    let tp = majorObjectTypesProperties(this.state.objectType, obj)

    //console.log("MajorObject::getObjectProperties", tp);

    const filteredtp = tp.filter((prop) => {
      return !excludedFields.includes(
        prop.identity.name.toLowerCase()
      ) /* && (!prop.permission || !prop.permission === 1) && (!prop.access || !prop.access === 1)*/
    })

    //console.log("getObjectProperties filtered", filteredtp);

    const ret = [...filteredtp].sort((a, b) => a.identity.name.localeCompare(b.identity.name))
    //console.log("MajorObject:getObjectProperties", obj, ret);

    return { structure: { fields: ret } }
  }

  /**
   * Returns values of object properties as {propertyName: propertyValue} object
   */
  getObjectPropertyValues = () => {
    const obj = this.getObject()
    if (!obj || !obj.object) return {}

    //console.log("MajorObject:getObjectPropertyValues", obj);
    let ret = {}
    this.getObjectProperties().structure.fields.map((field) => {
      const name = field.identity.name
      ret[field.identity.name] = objectPropertyCIWithDefault(
        obj,
        name,
        objectPropertyCIWithDefault(obj.object, name, getDefaultValue(field.type))
      )
    })
    return ret
  }

  getPublicationTabsProps = (hideAddButton = false, emptyText = ''): Tab => {
    return {
      title: 'publications',
      filters: [
        { name: '', width: 20 },
        { name: 'search', width: 308 },
        { name: 'tags', width: 300 },
        {
          name: 'status',
          width: 150
        }
      ],
      columns: [
        {
          name: 'name',
          type: columnsToType.getType('objectName'),
          displayName: 'Publication name',
          width: 220
        },
        {
          name: 'description',
          displayName: this.renderDescriptionColumnHeader(),
          type: this.getDescriptionType(),
          width: 662
        },
        { name: 'version', type: columnsToType.getType('version'), width: 150 },
        //{name: 'deployment', type: columnsToType.getType('deployment'), width: 150},
        //{name: 'architects', type: columnsToType.getType('architects'), displayName: 'Architect', width: 150},
        { name: 'rating', type: columnsToType.getType('rating'), width: 90 },
        {
          name: 'subscriptions',
          type: columnsToType.getType('subscriptions'),
          displayName: 'Sub',
          width: 60
        }
      ],
      forward: tableForwardClick(this.props, this.getObject(), 'publications'),
      tailButtons: [
        {
          label: this.viewOrEdit,
          onClick: (obj) => {
            this.props.history?.push('/view' + getFullUri(obj))
          }
        },
        {
          label: getTailButtonLabel('Delete'),
          onClick: (obj) => this.deleteMajorObject('publication', obj)
        }
      ],
      bottomButtons: hideAddButton
        ? []
        : [
            {
              label: '+ Add publication',
              onClick: () => {
                this.setState({
                  addingChild: true,
                  childObjectType: 'publication'
                })
              }
            },
            {
              label: 'Upload publication',
              onClick: () => {
                this.selectFileForUploadChildObject('publication')
              }
            }
          ],
      topButtons: hideAddButton
        ? []
        : [
            {
              label: '+ Add',
              onClick: () => {
                this.setState({
                  addingChild: true,
                  childObjectType: 'publication'
                })
              }
            }
          ],
      emptyText: emptyText
    }
  }

  getSubscriptionTabsProps = (hideAddButton = false, emptyText = ''): Tab => {
    const isEditable = this.checkEditable()

    return {
      title: 'subscriptions',
      filters: [
        { name: '', width: 17 },
        { name: 'search', width: 275 },
        { name: '', width: 190 },
        { name: 'tags', width: 275 }
      ],
      columns: [
        {
          name: 'name',
          type: columnsToType.getType('objectName'),
          displayName: 'Subscription name',
          width: 220
        },
        {
          name: 'description',
          displayName: this.renderDescriptionColumnHeader(),
          type: this.getDescriptionType(),
          width: 384
        },
        { name: 'version', type: columnsToType.getType('version'), width: 80 },
        {
          name: 'publication',
          type: columnsToType.getType('path_link'),
          displayName: 'Publication',
          width: 390
        },
        {
          name: 'approvedDate',
          type: columnsToType.getType('updatedDate'),
          displayName: 'Approve Date',
          width: 110
        }
      ],
      forward: tableForwardClick(this.props, this.getObject(), 'subscriptions'),
      tailButtons: [
        {
          label: this.viewOrEdit,
          onClick: (obj) => {
            this.props.history?.push('/view' + getFullUri(obj))
          }
        },
        {
          label: getTailButtonLabel('Delete'),
          onClick: (obj) => this.deleteMajorObject('subscription', obj)
        }
      ],
      contextMenuButtons: [
        {
          label: 'Copy subscription',
          data: { action: 'copy' },
          onClick: (e, data, t) => {
            // console.log("Dataset::Copy field", data);
            const fullPath = getFullUri(data)
            getObjectNew(getRequestFromPath(fullPath), 'subscriptions', null).then((resp) => {
              localStorage.clipboard = JSON.stringify(resp)
              localStorage.clipboardType = 'subscriptions'
            })
          }
        }
      ].concat(
        !isEditable
          ? []
          : [
              {
                label: 'Paste subscription',
                data: { action: 'paste' },
                onClick: () => {
                  //console.log("Paste dataset");

                  if (!localStorage.clipboard) {
                    alert('No element copied')
                    return
                  }
                  if (localStorage.clipboardType !== 'subscriptions') {
                    alert('No element copied')
                    return
                  }

                  this.setState({
                    pastingChild: true,
                    pastingChildName: JSON.parse(localStorage.clipboard).identity.name
                  })
                },
                // @ts-ignore
                showInBottom: true
              }
            ]
      ),
      bottomButtons: hideAddButton
        ? []
        : [
            {
              label: '+ Add subscription',
              onClick: () => {
                this.setState({
                  addingChild: true,
                  childObjectType: 'subscription'
                })
              }
            },
            {
              label: 'Upload subscription',
              onClick: () => {
                this.selectFileForUploadChildObject('subscription')
              }
            }
          ],
      topButtons: hideAddButton
        ? []
        : [
            {
              label: '+ Add',
              onClick: () => {
                this.setState({
                  addingChild: true,
                  childObjectType: 'subscription'
                })
              }
            }
          ],
      emptyText: emptyText
    }
  }

  childIsMinor = () => {
    return this.state.childObjectType === 'field' || this.state.childObjectType === 'layout'
  }

  realDeleteMajorObject(objectType, obj) {
    track(this.getObjectType(this.props), 'delere_child')
    deleteMajorObject(this.props.appState, this.props.actions, objectType, obj.identity.name, (error) => {
      this.setState({
        deleteMajorObject: {
          objectType: objectType,
          obj: obj,
          error: error
        }
      })
    })
    this.setState({ deleteMajorObject: null })
  }

  deleteMajorObject(objectType, obj) {
    console.log('deleteMajorObject', obj, this.state.currentUserRole)
    if (obj && obj.version && obj.version.completion === 'approved') {
      if (this.state.currentUserRole !== 'Architect') {
        //alert("Only Architects can delete Approved objects");
        this.showCannotDelete('Only Architect can delete approved objects')
        return false
      }
    }

    this.setState({
      deleteMajorObject: {
        objectType: objectType,
        obj: obj
      }
    })
  }

  showCannotDelete(messageText) {
    this.setState({ showCannotDelete: messageText })
  }

  reload = () => {
    //console.log("MajorObject:reload", action, this.props);
    /*
    const request = this.getDataRequestQuery(this.props).objectRequest;

    return getObjectMergedNew(request, this.state.objectType, this.props.actions, true);
    */
    getObjectsWithConnected(
      // @ts-ignore
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions,
      true
    )
  }

  sendObjectFailed(error) {
    if (error.status === 409) {
      //alert("The object was modified");
    }
    console.error('sendObjectFailed', error)
    alert(JSON.parse(error.responseText).error.message)
  }

  onDescriptionChange(new_value) {
    const majorObject = this.getObject()
    track(this.getObjectType(this.props), 'description_edit')

    this.props.actions.updateDescription(
      majorObject.identity.id,
      pluralTypeForms.get(this.state.objectType),
      new_value,
      null
    )

    sendObjectNew(
      idAPI[getApiResource(this.state.objectType)](majorObject.identity.id),
      'patch',
      this.props.actions,
      majorObject,
      {
        identity: {
          description: new_value
        }
      }
    ).then(
      () => {
        //this.reload();
      },
      (error) => this.sendObjectFailed(error)
    )

    /*
    sendObject(idAPI[getApiResource(this.state.objectType)](majorObject.identity.id).patch({
      identity: {
        description: new_value
      }
    }), this.state.objectType, this.props.actions, getFullUri(majorObject)).then(()=>{
      this.props.actions.updateDescription(majorObject.identity.id, this.state.objectType + 's',new_value, null);
      //this.reload();
    }, (error) => this.sendObjectFailed(error));
    */
  }

  onPictureChange(picture) {
    const majorObject = this.getObject()
    const new_obj = { object: { picture: 'logo.png' } }
    track(this.getObjectType(this.props), 'picture_edit')

    this.uploadFile(
      picture,
      () => {
        let url = objectPictureUrl(getFullUri(majorObject), majorObject.object.picture)
        window.apiCache.deleteObject(url)

        this.props.actions.updateMajorObject(majorObject.identity.id, pluralTypeForms.get(this.state.objectType), {
          object: Object.assign({}, majorObject.object, {
            picture: 'logo.png'
          })
        })
        sendObjectNew(
          idAPI[getApiResource(this.state.objectType)](majorObject.identity.id),
          'patch',
          this.props.actions,
          majorObject,
          new_obj
        ).then(
          () => {},
          (error) => this.sendObjectFailed(error)
        )
      },
      'logo.png'
    )
  }

  onObjectUsersChange(new_users) {
    // //console.log("objectUsersChange", new_users);
    track(this.getObjectType(this.props), 'users_edit')
    this.props.actions.updateUsers(this.state.id, pluralTypeForms.get(this.state.objectType), new_users)
  }

  onTagsEdit() {
    this.setState({ editingTags: true })
  }

  onTagsClose() {
    this.setState({ editingTags: false })
  }

  onTagsChange(tags, closeDialog, onSent) {
    let majorObject = this.getObject()
    if (!majorObject) {
      majorObject = this.props.majorObject
    }
    track(this.getObjectType(this.props), 'tags_edit')
    console.log('MajorObject:onTagsChange', tags, majorObject, this.state, this.props)

    // Prepare patch or put request to update tags
    let method = 'put'
    let new_obj = Object.assign({}, majorObject) // {object: {history: {updated: majorObject.object.history.updated}}};
    new_obj.object.tags = tags

    // Send update to the service.
    console.log('MajorObject:onTagsChange:SEND', method, new_obj, majorObject)

    this.props.actions.updateTags(majorObject.identity.id, this.state.objectType, new_obj.object.tags)
    sendObjectNew(
      idAPI[getApiResource(this.state.objectType)](majorObject.identity.id),
      method,
      this.props.actions,
      majorObject,
      new_obj
    ).then(
      (result) => {
        console.log('MajorObject:onTagsChange:RESULT', tags, result)
        if (onSent) onSent(closeDialog)
      },
      (error) => {
        console.log('MajorObject:onTagsChange:ERROR', tags, error)
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  onDocumentsEdit() {
    this.setState({ editingDocuments: true })
  }

  onDocumentsClose() {
    this.setState({ editingDocuments: false })
  }

  /**
   * Save edited docs, upload new and delete removed ones
   * @param {array} docs
   * @param closeDialog
   * @param onSent
   */
  onDocumentsChange(docs, closeDialog, onSent) {
    let majorObject = this.getObject()
    if (!majorObject) {
      majorObject = this.props.majorObject
    }
    track(this.getObjectType(this.props), 'docs_edit')
    console.log('MajorObject:onDocumentsChange', docs, majorObject, this.state, this.props)

    // Prepare patch or put request to update tags
    let method = 'put'
    let new_obj = Object.assign({}, majorObject) // {object: {history: {updated: majorObject.object.history.updated}}};
    new_obj.object.documents = docs

    // Send update to the service.
    console.log('MajorObject:onDocsChange:SEND', method, new_obj, majorObject)

    this.props.actions.updateMajorObjectDocuments(
      majorObject.identity.id,
      this.state.objectType,
      new_obj.object.documents
    )
    sendObjectNew(
      idAPI[getApiResource(this.state.objectType)](majorObject.identity.id),
      method,
      this.props.actions,
      majorObject,
      new_obj
    ).then(
      (result) => {
        console.log('MajorObject:onDocumentsChange:RESULT', docs, result)
        if (onSent) onSent(closeDialog)
      },
      (error) => {
        console.log('MajorObject:onDocumentsChange:ERROR', docs, error)
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  /**
   * Save edited docs, upload new and delete removed ones
   * @param {array} new_docs
   * @param {array} [uploaded_files]
   * @param {array} [deleted_files]
   */
  onDocumentsChange1(new_docs, uploaded_files, deleted_files) {
    console.log('MajorObject:onDocumentsChange', new_docs)
    track(this.getObjectType(this.props), 'documents_edit')
    const promises = []

    uploaded_files.map((file) => {
      // @ts-ignore
      promises.push(this.uploadFile(file))
    })

    deleted_files.map((file) => {
      // @ts-ignore
      promises.push(this.deleteFile(file))
    })

    const majorObject = this.getObject()
    const doc_list = new_docs.map((doc) => {
      return Object.assign({}, doc, {
        uri: doc.uri || getFullUri(majorObject) + '/' + doc.identity.name
      })
    })
    let new_obj = {
      object: {
        /*history: {updated: majorObject.object.history.updated},*/ documents: doc_list
      },
      identity: majorObject.identity
    }
    let method = 'patch'
    if (deleted_files && deleted_files.length) {
      method = 'put'
      new_obj = Object.assign({}, majorObject)
      new_obj.object.documents = doc_list
    }

    console.log('MajorObject:onDocumentsChange:SEND', method, new_obj, majorObject)

    promises.push(
      // @ts-ignore
      sendObjectNew(
        idAPI[getApiResource(this.state.objectType)](majorObject.identity.id),
        method,
        this.props.actions,
        majorObject,
        new_obj
      )
    )

    return new Promise<void>((resolve, reject) => {
      Promise.all(promises).then(
        () => {
          this.props.actions.updateMajorObjectDocuments(majorObject.identity.id, this.state.objectType, doc_list)
          resolve()
        },
        (error) => {
          this.sendObjectFailed(error)
          reject(error)
        }
      )
    })
  }

  onPopertiesOpen() {
    this.setState({
      editingProperties: true
    })
  }

  onPopertiesClose() {
    this.setState({
      editingProperties: false
    })
  }

  /**
   * finalize version of advanced settings and send approval request
   * @param settings
   * @param closeDialog
   * @param onSent
   */
  onSaveAdvancedSettings = (settings, closeDialog, onSent) => {
    const app = this.getObject()

    let newSettings = deepCopy(app.settings)
    newSettings['identity'] = { name: 'settings' }
    newSettings['type'] = 'Settings'
    if (newSettings.object) newSettings.object.properties = settings
    else newSettings.object = { properties: settings }

    console.log('MajorObject:onSettingsChange', settings, app, newSettings, this.state)

    // @ts-ignore
    this.getDataRequestQuery(this.props)
      .objectRequest.settings.put(newSettings)
      .then(
        (result) => {
          console.log('MajorObject:onSettingsChange:SETTINGS', settings, app, newSettings, result)

          // @ts-ignore
          this.getDataRequestQuery(this.props)
            .objectRequest.settings.get()
            .then(
              (newSettings) => {
                console.log('New received settings', newSettings)
                this.props.actions.updateAdvancedSettings(app.identity.id, this.state.objectType, newSettings)
                if (onSent) onSent(closeDialog)
              },
              (error) => {
                this.props.actions.setError(null)
                if (onSent) onSent(closeDialog, error)
              }
            )
        },
        (error) => {
          console.log('MajorObject:onSettingsChange:ERROR', settings, app, newSettings, error)
          this.props.actions.setError(null)
          if (onSent) onSent(closeDialog, error)
        }
      )
  }

  /**
   * finalize version of advanced settings and send approval request
   * @param message
   * @param newVersion
   * @param close
   * @param onSent
   */
  onFinalizeAdvancedSettings = (message, newVersion, close, onSent) => {
    const object = this.getObject()
    const objectUri = getFullUri(object)

    console.log('MajorObject:onFinalizeAdvancedSettings', message, newVersion, object, objectUri, this.props)

    if (object && object.settings && newVersion && !nonCSCompare(versionToStr(object.settings.version), newVersion)) {
      let newSettings: Settings = {} //{object: {type: 'Settings'}};
      //newSettings = deepCopy(object.settings);
      newSettings['type'] = 'Settings'
      newSettings.version = strToVersion(newVersion)

      let apiResource = idAPI[getApiResource(this.state.objectType)](object.identity.id)
      apiResource = object.settings ? apiResource.settings() : apiResource
      sendObjectNew(apiResource, 'patch', this.props.actions, newSettings, null, true).then(
        (result) => {
          console.log('MajorObject:onFinalizeAdvancedSettings:VERSION', object, newSettings, result)

          this.sendFinalizeAdvancedSettings(message, newVersion, close, onSent)
        },
        (error) => {
          console.log('MajorObject:onSettingsChange:ERROR', object, newSettings, error)
          this.props.actions.setError(null)
          if (onSent) onSent(close, error)
        }
      )
    } else if (message.message) {
      this.sendFinalizeAdvancedSettings(message, newVersion, close, onSent)
    }
  }

  sendFinalizeAdvancedSettings = (message, newVersion, close, done) => {
    const object = this.getObject()
    const objectUri = getFullUri(object)

    console.log('MajorObject:sendFinalizeAdvancedSettings', message, newVersion, object, objectUri, this.props)

    track(this.getObjectType(this.props), 'settings_finalize')

    if (message.message) {
      //console.log(this.getDataRequestQuery(this.props).objectRequest.url());
      //console.log('url', this.getDataRequestQuery(this.props).objectRequest.messages.url());
      // @ts-ignore
      let request = this.getDataRequestQuery(this.props).objectRequest.messages
      let issue = {}
      if (message.subject && message.message) {
        issue = {
          identity: {
            description: message.subject
          },
          attached: {
            name: objectUri
          },
          notes: message.description
        }
      } else if (message.issue && message.message) {
        issue = {
          identity: message.issue.identity,
          attached: {
            name: objectUri
          }
        }
      }

      let payload = {
        class: 'Message',
        action: 'FinalizeVersion',
        type: 'VersionApproval',
        attached: {
          name: objectUri + '/settings//versions/' + newVersion
        },
        sender: {
          id: localStorage['currentUserId'],
          description: this.props.userState?.profile.alias
        },
        text: message.message,
        issue: issue
      }

      console.log('MajorObject:sendFinalizeAdvancedSettings:SEND', payload)
      //sendObject(request.post(payload), 'message', this.props.actions, request.url())
      sendObjectNew(request, 'post', null, payload, null, true).then(
        (result: any) => {
          // Reload settings
          let apiResource = idAPI[getApiResource(this.state.objectType)](object.identity.id)
          apiResource = object.settings ? apiResource.settings() : apiResource
          //apiResource.get()
          getObjectNew(apiResource, 'setting', null, true).then(
            (newSettings) => {
              console.log('MajorObject:sendFinalizeAdvancedSettings:RELOAD', newSettings)
              this.props.actions.updateAdvancedSettings(object.identity.id, this.state.objectType, newSettings)
              if (done) done(close)
            },
            (error) => {
              this.props.actions.setError(null)
              if (done) done(close, error)
            }
          )

          // Reload issues
          getObjectNew(idAPI.issues(result.issue.identity.id), 'issue', null, true).then((issue) => {
            this.reload()
          })
        },
        (error) => {
          done(close, error)
        }
      )
    }
  }

  /**
   * Show approved version issue for approval
   */
  showApproveVersionIssue = () => {
    const object = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const objectUri = getFullUri(object)

    // close dialog
    this.setState({ editingSetting: false })

    //alert("Please approve the version using Messages");
    // todo: auto scroll to the issue
    //

    setTimeout(() => {
      //this.refs.messages.scrollToTopIssue();
      window.scrollTo(0, document.body.clientHeight)
    }, 1000)
    //

    let uri: null | string = null
    let obj = object.settings && object.settings.object ? object.settings.object : object.object
    if (obj && obj.history && obj.history.completions) {
      let completion = obj.history.completions.find(
        (completion) => nonCSCompare(completion.status, 'Finalized') && completion.completed
      )
      if (completion && completion.completedByMessage) {

        uri = completion.completedByMessage.replace('/issues/', '#issues/')

      }
    }

    if (!uri) {
      uri = (object.settings ? objectUri : object.object.parent.name) + '#issues/approve'
    }

    this.props.history?.push('/view' + uri)
    logger.info('MajorObject:showApproveVersionIssue:URI', { object, uri, obj }, this.state, this.props)

    //console.log("MajorObject:showApproveVersionIssue:VIEW", uri, object);
    // Messages component uses Connect with withRef parameter, and to call Messages's method we get the actual Messages instance by calling Connect's getWrappedInstance method

    // special case: user had chosen "Use existing issue" when finalized object, and chose issue from current object.
    // loading will not be triggered by url change, so we need to call reload here. otherwise issue won't be loaded and displayed.
    if (uri.indexOf(objectUri) !== -1 || object.settings) {
      this.reload()

      // workaround:
      // in Dataset, when Approve button was clicked after Finalizing and Selecting Existing Issue,
      // window was scrolled to Issues tab, but issues were not reloaded (they were cached)
      // and because of that, older version of issue was passed into Messages component and Approve button did not work
      // here cache is cleared so that issues will be reloaded, and newer object.history.updated will be loaded for the issue, so that Approve button will work correctly.
      // todo: clear only cached issues, not all objects and lists
      window.apiCache.store = {}
    }
  }

  /**
   * create new draft version of advanced settings
   * @param version - version for draft to create
   * @param close - is dialog need to close after completion
   * @param done - report when you are done
   */
  onCreateDraftAdvancedSettings(version, close, done) {
    const object = this.getObject()
    const objectUri = getFullUri(object)
    track(this.getObjectType(this.props), 'settings_draft')

    let newSettingsObject: Settings = {}
    newSettingsObject = deepCopy(object.settings)
    newSettingsObject.version = strToVersion(version.version)

    console.log('MajorObject:onCreateDraftAdvancedSettings', version, newSettingsObject, object, objectUri, this.props)

    // @ts-ignore
    this.getDataRequestQuery(this.props)
      .objectRequest.settings.put(newSettingsObject)
      .then(
        (result) => {
          // Reload settings
          let apiResource = idAPI[getApiResource(this.state.objectType)](object.identity.id)
          apiResource = object.settings ? apiResource.settings() : apiResource
          apiResource.get().then(
            (newSettings) => {
              console.log('MajorObject:onCreateDraftAdvancedSettings:RELOAD', newSettings)
              this.props.actions.updateAdvancedSettings(object.identity.id, this.state.objectType, newSettings)
              this.reload()
              if (done) done(close)
            },
            (error) => {
              this.props.actions.setError(null)
              if (done) done(close, error)
            }
          )
        },
        (error) => {
          done(close, error)
        }
      )
  }

  onPropertiesChange = (new_properties, closeDialog, onSent) => {
    const currentObject = this.getObject()
    const fields = this.getObjectProperties().structure.fields
    console.log('MajorObject:onPropertiesChange', new_properties, fields, currentObject)
    track(this.getObjectType(this.props), 'properties_edit')

    let accessLimiting = false
    for (let prop in new_properties) {
      if (nonCSCompare(prop, 'Access')) {
        if (nonCSCompare(new_properties[prop], 'Private')) accessLimiting = true
      }
    }
    console.log('accessLimiting', accessLimiting)

    if (accessLimiting) {
      const users = this.getObject().users || []
      console.log('users', users)
      const haveArchitect = users.reduce((found, user) => (nonCSCompare(user.role, 'Architect') ? true : found), false)
      console.log('haveArchitect', haveArchitect)
      if (!haveArchitect) {
        return new Promise((resolve, reject) => {
          const err = 'Assign Architect role for at least one user before making an object Private or Internal'
          console.error(err)
          if (onSent)
            onSent(closeDialog, {
              response: JSON.stringify({ error: { message: err } })
            }) //JSON.stringify({response: {error: {message: err}}})
          reject(err)
        })
      }
    }

    let sendingObject = { identity: {}, object: {} }
    let method = 'patch'

    for (let prop in new_properties) {
      //console.log("MajorObject:onPropertiesChange:PROP", prop, sendingObject);
      let field = fields.find((cur) => nonCSCompare(cur.identity.name, prop))
      if (field.fromObject) {
        sendingObject.object[prop] = new_properties[prop]
      } else if (prop === 'Locale' && currentObject.type === 'view') {
        console.log('MajorObject:onPropertiesChange:PROP_LOCALE', prop, new_properties[prop])
        sendingObject[prop] = parseInt(new_properties[prop])
      } else if (prop === 'Definitions') {
        // if we change our array with definitions we must update method to put
        new_properties[prop].length === currentObject[prop.toLowerCase()].length ? (method = 'patch') : (method = 'put')
        sendingObject[prop.toLowerCase()] = new_properties[prop]
      } else {
        sendingObject[prop] = new_properties[prop]
      }
    }

    if (sendingObject.identity) {
      sendingObject.identity = Object.assign({}, currentObject.identity)
    }

    return new Promise((resolve, reject) => {
      console.log('MajorObject:onPropertiesChange:SEND', this.state, currentObject, sendingObject)
      this.props.actions.receiveObject(
        this.state.objectType,
        currentObject.identity.name,
        Object.assign({}, currentObject, sendingObject, {
          object: Object.assign({}, currentObject.object, sendingObject.object)
        })
      )

      sendObjectNew(
        idAPI[getApiResource(this.state.objectType)](currentObject.identity.id),
        method,
        this.props.actions,
        currentObject,
        sendingObject
      ).then(
        () => {
          if (onSent) onSent(closeDialog)
          resolve('Object properties saved')
        },
        (err) => {
          const message = err.response ? JSON.parse(err.response).error.message : 'Error ' + err.status
          if (onSent) onSent(closeDialog, err)
          reject(message)
        }
      )
    })
  }

  loadAdvancedSettingsVersionOptions = () => {
    const app = this.getObject()

    return getRequestFromPath(getFullUri(app)).settings.versions.get()
  }

  onDeleteUser(user) {
    //console.log("onDeleteUser",id);
    const app = this.getObject()
    track(this.getObjectType(this.props), 'user_delete')
    //idAPI[getApiResource(this.state.objectType)](app.identity.id).users(obj.name).delete().then(()=>{
    getRequestFromPath(getFullUri(app))
      .users(user.name)
      .delete()
      .then(() => {
        this.props.actions.updateUsers(
          app.identity.id,
          pluralTypeForms.get(this.state.objectType),
          app.users.filter((u) => u.user.name !== user.name)
        )
      })
  }

  /**
   * Handle add new user to major object
   * @param {object} newUser - object describing new user
   * @param {boolean} [close] - should we close window
   * @param {function} [onSent] - after send callback
   */
  onAddUser(newUser, close, onSent) {
    const app = this.getObject()
    const currentUserRole = this.state.currentUserRole
    //console.log("MajorObject::onAddUser", newUser, close);
    track(this.getObjectType(this.props), 'user_add')

    // this.props.userState

    if (nonCSCompare(currentUserRole, 'architect')) {
      getRequestFromPath(getFullUri(app))
        .users.post({
          object: { id: app.identity.id, name: app.identity.name },
          role: newUser.role,
          user: newUser.profile.identity
        })
        .then(
          () => {
            const approved =
              this.state.currentUserRole === 'Architect'
                ? {
                    history: {
                      completion: [
                        {
                          status: 'Approved',
                          completed: true
                        }
                      ]
                    }
                  }
                : {}

            this.props.actions.updateUsers(
              app.identity.id,
              pluralTypeForms.get(this.state.objectType),
              app.users.concat(Object.assign({}, newUser, approved))
            )
            if (onSent) onSent(close)
            //console.log("onUserRoleChange", localStorage.currentUserId, newUser);
            if (localStorage.currentUserId === newUser.profile.identity.id) this.loadUserRole(this.props)
          },
          (err) => {
            this.props.actions.setError(null)
            if (onSent) onSent(close, err)
          }
        )
    } else {
      const objectMessageRequest = getRequestFromPath(getFullUri(app))[getApiResource('message')]
      const currentUserName =
        this.props.userState?.profile.firstname || this.props.userState?.profile.lastname
          ? this.props.userState?.profile.firstname + ' ' + this.props.userState?.profile.lastname
          : this.props.userState?.profile.alias
      const targetUserName =
        newUser.profile.firstname || newUser.profile.lastname
          ? newUser.profile.firstname + ' ' + newUser.profile.lastname
          : newUser.profile.alias
      const messageActionCommentIndex = getJsonSpecFields('messageAction').findIndex((field) =>
        nonCSCompare(field.identity.name, 'info')
      )
      const messageActionComment = getJsonSpecRecords('messageAction').find((record) =>
        nonCSCompare(record[0], 'RequestAccess')
      )[messageActionCommentIndex]

      objectMessageRequest
        .post({
          class: 'message',
          type: 'AccessApproval',
          issue: {
            status: 'Opened',
            identity: {
              description:
                'Request from ' +
                currentUserName +
                ' for user ' +
                targetUserName +
                ' to have ' +
                newUser.role +
                ' access'
            },
            attached: {
              name: getFullUri(app)
            },
            notes: messageActionComment + '\nUSer: ' + targetUserName + '\tRole: ' + newUser.role
          },
          attached: {
            name: getFullUri(app)
          },
          sender: {
            id: localStorage.currentUserId,
            description: this.props.userState?.alias
          },
          time: moment(getUTCTime()).format('YYYY-MM-DDTHH:mm:ss'),
          text:
            'Request from ' + currentUserName + ' for user ' + targetUserName + ' to have ' + newUser.role + ' access',
          action: 'RequestAccess',
          properties: [
            {
              identity: { name: 'role' },
              type: 'Enum',
              reference: '/Role',
              value: newUser.role
            },
            {
              identity: { name: 'user' },
              type: 'String',
              value: newUser.profile.identity.name
            },
            {
              identity: { name: 'id' },
              type: 'String',
              value: newUser.profile.identity.id
            }
          ]
        })
        .then(
          () => {
            this.props.actions.updateUsers(
              app.identity.id,
              pluralTypeForms.get(this.state.objectType),
              app.users.concat(Object.assign({}, newUser))
            )
            if (onSent) {
              onSent(close)
            }
          },
          (err) => {
            this.props.actions.setError(null)
            if (onSent) {
              onSent(close, err)
            }
          }
        )
    }
  }

  onUserRoleChange(newUser, close, onSent) {
    //console.log("MajorObject::onUserRoleChange", newUser, close);
    const app = this.getObject()
    track(this.getObjectType(this.props), 'user_change')
    getRequestFromPath(getFullUri(app))
      .users(newUser.profile.identity.name)
      .put({
        object: { id: app.identity.id, name: app.identity.name },
        type: capitalize(shortnames[pluralTypeForms.get(this.state.objectType)]),
        role: newUser.role,
        user: newUser.profile.identity
      })
      .then(
        () => {
          const approved =
            this.state.currentUserRole === 'Architect'
              ? {
                  history: {
                    completions: [
                      {
                        status: 'Approved',
                        completed: true
                      }
                    ]
                  }
                }
              : {
                  history: {
                    completions: []
                  }
                }
          this.props.actions.updateUsers(
            app.identity.id,
            pluralTypeForms.get(this.state.objectType),
            app.users.map((u) => {
              return u.profile.identity.id !== newUser.profile.identity.id
                ? u
                : Object.assign(u, { role: newUser.role }, approved)
            })
          )
          if (onSent) {
            onSent(close)
          }
          //console.log("onUserRoleChange", localStorage.currentUserId, newUser);
          if (localStorage.currentUserId === newUser.profile.identity.id) {
            this.loadUserRole(this.props)
          }
        },
        (err) => {
          this.props.actions.setError(null)
          if (onSent) onSent(close, err)
        }
      )
  }

  /**
   * Actual invite message is sent in InviteDialog component. This function updates issue list
   * @param email
   * @param message
   * @param role
   */
  onInviteUser(email, message, role) {
    track(this.getObjectType(this.props), 'user_invite')
    // Messages component is wrapped in Connect, so we have to use refs.wrappedInstance
    if (this.refs.messages && this.refs.messages['refs'].wrappedInstance) {
      // @ts-ignore
      this.refs.messages.refs.wrappedInstance.requestIssues().then(() => {
        //console.log("onInviteUser reloaded issues");
      })
    }

    //console.log("onInviteUser", typeof getRequestFromPath(getFullUri(this.getObject())), typeof getRequestFromPath(getFullUri(this.getObject())).users);
    /*
    getObjectListNew(
      getRequestFromPath(getFullUri(this.getObject())).users,
      'user',
      this.props.actions
    );
    */
    getObjectListNew(getRequestFromPath(getFullUri(this.getObject())).users, 'user', null, true).then((users) => {
      this.props.actions.receiveObjectListIntoProperty(users, this.getObject(), 'organization', 'users')
    })
  }

  onShowJsonClick = () => {
    track(this.getObjectType(this.props), 'json_show')
    this.setState({
      jsonOpen: true
    })
  }

  onLocaleChange(locale) {
    this.setState({
      locale: locale
    })
  }

  selectFileForUploadChildObject = (type) => {
    document.getElementById('objectLoad')?.click()
    //console.log("MajorObject::selectFileForUploadChildObject",type);
    this.setState({ uploadingChildObjectType: type }, () => {
      //console.log("MajorObject::selectFileForUploadChildObject state changed",document.getElementById("objectLoad"));
    })
    //this.uploadChildObject(file, type);
  }

  uploadChildObject = (file, type, closeDialog?, onSent?) => {
    track(this.getObjectType(this.props), 'child_upload')
    const parentObject = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const req = getRequestFromPath(getFullUri(parentObject))[getApiResource(type)]
    //logger.log("MajorObject::uploadChildObject", getFullUri(parentObject), getRequestFromPath(getFullUri(parentObject)), type, getApiResource(type), req);

    this.setState({
      uploadingError: 0,
      uploadingErrorJson: ''
    })

    //console.log("MajorObject::uploadChildObject", file, type);

    let fr = new FileReader()
    fr.onload = (str) => {
      try {
        //logger.log("MajorObject::uploadChildObject", fr.result);
        let parsed = JSON.parse(String(fr.result))
        //logger.log("MajorObject::uploadChildObject", parsed);
        if (parsed) {
          parsed.type = type
          this.setState({ uploadingJson: parsed, uploadingError: 0 })
          sendObjectNew(req, 'post', this.props.actions, parsed, null, true).then(
            (obj) => {
              if (onSent) onSent(closeDialog)
              //logger.log("Upload success");
              getObjectListNew(req, type, this.props.actions, true) // force api call
                .then(() => {
                  //logger.log("Update success");
                })
              this.setState(
                {
                  uploadingError: 0
                },
                () => {
                  window.location.reload()
                }
              )
            },
            (error) => {
              if (error.status === 409) {
                this.props.actions.setError(false)
                const errorMessage = JSON.parse(error.response).error.message
                //logger.log('MajorObject::uploadChildObject::errorMessage', errorMessage);

                this.setState({
                  showUpdateExistObjectDialog: true,
                  updateExistObjectName: errorMessage
                })

                if (this.state.updateExistObject) {
                  sendObjectNew(req(parsed.identity.name), 'put', this.props.actions, parsed, null, true).then(
                    () => {
                      if (onSent) onSent(closeDialog)
                      getObjectListNew(req, type, this.props.actions, true) // force api call
                        .then(() => {
                          //console.log("Update success");
                        })
                      //console.log("Upload success");
                      this.props.actions.setError(false)
                      this.setState({
                        uploadingError: 0,
                        updateExistObjectDialog: false,
                        showUpdateExistObjectDialog: false
                      })
                    },
                    (error) => {
                      //console.log("Upload error", error);
                      if (onSent) onSent(closeDialog, error)
                      this.setState({
                        uploadingError: error.status + ' ' + error.statusText,
                        uploadingErrorJson: JSON.parse(error.response),
                        updateExistObjectDialog: false,
                        showUpdateExistObjectDialog: false
                      })
                    }
                  )
                } else {
                  if (onSent) onSent(closeDialog, error)
                  this.setState({
                    uploadingError: 0
                  })
                  this.props.actions.setError(false)
                  return
                }
              } else {
                if (onSent) onSent(closeDialog, error)
              }
              //console.log("Upload error", error);
              this.setState({
                uploadingError: error.status + ' ' + error.statusText,
                uploadingErrorJson: JSON.parse(error.response)
              })
            }
          )
          //API[this.state.objectType].post(parsed);      // todo: fix the API call here
        } else {
          this.setState({ uploadingJson: null, uploadingError: 1 })
          if (onSent) onSent(closeDialog, Error('No parsing'))
        }
      } catch (e) {
        if (onSent) onSent(closeDialog, e)
        console.error(e) // eslint-disable-line no-console
        this.setState({
          uploadingJson: null,
          uploadingError: e.message
        })
      }
    }
    fr.readAsText(file)
  }

  uploadFile(file, done, customFileName) {
    const app = this.getObject()
    customFileName = customFileName || file.name
    let form = new FormData()
    form.append('file', file)

    return fetch(apiURL + API.resources.url() + '?uri=' + getFullUri(app) + '/' + customFileName, {
      method: 'post',
      headers: {},
      body: form
    }).then((data) => {
      if (done) {
        done()
      }
    })
    // .then( function( data ) {
    //   //console.log("upload success");
    //   done();
    // }.bind(this) )
    // .catch( ( error ) => {
    //   this.props.actions.setError(error);
    // });
  }

  deleteFile = (file) => {
    const obj = this.getObject()
    return fetch(apiURL + API.resources.url() + '?uri=' + getFullUri(obj) + '/' + file.identity.name, {
      method: 'delete'
    })
  }

  onChildObjectTableClick(id, column, type, value) {
    this.props.history?.push('/view' + getFullUri(value))
  }

  /**
   * Send request to get user's role in major object and save it in the local state
   * @param props     major object's properties
   */
  loadUserRole = (props) => {
    const userId =
      props.userState.profile && props.userState.profile.identity ? props.userState.profile.identity.name : null
    //console.log("MajorObject:loadUserRole", userId);

    // @ts-ignore
    const query = this.getDataRequestQuery(this.props)
    const request = query.endpoint || query.objectRequest
    if (this.state.errorStatus) {
      return
    }

      getObjectNew(request.users(userId), 'user').then((user) => {
      this.setState({ currentUserRole: user.role })
      //console.log("MajorObject:loadUserRole:ROLE", user, user.role);

      trackUserSet(userId, user.role)
    })
  }

  /**
   * Check if current user is in Object's user list and save role in local state; if user not found, load role from api
   * @param props
   */
  checkUserRole = (props) => (ds, users) => {
    const userId =
      props.userState.profile && props.userState.profile.identity ? props.userState.profile.identity.name : null
    //console.log("MajorObject:checkUserRole", ds, users, userId, props.userState);
    if (!userId) return
    // if user not found in object.users, request the user directly to get the role
    if (users.reduce((p, c) => p || c.profile.identity.name === userId, false) === false) {
      this.loadUserRole(props)
    } else {
      // get role from object.users
      this.setState({
        currentUserRole: users.reduce((p, c) => p || (c.profile.identity.name === userId ? c.role : false), false)
      })
    }
  }

  toggleExpandDescriptions = () => {
    track(this.getObjectType(this.props), 'description_toggle')
    this.setState({ expandDescriptions: !this.state.expandDescriptions })
  }

  renderDescriptionColumnHeader() {
    return (
      <span>
        Description&nbsp;
        <a
          className={
            'MajorObject__expandButton ' +
            (isDark(this.state.objectType) ? ' MajorObject__expandButton__dark ' : '') +
            (this.state.expandDescriptions ? 'MajorObject__expandButton__active' : '')
          }
          onClick={this.toggleExpandDescriptions}
        />
      </span>
    )
  }

  getDescriptionType = () => {
    return { name: this.state.expandDescriptions ? 'text_expanded' : 'text' }
  }

  checkEditable() {
    return this.getUserRole() !== false
  }

  clearTabsContentLoaded(tabs) {
    this.setState({
      tabsLoaded: tabs.reduce((p, tab) => Object.assign(p, { [tab]: false }), {})
    })
  }

  onTabsContentLoaded(tabs) {
    return () => {
      tabs.map((tab) =>
        this.setState({
          tabsLoaded: Object.assign({}, this.state.tabsLoaded, { [tab]: true })
        })
      )
    }
  }

  downloadPDF = () => {
    track(this.getObjectType(this.props), 'pdf_download')
    downloadSelectorAsPDF(
      document.querySelector('.MajorObjectView__connections .tab-panel'),
      this.getObject().identity.name +
        ' ' +
        // @ts-ignore
        document.querySelector('.MajorObjectView__connections .tabs-menu-item.is-active', 'tr')?.innerText.trim(),
      'tr'
    ).then(() => this.setState({ snackIsOpen: true }))
  }

  renderCollapseButton() {
    const style = this.state.collapsed ? 'closed' : 'opened'
    const backgroundColor = getObjectBackgroundColor(this.state.objectType)
    const modifyStyle = backgroundColor ? { backgroundColor: backgroundColor } : {}
    return (
      <div
        className={'MajorObjectView__collapse MajorObjectView__collapse_' + style}
        onClick={() => {
          this.setState({ collapsed: !this.state.collapsed })
        }}
        style={modifyStyle}
      >
        <img src={iArrowDownClose} alt="" />
      </div>
    )
  }

  cleanJson(object) {
    let ret = Object.assign({}, object)
    delete ret.users
    delete ret.versions
    delete ret.isFetching
    delete ret.type
    delete ret.checkpoints
    delete ret._path
    delete ret.cacheTimestamp
    delete ret.lifetime

    if (ret.object) {
      delete ret.object.lastApprovedVersion
    }
    return ret
  }

  pasteChild = (params, closeDialog, onSent) => {
    track(this.getObjectType(this.props), 'child_paste')
    let newObject = JSON.parse(localStorage.clipboard)
    let newName = params.objectName
    logger.info('MajorObject:pasteChild', { params, newName, localStorage }, this.state, this.props)

    if (newName && newName.length > 0) {
      if (params.restoreObject) {
        // We restore deleted object and override it data
        newObject.identity.name = newName + ' $Restore'
        logger.info('MajorObject:pasteChild:RESORE', { params, newName, newObject }, this.state, this.props)
      } else if (params.renameObject) {
        // We rename existing object with new name
        newObject.identity.name += ' $Rename ' + newName
        logger.info('MajorObject:pasteChild:RENAME', { params, newName, newObject }, this.state, this.props)
      } else {
        // We paste object with name specified.
        newObject.identity.name = newName
      }
    }

    let blob = new Blob([JSON.stringify(newObject)], {
      type: 'application/json'
    })
    blob['lastModifiedDate'] = ''
    blob['name'] = 'filename'

    this.uploadChildObject(blob, localStorage.clipboardType, closeDialog, onSent)
  }

  replaceChild = (newName, newFields) => {
    track(this.getObjectType(this.props), 'child_replace')
    this.realDeleteMajorObject(localStorage.clipboardType, this.state.replacingObject)

    let newObject = JSON.parse(localStorage.clipboard)
    newObject.identity.name = newName

    if (newFields && newFields.length > 0) {
      newObject.structure.fields = newObject.structure.fields.filter(
        (field, fieldIndex) => newFields.indexOf(fieldIndex) !== -1
      )
      newObject.data.records = newObject.data.records.map((record) => {
        return {
          index: record.index,
          values: record.values.filter((col, colIndex) => newFields.indexOf(colIndex) !== -1)
        }
      })
    }

    let blob = new Blob([JSON.stringify(newObject)], {
      type: 'application/json'
    })
    blob['lastModifiedDate'] = ''
    blob['name'] = 'filename'

    this.uploadChildObject(blob, localStorage.clipboardType)
    this.setState({ replacingChild: false })
  }

  renderPasteDialog() {
    const majorObject = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    //logger.info("MajorObject:renderPasteDialog", this, majorObject, this.state);

    if (!this.state.pastingChild) {
      return null
    }

    return (
      <div>
        <PasteDialog
          appState={this.props.appState}
          actions={this.props.actions}
          className={'PasteDialog'}
          modalTitle={'Paste ' + localStorage.clipboardType}
          confirmText={'Paste'}
          isVisible
          isEditable={editableState.EDITED}
          majorObject={majorObject}
          onClose={() => this.setState({ pastingChild: false })}
          onSave={this.pasteChild.bind(this)}
        />
      </div>
    )
  }

  renderReplaceDialog() {
    return this.state.replacingChild ? (
      <PasteDialogOld
        onClose={() => this.setState({ replacingChild: false })}
        pasteChild={this.replaceChild.bind(this)}
        replacingObject={this.state.replacingObject.identity.name}
      />
    ) : null
  }

  renderUpdateObjectDialog = () => {
    return this.state.showUpdateExistObjectDialog ? (
      <ConfirmDialog
        caption={'Overwrite'}
        text={
          <React.Fragment>
            <span>
              <b>Error: </b>
              <span style={{ color: 'red' }}>{this.state.updateExistObjectName}.</span>
            </span>
            <br />
            <br />
            <b>Object already exists. Overwrite?</b>
          </React.Fragment>
        }
        cancelText="No"
        confirmText="Yes"
        onCancel={() =>
          this.setState({
            updateExistObjectDialog: false,
            showUpdateExistObjectDialog: false
          })
        }
        onConfirm={() =>
          this.setState(
            {
              updateExistObjectDialog: true,
              showUpdateExistObjectDialog: false
            },
            () => window.location.reload()
          )
        }
      />
    ) : null
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackIsOpen: false })
  }

  renderDownloadPDF = () => {
    return (
      <div className="MajorObjectView__showJson">
        <span onClick={this.downloadPDF}>&nbsp;PDF&nbsp;</span>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          open={this.state.snackIsOpen}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          {/*// @ts-ignore*/}
          <MuiAlert onClose={this.handleClose} elevation={6} variant="filled" severity="success">
            Image also copied to clipboard
          </MuiAlert>
        </Snackbar>
      </div>
    )
  }

  renderShowJson = () => {
    return (
      <div className="MajorObjectView__showJson">
        <span onClick={this.onShowJsonClick}>&nbsp;JSON&nbsp;</span>
        {this.state.jsonOpen ? (
          <ModalWindow
            isOpen={this.state.jsonOpen}
            header={'JSON of object'}
            onClose={() => {
              this.setState({
                jsonOpen: false
              })
            }}
          >
            <JSONTreeCopy data={this.cleanJson(this.getObject())} shouldExpand />
          </ModalWindow>
        ) : null}
      </div>
    )
  }

  renderRightMenu() {
    return (
      <React.Fragment>
        {this.renderShowJson()}
        <br />
        {this.renderDownloadPDF()}
      </React.Fragment>
    )
  }

  renderDocumentList(docs) {
    return (
      <ul>
        {docs.map((doc, index) => (
          <li key={index}>
            <a href={doc.url}>{doc.name}</a>
          </li>
        ))}
      </ul>
    )
  }

  renderLoading() {
    return (
      <section className={'MajorObjectView MajorObjectView__loading MajorObjectView_' + this.state.objectType}>
        <h1>Loading</h1>
        <Loader />
      </section>
    )
  }

  renderHeaderPath() {
    const org = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const path = buildPathDetailed(getFullUri(org))
    //logger.info("MajorObject:renderHeaderPath", org, path, this.state);
    return <HeaderPath path={path} />
  }

  renderBackArrowTabs() {
    const org = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const path = buildPathDetailed(getFullUri(org))
    let ret: any = []
    if (path.length > 1) {
      const backPath = '/view' + path[path.length - 2].path
      ret.push([
        <div className="MajorObject__backArrowTabs" key={backPath}>
          <div
            className="MajorObject__backArrowTabsHover"
            style={{
              borderColor: getObjectBackgroundColor(getParentObjectType(this.state.objectType))
            }}
          ></div>
          <Link
            to={backPath}
            onClick={() => {
              storeChildObjectLink(org, this.state.objectType)
              track(this.state.objectType, 'backarrow')
            }}
          >
            <img className="MajorObject__backArrowNormal" src={getParentLeftArrowImage(this.state.objectType)} alt="" />
            <img
              className="MajorObject__backArrowHover"
              src={getParentLeftArrowImage(this.state.objectType, true)}
              alt=""
            />
          </Link>
        </div>
      ])
    }

    return ret
  }

  renderErrorWindow() {
    return <ErrorWindow actions={this.props.actions} history={this.props.history} />
  }

  renderLocale() {
    return ''
  }

  renderObjectHeader() {
    const org = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    return (
      <ObjectHeader
        title={org.identity.name}
        deployment={org.deployment}
        objectUsers={org.users ? org.users : []}
        picture={org.object ? objectPictureUrl(getFullUri(org), org.object.picture) : ''}
        onObjectUsersChange={this.onObjectUsersChange.bind(this)}
        onDeleteUser={this.onDeleteUser.bind(this)}
        onAddUser={this.onAddUser.bind(this)}
        onChangePicture={this.onPictureChange.bind(this)}
        onUserRoleChange={this.onUserRoleChange.bind(this)}
        onInviteUser={this.onInviteUser.bind(this)}
        className={this.state.objectType}
        object={org}
        appState={this.props.appState}
        userState={this.props.userState}
        actions={this.props.actions}
        renderLocale={this.renderLocale}
        isEditable
      />
    )
  }

  getUserExplicitRole(objUsers = null) {
    const users = objUsers || this.getObject().users
    if (users?.length) {
      const currentUser = users.find((user) => user.user.id === localStorage.currentUserId)
      return currentUser?.role
    }
  }

  getOrganizationExplicitRole(): OrganizationRoleEnum | undefined {
    const orgName = objectNameFromPathByType(getFullUri(this.getObject()), 'organizations')
    if (this.state.explicitOrganizationRole) return this.state.explicitOrganizationRole
    else {
      if (this._loadingOrganizationUserRole) return
      else {
        this._loadingOrganizationUserRole = true
        //logger.log("getOrganizationExplicitRole: requesting org users", orgName);
        getObjectNew(API.organizations(orgName).users(), 'organization').then((parentUsers) => {
          const explicitParentRole = this.getUserExplicitRole(parentUsers)
          //logger.log("getOrganizationExplicitRole: explicitParentRole=", explicitParentRole);
          if (explicitParentRole && explicitParentRole != OrganizationRoleEnum.NOROLE) {
            this.setState({
              explicitOrganizationRole: explicitParentRole
            })
          } else {
            this.setState({
              explicitOrganizationRole: OrganizationRoleEnum.NOROLE
            })
          }
        })
      }
    }
  }

  getParentUserExplicitRole(obj = null): OrganizationRoleEnum | undefined {
    const org = obj || this.getObject()
    //logger.log("getParentUserExplicitRole entering", obj);
    if (!org || !org.object || !org.object.parent || !org.object.parent.name) return

    /*
    const parent = getObjectById(this.props.appState, objectTypeFromPath(org.object.parent.name), org.object.parent.id);
    if (parent)
    {

    } */
    if (this.state.explicitParentRole && this.state.explicitParentRole != OrganizationRoleEnum.NOROLE) {
      return this.state.explicitParentRole
    } else {
      if (this._loadingParentUserRole && !obj) {
        return
      } else {
        this._loadingParentUserRole = true
        if (
          !objectTypeFromPath(org.object.parent.name) ||
          org.object.parent.name.indexOf('systems//applications/') !== -1
        ) {
          this.setState({
            explicitParentRole: OrganizationRoleEnum.NOROLE
          })
          return
        }
        //logger.log("getParentUserExplicitRole: requesting parent", org.object.parent.name, 'indexof', org.object.parent.name.indexOf('systems//applications/'));
        getObjectNew(
          getRequestFromPath(org.object.parent.name),
          singularType(objectTypeFromPath(org.object.parent.name)),
          null
        ).then((parentObj) => {
          //logger.log("getParentUserExplicitRole: parent", parentObj);
          getObjectListNew(getRequestFromPath(org.object.parent.name).users(), 'user').then((parentUsers) => {
            //logger.log("getParentUserExplicitRole: parent users", parentUsers);
            const explicitParentRole = this.getUserExplicitRole(parentUsers)
            //logger.log("getParentUserExplicitRole: explicitParentRole=", explicitParentRole);
            if (explicitParentRole) {
              this.setState({
                explicitParentRole: explicitParentRole
              })
            } else {
              if (
                !parentObj ||
                !parentObj.object.parent ||
                // @ts-ignore
                nonCSCompare(singularType(objectTypeFromPath(org.object.parent.name), 'organization'))
              ) {
                this.setState({
                  explicitParentRole: OrganizationRoleEnum.NOROLE
                })
              }
              this.getParentUserExplicitRole(parentObj)
            }
          })
        })
      }
    }
  }

  getUserRole() {
    return this.getUserRoleAndTip().role
  }

  getUserRoleAndTip() {
    const obj = this.getObject()

    if (!obj || !obj.object) {
      return { role: false, tip: '' }
    }

    const role = this.getUserExplicitRole()
    const parentRole = this.getParentUserExplicitRole()
    const orgRole = this.getOrganizationExplicitRole()
    //logger.log("MajorObject::getUserRole", role, parentRole);

    if (role) {
      return {
        role: role,
        tip: 'Your role ' + role + ' is defined in current object'
      }
    }

    const access = obj.object.access
    //logger.log("MajorObject::getUserRole calculated", ret, access);

    return getRoleInfo(access, parentRole, orgRole)
  }

  renderUserRole() {
    const role = this.getUserRoleAndTip()

    if (this.props.userState && this.props.userState.onUserRole) this.props.userState.onUserRole(role.role, role.tip)

    //console.log("MajorObject:renderUserRole", role);
    return '' //<div className="MajorObjectView__userRoleInHeader">{role}</div>;
  }

  renderCannotDeleteWindow() {
    if (this.state.showCannotDelete) {
      return (
        <ModalWindow
          header={'Cannot delete'}
          canClose
          isOpen
          className="OrganizationList__deleteModal"
          onClose={() => this.setState({ showCannotDelete: false })}
          footer={
            <div className="OrganizationList__deleteButtons">
              <button className="btn_big" onClick={() => this.setState({ showCannotDelete: false })}>
                Close
              </button>
            </div>
          }
        >
          {this.state.showCannotDelete}
        </ModalWindow>
      )
    } else return null
  }

  renderDeleteConfirmWindow() {
    if (this.state.deleteMajorObject || this.state.deleteChildObject) {
      let inner: JSX.Element = <></>
         if (this.state.deleteMajorObject) {
        inner = this.state.deleteMajorObject.obj ? (
          <p>
            Do you really want to delete {this.state.deleteMajorObject.objectType}{' '}
            {this.state.deleteMajorObject.obj.identity.name}?
          </p>
        ) : (
          <Loader />
        )
      } else {
        inner = <p>Do you really want to delete {this.state.deleteChildObject.obj.identity.name}?</p>
         }
      // ? delete parameter or response in interface and operations
      if(this.state.objectType==="interface")
      this.getObject().operations.map(element => {
        const param = element.parameters.find(e => e.id === this.state.deleteChildObject.obj.identity.id);
        const resp = element.responses.find(e => e.id === this.state.deleteChildObject.obj.identity.id);
        if (param||resp) {
                    inner = <p>Do you really want to delete? {this.state.deleteChildObject.obj.identity.name} will be removed from all operations ?</p>
        }
      })

      if (this.state.deleteMajorObject && this.state.deleteMajorObject.error) {
        inner = (
          <React.Fragment>
            {inner}
            <p className="EditorDialog__error">Error: {this.state.deleteMajorObject.error.error.message}</p>
          </React.Fragment>
        )
      }

      return (
        <ConfirmDialog
          caption={'Delete ' + (this.state.deleteMajorObject ? this.state.deleteMajorObject.objectType : '')}
          text={inner}
          cancelText="No"
          confirmText="Yes"
          onCancel={() => this.setState({ deleteMajorObject: null, deleteChildObject: null })}
          onConfirm={() =>
            this.state.deleteMajorObject
              ? this.realDeleteMajorObject(this.state.deleteMajorObject.objectType, this.state.deleteMajorObject.obj)
              : this.state.deleteChildObject.delete(this.state.deleteChildObject.obj)
          }
        />
      )
    } else {
      return null
    }
  }

  renderFileUploader() {
    return (
      <div className="FileInput_hidden">
        <input
          type="file"
          id="objectLoad"
          className=""
          onChange={(e) => {
            let files: any = []

            // @ts-ignore
            if (e.dataTransfer) {
              // @ts-ignore
              files = e.dataTransfer.files
            } else if (e.target) {
              files = e.target.files
            }
            files = [].slice.call(files)

            if (files.length > 0) this.uploadChildObject(files[0], this.state.uploadingChildObjectType)
          }}
        />
      </div>
    )
  }

  renderHeader() {
    return [
      this.renderErrorWindow(),
      this.renderHeaderPath(),
      this.renderUserRole(),
      this.renderObjectHeader(),
      this.state.deleteMajorObject || this.state.deleteChildObject ? this.renderDeleteConfirmWindow() : null,
      this.state.pastingChild ? this.renderPasteDialog() : null,
      this.renderReplaceDialog(),
      this.renderCannotDeleteWindow(),
      this.renderFileUploader(),
      this.renderUpdateObjectDialog()
    ]
  }

  renderDescription() {
    const org = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable = this.checkEditable()
    return (
      <ObjectDescription
        text={org.identity.description}
        title={org.identity.name}
        onChange={this.onDescriptionChange.bind(this)}
        isEditable={isEditable}
      />
    )
  }

  /**
   * loads list of parent organization's tags into local state
   */
  loadOrganizationTags(done) {
    const organizationName = objectNameFromPathByType(getFullUri(this.getObject()), 'organizations')
    getObjectNew(API.organizations(organizationName), 'organization').then((org) => {
      done(org.object.tags)
    })
  }

  renderTags() {
    const majorObject = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable = this.checkEditable()

    let tags = majorObject.object && majorObject.object.tags ? majorObject.object.tags : []
    //console.log("MajorObject:renderTags", this, majorObject, tags, isEditable, this.state);

    return (
      <div>
        <TagsDialog
          appState={this.props.appState}
          actions={this.props.actions}
          className={'TagsDialog'}
          buttonTitle={'Tags:'}
          isVisible
          isEditable={isEditable ? editableState.EDITING : editableState.BROWSABLE}
          isItems={itemState.HORIZONTAL}
          tags={tags}
          majorObject={majorObject}
          onClose={this.onTagsClose.bind(this)}
          onSave={this.onTagsChange.bind(this)}
        />
      </div>
    )
  }

  renderDocuments() {
    const majorObject = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable = this.checkEditable()

    let docs = majorObject.object && majorObject.object.documents ? majorObject.object.documents : []
    //logger.info("MajorObject:renderDocuments", this, majorObject, docs, isEditable, this.state);

    return (
      <div>
        <DocsDialog
          appState={this.props.appState}
          actions={this.props.actions}
          className={'DocsDialog'}
          modalTitle={isEditable ? 'Edit documents' : 'Documents'}
          buttonTitle={'Documents:'}
          isVisible
          isEditable={isEditable ? editableState.EDITING : editableState.BROWSABLE}
          isItems={itemState.HORIZONTAL}
          docs={docs}
          majorObject={majorObject}
          onClose={this.onDocumentsClose.bind(this)}
          onSave={this.onDocumentsChange.bind(this)}
        />
      </div>
    )
  }

  renderDocuments1() {
    const org = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable = this.checkEditable()
    logger.info('MajorObject:renderDocuments', this, org, isEditable, this.state)

    return (
      <DocumentList
        docs={org.object && org.object.documents ? org.object.documents : []}
        onSave={this.onDocumentsChange.bind(this)}
        onEdit={this.onDocumentsEdit.bind(this)}
        onClose={this.onDocumentsClose.bind(this)}
        isModal={this.state.editingDocuments}
        uploadFile={this.uploadFile.bind(this)}
        title="Documentation:"
        modalTitle="Edit documents"
        emptyText="No documents"
        defaultNewValue="New document"
        delimiter=""
        isEditable={isEditable}
        dark
        objectURL={getFullUri(org)}
      />
    )
  }

  renderAdvancedSettings() {
    const majorObject = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable = this.checkEditable()

    // Properties and completion status
    let properties: any = []
    let version: any = null

    // We have settings as an object and it have completions specified, lets extract status.
    if (majorObject.settings) {
      // Properties from the settings
      properties =
        majorObject.settings.object && majorObject.settings.object.properties
          ? majorObject.settings.object.properties
          : []
      version = majorObject.settings.version
        ? getFullVersion(majorObject.settings)
        : { major: 1, minor: 0, revision: 0 }
    } else if (majorObject.object) {
      // Properties from object properties
      properties = majorObject.object.properties ? majorObject.object.properties : []
    }

    let organizationClassObject = this.state.organizationClassObject
    if (!organizationClassObject) {
      if (!this._loadingOrganizationClassObject) {
        const orgName = objectNameFromPathByType(getFullUri(this.getObject()), 'organizations')
        if (orgName) {
          this._loadingOrganizationClassObject = true
          //logger.log("loading organizationClassObject")
          getObjectNew(API.organizations(orgName).datasets('Class'), 'dataset').then((dataset) => {
            this.setState({ organizationClassObject: dataset })
            //logger.log("loaded organizationClassObject", dataset);
          })
        }
      }
    } else {
      const classData = datasetToObjectArray(organizationClassObject)
      //logger.log("classData", classData);
      const currentObjectClassData = classData.find((classRow) => nonCSCompare(classRow.Class, this.state.objectType))
      //logger.log("currentObjectClassData", currentObjectClassData);
      if (currentObjectClassData) {
        if (currentObjectClassData.Properties) {
          try {
            JSON.parse(currentObjectClassData.Properties).map((classProp) => {
              if (!properties.find((existingProp: any) => existingProp.identity.name === classProp.Identity.Name)) {
                properties = properties.concat({
                  identity: {
                    name: classProp.Identity.Name,
                    description: classProp.Identity.Description
                  },
                  type: classProp.Type,
                  reference: classProp.Reference
                })
              }
              //logger.log("properties after concat", properties);
            })
          } catch (ex) {
            console.error(ex)
          }
        }
      }
    }

    let settingsStatus = majorObject.settings ? getVersionStatus(majorObject.settings) : ''

    //console.log("MajorObject:renderAdvancedSettings", versionToStr(version), { majorObject, isEditable, properties, settingsStatus}, this.state, this.props);

    return (
      <SettingsDialog
        appState={this.props.appState}
        actions={this.props.actions}
        buttonTitle={'Advanced Settings'}
        isVisible
        isEditable={
          isEditable
            ? settingsStatus === 'draft'
              ? editableState.EDITING
              : editableState.BROWSABLE
            : editableState.BROWSABLE
        }
        className={settingsStatus !== 'draft' ? 'EditorDialog__buttonViewOnly' : ''}
        isItems={itemState.VERTICAL}
        sortItems
        maxItems={5}
        settings={properties}
        majorObject={majorObject}
        version={version}
        onClose={() => this.setState({ editingSetting: false })}
        onCreateDraft={this.onCreateDraftAdvancedSettings.bind(this)}
        onFinalize={this.onFinalizeAdvancedSettings.bind(this)}
        onApprove={this.showApproveVersionIssue.bind(this)}
        onSave={this.onSaveAdvancedSettings.bind(this)}
      />
    )
  }

  renderProperties() {
    /*
    const isEditable = this.checkEditable();
    const app = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName);
    return <ObjectProperties
      className={this.state.objectType}
      fields={this.getObjectProperties()}
      values={this.getObjectPropertyValues()}
      onChange={this.onPropertiesChange}
      isEditable={isEditable}
      onDatasetLoad={this.onDatasetLoad}
      path={getFullUri(app)}
      object={app}
    />;
*/
    const majorObject = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable = this.checkEditable()

    let settingsStatus = getVersionStatus(majorObject)
    let propertiesFields = this.getObjectProperties()
    let propertiesValues = this.getObjectPropertyValues()

    //console.log("MajorObject:renderProperties", majorObject, settingsStatus, propertiesFields, propertiesValues);

    return (
      <DataDialogNew
        appState={this.props.appState}
        actions={this.props.actions}
        modalTitle={isEditable ? 'Edit properties' : 'Properties'}
        buttonTitle={'Properties'}
        isVisible
        isEditable={isEditable ? editableState.EDITING : editableState.BROWSABLE}
        className={settingsStatus !== 'draft' ? 'EditorDialog__buttonViewOnly' : ''}
        isItems={itemState.VERTICAL}
        maxItems={5}
        majorObject={majorObject}
        dataset={propertiesFields}
        data={propertiesValues}
        onClose={() => this.setState({ editingProperties: false })}
        onSave={this.onPropertiesChange}
      />
    )
  }

  renderNotFound() {
    return (
      <div>
        <img className="notFoundImage" src={i404} alt="404 Not Found" />
      </div>
    )
  }

  renderInfo() {
    //console.log("MajorObject::renderInfo", this.state.errorStatus);

    if (this.state.errorStatus) {
      return this.renderNotFound()
    }
    const backgroundColor = getObjectBackgroundColor(this.state.objectType)
    const modifyStyle = backgroundColor ? { borderColor: backgroundColor } : {}

    return (
      <div
        className={'MajorObjectView__info' + (this.state.collapsed ? ' MajorObjectView__info_collapsed' : '')}
        style={modifyStyle}
      >
        <div className="row">
          <div className="col-xs-6">
            {this.renderDescription()}
            {this.renderTags()}
            {this.renderDocuments()}
          </div>
          <div className="col-xs-3 MajorObject__PropertiesCol">{this.renderProperties()}</div>
          <div className="col-xs-3 MajorObject__AdvancedSettingsCol">{this.renderAdvancedSettings()}</div>
          <div className="col-xs-1">{this.renderRightMenu()}</div>
        </div>

        <div className="row">{this.renderCollapseButton()}</div>
      </div>
    )
  }

  render() {
    return <h1>MajorObject</h1>
  }
}
