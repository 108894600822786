import React from 'react'

import './ThButton.scss'

type ThButtonType = {
  onClick: () => void
  className?: string
  children: string
}

export const ThButton = ({ onClick, className, children }: ThButtonType) => (
  <button className={`ThButton ${className}`} onClick={onClick}>
    {children}
  </button>
)
