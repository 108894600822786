import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ObjectSearch from '../ObjectSearch/ObjectSearch'

import '../../styles/PoliciesStructure/PoliciesStructure.scss'
import iI from '../../resources/images/i-grey-2x.png'

class PoliciesStructure extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedId: -1
    }
  }

  mouseOver(item) {
    this.setState({
      selectedId: item.id
    })
  }

  change() {
    this.setState({ selectedId: -1 })
  }

  render() {
    return (
      <div className="PoliciesStructure row">
        <div className="PoliciesStructure__width col">
          <div className="PoliciesStructure__head col row">
            <div className="col PoliciesStructure__title">Policies: structure</div>
            <div className="col PoliciesStructure__edit">Edit</div>
          </div>
          <div className="PoliciesStructure__body col-xs-12">
            <div className="row">
              <div className="col-xs-6">
                Select Policies from list:
                <ObjectSearch
                  appState={this.props.appState}
                  types={['policies']}
                  onMouseOver={this.mouseOver.bind(this)}
                  onChange={this.change.bind(this)}
                />
              </div>
              <div className="col-xs-6">
                {this.state.selectedId !== -1 ? (
                  <div>
                    <img src={iI} className="PoliciesStructure__info" />
                    <div className="PoliciesStructure__descriptionHeader">Description:</div>
                    <br />
                    {this.props.appState.policies.map((policy) =>
                      policy.id === this.state.selectedId ? policy.identity.description : ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PoliciesStructure.propTypes = {
  appState: PropTypes.object
}

export default PoliciesStructure
