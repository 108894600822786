/**
 * Created by mailf on 05.04.2017.
 *
 * Dialog for editing anything. Editor is passed as child object
 */

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { track } from '../../helpers/analytics'
import { editableState, getVersionStatus } from '../../helpers/index'
import { ConfirmDialog } from '../ConfirmDialog/ConfirmDialog'
import { Loader } from '../Loader/Loader'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import './EditorDialog.scss'
import { EditorDialogButton } from './EditorDialogButton'
import { EditorVersion } from './EditorVersion'

export class EditorDialog extends Component {
  constructor(props) {
    super(props)
    // console.log("EditorDialog:constractor", props);

    this.state = {
      isFullscreen: false, // Dialog can be shown in full screen
      isEditable: props.isEditable, // Dialog allow to edit data and have 4 states
      isLoading: false,

      messageText: '', // Message to user when loading
      successText: '', // Message when laod succsful
      errorText: '', // Message when load unsuccesful
      confirmingCancel: false, // Display cancel confirmation window; If confirmingCancel is not false, it is set to
      // callback function for Cancel button
      confirmingEdit: false
    }
  }

  componentDidMount() {
    //logger.info("EditorDialog:componentDidMount", this.state);
    if (this.state.isEditable > editableState.EDITABLE) {
      //console.log("EditorDialog:componentDidMount", this.state.isEditable, ">", editableState.EDITABLE);

      this.onEdit()
    }

    if (this.props.isVisible) {
      if (this.state.isEditable > editableState.EDITABLE) {
        if (!this._tracked) track(this.props.objectType, 'dialog_edit')
        this._tracked = true
      } else {
        if (!this._tracked) track(this.props.objectType, 'dialog_view')
        this._tracked = true
      }
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      ('isLoading' in props && props.isLoading && props.isLoading !== this.state.isLoading) ||
      ('messageText' in props && props.messageText && props.messageText !== this.state.messageText) ||
      ('successText' in props && props.successText && props.successText !== this.state.successText) ||
      ('errorText' in props && props.errorText && props.errorText !== this.state.errorText)
    ) {
      // We change state of messages and loading if it is supplied different from current
      //console.log("EditorDialog:componentWillReceiveProps", props, this.state);

      this.setState({
        isLoading: 'isLoading' in props ? props.isLoading : this.state.isLoading,
        messageText: 'messageText' in props ? props.messageText : this.state.messageText,
        successText: 'successText' in props ? props.successText : this.state.successText,
        errorText: 'errorText' in props ? props.errorText : this.state.errorText
      })
    }

    if (this.props.isVisible) {
      if (this.state.isEditable > editableState.EDITABLE) {
        if (!this._tracked) track(this.props.objectType, 'dialog_edit')
        this._tracked = true
      } else {
        if (!this._tracked) track(this.props.objectType, 'dialog_view')
        this._tracked = true
      }
    }
  }

  /**
   * Execute activity when dialog closing
   */
  onClose = () => {
    // console.log("EditorDialog:onClose");

    // Clean status.
    this.setState({
      isLoading: false,
      messageText: '',
      successText: '',
      errorText: '',
      confirmingCancel: false
    })

    if (this.props.onClose) {
      setTimeout(() => {
        this.props.onClose()
      }, 500)
    }
  }

  /**
   * Execute move to edit mode for dialog. Can be overridden by func from props
   * To override return false from provided function
   * @param status - status for edit
   */
  onEdit = (status) => {
    //console.log("EditorDialog:onEdit", status);

    if (this.props.onEdit && this.props.onEdit(this.onChange)) {
      return
    }

    track(this.props.objectType, 'dialog_edit', 'edit')

    this.setState({
      isEditable: status && status > editableState.EDITING ? status : editableState.EDITING,
      isLoading: false,

      messageText: '',
      successText: '',
      errorText: '',
      confirmingCancel: false
    })
  }

  /**
   * Method to report changes in data from actual dialog. Dialog get pointer
   * to this function and call it when want to report new state of data in dialog.
   * @param data - true  - data changed and can be saved
   *               false - data in original state
   *               null  - successful save of data
   *               error - error response
   * @param message - reporting info message in dialog
   * @param success - reporting success message in dialog.
   * @param error - reporting error message in dialog.
   * @param reporting - report only, no change of state.
   * @return
   */
  onChange = (data, message, success, error, reporting) => {
    let isEditable = this.state.isEditable
    let isEditableDown = isEditable > editableState.EDITING && !reporting ? editableState.EDITING : isEditable
    let isEditableUp = isEditable < editableState.EDITABLE || reporting ? isEditable : editableState.EDITED

    console.log(
      'EditorDialog:onChange',
      {
        data,
        message,
        success,
        error,
        reporting,
        isEditableDown,
        isEditableUp
      },
      this.state,
      this.props
    )

    if (data === true) {
      this.setState({
        isEditable: isEditableUp,
        isLoading: message && message.length > 0,

        messageText: message,
        successText: success,
        errorText: error
      })
    } else if (data === false) {
      this.setState({
        isEditable: isEditableDown,
        isLoading: message && message.length > 0,

        messageText: message,
        successText: success,
        errorText: error
          ? (this.state.errorText && this.state.errorText !== error ? this.state.errorText + '; ' : '') + error
          : ''
      })
    } else if (!data) {
      this.setState({
        isEditable: isEditableDown,
        isLoading: false,

        messageText: '',
        successText: success && success.length > 0 ? success : 'Update completed!',
        errorText: ''
      })
    } else {
      let error = data
      let message = error.response ? JSON.parse(error.response) : null
      this.setState({
        isEditable: isEditableUp,
        isLoading: false,

        messageText: '',
        successText: '',
        errorText:
          message && message.error
            ? message.error.message
            : 'Unknown Error ' + (error.statusText ? '(' + error.statusText + ': ' + error.responseText + ')' : '')
      })
    }
  }

  /**
   * Execute save callback
   */
  onSave = () => {
    // console.log("EditorDialog:onSave");

    if (this.props.onSave && this.props.onSave(false)) return

    track(this.props.objectType, 'dialog_save', 'save')
    this.setState({
      isEditable: this.props.isEditable
    })
  }

  /**
   * Execute cancel or show cancel confirmation window
   */
  onTryCancel = (editState) => () => {
    if (this.props.skipCancelConfirmation || editState < editableState.EDITED) {
      this.onCancel()
    } else {
      this.setState({
        confirmingCancel: this.onCancel
      })
    }
  }

  /**
   * Execute cancel of changes done in dialog. Can be overridden by func from props
   * To override return false from provided function
   */
  onCancel = () => {
    console.log('EditorDialog:onCancel', this.state, this.props)
    if (this.props.onCancel && this.props.onCancel(false)) return

    track(this.props.objectType, 'dialog_cancel', 'cancel')

    let isEditable = this.state.isEditable
    let isEditableDown =
      isEditable > editableState.EDITING
        ? editableState.EDITING
        : isEditable > editableState.EDITABLE
        ? editableState.EDITABLE
        : isEditable

    this.setState({
      isEditable: isEditableDown,
      isLoading: false,

      messageText: '',
      successText: '',
      errorText: '',
      confirmingCancel: false
    })
  }

  /**
   * Execute save and close of dialog if requested
   * @return overwrite - true if behavior need to be overridden.
   */

  // ? added checks and confirmation window when changing a parameter or response
  onSaveAndClose = () => {
    console.log('EditorDialog:onSaveAndClose')

    if (this.state.confirmingEdit) {
      this.setState({
        confirmingEdit: false
      })
    }
    if (this.props.onSave && this.props.onSave(true)) return
    this.onClose()
  }

  onTrySaveAndClose = () => {
    const editingChild = this.props.editingChild
    if ((this.props.objectType === 'parameter' || this.props.objectType === 'response') && editingChild) {
      const majorObjectIdentity = this.props.editingChild.field.identity
      const oper = this.props.majorObject.operations
      let findData = []
      oper.map((elem) => {
        if (this.props.objectType === 'parameter') {
          const filterParam = elem.parameters.filter(
            (e) => e.id === majorObjectIdentity.id || e.name === majorObjectIdentity.name
          )
          findData.push(...filterParam)
        }
        if (this.props.objectType === 'response') {
          const filterResp = elem.responses.filter(
            (e) => e.id === majorObjectIdentity.id || e.name === majorObjectIdentity.name
          )
          findData.push(...filterResp)
        }
      })
      findData.length < 1 ? this.onSaveAndClose() : this.setState({ confirmingEdit: this.onSaveAndClose })
    } else this.onSaveAndClose()
  }

  onTryCancelAndClose = (editState) => () => {
    if (this.props.skipCancelConfirmation || editState < editableState.EDITED) {
      this.onCancelAndClose()
    } else {
      this.setState({
        confirmingCancel: this.onCancelAndClose
      })
    }
  }

  /**
   * Execute cancel of changes done in dialog and close of dialog if requested
   * @return owerrite - true if bihavior need to be overriden.
   */
  onCancelAndClose = () => {
    //console.log("EditorDialog:onCancelAndClose");

    if (this.props.onCancel && this.props.onCancel(true)) return

    this.onClose()
  }

  /**
   * Change screen size
   */
  onToggleFullScreen = () => {
    this.setState({ isFullscreen: !this.state.isFullscreen }, () => {
      if (this.props.onToggleFullScreen) this.props.onToggleFullScreen(this.state.isFullscreen)
    })
  }

  /**
   * Rendering control in the dialog header
   * @return
   */
  renderHeaderControls = (editState) => {
    let status = !this.props.majorObject
      ? 'draft'
      : getVersionStatus(this.props.majorObject.settings ? this.props.majorObject.settings : this.props.majorObject)
    let button = this.props.confirmText ? this.props.confirmText : 'Save'
    let disabled = editState < editableState.EDITED || this.state.isLoading || this.state.messageText.length > 0
    //console.log("EditorDialog:renderHeaderControls", editState, data, button, disabled, this.state, this.props);
    let isEditing = this.props.nextLevel
      ? editState === editableState.BROWSABLE || status !== 'draft' || this.props.nextLevel
      : editState === editableState.BROWSABLE || status !== 'draft'
    let content = (
      <div>
        {this.props.version ? (
          <div className="EditorDialog__headerVersion">
            <div className="row EditorDialog__row">
              <div className="col-xs-3">
                <EditorVersion
                  appState={this.props.appState}
                  actions={this.props.actions}
                  isEditable={editState > editableState.BROWSABLE ? editableState.EDITABLE : editableState.BROWSABLE}
                  isSettings
                  dialogData={this.props.dialogData}
                  object={this.props.majorObject}
                  version={this.props.version}
                  onVersionChange={this.props.onVersionChange}
                  onCreateDraft={this.props.onCreateDraft}
                  onFinalize={this.props.onFinalize}
                  onApprove={this.props.onApprove}
                />
              </div>
              <div className="col-xs-4">
                <div className="EditorDialog__headerButton">
                  <EditorDialogButton
                    dialogData={this.props.dialogData}
                    inEditMode={editState > editableState.EDITABLE}
                    saveText={button}
                    hideSave={disabled}
                    onEdit={this.onEdit.bind(this)}
                    onSave={this.onSave.bind(this)}
                    onCancel={this.onTryCancel(editState)}
                    cannotEdit={editState === editableState.BROWSABLE || status !== 'draft'}
                    cannotEditHint="Object is not editable"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="EditorDialog__headerControl">
            <EditorDialogButton
              dialogData={this.props.dialogData}
              inEditMode={editState > editableState.EDITABLE}
              saveText={button}
              hideSave={disabled}
              onEdit={this.onEdit.bind(this)}
              onSave={this.onSave.bind(this)}
              onCancel={this.onTryCancel(editState)}
              cannotEdit={isEditing}
              cannotEditHint="Object is not editable"
            />
          </div>
        )}
        {!this.props.hideFullscreenButton ? (
          <div className="EditorDialog__headerControlAdditional" onClick={this.onToggleFullScreen}>
            <span className={!this.state.isFullscreen ? 'Button_FullScreen' : 'Button_Window'}></span>
          </div>
        ) : null}
      </div>
    )

    if (this.props.headerContent) return this.props.headerContent(editState, content)
    else return content
  }

  /**
   * Rendering upper part of dialog
   * @return
   */
  renderUpper = (editState) => {
    //console.log("EditorDialog:renderUpper", this.props, this.state);
    if (this.props.upperContent) {
      return this.props.upperContent(editState)
    } else {
      return ''
    }
  }

  /**
   * Rendering footer of the dialog based on messages and state
   * @return
   */
  renderFooter = (editState) => {
    let button = this.props.confirmText ? this.props.confirmText : 'Save'
    let messaged = this.state.messageText && this.state.messageText.length > 0
    let disabled = editState < editableState.EDITED || this.state.isLoading || messaged ? ' disabled' : ''

    //console.log("EditorDialog:renderFooter", this.props, this.state, messaged, button, disabled);
    return (
      <div className="EditorDialog__footer">
        {this.props.footerContent ? this.props.footerContent(editState) : ''}
        {this.state.isLoading || messaged ? (
          <div>
            {messaged ? <div className="EditorDialog__message">{this.state.messageText}</div> : null}
            <div className="EditorDialog__loader">
              <Loader />
            </div>
          </div>
        ) : (
          <div>
            {this.state.errorText && this.state.errorText.length > 0 ? (
              <div className="EditorDialog__error">{this.state.errorText}</div>
            ) : (
              <div className="EditorDialog__success">{this.state.successText}</div>
            )}
          </div>
        )}
        {editState > editableState.EDITABLE ? (
          <div className="EditorDialog__footerButtons">
            <button
              className="btn_big EditorDialog__button EditorDialog__cancelButton"
              onClick={this.onTryCancelAndClose(editState)}
            >
              {disabled ? 'Close' : 'Cancel'}
            </button>
            <button
              className={'btn_big' + disabled + ' EditorDialog__button' + ' EditorDialog__saveButton'}
              onClick={this.onTrySaveAndClose.bind(this)}
            >
              {button}
            </button>
          </div>
        ) : (
          <div className="EditorDialog__footerButtons">
            <button
              className="btn_big EditorDialog__button EditorDialog__cancelButton"
              onClick={this.onClose.bind(this)}
            >
              Close
            </button>
          </div>
        )}
      </div>
    )
  }

  renderCancelConfirmation = () => {
    const baseLevel = this.props.level ? this.props.level : 0
    return (
      <ConfirmDialog
        baseLevel={baseLevel}
        caption="Discard changes?"
        text="If you continue, your changes will be discarded."
        cancelText="Back"
        confirmText="Discard"
        onCancel={() => this.setState({ confirmingCancel: false })}
        onConfirm={this.state.confirmingCancel}
      />
    )
  }
  // ? window rendering on parameter or response change
  renderEditConfirmation = () => {
    const baseLevel = this.props.level ? this.props.level : 0
    return (
      <ConfirmDialog
        baseLevel={baseLevel}
        caption="Confirm сhanges?"
        text="If you continue, your changes will be applied to the Operations."
        cancelText="No"
        confirmText="Yes"
        onCancel={() => this.setState({ confirmingEdit: false })}
        onConfirm={this.state.confirmingEdit}
      />
    )
  }

  /**
   * Render function of dialog
   * @return
   */
  render() {
    let editState = this.props.isEditable < 3 ? this.state.isEditable : this.props.isEditable
    if (this.props.isEditable === 0)
      // case when EditorDialog is not editable
      editState = 0

    let visibleState = this.props.isVisible
    let baseLevel = this.props.level ? this.props.level : 0
    let className =
      'EditorDialog' +
      (this.props.objectType ? ' EditorDialog_' + this.props.objectType : '') +
      (this.state.isFullscreen ? ' EditorDialog_fullscreen' : '') +
      ' ' +
      (editState >= editableState.EDITING ? 'EditorDialog__editing' : 'EditorDialog__viewing') +
      ' ' +
      (editState > editableState.BROWSABLE ? 'EditorDialog__editable' : '')

    //console.log("EditorDialog:render", editState, baseLevel, className, this.props, this.state);
    return (
      <div>
        <ModalWindow
          ref="modal"
          className={className}
          objectType={this.props.objectType}
          isOpen={visibleState}
          header={typeof this.props.modalTitle === 'function' ? this.props.modalTitle() : this.props.modalTitle}
          frame={this.props.modalFrame}
          height={this.props.editHeight}
          headerContent={<div>{visibleState ? this.renderHeaderControls(editState) : null}</div>}
          upper={visibleState ? this.renderUpper(editState) : ''}
          footer={<div>{visibleState ? this.renderFooter(editState) : null}</div>}
          onClose={this.onClose.bind(this)}
          level={baseLevel}
        >
          {visibleState ? this.props.editContent(editState) : ''}
          {this.state.confirmingCancel ? this.renderCancelConfirmation() : null}
          {this.state.confirmingEdit ? this.renderEditConfirmation() : null}
        </ModalWindow>
        {this.props.dialogContent ? this.props.dialogContent(editState) : null}
      </div>
    )
  }
}

EditorDialog.propTypes = {
  // Editor for specific object type
  appState: PropTypes.object,
  actions: PropTypes.object,
  className: PropTypes.string,
  level: PropTypes.number, // Level of dialog
  objectType: PropTypes.string, // Dialog type
  modalTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]), // Dialog title
  upperContent: PropTypes.func, // Function to prepare content for upper
  editContent: PropTypes.func, // Function to prepare content to edit
  editHeight: PropTypes.number, // Height of edit content
  footerContent: PropTypes.func, // Function to prepare content for footer
  headerContent: PropTypes.func, // Optional function to prepare content for header
  dialogContent: PropTypes.func, // Optional function to show child dialog
  confirmText: PropTypes.string, // Confirm caption.
  isVisible: PropTypes.bool, // Dialog visible
  isEditable: PropTypes.number, // Dialog edit index (1 - Editable, 2 - Editing, 3 - Edited)
  dialogData: PropTypes.object, // Dialog data container.
  majorObject: PropTypes.object, // Major object dialog run in.
  version: PropTypes.object,
  onClose: PropTypes.func, // Function will be called before close.
  onEdit: PropTypes.func, // Function will be called before going to edit mode.
  onVersionChange: PropTypes.func,
  onCreateDraft: PropTypes.func,
  onFinalize: PropTypes.func,
  onApprove: PropTypes.func,
  onSave: PropTypes.func.isRequired, // Function will be called before save.
  onCancel: PropTypes.func, // Function will be called before cancel changes.
  isLoading: PropTypes.bool,
  messageText: PropTypes.string,
  successText: PropTypes.string,
  errorText: PropTypes.string,
  skipCancelConfirmation: PropTypes.bool,
  hideFullscreenButton: PropTypes.bool,
  onToggleFullScreen: PropTypes.func
}
