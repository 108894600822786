import jwt_decode from 'jwt-decode'
import React, { Component } from 'react'
import { API } from '../../helpers/api'
import { AdaptiveImage } from '../AdaptiveImage/AdaptiveImage'
import { Footer } from '../Footer/Footer'
import { HeaderOld } from '../Header/HeaderOld'
import { Loader } from '../Loader/Loader'
import './style.scss'

const aesjs = require('aes-js')

/**
 * Local auth page for no-auth mode (work without Identity Service)
 * Allows login without password
 * This mode is activated in config.js by setting AUTH_URL to "disabled"
 * Setting window.authUserId in config.js is required
 */
const AES_KEY = 'DifHubLoginPag20'

function AES_encrypt(text, key1) {
  if (window.AUTH_URL !== '/identity') return text

  const key = new TextEncoder('ascii').encode(AES_KEY)
  //console.log("key",key);

  // Convert text to bytes
  const textBytes = aesjs.utils.utf8.toBytes(text)

  // The counter is optional, and if omitted will begin at 1
  let aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5))
  let encryptedBytes = aesCtr.encrypt(textBytes)

  // To print or store the binary data, you may convert it to hex
  const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes)
  //console.log(encryptedHex);

  // When ready to decrypt the hex string, convert it back to bytes
  encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex)

  // The counter mode of operation maintains internal state, so to
  // decrypt a new instance must be instantiated.
  aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5))

  //console.log(decryptedText);
  // "Text may be any length you wish, no padding is required."
  return encryptedHex
}

export class AuthPageLocal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      login: '',
      error: '',
      org: '',
      organizations: [],
      loading: false
    }
  }

  componentDidMount() {
    if (window.AUTH_URL_LOCAL !== 'true') {
      this.props.history.push('/auth')
      return
    }

    setTimeout(() => {
      this.setState({ loading: true })
      console.log('AuthPageLocal::componentDidMount', window.authUserId)
      if (!window.authUserId) alert('window.authUserId is not specified in config.js!')

      if (window.authOrganization) {
        this.setState({
          org: window.authOrganization
        })
        this.setState({ loading: false })
      } else {
        alert('window.authOrganization must be specified in config.js!')
      }
    }, 100)
  }

  login = () => {
    if (this.state.login && this.state.password) {
      this.setState({ loading: true })

      let login = AES_encrypt(this.state.login, AES_KEY)
      let password = AES_encrypt(this.state.password, AES_KEY)

      fetch(window.AUTH_URL, {
        method: 'POST',
        body: JSON.stringify({
          password: password,
          username: login
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        console.log('Fetch response', response)
        response.json().then((data) => {
          console.log('Fetch json', data)

          console.log('Clear token')
          localStorage['currentUserToken'] = ''
          localStorage['currentUserIdToken'] = ''

          const token = data.token.split(' ')[1]

          console.log('Store token', token)

          localStorage['currentUserToken'] = token
          localStorage['currentUserIdToken'] = token

          API.getcurrentuser.get().then((by) => {
            if (by && by.id) {
              console.log('received user by', by)
              this.setState({ loading: false })

              localStorage['currentUserId'] = by.id
              this.props.history.push(localStorage['loginReturnUrl'] ? localStorage['loginReturnUrl'] : '/select')
              localStorage['loginReturnUrl'] = ''
            }
          })

          const decoded = jwt_decode(token)
          const email = decoded.email

          API.organizations(this.state.org)
            .users(email)
            .get()
            .then(
              (user) => {
                console.log('received user', user)

                this.setState({ loading: false })

                localStorage['currentUserId'] = user.user.id

                this.props.history.push(localStorage['loginReturnUrl'] ? localStorage['loginReturnUrl'] : '/select')
                localStorage['loginReturnUrl'] = ''
              },
              (error) => {
                console.error('cant get user', error)
                this.setState({ loading: false })
                if (error.status === 404) {
                  this.setState({
                    error: 'Incorrect login: ' + this.state.login
                  })
                } else if (error.status === 0) {
                  this.setState({
                    error: 'Cannot connect to backend'
                  })
                } else {
                  if (error.response) {
                    this.setState({
                      error: 'Unknown error: ' + error.response
                    })
                  } else {
                    this.setState({
                      error: 'Unknown error: ' + JSON.stringify(error)
                    })
                  }
                }
              }
            )
        })
      })
    } else {
      this.setState({ error: 'Please enter login and password' })
    }
  }

  register = () => {
    if (this.state.login && this.state.password) {
      let login = AES_encrypt(this.state.login, AES_KEY)
      let password = AES_encrypt(this.state.password, AES_KEY)
      this.setState({ loading: true })

      fetch(window.AUTH_URL, {
        method: 'POST',
        body: JSON.stringify({
          password: password,
          username: login
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        console.log('Fetch response', response)
        response.json().then((data) => {
          console.log('Fetch json', data)

          console.log('Clear token')
          localStorage['currentUserToken'] = ''
          localStorage['currentUserIdToken'] = ''

          const token = data.token.split(' ')[1]

          if (!token) this.setState({ error: 'No token returned' })
          else {
            // use hardcoded user id to make the first request
            // it is needed to get user's id by their login
            if (!window.authUserId) alert('window.authUserId is not specified in config.js!')
            localStorage['currentUserId'] = window.authUserId
            localStorage['currentUserToken'] = token
            localStorage['currentUserIdToken'] = token

            const decoded = jwt_decode(token)
            const email = decoded.email

            API.register.post({ name: email, description: window.authOrganizationId }).then(
              (user) => {
                console.log('received user', user)

                API.getcurrentuser.get().then((by) => {
                  if (by && by.id) {
                    console.log('received user by', by)
                    this.setState({ loading: false })

                    localStorage['currentUserId'] = by.id
                    this.props.history.push(localStorage['loginReturnUrl'] ? localStorage['loginReturnUrl'] : '/select')
                    localStorage['loginReturnUrl'] = ''
                  }
                })

                /*
                  localStorage["currentUserId"] = user.id;
                  localStorage["currentUserToken"] = user.id;
                  localStorage["currentUserIdToken"] = user.id;

                  this.props.history.push(localStorage['loginReturnUrl'] ? localStorage['loginReturnUrl'] : '/select');
                  localStorage['loginReturnUrl'] = '';
                  */
              },
              (error) => {
                console.error('cant get user', error)
                this.setState({ loading: false })
                this.setState({
                  error: 'Incorrect login or password: ' + JSON.stringify(error)
                })
              }
            )
          }
        })
      })
    } else {
      this.setState({ error: 'Please enter login and password' })
    }
  }

  render() {
    //<button className="LoginPage__button" type="button" onClick={this.register}>Register</button>

    return (
      <div>
        <HeaderOld
          title="DataHub"
          userState={this.props.userState}
          appState={this.props.appState}
          actions={this.props.actions}
        />

        <div className="AuthPageLocal ">
          <div className="">
            <div className="row">
              <div className="col-xs-4"></div>
              <div className="col-xs-4">
                <form
                  onKeyPress={(e) => {
                    if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
                      this.login()
                    }
                  }}
                >
                  <div className="LoginPage__root ProfileView Dialog">
                    <h1 className="Profile__header">
                      <AdaptiveImage src={'logo-main'} class="LoginPage__Logo" />
                    </h1>

                    <div className="row">
                      <div className="col-xs-12">
                        <input
                          autoFocus
                          className="LoginPage__textInput"
                          placeholder="Username"
                          type="text"
                          defaultValue=""
                          onChange={(e) => this.setState({ login: e.target.value })}
                        />
                        <input
                          autoFocus
                          className="LoginPage__textInput"
                          placeholder="Password"
                          type="password"
                          defaultValue=""
                          onChange={(e) => this.setState({ password: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">
                        <button className="LoginPage__button" type="button" onClick={this.login}>
                          Sign in
                        </button>
                        <button className="LoginPage__button" type="button" onClick={this.register}>
                          Register
                        </button>
                      </div>
                    </div>
                    <div className="LoginPage__error">{this.state.error}</div>
                    <div className="LoginPage__loader">{this.state.loading ? <Loader /> : null}</div>
                  </div>
                </form>
              </div>
              <div className="col-xs-4"></div>
            </div>
          </div>
        </div>

        <div className="auth_footer"> </div>
        <Footer />
      </div>
    )
  }
}
