import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu'
import { Link } from 'react-router-dom'
import ScrollArea from 'react-scrollbar'
import { getRequestFromPath } from '../../helpers/api'
import { getFullUri, versionToStr } from '../../helpers/index'
import logger from '../../helpers/logger'
import { Loader } from '../Loader/Loader'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import { Collapse } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'

const jsdiff = require('diff')

// placed outside component's state to reduce redraw count
// lastCheckpointTime is used to force redraw
let checkpointData = [] // loaded objects for checkpoints that have been selected

export class ObjectHistoryTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedCheckpoint: null,
      lastCheckpointTime: 0, // used to force update of component when some checkpoint is loaded
      restoringCheckpoint: false,
      versions: this.props.data[0].versions,
      subMenu: {}
    }

    this.scrollRef = React.createRef()
  }

  /**
   * If versions were loaded by parent component, adds them to state
   * @param newProps
   */
  UNSAFE_componentWillReceiveProps(newProps) {
    let versions = this.state.versions

    if (this.state.selectedCheckpoint === null && versions && versions.length > 0) {
      for (let i = 0; i < versions.length; i++) {
        if (versions[i].checkpoints && versions[i].checkpoints.length > 0) {
          this.setState({ selectedCheckpoint: versions[i].checkpoints[0] })
          return
        }
      }
    }
  }

  /**
   * Selects checkpoint
   * @param checkpoint
   */
  select = (checkpoint) => () => {
    this.setState({ selectedCheckpoint: checkpoint })
  }

  /**
   * Renders the right part of component (diff between checkpoints)
   * @returns {*}
   */
  renderDiff() {
    let versions = this.state.versions

    const newPoint = this.state.selectedCheckpoint

    if (!newPoint) {
      return 'Please select checkpoint to view diff'
    }

    let oldPoint = null
    let checkpointFound = false

    // find previous checkpoint that is before selected checkpoint (in same version or in previous version)
    ;(versions || []).map((version) => {
      return (version.checkpoints || []).forEach((checkpoint, checkpointIndex) => {
        if (checkpointFound && !oldPoint) {
          oldPoint = checkpoint
        }

        if (checkpoint === newPoint) {
          checkpointFound = true
        }
      })
    })

    // if the first checkpoint was not loaded, request it
    if (!oldPoint) {
      //return "Please select newer checkpoint to view diff";
      if (!checkpointData[newPoint.updated]) {
        if (checkpointData[newPoint.updated] !== 'loading') {
          checkpointData[newPoint.updated] = 'loading'
          getRequestFromPath(getFullUri(this.props.parentObject))
            .versions(versionToStr(newPoint.version.version))
            .checkpoints(newPoint.updated)
            .get()
            .then((res) => {
              //console.log("checkpoint response", res);
              checkpointData[newPoint.updated] = res
              this.setState({ lastCheckpointTime: new Date().getTime() })
            })
        }
      }

      // if the first checkpoint was selected, show full json, not diff
      if (checkpointData[newPoint.updated] && checkpointData[newPoint.updated] !== 'loading') {
        let newJson = JSON.stringify(checkpointData[newPoint.updated], null, 4)

        return newJson.split('\n').map((line, lineCount) => {
          return (
            <div className={'row DatasetHistoryTable__jsonRow'} key={lineCount}>
              <div className="col-xs-1 DatasetHistoryTable__rowNumber">{lineCount + 1}</div>
              <div className="col-xs-1 DatasetHistoryTable__rowNumber">{lineCount + 1}</div>
              <div className="col-xs-10 DatasetHistoryTable__json">
                <pre>{line}</pre>
              </div>
            </div>
          )
        })
      } else {
        return
      }
    }

    // check if old checkpoint is loaded and request if not
    if (!checkpointData[oldPoint.updated]) {
      if (checkpointData[oldPoint.updated] !== 'loading') {
        checkpointData[oldPoint.updated] = 'loading'
        getRequestFromPath(getFullUri(this.props.parentObject))
          .versions(versionToStr(oldPoint.version.version))
          .checkpoints(oldPoint.updated)
          .get()
          .then((res) => {
            //console.log("checkpoint response", res);
            checkpointData[oldPoint.updated] = res
            this.setState({ lastCheckpointTime: new Date().getTime() })
          })
      }
    }
    // check if new checkpoint is loaded and request if not
    if (!checkpointData[newPoint.updated]) {
      if (checkpointData[newPoint.updated] !== 'loading') {
        checkpointData[newPoint.updated] = 'loading'
        getRequestFromPath(getFullUri(this.props.parentObject))
          .versions(versionToStr(newPoint.version.version))
          .checkpoints(newPoint.updated)
          .get()
          .then((res) => {
            //console.log("checkpoint response", res);
            checkpointData[newPoint.updated] = res
            this.setState({ lastCheckpointTime: new Date().getTime() })
          })
      }
    }

    // if both new and old checkpoints are loaded, show diff
    if (
      checkpointData[oldPoint.updated] &&
      checkpointData[oldPoint.updated] !== 'loading' &&
      checkpointData[newPoint.updated] &&
      checkpointData[newPoint.updated] !== 'loading'
    ) {
      //console.log("oldJson data", checkpointData[oldPoint.updated]);
      let oldJson = JSON.stringify(checkpointData[oldPoint.updated], null, 4) //.split('{').map(s => s + "\n{");
      //console.log("oldJson", oldJson);
      let newJson = JSON.stringify(checkpointData[newPoint.updated], null, 4) //.split('{').map(s => s + "\n{");

      // compare JSONs of two checkpoints and prepare indexes
      let changes = jsdiff.diffLines(oldJson, newJson)
      let oldLineIndex = 1
      let newLineIndex = 1

      let ret = changes.map((change, changeIndex) => {
        //let newLine = newJson[lineIndex];
        let prev = null
        let next = null

        let lineCount = change.value.split('\n').length
        let nextNewLineIndex = newLineIndex + (change.removed ? 0 : lineCount)
        let nextOldLineIndex = oldLineIndex + (change.added ? 0 : lineCount)

        if (changeIndex > 0) {
          if (!changes[changeIndex - 1].added && !changes[changeIndex - 1].removed) {
            const prevlines = changes[changeIndex - 1].value.split('\n')
            let prevlen = prevlines.length - 1
            while (prevlen > 0 && prevlines[prevlen].indexOf('{') === -1) prevlen--
            //console.log("lines",prevlines,"prevlen",prevlen,"slice",prevlines.slice(prevlen, prevlines.length - prevlen));
            let prevdata = prevlines.slice(prevlen)
            prev = (
              <div className={'row DatasetHistoryTable__jsonRow'}>
                <div className="col-xs-1 DatasetHistoryTable__rowNumber">{oldLineIndex - prevdata.length}</div>
                <div className="col-xs-1 DatasetHistoryTable__rowNumber">{newLineIndex - prevdata.length}</div>
                <div className="col-xs-10 DatasetHistoryTable__json">
                  <pre>{prevdata.join('\n')}</pre>
                </div>
              </div>
            )
          }
        }
        if (changeIndex < changes.length - 1) {
          if (!changes[changeIndex + 1].added && !changes[changeIndex + 1].removed) {
            next = (
              <div className={'row DatasetHistoryTable__jsonRow'}>
                <div className="col-xs-1 DatasetHistoryTable__rowNumber">{nextOldLineIndex}</div>
                <div className="col-xs-1 DatasetHistoryTable__rowNumber">{nextNewLineIndex}</div>
                <div className="col-xs-10 DatasetHistoryTable__json">
                  <pre>{changes[changeIndex + 1].value.split('\n').slice(0, 3).join('\n')}</pre>
                </div>
              </div>
            )
          }
        }

        let ret = null

        if (change.added) {
          ret = [
            <React.Fragment key={changeIndex}>
              {prev}
              <div className={'row DatasetHistoryTable__jsonRow DatasetHistoryTable__jsonRowBlue'}>
                <div className="col-xs-1 DatasetHistoryTable__rowNumber">{oldLineIndex}</div>
                <div className="col-xs-1 DatasetHistoryTable__rowNumber">{newLineIndex}</div>
                <div className="col-xs-10 DatasetHistoryTable__json">
                  <pre>{change.value}</pre>
                </div>
              </div>
              {next}
            </React.Fragment>
          ]
        } else if (change.removed) {
          ret = [
            <React.Fragment key={changeIndex}>
              {prev}
              <div className={'row DatasetHistoryTable__jsonRow DatasetHistoryTable__jsonRowRed'}>
                <div className="col-xs-1 DatasetHistoryTable__rowNumber">{oldLineIndex}</div>
                <div className="col-xs-1 DatasetHistoryTable__rowNumber">{newLineIndex}</div>
                <div className="col-xs-10 DatasetHistoryTable__json">
                  <pre>{change.value}</pre>
                </div>
              </div>
              {next}
            </React.Fragment>
          ]
        }

        newLineIndex = nextNewLineIndex
        oldLineIndex = nextOldLineIndex

        return ret
      })

      if (ret.filter((r) => r).length === 0) return 'No changes detected'
      else return ret
    }

    //console.log("checkpointData",checkpointData);
  }

  /**
   * Copy object from checkpoint to clipboard
   * @param e
   * @param data
   */
  copyCheckpoint = (e, data) => {
    console.log('copyCheckpoint ', data)

    let checkpoint = data.checkpoint

    if (checkpointData[checkpoint.updated]) {
      console.log('Copied', checkpointData[checkpoint.updated])

      localStorage.clipboard = JSON.stringify(checkpointData[checkpoint.updated])
      localStorage.clipboardType = this.props.parentObject.type
    } else {
      alert('Checkpoint data was not loaded, please try again')
    }
  }

  /**
   * Show confirmation for Restore checkpoint
   * @param e
   * @param data
   */
  restoreCheckpoint = (e, data) => {
    logger.log('restoreCheckpoint ', data)

    if (checkpointData[data.checkpoint.updated]) {
      this.setState({
        restoringCheckpoint: data.checkpoint
      })
    } else {
      alert('Checkpoint data was not loaded, please try again')
    }
  }

  /**
   * Call parent's restore function with selected checkpoint
   */
  realRestoreCheckpoint = () => {
    const { restore } = this.props.data[0]
    logger.log(
      'restore checkpoint',
      this.state.restoringCheckpoint.updated,
      checkpointData[this.state.restoringCheckpoint.updated]
    )
    restore(checkpointData[this.state.restoringCheckpoint.updated])
    this.setState({ restoringCheckpoint: false })
  }

  renderRestoreConfirmWindow() {
    return (
      <ModalWindow
        isOpen
        onClose={() => this.setState({ restoringCheckpoint: false })}
        canClose
        footer={
          <div className="OrganizationList__deleteButtons">
            <button className="btn_big" onClick={() => this.setState({ restoringCheckpoint: false })}>
              No
            </button>
            <button className="btn_big" onClick={this.realRestoreCheckpoint}>
              Yes
            </button>
          </div>
        }
      >
        Do you really want to restore {this.props.parentObject.type} to selected checkpoint?
      </ModalWindow>
    )
  }

  formatTime(updated) {
    return moment.utc(updated).local().format('MMM DD, YYYY HH:mm')
  }
  loadCheckpoints(version) {
    const versionStr = Object.values(version.version).join('.')

    if (!this.state.subMenu[versionStr] && version.checkpoints[0].versionName) {
      if (version.checkpoints.length > 1) {
        this.setState({ subMenu: { ...this.state.subMenu, [versionStr]: !this.state.subMenu[versionStr] } })
        return
      }
      getRequestFromPath(getFullUri(this.props.parentObject))
        .versions(version.checkpoints[0].versionName)
        .checkpoints()
        .get()
        .then((data) => {
          console.log(version, this.state.versions)
          let newVers = this.state.versions
          newVers.map((e) => {
            if (e.version === version.version) {
              const checkpoints = data.map((v) => {
                return Object.assign(
                  {},
                  v,
                  { version: e.checkpoints[0].version },
                  { versionName: versionStr },
                  { sub: true }
                )
              })

              e.checkpoints.push(...checkpoints)
              this.setState({ versions: newVers })
            }
          })
        })
    }

    this.setState({ subMenu: { ...this.state.subMenu, [versionStr]: !this.state.subMenu[versionStr] } })
  }

  render() {
    let { loadingCheckpoints } = this.props.data[0]
    let versions = this.state.versions
    const subMenu = this.state.subMenu
    return (
      <div className="DatasetHistoryTable">
        {loadingCheckpoints ? (
          <Loader />
        ) : (
          <div>
            {this.state.restoringCheckpoint ? this.renderRestoreConfirmWindow() : null}

            <div className="row DatasetHistoryTable__header">
              <div className="col-xs-2">Versions</div>
              <div className="col-xs-3">Date (local time)</div>
              <div className="col-xs-7">Json</div>
            </div>

            <ContextMenu id="datasetHistoryTableMenu">
              <MenuItem data={{ action: 'copy' }} onClick={this.copyCheckpoint}>
                Copy
              </MenuItem>
              <MenuItem data={{ action: 'restore' }} onClick={this.restoreCheckpoint}>
                Restore
              </MenuItem>
            </ContextMenu>

            <div className="row DatasetHistoryTable__body">
              <div className="col-xs-4">
                <div className="DatasetHistoryTable__scrollAreaOuter">
                  <ScrollArea ref={this.scrollRef} horizontal={false}>
                    <div className="DatasetHistoryTable__scrollAreaInner">
                      <div className="DatasetHistoryTable__cardBackground" />
                      <div className="DatasetHistoryTable__cardLine" />

                      {(versions || [])
                        .sort((a, b) => {
                          const at = Object.values(a.version).join('.')
                          const bt = Object.values(b.version).join('.')
                          if (at < bt) return 1
                          else if (at > bt) return -1
                          else return 0
                        })
                        .map((version) => {
                          const versionStr = Object.values(version.version).join('.')
                          return (version.checkpoints || []).map((checkpoint, checkpointIndex) => {
                            return (
                              <>
                                <ContextMenuTrigger
                                  holdToDisplay="-1"
                                  id="datasetHistoryTableMenu"
                                  key={checkpointIndex}
                                  collect={() => {
                                    return {
                                      checkpoint: checkpoint,
                                      checkpointIndex: checkpointIndex
                                    }
                                  }}
                                >
                                  <Collapse in={subMenu[versionStr]} timeout="auto" unmountOnExit>
                                    {(versions || [])
                                      .sort((a, b) => {
                                        const at = Object.values(a.version).join('.')
                                        const bt = Object.values(b.version).join('.')
                                        if (at < bt) return 1
                                        else if (at > bt) return -1
                                        else return 0
                                      })
                                      .map((versionSub) => {
                                        let sub = []
                                        versionSub.checkpoints.map((e) => {
                                          if (e.sub) sub.push(e)
                                        })
                                        return (sub || [])
                                          .sort((a, b) => {
                                            const at = new Date(a.updated)
                                            const bt = new Date(b.updated)
                                            console.log(new Date(a.updated), new Date(b.updated))
                                            if (at < bt) return 1
                                            else if (at > bt) return -1
                                            else return 0
                                          })
                                          .map(
                                            (checkpointSub, checkpointIndexSub) =>
                                              subMenu[versionStr] &&
                                              checkpointIndex === 0 &&
                                              checkpointSub.versionName === checkpoint.versionName && (
                                                <div
                                                  className={
                                                    'DatasetHistoryTable__card ' +
                                                    (checkpointSub === this.state.selectedCheckpoint
                                                      ? 'DatasetHistoryTable__card__active'
                                                      : '')
                                                  }
                                                  onClick={this.select(checkpointSub)}
                                                >
                                                  <div className="row">
                                                    <div className={'col-xs-1 col DatasetHistoryTable__icon'} />
                                                    <div className="col col-xs-7 DatasetHistoryTable__name">
                                                      {checkpointSub.updatedBy}
                                                    </div>
                                                    <div className="col col-xs-4 DatasetHistoryTable__time">
                                                      {this.formatTime(checkpointSub.updated)}
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                          )
                                      })}
                                  </Collapse>
                                  {checkpointIndex === 0 && (
                                    <div
                                      className={
                                        'DatasetHistoryTable__card DatasetHistoryTable__cardVersion ' +
                                        (checkpoint === this.state.selectedCheckpoint
                                          ? 'DatasetHistoryTable__card__active'
                                          : '')
                                      }
                                      onClick={this.select(checkpoint)}
                                    >
                                      <div className="row">
                                        <div
                                          className={'col-xs-1 col  DatasetHistoryTable__iconBig'}
                                          onClick={() => {
                                            this.loadCheckpoints(version)
                                          }}
                                        >
                                          <div className="plus">
                                            {!subMenu[versionStr] ? (
                                              <AddIcon fontSize="small" />
                                            ) : (
                                              <RemoveIcon fontSize="small" />
                                            )}
                                          </div>
                                        </div>
                                        <div className="col col-xs-7">
                                          v. {versionToStr(version.version)} by{' '}
                                          <span className="DatasetHistoryTable__name">{checkpoint.updatedBy}</span>
                                        </div>
                                        <div className="col col-xs-4 DatasetHistoryTable__time">
                                          {this.formatTime(checkpoint.updated)}
                                        </div>
                                        {checkpoint === this.state.selectedCheckpoint && version.message ? (
                                          <div className="DatasetHistoryTable__message">
                                            <Link to={'/view' + version.messageUri.replace('/issues/', '#issues/')}>
                                              {version.message.issue.identity.name}. {version.message.text}
                                            </Link>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  )}
                                </ContextMenuTrigger>
                              </>
                            )
                          })
                        })}
                    </div>
                  </ScrollArea>
                </div>
              </div>
              <div className="col-xs-8">{this.renderDiff() || <Loader />}</div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

ObjectHistoryTable.propTypes = {
  parentObject: PropTypes.object,
  data: PropTypes.array // data must have versions, checkpoints, loadingCheckpoints, restore
}
