/* eslint-disable array-callback-return */
/**
 * Created by mailf on 08.06.2016.
 */
import PropTypes from 'prop-types'
import React from 'react'
import DropZone from 'react-dropzone'
import { columnsToType, deepCopy, editableState, getFullUri, nonCSCompare, orderSort } from '../../helpers/index'
import logger from '../../helpers/logger'
import { EditorListBase } from '../EditorDialog/EditorListBase'
import { DocDialog } from './DocDialog'
import './DocList.scss'
import { getTailButtonLabel } from '../../helpers/helperComponents'

export class DocList extends EditorListBase {
  /**
   * Installs Paste listener
   */
  componentDidMount() {
    window.addEventListener('paste', this.onPasteItem)
  }

  /**
   * Removes paste listener
   */
  componentWillUnmount() {
    window.removeEventListener('paste', this.onPasteItem)
  }

  /**
   * Checks if clipboard contains an image
   * @param pasteEvent
   * @param callback
   */
  retrieveImageFromClipboardAsBlob(event, callback) {
    // Extract clipboard data.
    let clipboardData = event.clipboardData || window.clipboardData
    let items = null
    if (clipboardData) {
      items = clipboardData.items
    } else if (navigator.clipboard) {
      navigator.permissions.query({ name: 'clipboard-read' }).then((result) => {
        // If permission to read the clipboard is granted or if the user will
        // be prompted to allow it, we proceed.

        if (result.state === 'granted' || result.state === 'prompt') {
          navigator.clipboard.read().then((data) => {
            let count = data.length
            for (let i = 0; i < count; i++) {
              // Skip content if not image
              if (data[i].types[0].indexOf('image') === -1) continue
              // Retrieve image on clipboard as blob
              data[i].getType(data[i].types[0]).then((blob) => {
                logger.info(
                  'DocList:retrieveImageFromClipboardAsBlob:READ',
                  { data, count, blob },
                  this.state,
                  this.props
                )
                if (typeof callback === 'function') {
                  callback(blob)
                }
              })
            }
          })
        }
      })
      return
    }

    logger.info('DocList:retrieveImageFromClipboardAsBlob', event, clipboardData, items, this.state, this.props)

    if (items === undefined || items === null) {
      if (typeof callback === 'function') {
        callback(undefined)
      }
      return
    }

    for (let i = 0; i < items.length; i++) {
      // Skip content if not image
      if (items[i].type.indexOf('image') === -1) continue
      // Retrieve image on clipboard as blob
      let blob = items[i].getAsFile()

      if (typeof callback === 'function') {
        callback(blob)
      }
    }
  }

  /**
   * Select file from drop box
   * @param files - list of files selected.
   * @return
   */
  dropFile(files) {
    // Drop image file into element
    logger.info('DocList:dropFile', files, this.state, this.props)
    if (!files || files.length < 0) {
      logger.warn('DocList:dropFile no file selected', this.state, this.props)
      return 0
    }

    let objs = this.props.items || []
    let items = objs

    files.map((file) => {
      let fileName = file.path
      let fileURI =
        !this.props.contentUri && this.props.majorObject
          ? getFullUri(this.props.majorObject) + '/' + fileName
          : '/' + fileName

      let item = Object.assign({}, this.createItem(fileName), {
        type: 'Picture',
        uri: fileURI
      })
      items = items.concat(item)

      // Add content of pasted item.
      if (this.props.content && this.props.onContentChange) {
        const newContent = {
          identity: { name: fileName },
          type: 'Picture',
          fileURI: fileURI,
          fileName: fileName,
          fileData: file
        }
        this.props.onContentChange(newContent)
      }
    })
    this.props.onSave(items, false, () => {})
  }

  /**
   * Create new Item for editing
   * @return {object} empty element
   */
  createItem = (name) => {
    let objs = this.props.items || []

    let order = 0
    if (objs) {
      objs.map((f) => {
        order = order < f.order ? f.order : order
      })
    }
    order += 1
    return { identity: { name: name || '' }, order: order, type: '' }
  }

  /**
   * If there is a file on clipboard, save it as document in current issue
   * @param e
   */
  onPasteItem = (e) => {
    //logger.info("DocList:onPasteItem", e, this.state, this.props);
    this.retrieveImageFromClipboardAsBlob(e, (blob) => {
      if (!blob) {
        return
      }
      const fileName = 'screenshot_' + new Date().getTime() + '.png'

      let item = Object.assign({}, this.createItem(fileName), {
        type: 'Picture',
        uri: '/' + fileName
      })
      let objs = this.props.items || []
      let items = objs.concat(item)
      //logger.info("DocList:onPasteItem", { fileName, item, objs, items }, this.state, this.props);

      // Add content of pasted item.
      if (this.props.content && this.props.onContentChange) {
        const newFile = new File([blob], fileName, { type: 'image/png' })
        const newContent = {
          identity: { name: fileName },
          type: 'Picture',
          fileURI: '/' + fileName,
          fileName: fileName,
          fileData: newFile
        }
        this.props.onContentChange(newContent)
      }
      this.props.onSave(items, false, () => {})
    })
  }

  /**
   * If there is a file on clipboard, save it as document in current issue
   * @param e
   */
  onAddFiles = (e) => {
    let dialog = this.props.docsDialog
    let loader = null
    logger.info('DocList:onAddFiles', e, dialog, this.state, this.props)
    if (
      dialog &&
      dialog.refs &&
      dialog.refs.doclist_content &&
      dialog.refs.doclist_content.refs &&
      dialog.refs.doclist_content.refs.dropzone
    ) {
      loader = dialog.refs.doclist_content
    }

    if (loader) {
      loader.refs.dropzone.open()
    } else {
      logger.warn('DocList:onAddFiles', dialog, loader, this.state, this.props)
    }
  }

  /**
   * Delete Item from list
   * @param {Object} obj - object to edit
   * @param {Number} index - index of record to edit
   */
  onDeleteItem = (obj, deletedIndex) => {
    //logger.info("EditorListBase:onDeleteItem before check", obj, this.state, this.props);

    if (!obj._item.obj) {
      // We can't delete item from base layer
      return
    }
    let objs = this.props.items || []
    let items = objs.filter((tobj) => !nonCSCompare(this.getItemName(tobj), this.getItemName(obj)))
    logger.info('EditorListBase:onDeleteItem', obj, items, this.state, this.props)

    // Remove content if exist.
    if (this.props.content && this.props.onContentChange) {
      let currContent = this.props.content.find((cont) => nonCSCompare(cont.fileURI, obj.uri))
      let newContent = deepCopy(currContent)
      if (currContent) {
        newContent = deepCopy(currContent)
        newContent.fileData = null
      } else {
        newContent = { fileURI: obj.uri, fileData: null }
      }
      this.props.onContentChange(newContent)
      logger.info('EditorListBase:onDeleteItem:FILE', newContent, this.state, this.props)
    }
    this.props.onSave(items, false, () => {})
  }

  /**
   * Return filters requered for item list
   */
  getFilters = () => {
    return [
      { name: 'search', width: 200 },
      { name: '', width: 15 },
      { name: 'type', width: 80 }
    ]
  }

  /**
   * Return columns requered for item list
   */
  getColumns = () => {
    return [
      {
        displayName: '#',
        name: 'order',
        type: columnsToType.getType('id'),
        frozen: true,
        width: 40,
        hiddenInChildTable: true
      },
      {
        name: 'name',
        displayName: 'Document Name',
        type: columnsToType.getType('minorObjectName'),
        width: 170,
        onCellClick: (value) => {
          this.onViewItem(value)
        }
      },
      {
        name: 'description',
        displayName: this.renderDescriptionColumnHeader(),
        type: this.getDescriptionType(),
        width: 230
      },
      {
        name: 'type',
        type: columnsToType.getType('string'),
        frozen: true,
        width: 60
      },
      {
        name: 'uri',
        type: columnsToType.getType('string'),
        frozen: true,
        width: 265
      }
    ]
  }

  /**
   * Define rendering of tail buttons
   * @param {number} editState - edit state on dialog
   * @return - array of rendered buttons
   */
  getTailButtons(editState) {
    const canAdd = editState > editableState.EDITABLE
    if (!canAdd) {
      return (obj) => {
        let buttons = []
        //logger.info("TagList:getTailButtons", obj, this.props, this.state);
        if (!obj || !obj._item) {
          return buttons
        }
        buttons.push({
          label: getTailButtonLabel('View'),
          onClick: (obj, index) => this.props.onViewDocument(obj, index)
        })

        if (!nonCSCompare(obj.type, 'Link')) {
          buttons.push({
            label: getTailButtonLabel('Download'),
            onClick: (obj, index) => this.props.onDownloadDocument(obj, index)
          })
        } else {
          buttons.push({
            label: '',
            onClick: (obj, index) => this.onViewItem(obj, index)
          })
        }
        return buttons
      }
    }

    return (obj) => {
      let buttons = []
      //logger.info("TagList:getTailButtons", obj, this.props, this.state);
      if (!obj || !obj._item) {
        return buttons
      }

      buttons.push({
        label: getTailButtonLabel('View'),
        onClick: (obj, index) => this.props.onViewDocument(obj, index)
      })

      if (!nonCSCompare(obj.type, 'Link')) {
        buttons.push({
          label: getTailButtonLabel('Download'),
          onClick: (obj, index) => this.props.onDownloadDocument(obj, index)
        })
      }

      buttons.push({
        label: getTailButtonLabel('Edit'),
        onClick: (obj, index) => this.onEditItem(obj, index)
      })
      buttons.push({
        label: getTailButtonLabel('Delete'),
        onClick: (obj, index) => this.onDeleteItem(obj, index)
      })

      return buttons
    }
  }
  /**
   * Define rendering of bottom buttons
   * @param {number} editState - edit state on dialog
   * @return - array of rendered buttons
   */
  getBottomButtons(editState) {
    const canAdd = editState > editableState.EDITABLE
    let type = this.props.type

    return canAdd
      ? [
          {
            label: '+ Add ' + type,
            onClick: () => this.onAddItem()
          },
          {
            label: '+ Paste (Ctrl V) ',
            onClick: (e) => this.onPasteItem(e)
          },
          {
            label: '+ Add files',
            onClick: (e) => this.onAddFiles(e)
          }
        ]
      : []
  }

  /**
   * Return sorted items list to render in table
   * @param {object} items - items to render
   */
  sortItems = (items) => {
    //logger.info("DocList:sortItems", items);
    return items.sort(orderSort)
  }

  /**
   * Render additional content
   */
  renderContent = (editState) => {
    return (
      <div>
        {editState > editableState.EDITABLE ? (
          <div>
            <DropZone ref="dropzone" className="ContentLoader__DropZone" onDrop={this.dropFile.bind(this)}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div {...getRootProps()} className={`dropzone${isDragActive ? ' dropzone--isActive' : ''}`}>
                  <input {...getInputProps()} />
                  {isDragActive ? <p>...</p> : <p></p>}
                </div>
              )}
            </DropZone>
          </div>
        ) : null}
      </div>
    )
  }

  /**
   * Render dialog for item entry
   */
  renderItemDialog(editState, item, base, isEditable) {
    //logger.info("DocList:renderItemDialog", editState, item, base, isEditable, this.props, this.state);

    return (
      <div>
        <DocDialog
          appState={this.props.appState}
          actions={this.props.actions}
          level={0.3}
          isVisible
          isEditable={isEditable}
          doc={item}
          base={null}
          majorObject={this.props.majorObject}
          parentDialog={this.props.parentDialog}
          content={this.props.content}
          contentUri={this.props.contentUri}
          onContentChange={this.props.onContentChange}
          onClose={this.closeItem}
          onSave={(obj, closeDialog, onSent) => this.saveItem(obj, base, closeDialog, onSent)}
        />
      </div>
    )
  }
}

DocList.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isEditable: PropTypes.number, // State of editing in dialog
  majorObject: PropTypes.object.isRequired, // Major object we work against
  docsObject: PropTypes.object, // Documents dialog
  type: PropTypes.string.isRequired, // Type of object
  items: PropTypes.object.isRequired, // Resource object array
  base: PropTypes.object, // Resource object array
  content: PropTypes.array, // List of content to upload.
  contentUri: PropTypes.string, // URI for content
  onContentChange: PropTypes.func // Uploaded content change for dialog
}
