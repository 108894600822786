/**
 * Created by mailf on 11.10.2016.
 */
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { API, getApiResource } from '../../helpers/api'
import { getUTCTime, nonCSCompare } from '../../helpers/index'
import { getJsonSpecFields, getJsonSpecRecords } from '../../helpers/md'
import '../ActionWindow/ActionWindow.scss'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { Loader } from '../Loader/Loader'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import './InviteDialog.scss'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const messageActions = getJsonSpecRecords('messageAction')
const messageActionFields = getJsonSpecFields('messageAction')

export class InviteDialog extends Component {
  constructor(props) {
    super(props)

    let messageAction = this.getMessageAction(this.props.action)

    this.state = {
      messageSent: false,
      messageAction: messageAction,
      messageText: '',
      errorText: '',

      user: '',
      role: 'Analyst'
    }
  }

  /**
   * Get current action for execution
   * @param action to find
   * @return Action array of values
   */
  getMessageAction(action) {
    //console.log("InviteDialog:getMessageAction", action, messageActions);

    let messageActionIndex = messageActions.findIndex((item) => nonCSCompare(action, item.values[0]))
    if (messageActionIndex > -1) {
      return messageActions[messageActionIndex]
    }

    return {
      index: -1,
      values: []
    }
  }

  /**
   * Get current action value
   * @param name of field we need from current action
   * @param action
   * @return value
   */
  getMessageActionValue(name, action = this.state.messageAction) {
    const nameIndex = messageActionFields.findIndex((field) => nonCSCompare(field.identity.name, name))
    //console.log("InviteDialog:getMessageActionValue", name, nameIndex);

    return nameIndex > -1 && action.values ? action.values[nameIndex] : ''
  }

  /**
   * Get subject for invitation message
   * @return string
   */
  getMessageSubject() {
    let userState = this.props.user
    let userName =
      userState.profile.firstname || userState.profile.lastname
        ? userState.profile.firstname + ' ' + userState.profile.lastname
        : userState.profile.alias
    return (
      'Invitation to ' +
      this.state.user +
      ' from ' +
      userName +
      ' to join organization ' +
      this.props.organization.identity.name +
      ' as ' +
      this.state.role
    )
  }

  onClose() {
    //console.log("InviteDialog:onClose");
    this.props.close()
  }

  onSent(error) {
    //console.log("InviteDialog:onSent", error, this.state.messageSent);

    this.setState({
      messageSent: false,
      errorText: error && error.response ? JSON.parse(error.response).error.message : ''
    })

    if (!error) {
      this.props.close()
    }
  }

  changeUser = (event) => {
    //console.log("InviteDialog:changeUser", event);
    this.setState({
      user: event.target.value,
      errorText: ''
    })
  }

  changeRole = (event) => {
    //console.log("InviteDialog:changeRole", event);
    this.setState({
      role: event.target.value,
      errorText: ''
    })
  }

  changeMessage = (event) => {
    //console.log("InviteDialog:changeMessage", event);
    this.setState({
      messageText: event.target.value,
      errorText: ''
    })
  }

  onInvite = () => {
    let inputError = ''
    if (!this.state.user || this.state.user.length === 0) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify e-mail address for user you want to invite!'
    }
    if (!this.state.role || this.state.role.length === 0) {
      inputError +=
        (inputError.length > 0 ? '\n' : '') + 'Please specify role you want assign to user to play in organization!'
    }
    if (!this.state.messageText || this.state.messageText.length === 0) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please type message to user you want him to see on invite!'
    }
    this.setState({ errorText: inputError })
    if (inputError.length > 0) {
      return
    }

    //console.log("InviteDialog:onJoin", this.state.messageText);
    this.setState({ messageSent: true })

    const organization = this.props.organization.identity.name
    const orgMessageRequest = API[getApiResource('organization')](organization)[getApiResource('message')]

    orgMessageRequest
      .post({
        class: 'Message',
        type: 'AccessApproval',
        issue: {
          status: 'Opened',
          identity: {
            description: this.getMessageSubject()
          },
          attached: {
            name: '/organizations/' + organization
          },
          notes:
            this.getMessageActionValue('Info') + '\nUser: ' + this.state.user.trim() + '       Role: ' + this.state.role
        },
        attached: {
          name: '/organizations/' + organization
        },
        sender: {
          id: this.props.user.identity.id,
          description: this.props.user.profile.alias
        },
        text: this.state.messageText,
        time: moment(getUTCTime()).format('YYYY-MM-DDTHH:mm:ss'),
        action: this.props.action,
        properties: [
          {
            identity: { name: 'role' },
            type: 'Enum',
            reference: '/Role',
            value: this.state.role
          },
          {
            identity: { name: 'user' },
            type: 'String',
            value: this.state.user.trim()
          }
        ]
      })
      .then(
        () => {
          this.props.onSuccess()
          this.onSent()
        },
        (err) => {
          this.props.actions.setError(null)
          this.onSent(err)
        }
      )
  }

  renderFields = () => {
    let subject = this.getMessageSubject()
    //let options = getPropertyOptions('role');
    let options = this.props.roles.map((role) => {
      return {
        label: role.Role,
        value: role.Role,
        id: role.Code,
        description: role.Description
      }
    })

    return (
      <div>
        <div className="row InviteDialog__subjectRow">
          <div className="col-xs-3">Subject:</div>
          <div className="col-xs-9">
            <div className="InviteDialog__subjectValue">{subject}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3">User:</div>
          <div className="col-xs-9">
            <EditableEntity
              data={this.state.user}
              dataType={{ name: 'string' }}
              dataProps={{
                onChange: this.changeUser,
                placeholder: 'Type user e-mail'
              }}
              isEditable
              inEditMode
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-3">Role:</div>
          <div className="col-xs-9">
            <FormControl variant="outlined" fullWidth={true}>
              <Select
                value={this.state.role}
                onChange={this.changeRole}
                autoWidth={true}
                className="InviteDialog__select"
              >
                {options.map((item, idx) => (
                  <MenuItem value={item.value} key={`${item.label}__${idx}`}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    )
  }

  renderFooter = (caption, button, disabled) => {
    return (
      <div>
        <div>
          {this.state.messageSent ? (
            <div>
              <div className="ActionWindow__request">{'Your invitation send to user ' + this.state.user + '!'}</div>
              <Loader />
            </div>
          ) : (
            <div className="ActionWindow__error">{this.state.errorText}</div>
          )}
          <button className="btn_big ActionWindow__button" onClick={this.onClose.bind(this)}>
            Close
          </button>
          <button className={'btn_big' + disabled + ' ActionWindow__button'} onClick={this.onInvite.bind(this)}>
            {this.props.buttons ? this.props.buttons.send : button}
          </button>
        </div>
      </div>
    )
  }

  render() {
    let caption = this.getMessageActionValue('Caption')
    let button = caption.split(' ')[0]
    let info = this.getMessageActionValue('Info')
    let disabled = this.state.messageSent ? ' disabled' : ''

    // console.log("InviteDialog:render", this.props.organization, this.props.user, this.state, messageActions);
    return (
      <ModalWindow
        defaultHeight={400}
        isOpen
        header={caption}
        footer={this.renderFooter(caption, button, disabled)}
        onClose={this.onClose.bind(this)}
        className="OrganizationList__modal InviteDialog"
        upper={<div></div>}
      >
        <div>
          {this.renderFields()}
          <div className="InviteDialog__label">{info}</div>
          <div className="InviteDialog__text">
            <textarea
              className="TextArea"
              name="text"
              defaultValue={this.state.messageText}
              placeholder="Type invitation message"
              onChange={this.changeMessage.bind(this)}
              onKeyUp={(event) => event.stopPropagation()}
              onKeyPress={(event) => event.stopPropagation()}
              onKeyDown={(event) => event.stopPropagation()}
            />
          </div>
        </div>
      </ModalWindow>
    )
  }
}

InviteDialog.propTypes = {
  action: PropTypes.string, // RequestAccess
  organization: PropTypes.object, // organization of sender
  user: PropTypes.object, // sender
  onSuccess: PropTypes.func,
  close: PropTypes.func,
  actions: PropTypes.object
}

InviteDialog.defaultProps = {}
