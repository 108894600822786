/**
 * Created by kascode on 11.04.2019.
 */
import React from 'react'

import { API, getObjectsWithConnected, idAPI, sendObjectNew } from '../../helpers/api'
import { getObjectByName } from '../../helpers/data'
import { columnsToType, editableState, getFullUri, nonCSCompare, orderSort, pluralTypeForms } from '../../helpers/index'
import FilteredTabbedTable from '../FilteredTabbedTable/FilteredTabbedTable'
import { MajorObjectVersioned } from '../MajorObjectVersioned/MajorObjectVersioned'
import { Messages } from '../Messages/Messages'
import { ObjectHistoryTable } from '../ObjectHistoryTable/ObjectHistoryTable'
import { InstanceDialog } from './InstanceDialog'
import { TopologyChart } from './TopologyChart'
import { getTailButtonLabel } from '../../helpers/helperComponents'
import { Tab } from '../FilteredTabbedTable/TabType'

export class Topology extends MajorObjectVersioned {
  constructor(props) {
    super(props)

    // @ts-ignore
    this.state = {
      editingChild: null,
      editingChildMode: editableState.EDITING,
      editingChildType: ''
    }

    // @ts-ignore
    this.state.objectType = 'topology'
    // @ts-ignore
    this.state.objectName = this.getObjectName(this.state.objectType)
    // @ts-ignore
    this.state.showChart = false
  }

  UNSAFE_componentWillReceiveProps(props) {
    const objectType = this.getObjectType(props)
    if (!this.props.userState.profile && props.userState.profile) {
      this.loadUserRole(props)
    }
    if (objectType === this.state.objectType) return
    this.clearTabsContentLoaded(['instances']) // show Loader for object table
    getObjectsWithConnected(
      this.getDataRequestQuery(props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  componentDidMount() {
    // const sys = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName);
    const sys = this.getObject()

    if (sys && sys.identity.id) {
      this.props.actions.updateMajorObject(sys.identity.id, pluralTypeForms.get(this.state.objectType), {
        isFetching: true
      })
    }

    // this.clearTabsContentLoaded(['instances']);          // show Loader for object table
    getObjectsWithConnected(
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  getApiRequest = (props = this.props) => {
    const { params } = props.match
    const { organizationName, systemName, topologyName } = params

    return API.organizations(organizationName).systems(systemName).topologies(topologyName)
  }

  getDataRequestQuery(props, version?) {
    const { params } = props.match
    const { versionName } = params

    const topologyApiEndpoint = version ? this.getApiRequest(props).versions() : this.getApiRequest(props)

    const connectedObjectsEndpoint = version ? this.getApiRequest(props) : topologyApiEndpoint

    return {
      endpoint: this.getApiRequest(props),
      objectRequest: versionName ? topologyApiEndpoint.versions(versionName) : topologyApiEndpoint,
      objectRequestCallback: (obj, errorStatus) => {
        if (obj.object && obj.object.parent) {
          localStorage.setItem('currentOrganization', obj.object.parent.id)
        }

        this.setState({ errorStatus: errorStatus })
        return true
      },
      connectedObjectsRequests: [
        {
          objectType: 'version',
          isObjectProperty: true,
          majorObjectType: 'topology',
          propertyName: 'versions',
          request: connectedObjectsEndpoint.versions(),
          connectedObjectsRequestCallback: () => {
            if (this.state.loadingCheckpoints) {
              this.requestCheckpoints().then(() => {
                this.setState({ loadingCheckpoints: false })
              })
            }
          }
        },
        {
          objectType: 'user',
          majorObjectType: 'user',
          isObjectProperty: true,
          propertyName: 'users',
          request: connectedObjectsEndpoint.users(),
          connectedObjectsRequestCallback: this.checkUserRole(props)
        }
        /*
        {
          'objectType': 'issue',
          clearList: true,
          request: API.organizations(props.match.params.organizationName).systems(props.match.params.systemName).issues()

        },
        */
      ]
    }
  }

  getTabsProps = (): Tab[] => {
    const topology = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable = this.checkEditable()

    return [
      {
        title: 'instances',
        filters: [
          { name: '', width: 65 },
          { name: 'search', width: 300 },
          { name: '', width: 225 },
          { name: 'platform', width: 120 }
        ],
        columns: [
          {
            displayName: 'Ord.',
            name: 'order',
            type: columnsToType.getType('id'),
            frozen: true,
            width: 42,
            hiddenInChildTable: true
          },
          {
            name: 'name',
            displayName: 'Instance Name',
            type: columnsToType.getType('minorObjectName'),
            width: 226,
            onCellClick: (value) => {
              this.setState({
                editingChild: true,
                childObjectType: 'instance',
                activeMinorObject: this.getInstance(value.identity.name),
                activeMinorObjectEditable: this.checkEditable(),
                childInEditMode: false
              })
            }
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 500
          },
          {
            name: 'exported',
            displayName: 'Exp.',
            type: columnsToType.getType('required'),
            frozen: true,
            width: 40
          },
          {
            name: 'platform',
            type: columnsToType.getType('platform'),
            width: 100
          },
          { name: 'units', type: columnsToType.getType('string'), width: 280 }
        ],
        tailButtons: !isEditable
          ? []
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (obj) => {
                  console.log('Topology:getTabsProps:EDIT', obj)
                  this.setState({
                    editingChild: true,
                    childObjectType: 'instance',
                    activeMinorObject: this.getInstance(obj.identity.name),
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: this.checkEditable()
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (obj) => {
                  //this.deleteElement(obj.identity.id)
                  console.log('Delete', obj)
                  this.showDeleteInstance(obj.identity.id, obj.identity.name)
                }
              }
            ],
        contextMenuButtons: [
          {
            label: 'Copy instance',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              // console.log("Dataset::Copy field", data);
              const instance = this.getInstance(data.identity.name)
              localStorage.clipboard = JSON.stringify(instance)
              localStorage.clipboardType = 'instance'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste instance',
                  data: { action: 'paste' },
                  onClick: () => {
                    if (!localStorage.clipboard) {
                      alert('No instance copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'instance') {
                      alert('No instance copied')
                      return
                    }

                    this.setState({
                      pastingChild: true,
                      pastingChildName: JSON.parse(localStorage.clipboard).identity.name
                    })
                  },
                  //@ts-ignore
                  showInBottom: true
                },
                {
                  label: 'Reorder instances',
                  data: { action: 'reorder' },
                  onClick: () => {
                    const topology = this.getObject()
                    let newTopology = Object.assign({}, topology, {
                      instances: topology.instances.sort(orderSort).map((inst, index) => {
                        return Object.assign({}, inst, { order: index + 1 })
                      })
                    })
                    this.sendTopology(newTopology)
                  }
                },
                {
                  label: 'Reorder by 10 step',
                  data: { action: 'reorder' },
                  onClick: () => {
                    const topology = this.getObject()

                    let delta = 1
                    let newTopology = Object.assign({}, topology, {
                      instances: topology.instances.sort(orderSort).map((inst, index) => {
                        return Object.assign({}, inst, {
                          order: index === 0 ? delta : (delta += 10)
                        })
                      })
                    })
                    this.sendTopology(newTopology)
                  }
                }
              ]
        ),
        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () =>
                  this.setState({
                    editingChild: true,
                    activeMinorObject: this.createInstance(),
                    childObjectType: 'instance',
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: true
                  })
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add instance',
                onClick: () =>
                  this.setState({
                    editingChild: true,
                    activeMinorObject: this.createInstance(),
                    childObjectType: 'instance',
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: true
                  })
              }
            ]
      },
      {
        title: 'history',
        filters: [],
        columns: [],
        tableComponent: ObjectHistoryTable,
        parentObject: this.getObject()
      }
    ]
  }

  getData = () => {
    const topology = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const id = topology.identity.id
    let getObjs

    return this.getTabsProps().map((block) => {
      switch (block.title) {
        case 'instances':
          block.data = topology.instances
            ? topology.instances.map((inst) => ({
                ...inst,
                name: {
                  name: inst.identity.name,
                  objectType: 'instance',
                  path: inst.path
                },
                description: inst.identity.description,
                platform: inst.platform,
                exported: nonCSCompare(inst.type, 'Exported')
              }))
            : []
          block.data.sort(orderSort)
          //console.log("Topology:getData:INSTANCES", block);
          break
        case 'history':
          block.data = [
            {
              versions: topology.versions,
              checkpoints: topology.checkpoints,
              loadingCheckpoints: this.state.loadingCheckpoints,
              restore: this.restoreFromCheckpoint
            }
          ]
          break
        default:
          getObjs = () => []
      }

      return block
    })
  }

  /**
   * Get instance by name from topology
   * @param {string} instanceName - name of the instance we looking for
   * @return {object} instance
   */
  getInstance(instanceName) {
    const topology = this.getObject()

    return topology.instances.find((f) => nonCSCompare(f.identity.name, instanceName))
  }

  /**
   * Create new instance for editing
   * @return {object} empty element
   */
  createInstance = () => {
    const topology = this.getObject()
    let order = 0
    if (!order) {
      if (topology.instances) {
        topology.instances.map((f) => {
          order = order < f.order ? f.order : order
        })
      }
      order += 1
    }
    return {
      identity: { name: '' },
      order: order,
      type: 'Defined',
      configuration: {}
    }
  }

  /**
   * Delete instance
   * @param {string} id - id of the instance we looking for
   * @param {string} name - name of the instance we looking for
   * @return {object} empty element
   */
  deleteInstance = (id, name = '') => {
    const newTopology = Object.assign({}, this.getObject())

    const oldInstances = this.getObject().instances
    if (id) {
      newTopology.instances = oldInstances.filter((inst) => inst.identity.id !== id)
    } else if (name) {
      newTopology.instances = oldInstances.filter((inst) => !nonCSCompare(inst.identity.name, name))
    }
    console.log('Topology:deleteInstance', id, name, oldInstances, newTopology.instances)

    this.sendTopology(newTopology, null, null)
  }

  /**
   * Show instance delete dialog
   * @param {string} id - id of the instance we looking for
   * @param {string} elementName - name of the instance we looking for
   * @return {object} instance
   */
  showDeleteInstance = (id, elementName) => {
    this.setState({
      deleteChildObject: {
        objectType: 'instance',
        obj: { identity: { name: elementName } },
        delete: () => {
          this.setState({ deleteChildObject: false })
          this.deleteInstance(id, elementName)
        }
      }
    })
  }

  pasteChild = (params, closeDialog, onSent) => {
    const ds = this.getObject()

    let newObject = JSON.parse(localStorage.clipboard)
    newObject.identity.name = params.objectName // this.state.pastingChildName;

    if (localStorage.clipboardType === 'instance') {
      const newTopology = Object.assign({}, this.getObject())
      newTopology.instances.push(newObject)
      this.sendTopology(Object.assign({}, newTopology), closeDialog, onSent)
    }
  }

  /**
   * Close instance  dialog
   * @return
   */
  closeEditDialog = () => {
    this.setState(
      {
        editingChild: false,
        childObjectType: '',
        activeMinorObject: null
      },
      () => {}
    )
  }

  /**
   * Save instance in topology
   * @param {object} instance - instance to save
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   * @return
   */
  saveInstance = (instance, closeDialog, onSent) => {
    const topology = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    console.log('Topology:saveInstance', topology, instance, this.state)

    if (this.state.activeMinorObject && topology.instances) {
      // Update existend instance
      const newInstances = topology.instances
        .filter((inst) => !nonCSCompare(inst.identity.name, instance.identity.name))
        .concat(instance)
      const newTopology = { ...topology, instances: newInstances }

      this.sendTopology(newTopology, closeDialog, onSent)
    } else {
      // Create new instance.
      const newTopology = Object.assign({}, { identity: this.getObject().identity, instances: [instance] })

      this.saveTopology(newTopology, closeDialog, onSent)
    }
  }

  /**
   * Save Topology update using patch action
   * @param {object} [newTopology] - new Topology with changes only
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   * @return
   */
  saveTopology = (newTopology, closeDialog, onSent) => {
    console.log('Topology:saveTopology', newTopology)

    return sendObjectNew(
      idAPI.topologies(newTopology.identity.id),
      'patch',
      this.props.actions,
      this.getObject(),
      newTopology
    ).then(
      (result) => {
        if (onSent) onSent(closeDialog)
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  /**
   * Send new state of Topology by using put action
   * @param {object} [newTopology] - new Topology with all data
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {function} [onSent] - call back function to report status of update
   * @return
   */
  sendTopology = (newTopology, closeDialog?, onSent?) => {
    console.log('Topology:sendTopology', newTopology)

    return sendObjectNew(idAPI.topologies(newTopology.identity.id), 'put', this.props.actions, newTopology).then(
      (result) => {
        if (onSent) onSent(closeDialog)
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  restoreFromCheckpoint = (data) => {
    this.sendTopology(
      Object.assign({}, this.getObject(), data, {
        checkpoints: [],
        versions: []
      }),
      null,
      (tmp, error) => {
        if (!error) alert('Topology was restored!')
        else alert('Error')
      }
    )
  }

  /**
   * render Chart view button and Chart view window
   * @returns {XML[]}
   */
  renderRightMenu() {
    const topology = this.getObject()
    return (
      <React.Fragment>
        {this.renderShowJson()}
        <br />
        {this.renderDownloadPDF()}
        <br />
        <span>
          {topology && topology.object ? (
            <div className="MajorObjectView__showChart">
              <span>
                <a className="" onClick={() => this.setState({ showChart: true })}>
                  &nbsp;Chart&nbsp;
                </a>
              </span>
            </div>
          ) : null}
        </span>
      </React.Fragment>
    )
  }

  renderChart() {
    const topology = this.getObject()
    return <TopologyChart topology={topology} onClose={() => this.setState({ showChart: false })} />
  }

  render() {
    const topology = this.getObject()

    if (!topology || topology.isFetching) {
      return this.renderLoading()
    } else {
      return (
        <div className="MajorObject__outer">
          <section className={'MajorObjectView MajorObjectView_' + this.state.objectType}>
            {this.renderHeader()}
            {this.renderInfo()}

            <div className="MajorObjectView__connections">
              <div className="row">
                <div className="col-xs-12">
                  {this.renderBackArrowTabs()}
                  <FilteredTabbedTable
                    /*
                    // @ts-ignore */
                    tablesData={this.getData()}
                    theme={this.state.objectType}
                    tabsLoaded={this.state.tabsLoaded}
                    parentUri={getFullUri(topology)}
                  />
                  {this.renderFinalizedMessage()}
                </div>
              </div>
            </div>

            {this.state.editingChild && this.state.childObjectType === 'instance' ? (
              <InstanceDialog
                appState={this.props.appState}
                actions={this.props.actions}
                isEditable={this.state.childInEditMode ? 2 : this.checkEditable() ? 1 : 0}
                isVisible={this.state.editingChild}
                onSave={this.saveInstance}
                onClose={this.closeEditDialog}
                instance={this.state.activeMinorObject}
                majorObject={topology}
              />
            ) : null}

            {this.state.showChart ? this.renderChart() : null}
          </section>
          {topology && topology.identity && topology.identity.id ? (
            <Messages
              appState={this.props.appState}
              userState={this.props.userState}
              actions={this.props.actions}
              object={topology}
              objectType={this.state.objectType}
              objectReload={this.reload.bind(this)}
              objectChain={this.props.match.params}
              currentUserRole={this.state.currentUserRole}
            />
          ) : null}
        </div>
      )
    }
  }
}
