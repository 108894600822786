import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactPhoneInput from 'react-phone-input-2'
import { Tabs, TabPanel } from '../ReactSimpletabs'
import 'whatwg-fetch'
import { getUserPhotoUrl } from '../../helpers'
import { API, idAPI } from '../../helpers/api'
import { fetchDataUrlImmediate } from '../../helpers/api'
import { poolData } from '../../helpers/auth-cognito'
import { checkResourceUrl, editableState, itemPosition } from '../../helpers/index'
import '../../styles/Profile/Profile.scss'
import { ContentLoader } from '../DataDialog/ContentLoader'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import { TabbedModalWindow } from '../TabbedModalWindow/TabbedModalWindow'

import iI from '../../resources/images/i-grey-2x.png'
import iVisualisation from '../../resources/images/Visualisation-2x.png'
import iNotification from '../../resources/images/Notification-2x.png'
import iMarketing from '../../resources/images/Marketing-2x.png'
import iInterface from '../../resources/images/Interface-2x.png'
import defaultUserPicture from '../../resources/images/face-2x.png'
import { ExperimentalDesign } from './ExperimentalDesign.tsx'

const identityFields = []
const rootFields = ['employer']
const profileFields = ['alias', 'phone', 'email', 'firstname', 'middlename', 'lastname', 'position']
const addressFields = ['city', 'street', 'zipcode', 'state', 'country']
const addressFieldsName = []

const startingState = {
  inEditMode: true,
  isEdited: false,
  passwordsNotMatched: false,
  activeField: null,
  saved: false,
  profile: {},
  password: {
    old: '',
    new: '',
    new2: ''
  },
  activeTab: 'profile',
  editingPassword: false
}

export class Profile extends Component {
  constructor(props) {
    super()
    this.state = startingState
    this.state.picture = {
      fileURI: props.userState.profile
        ? props.userState.profile.picture
        : 'users/' + localStorage.currentUserId + '/photo',
      fileName: 'users/' + localStorage.currentUserId + '/photo',
      type: 'Image'
    }
    this.state.id = localStorage.currentUserId
    //this.state.id = '73e97190-a323-4781-90db-df92abb4610c';// props.userState.id;

    this.editordialogRef = React.createRef()
    this.tabbedRef = React.createRef()
  }

  componentDidMount() {
    if (!localStorage.currentUserId) return
    // //console.log("profile mount");
    this.updateUserInformation()
  }

  updateUserInformation() {
    idAPI
      .users(localStorage.currentUserId)
      .get()
      .then((user) => {
        // //console.log("profile mount get", user);
        this.props.actions.updateUserState(user)
      })

    if (sessionStorage['temp_password']) {
      //this.setState({activeTab: 'password'});
      //this.tabbedRef.current.selectTab(2);
      this.setState(
        {
          editingPassword: true,
          password: {
            old: sessionStorage['temp_password'],
            new: '',
            new2: ''
          }
        },
        () => {
          sessionStorage['temp_password'] = ''
        }
      )
    }
  }

  passwordStrengthMessage(newPassword) {
    newPassword = newPassword || this.state.password.new

    if (this.state.password && newPassword) {
      let ret = []
      if (newPassword.length < 8) {
        ret.push('Password must be at least 8 characters long')
        ret.push(<br />)
      }
      if (!newPassword.match(/[A-Z]/)) {
        ret.push('Password must include at least one capital letter')
        ret.push(<br />)
      }
      if (!newPassword.match(/[a-z]/)) {
        ret.push('Password must include at least one lowercase letter')
        ret.push(<br />)
      }
      if (!newPassword.match(/[0-9]/)) {
        ret.push('Password must include at least one digit')
        ret.push(<br />)
      }
      /* eslint-disable */
      if (!newPassword.match(/[-!@#$%^&*()_+={[\]:};<,>.\/\\|'"?]/)) {
        ret.push('Password must include at least one special character')
        ret.push(<br />)
      }
      /* eslint-enable*/
      //console.log("passwordStrengthMessage", newPassword, ret);
      return ret
    } else {
      //console.log("passwordStrengthMessage", newPassword, "(empty)");
      return ''
    }
  }

  close() {
    this.setState(
      {
        saved: false,
        inEditMode: false,
        isEdited: false
      },
      this.props.onClose
    )
  }

  isChanged() {
    let ret = false
    rootFields.forEach((field) => {
      if (field in this.state.profile) {
        ret = true
      }
    })
    identityFields.forEach((field) => {
      if (field in this.state.profile) {
        ret = true
      }
    })
    addressFields.forEach((field) => {
      if (field in this.state.profile) {
        ret = true
      }
    })
    addressFieldsName.forEach((field) => {
      if (field in this.state.profile) {
        ret = true
      }
    })
    profileFields.forEach((field) => {
      if (field in this.state.profile) {
        ret = true
      }
    })
    if (this.state.picture && this.state.picture.fileData) {
      ret = true
    }

    console.log('Profile:isChanged', ret, this.state.profile)

    return ret
  }

  isPasswordChanged() {
    let ret = false
    if (this.state.password.new) {
      ret = true
    }

    return ret
  }

  passwordError = (error) => {
    //console.log("API.error: ",error);
    this.setState({ passwordError: JSON.parse(error.response).error.message })
  }

  saveLocalProfile() {
    const userName = this.props.localUser.profile.identity.name
    //console.log("save userName=", userName);
    API.organizations(this.props.organization.identity.name)
      .profiles(userName)
      .patch(Object.assign({}, this.state.profile))
      .then(() => {
        //this.setState({isOpen: false});
        this.props.actions.updateUsers(
          this.props.organization.identity.id,
          'organizations',
          this.props.organization.users.map((u) =>
            u.profile.identity.id === this.props.localUser.profile.identity.id
              ? Object.assign({}, u, {
                  profile: Object.assign({}, u.profile, this.state.profile)
                })
              : u
          )
        )
        this.props.onClose()
      })
  }

  updateUserState(close) {
    //console.log("update user state", this.state);

    if (this.state.password.new) {
      if (this.checkNewPassword()) return false
    }

    let pictureChanged = false
    let changes = {
      identity: {
        //id: this.state.id
      },
      address: {},
      profile: {}
    }
    let clear = {
      identity: {},
      address: {},
      profile: {}
    }

    rootFields.forEach((field) => {
      if (field in this.state.profile) changes[field] = this.state.profile[field]
      else changes[field] = this.props.userState[field]
    })
    identityFields.forEach((field) => {
      if (field in this.state.profile) changes.identity[field] = this.state.profile[field]
      else changes.identity[field] = this.props.userState.identity ? this.props.userState.identity[field] : ''
    })
    addressFields.forEach((field) => {
      if (field in this.state.profile) changes.address[field] = this.state.profile[field]
    })
    addressFieldsName.forEach((field) => {
      if (field in this.state.profile) changes.address[field] = this.state.profile[field]
    })
    profileFields.forEach((field) => {
      if (field in this.state.profile) changes.profile[field] = this.state.profile[field]
      else changes.profile[field] = this.props.userState.profile ? this.props.userState.profile[field] : ''
    })

    //console.log("updateUserState state=", deepCopy(this.state));

    this.setState({ passwordError: '' })

    let promises = []
    if (this.state.password.new) {
      //console.log("Save password", deepCopy(this.state.password));
      changes.password = this.state.password
      sessionStorage.isLoggingOut = true
      if (window.AUTH_URL === '/cognitologin') {
        console.log('poolData', poolData)
        const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
        console.log('userPool', userPool)
        const cognitoUser = userPool.getCurrentUser()
        console.log('cognitoUser', cognitoUser)
        cognitoUser.getSession((err, session) => {
          console.log('session validity: ' + session.isValid())
          promises.push(
            new Promise((resolve, reject) => {
              cognitoUser.changePassword(this.state.password.old, this.state.password.new, (err, data) => {
                if (err) {
                  reject(err)
                  console.error(err)
                  this.setState({ passwordError: err.message })
                } else resolve(data)
              })
            })
          )
        })
      } else {
        API.on('error', this.passwordError)
        promises.push(
          API.users(this.props.userState.identity.name).password.post({
            oldpassword: this.state.password.old,
            newpassword: this.state.password.new
          })
        )
      }
      clear.password = { new: '', old: '', new2: '' }
    }
    clear.profile = {}
    changes.profile.alias = this.aliasFix(
      changes.profile.alias ? changes.profile.alias : this.props.userState.profile.alias,
      changes.profile.alias && changes.profile.alias === this.props.userState.profile.email
    )

    if (this.state.picture && this.state.picture.fileData) {
      let form = new FormData()
      form.append('file', this.state.picture.fileData)
      //fetch(getUserPhotoUrl(this.state.id), {method: 'delete', headers: {}}).then(()=>{
      const photoUrl = this.state.picture.fileName
      window.apiCache.deleteObject(photoUrl)

      /*
      promises.push(fetch(photoUrl, {
        method: 'post',
        headers: {},
        body: form
      }));
      */
      console.log('Profile:updateUserState:PICTURE', photoUrl, this.state.picture, this.state, this.props)

      promises.push(
        fetch(checkResourceUrl(API.resources.url() + '?uri=' + photoUrl), {
          method: 'post',
          headers: {},
          body: form
        })
      )

      clear.picture = null
      changes.profile.picture = photoUrl
      pictureChanged = true
    }

    if (!this.state.password.new) promises.push(idAPI.users(this.state.id).put(changes))

    Promise.all(promises).then(
      () => {
        sessionStorage.isLoggingOut = ''
        API.off('error', this.passwordError)
        this.props.actions.updateUserState(changes)
        this.setState(
          Object.assign(clear, {
            saved: true,
            inEditMode: false,
            isEdited: false
          }),
          () => {
            if (this.state.editingPassword) this.setState({ editingPassword: false })
            else if (close) this.close()
          }
        )
        if (pictureChanged) {
          // eslint-disable-next-line no-restricted-globals
          location.reload()
          // todo: do not reload, but refresh all user's photos on the page somehow
        }
      },
      (error) => {
        this.setState({})
      }
    )

    return true
    // this.props.onClose();
  }

  handlePhoneChange(value) {
    let profile = this.state.profile
    profile['phone'] = value.replace(' ', '').replace('-', '')
    profile.isEdited = true
    console.log('handlePhoneChange', value, profile)
    this.setState(profile)
  }

  handleChange(event) {
    //console.log("handleChange");
    /*
     let stateChange = {};
     stateChange[event.target.dataset.fieldname] = event.target.value;
     this.setState(stateChange);
     */
    let profile = this.state.profile
    profile[event.target.dataset.fieldname] = event.target.value
    profile.isEdited = true
    this.setState(profile)
  }

  handleFocus(event) {
    this.setState({ activeField: event.target.dataset.fieldname })
  }

  handleBlur() {
    this.setState({ activeField: null })
  }

  handleCheckbox(event) {
    let stateChange = {}
    stateChange[event.target.dataset.fieldname] = event.target.value ? true : false
    this.setState(stateChange)
  }

  checkNewPassword() {
    if (
      document.getElementById('new_password').value !== document.getElementById('new_password2').value &&
      (document.getElementById('new_password').value !== '' || document.getElementById('new_password2').value !== '')
    ) {
      // this.setState({passwordsNotMatched: true});
      return true
    } else {
      // this.setState({
      //   passwordsNotMatched: false,
      //   isEdited: true
      // });
      return false
    }
  }

  /*
  drop(files) {
    if (files.length > 0)
      this.setState({
        picture: files[0]
      });
  }
  */

  onContentChange(content) {
    if (content && content.fileURI) {
      this.setState({
        picture: content,
        isEdited: true
      })
    }
  }

  validationMessage(field) {
    ////console.log('validationMessage '+field,this.state[field]);
    if (field === this.state.activeField) return null
    if (!this.state.profile[field]) return null
    if (field === 'phone') {
      if (!this.state.profile[field].match(/[+0-9() -]+/i)) {
        return <div className="Profile__error">Please enter correct phone number</div>
      }
    }
    if (field === 'email') {
      if (this.state.profile[field].indexOf('.') === -1 || this.state.profile[field].indexOf('@') === -1) {
        return <div className="Profile__error">Please enter correct email</div>
      }
    }
    return null
  }

  tabChange() {
    this.setState({ saved: false })
  }

  dropZoneClick() {
    this.editordialogRef.current.refs.drop.open()
  }

  editPassword(index) {
    return (event) => {
      const notMatch = this.checkNewPassword()
      const strong = this.passwordStrengthMessage(index === 'new' ? event.target.value : undefined).length === 0

      console.log('editPassword', notMatch, strong)

      this.setState({
        password: Object.assign(this.state.password, {
          [index]: event.target.value
        }),
        isEdited: !notMatch && strong,
        passwordsNotMatched: notMatch
      })
    }
  }

  aliasFix = (alias, isDefaultValue) => {
    if (!isDefaultValue) return alias

    if (!alias) return ''
    if (alias.indexOf('@') === -1) return alias
    return alias.substr(0, alias.indexOf('@'))
  }

  renderTabPanels() {
    return ['profile', 'preferences', 'password'].map((key) => this.renderTabPanel(key))
  }

  renderView() {
    const userState = this.props.userState
    let style = {}
    style.backgroundImage =
      'url(' + (this.state && this.state.picture ? this.state.picture.preview : defaultUserPicture) + ')'
    return (
      <div>
        <section className="ProfileView ProfileView__noTabs">
          <div className="row">
            <div className="col-xs-2">
              <div className="ProfileView__photo" style={style}></div>
            </div>
            <div className="col-xs-5">
              {userState.identity.name}
              <br />
              {userState.midname}
              <br />
              {userState.surname}
            </div>
            <div className="col-xs-5">{userState.alias}</div>
          </div>
          {userState.jobTitle || userState.companyName ? (
            <div className="row">
              <div className="col-xs-6">{userState.jobTitle}</div>
              <div className="col-xs-6">{userState.companyName}</div>
            </div>
          ) : (
            ''
          )}
          {userState.email || userState.city || userState.phoneNumber || userState.address ? (
            <div className="row">
              <div className="col-xs-6">{userState.email}</div>
              <div className="col-xs-6">{userState.city}</div>
              <div className="col-xs-6">{userState.phoneNumber}</div>
              <div className="col-xs-6">{userState.address}</div>
            </div>
          ) : (
            ''
          )}
        </section>
      </div>
    )
  }

  renderInput(className, defaultValue, fieldName, placeholder, editState) {
    //console.log("renderInput ",fieldName,editState,editState >= editableState.EDITING,editState >= editableState.EDITING ? "" : "readonly");
    return (
      <div className="Profile__inputOuter">
        <div className="Profile__inputPlaceholder">
          {defaultValue || this.state.profile[fieldName] ? placeholder : ''}
        </div>
        <input
          type="text"
          className={className}
          defaultValue={defaultValue}
          data-fieldname={fieldName}
          placeholder={placeholder}
          onChange={this.handleChange.bind(this)}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          readOnly={editState >= editableState.EDITING ? '' : 'readonly'}
        />
        {this.validationMessage(fieldName)}
      </div>
    )
  }

  renderEditPassword(editState) {
    let userState = this.props.userState
    if (!userState.identity) return null
    if (this.props.isLocalProfile) userState = this.props.localUser
    return (
      <div>
        <section className="ProfileView ProfileView__changePassword">
          <div className="row">
            <div className="col-xs-12 ProfileView__topText">Change password</div>
            <div className="col-xs-6 col-xs-offset-3">
              <input
                className="Profile__textInput"
                type="password"
                placeholder="Old Password"
                value={this.state.password['old']}
                onChange={this.editPassword('old').bind(this)}
              />
            </div>
            <div className="col-xs-6 col-xs-offset-3">
              <input
                className="Profile__textInput"
                type="password"
                id="new_password"
                placeholder="New Password"
                onBlur={this.checkNewPassword.bind(this)}
                value={this.state.password['new']}
                onChange={this.editPassword('new').bind(this)}
              />
            </div>
            <div className="col-xs-6 col-xs-offset-3 Profile__passwordStrength">{this.passwordStrengthMessage()}</div>
            <div className="col-xs-6 col-xs-offset-3">
              <input
                className="Profile__textInput"
                type="password"
                id="new_password2"
                placeholder="Repeat new Password"
                onBlur={this.checkNewPassword.bind(this)}
                value={this.state.password['new2']}
                onChange={this.editPassword('new2').bind(this)}
              />
            </div>
            {this.state.passwordsNotMatched ? (
              <div className="col-xs-12 error Profile__error">Passwords do not match</div>
            ) : (
              ''
            )}
            <div className="col-xs-12 error Profile__error">{this.state.passwordError}</div>
          </div>
        </section>
      </div>
    )
  }

  isApdaxUser = async () => {
    const currentUserId = localStorage.getItem('currentUserLogin')
    if (currentUserId) {
      const user = await API.organizations(`/apdax/users/${currentUserId}`).get()
      if (user?.name === currentUserId) {
        return true
      }
    }
  }

  renderTabPanel(key, editState) {
    const isApdaxUser = this.isApdaxUser()

    let userState = this.props.userState
    //if (!userState.identity)
    //  return null;

    if (this.props.isLocalProfile) userState = this.props.localUser

    if (!userState.profile)
      userState.profile = {
        identity: {}
      }
    if (!userState.address) userState.address = {}

    // //console.log("userState profile", userState.profile);
    let style = {}
    style.backgroundImage =
      'url(' +
      (this.state && this.state.picture
        ? this.state.picture.preview
        : fetchDataUrlImmediate(getUserPhotoUrl(this.state.id), this.forceUpdate.bind(this))) +
      '), url(' +
      defaultUserPicture +
      ')'
    if (key === 'profile') {
      return (
        <div>
          {this.state.editingPassword ? (
            <EditorDialog
              hideFullscreenButton
              objectType="password"
              modalTitle="Change password"
              isVisible
              isEditable={this.isPasswordChanged() ? editableState.EDITED : editableState.EDITING}
              onClose={() => this.setState({ editingPassword: false })}
              onSave={this.updateUserState.bind(this)}
              editContent={this.renderEditPassword.bind(this)}
            />
          ) : null}

          <TabPanel key={key} title="Profile">
            <section className="ProfileView">
              <div className="row">
                <div className="col-xs-3 ProfileView__dropZoneOuter">
                  {this.props.isLocalProfile ? null : (
                    <ContentLoader
                      ref="content_loader"
                      appState={this.props.userState}
                      actions={this.props.actions}
                      isVisible
                      isEditable={editState}
                      controlPosition={itemPosition.BOTTOM}
                      majorObject={this.props.organization}
                      parentDialog={this.editordialogRef.current}
                      content={this.state.picture}
                      onChange={this.onContentChange.bind(this)}
                    />
                  )}
                  <div className="">
                    {this.renderInput(
                      'Profile__textInput Profile__aliasInput',
                      this.aliasFix(
                        userState.profile ? userState.profile.alias : '',
                        userState.profile && userState.profile.alias === userState.profile.email
                      ),
                      'alias',
                      'Alias',
                      editState
                    )}
                  </div>
                </div>
                <div className="col-xs-6">
                  {this.renderInput(
                    'Profile__textInput',
                    userState.profile.firstname ? userState.profile.firstname : '',
                    'firstname',
                    'First Name',
                    editState
                  )}
                  <br />
                  {this.renderInput(
                    'Profile__textInput',
                    userState.profile.middlename,
                    'middlename',
                    'Middle name',
                    editState
                  )}
                  <br />
                  {this.renderInput(
                    'Profile__textInput',
                    userState.profile.lastname,
                    'lastname',
                    'Last Name',
                    editState
                  )}
                </div>
                <div className="col-xs-3">
                  Name, alias, position and company name are displayed to other users. Contact information is displayed
                  only to developers.
                </div>
              </div>
              <div className="row">
                <div className="col-xs-6">
                  {this.renderInput(
                    'Profile__textInput',
                    userState.profile.position,
                    'position',
                    'Job Title',
                    editState
                  )}
                </div>
                {!this.props.isLocalProfile ? (
                  <div className="col-xs-6">
                    {this.renderInput('Profile__textInput', userState.employer, 'employer', 'Company Name', editState)}
                  </div>
                ) : null}
              </div>
              <div className="row">
                <div className="col-xs-6">
                  {this.renderInput('Profile__textInput', userState.profile.email, 'email', 'Email', editState)}
                </div>
                {!this.props.isLocalProfile ? (
                  <div className="col-xs-6">
                    {this.renderInput('Profile__textInput', userState.address.street, 'street', 'Address', editState)}
                  </div>
                ) : null}
                <div className="col-xs-6">
                  <div className="Profile__inputOuter">
                    <div className="Profile__inputPlaceholder">Phone</div>
                    <ReactPhoneInput
                      className="Profile__textInput"
                      defaultCountry="us"
                      preferredCountries={['us']}
                      value={this.state.phone ? this.state.phone : userState.profile ? userState.profile.phone : ''}
                      data-fieldname="phone"
                      placeholder="Phone Number"
                      onChange={this.handlePhoneChange.bind(this)}
                      onFocus={this.handleFocus.bind(this)}
                      onBlur={this.handleBlur.bind(this)}
                    />
                  </div>

                  {this.validationMessage('phone')}
                </div>
                {!this.props.isLocalProfile ? (
                  <div className="col-xs-6">
                    {this.renderInput('Profile__textInput', userState.address.city, 'city', 'City', editState)}
                  </div>
                ) : null}
                {!this.props.isLocalProfile ? (
                  <div className="col-xs-6">
                    {this.renderInput('Profile__textInput', userState.profile.skype, 'skype', 'Skype', editState)}
                  </div>
                ) : null}
                {!this.props.isLocalProfile ? (
                  <div className="col-xs-3">
                    {this.renderInput('Profile__textInput', userState.address.state, 'state', 'State', editState)}
                  </div>
                ) : null}
                {!this.props.isLocalProfile ? (
                  <div className="col-xs-3">
                    {this.renderInput(
                      'Profile__textInput',
                      userState.address.zipcode,
                      'zipcode',
                      'Zip code',
                      editState
                    )}
                  </div>
                ) : null}
                {!this.props.isLocalProfile ? (
                  <div className="col-xs-6">
                    {this.renderInput(
                      'Profile__textInput',
                      userState.profile.linkedin,
                      'linkedin',
                      'LinkedIn',
                      editState
                    )}
                  </div>
                ) : null}
                {!this.props.isLocalProfile ? (
                  <div className="col-xs-6">
                    {this.renderInput('Profile__textInput', userState.address.country, 'country', 'Country', editState)}
                  </div>
                ) : null}
              </div>

              <div className="row">
                <div className="col-xs-6">
                  Username:{' '}
                  {this.props.userState.profile
                    ? this.props.userState.profile.identity.name
                      ? this.props.userState.profile.identity.name
                      : ''
                    : ''}
                </div>
                <div className="col-xs-6">
                  <button
                    className="btn btn_big Profile__changePasswordButton"
                    onClick={() => this.setState({ editingPassword: true })}
                  >
                    Change password
                  </button>
                </div>
              </div>
              {isApdaxUser && (
                <div className="row">
                  <div className="col-xs-6">
                    <ExperimentalDesign />
                  </div>
                </div>
              )}
            </section>
          </TabPanel>
        </div>
      )
    } else if (key === 'preferences') {
      return (
        <TabPanel key={key} title="Preferences">
          <section className="ProfileView">
            <Tabs className="tabs-list Profile__preferencesTabs-list">
              <TabPanel
                title={
                  <div>
                    <img src={iVisualisation} alt="tab icon" />
                    <br />
                    Visualisation
                  </div>
                }
              ></TabPanel>
              <TabPanel
                title={
                  <div>
                    <img src={iInterface} alt="tab icon" />
                    <br />
                    Interface
                  </div>
                }
              >
                <div className="row Profile__selectView">
                  <div className="col-xs-4">Select view:</div>
                  <div className="col-xs-4">
                    <input
                      type="radio"
                      data-fieldname="view"
                      value="tree"
                      className="Profile__radio"
                      name="view"
                      id="view_tree"
                      onChange={this.handleChange.bind(this)}
                      defaultChecked={userState.view === 'tree'}
                    />
                    <label htmlFor="view_tree">Tree view</label>
                  </div>
                  <div className="col-xs-4">
                    <input
                      type="radio"
                      data-fieldname="view"
                      value="matrix"
                      className="Profile__radio"
                      name="view"
                      id="view_matrix"
                      onChange={this.handleChange.bind(this)}
                      defaultChecked={userState.view === 'matrix'}
                    />{' '}
                    <label htmlFor="view_matrix">Matrix view</label>
                  </div>
                </div>
                <div className="row Profile__selectView">
                  <div className="col-xs-1">
                    <img src={iI} className="Profile__infoIcon" alt="tab icon" />
                  </div>
                  <div className="col-xs-11">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat.
                  </div>
                </div>
              </TabPanel>
              <TabPanel
                title={
                  <div>
                    <img src={iMarketing} alt="tab icon" />
                    <br />
                    Marketing
                  </div>
                }
              >
                <div className="row Profile__selectView">
                  <div className="col-xs-12">
                    <input
                      type="checkbox"
                      data-fieldname="sendMarketing"
                      value="1"
                      className="Profile__check"
                      id="marketing_send"
                      onChange={this.handleCheckbox.bind(this)}
                      defaultChecked={userState.sendMarketing}
                    />
                    <label htmlFor="marketing_send">Send me marketing information</label>
                    <br />
                    <div className="row Profile__selectView">
                      <div className="col-xs-1">
                        <img src={iI} className="Profile__infoIcon" alt="tab icon" />
                      </div>
                      <div className="col-xs-11">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat.
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel
                title={
                  <div>
                    <img src={iNotification} alt="tab icon" />
                    <br />
                    Notifications
                  </div>
                }
              ></TabPanel>
            </Tabs>
          </section>
        </TabPanel>
      )
    } else return <TabPanel key={key} title="Password"></TabPanel>
  }

  render() {
    if (!localStorage.currentUserId) return null

    return (
      <div>
        <EditorDialog
          hideFullscreenButton
          ref={this.editordialogRef}
          objectType="profile"
          modalTitle={
            <div>
              {this.props.userState.profile
                ? this.props.userState.profile.identity.name
                  ? this.props.userState.profile.identity.name
                  : ''
                : ''}
            </div>
          }
          editContent={(editState) => (
            <div>
              {this.renderTabPanel(this.state.activeTab, editState)}
              {this.state.saved ? <div className="Profile__saved">Your changes have been saved.</div> : null}
            </div>
          )}
          isVisible={this.props.isOpen}
          isEditable={this.state.isEdited ? editableState.EDITED : editableState.EDITING}
          onClose={this.close.bind(this)}
          onSave={this.updateUserState.bind(this)}
          onEdit={() => {
            this.setState({ inEditMode: true })
          }}
          onCancel={() => {
            this.setState({ inEditMode: false, isEdited: false, profile: {} })
          }}
        />
      </div>
    )
  }

  renderOld() {
    if (this.props.isLocalProfile) {
      return (
        <ModalWindow
          defaultHeight="400px"
          isOpen={this.props.isOpen}
          onClose={this.close.bind(this)}
          header={
            this.props.userState.profile
              ? this.props.userState.profile.identity.name
                ? this.props.userState.profile.identity.name
                : ''
              : ''
          }
          footer={
            <div className="row">
              <div className="col-xs-6 col-xs-offset-3">
                <button className="ProfileView__button" onClick={this.close.bind(this)}>
                  Close
                </button>
                <button
                  className={'ProfileView__button ' + (this.isChanged() ? '' : 'ProfileView__button__disabled')}
                  onClick={this.saveLocalProfile.bind(this)}
                >
                  Save
                </button>
                {this.state.saved ? <div className="Profile__saved">Your changes have been saved.</div> : null}
              </div>
            </div>
          }
        >
          {this.renderTabPanel('profile')}
        </ModalWindow>
      )
    }

    const tabs = ['profile', 'preferences', 'password']
    return (
      <TabbedModalWindow
        ref={this.tabbedRef}
        header={
          this.props.userState.profile
            ? this.props.userState.profile.identity.name
              ? this.props.userState.profile.identity.name
              : ''
            : ''
        }
        isOpen={this.props.isOpen}
        onClose={this.close.bind(this)}
        defaultHeight="400px"
        content={tabs.map((tab) => this.renderTabPanel(tab))}
        tabs={tabs}
        onTabChange={this.tabChange.bind(this)}
        footer={tabs.map((tab, index) => (
          <div key={index} className="row">
            <div className="col-xs-6 col-xs-offset-3" key={index}>
              <button className="ProfileView__button" onClick={this.close.bind(this)}>
                Close
              </button>
              <button
                className={'ProfileView__button ' + (this.isChanged() ? '' : 'ProfileView__button__disabled')}
                onClick={this.updateUserState.bind(this)}
              >
                Save
              </button>
              {this.state.saved ? <div className="Profile__saved">Your changes have been saved.</div> : null}
            </div>
          </div>
        ))}
      />
    )
  }
}

Profile.propTypes = {
  userState: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  isLocalProfile: PropTypes.bool,
  localUser: PropTypes.object
}
