/**
 * Created by mailf on 28.09.2016.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { Loader } from '../Loader/Loader'
import './BigButton.scss'

export const BigButton = ({ color, status, onClick, text, className }) => {
  let content
  if (status === 'loader') {
    content = (
      <button className={'ButtonBlue ButtonBlue_big ButtonBlue_outline ' + className} disabled>
        <Loader />
      </button>
    )
  } else if (status === 'hidden') {
    content = ''
  } else {
    content = (
      <button className={'ButtonBlue ButtonBlue_big ' + className} onClick={onClick}>
        {text}
      </button>
    )
  }

  return <div className="BigButton">{content}</div>
}

BigButton.propTypes = {
  text: PropTypes.string.isRequired,
  status: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string
}
BigButton.defaultProps = {}
