/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * Created by kascode on 07.04.16.
 */
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { poolData } from '../../helpers/auth-cognito'
import homeIcon from '../../resources/images/home.png'
import settingsIcon from '../../resources/images/settings-2x.png'

export const LoginBlock = ({ width, userName, userSurname, onLogin }) => {
  const loggedIn = typeof userName !== 'undefined'
  //logger.info("LoginBlock:LoginBlock", { width, userName, userSurname, onLogin }, window.location, localStorage);

  // Let's safe original hash and path for login
  if (window.location && window.location.hash) {
    localStorage['currentUserRefreshLocation'] = window.location.pathname
  }

  return (
    <div className={(width ? width + ' ' : '') + 'LoginBlock'}>
      <div className="LoginBlock__username">
        <Link to="/select">
          <img className="Header__home" src={homeIcon} alt="icon" />
        </Link>
        {loggedIn ? <img className="Header__settings" onClick={onLogin} src={settingsIcon} alt="icon" /> : ''}
        &nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
        {loggedIn ? (
          <a
            href="#"
            onClick={() => {
              if (window.AUTH_URL === 'disabled') {
                localStorage['currentUserId'] = ''
                localStorage['currentUserToken'] = ''
                location.href = '/auth'
              } else if (window.AUTH_URL === '/cognitologin') {
                try {
                  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
                  const cognitoUser = userPool.getCurrentUser()
                  cognitoUser.getSession((err, session) => {
                    cognitoUser.signOut()
                    localStorage['currentUserId'] = ''
                    localStorage['currentUserToken'] = ''
                    localStorage['currentUserTokenTime'] = ''
                    localStorage['currentUserRefreshToken'] = ''
                    location.href = '/cognitologin'
                  })
                } catch (ex) {
                  console.error(ex)
                  localStorage['currentUserId'] = ''
                  localStorage['currentUserToken'] = ''
                  localStorage['currentUserTokenTime'] = ''
                  localStorage['currentUserRefreshToken'] = ''
                  location.href = '/cognitologin'
                }
              } else {
                window.releaseToken()
              }
              return false
            }}
          >
            sign out
          </a>
        ) : (
          <Link to="">Login</Link>
        )}
      </div>
    </div>
  )
}

LoginBlock.propTypes = {
  width: PropTypes.string,
  userName: PropTypes.string,
  userSurname: PropTypes.string,
  onLogin: PropTypes.func
}
