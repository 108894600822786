/* eslint-disable react/no-multi-comp */
import PropTypes from 'prop-types'
import React from 'react'
import { ObjectPicture } from '../ObjectPicture/ObjectPicture'
import { ObjectRating } from '../ObjectRating/ObjectRating'
import { SimpleTooltip } from '../SimpleTooltip/SimpleTooltip'
import { MajorObjectLink } from '../MajorObjectLink/MajorObjectLink'
import { objectPictureUrl } from '../../helpers'
import { getFullUri } from '../../helpers/index'
import { EnumTable } from '../NewTable/EnumTable'
import { MultilineText } from '../MultilineText/MultilineText'

import iIcon from '../../resources/images/i-small-2x.png'
import iDatasetOn from '../../resources/images/dataset-on-2x.png'
import iDatasetOff from '../../resources/images/dataset-off-2x.png'
import iInterfaceOn from '../../resources/images/interface-on-2x.png'
import iInterfaceOff from '../../resources/images/interface-off-2x.png'
import iPipeline from '../../resources/images/usage/34/pipeline-2x.png'

import '../../styles/MajorObjectBlock/MajorObjectBlock.scss'

export const MajorObjectBlock = ({
  objectData,
  className,
  type,
  hasLinks,
  clickHandler,
  isMinorObject,
  isGray,
  childCount,
  showDescription,
  showData,
  noMultiLine
}) => {
  const name = objectData.identity ? objectData.identity.name : objectData.name

  const renderMOBIcons = () => {
    let oneIcon
    let content

    if (type !== 'datasets' && type !== 'interfaces' && !hasLinks) return null

    if (type === 'datasets') {
      oneIcon = true
      content = <img src={iDatasetOn} className="MajorObjectBlock__icon" alt="icon" />
    } else if (type === 'interfaces') {
      oneIcon = true
      content = <img src={iInterfaceOn} className="MajorObjectBlock__icon" alt="icon" />
    } else if (type === 'pipelines') {
      oneIcon = true
      content = <img src={iPipeline} className="MajorObjectBlock__icon" alt="icon" />
    } else if (type === 'views') {
      oneIcon = true
      content = ''
    } else {
      oneIcon = false
      content = (
        <React.Fragment>
          <img
            src={objectData.datasets && objectData.datasets.length > 0 ? iDatasetOn : iDatasetOff}
            className="MajorObjectBlock__icon"
            alt="icon"
          />
          <img
            src={objectData.interfaces && objectData.interfaces.length > 0 ? iInterfaceOn : iInterfaceOff}
            className="MajorObjectBlock__icon"
            alt="icon"
          />
        </React.Fragment>
      )
    }

    return (
      <div
        className={
          'MajorObjectBlock__linked ' + className + '__linked' + (oneIcon ? ' MajorObjectBlock__linked_one' : '')
        }
      >
        {content}
      </div>
    )
  }

  const usage = objectData && objectData.usage ? objectData.usage : objectData.object ? objectData.object.usage : ''

  let description = objectData.identity ? objectData.identity.description : objectData.description

  if (showData && objectData.structure) {
    //console.log("MajorObjectBlock::showData", objectData);
    description = (
      <React.Fragment>
        {description}
        <span>
          <br />
          <EnumTable dataset={objectData} maxLineCount="3" colWidthsAuto />
        </span>
      </React.Fragment>
    )
  }

  return (
    <div>
      <div className={'MajorObjectBlock ' + (className || '') + ' MajorObjectBlock_' + type}>
        <div className="row">
          {renderMOBIcons()}
          {objectData.identity ? <ObjectRating name={name} rating={objectData.rating} /> : null}
          {!isMinorObject ? (
            <ObjectPicture
              name={name}
              img={objectPictureUrl(getFullUri(objectData), objectData.picture)}
              usage={usage}
            />
          ) : null}
          {isMinorObject ? (
            <div
              className={
                'col MajorObjectBlock__label' +
                (isGray ? '__gray ' : ' ') +
                className +
                '__label' +
                (isGray ? '__gray' : '')
              }
              title={name}
            >
              {noMultiLine ? name : <MultilineText text={name} maxLineCount={1} defaultLineHeight={25} />}
            </div>
          ) : (
            <MajorObjectLink type={type} object={objectData} onClick={clickHandler}>
              <span className={'col MajorObjectBlock__label ' + className + '__label'} title={name}>
                {name}
              </span>
            </MajorObjectLink>
          )}
          {(!showDescription && objectData.identity && objectData.identity.description) || objectData.description ? (
            <div className={'MajorObjectBlock__info ' + className + '__info'}>
              <SimpleTooltip className="ToolTipWidth300" title={description}>
                <img src={iIcon} className={'MajorObjectBlock__icon InfoIcon ' + className + '__icon'} alt="icon" />
              </SimpleTooltip>
            </div>
          ) : null}
          {childCount ? (
            <div className="MajorObjectBlock__childCount">
              <div>{childCount}</div>
            </div>
          ) : null}
        </div>
      </div>
      {showDescription === 'bottom' && description ? (
        <div className="MajorObjectBlock__descriptionBottom">{description}</div>
      ) : null}
      {showDescription === 'right' && description ? (
        <div className="MajorObjectBlock__descriptionRight col">{description}</div>
      ) : null}
    </div>
  )
}

MajorObjectBlock.propTypes = {
  objectData: PropTypes.object.isRequired,
  className: PropTypes.string,
  hasLinks: PropTypes.bool,
  type: PropTypes.string,
  clickHandler: PropTypes.func,
  isMinorObject: PropTypes.bool,
  childCount: PropTypes.number,
  showDescription: PropTypes.string // "" - show (i) icon, "right" - show Description to the right of the block, "bottom" - show to the bottom of the block
}
