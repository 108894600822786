import moment from 'moment'
import resolutionRed from '../resources/images/alarm-2x.png'
import resolutionYellow from '../resources/images/alarm-yellow-2x.png'
import resolutionGreen from '../resources/images/alarm-green-2x.png'
import md from './md'

export const resolutions = [
  { color: 'red', label: 'Missing' },
  { color: 'yellow', label: 'Near' },
  { color: 'green', label: 'Far' }
]

export function getIssueAbsoluteDeadline(issue) {
  //const now = moment();
  const time = getIssueDeadline(issue)
  const deadlineTime = moment(issue.object.history.created).add(moment.duration(time))
  //console.log("getIssueAbsoluteDeadline", issue.identity.name, moment(issue.object.history.created),  time, deadlineTime, moment(issue.object.history.created) + time);
  return deadlineTime
  //return moment.duration(deadlineTime.diff(now));
}

// in: object (period) or number (1, 2, 3)
export function issueResolutionIcon(resolution) {
  let index = resolution
  if (typeof resolution === 'object') {
    const delta = moment.duration(moment(resolution).diff(moment()))

    // @ts-ignore
    if (delta < 0) {
      index = 1
    } else if (delta.days() > 1) {
      index = 3
    } else if (delta.hours() > 4) {
      index = 2
    } else {
      index = 1
    }
  }

  if (index === 1) {
    return resolutionRed
  } else if (index === 2) {
    return resolutionYellow
  }

  return resolutionGreen
}

export const issuePropList = {
  priority: {
    source: md.repo.metadata.apdax.systems.difhub.applications.messaging.datasets.issuepriority,
    fields: ['name', 'description', 'code', 'period']
  },
  type: {
    source: md.repo.metadata.apdax.systems.difhub.applications.messaging.datasets.issuetype,
    fields: ['type', 'description', 'code', 'period', 'settings']
  },
  status: {
    source: md.repo.metadata.apdax.systems.difhub.applications.messaging.datasets.issuestatus,
    fields: ['name', 'description', 'code', 'period']
  },
  resolution: {
    source: md.repo.metadata.apdax.systems.difhub.applications.messaging.datasets.issueresolution,
    fields: ['name', 'description', 'code']
  }
}

export function issuePriorityLabel(code) {
  return issuePropList.priority.source.data.records.reduce((prev: any, src: any) => {
    return src.values[2] == code ? src.values[0] : prev
  }, null)
}

export function getIssueDeadline(issue) {
  return calcDeadline(issue).time
}

export function calcDeadline(issue) {
  let sumStatusPeriod = 0
  let nextStatusPeriod = 0
  let nextStatus = ''
  let nextBreak = false
  if (!issue.fullStatus || !issue.fullPriority || !issue.fullType) {
    return { time: 0, text: '' }
  }
  let statusRecords = issuePropList['status'].source.data.records
  for (let i = 0; i < statusRecords.length; i++) {
    if (nextBreak) {
      nextStatus = statusRecords[i][0]
      nextStatusPeriod = parseInt(statusRecords[i][3])
      break
    }
    sumStatusPeriod += parseInt(statusRecords[i][3])
    if (statusRecords[i][0] === issue.fullStatus.name) {
      nextBreak = true
    }
  }
  if (!nextBreak) sumStatusPeriod = 100
  sumStatusPeriod = 100 - sumStatusPeriod
  const typePeriod = issue.fullType.period
  //console.log("getIssueDeadline",issue.identity.name, issue, issue.fullPriority.period,moment.duration(issue.fullPriority.period).asMilliseconds() ,issue.fullStatus.name, sumStatusPeriod);
  let res = moment.duration((moment.duration(issue.fullPriority.period).asMilliseconds() * typePeriod * 100.0) / 100.0)
  //console.log("getIssueDeadline", res);
  //let text = "Priority period=" + moment.duration(issue.fullPriority.period).humanize() + " * status period=" + Math.round(sumStatusPeriod) + "%" + " = " + res.humanize();
  return {
    time: res,
    text: {
      toNextPercent: nextStatusPeriod,
      nextStatus: nextStatus,
      toClosePercent: sumStatusPeriod,
      fullTime: moment.duration(moment.duration(issue.fullPriority.period).asMilliseconds()).humanize(),
      toNextTime: moment
        .duration((moment.duration(issue.fullPriority.period).asMilliseconds() * typePeriod * nextStatusPeriod) / 100.0)
        .humanize(),
      toCloseTime: moment
        .duration((moment.duration(issue.fullPriority.period).asMilliseconds() * typePeriod * sumStatusPeriod) / 100.0)
        .humanize(),
      typePeriod: typePeriod
    }
  }
}

export function deadlineCalculation(issue) {
  issue = Object.assign(issue, {
    fullPriority: getIssuePropByName(issue.priority, 'priority'),
    fullStatus: getIssuePropByName(issue.status, 'status'),
    fullType: getIssuePropByName(issue.type, 'type')
  })
  //console.log("calcDedaline:", issue);
  return calcDeadline(issue).text
}

export function issueResolutionColor(period) {
  if (period.days() > 1) return 'green'
  if (period.hours() > 4) return 'yellow'
  return 'red'
}

export function getIssuePropByName(name, propName) {
  let ret
  name = (name + '').replace(/ /g, '')
  const list = issuePropList[propName].source.data.records

  list.map((el) => {
    if (el.values[0].replace(/ /g, '') === name) {
      // //console.log("getIssuePropByName:for",name,propName,el);
      ret = {}
      for (let i = 0; i < issuePropList[propName].fields.length; i++)
        ret[issuePropList[propName].fields[i]] = el.values[i]
    }
  })
  // console.log("getIssuePropByName",name,propName,list,ret);
  return ret
}
