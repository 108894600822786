import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { KanbanCard } from './KanbanCard'
import { defaultOrder, message, reorderMap } from './kanbanHelper'
import { clearIssue, prepareMessage } from '../../components/IssueDialog/issueDialogHelper'

import { IRootState } from '../../reducers/rootState'
import IconButton from '@material-ui/core/IconButton'
import { cloneDeep, orderBy } from 'lodash'
import { Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { httpClient } from '../../helpers/httpClient'

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 0.5rem 0`,
  borderRadius: '0.25rem',

  // change background colour if dragging
  background: isDragging ? '#f4f5f7' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle
})

const getListStyle = (isDraggingOver) => ({
  margin: '0.5rem',
  background: isDraggingOver ? 'lightblue' : 'rgb(235, 236, 240)',
  borderRadius: '0.25rem',
  padding: '0.5rem',
  width: 250,
  maxHeight: '100%',
  overflow: 'hidden'
})

const KanbanBoard: React.FC<any> = ({ issues, columns, onCardClick, selectedOrg, selectedIssues }) => {
  const [cards, setCards] = useState<any>({})
  const [selectedCol, setSelectedCol] = useState<any>()
  const { userAppState } = useSelector((state: IRootState) => state)
  const [anchorEl, setAnchorEl] = React.useState(null)

  useEffect(() => {
    const matrix = columns.reduce((allNames, name) => {
      allNames[name] = {
        issues: [],
        ...defaultOrder
      }
      return allNames
    }, {})
    for (const issue of issues) {
      // cleanup to send
      issue.priority = issue.fullPriority.name
      matrix[issue.status].issues.push(issue)
    }
    setCards(matrix)
  }, [issues])

  const handleClick = (event, col) => {
    setSelectedCol(col)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function onDragEnd(result: DropResult) {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }

    const data = reorderMap({
      cards,
      source,
      destination
    })

    const droppableIssue = cards[source.droppableId].issues[source.index]
    const issueToSend = clearIssue(droppableIssue)
    issueToSend.status = destination.droppableId

    const objectToSend = prepareMessage(issueToSend, droppableIssue, userAppState, message)

    httpClient.post(`/organizations/${selectedOrg}/messages`, objectToSend).then(({ data }) => {
      console.log(data)
    })

    setCards(data)
  }

  const orderByPriority = (order) => {
    const newCards = cloneDeep(cards)
    newCards[selectedCol].issues = orderBy(newCards[selectedCol].issues, ['fullPriority.code'], [order])
    newCards[selectedCol].priorityOrder = order
    setCards(newCards)
    setAnchorEl(null)
  }

  const orderByTime = (order) => {
    const newCards = cloneDeep(cards)
    newCards[selectedCol].issues = orderBy(
      newCards[selectedCol].issues,
      (issue: any) => moment(issue.time).format('X'),
      [order]
    )
    newCards[selectedCol].timeOrder = order
    setCards(newCards)
    setAnchorEl(null)
  }

  return (
    <div style={{ display: 'flex', maxHeight: '90vh' }}>
      <DragDropContext onDragEnd={onDragEnd}>
        {columns.map((col, ind) => (
          <Droppable key={ind} droppableId={col}>
            {(provided, snapshot) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                <div style={{ display: 'flex' }}>
                  <div>
                    <h2 className="KanbanCard__list-name">{col}</h2>
                  </div>
                  <div style={{ marginLeft: 'auto' }}>
                    <IconButton size="small" color="primary" onClick={(event) => handleClick(event, col)}>
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                </div>

                <div style={{ overflowY: 'auto', maxHeight: '100%' }}>
                  {cards[col]?.issues.map((card, index) => (
                    <Draggable key={card.identity.id} draggableId={card.identity.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onClick={() => onCardClick(card)}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <KanbanCard
                            isSelected={selectedIssues.some((issue) => issue?.identity?.id === card.identity.id)}
                            card={card}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => orderByPriority('asc')}>Sort by priority (asc)</MenuItem>
        <MenuItem onClick={() => orderByPriority('desc')}>Sort by priority (desc)</MenuItem>
        <MenuItem onClick={() => orderByTime('asc')}>Sort by time (asc)</MenuItem>
        <MenuItem onClick={() => orderByTime('desc')}>Sort by time (desc)</MenuItem>
      </Menu>
    </div>
  )
}

export default KanbanBoard
