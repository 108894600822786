/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { deploymentChildTypes, getFullUri } from '../../helpers'
import { getObjectNew, getRequestFromPath, singularType } from '../../helpers/api'
import { nonCSCompare } from '../../helpers/index'
import { downloadSelectorAsPDF } from '../../helpers/pdfDownloader'
import logger from '../../helpers/logger'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import './EnvironmentChart.scss'
import { EnvironmentLinesDrawer } from './EnvironmentLinesDrawer'
import { InstanceViewer } from './InstanceViewer'
import { PackageViewer } from './PackageViewer'

export class EnvironmentChart extends Component {
  constructor(props) {
    super(props)

    let height = this.InitHeight(props)

    this.state = {
      change: null, // Method to report or cancel modification.
      //origin: JSON.stringify(props.activity),
      height: height,
      editMode: false,
      topologies: [],
      deployments: [],
      applications: [],
      selectedItemId: '',
      selectedItemType: '',
      lastUpdateTime: null // Id for update line render when coordination need update
    }
  }

  componentDidMount() {
    deploymentChildTypes.map((type) =>
      this.setState({
        [type]: []
      })
    )

    // load topologies and deployments
    const env = this.props.environment

    let promises = []

    ;(env.topologies || []).map((topologyReference) => {
      promises.push(
        new Promise((resolve, reject) => {
          getObjectNew(getRequestFromPath(topologyReference.reference), 'topology').then((topology) => {
            this.setState(
              {
                topologies: Object.assign({}, this.state.topologies, {
                  [topologyReference.reference]: topology
                })
              },
              resolve
            )
          }, reject)
        })
      )
    })
    ;(env.deployments || []).map((deploymentReference) => {
      promises.push(
        new Promise((resolve, reject) => {
          getObjectNew(getRequestFromPath(deploymentReference.reference), 'deployment').then((deployment) => {
            this.setState(
              {
                deployments: Object.assign({}, this.state.deployments, {
                  [deploymentReference.reference]: deployment
                })
              },
              resolve
            )
          }, reject)
        })
      )
    })

    Promise.all(promises).then(() => {
      // load applications and child objects
      ;(env.topologies || []).map((topologyReference) => {
        const topology = this.state.topologies[topologyReference.reference]
        ;(topologyReference.instanceNames || []).map((instanceName) => {
          const instance = topology.instances.find((top) => nonCSCompare(top.identity.name, instanceName))
          // eslint-disable-next-line no-unused-vars
          const units =
            typeof instance.units === 'string' ? instance.units.split(',').map((unit) => unit.trim()) : instance.units
          ;(env.deployments || []).map((deploymentReference) => {
            const deployment = this.state.deployments[deploymentReference.reference]
            ;(deployment.packages || []).map((packageData) => {
              packageData.applications.map((appRef) => {
                //logger.log("Appref", appRef.reference);
                getObjectNew(getRequestFromPath(appRef.reference), 'application').then((app) => {
                  this.setState({
                    applications: Object.assign({}, this.state.applications, {
                      [appRef.reference]: app
                    })
                  })
                })
                deploymentChildTypes.map((type) => {
                  ;(appRef[type] || []).map((childRef) => {
                    //logger.log("childRef", childRef.reference, type);
                    if (childRef.reference)
                      getObjectNew(getRequestFromPath(childRef.reference), singularType(type)).then((child) => {
                        this.setState({
                          [type]: Object.assign({}, this.state[type], {
                            [childRef.reference]: child
                          })
                        })
                      })
                  })
                })
              })
            })
          })
        })
      })
    })
  }

  /**
   * Init dialog height
   * @param props
   */
  InitHeight = (props) => {
    props = props || this.props

    let height = 600

    if (height < window.innerHeight - 248) {
      height = window.innerHeight - 248
    }

    return height
  }

  /**
   * close dialog
   */
  onClose() {
    this.setState({
      editMode: false
    })
    if (this.props.onClose) this.props.onClose()
  }

  /**
   * Adjust size of scroll area in dialog
   * @param width - increase or reduction in control width.
   * @param height - increase or reduction in control height.
   * @return
   */
  onAdjust = (width, height) => {
    this.setState((prevState) => {
      return { height: prevState.height + height }
    })
  }

  /**
   * Set edit mode on
   */
  startEdit = (change) => {
    this.setState({
      change: change
    })
    window.SQL.preventTableMove = false
  }

  /**
   * Execute activity when message send to service
   * @param {boolean} close - flag to close dialog
   * @param {*} error - return from service if any.
   * @return
   */
  onSent = (close, error) => {
    // Report complete of save with error or not
    if (this.state.change) {
      this.state.change(error)
    }

    if (close && !error) {
      setTimeout(() => {
        this.onClose()
      }, 1000)
    }
  }

  /**
   * Cancel changes
   * @param {boolean} [closeDialog] Do we need to close dialog
   */
  cancelEdit = (closeDialog) => {
    // Cancel save mode
    if (this.state.change) {
      this.state.change(false)
    }

    this.setState({
      change: null,
      inputs: this.props.inputs || []
    })

    window.SQL.preventTableMove = true
  }

  /**
   * show or hide main window
   */
  toggleEditMode() {
    this.setState((prevState) => ({
      ...prevState,
      editMode: !prevState.editMode,
      isEdited: false
    }))
  }

  saveTopology = (topology, closeDialog, onSent) => {}

  downloadPDF = () => {
    const area =
      document.querySelector('.EditorDialog_environment_chart .scrollarea-content ') ||
      document.querySelector('.EditorDialog_environment_chart_fullscreen .scrollarea-content ')
    downloadSelectorAsPDF(area, this.props.environment.identity.name + ' Chart', 'div.table')
  }

  /**
   * toggle full screen for dialog
   */
  toggleFullScreen = () => {
    this.setState((prevState) => ({
      ...prevState,
      fullScreen: !prevState.fullScreen
    }))
  }

  /**
   * highlight item when we click on it
   * @param id
   * @param type
   * @param selectedItem
   */
  onItemClick = (id, type, selectedItem) => {
    this.setState({
      selectedItemId: id,
      selectedItemType: type,
      selectedItem
    })
  }

  /**
   * redraw lines when DOM are changed
   * @param withTimeout
   * @param timeout
   */
  updateLinesRender = (withTimeout, timeout) => {
    if (withTimeout) {
      // We need to redraw as only after page redraw we will have right coordinates for lines.
      setTimeout(() => {
        this.setState((prev) => {
          return {
            ...prev,
            lastUpdateTime: new Date()
          }
        })
      }, timeout)
    } else {
      this.setState((prev) => {
        return {
          ...prev,
          lastUpdateTime: new Date()
        }
      })
    }
  }

  /**
   * callback for click on line
   * @param editState
   */
  onArrowClick = (editState) => {
    logger.log(editState)
  }

  /**
   * update lines when table expanding
   */
  onTableExpand = () => {
    this.updateLinesRender(true, 0)
  }

  onItemScroll = () => {
    this.updateLinesRender(false)
  }

  /**
   * Displays package (StructureTable with onClick handler)
   * @param editState
   * @param instance
   * @param reference
   * @param topology
   * @returns
   */
  renderInstance = (editState, instance, reference, topology) => {
    return (
      <InstanceViewer
        editState={editState}
        instance={instance}
        reference={reference}
        topology={topology}
        selectedItemId={this.state.selectedItemId}
        environment={this.props.environment}
        onItemClick={this.onItemClick}
        onTableExpand={this.onTableExpand}
        onItemScroll={this.onItemScroll}
      />
    )
  }

  /**
   * Displays package (StructureTable with onClick handler)
   * @param editState
   * @param packageData
   * @param reference
   * @param deployment
   * @returns
   */
  renderPackage = (editState, packageData, reference, deployment) => {
    return (
      <PackageViewer
        editState={editState}
        deployment={deployment}
        packageData={packageData}
        reference={reference}
        selectedItemId={this.state.selectedItemId}
        selectedItemType={this.state.selectedItemType}
        environment={this.props.environment}
        onItemClick={this.onItemClick}
        onTableExpand={this.onTableExpand}
        onItemScroll={this.onItemScroll}
      />
    )
  }

  /**
   * draw lines between packages and instances
   * @param editState
   * @param lines
   * @returns {JSX.Element}
   */
  renderLines = (editState, lines) => {
    return (
      <EnvironmentLinesDrawer
        editState={editState}
        lines={lines}
        lastUpdateTime={this.state.lastUpdateTime}
        onArrowClick={this.onArrowClick}
      />
    )
  }

  renderEditor = (editState) => {
    const env = this.props.environment

    let lines = []

    env.applications.map((appRef) => {
      // find package
      let packageDatas = []
      let deployments = []
      env.deployments.map((deploymentReference) => {
        const curDeployment = this.state.deployments[deploymentReference.reference]
        if (!curDeployment) {
          return
        }
        ;(curDeployment.packages || []).map((curPackageData) => {
          ;(curPackageData.applications || []).map((curAppRef) => {
            if (curAppRef.reference === appRef.reference) {
              packageDatas.push(curPackageData)
              deployments.push(curDeployment)
            }
          })
        })
      })
      // logger.log("deployments::", deployments);

      if (!deployments || deployments.length === 0) {
        return
      }

      deploymentChildTypes.map((type) => {
        ;(appRef[type] || []).map((childRef, idx) => {
          if (childRef.layoutInstances) {
            childRef.layoutInstances.map((inst) => {
              const topologyReference = inst.reference
              const instanceName = inst.instance

              // logger.log({topologyReference, instanceName});

              const topology = this.state.topologies[topologyReference]
              if (!topology) {
                return
              }

              const instance = topology.instances.find((top) => nonCSCompare(top.identity.name, instanceName))
              if (!instance) {
                return
              }

              const child = this.state[type][childRef.reference]
              if (!child) {
                return
              }

              //const unit = childRef.layoutInstance.unit;    /// ??????????

              deployments.map((deployment, index) => {
                const packageData = packageDatas[index]
                logger.log('Find ', childRef.reference, 'in', packageData, type)
                const packageChildRef = (
                  packageData.applications.find((ar) => ar.reference === appRef.reference)[type] || []
                ).find((cr) => cr.reference === childRef.reference)
                if (packageChildRef) {
                  logger.log('packageChildRef', packageChildRef)
                  const unit = child.layouts[0].unit
                  lines.push({
                    topology,
                    deployment,
                    instance,
                    packageData,
                    unit,
                    appRef,
                    type,
                    childRef,
                    child
                  })
                }
              })
            })
          }
        })
      })
    })

    return (
      <div className="EnvironmentChart SQLDesigner__root ">
        <div className="EditorDialog_environment_chart__additionalButtons">
          <div className="EditorDialog_environment_chart__headerControlPdf">
            <a className="" onClick={this.downloadPDF}>
              <span>{'{PDF}'}</span>
            </a>
          </div>
        </div>

        <div className="row" id="EnvironmentChart_row">
          <div className="col-xs-5 EnvironmentChart__deployments">
            <div>Deployments:</div>

            {env.deployments.map((deploymentReference) => {
              const deployment = this.state.deployments[deploymentReference.reference]
              if (!deployment) {
                return
              }
              return (
                <div className="EnvironmentChart__deployment row" key={deploymentReference.reference}>
                  <div className="EnvironmentChart__deploymentName col-xs-2">{deployment.identity.name}</div>
                  <div className="EnvironmentChart__deploymentPackages col-xs-10">
                    {deployment.packages.map((packageData) => {
                      return this.renderPackage(
                        editState,
                        packageData,
                        getFullUri(deployment) + '/packages/' + packageData.identity.name,
                        deployment
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>

          <div className="col-xs-2 EnvironmentChart__lines">
            <svg>{this.renderLines(editState, lines)}</svg>
          </div>

          <div className="col-xs-5 EnvironmentChart__topologies">
            <div>Topologies:</div>

            {env.topologies.map((topologyReference) => {
              const topology = this.state.topologies[topologyReference.reference]
              if (!topology) {
                return
              }
              return (
                <div className="EnvironmentChart__topology row" key={topologyReference.reference}>
                  <div className="EnvironmentChart__topologyInstances col-xs-10">
                    {topology.instances.map((instance) => {
                      return this.renderInstance(
                        editState,
                        instance,
                        getFullUri(topology) + '/instances/' + instance.identity.name,
                        topology
                      )
                    })}
                  </div>
                  <div className="EnvironmentChart__topologyName col-xs-2">{topology.identity.name}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  renderFooter = (editState) => {
    if (this.state.selectedItemType === 'application') {
      const { application, deployment, packageData } = this.state.selectedItem
      return (
        <div className="EnvironmentChart__footerBlock">
          <div className="EnvironmentChart__footerPath">
            Application {application.identity.name} in package {packageData.identity.name} in deployment{' '}
            {deployment.identity.name}
          </div>
          {[application.identity, packageData.identity, deployment.identity]
            .filter((d) => d.description)
            .map((d) => (
              <div className="EnvironmentChart__footerItem" key={d.name}>
                <div className="EnvironmentChart__footerName">{d.name} </div> {d.description}
              </div>
            ))}
        </div>
      )
    } else if (this.state.selectedItemType === 'unit') {
      const { topology, instance, unit } = this.state.selectedItem
      return (
        <div className="EnvironmentChart__footerBlock">
          <div className="EnvironmentChart__footerPath">
            Unit {unit} in instance {instance.identity.name} in topology {topology.identity.name}
          </div>
          {[instance.identity, topology.identity]
            .filter((d) => d.description)
            .map((d) => (
              <div className="EnvironmentChart__footerItem" key={d.name}>
                <div className="EnvironmentChart__footerName">{d.name} </div> {d.description}
              </div>
            ))}
        </div>
      )
    } else if (deploymentChildTypes.indexOf(this.state.selectedItemType) !== -1) {
      const { application, deployment, packageData, childRef } = this.state.selectedItem
      // logger.log("Identiy", [childRef.identity, application.identity, packageData.identity, deployment.identity].filter(d => d.description));
      return (
        <div className="EnvironmentChart__footerBlock">
          <div className="EnvironmentChart__footerPath">
            {singularType(this.state.selectedItemType)} {childRef.identity.name} in application{' '}
            {application.identity.name} in package {packageData.identity.name} in deployment {deployment.identity.name}
          </div>
          {[childRef.identity, application.identity, packageData.identity, deployment.identity]
            .filter((d) => d.description)
            .map((d) => (
              <div className="EnvironmentChart__footerItem" key={d.name}>
                <div className="EnvironmentChart__footerName">{d.name} </div> {d.description}
              </div>
            ))}
        </div>
      )
    }

    return null
  }

  render() {
    return (
      <EditorDialog
        level="0"
        ref="dialog"
        objectType={this.state.fullScreen ? 'environment_chart_fullscreen' : 'environment_chart'}
        modalTitle={'Environment diagram: ' + (this.props.environment ? this.props.environment.identity.name : '')}
        confirmText={this.props.environment ? 'Update' : 'Create'}
        editContent={this.renderEditor}
        footerContent={this.renderFooter}
        editHeight={this.state.height}
        isEditable={this.props.isEditable}
        isVisible
        onClose={this.onClose.bind(this)}
        onSave={this.saveTopology}
        onEdit={this.startEdit}
        onCancel={this.cancelEdit}
        onToggleFullScreen={() => this.forceUpdate()}
      />
    )
  }
}

EnvironmentChart.propTypes = {
  environment: PropTypes.object,
  isEditable: PropTypes.number,
  topology: PropTypes.object,
  onClose: PropTypes.func,
  inputs: PropTypes.array
}
