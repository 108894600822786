/**
 * Created by mailf on 19.10.2016.
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import ObjectSearchMultistep from '../ObjectSearch/ObjectSearchMultistep'
import ObjectPathEditor from '../ObjectSearch/ObjectPathEditor'

import './ObjectSelector.scss'
import { getFullUri } from '../../helpers/index'

import iArrowDownClose from '../../resources/images/arrow-down-close.png'

export class ObjectSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showByStep: this.props.showByStep
    }
  }

  onSelect = (obj, objectType) => {
    let path = getFullUri(obj)

    //console.log("ObjectSelector:OnSelect", obj, objectType, path);

    if (this.props.onSelect && this.props.onSelect(obj, objectType)) return true
  }

  render() {
    //console.log("ObjectSelector:OnSelect", this.state, this.props);
    return (
      <div className="ObjectSelector">
        <div className={'ObjectSelector_' + (this.state.showByStep ? 'opened' : 'closed')}>
          <ObjectPathEditor
            appState={this.props.appState}
            actions={this.props.actions}
            startObjectPath={this.props.startObjectPath}
            targetObjectPath={this.props.targetObjectPath}
            childrenByType={this.props.childrenObjectByType}
            isClosed={this.state.showByStep}
            onSelect={this.onSelect}
            onAdjust={this.props.onAdjust}
          />
        </div>
        {this.state.showByStep ? (
          <div className="ObjectSelector_closed">
            <ObjectSearchMultistep
              appState={this.props.appState}
              actions={this.props.actions}
              title={this.props.title}
              parentPath={this.props.startObjectPath}
              targetPath={this.props.targetObjectPath}
              targetType={this.props.targetObjectType}
              childrenByType={this.props.childrenObjectByType}
              onSelect={this.onSelect}
              onAdjust={this.props.onAdjust}
            />
          </div>
        ) : null}
        <div className="ObjectSelector_collapsable ObjectSelector_title">
          <div
            className={
              'ObjectSelector_collapse ' + 'ObjectSelector_collapse_' + (this.state.showByStep ? 'opened' : 'closed')
            }
            onClick={() => {
              if (this.props.onAdjust) {
                this.props.onAdjust(0, (this.state.showByStep ? -1 : 1) * 180)
              }
              this.setState({ showByStep: !this.state.showByStep })
            }}
          >
            <img src={iArrowDownClose} alt="" />
          </div>
        </div>
      </div>
    )
  }
}

ObjectSelector.propTypes = {
  startObject: PropTypes.object,
  startObjectPath: PropTypes.string,
  startObjectType: PropTypes.string,
  targetObjectType: PropTypes.string,
  targetObjectPath: PropTypes.string, // this is full path with empty or star for selectable part: /organizations/*/systems/*/applications
  selectedType: PropTypes.string,
  appState: PropTypes.object,
  actions: PropTypes.object,
  onSelect: PropTypes.func,
  onAdjust: PropTypes.func,
  showByStep: PropTypes.bool,
  title: PropTypes.string
}
ObjectSelector.defaultProps = {
  showByStep: true
}
