/**
 * Created by kascode on 26.06.17.
 */
import * as React from 'react'
// eslint-disable-next-line import/no-duplicates
import { Component } from 'react'
import logger from '../../helpers/logger'
import * as PropTypes from 'prop-types'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { LinkedKeyTables } from '../LinkedKeyTables/LinkedKeyTables'
import { getObjectNew, idAPI, getRequestFromPath } from '../../helpers/api'
import {
  nonCSCompare,
  deepCopy,
  findById,
  findByName,
  findByIdentity,
  getParentUri,
  editableState,
  majorObjectSort,
  updateLocalObject,
  clearLocalObject,
  getFullUri
} from '../../helpers/index'

import './TopologySubscription.scss'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { renderRestore } from '../../helpers/helperComponents'

export class TopologySubscription extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      topologyObject: null
    })
  }

  getObjectType() {
    return 'topologyReference'
  }

  UNSAFE_componentWillReceiveProps() {}

  componentDidMount() {
    if (this.props.minorObject)
      this.getTopologyData(this.props.minorObject.identity.id, this.props.minorObject.identity.name)
  }

  /**
   * Get initial state
   * @param {object} [props]
   * @returns {*}
   */
  getInitial(props) {
    props = props || this.props
    console.log('TopologySubscription:getInitialState', props.object, props.publication)
    if (props.minorObject) {
      return JSON.parse(JSON.stringify(props.minorObject))
    } else {
      return {
        identity: { name: '', description: '' },
        version: { major: 0, minor: 0, revision: 0 },
        instanceNames: []
      }
      //createEmptyObject('Topologyreference');
    }
  }

  /**
   * Change state of topology
   * @param {object} - new state of object
   * @returns {*} true - it is updated, false - no changes
   */
  ChangeTopologyReference = (reference) => {
    return this.Change(reference)
  }

  /**
   * Get data for provided topology
   * @param {string} id - uid of topology
   * @param {string} name - topology name
   * @param {boolean} updateReference - whether we need to update reference
   */
  getTopologyData = (id, name, updateReference) => {
    let publication = this.props.publication
    let interfc = findById(publication.topologies, id)
    if (!interfc) return

    if (!this.props.minorObject || this.props.minorObject.identity.id !== id) {
      const topologyReference = {
        identity: interfc.identity,
        version: interfc.version,
        instanceNames: []
      }
      if (updateReference) this.ChangeTopologyReference(topologyReference)
    }

    getObjectNew(idAPI.topologies(id), 'topology', this.props.actions).then(
      (obj) => {
        this.setState({
          height: this.state.height + (obj.instances ? obj.instances.length : 0) * 40,

          topologyObject: obj
        })
      },
      (err) => {
        //console.log("InrefacePublication:getTopologyData:ERROR", err);
      }
    )
  }

  checkInputErrors(topologyReference) {
    let inputError = ''
    if (this.state.topologyReference.instanceNames.length < 1) {
      inputError += 'Please select instances for subscription! '
    }
    return inputError
  }

  onSelectTopology(event) {
    // console.log("TopologySubscription:onSelectTopology", intrfc);
    let intrfc = event.target.value

    logger.info('TopologySubscription:onSelectTopology', intrfc, this.props, this.state)

    // Indicate save mode
    if (this.state.change && intrfc) {
      this.getTopologyData(intrfc.identity.id, intrfc.identity.name, true)
    }
  }

  getTopologyOptions() {
    //console.log("TopologySubscription:getTopologyOptions", this.props.publication, this.state.topologyReference);
    let subscribed =
      this.props.subscription && this.props.subscription.topologies ? this.props.subscription.topologies : []
    let published = this.props.publication && this.props.publication.topologies ? this.props.publication.topologies : []
    let topologies = published.filter((ds) => subscribed.findIndex((dt) => ds.identity.id === dt.identity.id) === -1)

    return topologies.map((element, index) => {
      return {
        id: index,
        label: element.identity.name,
        value: element
      }
    })
  }

  getInstanceOptions() {
    //console.log("TopologySubscription:getinstanceOptions", this.props, this.state.topologyReference);
    let interfc = findByIdentity(this.props.publication.topologies, this.state.topologyReference)
    if (!interfc || !interfc.instanceNames) return []

    let topologyInstances = this.state.topologyObject ? this.state.topologyObject.instances : null
    if (topologyInstances) {
      return interfc.instanceNames.map((element, index) => {
        let inst = findByName(topologyInstances, element)
        return [index, inst && inst.identity ? inst.identity.name : '', '']
      })
    }
    return interfc.instanceNames.map((element, index) => [index, element, ''])
  }

  onInstanceChange(index) {
    return (newSelected) => {
      let topologyReference = deepCopy(this.state.topologyReference)
      let instanceOptions = this.getInstanceOptions()

      //console.log("TopologySubscription:onInstanceChange", index, newSelected, topologyReference, instanceOptions);

      topologyReference.instanceNames = []
      for (let i in newSelected) {
        let f = instanceOptions[newSelected[i]]
        topologyReference.instanceNames.push(f[1])
      }

      this.ChangeTopologyReference(topologyReference)
    }
  }

  /**
   * Filter string for item in the list
   * @return default filter string
   */
  itemFilter = (item, type, query) => {
    //console.log("TopologySubscription:itemFilter", item, type, query, this.props);
    // Check if topology exist in publication
    if (
      this.props.publication.topologies.findIndex((inter) => nonCSCompare(item.identity.name, inter.identity.name)) ===
      -1
    )
      return false

    // Check if topology already used in subscription
    if (
      this.props.subscription.topologies.findIndex((inter) => nonCSCompare(item.identity.name, inter.identity.name)) !==
      -1
    )
      return false

    if (!query || query.length === 0) {
      return true
    } else {
      return item.identity.name && item.identity.name.toLowerCase().startsWith(query)
    }
  }

  renderInstances = (editState) => {
    //console.log("TopologySubscription:renderInstances", this.state, this.props);
    let instanceOptions = this.getInstanceOptions()
    if (!instanceOptions || instanceOptions.length === 0) return null

    let instanceNames =
      this.state.topologyReference && this.state.topologyReference.instanceNames
        ? this.state.topologyReference.instanceNames
        : []
    let selectedInstances = instanceNames.map((element, index) =>
      instanceOptions.findIndex((op) => nonCSCompare(op[1], element))
    )

    //console.log("TopologySubscription:renderInstances", selectedInstances, instanceOptions);
    return (
      <div>
        <div>
          <div className="row TopologySubscription__field">
            <div className="col-xs-1">
              <span className="TopologySubscription__label">Instances:</span>
            </div>
          </div>
          <div className="row TopologySubscription__row">
            <LinkedKeyTables
              columns={['NN', 'Name', 'Action']}
              selected={selectedInstances}
              items={instanceOptions}
              onChange={this.onInstanceChange(0).bind(this)}
              editable={editState > editableState.EDITABLE}
              onSrcFieldChange={() => {}}
              canAddField={(index) => {
                return true
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render topology for publication.
   */
  renderTopology = (editState) => {
    let description =
      this.state.topologyReference.identity &&
      this.state.topologyReference.identity.description &&
      this.state.topologyReference.identity.description.length > 0
        ? this.state.topologyReference.identity.description
        : ''
    let name =
      this.state.topologyReference.identity &&
      this.state.topologyReference.identity.name &&
      this.state.topologyReference.identity.name.length > 0
        ? this.state.topologyReference.identity.name
        : 'Select to subscribe'

    let topologies = this.getTopologyOptions()

    const inEditMode = editState > editableState.EDITABLE
    return (
      <div>
        <div className={'NewSettingsBlock'}>
          <div className="row TopologySubscription__row">
            <div className="col-xs-1">
              <span className="TopologySubscription__label">Topology:</span>
            </div>
            <div className="col-xs-6">
              {inEditMode ? (
                <EditableEntity
                  data={name}
                  dataType={{
                    name: 'enum',
                    options: topologies
                  }}
                  dataProps={{
                    placeholder: 'Select topology',
                    onChange: this.onSelectTopology.bind(this)
                  }}
                  isEditable={inEditMode}
                  inEditMode={inEditMode}
                />
              ) : (
                <EditableEntity
                  data={name}
                  dataType={{ name: 'string' }}
                  dataProps={{ placeholder: '' }}
                  isEditable={false}
                  inEditMode={false}
                />
              )}
            </div>
            <div className="col-xs-3">
              <span className="TopologySubscription__label">Version:</span>
            </div>
            <div className="col-xs-4">
              <div className="TopologySubscription__subjectVersion">
                {this.state.topologyReference.version.major + '.*.*'}
              </div>
            </div>
            <div className="col-xs-8">
              <div className="TopologySubscription__infoIcon" />
            </div>
          </div>
        </div>
        <div className={'NewSettingsBlock'}>
          <div className="row TopologySubscription__row TopologySubscription__descriptionRow">
            <div className="col-xs-1">
              <div className="TopologySubscription__subjectLabel">Description:</div>
            </div>
            <div className="col-xs-7">
              <div className="topologySubscription__subjectValue">{description}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render scrollable area of dialog
   * @return
   */
  renderData = (editState) => {
    //console.log("TopologySubscription:renderData", this.state, editState);

    return (
      <div className="TopologySubscription">
        {this.renderTopology(editState)}
        {this.renderInstances(editState)}
      </div>
    )
  }

  render() {
    let caption = 'Topology to subscribe'

    //console.log("TopologySubscription:render", this.props, this.state);
    return (
      <div className="TopologySubscription">
        <EditorDialog
          objectType="data"
          modalTitle={caption}
          editContent={this.renderData.bind(this)}
          headerContent={renderRestore(this, () => {
            let appUri = getFullUri(this.props.publication)
            appUri = appUri.substr(0, appUri.indexOf('/publications'))
            getObjectNew(getRequestFromPath(appUri + '/topologies/' + this.state.topologyReference.identity.name)).then(
              (intf) => {
                this.getTopologyData(intf.identity.id, intf.identity.name)
              }
            )
          })}
          editHeight={this.state.height}
          isVisible={this.props.isVisible}
          isEditable={this.props.isEditable}
          onClose={this.props.onClose}
          onSave={this.save.bind(this)}
          onEdit={this.startEdit}
          onCancel={this.cancelEdit}
        />
      </div>
    )
  }
}

TopologySubscription.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  publication: PropTypes.object,
  subscription: PropTypes.object,
  object: PropTypes.object,
  minorObject: PropTypes.object,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}
