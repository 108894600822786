import PropTypes from 'prop-types'
import React, { Component } from 'react'
import logger from '../../helpers/logger'
import { deepCopy, nonCSCompare, editableState } from '../../helpers/index'

import './InstanceDialog.scss'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'

export class InstanceDialog extends EditorDialogBase {
  constructor(props) {
    super(props)
    //logger.info("InstanceDialog", props);
  }

  getObjectType() {
    return 'instance'
  }

  getMinHeight() {
    return 500
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load platforms list from organization we are in.
    this.loadPlatform(this.state.instance ? this.state.instance.platform : null)

    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifGate/applications/System/datasets/Instance',
      '/organizations/Apdax/systems/DifGate/applications/System/views/InstanceDialog',
      '/organizations/Apdax/systems/DifGate/applications/System/datasets/InstanceType'
    )

    logger.info('InstanceDialog:open', this.state, this.props)
  }

  checkInputErrors(instance) {
    const condition = this.props.majorObject
    let inputError = ''
    if (condition && instance.identity && instance.identity.name) {
      const checkInstaceName = condition.instances.find(
        (e) =>
          nonCSCompare(e.identity.name, instance.identity.name) && !nonCSCompare(e.identity.id, instance.identity.id)
      )
      if (checkInstaceName) {
        inputError += 'Instance name already exist! '
      }
    }
    if (!instance.identity || !instance.identity.name) {
      inputError += 'Please specify instance name! '
    }
    if (nonCSCompare(instance.type, 'Defined') && !instance.platform) {
      inputError += 'Please specify instance platform! '
    }
    if (!nonCSCompare(instance.type, 'Defined') && (!instance.reference || !instance.reference.reference)) {
      inputError += 'Please specify instance reference topology! '
    }
    if (!nonCSCompare(instance.type, 'Defined') && instance.reference && !instance.reference.instance) {
      inputError += 'Please specify reference topology instance! '
    }
    if (!instance.units) {
      inputError += 'Please specify instance units! '
    }

    return inputError
  }

  /**
   * Validate value of changed field
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @return true - change approved
   */
  onValidateChange = (element, field, value, instance, option) => {
    logger.info('InstanceDialog:onValidateChange', { element, field, value, instance }, this.state, this.props)
    if (field === 'type' && value) {
      // For operation we prepare structure to keep info about interface.
      if (nonCSCompare(value, 'exported')) {
        instance['reference'] = {
          reference: '',
          subscription: '',
          instance: ''
        }
        if (instance.configuration) delete instance.configuration
        if (instance.platform) delete instance.platform
      } else if (nonCSCompare(value, 'defined')) {
        instance['configuration'] = {}
        if (instance.reference) delete instance.reference
        if (instance.platform) delete instance.platform
      }
    } else if (field === 'platform' && instance && option) {
      instance.platform = value
      let uri = option.object['Instance']
      if (uri) {
        instance.configuration.reference = '/organizations/' + this.state.organization + '/datasets/' + uri
      } else {
        delete instance.configuration.reference
      }
      delete instance.configuration.value
    } else if (field === 'reference' && instance && option) {
      instance.reference = value
      if (instance.reference.instance) {
        instance.platform = option.object['Platform']
      }
    } else if (field === 'units' && instance) {
      let array = value.split(',').map(unit => { return unit.trim(); });
      instance.units = array;
      logger.info("InstanceDialog:onValidateChangeUNITS", { field, value, array, instance }, this.state, this.props);
    }
    return instance
  }

  renderEditor = (editState) => {
    // State and props.
    const instance = this.state.instance
    //logger.info("InstanceDialog:renderEditor", editState, instance, this.state, this.props);

    return (
      <div className="InstanceDialog" onClick={() => this.onCheckDialogHeight('.InstanceDialog')}>
        {this.renderView(editState, (element) => {
          switch (element) {
            default:
              return null
            case 'platform':
              return instance.type && !nonCSCompare(instance.type, 'Defined') ? <div></div> : null
            case 'configuration':
              return (instance.type && !nonCSCompare(instance.type, 'Defined')) || !instance.platform ? (
                <div></div>
              ) : null
            case 'reference':
              return instance.type && nonCSCompare(instance.type, 'Defined') ? <div></div> : null
          }
        })}
      </div>
    )
  }
}

InstanceDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  instance: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
