import PropTypes from 'prop-types'
import React, { Component } from 'react'

import '../../styles/MultilineText/MultilineText.scss'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import { nl2br } from '../../helpers/nl2br'

type AddIssueInstructionProps = {
  text: string
  className?: string
  title: string
  isVisible: boolean
  onClose: any
}

type AddIssueInstructionState = {}

export class AddIssueInstruction extends Component<AddIssueInstructionProps, AddIssueInstructionState> {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: props.isVisible,
      text: props.text,
      title: props.title
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // //console.log("AddIssueInstruction new props", newProps);
    this.setState({ isOpen: false }, function () {
      // @ts-ignore
      this.forceUpdate()
    })
  }

  open(event) {
    this.setState({ isOpen: true })
    event.stopPropagation()
  }

  close() {
    this.setState({ isOpen: false })
  }

  render() {
    let style = { maxHeight: 'none', overflow: 'hidden' }

    return (
      <div className="AddIssueInstruction">
        <div className={'AddIssueInstruction__text ' + this.props.className} style={style}>
          {nl2br(this.props.text)}
        </div>

        <ModalWindow
          isOpen={this.props.isVisible}
          header={this.props.title}
          onClose={this.props.onClose}
          canClose
          footer={
            <button className="btn_big" onClick={this.props.onClose}>
              Close
            </button>
          }
        >
          <div className="AddIssueInstruction__full">{nl2br(this.props.text)}</div>
        </ModalWindow>
      </div>
    )
  }
}
