import React from 'react'

export const Right = () => (
  <div className="col-xs-4 ">
    <div className="auth_right row">
      <div className="col-xs-2">
        <img className="auth_lamp" src="/images/lamp-2x.png" alt="auth lamp" />
      </div>
      <div className="col-xs-10">
        <h2>Communication</h2>
        <br />
        MetUCat can be configured to create applications which fit into any data platform used by your enterprise.
        Architects, developers and business analysts can collaborate within MetUCat, eliminating misunderstandings and
        disconnects from an application’s inception through its deployment and maintenance.
      </div>
    </div>
  </div>
)
