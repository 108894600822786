/**
 * Created by mailf on 08.06.2016.
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import logger from '../../helpers/logger'
import { SettingDialog } from './SettingDialog'
import { deepMerge, nonCSCompare, editableState } from '../../helpers/index'

import './SettingList.scss'
import { EditorListBase } from '../EditorDialog/EditorListBase'

export class SettingList extends EditorListBase {
  constructor(props) {
    super(props)

    //logger.info("SettingList:constructor", this.props, this.state);
  }

  /**
   * Save Item in layout
   * @param {object} obj - item to save
   * @param {object} base - item from base layer
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   * @return
   */
  saveSetting = (obj, base, closeDialog, onSent) => {
    let item = base ? deepMerge(base, obj) : obj
    let objs = this.props.items || []
    console.log('SettingList:saveSetting', obj, base, item, this.state, this.props)

    let items = objs.filter((tobj) => !nonCSCompare(tobj.identity.name, obj.identity.name))
    items.push(item)
    if (this.props.onSave) {
      this.props.onSave(items, closeDialog, onSent)
    }
  }

  /**
   * Render dialog for item entry
   */
  renderItemDialog(editState, item, base, isEditable) {
    //logger.info("SettingList:renderItemDialog", editState, item, base, isEditable, this.props, this.state);

    return (
      <SettingDialog
        appState={this.props.appState}
        actions={this.props.actions}
        isVisible
        isEditable={isEditable}
        type={this.props.type}
        usage={this.props.usage}
        setting={item}
        base={base}
        majorObject={this.props.majorObject}
        parentDialog={this.props.parentDialog}
        onClose={this.closeItem}
        onSave={(obj, closeDialog, onSent) => this.saveSetting(obj, base, closeDialog, onSent)}
      />
    )
  }
}

SettingList.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object,
  isEditable: PropTypes.number, // State of editing in dialog
  majorObject: PropTypes.object.isRequired, // Major object we work against
  type: PropTypes.string, // Type of object
  usage: PropTypes.string, // Usage for propery predefined
  items: PropTypes.object.isRequired, // Resource object array
  base: PropTypes.object // Resource object base array
}
