import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import { Routes } from './routes'
import configureStore from './store/configureStore'
import reportWebVitals from './reportWebVitals'
import 'flexboxgrid/dist/flexboxgrid.min.css'
import './styles/styles.scss'

require('./helpers/polyfill.js')

require('./resources/lib/crypto.js')
require('./resources/lib/json-sans-eval.js')
require('./resources/lib/jws-3.0.js')
require('./resources/lib/rsa.js')
require('./resources/lib/token.js')
require('./resources/lib/JSClasses.js')
require('./components/SQLDesigner/wwwsqldesigner.js')

const store = configureStore()
export const history = createBrowserHistory()

ReactDOM.render(
  <Provider store={store}>
    {/*// @ts-ignore*/}
    <Router history={history}>
      <Routes />
    </Router>
  </Provider>,
  document.getElementById('root')
)

reportWebVitals()
