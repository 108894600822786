/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-typos */
import PropTypes from 'prop-types'
import React, { Component, RefObject } from 'react'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import { FilterableContent } from '../FilterableContent/FilterableContent'
import { SQLDesigner } from '../SQLDesigner'
import { SimpleTooltip } from '../SimpleTooltip/SimpleTooltip'
import { EditDialogButton } from '../EditButton/EditDialogButton'
import { KascodeSelect } from '../KascodeSelect/KascodeSelect'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import Table from '../Table/Table'
import { editableState, getFullUri } from '../../helpers/index'

import './SQLDesignerWindow.scss'

import iI from '../../resources/images/i-dark-blue-2x.png'
import iArrowDownClose from '../../resources/images/arrow-down-close.png'

const NEW_VIEW_NAME = 'new view'

type SQLDesignerWindowProps = {
  application: any
  filters: any[] | JSX.Element
  actions: any
  views: any[]
  onTableMove: Function
  onViewListChange: Function
  mode?: string
  datasets: any[]
  subscriptions: any[]
  onClose: () => void
}

type SQLDesignerWindowState = {
  filters: any[]
  modifiedDatasets: any[]
  isEditing: boolean
  clear: boolean
  views: any[]
  editViews: any[]
  selectedView: string
  showManageViews: boolean
  newViewName: string
  edited: boolean
  renamedViews: any[]
  deletedViews: any[]
  bottomAreaCollapsed: boolean
  showChangeModeConfirmation: boolean
  mode: any
  loading: boolean
}

export class SQLDesignerWindow extends Component<SQLDesignerWindowProps, SQLDesignerWindowState> {
  filterablecontentRef: RefObject<any>
  sqldesignerRef: RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      filters: [],
      modifiedDatasets: [],
      isEditing: false,
      clear: false,
      views: [],
      editViews: [],
      selectedView: 'default',
      showManageViews: false,
      newViewName: 'new view',
      edited: false,
      renamedViews: [],
      deletedViews: [],
      loading: false,
      bottomAreaCollapsed: false,
      showChangeModeConfirmation: false,
      mode: this.getInitialMode(props.application)
    }

    this.filterablecontentRef = React.createRef()
    this.sqldesignerRef = React.createRef()
  }

  componentDidMount() {
    window.savedChartXmlString = ''
  }

  getInitialMode = (app) => {
    const mode = localStorage['ChartViewMode_' + getFullUri(app)] || 'window'
    return mode
  }

  clearAllFilters() {
    this.filterablecontentRef.current.clearAllFilters()
  }

  /**
   * Toggle fullSize/window mode and update state and style
   * Style update is needed to prevent additional scrollbar
   */
  changeMode = () => {
    setTimeout(() => {
      if (this.state.modifiedDatasets.length > 0) {
        this.setState({ showChangeModeConfirmation: true })
        return
      }
      this.setState(
        {
          mode: this.state.mode === 'fullSize' ? 'window' : 'fullSize'
        },
        () => {
          localStorage['ChartViewMode_' + getFullUri(this.props.application)] = this.state.mode
          document.body.style.overflow = this.state.mode === 'fullSize' ? 'hidden' : 'auto'
        }
      )
    }, 0)
  }

  /**
   * Save new position of Table in local state
   * @param name
   * @param x
   * @param y
   * @param color
   * @param links
   * @param path
   * @param w
   * @param h
   * @param links_onetomany
   */
  tableMove(name, x, y, color, links, path, w, h, links_onetomany, subName) {
    let datasets = this.state.modifiedDatasets.filter((ds) => ds.name !== name)
    datasets.push({
      name: name,
      x: x,
      y: y,
      w: w,
      h: h,
      color: color,
      links: links,
      path: path,
      links_onetomany: links_onetomany,
      subName: subName
    })
    this.setState({ modifiedDatasets: datasets })

    window.savedChartXmlString = ''
  }

  /**
   * Create new view (name taken from local state) with no elements
   */
  createView = () => {
    this.setState(
      {
        selectedView: NEW_VIEW_NAME,
        showManageViews: false
      },
      () => {
        this.onCancelEdit()
        this.props.onViewListChange(this.getViews().concat(this.state.newViewName))
        //console.log("create ",'dataeats');
      }
    )
  }

  /**
   * Get list of existing views + default
   * @returns {Array}
   */
  getViews = () => {
    return this.props.views.filter((v) => v && v !== 'default').concat('default')
  }

  /**
   * Save edited view list in local state
   * @param newData
   */
  onEditViews = (newData) => {
    let rv: any[] = []
    this.state.editViews.forEach((view, index) => {
      let newRows = newData.filter((nr, id) => id === index)
      if (newRows.length > 0) if (newRows[0].name !== view) rv.push({ old: view, new: newRows[0].name })
    })
    this.setState({
      edited: true,
      renamedViews: rv,
      editViews: newData.map((newRow) => newRow.name)
    })
  }

  /**
   * Shows Table with views
   * @param editState
   * @returns {XML}
   */
  renderManageViews = (editState) => {
    const editable = editState >= editableState.EDITING

    return (
      <Table
        hideHeader
        data={this.state.editViews.map((view, index) => {
          return { id: index, name: view }
        })}
        columns={[{ name: 'name', type: { name: 'string' }, width: 700 }]}
        isEditable={editable}
        inEditMode={editable}
        externalControl
        showAdd={editable}
        showDelete={editable}
        onAdd={() =>
          this.setState({
            edited: true,
            editViews: this.state.editViews.concat('new view')
          })
        }
        onDelete={(index, obj) =>
          this.setState({
            edited: true,
            deletedViews: this.state.deletedViews.concat(obj.name),
            editViews: this.state.editViews.filter((view, ind) => ind !== index)
          })
        }
        onEdit={this.onEditViews}
        onSave={() => {}}
        addDeleteText={{ add: 'Add view', delete: 'Delete view' }}
      />
    )
  }

  /**
   * Show dialog with views editor
   * @returns {XML}
   */
  renderManageViewsWindow() {
    return (
      <EditorDialog
        objectType="view"
        modalTitle="Manage views"
        editContent={this.renderManageViews}
        isVisible
        isEditable={this.state.edited ? editableState.EDITED : editableState.EDITABLE}
        onClose={() => this.setState({ showManageViews: false })}
        onSave={() => {
          this.onCancelEdit()
          this.props.onViewListChange(this.state.editViews, this.state.renamedViews, this.state.deletedViews)
          this.setState({
            renamedViews: [],
            deletedViews: []
          })
        }}
        onCancel={() => {
          this.setState({
            editViews: this.props.views.slice(),
            renamedViews: [],
            deletedViews: []
          })
        }}
        messageText=""
        successText=""
        errorText=""
        hideFullscreenButton
      ></EditorDialog>
    )
  }

  /**
   * Render contents of main window
   * @param renderControls
   * @returns {XML}
   */
  renderInner(renderControls = false) {
    if (this.state.clear) return <div></div>

    return (
      <FilterableContent
        ref={this.filterablecontentRef}
        dataFilters={[{ name: 'search' }, { name: 'tags' }, { name: 'usage' }]}
        data={this.props.datasets.map((ds) => {
          return Object.assign({}, ds, {
            usage: ds.object.usage
          })
        })}
        onChangeFilter={(filters) => {
          window.SQL.filters = filters
          window.SQL.onChangeFilter()
        }}
        title="Chart"
        parentUri={getFullUri(this.props.application)}
      >
        <SQLDesigner
          ref={this.sqldesignerRef}
          application={this.props.application}
          actions={this.props.actions}
          datasets={this.props.datasets}
          subscriptions={this.props.subscriptions}
          onTableMove={this.tableMove.bind(this)}
          onModeChange={this.changeMode}
          mode={this.state.mode}
          isEditing={this.state.isEditing}
          selectedView={this.state.selectedView}
          onManageViews={() =>
            this.setState({
              showManageViews: true,
              edited: false,
              editViews: this.props.views.slice(),
              renamedViews: [],
              deletedViews: []
            })
          }
          isEditingViews={this.state.showManageViews}
        />
      </FilterableContent>
    )
  }

  /**
   * Render footer of full screen/window
   * @returns {XML}
   */
  renderFooter() {
    return (
      <div
        id="bottom_area"
        className={
          (this.state.bottomAreaCollapsed ? 'SQLDesigner__bottomArea__collapse' : '') +
          ' ' +
          (this.state.isEditing ? '' : 'SQLDesigner__bottomArea__view')
        }
      >
        <div
          className={
            'SQLDesigner__collapseButton ' +
            (this.state.bottomAreaCollapsed ? 'SQLDesigner__collapseButton__collapse' : '')
          }
          onClick={() =>
            this.setState({
              bottomAreaCollapsed: !this.state.bottomAreaCollapsed
            })
          }
        >
          <img src={iArrowDownClose} alt="arrow down close icon" />
        </div>
        {this.state.loading ? null : (
          <div className="SQLDesigner__hint">
            <SimpleTooltip title="Hint: Click a dataset to return it to the screen">
              <img className="SQLDesigner__icon" src={iI} alt="icon" />
            </SimpleTooltip>
          </div>
        )}
      </div>
    )
  }

  /**
   * Handle view change
   * @param e
   */
  changeView(e) {
    const view = e.target.value
    this.setState({ selectedView: view }, () => {
      this.onCancelEdit()
    })
  }

  /**
   * Exit edit mode, cancel changes, force re-init
   */
  onCancelEdit() {
    window.savedChartXmlString = ''
    this.setState(
      {
        isEditing: false,
        modifiedDatasets: []
      },
      () => {
        document.getElementById('area')!.innerHTML = ''
        document.getElementById('bottom_area')!.innerHTML = ''
        this.setState({ clear: true }, () => {
          setTimeout(() => {
            this.setState({ clear: false }, () => {
              //this.sqldesignerRef.current.update();
            })
          }, 0)
        })
      }
    )
  }

  /**
   * Save modified datasets from local state, exit edit mode
   */
  save() {
    window.savedChartXmlString = ''
    this.props.onTableMove(this.state.modifiedDatasets, this.state.selectedView)
    this.setState({
      isEditing: false,
      modifiedDatasets: []
    })
    //this.props.onClose();
  }

  renderSelectView() {
    return (
      <div className="SQLDesigner__headerViews">
        <KascodeSelect
          placeholder={this.state.selectedView}
          options={this.getViews().map((view, index) => {
            return { id: index, label: view, value: view }
          })}
          onValuesChange={this.changeView.bind(this)}
        />
      </div>
    )
  }

  renderEditButton() {
    return (
      <div className="ModalWindow__headerEditButton">
        <EditDialogButton
          inEditMode={this.state.isEditing}
          onCancel={this.onCancelEdit.bind(this)}
          onEdit={() => this.setState({ isEditing: true })}
          onSave={this.save.bind(this)}
          saveText="Save"
        />
      </div>
    )
  }

  close() {
    document.body.style.overflow = 'auto'
    this.props.onClose()
  }

  renderChangeModeConfirmation() {
    if (this.state.showChangeModeConfirmation) {
      return (
        <ModalWindow
          className="SQLDesigner__modal SQLDesigner__smallModal"
          isOpen
          header="Change mode"
          footer={
            <div>
              <button className="btn btn_blue" onClick={() => this.setState({ showChangeModeConfirmation: false })}>
                Ok
              </button>
            </div>
          }
          canClose
          onClose={() => this.setState({ showChangeModeConfirmation: false })}
        >
          Please save or cancel your changes before switching to full screen/window mode.
        </ModalWindow>
      )
    }
  }

  renderCloseButton() {
    return (
      <div onClick={this.close.bind(this)}>
        <a className="SQLDesigner__link">Close</a>
      </div>
    )
  }

  render() {
    // FilterableContent here is used only to render filters. actual filtering is executed in SQLDesigner filterTable function

    let manageViews = this.state.showManageViews ? this.renderManageViewsWindow() : null

    // full screen render

    if (this.state.mode === 'fullSize') {
      return (
        <div>
          <div className="SQLDesigner__fullSize SQLDesigner__modal">
            <div className="SQLDesigner__controlRow row">
              <div className="col-xs-2">View:</div>
              <div className="col-xs-10"> {this.renderSelectView()}</div>
            </div>
            <div className="SQLDesigner__controlRowRight row">
              <div className="col-xs-8">{this.renderEditButton()}</div>
              <div className="col-xs-4 SQLDesigner__closeButton">{this.renderCloseButton()}</div>
            </div>
            {this.renderInner()}
            {this.renderFooter()}
            {this.renderChangeModeConfirmation()}
            {manageViews}
          </div>
        </div>
      )
    }

    // window render

    return (
      <div>
        <ModalWindow
          className="SQLDesigner__modal"
          isOpen
          header={this.props.application.identity.name}
          footer={this.renderFooter()}
          onClose={this.close.bind(this)}
          headerContent={
            <span>
              {this.renderSelectView()}
              {this.renderEditButton()}
            </span>
          }
        >
          {this.renderInner()}
          {this.renderChangeModeConfirmation()}
        </ModalWindow>
        {manageViews}
      </div>
    )
  }
}
