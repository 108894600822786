import PropTypes from 'prop-types'

import React, { Component } from 'react'
import logger from '../../helpers/logger'
import { SettingsDialog } from '../SettingDialog/SettingsDialog'
import InputsDialog from './InputsDialog'
import OutputsDialog from './OutputsDialog'
import { MapDialog } from './MapDialogNew'
import { deepCopy, nonCSCompare, editableState, itemState, referenceSort } from '../../helpers/index'

import './ActivityDialog.scss'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'

const STRUCTURE_HEIGHT = 60
const INPUT_HEIGHT = 20
const OUTPUT_HEIGHT = 20
const SETTING_HEIGHT = 20
const MAP_HEIGHT = 20

export class ActivityDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      editingInput: false,
      editingOutput: false,
      editingMap: false,

      datasets: null, // Transformation datasets
      interfaces: null, // Interfaces for operations
      activities: null // Pipeline list of activities.
    })

    logger.info('ActivityDialog', this.state, this.props)
  }

  getObjectType() {
    return 'activity'
  }

  getMinHeight() {
    return 500
  }

  getElementsHeight() {
    // We have in parallel rules and properties
    return {
      transformation: STRUCTURE_HEIGHT,
      operation: STRUCTURE_HEIGHT,
      __group: {
        inputs: INPUT_HEIGHT,
        outputs: OUTPUT_HEIGHT,
        properties: SETTING_HEIGHT
      },
      __line: MAP_HEIGHT
    }
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/Pipeline/datasets/Activity',
      '/organizations/Apdax/systems/DifHub/applications/Pipeline/views/ActivityDialog',
      '/organizations/Apdax/systems/DifHub/applications/Pipeline/datasets/Type'
    )

    logger.info('ActivityDialog:open', this.state, this.props)

    // Lets load common options for edit.
    if (this.props.isEditable > editableState.EDITABLE) {
      this.loadDataOptions()
    }
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  loadDataOptions = () => {
    // Setting objects we can select in dialog
    if (!this.state.datasets) {
      this.loadOptions('datasets', 'dataset', 'transform,location,schedule,policy')
    }

    // Setting objects we can select in dialog
    if (!this.state.interfaces) {
      this.loadOptions('interfaces', 'interface')
    }

    // Pipeline activities
    if (this.state.activities) return

    let activities = this.props.activity
      ? this.props.majorObject.activities.filter((act) => act.identity.name !== this.props.activity.identity.name)
      : this.props.majorObject.activities
    let options = activities
      .map((el, i) => {
        return {
          id: i,
          value: el,
          label: el.identity.name
        }
      })
      .sort(referenceSort)

    this.setState({
      activities: options
    })

    logger.info('ActivityDialog:loadDataOptions', this.state, this.props)
  }

  checkInputErrors(activity) {
    let inputError = ''
    const condition = this.props.majorObject
    if (!activity.identity || !activity.identity.name) {
      inputError += 'Please specify activity name!'
    }
    if (nonCSCompare(activity.type, 'Operation') && (!activity.operation || !activity.operation.reference)) {
      inputError += 'Please specify activity operation interface! '
    }
    if (nonCSCompare(activity.type, 'Operation') && activity.operation && !activity.operation.components) {
      inputError += 'Please specify activity operations! '
    }
    if (condition && activity.identity && activity.identity.name) {
      const checkActivityName = condition.activities.find(
        (e) =>
          nonCSCompare(e.identity.name, activity.identity.name) && !nonCSCompare(e.identity.id, activity.identity.id)
      )
      if (checkActivityName) {
        inputError += 'Element name already exist'
      }
    }
    return inputError
  }

  /**
   * Override the method in child dialogs, to handle changeEditable() event: opening and closing the dialog
   */
  onChangeEditable = (isEditable) => {
    //logger.info("ActivityDialog:onChangeEditable", isEditable, this.props);

    // Lets load common options for edit.
    if (isEditable > editableState.EDITABLE) {
      this.loadDataOptions()
    }
  }

  /**
   * Validate value of changed field
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @return true - change approved
   */
  onValidateChange = (element, field, value, activity, option) => {
    logger.info('ActivityDialog:onValidateChange', { element, field, value, activity }, this.state, this.props)
    if (field === 'type' && value) {
      // For operation we prepare structure to keep info about interface.
      if (nonCSCompare(value, 'operation')) {
        activity['operation'] = { reference: '', subscription: '' }
        if (activity.transformation) delete activity.transformation
      } else if (nonCSCompare(value, 'transformation')) {
        activity['transformation'] = {}
        if (activity.operation) delete activity.operation
      }
    } else if (field === 'operation' && value) {
      if (activity.operation.components) {
        // Components is array but reference make it value.
        activity.operation.components = [activity.operation.components]
      }
    }
    return activity
  }

  onInputsSave = (inputs, closeDialog, onSent) => {
    let activity = deepCopy(this.state.activity)
    activity.inputs = inputs

    if (onSent) onSent(closeDialog)
    //logger.info("ActivityDialog:onInputSave", activity, inputs);

    this.Change(activity)
  }

  onOutputsSave = (outputs, closeDialog, onSent) => {
    let activity = deepCopy(this.state.activity)
    activity.outputs = outputs

    if (onSent) onSent(closeDialog)
    //logger.info("ActivityDialog:onOutputSave", activity, outputs);

    this.Change(activity)
  }

  onMapSave = (outputs, closeDialog, onSent) => {
    let activity = deepCopy(this.state.activity)
    activity.outputs = outputs

    if (onSent) onSent(closeDialog)
    //logger.info("ActivityDialog:onOutputSave", activity, outputs);

    this.Change(activity)
  }

  onPropertiesSave = (properties, closeDialog, onSent) => {
    let activity = deepCopy(this.state.activity)
    activity.properties = properties.map((property) => {
      return Object.assign({}, property, {
        value: typeof property.value === 'object' ? JSON.stringify(property.value) : property.value
      })
    })

    if (onSent) onSent(closeDialog)

    this.Change(activity)
  }

  renderEditor = (editState) => {
    let isEditable = this.props.isEditable > editableState.BROWSABLE ? editState : editableState.BROWSABLE

    let activity = this.state.activity
    let caption = ' of activity ' + (activity.identity ? activity.identity.name : '')
    //logger.info("ActivityDialog:renderEditor", { editState, activity, type }, this.state, this.props);

    return (
      <div className="ActivityDialog" onClick={() => this.onCheckDialogHeight('.ActivityDialog')}>
        {this.renderView(editState, (element) => {
          switch (element) {
            default:
              return null
            case 'transformation':
              return !activity.type || !nonCSCompare(activity.type, 'Transformation') ? <div></div> : null
            case 'operation':
              return !activity.type || !nonCSCompare(activity.type, 'Operation') ? <div></div> : null
          }
        })}

        <div className="ActivityDialog__advancedOptions row">
          <div className="col-xs-6">
            <InputsDialog
              appState={this.props.appState}
              actions={this.props.actions}
              modalTitle={'Edit inputs' + caption}
              buttonTitle={'Inputs'}
              isItems={itemState.VERTICAL}
              sortItems
              isVisible
              isEditable={editState > editableState.EDITING ? editableState.EDITING : editState}
              majorObject={this.props.majorObject}
              inputs={activity.inputs}
              activity={activity}
              activities={this.state.activities}
              datasets={this.state.datasets}
              interfaces={this.state.interfaces}
              onEditableChange={this.changeEditable}
              onClose={() => this.setState({ editingInput: false })}
              onSave={this.onInputsSave}
            />
          </div>
          <div className="col-xs-6">
            <OutputsDialog
              appState={this.props.appState}
              actions={this.props.actions}
              modalTitle={'Edit outputs' + caption}
              buttonTitle={'Outputs'}
              isItems={itemState.VERTICAL}
              sortItems
              isVisible
              isEditable={editState > editableState.EDITING ? editableState.EDITING : editState}
              majorObject={this.props.majorObject}
              outputs={activity.outputs}
              activity={activity}
              activities={this.state.activities}
              datasets={this.state.datasets}
              interfaces={this.state.interfaces}
              onEditableChange={this.changeEditable}
              onClose={() => this.setState({ editingOutput: false })}
              onSave={this.onOutputsSave}
            />
          </div>
          <div className="col-xs-6">
            <SettingsDialog
              appState={this.props.appState}
              actions={this.props.actions}
              buttonTitle={'Settings'}
              isItems={itemState.VERTICAL}
              sortItems
              isVisible
              isEditable={isEditable > editableState.EDITING ? editableState.EDITING : isEditable}
              majorObject={this.props.majorObject}
              settings={activity.properties}
              onEditableChange={this.changeEditable}
              onClose={() => this.setState({ editingSetting: false })}
              onSave={this.onPropertiesSave}
            />
          </div>
        </div>
        <div className="ActivityDialog__map row">
          <div className="col-xs-12">
            <MapDialog
              appState={this.props.appState}
              actions={this.props.actions}
              modalTitle={'Edit map' + caption}
              buttonTitle={'Map'}
              isItems={itemState.VERTICAL}
              sortItems
              isVisible
              isEditable={isEditable > editableState.EDITING ? editableState.EDITING : isEditable}
              majorObject={this.props.majorObject}
              outputs={activity.outputs}
              activity={activity}
              datasets={this.state.datasets || []}
              onEditableChange={this.changeEditable}
              onClose={() => this.setState({ editingMap: false })}
              onSave={this.onMapSave}
            />
          </div>
        </div>
      </div>
    )
  }
}

ActivityDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  activity: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
