import PropTypes from 'prop-types'
import React from 'react'
import { deepCopy, deepMerge } from '../../helpers/index'
import logger from '../../helpers/logger'
import { getFieldMetadata } from '../../resources/lib/metadata'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './FrameDialog.scss'

export class FrameDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      frameType: ['Position', 'Margin', 'Border', 'Padding', 'Content']
    })

    logger.info('FrameDialog', this.state, this.props)
  }

  getObjectType() {
    return 'frame'
  }

  getMinHeight() {
    return 500
  }

  /**
   * Returns initial object for dialog
   */
  getInitial(props) {
    props = props || this.props
    return deepCopy(props[this.getObjectType()]) || {}
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/View/datasets/Frame',
      '/organizations/Apdax/systems/DifHub/applications/View/views/FrameDialog'
    )

    logger.info('FrameDialog:open', this.state, this.props)
  }

  /**
   * Validate value of changed field
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @return true - change approved
   */
  onValidateChange = (element, field, value, frame, option) => {
    logger.info('FrameDialog:onValidateChange', { element, field, value, frame }, this.state, this.props)
    //if (element === 'borderAll' && value) {
    //}
    return frame
  }

  isFrameValid = (type, frame, side) => {
    if (!frame) {
      return false
    }

    //console.log("FrameDialog:isFrameValid", type, frame, side);

    if (side && (frame[side] === null || frame[side] === undefined)) {
      return false
    }

    if (type.toLowerCase() === 'position') {
      if (!side) {
        if (
          (frame.left === null || frame.left === undefined) &&
          (frame.right === null || frame.right === undefined) &&
          (frame.top === null || frame.top === undefined) &&
          (frame.bottom === null || frame.bottom === undefined)
        ) {
          return false
        }
        return true
      }

      if (side === 'left' && frame.left === 0 && frame.right > 0) {
        return false
      }
      if (side === 'right' && frame.right === 0 && frame.left >= 0) {
        return false
      }
      if (side === 'top' && frame.top === 0 && frame.bottom > 0) {
        return false
      }
      if (side === 'bottom' && frame.bottom === 0 && frame.top >= 0) {
        return false
      }
      return true
    }

    if (frame.left === frame.right && frame.top === frame.bottom) {
      return false
    }

    if ((side === 'left' || side === 'right') && frame.left === frame.right) {
      return false
    }
    if ((side === 'top' || side === 'bottom') && frame.top === frame.bottom) {
      return false
    }
    return true
  }

  getFrameField = (type, side) => {
    const dataset = this.state.objectDataset
    const frame = type.toLowerCase()
    logger.info('FrameDialog:getFrameField', { type, side, frame, dataset }, this.state, this.props)

    let oldField = getFieldMetadata(dataset, frame + '.' + side)
    let newField = deepCopy(oldField)
    //logger.info("FrameDialog:getFrameField", { type, side, frame, dataset, oldField, newField }, this.state, this.props);

    // set up right field name.
    if (newField && newField.identity) {
      newField.identity.name = frame + '.' + side
    }
    return newField
  }

  renderFrame = (editState, type) => {
    //logger.info("FrameDialog:renderFrame", editState, type, this.state, this.props);

    return (
      <div>
        <div className="row">
          <div className="FrameDialog_frameLabel">{type + ':'}</div>
          <div className="row">
            <div className="col-xs-2">{this.renderElement(editState, 'left', this.getFrameField(type, 'left'))}</div>
            <div className="col-xs-5">{this.renderElement(editState, 'right', this.getFrameField(type, 'right'))}</div>
            <div className="col-xs-8">{this.renderElement(editState, 'width', this.getFrameField(type, 'width'))}</div>
          </div>
          <div className="row">
            <div className="col-xs-2">{this.renderElement(editState, 'top', this.getFrameField(type, 'top'))}</div>
            <div className="col-xs-5">
              {this.renderElement(editState, 'bottom', this.getFrameField(type, 'bottom'))}
            </div>
            {type === 'Position' ? (
              <div className="col-xs-8">
                {this.renderElement(editState, 'height', this.getFrameField(type, 'height'))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  renderEditor = (editState) => {
    //logger.info("FrameDialog:renderEditor", editState, this.state, this.props);
    let type = this.state.frameType

    return (
      <div className="FrameDialog">
        {type.map((t) => {
          return this.renderFrame(editState, t)
        })}
      </div>
    )
  }

  /**
   * render view of component when window is closed
   * @returns {*}
   */
  renderItems() {
    //console.log("FrameDialog:renderItems", this.state, this.props);
    let type = this.state.frameType

    let frame = this.state.frame
    let merge = this.props.base ? deepMerge(this.props.base, frame) : frame

    return (
      <div className="FrameDialog">
        {type.map((t, idx) => {
          let pos = merge[t.toLowerCase()]
          return (
            <div key={idx}>
              {pos && this.isFrameValid(t, pos) ? (
                <div className="FrameDialog row">
                  <div className="FrameDialog__name col">{t + ':'}</div>
                  <div className="FrameDialog__value col">
                    {(this.isFrameValid(t, pos, 'left') ? 'L:' + pos.left + ' ' : '') +
                      (this.isFrameValid(t, pos, 'right') ? 'R:' + pos.right + ' ' : '') +
                      (this.isFrameValid(t, pos, 'top') ? 'T:' + pos.top + ' ' : '') +
                      (this.isFrameValid(t, pos, 'bottom') ? 'B:' + pos.bottom + ' ' : '') +
                      (pos.width ? 'W:' + pos.width + ' ' : '') +
                      (pos.height ? 'H:' + pos.height + ' ' : '')}
                  </div>
                </div>
              ) : null}
            </div>
          )
        })}
      </div>
    )
  }
}

FrameDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  style: PropTypes.object, // Actual style from current layer
  base: PropTypes.object, // Data of style from base layer. Can't change
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
