import PropTypes from 'prop-types'
import React, { Component } from 'react'
//import ReactTooltip from "react-tooltip";
import { SimpleTooltip } from '../SimpleTooltip/SimpleTooltip'
import { demoRatingByName } from '../../helpers/index'
import '../../styles/ObjectRating/ObjectRating.scss'

const starMapping = {
  UNRATED: '/images/grey-star-2x.png',
  GOOD: '/images/gold-star-2x.png',
  BAD: '/images/bronze-star-2x.png'
}

export class ObjectRating extends Component {
  static calculateRating(rating) {
    if (!rating) return 'UNRATED'
    if (rating.good + rating.bad < 10) {
      return 'UNRATED'
    } else {
      return rating.good / rating.bad < 2 ? 'BAD' : 'GOOD'
    }
  }

  static chooseStar(rating) {
    return starMapping[rating]
  }

  render() {
    let this_rating = demoRatingByName(this.props.name ? this.props.name : '') // this.props.rating
    if (this.props.showNumbers) {
      return (
        <div className="ObjectRating__outer">
          <img
            src={ObjectRating.chooseStar(ObjectRating.calculateRating(this_rating))}
            className="ObjectRating__star"
          />
          <div className="ObjectRating__text">
            <span className="ObjectRating__plus">+</span>
            {this_rating.good} / <span className="ObjectRating__minus">-</span>
            {this_rating.bad}
          </div>
        </div>
      )
    } else {
      return (
        <SimpleTooltip
          className="ObjectRating__tooltip"
          title={
            <span>
              <span className="ObjectRating__plus">+</span> {this_rating.good} /{' '}
              <span className="ObjectRating__minus">-</span> {this_rating.bad}
            </span>
          }
        >
          <div className="ObjectRating">
            <div>
              <img
                src={ObjectRating.chooseStar(ObjectRating.calculateRating(this_rating))}
                className="ObjectRating__star"
              />
            </div>
          </div>
        </SimpleTooltip>
      )
    }
  }
}

ObjectRating.propTypes = {
  rating: PropTypes.object,
  name: PropTypes.string,
  showNumbers: PropTypes.bool
}
