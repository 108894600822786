import PropTypes from 'prop-types'

import React, { Component } from 'react'
import logger from '../../helpers/logger'
import DropZone from 'react-dropzone'
import { SettingsDialog } from '../SettingDialog/SettingsDialog'
import { StyleDialog } from './StyleDialog'
import { FrameDialog } from './FrameDialog'
import { API, fetchDataUrlImmediate } from '../../helpers/api'
import { deepCopy, nonCSCompare, editableState, itemState, getFullUri, checkResourceUrl } from '../../helpers/index'

import './ElementDialog.scss'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'

import iLoad from '../../resources/images/Load-pic-deep-blue-2x.png'
import iLoadDark from '../../resources/images/Load-pic-2x.png'

const STRUCTURE_HEIGHT = 40
const IMAGE_HEIGHT = 400
const STYLE_HEIGHT = 40
const FRAME_HEIGHT = 20
const SETTING_HEIGHT = 20

export class ElementDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      editingStyle: false,
      editingFrame: false,

      imageChanged: false
    })

    logger.info('ElementDialog', this.state, this.props)
  }

  getObjectType() {
    return 'element'
  }

  getMinHeight() {
    return 565
  }

  getElementsHeight() {
    // We have in parallel rules and properties
    return {
      control: STRUCTURE_HEIGHT,
      image: IMAGE_HEIGHT,
      __group: {
        frame: FRAME_HEIGHT,
        style: STYLE_HEIGHT,
        properties: SETTING_HEIGHT
      }
    }
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/View/datasets/Element',
      '/organizations/Apdax/systems/DifHub/applications/View/views/ElementDialog'
    )

    logger.info('ElementDialog:open', this.state, this.props)

    // Lets load common options for edit.
    if (this.props.isEditable > editableState.EDITABLE) {
      this.loadDataOptions()
    }
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  loadDataOptions = () => {
    logger.info('ElementDialog:loadDataOptions', this.state, this.props)
  }

  checkInputErrors(element) {
    let inputError = ''
    const definition = this.props.getActiveDefinition()
    if (!element.identity || !element.identity.name) {
      inputError += 'Please specify element name!'
    } else if (definition && element.identity && element.identity.name) {
      const checkElementName = definition.elements.find(
        (e) => nonCSCompare(e.identity.name, element.identity.name) && !nonCSCompare(e.identity.id, element.identity.id)
      )
      if (checkElementName) {
        inputError += 'Element name already exist'
      }
    }
    return inputError
  }

  /**
   * Implementation of actual save event
   * @param {object} [object] actual object managed by dialog
   * @param {boolean} [closeDialog] Do we need to close dialog
   * @param {func} [onSent] callback after send
   */
  onSave(object, closeDialog, onSent) {
    // Save image if it was loaded.
    if (this.state.imageChanged) {
      this.uploadFile(this.state.imageChanged, () => {}, this.state.imageFileName)
    }

    if (this.props.onSave) {
      this.props.onSave(object, closeDialog, onSent)
      return true // not to do anything here.
    }
  }

  /**
   * Override the method in child dialogs, to handle changeEditable() event: opening and closing the dialog
   */
  onChangeEditable = (isEditable) => {
    //logger.info("ElementDialog:onChangeEditable", isEditable, this.props);

    // Lets load common options for edit.
    if (isEditable > editableState.EDITABLE) {
      this.loadDataOptions()
    }
  }

  addPicture = () => {
    let dialog = this.getDialogRef()
    if (dialog) {
      dialog.refs.dropzone.open()
    }
  }

  deletePicture = () => {
    let element = deepCopy(this.state.element)
    element.image = ''

    //SashaB: do we need delete image file here?
    this.Change(element)
  }

  dropFile(files) {
    // Drop image file into element
    let element = deepCopy(this.state.element)
    let fileName = this.state.element.identity.name || new Date().getTime()
    element.image = API.resources.url() + '?uri=' + getFullUri(this.props.majorObject) + '/' + fileName

    this.setState({
      imageFileName: fileName,
      imageChanged: Object.assign(files[0], {
        preview: URL.createObjectURL(files[0])
      })
    })

    console.log('ElementDialog:dropFile', element, this.state)
    this.Change(element)
  }

  uploadFile(file, done, customFileName) {
    const app = this.props.majorObject
    customFileName = customFileName || file.name
    let form = new FormData()
    form.append('file', file)

    return fetch(checkResourceUrl(API.resources.url() + '?uri=' + getFullUri(app) + '/' + customFileName), {
      method: 'post',
      headers: {},
      body: form
    }).then((data) => {
      if (done) done()
    })
  }

  onStyleSave = (style, closeDialog, onSent) => {
    let element = deepCopy(this.state.element)
    element.style = {
      font: style.font,
      background: style.background,
      border: style.border
    }

    if (onSent) onSent(closeDialog)
    console.log('ElementDialog:onStyleSave', element, style)

    this.Change(element)
  }

  onFrameSave = (frame, closeDialog, onSent) => {
    let element = deepCopy(this.state.element)
    element.frame = {
      position: frame.position,
      margin: frame.margin,
      border: frame.border,
      padding: frame.padding,
      content: frame.content
    }

    if (onSent) onSent(closeDialog)
    console.log('ElementDialog:onFrameSave', element, frame)

    this.Change(element)
  }

  onPropertiesSave = (properties, closeDialog, onSent) => {
    let element = deepCopy(this.state.element)
    element.properties = properties.map((property) => {
      return Object.assign({}, property, {
        value: typeof property.value === 'object' ? JSON.stringify(property.value) : property.value
      })
    })

    if (onSent) onSent(closeDialog)

    this.Change(element)
  }

  renderEditor = (editState) => {
    let isEditable = this.props.isEditable > editableState.BROWSABLE ? editState : editableState.BROWSABLE

    let base = this.props.base
    let element = this.state.element
    let image = element && element.image ? element.image : base && base.image ? base.image : null
    logger.info('ElementDialog:renderEditor', { editState, element, base }, this.state, this.props)

    return (
      <div>
        <div className="ElementDialog">
          {this.renderView(editState, (element) => {
            switch (element) {
              default:
                return null
              case 'name':
                return base ? this.renderElement(editableState.BROWSABLE, element) : null
            }
          })}

          <div className="ElementDialog__block_text clearfix">
            <label className="ElementDialog__label">
              Image: <br />
              {element && element.image ? (
                <div
                  className={'ElementDialog__loadIcon ' + (this.props.dark ? 'ElementDialog__loadIconDark' : '')}
                  onClick={this.deletePicture.bind(this)}
                >
                  <img src={this.props.dark ? iLoadDark : iLoad} />
                  Remove
                </div>
              ) : (
                <div
                  className={'ElementDialog__loadIcon ' + (this.props.dark ? 'ElementDialog__loadIconDark' : '')}
                  onClick={this.addPicture.bind(this)}
                >
                  <img src={this.props.dark ? iLoadDark : iLoad} />
                  Load
                </div>
              )}
            </label>
            <div className="value_right">
              {!image ? (
                'No image'
              ) : this.state.imageChanged ? (
                <img src={this.state.imageChanged.preview} className="ElementDialog__image" />
              ) : (
                <img className="ElementDialog__image" src={fetchDataUrlImmediate(checkResourceUrl(image))} />
              )}

              {editState > editableState.EDITABLE ? (
                <div>
                  <DropZone ref="dropzone" className="ElementDialog__DropZone" onDrop={this.dropFile.bind(this)}>
                    {({ getRootProps, getInputProps, isDragActive }) => (
                      <div {...getRootProps()} className={`dropzone${isDragActive ? ' dropzone--isActive' : ''}`}>
                        <input {...getInputProps()} />
                        {isDragActive ? <p>...</p> : <p></p>}
                      </div>
                    )}
                  </DropZone>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div>
          <div className="ElementDialog__advancedOptions row">
            <div className="col-xs-6">
              <StyleDialog
                appState={this.props.appState}
                actions={this.props.actions}
                buttonTitle={'Style'}
                isItems={itemState.VERTICAL}
                isVisible
                isEditable={isEditable > editableState.EDITING ? editableState.EDITING : isEditable}
                onEditableChange={this.changeEditable}
                onSave={this.onStyleSave}
                style={element && element.style ? element.style : null}
                base={base && base.style ? base.style : null}
                majorObject={this.props.majorObject}
              />
            </div>
            <div className="col-xs-6">
              <FrameDialog
                appState={this.props.appState}
                actions={this.props.actions}
                buttonTitle={'Frame'}
                isItems={itemState.VERTICAL}
                isVisible
                isEditable={isEditable > editableState.EDITING ? editableState.EDITING : isEditable}
                onEditableChange={this.changeEditable}
                onSave={this.onFrameSave}
                frame={element && element.frame ? element.frame : null}
                base={base && base.frame ? base.frame : null}
                majorObject={this.props.majorObject}
              />
            </div>
            <div className="col-xs-6">
              <SettingsDialog
                appState={this.props.appState}
                actions={this.props.actions}
                buttonTitle={'Settings'}
                isItems={itemState.VERTICAL}
                sortItems
                isVisible
                isEditable={isEditable > editableState.EDITING ? editableState.EDITING : isEditable}
                majorObject={this.props.majorObject}
                settings={element.properties}
                base={base && base.properties ? base.properties : null}
                onEditableChange={this.changeEditable}
                onClose={() => this.setState({ editingSetting: false })}
                onSave={this.onPropertiesSave}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ElementDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  element: PropTypes.object, // Actual element from current layer
  base: PropTypes.object, // Data of element from base layer. Can't change
  locale: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
