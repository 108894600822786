import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { columnsToType, getFullUri } from '../../helpers'
import { FilterableContent } from '../FilterableContent/FilterableContent'
import { NestedTable } from '../NewTable/NestedTable'

export class InstanceViewer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showSearch: false, // Search filter
      filterMapped: false // Mapped filter
    }
  }

  toggleSearchVisible = () => {
    this.setState((prevState) => ({
      ...prevState,
      showSearch: !prevState.showSearch
    }))

    if (this.props.onTableExpand) {
      this.props.onTableExpand()
    }
  }

  toggleFilterMapped = () => {
    this.setState((prevState) => ({
      ...prevState,
      filterMapped: !prevState.filterMapped
    }))

    if (this.props.onTableExpand) {
      this.props.onTableExpand()
    }
  }

  onTableExpand = () => {
    if (this.props.onTableExpand) {
      this.props.onTableExpand()
    }
  }

  onItemScroll = () => {
    if (this.props.onItemScroll) {
      this.props.onItemScroll()
    }
  }

  onItemClick = (id, instance, topology, unitName, type) => {
    // logger.log("InstanceViewer::onInstanceClick", { id, instance, topology, unitName, type });

    if (this.props.onItemClick) {
      const selectedItem = {
        instance: instance,
        topology: topology,
        unit: unitName
      }

      this.props.onItemClick(id, type, selectedItem)
    }
  }

  render() {
    const { instance, topology, selectedItemId, environment } = this.props
    const { filterMapped, showSearch } = this.state

    // divId is used to process field clicks
    const divId = 'topology_' + topology.identity.name + '_instance_' + instance.identity.name

    const units =
      typeof instance.units === 'string' ? instance.units.split(',').map((unit) => unit.trim()) : instance.units

    const data = units.map((unitName) => {
      const myId = divId + '_unit_' + unitName
      return {
        name: (
          <div
            id={myId}
            className={myId === selectedItemId ? 'selectedItem' : ''}
            onClick={() => this.onItemClick(myId, instance, topology, unitName, 'unit')}
          >
            {unitName}
          </div>
        )
      }
    })

    return (
      <div id={divId} className={'table'} onScroll={() => this.onItemScroll()}>
        <table>
          <thead>
            <tr>
              <td className="title" colSpan="2" style={{ backgroundColor: 'rgb(4, 193, 180)' }}>
                <div className="ObjectPicture" style={{ color: 'transparent' }}>
                  x
                </div>
                <span className="table_header">{instance.identity.name}</span>
                <div
                  className={'EnvironmentChart__search ' + (showSearch ? 'EnvironmentChart__search__active' : '')}
                  onClick={this.toggleSearchVisible}
                />
                <div
                  className={'EnvironmentChart__filter ' + (filterMapped ? 'EnvironmentChart__filter__active' : '')}
                  onClick={this.toggleFilterMapped}
                />
              </td>
            </tr>
          </thead>
        </table>
        <FilterableContent
          data={data}
          dataFilters={showSearch ? [{ name: 'search', width: 389 }] : []}
          parentUri={getFullUri(environment) + '/Chart/instances/' + instance.identity.name}
          onChangeFilter={() => {
            setTimeout(() => {
              this.forceUpdate()
            }, 100)
          }}
          noLocalStorage
        >
          <NestedTable
            columns={[
              {
                displayName: ' ',
                name: 'expandButton',
                type: { name: 'data' },
                frozen: true,
                width: 30
              },
              {
                displayName: ' ',
                name: 'name',
                type: columnsToType.getType('data'),
                width: 411 - 30 - 80 - 50
              },
              {
                displayName: ' ',
                name: 'type',
                type: columnsToType.getType('string'),
                width: 130
              }
            ]}
            onExpand={() => this.onTableExpand()}
          />
        </FilterableContent>
      </div>
    )
  }
}

InstanceViewer.propTypes = {
  editState: PropTypes.number,
  instance: PropTypes.object,
  reference: PropTypes.string,
  topology: PropTypes.object,
  selectedItemId: PropTypes.string,
  environment: PropTypes.object,
  onItemClick: PropTypes.func,
  onTableExpand: PropTypes.func,
  onItemScroll: PropTypes.func
}
