import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ModalWindow } from '../ModalWindow/ModalWindow'

import './TabbedModalWindow.scss'

export class TabbedModalWindow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabIndex: 0
    }
  }

  click(index) {
    return () => {
      this.setState({ activeTabIndex: index }, () => (this.props.onTabChange ? this.props.onTabChange(index) : null))
    }
  }

  selectTab(index) {
    this.setState({
      activeTabIndex: index
    })
  }

  renderTabs(header) {
    return (
      <div>
        {header}
        <div className="TabbedModalWindow__tabs">
          {this.props.tabs.map((tab, index) => (
            <div
              className={
                'TabbedModalWindow__tab ' +
                (this.state.activeTabIndex === index ? 'TabbedModalWindow__tab__active' : '')
              }
              onClick={this.click(index).bind(this)}
              key={index}
            >
              {tab}
            </div>
          ))}
        </div>
      </div>
    )
  }

  render() {
    return (
      <ModalWindow
        isOpen={this.props.isOpen}
        header={this.renderTabs(this.props.header)}
        upper={!this.props.upper ? null : this.props.upper[this.state.activeTabIndex]}
        footer={!this.props.footer ? null : this.props.footer[this.state.activeTabIndex]}
        onClose={this.props.onClose}
        canClose={this.props.canClose}
        className={'TabbedModalWindow ' + this.props.className}
        defaultHeight={this.props.defaultHeight}
      >
        {this.props.content[this.state.activeTabIndex]}
      </ModalWindow>
    )
  }
}

TabbedModalWindow.propTypes = {
  onTabChange: PropTypes.func,
  tabs: PropTypes.array,
  isOpen: PropTypes.bool,
  header: PropTypes.string,
  upper: PropTypes.object,
  footer: PropTypes.object,
  onClose: PropTypes.func,
  canClose: PropTypes.bool,
  className: PropTypes.string,
  defaultHeight: PropTypes.string,
  content: PropTypes.object
}
