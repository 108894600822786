/**
 * Created by mailf on 08.06.2016.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { columnsToType, editableState, nonCSCompare } from '../../helpers/index'
import { EditorListBase } from '../EditorDialog/EditorListBase'
import { SettingsDialog } from '../SettingDialog/SettingsDialog'
import './LayoutInstanceList.scss'
import { getTailButtonLabel } from '../../helpers/helperComponents'

export class LayoutInstanceList extends EditorListBase {
  /**
   * Save Item in layout
   * @param {object} obj - item to save
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   * @return
   */
  saveItem = (obj, item, closeDialog, onSent) => {
    let objs = this.props.items || []
    item.properties = obj
    console.log('LayoutInstanceList:saveItem', { obj, item, objs }, this.state, this.props)

    let items = objs.filter((tobj) => !nonCSCompare(tobj.layoutName, item.layoutName))
    items.push(item)
    if (this.props.onSave) {
      this.props.onSave(items, closeDialog, onSent)
    }
  }

  /**
   * Return filters requered for item list
   */
  getFilters = () => {
    return [
      { name: '', width: 40 },
      { name: 'search', width: 260 }
    ]
  }

  /**
   * Return columns requered for item list
   */
  getColumns = () => {
    return [
      {
        name: 'name',
        displayName: 'Layout Name',
        type: columnsToType.getType('minorObjectName'),
        width: 240 - 24 + 28,
        onCellClick: (value) => {
          this.onViewItem(value)
        }
      },
      {
        name: 'platform',
        displayName: 'Platform',
        type: columnsToType.getType('string'),
        frozen: true,
        width: 103
      },
      {
        name: 'unit',
        displayName: 'Unit',
        type: columnsToType.getType('string'),
        frozen: true,
        width: 193
      },
      {
        name: 'instance',
        displayName: 'Instance',
        type: columnsToType.getType('string'),
        frozen: true,
        width: 153 + 172
      }
    ]
  }

  /**
   * Return item data to render in table
   * @param {object} obj - item to render
   * @param {object} base - item from base layer to render
   * @param {number} editState - edit state on dialog
   */
  getItem = (obj, base, editState) => {
    let currObj = obj ? obj : base
    if (!currObj) {
      return {}
    }

    //logger.info("LatoutInstanceList:getItem", this.getItemName(currObj), { obj, base, currObj }, this.state, this.props);

    // Prepare object data
    return Object.assign({}, currObj, {
      name: {
        name: currObj.layoutName,
        objectType: this.props.type,
        objectState: obj === null
      },
      platform: currObj.platform,
      unit: currObj.unit,
      instance: currObj.reference
    })
  }

  /**
   * Define rendering of tail buttons
   * @param {number} editState - edit state on dialog
   * @return - array of rendered buttons
   */
  getTailButtons(editState) {
    const canAdd = editState > editableState.EDITABLE
    if (!canAdd) {
      return [
        {
          label: getTailButtonLabel('View'),
          onClick: (obj, index) => this.onViewItem(obj, index)
        },
        { label: '', onClick: (obj, index) => this.onViewItem(obj, index) }
      ]
    }

    return (obj) => {
      let buttons = []
      //logger.info("TagList:getTailButtons", obj, this.props, this.state);
      if (!obj || !obj._item) {
        return buttons
      }

      buttons.push({
        label: getTailButtonLabel('Edit'),
        onClick: (obj, index) => this.onEditItem(obj, index)
      })

      return buttons
    }
  }

  /**
   * Define rendering of top buttons
   * @param {number} editState - edit state on dialog
   * @return - array of rendered buttons
   */
  getTopButtons(editState) {
    return []
  }

  /**
   * Define rendering of bottom buttons
   * @param {number} editState - edit state on dialog
   * @return - array of rendered buttons
   */
  getBottomButtons(editState) {
    return []
  }

  /**
   * Render dialog for item entry
   */
  renderItemDialog(editState, item, base, isEditable) {
    //logger.info("LayoutInstanceList:renderItemDialog", editState, item, base, isEditable, this.props, this.state);

    return (
      <SettingsDialog
        sortItems
        isVisible
        isEditable={isEditable > editableState.EDITING ? editableState.EDITING : isEditable}
        settings={item.properties}
        majorObject={this.props.majorObject}
        onEditableChange={this.changeEditable}
        onClose={this.closeItem}
        onSave={(obj, closeDialog, onSent) => this.saveItem(obj, item, closeDialog, onSent)}
      />
    )
  }
}

LayoutInstanceList.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isEditable: PropTypes.number, // State of editing in dialog
  majorObject: PropTypes.object.isRequired, // Major object we work against
  type: PropTypes.string.isRequired, // Type of object
  items: PropTypes.object.isRequired, // Resource object array
  base: PropTypes.object // Resource object array
}
