import React, { Component } from 'react'
import { API } from '../../helpers/api'
import { AdaptiveImage } from '../AdaptiveImage/AdaptiveImage'
import { Footer } from '../Footer/Footer'
import { HeaderOld } from '../Header/HeaderOld'
import './style.scss'

/**
 * Local auth page for no-auth mode (work without Identity Service)
 * Allows login without password
 * This mode is activated in config.js by setting AUTH_URL to "disabled"
 * Setting window.authUserId in config.js is required
 */

export class AuthPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      login: '',
      error: '',
      org: '',
      organizations: []
    }
  }

  componentDidMount() {
    if (window.AUTH_URL_LOCAL === 'true') {
      this.props.history.push('/authlocal')
      return
    }

    setTimeout(() => {
      let prevId = localStorage['currentUserId']
      console.log('AuthPage::componentDidMount', window.authUserId)
      if (!window.authUserId) alert('window.authUserId is not specified in config.js!')

      if (window.authOrganization) {
        this.setState({
          org: window.authOrganization
        })
      } else {
        localStorage['currentUserId'] = window.authUserId

        API.organizations.get().then(
          (orgs) => {
            this.setState({
              organizations: orgs
            })
            localStorage['currentUserId'] = prevId
          },
          (error) => {
            this.setState({
              error: 'Cannot connect to backend service'
            })
          }
        )
      }
    }, 100)
  }

  login = () => {
    if (this.state.login) {
      // use hardcoded user id to make the first request
      // it is needed to get user's id by their login
      if (!window.authUserId) alert('window.authUserId is not specified in config.js!')
      localStorage['currentUserId'] = window.authUserId

      API.organizations(this.state.org)
        .users(this.state.login)
        .get()
        .then(
          (user) => {
            console.log('received user', user)

            localStorage['currentUserId'] = user.user.id
            localStorage['currentUserToken'] = user.user.id
            localStorage['currentUserIdToken'] = user.user.id

            this.props.history.push(localStorage['loginReturnUrl'] ? localStorage['loginReturnUrl'] : '/select')
            localStorage['loginReturnUrl'] = ''
          },
          (error) => {
            console.error('cant get user', error)
            if (error.status === 404) {
              this.setState({
                error: 'Incorrect login: ' + this.state.login
              })
            } else if (error.status === 0) {
              this.setState({
                error: 'Cannot connect to backend'
              })
            } else {
              if (error.response) {
                this.setState({
                  error: 'Unknown error: ' + error.response
                })
              } else {
                this.setState({
                  error: 'Unknown error: ' + JSON.stringify(error)
                })
              }
            }
          }
        )
    }
  }

  register = () => {
    if (this.state.login) {
      // use hardcoded user id to make the first request
      // it is needed to get user's id by their login
      if (!window.authUserId) alert('window.authUserId is not specified in config.js!')
      localStorage['currentUserId'] = window.authUserId

      API.register
        .post({
          name: this.state.login,
          description: window.authOrganizationId
        })
        .then(
          (user) => {
            console.log('received user', user)

            localStorage['currentUserId'] = user.id
            localStorage['currentUserToken'] = user.id
            localStorage['currentUserIdToken'] = user.id

            this.props.history.push(localStorage['loginReturnUrl'] ? localStorage['loginReturnUrl'] : '/select')
            localStorage['loginReturnUrl'] = ''
          },
          (error) => {
            console.error('cant get user', error)
            this.setState({
              error: 'Incorrect login or organization'
            })
          }
        )
    }
  }

  render() {
    //<button className="LoginPage__button" type="button" onClick={this.register}>Register</button>

    return (
      <div>
        <HeaderOld
          title="DataHub"
          userState={this.props.userState}
          appState={this.props.appState}
          actions={this.props.actions}
        />

        <div className="AuthPage ">
          <div className="">
            <div className="row">
              <div className="col-xs-4"></div>
              <div className="col-xs-4">
                <div className="LoginPage__root ProfileView Dialog">
                  <h1 className="Profile__header">
                    <AdaptiveImage src={'logo-main'} class="LoginPage__Logo" />
                  </h1>

                  {window.authOrganization ? (
                    ''
                  ) : (
                    <div>
                      Select your organization:{' '}
                      <select onChange={(e) => this.setState({ org: e.target.value })}>
                        {this.state.organizations.map((org, index) => (
                          <option key={index} value={org.identity.name}>
                            {org.identity.name}
                          </option>
                        ))}
                      </select>
                      <br />
                    </div>
                  )}
                  <div className="row">
                    <div className="col-xs-12">
                      <input
                        autoFocus
                        className="LoginPage__textInput"
                        placeholder="Username"
                        type="text"
                        defaultValue=""
                        onChange={(e) => this.setState({ login: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <button className="LoginPage__button" type="button" onClick={this.login}>
                        Sign in
                      </button>
                    </div>
                  </div>
                  <div className="LoginPage__error">{this.state.error}</div>
                </div>
              </div>
              <div className="col-xs-4"></div>
            </div>
          </div>
        </div>

        <div className="auth_footer"> </div>
        <Footer />
      </div>
    )
  }
}
