import React, { Component } from 'react'
import { datasetToObjectArray } from '../../helpers'
import md from '../../helpers/md'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { KascodeSelect } from '../KascodeSelect/KascodeSelect'

const defaultFontList = [
  'Andale Mono',
  'Arial',
  'Arial Black',
  'Avant Garde',
  'Bookman',
  'Century Schoolbook',
  'Comic Sans MS',
  'Courier',
  'Courier New',
  'Garamond',
  'Georgia',
  'Helvetica',
  'Impact',
  'Palatino',
  'Tahoma',
  'Times',
  'Times New Roman',
  'Verdana'
]

export default class FontEditor extends Component {
  render() {
    const { data, props } = this.props

    let list = defaultFontList.slice()
    let customFont = false
    if (data && data.name && list.indexOf(data.name) === -1) {
      list.push(data.name)
      customFont = true
    }

    return (
      <div className="row">
        <div className="col-xs-12">
          <label className={'EditableEntity_font_label' + (props.className ? ' ' + props.className : '')}>
            Font name:{' '}
          </label>
          <div className={'EditableEntity_font_value_right' + (props.className ? ' ' + props.className : '')}>
            {customFont ? (
              <input
                type="text"
                value={data.name}
                placeholder="Type font name"
                onChange={(e) => {
                  props.onChange({
                    target: {
                      value: Object.assign({}, data || {}, {
                        name: e.target.value
                      })
                    }
                  })
                }}
              />
            ) : (
              <KascodeSelect
                options={list
                  .map((font, id) => {
                    return { label: font, value: font, id: id }
                  })
                  .concat({
                    label: 'Custom font',
                    value: 'Custom font',
                    id: 1000
                  })}
                placeholder={data && data.name ? data.name : 'Choose or type font'}
                onValuesChange={(e) => {
                  props.onChange({
                    target: {
                      value: Object.assign({}, data || {}, {
                        name: e.target.value
                      })
                    }
                  })
                }}
                onEditText={(text) => {
                  props.onChange({
                    target: {
                      value: Object.assign({}, data || {}, { name: text })
                    }
                  })
                }}
                onBlur={props.onBlur}
                hideCheckBox
              />
            )}
          </div>
        </div>
        <div className="col-xs-12">
          <label className={'EditableEntity_font_label' + (props.className ? ' ' + props.className : '')}>
            Style:{' '}
          </label>
          <div className={'EditableEntity_font_value_right' + (props.className ? ' ' + props.className : '')}>
            <KascodeSelect
              options={datasetToObjectArray(
                md.repo.metadata.apdax.systems.difhub.applications.view.datasets.fontstyle
              ).map((style) => {
                return {
                  id: style.Code,
                  label: style.Style,
                  value: style.Style
                }
              })}
              placeholder={data && data.style ? data.style : 'Choose font style'}
              onValuesChange={(e) => {
                props.onChange({
                  target: {
                    value: Object.assign({}, data || {}, {
                      style: e.target.value
                    })
                  }
                })
              }}
              onBlur={props.onBlur}
              hideCheckBox
            />
          </div>
        </div>
        <div className="col-xs-12">
          <label className={'EditableEntity_color_label' + (props.className ? ' ' + props.className : '')}>
            Color:{' '}
          </label>
          <div className={'EditableEntity_color_value_right' + (props.className ? ' ' + props.className : '')}>
            <EditableEntity
              data={data ? data.color : ''}
              dataType={{ name: 'color' }}
              dataProps={{
                onChange: (e) => {
                  props.onChange({
                    target: {
                      value: Object.assign({}, data || {}, {
                        color: e.target.value
                      })
                    }
                  })
                }
              }}
              isEditable
              inEditMode
            />
          </div>
        </div>
        <div className="col-xs-12">
          <label className={'EditableEntity_font_label' + (props.className ? ' ' + props.className : '')}>
            Weight:{' '}
          </label>
          <div className={'EditableEntity_font_value_right' + (props.className ? ' ' + props.className : '')}>
            <EditableEntity
              data={data ? data.weight : ''}
              dataType={{ name: 'integer' }}
              dataProps={{
                onChange: (e) => {
                  props.onChange({
                    target: {
                      value: Object.assign({}, data || {}, {
                        weight: e.target.value
                      })
                    }
                  })
                }
              }}
              isEditable
              inEditMode
            />
          </div>
        </div>
        <div className="col-xs-12">
          <label className={'EditableEntity_font_label' + (props.className ? ' ' + props.className : '')}>Size: </label>
          <div className={'EditableEntity_font_value_right' + (props.className ? ' ' + props.className : '')}>
            <EditableEntity
              data={data ? data.size : ''}
              dataType={{ name: 'integer' }}
              dataProps={{
                onChange: (e) => {
                  props.onChange({
                    target: {
                      value: Object.assign({}, data || {}, {
                        size: e.target.value
                      })
                    }
                  })
                }
              }}
              isEditable
              inEditMode
            />
          </div>
        </div>
      </div>
    )
  }
}
