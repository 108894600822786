/**
 * Created by
 * Created by sashaberger on 01.10.2017.
 *
 * Window for finilization of object version and cration of new version draft.
 *
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { API, getObjectNew } from '../../helpers/api'
import { editableState, getFullUri, getVersionOptions, nonCSCompare } from '../../helpers/index'
import { getJsonSpecFields, getJsonSpecRecords } from '../../helpers/md'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import ObjectSearchMultistep from '../ObjectSearch/ObjectSearchMultistep'
import './FinalizeWindow.scss'

// Disable loging
//console.log = function() {}

// const messageActions = getJsonSpecRecords('messageAction');
// const messageActionFields = getJsonSpecFields('messageAction');

const messageActions = getJsonSpecRecords('messageAction')
const messageActionFields = getJsonSpecFields('messageAction')

export class FinalizeWindow extends Component {
  constructor(props) {
    super(props)

    //console.log("FinalizeWindow:constractor", props);

    let actionName = ''
    if (this.props.object.type !== undefined) {
      switch (this.props.object.type) {
        case 'dataset':
          actionName = 'FinalizeVersion'
          break
        case 'interface':
          actionName = 'FinalizeVersion'
          break
        case 'pipeline':
          actionName = 'FinalizeVersion'
          break
        case 'view':
          actionName = 'FinalizeVersion'
          break
        case 'publication':
          actionName = 'FinalizePublication'
          break
        case 'subscription':
          actionName = 'FinalizeSubscription'
          break
        case 'topology':
          actionName = 'FinalizeVersion'
          break
        case 'deployment':
          actionName = 'FinalizeVersion'
          break
        case 'environment':
          actionName = 'FinalizeVersion'
          break
        case 'settings':
          actionName = 'FinalizeVersion'
          break
        default:
          break
      }
    }

    let messageAction = this.getMessageAction(actionName)

    this.state = {
      messageAction: messageAction,
      selectedOrganization: null,
      isSelectingIssue: false,
      selectedIssue: null,
      subjectText: '',
      selectedObjectPath: this.props.object ? this.props.object.object.parent.name : '',

      change: null, // Method to report or cancel modification.

      height: this.InitHeight(),

      version: this.props.version,
      versions:
        this.props.versions && this.props.versions.length > 1 ? getVersionOptions(this.props.versions[1].version) : null
    }
  }

  /**
   * Get current action for execution
   * @param action to find
   * @return Action array of values
   */
  getMessageAction(action) {
    //console.log("FinalizeWindow:getMessageAction", action, messageActions);

    let messageActionIndex = messageActions.findIndex((item) => nonCSCompare(action, item.values[0]))
    if (messageActionIndex > -1) {
      return messageActions[messageActionIndex]
    }
    return {}
  }

  /**
   * Get current action value
   * @param name of field we need from current action
   * @param action - action value of which to get
   * @return value
   */
  getMessageActionValue(name, action = this.state.messageAction) {
    const nameIndex = messageActionFields.findIndex((field) => nonCSCompare(field.identity.name, name))
    //console.log("FinalizeWindow:getMessageActionValue", name, nameIndex, action);

    return nameIndex > -1 && action.values ? action.values[nameIndex] : ''
  }

  /**
   * Init dialog height
   */
  InitHeight = () => {
    //console.log("FinalizeWindow:InitHeight", props);

    let height = 681

    return height
  }

  /**
   * Execute activity when dialog closing
   * @return
   */
  onClose = () => {
    if (this.props.onClose) this.props.onClose()
  }

  /**
   * Execute edit in the dialog
   * @param
   * @return
   */
  onEdit = (change) => {
    //console.log("FinalizeWindow:onEdit", this.state.isEditable);

    this.setState({
      change: change
    })
  }

  /**
   * Execute activity when message send to service
   * @param close - close dialog or not
   * @param error - return from service if any.
   * @return
   */
  onSent = (close, error) => {
    //console.log("FinalizeWindow:onSent", close, error);

    // Report complete of save with error or not
    if (this.state.change) {
      this.state.change(error)
    }

    if (close && !error) {
      setTimeout(() => {
        this.onClose()
      }, 1000)
    }
  }

  /**
   * Execute activity when save button pushed in dialog
   * @param close - close dialog or not
   * @return
   */
  onSave = (close) => {
    let inputError = ''
    if ((!this.state.subjectText || this.state.subjectText.length === 0) && !this.state.selectedIssue) {
      inputError += 'Please type issue subject or select message issue!'
    }
    if (!this.state.messageText || this.state.messageText.length === 0) {
      inputError += 'Please type message to architect with reson for approval!'
    }
    //console.log("FinalizeDialog:onSave", close, inputError);
    if (inputError.length > 0) {
      if (this.state.change) {
        this.state.change(true, '', '', inputError)
      }
      return true
    }

    if (this.state.change) {
      this.state.change(true, 'Data send to the service...', '', '')
    }

    let info = this.getMessageActionValue('Info')

    //console.log("FinalizeWindow:onFinalize", this.state.subjectText, this.state.selectedIssue, this.state.messageText);
    this.props.onFinalize(
      {
        subject: this.state.selectedIssue ? '' : this.state.subjectText,
        description: info,
        issue: this.state.selectedIssue,
        message: this.state.messageText
      },
      this.state.version,
      close,
      this.onSent.bind(this)
    )

    // Delay to close on save.
    return true
  }

  /**
   * Cancel editing in the dialog
   * @param close - cancel execution and close dialog.
   * @return
   */
  onCancel = (close) => {
    //console.log("FinalizeWindow:onCancel", close);

    // Cancel save mode
    if (this.state.change) this.state.change(false)

    this.setState({
      selectedOrganization: null,
      isSelectingIssue: false,
      selectedIssue: null,
      subjectText: '',
      selectedObjectPath: this.props.object ? this.props.object.object.parent.name : '',
      change: null,
      height: this.InitHeight()
    })
  }

  onSelect = (obj, objectType) => {
    //console.log("FinalizeWindow:onSelect", obj, objectType, getFullUri(obj));
    if (nonCSCompare(objectType, 'issues') && obj.identity) {
      // Indicate save mode
      if (this.state.change) this.state.change(true, '', '', '')

      this.setState({
        subjectText: obj.identity.name + ': ' + obj.identity.description,
        selectedIssue: obj,
        selectedObjectPath: getFullUri(obj),
        height: this.InitHeight(),
        isSelectingIssue: !this.state.isSelectingIssue
      })
    } else {
      this.setState({
        subjectText: '',
        selectedIssue: null,
        selectedObjectPath: getFullUri(obj)
      })
    }
    return true
  }

  /**
   * Adjust size of scrall area in dialog
   * @param width - increase or reduction in control width.
   * @param height - increase or reduction in control height.
   * @return
   */
  onAdjust = (width, height) => {
    this.setState((prevState) => {
      //console.log("FinalizeWindow:onAdjust", width, height, prevState.width, prevState.height, source);
      return { height: prevState.height + height }
    })
  }

  subjectChange(e) {
    let data = e.target.value
    //console.log("FinalizeWindow:subjectChange", e);

    // Indicate save mode
    if (this.state.change && data.length > 0) this.state.change(true, '', '', '')

    this.setState({
      subjectText: data
    })
  }

  editMessage(e) {
    let data = e.target.value
    //console.log("FinalizeWindow:editMessage", e);

    // Indicate save mode
    if (this.state.change && data.length > 0) this.state.change(true, '', '', '')

    this.setState({
      messageText: data
    })
  }

  toggleSelectIssue = () => {
    //console.log("FinalizeWindow:toggleSelectIssue", this.state.selectedOrganization, this.state.isSelectingIssue, this.state.selectedIssue, this.state.messageText);
    this.setState({ isSelectingIssue: !this.state.isSelectingIssue }, () => {
      if (this.state.isSelectingIssue) {
        if (!this.state.selectedOrganization) {
          // We need organization to work with
          const orgName = this.props.organization
          getObjectNew(API.organizations(orgName), 'organization', this.props.actions).then((org) => {
            //console.log("FinalizeWindow:toggleSelectIssue:ORG", orgName, org);
            this.setState({
              subjectText: '',
              selectedIssue: null,
              selectedObjectPath: this.props.object ? this.props.object.object.parent.name : '',
              selectedOrganization: org
            })
          })
        } else {
          this.setState({
            subjectText: '',
            selectedIssue: null,
            selectedObjectPath: this.props.object ? this.props.object.object.parent.name : ''
          })
        }
      } else {
        this.setState({
          subjectText: '',
          selectedIssue: null,
          selectedObjectPath: this.props.object ? this.props.object.object.parent.name : ''
        })
      }
    })
  }

  onVersionChange(version) {
    //console.log("DraftWindow:onVersionChange", version, this.state.isVersionSelecting);

    this.setState({
      version: version
    })
  }

  /**
   * Render scrollable area of dialog
   * @return
   */
  renderData = (editState) => {
    let caption = this.getMessageActionValue('Caption')
    let button = caption ? caption.split(' ')[0] : ''
    let info = this.getMessageActionValue('Info')

    //console.log("FinalizeWindow:renderData", this.state, this.props, editState);
    return (
      <div className="FinalizeWindow">
        <div className="row FinalizeWindow__subjectRow">
          <div className="col-xs-3">
            <button className="btn btn_blue FinalizeWindow__issue" onClick={this.toggleSelectIssue}>
              Select existing issue
            </button>
          </div>
          <div className="col-xs-9">
            {this.state.isSelectingIssue && this.state.selectedOrganization ? (
              <div className="FinalizeWindow__subjectText">{this.state.subjectText}</div>
            ) : (
              <div className="FinalizeWindow__subjectInput">
                <EditableEntity
                  data={this.state.subjectText}
                  dataType={{ name: 'string' }}
                  dataProps={{
                    placeholder: 'Type subject of the new issue here',
                    onChange: this.subjectChange.bind(this)
                  }}
                  isEditable
                  inEditMode={editState > editableState.EDITABLE}
                />
              </div>
            )}
          </div>
        </div>
        {this.state.isSelectingIssue && this.state.selectedOrganization ? (
          <div className="FinalizeWindow__issueSelectDiv">
            Select issue: <br />
            <ObjectSearchMultistep
              actions={this.props.actions}
              parentPath={this.state.selectedObjectPath}
              parentObject={this.state.selectedOrganization}
              parentObjectType="organizations"
              targetType="issues"
              childrenByType={{
                organizations: ['systems', 'issues'],
                systems: ['applications', 'issues'],
                applications: ['datasets', 'interfaces', 'publications', 'subscriptions', 'issues'],
                datasets: 'issues',
                interfaces: 'issues',
                publications: 'issues',
                subscriptions: 'issues'
              }}
              onSelect={this.onSelect}
              onAdjust={this.onAdjust}
            />
          </div>
        ) : null}
        <div className="FinalizeWindow__info">{info}</div>
        <div className="FinalizeWindow__message FinalizeWindow__message__multiLine  clearfix">
          <EditableEntity
            className={'FinalizeWindow__message'}
            data={this.state.messageText}
            dataType={{ name: 'text' }}
            dataProps={{
              placeholder: 'Type message text',
              onChange: this.editMessage.bind(this)
            }}
            isEditable
            inEditMode={editState > editableState.EDITABLE}
          />
        </div>
        {this.state.versions ? (
          <div>
            <div className="FinalizeWindow__version">Adjust version before finalizing:</div>
            <div className="DraftWindow__text">
              <div className="row ">
                {this.state.versions.map((version, i) => (
                  <div className="col-xs-4" key={i}>
                    <div className="DraftWindow__name">{version.name + ':'}</div>
                    <input
                      type="radio"
                      className="Input_radio"
                      id={'version_' + version.label}
                      checked={this.state.version === version.label}
                      onClick={() => this.onVersionChange(version.label)}
                    />
                    <label htmlFor={'version_' + version.label}>{version.label}</label>
                    <div className="DraftWindow__description">{version.description}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  /**
   *
   * Render function of dialog
   * @return
   */
  render() {
    let caption = this.getMessageActionValue('Caption')
    let button = caption ? caption.split(' ')[0] : ''
    let title =
      button +
      ' ' +
      this.props.object.type +
      ' "' +
      this.props.object.identity.name +
      '" version: ' +
      this.state.version

    //console.log("FinalizeWindow:render", this.props, this.state, title, button);
    return (
      <div className="FinalizeWindow">
        <EditorDialog
          objectType="data"
          modalTitle={title}
          confirmText={button}
          editContent={this.renderData.bind(this)}
          editHeight={this.state.height}
          isVisible={this.props.isVisible}
          isEditable={this.props.isEditable}
          onClose={this.onClose.bind(this)}
          onSave={this.onSave.bind(this)}
          onEdit={this.onEdit.bind(this)}
          onCancel={this.onCancel.bind(this)}
        />
      </div>
    )
  }
}

FinalizeWindow.propTypes = {
  actions: PropTypes.object,
  organization: PropTypes.string,
  object: PropTypes.object,
  version: PropTypes.string,
  versions: PropTypes.array,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  onFinalize: PropTypes.func,
  onClose: PropTypes.func
}
