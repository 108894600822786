export const UPDATE_USER_STATE = 'UPDATE_USER_STATE'
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION'
export const UPDATE_APPLICATION_NAME = 'UPDATE_APPLICATION_NAME'
export const UPDATE_APPLICATION_DESCRIPTION = 'UPDATE_APPLICATION_DESCRIPTION'
export const UPDATE_DATASET_DATA = 'UPDATE_DATASET_DATA'
export const UPDATE_APPLICATION_USERS = 'UPDATE_APPLICATION_USERS'
export const UPDATE_APPLICATION_PROPERTIES = 'UPDATE_APPLICATION_PROPERTIES'
export const UPDATE_APPLICATION_ADVANCED_SETTINGS = 'UPDATE_APPLICATION_ADVANCED_SETTINGS'
export const CREATE_MAJOR_OBJECT = 'CREATE_MAJOR_OBJECT'
export const UPDATE_MAJOR_OBJECT = 'UPDATE_MAJOR_OBJECT'
export const UPDATE_MAJOR_OBJECT_NAME = 'UPDATE_MAJOR_OBJECT_NAME'
export const UPDATE_MAJOR_OBJECT_DESCRIPTION = 'UPDATE_MAJOR_OBJECT_DESCRIPTION'
export const UPDATE_MAJOR_OBJECT_USERS = 'UPDATE_MAJOR_OBJECT_USERS'
export const UPDATE_MAJOR_OBJECT_PROPERTIES = 'UPDATE_MAJOR_OBJECT_PROPERTIES'
export const UPDATE_MAJOR_OBJECT_TAGS = 'UPDATE_MAJOR_OBJECT_TAGS'
export const UPDATE_MAJOR_OBJECT_ADVANCED_SETTINGS = 'UPDATE_MAJOR_OBJECT_ADVANCED_SETTINGS'
export const UPDATE_OBJECT_PARENT = 'UPDATE_OBJECT_PARENT'
export const REQUEST_OBJECT = 'REQUEST_OBJECT'
export const RECEIVE_OBJECT = 'RECEIVE_OBJECT'
export const RECEIVE_OBJECT_LIST = 'RECEIVE_OBJECT_LIST'
export const RECEIVE_ERROR_OBJECT = 'RECEIVE_ERROR_OBJECT'
export const MAJOR_OBJECT_ADD_MESSAGE = 'MAJOR_OBJECT_ADD_MESSAGE'
export const UPDATE_FIELD = 'UPDATE_FIELD'
export const UPDATE_PUBLICATION_INTERFACES = 'UPDATE_PUBLICATION_INTERFACES' // TODO: check where it used
export const UPDATE_PUBLICATION_INTERFACE = 'UPDATE_PUBLICATION_INTERFACE'
export const UPDATE_PUBLICATION_TOPOLOGY = 'UPDATE_PUBLICATION_TOPOLOGY'
export const DELETE_PUBLICATION_INTERFACE = 'UPDATE_PUBLICATION_INTERFACE'
export const UPDATE_PUBLICATION_DATASET = 'UPDATE_PUBLICATION_DATASET'
export const DELETE_OBJECT = 'DELETE_OBJECT'
export const CLEAR_OBJECT_LIST = 'CLEAR_OBJECT_LIST'
export const RECEIVE_OBJECT_LIST_INTO_PROPERTY = 'RECEIVE_OBJECT_LIST_INTO_PROPERTY'
export const DELETE_PUBLICATION_DATASET = 'DELETE_PUBLICATION_DATASET'
export const DELETE_MAJOR_OBJECT = 'DELETE_MAJOR_OBJECT'
export const RECEIVE_SETTINGS_INTO_PROPERTY = 'RECEIVE_SETTINGS_INTO_PROPERTY'
export const ADD_MESSAGE = 'ADD_MESSAGE'
export const SET_ERROR = 'SET_ERROR'
export const UPDATE_MAJOR_OBJECT_DOCUMENTS = 'UPDATE_MAJOR_OBJECT_DOCUMENTS'
export const UPDATE_ISSUE_TASKS = 'UPDATE_ISSUE_TASKS'
export const RECEIVE_OBJECT_MERGED = 'RECEIVE_OBJECT_MERGED'
export const UPDATE_SUBSCRIPTION_DATASET = 'UPDATE_SUBSCRIPTION_DATASET'
export const DELETE_SUBSCRIPTION_DATASET = 'DELETE_SUBSCRIPTION_DATASET'
export const CLEAR_APP_STATE = 'CLEAR_APP_STATE'
export const CLEAR_ERROR = 'CLEAR_ERROR'

// ui types
export const SIDEBAR_COLLAPSE = 'SIDEBAR_COLLAPSE'
export const EXPERIMENTAL_DESIGN = 'EXPERIMENTAL_DESIGN'
export const TAB_NAME = 'TAB_NAME'
