/* eslint-disable array-callback-return */
/**
 * Created by kascode on 13.04.16.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import 'react-selectize/themes/index.css'
import { capitalize, columnsToType } from '../../helpers'
import '../../styles/FilterableContent/FilterableContent.scss'
import { KascodeSelect } from '../KascodeSelect/KascodeSelect'
import { ObjectRating } from '../ObjectRating/ObjectRating'
import { formatByType } from '../../helpers/editByType'
import { deepCopy } from 'resources/lib'

export class FilterableContent extends Component {

  constructor(props) {
    super(props)
    let activeFilters = []
    if (!props.noLocalStorage) {
      activeFilters =
        localStorage['DH_filters_' + props.parentUri + '_' + props.title] &&
        localStorage['DH_filters_' + props.parentUri + '_' + props.title].length
          ? JSON.parse(localStorage['DH_filters_' + props.parentUri + '_' + props.title]) || []
          : []
    }
    this.state = {
      activeFilters: activeFilters,
      hideTranslations:false
    }
  }

  isSearchTypeFilter = (name) => {
    return name === 'alias' || name === 'search'
  }

  componentWillUnmount() {
    localStorage['DH_filters_' + this.props.parentUri + '_' + this.props.title] = []
  }

  componentDidMount() {
    const filters = this.renderFilters(
      this.props.dataFilters.map((filter) => (typeof filter === 'object' ? filter.name : filter)),
      this.props.data
    )
    if ((this.props.transEnum || this.props.transView) && this.props.data && filters) {
      this.setState({
        activeFilters: [{ name: filters.locales.props.placeholder, value: [filters.locales.props.options[0]?.value] }]
      })
    }
  }

  /**
   * Check if string (may be null) starts with substring, case insensitive
   * @param haystack
   * @param needle
   * @returns {*}
   */
  checkIncludes = (haystack, needle) => {
    if (!haystack) return false
    if (!needle) needle = ''
    return haystack.toLowerCase().includes(needle.toLowerCase())
  }

  //added filtration by locale at translation buttom btn
  getFilteredDataForEnumTrans(newData, filters) {
    let data = newData
    const hideTranslations=this.state.hideTranslations
    if(hideTranslations){
     data=newData.filter((element)=>{
       const locale=filters.find((filter)=>filter.name==='locales')?.value?.[0]
       if(element.Translations){
         const translations=JSON.parse(element.Translations)
         if(!translations.length){
           return !(translations?.Name && translations?.Description && translations.Locale === locale);
         }
         const currentLocaleTranslation =translations.find((e)=>e.Locale === locale)
         return !(currentLocaleTranslation?.Name && currentLocaleTranslation?.Description);
       }
       return true
     })
    }
    if (filters.length) {
      const filt = filters.filter((f) => f.name === 'locales')
      if (filt.length) {
        return data.map((el) => {
          const copyEl = deepCopy(el)
          let translations = []
          filt[0].value.map((value) => {
            if (filt[0].name === 'locales') {
              if (copyEl.Translations) {
                const trans = Array.isArray(JSON.parse(copyEl.Translations))
                  ? JSON.parse(copyEl.Translations)
                  : [JSON.parse(copyEl.Translations)]
                const convertTrans = trans
                  .map((t) => {
                    if (t.Locale !== value) return
                    if (t.name) {
                      return {
                        Description: t.description.Description,
                        Text: t.description.Text,
                        Control: t.description.Control
                      }
                    }
                    return {
                      Name: t.Name,
                      Description: t.Description
                    }
                  })
                  .filter((n) => n)
                translations.push(...convertTrans)
              }
            }
          })
          copyEl.Translations = JSON.stringify(translations)
          return copyEl
        })
      }
    }
    return data
  }

  isSelectFilter(name){
    if(name==='select')return true
    return false
  }

  getFilteredData(data, filters) {
    if (filters.length) {
      return data.filter((el) => {
        for (let filter of filters) {
          let targetValue = el[filter.name]
          if (this.isSearchTypeFilter(filter.name)) {
            if (filter.name === 'alias') {
              if (!el.profile) return false
              //if (!el.profile.firstname || !el.profile.lastname || !el.profile.email)
              //  return false;
              const s = filter.value[0]
              if (
                !this.checkIncludes(el.profile.firstname, s) &&
                !this.checkIncludes(el.profile.lastname, s) &&
                !this.checkIncludes(el.profile.email, s) &&
                !this.checkIncludes(el.profile.alias, s) &&
                !this.checkIncludes(el.email, s) &&
                !this.checkIncludes(el.profile.identity.name, s)
              )
                return false
            } else if (filter.name === 'search') {
              const filterData = this.props.dataFilters.reduce(
                (data, cur) => (cur.name === 'search' ? cur : data),
                null
              )

              if (!filterData) return false
              //console.log("filter search=",filter,"el=",el,filterData);
              if (filterData.searchFields) {
                let found = false
                filterData.searchFields.map((fieldName) => {
                  if (el[fieldName] && el[fieldName].toLowerCase().includes(filter.value[0].toLowerCase())) found = true
                })
                if (!found) return false
              } else {
                return (
                  el.identity &&
                  ((el.identity.name && el.identity.name.toLowerCase().includes(filter.value[0].toLowerCase())) ||
                    (el.identity.description &&
                      el.identity.description.toLowerCase().includes(filter.value[0].toLowerCase())))
                )
              }
            }
          } else if (filter.name === 'rating') {
            const rating = ObjectRating.calculateRating(targetValue)

            if (filter.value.indexOf(rating) === -1) return false
          } else {
            if (filter.name === 'architects') {
              let found = 0
              targetValue.map((val) => {
                if (filter.value.includes(val.name)) found = true
              })
              return found
            } else if (filter.name === 'system' || filter.name === 'application') {
              if (!filter.value.includes(targetValue.name)) return false
            } else if (Object.prototype.toString.call(targetValue) === '[object Array]') {
              if (targetValue.filter((val) => filter.value.includes(val)).length === 0) {
                return false
              }
            } else {
              if (filter.value.indexOf(targetValue) === -1) return false
            }
          }
        }
        return true
      })
    }
    return data
  }

  getFilterOptions(filterName, dataToFilter) {
    let vals = []
    const optionRenderMethod = formatByType[columnsToType.getType(filterName).name]

    function checkObjectOptionDupes(value) {
      for (let v of vals) {
        if (v.value && v.value.indexOf(value) > -1) return true
      }

      return false
    }

    let arch_names = []
    for (let d of dataToFilter) {
      if (filterName === 'rating') {
        const rating = ObjectRating.calculateRating(d[filterName])
        if (!checkObjectOptionDupes(rating))
          vals.push({
            value: rating,
            label: <img className="KascodeSelect__rating-star" src={ObjectRating.chooseStar(rating)} alt="icon" />
          })
      } else if (filterName === 'architects') {
        let dvals = d[filterName]

        for (let arch of dvals) {
          ////console.log('array', arch_names, 'name', arch.name);
          if (!arch_names.includes(arch.name)) {
            vals.push({
              value: arch.name,
              label: arch.name //formatByType['person']
            })
            arch_names.push(arch.name)
          }
        }
      } else if (filterName === 'system' || filterName === 'application') {
        let val = d[filterName]
        if (val) {
          vals.push({
            value: val.name,
            label: val.name
          })
        }
      } else if (filterName === 'locales' || filterName === 'fields') {
        let val = d[filterName]
        if (val) {
          for (let el of val) {
            let exists = false
            for (let v of vals) {
              if (v.value === el) {
                exists = true
                break
              }
            }

            if (!exists)
              vals.push({
                value: el,
                label: el
              })
          }
        }
      } else {
        let val = d[filterName]
        if (val) {
          if (Object.prototype.toString.call(val) === '[object Array]') {
            // Concat without duplicates
            for (let el of val) {
              let exists = false

              for (let v of vals) {
                if (v.value === el) {
                  exists = true
                  break
                }
              }

              if (!exists) {
                vals.push({
                  value: el,
                  label: optionRenderMethod(el)
                })
              }
            }
          } else {
            let exists = false

            for (let v of vals) {
              if (v.value === val) {
                exists = true
                break
              }
            }

            if (!exists) {
              vals.push({
                value: val,
                label: val
              })
            }
          }
        }
      }
    }

    return vals
  }

  removeFilter(filter) {
    const newFilters = this.state.activeFilters.filter((el) => el.name !== filter)
    this.setState(
      {
        activeFilters: newFilters
      },
      () => {
        if (!this.props.noLocalStorage)
          localStorage['DH_filters_' + this.props.parentUri + '_' + this.props.title] = JSON.stringify(newFilters)
        if (this.props.onChangeFilter) this.props.onChangeFilter(newFilters)
      }
    )
  }

  clearAllFilters() {
    if (!this.props.noLocalStorage)
      localStorage['DH_filters_' + this.props.parentUri + '_' + this.props.title] = JSON.stringify('')
    this.setState({ activeFilters: this.state.activeFilters.filter((e) => e.name === 'locales') }, () => {
      if (this.props.onChangeFilter) this.props.onChangeFilter([])
    })
  }

  updateFilter(filterName, value) {
    let currentFilters = this.state.activeFilters
    let newFilters = []
    let updated = false

    for (let activeFilter of currentFilters) {
      if (activeFilter.name === filterName) {
        activeFilter.value = value
        updated = true
      }

      newFilters.push(activeFilter)
    }

    if (!updated) {
      newFilters.push({
        name: filterName,
        value
      })
    }

    this.setState(
      {
        activeFilters: newFilters
      },
      () => {
        if (!this.props.noLocalStorage)
          localStorage['DH_filters_' + this.props.parentUri + '_' + this.props.title] = JSON.stringify(newFilters)
        if (this.props.onChangeFilter) this.props.onChangeFilter(newFilters)
      }
    )
  }

  renderFilters(filters, data) {
    let filtersWithValues = []
    let filtersHTML = {}
    for (let filter of filters) {
      filtersWithValues.push({
        name: filter,
        values: this.getFilterOptions(filter, data)
      })
    }
    //logger.info("FilteredContenet:renderFilters", { filters, data, filtersWithValues }, this.state, this.props);

    filtersWithValues.map((el, index) => {
      if (!el.name) return null

      if (this.isSearchTypeFilter(el.name)) {
        const searchText = this.state.activeFilters.reduce(
          (foundResult, filter) =>
            filter.name === el.name // find this filter in state.activeFilters
              ? filter.value.join(',') || el.name
              : foundResult,
          ''
        )
        filtersHTML[el.name] = (
          <div className="SearchFilterInput__outer">
            <input
              value={searchText}
              className={'SearchFilterInput ' + (searchText ? 'selectedFilter' : '')}
              type="text"
              placeholder={capitalize(el.name)}
              onChange={(event) =>
                event.target.value ? this.updateFilter(el.name, [event.target.value]) : this.removeFilter(el.name)
              }
            />
          </div>
        )
      } else if(this.isSelectFilter(el.name)){
        filtersHTML[el.name]=(
            <div className={'d-flex ml-10'}>
              <label htmlFor="hideTranslations">Hide completed translations: </label>
              <input
                  type="checkbox"
                  id="hideTranslations"
                  defaultChecked={this.state.hideTranslations}
                  onClick={()=>this.setState(  {
                    hideTranslations: !this.state.hideTranslations
                  })}
              />
            </div>
        )
      } else {
        //console.log("FilteredContenet:renderFilters:SELECT", el, this.state.activeFilters);
        filtersHTML[el.name] = (
          <KascodeSelect
            transEnum={this.props.transEnum}
            transView={this.props.transView}
            placeholder={
              this.state.activeFilters.reduce(
                (foundResult, filter) =>
                  filter.name === el.name // find this filter in state.activeFilters
                    ? filter.value.join(',') || el.name
                    : foundResult,
                ''
              ) || el.name
            }
            options={el.values.map((value, index) => {
              return { id: index, label: value.label, value: value.value }
            })}
            className={
              this.state.activeFilters.reduce(
                (foundResult, filter) =>
                  filter.name === el.name // find this filter in state.activeFilters
                    ? filter.value.join(',') || el.name
                    : foundResult,
                ''
              )
                ? 'selectedFilter'
                : ''
            }
            onValuesChange={(event) => {
              ////console.log("onvalueschange", event);
              let values = event.target.value.map((val) => val.value)
              if (!values.length) this.removeFilter(el.name)
              else this.updateFilter(el.name, values)
            }}
            externalControl
            activeOptions={
              this.state.activeFilters.reduce(
                (foundResult, filter) =>
                  filter.name === el.name // find this filter in state.activeFilters
                    ? filter.value.map(
                        // for each of its values
                        (value) =>
                          el.values.reduce(
                            (
                              foundValueIndex,
                              elValue,
                              index // find the index of the value in el.filter.values
                            ) => (elValue.value === value ? index : foundValueIndex),
                            -1
                          )
                      )
                    : foundResult,
                []
              ) // if filter not active, value list is empty
            }
            multi
          />
        )
      }
    })
    //logger.info("FilteredContenet:renderFilters:RETURN", filtersHTML, Object.keys(filtersHTML).length === 0 ? null : filtersHTML);
    return Object.keys(filtersHTML).length === 0 ? null : filtersHTML
  }
  //changes depending on the filtering sequence
  makeFilterData() {
    if (!this.state.activeFilters) return this.props.data
    if (this.state.activeFilters && this.state.activeFilters[0] && this.state.activeFilters[0].name === 'locales') {
      return this.getFilteredData(
        this.getFilteredDataForEnumTrans(this.props.data, this.state.activeFilters),
        this.state.activeFilters
      )
    } else {
      return this.getFilteredDataForEnumTrans(
        this.getFilteredData(this.props.data, this.state.activeFilters),
        this.state.activeFilters
      )
    }
  }

  render() {
    const filteredData = this.makeFilterData()
    //logger.info("FilterableContent:render", filteredData, this.state, this.props);
    return (
      <div>
        <div className="content">
          {React.cloneElement(this.props.children, {
            data: filteredData,
            dataFilters: this.props.dataFilters,
            activeFilters: this.state.activeFilters,
            filters: this.renderFilters(
              this.props.dataFilters.map((filter) => (typeof filter === 'object' ? filter.name : filter)),
              this.props.data
            ),
            onClearAllFilters: this.clearAllFilters.bind(this)
          })}
        </div>
        {this.state.activeFilters.length > 0 ? (
          <div className="Table__filteredView">
            {' '}
            Filtered view {filteredData.length}/{this.props.data.length} items{' '}
            <span className="btn_edit" onClick={this.clearAllFilters.bind(this)}>
              Clear filters
            </span>
          </div>
        ) : null}
      </div>
    )
  }
}

FilterableContent.propTypes = {
  dataFilters: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  children: PropTypes.object,
  onChangeFilter: PropTypes.func,
  parentUri: PropTypes.string.isRequired,
  title: PropTypes.string,
  noLocalStorage: PropTypes.bool
}
