/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../actions/index'
import { columnsToType } from '../../helpers'
import { getObjectByName, getObjectByPath } from '../../helpers/data'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { Loader } from '../Loader/Loader'
import Table from './Table'

import iEditDark from '../../resources/images/edit-dark-2x.png'
import iDelete from '../../resources/images/discard-2x.png'

class TableRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inEditMode: this.props.isEditable,
      height: 50,
      top: 0, // width of top border
      hovered: false,
      controlsClosed: false
    }
    this.isUpdating = false
    this.rootRef = React.createRef()
  }

  getTbodyNode = () => {
    let node = this.rootRef.current
    if (!node) {
      return null
    }
    return node
  }

  getTrNode = () => {
    let node = this.getTbodyNode()
    if (!node) return null
    const tr = node.getElementsByTagName('tr')
    if (!tr || tr.length === 0) return null
    node = tr[0]
    return node
  }

  componentDidMount() {}

  componentDidUpdate() {}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isEditable !== this.state.inEditMode) this.setState({ inEditMode: nextProps.isEditable })
  }

  countRowControls = () => {
    let res = 0

    if (this.props.isEditableByRow) res++
    if (this.props.showDelete) res++
    if (this.props.showCopy) res++

    return res
  }

  onControlsClick = () => {
    if (this.state.hovered) {
      this.setState({
        controlsClosed: !this.state.controlsClosed
      })
    }
  }

  /**
   * Render table cell
   * @param columnProps
   * @param valueType type of cell value
   * @param width
   * @param value value of cell
   * @param rowIndex
   * @returns {XML}
   */
  renderCell(columnProps, valueType, width, value, rowIndex, datasetReference, index, rowValue, referenceUsage) {
    // if (valueType.name === 'integer')
    //   //console.log("renderCell", value, typeof value);
    if (columnProps.onCellClickGenerator)
      columnProps.onCellClick = columnProps.onCellClickGenerator(columnProps, value, rowIndex, rowValue)
    const isExpanded = this.props.expandedRowIndexes.includes(rowIndex)
    const isEditing =
      this.props.editingField &&
      rowIndex === this.props.editingField.rowId &&
      columnProps.name === this.props.editingField.columnName

    let expandText = '+'
    let collapseText = '\u2013'
    if (referenceUsage) {
      if (referenceUsage === 'Enum') {
        expandText = '#'
      }
      if (referenceUsage === 'Reference') {
        //expandText = "&";
        expandText = '&'
      }
    }
    //console.log("TableRow:renderCell", columnProps, valueType, value, datasetReference);

    return (
      <td
        className={'TableCell TableCell_' + columnProps.name}
        style={{
          width: width ? width : 'auto',
          maxWidth: width ? width : 'auto',
          boxSizing: 'border-box'
        }}
        key={index}
        ref={this.rootRef}
      >
        {value.reference ? (
          <EditableEntity
            data={isEditing ? this.props.editingField.value : value.name}
            dataType={{ name: 'reference' }}
            dataProps={{
              onChange: (e) => {
                // eslint-disable-next-line no-unused-vars
                const event = {
                  target: {
                    value: {
                      name: e.target.value,
                      reference: value.structure
                    }
                  }
                }
                this.props.onValueChange(columnProps.name)(e)
              },
              onBlur: this.props.applyEdit
            }}
            isEditable={this.state.inEditMode && !columnProps.frozen}
            inEditMode={isEditing}
            onEditStart={() => this.props.editField(rowIndex, columnProps.name, valueType, value.name)}
            onEntityClick={columnProps.onCellClick ? columnProps.onCellClick : false}
          />
        ) : (
          <EditableEntity
            data={isEditing ? this.props.editingField.value : value}
            dataType={valueType}
            dataProps={{
              onChange: this.props.onValueChange(columnProps.name),
              onBlur: this.props.applyEdit,
              maxLineCount: 2,
              defaultLineHeight: 19
            }}
            isEditable={this.state.inEditMode && !columnProps.frozen}
            inEditMode={isEditing}
            onEditStart={() => this.props.editField(rowIndex, columnProps.name, valueType, value)}
            onEntityClick={columnProps.onCellClick ? columnProps.onCellClick : false}
          />
        )}
        {value.reference ? (
          <div className={'TableRefExpander' + (isExpanded ? ' TableRefExpander_active' : '')}>
            <div
              className="TableRefExpander__btn"
              onClick={() => this.props.toggleExpandedRow(this, rowIndex, value.reference)}
            >
              {isExpanded ? <span>{collapseText}</span> : <span>{expandText}</span>}
            </div>
          </div>
        ) : null}
        {datasetReference ? (
          <div className={'TableRefExpander' + (isExpanded ? ' TableRefExpander_active' : '')}>
            <div
              className="TableRefExpander__btn"
              onClick={() => this.props.toggleExpandedRow(rowIndex, datasetReference)}
            >
              {isExpanded ? <span>{collapseText}</span> : <span>{expandText}</span>}
            </div>
          </div>
        ) : null}
      </td>
    )
  }

  /**
   * Render floating buttons
   * @param rowIndex
   * @param numOfControls
   * @param heightOfRow
   * @returns {XML}
   */
  renderFloatingButtons(rowIndex, numOfControls, heightOfRow) {
    // Render floating buttons on record
    if (!numOfControls) return <div></div>

    let element = 40 + 60 * numOfControls
    let width = 0
    for (let i = this.props.columns.length - 1; i >= 0; i--) {
      width += this.props.columns[i].width
      if (width > element) {
        width = width - element > 28 ? element : width
        break
      }
    }

    //console.log("TableRow:renderFloatingButtons", rowIndex, numOfControls, heightOfRow, width);
    return (
      <div className={'Table__floatingButtonsOuter'} style={{ height: heightOfRow }}>
        <div className="Table__floatingButtons">
          {this.state.controlsClosed ? (
            <div className={'Table__floatingButtonsInner Table__floatingButtonsInner_closed'}></div>
          ) : (
            <div className={'Table__floatingButtonsInner'} style={{ left: -width, width: width }}>
              {this.props.isEditableByRow ? (
                <span>
                  <a onClick={this.props.onEditClick}>
                    <img className="PenIcon" src={iEditDark} alt="icon" /> Edit
                  </a>
                </span>
              ) : null}
              {this.props.showCopy ? (
                <span>
                  <a onClick={this.props.onCopyClick}>
                    <img className="PenIcon" src={iEditDark} alt="icon" /> Copy
                  </a>
                </span>
              ) : null}
              {this.props.showDelete ? (
                <span>
                  <a onMouseDown={this.props.delete(this.props.value.id, this.props.value)}>
                    <img className="BinIcon" src={iDelete} alt="" />
                    Delete
                  </a>
                  &nbsp;
                </span>
              ) : null}
            </div>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { cellValues, columns, tableId, rowId, index, valueType, isLast, value } = this.props

    const ref = cellValues.filter((el) => {
      return el.reference
    })
    const hasRef = ref.length > 0
    const isExpanded = this.props.expandedRowIndexes.includes(rowId)
    let result = []
    const hasDatasetRef = cellValues.filter((el) => el.datasetReference).length > 0
    const datasetReference = hasDatasetRef ? cellValues.filter((el) => el.datasetReference)[0].datasetReference : null
    const referenceUsage = hasDatasetRef ? cellValues.filter((el) => el.datasetReference)[0].label : null
    const numOfControls = this.countRowControls()
    const rowUID = tableId + ':' + index

    //console.log("TableRow:render", this.props, datasetReference);

    result.push(
      <tr
        id={rowUID}
        className={
          'Table__row EditableRow ' +
          (this.state.controlsClosed ? ' Table__row_nobg' : '') +
          (hasRef || hasDatasetRef
            ? ' Table__expandableRow' +
              (isExpanded ? ' Table__expandableRow_active' : '') +
              (isLast ? ' Table__expandableRow_last' : '')
            : '')
        }
        key={index}
        onMouseEnter={() => {
          this.setState({ hovered: true })
        }}
        onMouseLeave={() => {
          this.setState({ hovered: false, controlsClosed: false })
        }}
        onClick={this.onControlsClick}
      >
        {this.renderFloatingButtons(
          rowUID,
          numOfControls,
          document.getElementById(rowUID) ? document.getElementById(rowUID).offsetHeight : 57
        )}
        {cellValues.map((value, index) => {
          const determinedValueType =
            columns[index].name === 'value' && columns[index].type.name === 'self' ? valueType : columns[index].type
          return this.renderCell(
            columns[index],
            determinedValueType,
            this.props.columns[index].width ? this.props.columns[index].width : false,
            value,
            rowId,
            columns[index].name === 'expandButton' ? datasetReference : null,
            index,
            this.props.value,
            referenceUsage
          )
        })}
        {this.state.isDeleting ? (
          <td className="Table__deleteButton" onClick={this.props.delete(index, this.props.value)}>
            <img src={iDelete} alt="" />
          </td>
        ) : (
          ''
        )}
      </tr>
    )

    if (hasDatasetRef) {
      const ds =
        getObjectByPath(this.props.appState, 'dataset', datasetReference) ||
        getObjectByName(this.props.appState, 'dataset', datasetReference.replace('/', ''))

      let datasetReferenceIsEnum = ds && ds.object && ds.object.usage === 'Enum'
      let datasetReferenceIsReference = referenceUsage === 'Reference'
      //console.log("1: datasetReferenceIsEnum '" + (ds && ds.identity ?  ds.identity.name : "") + "', '" +  (ds && ds.object ? ds.object.usage : "") + "'", ds && ds.structure && ds.structure.fields);

      const fieldMapper = columns.filter((col) => col.fieldMapper)[0].fieldMapper
      const fieldEditor = columns.reduce((p, col) => (col.fieldEditor ? col.fieldEditor : p), null)
      const excludedWidth = columns
        .filter((col) => col.name === 'key' || col.name === 'id' || col.name === 'order')
        .reduce((p, c) => p + c.width, 0)

      //console.log("TableRow:render:expandRow", this.props, datasetReference, ds);
      //console.log("TableRow:render:expandRow", ds && ds.identity ? ds.identity.name : "", ds && ds.object ? ds.object.usage : "", referenceUsage);

      if (ds && ds.structure && ds.structure.fields) {
        //console.log("2: datasetReferenceIsEnum '" + (ds && ds.identity ?  ds.identity.name : "") + "', '" +  (ds && ds.object ? ds.object.usage : "") + "'", ds && ds.structure && ds.structure.fields);

        const datasetColumns = columns.filter((col) => col.name !== 'key' && col.name !== 'id' && col.name !== 'order')
        let remainingWidth = columns.reduce((p, c) => p + c.width, 0) - excludedWidth

        const srcWidths = columns
          .filter((col) => col.name !== 'key' && col.name !== 'id' && col.name !== 'order')
          .map((c) => c.width)

        let refWidths = []
        for (let i = 0; i < datasetColumns.length - 1; i++) {
          refWidths[i] = srcWidths[i]
          remainingWidth -= srcWidths[i]
        }
        refWidths[1] -= 40
        refWidths[datasetColumns.length - 1] = remainingWidth

        // for reference we display Identity and structure is expanded inside
        if (datasetReferenceIsReference) {
          let object = this.props.dataObject
          let data = this.props.onExpandData ? this.props.onExpandData(ds, object) : null

          //console.log("TableRow:render:expandRow:DATA", this.props, ds, object, data);

          const refLineValues = {
            name: 'Reference',
            description: 'Reference to the object structure by identity',
            type: '/Identity'
          }

          /*
          if (isExpanded) {
            result.push(<tr className="Table__row">
              <td className="TableCell" colSpan="100">
                <table style={{marginLeft: (excludedWidth + 40) + "px"}} className="Table Table_dataset Table_structure" cellPadding="5">
                  <tr className="Table__row">
                    <td style={{width: "40px", boxSizing: "border-box"}} className="TableCell">
                      <div className={"TableRefExpander" + (" TableRefExpander_active" )}>
                        <div className="TableRefExpander__btn">
                          <span>&ndash;</span>
                        </div>
                      </div>
                    </td>
          {datasetColumns.slice(1).map((col, index) => {
            return <td className="TableCell" style={{width: refWidths[index + 1] + (index === 0 ? -40 : 0), boxSizing: "border-box"}}>
              {refLineValues[col.name] || ""}
            </td>;
          })}
                  </tr>
                </table>
              </td>
            </tr>);
          }
          */

          if (isExpanded) {
            result.push(
              <tr className="Table__row Table__expandRow Table__expandReferenceRow">
                <td className="TableCell" colSpan="100">
                  <Table
                    data={[refLineValues]}
                    columns={datasetColumns.map((col, index) => {
                      return Object.assign({}, col, {
                        width: refWidths[index],
                        type: {
                          name: col.name !== 'expandButton' ? 'string' : 'showExpandButton'
                        }
                      })
                    })}
                    hideHeader
                    style={{ marginLeft: excludedWidth + 44 + 'px' }}
                  />
                </td>
              </tr>
            )
          }

          if (isExpanded) {
            refWidths[1] -= 38

            result.push(
              <tr className={'Table__row Table__expandRow' + (isExpanded ? '' : ' Table__row_hidden')}>
                <div></div>
                <td className="TableCell" colSpan="100">
                  <Table
                    data={data ? data : ds.structure.fields.map(fieldMapper(value, columns))}
                    columns={datasetColumns.map((col, index) => {
                      return Object.assign({}, col, { width: refWidths[index] })
                    })}
                    dataset={ds}
                    dataPath={this.props.dataPath}
                    dataObject={this.props.dataObject}
                    inEditMode={this.props.isEditable}
                    isEditable={this.props.isEditable}
                    externalControl={this.props.isEditable}
                    onEdit={
                      this.props.dataPath !== null
                        ? this.props.onEdit
                        : fieldEditor
                        ? fieldEditor(value, columns)
                        : undefined
                    }
                    style={{ marginLeft: excludedWidth + 78 + 'px' }}
                    hideHeader
                    onExpandData={this.props.onExpandData}
                    onExpandRow={this.props.onExpandRow}
                    onSave={() => {}}
                    onValueChange={this.props.onTableValueChange}
                  />
                </td>
              </tr>
            )
          }
        }
        // for enum we display its options (Data Table)
        else if (datasetReferenceIsEnum) {
          //result.push(null);

          /*
           columns={[].concat(ds.structure && ds.structure.fields ? ds.structure.fields.sort(
           (a, b) => a.order > b.order ? 1 : (b.order > a.order ? -1 : 0)
           ).map((f, fi) => {
           if (!f.type) f.type = "String"; // remove this quick dirty fix
           return {
           name: f.identity.name,
           type: Object.assign({}, columnsToType.getType(f.type.toLowerCase()), f.reference ? {reference: f.reference} : {}),
           width: 1184 / (1 + (ds.structure && ds.structure.fields ? ds.structure.fields.length : 1)) - (fi === 0 ? 28 : 0)
           };
           }) : [])}
           */

          if (isExpanded) {
            result.push(
              <tr className={'Table__row Table__expandRow' + (isExpanded ? '' : ' Table__row_hidden')}>
                <div></div>
                <td className="TableCell" colSpan="100">
                  <Table
                    data={(ds.data.records || []).map((record, index) => {
                      const res = {
                        id: index
                      }
                      for (let i = 0; i < record.values.length; i++) {
                        if (ds.structure.fields[i]) {
                          let field = ds.structure.fields[i].identity.name
                          res[field] = record.values[i]
                        }
                      }
                      res._index = index + 1
                      return res
                    })}
                    columns={[
                      {
                        name: '',
                        type: columnsToType.getType('string'),
                        width: refWidths[0]
                      }
                    ].concat(
                      ['name', 'description', 'value'].map((colUsage, iCol) => {
                        let colIndex = -1
                        // eslint-disable-next-line array-callback-return
                        ds.structure.fields.map((field, i) => {
                          console.log('field', field, 'usage', colUsage)
                          if (field.usage.toLowerCase() === colUsage) colIndex = i
                        })
                        if (iCol < 3 && colIndex === -1) colIndex = iCol
                        //console.log("");
                        if (colIndex > -1) {
                          return {
                            name: ds.structure.fields[colIndex].identity.name,
                            type: columnsToType.getType('string'),
                            width: refWidths[iCol + 1]
                          }
                        } else {
                          return {
                            name: '_temp_' + iCol,
                            type: columnsToType.getType('string'),
                            width: refWidths[iCol + 1]
                          }
                        }
                      })
                    )}
                    dataset={ds}
                    style={{ marginLeft: excludedWidth + 40 + 'px' }}
                    hideHeader
                  />
                </td>
              </tr>
            )
          }
        } else {
          let object = this.props.dataObject
          let data = this.props.onExpandData ? this.props.onExpandData(ds, object) : null

          //console.log("TableRow:render:expandRow:DATA", this.props, ds, object, data);

          result.push(
            <tr className={'Table__row Table__expandRow' + (isExpanded ? '' : ' Table__row_hidden')}>
              <div></div>
              <td className="TableCell" colSpan="100">
                <Table
                  data={data ? data : ds.structure.fields.map(fieldMapper(value, columns))}
                  columns={datasetColumns.map((col, index) => {
                    return Object.assign({}, col, { width: refWidths[index] })
                  })}
                  dataset={ds}
                  dataPath={this.props.dataPath}
                  dataObject={this.props.dataObject}
                  inEditMode={this.props.isEditable}
                  isEditable={this.props.isEditable}
                  externalControl={this.props.isEditable}
                  onEdit={
                    this.props.dataPath !== null
                      ? this.props.onEdit
                      : fieldEditor
                      ? fieldEditor(value, columns)
                      : undefined
                  }
                  style={{ marginLeft: excludedWidth + 40 + 'px' }}
                  hideHeader
                  onExpandData={this.props.onExpandData}
                  onExpandRow={this.props.onExpandRow}
                  onSave={() => {}}
                  onValueChange={this.props.onTableValueChange}
                />
              </td>
            </tr>
          )
        }
      } else {
        result.push(
          <div>
            <tr className={'Table__row Table__expandRow' + (isExpanded ? '' : ' Table__row_hidden')}>
              <td className="TableCell Table__expandableRowLoader" style={{ paddingLeft: excludedWidth + 'px' }}>
                <Loader />
              </td>
            </tr>
          </div>
        )
      }
    }

    if (hasRef) {
      const widths = this.props.columns.map((col) => col.width)
      const refCols = this.props.columns.filter((col) => col.name !== 'key' && col.name !== 'id')
      const refWidths = widths.slice(this.props.columns.length - refCols.length)
      const excludedWidth = widths
        .slice(0, this.props.columns.length - refCols.length)
        .reduce((prevVal, curVal) => prevVal + curVal, 0)
      refWidths[0] -= 34

      result.push(
        <tr className={'Table__row Table__expandRow' + (isExpanded ? '' : ' Table__row_hidden')}>
          <td className="TableCell">
            <Table
              columns={refCols.map((col, index) => Object.assign(col, { width: refWidths[index] }))}
              data={ref[0].reference}
              style={{ marginLeft: excludedWidth + 34 + 'px' }}
              hideHeader
            />
          </td>
        </tr>
      )
    }

    return <tbody>{result}</tbody>
  }
}

TableRow.propTypes = {
  tableId: PropTypes.string,
  rowId: PropTypes.string,
  index: PropTypes.number,
  valueType: PropTypes.object,
  cellValues: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      type: PropTypes.shape({
        name: PropTypes.string.isRequired,
        options: PropTypes.array
      })
    })
  ).isRequired,
  dataPath: PropTypes.string,
  dataObject: PropTypes.object,
  onDataEdit: PropTypes.func,
  isMouseOverRow: PropTypes.bool,
  isEditable: PropTypes.bool,
  isEditableByRow: PropTypes.bool,
  showDelete: PropTypes.bool,
  showCopy: PropTypes.bool,
  onEditClick: PropTypes.func,
  onCopyClick: PropTypes.func,
  onValueChange: PropTypes.func,
  onExpandData: PropTypes.func,
  onExpandRow: PropTypes.func,
  path: PropTypes.string
}

function mapStateToProps(state) {
  return {
    appState: state.appState
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableRow)

//export default TableRow;
