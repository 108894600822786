import React, { Component } from 'react'
import { nonCSCompare } from '../../helpers'
import logger from '../../helpers/logger'
import PropTypes from 'prop-types'

export class LinesDrawer extends Component {
  /**
   * Check if element on visible page. It go fastest wat to determine if element on visual page.
   * @param {string} mode - this is input or output element
   * @param {string} path - this is path of element with name of table and  name of element
   * @returns {boolean} true - on visible page, false - not visible
   */
  isElementVisible = (mode, path) => {
    // Need valid path
    if (!path) {
      return false
    }

    // Do we have pages?
    let data = mode === 'output' ? this.props.pageOutputData : this.props.pageInputData
    if (!data) {
      return false
    }

    // Do we have dataset table and field
    let element = path.split('/')
    //logger.info("LinesDrawer:isElementVisible:ELEMENT", { mode, path, data, element }, this.state, this.props);
    if (element.length < 2 && element[0] && element[1]) {
      return false
    }

    let table = data[element[0]]
    let field = element[1]?.split('.')[0]
    //logger.info("LinesDrawer:isElementVisible:FIELD", { mode, path, data, element, table, field }, this.state, this.props);
    if (!table || !field) {
      return false
    }

    let before = table.beforePages && table.beforePages.length > 0 ? table.beforePages : null
    let after = table.afterPages && table.afterPages.length > 0 ? table.afterPages : null
    let visible = true

    if (!after && !before) {
      // We have only visible data
    } else {
      // Find in visible
      let isVisible =
        table.visiblePages.findIndex((item) => nonCSCompare(item[0].props.row.identity.name, field)) !== -1
      if (isVisible) {
        // We found in visible we are good.
      } else {
        // We don't have anything before - it means we after
        visible = false
      }
    }
    //logger.info("LinesDrawer:isElementVisible:FOUND", { mode, path, data, element, table, field, before, after, visible }, this.state, this.props);
    return visible
  }

  /**
   * Check position of element outside of visible page, we know element not visible. We will look only on pages
   * before or after trying to determine where is element by scanning less posible number of pages.
   * @param {string} mode - this is input or output element
   * @param {string} path - this is path of element with name of table and  name of element
   * @returns {number} 0 - can't determent, -1 - before visible, 1 - after visible
   */
  getElementSide = (mode, path) => {
    // Need valid path
    if (!path) {
      return 0
    }

    // Do we have pages?
    let data = mode === 'output' ? this.props.pageOutputData : this.props.pageInputData
    if (!data) {
      return 0
    }

    // Do we have dataset table and field
    let element = path.split('/')
    if (element.length < 2 && element[0] && element[1]) {
      return 0
    }

    let table = data[element[0]]
    let field = element[1].split('.')[0]
    if (!table || !field) {
      return 0
    }

    let before = table.beforePages && table.beforePages.length > 0 ? table.beforePages : null
    let after = table.afterPages && table.afterPages.length > 0 ? table.afterPages : null
    let position = 0

    if (!after && !before) {
      // We have only visible data
    } else {
      if (!before) {
        // We don't have anything before - it means we after
        position = 1
      } else if (!after) {
        // Nothing after it means we are before.
        position = -1
      } else {
        // We have before and after let's check in small one.
        if (before.length < after.length) {
          let isBefore = before.findIndex((item) => nonCSCompare(item[0].props.row.identity.name, field)) !== -1
          position = isBefore ? -1 : 1
        } else {
          let isAfter = after.findIndex((item) => nonCSCompare(item[0].props.row.identity.name, field)) !== -1
          position = isAfter ? 1 : -1
        }
      }
    }
    //logger.info("LinesDrawer:getElementPosition", { mode, path, data, element, table, field, before, after, position }, this.state, this.props);
    return position
  }

  /**
   * Finds DOM element for field by id
   * @param mode
   * @param path
   * @returns {boolean}
   */
  findElementOrParent = (mode, path) => {
    if (!path) {
      return false
    }
    let npath = mode + '_' + path
    let node = false
    while (!node && npath.length > 0) {
      node = document.getElementById(npath)
      if (node) {
        //logger.info("LinesDrawer:findElementOrParent", { mode, npath, document, node }, this.state, this.props);
        return node
      }
      npath = npath.substr(0, npath.lastIndexOf('.'))
      //logger.info("LinesDrawer:findElementOrParent:NEXT", { mode, npath, document, node }, this.state, this.props);
    }
    //logger.info("LinesDrawer:findElementOrParent:NO-NODE", { mode, npath, document }, this.state, this.props);
    return false
  }

  /**
   * Finds DOM element - parent table for field
   * @param mode
   * @param path
   * @returns {*}
   */
  findElementTable = (mode, path) => {
    if (!path) {
      return false
    }

    // remove field name
    path = path.substr(0, path.indexOf('/'))

    const divId = 'dataset_' + mode + '_' + path

    //logger.log("findElementTable", mode, path, divId);
    const node = document.getElementById(divId)
    if (!node) {
      logger.warn('LinesDrawer:findElementTable', { divId, mode, path })
      return false
    } else {
      return node
    }
  }

  /**
   * Renders SVG line between two points
   * @param p1
   * @param p2
   * @returns {string}
   */
  renderLineCoords = (p1, p2) => {
    const half = Math.floor((p2[0] - p1[0]) / 2)
    return (
      'M ' +
      p1[0] +
      ' ' +
      (p1[1] - 30) +
      ' C ' +
      (p1[0] + half) +
      ' ' +
      (p1[1] - 30) +
      ' ' +
      (p2[0] - half) +
      ' ' +
      (p2[1] - 30) +
      ' ' +
      p2[0] +
      ' ' +
      (p2[1] - 30)
    )
  }

  render() {
    // positions of line starts and ends relative to Field name
    const leftShiftRight = -15
    const titleHeight = 30
    const rowHeight = 31

    const outputs = this.props.outputs || []
    let editState = this.props.editState

    const svg = document.querySelector('svg')
    if (!svg) {
      return null
    }
    const svgRect = svg.getBoundingClientRect()

    // logger.log("LinesDrawer:renderLines", { editState, outputs }, this.props, this.state);

    // Itereate over all outputs
    return outputs.map((output) => {
      // If we don't have map no lines to draw
      if (!output.map) {
        return null
      }

      // Iterate over map of output.
      return output.map.map((map, idx) => {
        return (
          <svg key={idx}>
            {(map.inputs || []).map((input) => {
              // Find DOM elements for input and output by their respective paths
              let inputPath = input
              let outputPath = output.identity.name + '/' + map.output

              // Let's find poition of input and output on visible page.
              let isInputVisible = this.isElementVisible('input', inputPath)
              let isOutputVisible = this.isElementVisible('output', outputPath)

              // Let's first check if input or output on visible page or we don't need this line.
              if (!isInputVisible && !isOutputVisible) {
                return
              }

              let inputTable = null
              let outputTable = null

              let inputElement = isInputVisible ? this.findElementOrParent('input', inputPath) : null
              let outputElement = isOutputVisible ? this.findElementOrParent('output', outputPath) : null
              //logger.info("LinesDrawer:renderLines:ELEMENT", inputPath, outputPath, inputElement, outputElement, this.state, this.props);

              let baseElement = document.getElementById('MapDialog_row')
              let base = baseElement.getBoundingClientRect()

              // if element is not visible, then the field belongs to invisible page - previous or next
              // we need to get coordinates of table to draw the line
              if (!inputElement) {
                inputTable = this.findElementTable('input', inputPath)
              } else {
                inputTable = inputElement
                while (
                  inputTable !== null &&
                  !(inputTable.tagName.toLowerCase() === 'div' && inputTable.classList.contains('table'))
                ) {
                  inputTable = inputTable.parentNode
                }
              }

              // if element is not visible, then the field belongs to invisible page - previous or next
              // we need to get coordinates of table to draw the line
              if (!outputElement) {
                outputTable = this.findElementTable('output', outputPath)
              } else {
                outputTable = outputElement
                while (
                  outputTable !== null &&
                  !(outputTable.tagName.toLowerCase() === 'div' && outputTable.classList.contains('table'))
                ) {
                  outputTable = outputTable.parentNode
                }
              }

              if (!inputTable || !outputTable) {
                return
              }

              const inputRect = inputTable.getBoundingClientRect()
              const outputRect = outputTable.getBoundingClientRect()

              let tableWidth = inputTable.parentNode.clientWidth

              let x0
              let y0
              if (inputElement) {
                // if element is visible, arrow coordinates are based on its coordinates
                const r0 = inputElement.getBoundingClientRect()
                // x0 = r0.left - base.left + tableWidth + leftShiftLeft;
                x0 = tableWidth - 8
                y0 = r0.top - base.top + titleHeight + rowHeight / 2
                // logger.log("base", base.top, base.height, "input", inputRect.top, inputRect.height, "r0", r0.top, r0.height, "y0",  r0.top - base.top + titleHeight + rowHeight / 2, y0);
                //"x0",x0,"y0",y0,"x1",x1,"y1",y1
              } else {
                // if element is not visible, arrow coordinates are based on its parent table
                // x0 = tableWidth + leftShiftLeft;
                x0 = tableWidth - 8
                let inputPosition = this.getElementSide('input', inputPath)
                if (inputPosition === -1) {
                  y0 = inputRect.top - base.top + titleHeight
                } else {
                  y0 = inputRect.top - base.top + inputRect.height + titleHeight - 5
                }
              }
              let y0_0 = y0
              // y is limited by table's top and top+height
              y0 = Math.min(inputRect.top + inputRect.height - base.top + titleHeight, y0)
              y0 = Math.max(y0, inputRect.top - base.top + titleHeight)

              tableWidth = outputTable.parentNode.clientWidth
              let x1
              let y1
              if (outputElement) {
                const r1 = outputElement.getBoundingClientRect()
                x1 = r1.left - base.left + leftShiftRight
                y1 = r1.top - base.top + titleHeight + rowHeight / 2
              } else {
                x1 = outputRect.left - base.left + leftShiftRight + 15
                let outputPosition = this.getElementSide('output', outputPath)
                if (outputPosition === -1) {
                  y1 = outputRect.top - base.top + titleHeight + 5
                } else {
                  y1 = outputRect.top - base.top + outputRect.height + titleHeight - 5
                }
              }
              y1 = Math.min(outputRect.top + outputRect.height - base.top + titleHeight, y1)
              y1 = Math.max(y1, outputRect.top - base.top + titleHeight)

              // svgRect is placed in center of the window, so we need to correct arrow coordinates relative to base
              x0 -= svgRect.left - base.left
              x1 -= svgRect.left - base.left
              //console.log("svgRect.left + base.left", svgRect.left - base.left);

              // selected output is highlighted
              let color = map.formula ? '#CBCBCB' : '#7BAD8B'
              if (editState <= 1) {
                color = map.formula ? '#D9CDED' : '#cdb9e5'
              }

              let isActive = false
              const { selectedOutputPath, selectedInputPath } = this.props

              if (selectedOutputPath && selectedInputPath) {
                if (selectedOutputPath === outputPath && selectedInputPath === inputPath) isActive = true
              } else if (selectedOutputPath) {
                if (selectedOutputPath === outputPath) isActive = true
              } else if (selectedInputPath) {
                if (selectedInputPath === inputPath) isActive = true
              }

              // switch line color depends on editState
              if (isActive) {
                if (editState <= 1) {
                  color = map.formula ? '#D9CDED' : '#8F70CB'
                } else {
                  color = map.formula ? '#238A96' : '#265838'
                }
              }
              // logger.log("Checking color for line", output, output.map, input, color);

              // circles are placed at the fields, first path is visible mapping, second transparent path is used to intercept clicks (visible path is too small to click)
              return [
                <React.Fragment key={input}>
                  <circle cx={x0} cy={y0 - 30} fill={color} r={2} />
                  <path
                    stroke={color}
                    fill="none"
                    d={this.renderLineCoords([x0, y0], [x1, y1])}
                    style={{ display: 'block' }}
                  />
                  <path
                    stroke="transparent"
                    strokeWidth="10"
                    fill="none"
                    d={this.renderLineCoords([x0, y0], [x1, y1])}
                    style={{ display: 'block' }}
                    onClick={() => this.props.onClick(editState, output, map, input, 'output')}
                    onMouseOver={() => this.props.onMouseOver(editState, output, map, input)}
                    onMouseOut={() => this.props.onMouseOut(editState, output, map, input)}
                  />
                  <circle cx={x1} cy={y1 - 30} fill={color} r={2} />
                </React.Fragment>
              ]
            })}
          </svg>
        )
      })
    })
  }
}

LinesDrawer.propTypes = {
  editState: PropTypes.number,
  outputs: PropTypes.array,
  pageInputData: PropTypes.object,
  pageOutputData: PropTypes.object,
  selectedOutputPath: PropTypes.bool,
  selectedInputPath: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func
}
