import { objectJsons } from './objectJsons'

export const parentByType = {
  organizations: '',
  datasets: 'applications',
  applications: 'systems',
  pipelines: 'applications',
  interfaces: 'applications',
  publications: 'applications',
  subscriptions: 'applications',
  systems: 'organizations',
  policies: 'applications',
  layouts: 'datasets',
  organization_datasets: 'organizations',
  views: 'applications',
  topologies: 'systems',
  deployments: 'systems',
  environments: 'systems'
}

/**
 * Non case sensitive string compare
 * @param {String} str1
 * @param {String} str2
 */

export function lcfirst(str) {
  str += ''
  const f = str.charAt(0).toLowerCase()
  return f + str.substr(1)
}

export function getDefaultValue(type) {
  if (type === null) return null

  //console.log('getDefaultValue', type);

  type = type || ''
  type = type.toLowerCase()

  switch (type) {
    case 'string':
    case 'text':
    case 'guid':
    case 'date':
    case 'datetime':
    case 'time':
    case 'enum':
      return ''
    case 'small':
    case 'short':
    case 'integer':
    case 'long':
    case 'huge':
    case 'byte':
    case 'unsigned short':
    case 'unsigned integer':
    case 'unsigned long':
    case 'unsigned huge':
    case 'float':
    case 'double':
    case 'decimal':
      return 0
    case 'boolean':
      return false
    case 'structure':
    case 'reference':
      return {}
  }

  return ''
}

/**
 * Copy object by all levels of the object
 * @param o - this is actual object to copy
 * @returns - complete copy of the object.
 */
export function deepCopy(o) {
  var copy = o,
    k

  if (o && typeof o === 'object') {
    copy = Array.isArray(o) ? [] : {}
    for (k in o) {
      copy[k] = deepCopy(o[k])
    }
  }
  return copy
}

export function objectNameFromPathByType(path, objectType) {
  if (!path) return null
  const parts = path.split('/')
  const firstTypeIndex = path.substr(0, 1) === '/' ? 1 : 0
  for (let i = firstTypeIndex; i < parts.length - 1; i = i + 2) {
    if (parts[i] === objectType) {
      return parts[i + 1]
    }
  }
  return null
}

function lastObjectName(s) {
  return objectNameFromPathByType(s, 'datasets')
}

export function createEmptyObject(objectType, objectJSON?: any) {
  let ret = {}
  let json = objectType ? objectJsons[objectType] : objectJSON
  //console.log("createEmptyObject", objectType, json);

  if (!json || !json.structure || !json.structure.fields) {
    //console.warn("createEmptyObject not found", objectType);
    return {}
  }

  // We need fields array in order of fields.
  let fields = json.structure.fields.sort((a, b) => {
    const ao = parseInt(a.order)
    const bo = parseInt(b.order)

    if (ao > bo) return 1
    else if (bo > ao) return -1
    return 0
  })

  // We need to iterate all fields of structure and create fields.
  for (let j in fields) {
    const field = fields[j]
    if (!field || !field.identity) continue

    // Get name from identity or from field reference.
    let name = field.identity.name
    let value = field.value

    if (
      !(field.count === 0 || field.count > 1) &&
      field.reference &&
      field.type === 'Structure' &&
      name.toUpperCase().startsWith('INHERIT:')
    ) {
      var o = createEmptyObject(lcfirst(lastObjectName(field.reference)))
      var k
      //console.log("createEmptyObject:INHERIT", lcfirst(lastObjectName(field.reference)), o, ret);

      if (o && typeof o === 'object') {
        for (k in o) {
          ret[k] = deepCopy(o[k])
        }
      }
      continue
    }

    // Get name from reference if missing
    if (!name && field.reference) name = field.reference.toLowerCase()
    if (!name) continue

    // If value not specified make it from type.
    if (!value || value === null) value = getDefaultValue(field.type)

    // Make name low case.
    if (name.charAt(1).toLowerCase() === name.charAt(1)) {
      name = lcfirst(name)
    }

    // We go into recursion if we on reference or to default value.
    ret[name] =
      field.count === 0 || field.count > 1
        ? []
        : field.reference && field.type === 'Structure'
        ? createEmptyObject(lcfirst(lastObjectName(field.reference)))
        : value
    //let type = Object.assign({name: field.type}, !field.reference ? {} : {reference: field.reference});
  }

  //console.log("createEmptyObject:RESULT", objectType, ret);
  return ret
}

export function hasInArrayItem(str: string, arr: string[]): boolean {
  if (!str || str.length == 0 || !arr || arr.length == 0) return false
  const lowercased = arr.map((string) => string.toLowerCase())
  return lowercased.includes(str.toLowerCase())
}

type DetailedPath = {
  name: string
  path: string
  type: string
}

export function buildPathDetailed(path: string): DetailedPath[] {
  if (!path) return []
  let pathSplit = path.split('/')
  let stepBack = path[path.length] === '/' ? 3 : 2

  if (pathSplit.length < stepBack) return []

  // if we have versions in path, cut it out
  if (pathSplit.includes('versions')) {
    pathSplit = pathSplit.splice(0, pathSplit.length - 2)
    path = pathSplit.join('/')
  }

  let ret: DetailedPath[] = []
  const i = pathSplit.length - stepBack
  const pathItem = {
    name: pathSplit[i + 1],
    type: pathSplit[i].slice(0, pathSplit[i].length),
    path
  }

  if (Object.keys(parentByType).includes(pathSplit[i])) ret = buildPathDetailed(pathSplit.splice(0, i).join('/'))

  ret.push(pathItem)

  return ret
}

export function getDatasetPathFromReference(ref: string, objPath: string): string {
  let ret = !ref || ref === '' ? null : ''

  if (ref.indexOf('/organizations/') !== -1) {
    ret = ref
  } else if (ref[0] === '/') {
    // dataset of organization
    ret = '/organizations/' + objectNameFromPathByType(objPath, 'organizations') + '/datasets/' + ref.substr(1)
  } else if (ref.substr(0, 2) === '..') {
    // dataset from subscriptions
    throw 'loading datasets from subscriptions not implemented'
  } else {
    // dataset of organization referenced from organization/system/dataset of organization
    if (!objectNameFromPathByType(objPath, 'applications')) {
      ret = '/organizations/' + objectNameFromPathByType(objPath, 'organizations') + '/datasets/' + ref
    } else {
      // dataset of application referenced from dataset/publication/subscription of application
      ret =
        '/organizations/' +
        objectNameFromPathByType(objPath, 'organizations') +
        '/systems/' +
        objectNameFromPathByType(objPath, 'systems') +
        '/applications/' +
        objectNameFromPathByType(objPath, 'applications') +
        '/datasets/' +
        ref
    }
  }

  if (ref.indexOf('$:') === 0 && ret?.indexOf('$:') !== 0) ret = '$:' + ret

  return ret
}
