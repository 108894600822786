/**
 * Created by kascode on 03.05.17.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { createEmptyObject, editableState, updateLocalObject, nonCSCompare } from '../../helpers/index'
import { getJsonSpecRecords } from '../../helpers/md'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { FieldEditor } from '../FieldEditor/FieldEditor'
import './ParameterDialog.scss'

export class ParameterDialog extends EditorDialogBase {
  getObjectType() {
    return 'parameter'
  }

  UNSAFE_componentWillReceiveProps() {}

  /**
   * Update value of field property in parameter
   * @param newField
   */
  updateField = (newField) => {
    console.log('ParameterDialog:updateField', newField)
    if (this.state.change) {
      this.state.change(true, '', '', '')
      /*
      this.setState({
        parameter: Object.assign({}, this.state.parameter, {field: newField})
      });
      */
      this.setState(updateLocalObject(Object.assign({}, this.state.parameter, { field: newField })))
    }
  }

  setLocation = (event) => {
    let data = event.target.value
    let curr = this.getInitial().location
    console.log('ParameterDialog:setLocation', data)

    // Indicate save mode
    if (this.state.change && data !== curr) {
      this.state.change(true, '', '', '')
      /*
      this.setState({
        parameter: Object.assign({}, this.state.parameter, {location: data})
      });
      */
      this.setState(updateLocalObject(Object.assign({}, this.state.parameter, { location: data })))
    }
  }

  checkInputErrors(parameter) {
    let inputError = ''
    const condition = this.props.majorObject.parameters
    if (
      !parameter.field ||
      !parameter.field.identity ||
      !parameter.field.identity.name ||
      parameter.field.identity.name.length === 0
    ) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify parameter names!'
    }
    if (condition && parameter.field && parameter.field.identity && parameter.field.identity.name) {
      const checkParameterName = condition.find(
        (e) =>
          nonCSCompare(e.field.identity.name, parameter.field.identity.name) &&
          !nonCSCompare(e.field.identity.id, parameter.field.identity.id)
      )
      if (checkParameterName) {
        inputError += 'Parameter name already exist'
      }
    }
    return inputError
  }

  /**
   * Get initial value of parameter
   * @param {object} [props]
   * @returns {*}
   */
  getInitial(props) {
    props = props || this.props
    //console.log("ParameterDialog:getInitialParameter", props.parameter, props.majorObject);
    if (props.parameter) {
      return JSON.parse(JSON.stringify(props.parameter))
    } else {
      let parameter = createEmptyObject('parameter')
      let order = 0

      if (props.majorObject) {
        // Count current max order and have next as order for new field
        props.majorObject.parameters.forEach((p) => {
          order = order < p.field.order ? p.field.order : order
        })
      }
      parameter.field.order = order + 1
      return parameter
    }
  }

  /**
   * Render content of parameter editor dialog
   */
  renderEditor = (editState) => {
    // get location names
    const locations = getJsonSpecRecords('location').map((l) => ({
      id: l.index,
      label: l.values[0],
      value: l.values[0]
    }))

    const activeOption = locations.find((l) => l.value === this.state.parameter.location)

    return (
      <div className="ParameterDialog">
        <FieldEditor
          field={this.state.parameter.field}
          inEditMode={editState > editableState.EDITABLE}
          onChange={this.updateField}
          majorObject={this.props.majorObject}
          actions={this.props.actions}
        />
        <div className="ParameterDialog__block row">
          <div className="col-xs-3">Location:</div>
          <div className={editState > editableState.EDITABLE ? 'col-xs-9' : 'col-xs-12'}>
            <EditableEntity
              dataProps={Object.assign({
                onChange: this.setLocation.bind(this),
                activeOptions: activeOption ? activeOption.id : null,
                placeholder: 'Choose location',
                hideCheckBox: true,
                multi: false
              })}
              dataType={{ name: 'enum', options: locations }}
              data={this.state.parameter.location}
              isEditable
              inEditMode={editState > editableState.EDITABLE}
            />
          </div>
        </div>
        {/*<div className="EditorDialog__block">*/}
        {/*<strong>Examples:</strong>*/}
        {/*</div>*/}
      </div>
    )
  }
}

ParameterDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  parameter: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
