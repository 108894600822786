import React, { Component, RefObject } from 'react'
import { dequal } from 'dequal'

import KeyTable from '../KeyTable/KeyTable'

import iRight from '../../resources/images/right-arrow-2x.png'
import iLeft from '../../resources/images/left-arrow-2x.png'

import '../../styles/LinkedKeyTables/LinkedKeyTables.scss'
import '../../styles/KeySettings/KeySettings.scss'

type LinkedKeyTablesProps = {
  selected: any[]
  items: any[]
  columns: any[]
  onChange: Function
  editable: boolean
}

type LinkedKeyTablesState = {
  srcFields: any[]
  dstFields: any[]
  selected: any
}

export class LinkedKeyTables extends Component<LinkedKeyTablesProps, LinkedKeyTablesState> {
  srctableRef: RefObject<any>
  dsttableRef: RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      srcFields: [],
      dstFields: [],
      selected: undefined
    }

    this.srctableRef = React.createRef()
    this.dsttableRef = React.createRef()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !dequal(nextProps, this.props) || !dequal(nextState, this.state)
  }

  srcFieldSelected(values) {
    ////console.log("v=",value,"state=",this.state.srcField,"===",value === this.state.srcField);
    console.log('srcFieldSelected', values)
    this.setState(
      {
        srcFields: values
      },
      () => {
        // onSrcFieldChange is never used
        //if (this.props.onSrcFieldChange)
        //  this.props.onSrcFieldChange(values);
      }
    )
  }

  dstFieldSelected(values) {
    console.log('dstFieldSelected', values)
    this.setState({
      dstFields: values
    })
  }

  onAddKeyField(key) {
    if (this.state.srcFields.length === 0) return

    //canAddField is never used
    /*
    if (this.props.canAddField && !this.props.canAddField(this.state.srcFields))
      return;
    */
    let newSelected = this.props.selected.slice()
    newSelected = newSelected.concat(this.state.srcFields)
    console.log('onAddKeyField', key, this.state.srcFields, newSelected)
    this.setState({ selected: newSelected }, () => {
      if (this.props.onChange) {
        this.props.onChange(newSelected)
      }
      this.selectNone()
    })
  }

  onDeleteKeyField(key) {
    if (this.state.dstFields.length === 0) {
      return
    }
    let newSelected = this.props.selected.slice()
    newSelected = newSelected.filter((el) => this.state.dstFields.indexOf(el) === -1)
    this.setState({ selected: newSelected }, () => {
      if (this.props.onChange) {
        this.props.onChange(newSelected)
      }
      this.selectNone()
    })
  }

  selectNone = () => {
    if (this.srctableRef.current) {
      this.srctableRef.current.selectNone()
    }
    if (this.dsttableRef.current) {
      this.dsttableRef.current.selectNone()
    }
  }

  onBlur = () => {
    //console.log("blur");
    setTimeout(() => this.selectNone(), 1000)
    // todo: get rid of setTimeout call
    // for some mysterious reason, onBlur is triggered by clicking the Add/Remove button (which is inside the container!)
  }

  render() {
    let srcData: any[] = []
    let dstData: any[] = []
    ////console.log("LinkedKeyTables", this.props.items);
    for (let i = 0; i < this.props.items.length; i++) {
      let item = this.props.items[i]
      ////console.log(item);
      if (this.props.selected.indexOf(item[0]) !== -1) dstData.push(item)
      else srcData.push(item)
    }
    return (
      <div className="LinkedKeyTable" tabIndex={0} onBlur={this.onBlur}>
        <div className="row">
          {this.props.editable ? (
            <div className="col-xs-5">
              <KeyTable
                ref="srctable"
                columns={this.props.columns}
                data={srcData}
                onSelect={this.srcFieldSelected.bind(this)}
              />
            </div>
          ) : null}
          {this.props.editable ? (
            <div className="col-xs-2 ">
              <div className="KeySettings__divButtons">
                <div className="KeySettings__arrowButton1" onClick={this.onAddKeyField.bind(this)}>
                  <img src={iRight} className="KeySettings__arrow" alt="arrow icon" />
                  <div className="KeySettings__arrowButton1__Text">Add</div>
                </div>
                <div className="KeySettings__arrowButton2" onClick={this.onDeleteKeyField.bind(this)}>
                  <img src={iLeft} className="KeySettings__arrow" alt="arrow icon" />
                  <div className="KeySettings__arrowButton2__Text">Remove</div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-xs-5">
            <KeyTable
              ref="dsttable"
              columns={this.props.columns}
              data={dstData}
              onSelect={this.dstFieldSelected.bind(this)}
            />
          </div>
        </div>
      </div>
    )
  }
}
