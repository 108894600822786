/**
 * Created by sashaberger on 11.02.2019.
 *
 * Control to manage version of edited object.
 *
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { dequal } from 'dequal'

import { getObjectListNew, getObjectNew, getRequestFromPath } from '../../helpers/api'
import {
  editableState,
  getFullUri,
  getVersionStatus,
  nonCSCompare,
  versionCompare,
  versionSort,
  versionToStr
} from '../../helpers/index'
import logger from '../../helpers/logger'
import { LocaleSelect } from '../LocaleSelect/LocaleSelect'
import { DraftDialog } from './DraftDialog'
import './EditorVersion.scss'
import EditorVersionButton from './EditorVersionButton'
import { FinalizeDialog } from './FinalizeDialog'

export class EditorVersion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      object: null, // Object of current version
      versions: null, // List of object versions

      isFinalizing: false,
      isCreatingDraft: false
    }
  }

  componentDidMount() {
    // Load version for object.
    if (this.props.object && this.props.version) {
      this.loadObjectVersions(this.props.object, this.props.versions)
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      (newProps.version && this.props.version && !versionCompare(newProps.version, this.props.version)) ||
      (newProps.object && this.props.object && !dequal(newProps.object, this.props.object)) ||
      (!newProps.versions && this.props.versions) ||
      (newProps.versions && !this.props.versions) ||
      (newProps.versions && this.props.versions && !dequal(newProps.versions, this.props.versions))
    ) {
      //logger.info("EditorVersion:componentWillReceiveProps", newProps, this.props);
      this.loadObjectVersions(newProps.object, newProps.versions)
    }
  }

  /**
   * Load list of versions of the object
   * @param {object} object - object versions we are looking for
   * @param {object} versions - current state of versions
   */
  loadObjectVersions = (object, versions) => {
    //logger.info("EditorVersion:loadObjectVersions", object, this.state, this.props);

    if (versions) {
      //logger.info("EditorVersion:VERSIONS", {object, versions},this.state, this.props);

      // Version options
      let options = versions.map((v, i) => {
        return {
          id: i,
          value: nonCSCompare(versionToStr(v), versionToStr(object.version)) ? object : v,
          label: v.version.major + '.' + v.version.minor + '.' + v.version.revision + ' ' + getVersionStatus(v)
        }
      })

      //logger.info("EditorVersion:OPTIONS", options);

      // Sort versions.
      options = options.sort((a, b) => versionSort(a.value.version, b.value.version))

      this.setState({ object: object, versions: versions, options: options })
    } else if (this.props.isSettings) {
      // Load list of object versions we need in dialog
      let path = getFullUri(object) + '/settings/versions'
      getObjectListNew(getRequestFromPath(path), 'setting').then(
        (result) => {
          //logger.info("EditorVersion:loadObjectVersions:settings:VERSIONS", path, result, versionToStr(object.settings.version));

          // Version options
          let options = result.map((v, i) => {
            let obj = nonCSCompare(versionToStr(v.version), versionToStr(object.settings.version)) ? object.settings : v
            return {
              id: i,
              value: obj,
              label:
                obj.version.major + '.' + obj.version.minor + '.' + obj.version.revision + ' ' + getVersionStatus(obj)
            }
          })

          // Sort versions.
          options = options.sort((a, b) => versionSort(a.value.version, b.value.version))

          this.setState({
            object: object.settings,
            versions: result,
            options: options
          })
          //logger.info("EditorVersion:loadObjectVersions:OPTIONS", options);
        },
        (error) => {
          logger.info('EditorVersion:loadObjectVersions:ERROR', error)
        }
      )
    } else {
      // Load list of object versions we need in dialog
      let path = getFullUri(object) + '/versions'
      getObjectListNew(getRequestFromPath(path), object.type).then(
        (result) => {
          //logger.info("EditorVersion:loadObjectVersions:VERSIONS", { object, versions, path, result }, this.state, this.props);

          // Version options
          let options = result.map((v, i) => {
            return {
              id: i,
              value: nonCSCompare(versionToStr(v), versionToStr(object.version)) ? object : v,
              label: v.version.major + '.' + v.version.minor + '.' + v.version.revision + ' ' + getVersionStatus(v)
            }
          })

          //logger.info("EditorVersion:loadObjectVersions:OPTIONS", options);

          // Sort versions.
          options = options.sort((a, b) => versionSort(a.value.version, b.value.version))

          this.setState({ object: object, versions: result, options: options })
          //logger.info("EditorVersion:loadObjectVersions:SORT", options);
        },
        (error) => {
          logger.info('EditorVersion:loadObjectVersions:ERROR', error)
        }
      )
    }
  }

  /**
   * Calculate action available for the version
   * @param {object} version - object version information
   * @return {string} versio status
   */
  getVersionAction = (version) => {
    let status = getVersionStatus(version)

    if (nonCSCompare(status, 'draft')) {
      return 'Finalize'
    } else if (nonCSCompare(status, 'finalized')) {
      return 'Approve'
    }
    if (nonCSCompare(status, 'approved')) {
      return 'Create draft'
    }
    return ''
  }

  /**
   * Selected version option
   * @param {object} version - object version information
   * @return {objrct} element of version options representing current object
   */
  getVersionOption(version) {
    let options = this.state.options
    //logger.info("EditorVersion:getVersionOption", version, options, this.props, this.state);

    if (!version || !options) {
      return null
    }

    let option = options.find(
      (v) =>
        v.value.version.major === version.major &&
        v.value.version.minor === version.minor &&
        v.value.version.revision === version.revision
    )

    return option ? option : null
  }

  /**
   * Is last version of dataset
   * @param {object} version - object version information
   * @return {biilean} version is not an last onee
   */
  isLastVersion(version) {
    let options = this.state.options
    //logger.info("EditorVersion:isLastVersion", version, options, this.props, this.state);

    if (!version || !options) {
      return false
    }

    let index = options.findIndex(
      (v) =>
        v.value.version.major === version.major &&
        v.value.version.minor === version.minor &&
        v.value.version.revision === version.revision
    )

    return index === 0 ? true : false
  }

  /**
   * Execute action appropriate for status
   * @param {string} action - action of specific version
   * @param {boolean} state - state of actionn
   */
  versionAction = (action, state) => {
    logger.info('EditorVersion:versionAction', action, state)

    if (nonCSCompare(action, 'Finalize')) {
      this.setState({ isFinalizing: state })
    } else if (nonCSCompare(action, 'Approve')) {
      this.props.onApprove()
    }
    if (nonCSCompare(action, 'Create draft')) {
      this.setState({ isCreatingDraft: state })
    }
  }

  /**
   * Execute changes of selected version
   * @param {string} action - action of specific version
   * @param {boolean} state - state of actionn
   */
  versionChange = (e) => {
    let options = this.state.options
    let object = this.props.object
    let version = options.find((v) => v.label === e)
    //console.log('EditVersion:versionChange', options, e, version, this.props);

    if (!version || !this.props.onVersionChange) {
      return
    }

    if (version.value.object && version.value.object.properties) {
      this.props.onVersionChange(version.value.version, version.value.object.properties)
      return
    }

    //logger.info("EditorVersion:versionChange:LOAD", version, this.state, this.props);

    if (this.props.isSettings) {
      // Load object version we need in dialog
      getObjectNew(
        getRequestFromPath(getFullUri(object)).settings.versions(versionToStr(version.value.version)),
        'setting'
      ).then(
        (result) => {
          //logger.info("EditorVersion:versionChange:VERSION", result);
          this.props.onVersionChange(version.value.version, result.object.properties)
        },
        (error) => {
          logger.error('EditorVersion:versionChange:ERROR', error)
        }
      )
    } else {
      // Load object version we need in dialog
      //let path = getFullUri(object) + '/versions/' + versionToStr(version.value.version);
      getObjectNew(
        getRequestFromPath(getFullUri(object)).versions(versionToStr(version.value.version)),
        object.type
      ).then(
        (result) => {
          //logger.info("EditorVersion:versionChange:VERSION", result);
          this.props.onVersionChange(version.value.version, result.object.properties)
        },
        (error) => {
          logger.error('EditorVersion:versionChange:ERROR', error)
        }
      )
    }
  }

  /**
   * Render function of version editor
   * @return
   */
  render() {
    let { className, version } = this.props

    let options = this.state.options
    let option = this.getVersionOption(version)
    let action = this.getVersionAction(option ? option.value : null)
    //logger.info("EditorVersion:render", option, options, action, this.props, this.state);

    return (
      <div className={'EditorVersion' + (className ? ' EditorVersion_' + className : '')}>
        <div className="EditorVersion__selectVersion">
          <div>
            {!this.isLastVersion(version) ? (
              <div className="EditorVersion__selectVersion__grayButton">{action}</div>
            ) : (
              <EditorVersionButton
                editText={action}
                className={
                  'EditorVersionButton__' +
                  action.replace(' ', '_') +
                  ' ' +
                  (this.props.isDisabled ? 'EditorVersionButton__disabled' : '')
                }
                onEdit={() => this.versionAction(action, true)}
              />
            )}
          </div>
          <div className="SelectVersion__content">
            <strong>Version: </strong>
            {option && options ? (
              <LocaleSelect
                options={options.map((v) => v.label)}
                currentOption={option.label}
                onClick={this.versionChange}
              />
            ) : null}
          </div>
        </div>
        {this.state.isFinalizing ? (
          <FinalizeDialog
            appState={this.props.appState}
            actions={this.props.actions}
            objectType={'finalize'}
            isEditable={editableState.EDITING}
            isVisible={this.state.isFinalizing}
            majorObject={this.props.object}
            currentVersion={version}
            previousVersion={options.length > 1 ? options[1].value.version : null}
            onClose={() => this.versionAction('Finalize', false)}
            onFinalize={this.props.onFinalize}
          />
        ) : null}
        {this.state.isCreatingDraft ? (
          <DraftDialog
            appState={this.props.appState}
            actions={this.props.actions}
            objectType={'draft'}
            isEditable={editableState.EDITING}
            isVisible={this.state.isCreatingDraft}
            majorObject={this.props.object}
            currentVersion={version}
            onClose={() => this.versionAction('Create draft', false)}
            onSave={this.props.onCreateDraft}
          />
        ) : null}
      </div>
    )
  }
}

EditorVersion.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object,
  className: PropTypes.string,
  isEditable: PropTypes.number,
  isSettings: PropTypes.number, // This is version for advance settings.
  object: PropTypes.object, // Current object to present version for
  version: PropTypes.object, // Version we present for current object
  versions: PropTypes.array, // List of versions for optimization
  onVersionChange: PropTypes.func,
  onCreateDraft: PropTypes.func,
  onFinalize: PropTypes.func,
  onApprove: PropTypes.func
}
