/**
 * Created by mailf on 19.08.2016.
 */
/*
const DocumentList = ({docs}) => {
  return (
    <ul>{docs.map((doc, index) => <li key={index}><a href={doc.url}>{doc.name}</a></li>)}</ul>
  );
};
*/
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { editableState } from '../../helpers'
import { authDownload } from '../../helpers/api'
import { getFullResourceUri, getJsonSpecRecords } from '../../helpers/index'
import iDownload from '../../resources/images/download-2x.png'
import iOpen from '../../resources/images/open-2x.png'
import { Badge } from '../Badge/Badge'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditButton } from '../EditButton/EditButton'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { FilterableContent } from '../FilterableContent/FilterableContent'
import { List } from '../List/List'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import { TypedTable } from '../NewTable/TypedTable'
import './DocumentList.scss'
import { getTailButtonLabel } from '../../helpers/helperComponents'

export class DocumentList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: true, // Edit button clicked, Edit mode active
      editingIndex: -1,
      visibleTagCount: 0, // 0, if all are visible
      showAllTags: false, // arrow is pressed and component is expanded
      isChanged: false, // cannot close modal window, if changed and not cancelled/saved
      deletedDocuments: [],
      uploadedDocuments: [],
      editorOpened: props.editorOpened,
      docs: props.docs,
      editState: props.isEditable ? editableState.EDITING : 0,
      addingDocumentUri: false
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState(
      {
        visibleTagCount: 0,
        showAllTags: false,
        editState: newProps.isEditable ? editableState.EDITING : 0,
        docs: newProps.docs.slice()
      },
      function () {
        //this.forceUpdate();
      }
    )
  }

  showLess() {
    this.setState({ showAllTags: false })
  }
  showMore() {
    this.setState({ showAllTags: true })
  }

  /**
   * Save changes
   * @param {boolean} [closeDialog] Do we need to close dialog
   */
  saveDocuments = (closeDialog) => {
    if (this.props.onSave) {
      this.props.onSave(this.state.docs, this.state.uploadedDocuments, this.state.deletedDocuments).then(
        () => {
          this.setState({
            isChanged: false,
            isEditing: false,
            isModal: !closeDialog
          })
        },
        (error) => {
          this.setState({
            error
          })
        }
      )
    }
  }

  cancelEdit() {
    this.setState({
      isEditing: false,
      docs: this.props.docs.slice(),
      isChanged: false
    })
  }

  clickTag(index) {
    this.setState({
      editingIndex: index
    })
  }

  /**
   * Shows upload dialog
   */
  addDocument = () => {
    document.getElementById('DocumentList_Upload').click()
  }

  /**
   * Shows field to enter document URI
   */
  addDocumentUri = () => {
    this.setState({ addingDocumentUri: true, editingName: '', editingUri: '' })
  }

  /**
   * Add new document and save files for upload during save
   */
  addNewFile(event) {
    let files = event.target.files
    // //console.log("Upload",event);
    if (files.length > 0) {
      let new_docs = this.state.docs.slice()
      new_docs.push({ identity: { name: files[0].name } })

      let new_documents = this.state.uploadedDocuments.slice()
      new_documents.push(files[0])

      this.setState({
        docs: new_docs,
        uploadedDocuments: new_documents,
        editingIndex: -1,
        isDeleting: false,
        editState: editableState.EDITED,
        isChanged: true
      })
    }
  }

  /**
   * Adds new document with URI from state
   */
  addNewUri = () => {
    let new_docs = this.state.docs.slice()
    new_docs.push({
      identity: { name: this.state.editingName },
      uri: this.state.editingUri
    })

    this.setState({
      docs: new_docs,
      //uploadedDocuments: new_documents,
      editingIndex: -1,
      isDeleting: false,
      editState: editableState.EDITED,
      isChanged: true,
      addingDocumentUri: false
    })
  }

  /**
   * Removes document from display list and adds it to delete queue
   * @param {string} docUri
   */
  deleteDocument = (docUri) => {
    let docToDelete
    const newDocList = this.state.docs.filter((doc) => {
      if (doc.uri === docUri) {
        docToDelete = doc
        return false
      }
      return true
    })
    const newDocsToDeleteList = docToDelete
      ? this.state.deletedDocuments.concat(docToDelete)
      : this.state.deletedDocuments

    return () => {
      this.setState({
        docs: newDocList,
        deletedDocuments: newDocsToDeleteList,
        editState: editableState.EDITED
      })
    }
  }

  /**
   * Converts document type to KascodeSelect option
   */
  documentTypeRecordToOption = (record) => {
    //console.log("documentTypeRecordToOption", record);
    return {
      id: parseInt(record.values[2]),
      label: record.values[0],
      value: record.values[0]
    }
  }

  /**
   * Renders URI editor for new files
   */
  renderEditUri() {
    return (
      <ModalWindow
        isOpen
        level="1"
        header="Add document URI"
        canClose
        onClose={() => this.setState({ addingDocumentUri: false })}
      >
        <div className="row DocumentList__editUriRow">
          <div className="col-xs-12">
            <EditableEntity
              data={this.state.editingName ? this.state.editingName : ''}
              dataType={{ name: 'string' }}
              dataProps={{
                placeholder: 'Enter document name',
                onChange: (e) => this.setState({ editingName: e.target.value })
              }}
              isEditable
              inEditMode
            />
          </div>
        </div>
        <div className="row DocumentList__editUriRow">
          <div className="col-xs-12">
            <EditableEntity
              data={this.state.editingUri ? this.state.editingUri : ''}
              dataType={{ name: 'string' }}
              dataProps={{
                placeholder: 'Enter uri',
                onChange: (e) => this.setState({ editingUri: e.target.value })
              }}
              isEditable
              inEditMode
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <button className="btn btn_blue DocumentList__button" onClick={this.addNewUri}>
              Add
            </button>
          </div>
          <div className="col-xs-6">
            <button
              className="btn btn_blue DocumentList__button"
              onClick={() => this.setState({ addingDocumentUri: false })}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalWindow>
    )
  }

  renderUpload() {
    return [
      this.state.docs.map((tag, index) => (
        <input
          className="TagsList__hiddenFileUpload"
          type="file"
          multiple="false"
          name={'document' + index}
          key={index}
          onChange={this.addNewFile.bind(this)}
        />
      )),
      <input
        className="TagsList__hiddenFileUpload"
        id="DocumentList_Upload"
        type="file"
        multiple="false"
        name={'document' + this.state.docs.length}
        key={this.state.docs.length}
        onChange={this.addNewFile.bind(this)}
      />
    ]
  }

  /**
   * Handles document edit, replaces edited document in local state
   */
  onEditDocument = (rowIndex, oldRow, newRow) => {
    let newDoc = this.state.docs[rowIndex]
    newDoc.identity = newRow
    newDoc.type = newRow.type
    this.setState({
      editState: editableState.EDITED,
      docs: this.state.docs.map((d, i) => {
        if (i === rowIndex) return newDoc
        return d
      })
    })
  }

  /**
   * Opens document in new tab to view
   */
  onViewDocument = (doc, index) => {
    const docUri = doc.uri
    const fullUri = getFullResourceUri(docUri)
    if (fullUri) window.open(fullUri)
    else window.alert('The document has not been uploaded yet.')
  }

  /**
   * Downloads document
   */
  onDownloadDocument = (doc, index) => {
    const docUri = doc.uri
    const fullUri = getFullResourceUri(docUri)
    if (fullUri) authDownload(doc.name, fullUri)
    else window.alert('The document has not been uploaded yet.')
  }

  onDeleteDocument = (doc, index) => {
    this.deleteDocument(doc.uri)()
  }

  renderDocumentListControls = () => {
    return (
      <div className="DocumentList__controls">
        <button className="btn_edit" onClick={this.addDocument}>
          + Upload
        </button>
        <button className="btn_edit" onClick={this.addDocumentUri}>
          + Add by uri
        </button>
      </div>
    )
  }

  /**
   * Renders dialog edit content: filtered table and controls
   */
  renderEditContent = () => {
    const docs = this.state.editState === editableState.EDITED ? this.state.docs : this.props.docs

    return (editState) => {
      return (
        <div>
          <div className="DocumentList__list">
            <FilterableContent
              dataFilters={[
                { name: 'search', width: 200 },
                { name: 'type', width: 200 }
              ]}
              data={docs.map((doc) => {
                return Object.assign({}, doc, {
                  name: doc.identity.name,
                  type: doc.type,
                  description: doc.identity.description
                })
              })}
              title="documentList"
            >
              <TypedTable
                columns={[
                  { name: 'name', type: { name: 'string' }, width: 200 },
                  {
                    name: 'type',
                    type: {
                      name: 'enum',
                      options: getJsonSpecRecords('documentType').map(this.documentTypeRecordToOption)
                    },
                    width: 200
                  },
                  { name: 'description', type: { name: 'string' }, width: 364 }
                ]}
                tailButtons={[
                  {
                    label: getTailButtonLabel('View'),
                    onClick: this.onViewDocument
                  },
                  {
                    label: getTailButtonLabel('Download'),
                    onClick: this.onDownloadDocument
                  },
                  {
                    label: getTailButtonLabel('Delete'),
                    onClick: this.onDeleteDocument
                  }
                ]}
                className="documents"
                isEditable
                onChangeRow={this.onEditDocument}
              />
            </FilterableContent>
          </div>
          {docs.length === 0
            ? editState >= editableState.EDITABLE
              ? 'No documents found. Upload documents that you want to attach'
              : 'No documents found'
            : null}
          {this.renderUpload()}
          {editState > editableState.EDITABLE ? this.renderDocumentListControls() : null}
        </div>
      )
    }
  }

  renderEditor = () => {
    //console.log("DocumentList:renderEditor", this.state, this.props);
    return (
      <EditorDialog
        onSave={this.saveDocuments}
        onEdit={() => {
          this.setState({
            isEditing: editableState.EDITING
          })
        }}
        onCancel={() => {
          this.setState({
            isEditing: editableState.EDITABLE,
            docs: this.props.docs,
            changed: false
          })
        }}
        onClose={() => {
          this.setState(
            {
              editorOpened: false
            },
            this.props.onClose ? this.props.onClose : undefined
          )
        }}
        modalTitle="Document list"
        editContent={this.renderEditContent(this.state.editState)}
        isVisible
        isEditable={this.state.editState}
        level={this.props.level}
      />
    )
  }

  renderListItem = (doc) => {
    //console.log("DocumentList:render", this.state, this.props);
    return (
      <Badge
        key={doc.identity.name}
        color="clear"
        className="DocumentList__listItem"
        onClick={() => {
          const fullUri = getFullResourceUri(doc.uri)
          if (fullUri) authDownload(doc.identity.name, fullUri)
          else window.alert('The document has not been uploaded yet.')
        }}
      >
        {doc.identity.name}
        <img src={iDownload} alt="" />
        <img
          src={iOpen}
          alt=""
          onClick={(e) => {
            const fullUri = getFullResourceUri(doc.uri)
            //          console.log("open", e, fullUri)
            window.open(fullUri)
            e.stopPropagation()
          }}
        />
      </Badge>
    )
  }

  render() {
    const docs = this.state.editState === editableState.EDITED ? this.state.docs : this.props.docs

    let style = {
      maxWidth: this.props.maxWidth ? this.props.maxWidth : 'none',
      maxHeight: this.props.maxHeight ? this.props.maxHeight : 'none'
    }
    if (this.state.showAllTags) {
      style['whiteSpace'] = 'normal'
      style['overflow'] = 'auto'
      style['maxHeight'] = 'none'
    }

    return (
      <span className="DocumentList">
        {this.props.isEditable ? (
          <EditButton
            onEdit={() => {
              this.setState({
                editorOpened: true
              })
            }}
            className="TagsList__editButton"
          />
        ) : null}
        {!this.props.hideList ? (
          <List
            title={this.props.title || 'Documents: '}
            items={docs.map(this.renderListItem)}
            defaultDisplayCount={1}
            onTitleClick={() => {
              this.setState({
                editorOpened: true
              })
            }}
            inline
          />
        ) : null}
        {this.state.editorOpened || this.props.forceOpened ? this.renderEditor() : null}
        {this.state.addingDocumentUri ? this.renderEditUri() : null}
      </span>
    )
  }
}

DocumentList.propTypes = {
  docs: PropTypes.array.isRequired,
  isEditable: PropTypes.bool,
  title: PropTypes.string.isRequired,
  addWithEmail: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  onEditTagValue: PropTypes.func,
  dark: PropTypes.bool,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  editorOpened: PropTypes.bool,
  objectURL: PropTypes.string,
  delimiter: PropTypes.string,
  hideList: PropTypes.bool,
  forceOpened: PropTypes.bool,
  level: PropTypes.number // Level of dialogs where current is rendered
}
