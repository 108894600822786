/**
 * Created by mailf on 14.06.2016.
 */
import PropTypes from 'prop-types'

import React from 'react'

import './EditorVersionButton.scss'

const EditorVersionButton = ({ onEdit, editText, className }) => {
  return (
    <div className={'EditorVersionButton ' + className} onClick={onEdit}>
      <span className="EditorVersionButton__icon"></span>
      <span className="EditorVersionButton__text">{editText}</span>
    </div>
  )
}

EditorVersionButton.propTypes = {
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  inEditMode: PropTypes.bool,
  hideSave: PropTypes.bool,
  editText: PropTypes.string,
  saveText: PropTypes.string,
  theme: PropTypes.string,
  noIcon: PropTypes.bool,
  className: PropTypes.string,
  isDialog: PropTypes.bool,
  cannotEdit: PropTypes.bool
}

export default EditorVersionButton
