/**
 * Created by kascode on 15.05.17.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { dequal } from 'dequal'

import { getObjectListByUsage, getObjectNew, getRequestFromPath } from '../../helpers/api'
import {
  deepCopy,
  deepSet,
  editableState,
  getFullUri,
  listOfMajorObjectsToEnumOptions,
  listOfPropertiesToEnumOptions,
  nonCSCompare,
  objectNameFromPathByType,
  optionSort,
  pathByType,
  referenceSort
} from '../../helpers/index'
import logger from '../../helpers/logger'
import { DataEditor } from './DataEditor'

export class PropertyEditor extends Component {
  constructor(props) {
    super(props)

    const path = getFullUri(this.props.majorObject)
    const organization = objectNameFromPathByType(path, 'organizations')

    this.state = {
      organization: organization,
      path: path,

      dataOptions: null // Options loaded.
    }
  }

  componentDidMount() {
    //logger.info("PropertyEditor:componentDidMount");
    this.loadData(this.props)
  }

  componentWillReceiveProps(newProps) {
    if (
      (newProps.field && !dequal(newProps.field, this.props.field)) ||
      (newProps.data && !dequal(newProps.data, this.props.data)) ||
      newProps.isEditable !== this.props.isEditable
    ) {
      this.loadData(newProps)
    }
  }

  /**
   * Load dataset with data
   * @param field - field with enumerator data specification
   * @return asynchronously add datasets to state
   */
  loadData = (props) => {
    //logger.info("PropertyEditor:loadData", field, this.state, this.props);
    //logger.info("DataEditor:loadData", field, this.state, this.props);
    if (props.isEditable > editableState.EDITABLE) {
      // Load options for property structures
      this.loadDataOptions(props)
    }
  }

  /**
   * Load dataset with enumerator specification. Work asynchronously
   * @param {string} props - current state of properties
   * @return asynchronously add datasets to state
   */
  loadDataOptions = (props) => {
    //logger.info("PropertyEditor:loadDataOptions", this.state, this.props);
    if (props.options && props.options.enumOptions) {
      //logger.info("PropertyEditor:loadDataOptions:EXTERNAL", this.props.options.enumOptions);
      this.setState({ dataOptions: props.options.enumOptions })
    } else if ((!props.data || !props.data.reference) && (!props.base || !props.base.reference)) {
      // Load list of objects we need in dialog
      let usage = props.options.usage || ''
      if (!usage && props.element && props.element.control) {
        usage = props.element.control.value['dataUsage'] || ''
      }
      let path = this.state.path
      //logger.info("PropertyEditor:loadDataOptions:REQUEST", path, usage, this.state, this.props);

      getObjectListByUsage(path, 'dataset', usage).then(
        (result) => {
          let options = listOfMajorObjectsToEnumOptions(result, this.props.majorObject, referenceSort)
          //logger.info("PropertyEditor:loadDataOptions:OBJECTS", result, options, this.props.majorObject);
          if (props.options.onFiler) {
            options = props.options.onFiler(options)
          }
          this.loadAdvanceProperties(props, usage, options)
        },
        (error) => {
          if (this.props.onError) {
            this.props.onError(
              'System',
              1003,
              error,
              { usage: usage, path: path },
              this.props.element,
              this.props.field
            )
          }
          //logger.warn("PropertyEditor:loadDataOptions:ERROR", error);
          this.setState({ dataOptions: [] })
        }
      )
    }
  }

  /**
   * Load advance properties from application
   * @param props - state of properties
   * @param usage - usage of daraset we upsate
   * @param list - list of data structures we can use.
   * @return asynchronously create list of advance properties
   */
  loadAdvanceProperties = (props, usage, list) => {
    // Path to major object we operate for.
    let path = this.state.path

    const pathSplit = path.substring(1).split('/')
    let appPath = pathByType(pathSplit, 'applications')
    let propPath = appPath + '/settings'
    //logger.info("PropertyEditor:loadAdvanceProperties", { props, usage, list, appPath, propPath }, this.state, this.props);

    // We have no application to look advance properties for.
    if (!appPath) {
      this.setState({
        dataOptions: list
      })
      return
    }

    // Load list of application advanced properties
    getObjectNew(getRequestFromPath(propPath), 'settings').then(
      (result) => {
        let properties = (result && result.object && result.object.properties ? result.object.properties : []).filter(
          (prop) => {
            if (!nonCSCompare(prop.type, 'Structure')) return false

            const pathSplit = prop.reference.substring(1).split('/')
            let dsPath = pathByType(pathSplit, 'datasets')

            //logger.info("PropertyEditor:loadAdvanceProperties:SETTING", dsPath);
            if (list.findIndex((op) => op.value.startsWith(dsPath)) === -1) return false

            return true
          }
        )
        let offset = list.length
        let appOptopns = listOfPropertiesToEnumOptions(properties)
          .sort(optionSort)
          .map((op) => {
            op.id += offset

            op.label = '$$' + op.label
            op.value = '$$' + op.value
            return op
          })
        let options = list.concat(appOptopns)
        //logger.info("PropertyEditor:loadAdvanceProperties:SETTINGS", { list, result, properties, options });

        this.setState({
          dataOptions: options
        })
      },
      (error) => {
        logger.warn('PropertyEditor:loadAdvanceProperties:ERROR', error)
      }
    )
  }

  /**
   * Value edited as a object of specified structure
   * @param value - new state of the object
   */
  onValueChange = (value, message, option) => {
    if (message) {
      if (this.state.change) this.state.change(null, message)
      return
    }

    let property = this.props.data ? deepCopy(this.props.data) : {}
    let base = this.props.base ? this.props.base : {}
    if (!value) {
      delete property.value
      delete property.reference
    } else {
      property.value = value

      // Inherit reference from base value.
      if (!property.reference && base.reference) {
        property.reference = base.reference
        if (base.subscription) {
          property.subscription = base.subscription

          // Description if we don't owerride previous
          if (property.identity && !property.identity.description) {
            property.identity.description = base.subscription
          }
        }
      }
    }

    //logger.info("PropertyEditor:onValueChange", value, property, this.state, this.props);
    if (this.props.options.onChange) {
      this.props.options.onChange(property, message, option)
    }
  }

  /**
   * New object selected for property
   * @param value - new state of the object
   */
  onReferenceChange = (value, message, option) => {
    if (message) {
      if (this.state.change) this.state.change(null, message)
      return
    }

    let property = this.props.data ? deepCopy(this.props.data) : {}
    let label = option && option.label ? option.label : ''
    // Name if we don't owerride previous
    if (!property.identity || !property.identity.name || property.identity.name[0] === '$') {
      ///logger.info("PropertyEditor:onReferenceChange:NAME", property, label);
      deepSet(property, 'identity.name', label[0] === '$' ? label : '$$$$' + label)
    }
    property.reference = option ? option.value : value
    // Subscription if we have one.
    if (option && option['subscription']) {
      property.subscription = option['subscription']

      // Description if we don't owerride previous
      if (!property.identity || !property.identity.description || property.identity.description[0] === '/') {
        deepSet(property, 'identity.description', option['subscription'])
      }
    }

    // Process value
    let name = property.identity && property.identity.name ? property.identity.name : ''

    if (name && name[0] === '$' && name[1] === '$' && name[2] !== '$') {
      // We are on value from application advanced settings
      property.value = option.object.value
    } else {
      // Remove value
      delete property.value
    }

    logger.info('PropertyEditor:onReferenceChange', { label, value, message, option, property }, this.state, this.props)
    if (this.props.options.onChange) {
      this.props.options.onChange(property, message, option)
    }
  }

  render() {
    // Property to render.
    let field = this.props.field
    let element = this.props.element
    if (!field || !element || !element.control) {
      logger.warn('PropertyEditor:render:NO-FIELD-ELEMENT', { field, element }, this.state, this.props)
      return null
    }

    let property = this.props.data || {}
    let base = this.props.base || {}

    let type = property.reference ? 'Structure' : base.reference ? 'Structure' : 'Enum'
    let isEditable = this.props.isEditable

    // Field for object selection.
    let editField = deepCopy(field)
    editField.identity.name = property.identity ? property.identity.name : ''
    editField.type = type
    if (type === 'Structure') {
      let name = ''
      // For stracture we render structure referenced by property
      if (property.reference) {
        editField.reference = property.reference
        editField.subscription = property.subscription
        if (
          !editField.subscription &&
          property.identity &&
          property.identity.description &&
          property.identity.description[0] === '/'
        ) {
          editField.subscription = property.identity.description
        }
        name = property.identity ? property.identity.name : ''
      } else if (base.reference) {
        editField.reference = base.reference
        editField.subscription = base.subscription
        if (
          !editField.subscription &&
          base.identity &&
          base.identity.description &&
          base.identity.description[0] === '/'
        ) {
          editField.subscription = base.identity.description
        }
        name = base.identity ? base.identity.name : ''
      }

      // We are on application based property.

      if (name && name[0] === '$' && name[1] === '$' && name[2] !== '$') {
        isEditable = isEditable > editableState.BROWSABLE ? editableState.EDITABLE : editableState.BROWSABLE
      }
    }

    // Options for object selection
    let editOptions = deepCopy(this.props.options)
    editOptions.onChange = property.reference || base.reference ? this.onValueChange : this.onReferenceChange
    if (type === 'Enum') {
      // For enum we render selector of posible datasets to select.
      if (isEditable > editableState.EDITABLE) {
        editOptions.placeholder = element.control.value['selectTip']
        editOptions.enumOptions = this.state.dataOptions
        if (!editOptions.enumOptions) {
          editOptions.enumOptions = []
          isEditable = editableState.LOADING
        }
      }
    }
    //logger.info("PropertyEditor:render", { type, field, element, property, editField, editOptions, isEditable }, this.state, this.props);

    return (
      <div className="PropertyEditor">
        {type === 'Enum' ? (
          <DataEditor
            appState={this.props.appState}
            actions={this.props.actions}
            class={this.props.class}
            options={editOptions}
            isEditable={isEditable}
            majorObject={this.props.majorObject}
            field={editField}
            element={element}
            onError={this.props.onError}
          />
        ) : null}

        {type === 'Structure' ? (
          <DataEditor
            appState={this.props.appState}
            actions={this.props.actions}
            class={this.props.class}
            options={editOptions}
            isEditable={isEditable}
            majorObject={this.props.majorObject}
            field={editField}
            data={property.value}
            base={base.value}
            element={element}
            onError={this.props.onError}
          />
        ) : null}
      </div>
    )
  }
}

PropertyEditor.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  class: PropTypes.string, // Class information for data dialog editor components.
  options: PropTypes.object, // Options for editing data.
  isEditable: PropTypes.number, // State of editor
  majorObject: PropTypes.object,
  field: PropTypes.object.isRequired, // Field for data to introduce
  data: PropTypes.string, // Data for field
  base: PropTypes.string, // Base data for field, it can be dataset if field is structure
  element: PropTypes.object, // Element of the view represented field
  dataset: PropTypes.object, // Dataset with data for types with references
  change: PropTypes.func // Change fanction from dialog.
}
