export function nonCSCompare(str1, str2) {
  if (!str1 && !str2) {
    // This is two empty strings case, we consider it equal
    return true
  } else if ((str1 && !(typeof str1 === 'string')) || (str2 && !(typeof str2 === 'string'))) {
    // We compare null or different types
    // console.warn('nonCSCompare one of arguments is falsy', str1, str2)
    return false
  } else if (!str1 || !str2) {
    // We are compare null with string
    return false
  } else {
    // Let's compare strings
    return str1.toLowerCase() === str2.toLowerCase()
  }
}
