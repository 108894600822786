import * as types from '../constants/ActionTypes'

const initialState = {
  sidebarCollapse: JSON.parse(localStorage.getItem('collapseSidebar')) || true,
  experimentalDesign: JSON.parse(localStorage.getItem('experimentalDesign')) || false,
  tabName: '',
  theme: ''
}

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SIDEBAR_COLLAPSE:
      return {
        ...state,
        sidebarCollapse: !state.sidebarCollapse
      }
    case types.EXPERIMENTAL_DESIGN:
      return {
        ...state,
        experimentalDesign: !state.experimentalDesign
      }

    case types.TAB_NAME:
      return {
        ...state,
        tabName: action.payload.tabName,
        theme: action.payload.theme
      }

    default:
      return state
  }
}
