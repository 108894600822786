import { nonCSCompare } from '../../helpers'

export enum OrganizationRoleEnum {
  ARCHITECT = 'Architect',
  DEVELOPER = 'Developer',
  ANALYST = 'Analyst',
  NOROLE = 'NoRole'
}

export enum AccessEnum {
  PUBLIC = 'Public',
  EXTERNAL = 'External',
  INTERNAL = 'Internal',
  PRIVATE = 'Private'
}

export const getRoleInfo = (access: AccessEnum, parentRole?: OrganizationRoleEnum, orgRole?: OrganizationRoleEnum) => {
  let ret: boolean | string | undefined = false
  let roleTip = ''

  if (nonCSCompare(access, AccessEnum.PUBLIC)) {
    if (
      nonCSCompare(parentRole, OrganizationRoleEnum.DEVELOPER) ||
      nonCSCompare(parentRole, OrganizationRoleEnum.ARCHITECT)
    ) {
      ret = parentRole
      roleTip = 'Object has Access=Public and your role ' + parentRole + ' is defined in parent object'
    } else {
      ret = OrganizationRoleEnum.ANALYST
      roleTip = 'Object has Access=Public and you have no role in parent object'
    }
  } else if (nonCSCompare(access, AccessEnum.EXTERNAL)) {
    ret = parentRole
    roleTip = 'Object has Access=External and you have role ' + parentRole + ' defined in parent object'
  } else if (nonCSCompare(access, AccessEnum.INTERNAL)) {
    if (nonCSCompare(orgRole, OrganizationRoleEnum.ARCHITECT)) {
      ret = OrganizationRoleEnum.ARCHITECT
      roleTip = 'Object has Access=Internal and you are organization-level Architect'
    } else {
      ret = OrganizationRoleEnum.ANALYST
      roleTip = 'Object has Access=Internal and you have no role defined in current object'
    }
  } else if (nonCSCompare(access, AccessEnum.PRIVATE)) {
    if (nonCSCompare(orgRole, OrganizationRoleEnum.ARCHITECT)) {
      ret = OrganizationRoleEnum.ARCHITECT
      roleTip = 'Object has Access=Private and you are organization-level Architect'
    } else {
      ret = false
      roleTip = 'Object has Access=Private and you have no role defined in current object'
    }
  }

  if (ret === OrganizationRoleEnum.NOROLE) ret = false
  return { role: ret, tip: roleTip }
}
