/**
 * Created by kascode on 15.05.17.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { dequal } from 'dequal'

import { API, getObjectListByUsage, getObjectNew, getRequestFromPathVersioned } from '../../helpers/api'
import {
  datasetToEnumOptions,
  deepCopy,
  displayDatasetUri,
  displayReferenceUri,
  editableState,
  findByIdentity,
  getFullUri,
  getVersionStatus,
  listOfMajorObjectsToEnumOptions,
  listOfMinorObjectsToEnumOptions,
  nonCSCompare,
  objectNameFromPathByType,
  pluralTypeForms,
  referenceSort,
  storageObjectUri,
  storageSubscriptionUri,
  versionCompare
} from '../../helpers/index'
import logger from '../../helpers/logger'
import { deepGet } from '../../resources/lib/metadata'
import { DataEditor } from './DataEditor'

export class ReferenceEditor extends Component {
  constructor(props) {
    super(props)

    const path = getFullUri(this.props.majorObject)
    const organization = objectNameFromPathByType(path, 'organizations')
    //const application = pathByType(path.substring(1).split('/'), 'applications');

    this.state = {
      organization: organization,
      //application: application,
      path: path,

      dataOptions: null, // Options loaded.

      objectStructure: null, // Structure loaded.
      objectDataset: null, // Dataset to use for field selection.
      objectOptions: null, // Options from loaded structure loaded.

      objectReference: null, // Reference of loaded structure.
      objectSubscription: null, // Subscription of loaded structure.

      apiError: null
    }

    this._loadingStructureReference = false
    this._loadingStructureSubscription = false
  }

  componentDidMount() {
    //logger.info("ReferenceEditor:componentDidMount");
    this.loadData(this.props)
    API.on('error', (error) => {
      //referenceOptions.warning = error.statusMessage;
      logger.info(error)
      this.setState({ apiError: error.statusText })
    })
  }

  componentWillReceiveProps(newProps) {
    //logger.info("ReferenceEditor:componentWillReceiveProps", newProps, this.state, this.props);
    //logger.info("ReferenceEditor:componentWillReceiveProps:TRACE", Error().stack);
    if (
      (newProps.field && !dequal(newProps.field, this.props.field)) ||
      (newProps.data && !dequal(newProps.data, this.props.data)) ||
      newProps.isEditable !== this.props.isEditable
    ) {
      this.loadData(newProps)
    }
    // API.on('error', (error) => {
    //   //referenceOptions.warning = error.statusMessage;
    //   logger.info(error);
    // });
  }

  /**
   * Load dataset with data
   * @param field - field with enumerator data specification
   * @return asynchronously add datasets to state
   */
  loadData = (props) => {
    //logger.info("ReferenceEditor:loadData", props, this.state, this.props);
    if (props.isEditable > editableState.EDITABLE) {
      // Load options for property structures
      this.loadDataOptions(props)
      this.loadDataStructure(props)
    }
  }

  /**
   * Load dataset with enumerator specification. Work asynchronously
   * @param {string} props - current state of properties
   * @return asynchronously add datasets to state
   */
  loadDataOptions = (props) => {
    //logger.info("ReferenceEditor:loadDataOptions", this.state, this.props);
    if (props.options && props.options.enumOptions) {
      //logger.info("ReferenceEditor:loadDataOptions:EXTERNAL", this.props.options.enumOptions);
      this.setState({ dataOptions: props.options.enumOptions })
    } else {
      // We already have options
      if (
        this.state.dataOptions &&
        props.options.type === this.props.options.type &&
        props.options.usage === this.props.options.usage
      ) {
        //logger.info("ReferenceEditor:loadDataOptions:TRACE", Error().stack);
        return
      }

      // Load list of objects we need in dialog
      let type = props.options.type || ''
      if (!type && props.element && props.element.control) {
        type = props.element.control.value['referenceType'] || ''
      }

      let usage = props.options.usage || ''
      if (!usage && props.element && props.element.control) {
        usage = props.element.control.value['referenceUsage'] || ''
      }

      let filter = ''
      if (!filter && props.element && props.element.control) {
        filter = props.element.control.value['objectFilter'] || ''
      }

      // We can look into format of the field is it DHURI
      let field = this.props.field
      if (!type && !usage && field && nonCSCompare(field.type, 'reference')) {
        let format = field.format
        if (format && format.startsWith('DHURI(')) {
          let data = format.substring(6).split(',')
          type = data && data.length > 0 && data[0] ? data[0].trim() : ''
          usage = data && data.length > 1 && data[1] ? data[1].trim().slice(0, -1) : ''
        }
      }
      if (!type) {
        // We don't have options defined.
        return
      }

      let state = ''
      if (!state && props.element && props.element.control) {
        state = props.element.control.value['referenceState']
      }
      if (state === 'Non') {
        // We don't need to reqiest options.
        return
      }

      let path = this.state.path
      logger.info('ReferenceEditor:loadDataOptions:REQUEST', { props, type, usage, state }, this.state, this.props)

      getObjectListByUsage(this.state.path, type, usage, null, state === 'Local').then(
        (result) => {
          let options = listOfMajorObjectsToEnumOptions(result, this.props.majorObject, referenceSort)
          if (props.options.onFiler) {
            options = props.options.onFiler(options)
          } else if (this.props.majorObject && filter && this.props.majorObject[filter]) {
            // Remove major object from list base on list of already existing objects
            let list = this.props.majorObject[filter]
            let id = this.props.data && this.props.data.identity ? this.props.data.identity.id : ''
            //logger.info("ReferenceEditor:loadDataOptions:FILTER", { type, usage, filter, list, id, result, options }, this.state, this.props);

            options = options.filter(
              (el) =>
                !el ||
                nonCSCompare(el.object.identity.id, id) ||
                list.findIndex((item) => nonCSCompare(el.object.identity.id, item.identity.id)) === -1
            )
          } else if (this.props.majorObject && nonCSCompare(this.props.majorObject.object.type, type)) {
            // Remove major object from list
            options = options.filter(
              (el) => !el || !nonCSCompare(el.object.identity.id, this.props.majorObject.identity.id)
            )
          }
          //logger.info("ReferenceEditor:loadDataOptions:OBJECTS", { type, usage, filter, result, options }, this.state, this.props);

          this.setState((prevState) => {
            if (prevState.objectStructure) {
              // Let's update options base on dataset
              this.replaceOption(options, prevState.objectStructure)
            }
            return { dataOptions: options }
          })
        },
        (error) => {
          if (this.props.onError) {
            this.props.onError(
              'System',
              1001,
              error,
              { type: type, usage: usage, path: path },
              this.props.element,
              this.props.field
            )
          }
          logger.warn('ReferenceEditor:loadDataOptions:ERROR', error)
          this.setState({ dataOptions: [] })
        }
      )
    }
  }

  /**
   * Load dataset with structure specification
   * @param props - state of properties
   * @return asynchronously add datasets to state
   */
  loadDataStructure = (props) => {
    // Reference have valid uri for data to download.
    let reference = props.data
    //logger.info("ReferenceEditor:loadDataStructure", reference, props, this.state, this.props);

    if (props.dataset) {
      //logger.info("DataEditor:loadDataStructure", field, dataset);
      // Find type
      let list = props.options.list
      if (!list && props.element && props.element.control) {
        list = props.element.control.value['objectList']
        if (!list) {
          list = props.element.control.value['objectType'] + 's'
        }
      }

      let usage = props.options.usage || ''
      if (!usage && props.element && props.element.control) {
        usage = props.element.control.value['referenceUsage'] || ''
      }

      let options = nonCSCompare(usage, 'enum')
        ? datasetToEnumOptions(props.dataset)
        : listOfMinorObjectsToEnumOptions(deepGet(props.dataset, list))
      //logger.info("ReferenceEritor:loadObjectStructure:EXTERNAL", props.dataset, list, options);

      this.setState({
        objectStructure: props.dataset,
        objectOptions: options,
        objectReference: null,
        objectSubscription: null
      })
    } else if (reference && reference.reference && reference.reference.length > 0) {
      // We already have options
      if (
        ((this.state.objectStructure &&
          this.state.objectReference === reference.reference &&
          (!reference.subscription || this.state.objectSubscription === reference.subscription)) ||
          (!this.state.objectStructure &&
            this._loadingStructureReference === reference.reference &&
            (!reference.subscription || this._loadingStructureSubscription === reference.subscription))) &&
        props.options.type === this.props.options.type &&
        props.options.usage === this.props.options.usage &&
        props.options.list === this.props.options.list
      ) {
        //logger.info("ReferenceEditor:loadDataOptions:TRACE", Error().stack);
        return
      }

      // Mark we loading it already
      this._loadingStructureReference = reference.reference
      this._loadingStructureSubscription = reference.subscription

      // Find type
      let type = props.options.type
      if (!type && props.element && props.element.control) {
        type = props.element.control.value['referenceType']
      }
      if (!type) {
        // We don't have options defined.
        return
      }

      // Find collection name
      let list = props.options.list
      if (!list && props.element && props.element.control) {
        list = props.element.control.value['objectList']
        if (!list) {
          list = props.element.control.value['objectType'] + 's'
        }
      }

      // Find type of the collection
      let usage = props.options.usage || ''
      if (!usage && props.element && props.element.control) {
        usage = props.element.control.value['referenceUsage'] || ''
      }

      // We will request exact version we chose from list.
      let path = reference.reference
      let req = getRequestFromPathVersioned(path)

      logger.info(
        'ReferenceEditor:loadDataStructure:LOAD',
        props.element && props.element.identity ? props.element.identity.name : '',
        { type, list, usage, reference, props },
        this.state,
        this.props
      )
      //logger.info("ReferenceEditor:loadDataStructure:TRACE", Error().stack);

      // Load structure we need for dialog
      getObjectNew(req, type).then(
        (result) => {
          // Check if we need subscription for filtering.
          let options = null
          let dataset = null
          if (nonCSCompare(usage, 'enum')) {
            // Enumerator we don't need subscription information.
            options = datasetToEnumOptions(result)
          } else {
            // We first get list directly from object.
            options = listOfMinorObjectsToEnumOptions(deepGet(result, list))

            // Check if we need filter from subscription.
            let subList = null
            if (!reference.subscription || reference.subscription.length === 0) {
              // No subscription, let's check if we need field selector.
              if (type.toLowerCase() === 'dataset' && (list === 'fields' || list.endsWith('.fields'))) {
                // We can use dataset as source of field selection.
                dataset = result
              }
            } else if (type.toLowerCase() === 'dataset') {
              // Layouts of fields of dataset from subscription
              if (list === 'layouts') {
                subList = 'layoutNames'
              } else if (list === 'fields' || list.endsWith('.fields')) {
                subList = 'fieldNames'
              }
            } else if (type.toLowerCase() === 'interface') {
              // Operations of interfaces from subscriptions
              if (list === 'operations') {
                subList = 'operationNames'
              }
            }

            if (subList !== null) {
              // Need subscription to define options.
              let subPath = reference.subscription
              let subReq = getRequestFromPathVersioned(subPath, true)

              // Load structure we need for dialog
              getObjectNew(subReq, 'subscription').then(
                (subscription) => {
                  // Filter base on data in subscription
                  let arr = pluralTypeForms.get(type.toLowerCase())
                  let obj = findByIdentity(subscription[arr], result)

                  let filter = obj ? deepGet(obj, subList) : null
                  if (filter && filter.length > 0) {
                    options = options.filter((op) => filter.indexOf(op.label) !== -1)
                  }

                  //logger.info("ReferenceEritor:loadObjectStructure:SUBSCRIPTION", { type, usage, list, subList, reference, result, subscription, arr, obj, filter, options }, this.state, this.props);

                  this.setState(
                    (prevState) => {
                      if (prevState.dataOptions) {
                        // Let's update options base on dataset
                        this.replaceOption(prevState.dataOptions, result)
                      }
                      return {
                        objectStructure: result,
                        objectDataset: null,
                        objectOptions: options,
                        objectReference: path,
                        objectSubscription: subPath
                      }
                    },
                    () => {
                      this._loadingStructureReference = false
                      this._loadingStructureSubscription = false
                    }
                  )
                },
                (error) => {
                  if (this.props.onError) {
                    this.props.onError(
                      'System',
                      1002,
                      error,
                      { type: type, path: path },
                      this.props.element,
                      this.props.field
                    )
                  }
                  logger.warn(
                    'ReferenceEritor:loadObjectStructure:ERROR',
                    error,
                    { type: type, path: path },
                    this.props.element,
                    this.props.field
                  )
                }
              )
              return
            }
          }

          logger.info(
            'ReferenceEritor:loadObjectStructure:STRUCTURE',
            { type, path, reference, result, list, options },
            this.state,
            this.props
          )

          this.setState(
            (prevState) => {
              if (prevState.dataOptions) {
                // Let's update options base on dataset
                this.replaceOption(prevState.dataOptions, result)
              }
              return {
                objectStructure: result,
                objectDataset: dataset,
                objectOptions: options,
                objectReference: path,
                objectSubscription: null
              }
            },
            () => {
              this._loadingStructureReference = false
              this._loadingStructureSubscription = false
            }
          )
        },
        (error) => {
          if (this.props.onError) {
            this.props.onError('System', 1002, error, { type: type, path: path }, this.props.element, this.props.field)
          }
          logger.warn(
            'ReferenceEritor:loadObjectStructure:ERROR',
            error,
            { type: type, path: path },
            this.props.element,
            this.props.field
          )
        }
      )
    }
  }

  /**
   * Replace option in option list for current version
   * @param options - current list of options
   * @param opject - new state of the object
   */
  replaceOption = (options, object) => {
    if (!options || !object) {
      return null
    }

    //logger.info("ReferenceEditor:replaceOption", this.props.element && this.props.element.identity ? this.props.element.identity.name : '', { options, object }, this.state, this.props);

    // Update option list with new version for reference selection.
    let currOption = options.find(
      (op) => op.object && op.object.identity && object.identity && op.object.identity.id === object.identity.id
    )
    if (currOption && currOption.object && !versionCompare(currOption.object.version, object.version)) {
      let record = currOption.object

      record.object = object.object
      record.version = object.version

      // For object we reference by URI
      currOption.label = displayDatasetUri(record)
      currOption.value = storageObjectUri(record, this.props.majorObject)

      // For subscription we can initialize subscription.
      if (record['subscription']) {
        currOption.subscription = storageSubscriptionUri(record, this.props.majorObject)
      }

      currOption.status = getVersionStatus(record)

      if (record.identity) {
        currOption.description = (record.identity.description || '') + '  Status: ' + currOption.status
      }
    }
    return currOption
  }

  /**
   * Value edited as a object selected
   * @param value - new state of the object
   */
  onObjectChange = (value, message, option) => {
    if (message) {
      if (this.state.change) this.state.change(null, message)
      return
    }

    // We add reference to specific object
    let reference = this.props.data ? deepCopy(this.props.data) : {}
    let element = this.props.element
    let object = element.control.value['objectType']
    reference[object] = option ? option.label : value

    // We can now update aditional infor about reference, when required.
    let structure = this.state.objectStructure
    if (reference.identity && structure && structure.identity) {
      // lets add object information.
      reference.identity = structure.identity
    }
    if (reference.version && structure && structure.version) {
      // lets add object information.
      reference.version = structure.version
    }

    //logger.info("ReferenceEditor:onObjectChange", { value, option, reference }, this.state, this.props);
    if (this.props.options.onChange) {
      this.props.options.onChange(reference, message, option, structure)
    }
  }

  /**
   * New object selected for property
   * @param value - new state of the object
   */
  onReferenceChange = (value, message, option) => {
    if (message) {
      if (this.state.change) this.state.change(null, message)
      return
    }

    let reference = this.props.data ? deepCopy(this.props.data) : {}
    let field = this.props.field
    if (field && nonCSCompare(field.type, 'reference')) {
      // We are on reference type, it mean our structure is identity
      reference.name = option ? option.value : value
      reference.description = ''

      // Subscription if we have one.
      if (option && option['subscription']) {
        reference.description = option['subscription']
      }
    } else {
      // We are on reference property, it mean our structure is property
      reference.reference = option ? option.value : value
      reference.subscription = ''

      // Subscription if we have one.
      if (option && option['subscription']) {
        reference.subscription = option['subscription']
      }
    }

    // Remove information about actual referece to object
    let element = this.props.element
    if (element && element.control) {
      let object = element.control.value['objectType'].toLowerCase()
      delete reference[object]
    }

    // Load structure to have options of objects to select.
    this.setState(
      {
        objectStructure: null,
        objectDataset: null,
        objectOptions: null,
        objectReference: null,
        objectSubscription: null
      },
      () => {
        // Load new state of data stracture.
        //logger.info("ReferenceEditor:onReferenceChange:LOAD-STRUCTURE", { value, option, element, reference }, this.state, this.props);
        this.loadDataStructure(this.props)
      }
    )

    //logger.info("ReferenceEditor:onReferenceChange", { value, option, element, reference }, this.state, this.props);
    if (this.props.options.onChange) {
      this.props.options.onChange(reference, message, option)
    }
  }

  /**
   * Version change for object selected as reference
   * @param value - new state of the object
   */
  onVersionChange = (value, message, option) => {
    if (message) {
      if (this.state.change) this.state.change(null, message)
      return
    }

    let reference = this.props.data ? deepCopy(this.props.data) : {}
    let field = this.props.field
    if (field && nonCSCompare(field.type, 'reference')) {
      // We are on reference type, it mean our structure is identity
      reference.name = option ? option.value : value
    } else {
      // We are on reference property, it mean our structure is property
      reference.reference = option ? option.value : value
    }

    // Remove information about actual referece to object
    let element = this.props.element
    if (element && element.control) {
      let object = element.control.value['objectType'].toLowerCase()
      delete reference[object]
    }

    // Update option list with new version for reference selection.
    let currOption = this.replaceOption(this.state.dataOptions, option.object)

    // Load structure to have options of objects to select.
    this.setState(
      {
        objectStructure: null,
        objectDataset: null,
        objectOptions: null,
        objectReference: null,
        objectSubscription: null
      },
      () => {
        // Load new state of data stracture.
        this.loadDataStructure(this.props)
      }
    )

    //logger.info("ReferenceEditor:onVersionChange", { value, option, element, reference, currOption }, this.state, this.props);
    if (this.props.options.onChange) {
      this.props.options.onChange(reference, message, currOption)
    }
  }

  render() {
    // Property to render.
    let field = this.props.field
    let element = this.props.element
    if (!field) {
      logger.warn('ReferenceEditor:render:NO-FIELD', { field, element }, this.state, this.props)
      field = { type: 'Structure' }
    }

    let reference = this.props.data || {}

    let type = 'Reference'
    let isRefEditable = this.props.isEditable
    let isObjEditable = this.props.isEditable

    let isReference = field && nonCSCompare(field.type, 'reference')
    let isElement = element && element.control

    // Field for object selection.
    let editField = deepCopy(field)
    editField.type = this.props.options && this.props.options.enumOptions ? 'Enum' : type

    // Field for object selection.
    // Options for reference object selection
    let referenceState = isElement ? element.control.value['referenceState'] : ''
    if (referenceState === 'Non') {
      isRefEditable = editableState.BROWSABLE
    }

    let referenceOptions = deepCopy(this.props.options)
    let referenceData = ''
    referenceOptions.placeholder = isElement ? element.control.value['selectReferenceTip'] : ''
    referenceOptions.onChange = this.onReferenceChange
    if (isRefEditable > editableState.EDITABLE) {
      referenceOptions.enumOptions = this.state.dataOptions
      if (!referenceOptions.enumOptions) {
        referenceOptions.enumOptions = []
        isRefEditable = editableState.LOADING
      }
      if (this.state.objectStructure && this.state.objectStructure.version) {
        let referenceStatus = getVersionStatus(this.state.objectStructure)
        referenceOptions.warning =
          referenceStatus !== 'draft' ? '' : 'Warning! ' + this.state.objectStructure.object.type + ' is not finalized!'
        //logger.info("ReferenceEditor:render:WARNING", element.identity.name, { referenceStatus, referenceElement, referenceOptions, referenceData }, this.state, this.props);
      }

      // Let's select one we can from option. This is in case we don't have full info
      // Inside reference. This is case we missed subscripsion info.
      if (referenceOptions.enumOptions && referenceOptions.enumOptions.length > 0) {
        let referenceItem = null
        if (isReference && reference.name && !reference.description) {
          referenceItem = referenceOptions.enumOptions.find((item) => item.value === reference.name)
        } else if (reference.reference && !reference.subscription) {
          referenceItem = referenceOptions.enumOptions.find((item) => item.value === reference.reference)
        }
        referenceData = referenceItem ? referenceItem.label : ''
      }
    }

    if (!referenceData) {
      // We not in edit mode and can use only wheat we have in subscription.
      // First case it for properties, second for components and references.
      if (isReference && reference.name) {
        referenceData = displayReferenceUri(reference.name, reference.description)
      } else if (reference.reference) {
        referenceData = displayReferenceUri(reference.reference, reference.subscription)
      }
    }

    let referenceElement = null
    if (isElement) {
      referenceElement = deepCopy(element)
      referenceElement.text = element.control.value['objectLabel']
      if (element.control.value['referenceDescription']) {
        referenceElement.identity.description = element.control.value['referenceDescription']
      }
    }

    let objectDataset = null
    let objectField = null
    if (editField) {
      objectField = deepCopy(editField)
      objectField.type = 'Enum'
      if (isElement && nonCSCompare(element.control.value['objectState'], 'multiple')) {
        // We select subset from collection.
        objectField.type = 'Selector'

        // We can use dataset as source of fields to select.
        if (this.state.objectDataset) {
          objectField.type = 'FieldSelector'

          objectDataset = this.state.objectDataset
        }
      }
    }

    // Options for reference object selection
    let objectOptions = deepCopy(this.props.options)
    let objectData = isElement ? reference[element.control.value['objectType']] : null
    objectOptions.placeholder = isElement ? element.control.value['selectObjectTip'] : ''
    objectOptions.onChange = this.onObjectChange
    if (isObjEditable > editableState.EDITABLE) {
      objectOptions.enumOptions = this.state.objectOptions
      if (!objectOptions.enumOptions) {
        objectOptions.enumOptions = []
        isObjEditable = editableState.LOADING
      }
    }
    //logger.info("ReferenceEditor:render", element.identity.name, { field, element, reference, referenceElement, referenceOptions, referenceData, isRefEditable, objectData, objectOptions, isObjEditable }, this.state, this.props);
    //logger.info("ReferenceEditor:render:TRACE", Error().stack);

    if (this.state.apiError) {
      referenceOptions.warning = this.state.apiError
    }

    return (
      <div className="ReferenceEditor">
        {!referenceElement || referenceElement.text ? (
          <DataEditor
            appState={this.props.appState}
            actions={this.props.actions}
            class={this.props.class}
            options={referenceOptions}
            isEditable={isRefEditable}
            majorObject={this.props.majorObject}
            field={editField}
            data={referenceData}
            element={referenceElement}
            onVersionChange={this.onVersionChange}
            onAdjust={this.props.onAdjust}
            onError={this.props.onError}
          />
        ) : null}

        {!isReference && referenceData && element.text ? (
          <DataEditor
            appState={this.props.appState}
            actions={this.props.actions}
            class={this.props.class}
            options={objectOptions}
            isEditable={isObjEditable}
            majorObject={this.props.majorObject}
            field={objectField}
            data={objectData}
            element={element}
            dataset={objectDataset}
            onAdjust={this.props.onAdjust}
            onError={this.props.onError}
          />
        ) : null}
      </div>
    )
  }
}

ReferenceEditor.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object,
  class: PropTypes.string, // Class information for data dialog editor components.
  options: PropTypes.object, // Options for editing data.
  isEditable: PropTypes.number, // State of editor
  majorObject: PropTypes.object,
  field: PropTypes.object.isRequired, // Field for data to introduce
  data: PropTypes.string, // Data for field
  element: PropTypes.object, // Element of the view represented field
  dataset: PropTypes.object, // Dataset with data for types with references
  change: PropTypes.func // Change fanction from dialog.
}
