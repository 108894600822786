/* eslint-disable no-unused-vars */
export const getToken = (success, error) => {
  const { hash } = window.location

  if (hash && hash.includes('access_token')) {
    const [accessTokenBlock, idTokenBlock, refreshTokenBlock] = window.location.hash.split('&')

    const accessToken = accessTokenBlock.split('=')[1]

    const idToken = accessTokenBlock.split('=')[1]

    localStorage.setItem('currentUserToken', idToken)

    success()
  } else {
    console.error('No token in url hash')

    error()
  }
}

export const authorize = () => (document.location.href = window.AUTH_URL)
