import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import logger from '../../helpers/logger'
import { Loader } from '../Loader/Loader'
import { API, getApiResource, getObjectListNew, getRequestFromPath, singularType } from '../../helpers/api'
import { versionToStr, versionSort, issueSort, getFullUri } from '../../helpers/index'
import ScrollArea from 'react-scrollbar'

import './ObjectItemSearch.scss'

const OPTION_HEIGHT = 26.4
const MAX_RESULT_LINES = 20
const MAX_OPTIONS_HEIGHT = 1000

function isInteger(value) {
  return value.match(/^[0-9]+$/)
}

class ObjectItemSearch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      path: '',
      pathType: '',
      editText: '',
      editItems: [],
      editHeight: 0,
      editActive: false,
      editFocused: false
    }
  }

  componentDidMount() {
    let props = this.props
    //logger.info("ObjectItemSearch:componentDidMount", props);
    this.getState(props.query, null, null, props.path, props.type).then((newState) => {
      this.setState(newState)
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (
      (this.props.query !== nextProps.query && this.state.editText !== nextProps.query) ||
      (this.props.path !== nextProps.path && this.state.path !== nextProps.path)
    ) {
      //logger.info("ObjectItemSearch:componentWillReceiveProps", nextProps, this.state, this.props);
      this.getState(nextProps.query, false, null, nextProps.path, nextProps.type).then((newState) => {
        this.setState(newState)
      })
    }
  }

  /**
   * Get state from new path
   * @return
   */
  getState = async (query, active, focused, path, type, loaded) => {
    let state = Object.assign({}, this.state)

    //logger.info("ObjectItemSearch:getState", { query, active, focused, path, type, loaded, state }, Error().stack, this.state, this.props);

    state.path = path !== null && path !== undefined ? path : this.props.path
    state.pathType = type !== null && type !== undefined ? type : this.props.type
    state.editText = query !== null && query !== undefined ? query : this.props.query
    state.editItems =
      this.state.editItems !== null || loaded ? await this.getItems(state.editText, state.path, state.pathType) : null
    state.editHeight = state.editItems ? state.editItems.length * (this.props.renderObjectsPath ? 55 : 45) : 0
    state.editActive = active !== null && active !== undefined ? active : this.state.editActive
    state.editFocused = focused !== null && focused !== undefined ? focused : this.state.editFocused

    //logger.info("ObjectItemSearch:getState;RESULT", { query, active, focused, path, type, loaded, state }, this.state, this.props);

    // Adjust size for new lines in list.
    if (this.props.onAdjust) {
      this.props.onAdjust(
        0,
        (state.editActive ? state.editHeight : 0) - (this.state.editActive ? this.state.editHeight : 0),
        'ObjectItemSearch'
      )
    }
    return state
  }

  /**
   * Get objects for current path
   * @return
   */
  getList = async (query, path, type) => {
    if (!path || path.length < 1) return false

    let splitPath = path.substring(1).split('/')
    let pathIndex = splitPath.length - 1
    let pathType = splitPath[pathIndex]
    let pathData = path

    // If we don't on items We move to
    if (pathIndex > 0 && !(pathIndex & 1)) {
      pathData = path.slice(0, path.length - pathType.length - 1)
    }

    logger.info(
      'ObjectItemSearch:getList:REQUEST',
      { query, path, type, pathIndex, pathType, pathData },
      this.state,
      this.props
    )

    if (!type) {
      return []
    } else if (type === 'organizations') {
      return await getObjectListNew(API.organizations, 'organization')
    } else {
      let req = getRequestFromPath(pathData)
      return await getObjectListNew(req[getApiResource(type)], singularType(type))
    }
  }

  /**
   * List of items from path
   * @return
   */
  getItems = async (query, path, type) => {
    let filterText = ''

    logger.info('ObjectItemSearch:getItems----------------', query, path, type)

    if (query && query.length > 0) {
      // We have this type as target. We will filter list by it.
      filterText = query.toLowerCase()
    } else {
      filterText = ''
    }

    let list = await this.getList(query, path, type)

    //logger.info("ObjectItemSearch:getItems:MAP", list, filterText);

    if (list) {
      // List of items we have
      let filter = this.props.filter ? this.props.filter : this.defaultFilter
      let parent = this.props.path

      let filtered = list.filter((item) => getFullUri(item).startsWith(parent) && filter(item, type, filterText))
      let sorted = this.props.sortFunction ? this.props.sortFunction(type, filtered) : this.defaultSort(type, filtered)

      //logger.info("ObjectItemSearch:getItems:OBJECT", filtered, sorted);
      return sorted
    }

    return []
  }

  /**
   * Get current query
   * @return current state of item selected
   */
  getQueryText = () => {
    return this.state.editText ? this.state.editText : this.props.query
  }

  getPlaceholderText = () => {
    return this.props.placeholder ? this.props.placeholder : 'Type or select ' + singularType(this.props.type) + ' name'
  }

  /**
   * Start editing when focus set to the input
   * @return
   */
  onEditPath = async () => {
    // Set new state and focus.
    //logger.info("ObjectItemSearch:onEditPath:TEXT", this.getQueryText());
    let state = await this.getState(this.getQueryText(), true, true)

    //logger.info("ObjectItemSearch:onEditPath", state);
    this.setState(state)
  }

  /**
   * Close query editing when we done with item selection
   * @return
   */
  onListClose = async () => {
    // Set new state and focus.
    //logger.info("ObjectItemSearch:onListClose:TEXT", this.getQueryText());
    let state = await this.getState(this.getQueryText(), false)

    //logger.info("ObjectItemSearch:onListClose", state);
    this.setState(state)
  }

  /**
   * Change of path from editing
   * @return
   */
  onChangePath = async (event) => {
    let query = event.target.value
    //logger.info("ObjectItemSearch:onChangePath:TEXT", query);

    let state = await this.getState(query)

    //logger.info("ObjectItemSearch:onChangePath", query, state);
    this.setState(state)
  }

  /**
   * Select specific item in list of item and update path
   * @return
   */
  onSelectItem = async (item) => {
    // Set new path base on selected item and return focus to editor
    let type = this.state.pathType
    let query = item.type === 'versions' ? versionToStr(item.version) : item.identity.name

    //logger.info("ObjectItemSearch:onSelectItem", query, type);

    if (this.props.onSelect && this.props.onSelect(item, type)) return true

    // Reset state from new path if parent not blocked it.
    this.setState(await this.getState(query, false, false))
  }

  /**
   * Try to update path and list of relevant items when key presed
   * @return
   */
  keyPress = async (event) => {
    let code = null

    if (event.keyCode !== undefined) {
      code = event.keyCode
    }

    //logger.info("ObjectItemSearch:keyPress", this.state.path, this.state.pathType, this.state.editText);

    // //logger.info("keyPress", code);
    if (code === 8) {
      // BACKSPACE
      //logger.info("ObjectPathEditor:keyPress:BACKSPACE", this.state.editText);

      // We remove simbol by simbol.
      this.setState(await this.getState(this.state.editText.slice(0, this.state.editText.length - 1)))
      event.preventDefault()
    } else if (code === 13 || code === 191) {
      // ENTER or /
      // we try to finish search for current object
      let ret = this.state.editItems && this.state.editItems.length > 0 ? this.state.editItems[0] : null

      //logger.info("ObjectPathEditor:keyPress:ENTER", ret, this.state);

      if (ret) {
        this.onSelectItem(ret)
      }
      event.preventDefault()
    }
  }

  /**
   * Format item information to be represented in editor
   * @return item as a string
   */
  itemFormat(item) {
    // logger.info("ObjectItemSearch:renderItemName", item);
    if (this.state.pathType === 'issues') {
      return item.identity.name + ': ' + item.identity.description
    } else if (this.state.pathType === 'versions') {
      return versionToStr(item.version)
    } else {
      return item.identity.name
    }
  }

  /**
   * Filter string for item
   * @return default filter string
   */
  defaultFilter = (item, type, query) => {
    //logger.info("ObjectItemSearch:defaultFilter", item, type, query);
    if (!query || query.length === 0) {
      return type !== 'issues' || item.status !== 'Closed'
    } else if (type === 'issues') {
      return (
        ((isInteger(query) && item.identity.name && item.identity.name.startsWith(query)) ||
          (item.identity.description && item.identity.description.toLowerCase().indexOf(query) !== -1)) &&
        item.status !== 'Closed'
      )
    } else if (type === 'versions') {
      return item.version && versionToStr(item.version).startsWith(query)
    } else {
      return item.identity.name && item.identity.name.toLowerCase().startsWith(query)
    }
  }

  /**
   * Sort string for item
   * @return default sorted list
   */
  defaultSort = (type, list) => {
    //logger.info("ObjectItemSearch:defaultSort", type, list);
    if (type === 'issues') {
      return list.sort((a, b) => issueSort(a, b))
    } else if (type === 'versions') {
      return list.sort((a, b) => versionSort(a.version, b.version))
    } else {
      return list
    }
  }

  render() {
    const res = this.state.editActive && !this.props.isClosed ? this.state.editItems : []
    const placeholder = this.getPlaceholderText()

    //logger.info("ObjectItemSearch:render", this.state, this.props, res);
    return (
      <div className={'ObjectItemSearch'}>
        <input
          className="ObjectItemSearch.TextInput"
          type="text"
          ref="input"
          placeholder={placeholder}
          value={this.getQueryText()}
          onFocus={this.onEditPath.bind(this)}
          onBlur={() => {
            this.setState({ editFocused: false })
          }}
          onChange={this.onChangePath.bind(this)}
          onKeyDown={this.keyPress.bind(this)}
        />
        {res && res.length > 0 ? (
          <div className="ObjectItemSearch__Close" onClick={this.onListClose.bind(this)}>
            Close
          </div>
        ) : (
          ''
        )}
        {res && res.length > 0 ? (
          <div className="ObjectItemSearch__results">
            {res.map((result, i) => (
              <div
                className="ObjectItemSearch__result"
                onClick={() => {
                  this.onSelectItem(result)
                }}
                key={i}
              >
                {this.itemFormat(result)}
                {this.props.renderObjectsPath ? (
                  <div className="ObjectItemSearch__resultPath">{getFullUri(result)}</div>
                ) : null}
              </div>
            ))}
          </div>
        ) : (
          <div>
            {this.state.editFocused && !this.props.isClosed ? (
              <div className="ObjectItemSearch__result">
                {res ? <div className="ObjectItemSearch__resultHeader">No results found</div> : <Loader />}
              </div>
            ) : null}
          </div>
        )}
      </div>
    )
  }
}

ObjectItemSearch.propTypes = {
  appState: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  filter: PropTypes.func,
  query: PropTypes.string,
  placeholder: PropTypes.string,
  path: PropTypes.string,
  type: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  onAdjust: PropTypes.func,
  isClosed: PropTypes.bool,
  renderObjectsPath: PropTypes.bool, // to not render path
  keepObjectsWithoutPath: PropTypes.bool, // usually objects with no path are not displayed in search results
  // but organizations never have path, so this should be turned on for them
  className: PropTypes.string,
  sortFunction: PropTypes.func
}

//export default ObjectItemSearch;

function mapStateToProps(state) {
  return {
    appState: state.appState
  }
}

export default connect(mapStateToProps, null)(ObjectItemSearch)
