/**
 * Created by sashaberger on 07.02.2017.
 *
 * Dialog to add or edit organization in organization list.
 *
 */
import PropTypes from 'prop-types'

import React, { Component, RefObject } from 'react'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { TagsDialog } from '../MajorObject/TagsDialog'
import { DocsDialog } from '../MajorObject/DocsDialog'
import { SettingsDialog } from '../SettingDialog/SettingsDialog'
import { TypedTable } from '../NewTable/TypedTable'
import { ObjectSelector } from '../ObjectSearch/ObjectSelector'
import UserSearch from '../UserSearch/UserSearch'
import { API, getApiResource, getRequestFromPath, sendObjectNew } from '../../helpers/api'
import {
  columnsToType,
  getFullUri,
  checkResourceUrl,
  editableState,
  itemState,
  deepCopy,
  nonCSCompare,
  deadlineCalculation,
  issuePropList,
  objectNameFromPathByType,
  updateLocalObject,
  clearLocalObject,
  getStatusIcon
} from '../../helpers/index'
import logger from '../../helpers/logger'

import { IssueNewMessage } from '../IssueNewMessage/IssueNewMessage'
import './IssueDialog.scss'

import iArrowDownClose from '../../resources/images/arrow-down-close.png'
import iAdd from '../../resources/images/add_files-2x.png'
import { issuePriorityIcon, renderLog, renderRestore } from '../../helpers/helperComponents'
import { clearIssue, createLog, getDocumentUri, prepareMessage } from './issueDialogHelper'

type IssueDialogProps = {
  appState: any
  actions: any
  users: any // Users of this object
  user: any // User editing issue
  parent: any // Parent object we creating issue for
  issue: any // Issue we need to edit
  isVisible: boolean
  isEditable: number
  architect: any
  onClose: () => void
  issueCreatedBy: any
  onSave: (result: any) => void
  defaultText: string
}

type IssueDialogState = {
  documentDialogOpened: boolean
  editingDocs: boolean
  editingTags: boolean

  advancedSettingsOpened: boolean

  change: Function | null

  issue: any
  message: {
    class: string
    type: string
    text: string
  }
  height: number
  time: any
  uploadContent: any[]
  objectType: string
  editingSetting: boolean
}

export class IssueDialog extends Component<IssueDialogProps, IssueDialogState> {
  tasktableRef: RefObject<any>
  editordialogRef: RefObject<any>

  constructor(props) {
    super(props)

    //console.log("IssueDialog:constructor", props);

    this.state = {
      documentDialogOpened: false, // Control visability of document dialog
      editingDocs: false,
      editingTags: false,

      advancedSettingsOpened: false, // Control visability of advance settings

      change: null, // Method to report or cancel modification.

      issue: this.InitIssue(props),
      message: {
        class: 'Message', // bad idea to name property with reserved word in many languages
        type: 'Regular',
        text: ''
      },
      height: this.InitHeight(props),
      time: new Date().getTime(), // used to generate unique document uri
      uploadContent: [], // List of content for upload
      objectType: 'issue',
      editingSetting: false
    }

    this.tasktableRef = React.createRef()
    this.editordialogRef = React.createRef()
  }

  /**
   * Returns object type for dialog, for example "activity"
   */
  getObjectType() {
    return 'issue'
  }

  /**
   * Init Issue current state
   * @param user - selected user
   */
  InitIssue = (props) => {
    //console.log("IssueDialog:InitIssue", props);

    if (props.issue) {
      return deepCopy(props.issue)
    }
    return Object.assign({}, null, {
      assigned: { id: props.user.identity.id, name: props.user.identity.name },
      attached: {
        id: props.parent.identity.id,
        name: getFullUri(props.parent)
      },
      type: 'WorkItem',
      priority: 'Normal',
      status: 'Opened',
      time: new Date().getTime(),
      notes: props.defaultText || '',
      trackers: [
        /*{
        id: localStorage['currentUserId'],
        name: this.props.user.identity.name,
        description: '/organizations/' + objectNameFromPathByType(getFullUri(this.props.parent), 'organizations')
      } */
      ]
    })
  }

  /**
   * Init dialog height
   * @param user - selected user
   */
  InitHeight = (props) => {
    //console.log("IssueDialog:InitHeight", props, window.innerHeight);

    let height = 950
    if (props.issue && props.issue.tasks) {
      height += props.issue.tasks.length * 52
    }

    if (height < window.innerHeight - 247) height = window.innerHeight - 247

    return height
  }

  /**
   * Installs Paste listener
   */
  componentDidMount() {
    window.addEventListener('paste', this.onPaste)
  }

  /**
   * If there is a file on clipboard, save it as document in current issue
   * @param e
   */
  onPaste = (e) => {
    this.retrieveImageFromClipboardAsBlob(e, (blob) => {
      const fileName = 'screenshot_' + new Date().getTime() + '.png'

      let docs = this.state.issue.object ? this.state.issue.object.documents : []
      let order = 0
      docs.forEach((doc) => {
        order = order < doc.order ? doc.order : order
      })
      logger.info('IssueDialog:onPaste', { fileName, docs, order }, this.state, this.props)

      // Create new document for screen shot
      const newDocument = {
        identity: { name: fileName },
        order: order + 1,
        type: 'Picture',
        uri: '/' + fileName
      }
      const uploadedFile = new File([blob], fileName, { type: 'image/png' })
      const uploadContent = {
        identity: { name: fileName },
        type: 'Picture',
        fileURI: '/' + fileName,
        fileName: fileName,
        fileData: uploadedFile
      }

      let newDocList = docs.concat(newDocument)
      let newContentList = (this.state.uploadContent || []).concat(uploadContent)
      const newIssue = {
        object: {
          history:
            this.state.issue.object && this.state.issue.object.history
              ? { updated: this.state.issue.object.history.updated }
              : null,
          documents: newDocList
        }
      }
      this.setState(
        {
          uploadContent: newContentList,
          issue: Object.assign({}, this.state.issue, newIssue)
        },
        () => {
          this.state.change && this.state.change(true, '', '', '')
        }
      )
    })
  }

  /**
   * Removes paste listener
   */
  componentWillUnmount() {
    window.removeEventListener('paste', this.onPaste)
  }

  /**
   * Checks if clipboard contains an image
   * @param pasteEvent
   * @param callback
   */
  retrieveImageFromClipboardAsBlob(pasteEvent, callback) {
    if (pasteEvent.clipboardData === false) {
      if (typeof callback === 'function') {
        callback(undefined)
      }
    }

    let items = pasteEvent.clipboardData.items

    if (items === undefined) {
      if (typeof callback === 'function') {
        callback(undefined)
      }
    }

    for (let i = 0; i < items.length; i++) {
      // Skip content if not image
      if (items[i].type.indexOf('image') === -1) continue
      // Retrieve image on clipboard as blob
      let blob = items[i].getAsFile()

      if (typeof callback === 'function') {
        callback(blob)
      }
    }
  }

  /**
   * Used to show different instructions on devices
   * @param key
   * @returns {string}
   */
  getKeyLabel(key) {
    // Check the browser and return keys
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    if (isSafari) {
      if (key === 'printscreen') return 'Command ⌘ + Control + Shift + 4'
      else if (key === 'paste') return 'Command ⌘ + V'
    } else {
      if (key === 'printscreen') return 'Alt + PrintScreen'
      else if (key === 'paste') return 'Ctrl + V'
    }
  }

  /**
   * Execute activity when dialog closing
   * @return
   */
  onClose = () => {
    //console.log("IssueDialog:onClose");
    if (this.props.onClose) this.props.onClose()
  }

  /**
   * Execute edit in the dialog
   * @param
   * @return
   */
  onEdit = (change) => {
    //console.log("IssueDialog:onEdit", this.state.isEditable);

    this.setState({
      change: change
    })
  }

  /**
   * Execute activity when message send to service
   * @param error return from service if any.
   * @return
   */
  onSent = (close, error?) => {
    //console.log("IssueDialog:onSent", close, error);

    // Report complete of save with error or not
    if (this.state.change) {
      this.state.change(error)
    }

    if (close && !error) {
      setTimeout(() => {
        this.onClose()
      }, 1000)
      // @ts-ignore
      this.setState(clearLocalObject(this.InitIssue(this.props)))
    }
  }

  /**
   * Execute activity when save button pushed in dialog
   * @param error return from service if any.
   * @return
   */
  onSave = (close) => {
    logger.info('IssueDialog:onSave', close, this.state.issue, this.state.message)

    const issueToSend = clearIssue(this.state.issue)
    let inputError = ''

    if (!issueToSend.identity || !issueToSend.identity.description) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify issue subject!'
    }
    if (issueToSend.status === 'Resolved' && !issueToSend.resolution) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify resolution!'
    }
    // console.log("IssueDialog:onSave", close, inputError);
    if (inputError.length > 0) {
      if (this.state.change) {
        this.state.change(true, '', '', inputError)
      }
      return true
    }

    if (this.state.change) {
      this.state.change(true, 'Sending message to the service...', '', '')
    }

    let apiRequest = this.getMessagesRequest()
    let isNewIssue = this.isNewIssue()

    //console.log("IssueDialog:onSave", isNewIssue);

    const objectToSend = prepareMessage(issueToSend, this.props.issue, this.props.user, this.state.message)

    logger.info('IssueDialog:onSave:SEND', objectToSend)

    if (!objectToSend.type) objectToSend.type = 'issue'

    sendObjectNew(apiRequest, 'post', this.props.actions, objectToSend, null, true).then(
      (result: any) => {
        logger.info('IssueDialog:onSave:RESULT', result)
        this.state.change && this.state.change(true, 'Updating issue documents...', '', '')
        this.uploadContent(this.state.uploadContent, getDocumentUri(result.issue.identity)).then(
          () => {
            if (this.props.onSave) {
              this.props.onSave(result)
            }
            this.onSent(close)
          },
          (err) => {
            this.props.actions.setError(null)
            this.onSent(close, err)
          }
        )
      },
      (err) => {
        this.props.actions.setError(null)
        this.onSent(close, err)
      }
    )

    // Delay to close on save.
    return true
  }

  /**
   * On change content loaded into dialog.
   */
  onContentChange = (content) => {
    // Update content of dialog.
    let uploadContent = this.state.uploadContent
      .filter((cont) => nonCSCompare(cont.objectName, content.objectName))
      .concat(content)
    this.setState({
      uploadContent: uploadContent
    })
  }

  /**
   * Upload content.
   * @param {array} content - list of content objects
   * @param {string} contentUri - root path for content upload
   * @returns {Promise}
   */
  uploadContent = (content, contentUri) => {
    // Change status of dialog
    return new Promise<void>((resolve, reject) => {
      if (!content || content.length > 0) {
        resolve()
      }

      let promises: Promise<any>[] = []
      let root = contentUri ? (contentUri.endsWith('/') ? contentUri : contentUri + '/') : '/'
      logger.info(
        'IssueDialog:uploadContent',
        this.getObjectType(),
        { content, contentUri, root },
        this.state,
        this.props
      )

      content.forEach((file) => {
        let uri = file.fileURI ? (file.fileURI.startsWith('/') ? file.fileURI.substring(1) : file.fileURI) : ''
        logger.info(
          'IssueDialog:uploadContent:FILE',
          this.getObjectType(),
          { file, uri, root, content, contentUri },
          this.state,
          this.props
        )
        if (file.fileData) {
          let form = new FormData()
          form.append('file', file.fileData)

          promises.push(
            fetch(checkResourceUrl(API.resources.url() + '?uri=' + root + uri), {
              method: 'post',
              headers: {},
              body: form
            })
          )
        } else {
          promises.push(
            fetch(checkResourceUrl(API.resources.url() + '?uri=' + root + uri), {
              method: 'delete'
            })
          )
        }
      })

      Promise.all(promises).then(
        () => {
          logger.info('EditorDialogBase:uploadContent:SUCCESS', this.getObjectType(), this.state, this.props)
          resolve()
        },
        (error) => {
          logger.warn('EditorDialogBase:uploadContent:ERROR', this.getObjectType(), error, this.state, this.props)
          reject(error)
        }
      )
    })
  }

  /**
   * Cancel editing in the dialog
   * @param close - cancel execution and close dialog.
   * @return
   */
  onCancel = (close) => {
    //console.log("IssueDialog:onCancel", close);

    // Cancel save mode
    if (this.state.change) this.state.change(false)

    this.setState({
      change: null,
      //issue: this.InitIssue(this.props),
      height: this.InitHeight(this.props)
    })
    // @ts-ignore
    this.setState(clearLocalObject(this.InitIssue(this.props)))
  }

  /**
   * Adjust size of scrall area in dialog
   * @param width - increase or reduction in control width.
   * @param height - increase or reduction in control height.
   * @return
   */
  onAdjust = (width, height, source?) => {
    this.setState((prevState) => {
      //console.log("IssueDialog:onAdjust", width, height, prevState.width, prevState.height, source);
      return { height: prevState.height + height }
    })
  }

  /**
   * Change of issue description by typing it
   * @param e - description
   */
  onIssueDescriptionChange = (event) => {
    let data = event.target.value
    //console.log("IssueDialog:onIssueDescriptionChange", data);

    // Indicate save mode
    if (
      this.state.change &&
      (!this.props.issue || !this.props.issue.identity || data !== this.props.issue.identity.description)
    ) {
      this.state.change(true, '', '', '')

      const identity = Object.assign({}, this.state.issue.identity ? this.state.issue.identity : {})
      identity.description = data

      /*
      this.setState({
        issue: Object.assign({}, this.state.issue, {identity: identity})
      });
      */
      // @ts-ignore
      this.setState(updateLocalObject(Object.assign({}, this.state.issue, { identity: identity })))
    }
  }

  /**
   * Change of notes by typing it
   * @param e - notes
   */
  onIssueNotesChange = (event) => {
    let data = event.target.value
    //console.log("IssueDialog:onIssueNotesChange", data);

    // Indicate save mode
    if (this.state.change && (!this.props.issue || data !== this.props.issue.notes)) {
      this.state.change(true, '', '', '')

      this.setState(
        //  issue: Object.assign({}, this.state.issue, {notes: data})
        // @ts-ignore
        updateLocalObject(Object.assign({}, this.state.issue, { notes: data }))
      )
    }
  }

  onTagsEdit() {
    this.setState({ editingTags: true })
  }

  onTagsClose() {
    this.setState({ editingTags: false })
  }

  /**
   * Update tags list of issue
   */
  onTagsChange(tags, closeDialog, onSent) {
    console.log('IssueDialog:onTagsChange', tags, this.state)
    const newIssue = deepCopy(this.state.issue)
    if (!newIssue.object) {
      newIssue.object = {}
    }
    newIssue.object.tags = tags

    this.setState(
      {
        issue: newIssue
      },
      () => {
        this.state.change && this.state.change(true, '', '', '')
      }
    )

    if (onSent) {
      onSent(closeDialog)
    }
  }

  onDocsEdit() {
    logger.info('IssueDialog:onDocsEdit', this.state)
    this.setState({ editingDocs: true })
  }

  onDocsClose() {
    this.setState({ editingDocs: false })
  }

  /**
   * Update tags list of issue
   */
  onDocsChange(docs, closeDialog, onSent) {
    console.log('IssueDialog:onDocsChange', docs, this.state)
    const newIssue = deepCopy(this.state.issue)
    if (!newIssue.object) {
      newIssue.object = {}
    }
    newIssue.object.documents = docs

    this.setState(
      {
        issue: newIssue
      },
      () => {
        this.state.change && this.state.change(true, '', '', '')
      }
    )

    if (onSent) {
      onSent(closeDialog)
    }
  }

  /**
   * Add task to issue task list
   * @param
   */
  onAddTask = () => {
    //console.log("IssueDialog:onAddTask", this.state.issue);

    // Indicate save mode
    if (this.state.change) {
      this.state.change(true, '', '', '')
      this.onAdjust(0, 52)

      let issue = this.state.issue ? deepCopy(this.state.issue) : {}
      if (!issue.tasks) {
        issue.tasks = []
      }

      let maxId = (issue.tasks.reduce((prev, task) => Math.max(prev, task.identity.name), 0) || 0) + 1
      issue.tasks.push({
        identity: { name: maxId, description: '' },
        id: maxId,
        check: false
      })

      // @ts-ignore
      this.setState(updateLocalObject(issue), () => {
        //console.log("setstate old",Object.assign({},this.editordialogRef.current.refs.tasktable.state), issue.tasks.slice, {editingField: {rowIndex: issue.tasks.length - 1, colIndex: 2}});
        this.tasktableRef.current.setState({
          editingField: { rowIndex: issue.tasks.length - 1, colIndex: 1 }
        })
      })
    }
    return false
  }

  /**
   * Delete task from issue task list
   * @param index - index of issue to delete
   */
  onDeleteTask = (task, index) => {
    //console.log("IssueDialog:onDeleteTask", index, this.state.issue);

    // Indicate save mode
    if (this.state.change) {
      this.state.change(true, '', '', '')
      this.onAdjust(0, 52)

      let issue = this.state.issue ? deepCopy(this.state.issue) : {}
      if (!issue.tasks) {
        issue.tasks = []
      }

      // Mark task as deleted
      issue.tasks = issue.tasks.map((task, idx) =>
        Object.assign(
          task,
          idx === index
            ? {
                identity: {
                  name: task.identity.name,
                  description: ''
                },
                isDeleted: true
              }
            : {}
        )
      )

      // @ts-ignore
      this.setState(updateLocalObject(issue))
    }
    return false
  }

  /**
   * Edit task of the issue task list
   */
  onEditTask = (rowIndex, oldRow, newRow) => {
    // Indicate save mode
    if (this.state.change) {
      this.state.change(true, '', '', '')

      let issue = this.state.issue ? deepCopy(this.state.issue) : {}
      if (!issue.tasks) {
        issue.tasks = []
      }

      //console.log("onEditTask", rowIndex, oldRow, newRow);
      // Edit task
      issue.tasks[rowIndex] = Object.assign({}, issue.tasks[rowIndex], {
        identity: {
          name: parseInt(newRow.number),
          description: newRow.name
        },
        check: newRow.check
      })

      //console.log("onEditTask tasks", issue.tasks);

      //this.setState({issue: issue});
      // @ts-ignore
      this.setState(updateLocalObject(issue))
    }
    return false
  }

  /**
   * Edit state of task
   * @param newData - change data of the task
   */
  onEditTaskState = (row, value) => {
    //console.log("IssueDialog:onEditTaskState", row, value, this.state.issue);

    // Indicate save mode
    if (this.state.change) {
      this.state.change(true, '', '', '')

      let issue = this.state.issue ? deepCopy(this.state.issue) : {}
      if (!issue.tasks) {
        issue.tasks = []
      }
      issue.tasks[row].check = value
      setTimeout(() => {
        // @ts-ignore
        this.setState(updateLocalObject(issue))
      }, 0)
    }
    return false
  }

  /**
   * Get current state of attache path
   * @return path of object issue attached too
   */
  getAttachedPath = () => {
    //console.log("IssueDialog:getAttachedPath", this.props.parent, this.state.issue);
    if (this.state.issue && this.state.issue.attached && this.state.issue.attached.name) {
      return this.state.issue.attached.name
    }
    return getFullUri(this.props.parent)
  }

  /**
   * Change attached path based on path selected
   * @param obj - selected object path
   */
  onAttachedChange = (obj) => {
    let path = getFullUri(obj)
    //console.log("IssueDialog:onAttachedChange", obj, path, this.state, this.props);

    // Indicate save mode
    if (this.state.change) {
      this.state.change(true, '', '', '')

      this.setState(
        //    issue: Object.assign({}, this.state.issue, {attached: {name: path}})
        // @ts-ignore
        updateLocalObject(Object.assign({}, this.state.issue, { attached: { name: path } }))
      )
    }
    return true
  }

  /**
   * Get current state of attache path
   * @return path of object issue attached too
   */
  onTrackChange = () => {
    logger.info('IssueDialog:onTrackChange', this.state.issue, this.props.user)

    // Indicate save mode
    if (this.state.change) {
      this.state.change(true, '', '', '')

      const tracker = {
        id: localStorage['currentUserId'],
        name: this.props.user.identity.name,
        description: '/organizations/' + objectNameFromPathByType(getFullUri(this.props.parent), 'organizations')
      }

      let trackers = []
      if (this.isTracked()) {
        trackers = this.state.issue.trackers.filter((t) => t.id !== this.props.user.identity.id)
      } else {
        trackers = ((this.state.issue && this.state.issue.trackers) || []).concat(tracker)
      }

      this.setState(
        //issue: Object.assign({}, this.state.issue, {trackers: trackers})
        // @ts-ignore
        updateLocalObject(Object.assign({}, this.state.issue, { trackers: trackers }))
      )
    }
    return false
  }

  /**
   * Get current state of tracks users
   * @return path of object issue attached too
   */
  isTracked = () => {
    if (this.state.issue && this.state.issue.trackers) {
      return this.state.issue.trackers.reduce((p, c) => (c.id === this.props.user.identity.id ? true : p), false)
    }
    return false
  }

  /**
   * Get current assigned user id
   * @return {string} id of assigned user
   */
  getAssignedUser = () => {
    if (this.state.issue && this.state.issue.assigned && this.state.issue.assigned.id) {
      return this.state.issue.assigned.id
    }

    // If issue assigned to us it has no assigned id
    if (
      this.state.issue &&
      this.state.issue.assigned &&
      !this.state.issue.assigned.id &&
      this.state.issue.assigned.name
    ) {
      return this.state.issue.assigned.id
    }

    return this.props.user.identity.id
  }

  /**
   * Change assigned user based on path selected
   * @param user - selected user
   */
  onAssignedChange = (user) => {
    //console.log("IssueDialog:onAssignedChange", user, this.state.issue, this.state.message);

    // Indicate save mode
    if (this.state.change) {
      this.state.change(true, '', '', '')

      const id = user.user ? user.user.id : user.identity.id

      const issueUpdate: any = {
        assigned: { id: id, name: user.profile.alias }
      }

      if (!id) {
        let messageUpdate = {
          attached: this.state.issue.attached
        }

        issueUpdate.attached = {
          id: '3012c11f-23b3-4c59-9eef-e2b6dc6079a4',
          name: '/organizations/Apdax'
        }

        issueUpdate.trackers = [
          {
            id: localStorage['currentUserId'],
            name: this.props.user.identity.name,
            description: '/organizations/' + objectNameFromPathByType(getFullUri(this.props.parent), 'organizations')
          }
        ]

        this.setState({
          //issue: Object.assign({}, this.state.issue, issueUpdate),
          message: Object.assign({}, this.state.message, messageUpdate)
        })
      } else {
        this.setState({
          //issue: Object.assign({}, this.state.issue, issueUpdate)
        })
      }
      // @ts-ignore
      this.setState(updateLocalObject(Object.assign({}, this.state.issue, issueUpdate)))
    }
    return false
  }

  /**
   * Check property value
   * @param prop - property to check
   * @param val - value to validate
   * @return true if type equal to value.
   */
  isPropertyValueEquals = (prop, val) => {
    return this.state.issue && (this.state.issue[prop] === val || this.state.issue[prop] === val.replace(/\s+/g, ''))
  }

  /**
   * Change issue type
   * @param
   */
  onTypeChange = (event) => {
    let data = event.target.value
    //console.log("IssueDialog:onTypeChange", data, this.state.issue);

    // Indicate save mode
    if (this.state.change) {
      this.state.change(true, '', '', '')

      this.setState(
        //issue: Object.assign({}, this.state.issue, {type: data.replace(/ /g, '')})
        // @ts-ignore
        updateLocalObject(Object.assign({}, this.state.issue, { type: data.replace(/ /g, '') }))
      )
    }
    return false
  }

  onMessageChange = (newMessage) => {
    if (this.state.change) {
      this.state.change(true, '', '', '')

      //*
      this.setState((prevState, props) => {
        return {
          message: Object.assign({}, prevState.message, newMessage)
        }
      })
      // */
      //this.setState(updateLocalObject(Object.assign({},this.state.issue,{message: Object.assign({}, this.state.message, newMessage)})));
    }
  }

  /**
   * Change issue priority
   * @param
   */
  onPriorityChange = (event) => {
    let data = event.target.dataset.value || event.target.parentNode.dataset.value
    //console.log("IssueDialog:onPriorityChange", data, this.state.issue, event.target, event.target.parentNode);

    // Indicate save mode
    if (this.state.change) {
      this.state.change(true, '', '', '')

      this.setState(
        //    issue: Object.assign({}, this.state.issue, {priority: data})
        // @ts-ignore
        updateLocalObject(Object.assign({}, this.state.issue, { priority: data }))
      )
    }
    return false
  }

  /**
   * Change issue priority
   * @param
   */
  onStatusChange = (event) => {
    let data = event.target.dataset.value || event.target.parentNode.dataset.value
    //console.log("IssueDialog:onStatusChange", data, this.state.issue);

    // Indicate save mode
    if (this.state.change) {
      this.state.change(true, '', '', '')

      // @ts-ignore
      this.setState(updateLocalObject(Object.assign({}, this.state.issue, { status: data })), () => {
        if (data === 'Resolved') {
          let userFound = false
          if (this.props.issueCreatedBy && this.props.issueCreatedBy.id) {
            this.props.users.forEach((user) => {
              if (user.user.id === this.props.issueCreatedBy.id) userFound = true
            })
          }
          if (userFound) {
            this.setState(
              // @ts-ignore
              updateLocalObject(
                Object.assign({}, this.state.issue, {
                  assigned: {
                    id: this.props.issueCreatedBy.id,
                    name: this.props.issueCreatedBy.name
                  }
                })
              )
            )
          } else {
            if (this.props.architect && this.props.architect.user)
              this.setState(
                // @ts-ignore
                updateLocalObject(
                  Object.assign({}, this.state.issue, {
                    assigned: this.props.architect.user
                  })
                )
              )
          }
        }
      })
    }
    return false
  }

  /**
   * Change issue priority
   * @param
   */
  onResolutionChange = (event) => {
    let data = event.target.value
    //console.log("IssueDialog:onResolutionChange", data, this.state.issue);

    // Indicate save mode
    if (this.state.change) {
      this.state.change(true, '', '', '')

      if (data !== this.state.issue.resolution) {
        this.setState(
          // @ts-ignore
          updateLocalObject(
            Object.assign({}, this.state.issue, {
              resolution: data !== null ? data : ''
            })
          ),
          () => {
            this.setState({
              // why was this needed?
              //issue: Object.assign({}, this.state.issue, {assigned: {id: ''}})
            })
          }
        )
      } else {
        this.setState(
          // issue: Object.assign({}, this.state.issue, {resolution: ""})
          // @ts-ignore
          updateLocalObject(Object.assign({}, this.state.issue, { resolution: '' }))
        )
      }
    }
    return false
  }

  /**
   * Apdate issue custom properties
   * @param
   */
  onSettingsChange = (settings, closeDialog, onSent) => {
    // //console.log("ObjectCreator:onAdvancedSettingsChange", settings);
    const newObject = Object.assign({}, this.state.issue.object, {
      properties: settings
    })
    this.setState(
      //issue: Object.assign({}, this.state.issue, {object: newObject})
      // @ts-ignore
      updateLocalObject(Object.assign({}, this.state.issue, { object: newObject }))
    )
    if (this.state.change) {
      this.state.change(true, '', '', '')
    }
    if (onSent) onSent(closeDialog)
  }

  Change(newIssue) {
    this.setState(
      //issue: Object.assign({}, this.state.issue, {object: newObject})
      // @ts-ignore
      updateLocalObject(Object.assign({}, this.state.issue, newIssue))
    )
    if (this.state.change) {
      this.state.change(true, '', '', '')
    }
  }

  /**
   * key press handler for Tasks table
   * @param e
   */
  taskKeyPress = (event) => {
    const charCode = event.which || event.charCode || event.keyCode || 0

    //console.log("IssueDialog::taskKeyPress", charCode);

    if (charCode === 13 && this.props.isEditable) {
      this.onAddTask()
    }
  }

  getMessagesRequest = () => {
    return getRequestFromPath(getFullUri(this.props.parent))[getApiResource('message')]
  }

  isNewIssue = () => !this.props.issue

  /**
   * Render controls to enter issue identity information
   * @return
   */
  renderIssueIdentity = (editState) => {
    return (
      <div className="IssueDialog">
        <div className="NewIssueSettingsBlock">
          <div className="NewIssueSettingsBlock__left NewIssueSettingsBlock__left__name">
            <label className="IssueDialog__label">Subject:</label>
          </div>
          <div className="NewIssueSettingsBlock__right">
            <div className="IssueDialog__name">
              <EditableEntity
                data={
                  this.state.issue && this.state.issue.identity && this.state.issue.identity.description
                    ? this.state.issue.identity.description
                    : ''
                }
                dataType={{ name: 'string' }}
                dataProps={{
                  placeholder: 'Issue subject',
                  onChange: this.onIssueDescriptionChange.bind(this)
                }}
                isEditable
                inEditMode={editState > editableState.EDITABLE}
              />
            </div>
          </div>
        </div>

        <div className="NewIssueSettingsBlock NewIssueSettingsBlock_compact">
          <div className="NewIssueSettingsBlock__left">
            <div className="IssueDialog__label">
              Description:
              <div className="ObjectCreator__addIcon">
                <img
                  src={iAdd}
                  className="ObjectCreator__addIcon__icon"
                  onClick={this.onDocsEdit.bind(this)}
                  alt="add icon"
                />
                {this.state.issue && this.state.issue.object ? (
                  this.state.issue.object.documents && this.state.issue.object.documents.length ? (
                    <span className="ObjectCreator__docCounter">{this.state.issue.object.documents.length}</span>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="NewIssueSettingsBlock__right">
            <div className="IssueDialog__value IssueDialog__value__multiLine  clearfix">
              <EditableEntity
                data={this.state.issue && this.state.issue.notes ? this.state.issue.notes : ''}
                dataType={{ name: 'text_updatable_autosize' }}
                dataProps={{
                  placeholder: 'Issue description',
                  onChange: this.onIssueNotesChange.bind(this)
                }}
                isEditable
                inEditMode={editState > editableState.EDITABLE}
              />
            </div>
          </div>
        </div>
        <div className="NewIssueSettingsBlock NewIssueSettingsBlock_compact">
          <div className="NewIssueSettingsBlock__left">&nbsp;</div>
          <div className="NewIssueSettingsBlock__right">
            {this.state.issue &&
            this.state.issue.object &&
            this.state.issue.object.documents &&
            this.state.issue.object.documents.reduce(
              (p, doc) => (doc.identity.name.indexOf('screenshot_') === 0 ? true : p),
              false
            ) ? (
              'Screenshot has been attached'
            ) : (
              <span>
                Press <b>{this.getKeyLabel('printscreen')}</b> key to take a screenshot and{' '}
                <b>{this.getKeyLabel('paste')}</b> to attach it to the issue
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render controls for issue tasks management
   * @return
   */
  renderIssueTasks = (editState) => {
    return (
      <div className="IssueDialog">
        <div
          className={
            'NewIssueSettingsBlock IssueDialog__tableTasks ' +
            (!this.props.issue ? 'IssueDialog__tableTasks_create' : 'IssueDialog__tableTasks_edit')
          }
        >
          <div className="NewIssueSettingsBlock__left">
            <label className="IssueDialog__label">Tasks:</label>
          </div>
          <div className="NewIssueSettingsBlock__right" onKeyPress={this.taskKeyPress}>
            <TypedTable
              ref={this.tasktableRef}
              hideHeader
              data={
                this.state.issue && this.state.issue.tasks
                  ? this.state.issue.tasks
                      .filter((task) => !task.isDeleted)
                      .map((task, index) =>
                        Object.assign({}, task, {
                          id: index,
                          number: task.identity.name + '.',
                          name: task.identity.description
                        })
                      )
                      .sort((a, b) => {
                        if (parseInt(a.number) > parseInt(b.number)) return 1
                        if (parseInt(b.number) > parseInt(a.number)) return -1
                        return 0
                      })
                  : []
              }
              columns={
                this.props.issue
                  ? [
                      {
                        name: 'check',
                        type: columnsToType.getType('isDefault'),
                        width: 24
                      },
                      {
                        name: 'number',
                        type: columnsToType.getType('value'),
                        frozen: true,
                        width: 20
                      },
                      {
                        name: 'name',
                        type: columnsToType.getType('taskName'),
                        width: 720 - 94 + 90
                      }
                    ]
                  : [
                      {
                        name: 'number',
                        type: columnsToType.getType('value'),
                        frozen: true,
                        width: 20
                      },
                      {
                        name: 'name',
                        type: columnsToType.getType('taskName'),
                        width: 740 - 90 + 90
                      }
                    ]
              }
              isEditable={editState > editableState.EDITABLE}
              inEditMode={editState > editableState.EDITABLE}
              bottomButtons={[{ label: 'Add task', onClick: this.onAddTask.bind(this) }]}
              tailButtons={[{ label: 'Delete', onClick: this.onDeleteTask.bind(this) }]}
              onChangeRow={this.onEditTask.bind(this)}
              addDeleteText={{ add: 'Add task', delete: 'Delete task' }}
            />
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render controls for attched object selection
   * @return
   */
  renderIssueAttached = (editState) => {
    return (
      <div className="IssueDialog">
        <div className="NewIssueSettingsBlock NewIssueSettingsBlock__borderBlue">
          <div className="NewIssueSettingsBlock__left">
            <div className="IssueDialog__label">Attached to:</div>
          </div>
          <div className="NewIssueSettingsBlock__right">
            <div className="NewIssueSettingsBlock__objectSearch">
              <ObjectSelector
                appState={this.props.appState}
                actions={this.props.actions}
                startObjectPath={this.getAttachedPath()}
                onSelect={this.onAttachedChange.bind(this)}
                onAdjust={this.onAdjust.bind(this)}
                showByStep={false}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render controls for assigned person selection
   * @return
   */
  renderIssueAssigned = (editState) => {
    const metucatTeamUser = {
      user: { id: '', name: 'MetUCat team' },
      object: { id: '57bca937-14f0-416f-9b9d-9a03d9a38254', name: 'Test org' },
      type: 'Org',
      role: 'Architect',
      history: {},
      profile: {
        identity: { id: '', name: 'MetUCat Team' },
        firstname: 'MetUCat',
        lastname: 'Team',
        alias: ' ',
        position: '',
        phone: '',
        email: 'Assign to MetUCat development team'
      }
    }
    return (
      <div className="IssueDialog">
        <div className="NewIssueSettingsBlock NewIssueSettingsBlock__borderGray">
          <div className="NewIssueSettingsBlock__left">
            <label className="IssueDialog__label">Assigned to:</label>
            <label className="IssueDialog__label NewIssueSettingsBlock__track NewIssueSettingsBlock__setting">
              Track:
              <input
                type="checkbox"
                value={this.isTracked()}
                className="IssueDialog CheckBox"
                onClick={this.onTrackChange}
                checked={this.isTracked()}
              />
              <div className="check"></div>
            </label>
          </div>
          <div className="NewIssueSettingsBlock__right">
            <div className="NewIssueSettingsBlock__userSearch">
              <UserSearch
                objectPath={this.getAttachedPath()}
                onSelect={(user) => {
                  this.onAssignedChange(user)
                }}
                onAdjust={this.onAdjust.bind(this)}
                selectedUser={this.getAssignedUser()}
                allowObjectUsers
                firstOption={window.localInstallation ? null : metucatTeamUser}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render controls for assigned person selection
   * @return
   */
  renderIssueProperties = (editState) => {
    const deadlineDetails: any = deadlineCalculation(this.state.issue)

    //console.log("ipl",issuePropList['type']);

    return (
      <div className="IssueDialog">
        <div className="NewIssueSettingsBlock NewIssueSettingsBlock_borderGray">
          <div className="row">
            <div className="col NewIssueSettingsBlock__typeCol">
              <label className="IssueDialog__columnLabel IssueDialog__columnLabelType">Type</label>
              {issuePropList['type'].source.data.records.map((issuetypeRecord, index) => {
                const typeName = issuetypeRecord.values[issuePropList['type'].fields.indexOf('type')]
                const typeCode = issuetypeRecord.values[issuePropList['type'].fields.indexOf('code')]
                return (
                  <span key={index}>
                    <label htmlFor={'t-' + typeCode} className="NewIssueSettingsBlock__setting">
                      <input
                        id={'t-' + typeCode}
                        type="radio"
                        name="type"
                        value={typeName}
                        onClick={this.onTypeChange}
                        checked={this.isPropertyValueEquals('type', typeName)}
                      />
                      <div className="check"></div>
                      {typeName}
                    </label>
                    <br />
                  </span>
                )
              })}
            </div>
            <div className="col NewIssueSettingsBlock__priorityCol">
              <label className="IssueDialog__columnLabel IssueDialog__columnLabelPriority">Priority</label>
              {issuePropList['priority'].source.data.records.map((priorityRecord, index) => {
                const priorityName = priorityRecord.values[issuePropList['priority'].fields.indexOf('name')]
                const priorityCode = priorityRecord.values[issuePropList['priority'].fields.indexOf('code')]
                return (
                  <div
                    onClick={this.onPriorityChange}
                    data-value={priorityName}
                    key={index}
                    className={
                      'IssuesView__priority ' +
                      (this.isPropertyValueEquals('priority', priorityName) ? 'IssuesView__activeLeftFilter' : '')
                    }
                  >
                    {issuePriorityIcon(priorityCode)}
                    {priorityName}
                  </div>
                )
              })}
            </div>
            <div className="col NewIssueSettingsBlock__statusCol">
              <label className="IssueDialog__columnLabel IssueDialog__columnLabelStatus">Status</label>
              {issuePropList['status'].source.data.records.map((statusRecord, index) => {
                const statusName = statusRecord.values[issuePropList['status'].fields.indexOf('name')]
                const statusIcon = getStatusIcon(statusName)
                return (
                  <div
                    onClick={this.onStatusChange}
                    data-value={statusName}
                    key={index}
                    className={
                      'IssuesView__statusFilter ' +
                      ('IssuesView__statusFilter__' + statusName + ' ') +
                      (this.isPropertyValueEquals('status', statusName) ? 'IssuesView__statusFilter__active' : '')
                    }
                  >
                    <img src={statusIcon} alt="status icon" />
                    {statusName}
                  </div>
                )
              })}
            </div>
            <div className="col NewIssueSettingsBlock__resolutionCol">
              <label className="IssueDialog__columnLabel IssueDialog__columnLabelResolution">Resolution</label>
              {issuePropList['resolution'].source.data.records.map((resolutionRecord, index) => {
                const resolutionName = resolutionRecord.values[issuePropList['resolution'].fields.indexOf('name')]
                const resolutionCode = resolutionRecord.values[issuePropList['resolution'].fields.indexOf('code')]
                return (
                  <span key={index}>
                    <label htmlFor={'r-' + resolutionCode} className="NewIssueSettingsBlock__setting">
                      <input
                        id={'r-' + resolutionCode}
                        type="checkbox"
                        name="resolution"
                        value={resolutionName}
                        onClick={this.onResolutionChange}
                        checked={this.isPropertyValueEquals('resolution', resolutionName)}
                      />
                      <div className="check"></div>
                      {resolutionName}
                    </label>
                    <br />
                  </span>
                )
              })}
            </div>
          </div>
        </div>
        <div className="NewIssueSettingsBlock NewIssueSettingsBlock_borderGray">
          {deadlineDetails.nextStatus ? (
            <div>
              <span className="IssueDialog__comment">
                Time to Close: &nbsp;
                {deadlineDetails.fullTime} &times; {deadlineDetails.typePeriod} &times; {deadlineDetails.toClosePercent}
                % = {deadlineDetails.toCloseTime}
              </span>
              {deadlineDetails.nextStatus !== 'Closed' ? (
                <div>
                  <label className="IssueDialog__comment">
                    Time to {deadlineDetails.nextStatus}:&nbsp;
                    {deadlineDetails.fullTime} &times; {deadlineDetails.typePeriod} &times;{' '}
                    {deadlineDetails.toNextPercent}% = {deadlineDetails.toNextTime}
                  </label>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    )
  }

  renderIssueMessage = (editState) => {
    return (
      <IssueNewMessage
        issue={this.state.issue}
        users={this.props.users.map((u) => ({
          display: u.profile.alias,
          id: u.user.id
        }))}
        messageUpdateListener={this.onMessageChange}
        selected
        disableSend
      />
    )
  }

  /**
   * Render controls for assigned person selection
   * @return
   */
  renderIssueLog = (editState) => {
    const log = createLog(this.props.issue, this.state.issue)

    return (
      <div className="IssueDialog IssueDialog_log">
        {log.length > 0 ? (
          <div>
            <div className="NewIssueSettingsBlock__left">
              <label className="IssueDialog_log__label">Log:</label>
            </div>
            <div className="NewIssueSettingsBlock__right">
              <div className="IssueDialog_log__log">{renderLog(log)}</div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  /**
   * Render advance settings for issue
   * @return
   */
  renderIssueSettings = (editState) => {
    return (
      <div className="IssueDialog">
        <div className="NewIssueSettingsBlock NewIssueSettingsBlock_collapsable">
          {!this.state.editingDocs ? null : this.renderDocuments()}
          {!this.state.advancedSettingsOpened ? (
            <div className="NewIssueSettingsBlock__title">
              <strong>Advanced settings</strong>
            </div>
          ) : (
            <div className="row NewIssueSettingsBlock__content">
              <div className="col-xs-12">{this.renderTags()}</div>
              <div className="col-xs-12">{this.renderDocuments()}</div>
              <div className="col-xs-3">
                <SettingsDialog
                  buttonTitle={'Advanced Settings'}
                  isVisible
                  isEditable={editableState.EDITING}
                  isItems={itemState.VERTICAL}
                  sortItems
                  maxItems={5}
                  settings={this.state.issue && this.state.issue.object ? this.state.issue.object.properties : []}
                  majorObject={this.props.parent}
                  onClose={() => this.setState({ editingSetting: false })}
                  onSave={this.onSettingsChange}
                />
              </div>
            </div>
          )}
          <div className="NewIssueSettingsBlock_collapsable IssueDialog__collapse_title">
            <div
              className={
                'IssueDialog__collapse ' +
                'IssueDialog__collapse_' +
                (this.state.advancedSettingsOpened ? 'opened' : 'closed')
              }
              onClick={() => {
                this.onAdjust(0, (this.state.advancedSettingsOpened ? -1 : 1) * 82)
                this.setState({
                  advancedSettingsOpened: !this.state.advancedSettingsOpened
                })
              }}
            >
              <img src={iArrowDownClose} alt="" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  /**
   * loads list of parent organization's tags into local state
   */
  loadOrganizationTags(done) {
    //console.log("loadOrganizationTags",this.props.parent);
    const organizationName = objectNameFromPathByType(getFullUri(this.props.parent), 'organizations')
    //console.log("loadOrganizationTags organizationName",organizationName);
    API.organizations(organizationName)
      .get()
      .then((org) => {
        done(org.object.tags)
      })
  }

  /**
   * Render tag list
   * @return
   */
  renderTags = () => {
    const majorObject = this.props.parent

    let tags =
      this.state.issue && this.state.issue.object && this.state.issue.object.tags ? this.state.issue.object.tags : []
    //console.log("Issue:renderTags", tags, majorObject, this.state, this.props);

    return (
      <div>
        <TagsDialog
          appState={this.props.appState}
          actions={this.props.actions}
          level={0.2}
          buttonTitle={'Tags:'}
          isVisible
          isEditable={editableState.EDITING}
          isItems={itemState.HORIZONTAL}
          tags={tags}
          majorObject={majorObject}
          issue={this.state.issue}
          onClose={this.onTagsClose.bind(this)}
          onSave={this.onTagsChange.bind(this)}
        />
      </div>
    )
  }

  /**
   * Render tag list
   * @return
   */
  renderDocuments = () => {
    const majorObject = this.props.parent

    let docs =
      this.state.issue && this.state.issue.object && this.state.issue.object.documents
        ? this.state.issue.object.documents
        : []
    console.log('Issue:renderDocuments', docs, majorObject, this.state, this.props)

    return (
      <div>
        <DocsDialog
          appState={this.props.appState}
          actions={this.props.actions}
          level={0.2}
          buttonTitle={'Documents:'}
          isVisible
          isEditable={editableState.EDITING}
          isItems={this.state.editingDocs ? itemState.NONE : itemState.HORIZONTAL}
          majorObject={majorObject}
          docs={docs}
          content={this.state.uploadContent}
          contentUri={getDocumentUri(this.state.issue.identity)}
          onContentChange={this.onContentChange}
          onClose={this.onDocsClose.bind(this)}
          onSave={this.onDocsChange.bind(this)}
        />
      </div>
    )
  }

  /**
   * Render scrollable area of dialog
   * @return
   */
  renderData = (editState) => {
    //console.log("IssueDialog:renderData", this.state, this.props, editState);
    return (
      <div className="IssueDialog">
        {this.renderIssueIdentity(editState)}
        {this.renderIssueTasks(editState)}
        {this.renderIssueAttached(editState)}
        {this.renderIssueAssigned(editState)}
        {this.renderIssueProperties(editState)}
        {this.renderIssueSettings(editState)}
        {this.renderIssueMessage(editState)}
        {this.renderIssueLog(editState)}
      </div>
    )
  }

  /**
   *
   * Render function of dialog
   * @return
   */
  render() {
    //console.log("IssueDialog:render", this.props, this.state);

    return (
      <div className="IssueDialog">
        <EditorDialog
          ref={this.editordialogRef}
          objectType="issue"
          modalTitle={this.props.issue ? 'Edit issue ' + this.props.issue.identity.name : 'Create issue'}
          confirmText={this.props.issue ? 'Update' : 'Create'}
          editContent={this.renderData.bind(this)}
          headerContent={renderRestore(this)}
          editHeight={this.state.height}
          isVisible={this.props.isVisible}
          isEditable={this.props.isEditable}
          onClose={this.onClose.bind(this)}
          onSave={this.onSave.bind(this)}
          onEdit={this.onEdit.bind(this)}
          onCancel={this.onCancel.bind(this)}
        />
      </div>
    )
  }
}
