/**
 * Created by Sasha Berger on 01.12.19.
 *
 * Component to edit elements of dialogs. Component use field and element data to
 * render information and collect data.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { getObjectNewOrJson } from '../../helpers/api'
import {
  datasetToEnumOptions,
  displayReferenceUri,
  editableState,
  getFullUri,
  itemPosition,
  nonCSCompare,
  objectNameFromPathByType
} from '../../helpers/index'
import logger from '../../helpers/logger'
import { getElementLabel } from '../../resources/lib/metadata'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { Loader } from '../Loader/Loader'
import { ContentLoader } from './ContentLoader'
import { DataSelector } from './DataSelector'
import { FieldSelector } from './FieldSelector'
import { ObjectEditor } from './ObjectEditor'
import { ReferenceSelector } from './ReferenceSelector'
import { dequal } from 'dequal'

export class DataEditor extends Component {
  constructor(props) {
    super(props)

    const path = getFullUri(this.props.majorObject)
    const organization = objectNameFromPathByType(path, 'organizations')

    this.state = {
      organization: organization,
      path: path,

      loadingDataset: false, // Loading options in case value represented by options
      enumOptions: null, // Options loaded.
      dataStructure: null, // Options loaded.
      loadedDataset: false
    }
  }

  componentDidMount() {
    //logger.info("DataEditor:componentDidMount");
    this.loadData(this.props)
  }

  componentWillReceiveProps(newProps) {
    //logger.info("DataEditor:componentWillReceiveProps", newProps, this.state, this.props);
    if (
      (newProps.field && !dequal(newProps.field, this.props.field)) ||
      (newProps.dataset && !dequal(newProps.dataset, this.props.dataset)) ||
      newProps.isEditable !== this.props.isEditable
    ) {
      this.loadData(newProps)
    }
  }

  /**
   * Load dataset with data
   * @param props - current state of properties
   * @return asynchronously add datasets to state
   */
  loadData = (props) => {
    //logger.info("DataEditor:loadData", field, this.state, this.props);
    if (props.isEditable > editableState.EDITABLE && !this.state.loadingDataset) {
      // Check field type
      let field = props.field
      let type = field.type ? field.type.toLowerCase() : 'string'

      // Load needed data
      if (nonCSCompare(type, 'enum')) {
        this.loadEnumOptions(props)
      } else if (nonCSCompare(type, 'structure')) {
        this.loadDataStructure(props)
      }
    }
  }

  /**
   * Load dataset with enumerator specification. Work asynchronously
   * @param props - state of properties
   * @return asynchronously add datasets to state
   */
  loadEnumOptions = (props) => {
    // Use options from props or try to download.
    let field = props.field

    //logger.info("DataEditor:loadEnumOptions", field, props, this.state, this.props);
    if (props.options && props.options.enumOptions) {
      //logger.info("DataEditor:loadEnumOptions:EXTERNAL", props.options.enumOptions);
      this.setState({ enumOptions: props.options.enumOptions })
    } else if (field && field.reference && field.reference.length > 0) {
      // We already have options
      if (this.state.enumOptions && this.state.loadedDataset && field.reference === this.props.field.reference) {
        return
      }

      let path = field.reference
      // logger.info('DataEditor:loadEnumOptions:REQUEST', path, field, this.state, this.props)

      // Mark it as loading begin
      this.setState({ loadingDataset: true })

      getObjectNewOrJson(path, 'dataset', null, true, this.state.organization).then(
        (dataset) => {
          const datasetName = objectNameFromPathByType(field.reference, 'datasets')

          let usage = field.usage === 'Property' ? 'Name' : 'Value'
          let options = []
          if (!nonCSCompare(datasetName, 'usage')) {
            options = datasetToEnumOptions(dataset, usage)
            //logger.info("DataEditor:loadEnumOptions", usage, field, dataset, options);
          } else {
            let objType = this.props.majorObject.object.type
            if (!objType) {
              // Let's try type from object.
              objType = this.props.majorObject.type
            }
            // This is for old objects with strange types
            if (objType === 'Org' || objType === 'org') {
              objType = 'organization'
            }
            options = datasetToEnumOptions(dataset, 'Value').filter((op) => nonCSCompare(op.object['Object'], objType))
            //logger.info("DataEditor:loadEnumOptions:USAGE", field, dataset, options, objType, this.props);
          }

          this.setState({
            enumOptions: options,
            loadingDataset: false,
            loadedDataset: true
          })
        },
        (error) => {
          logger.warn('PropertyEditor:loadDataOptions:ERROR', error)
          this.setState({
            dataOptions: [],
            loadingDataset: false,
            loadedDataset: false
          })
          if (this.props.onError) {
            this.props.onError('System', 1004, error, { path: path }, this.props.element, this.props.field)
          }
        }
      )
    }
  }

  /**
   * Load dataset with structure specification
   * @param props - state of properties
   * @return asynchronously add datasets to state
   */
  loadDataStructure = (props) => {
    // Reference have valid uri for data to download.
    let field = props.field

    if (props.dataset) {
      //logger.info("DataEditor:loadDataStructure", field, dataset);
      this.setState({ dataStructure: props.dataset })
    } else if (field.reference && field.reference.length > 0) {
      // We already have dataset
      if (this.state.dataStructure && this.state.loadedDataset && field.reference === this.props.field.reference) {
        //logger.info("DataEditor:loadDataStructure:LOADED", field, this.state, this.props);
        return
      }

      let path = field.reference
      //logger.info("DataEditor:loadDataStructure:REQUEST", field, this.state, this.props);

      // Mark it as loading begin
      this.setState({ loadingDataset: true })

      getObjectNewOrJson(path, 'dataset', null, false, this.state.organization).then(
        (dataset) => {
          //logger.info("DataEditor:loadDataStructure:RESULT", field, dataset);
          this.setState({
            dataStructure: dataset,
            loadingDataset: false,
            loadedDataset: true
          })
        },
        (error) => {
          logger.warn('PropertyEditor:loadDataStructure:ERROR', error, this.props)
          this.setState({
            dataStructure: {},
            loadingDataset: false,
            loadedDataset: false
          })
          if (this.props.onError) {
            this.props.onError('System', 1005, error, { path: path }, this.props.element, this.props.field)
          }
        }
      )
    }
  }

  /**
   * Render field data based field and element information.
   * @param dataField - field with enumerator data specification
   * @param dataElement - field with enumerator data specification
   * @return rendering information for field
   */
  renderField(dataField, dataElement) {
    if (dataField.identity && dataField.identity.name === 'Reference' && dataField.type === 'String') {
      dataField.type = 'Reference'
    }
    // Field and element parameters for rendering
    let fieldName = dataField.identity ? dataField.identity.name : ''
    let fieldType = dataField.type ? dataField.type.toLowerCase() : 'string'
    let fieldValue = this.props.data || ''
    let fieldPrivacy = dataField.privacy

    let fieldEditable = this.props.isEditable
    let fieldLoading = this.state.loadingDataset || fieldEditable === editableState.LOADING
    let fieldContent = null
    let fieldControl = itemPosition.NONE

    // For long text
    let fieldLines = 1
    if (nonCSCompare(fieldType, 'string') && !dataField.size) {
      fieldType = 'text_updatable'
      fieldLines = 3
    }

    // Get data from element if provided
    let fieldTip = this.props.options.placeholder
    if (dataElement && dataElement.control && dataElement.control.value) {
      if (!fieldTip) {
        if (this.props.base) {
          fieldTip = typeof this.props.base === 'object' ? JSON.stringify(this.props.base) : this.props.base
        } else {
          fieldTip = dataElement.control.value['editTip']
        }
      }

      // Change field type for string format.
      let editFormat = dataElement.control.value['editFormat']
      if (!editFormat) {
        editFormat = dataField['format']
      }

      if (!editFormat) {
        // no format
      } else if (editFormat.toLowerCase().startsWith('string')) {
        fieldType = 'string'
        fieldLines = 1
      } else if (editFormat && editFormat.toLowerCase().startsWith('content')) {
        // Prepare content for loader.
        let align = dataElement.align.toLowerCase()
        fieldType = 'content'
        fieldControl =
          align.indexOf('top') !== -1
            ? itemPosition.TOP
            : align.indexOf('bottom')
            ? itemPosition.BOTTOM
            : itemPosition.NON
        fieldContent = (this.props.content || []).find((cont) => nonCSCompare(cont.fileURI, fieldValue))
        if (!fieldContent && fieldValue) {
          fieldContent = { fileURI: fieldValue }
        }
      } else if (editFormat && editFormat.toLowerCase().startsWith('reference')) {
        fieldType = 'reference'
      } else if (editFormat && editFormat.toLowerCase().startsWith('selector')) {
        fieldType = 'selector'
      } else if (editFormat && editFormat.toLowerCase().startsWith('fieldselector')) {
        fieldType = 'fieldselector'
      } else if (editFormat && editFormat.toLowerCase().startsWith('font')) {
        fieldType = 'font'
      } else if (editFormat && editFormat.toLowerCase().startsWith('background')) {
        fieldType = 'background'
      } else if (editFormat && editFormat.toLowerCase().startsWith('border')) {
        fieldType = 'border'
      }
    }

    // Options for types with selected options
    let fieldReference = dataField.reference
    let fieldSubscription = dataField.subscription
    let fieldUri = ''
    let fieldOptions = []
    let fieldOption = ''
    let fieldStructure = null
    if (nonCSCompare(fieldType, 'enum')) {
      if (this.state.enumOptions) {
        fieldOptions = this.state.enumOptions

        // We have filter we can filter options.
        if (this.props.options.onFilter) {
          fieldOptions = this.props.options.onFilter(fieldOptions)
        }

        // Try to find option for the value and extract value.
        fieldOption = fieldOptions.find((op) => (op.usage ? op.usage[dataField.usage] : op.value) === fieldValue)
        if (fieldOption) {
          fieldValue = fieldOption.usage ? fieldOption.usage['Name'] : fieldOption.label
        }
      }
      //logger.info("DataEditor:render:ENUM", {dataField, fieldType, fieldOption, fieldValue, fieldEditable, fieldLoading }, this.state, this.props);
    } else if (nonCSCompare(fieldType, 'structure') && fieldReference) {
      fieldUri = displayReferenceUri(fieldReference, fieldSubscription)
      fieldStructure = this.state.dataStructure

      // We on reference to app structure.
      if (fieldReference[0] === '$') {
        fieldUri = fieldName
      }
      //logger.info("DataEditor:render:STRUCTURE", { fieldName, fieldType, fieldReference, fieldSubscription, fieldUri, fieldStructure, fieldValue, fieldEditable, fieldLoading }, this.state, this.props);
    } else if (
      nonCSCompare(fieldType, 'selector') ||
      nonCSCompare(fieldType, 'fieldselector') ||
      nonCSCompare(fieldType, 'reference')
    ) {
      fieldOptions = this.props.options.enumOptions
      if (this.props.options.onFilter) {
        fieldOptions = this.props.options.onFilter(fieldOptions)
      }

      // Need to prepare options and values for selection.
      if (!fieldValue) {
        // We have no
        fieldValue = []
      } else {
        // We don't have array of values we have singale value
        if (!Array.isArray(fieldValue)) {
          // Let's convert it to array with one element.
          fieldValue = [fieldValue]
        }

        // Need to create list of items and selection of items.
        if (fieldOptions) {
          if (this.props.dataset) {
            // Field selector and we have options only from first level of dataset fields.
            fieldValue = fieldValue.map((item, index) => {
              const { fieldData } = this.props
              return {
                id: index,
                label: item,
                value: item,
                description: fieldData ? fieldData.filter((field) => item === field.label)[0].description : ''
              }
            })
          } else {
            // Normal selector from list of options
            fieldValue = fieldOptions.filter((op) => fieldValue.indexOf(op.label) !== -1)
          }
        } else {
          fieldOptions = fieldValue.map((item, index) => {
            const { fieldData } = this.props
            return {
              id: index,
              label: item,
              value: item,
              description: fieldData ? fieldData.filter((field) => item === field.label)[0].description : ''
            }
          })
          fieldValue = fieldOptions
        }
      }
      //logger.info("DataEditor:render:SELECTOR", { fieldName, fieldType, fieldReference, fieldSubscription, fieldUri, fieldStructure, fieldValue, fieldOptions, fieldEditable, fieldLoading }, this.state, this.props);
    }

    // Merge input and current properties
    const fieldProps = Object.assign({}, this.props.options, {
      onChange: (event) => {
        // find selected object for select input
        const option = fieldOptions.find((op) => op.value === event.target.value)
        this.props.options.onChange(
          option && option.usage && option.usage[dataField.usage] ? option.usage[dataField.usage] : event.target.value,
          null,
          option
        )
        // console.log(event, option, option && option.usage ? option.usage[dataField.usage] : event.target.value)
      },
      options: fieldOptions,
      placeholder: fieldTip,
      maxLineCount: fieldLines,
      isEditable: fieldEditable
    })
    // logger.info(
    //   'DataEditor:render',
    //   {
    //     dataField,
    //     fieldType,
    //     fieldValue,
    //     fieldTip,
    //     fieldReference,
    //     fieldOption,
    //     fieldOptions,
    //     fieldProps,
    //     fieldEditable,
    //     fieldLoading
    //   },
    //   this.state,
    //   this.props
    // )

    // Show loader on case we are waiting for data.
    if (fieldLoading) return <Loader />

    switch (fieldType) {
      default:
        return (
          <EditableEntity
            dataType={{
              name: fieldType,
              options: fieldOptions,
              privacy: fieldPrivacy
            }}
            dataProps={fieldProps}
            data={fieldValue}
            isEditable={fieldEditable >= editableState.EDITABLE}
            inEditMode={fieldEditable > editableState.EDITABLE}
          />
        )
      case 'structure':
        return (
          <ObjectEditor
            appState={this.props.appState}
            actions={this.props.actions}
            options={fieldProps}
            header={fieldUri}
            modalTitle={fieldName && fieldName.startsWith('$$$$') ? fieldName.substring(4) : fieldName}
            noSchema
            isVisible
            isEditable={fieldEditable}
            majorObject={this.props.majorObject}
            dataset={fieldStructure}
            data={fieldValue}
            onChange={this.props.options.onChange}
          />
        )
      case 'content':
        return (
          <ContentLoader
            ref="content_loader"
            appState={this.props.appState}
            actions={this.props.actions}
            class={this.props.class}
            options={fieldProps}
            isVisible
            isEditable={fieldEditable}
            majorObject={this.props.majorObject}
            parentDialog={this.props.parentDialog ? this.props.parentDialog.getDialogRef() : null}
            controlPosition={fieldControl}
            content={fieldContent}
            contentUri={this.props.contentUri}
            onChange={this.props.onContentChange}
            onAdjust={this.props.onAdjust}
          />
        )
      case 'selector':
        return (
          <DataSelector
            captions={['NN', 'Name']}
            columns={['id', 'label']}
            editable={fieldEditable > editableState.EDITABLE}
            majorObject={this.props.majorObject}
            selected={fieldValue}
            items={fieldOptions}
            onSelect={(selected) => this.props.options.onChange(selected.map((item) => item.label))}
            onAdjust={this.props.onAdjust}
          />
        )
      case 'fieldselector':
        return (
          <FieldSelector
            captions={['NN', 'Name']}
            columns={['id', 'label']}
            editable={fieldEditable > editableState.EDITABLE}
            majorObject={this.props.majorObject}
            selected={fieldValue}
            items={fieldOptions}
            dataset={this.props.dataset}
            onSelect={(selected) => this.props.options.onChange(selected.map((item) => item.label))}
            onAdjust={this.props.onAdjust}
          />
        )
      case 'reference':
        return (
          <ReferenceSelector
            appState={this.props.appState}
            actions={this.props.actions}
            columns={['id', 'label']}
            dataProps={fieldProps}
            isVisible
            isEditable={fieldEditable}
            type={this.props.type}
            data={fieldValue ? (Array.isArray(fieldValue) ? fieldValue[0] : fieldValue) : null}
            onVersionChange={this.props.onVersionChange}
            onAdjust={this.props.onAdjust}
          />
        )
    }
  }

  render() {
    //logger.info("DataEditor:render", this.state, this.props);
    // Render editor as part of dialog
    if (this.props.class !== undefined && this.props.element) {
      let elementClass = this.props.class ? '_' + this.props.class : ''
      return (
        <div className={'DataEditor__block' + elementClass + ' clearfix'}>
          <label className="DataEditor__block__label">
            <EditableEntity
              dataType={{ name: 'propertyName' }}
              data={{
                name: getElementLabel(this.props.element),
                description: this.props.element.identity.description || this.props.field.identity.description
              }}
              dataProps={{
                hideMore: true,
                maxLineCount: 2
              }}
            />
          </label>
          <div className={'DataEditor__block' + elementClass + '__value_right'}>
            {this.renderField(this.props.field, this.props.element)}
            {this.props.options.warning ? (
              <div className={'DataEditor__block' + elementClass + '__value_right__warning'}>
                {this.props.options.warning}
              </div>
            ) : null}
          </div>
        </div>
      )
    } else {
      return this.renderField(this.props.field, this.props.element)
    }
  }
}

DataEditor.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  class: PropTypes.string, // Class information for data dialog editor components.
  options: PropTypes.object.isRequired, // Options for editing data.
  isEditable: PropTypes.number, // State of editor
  majorObject: PropTypes.object,
  parentDialog: PropTypes.object, // Reference to parent dialog for editor.
  field: PropTypes.object.isRequired, // Field for data to introduce
  data: PropTypes.string, // Data for field, it can be dataset if field is structure
  base: PropTypes.string, // Base data for field, it can be dataset if field is structure
  element: PropTypes.object, // Element of the view represented field
  dataset: PropTypes.object, // Dataset contains stracture of the data if field is structure
  content: PropTypes.object, // Content to use for content loader
  onVersionChange: PropTypes.func, // Change of version of object for reference
  onContentChange: PropTypes.func, // Change content loaded or deleted
  onAdjust: PropTypes.func // Adjust parent dialog sige to feet changes.
}
