/**
 * Created by mailf on 08.06.2016,
 * MOdified By sashab on 7/20/2020
 *
 * Manged content in form of table and suport stracture as a row in the table.
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'

// please dont remove it, because all app will fall down!!!
import DataEditor from '../DataDialog/DataEditor'

import logger from '../../helpers/logger'
import SimpleTable from './SimpleTable'
import { TypedTableCell } from './TypedTableCell'
import { isMultilineType } from '../../helpers'
import { editableState } from '../../helpers/index'
import { PagedContent } from './PagedContent'
import { convertWidth, defaultCompare } from './typedTableHelper'

const TAIL_MIN_SIZE = 20
const TAIL_COLLAPSED_SIZE = 10
const TAIL_BUTTON_SIZE = 70
const EXPAND_BUTTON_SIZE = 28
const CLEAR_FILTERS_WIDTH = 200
const DEFAULT_TAIL_HEIGHT = 57

/**
 * TypedTable is used to render {columns, data} into table of EditableEntities
 * data = array of objects, object keys are columns[].name as in old Table component
 * It is externally controlled
 * onChange is raw event of editing any cell
 * onChangeRow sends full record(row)
 */
export class TypedTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editingField: false,
      tableId: this.props.firstRowIndex,
      sortColumn: false,
      sortDirection: false,
      currentPage: 0,
      rowsPerPage: this.props.pageSize || 100
    }

    // logger.info("TypedTable:constructor", this.state.tableId, this.state, this.props);
  }

  componentDidMount() {
    if (this.props.columns) {
      let sum = 0
      this.props.columns.map((col) => (sum += parseInt(col.width)))
      //console.log("TypedTable total width", sum);
    }

    // workaround to set correct Height for Tail buttons (MultilineText updates its height after first redraw,
    // so we need to refresh the table when MultilineText has collapsed)
    const hasMultilineColumns = this.props.columns.reduce(
      (found, col) => found || (col && col.type && isMultilineType(col.type.name)),
      false
    )
    if (hasMultilineColumns) {
      setTimeout(() => {
        this.forceUpdate()
      }, 0)
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // this is needed to refresh tail buttons height
    if (newProps.columns.length !== this.props.columns.length || newProps.data.length !== this.props.data.length) {
      //logger.info("TypedTable:componentWillReceiveProps", this.state.tableId, newProps.data.firstRowIndex, newProps, this.state, this.props);
      this.setState({ tableId: Math.random().toString(16).substr(2, 8) })
    }
  }

  setEditingField = (newEditingField) => {
    //console.log("TypedTable::setEditingField", this.state.editingField, newEditingField);
    this.setState({ editingField: newEditingField })
  }

  deselect = () => {
    this.setEditingField(null)
  }

  /**
   * render one cell
   * @param value
   * @param colIndex
   * @param rowIndex
   * @param col
   * @param row
   * @returns {XML}
   */
  renderCell(value, colIndex, rowIndex, col, row) {
    const editable = this.props.isEditable && !col.frozen
    const editMode =
      editable &&
      ((this.state.editingField &&
        this.state.editingField.colIndex === colIndex &&
        this.state.editingField.rowIndex === rowIndex) ||
        col.inEditMode)
    const editState = editable ? (editMode ? editableState.EDITING : editableState.EDITABLE) : editableState.BROWSABLE

    // console.log('TypedTable:renderCell', value, colIndex, rowIndex, col, row, this.props)

    return (
      <TypedTableCell
        key={colIndex}
        value={value}
        editable={col.type.name === 'boolean' ? true : editable}
        editMode={col.type.name === 'boolean' ? true : editMode}
        type={Array.isArray(value) ? { name: 'array' } : col.type || this.props.getType(colIndex, rowIndex)}
        colIndex={colIndex}
        rowIndex={rowIndex}
        col={col}
        row={row}
        rowData={this.props.data[rowIndex]}
        setEditingField={this.setEditingField}
        onChange={this.props.onChange}
        onChangeRow={this.props.onChangeRow}
        onBlur={this.props.onBlur}
        onDeselect={this.deselect}
        firstRowIndex={this.props.firstRowIndex}
        transEnum={this.props.transEnum ? this.props.transEnum : false}
        transView={this.props.transView ? this.props.transView : false}
      />
    )
  }

  /**
   * render all cells to double array
   * @returns {*}
   */
  getCellValues() {
    let sortedData = this.props.data.slice()
    if (this.state.sortColumn !== false)
      sortedData = sortedData.sort((rowA, rowB) => {
        const sortCol = this.props.columns[this.state.sortColumn].name
        const compare = this.props.columns[this.state.sortColumn].sortFunc || this.defaultCompare
        if (this.state.sortDirection === 'desc') {
          return compare(rowA[sortCol], rowB[sortCol])
        } else {
          return compare(rowB[sortCol], rowA[sortCol])
        }
      })
    //logger.info("TypedTable:getCellValues", sortedData, this.state, this.props);
    return sortedData.map((row, rowIndex) => {
      //return row.map((value, index) => this.renderCell(value, index, rowIndex));
      return this.props.columns.map((col, colIndex) =>
        this.renderCell(
          col.displayName === 'EMail & Login' && row[col.name] !== row.profile.identity.name
            ? [row[col.name], row.profile.identity.name]
            : row[col.name],
          colIndex,
          rowIndex,
          col,
          row
        )
      )
    })
  }

  /**
   * render tail buttons for a row
   * @param row
   * @param index
   * @param hideButtons
   * @returns {*}
   */
  renderTail(row, index, hideButtons = false) {
    if (
      (!this.props.tailButtons || this.props.tailButtons.length === 0) &&
      (!this.props.customColumns || this.props.customColumns.length === 0)
    ) {
      return null
    }

    let buttons = hideButtons ? [] : this.props.tailButtons || []

    if (typeof buttons === 'function') {
      buttons = buttons(row, index)
    }

    // calculate width for tail container
    let element = TAIL_MIN_SIZE
    buttons.map((button) => {
      element += button.size || TAIL_BUTTON_SIZE
    })

    let width = 0
    for (let i = this.props.columns.length - 1; i >= 0; i--) {
      width += this.props.columns[i].width
      if (width > element) {
        // Check if width is too long for element.
        if (width - element > EXPAND_BUTTON_SIZE) {
          // It is way too long.
          if (width - element > element / 2) {
            width = element
          }
        }
        //console.error("TypedTable:renderTail", width, element, this.props);
        break
      }
    }

    if (hideButtons) {
      width = TAIL_COLLAPSED_SIZE
    }

    const id = 'floatingButtons_' + this.state.tableId + '_' + (row.id || index)
    const rowElement = document.getElementById(id)
    const heightOfRow = rowElement ? rowElement.parentElement.offsetHeight : DEFAULT_TAIL_HEIGHT
    // logger.info("TypedTable:renderTail", { row, index, hideButtons, width, id, rowElement }, this.state, this.props);

    const divButtons = (
      <td
        key={2 * index}
        className={
          'TypedTable__floatingButtonsOuter ' + (row.rowName ? 'TypedTable__floatingButtons__' + row.rowName : '')
        }
        style={{ height: heightOfRow }}
        id={id}
      >
        <div className="TypedTable__floatingButtons">
          <div className={'TypedTable__floatingButtonsInner'} style={{ left: -width, width: width }}>
            {buttons.map((button, buttonIndex) => {
              const buttonLabel = typeof button.label === 'function' ? button.label(row, index) : button.label
              return (
                <span key={buttonIndex}>
                  <a
                    onClick={(e) => {
                      button.onClick(row, index)
                      e.stopPropagation()
                    }}
                  >
                    {buttonLabel}
                  </a>
                </span>
              )
            })}
          </div>
        </div>
      </td>
    )

    if (index === -1) {
      return divButtons
    }

    return [
      divButtons,
      (this.props.customColumns || []).map((col) => (
        <div key={2 * index + 1} className={'TypedTable__customColumn TypedTable__customColumn_' + col.name}>
          {row[col.name]}
        </div>
      ))
    ]
  }

  clearAllFilters(e) {
    e.preventDefault()
    this.props.onClearAllFilters()
  }

  /**
   * render table filters (generated by FilterableContent and passed through filters/dataFilters properties)
   * @returns {XML}
   */
  renderFilters() {
    const { columns, filters, dataFilters, topButtons } = this.props
    const fullWidth = columns.reduce((p, col) => p + col.width, 0)
    const filtersWidth = dataFilters.reduce((p, col) => p + col.width, 0)

    let remainingWidth = 40
    if (typeof fullWidth === 'number' && typeof filtersWidth === 'number') {
      remainingWidth = fullWidth - CLEAR_FILTERS_WIDTH - filtersWidth - 16
    }
    //console.log("TypedTable:renderFilters", { columns, filters, dataFilters, fullWidth, filtersWidth, remainingWidth }, this.state, this.props);

    return (
      <tr className="Table__filters">
        <th colSpan="100">
          <div
            className="Table__customFilterCell"
            style={{
              width: convertWidth(CLEAR_FILTERS_WIDTH, this.props.isFullscreen, this.props.widthBeforeFullscreen)
            }}
          >
            Filters:{' '}
            {this.props.activeFilters && this.props.activeFilters.length > 0 ? (
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a className="Table__clearFilter" onClick={this.clearAllFilters.bind(this)}>
                  Clear
                </a>
              </span>
            ) : null}
          </div>

          {dataFilters.map((filter, index) => {
            // Calculate width for filters
            const width = convertWidth(filter.width, this.props.isFullscreen, this.props.widthBeforeFullscreen)
            const style = { width: width }
            //console.log("TypedTable:renderFilters", { filter, index, width, style }, this.state, this.props);

            return (
              <div className="Table__customFilterCell" key={index} style={style}>
                {filters[filter.name] || '\u00a0'}
              </div>
            )
          })}

          {(topButtons || []).length > 0 ? (
            <div className="Table__customFilterCell TypedTable__topButtonsCell ">
              {topButtons.map((button, index) => this.renderTopButton(button, index))}
            </div>
          ) : null}
        </th>
      </tr>
    )
  }

  /**
   * render one topbutton
   * @param button {label:string, onClick: ()=>{}}
   * @param index
   * @returns {XML}
   */
  renderTopButton(button, index) {
    return (
      <span key={index} className="btn_edit TypedTable__topButton" onClick={button.onClick}>
        {button.label}
      </span>
    )
  }

  /**
   * render one bottom button
   * @param button {label:string, onClick: ()=>{}}
   * @param buttonIndex
   * @returns {XML}
   */
  renderBottomButton(button, buttonIndex) {
    return (
      <span key={buttonIndex} className="btn_edit TypedTable__bottomButton" onClick={button.onClick}>
        {button.label}
      </span>
    )
  }

  renderColHeader(col, index) {
    if (!col.sortable) return col.displayName || col.name
    else
      return (
        <span key={index}>
          {col.displayName || col.name}
          <span
            className={
              'TypedTable__sortButton ' +
              (this.state.sortColumn === index ? 'TypedTable__sortButton__active ' : '') +
              (this.state.sortColumn === index && this.state.sortDirection === 'desc'
                ? ' TypedTable__sortButton__desc'
                : '')
            }
            onClick={() => {
              if (this.state.sortColumn === index) {
                this.setState({
                  sortDirection: this.state.sortDirection === 'desc' ? 'asc' : 'desc'
                })
              } else {
                this.setState({ sortColumn: index, sortDirection: 'asc' })
              }
            }}
          />
        </span>
      )
  }

  onPageChange = (page, data, dataBefore, dataAfter) => {
    if (this.props.onPageChange) {
      return this.props.onPageChange(page, data, dataAfter, dataBefore)
    }

    this.setState({ currentPage: page })
  }

  render() {
    let {
      columns,
      data,
      className,
      hideHeader,
      filters,
      bottomButtons,
      bottom,
      tailButtons,
      contextMenuButtons,
      tablePath,
      firstRowIndex,
      setMinWidth,
      noSetWidth,
      headBgColor,
      headFontColor
    } = this.props

    if (!columns || !data) {
      // empty table not allowed
      logger.error('TypedTable', this.props)
    }

    return (
      <div className="TypedTable" id={'typedtable_' + this.state.tableId}>
        <PagedContent
          data={this.getCellValues()}
          pageSize={this.state.rowsPerPage}
          defaultPage={this.props.defaultPage}
          onPageChange={this.onPageChange}
          dataPropertyName="cellValues"
          scrollToTop={this.props.scrollToTop}
        >
          <SimpleTable
            colHeaders={hideHeader ? [] : columns.map((col, index) => this.renderColHeader(col, index))}
            colWidths={columns.map((col) =>
              convertWidth(col.width, this.props.isFullscreen, this.props.widthBeforeFullscreen)
            )}
            className={className}
            rowClassNames={data
              .slice(
                this.state.currentPage * this.state.rowsPerPage,
                (this.state.currentPage + 1) * this.state.rowsPerPage
              )
              .map((rec, index) =>
                this.state.editingField && index === this.state.editingField.rowIndex ? 'TypedTable__editingRow' : ''
              )}
            filters={filters ? this.renderFilters() : null}
            cellValues={[]}
            upperTail={this.renderTail([], -1)}
            tails={data
              .slice(
                this.state.currentPage * this.state.rowsPerPage,
                (this.state.currentPage + 1) * this.state.rowsPerPage
              )
              .map((row, index) => this.renderTail(row, index))}
            zeroTails={data
              .slice(
                this.state.currentPage * this.state.rowsPerPage,
                (this.state.currentPage + 1) * this.state.rowsPerPage
              )
              .map((row, index) => this.renderTail(row, index, true))}
            contextMenuButtons={contextMenuButtons}
            contextMenuCollect={(index) => data[index]}
            tableId={`${this.state.tableId}`}
            tablePath={tablePath}
            fieldMode={this.props.fieldMode}
            fieldState={this.props.fieldState}
            onFieldClick={this.props.onFieldClick}
            firstRowIndex={firstRowIndex}
            setMinWidth={setMinWidth}
            noSetWidth={noSetWidth}
            headBgColor={headBgColor}
            headFontColor={headFontColor}
          />
        </PagedContent>
        {bottom || (bottomButtons && bottomButtons.length > 0) ? (
          <div className="TypedTable__bottom">
            {bottom}
            {(bottomButtons || []).map((button, buttonIndex) => this.renderBottomButton(button, buttonIndex))}
          </div>
        ) : null}
      </div>
    )
  }
}

TypedTable.propTypes = {
  columns: PropTypes.array.isRequired, // array of {name, width, (opt.)type, (opt.)displayName, (opt.)inEditMode: bool}
  data: PropTypes.array, // array of objects, object keys = columns.names
  getType: PropTypes.func, // (colIndex, rowIndex) => type (optional, used if columns[i].type is not set)
  className: PropTypes.string,
  hideHeader: PropTypes.bool,
  isEditable: PropTypes.bool,
  onChange: PropTypes.func, // (colIndex, rowIndex, col, oldValue, newValue)
  onChangeRow: PropTypes.func, // (rowIndex, oldRow, newRow)
  onBlur: PropTypes.func, // (colIndex, rowIndex, value)
  tailButtons: PropTypes.array, // array of {label, onClick}
  contextMenuButtons: PropTypes.array, // array of {label, onClick, showInBottom}
  bottomButtons: PropTypes.array, // display at table bottom; array of {label, onClick}
  bottom: PropTypes.string || PropTypes.component, // block (string/component) to display to the bottom of the table
  noSetWidth: PropTypes.bool, // width is not set
  filters: PropTypes.object, // array of string/component from FilterableContent
  activeFilters: PropTypes.string,
  dataFilters: PropTypes.array, // array from FilterableContent
  topButtons: PropTypes.array, // array of {label, onClick} to show to the right of the filters
  tablePath: PropTypes.string, // Path to the table data, it is used before name of fields for ids
  fieldMode: PropTypes.object, // Map of fields mode. When presented it show only field in defined state.
  fieldState: PropTypes.object, // Map of fields with special state
  firstRowIndex: PropTypes.number, // see SimpleTable.firstRowIndex
  setMinWidth: PropTypes.bool,
  customColumns: PropTypes.array, //
  headBgColor: PropTypes.string,
  headFontColor: PropTypes.string,
  isFullscreen: PropTypes.bool,
  widthBeforeFullscreen: PropTypes.number,
  onFieldClick: PropTypes.func, // Callback for field click. Used for structure tables.
  onClearAllFilters: PropTypes.func
}
