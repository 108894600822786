/**
 * Created by kascode on 15.05.17.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { getFullUri, objectNameFromPathByType } from '../../helpers'
import {
  formatEnumName,
  formatObjectName,
  getOrganizationDataset,
  getRequestFromPath,
  requestVersionedObjectByURI
} from '../../helpers/api'
import {
  editableState,
  getSpecFieldOrdinal,
  getSpecRecords,
  monokaiJsonTheme,
  nonCSCompare,
  pathForMajorVersion
} from '../../helpers/index'
import logger from '../../helpers/logger'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { DataDialogNew } from './DataDialogNew'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditButton } from '../EditButton/EditButton'
import { GenerateParamsDialog } from '../GenerateParamsDialog/GenerateParamsDialog'
import { JSONTreeCopy } from '../JSONTreeCopy/JSONTreeCopy'
import './ObjectEditor.scss'

export class ObjectEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      jsonMode: false,
      errorMessage: '',
      editingData: false,

      editingSchemaParams: false,
      schemaParams: props.match?.params
        ? props.match.params
        : {
            schemaVersion: '2.0', // Version of Swagger schema
            nameOption: 'lower', // Use upper/lower case for first latter for name in schema and remove spaces.
            enumOption: 'none', // Use upper/lower case and replace spaces with underscores for values in enums.
            hideFuture: true, // Hide field with prefix FUTURE:.
            hideOptional: false, // Hide optional and keep only required field.
            approvedVersion: true // Use last approve minor version for specified major version.
          }
    }
  }

  componentDidMount() {
    this.LoadTypes()
  }

  /**
   * Load types information
   * @return
   */
  LoadTypes = () => {
    let types = this.state.types
    if (!types) {
      // Load types from organization
      const organization = objectNameFromPathByType(getFullUri(this.props.majorObject), 'organizations')
      //logger.info("ObjectEditor::LoadTypes", { types, organization }, this.state, this.props);

      getOrganizationDataset('Type', organization).then((result) => {
        this.setState({
          types: result,
          typeSystem: getSpecFieldOrdinal(result, 'Type'),
          typeJson: getSpecFieldOrdinal(result, 'RESTfull-API')
        })
      })
    }
  }

  /**
   * Object from text of JSON
   * @param {string} [data] text to format
   */
  formatJSON(data) {
    if (!data) return null

    if (typeof data === 'object') {
      return Object.keys(data).length === 0 ? null : data
    }

    try {
      const obj = JSON.parse(data)
      if (!obj) {
        return null
      }
      return obj
    } catch (e) {
      return null
    }
  }

  /**
   * Generate JSON field value
   * @param field - field structure.
   * @return value.
   */
  createFieldExample = (field) => {
    if (!field || !field.value || field.value.length === 0) return ''

    const types = this.state.types
    const typeJson = this.state.typeJson
    const typeSystem = this.state.typeSystem

    let type = getSpecRecords(types).find((t) => nonCSCompare(t.values[typeSystem], field.type)).values[typeJson]
    //logger.info("ObjectEditor::createField", field, type);
    if (type === 'string') {
      return field.value
    } else if (type === 'boolean') {
      return nonCSCompare(field.value, 'true') ? true : false
    } else if (type === 'integer') {
      return Number(field.value)
    } else if (type === 'number') {
      return Number(field.value)
    } else {
      return field.value
    }
  }

  /**
   * Generate JSON documentexample for dataset
   * @param optional - requeried optional fields in example.
   * @return example data.
   */
  createDatasetExample = (ds, optional, example) => {
    if (!ds || !ds.structure) {
      return
    }

    let dsExample = example ? example : {}
    const dsPromises = []

    const nameOption = this.state.nameOption
    const hideFuture = this.state.hideFuture

    if (!example) {
      logger.info('ObjectEditor::createDatasetExample', ds, optional)
      if (this.props.onChange) {
        this.props.onChange(null, 'Create example for dataset...')
      }
    }

    // We need fields array in order of fields.
    let fields = ds.structure.fields.sort((a, b) => {
      const ao = parseInt(a.order)
      const bo = parseInt(b.order)

      if (ao > bo) return 1
      else if (bo > ao) return -1

      return 0
    })

    // We need to iterate all fields of structure and create fields.
    for (let j in fields) {
      const field = fields[j]

      // Optional properties nor requiered.
      if (!optional && field.optional === true) continue

      // Get name from identity or from field reference.
      let name = field.identity.name
      if (name.toUpperCase().startsWith('FUTURE:')) {
        if (hideFuture === true) continue

        name = name.substring(7)
      }

      // Get name from identity or from field reference.
      name = formatObjectName(name, nameOption)
      if (!name) continue

      console.log('ObjectEditor:createDatasetExample:NAME', name, field)

      // Generate Item types.
      this.generateItemType(ds, field, name, optional, dsPromises, dsExample)
    }

    return Promise.all(dsPromises).then(
      (result) => {
        if (!example) {
          logger.info('ObjectEditor:createDatasetExample:RESULT', dsExample)

          // Update example
          this.props.onChange(dsExample)
        }
      },
      (err) => {
        //logger.info("ObjectEditor::createDatasetExample:ERROR", err);
        if (this.props.onChange) {
          this.props.Change(null, 'Error: ' + err.message)
        }
      }
    )
  }

  /**
   * Generate type data for item
   * @param field - field information to extract data for type.
   * @param type - type of the field for JSON.
   * @param item - item to add type to.
   * @return schema, ref or value
   */
  generateItemType = (ds, field, name, optional, dsPromises, dsExample) => {
    // Type for property. If schema required we created intermidiate item.
    const approved = this.state.approvedVersion
    let reference = field.reference ? pathForMajorVersion(field.reference) : ''
    let item
    //console.log("ObjectEditor:generateItemType", field, dsExample);

    if (nonCSCompare(field.type, 'Structure') && field.reference) {
      // Schema for array of items.
      if (field.count === 0 || field.count > 1) {
        // We are processing array of structures
        dsExample[name] = []
        item = dsExample[name][0] = {}
      } else {
        // We are processing structure, lets see do we need process inheritance
        if (name.toUpperCase().startsWith('INHERIT:')) {
          item = dsExample
          //console.log("ObjectEditor:generateItemType:INHERIT", name, item, dsExample);
        } else {
          item = dsExample[name] = {}
        }
      }

      // We go into recursion if we on reference.
      dsPromises.push(
        requestVersionedObjectByURI(reference, approved).then(
          (obj) => {
            //console.log("ObjectEditor:generateItemType:OBJECT", obj, dsPromises.length, dsPromises);
            return this.createDatasetExample(obj, optional, item)
          },
          (err) => {
            console.error('ObjectEditor:generateItemType:DATASET:ERROR', err, reference, ds)
            return false
          }
        )
      )
    } else if (nonCSCompare(field.type, 'Enum') && field.reference) {
      // Setup regular field custom schema properties
      let value = ''
      if (field.value && field.value.length > 0) {
        const enumOption = this.state.enumOption
        value = formatEnumName(field.value, field.usage, enumOption)
      }

      // Schema for array of items.
      if (field.count === 0 || field.count > 1) {
        dsExample[name] = []
        item = dsExample[name][0] = value
      } else {
        item = dsExample[name] = value
      }
    } else if (nonCSCompare(field.type, 'Reference') && field.reference) {
      // Get organization path.
      const organization = objectNameFromPathByType(getFullUri(this.props.majorObject), 'organizations')
      let identityUri = '/organizations/' + organization + '/datasets/Identity'

      // Schema for array of items.
      if (field.count === 0 || field.count > 1) {
        dsExample[name] = []
        item = dsExample[name][0] = {}
      } else {
        item = dsExample[name] = {}
      }

      // We go into recursion if we need reference structure.
      dsPromises.push(
        getRequestFromPath(identityUri)
          .get()
          .then(
            (obj) => {
              //console.log("ObjectEditor:generateItemType:REFERENCE", obj, dsPromises.length, dsPromises);
              return this.createDatasetExample(obj, optional, item)
            },
            (err) => {
              console.error('ObjectEditor:generateItemType:REFERENCE:ERROR', err, reference, ds)
              return false
            }
          )
      )
    } else {
      // Schema for array of items.
      if (field.count === 0 || field.count > 1) {
        dsExample[name] = []
        item = dsExample[name][0] = this.createFieldExample(field)
      } else {
        item = dsExample[name] = this.createFieldExample(field)
      }

      //logger.info("ObjectEditor:generateItemType:ITEM", dsExample, item, field);
    }
    return true
  }

  /**
   * Generate empy example of dataset based on types and default values.
   * @param params - schema params from Generate dialog
   */
  onDataGenerate = (schemaParams) => {
    console.log('ObjectEditor:onDataGenerate', schemaParams, this.props, this.state)
    this.setState(schemaParams || {}, () => {
      this.createDatasetExample(this.props.dataset, schemaParams && schemaParams.hideOptional ? false : true)
    })
  }

  /**
   * Clear current state of dialog from all settings it in.
   */
  onDataClear = () => {
    //logger.info("ExampleDialog:onDataClear", data, this.props);
    this.setState({ jsonMode: false, errorMessage: '', editingData: false })

    this.props.onChange(null)
  }

  /**
   * Editing data by data dialog.
   */
  onDataDialog = (edit) => {
    logger.info('ExampleDialog:onDataDialog', edit, this.props)
    if (edit) {
      if (!this.props.dataset || !this.props.dataset.structure || !this.props.dataset.structure.fields) {
        this.setState({
          editingData: false,
          errorMessage: 'Incorrect object structure: ' + JSON.stringify(this.props.dataset)
        })
      } else {
        this.setState({ editingData: true, errorMessage: '' })
      }
    } else {
      this.setState({ editingData: false, errorMessage: '' })
    }
  }

  /**
   * Event when user ask to generate object json.
   */
  onGenerateClick = () => {
    if (this.props.noSchema) {
      this.onDataGenerate(this.state.schemaParams)
    } else {
      this.setState({ editingSchemaParams: true })
    }
  }

  /**
   * On update of data in text form. if data not correct it will be not formated.
   */
  onTextChange = (e) => {
    let data = e.target.value
    console.log('ObjectEditor:onTextChange', data, this.props)
    this.setState({ errorMessage: '' })

    let obj = this.formatJSON(data)

    this.props.onChange(obj ? obj : data)
  }

  /**
   * On update of data from dialog. if data not correct it will be not formated.
   */
  onDataSave = (data, closeDialog, onSent) => {
    //logger.info("ObjectEditor:onDataSave", data, closeDialog);
    if (onSent) onSent(closeDialog)

    this.props.onChange(data)
  }

  /**
   * Render generate params dialog. Dialog provide ability to specify parameters for genetration
   * We don't use it here as we use difaults on;y for now.
   * @returns {XML}
   */
  renderSchemaParams() {
    //logger.info("ObjectEditor:renderSchemaParams", this.props, this.state);
    let params = this.state.schemaParams
    return (
      <GenerateParamsDialog
        params={params}
        onClose={() => this.setState({ editingSchemaParams: false })}
        onGenerate={(params) => {
          this.setState({ editingSchemaParams: false, schemaParams: params })
          //logger.info("ObjectEditor:renderSchemaParams:GENERATE", params);
          this.onDataGenerate(params)
        }}
      />
    )
  }

  /**
   * Render dialog for data loading. This is data dialog we need to render.
   */
  renderDataDialog(editState) {
    console.log('ObjectEditor:renderDataDialog', this.state, this.props)

    return (
      <div className="ExampleDialog">
        <DataDialogNew
          appState={this.props.appState}
          actions={this.props.actions}
          modalTitle={this.props.modalTitle}
          isVisible
          isEditable={editState > editableState.EDITABLE ? editableState.EDITING : editableState.BROWSABLE}
          majorObject={this.props.majorObject}
          dataset={this.props.dataset}
          data={this.props.data ? this.formatJSON(this.props.data) : null}
          onClose={() => {
            this.onDataDialog(false)
          }}
          onSave={this.onDataSave}
        />
      </div>
    )
  }

  /**
   * Render data in text or brousable mode.
   */
  renderData = (editState) => {
    let obj = this.props.data ? this.formatJSON(this.props.data) : null
    let text = obj ? JSON.stringify(obj) : this.props.data
    let error = (obj ? '' : 'Incorrect JSON!') + (this.state.errorMessage ? ' ' + this.state.errorMessage : '')

    // Merge input and current properties
    const objectProps = Object.assign({}, this.props.options, {
      className: 'ObjectEditor__text',
      placeholder:
        this.props.options && this.props.options.placeholder
          ? this.props.options.placeholder
          : 'Type JSON directly, generate empty JSON object by pusshing button <Generate> or open input dialog by pushing button <Edit>',
      onChange: this.onTextChange
    })
    //logger.info("ObjectEditor::renderData", { obj, text }, this.state, this.props);

    return editState > editableState.EDITABLE && !this.state.jsonMode ? (
      <span className="ObjectEditor">
        <EditableEntity
          data={text}
          dataType={{ name: 'text_updatable' }}
          dataProps={objectProps}
          isEditable
          inEditMode
        />
        {error ? <span className="ObjectEditor__error">{error}</span> : ''}
      </span>
    ) : (
      <span>
        <JSONTreeCopy data={obj} shouldExpandNode={() => false} theme={monokaiJsonTheme} />
      </span>
    )
  }

  render() {
    // State of execution for component
    let isEditable = this.props.isEditable

    return (
      <div className="clearfix">
        {this.state.editingSchemaParams ? this.renderSchemaParams() : null}
        <div className="ObjectEditor__block_text clearfix">
          {this.props.header.length > 30 ? (
            <div className="ObjectEditor__block_text clearfix">
              <label className="ObjectEditor__type">{this.props.header}</label>
            </div>
          ) : (
            <label className="ObjectEditor__type">{this.props.header}</label>
          )}
          <div className="ObjectEditor__block_text clearfix">
            {isEditable > editableState.EDITABLE ? (
              <div className="ObjectEditor">
                {this.props.dataset ? (
                  <div
                    onClick={this.onGenerateClick}
                    value={'Generate'}
                    key={0}
                    className={'ObjectEditor__controlButton ObjectEditor__controlButton__active'}
                  >
                    {'Generate'}
                  </div>
                ) : null}
                <div
                  onClick={this.onDataClear}
                  value={'Clear'}
                  key={1}
                  className={'ObjectEditor__controlButton ObjectEditor__controlButton__active'}
                >
                  {'Clear'}
                </div>
                <div
                  onClick={() => this.setState({ jsonMode: !this.state.jsonMode })}
                  className={'ObjectEditor__controlButton ObjectEditor__controlButton__active'}
                >
                  {!this.state.jsonMode ? 'View JSON' : 'View Text'}
                </div>
                {this.props.dataset ? (
                  <EditButton
                    onEdit={() => {
                      this.onDataDialog(true)
                    }}
                  />
                ) : null}
              </div>
            ) : null}
            {isEditable === editableState.EDITABLE ? (
              <div className="ObjectEditor">
                <div
                  onClick={this.onDataClear}
                  value={'Clear'}
                  key={1}
                  className={'ObjectEditor__controlButton ObjectEditor__controlButton__active'}
                >
                  {'Clear'}
                </div>
              </div>
            ) : null}
            {this.state.editingData ? this.renderDataDialog(isEditable) : null}
          </div>
        </div>
        <div className="ObjectEditor__block_text clearfix">{this.renderData(isEditable)}</div>
      </div>
    )
  }
}

ObjectEditor.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired, // Options for editing data.
  header: PropTypes.string.isRequired, // Header of component. Usually it is name/path of presented object
  modalTitle: PropTypes.string, // Title of data dialog when data edited by it.
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  noSchema: PropTypes.bool, // if schema parameters dialog are not needed
  majorObject: PropTypes.object, // Major object we handle data for
  dataset: PropTypes.object, // Dataset object which specify structure of data we introduce
  data: PropTypes.object, // Data of object we are rendering, if already exist.
  onChange: PropTypes.func // This is called when data change in component
}
