import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { formatForStructureTable } from '../../helpers'
import { getDatasetPathFromReference } from '../../helpers/index'
//import DatasetTable from './DatasetTable';
import { EnumTable } from './EnumTable'
import { LoadableContent } from './LoadableContent'
import { TypedTable } from './TypedTable'

// how much child table is shifted to the right (in pixels)
const CHILD_MARGIN = 32

/**
 * StructureTable is used to render Dataset.Structure
 * child Structures are expanded into child StructureTables
 * child Enums are expanded into child EnumTables
 * StructureTable is not editable and is externally controlled
 */
export class StructureTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      expandedRows: [],
      expandedReferenceRows: []
    }
  }

  toggleExpandedRow(index) {
    let newExpandedRows = this.state.expandedRows.slice()
    newExpandedRows[index] = !newExpandedRows[index]
    this.setState({ expandedRows: newExpandedRows }, () => {
      if (this.props.onExpand) this.props.onExpand(index, newExpandedRows[index])
    })
  }

  toggleExpandedReferenceRow(index) {
    let newExpandedRows = this.state.expandedReferenceRows.slice()
    newExpandedRows[index] = !newExpandedRows[index]
    this.setState({ expandedReferenceRows: newExpandedRows })
    if (this.props.onExpand) this.props.onExpand(index, newExpandedRows[index])
  }

  toggleAllRows() {
    const isExpanded = this.state.expandedRows.reduce((p, c) => (c ? c : p), false)
    let newExpandedRows = this.props.data.map((row) => !isExpanded)
    this.setState({ expandedRows: newExpandedRows })
    if (this.props.onExpand) this.props.onExpand(-1, isExpanded)
  }

  getExpandButtonText = (type) => {
    if (type === 'structure') return '+'
    if (type === 'enum') return '#'
    if (type === 'reference') return '&'

    return '+'
  }

  /**
   * Render table divided by groups
   * This is old code and it don't work for paged content.
   * @returns {XML}
   */
  renderOld() {
    let {
      columns,
      data,
      level,
      tablePath,
      objectPath,
      tailButtons,
      contextMenuButtons,
      bottomButtons,
      className,
      filters,
      dataFilters,
      topButtons,
      customColumns,
      headBgColor,
      headFontColor,
      isFullscreen,
      widthBeforeFullscreen
    } = this.props
    level = level || 0
    tablePath = tablePath || ''

    // As in PropertyTable, the fields are divided into groups, structures and enums are placed into separate child tables
    let fieldGroups = [[]]

    data.map((field, index) => {
      field.index = index
      if (field.type.datasetReference) {
        fieldGroups.push([field])
        fieldGroups.push([])
      } else {
        fieldGroups[fieldGroups.length - 1].push(field)
      }
    })

    fieldGroups = fieldGroups.filter((group) => group.length > 0)

    if (fieldGroups.length === 0) fieldGroups.push([])

    //console.log("StructureTable groups",fieldGroups);
    //console.log("StructureTable total width level="+level, columns.reduce((p,c)=>p+parseInt(c.width), 0));

    let hasExpandedBody = false
    let rowIndex = 0

    return (
      <div>
        {fieldGroups.map((group, groupIndex) => {
          let currentRowIndex = rowIndex
          rowIndex += group.length
          let expandButton = ''
          let expandedBody = ''

          //console.log("group", group.map(g => Object.assign({}, g, {expandButton: expandButton})));

          if (group.length > 0 && group[0].type.datasetReference) {
            //expandedBody = "expanded body";
            const isEnum = group[0].type.label.toLowerCase() === 'enum'
            const isReference = group[0].type.label.toLowerCase() === 'reference'

            let widthFound = false
            const newTableMargin =
              columns.reduce((w, col) => w + (col.hiddenInChildTable ? col.width : 0), 0) + CHILD_MARGIN
            const newColumns = columns
              .filter((col) => !col.hiddenInChildTable)
              .map((col) => {
                if (!widthFound && col.width > CHILD_MARGIN) {
                  widthFound = true
                  return Object.assign({}, col, {
                    width: col.width - CHILD_MARGIN - (isReference ? CHILD_MARGIN : 0)
                  })
                }
                return col
              })
            const customColumns = columns
              .filter((col) => col.customDisplayInChildTable)
              .concat(this.props.customColumns || [])
            //console.log("customColumns", this.props.customColumns, customColumns);

            expandButton = (
              <div
                className={
                  'StructureTable__expandButton ' +
                  (level > 0 ? 'StructureTable__expandButton__nested ' : ' ') +
                  (isEnum ? 'StructureTable__expandButton__enum ' : ' ') +
                  (this.state.expandedRows[groupIndex] ? 'StructureTable__expandButton_active' : '')
                }
                onClick={() => this.toggleExpandedRow(groupIndex)}
              >
                {this.state.expandedRows[groupIndex]
                  ? '-'
                  : this.getExpandButtonText(group[0].type.label.toLowerCase())}
              </div>
            )

            let childPath = getDatasetPathFromReference(group[0].type.datasetReference, objectPath)
            if (this.props.showReferenceAsIdentity) childPath = '/Identity'

            expandedBody = (
              <div
                className="StructureTable__childRow"
                style={{
                  marginLeft: newTableMargin + (isReference ? CHILD_MARGIN : 0) + 'px'
                }}
              >
                <LoadableContent
                  visible={
                    isReference ? this.state.expandedReferenceRows[groupIndex] : this.state.expandedRows[groupIndex]
                  }
                  path={childPath}
                  dataPropertyName={isEnum ? 'dataset' : 'data'}
                  dataTransform={(structureObject) => {
                    //console.log("StructureTable:dataTransform", structureObject);
                    return isEnum ? structureObject : formatForStructureTable(structureObject)
                  }}
                  onLoad={this.props.onLoad}
                >
                  {isEnum ? (
                    <EnumTable colWidthsAuto={1188 - level * 40} />
                  ) : (
                    <StructureTable
                      level={level + 1 + (isReference ? 1 : 0)}
                      tablePath={tablePath + '.' + group[0].identity.name}
                      onFieldClick={this.props.onFieldClick}
                      columns={newColumns}
                      customColumns={customColumns}
                      onExpand={this.props.onExpand}
                      showReferenceAsIdentity={this.props.showReferenceAsIdentity}
                    />
                  )}
                </LoadableContent>
              </div>
            )

            if (expandedBody && this.state.expandedRows[groupIndex]) hasExpandedBody = true

            if (isReference) {
              if (this.state.expandedRows[groupIndex]) {
                if (this.props.showReferenceAsIdentity) {
                  expandedBody = (
                    <div>
                      {this.state.expandedRows[groupIndex] ? (
                        <div
                          className="StructureTable__childRowReference StructureTable__childRow"
                          style={{ marginLeft: newTableMargin + 'px' }}
                        >
                          <div
                            className={
                              'StructureTable__expandButton ' +
                              (level > 0 ? 'StructureTable__expandButton__nested ' : ' ') +
                              (this.state.expandedReferenceRows[groupIndex]
                                ? 'StructureTable__expandButton_active'
                                : '')
                            }
                            onClick={() => this.toggleExpandedReferenceRow(groupIndex)}
                          >
                            {this.state.expandedReferenceRows[groupIndex] ? '-' : '+'}
                          </div>
                          <div className="StructureTable__referenceIdentityUsage">Identity</div>
                          <div className="StructureTable__referenceIdentityType">Structure: /Identity</div>
                        </div>
                      ) : null}
                      {this.state.expandedRows[groupIndex] ? expandedBody : null}
                    </div>
                  )
                } else {
                  expandedBody = (
                    <div>
                      {this.state.expandedRows[groupIndex] ? (
                        <div
                          className="StructureTable__childRowReference StructureTable__childRow"
                          style={{ marginLeft: newTableMargin + 'px' }}
                        >
                          <div
                            className={
                              'StructureTable__expandButton ' +
                              (level > 0 ? 'StructureTable__expandButton__nested ' : ' ') +
                              (this.state.expandedReferenceRows[groupIndex]
                                ? 'StructureTable__expandButton_active'
                                : '')
                            }
                            onClick={() => this.toggleExpandedReferenceRow(groupIndex)}
                          >
                            {this.state.expandedReferenceRows[groupIndex] ? '-' : '+'}
                          </div>
                          <div className="StructureTable__referenceIdentityLabel">Identity</div>
                          <div className="StructureTable__referenceIdentityDescription">Referenced Structure</div>
                          <div className="StructureTable__referenceIdentityUsage">Identity</div>
                          <div className="StructureTable__referenceIdentityType">Structure: /Identity</div>
                        </div>
                      ) : null}
                      {this.state.expandedRows[groupIndex] ? expandedBody : null}
                    </div>
                  )
                }
              } else {
                expandedBody = ''
              }
            } else {
              expandedBody = <div>{expandedBody}</div>
            }
          }
          return (
            <div data-table-path={tablePath} className="PagedContent__outerTyped" key={groupIndex}>
              <TypedTable
                data={group.map((g) => Object.assign({}, g, { expandButton: expandButton }))}
                ref={'typedtable' + groupIndex}
                className={
                  className +
                  ' StructureTable ' +
                  (hasExpandedBody ? 'StructureTable__expanded' : '') +
                  ' ' +
                  (level > 0 ? 'StructureTable__nested' : '')
                }
                hideHeader={groupIndex > 0 || level > 0}
                columns={columns}
                customColumns={customColumns}
                tailButtons={tailButtons}
                contextMenuButtons={contextMenuButtons}
                bottomButtons={groupIndex === fieldGroups.length - 1 && level === 0 ? bottomButtons : []}
                bottom={expandedBody}
                filters={groupIndex === 0 ? filters : false}
                dataFilters={groupIndex === 0 ? dataFilters : false}
                topButtons={groupIndex === 0 ? topButtons : false}
                firstRowIndex={currentRowIndex}
                tablePath={tablePath}
                headBgColor={headBgColor}
                headFontColor={headFontColor}
                isFullscreen={isFullscreen}
                widthBeforeFullscreen={widthBeforeFullscreen}
                onPageChange={this.props.onPageChange}
                pageSize={this.props.pageSize}
                defaultPage={this.props.defaultPage}
              />
            </div>
          )
        })}
      </div>
    )
  }

  /**
   * Render table as list of fields. It works well with staged content
   * @returns {XML}
   */
  render() {
    let {
      columns,
      data,
      level,
      tablePath,
      objectPath,
      tailButtons,
      contextMenuButtons,
      bottomButtons,
      className,
      filters,
      dataFilters,
      topButtons,
      customColumns,
      headBgColor,
      headFontColor,
      isFullscreen,
      widthBeforeFullscreen
    } = this.props
    level = level || 0
    tablePath = tablePath || ''

    let rootLength = tablePath ? tablePath.length : 0
    let rootPath =
      rootLength > 0
        ? tablePath[rootLength - 1] !== '/' && tablePath[rootLength - 1] !== '.'
          ? tablePath + '.'
          : tablePath
        : ''
    // logger.info("StructureTable:render", { level, tablePath, rootLength, rootPath}, this.state, this.props);

    let rowIndex = 0
    let tableData = data.map((field, index) => {
      let hasExpandedBody = false
      let expandButton = ''
      let expandedBody = ''

      //logger.info("StructureTable:render:GROUP", group.map(g => Object.assign({}, g, {expandButton: expandButton})));

      if (field.type.datasetReference) {
        //expandedBody = "expanded body";
        const isEnum = field.type.label.toLowerCase() === 'enum'
        const isReference = field.type.label.toLowerCase() === 'reference'

        let widthFound = false
        const newTableMargin =
          columns.reduce((w, col) => w + (col.hiddenInChildTable ? col.width : 0), 0) + CHILD_MARGIN
        const newColumns = columns
          .filter((col) => !col.hiddenInChildTable)
          .map((col) => {
            if (!widthFound && col.width > CHILD_MARGIN) {
              widthFound = true
              return Object.assign({}, col, {
                width: col.width - CHILD_MARGIN - (isReference ? CHILD_MARGIN : 0)
              })
            }
            return col
          })
        const customColumns = columns
          .filter((col) => col.customDisplayInChildTable)
          .concat(this.props.customColumns || [])

        expandButton = (
          <div
            className={
              'StructureTable__expandButton ' +
              (level > 0 ? 'StructureTable__expandButton__nested ' : ' ') +
              (isEnum ? 'StructureTable__expandButton__enum ' : ' ') +
              (this.state.expandedRows[index] ? 'StructureTable__expandButton_active' : '')
            }
            onClick={() => this.toggleExpandedRow(index)}
          >
            {this.state.expandedRows[index] ? '-' : this.getExpandButtonText(field.type.label.toLowerCase())}
          </div>
        )

        let childPath = getDatasetPathFromReference(field.type.datasetReference, objectPath)
        if (this.props.showReferenceAsIdentity) childPath = '/Identity'

        expandedBody = (
          <div
            className="StructureTable__childRow"
            style={{
              marginLeft: newTableMargin + (isReference ? CHILD_MARGIN : 0) + 'px'
            }}
          >
            <LoadableContent
              visible={isReference ? this.state.expandedReferenceRows[index] : this.state.expandedRows[index]}
              path={childPath}
              dataPropertyName={isEnum ? 'dataset' : 'data'}
              dataTransform={(structureObject) => {
                //logger.info("StructureTable:render:dataTransform", structureObject);
                return isEnum ? structureObject : formatForStructureTable(structureObject)
              }}
              onLoad={this.props.onLoad}
            >
              {isEnum ? (
                <EnumTable colWidthsAuto={1188 - level * 40} />
              ) : (
                <StructureTable
                  level={level + 1 + (isReference ? 1 : 0)}
                  tablePath={rootPath + field.identity.name}
                  fieldMode={this.props.fieldMode}
                  fieldState={this.props.fieldState}
                  onFieldClick={this.props.onFieldClick}
                  columns={newColumns}
                  customColumns={customColumns}
                  onExpand={this.props.onExpand}
                  showReferenceAsIdentity={this.props.showReferenceAsIdentity}
                  tailButtons={this.props.nextLvlTail ? this.props.nextLvlTail : this.props.tailButtons}
                />
              )}
            </LoadableContent>
          </div>
        )

        if (expandedBody && this.state.expandedRows[index]) hasExpandedBody = true

        if (isReference) {
          if (this.state.expandedRows[index]) {
            if (this.props.showReferenceAsIdentity) {
              expandedBody = (
                <div>
                  {this.state.expandedRows[index] ? (
                    <div
                      className="StructureTable__childRowReference StructureTable__childRow"
                      style={{ marginLeft: newTableMargin + 'px' }}
                    >
                      <div
                        className={
                          'StructureTable__expandButton ' +
                          (level > 0 ? 'StructureTable__expandButton__nested ' : ' ') +
                          (this.state.expandedReferenceRows[index] ? 'StructureTable__expandButton_active' : '')
                        }
                        onClick={() => this.toggleExpandedReferenceRow(index)}
                      >
                        {this.state.expandedReferenceRows[index] ? '-' : '+'}
                      </div>
                      <div className="StructureTable__referenceIdentityUsage">Identity</div>
                      <div className="StructureTable__referenceIdentityType">Structure: /Identity</div>
                    </div>
                  ) : null}
                  {this.state.expandedRows[index] ? expandedBody : null}
                </div>
              )
            } else {
              expandedBody = (
                <div>
                  {this.state.expandedRows[index] ? (
                    <div
                      className="StructureTable__childRowReference StructureTable__childRow"
                      style={{ marginLeft: newTableMargin + 'px' }}
                    >
                      <div
                        className={
                          'StructureTable__expandButton ' +
                          (level > 0 ? 'StructureTable__expandButton__nested ' : ' ') +
                          (this.state.expandedReferenceRows[index] ? 'StructureTable__expandButton_active' : '')
                        }
                        onClick={() => this.toggleExpandedReferenceRow(index)}
                      >
                        {this.state.expandedReferenceRows[index] ? '-' : '+'}
                      </div>
                      <div className="StructureTable__referenceIdentityLabel">Identity</div>
                      <div className="StructureTable__referenceIdentityDescription">Referenced Structure</div>
                      <div className="StructureTable__referenceIdentityUsage">Identity</div>
                      <div className="StructureTable__referenceIdentityType">Structure: /Identity</div>
                    </div>
                  ) : null}
                  {this.state.expandedRows[index] ? expandedBody : null}
                </div>
              )
            }
          } else {
            expandedBody = ''
          }
        } else {
          expandedBody = <div>{expandedBody}</div>
        }
      }

      return Object.assign({}, field, {
        expandButton: expandButton,
        expandedBody: expandedBody,
        hasExpandedBody: hasExpandedBody
      })
    })

    return (
      <div>
        <div data-table-path={tablePath} className="PagedContent__outerTyped">
          <TypedTable
            data={tableData}
            ref={'typedtable' + rowIndex}
            className={className + 'StructureTable' + (level > 0 ? ' StructureTable__nested' : '')}
            hideHeader={level > 0 || (this.props.hideHeader && !filters)}
            columns={columns}
            customColumns={customColumns}
            tailButtons={tailButtons}
            contextMenuButtons={contextMenuButtons}
            bottomButtons={bottomButtons}
            filters={filters}
            dataFilters={dataFilters}
            topButtons={topButtons}
            firstRowIndex={rowIndex}
            tablePath={tablePath}
            fieldMode={this.props.fieldMode}
            fieldState={this.props.fieldState}
            onFieldClick={this.props.onFieldClick}
            headBgColor={headBgColor}
            headFontColor={headFontColor}
            isFullscreen={isFullscreen}
            widthBeforeFullscreen={widthBeforeFullscreen}
            onPageChange={this.props.onPageChange}
            pageSize={this.props.pageSize}
            defaultPage={this.props.defaultPage}
          />
        </div>
      </div>
    )
  }
}

StructureTable.propTypes = {
  columns: PropTypes.array, // same as in TypedTable
  className: PropTypes.string,
  data: PropTypes.array, // same as in TypedTable
  tailButtons: PropTypes.array, // array of {label, onClick}
  contextMenuButtons: PropTypes.array,
  bottomButtons: PropTypes.array, // display at table bottom
  filters: PropTypes.object, // array of string/component from FilterableContent
  dataFilters: PropTypes.array, // array from FilterableContent
  topButtons: PropTypes.array,
  tablePath: PropTypes.string,
  fieldMode: PropTypes.object, // Map of fields mode. When presented it show only field in defined state.
  fieldState: PropTypes.object, // Map of fields with special state
  onExpand: PropTypes.func,
  onLoad: PropTypes.func,
  showReferenceAsIdentity: PropTypes.bool,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func,
  headBgColor: PropTypes.string,
  headFontColor: PropTypes.string,
  isFullscreen: PropTypes.bool,
  widthBeforeFullscreen: PropTypes.number,
  defaultPage: PropTypes.number, // default page for PagedContent
  onFieldClick: PropTypes.func // Callback for field click. Used for structure tables.
}
