import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { buildPathDetailed, columnsToType, getFullUri, objectNameFromPathByType } from '../../helpers'
import { track } from '../../helpers/analytics'
import { getRequestFromPath } from '../../helpers/api'
import logger from '../../helpers/logger'
import { MainLayout } from '../../layouts/MainLayout/MainLayout'
import { ErrorWindow } from '../ErrorWindow/ErrorWindow'
import { HeaderPath } from '../HeaderPath/HeaderPath'
//import Table from '../Table/Table';
import { TypedTable } from '../NewTable/TypedTable'
import './style.scss'

export class ApplicationView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      applications: []
    }
  }

  componentDidMount() {
    let listData = localStorage.lastApplications
      ? JSON.parse(localStorage.lastApplications).filter((p) => typeof p === 'object')
      : []
    const now = new Date().getTime()

    let list = []

    listData
      .sort((a, b) => (a.time < b.time ? 1 : a.time > b.time ? -1 : 0))
      .forEach((app) => {
        // the app was visited today
        if (now - app.time < 86400 * 1000) {
          list.push(app)
        }
      })
    const paths = listData.map((app) => app.path)
    listData = listData
      .map((app, index) => {
        const find = paths.indexOf(app.path)
        if (find === index) {
          return Object.assign(app, { count: 1 })
        } else {
          listData[find].count++
          return null
        }
      })
      .filter((app) => app)

    //console.log("listData", listData);

    listData.sort((a, b) => (a.count < b.count ? 1 : a.count > b.count ? -1 : 0)).map((app) => list.push(app))

    const pathList = list.map((app) => app.path)
    list = list.filter((app, index) => pathList.indexOf(app.path) === index)

    list = list.slice(0, 10)

    let promises = list
      .filter((app) => !app.app)
      .map((app) => {
        return getRequestFromPath(app.path)
      })
      .filter((request) => request !== false)
      .map((request) => {
        return new Promise((resolve, reject) => {
          request
            .get()
            .then((app) => {
              resolve(app)
            })
            .catch((err) => {
              resolve(null)
            })
        })
      })

    Promise.all(promises)
      .then((rets) => {
        this.setState({
          applications: this.state.applications
            .concat(rets.filter((app) => app !== null))
            .concat(list.filter((app) => app.app).map((app) => app.app))
        })
      })
      .catch((err) => {
        logger.error(err)
      })

    track('ApplicationView', 'component', 'open')
  }

  render() {
    const appState = this.props.appState

    let defaultOrg = null
    if (appState.organizations)
      appState.organizations.forEach((org, index) => {
        if (!localStorage.getItem('currentOrganization') && index === 0) {
          defaultOrg = org
        }
        if (localStorage.getItem('currentOrganization') === org.identity.id) {
          defaultOrg = org
        }
      })

    return (
      <div className="ApplicationView__root">
        <MainLayout userState={this.props.userState} appState={this.props.appState} actions={this.props.actions}>
          <ErrorWindow actions={this.props.actions} history={this.props.history} />

          <div className="SelectView__upper">APPLICATIONS</div>

          {defaultOrg ? <HeaderPath path={buildPathDetailed(getFullUri(defaultOrg))} /> : null}

          <div className="ApplicationView">
            Last 10 applications:
            <br />
            <br />
            <TypedTable
              columns={[
                {
                  name: 'name',
                  type: columnsToType.getType('objectName'),
                  displayName: 'Application name',
                  width: 300
                },
                {
                  name: 'orgName',
                  type: columnsToType.getType('description'),
                  displayName: 'Organization',
                  width: 150
                },
                {
                  name: 'sysName',
                  type: columnsToType.getType('description'),
                  displayName: 'System',
                  width: 150
                },
                {
                  name: 'description',
                  type: columnsToType.getType('description'),
                  width: 338
                },
                {
                  name: 'usage',
                  type: columnsToType.getType('usage'),
                  width: 164
                },
                {
                  name: 'rating',
                  type: columnsToType.getType('rating'),
                  width: 84
                }
              ]}
              data={this.state.applications.map((app) => {
                return {
                  name: {
                    name: app.identity.name,
                    objectType: 'applications',
                    path: app.object.parent.name + '/applications/' + app.identity.name
                  },
                  orgName: objectNameFromPathByType(app.object.parent.name, 'organizations'),
                  sysName: objectNameFromPathByType(app.object.parent.name, 'systems'),
                  description: app.identity.description,
                  usage: app.object.usage,
                  version: app.object.version
                }
              })}
            />
          </div>
        </MainLayout>
      </div>
    )
  }
}

ApplicationView.propTypes = {
  appState: PropTypes.object,
  userState: PropTypes.object,
  actions: PropTypes.object,
  history: PropTypes.object
}
