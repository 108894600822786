import PropTypes from 'prop-types'

import React, { Component } from 'react'
import logger from '../../helpers/logger'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { deepCopy, deepMerge, nonCSCompare, editableState } from '../../helpers/index'

import './StyleDialog.scss'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'

export class StyleDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      applyAll: this.isApplyAll(this.state.style.border)
    })

    logger.info('StyleDialog', this.state, this.props)
  }

  getObjectType() {
    return 'style'
  }

  getMinHeight() {
    return 550
  }

  /**
   * Returns initial object for dialog
   */
  getInitial(props) {
    props = props || this.props
    return deepCopy(props[this.getObjectType()]) || {}
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/View/datasets/Style',
      '/organizations/Apdax/systems/DifHub/applications/View/views/StyleDialog'
    )

    logger.info('StyleDialog:open', this.state, this.props)
  }

  /**
   * Is border same for all sides
   * @param {object} border - border to analize
   * @returns {boolean} true - it is same for every side
   */
  isApplyAll = (border) => {
    if (!border || (!border.left && !border.right && !border.top && !border.bottom)) {
      return true
    }
    let left = border.left ? JSON.stringify(border.left).toLowerCase() : ''
    let right = border.right ? JSON.stringify(border.right).toLowerCase() : ''
    let top = border.top ? JSON.stringify(border.top).toLowerCase() : ''
    let bottom = border.bottom ? JSON.stringify(border.bottom).toLowerCase() : ''

    if (left === right && right === top && top === bottom) {
      return true
    }
    return false
  }

  /**
   * Validate value of changed field
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @return true - change approved
   */
  onValidateChange = (element, field, value, style, option) => {
    logger.info('StyleDialog:onValidateChange', { element, field, value, style }, this.state, this.props)
    if (element === 'borderAll' && value) {
      if (!style.border) style.border = {}
      style.border.left = deepCopy(value)
      style.border.right = deepCopy(value)
      style.border.top = deepCopy(value)
      style.border.bottom = deepCopy(value)
    }
    return style
  }

  onApplyChange = (event) => {
    let data = event.target.value
    this.setState({
      applyAll: data === 'Yes'
    })
    //this.onAdjust(0, data === "Yes" ? -200 : 200);
  }

  renderExample = (editState) => {
    let style = this.state.style
    let merge = this.props.base ? deepMerge(this.props.base, style) : style

    let background = merge.background ? merge.background : {}
    let font = merge.font ? merge.font : {}
    let border = merge.border ? merge.border : {}
    //console.log("StyleDialog:renderExample", {style, merge, background, font, border}, this.state, this.props);

    let exampleStyle = {}
    exampleStyle.margin = '25px'
    exampleStyle.padding = '20px 10px'
    exampleStyle.textAlign = 'center'
    exampleStyle.width = '100%'
    if (background) exampleStyle.backgroundColor = background.color
    if (font) {
      exampleStyle.fontFamily = font.name
      exampleStyle.fontStyle = font.style
      exampleStyle.color = font.color
      exampleStyle.fontWeight = font.weight
      exampleStyle.fontSize = font.size + 'px'
    }
    if (border) {
      if (this.state.applyAll) {
        if (border.left) {
          exampleStyle.borderColor = border.left.color
          exampleStyle.borderStyle = border.left.style
          exampleStyle.borderWidth = border.left.width + 'px'
        }
      } else {
        if (border.left) {
          exampleStyle.borderLeftColor = border.left.color
          exampleStyle.borderLeftStyle = border.left.style
          exampleStyle.borderLeftWidth = border.left.width + 'px'
        }
        if (border.right) {
          exampleStyle.borderRightColor = border.right.color
          exampleStyle.borderRightStyle = border.right.style
          exampleStyle.borderRightWidth = border.right.width + 'px'
        }
        if (border.top) {
          exampleStyle.borderTopColor = border.top.color
          exampleStyle.borderTopStyle = border.top.style
          exampleStyle.borderTopWidth = border.top.width + 'px'
        }
        if (border.bottom) {
          exampleStyle.borderBottomColor = border.bottom.color
          exampleStyle.borderBottomStyle = border.bottom.style
          exampleStyle.borderBottomWidth = border.bottom.width + 'px'
        }
      }
    }

    return (
      <div className="StyleDialog__exampleOuter">
        <div className="StyleDialog__example" style={exampleStyle}>
          Example
        </div>
      </div>
    )
  }

  renderEditor = (editState) => {
    //logger.info("StyleDialog:renderEditor", editState, this.state, this.props);

    return (
      <div className="StyleDialog">
        <div className="row">
          <div className="col-xs-4">
            {this.renderExample(editState)}
            {this.renderElement(editState, 'background')}
          </div>
          <div className="col-xs-4">{this.renderElement(editState, 'font')}</div>
        </div>

        <div className="row">
          <div className="col-xs-4">
            <div className="StyleDialog__border__label">Border:</div>
            <div className="StyleDialog__border__value">
              All sides &nbsp;&nbsp;
              <EditableEntity
                data={this.state.applyAll ? 'Yes' : 'No'}
                dataType={{ name: 'radio' }}
                dataProps={{
                  options: ['Yes', 'No'],
                  onChange: this.onApplyChange
                }}
                isEditable
                inEditMode={editState > editableState.EDITABLE}
              />
            </div>
          </div>
        </div>

        {this.state.applyAll ? (
          <div className="row">
            <div className="col-xs-4">{this.renderElement(editState, 'borderAll')}</div>
          </div>
        ) : (
          <div className="row">
            <div className="col-xs-4">
              {this.renderElement(editState, 'borderLeft')}
              {this.renderElement(editState, 'borderTop')}
            </div>
            <div className="col-xs-4">
              {this.renderElement(editState, 'borderRight')}
              {this.renderElement(editState, 'borderBottom')}
            </div>
          </div>
        )}
      </div>
    )
  }

  /**
   * render view of component when window is closed
   * @returns {*}
   */
  renderItems() {
    //console.log("StyleDialog:renderItems", this.state, this.props);

    let style = this.state.style
    let merge = this.props.base ? deepMerge(this.props.base, style) : style

    let back = merge.background ? merge.background : {}
    let font = merge.font ? merge.font : {}
    let border = merge.border ? merge.border : {}

    let isApplyAll = border ? this.isApplyAll(border) : true

    return (
      <div className="StyleDialog">
        {back && back.color ? (
          <div className="StyleDialog row">
            <div className="StyleDialog__name col">{'Background: '}</div>
            <div className="StyleDialog__value col">{back.color ? back.color : ''}</div>
          </div>
        ) : null}
        {font && (font.name || font.style || font.color || font.size || font.weight) ? (
          <div className="StyleDialog row">
            <div className="StyleDialog__name col">{'Font:'}</div>
            <div className="StyleDialog__value col">
              {(font.name ? font.name + ' ' : '') +
                (font.style ? font.style + ' ' : '') +
                (font.color ? font.color + ' ' : '') +
                (font.size ? font.size + ' ' : '') +
                (font.weight ? font.weight + ' ' : '')}
            </div>
          </div>
        ) : null}
        {border && (border.left || border.right || border.top || border.bottom) ? (
          <div className="StyleDialog row">
            <div className="StyleDialog__name col">{'Border:'}</div>
            {isApplyAll ? (
              <div className="StyleDialog__value col">
                {(border.left.style ? border.left.style + ' ' : '') +
                  (border.left.color ? border.left.color + ' ' : '') +
                  (border.left.width ? border.left.width + ' ' : '')}
              </div>
            ) : null}
          </div>
        ) : null}
        {border && border.left && !isApplyAll ? (
          <div className="StyleDialog row">
            <div className="StyleDialog__border__name col">{'Left:'}</div>
            <div className="StyleDialog__value col">
              {(border.left.style ? border.left.style + ' ' : '') +
                (border.left.color ? border.left.color + ' ' : '') +
                (border.left.width ? border.left.width + ' ' : '')}
            </div>
          </div>
        ) : null}
        {border && border.right && !isApplyAll ? (
          <div className="StyleDialog row">
            <div className="StyleDialog__border__name col">{'Right:'}</div>
            <div className="StyleDialog__value col">
              {(border.right.style ? border.right.style + ', ' : '') +
                (border.right.color ? border.right.color + ', ' : '') +
                (border.left.width ? border.left.width + ' ' : '')}
            </div>
          </div>
        ) : null}
        {border && border.top && !isApplyAll ? (
          <div className="StyleDialog row">
            <div className="StyleDialog__border__name col">{'Top:'}</div>
            <div className="StyleDialog__value col">
              {(border.top.style ? border.top.style + ', ' : '') +
                (border.top.color ? border.top.color + ', ' : '') +
                (border.left.width ? border.left.width + ' ' : '')}
            </div>
          </div>
        ) : null}
        {border && border.bottom && !isApplyAll ? (
          <div className="StyleDialog row">
            <div className="StyleDialog__border__name col">{'Bottom:'}</div>
            <div className="StyleDialog__value col">
              {(border.bottom.style ? border.bottom.style + ', ' : '') +
                (border.bottom.color ? border.bottom.color + ', ' : '') +
                (border.left.width ? border.left.width + ' ' : '')}
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

StyleDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  style: PropTypes.object, // Actual style from current layer
  base: PropTypes.object, // Data of style from base layer. Can't change
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
