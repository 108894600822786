import React from 'react'
import { Mention, MentionsInput } from 'react-mentions'

import './IssueMessageTextarea.scss'

type IssueMessageTextareaProps = {
  className: string
  onChange: (event, newVal, newValPlain, mentions) => void
  onSendMessage: () => void
  users: any[]
  value: string
}

export const IssueMessageTextarea = ({
  className,
  onChange,
  onSendMessage,
  users,
  value
}: IssueMessageTextareaProps) => {
  return (
    <div className="IssueMessageTextarea" onKeyDown={(e) => {}}>
      <MentionsInput
        className={className}
        value={value}
        onChange={onChange}
        onKeyUp={(event) => event.stopPropagation()}
        onKeyPress={(event) => event.stopPropagation()}
        onKeyDown={(e) => {
          if ((e.ctrlKey || e.metaKey) && (e.keyCode === 13 || e.keyCode === 10)) {
            onSendMessage()
          }
          e.stopPropagation()
        }}
        displayTransform={(id, display, type) => '@' + display + ' '}
      >
        <Mention
          trigger="@"
          data={users.map((user) => {
            return { id: user.id + '', display: user.display + '' }
          })}
        />
      </MentionsInput>
    </div>
  )
}
