/**
 * Created by kascode on 08.04.16.
 */
import * as types from '../constants/ActionTypes'

export function updateUserState(payload) {
  return { type: types.UPDATE_USER_STATE, payload }
}

export type UserActions = {
  updateUserState
}
