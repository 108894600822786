/**
 * Created by mailf on 22.08.2016.
 */
import React from 'react'
import { Link } from 'react-router-dom'

import {
  columnsToType,
  deepCopy,
  deploymentChildTypes,
  getFullUri,
  majorObjectSort,
  pathByType,
  pluralTypeForms,
  singularTypeForms,
  tableForwardClick,
  typeFromUri
} from '../../helpers'
import {
  API,
  getObjectListNew,
  getObjectNew,
  getObjectsWithConnected,
  getRequestFromPath,
  sendObjectNew
} from '../../helpers/api'
import { getObjectByName, getObjects, getTopologies } from '../../helpers/data'
import logger from '../../helpers/logger'
import FilteredTabbedTable from '../FilteredTabbedTable/FilteredTabbedTable'
import { MajorObject } from '../MajorObject/MajorObject'
import { MajorObjectVersioned } from '../MajorObjectVersioned/MajorObjectVersioned'
import { Messages } from '../Messages/Messages'
import ObjectCreator from '../ObjectCreator/ObjectCreator'
import { getTailButtonLabel } from '../../helpers/helperComponents'
import { RequestQuery } from '../Application/Application'
import { Tab } from '../FilteredTabbedTable/TabType'

export enum SystemTabEnum {
  APPLICATIONS = 'applications',
  DEPLOYMENTS = 'deployments',
  TOPOLOGIES = 'topologies',
  ENVIRONMENTS = 'environments',
  PUBLICATIONS = 'publications',
  SUBSCRIPTIONS = 'subscriptions'
}

export class System extends MajorObject {
  constructor(props) {
    super(props)

    // @ts-ignore
    this.state.objectType = 'system'
    // @ts-ignore
    this.state.objectName = this.getObjectName(this.state.objectType)
  }

  UNSAFE_componentWillReceiveProps(props) {
    const objectType = this.getObjectType(props)
    if (!this.props.userState.profile && props.userState.profile) {
      this.loadUserRole(props)
    }
    if (objectType === this.state.objectType) return
    this.clearTabsContentLoaded(['applications', 'topologies', 'deployments', 'environments']) // show Loader for object table
    getObjectsWithConnected(
      this.getDataRequestQuery(props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  componentDidMount() {
    // const sys = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName);
    const sys = this.getObject()

    if (sys && sys.identity.id) {
      this.props.actions.updateMajorObject(sys.identity.id, pluralTypeForms.get(this.state.objectType), {
        isFetching: true
      })
    }

    this.clearTabsContentLoaded(['applications', 'topologies', 'deployments', 'environments']) // show Loader for object table
    getObjectsWithConnected(
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  getDataRequestQuery(props): RequestQuery {
    return {
      objectRequest: API.organizations(props.match.params.organizationName).systems(props.match.params.systemName),
      objectRequestCallback: (obj, errorStatus) => {
        if (obj.object && obj.object.parent) {
          localStorage.setItem('currentOrganization', obj.object.parent.id)
        }

        this.setState({ errorStatus: errorStatus })
        return true
      },
      connectedObjectsRequests: [
        {
          objectType: 'application',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .applications(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded(['applications'])
        },
        {
          objectType: 'topology',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .topologies(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded([pluralTypeForms.get('topology')])
        },
        {
          objectType: 'deployment',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .deployments(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded([pluralTypeForms.get('deployment')])
        },
        {
          objectType: 'environment',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .environments(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded([pluralTypeForms.get('environment')])
        },
        {
          objectType: 'publication',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .publications(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded(['publications'])
        },
        {
          objectType: 'subscription',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .subscriptions(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded(['subscriptions'])
        },
        {
          objectType: 'setting',
          isObjectSettings: true,
          majorObjectType: 'system',
          propertyName: 'settings',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .settings(),
          defaultValue: []
        },
        /*
        {
          'objectType': 'issue',
          clearList: true,
          request: API.organizations(props.match.params.organizationName).systems(props.match.params.systemName).issues()

        },
        */
        {
          objectType: 'user',
          majorObjectType: 'system',
          isObjectProperty: true,
          propertyName: 'users',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .users(),
          connectedObjectsRequestCallback: this.checkUserRole(props)
        }
      ]
    }
  }

  getTabsProps = (): any[] => {
    let canDelete = this.state.currentUserRole === 'Architect'

    const tabs: Tab[] = [
      {
        title: 'applications',
        filters: [
          { name: '', width: 100 },
          { name: 'search', width: 300 },
          { name: 'tags', width: 250 },
          {
            name: '',
            width: 80
          },
          { name: 'usage', width: 150 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('objectName'),
            displayName: 'Application name',
            width: 300
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 638
          },
          { name: 'usage', type: columnsToType.getType('usage'), width: 164 },
          //        {name: 'locales', type: columnsToType.getType('locales'), displayName: 'Avl. local', width: 200},
          {
            name: 'pubsub',
            type: columnsToType.getType('pubsub'),
            displayName: 'Pub/Sub',
            width: 84
          }
        ],
        forward: tableForwardClick(this.props, this.getObject(), 'applications'),
        tailButtons: !canDelete
          ? [
              {
                label: getTailButtonLabel('Delete'),
                onClick: (obj) => this.showCannotDelete('Only Architect can delete application')
              }
            ]
          : [
              {
                label: getTailButtonLabel('Delete'),
                onClick: (obj) => this.deleteMajorObject('application', obj)
              }
            ],
        bottomButtons: [
          {
            label: '+ Add application',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'application'
              })
            }
          }
        ],
        contextMenuButtons: [
          {
            label: 'Copy application',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              //console.log("Copy application", e, data, t);
              const appPath = getFullUri(data)
              getObjectNew(getRequestFromPath(appPath), 'application', null).then((resp) => {
                localStorage.clipboard = JSON.stringify(resp)
                localStorage.clipboardType = 'application'
              })
            }
          },
          {
            label: 'Paste application',
            data: { action: 'paste' },
            onClick: () => {
              //console.log("Paste application");
              if (!localStorage.clipboard) {
                alert('No application copied')
                return
              }
              if (localStorage.clipboardType !== 'application') {
                alert('No application copied')
                return
              }
              this.setState({
                pastingChild: true,
                pastingChildName: JSON.parse(localStorage.clipboard).identity.name
              })
            },
            showInBottom: true
          }
        ],
        topButtons: [
          {
            label: '+ Add',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'application'
              })
            }
          }
        ]
      },
      {
        title: 'deployments',
        forward: tableForwardClick(this.props, this.getObject(), 'deployments'),
        filters: [
          { name: '', width: 100 },
          { name: 'search', width: 260 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('objectName'),
            displayName: 'Deployment name',
            width: 300
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 694
          },
          {
            name: 'usage',
            type: columnsToType.getType('usage'),
            width: 100 + 10
          },
          {
            name: 'version',
            type: columnsToType.getType('version'),
            width: 80
          }
        ],
        errorText: this.state.uploadingError,
        tailButtons: [
          {
            label: this.viewOrEdit,
            onClick: (obj) => {
              this.props.history?.push('/view' + getFullUri(obj))
            }
          },
          {
            label: getTailButtonLabel('Delete'),
            onClick: (obj) =>
              canDelete
                ? this.deleteMajorObject('deployment', obj)
                : this.showCannotDelete('Only Architect can delete deployment')
          }
        ],
        bottomButtons: [
          {
            label: '+ Add deployment',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'deployment'
              })
            }
          },
          {
            label: 'Upload deployment',
            onClick: () => this.selectFileForUploadChildObject('deployment')
          }
        ],
        topButtons: [
          {
            label: '+ Add',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'deployment'
              })
            }
          }
        ]
      },
      {
        title: 'topologies',
        forward: tableForwardClick(this.props, this.getObject(), 'topologies'),
        filters: [
          { name: '', width: 100 },
          { name: 'search', width: 250 },
          { name: 'tags', width: 270 },
          { name: 'usage', width: 120 },
          { name: '', width: 81 },
          { name: 'rating', width: 90 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('objectName'),
            displayName: 'Topology name',
            width: 300
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 534
          },
          {
            name: 'usage',
            type: columnsToType.getType('usage'),
            width: 100 + 10
          },
          {
            name: 'version',
            type: columnsToType.getType('version'),
            width: 89 - 5 - 10
          },
          { name: 'rating', type: columnsToType.getType('rating'), width: 90 },
          {
            name: 'pubsub',
            type: columnsToType.getType('pubsub'),
            displayName: 'Pub/Sub',
            width: 80
          }
        ],
        errorText: this.state.uploadingError,
        tailButtons: [
          {
            label: this.viewOrEdit,
            onClick: (obj) => {
              this.props.history?.push('/view' + getFullUri(obj))
            }
          },
          {
            label: getTailButtonLabel('Delete'),
            onClick: (obj) =>
              canDelete
                ? this.deleteMajorObject('topology', obj)
                : this.showCannotDelete('Only Architect can delete topology')
          }
        ],
        bottomButtons: [
          {
            label: '+ Add topology',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'topology'
              })
            }
          },
          {
            label: 'Upload topology',
            onClick: () => this.selectFileForUploadChildObject('topology')
          }
        ],
        contextMenuButtons: [
          {
            label: 'Copy topology',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              //console.log("Copy pipeline", e, data, t);
              const topologyPath = getFullUri(data)
              getObjectNew(getRequestFromPath(topologyPath), 'topology', null).then((resp) => {
                localStorage.clipboard = JSON.stringify(resp)
                localStorage.clipboardType = 'topology'
              })
            }
          },
          {
            label: 'Paste topology',
            data: { action: 'paste' },
            onClick: () => {
              //console.log("Paste pipeline");
              if (!localStorage.clipboard) {
                alert('No topology copied')
                return
              }
              if (localStorage.clipboardType !== 'topology') {
                alert('No topology copied')
                return
              }
              this.setState({
                pastingChild: true,
                pastingChildName: JSON.parse(localStorage.clipboard).identity.name
              })
            },
            showInBottom: true
          }
        ],
        topButtons: [
          {
            label: '+ Add',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'topology'
              })
            }
          }
        ]
      },
      {
        title: 'environments',
        forward: tableForwardClick(this.props, this.getObject(), 'environments'),
        filters: [
          { name: '', width: 100 },
          { name: 'search', width: 245 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('objectName'),
            displayName: 'Environment name',
            width: 300
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 694
          },
          {
            name: 'usage',
            type: columnsToType.getType('usage'),
            width: 100 + 10
          },
          {
            name: 'version',
            type: columnsToType.getType('version'),
            width: 80
          }
        ],
        tailButtons: [
          {
            label: this.viewOrEdit,
            onClick: (obj) => {
              this.props.history?.push('/view' + getFullUri(obj))
            }
          },
          {
            label: getTailButtonLabel('Delete'),
            onClick: (obj) =>
              canDelete
                ? this.deleteMajorObject('environment', obj)
                : this.showCannotDelete('Only Architect can delete environment')
          }
        ],
        bottomButtons: [
          {
            label: '+ Add environment',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'environment'
              })
            }
          },
          {
            label: 'Upload environment',
            onClick: () => this.selectFileForUploadChildObject('environment')
          }
        ],
        topButtons: [
          {
            label: '+ Add',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'environment'
              })
            }
          }],
      }
    ]
    return tabs.concat(this.getPublicationTabsProps()).concat(this.getSubscriptionTabsProps())
    //.concat(this.getPublicationTabsProps(true, 'Topology publications will be displayed here. To create dataset or interface publication, open Publication tab in an Application'))
    //.concat(this.getSubscriptionTabsProps(true, 'Topology subscription will be displayed here. To create dataset or interface subscription, open Publication tab in an Application'));
  }

  viewOrEdit = (row, index) => {
    return row.version && row.version.completion ? getTailButtonLabel('View') : getTailButtonLabel('Edit')
  }

  getData = () => {
    const sys = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const id = sys.identity.id
    let getObjs

    return this.getTabsProps().map((block) => {
      switch (block.title) {
        case SystemTabEnum.APPLICATIONS:
          getObjs = (appState, id) => getObjects(appState, SystemTabEnum.APPLICATIONS, id)
          break
        case SystemTabEnum.DEPLOYMENTS:
          getObjs = (appState, id) => getObjects(appState, SystemTabEnum.DEPLOYMENTS, id)
          break
        case SystemTabEnum.TOPOLOGIES:
          getObjs = getTopologies
          break
        case SystemTabEnum.ENVIRONMENTS:
          getObjs = (appState, id) => getObjects(appState, SystemTabEnum.ENVIRONMENTS, id)
          break
        case SystemTabEnum.PUBLICATIONS:
          getObjs = (appState, id) => getObjects(appState, SystemTabEnum.PUBLICATIONS, id)
          break
        case SystemTabEnum.SUBSCRIPTIONS:
          getObjs = (appState, id) => getObjects(appState, SystemTabEnum.SUBSCRIPTIONS, id)
          break
        default:
          getObjs = () => []
      }

      //logger.info("System:getData", block.title, { sys, id }, this.state, this.props);
      if (block.title === undefined) {
        //
      } else {
        block.data = getObjs(this.props.appState, id).map((o) => {
          if (!o.object) {
            return null
          }
          let approvedStatus =
            o.object && o.object.history && o.object.history.completions && o.object.history.completions.length > 0
              ? o.object.history.completions.find((cp) => cp.status === 'approved')
              : null
          let pubSplit =
            o.publication && o.publication.identity && o.publication.identity.name
              ? o.publication.identity.name.substring(1).split('/')
              : []
          let pubURI =
            pathByType(pubSplit, 'publications', true) +
            (o.publication && o.publication.version ? '/v' + o.publication.version.major : '')
          //logger.info("System:getData", { id, o  }, this.state, this.props);

          let subscriptions: any[] = []
          for (let index = 0; index < o.object.subscriptionCount; index++) {
            subscriptions.push(index)
          }

          return Object.assign({}, o, {
            name: {
              name: o.identity.name,
              objectType: block.title,
              path: getFullUri(o),
              picture: o.object.picture,
              usage: o.object.usage
            },
            version: {
              last: o.version,
              approved: o.object.lastApprovedVersion || null,
              completion:
                o.object && o.object.history && o.object.history.completions && o.object.history.completions.length > 0
                  ? MajorObjectVersioned.getObjectStatus(o.object.history)
                  : ''
            },
            description: o.identity.description,
            architects: o.userrole ? o.userrole.filter((el) => el.role === 'architect') : [],
            usage: o.object.usage,
            tags: o.object.tags ? o.object.tags.map((tag) => tag.name) : [],
            pubsub: !o.object
              ? {}
              : {
                  publications: o.object.publicationCount,
                  subscriptions: o.object.subscriptionCount
                },
            publication: pubURI,
            subscriptions,
            approvedDate: approvedStatus ? approvedStatus.completed : '',
            path: o.path
          })
        })
      }

      block.data.sort(majorObjectSort)

      return block
    })
  }

  renderRightMenu() {
    const system = this.getObject()
    return (
      <React.Fragment>
        {this.renderShowJson()}
        <br />
        {this.renderDownloadPDF()}
        <br />
        <div className="MajorObjectView__showChart">
          <span>
            <Link to={'/documentation/' + system.identity.name} target="_blank">
              &nbsp;DOC&nbsp;
            </Link>
          </span>
        </div>
      </React.Fragment>
    )
  }

  pasteChild = (params, closeDialog, onSent) => {
    let system = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    let sysUri = getFullUri(system)

    let newObject = JSON.parse(localStorage.clipboard)
    let srcUri = getFullUri(newObject)

    // Change name of application to name specified.
    let newName = params.objectName
    logger.info('System:pasteChild', { params, newName, localStorage }, this.state, this.props)

    if (newName && newName.length > 0) {
      // We paste object with name specified.
      newObject.identity.name = newName
    }
    let dstUri = sysUri + '/applications/' + newObject.identity.name

    // If it not application, we done.
    if (localStorage.clipboardType !== 'application') {
      // Upload new application to the current systen on service
      let blob = new Blob([JSON.stringify(newObject)], {
        type: 'application/json'
      })
      blob['lastModifiedDate'] = ''
      blob['name'] = 'filename'

      this.uploadChildObject(blob, localStorage.clipboardType)
      this.setState({ pastingChild: false })
      return
    }

    // For application we need to copy all other objects.
    let req = getRequestFromPath(sysUri + '/applications')

    sendObjectNew(req, 'post', this.props.actions, newObject).then(
      (result) => {
        params.pasteObjects = ''
        Promise.all(deploymentChildTypes.map((type) => this.copyAppObjects(params, srcUri, dstUri, type, onSent))).then(
          () => {
            logger.info('System:pasteChild:DONE===============>', { params, srcUri, dstUri }, this.state, this.props)
            if (onSent) onSent(closeDialog)
          },
          (error) => {
            logger.info('System:pasteChild:ERROR---------------->', error)
            if (onSent) onSent(closeDialog, error)
          }
        )
      },
      (error) => {
        logger.info('System:pasteChild:APP-ERROR', error)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  /**
   * Copy objects of application of specific type.
   */
  copyAppObjects = (params, src, dst, type, onSent) => {
    const stype = singularTypeForms.get(type)

    return new Promise<void>((resolve, reject) => {
      getObjectListNew(getRequestFromPath(src)[type], stype).then(
        (objs) => {
          //logger.info("System:copyAppObjects:APP", type, { src, dst, type, stype, objs}, this.state, this.props);

          Promise.all(objs.map((obj) => this.copyObject(params, src, dst, type, obj, onSent))).then(
            () => {
              logger.info('System:copyAppObjects:DONE', type)
              resolve()
            },
            (error) => {
              logger.info('System:copyAppObjects:ERROR', error)
              reject(error)
            }
          )
        },
        (error) => {
          logger.info('System:copyAppObjects:ERROR', src, dst, error)
          reject(error)
        }
      )
    })
  }

  /**
   * Copy dataset of application.
   */
  copyObject = (params, src, dst, type, obj, onSent) => {
    return new Promise((resolve, reject) => {
      getObjectNew(getRequestFromPath(getFullUri(obj)), singularTypeForms.get(type)).then(
        (result) => {
          //logger.info("System:copyObject:RECIVED", obj, result);

          try {
            let newObject: any = null
            switch (type) {
              case 'datasets':
                newObject = this.copyDataset(src, dst, result)
                break
              case 'interfaces':
                newObject = this.copyInterface(src, dst, result)
                break
              case 'pipelines':
                newObject = this.copyPipeline(src, dst, result)
                break
              case 'views':
                newObject = this.copyView(src, dst, result)
                break
              case 'publications':
                newObject = this.copyPublication(src, dst, result)
                break
              case 'subscriptions':
                newObject = this.copySubscription(src, dst, result)
                break
              default:
                logger.info('System:copyObject:UNSUPORTED', type, obj.identity.name)
                resolve(newObject)
                return
            }

            let req = getRequestFromPath(dst + '/' + type)
            //logger.info("System:copyObject", type, newObject.identity.name);

            sendObjectNew(req, 'post', this.props.actions, newObject).then(
              () => {
                params.pasteObjects += '\n PASTE: ' + type + ' => ' + newObject.identity.name
                if (onSent) onSent('progress', params)
                resolve(newObject)
              },
              (error) => {
                params.pasteObjects += '\n ERROR: ' + type + ' => ' + newObject.identity.name + ' (' + error + ')'
                if (onSent) onSent('progress', params)
                logger.warn('System:copyObject:ERROR', error)
                reject(error)
              }
            )
          } catch (e) {
            logger.warn('System:copyObject:ERROR', e)
            reject(e)
          }
        },
        (error) => {
          logger.warn('API:requestObject:REQUESTERROR', error)
          reject(error)
        }
      )
    })
  }

  /**
   * Copy dataset of application.
   */
  copyDataset = (src, dst, dataset) => {
    let newDataset = deepCopy(dataset)
    if (newDataset && newDataset.object && newDataset.object.properties) {
      this.copyProperties(src, dst, newDataset.object.properties)
    }
    newDataset.structure.fields.forEach((field) => {
      if (field.reference && field.reference.startsWith(src)) {
        // Update reference to new path
        field.reference = dst + field.reference.substring(src.length)
      }
      if (field.properties) {
        this.copyProperties(src, dst, field.properties)
      }
    })
    return newDataset
  }

  /**
   * Copy interface of application.
   */
  copyInterface = (src, dst, inter) => {
    let newInterface = deepCopy(inter)
    if (newInterface && newInterface.object && newInterface.object.properties) {
      this.copyProperties(src, dst, newInterface.object.properties)
    }
    newInterface.parameters.forEach((param) => {
      if (param.field.reference && param.field.reference.startsWith(src)) {
        // Update reference to new path
        param.field.reference = dst + param.field.reference.substring(src.length)
      }
    })
    newInterface.responses.forEach((resp) => {
      if (resp.field.reference && resp.field.reference.startsWith(src)) {
        // Update reference to new path
        resp.field.reference = dst + resp.field.reference.substring(src.length)
      }
    })
    return newInterface
  }

  /**
   * Copy pipeline of application.
   */
  copyPipeline = (src, dst, pipeline) => {
    let newPipeline = deepCopy(pipeline)
    if (newPipeline && newPipeline.object && newPipeline.object.properties) {
      this.copyProperties(src, dst, newPipeline.object.properties)
    }
    if (newPipeline && newPipeline.activities) {
      newPipeline.activities.forEach((act) => {
        if (act && act.inputs) {
          act.inputs.forEach((input) => {
            if (input.component) {
              this.copyProperty(src, dst, input.component)
            }
          })
        }
        if (act && act.outputs) {
          act.outputs.forEach((output) => {
            if (output.component) {
              this.copyProperty(src, dst, output.component)
            }
          })
        }
        if (act && act.operation) {
          this.copyProperty(src, dst, act.operation)
        }
        if (act && act.transformation) {
          this.copyProperty(src, dst, act.transformation)
        }
        if (act && act.properties) {
          this.copyProperties(src, dst, act.properties)
        }
      })
    }
    return newPipeline
  }

  /**
   * Copy interface of application.
   */
  copyView = (src, dst, view) => {
    let newView = deepCopy(view)
    if (newView && newView.object && newView.object.properties) {
      this.copyProperties(src, dst, newView.object.properties)
    }
    if (newView && newView.definitions) {
      newView.definitions.forEach((def) => {
        if (def && def.elements) {
          def.elements.forEach((elem) => {
            if (elem.control) {
              this.copyProperty(src, dst, elem.control)
            }
            if (elem.properties) {
              this.copyProperties(src, dst, elem.properties)
            }
          })
        }
      })
    }
    return newView
  }

  /**
   * Copy publication of application.
   */
  copyPublication = (src, dst, publication) => {
    let newPublication = deepCopy(publication)
    if (newPublication && newPublication.object && newPublication.object.properties) {
      this.copyProperties(src, dst, newPublication.object.properties)
    }
    return newPublication
  }

  /**
   * Copy subscription of application.
   */
  copySubscription = (src, dst, subscription) => {
    let newSubscription = deepCopy(subscription)
    if (newSubscription && newSubscription.object && newSubscription.object.properties) {
      this.copyProperties(src, dst, newSubscription.object.properties)
    }
    return newSubscription
  }

  /**
   * Copy properties of object from application.
   */
  copyProperties = (src, dst, properties) => {
    if (!properties) return
    properties.forEach((property) => {
      this.copyProperty(src, dst, property)
    })
  }

  /**
   * Copy property of object from application.
   */
  copyProperty = (src, dst, property) => {
    if (!property) return

    // Check reference to structure.
    if (property.reference && property.reference.startsWith(src)) {
      property.reference = dst + property.reference.substring(src.length)
    }

    // Check reference to subscription
    if (property.subscription && property.subscription.startsWith(src)) {
      property.subscription = dst + property.subscription.substring(src.length)
    }

    // Replace in description as it can contain reference to application
    if (property.identity && property.identity.description) {
      property.identity.description = property.identity.description.replace(src, dst)
    }
  }

  render() {
    const system = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    if (!system || system.isFetching) {
      //console.log("system: ", org);
      return this.renderLoading()
    } else {
      //console.log("system: ", org);
      return (
        <div className="MajorObject__outer">
          <section className={'MajorObjectView MajorObjectView_' + this.state.objectType}>
            {this.renderHeader()}
            {this.renderInfo()}

            <div className="MajorObjectView__connections">
              <div className="row">
                <div className="col-xs-12">
                  {this.renderBackArrowTabs()}
                  <FilteredTabbedTable
                    /*
                    // @ts-ignore */
                    tablesData={this.getData()}
                    theme={this.state.objectType}
                    tabsLoaded={this.state.tabsLoaded}
                    parentUri={getFullUri(system)}
                  />
                </div>
              </div>
            </div>

            <ObjectCreator
              isOpen={this.state.addingChild}
              parentObjectType={this.state.objectType}
              parentObject={system}
              objectChain={this.props.match.params}
              childObjectType={this.state.childObjectType ? this.state.childObjectType : 'none'}
              onSuccess={(result) => {
                //console.log("onSuccess of child create", result);
                if (this.state.childObjectType !== 'field') this.props.history?.push('/view' + getFullUri(result))
              }}
              onCancel={() => {
                this.setState({
                  addingChild: false
                })
              }}
              actions={this.props.actions}
              appState={this.props.appState}
            />
          </section>
          {system && system.identity && system.identity.id ? (
            <Messages
              ref="messages"
              appState={this.props.appState}
              userState={this.props.userState}
              actions={this.props.actions}
              object={system}
              objectType={this.state.objectType}
              objectReload={(objectUri) => {
                let type = typeFromUri(objectUri)
                console.warn('reloading', type)
                setTimeout(() => {
                  // Cleancurrent state of lists as after action we don't know state.
                  console.log('System:objectReload', type, objectUri, this.props)
                  this.clearTabsContentLoaded(['applications', 'topologies']) // show Loader for object table
                  window.apiCache.store = {}

                  // We need to load new state of object we take action on to show his right state
                  getObjectNew(getRequestFromPath(objectUri), type, this.props.actions, true)

                  // We need to reload lists to have right state of objects.
                  getObjectsWithConnected(
                    this.getDataRequestQuery(this.props),
                    this.state.objectType,
                    this.state.objectName,
                    this.props.actions,
                    true
                  )
                }, 0)
              }}
              objectChain={this.props.match.params}
              currentUserRole={this.state.currentUserRole}
            />
          ) : null}
        </div>
      )
    }
  }
}
