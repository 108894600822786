/**
 * Created by Sasha Berger on 05.07.20.
 *
 * Component to select reference to difhub object. Reference selected to versioned object.
 * Control render object and version list to select from.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { dequal } from 'dequal'
import { getApiResource, getObjectListNew, getRequestFromPath } from '../../helpers/api'
import {
  editableState,
  issueSort,
  listOfMajorObjectsToEnumOptions,
  versionSort,
  versionToStr
} from '../../helpers/index'
import logger from '../../helpers/logger'
import vDown from '../../resources/images/v-down-2x.png'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { DataSelectorTable } from './DataSelectorTable'
import './ReferenceSelector.scss'

const ITEM_HEIGHT = 45

function isInteger(value) {
  return value.match(/^[0-9]+$/)
}

export class ReferenceSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editType: this.props.type || 'Object',
      editText: this.props.data && this.props.data.label ? this.props.data.label : '',
      editItems: [],
      editHeight: 0,
      editSelected: this.props.data,

      isOpened: false,

      editVItems: [],
      editVHeight: 0,
      editVSelected: '',

      isVOpened: false
    }
  }

  componentDidMount() {
    // Set data
    this.setNewState()
    //logger.info("ReferenceSelector:componentWillReceiveProps", this.state, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(nextProps)) {
      logger.info('ReferenceSelector:componentWillReceiveProps', nextProps, this.state, this.props)
      this.setState(
        this.getState(
          nextProps.data && nextProps.data && nextProps.data.label ? nextProps.data.label : '',
          nextProps.type,
          null,
          nextProps.data,
          nextProps.dataProps ? nextProps.dataProps.options : null
        )
      )
    }
  }

  setNewState() {
    this.setState(this.getState())
  }

  /**
   * Get state from new path
   * @param {string} [query] query to filter items for selection
   * @param {string} [type] type of object to reference
   * @param {boolean} [open] open list for selection
   * @param {object} [select] select one of items from list
   * @param {array} [items] new items as an option
   * @return
   */
  getState = (query, type, open, select, items) => {
    let state = Object.assign({}, this.state)

    //state.path = (path !== null && path !== undefined) ? path : this.props.path;
    state.editType = type !== null && type !== undefined ? type : state.editType
    state.editText = query !== null && query !== undefined ? query : state.editText
    state.editSelected = select !== null && select !== undefined ? select : state.editSelected

    state.isOpened = open !== null && open !== undefined ? open : state.isOpened

    state.editItems = this.getItems(state.editText, state.editType, state.editSelected, items)
    state.editHeight = state.editItems ? (state.editItems.length > 8 ? 8 : state.editItems.length) * ITEM_HEIGHT : 0

    // Adjust space we need for data of dialog.
    if (this.props.onAdjust && (this.state.isOpened || state.isOpened)) {
      this.props.onAdjust(
        0,
        (state.isOpened ? state.editHeight : 0) - (this.state.isOpened ? this.state.editHeight : 0)
      )
    }

    // Load version if we in editable mode
    if (this.props.isEditable > editableState.EDITABLE) {
      this.getVersions(state.editSelected, state.isOpened)
    }

    //logger.info("ReferenceSelector:getState:RESULT", { query, type, open, select, items, state}, this.state, this.props);

    return state
  }

  /**
   * List of items from path
   * @return
   */
  getItems = (query, type, selected, items) => {
    let filterText = ''

    if (query && query.length > 0) {
      // We have this type as target. We will filter list by it.
      filterText = query
    }
    //logger.info("ReferenceSelector:getItems", { query, type, filterText }, this.state, this.props);
    let currItems = items
    if (!currItems && this.props.dataProps && this.props.dataProps.options) {
      currItems = this.props.dataProps.options
    }

    if (currItems) {
      // List of items we have
      let filter = this.props.filter ? this.props.filter : this.defaultFilter(type)
      let sort = this.props.sort ? this.props.sort : this.defaultSort(type)

      let sorted = sort ? currItems.sort(sort) : currItems
      let remaped = sort
        ? sorted.map((item, index) => {
            return {
              id: index,
              object: item.object,
              label: item.label,
              value: item.value
            }
          })
        : sorted
      let filtered = selected ? remaped : remaped.filter((item) => filter(item, filterText))
      //logger.info("ReferenceSelector:getItems:OBJECT", { query, type, isSelected, sorted, remaped, filtered }, this.state, this.props);

      return filtered
    }
    return []
  }

  /**
   * List of version items from path
   * @param {object} [selected] select one of items from list
   * @param {boolean} [opened] open list for selection
   * @return
   */
  getVersions = (selected, opened) => {
    let path = selected ? selected.value : this.props.data ? this.props.data.value : null
    if (!path || path.length < 1) {
      // Adjust space we need for data of dialog.
      if (this.props.onAdjust && this.state.isVOpened) {
        this.props.onAdjust(0, -this.state.editVHeight)
      }

      if (this.state.editVSelected) {
        this.setState({
          editVItems: [],
          editVHeight: 0,
          editVSelected: '',

          isVOpened: false
        })
      }
      return
    }

    //logger.info("ReferenceSelector:getVersions", { selected, opened, path }, this.state, this.props);

    // We alreaduy on same object path.
    let splitPath = path.substring(1).split('/')
    if (path === this.state.editVSelected || splitPath.length <= 1) {
      return
    }

    let pathIndex = splitPath.length - 1
    let pathData = splitPath[pathIndex]
    let pathType = splitPath[pathIndex - 1]
    let parentPath = path.slice(0, path.length - pathType.length - pathData.length - 2)

    // If we don't on items we move to we skip load
    if (!(pathIndex & 1)) {
      return []
    }

    //logger.info("ReferenceSelector:getVItems:REQEST", { selected, opened, path, splitPath, pathIndex, pathData, pathType, parentPath }, this.state, this.props);

    if (pathType === 'versions') {
      let req = getRequestFromPath(parentPath)
      getObjectListNew(req[getApiResource(pathType)], 'version').then((result) => {
        let editVItems = listOfMajorObjectsToEnumOptions(
          result,
          selected ? selected.object : this.props.data ? this.props.data.object : null,
          this.defaultSort('versions')
        )
        let editVHeight = editVItems ? (editVItems.length > 8 ? 8 : editVItems.length) * ITEM_HEIGHT : 0

        // Adjust space we need for data of dialog.
        if (this.props.onAdjust && this.state.isVOpened) {
          this.props.onAdjust(0, editVHeight - this.state.editVHeight)
        }

        this.setState({
          editVItems: editVItems,
          editVHeight: editVHeight,
          editVSelected: path,

          isVOpened: opened ? false : this.state.isVOpened
        })
      })
    } else {
      // Adjust space we need for data of dialog.
      if (this.props.onAdjust && this.state.isVOpened) {
        this.props.onAdjust(0, -this.state.editVHeight)
      }

      this.setState({
        editVItems: [],
        editVHeight: 0,
        editVSelected: '',

        isVOpened: false
      })
    }
  }

  /**
   * Start editing when focus set to the input
   * @return
   */
  onEditItem = () => {
    // Set new state and focus.
    let state = this.getState(null, null, true)

    //logger.info("ReferenceSelector:onEditItem", state, this.state, this.props);
    this.setState(state)
  }

  /**
   * Change of path from editing
   * @param {object} [event] event of changing value in input
   * @return
   */
  onChangeItem = (event) => {
    let query = event.target.value
    let state = this.getState(query, null, true, false)

    //logger.info("ReferenceSelector:onChangePath", query, state);
    this.setState(state)
  }

  /**
   * Select specific item in list of items and update item text
   * @param {array} [values] selected value in drop list
   * @return
   */
  onSelectItem = (values) => {
    //logger.info("ReferenceSelector:onSelectItem", values);

    if (values && values.length > 0) {
      let id = values[0]
      let op = (this.state.editItems || []).find((item) => item.id === id)
      let ev = { target: { value: op.value } }

      // We closing drop list lets update state.
      let state = this.getState(op.label, null, false, true)
      //logger.info("ReferenceSelector:onSelectItem:STATE", { values, id, op, ev, state }, this.state, this.props);

      this.setState(state, () => {
        if (this.props.dataProps && this.props.dataProps.onChange) {
          this.props.dataProps.onChange(ev)
        }
      })
    }
  }

  /**
   * Try to update path and list of relevant items when key presed
   * @return
   */
  keyPress = (event) => {
    let code = null

    if (event.keyCode !== undefined) {
      code = event.keyCode
    }

    //logger.info("ReferenceSelector:keyPress", code, this.state.path, this.state.pathType, this.state.editText);
    if (code === 8) {
      // BACKSPACE
      //logger.info("ObjectPathEditor:keyPress:BACKSPACE", this.state.editText);
    } else if (code === 13 || code === 191) {
      // ENTER or /
      // we try to finish search for current object
      let ret = this.state.editItems && this.state.editItems.length > 0 ? this.state.editItems[0] : null

      //logger.info("ObjectPathEditor:keyPress:ENTER", ret, this.state);

      if (ret) {
        this.onSelectItem([ret.id])
      }
      event.preventDefault()
    }
  }

  /**
   * Togle view for list of references
   * @return
   */
  onOpenToggle = () => {
    logger.info('ReferenceSelector:onOpenToggle', this.state.isOpened, this.state, this.props)

    // Adjust space we need for data of dialog.
    if (this.props.onAdjust) {
      this.props.onAdjust(0, this.state.isOpened ? -this.state.editHeight : this.state.editHeight)
    }
    this.setState({
      isOpened: !this.state.isOpened,
      isVOpened: false
    })
  }

  /**
   * Start editing version when focus set to the input
   * @return
   */
  onEditVersion = () => {
    //logger.info("ReferenceSelector:onEditVersion", this.state, this.props);
    this.setState({
      isOpened: false,
      isVOpened: true
    })
  }

  /**
   * Change of path from editing
   * @param {object} [event] event of changing value in input
   * @return
   */
  onChangeVersion = (event) => {
    //logger.info("ReferenceSelector:onChangeVersion", query, state);
  }

  /**
   * Select specific version in list of version items and update item text
   * @param {array} [values] selected value in drop list
   * @return
   */
  onSelectVersion = (values) => {
    //logger.info("ReferenceSelector:onSelectVersion", values);

    if (values && values.length > 0) {
      let id = values[0]
      let op = (this.state.editVItems || []).find((item) => item.id === id)

      // We closing drop list lets remove value.
      if (this.props.onAdjust && this.state.isVOpened) {
        this.props.onAdjust(0, -this.state.editVHeight)
      }

      //logger.info("ReferenceSelector:onSelectVersion", { values, id, op}, this.state, this.props);

      this.setState({ isVOpened: false }, () => {
        if (this.props.onVersionChange) {
          this.props.onVersionChange(op.value, '', op)
        }
      })
    }
  }

  /**
   * Try to update path and list of relevant items when key presed
   * @return
   */
  keyVPress = (event) => {
    let code = null

    if (event.keyCode !== undefined) {
      code = event.keyCode
    }

    //logger.info("ReferenceSelector:keyPress", code, this.state.path, this.state.pathType, this.state.editText);
    if (code === 8) {
      // BACKSPACE
      //logger.info("ObjectPathEditor:keyPress:BACKSPACE", this.state.editText);
    } else if (code === 13 || code === 191) {
      // ENTER or /
      // we try to finish search for current object
      let ret = this.state.editVItems && this.state.editVItems.length > 0 ? this.state.editVItems[0] : null

      //logger.info("ObjectPathEditor:keyPress:ENTER", ret, this.state);

      if (ret) {
        this.onSelectVersion([ret.id])
      }
      event.preventDefault()
    }
  }

  /**
   * Togle view for list of references
   * @return
   */
  onVersionToggle = () => {
    logger.info('ReferenceSelector:onVersionToggle', this.state.isOpened, this.state, this.props)

    // Adjust space we need for data of dialog.
    if (this.props.onAdjust) {
      this.props.onAdjust(0, this.state.isVOpened ? -this.state.editVHeight : this.state.editVHeight)
    }
    this.setState({
      isOpened: false,
      isVOpened: !this.state.isVOpened
    })
  }

  /**
   * Format item information to be represented in editor
   * @return item as a string
   */
  itemFormat(item) {
    //logger.info("ReferenceSelector:renderItemName", item);
    if (this.state.pathType === 'issues') {
      return item.object.identity.name + ': ' + item.object.identity.description
    } else if (this.state.pathType === 'versions') {
      return versionToStr(item.object.version)
    } else {
      return item.label
    }
  }

  /**
   * Filter string for item
   * @return default filter string
   */
  defaultFilter = (type) => {
    //logger.info("ReferenceSelector:defaultFilter", item, type, query);
    if (type === 'issues') {
      return (item, query) =>
        (isInteger(query) && item.label) ||
        (item.object &&
          item.object.identity &&
          item.object.identity.description &&
          item.identity.description.toLowerCase().indexOf(query.toLowerCase()) !== -1 &&
          item.object.status !== 'Closed')
    } else if (type === 'versions') {
      return (item, query) =>
        !query || query.length === 0 || (item.version && versionToStr(item.version).startsWith(query))
    } else {
      return (item, query) =>
        !query || query.length === 0 || (item.label && item.label.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    }
  }

  /**
   * Sort string for item
   * @return default sorted list
   */
  defaultSort = (type) => {
    //logger.info("ReferenceSelector:defaultSort", type, list);
    if (type === 'issues') {
      return (a, b) => issueSort(a.object, b.object)
    } else if (type === 'versions') {
      return (a, b) => versionSort(a.object.version, b.object.version)
    } else {
      return null
    }
  }

  render() {
    const isEditable = this.props.isEditable

    const items = this.state.editItems || []
    const selected = this.state.editSelected ? items.find((item) => this.state.editSelected.value === item.value) : null

    const vitems = this.state.editVItems || []
    const vselected = this.state.editVSelected ? vitems.find((item) => this.state.editVSelected === item.value) : null
    const vhighlighted = vitems.find((item) => item.status === 'draft')
    //logger.info("ReferenceSelector:render", {isEditable, items, selected, vitems, vselected}, this.state, this.props);

    return (
      <div className={'ReferenceSelector'}>
        <div className="row ReferenceSelector__subjectRow">
          <div className="col-xs-9">
            <EditableEntity
              dataType={{ name: 'string' }}
              dataProps={{
                onChange: this.onChangeItem.bind(this),
                onFocus: this.onEditItem.bind(this),
                onKeyDown: this.keyPress.bind(this),
                placeholder: this.props.dataProps ? this.props.dataProps.placeholder : '',
                maxLineCount: 1
              }}
              data={this.state.editText}
              isEditable={isEditable >= editableState.EDITABLE}
              inEditMode={isEditable > editableState.EDITABLE}
            />
            {isEditable > editableState.EDITABLE ? (
              <div
                className={
                  'ReferenceSelector__toggle' + (this.state.isOpened ? ' ReferenceSelector__toggle_rotated' : '')
                }
                onClick={this.onOpenToggle.bind(this)}
              >
                <img src={vDown} alt="" />
              </div>
            ) : null}
          </div>
          <div className="col-xs-3">
            {isEditable > editableState.EDITABLE ? (
              <EditableEntity
                dataType={{ name: 'string' }}
                dataProps={{
                  onChange: this.onChangeVersion.bind(this),
                  onFocus: this.onEditVersion.bind(this),
                  onKeyDown: this.keyVPress.bind(this),
                  maxLineCount: 1
                }}
                data={vselected ? versionToStr(vselected.object.version) : ''}
                isEditable={isEditable >= editableState.EDITABLE}
                inEditMode={isEditable > editableState.EDITABLE}
              />
            ) : null}
            {isEditable > editableState.EDITABLE ? (
              <div
                className={
                  'ReferenceSelector__vtoggle' + (this.state.isVOpened ? ' ReferenceSelector__vtoggle_rotated' : '')
                }
                onClick={this.onVersionToggle.bind(this)}
              >
                <img src={vDown} alt="" />
              </div>
            ) : null}
            {this.state.isVOpened && vitems && vitems.length > 0 ? (
              <DataSelectorTable
                ref="dsttable"
                columns={[(op) => versionToStr(op.object.version)]}
                isSingleSelect
                data={vitems}
                selected={vselected ? [vselected.id] : []}
                highlighted={vhighlighted ? [vhighlighted.id] : []}
                onSelect={this.onSelectVersion.bind(this)}
              />
            ) : null}
          </div>
        </div>
        {isEditable > editableState.EDITABLE ? (
          <div>
            {this.state.isOpened && items && items.length > 0 ? (
              <DataSelectorTable
                ref="dsttable"
                columns={this.props.columns}
                isSingleSelect
                data={items}
                selected={selected ? [selected.id] : []}
                onSelect={this.onSelectItem.bind(this)}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    )
    //<button className="btn btn_blue" onClick={this.onVersionToggle}>{this.state.isVOpened ? 'Cancel' : 'Version'}</button> : null
  }
}

ReferenceSelector.propTypes = {
  appState: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  captions: PropTypes.array, // Set of captions for columns to present
  columns: PropTypes.array, // Set of columns to present, it can be path in option or function
  dataProps: PropTypes.object, // Visualization settings
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number, // State of editor
  type: PropTypes.string, // Type of object to selector. Important when it need sort
  data: PropTypes.object, // Option of selected object
  onVersionChange: PropTypes.func, // Version changed for selected object.
  onAdjust: PropTypes.func // Adjust size of dialog to show options
}
