import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import {
  buttonsByAction,
  buttonVisibleByRole,
  checkLog,
  getLocalDate,
  getLocalTime,
  getUserPhotoUrl,
  getVersionStatus,
  objectTypeFromPath,
  renderDateOrTime,
  renderURI,
  shortAttached
} from '../../helpers'
import { getObjectNew, getRequestFromPath, idAPI, singularType } from '../../helpers/api'
import { fetchDataUrlImmediate } from '../../helpers/api'
import { ActionWindow } from '../ActionWindow/ActionWindow'
import { JSONTreeCopy } from '../JSONTreeCopy/JSONTreeCopy'
import { Person } from '../Person/Person'
import { SimpleTooltip } from '../SimpleTooltip/SimpleTooltip'
import './IssueMessage.scss'

import defaultUserPicture from '../../resources/images/face super small-2x.png'
import iAction from '../../resources/images/action-2x.png'
import iAssign from '../../resources/images/assign-2x.png'
import iStatus from '../../resources/images/hi-status-2x.png'
import iAttach from '../../resources/images/add-2x.png'
import { renderLog } from '../../helpers/helperComponents'
import { nl2br } from '../../helpers/nl2br'
import { Actions } from '../../actions'

type IssueMessageProps = {
  message: any
  onTabSelect: Function
  onSelect: Function
  buttonsDisabled: boolean
  activeTab: number
  messageIndex: number
  role: any
  disableButtons: Function
  onConfirm: Function
  active: boolean
  issue: any
  userState: any
  actions: Actions
  messageNumber: number
  currentUserRole: any
  reloadMessages: Function
}

type IssueMessageState = {
  confirmOpen: boolean
  fullMessage: boolean
  text: string
  messageObject: any
  showFullAttached: boolean
  confirmButton: any
}

export class IssueMessage extends Component<IssueMessageProps, IssueMessageState> {
  constructor(props) {
    super(props)
    this.state = {
      confirmOpen: false,
      fullMessage: false,
      text: '',
      messageObject: null,
      showFullAttached: false,
      confirmButton: undefined
    }
  }

  /**
   * On Mount initialize object we are have message about
   */
  componentDidMount() {
    //logger.info("IssueMessage:componentDidMount", this.state, this.props);

    if (this.props.message && !this.props.message.object && this.props.message.attached && this.props.message.action) {
      let ref = this.props.message.attached
      const type = singularType(objectTypeFromPath(ref))
      getObjectNew(getRequestFromPath(ref), type).then((object) => {
        //logger.info("IssueMessage:componentDidMount:OBJECT", object, type, ref);
        this.setState({ messageObject: object })
      })
    }
  }

  clickTab(val) {
    return (event) => {
      this.props.onTabSelect(val)
      event.stopPropagation()
      event.preventDefault()
    }
  }

  onSelect() {
    this.props.onSelect()
  }

  onButton(button) {
    return () => {
      idAPI
        .messages(this.props.message.identity.id)
        .get()
        .then((fullMessage) => {
          this.setState({
            confirmOpen: true,
            text: '',
            confirmButton: button,
            fullMessage: fullMessage
          })
        })
    }
  }

  confirmClose() {
    // //console.log("confirmClose");
    this.setState({ confirmOpen: false }, () => {
      //this.props.reloadMessages();
    })
  }

  confirmEdit(event) {
    this.setState({ text: event.target.value })
  }

  renderMessageText = (text) => {
    let newText = text
    //console.log("renderMessageText nextText", newText);
    const textAndJson = newText.split('```')
    let newFormattedText: any = []
    for (let i = 0; i < Math.ceil(textAndJson.length / 2); i++) {
      newFormattedText.push(nl2br(textAndJson[i * 2]))
      if (textAndJson[i * 2 + 1] && textAndJson[i * 2 + 1].trim() !== '') {
        const jsonData = textAndJson[i * 2 + 1]
        //console.log("Parse json:", jsonData);
        let json = jsonData
        try {
          json = JSON.parse(jsonData)
          //console.log("Parsed",json);
        } catch (e) {
          console.error('Cannot parse', jsonData)
        }
        newFormattedText.push(<JSONTreeCopy data={json} shouldExpand />)
      }
    }

    return <div className="IssueMessage__text">{newFormattedText}</div>
  }

  renderMessageLog = (log) => {
    if (log && log.field) return <div className="IssueMessage__text">{renderLog(log.field)}</div>
    else return ''
  }

  removeVersionFromUri = (uri) => {
    if (uri.indexOf('/versions/') !== -1) return uri.substr(0, uri.indexOf('/versions/'))
    return uri
  }

  renderAttached() {
    return this.props.message.attached ? (
      <SimpleTooltip title={this.props.message.attached}>
        <span>
          <b>URL: </b>
          <Link to={this.removeVersionFromUri('/view' + this.props.message.attached)}>
            {renderURI(
              this.state.showFullAttached ? this.props.message.attached : shortAttached(this.props.message.attached)
            )}
          </Link>
        </span>
      </SimpleTooltip>
    ) : null
  }

  renderMessageIndex(index) {
    return <div className="IssueMessage__index">{index}</div>
    // return "#" + index;
  }

  renderActive() {
    let style = {
      backgroundImage:
        'url(' +
        fetchDataUrlImmediate(getUserPhotoUrl(this.props.message.senderId), this.forceUpdate.bind(this)) +
        '), url(' +
        defaultUserPicture +
        ')'
    }

    let buttonsEnabled = !this.props.buttonsDisabled

    //console.log("IssueMessage:renderActive", this.props.message, this.props, this.state);
    let type = this.props.message.type
    let object = this.props.message.object ? this.props.message.object : this.state.messageObject

    if (type && object) {
      let status = getVersionStatus(object)

      //console.log("IssueMessage:renderActive:STATUS", this.props.message, status);

      if (status === 'approved') buttonsEnabled = false
    }

    return (
      <div className="IssueMessage row IssueMessage__active">
        <div className="col-xs-12 IssueMessage__topCol">
          <div className="row IssueMessage__urlRow">
            <div className="col-xs-9 col  IssueMessage__url">{this.renderAttached()}</div>

            <div className="col-xs-3 col IssueMessage__tabsOuter">
              <div className="IssueMessage__tabs">
                <span
                  className={'IssueMessage__tab' + (this.props.activeTab !== 0 ? ' IssueMessage__tab__active' : '')}
                  onClick={this.clickTab(1).bind(this)}
                >
                  Log
                </span>
                <span
                  className={'IssueMessage__tab' + (this.props.activeTab === 0 ? ' IssueMessage__tab__active' : '')}
                  onClick={this.clickTab(0).bind(this)}
                >
                  Message
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-3 IssueMessage__lastMessage">
              {/* {"#" + (this.props.messageNumber-this.props.messageIndex)} */}
            </div>
          </div>
          <div className="row IssueMessage__row">
            {this.renderMessageIndex(this.props.messageIndex)}
            <div className="IssueMessage__col-picture">
              <div className="Issue__assignedPicture" style={style}></div>
            </div>
            <div className="col-xs-8 IssueMessage__sender__active">
              <Person userId={this.props.message.senderId} user={{ profile: { alias: this.props.message.sender } }} />
            </div>
            <div className="col-xs-3 IssueMessage__sent IssueMessage__sent_active">
              Sent: {getLocalTime(this.props.message.time) + ' ' + getLocalDate(this.props.message.time)}
              <div className="IssueMessage__iconBlock">
                <img
                  src={iStatus}
                  className={
                    'IssueMessage__icon ' +
                    (checkLog(this.props.message.log, 'status') ? '' : 'IssueMessage__icon__hidden')
                  }
                  title="Status changed"
                  alt="icon"
                />
                <img
                  src={iAttach}
                  className={
                    'IssueMessage__icon ' +
                    (checkLog(this.props.message.log, 'attached') ? '' : 'IssueMessage__icon__hidden')
                  }
                  title="Attached changed"
                  alt="icon"
                />
                <br />
                <img
                  src={iAssign}
                  className={
                    'IssueMessage__icon ' +
                    (checkLog(this.props.message.log, 'assigned') ? '' : 'IssueMessage__icon__hidden')
                  }
                  title="Assigned changed"
                  alt="icon"
                />
                <img
                  src={iAction}
                  className={'IssueMessage__icon ' + (this.props.message.action ? '' : 'IssueMessage__icon__hidden')}
                  title="Action"
                  alt="icon"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              {this.props.activeTab === 0 ? (
                <div>
                  {this.renderMessageText(this.props.message.name)}
                  {this.props.message.type ? (
                    <div className="IssueMessage__buttonRow">
                      {buttonsByAction(this.props.message.type).map((button: any, i) => {
                        //logger.info("IssueMessage:renderActive:buttonsByAction", {button, i}, this.state, this.props);
                        return !buttonVisibleByRole(button, this.props.role) ? null : (
                          <button
                            className={'btn btn_blue ' + (buttonsEnabled ? '' : 'disabled')}
                            onClick={this.onButton(button).bind(this)}
                            key={i}
                          >
                            {button.caption.split(' ')[0]}
                          </button>
                        )
                      })}
                    </div>
                  ) : null}
                </div>
              ) : (
                this.renderMessageLog(this.props.message.log)
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderNotActive() {
    let style = {
      backgroundImage:
        'url(' +
        fetchDataUrlImmediate(getUserPhotoUrl(this.props.message.senderId), this.forceUpdate.bind(this)) +
        '), url(' +
        defaultUserPicture +
        ')'
    }

    return (
      <div className="IssueMessage row IssueMessage__notActive " onClick={this.onSelect.bind(this)}>
        {this.renderMessageIndex(this.props.messageIndex)}
        <div className="IssueMessage__col-picture">
          <div className="IssueMessage__photo" style={style}></div>
        </div>
        <div className="col-xs-2 IssueMessage__sender">
          <Person userId={this.props.message.senderId} user={{ profile: { alias: this.props.message.sender } }} />
          <br />
        </div>
        <div className="col-xs-7 IssueMessage__textOuter">
          {this.props.activeTab === 0
            ? this.renderMessageText(this.props.message.name)
            : this.renderMessageLog(this.props.message.log)}
        </div>
        <div className="col-xs-2 IssueMessage__sent">
          {renderDateOrTime(this.props.message.time)}
          <div className="IssueMessage__iconBlock">
            <img
              src={iStatus}
              className={
                'IssueMessage__icon ' + (checkLog(this.props.message.log, 'status') ? '' : 'IssueMessage__icon__hidden')
              }
              title="Status changed"
              alt="icon"
            />
            <img
              src={iAttach}
              className={
                'IssueMessage__icon ' +
                (checkLog(this.props.message.log, 'attached') ? '' : 'IssueMessage__icon__hidden')
              }
              alt="icon"
              title="Attached changed"
            />
            <br />
            <img
              src={iAssign}
              className={
                'IssueMessage__icon ' +
                (checkLog(this.props.message.log, 'assigned') ? '' : 'IssueMessage__icon__hidden')
              }
              title="Assigned changed"
              alt="icon"
            />
            <img
              src={iAction}
              className={'IssueMessage__icon ' + (this.props.message.action ? '' : 'IssueMessage__icon__hidden')}
              title="Action"
              alt="icon"
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="IssueMessage">
        {!this.props.active ? this.renderNotActive() : this.renderActive()}
        {this.state.confirmOpen ? (
          <ActionWindow
            user={{
              profile: {
                firstname: this.props.message.sender,
                lastname: '',
                id: this.props.message.senderId
              }
            }}
            close={this.confirmClose.bind(this)}
            confirm={(action, issue, message, onsent) => {
              this.props.disableButtons()
              this.props.onConfirm(action, issue, message, onsent)
            }}
            viewMode
            action={this.state.confirmButton.action}
            message={this.state.fullMessage}
            issue={this.props.issue}
            userState={this.props.userState}
            actions={this.props.actions}
            closeDefault={true}
            applyAllDefault={true}
          />
        ) : null}
      </div>
    )
  }
}
