import React from 'react'
import { Link } from 'react-router-dom'

import { track } from '../../helpers/analytics'
import {
  API,
  getApiResource,
  getObjectListNew,
  getObjectNew,
  getObjectsWithConnected,
  getRequestFromPath
} from '../../helpers/api'
import { getObjectByName, getObjectByPath, getObjects } from '../../helpers/data'
import {
  columnsToType,
  editableState,
  getFullUri,
  majorObjectSort,
  nonCSCompare,
  pathByType,
  pluralTypeForms,
  tableForwardClick,
  typeFromUri,datasetToObjectArray
} from '../../helpers/index'
import md from '../../helpers/md'
import logger from '../../helpers/logger'
import FilteredTabbedTable from '../FilteredTabbedTable/FilteredTabbedTable'
import { MajorObject } from '../MajorObject/MajorObject'
import { MajorObjectVersioned } from '../MajorObjectVersioned/MajorObjectVersioned'
import { Messages } from '../Messages/Messages'
import ObjectCreator from '../ObjectCreator/ObjectCreator'
import { SQLDesignerWindow } from '../SQLDesignerWindow/SQLDesignerWindow'
import { ImportSchemaDialog } from './ImportSchemaDialog'
import { getTailButtonLabel } from '../../helpers/helperComponents'
import { Tab } from '../FilteredTabbedTable/TabType'
import { ModalWindow } from 'components/ModalWindow/ModalWindow'

export enum ApplicationTabsEnum {
  DATASETS = 'datasets',
  INTERFACES = 'interfaces',
  PIPELINES = 'pipelines',
  VIEWS = 'views',
  PUBLICATIONS = 'publications',
  SUBSCRIPTIONS = 'subscriptions'
}

export type RequestQuery = {
  objectRequest: any
  objectRequestCallback: any
  connectedObjectsRequests: ConnectedObjectsRequests[]
}

type ConnectedObjectsRequests = {
  objectType: string
  request: any
  connectedObjectsRequestCallback?: any
  isObjectSettings?: boolean
  majorObjectType?: string
  propertyName?: string
  defaultValue?: []
  isObjectProperty?: boolean
  requestParameters?: any
  clearList?: boolean
}

export class Application extends MajorObjectVersioned {
  sqldesignerRef: any
  filteredtabbedtableRef: any

  constructor(props) {
    super(props)

    // @ts-ignore
    this.state.objectType = 'application'
    // @ts-ignore
    this.state.objectName = this.getObjectName(this.state.objectType)
    // @ts-ignore
    this.state.showSQLDesigner = false
    // @ts-ignore
    this.state.fullSizeSQLDesigner = false
    // @ts-ignore
    this.state.importingDataset = false
    // @ts-ignore
    this.state.importDatasetType = ''
    // @ts-ignore
    this.state.enumWindowOpen = false
    // @ts-ignore
    this.state.enumData = {}
    //@ts-ignore

    this.sqldesignerRef = React.createRef()
    this.filteredtabbedtableRef = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    const objectType = this.getObjectType(this.props)

    if (!this.props.userState.profile && prevProps.userState.profile) {
      this.loadUserRole(this.props)
    }

    if (objectType === this.state.objectType) {
      return
    }

    this.clearTabsContentLoaded(['datasets', 'subscriptions', 'publications', 'interfaces']) // show Loader for object table

    getObjectsWithConnected(
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  componentDidMount() {
    const sys = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    if (sys && sys.identity.id) {
      this.props.actions.updateMajorObject(sys.identity.id, pluralTypeForms.get(this.state.objectType), {
        isFetching: true
      })
    }

    this.clearTabsContentLoaded(['datasets', 'subscriptions', 'publications', 'interfaces']) // show Loader for object table
    getObjectsWithConnected(
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  /**
   * add application to locally stored last 10 apps list for Application View page
   */
  addToRecent(app, errorStatus) {
    //console.log("AddToRecent", app, errorStatus);
    this.setState({ errorStatus: errorStatus })

    if (!app || !app.identity || !app.identity.name || errorStatus === 404) {
      let uri = API.organizations(this.props.match.params.organizationName)
        .systems(this.props.match.params.systemName)
        .applications(this.props.match.params.applicationName)
        .url()
      console.log('addToRecent not found', uri, app, errorStatus)
      let list = localStorage.lastApplications ? JSON.parse(localStorage.lastApplications) : []
      list = list.filter((p) => typeof p === 'object').filter((p) => p.path !== uri)
      localStorage.lastApplications = JSON.stringify(list)

      return true
    }

    const path = getFullUri(app)
    let list = localStorage.lastApplications ? JSON.parse(localStorage.lastApplications) : []
    list = list.filter((p) => typeof p === 'object').concat({ path: path, app: app, time: new Date().getTime() })
    if (list.length > 100) {
      list = list.slice(-100)
    }

    localStorage.lastApplications = JSON.stringify(list)
    return true
  }

  getDataRequestQuery(props): RequestQuery {
    return {
      objectRequest: API.organizations(props.match.params.organizationName)
        .systems(props.match.params.systemName)
        .applications(props.match.params.applicationName),
      objectRequestCallback: (app, errorStatus) => {
        console.log('Application::objectRequestCallback')
        this.addToRecent(app, errorStatus)

        getObjectNew(
          API.organizations(props.match.params.organizationName).systems(props.match.params.systemName),
          'system'
        ).then((system) => {
          this.setState({ defaultLocale: system.locale })
          if (system.object && system.object.parent) {
            localStorage.setItem('currentOrganization', system.object.parent.id)
          }
        })

        return true
      },
      connectedObjectsRequests: [
        {
          objectType: 'dataset',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .applications(props.match.params.applicationName)
            .datasets(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded(['datasets'])
        },
        {
          objectType: 'publication',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .applications(props.match.params.applicationName)
            .publications(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded(['publications'])
        },
        {
          objectType: 'interface',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .applications(props.match.params.applicationName)
            .interfaces(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded(['interfaces'])
        },
        {
          objectType: 'pipeline',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .applications(props.match.params.applicationName)
            .pipelines(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded(['pipelines'])
        },
        {
          objectType: 'subscription',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .applications(props.match.params.applicationName)
            .subscriptions(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded(['subscriptions'])
        },
        {
          objectType: 'setting',
          isObjectSettings: true,
          majorObjectType: 'application',
          propertyName: 'settings',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .applications(props.match.params.applicationName)
            .settings(),
          defaultValue: []
        },
        {
          objectType: 'user',
          majorObjectType: 'application',
          isObjectProperty: true,
          propertyName: 'users',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .applications(props.match.params.applicationName)
            .users(),
          connectedObjectsRequestCallback: this.checkUserRole(props)
        },
        {
          objectType: 'view',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .applications(props.match.params.applicationName)
            .views(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded(['views'])
        }
      ]
    }
  }

  viewOrEdit = (row, index) => {
    return row.version && row.version.completion ? getTailButtonLabel('View') : getTailButtonLabel('Edit')
  }

  getDataTabWidth = (field, dataset, width) => {
    let length = dataset.structure && dataset.structure.fields ? dataset.structure.fields.length : 1

    if (length === 1) {
      return width
    }

    let index =
      dataset.structure && dataset.structure.fields
        ? dataset.structure.fields.findIndex((field) => nonCSCompare(field.usage, 'description'))
        : -1
    let column = index === -1 ? ((width - 100) / length) | 0 : ((width - 100) / (length + 1)) | 0
    column = Math.max(100, column)

    if (!field) {
      // Index column
      return width - (index === -1 ? length * column : (length + 1) * column)
    } else if (nonCSCompare(field.usage, 'description')) {
      // We are calculating for description
      return 2 * column
    } else {
      // We are calculating for any other field
      return column
    }
  }
  
  getDataDescriptionType = (fieldName) => {
    return {
      name: this.state['expandDataDescriptions_' + fieldName] ? 'text_expanded' : 'text'
    }
  }

  getPropsTrans = () => {
    return {
      title: 'Translations',   
      noSetWidth: true,
      filters: [
        { name: '', width: 10 },
        {
          name: 'search',
          width: 290,
          searchFields: ['Name','Enum',"view","name"]
        },
        {
          name: "locales",
          width: 100,
        },
        { name: 'select', width: 290}
      ]
    }
  }

 getLocaleById = (locale) => {
    const locales = datasetToObjectArray(
      md.repo.metadata.apdax.systems.difhub.applications.organization.datasets.locale
    )
    const currLocale =locales.find(
      (lc) => nonCSCompare(lc['Locale id'], locale) || nonCSCompare(lc['Locale Code'], locale.toString())
    )
   return currLocale['Language Code']
  }


  dataStructureView = result => {
    const data:any = []
    if (result.definitions) {
      result.definitions.map(localeElem => {
        localeElem.elements.map(el => {
          const obj: any = { ...el.identity, text: el.text, currLocale: this.getLocaleById(localeElem.locale) }
          obj.description=JSON.stringify({Description:el.identity.description,Text:el.text,Control: el.control ? el.control.value : {}})
          data.push(obj)
        })
      })
      const enData = data.filter(el => el.currLocale === "en")
      const otherData = data.filter(el => el.currLocale !== "en")
      otherData.map(otherElem => {
        enData.map(enElem => {
          if (enElem.name === otherElem.name) {
            enElem.Translations = enElem.Translations ?
              [...enElem.Translations, { name: otherElem.name, Locale: otherElem.currLocale, description: JSON.parse(otherElem.description)}] :
              [{ name: otherElem.name, Locale: otherElem.currLocale, description:JSON.parse(otherElem.description)}]
          }
        })
      })  
      data.map(el => {
        el.Translations=JSON.stringify(el.Translations)
      })
      return data.filter(el => el.currLocale ==="en")
    }
  };

  columnsStructureView = () => {
    return [
      {
        name: 'view',
        displayName: "View",
        type: columnsToType.getType('View'),
        width: 150
      },
      {
        name: 'name',
        displayName: "Name",
        type: columnsToType.getType('Name'),
        width: 150
      }, {
        name: 'description',
        displayName: "Description",
        type: { name: "string" },
        width: 310
      },
      {
         name: 'Translations',
         displayName: "Translations",
         type: { name: "string" },
         width: 310
       }
    ]
  };

  dataStructure = (result) => {
    return result.data && result.data.records
      ? result.data.records
        .sort((a, b) => (a.index > b.index ? 1 : a.index < b.index ? -1 : 0))
        .map((record, index) => {
          const res: any = {
            id: index
          }
          for (let i = 0; i < record.values.length; i++) {
            if (result.structure.fields[i] && result.structure.fields[i].usage) {
              let field = result.structure.fields[i].usage
              res[field] = record.values[i]
            }
          }
          res._index = record.index
          return res
        })
      : []
  }

  columnsStructure = () => {
    return [
      {
        name: 'Enum',
        displayName: "Enum",
        type: columnsToType.getType('Enum'),
        width: 150
      },
      {
        name: 'Name',
        displayName: "Name",
        type: columnsToType.getType('Name'),
        width: 150
      }, {
        name: 'Description',
        displayName: "Description",
        type: { name: "string" },
        width: 210
      }, {
        name: 'Translations',
        displayName: "Translations",
        type: { name: "string" },
        width: 210
      }
    ]
  };

  enumOpenView = () => {
    const localeTranslation:any = []
    const tabsProps = this.getPropsTrans()
    let columns = this.columnsStructureView()
    this.props.appState.views.map(item => { 
      getObjectListNew(getRequestFromPath(item._path), 'view').then(
        (result) => {
          const data:any = this.dataStructureView(result)
          data.map(item => {
            item.structurePath = result._path;
            item.view = result.identity.name;
            if (item.Translations) {
              const transParse = JSON.parse(item.Translations)
             transParse.map(tr => {
                if (!tr.Locale) return
                return localeTranslation.includes(tr.Locale) ? null : localeTranslation.push(tr.Locale)
              })
            }
            item.locales=localeTranslation
          })
          //@ts-ignore
        !this.state.enumData.data ?
            //@ts-ignore
            this.setState({ enumData: { ...tabsProps, data, columns,transView:true} }) :
            //@ts-ignore
            this.setState({ enumData: { ...tabsProps, data: [...this.state.enumData.data, ...data], columns,transView:true} })    
          // 
        })
     })
  }
  

  enumOpen = () => {
    const tabsProps = this.getPropsTrans()
    let columns = this.columnsStructure()
   const localeTranslation:any= []
    this.props.appState.datasets.map(item => {
      if (item.object.usage !== "Enum") return
      getObjectListNew(getRequestFromPath(item._path), 'dataset').then(
        (result) => {
          const data = this.dataStructure(result)
          data.map(item => {
            item.structurePath = result._path;
            item.Enum = result.identity.name;
            if (item.Translations) {
              const tr = Array.isArray(JSON.parse(item.Translations)) ? JSON.parse(item.Translations) : [JSON.parse(item.Translations)];
              tr.map(el => {
                if (!el.Locale) return
                return localeTranslation.includes(el.Locale) ? null : localeTranslation.push(el.Locale)
              })
            }
            item.locales = localeTranslation
            return
          })
          //@ts-ignore
          !this.state.enumData.data ?
            //@ts-ignore
            this.setState({ enumData: { ...tabsProps, data, columns,locales:localeTranslation,transEnum:true} }) :
            //@ts-ignore
            this.setState({ enumData: { ...tabsProps, data: [...this.state.enumData.data, ...data], columns,locales:localeTranslation,transEnum:true} })   
        }
      )
    })
  }

  renderNoEnums = () => {
    const condition = this.props.appState.datasets.filter(item => {
      return item.object.usage==="Enum"
    })
    return condition.length?this.renderLoading():<div className="MajorObject__emptyOuter">
        <div>
          No enums found
          <br />
        </div>
      </div>
  }

  
  renderEnumWindow = () => {
    //@ts-ignore
    const enumData = this.state.enumData
    return (
      //@ts-ignore
      <ModalWindow
        //@ts-ignore
        isOpen={this.state.enumWindowOpen}
        header={'Translations'}
        width={'1200px'}
            onClose={() => {
          this.setState({
            //@ts-ignore
            enumWindowOpen: false,
            enumData: [],
          })
        }}
      >
        {//@ts-ignore 
          !enumData.data ?  this.renderNoEnums() : (<FilteredTabbedTable tablesData={[enumData]} noInfo={true} />)
        }
      </ModalWindow>
    )
  }

  getTabsProps = () => {
    //console.log("Application:getTabsProps", this.getPublicationTabsProps());
    const tabs: Tab[] = [
      {
        title: 'datasets',
        filters: [
          { name: '', width: 105 },
          { name: 'search', width: 245 },
          { name: 'tags', width: 270 },
          { name: 'usage', width: 120 },
          { name: '', width: 81 },
          { name: 'rating', width: 90 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('objectName'),
            displayName: 'Dataset name',
            width: 300 + 10
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 520
          },
          {
            name: 'usage',
            type: columnsToType.getType('usage'),
            width: 100 + 10
          },
          {
            name: 'version',
            type: columnsToType.getType('version'),
            width: 89 - 5 - 10
          },
          { name: 'rating', type: columnsToType.getType('rating'), width: 90 },
          {
            name: 'pubsub',
            type: columnsToType.getType('pubsub'),
            displayName: 'Pub/Sub',
            width: 80
          }
        ],
        errorText: this.state.uploadingError,
        forward: tableForwardClick(this.props, this.getObject(), 'datasets'),
        tailButtons: [
          {
            label: this.viewOrEdit,
            onClick: (obj) => {
              this.props.history?.push('/view' + getFullUri(obj))
            }
          },
          {
            label: getTailButtonLabel('Delete'),
            onClick: (obj) => this.deleteMajorObject('dataset', obj)
          }
        ],
        bottomButtons: [
          {
            label: '+ Add dataset',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'dataset'
              })
            }
          },
          {
            label: 'Upload dataset',
            onClick: () => this.selectFileForUploadChildObject('dataset')
          },
          {
            label: 'Import dataset',
            onClick: () => this.setState({ importingDataset: true })
          },
          {
            label: 'Translations',
            onClick: () => {
              this.setState({
                // @ts-ignore
                enumWindowOpen: true,
              })
              this.enumOpen()
            }
          },
        ],
        contextMenuButtons: [
          {
            label: 'Copy dataset',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              //console.log("Copy dataset", e, data, t);
              const datasetPath = getFullUri(data)

              //console.log("Copy dataset",data, datasetPath);
              getObjectNew(getRequestFromPath(datasetPath), 'dataset', null, true).then((resp) => {
                localStorage.clipboard = JSON.stringify(resp)
                localStorage.clipboardType = 'dataset'
              })
            }
          },
          {
            label: 'Paste dataset',
            data: { action: 'paste' },
            onClick: () => {
              //console.log("Paste dataset");
              if (!localStorage.clipboard) {
                alert('No dataset copied')
                return
              }
              if (localStorage.clipboardType !== 'dataset') {
                alert('No dataset copied')
                return
              }
              this.setState({
                pastingChild: true,
                pastingChildName: JSON.parse(localStorage.clipboard).identity.name
              })
            },
            showInBottom: true
          },
          {
            label: 'Replace dataset',
            data: { action: 'replace' },
            onClick: (e, obj) => {
              //console.log("Paste dataset");
              if (!localStorage.clipboard) {
                alert('No dataset copied')
                return
              }
              if (localStorage.clipboardType !== 'dataset') {
                alert('No dataset copied')
                return
              }
              console.log('replacingObject', obj)
              this.setState({
                replacingChild: true,
                replacingObject: obj,
                replacingChildName: JSON.parse(localStorage.clipboard).identity.name
              })
            }
          }
        ],
        topButtons: [
          {
            label: '+ Add',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'dataset'
              })
            }
          }
        ]
      },
      {
        title: 'interfaces',
        filters: [
          { name: '', width: 20 },
          { name: 'search', width: 275 },
          { name: '', width: 100 },
          { name: 'tags', width: 266 },
          { name: '', width: 159 },
          { name: 'rating', width: 90 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('objectName'),
            displayName: 'Interface name',
            width: 225
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 375
          },
          { name: 'path', type: columnsToType.getType('path'), width: 325 },
          {
            name: 'version',
            type: columnsToType.getType('version'),
            width: 89
          },
          { name: 'rating', type: columnsToType.getType('rating'), width: 90 },
          {
            name: 'pubsub',
            type: columnsToType.getType('pubsub'),
            displayName: 'Pub/Sub',
            width: 80
          }
        ],
        forward: tableForwardClick(this.props, this.getObject(), 'interfaces'),
        tailButtons: [
          {
            label: this.viewOrEdit,
            onClick: (obj) => {
              this.props.history?.push('/view' + getFullUri(obj))
            }
          },
          {
            label: getTailButtonLabel('Delete'),
            onClick: (obj) => this.deleteMajorObject('interface', obj)
          }
        ],
        bottomButtons: [
          {
            label: '+ Add interface',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'interface'
              })
            }
          },
          {
            label: 'Upload interface',
            onClick: () => {
              this.selectFileForUploadChildObject('interface')
            }
          }
        ],
        topButtons: [
          {
            label: '+ Add',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'interface'
              })
            }
          }
        ],
        contextMenuButtons: [
          {
            label: 'Copy interface',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              const datasetPath = getFullUri(data)

              getObjectNew(getRequestFromPath(datasetPath), 'interface', null, true).then((resp) => {
                localStorage.clipboard = JSON.stringify(resp)
                localStorage.clipboardType = 'interface'
              })
            }
          },
          {
            label: 'Paste interface',
            data: { action: 'paste' },
            onClick: () => {
              if (!localStorage.clipboard) {
                alert('No interface copied')
                return
              }
              if (localStorage.clipboardType !== 'interface') {
                alert('No interface copied')
                return
              }
              this.setState({
                pastingChild: true,
                pastingChildName: JSON.parse(localStorage.clipboard).identity.name
              })
            },
            showInBottom: true
          }
        ]
      },
      {
        title: 'pipelines',
        filters: [
          { name: '', width: 105 },
          { name: 'search', width: 245 },
          { name: 'tags', width: 266 },
          { name: '', width: 180 },
          { name: 'usage', width: 100 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('objectName'),
            displayName: 'Pipeline name',
            width: 300 + 10
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 690
          },
          {
            name: 'usage',
            type: columnsToType.getType('usage'),
            width: 100 + 10
          },
          {
            name: 'version',
            type: columnsToType.getType('version'),
            width: 89 - 5 - 10
          }
        ],
        errorText: this.state.uploadingError,
        forward: tableForwardClick(this.props, this.getObject(), 'pipelines'),
        tailButtons: [
          {
            label: this.viewOrEdit,
            onClick: (obj) => {
              this.props.history?.push('/view' + getFullUri(obj))
            }
          },
          {
            label: getTailButtonLabel('Delete'),
            onClick: (obj) => this.deleteMajorObject('pipeline', obj)
          }
        ],
        bottomButtons: [
          {
            label: '+ Add pipeline',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'pipeline'
              })
            }
          },
          {
            label: 'Upload pipeline',
            onClick: () => this.selectFileForUploadChildObject('pipeline')
          }
        ],
        contextMenuButtons: [
          {
            label: 'Copy pipeline',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              //console.log("Copy pipeline", e, data, t);
              const pipelinePath = getFullUri(data)

              //console.log("Copy pipeline",data, datasetPath);
              getObjectNew(getRequestFromPath(pipelinePath), 'pipeline', null, true).then((resp) => {
                localStorage.clipboard = JSON.stringify(resp)
                localStorage.clipboardType = 'pipeline'
              })
            }
          },
          {
            label: 'Paste pipeline',
            data: { action: 'paste' },
            onClick: () => {
              //console.log("Paste pipeline");
              if (!localStorage.clipboard) {
                alert('No pipeline copied')
                return
              }
              if (localStorage.clipboardType !== 'pipeline') {
                alert('No pipeline copied')
                return
              }
              this.setState({
                pastingChild: true,
                pastingChildName: JSON.parse(localStorage.clipboard).identity.name
              })
            },
            showInBottom: true
          }
        ],
        topButtons: [
          {
            label: '+ Add',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'pipeline'
              })
            }
          }
        ]
      },
      {
        title: 'views',
        filters: [
          { name: '', width: 105 },
          { name: 'search', width: 245 },
          { name: 'tags', width: 266 },
          { name: '', width: 180 },
          { name: 'usage', width: 100 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('objectName'),
            displayName: 'View name',
            width: 300 + 10
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 690
          },
          {
            name: 'usage',
            type: columnsToType.getType('usage'),
            width: 100 + 10
          },
          {
            name: 'version',
            type: columnsToType.getType('version'),
            width: 89 - 5 - 10
          }
        ],
        errorText: this.state.uploadingError,
        forward: tableForwardClick(this.props, this.getObject(), 'views'),
        tailButtons: [
          {
            label: this.viewOrEdit,
            onClick: (obj) => {
              this.props.history?.push('/view' + getFullUri(obj))
            }
          },
          {
            label: getTailButtonLabel('Delete'),
            onClick: (obj) => this.deleteMajorObject('view', obj)
          }
        ],
        bottomButtons: [
          {
            label: '+ Add view',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'view'
              })
            }
          },
          {
            label: 'Upload view',
            onClick: () => this.selectFileForUploadChildObject('view')
          },
          {
            label: 'Translations',
            onClick: () => {
              this.setState({
                // @ts-ignore
                enumWindowOpen: true,
              })
              this.enumOpenView()
            }
          }
        ],
        contextMenuButtons: [
          {
            label: 'Copy view',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              //console.log("Copy view", e, data, t);
              const viewPath = getFullUri(data)

              //console.log("Copy view",data, datasetPath);
              getObjectNew(getRequestFromPath(viewPath), 'view', null, true).then((resp) => {
                localStorage.clipboard = JSON.stringify(resp)
                localStorage.clipboardType = 'view'
              })
            }
          },
          {
            label: 'Paste view',
            data: { action: 'paste' },
            onClick: () => {
              //console.log("Paste view");
              if (!localStorage.clipboard) {
                alert('No view copied')
                return
              }
              if (localStorage.clipboardType !== 'view') {
                alert('No view copied')
                return
              }
              this.setState({
                pastingChild: true,
                pastingChildName: JSON.parse(localStorage.clipboard).identity.name
              })
            },
            showInBottom: true
          }
        ],
        topButtons: [
          {
            label: '+ Add',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'view'
              })
            }
          }
        ]
      }
    ]
    return tabs.concat(this.getPublicationTabsProps()).concat(this.getSubscriptionTabsProps())
  }

  getData = () => {
    const app = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const id = app.identity.id

    return this.getTabsProps().map((block) => {
      block.data = getObjects(this.props.appState, block.title, id).map((o) => {
        let approvedStatus =
          o.object && o.object.history && o.object.history.completions && o.object.history.completions.length > 0
            ? o.object.history.completions.find((cp) => cp.status === 'approved')
            : null
        let pubSplit =
          o.publication && o.publication.identity && o.publication.identity.name
            ? o.publication.identity.name.substring(1).split('/')
            : []
        let pubURI =
          pathByType(pubSplit, 'publications', true) +
          (o.publication && o.publication.version ? '/v' + o.publication.version.major : '')

        let subscriptions: number[] = []
        for (let index = 0; index < o.object.subscriptionCount; index++) {
          subscriptions.push(index)
        }

        return Object.assign({}, o, {
          name: {
            name: o.identity.name,
            objectType: o.title,
            picture: o.object.picture,
            path: getFullUri(o),
            usage: o.object.usage ? o.object.usage : ''
          },
          version: {
            last: o.version,
            approved: o.object.lastApprovedVersion || null,
            completion:
              o.object && o.object.history && o.object.history.completions && o.object.history.completions.length > 0
                ? MajorObjectVersioned.getObjectStatus(o.object.history)
                : ''
          },
          description: o.identity.description,
          architects: o.userrole ? o.userrole.filter((el) => el.role === 'architect') : [],
          tags: o.object.tags ? o.object.tags.map((tag) => tag.name) : [],
          usage: o.object.usage ? o.object.usage : '',
          pubsub: !o.object
            ? {}
            : {
                publications: o.object.publicationCount,
                subscriptions: o.object.subscriptionCount
              },
          publication: pubURI,
          subscriptions,
          approvedDate: approvedStatus ? approvedStatus.completed : '',
          path: o.path
        })
      }) //.sort((a, b) => a.identity.name > b.identity.name ? 1 : (a.identity.name === b.identity.name ? 0 : -1));
      block.data.sort(majorObjectSort)
      return block
    })
  }

  /**
   * Create unique name for dataset.
   * @param ds - parent dataset.
   * @param name - name of the object to assign.
   * @param define - list of definisions.
   * @return unique name.
   */
  createDatasetUniqueName = (ds, name, define) => {
    //console.log("ImportSchemaDialog:createDatasetUniqueName", ds, name, define);
    if (!define || !define.datasets) return name

    // Check if we already have this name.
    let uniqueName = name
    if (define.datasets[uniqueName]) {
      // Generate unique name.
      let i = 0
      let genName = ds.identity.name + name

      uniqueName = genName
      while (define.datasets[uniqueName]) {
        uniqueName = genName + i++
      }
    }

    // Registry new dataset.
    define.datasets[uniqueName] = {}

    //console.log("ImportSchemaDialog:createDatasetUniqueName:UNIQUE", uniqueName, define);

    return uniqueName
  }

  /**
   * Create dataset from JSON schema.
   * Schema comply with Swagger 2.0 speification.
   * @param name - name of the object to assign.
   * @param schema - schema object with JSON schema.
   * @return
   */
  createDatasetFromSchema = async (ds, schema, define, closeDialog?, onSent?) => {
    const dsDefine = define ? define : { definitions: {}, promises: [], datasets: {} }

    if (schema) {
      //console.log("ImportSchemaDialog:createDatasetFromSchema", ds, schema);

      if (schema.type !== 'object' && !schema.enum) {
        return
      }

      // Handle difinisions if provided.
      if (schema.definitions) {
        // Definitions structures
        dsDefine.definitions = schema.definitions

        for (let idModel in schema.definitions) {
          // Current item.
          let model = schema.definitions[idModel]

          // For object we call same for subschema.
          this.createDatasetFromSchema({ identity: { name: idModel } }, model, dsDefine)
        }
      }

      // Name can be in schema or specified.
      if (!ds.identity.name || ds.identity.length < 2) {
        ds.identity.name = schema['title']
      }

      // No name we will need to created some.
      if (!ds.identity.name || ds.identity.name.length === 0) {
        ds.identity.name = 'Unknown Name'
      }

      // No description we will need to created some.
      if (!ds.identity.description || ds.identity.description.length === 0) {
        ds.identity.description = schema['description']
      }

      // Name of dataset from schema
      if (schema.enum) {
        // Dataset is an enumerator with data
        ds.object = { type: 'Dataset', usage: 'Enum', access: 'External' }
        ds.structure = {}
        ds.structure.fields = [{ identity: { name: 'Code' }, type: schema.type, usage: 'Value' }]
        ds.data = { records: [] }

        for (let iData in schema.enum) {
          // Data record.
          //console.log("ImportSchemaDialog:createDatasetFromSchema:DATA", iData, schema.enum[iData]);
          ds.data.records.push({ index: iData, values: [schema.enum[iData]] })
        }
      } else {
        // Dataset is a structure
        ds.object = { type: 'Dataset', usage: 'Structure', access: 'External' }
        ds.structure = {}
        ds.structure.fields = []
      }

      //console.log("ImportSchemaDialog:createDatasetFromSchema:FIELDS", ds, schema);

      // Fields from properties
      for (let idProperty in schema.properties) {
        // Current item.
        let property = schema.properties[idProperty]
        let type = property.type
        let dtype = property['x-data-type']

        let field: any = { identity: { name: idProperty }, usage: 'Property' }

        // Description we will need to created some.
        if (property['description']) {
          field.identity.description = property['description']
        }

        // Required field
        if (schema.required && schema.required.findIndex((item) => nonCSCompare(item, idProperty)) !== -1) {
          field.optional = false
        }

        // This is array object.
        if (nonCSCompare(type, 'array')) {
          field.count = 0

          type = property.items.type
          property = property.items
        }

        if (property['$ref']) {
          // For reference to object we reference it.
          let ref = property['$ref'].substring(14)

          if (dsDefine.definitions && dsDefine.definitions[ref] && dsDefine.definitions[ref].enum) {
            field.type = 'Enum'
            field.usage = 'Value'
          } else {
            field.type = 'Structure'
          }
          field.reference =
            '/organizations/' +
            this.props.match.params.organizationName +
            '/systems/' +
            this.props.match.params.systemName +
            '/applications/' +
            this.props.match.params.applicationName +
            '/datasets/' +
            ref
        } else if (nonCSCompare(type, 'object')) {
          // Generate unique name for dataset.
          let name = this.createDatasetUniqueName(ds, idProperty, dsDefine)

          // For object we call same for subschema.
          this.createDatasetFromSchema({ identity: { name: name } }, property, dsDefine)
          field.type = 'Structure'
          field.reference =
            '/organizations/' +
            this.props.match.params.organizationName +
            '/systems/' +
            this.props.match.params.systemName +
            '/applications/' +
            this.props.match.params.applicationName +
            '/datasets/' +
            name
        } else if (property.enum) {
          // Generate unique name for dataset.
          let name = this.createDatasetUniqueName(ds, idProperty, dsDefine)

          // For enumerator we call same for subschema of enumerator.
          this.createDatasetFromSchema({ identity: { name: name } }, property, dsDefine)
          field.type = 'Enum'
          field.usage = 'Value'
          field.reference =
            '/organizations/' +
            this.props.match.params.organizationName +
            '/systems/' +
            this.props.match.params.systemName +
            '/applications/' +
            this.props.match.params.applicationName +
            '/datasets/' +
            name
        } else if (nonCSCompare(type, 'boolean')) {
          // This is simple type.
          field.type = dtype ? dtype : 'Boolean'
        } else if (nonCSCompare(type, 'string')) {
          // This is simple type.
          field.type = dtype ? dtype : 'String'
        } else if (nonCSCompare(type, 'integer')) {
          // This is simple type.
          field.type = dtype ? dtype : 'Integer'
        } else if (nonCSCompare(type, 'number')) {
          // This is simple type.
          field.type = dtype ? dtype : 'Float'
        }

        // Size for properties with size.
        if (property['x-size']) {
          field.size = property['x-size']
        }

        // Scale and Precision.
        if (property['x-data-scale']) {
          field.scale = property['x-data-scale']
        }
        if (property['x-data-precision']) {
          field.precision = property['x-data-precision']
        }
        //console.log("ImportSchemaDialog:createDatasetFromSchema:FIELD", type, property, field);

        ds.structure.fields.push(field)
      }

      //console.log("ImportSchemaDialog:createDatasetFromSchema:POST", ds);
    }

    dsDefine.promises.push(
      API.organizations(this.props.match.params.organizationName)
        .systems(this.props.match.params.systemName)
        .applications(this.props.match.params.applicationName)
        .datasets.post(ds)
    )

    return Promise.all(dsDefine.promises).then(
      (result) => {
        if (!define) {
          const type = 'dataset'
          const parentObject = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
          const req = getRequestFromPath(getFullUri(parentObject))[getApiResource(type)]

          getObjectListNew(req, type, this.props.actions, true) // force api call
            //API.organizations(this.props.match.params.organizationName).systems(this.props.match.params.systemName).applications(this.props.match.params.applicationName).datasets.get()
            .then(() => {
              //console.log("Update success");
            })
          this.props.actions.setError(false)

          if (onSent) onSent(closeDialog)
        }
      },
      (err) => {
        console.error('ImportSchemaDialog:createDatasetFromSchema:ERROR', err)
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, err)
      }
    )
  }

  renderImportDatasetDialog() {
    const app = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    return (
      <ImportSchemaDialog
        appState={this.props.appState}
        actions={this.props.actions}
        modalTitle={'Import dataset'}
        confirmText={'Import'}
        isVisible={this.state.importingDataset}
        isEditable={editableState.EDITING}
        onSave={this.createDatasetFromSchema}
        onClose={() => this.setState({ importingDataset: false })}
        majorObject={app}
      />
    )
  }

  /**
   * get list of visible filtered datasets for chart view
   * @returns {*}
   */
  getFilteredDatasets() {
    const app = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    if (!this.filteredtabbedtableRef.current || !this.filteredtabbedtableRef.current.refs.filterablecontent_datasets) {
      return []
    }
    let names = this.filteredtabbedtableRef.current.refs.filterablecontent_datasets
      .getFilteredData(
        this.filteredtabbedtableRef.current.refs.filterablecontent_datasets.props.data,
        this.filteredtabbedtableRef.current.refs.filterablecontent_datasets.state.activeFilters
      )
      .map((ds) => ds.name.name)
    //console.log("getFilteredDatasets names",names);
    let datasets = getObjects(this.props.appState, ApplicationTabsEnum.DATASETS, app.identity.id)
    //console.log("getFilteredDatasets dtaaaet", datasets);
    let filtered = datasets.filter((ds) => names.includes(ds.identity.name))
    //console.log("getFilteredDatasets filterd", filtered);
    return filtered
  }

  /**
   * get current active filters for chart view
   * @returns {*}
   */
  getDatasetFilters() {
    if (!this.filteredtabbedtableRef.current || !this.filteredtabbedtableRef.current.refs.filterablecontent_datasets)
      return []
    let filters = this.filteredtabbedtableRef.current.refs.filterablecontent_datasets.renderFilters(
      this.filteredtabbedtableRef.current.refs.filterablecontent_datasets.props.dataFilters.map((filter) =>
        typeof filter === 'object' ? filter.name : filter
      ),
      this.filteredtabbedtableRef.current.refs.filterablecontent_datasets.props.data
    )
    let ret: any = []
    for (let i in filters) ret.push(<div className="SQLDesigner__filter">{filters[i]}</div>)
    return <div className="SQLDesigner__filters">{ret}</div>
  }

  /**
   * refresh chart view window
   */
  updateDatasetWindow = () => {
    if (this.state.showSQLDesigner) {
      this.setState({ showSQLDesigner: false }, () => {
        this.setState({ showSQLDesigner: true }, () => {
          this.sqldesignerRef.current.update()
        })
      })
    }
  }

  /**
   * handle change of view list in chart view, save new views in dataset.elements for all datasets
   * @param views
   * @param renamedViews
   * @param deletedViews
   * @constructor
   */
  SQLDesignerOnViewListChange = (views, renamedViews, deletedViews) => {
    const app = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    /*
    let newObject = Object.assign({}, {properties: (app.settings && app.settings.object ? app.settings.object.properties.filter(p => p.identity.name !== "views") : []).concat({
      identity: {name: "views"},
      value: views.join(", ")
    })});
    */
    let newObject = {
      object: Object.assign({}, app.object, {
        elements: views.map((viewName) => {
          return {
            identity: { name: viewName }
          }
        })
      })
    }
    let promise = []
    /*
    if (!app.settings || !app.settings.object || !app.settings.object.properties)
    {
      console.log("need to create app settings");
      promise = [API.organizations(this.props.match.params.organizationName).systems(this.props.match.params.systemName).applications(this.props.match.params.applicationName).settings().post({})];
    }
    */
    Promise.all(promise).then(() => {
      //API.organizations(this.props.match.params.organizationName).systems(this.props.match.params.systemName).applications(this.props.match.params.applicationName).settings().patch({
      //  object: newObject}).then(()=>{
      API.organizations(this.props.match.params.organizationName)
        .systems(this.props.match.params.systemName)
        .applications(this.props.match.params.applicationName)
        .patch(newObject)
        .then(() => {
          //console.log("will patch application " + app.identity.id, {object: newObject});

          //console.log("renamed views: ", renamedViews);

          renamedViews.forEach((rv) => {
            this.getFilteredDatasets().forEach((ds) => {
              if (ds && ds.object && ds.object.elements) {
                // check if renamed view.old name is used in this dataset
                //console.log("checking dataset", ds.identity.name);
                if (ds.object.elements.reduce((p, c) => (c.identity.name === rv.old ? true : p), false)) {
                  //console.log("view " + rv.old + " is used in dataset " + ds.identity.name + " and will be renamed to " + rv.new);
                  let newElements = ds.object.elements.map((el) =>
                    el.identity.name === rv.old ? Object.assign({}, el, { identity: { name: rv.new } }) : el
                  )
                  let req = API.organizations(this.props.match.params.organizationName)
                    .systems(this.props.match.params.systemName)
                    .applications(this.props.match.params.applicationName)
                    .datasets(ds.identity.name)
                  req
                    .patch({
                      object: { elements: newElements }
                    })
                    .then(() => {
                      this.props.actions.updateMajorObject(ds.identity.id, 'datasets', {
                        object: Object.assign({}, ds.object, {
                          elements: newElements
                        })
                      })
                    })
                }
              }
            })
          })

          deletedViews.forEach((dv) => {
            this.getFilteredDatasets().forEach((ds) => {
              if (ds && ds.object && ds.object.elements) {
                // check if renamed view.old name is used in this dataset
                //console.log("checking dataset", ds.identity.name);
                if (ds.object.elements.reduce((p, c) => (c.identity.name === dv ? true : p), false)) {
                  //console.log("view " + dv + " is used in dataset " + ds.identity.name + " and will be deleted");
                  let newElements = ds.object.elements.filter((el) => el.identity.name !== dv)
                  let req = API.organizations(this.props.match.params.organizationName)
                    .systems(this.props.match.params.systemName)
                    .applications(this.props.match.params.applicationName)
                    .datasets(ds.identity.name)
                  req
                    .patch({
                      object: { elements: newElements }
                    })
                    .then(() => {
                      this.props.actions.updateMajorObject(ds.identity.id, 'datasets', {
                        object: Object.assign({}, ds.object, {
                          elements: newElements
                        })
                      })
                    })
                }
              }
            })
          })

          this.props.actions.updateMajorObject(app.identity.id, 'applications', newObject)
          //this.props.actions.updateAdvancedSettings(app.identity.id, 'application', newObject.properties);
        })
    })
  }

  /**
   * handle change of position of datasets in chart view, save it in Dataset.elements or Subscription.elements
   * @param modifiedDatasets
   * @param viewName
   * @constructor
   */
  SQLDesignerOnTableMove = (modifiedDatasets, viewName) => {
    let subElements = {} // list of elements for each subscription
    let subNames: any = [] // list of view name:subscription name
    let subPromises = [] // list of promises of subscription get requests

    let subPaths: any = []

    // first part: update of Datasets' elements for datasets of this Application
    modifiedDatasets.forEach((ds) => {
      let { name, x, y, color, links, path, w, h, links_onetomany } = ds
      //.map(ds => (ds.name, ds.x, ds.y, ds.color, ds.links, ds.path, ds.w, ds.h, this.state.selectedView, ds.links_onetomany)

      let nameWithoutVersion = name.indexOf('/v') === -1 ? name : name.substr(0, name.indexOf('/v'))

      //console.log('Application onTableMove', name, x, y, color, links, path, w, h, viewName);
      console.log(
        'SQLDesignerOnTableMove',
        this.getFilteredDatasets().map((ds) => ds.identity.name),
        nameWithoutVersion
      )
      let datasets = this.getFilteredDatasets().filter((ds) => ds.identity.name === nameWithoutVersion)
      let dataset: any = null
      let req: any = null
      if (datasets.length === 0) {
        console.log('table from subscription. path=', path)
        const subPath = path.substr(0, path.indexOf('/datasets/'))
        ds.subPath = subPath
        //console.log("ds "+name+" subpath=", subPath);
        subPaths.push(subPath)
        // for Subscription Datasets, subPaths array is filled
      } else {
        console.log('table NOT from subscription. path=', path)
        dataset = datasets[0]
        req = API.organizations(this.props.match.params.organizationName)
          .systems(this.props.match.params.systemName)
          .applications(this.props.match.params.applicationName)
          .datasets(nameWithoutVersion)

        // normal Datasets are saved immediately

        //let newSettings = [{identity:{name: 'x'}, value: x}, {identity: {name: 'y'}, value: y}, {identity: {name: 'color'}, value: color}, {identity: {name: 'links'}, value: links}];
        //let newSettings = [{identity: {name: 'links'}, value: links}, {identity: {name: 'links_onetomany'}, value: links_onetomany}];
        //console.log("Application:onTableMove", dataset);

        let newElements = (dataset.object.elements || [])
          .filter((e) => e.identity.name !== viewName)
          .concat([
            {
              identity: { name: viewName },
              links: links
                .split(',')
                .filter((link) => link)
                .map((link) => {
                  return { type: 'One-To-One', element: { name: link } }
                })
                .concat(
                  links_onetomany
                    .split(',')
                    .filter((link) => link)
                    .map((link) => {
                      return { type: 'One-To-Many', element: { name: link } }
                    })
                ),
              frame: { position: { left: x, top: y, width: w, height: h } },
              style: { border: { left: { color: color } } }
            }
          ])
        req
          .patch({
            object: { elements: newElements } //properties: newSettings,
          })
          .then(() => {
            this.props.actions.updateMajorObject(dataset.identity.id, 'datasets', {
              object: Object.assign({}, dataset.object, {
                elements: newElements
              })
            }) //{properties: newSettings},
          })
      }
    })

    // second part: update of Datasets in Subscriptions

    subPaths = subPaths.filter((path, index) => subPaths.indexOf(path) === index) // unique

    //missing Subscription objects are requested
    subPromises = subPaths.map((path) => {
      let sub = getObjectByPath(this.props.appState, 'subscription', path)
      if (!sub) {
        let req = getRequestFromPath(path)
        return new Promise((resolve, reject) => {
          req.get().then(
            (sub) => {
              resolve(
                Object.assign({}, sub, {
                  type: 'subscription',
                  _path: path
                })
              )
            },
            () => reject()
          )
        })
      } else {
        return new Promise((resolve, reject) => resolve(sub))
      }
    })

    Promise.all(subPromises).then((subscriptions) => {
      //console.log("loaded subscriptions", subscriptions);

      // After all Subscriptions have been loaded, new elements for each Subscription dataset are generated

      modifiedDatasets.forEach((ds) => {
        if (ds.subPath) {
          let { name, x, y, color, links, path, w, h, links_onetomany, subName } = ds
          console.log('Sub ds', name, x, y, color, links, path, w, h, viewName, subName)
          let newElements = [
            {
              identity: { name: viewName + ':' + subName },
              links: links
                .split(',')
                .filter((link) => link)
                .map((link) => {
                  return { type: 'One-To-One', element: { name: link } }
                })
                .concat(
                  links_onetomany
                    .split(',')
                    .filter((link) => link)
                    .map((link) => {
                      return { type: 'One-To-Many', element: { name: link } }
                    })
                ),
              frame: { position: { left: x, top: y, width: w, height: h } },
              style: { border: { left: { color: color } } }
            }
          ]

          //console.log("newElements prepare for " + ds.subPath, newElements);

          subElements[ds.subPath] = (subElements[ds.subPath] || []).concat(newElements)
          // subElements store all new elements for Subscription, key is Subscription.path

          subNames.push(viewName + ':' + subName)
          // subNames store all element.names to remove old elements with same names
        }
      })

      //console.log("*** subPromises all", subElements, subNames);

      // new elements from many datasets are grouped by subscription to make only one request per subscription

      for (let subPath in subElements) {
        let elements = subElements[subPath]
        //console.log("subPath", subPath, elements);

        let subscription: any = subscriptions.filter((s: any) => s._path === subPath)[0]
        //console.log("sub", subscription);

        let newElements = subscription.object.elements || []
        subNames.map((sn) => (newElements = newElements.filter((ne) => ne.identity.name !== sn))) // remove previous elements for all our datasets

        newElements = newElements.concat(elements) // merge old elements with new elements

        //console.log("newElements", newElements);

        let req = getRequestFromPath(subPath)

        req
          .patch({
            object: { elements: newElements } //properties: newSettings,
          })
          .then(() => {
            this.props.actions.updateMajorObject(subscription.identity.id, 'subscriptions', {
              object: Object.assign({}, subscription.object, {
                elements: newElements
              })
            }) //{properties: newSettings},
            window.apiCache.deleteObject(subPath)
          })
      }
    })
  }

  /**
   * render Chart view button and Chart view window
   * @returns {XML[]}
   */
  // @ts-ignore
  renderRightMenu() {
    const app = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    //(app.settings && app.settings.object ? app.settings.object.properties : []).reduce((p,c)=>c.identity.name === "views" ? c.value : p, "").split(", ")

    return [
      //@ts-ignore
  this.state.enumWindowOpen?this.renderEnumWindow():null ,
      this.renderShowJson(),
      <br key={1} />,
      this.renderDownloadPDF(),
      <br key={2} />,
      <span key={3}>
        <div key={4} className="MajorObjectView__showChart">
          <span>
            <a
              className=""
              onClick={() => {
                if (this.filteredtabbedtableRef.current.refs.tabs.state.tabActive !== 1)
                  this.filteredtabbedtableRef.current.refs.tabs.setState({
                    tabActive: 1
                  })
                track('application', 'chart')

                setTimeout(() => {
                  this.setState({ showSQLDesigner: true, fullSizeSQLDesigner: true }, () => {
                    this.sqldesignerRef.current.clearAllFilters()
                  })
                  window.SQL.filters =
                    this.filteredtabbedtableRef.current.refs.filterablecontent_datasets.state.activeFilters
                }, 0)
              }}
            >
              &nbsp;Chart&nbsp;
            </a>
          </span>
        </div>
      </span>,
      <br key={5} />,
      <div key={6} className="MajorObjectView__showChart">
        <span>
          <Link
            to={'/documentation/' + this.props.match.params.systemName + '/' + this.props.match.params.applicationName}
            target="_blank"
          >
            &nbsp;DOC&nbsp;
          </Link>
        </span>
      </div>
    ]
  }

  render() {
    const app = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    if (!app || app.isFetching) {
      ////console.log("application: ", app);
      return this.renderLoading()
    } else {
      ////console.log("application: ", app);

      return (
        <div className="MajorObject__outer">
          <section className={'MajorObjectView MajorObjectView_' + this.state.objectType}>
            {this.renderHeader()}
            {this.renderInfo()}
            {this.state.importingDataset ? this.renderImportDatasetDialog() : null}

            <div className="MajorObjectView__connections">
              <div className="row">
                <div className="col-xs-12">
                  {this.renderBackArrowTabs()}
                  <FilteredTabbedTable
                    ref={this.filteredtabbedtableRef}
                    /*
                    // @ts-ignore */
                    tablesData={this.getData()}
                    theme={this.state.objectType}
                    tabsLoaded={this.state.tabsLoaded}
                    onChangeFilter={this.updateDatasetWindow}
                    parentUri={getFullUri(app)}
                  />
                </div>
              </div>
            </div>

            {this.state.addingChild ? (
              <ObjectCreator
                isOpen={this.state.addingChild}
                parentObject={app}
                childObjectLocale={this.state.defaultLocale}
                parentObjectType={this.state.objectType}
                objectChain={this.props.match.params}
                childObjectType={this.state.childObjectType ? this.state.childObjectType : 'none'}
                onSuccess={(result) => {
                  ////console.log("onSuccess of child create", result);
                  if (this.state.childObjectType !== 'field') this.props.history?.push('/view' + getFullUri(result))
                }}
                onCancel={() => {
                  this.setState({
                    addingChild: false
                  })
                }}
                actions={this.props.actions}
                appState={this.props.appState}
              />
            ) : null}
          </section>

          {app && app.identity && app.identity.id ? (
            <Messages
              parent={app}
              appState={this.props.appState}
              userState={this.props.userState}
              actions={this.props.actions}
              object={app}
              objectType={this.state.objectType}
              currentUserRole={this.state.currentUserRole}
              objectReload={(objectUri) => {
                console.warn('reloading', typeFromUri(objectUri))
                setTimeout(() => {
                  // Cleancurrent state of lists as after action we don't know state.
                  //logger.info("Application:objectReload", {app, objectUri}, typeFromUri(objectUri), this.state, this.props);
                  this.clearTabsContentLoaded([
                    'datasets',
                    'interfaces',
                    'pipeline',
                    'view',
                    'subscriptions',
                    'publications'
                  ]) // show Loader for object table

                  // We clean all caches here. todo: smarter cleaning
                  window.apiCache.store = {}

                  // We need to load new state of object we take action on to show his right state
                  getObjectNew(getRequestFromPath(objectUri), typeFromUri(objectUri), this.props.actions, true).then(
                    (result) => {
                      logger.info('Application:objectReload:RESULT', result, this.state, this.props)
                    }
                  )

                  // We need to reload lists to have right state of objects.
                  getObjectsWithConnected(
                    this.getDataRequestQuery(this.props),
                    this.state.objectType,
                    this.state.objectName,
                    this.props.actions,
                    true
                  )
                }, 0)
              }}
            />
          ) : null}

          {this.state.showSQLDesigner && (
            <SQLDesignerWindow
              ref={this.sqldesignerRef}
              application={app}
              datasets={getObjects(this.props.appState, ApplicationTabsEnum.DATASETS, app.identity.id)}
              subscriptions={getObjects(this.props.appState, ApplicationTabsEnum.SUBSCRIPTIONS, app.identity.id)}
              filters={this.getDatasetFilters()}
              actions={this.props.actions}
              onClose={() => this.setState({ showSQLDesigner: false })}
              views={app.object.elements.map((el) => el.identity.name)}
              onViewListChange={this.SQLDesignerOnViewListChange}
              onTableMove={this.SQLDesignerOnTableMove}
            />
          )}
        </div>
      )
    }
  }
}
