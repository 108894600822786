/**
 * Created by sashab on 05.12.2020.
 *
 * Update soecific key.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { deepCopy, editableState, listOfMinorObjectsToEnumOptions, nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './KeyDialog.scss'
import './KeysDialog.scss'

export class KeyDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    logger.info('KeyDialog', this.state, this.props)
  }

  getObjectType() {
    return 'dkey'
  }

  /**
   * Returns minimum height for dialog
   */
  getMinHeight() {
    return 300
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/Dataset/datasets/Key',
      '/organizations/Apdax/systems/DifHub/applications/Dataset/views/KeyDialog'
    )

    logger.info('KeyDialog:open', this.state, this.props)

    // Lets load common options for edit.
    if (this.props.isEditable > editableState.BROWSABLE) {
      this.loadStructure(this.state.key)
      //this.onAdjust(0, 565);
    }
  }

  /**
   * Load object stracture
   */
  loadStructure = (key) => {
    // Load current object
    logger.info('KeyDialog:loadStructure', key, this.state, this.props)

    if (this.props.majorObject && this.props.majorObject.structure && this.props.majorObject.structure.fields) {
      let options = listOfMinorObjectsToEnumOptions(this.props.majorObject.structure.fields)
      this.setState({
        fieldOptions: options
      })
    }
  }

  /**
   * Get initial value of parameter
   * @param {object} [props]
   * @returns {*}
   */
  getInitial(props) {
    props = props || this.props
    //logger.info("KeyDialog:getInitialInput", props);
    if (!props.dkey) {
      return { identity: {}, order: 1, primary: true, fields: [] } //createEmptyObject('input');
    }
    return deepCopy(props.dkey || {})
  }

  checkInputErrors = (key) => {
    let inputError = ''
    logger.info('KeyDialog:checkInputErrors', key, this.state, this.props)

    if (!key.identity || !key.identity.name) {
      inputError += 'Please specify key name!'
    } else if (
      key &&
      key.primary &&
      this.props.majorObject &&
      this.props.majorObject.structure &&
      this.props.majorObject.structure.keys
    ) {
      // Check we have one primary already
      let primary = this.props.majorObject.structure.keys.find((k) => k.primary)
      if (primary && primary.identity && !nonCSCompare(key.identity.name, primary.identity.name)) {
        inputError += 'Primary key already exist in dataset!'
      }
    } else if (!key || !key.fields || key.fields.length === 0) {
      inputError += 'Please select fields for the key!'
    }

    // Check if we have
    return inputError
  }

  /**
   * Validate value of changed field
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @return true - change approved
   */
  onValidateChange = (element, field, value, key, option) => {
    logger.info('KeyDialog:onValidateChange', { element, field, value, key, option }, this.state, this.props)

    if (element === 'primary') {
      if (value) {
        key['constraint'] = { reference: '', subscription: '', references: [] }
      } else if (key['constraint']) {
        delete key['constraint']
      }
    }
    return key
  }

  renderEditor = (editState) => {
    let key = this.state.dkey
    let isPrimary = key.primary
    //logger.info("KeyDialog:renderEditor", { editState, key, isPrimary }, this.state, this.props);

    return (
      <div className="KeyDialog" onClick={() => this.onCheckDialogHeight('.KeyDialog')}>
        {this.renderView(editState, (element) => {
          switch (element) {
            default:
              return null
            case 'cardinality':
              return isPrimary ? <div></div> : null
            case 'constraint':
              return isPrimary ? <div></div> : null
          }
        })}
      </div>
    )
  }
}

KeyDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  dkey: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
