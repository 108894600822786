import React, { Component } from 'react'
import JSONTree from 'react-json-tree'

import { monokaiJsonTheme } from '../../helpers'

import './JSONTreeCopy.scss'

type JSONTreeCopyProps = {
  data: any
  shouldExpand: boolean
  copyButtonLabel?: string
}

type JSONTreeCopyState = {}

export class JSONTreeCopy extends Component<JSONTreeCopyProps, JSONTreeCopyState> {
  copyJson = () => {
    navigator.clipboard.writeText(JSON.stringify(this.props.data)).then()
  }

  render() {
    const { data, shouldExpand, copyButtonLabel } = this.props

    return (
      <div className="JSONTreeCopy__jsonContainer">
        <button className=" btn btn_blue JSONTreeCopy__copyJsonButton" onClick={this.copyJson}>
          {copyButtonLabel || 'Copy'}
        </button>
        <textarea className="JSONTreeCopy__copyJsonTextarea" value={JSON.stringify(data)}></textarea>
        <JSONTree data={data} shouldExpandNode={() => shouldExpand} theme={monokaiJsonTheme} />
      </div>
    )
  }
}
