import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { buildPathDetailed } from '../../helpers'
import { track } from '../../helpers/analytics'
import { API, getObjectListNew, getObjectNew } from '../../helpers/api'
import { getFullUri } from '../../helpers/index'
import { CollapsableList } from '../CollapsableList/CollapsableList'
import { ErrorWindow } from '../ErrorWindow/ErrorWindow'
import { HeaderPath } from '../HeaderPath/HeaderPath'
import { KascodeSelect } from '../KascodeSelect/KascodeSelect'
import { Loader } from '../Loader/Loader'
import { MajorObjectBlock } from '../MajorObjectBlock/MajorObjectBlock'
import { Matrix } from '../Matrix/Matrix'
import { MainLayout } from '../../layouts/MainLayout/MainLayout'
import './style.scss'

import iMatrixOn from '../../resources/images/matrix-button-activ-2x.png'
import iMatrixOff from '../../resources/images/matrix-button-2x.png'
import iTreeOn from '../../resources/images/tree-button-activ-2x.png'
import iTreeOff from '../../resources/images/tree-button-2x.png'
import { downloadSelectorAsPDF } from '../../helpers/pdfDownloader'

let loadingObjectCount = 0
let searchTimeout = null

export class PublicationView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      view: 'tree',
      currentOrganizationId: localStorage.getItem('currentOrganization'),
      loadFinished: false,
      sortOrder: ['asc', 'asc', 'asc'],
      fullCollapsed: [false, false, false],
      fullExpanded: [false, false, false],
      usageFilter: [false, false, false],
      tagFilter: [false, false, false],
      search: ['', '', '']
    }
  }

  componentDidMount() {
    this.getAllObjects()
    track('ApplicationView', 'component', 'open')
  }

  getApplicationData(id) {
    return this.props.appState.applications.filter((el) => el.identity.id === id)[0]
  }

  getPublicationData(id) {
    return this.props.appState.publications.filter((el) => el.identity.id === id)[0]
  }

  viewMatrix() {
    this.setState({ view: 'matrix' })
  }

  viewTree() {
    this.setState({ view: 'tree' })
  }

  sliceCurrentOrg(orglist) {
    let defaultIndex = 0
    if (localStorage.getItem('currentOrganization')) {
      orglist.forEach((org, index) => {
        if (org.identity.id === localStorage.getItem('currentOrganization')) defaultIndex = index
      })
    }
    //console.log("sliceCurrentOrg defaultIndex",defaultIndex);
    //console.log("will load organization ",orglist[defaultIndex]);
    return [orglist[defaultIndex]]
  }

  loadingObjectAdd(count) {
    loadingObjectCount += count
    ////console.log("added couunt="+loadingObjectCount);
    document.getElementById('SystemsView__loadingObjectCount').innerHTML = 'Loading ' + loadingObjectCount + ' objects'
  }
  loadingObjectRemove(count) {
    loadingObjectCount -= count
    ////console.log("deleted couunt="+loadingObjectCount);
    document.getElementById('SystemsView__loadingObjectCount').innerHTML = 'Loading ' + loadingObjectCount + ' objects'
    if (loadingObjectCount === 0) {
      document.getElementById('SystemsView__loadingObjectCount').innerHTML = ''
      this.setState({
        loadFinished: true
      })
    }
  }

  getAllObjects() {
    //logger.info("PublicationView:getAllObjects", this.state, this.props);

    this.setState({
      loadFinished: false
    })
    getObjectListNew(API.organizations, 'organization', this.props.actions).then((orglist) => {
      this.sliceCurrentOrg(orglist).forEach((org) => {
        this.loadingObjectAdd(1)
        //console.log("after slice org",org);
        getObjectListNew(API.organizations(org.identity.name).systems, 'system', this.props.actions).then(
          (syslist) => {
            let syslistFiltered = syslist.filter((sys) => {
              //console.log('orgid = ' + org.identity.id + ', sys', sys);
              if (!sys.identity.name) return false
              if (!sys.object || !sys.object.parent || sys.object.parent.id !== org.identity.id) return false
              return true
            })
            this.loadingObjectAdd(syslistFiltered.length)
            this.props.actions.updateMajorObject(org.identity.id, 'organizations', {
              systems: syslist.map((sys) => sys.identity.id)
            })
            syslistFiltered.forEach((sys) => {
              getObjectListNew(
                API.organizations(org.identity.name).systems(sys.identity.name).applications,
                'application',
                this.props.actions
              ).then(
                (applist) => {
                  this.loadingObjectAdd(applist.length)
                  //console.log('applist', applist);
                  this.props.actions.updateMajorObject(sys.identity.id, 'systems', {
                    applications: applist.map((app) => app.identity.id)
                  })
                  applist.forEach((app) => {
                    //console.log('make request for app',app);
                    getObjectListNew(
                      API.organizations(org.identity.name).systems(sys.identity.name).applications(app.identity.name)
                        .publications,
                      'publication',
                      this.props.actions
                    ).then(
                      (publist) => {
                        this.loadingObjectAdd(publist.length)
                        this.props.actions.updateMajorObject(app.identity.id, 'applications', {
                          publications: publist.map((pub) => pub.identity.id)
                        })
                        publist.forEach((pub) => {
                          getObjectNew(
                            API.organizations(org.identity.name)
                              .systems(sys.identity.name)
                              .applications(app.identity.name)
                              .publications(pub.identity.name),
                            'publication',
                            this.props.actions
                          )
                          getObjectListNew(
                            API.organizations(org.identity.name)
                              .systems(sys.identity.name)
                              .applications(app.identity.name)
                              .publications(pub.identity.name)
                              .subscriptions(),
                            'subscription',
                            this.props.actions
                          ).then(
                            (sublist) => {
                              this.props.actions.updateMajorObject(pub.identity.id, 'publications', {
                                subscriptions: sublist.map((sub) => sub.identity.id)
                              })
                              //this.setState({
                              //  loadFinished: true
                              //});
                              this.loadingObjectRemove(1)
                            },
                            () => this.loadingObjectRemove(1)
                          )
                        })
                        this.loadingObjectRemove(1)
                      },
                      () => this.loadingObjectRemove(1)
                    )
                  })
                  this.loadingObjectRemove(1)
                },
                () => this.loadingObjectRemove(1)
              )
            })
            this.loadingObjectRemove(1)
          },
          () => this.loadingObjectRemove(1)
        )
      })
    })
  }

  setSort = (i) => {
    return (event) => {
      let sortCopy = this.state.sortOrder.slice()
      sortCopy[i] = event.target.value
      this.setState({ sortOrder: sortCopy })
    }
  }

  showMore = (i) => {
    return (event) => {
      let coll = this.state.fullCollapsed.slice()
      coll[i] = false
      this.setState({ fullCollapsed: coll })
    }
  }

  showLess = (i) => {
    return (event) => {
      let coll = this.state.fullCollapsed.slice()
      coll[i] = true
      this.setState({ fullCollapsed: coll })
    }
  }

  expand = (i) => {
    return (event) => {
      let coll = this.state.fullExpanded.slice()
      coll[i] = true
      this.setState({ fullExpanded: coll })
    }
  }

  collapse = (i) => {
    return (event) => {
      let coll = this.state.fullExpanded.slice()
      coll[i] = false
      this.setState({ fullExpanded: coll })
    }
  }

  setUsageFilter = (i) => {
    return (event) => {
      //console.log("setUsageFilter",i,event.target.value.map(opt => opt.value));
      this.setState({
        usageFilter: this.state.usageFilter.map((oldVal, index) =>
          index === i ? event.target.value.map((opt) => opt.value) : oldVal
        )
      })
    }
  }
  setTagFilter = (i) => {
    return (event) => {
      this.setState({
        tagFilter: this.state.tagFilter.map((oldVal, index) =>
          index === i ? event.target.value.map((opt) => opt.value) : oldVal
        )
      })
    }
  }

  setSearch = (i) => {
    return (event) => {
      //console.log("SetSearch", i, searchTimeout);
      if (searchTimeout) clearTimeout(searchTimeout)

      let t = this
      let newVal = event.target.value

      searchTimeout = setTimeout(() => {
        //console.log("SetSearch actual", i, newVal);
        t.setState({
          search: t.state.search.map((val, index) => (index === i ? newVal : val))
        })

        searchTimeout = null
      }, 200)

      return true
    }
  }

  getUsageOptions(i) {
    let usages = []
    if (i === 0) {
      if (this.props.appState.systems) {
        this.props.appState.systems.map((sys) => (!sys.object ? null : usages.push(sys.object.usage)))
      }
    }
    if (i === 1) {
      if (this.props.appState.applications) {
        this.props.appState.applications.map((sys) => (!sys.object ? null : usages.push(sys.object.usage)))
      }
    }
    if (i === 2) {
      if (this.props.appState.publications) {
        this.props.appState.publications.map((sys) => (!sys.object ? null : usages.push(sys.object.usage)))
      }
    }
    usages = usages.filter((u) => u)
    usages = usages.filter((v, i) => usages.indexOf(v) === i) //unique
    return usages.map((usage, index) => {
      return { id: index, label: usage, value: usage }
    })
  }

  getTagOptions(i) {
    let tags = []
    if (i === 0) {
      if (this.props.appState.systems) {
        this.props.appState.systems.map((sys) => (!sys.object ? null : (tags = tags.concat(sys.object.tags))))
      }
    }
    if (i === 1) {
      if (this.props.appState.applications) {
        this.props.appState.applications.map((sys) => (!sys.object ? null : (tags = tags.concat(sys.object.tags))))
      }
    }
    if (i === 2) {
      if (this.props.appState.publications) {
        this.props.appState.publications.map((sys) => (!sys.object ? null : (tags = tags.concat(sys.object.tags))))
      }
    }
    tags = tags
      .filter((u) => u)
      .map((tag) => tag.name)
      .filter((u) => u)
    tags = tags.filter((v, i) => tags.indexOf(v) === i) //unique
    return tags.map((tag, index) => {
      return { id: index, label: tag, value: tag }
    })
  }

  tagIncluded(tags, searchTag) {
    if (!searchTag || searchTag.length === 0) return true
    if (!tags) return false
    return tags.reduce((prev, tag) => (tag && searchTag.indexOf(tag.name) !== -1 ? true : prev), false)
  }

  usageIncluded(usageFilter, usage) {
    if (!usageFilter || usageFilter.length === 0) return true
    return usageFilter.indexOf(usage) !== -1
  }

  searchIncluded(searchFilter, identity) {
    if (!searchFilter || searchFilter.length === 0) return true
    if (!identity) return false
    return (
      (identity.name && identity.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) ||
      (identity.description && identity.description.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1)
    )
  }

  downloadPDF = (org) => () => {
    downloadSelectorAsPDF(document.querySelector('.PublicationView__body'), org.identity.name + ' Publications View')
  }

  render() {
    const appState = this.props.appState
    let defaultOrg = null
    if (appState.organizations)
      appState.organizations.forEach((org, index) => {
        if (!localStorage.getItem('currentOrganization') && index === 0) {
          defaultOrg = org
        }
        if (localStorage.getItem('currentOrganization') === org.identity.id) {
          defaultOrg = org
        }
      })
    //logger.info("PublicationView:render", this.state, this.props);

    return (
      <div className="PublicationView__root">
        <MainLayout userState={this.props.userState} appState={this.props.appState} actions={this.props.actions}>
          <ErrorWindow actions={this.props.actions} history={this.props.history} />

          <div className="SelectView__upper">PUBLICATIONS</div>

          {defaultOrg ? <HeaderPath path={buildPathDetailed(getFullUri(defaultOrg))} /> : null}

          <div className="PublicationView">
            <br />

            <div className="row PublicationView__controls">
              <div className="col-xs-2">
                <img
                  className="SystemsView__control"
                  src={this.state.view === 'tree' ? iMatrixOff : iMatrixOn}
                  onClick={this.viewMatrix.bind(this)}
                  alt="icon control"
                />
                <img
                  className="SystemsView__control"
                  src={this.state.view === 'tree' ? iTreeOn : iTreeOff}
                  onClick={this.viewTree.bind(this)}
                  alt="control icon"
                />
              </div>
              <div className="SystemsView__pdf col-xs-10">
                <span onClick={this.downloadPDF(defaultOrg)}>&nbsp;PDF&nbsp;</span>
              </div>
            </div>

            {!this.state.loadFinished ? (
              <div className="SystemsView__loader">
                <Loader />
                <div id="SystemsView__loadingObjectCount"></div>
              </div>
            ) : (
              <div className="PublicationView__body">
                <div className="row PublicationView__bodyRow">
                  <div className="col-xs-12 row PublicationView__titles">
                    <div className="SystemsView__systemNameDiv col-xs-4">Systems</div>
                    <div className="SystemsView__systemNameDiv col-xs-4">Applications</div>
                    <div className="SystemsView__systemNameDiv col-xs-4">Publications</div>
                  </div>

                  <div className="row SystemsView__controlOut">
                    {[0, 1, 2].map((i, idx) => {
                      return (
                        <div key={idx} className="col-xs-4">
                          <div className="row">
                            <div className="col-xs-3">
                              <select onChange={this.setSort(i)}>
                                <option value="asc">Sort by A-Z</option>
                                <option value="desc">Sort by Z-A</option>
                              </select>
                            </div>
                            <div className="col-xs-3">
                              <input
                                className="SystemsView__searchInput"
                                type="text"
                                defaultValue=""
                                onChange={this.setSearch(i)}
                                placeholder="Search"
                              />
                            </div>
                            <div className="col-xs-6 SystemsView__controlOutButtons">
                              {this.state.fullCollapsed[i] ? (
                                <button onClick={this.showMore(i)} className="btn btn_light btn_align_left">
                                  Show All
                                </button>
                              ) : (
                                <button onClick={this.showLess(i)} className="btn btn_light btn_align_left">
                                  Hide All
                                </button>
                              )}
                              {this.state.fullExpanded[i] ? (
                                <button onClick={this.collapse(i)} className="btn btn_light">
                                  Collapse all
                                </button>
                              ) : (
                                <button onClick={this.expand(i)} className="btn btn_light">
                                  Expand All
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-6">
                              <KascodeSelect
                                onValuesChange={this.setUsageFilter(i)}
                                placeholder={
                                  this.state.usageFilter[i].length
                                    ? this.state.usageFilter[i].reduce((p, c) => (p ? p + ', ' + c : c), '')
                                    : 'Usage'
                                }
                                options={this.getUsageOptions(i)}
                                multi
                              />
                            </div>
                            <div className="col-xs-6">
                              <KascodeSelect
                                onValuesChange={this.setTagFilter(i)}
                                placeholder={
                                  this.state.tagFilter[i].length
                                    ? this.state.tagFilter[i].reduce((p, c) => (p ? p + ', ' + c : c), '')
                                    : 'Tag'
                                }
                                options={this.getTagOptions(i)}
                                multi
                              />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  {this.state.view === 'tree' ? (
                    <div className="row PublicationView__bodyRow">
                      <div className="col-xs-12 SystemsView__mainRow row PublicationView__list">
                        <CollapsableList
                          loadFinished={this.state.loadFinished}
                          className="SystemList"
                          neverCollapse
                          fullCollapsed={this.state.fullCollapsed[0]}
                          sortOrder={this.state.sortOrder[0]}
                          isTopLevel
                          items={
                            !this.props.appState.systems
                              ? []
                              : this.props.appState.systems
                                  .filter(
                                    (system) =>
                                      system &&
                                      system.identity.name !== '' &&
                                      system.object &&
                                      system.object.parent &&
                                      system.object.parent.id === this.state.currentOrganizationId &&
                                      (!this.state.usageFilter[0] ||
                                        this.usageIncluded(system.object.usage, this.state.usageFilter[0])) &&
                                      (!this.state.tagFilter[0] ||
                                        this.tagIncluded(system.object.tags, this.state.tagFilter[0])) &&
                                      this.searchIncluded(this.state.search[0], system.identity)
                                  )
                                  .map((system, index) => {
                                    //console.log("System:", system);
                                    return {
                                      title: system.identity.name,
                                      label: (
                                        <MajorObjectBlock
                                          objectData={system}
                                          type="systems"
                                          className="SystemBlock"
                                          showDescription={
                                            this.state.fullCollapsed[1] ||
                                            !system.applications ||
                                            system.applications.length === 0
                                              ? 'right'
                                              : 'bottom'
                                          }
                                        />
                                      ),
                                      body: (
                                        <CollapsableList
                                          index={index}
                                          fullCollapsed={this.state.fullCollapsed[1]}
                                          neverCollapse={this.state.fullExpanded[1]}
                                          sortOrder={this.state.sortOrder[1]}
                                          className={
                                            'ApplicationList ' +
                                            (this.state.fullCollapsed[2] ? 'ApplicationList__descriptionRight' : '')
                                          }
                                          items={
                                            !system.applications
                                              ? []
                                              : system.applications
                                                  .map((app_id, index) => {
                                                    const app = this.getApplicationData(app_id)
                                                    if (!app) return null
                                                    if (
                                                      this.state.usageFilter[1] &&
                                                      !this.usageIncluded(this.state.usageFilter[1], app.object.usage)
                                                    )
                                                      return null
                                                    if (
                                                      this.state.tagFilter[1] &&
                                                      !this.tagIncluded(app.object.tags, this.state.tagFilter[1])
                                                    )
                                                      return null
                                                    if (!this.searchIncluded(this.state.search[1], app.identity))
                                                      return null

                                                    return {
                                                      title: app.identity.name,
                                                      body: (
                                                        <CollapsableList
                                                          className="PublicationList"
                                                          key={index}
                                                          fullCollapsed={this.state.fullCollapsed[2]}
                                                          neverCollapse={this.state.fullExpanded[2]}
                                                          sortOrder={this.state.sortOrder[2]}
                                                          items={
                                                            !app.publications
                                                              ? []
                                                              : app.publications
                                                                  .map((pub_id) => {
                                                                    const pub = this.getPublicationData(pub_id)
                                                                    if (!pub) return null
                                                                    if (
                                                                      this.state.usageFilter[2] &&
                                                                      !this.usageIncluded(
                                                                        this.state.usageFilter[2],
                                                                        pub.object.usage
                                                                      )
                                                                    )
                                                                      return null
                                                                    if (
                                                                      this.state.tagFilter[2] &&
                                                                      !this.tagIncluded(
                                                                        pub.object.tags,
                                                                        this.state.tagFilter[2]
                                                                      )
                                                                    )
                                                                      return null
                                                                    if (
                                                                      !this.searchIncluded(
                                                                        this.state.search[2],
                                                                        pub.identity
                                                                      )
                                                                    )
                                                                      return null
                                                                    //console.log("pub", pub);
                                                                    return {
                                                                      title: pub.identity.name,
                                                                      label: (
                                                                        <MajorObjectBlock
                                                                          key={pub_id}
                                                                          objectData={pub}
                                                                          type="publications"
                                                                          className="PublicationBlock"
                                                                          hasLinks
                                                                          childCount={
                                                                            !pub.subscriptions
                                                                              ? 0
                                                                              : pub.subscriptions.length
                                                                          }
                                                                          showDescription="right"
                                                                        />
                                                                      ),
                                                                      body: ''
                                                                    }
                                                                  })
                                                                  .filter((e) => e !== null)
                                                          }
                                                          loadFinished={this.state.loadFinished}
                                                        />
                                                      ),
                                                      label: (
                                                        <MajorObjectBlock
                                                          objectData={app}
                                                          type="applications"
                                                          className="ApplicationBlock"
                                                          showDescription={
                                                            this.state.fullCollapsed[2] ||
                                                            !app.publications ||
                                                            app.publications.length === 0
                                                              ? 'right'
                                                              : 'bottom'
                                                          }
                                                        />
                                                      )
                                                    }
                                                  })
                                                  .filter((e) => e !== null)
                                          }
                                          loadFinished={this.state.loadFinished}
                                        />
                                      )
                                    }
                                  })
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="col-xs-12 SystemsView__mainRowChild">
                      {!this.props.appState.systems
                        ? null
                        : this.props.appState.systems
                            .filter((sys) => sys && sys.identity.name !== '')
                            .map((system, index) => (
                              <Matrix
                                loadFinished={this.state.loadFinished}
                                key={index}
                                text={system.identity.name}
                                headerLinkType="systems"
                                headerItem={system}
                                description={system.identity.description ? system.identity.description : ''}
                                showControls
                                fullCollapsable
                                initialVisibleItems={9}
                                parentClass="MatrixSystem"
                                childClass="col-xs-4"
                                items={
                                  !system.applications
                                    ? []
                                    : system.applications.map((app_id, index) => {
                                        const app = this.getApplicationData(app_id)
                                        return {
                                          title: app.identity.name,
                                          body: (
                                            <Matrix
                                              loadFinished={this.state.loadFinished}
                                              text={app.identity.name}
                                              headerLinkType="applications"
                                              headerItem={app}
                                              key={app_id}
                                              index={index}
                                              description={
                                                app.identity.description ? app.identity.description[app.locale] : ''
                                              }
                                              showControls={false}
                                              fullCollapsable={false}
                                              initialVisibleItems={2}
                                              parentClass="MatrixApplication"
                                              childClass=""
                                              icon="dark"
                                              items={
                                                !app.publications
                                                  ? []
                                                  : app.publications.map((pub_id) => {
                                                      const pub = this.getPublicationData(pub_id)
                                                      return {
                                                        title: pub.identity.name,
                                                        body: (
                                                          <MajorObjectBlock
                                                            key={pub_id}
                                                            type="publications"
                                                            objectData={pub}
                                                            className="PublicationBlock"
                                                            hasLinks
                                                          />
                                                        )
                                                      }
                                                    })
                                              }
                                            />
                                          )
                                        }
                                      })
                                }
                              />
                            ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </MainLayout>
      </div>
    )
  }
}

PublicationView.propTypes = {
  appState: PropTypes.object,
  userState: PropTypes.object,
  actions: PropTypes.object
}
