import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  checkFieldPrivacy,
  deepCopy,
  fieldTypeToString,
  getDefaultValue,
  objectNameFromPathByType,
  objectPropertyCI,
  setObjectPropertyCI
} from '../../helpers'
import { getObjectNewOrJson } from '../../helpers/api'
import { datasetToEnumOptions, editableState, getDatasetPathFromReference, nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import { getDatasetData, getDatasetMetadata } from '../../resources/lib/metadata'
import { DataEditor } from '../DataDialog/DataEditor'
import { ReferenceEditor } from '../DataDialog/ReferenceEditor'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { MultilineText } from '../MultilineText/MultilineText'
import { LoadableContent } from './LoadableContent'
import SimpleTable from './SimpleTable'
import { encryptPrivateField } from '../../helpers/helperComponents'

const CHILD_MARGIN = 32
const TAIL_MIN_SIZE = 20
const TAIL_COLLAPSED_SIZE = 10
const TAIL_BUTTON_SIZE = 60
const EXPAND_BUTTON_SIZE = 28
const DEFAULT_TAIL_HEIGHT = 57

const ACCESS_READ_ONLY = parseInt(
  getDatasetData(getDatasetMetadata('/organizations/Apdax/systems/DifHub/applications/System/datasets/Access')).find(
    (a) => a.Access === 'Internal'
  ).Code
)

/**
DataEditorTable is table component like PropertyTable, but uses DataEditor and input properties are different
 */
export class DataEditorTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expandedRows: props.expandedRows || [],
      loadedEnumsForValueDescriptions: {} // we have to load enum datasets in order to show Value descriptions in the (i) tooltip
    }
  }

  toggleExpandedRow(index) {
    return () => {
      let newExpandedRows = this.state.expandedRows.slice()
      newExpandedRows[index] = !newExpandedRows[index]
      this.setState({ expandedRows: newExpandedRows })
      if (this.props.onToggleExpanded) this.props.onToggleExpanded(newExpandedRows)
    }
  }

  /**
   * Returns passed or default column widths
   */
  getColWidths = () => {
    return (this.props.colWidths || [CHILD_MARGIN, 310, 294, 428]).map((w) => parseInt(w) + 'px')
  }

  onChange = (changedValue) => {
    let field = this.props.fields.reduce(
      (found, f) => (nonCSCompare(f.identity.name, changedValue.name) ? f : found),
      null
    )
    if (!field) {
      console.error('DataEditorTable::onChange not found field for', changedValue, this.props.fields)
      return false
    }

    if (checkFieldPrivacy(field) /*|| field.type !== 'Structure'*/) {
      changedValue.encryptedValue = changedValue.value === '' ? '' : encryptPrivateField(changedValue.value)
    } else {
      changedValue.encryptedValue = changedValue.encryptedValue || changedValue.value
    }

    //console.log("DataEditorTable::onChange", field, checkFieldPrivacy(field.type), changedValue);

    this.props.onChange(changedValue)
  }

  /**
   * Creates new property object and passes it to onChange handler
   * @param prop
   * @param newRow
   */
  updateRow(prop, newRow, newEncryptedRow) {
    const newChildObject = {
      name: prop.identity.name,
      value: newRow,
      encryptedValue: newEncryptedRow
    }
    if (this.props.onChange) this.props.onChange(newChildObject)
  }

  /**
   * Renders child DataEditorTable for array
   */
  renderChildArray(prop, val, encryptedVal, isExpanded, groupIndex) {
    const { level, isEditable, editableValues, parentObject, colWidths } = this.props

    //console.log("array property", prop);

    // convert value to array if it is not
    const arrayVal = (typeof val === 'object' && val && val.length ? val : [val]) || []
    let arrayObj = {}
    arrayVal.map((value, valueIndex) => {
      arrayObj[prop.identity.name + '[' + valueIndex + ']'] = value
    })

    const encryptedArrayVal =
      (typeof encryptedVal === 'object' && encryptedVal && encryptedVal.length ? encryptedVal : [encryptedVal]) || []
    let encryptedArrayObj = {}
    encryptedArrayVal.map((value, valueIndex) => {
      encryptedArrayObj[prop.identity.name + '[' + valueIndex + ']'] = value
    })
    //console.log("renderChildArray", arrayVal);

    return (
      <div>
        {!isExpanded ? null : (
          <div className="PropertyTable__childRow">
            <DataEditorTable
              fields={arrayVal.map((value, valueIndex) => {
                return {
                  identity: {
                    name: prop.identity.name + '[' + valueIndex + ']'
                  },
                  type: nonCSCompare(prop.type, 'reference') ? 'Structure' : prop.type,
                  reference: nonCSCompare(prop.type, 'reference')
                    ? '/organizations/' +
                      objectNameFromPathByType(prop.reference, 'organizations') +
                      '/datasets/identity'
                    : prop.reference,
                  subscription: nonCSCompare(prop.type, 'reference') ? null : prop.subscription,
                  count: 1,
                  access: prop.access,
                  usage: prop.usage
                }
              })}
              values={arrayObj}
              encryptedValues={encryptedArrayObj}
              level={(level || 0) + 1}
              isEditable={isEditable && !(prop.access === ACCESS_READ_ONLY)}
              editableValues={editableValues}
              onChange={(changedValue) => {
                const i = changedValue.name.lastIndexOf('[')
                const j = changedValue.name.lastIndexOf(']')
                const fieldName = changedValue.name.substring(0, i)
                const fieldIndex = parseInt(changedValue.name.substring(i + 1, j))
                let newRow =
                  fieldIndex >= arrayVal.length
                    ? arrayVal.concat(changedValue.value)
                    : arrayVal.map((oldValue, index) => (index === fieldIndex ? changedValue.value : oldValue))

                const v = changedValue.encryptedValue ? changedValue.encryptedValue : changedValue.value
                let newEncryptedRow =
                  fieldIndex >= arrayVal.length
                    ? encryptedArrayVal.concat(v)
                    : encryptedArrayVal.map((oldValue, index) => (index === fieldIndex ? v : oldValue))
                //console.log("array:onChange", changedValue, fieldName, fieldIndex, newRow, arrayVal.concat(changedValue.value) );

                this.updateRow(prop, newRow, newEncryptedRow)
              }}
              showDelete={!(prop.access === ACCESS_READ_ONLY)}
              showAdd={!(prop.access === ACCESS_READ_ONLY)}
              onDelete={(obj, index) => {
                const newRow = arrayVal.filter((oldValue, oldIndex) => index !== oldIndex)
                const newEncryptedRow = encryptedArrayVal.filter((oldValue, oldIndex) => index !== oldIndex)
                this.updateRow(prop, newRow, newEncryptedRow)
              }}
              onAdd={() => {
                const newRow = arrayVal.concat(getDefaultValue(prop.type.name))
                const newEncryptedRow = encryptedArrayVal.concat(getDefaultValue(prop.type.name))
                this.updateRow(prop, newRow, newEncryptedRow)
              }}
              majorObject={this.props.majorObject}
              colWidths={this.getColWidths().map((width, index) => (index === 0 ? width : width))}
              onToggleExpanded={this.props.onToggleExpanded}
            />
          </div>
        )}
      </div>
    )
  }

  /**
   * Renders child table for Structure-field
   * @param {*} prop
   * @param {*} val
   * @param {*} isExpanded
   * @param {*} groupIndex
   */
  renderChildStructure(prop, val, encryptedVal, isExpanded, groupIndex) {
    const { level, isEditable, editableValues, objectPath, parentObject, colWidths } = this.props

    if (!val) val = {}
    if (!encryptedVal) encryptedVal = deepCopy(val)

    //console.log("renderChildStructure", prop, val, encryptedVal);

    return (
      <div>
        <div className="PropertyTable__childRow">
          <LoadableContent
            visible={isExpanded}
            onLoad={this.props.onToggleExpanded}
            path={getDatasetPathFromReference(prop.reference, objectPath)}
            dataPropertyName="fields"
            dataPropertyName2="majorObject"
            dataTransform={(structureObject) => {
              //console.log("PropertyTable::dataTransform", structureObject);

              // dataset is transformed into property array
              if (!structureObject || !structureObject.structure || !structureObject.structure.fields) return null

              return structureObject.structure.fields
            }}
          >
            <DataEditorTable
              values={val}
              level={(level || 0) + 1}
              isEditable={isEditable && !(prop.access === ACCESS_READ_ONLY)}
              onChange={(changedValue) => {
                const newRow = setObjectPropertyCI(val, changedValue.name, changedValue.value)
                const newEncryptedRow = setObjectPropertyCI(
                  encryptedVal,
                  changedValue.name,
                  changedValue.encryptedValue
                )
                logger.log('ChildStructure.change', newRow, newEncryptedRow)
                this.props.onChange({
                  name: prop.identity.name,
                  value: newRow,
                  encryptedValue: newEncryptedRow
                })
              }}
              objectPath={prop.reference}
              colWidths={this.getColWidths().map((width, index) => (index === 0 ? width : width))}
              majorObject={this.props.majorObject}
              onToggleExpanded={this.props.onToggleExpanded}
            />
          </LoadableContent>
        </div>
      </div>
    )
  }

  /**
   * Loads description for current Value from Enumerator's column which has usage=description and appends it to field's description
   * @param {*} field
   */
  getFieldIdentityWithValue(field) {
    let id = Object.assign({}, field.identity)

    if (nonCSCompare(field.type, 'enum')) {
      const val = this.props.values[field.identity.name]
      if (this.state.loadedEnumsForValueDescriptions[field.reference]) {
        const dataset = this.state.loadedEnumsForValueDescriptions[field.reference]
        if (typeof dataset === 'object') {
          const options = datasetToEnumOptions(dataset)
          for (let opt in options) {
            // find current value in Enumerator's options and take description
            if (nonCSCompare(options[opt].label, val) || nonCSCompare(options[opt].value, val)) {
              if (options[opt].description)
                id.description = (
                  <React.Fragment>
                    {id.description}, <br />, {val + ': ' + options[opt].description}
                  </React.Fragment>
                )
            }
          }
        }
      } else {
        // set loadedEnumsForValueDescriptions to 'loading' to prevent next API calls while waiting for answer
        this.setState({
          loadedEnumsForValueDescriptions: Object.assign({}, this.state.loadedEnumsForValueDescriptions, {
            [field.reference]: 'Loading'
          })
        })
        getObjectNewOrJson(field.reference, 'dataset').then((ds) => {
          // save loaded dataset in local state
          this.setState({
            loadedEnumsForValueDescriptions: Object.assign({}, this.state.loadedEnumsForValueDescriptions, {
              [field.reference]: ds
            })
          })
        })
      }
    }

    return id
  }

  /**
   * Renders cells for one field group
   * @param {*} group
   * @param {*} expandButton
   */
  renderCells(group, expandButton) {
    const { isEditable, majorObject, onChange } = this.props
    let values = this.props.values || {}

    return group.map((field, fieldIndex) => {
      //console.log("DataEditorTable:RenderCells", field);
      return [
        expandButton,
        // eslint-disable-next-line react/jsx-key
        <EditableEntity
          dataType={{ name: 'propertyName' }}
          data={this.getFieldIdentityWithValue(field)}
          dataProps={{}}
        />,
        fieldTypeToString(field, majorObject),
        expandButton ? (
          <MultilineText text={JSON.stringify(values[field.identity.name])} maxLineCount={3} defaultLineHeight={20} />
        ) : nonCSCompare(field.type, 'reference') ? (
          <ReferenceEditor
            key="1"
            field={field}
            data={objectPropertyCI(values, field.identity.name, field.type)}
            options={{
              onOpen: this.props.onToggleExpanded,
              onChange: (newValue) => {
                //console.log("onChange DataEditorTable", field, newValue);
                this.onChange({
                  name: field.identity.name,
                  value: newValue
                })
              }
            }}
            change={(newValue) => {
              //console.log("change DataEditorTable", field, newValue);
            }}
            isEditable={isEditable ? editableState.EDITING : 0}
            majorObject={this.props.majorObject}
          />
        ) : (
          <DataEditor
            key="1"
            field={field}
            data={objectPropertyCI(values, field.identity.name, field.type)}
            options={{
              onOpen: this.props.onToggleExpanded,
              onChange: (newValue) => {
                //console.log("onChange DataEditorTable", field, newValue);
                this.onChange({
                  name: field.identity.name,
                  value: newValue
                })
              }
            }}
            change={(newValue) => {
              //console.log("change DataEditorTable", field, newValue);
            }}
            isEditable={isEditable ? editableState.EDITING : 0}
            majorObject={this.props.majorObject}
          />
        )
      ]
    })
  }

  /**
   * render one bottom button
   * @param button {label:string, onClick: ()=>{}}
   * @returns {XML}
   */
  renderBottomButton(button, buttonIndex) {
    return (
      <span key={buttonIndex} className="btn_edit TypedTable__bottomButton" onClick={button.onClick}>
        {button.label}
      </span>
    )
  }

  /**
   * render tail buttons for a row
   * @param row
   * @param index
   * @param hideButtons
   * @returns {*}
   */
  renderTail(row, index, hideButtons = false) {
    let buttons = hideButtons ? [] : this.props.showDelete ? [{ label: 'Delete', onClick: this.props.onDelete }] : []

    // do not render tail for currently editing row
    //if (this.state.editingField && this.state.editingField.rowIndex === index)
    //  return <div className="TypedTable__floatingButtonsOuter" style={{height: heightOfRow}} id={id}></div>;

    // calculate width for tail container
    let element = TAIL_MIN_SIZE
    buttons.map((button) => {
      element += button.size || TAIL_BUTTON_SIZE
    })

    let width = 0
    for (let i = 4 - 1; i >= 0; i--) {
      width += parseInt(this.getColWidths()[i])
      if (width > element) {
        // Check if width is too long for element.
        if (width - element > EXPAND_BUTTON_SIZE) {
          // It is way too long.
          if (width - element > element / 2) {
            width = element
          }
        }
        //console.error("TypedTable:renderTail", width, element, this.props);
        break
      }
    }

    if (hideButtons) width = TAIL_COLLAPSED_SIZE

    const id = 'floatingButtons_' + this.state.tableId + '_' + (row.id || index)

    const heightOfRow = document.getElementById(id)
      ? document.getElementById(id).parentNode.offsetHeight
      : DEFAULT_TAIL_HEIGHT

    let divButtons = (
      <div
        key={2 * index}
        className={
          'TypedTable__floatingButtonsOuter ' + (row.rowName ? 'TypedTable__floatingButtons__' + row.rowName : '')
        }
        style={{ height: heightOfRow }}
        id={id}
      >
        <div className="TypedTable__floatingButtons">
          <div className={'TypedTable__floatingButtonsInner'} style={{ left: -width, width: width }}>
            {buttons.map((button, buttonIndex) => {
              const buttonLabel = typeof button.label === 'function' ? button.label(row, index) : button.label
              return (
                <span key={buttonIndex}>
                  <a
                    onClick={(e) => {
                      button.onClick(row, row.index)
                      e.stopPropagation()
                    }}
                  >
                    {buttonLabel}
                  </a>
                </span>
              )
            })}
          </div>
        </div>
      </div>
    )

    if (buttons.length === 0)
      divButtons = (
        <div
          key={2 * index}
          className={
            'TypedTable__floatingButtonsOuter ' + (row.rowName ? 'TypedTable__floatingButtons__' + row.rowName : '')
          }
          style={{ height: 0 }}
          id={id}
        ></div>
      )

    if (index === -1) return divButtons

    return [
      divButtons,
      (this.props.customColumns || []).map((col) => (
        <div key={2 * index + 1} className={'TypedTable__customColumn TypedTable__customColumn_' + col.name}>
          {row[col.name]}
        </div>
      ))
    ]
  }

  render() {
    const { fields, isEditable, majorObject, level, bottom, showAdd } = this.props
    let values = this.props.values || {}
    let encryptedValues = this.props.encryptedValues || deepCopy(values)

    const bottomButtons = showAdd ? [{ label: '+ Add element', onClick: this.props.onAdd }] : []

    //console.log("DataEditorTable:render", fields, values);

    let propertyGroups = [[]]

    fields.map((prop, index) => {
      prop.index = index
      if (prop.reference || prop.count === 0 || prop.count > 1) {
        propertyGroups.push([prop])
        propertyGroups.push([])
      } else {
        propertyGroups[propertyGroups.length - 1].push(prop)
      }
    })

    propertyGroups = propertyGroups.filter((group) => group.length > 0)

    if (propertyGroups.length === 0) {
      propertyGroups.push([])
    }

    let rowIndex = 0

    return (
      <div>
        {propertyGroups.map((group, groupIndex) => {
          let currentRowIndex = rowIndex
          rowIndex += group.length

          let expandedBody = ''
          let expandButton = ''
          if (group.length > 0) {
            if (group[0].count === 0 || group[0].count > 1) {
              expandedBody = this.renderChildArray(
                group[0],
                objectPropertyCI(values, group[0].identity.name),
                objectPropertyCI(encryptedValues, group[0].identity.name),
                this.state.expandedRows[groupIndex],
                groupIndex
              )
            }
            // for structures we render expand button, and for expanded structures we render child Property Tables
            else if (group[0].reference && group[0].type.toLowerCase() === 'structure' /*&& !(level > 0)*/) {
              expandedBody = this.renderChildStructure(
                group[0],
                objectPropertyCI(values, group[0].identity.name),
                objectPropertyCI(encryptedValues, group[0].identity.name),
                this.state.expandedRows[groupIndex],
                groupIndex
              )
            }
          }

          if (expandedBody) {
            expandButton = (
              <div
                className={
                  'PropertyTable__expandButton ' +
                  (this.state.expandedRows[groupIndex] ? 'PropertyTable__expandButton_active' : '')
                }
                onClick={this.toggleExpandedRow(groupIndex)}
              >
                {this.state.expandedRows[groupIndex] ? '-' : '+'}
              </div>
            )
          }

          const hide = group.length > 0 && group[0].hide

          // header is rendered only for the first top-level table
          return (
            <div className="DataEditorTable__container" key={groupIndex}>
              <SimpleTable
                colHeaders={level > 0 || groupIndex !== 0 ? [] : [' ', 'Name', 'Type', 'Value']}
                cellValues={this.renderCells(group, expandButton)}
                colWidths={this.getColWidths().map((width, colIndex) => {
                  return colIndex === 1 ? parseInt(width) - level * CHILD_MARGIN + 'px' : width
                })}
                tails={group.map((row, index) => this.renderTail(row, index, !this.props.showDelete))}
                upperTail={this.renderTail([], -1)}
                zeroTails={group.map((row, index) => this.renderTail(row, index, true))}
                filters={[]}
              />
              {expandedBody}
            </div>
          )
        })}
        {bottom || (bottomButtons && bottomButtons.length > 0) ? (
          <div className="TypedTable__bottom">
            {bottom}
            {(bottomButtons || []).map((button, buttonIndex) => this.renderBottomButton(button, buttonIndex))}
          </div>
        ) : null}
      </div>
    )
  }
}

DataEditorTable.propTypes = {
  fields: PropTypes.array,
  values: PropTypes.array || PropTypes.object,
  encryptedValues: PropTypes.array,
  colWidths: PropTypes.array,
  // eslint-disable-next-line react/no-typos
  isEditable: PropTypes.boolean,
  majorObject: PropTypes.object, // used to display relative type references
  onChange: PropTypes.func,
  onToggleExpanded: PropTypes.func
}
