import PropTypes from 'prop-types'
import React from 'react'

const ErrorPage = ({ errorText, errorCode }) => {
  return (
    <div>
      <h1>{errorCode}</h1>
      <h3>{errorText}</h3>
    </div>
  )
}

ErrorPage.propTypes = {
  errorText: PropTypes.string,
  errorCode: PropTypes.number
}

export const AuthErrorPage = () =>
  ErrorPage({
    errorText: 'Authorization token error. Please check if time is set correctly on your machine.',
    errorCode: 401
  })

export default ErrorPage
