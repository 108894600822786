/**
 * Created by kascode on 07.04.16.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../styles/SearchInput.scss'
import SystemsView from '../SystemsView/SystemsView'

import searchPic from '../../resources/images/search-2x.png'

class SearchInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      loaded: false
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.appState.organizations || !newProps.appState.organizations.length) {
      this.setState({ loaded: false })
    }
  }

  onSelect(object) {
    let url = '/view/' + object.type + '/' + object.identity.name
    window.location = url
  }

  onBlur() {}

  textChange = (e) => {
    this.setState({ query: e.target.value, loaded: true })
    //document.body.style.overflow = e.target.value !== '' ? "hidden" : "auto";
    //console.log("textChange", document.body.style.overflow, e.target.value !== '', e.target.value);
  }

  render() {
    const { color } = this.props
    return (
      <div className="SearchInput">
        <input type="text" defaultValue="" onChange={this.textChange} placeholder="Search" />

        <img className="SearchInput__pic" src={searchPic} alt="search picture" />

        {this.state.loaded ? (
          <div className={'SearchInput__systemsView ' + (this.state.query ? 'SearchInput__systemsView__visible' : '')}>
            <SystemsView
              appState={this.props.appState}
              userState={this.props.userState}
              actions={this.props.actions}
              searchQuery={this.state.query}
              hidden={this.state.query === ''}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

SearchInput.propTypes = {
  color: PropTypes.string,
  appState: PropTypes.object
}

//export default SearchInput;

function mapStateToProps(state) {
  return {
    userState: state.userAppState,
    appState: state.appState
  }
}

export default connect(mapStateToProps, null, null)(SearchInput)
