/**
 * Created by kascode on 07.04.16.
 * Updated by vlad vovk on 15.03.21.
 */

import React from 'react'
import SearchInput from '../SearchInput/SearchInput'

import './Header.scss'

export const Header: React.FC<any> = ({ hideSearch, actions }) => {
  return (
    <div className="Header__outer">
      <div className="Header">
        <div className="Header__topRow">
          <div className="container">
            <div className="Header__grid">
              <div className="empty"></div>
              <div className="Header__search">
                {!hideSearch ? <SearchInput color="dark" actions={actions} /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Header__after"></div>
    </div>
  )
}
