import React from 'react'

import { capitalize, editableState, getLocalObject, restoreLocalObject } from './index'

import iLogEdit from '../resources/images/log_icon_edit.png'
import iLogDelete from '../resources/images/log_icon_delete.png'
import iLogAdd from '../resources/images/log_icon_add.png'
import iFull from '../resources/images/fullscreen-2x.png'
import iWindow from '../resources/images/window-2x.png'
import logger from './logger'

// todo: take key from organization's properties

const APDAX_PUBLIC_KEY =
  '<RSAKeyValue><Modulus>x1xBTEcUuZ5t07Ix6EI5gIcnAKDzsgNLUVAZs4saopA8i7YuXnkjce+cYbg7XkUG9Bzw2DM/qC1NaJmeBOS3zKJvh0Y6044J4YhWACd1YCkeGhKoVMr9oWVz7+POI3Ju74L3uT1C4njEf1vrbJjKE7yKAqNn1kHSpkLvDualsmVxhoD0r9Ja0KYwDt8wHNghXyIk9YrREihqroiowgQCE6iHUR6X7dXgjqx3D5XgT0jHEL8j8TabFgrDOgJOXRDwwZWxgLLfSQ0g7oqDRNxTd/yNahR9pCESfT1QWf9+cACc+NRzgxhdD9P/GZ6g61Tc0cTwaWDQ8wuZJ8ePwrjjpQ==</Modulus><Exponent>AQAB</Exponent></RSAKeyValue>'
/*
const APDAX_PUBLIC_KEY = "-----BEGIN PUBLIC KEY-----" +
"MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAx1xBTEcUuZ5t07Ix6EI5"+
"gIcnAKDzsgNLUVAZs4saopA8i7YuXnkjce+cYbg7XkUG9Bzw2DM/qC1NaJmeBOS3"+
"zKJvh0Y6044J4YhWACd1YCkeGhKoVMr9oWVz7+POI3Ju74L3uT1C4njEf1vrbJjK"+
"E7yKAqNn1kHSpkLvDualsmVxhoD0r9Ja0KYwDt8wHNghXyIk9YrREihqroiowgQC"+
"E6iHUR6X7dXgjqx3D5XgT0jHEL8j8TabFgrDOgJOXRDwwZWxgLLfSQ0g7oqDRNxT"+
"d/yNahR9pCESfT1QWf9+cACc+NRzgxhdD9P/GZ6g61Tc0cTwaWDQ8wuZJ8ePwrjj"+
"pQIDAQAB"+
"-----END PUBLIC KEY-----";
*/

export function encryptPrivateField(val) {
  var rsa = new window.System.Security.Cryptography.RSACryptoServiceProvider()
  // Import the RSA Key information.
  rsa.FromXmlString(APDAX_PUBLIC_KEY)
  var toEncryptData = window.System.Text.Encoding.UTF8.GetBytes(val)
  var encryptedBytes = rsa.Encrypt(toEncryptData, false)
  var encrypted = window.System.Convert.ToBase64String(encryptedBytes)

  /*
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(APDAX_PUBLIC_KEY);
  var encrypted = encrypt.encrypt(val);
  */
  logger.log('encryptPrivateField', encrypted)
  return encrypted
}

export function renderLog(log) {
  //console.log("renderLog", log);
  return (
    <div className="IssueMessage__log">
      {log.reduce((p, c, i) => {
        let opr = ''
        let atr = ''
        let str = ''
        let img = ''
        //console.log("renderLog:record", p, c, i);

        if (c.oldValue && c.newValue) {
          opr = ' Changed'
          atr = capitalize(c.name) + ':'
          str = c.oldValue + '   ==>   ' + c.newValue
          img = iLogEdit
        } else if (c.oldValue) {
          opr = ' Deleted'
          atr = capitalize(c.name) + ':'
          str = c.oldValue
          img = iLogDelete
        } else if (c.newValue) {
          opr = ' Added'
          atr = capitalize(c.name) + ':'
          str = c.newValue
          img = iLogAdd
        }
        return p.concat(
          img ? (
            <div className="IssueMessage__logRecord" key={i}>
              <div className="IssueMessage__logRecord__opr">
                <img src={img} />
                {opr}
              </div>
              <div className="IssueMessage__logRecord__atr">{atr}</div>
              <div className="IssueMessage__logRecord__str">{str}</div>
            </div>
          ) : (
            ''
          )
        )
      }, [])}
    </div>
  )
}

/**
 * Renders Restore button for dialog
 * Use: <EditorDialog headerContent={renderRestore(this)} ... />
 * @param dialog
 */
export const renderRestore =
  (dialog, done = false) =>
  (editState, content) => {
    const oldObject = getLocalObject(dialog)
    let ret = [content]
    if (oldObject && editState > editableState.EDITABLE) {
      ret.push(
        <div className="EditorDialog__restore">
          <a className="" onClick={() => restoreLocalObject(dialog, done)}>
            Restore
          </a>
        </div>
      )
    }
    //console.log("UTL:renderRestore", dialog);
    if (dialog.showFullScreen && dialog.showFullScreen()) {
      ret.push(
        <div className="EditorDialog__headerControlAdditional" onClick={dialog.toggleFullScreen}>
          <img className="" src={!dialog.state.fullScreen ? iFull : iWindow} />
        </div>
      )
    }

    return ret
  }

const iPriority = [
  '/images/hi-status-2x.png',
  '/images/1-status-2x-new.png',
  '/images/2-status-2x-new.png',
  '/images/3-status-2x-new.png',
  '/images/4-status-2x-new.png'
]

export function issuePriorityIcon(code) {
  return <img className="IssueCard__priorityIcon" src={iPriority[code]} />
}

export function getTailButtonLabel(buttonType) {
  const buttonTypeId = buttonType.toLowerCase()
  if (buttonTypeId == 'edit') {
    return (
      <span className="TailButton TailButton_Edit">
        <span className="TailButtonIcon TailButtonIcon_edit"></span>
        <span className="TailButtonText">Edit</span>
      </span>
    )
  }
  if (buttonTypeId == 'delete') {
    return (
      <span className="TailButton TailButton_Delete">
        <span className="TailButtonIcon TailButtonIcon_delete"></span>
        <span className="TailButtonText">Delete</span>
      </span>
    )
  }
  if (buttonTypeId == 'add') {
    return (
      <span className="TailButton TailButton_Add">
        <span className="TailButtonText">+ Add</span>
      </span>
    )
  }
  if (buttonTypeId == 'view') {
    return (
      <span className="TailButton TailButton_View">
        <span className="TailButtonIcon TailButtonIcon_view"></span>
        <span className="TailButtonText">View</span>
      </span>
    )
  }
  if (buttonTypeId == 'download') {
    return (
      <span className="TailButton TailButton_Download">
        <span className="TailButtonIcon TailButtonIcon_download"></span>
        <span className="TailButtonText">Load</span>
      </span>
    )
  }
  if (buttonTypeId == 'link') {
    return (
      <span className="TailButton TailButton_Link">
        <span className="TailButtonIcon TailButtonIcon_view"></span>
        <span className="TailButtonText">Link</span>
      </span>
    )
  }
  if (buttonTypeId == 'select') {
    return (
      <span className="TailButton TailButton_Add">
        <span className="TailButtonText">+ Select</span>
      </span>
    )
  }
  if (buttonTypeId == 'unselect') {
    return (
      <span className="TailButton TailButton_Add">
        <span className="TailButtonText">- Unselect</span>
      </span>
    )
  }

  return buttonType
}

export function getBottomButtonLabel(buttonType, buttonText) {
  const buttonTypeId = buttonType.toLowerCase()
  if (buttonTypeId == 'export') {
    return (
      <span className="TailButton TailButton_Download">
        <span className="TailButtonIcon TailButtonIcon_download"></span>
        <span className="TailButtonText">{buttonText ? buttonText : 'Export'}</span>
      </span>
    )
  } else if (buttonTypeId == 'import') {
    return (
      <span className="TailButton TailButton_Upload">
        <span className="TailButtonIcon TailButtonIcon_upload"></span>
        <span className="TailButtonText">{buttonText ? buttonText : 'Import'}</span>
      </span>
    )
  }
  return buttonType
}
