/**
 * Created by mailf on 23.08.2016.
 */

import React, { RefObject, Fragment } from 'react'
import { MajorObject } from '../MajorObject/MajorObject'
import { TagsDialog } from '../MajorObject/TagsDialog'
import { DocumentList } from '../DocumentList/DocumentList'
import ObjectProperties from '../ObjectProperties/ObjectProperties'
import ObjectAdvancedSettings from '../ObjectAdvancedSettings/ObjectAdvancedSettings'
import { EditButton } from '../EditButton/EditButton'
import moment from 'moment'
import { getIssueDeadline, renderDateOrTime, getUserPhotoUrl } from '../../helpers'
import { editableState, itemState } from '../../helpers/index'

import { Person } from '../Person/Person'
import { Loader } from '../Loader/Loader'
import { fetchDataUrlImmediate } from '../../helpers/api'

import './Issue.scss'

import iArrowDownClose from '../../resources/images/arrow-down-close.png'
import iAdd from '../../resources/images/add-2x.png'
import defaultUserPicture from '../../resources/images/face-2x.png'
import { issuePriorityIcon } from '../../helpers/helperComponents'
import { nl2br } from '../../helpers/nl2br'

export class Issue extends MajorObject {
  assignedRef: RefObject<any>
  docsRef: RefObject<any>
  issueDataRefDiv: RefObject<any>

  constructor(props) {
    super(props)

    this.state = Object.assign(this.state, { collapsed: true })

    this.assignedRef = React.createRef()
    this.docsRef = React.createRef()
    this.issueDataRefDiv = React.createRef()
  }

  getObjectType(props) {
    return 'issue'
  }

  componentDidUpdate(prevProps, prevState, prevContext) {
    if (this.props.handleIssueCollapseButton) {
      if (!this.state.collapsed && prevState.collapsed) {
        const issueData = this.issueDataRefDiv.current

        const mainContentHeight = issueData.querySelector('.col-xs-12').offsetHeight
        // issueData has big child col-xs-12 and two col-xs-5
        // we need to know which of later ones has bigger height
        const cols = issueData.querySelectorAll('.col-xs-5')
        const heightOfTallest =
          cols[0].offsetHeight > cols[1].offsetHeight ? cols[0].offsetHeight : cols[1].offsetHeight

        this.props.handleIssueCollapseButton(mainContentHeight + heightOfTallest)
      } else if (this.state.collapsed && !prevState.collapsed) {
        this.props.handleIssueCollapseButton(false)
      }
    }
  }

  renderChecklist = () => {
    const issue = this.props.majorObject
    let checklist = issue.tasks

    if (!checklist || !checklist.length) checklist = []

    return (
      <div>
        <div>
          <div className="col Issue__checklistHeader">
            Tasks:{' '}
            {checklist ? (
              <span>
                {checklist.filter((task) => task.check).length} of {checklist.length}
              </span>
            ) : (
              'no tasks'
            )}{' '}
          </div>
        </div>
        <div>
          <div className="col Issue__checklistItems">
            {checklist
              ? checklist
                  .sort((a, b) => {
                    if (parseInt(a.identity.name) > parseInt(b.identity.name)) return 1
                    if (parseInt(b.identity.name) > parseInt(a.identity.name)) return -1
                    return 0
                  })
                  .map((task, index) => (
                    <div
                      className={'Issue__checklistItem' + (task.check ? ' Issue__checklistItem_checked' : '')}
                      key={index}
                    >
                      {task.identity.description ? task.identity.description : task.identity.name}
                    </div>
                  ))
              : null}
          </div>
        </div>
      </div>
    )
  }

  renderIssueName = () => {
    return (
      <div className="Issue__subject">
        <div className="Issue__subjectLabel">Subject:</div>
        <div className="Issue__subjectValue">{this.props.majorObject.identity.description}</div>
      </div>
    )
  }

  renderNotes = (tall) => {
    return (
      <div className="Issue__description">
        <div className="Issue__descriptionLabel ">Description:</div>
        <div className={'Issue__descriptionValue' + (tall ? ' Issue__descriptionValue_tall' : '')}>
          {nl2br(this.props.majorObject.notes)}
        </div>
      </div>
    )
  }

  /**
   * Render tag list
   * @return
   */
  renderTags = () => {
    const majorObject = this.props.majorObject
    const issue = this.props.majorObject

    let tags = issue && issue.object && issue.object.tags ? issue.object.tags : []
    console.log('Issue:renderTags', tags, majorObject, this.state, this.props)

    return (
      <div>
        <TagsDialog
          appState={this.props.appState}
          actions={this.props.actions}
          buttonTitle={'Tags:'}
          isVisible
          isEditable={editableState.BROWSABLE}
          isItems={itemState.HORIZONTAL}
          tags={tags}
          majorObject={majorObject}
          onClose={this.onTagsClose.bind(this)}
          onSave={this.onTagsChange.bind(this)}
        />
      </div>
    )
  }

  renderDocuments = () => {
    return (
      <DocumentList
        ref={this.docsRef}
        docs={
          this.props.majorObject.object && this.props.majorObject.object.documents
            ? this.props.majorObject.object.documents
            : []
        }
        onChange={this.onDocumentsChange.bind(this)}
        onEdit={this.onDocumentsEdit.bind(this)}
        onClose={this.onDocumentsClose.bind(this)}
        isModal={this.state.editingDocuments}
        title={this.state.collapsed ? ' ' : 'Documents:'}
        modalTitle="Edit documents"
        emptyText="No documents"
        defaultNewValue="New document"
        delimiter=""
        isEditable={false}
        editByTitle
        dark
        objectURL={this.props.majorObject.attached.name + '/issues/' + this.props.majorObject.identity.name}
      />
    )
  }

  renderCollapseButton() {
    const style = this.state.collapsed ? 'closed' : 'opened'

    return (
      <div
        className={`MajorObjectView__collapse MajorObjectView__collapse_${style}`}
        onClick={() => {
          this.setState({ collapsed: !this.state.collapsed })
        }}
      >
        <img src={iArrowDownClose} alt="" />
      </div>
    )
  }

  renderDocumentAddButton() {
    return this.state.collapsed ? (
      <div className="Issue__documentAddButton">
        <img
          src={iAdd}
          className="ObjectCreator__addIcon__icon"
          onClick={() => this.docsRef.current.setState({ editorOpened: true })}
          alt="Issue document Add Button"
        />
      </div>
    ) : (
      ''
    )
  }

  render() {
    const issue = this.props.majorObject
    let resolution: any = 0
    const deadline = getIssueDeadline(issue)

    const userPhotoUrl = getUserPhotoUrl(issue.assigned.id)

    let style = {
      backgroundImage:
        'url(' + fetchDataUrlImmediate(userPhotoUrl, this.forceUpdate.bind(this)) + '), url(' + defaultUserPicture + ')'
    }

    if (deadline) resolution = moment.duration(moment(issue.time).add(deadline).diff(moment()))

    const hasTasks = issue.tasks && issue.tasks.length
    return (
      <div className={'Issue' + (!this.state.collapsed ? ' Issue_opened' : '') + ' MajorObjectView_issue'}>
        <div className="Issue__identity">
          <div className="Issue__identityHead">
            <h1 className="Issue__name">{issue.identity.name}</h1>
            <div className="Issue__dateCreated">{renderDateOrTime(issue.object.history.created)}</div>
          </div>
          <div className="Issue__assignedTo">
            <div
              style={style}
              className="Issue__assignedPicture"
              onClick={
                this.assignedRef.current ? this.assignedRef.current.openModal.bind(this.assignedRef.current) : null
              }
            ></div>
            {issue.assigned ? (
              <span className="Issue__assigned">
                <strong>Assigned to:</strong>
                <br />
                {!issue.assigned.name ? (
                  <Loader />
                ) : (
                  <span className="Issue__assignedName">
                    <Person
                      ref={this.assignedRef}
                      userId={issue.assigned.id}
                      user={{ profile: { alias: issue.assigned.name } }}
                    />
                  </span>
                )}
              </span>
            ) : (
              ''
            )}
          </div>
          <div className="Issue__property">
            <strong>Type:</strong> {issue.fullType.type}
          </div>
          <div className="Issue__property Issue__status">
            <strong className="Issue__statusLabel">Status:</strong>
            <div className="Issue__statusValue">
              {issue.fullStatus.name}
              <br />
              {issue.resolution ? '(' + issue.resolution + ')' : ''}
            </div>
          </div>
          <div className="Issue__property">
            <strong>Priority:</strong> {issue.fullPriority.name} {issuePriorityIcon(issue.fullPriority.code)}
          </div>
          <div className="Issue__property">
            <strong>Deadline:</strong>{' '}
            {deadline ? resolution.humanize() + (resolution < 0 ? ' ago' : '') + ' to resolution' : ''}
          </div>
        </div>

        <div className="row Issue__data clearfix" ref={this.issueDataRefDiv}>
          {this.renderCollapseButton()}
          <div className="col-xs-12">
            <div className="Issue__edit boldOnHover">
              <EditButton onEdit={this.props.onEdit(this.props.majorObject)} />
            </div>
            {this.renderIssueName()}
            {this.renderNotes(!hasTasks)}
            {hasTasks ? this.renderChecklist() : null}
            {!this.state.collapsed ? this.renderTags() : null}
            {this.renderDocumentAddButton()}
            {this.renderDocuments()}
          </div>
          {!this.state.collapsed ? (
            <div className="col-xs-5">
              <ObjectProperties properties={this.getObjectProperties()} dark isEditable={false} />
            </div>
          ) : null}
          {!this.state.collapsed ? (
            <div className="col-xs-5">
              <ObjectAdvancedSettings settings={issue.object.properties} dark isEditable={false} />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}
