import PropTypes from 'prop-types'
import React, { Component } from 'react'
import iWarning from '../../resources/images/confirmation-2x.png'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import './ConfirmDialog.scss'

export class ConfirmDialog extends Component {
  render() {
    const { baseLevel, text, caption, onCancel, onConfirm, cancelText, confirmText } = this.props

    return (
      <ModalWindow
        header={caption}
        canClose
        isOpen
        level={(baseLevel || 0) + 1}
        className="ConfirmDialog"
        onClose={onCancel}
        footer={
          <div className="OrganizationList__deleteButtons">
            <button className="btn_big" onClick={onCancel}>
              {cancelText}
            </button>
            <button className="btn_big" onClick={onConfirm}>
              {confirmText}
            </button>
          </div>
        }
      >
        <p>
          <img src={iWarning} alt="warning" />
        </p>
        <p>{text}</p>
      </ModalWindow>
    )
  }
}

ConfirmDialog.propTypes = {
  text: PropTypes.string,
  caption: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  baseLevel: PropTypes.number,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string
}
