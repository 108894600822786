import csv from 'csv'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { capitalize } from '../../helpers'
import { track } from '../../helpers/analytics'
import { API, getApiResource, getObjectListNew, getObjectNew, getRequestFromPath } from '../../helpers/api'
import { getFullUri } from '../../helpers/index'
import logger from '../../helpers/logger'
import '../../styles/SystemBlock/SystemBlock.scss'
import { AdaptiveImage } from '../AdaptiveImage/AdaptiveImage'
import { CollapsableList } from '../CollapsableList/CollapsableList'
import { KascodeSelect } from '../KascodeSelect/KascodeSelect'
import { Loader } from '../Loader/Loader'
import { MajorObjectBlock } from '../MajorObjectBlock/MajorObjectBlock'
import { MajorObjectVersioned } from '../MajorObjectVersioned/MajorObjectVersioned'
import { Matrix } from '../Matrix/Matrix'
import './SystemsView.scss'
import { downloadSelectorAsPDF } from '../../helpers/pdfDownloader'

let loadingObjectCount = 0
let searchTimeout = null

class SystemsView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      view: 'tree',
      currentOrganizationId: localStorage.getItem('currentOrganization'),
      loadFinished: false,
      sortOrder: ['asc', 'asc', 'asc'],
      fullCollapsed: [false, false, false],
      fullExpanded: [
        this.props.searchQuery ? true : false,
        this.props.searchQuery ? true : false,
        this.props.searchQuery ? true : false
      ],
      usageFilter: [false, false, false],
      tagFilter: [false, false, false],
      childTypeFilter: [],
      childStatusFilter: '',
      search: ['', '', this.props.searchQuery || ''],
      enumsLoaded: false
    }
  }

  componentDidMount() {
    this.getAllObjects()
    track('SystemsView', 'component', 'open')
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.searchQuery && newProps.searchQuery !== this.props.searchQuery) {
      this.setState({ search: ['', '', newProps.searchQuery] })
    }
  }

  getApplicationData = (id) => {
    return (this.props.appState.applications || []).find((el) => el.identity.id === id)
  }
  getDatasetData = (id) => {
    return (this.props.appState.datasets || []).find((el) => el.identity.id === id)
  }
  getInterfaceData = (id) => {
    return (this.props.appState.interfaces || []).find((el) => el.identity.id === id)
  }
  getPipelineData = (id) => {
    return (this.props.appState.pipelines || []).find((el) => el.identity.id === id)
  }
  getViewData = (id) => {
    return (this.props.appState.views || []).find((el) => el.identity.id === id)
  }

  viewMatrix() {
    this.setState({ view: 'matrix' })
  }

  viewTree() {
    this.setState({ view: 'tree' })
  }

  sliceCurrentOrg(orglist) {
    let defaultIndex = 0
    if (localStorage.getItem('currentOrganization')) {
      orglist.map((org, index) => {
        if (org.identity.id === localStorage.getItem('currentOrganization')) defaultIndex = index
      })
    }
    //console.log("sliceCurrentOrg defaultIndex",defaultIndex);
    //console.log("will load organization ",orglist[defaultIndex]);
    return [orglist[defaultIndex]]
  }

  loadingObjectAdd(count, objectType) {
    loadingObjectCount += count
    //console.log("added "+count+" " +objectType+" to " + (loadingObjectCount - count) + " result " + loadingObjectCount);
    if (document.getElementById('SystemsView__loadingObjectCount'))
      document.getElementById('SystemsView__loadingObjectCount').innerHTML =
        'Loading ' + loadingObjectCount + ' objects'
  }

  loadingObjectRemove(count, objectType) {
    loadingObjectCount -= count
    //console.log("deleted "+count+" " + objectType + " from " + (loadingObjectCount + count) + " result " + loadingObjectCount);
    if (document.getElementById('SystemsView__loadingObjectCount'))
      document.getElementById('SystemsView__loadingObjectCount').innerHTML =
        'Loading ' + loadingObjectCount + ' objects'
    if (loadingObjectCount === 0) {
      setTimeout(() => {
        this.setState({
          loadFinished: true
        })
      }, 50) // timeout is needed to render final object count
    }
  }

  getAllEnums() {
    this.setState({
      loadFinished: false
    })

    let datasetPromises = []

    this.props.appState.datasets.map((ds) => {
      if (ds.object && ds.object.usage === 'Enum') {
        this.loadingObjectAdd(1, 'ds')
        datasetPromises.push(
          new Promise((resolve, reject) => {
            getObjectNew(getRequestFromPath(getFullUri(ds)), 'dataset', this.props.actions).then(
              (ret) => resolve(ret),
              (err) => resolve(null)
            )
          })
        )
      }
    })

    Promise.all(datasetPromises).then((datasets) => {
      this.loadingObjectRemove(datasets.length)
      this.props.actions.receiveObjectList(
        'dataset',
        datasets.filter((d) => d)
      )
      this.setState({ enumsLoaded: true })
    })
  }

  getAllObjects() {
    this.setState({
      loadFinished: false
    })
    getObjectListNew(API.organizations, 'organization', this.props.actions).then((orglist) => {
      this.sliceCurrentOrg(orglist).map((org) => {
        this.loadingObjectAdd(1, 'org')
        //console.log("after slice org",org);
        getObjectListNew(API.organizations(org.identity.name).systems, 'system', this.props.actions).then(
          (syslist) => {
            let syslistFiltered = syslist.filter((sys) => {
              //console.log('orgid = ' + org.identity.id + ', sys', sys);
              if (!sys.identity.name) return false
              if (!sys.object || !sys.object.parent || sys.object.parent.id !== org.identity.id) return false
              return true
            })
            this.loadingObjectAdd(syslistFiltered.length, 'sys')
            this.props.actions.updateMajorObject(org.identity.id, 'organizations', {
              systems: syslist.map((sys) => sys.identity.id)
            })
            syslistFiltered.map((sys) => {
              getObjectListNew(
                API.organizations(org.identity.name).systems(sys.identity.name).applications,
                'application',
                this.props.actions
              ).then(
                (applist) => {
                  this.loadingObjectAdd(applist.length, 'app')
                  //console.log('applist', applist);
                  this.props.actions.updateMajorObject(sys.identity.id, 'systems', {
                    applications: applist.map((app) => app.identity.id)
                  })
                  applist.map((app) => {
                    //console.log('make request for app',app);
                    // get all Datasets of Application

                    let datasets = []
                    let interfaces = []
                    let pipelines = []
                    let views = []

                    //console.log("app before promise");

                    const appDatasetsPromise = new Promise((resolve, reject) => {
                      // request dataset list
                      getObjectListNew(
                        API.organizations(org.identity.name).systems(sys.identity.name).applications(app.identity.name)
                          .datasets,
                        'dataset'
                      ).then((dsist) => {
                        datasets = dsist
                        app.datasets = dsist.map((ds) => ds.identity.id)
                        resolve()
                      }, resolve)
                    })

                    const appInterfacesPromise = new Promise((resolve, reject) => {
                      // request dataset list
                      getObjectListNew(
                        API.organizations(org.identity.name).systems(sys.identity.name).applications(app.identity.name)
                          .interfaces,
                        'interface',
                        null
                      ).then((appInts) => {
                        interfaces = appInts
                        app.interfaces = appInts.map((intrfc) => intrfc.identity.id)
                        resolve()
                      }, resolve)
                    })

                    const appPipelinesPromise = new Promise((resolve, reject) => {
                      // request dataset list
                      getObjectListNew(
                        API.organizations(org.identity.name).systems(sys.identity.name).applications(app.identity.name)
                          .pipelines,
                        'pipeline',
                        null
                      ).then((appPipelines) => {
                        pipelines = appPipelines
                        app.pipelines = appPipelines.map((pp) => pp.identity.id)
                        resolve()
                      }, resolve)
                    })

                    const appViewsPromise = new Promise((resolve, reject) => {
                      // request dataset list
                      getObjectListNew(
                        API.organizations(org.identity.name).systems(sys.identity.name).applications(app.identity.name)
                          .views,
                        'view',
                        null
                      ).then((appViews) => {
                        views = appViews
                        app.views = appViews.map((v) => v.identity.id)
                        resolve()
                      }, resolve)
                    })

                    //console.log("app afer promise");
                    this.loadingObjectAdd(4, 'appchild')

                    Promise.all([appDatasetsPromise, appInterfacesPromise, appPipelinesPromise, appViewsPromise]).then(
                      () => {
                        //console.log("app in promise all", datasets);
                        this.props.actions.receiveObjectList('dataset', datasets)
                        this.props.actions.receiveObjectList('interface', interfaces)
                        this.props.actions.receiveObjectList('pipeline', pipelines)
                        this.props.actions.receiveObjectList('view', views)
                        this.loadingObjectRemove(4, 'appchild')
                        this.props.actions.receiveObject('application', app.identity.id, app)
                      }
                    )
                  }) // applist map
                  this.loadingObjectRemove(applist.length, 'app')

                  // system loaded

                  this.loadingObjectRemove(1, 'sys')
                },
                () => {
                  this.loadingObjectRemove(1, 'sys')
                }
              )
            })
            this.loadingObjectRemove(1, 'org')
          },
          () => {
            this.loadingObjectRemove(1, 'org')
          }
        )
      })
    })
  }

  setSort = (i) => {
    return (event) => {
      let sortCopy = this.state.sortOrder.slice()
      sortCopy[i] = event.target.value
      this.setState({ sortOrder: sortCopy })
    }
  }

  showMore = (i) => {
    return (event) => {
      let coll = this.state.fullCollapsed.slice()
      coll[i] = false
      this.setState({ fullCollapsed: coll })
    }
  }

  showLess = (i) => {
    return (event) => {
      let coll = this.state.fullCollapsed.slice()
      coll[i] = true
      this.setState({ fullCollapsed: coll })
    }
  }

  expand = (i) => {
    return (event) => {
      let coll = this.state.fullExpanded.slice()
      coll[i] = true
      this.setState({ fullExpanded: coll })
      if (this.isEnumFilter()) {
        this.getAllEnums()
      }
    }
  }

  collapse = (i) => {
    return (event) => {
      let coll = this.state.fullExpanded.slice()
      coll[i] = false
      this.setState({ fullExpanded: coll })
    }
  }

  setUsageFilter = (i) => {
    return (event) => {
      //console.log("setUsageFilter",i,event.target.value.map(opt => opt.value));
      this.setState({
        usageFilter: this.state.usageFilter.map((oldVal, index) =>
          index === i ? event.target.value.map((opt) => opt.value) : oldVal
        )
      })
    }
  }
  setTagFilter = (i) => {
    return (event) => {
      this.setState({
        tagFilter: this.state.tagFilter.map((oldVal, index) =>
          index === i ? event.target.value.map((opt) => opt.value) : oldVal
        )
      })
    }
  }
  setChildTypeFilter = (event) => {
    this.setState({
      childTypeFilter: event.target.value.map((opt) => opt.value)
    })
  }
  setChildStatusFilter = (event) => {
    this.setState({
      childStatusFilter: event.target.value.map((opt) => opt.value)
    })
  }

  isEnumFilter() {
    return this.state.usageFilter[2].length === 1 && this.state.usageFilter[2][0] === 'Enum'
  }

  setSearch = (i) => {
    return (event) => {
      //console.log("SetSearch", i, searchTimeout);
      if (searchTimeout) clearTimeout(searchTimeout)

      let t = this
      let newVal = event.target.value

      searchTimeout = setTimeout(() => {
        //console.log("SetSearch actual", i, newVal);
        t.setState({
          search: t.state.search.map((val, index) => (index === i ? newVal : val))
        })

        searchTimeout = null
      }, 200)

      return true
    }
  }

  clearFilters = () => {
    this.setState({
      sortOrder: ['asc', 'asc', 'asc'],
      usageFilter: [false, false, false],
      tagFilter: [false, false, false],
      childTypeFilter: [],
      childStatusFilter: '',
      search: ['', '', this.props.searchQuery || '']
    })
    return false
  }

  getUsageOptions(i) {
    let usages = []
    if (i === 0) {
      if (this.props.appState.systems) {
        this.props.appState.systems.map((sys) => (!sys.object ? null : usages.push(sys.object.usage)))
      }
    }
    if (i === 1) {
      if (this.props.appState.applications) {
        this.props.appState.applications.map((sys) => (!sys.object ? null : usages.push(sys.object.usage)))
      }
    }
    if (i === 2) {
      if (this.props.appState.datasets) {
        this.props.appState.datasets.map((sys) => (!sys.object ? null : usages.push(sys.object.usage)))
      }
      if (this.props.appState.interfaces) {
        this.props.appState.interfaces.map((sys) => (!sys.object ? null : usages.push(sys.object.usage)))
      }
      if (this.props.appState.pipelines) {
        this.props.appState.pipelines.map((sys) => (!sys.object ? null : usages.push(sys.object.usage)))
      }
      if (this.props.appState.views) {
        this.props.appState.views.map((sys) => (!sys.object ? null : usages.push(sys.object.usage)))
      }
    }
    usages = usages.filter((u) => u)
    usages = usages.filter((v, i) => usages.indexOf(v) === i) //unique
    return usages.map((usage, index) => {
      return { id: index, label: usage, value: usage }
    })
  }

  getTagOptions(i) {
    let tags = []
    if (i === 0) {
      if (this.props.appState.systems) {
        this.props.appState.systems.map((sys) => (!sys.object ? null : (tags = tags.concat(sys.object.tags))))
      }
    }
    if (i === 1) {
      if (this.props.appState.applications) {
        this.props.appState.applications.map((sys) => (!sys.object ? null : (tags = tags.concat(sys.object.tags))))
      }
    }
    if (i === 2) {
      if (this.props.appState.datasets) {
        this.props.appState.datasets.map((sys) => (!sys.object ? null : (tags = tags.concat(sys.object.tags))))
      }
      if (this.props.appState.interfaces) {
        this.props.appState.interfaces.map((sys) => (!sys.object ? null : (tags = tags.concat(sys.object.tags))))
      }
      if (this.props.appState.pipelines) {
        this.props.appState.pipelines.map((sys) => (!sys.object ? null : (tags = tags.concat(sys.object.tags))))
      }
      if (this.props.appState.views) {
        this.props.appState.views.map((sys) => (!sys.object ? null : (tags = tags.concat(sys.object.tags))))
      }
    }
    tags = tags
      .filter((u) => u)
      .map((tag) => tag.name)
      .filter((u) => u)
    tags = tags.filter((v, i) => tags.indexOf(v) === i) //unique
    return tags.map((tag, index) => {
      return { id: index, label: tag, value: tag }
    })
  }

  tagIncluded(searchTag, tags) {
    if (!searchTag || searchTag.length === 0) return true
    if (!tags) return false
    return tags.reduce((prev, tag) => (tag && searchTag.indexOf(tag.name) !== -1 ? true : prev), false)
  }

  usageIncluded(usageFilter, usage) {
    if (!usageFilter || usageFilter.length === 0) return true
    return usageFilter.indexOf(usage) !== -1
  }

  searchIncluded(searchFilter, identity) {
    //return true;

    if (!searchFilter || searchFilter.length === 0) return true
    if (!identity) return false
    return (
      (identity.name && identity.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1) ||
      (identity.description && identity.description.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1)
    )
  }

  /*
   searchOrder = (a, b) => {
   const searchFilter = this.state.search[2];

   if (!a || !b || !a.identity || !b.identity)
   return 0;
   const an = a.identity.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1;
   const bn = b.identity.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1;
   if (an && !bn)
   return -1;
   else if (bn && !an)
   return 1;
   return 0;
   };
   */

  getDataFunc = (objectType) => {
    let getFunc = null
    switch (objectType) {
      case 'system':
        getFunc = (x) => x
        break
      case 'application':
        getFunc = this.getApplicationData
        break
      case 'dataset':
        getFunc = this.getDatasetData
        break
      case 'interface':
        getFunc = this.getInterfaceData
        break
      case 'pipeline':
        getFunc = this.getPipelineData
        break
      case 'view':
        getFunc = this.getViewData
        break
      default:
        console.warn('searchOrder incorrect type', objectType)
        return null
    }
    return getFunc
  }

  hasSearchInNameStart(a, searchFilter) {
    return a.identity.name.toLowerCase().indexOf(searchFilter.toLowerCase()) === 0
  }

  hasSearchAtWordStart(a, searchFilter) {
    return this.hasSearchInNameStart(a, searchFilter) || this.hasSearchInName(a, ' ' + searchFilter)
  }

  hasSearchInName(a, searchFilter) {
    //console.log("hasSearchInName", a, searchFilter);
    return a.identity.name.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1
  }

  searchPriority(a, searchFilter) {
    //console.log("searchPriority", a, searchFilter);
    if (!a || !a.identity || !searchFilter) return 0
    if (this.hasSearchInNameStart(a, searchFilter)) return 3
    if (this.hasSearchInName(a, searchFilter)) return 2
    if (this.hasSearchAtWordStart(a, searchFilter)) return 1
    return 0
  }

  hasSearchFilter = () => {
    return this.state.search[0] || this.state.search[1] || this.state.search[2]
  }

  searchOrder = (objectType, searchFilter) => (ai, bi) => {
    let a = null
    let b = null
    if (typeof ai === 'object' && typeof bi === 'object') {
      if (ai.identity && bi.identity) {
        a = ai
        b = bi
      } else {
        console.warn('objects passed to SearchOrder without identity', objectType, searchFilter, ai, bi)
        return 0
      }
    } else {
      const getFunc = this.getDataFunc(objectType)
      if (!getFunc) return 0
      a = getFunc(ai)
      b = getFunc(bi)
    }
    //console.log("searchOrder", objectType, searchFilter, a, b, ai, bi);
    if (!a || !b || !a.identity || !b.identity) return 0
    const an = this.searchPriority(a, searchFilter)
    const bn = this.searchPriority(b, searchFilter)
    if (an > 0 || bn > 0) console.log('searchOrder', searchFilter, a.identity.name, b.identity.name, an, bn)
    if (an > bn) return -1
    else if (bn > an) return 1
    return 0
  }

  getSystemSearchDatasetPriority = (sys) => {
    let priority = 0
    this.getApplications(sys).map((app) => {
      priority = Math.max(app.priority, priority)
    })
    return priority
  }

  getSystemSearchApplicationPriority = (sys) => {
    let priority = 0
    this.getApplications(sys).map((app) => {
      //console.log("getSystemSearchDatasetPriority", sys, app, priority);
      priority = Math.max(this.searchPriority(app, this.state.search[1]), priority)
    })
    return priority
  }

  getSystems = () => {
    return !this.props.appState.systems
      ? []
      : this.props.appState.systems
          .filter(
            (system) =>
              system &&
              system.identity.name !== '' &&
              system.object &&
              system.object.parent &&
              system.object.parent.id === this.state.currentOrganizationId &&
              (!this.state.usageFilter[0] || this.usageIncluded(this.state.usageFilter[0], system.object.usage)) &&
              (!this.state.tagFilter[0] || this.tagIncluded(this.state.tagFilter[0], system.object.tags)) &&
              this.searchIncluded(this.state.search[0], system.identity)
          )
          .sort(this.searchOrder('system', this.state.search[0]))
          .sort((a, b) => {
            if (!this.state.search[1]) return 0
            const aMaxPriority = this.getSystemSearchApplicationPriority(a)
            const bMaxPriority = this.getSystemSearchApplicationPriority(b)
            console.log('sort systems', a, b, aMaxPriority, bMaxPriority)
            if (aMaxPriority > bMaxPriority) return -1
            else if (aMaxPriority < bMaxPriority) return 1
            return 0
          })
          .sort((a, b) => {
            if (!this.state.search[2]) return 0
            const aMaxPriority = this.getSystemSearchDatasetPriority(a)
            const bMaxPriority = this.getSystemSearchDatasetPriority(b)
            console.log('sort systems', a, b, aMaxPriority, bMaxPriority)
            if (aMaxPriority > bMaxPriority) return -1
            else if (aMaxPriority < bMaxPriority) return 1
            return 0
          })
  }

  getApplicationChildren = (app, childType) => {
    const childTypePlural = getApiResource(childType)
    return !app[childTypePlural]
      ? []
      : app[childTypePlural]
          .map((ds_id) => {
            const getFunc = this.getDataFunc(childType)
            const ds = getFunc(ds_id)
            if (!ds) return null
            if (this.state.usageFilter[2] && !this.usageIncluded(this.state.usageFilter[2], ds.object.usage))
              return null
            if (this.state.tagFilter[2] && !this.tagIncluded(this.state.tagFilter[2], ds.object.tags)) return null
            if (!this.searchIncluded(this.state.search[2], ds.identity)) return null
            if (
              this.state.childTypeFilter.length > 0 &&
              !this.state.childTypeFilter.includes(capitalize(childTypePlural))
            )
              return null
            let dsVersionStatus =
              ds.object &&
              ds.object.history &&
              ds.object.history.completions &&
              ds.object.history.completions.length > 0
                ? MajorObjectVersioned.getObjectStatus(ds.object.history)
                : 'draft'
            //console.log("ds",ds,"dsVersionStatus", dsVersionStatus);
            if (
              this.state.childStatusFilter.length > 0 &&
              !this.state.childStatusFilter.includes(capitalize(dsVersionStatus))
            )
              return null
            //console.log("pub", pub);

            return {
              title: ds.identity.name,
              label: (
                <MajorObjectBlock
                  key={ds_id}
                  objectData={ds}
                  type={childTypePlural}
                  className={capitalize(childType) + 'Block'}
                  hasLinks
                  childCount={0}
                  showDescription="right"
                  showData={this.isEnumFilter()}
                />
              ),
              body: '',
              identity: ds.identity,
              entity: ds
            }
          })
          .filter((e) => e !== null)
          .sort(this.searchOrder(childType, this.state.search[2]))
  }

  getApplications = (system) => {
    return !system.applications
      ? []
      : system.applications
          .sort(this.searchOrder('application', this.state.search[1]))
          .map((app_id, index) => {
            const app = this.getApplicationData(app_id)
            if (!app) return null
            if (this.state.usageFilter[1] && !this.usageIncluded(this.state.usageFilter[1], app.object.usage))
              return null
            if (this.state.tagFilter[1] && !this.tagIncluded(this.state.tagFilter[1], app.object.tags)) return null
            if (!this.searchIncluded(this.state.search[1], app.identity)) return null

            app.children = [].concat.apply(
              [],
              ['dataset', 'interface', 'view', 'pipeline'].map((childType) =>
                this.getApplicationChildren(app, childType)
              )
            )
            if (this.state.search[2])
              app.priority = (app.children || []).reduce(
                (prev, cur) => Math.max(prev, this.searchPriority(cur, this.state.search[2])),
                0
              )

            //if (this.state.search[2])
            //  app.children = app.children.sort(this.searchOrder('', this.state.search[2]));

            console.log('Children for', app.identity.name, app.children)

            return app
          })
          .filter((app) => app !== null)
          .sort((a, b) => {
            if (!this.state.search[2]) return 0
            console.log('sort applications', a, b, a.priority, b.priority)
            if (a.priority > b.priority) return -1
            else if (a.priority < b.priority) return 1
            return 0
          })
    //.filter(app => app).map(app => console.log("returnfed", app));
  }

  downloadPDF = (org) => () => {
    downloadSelectorAsPDF(
      document.querySelector('.SystemsView__body'),
      org.identity.name + ' Systems View',
      '.MajorObjectBlock'
    )
  }

  downloadCSV = (org) => () => {
    let csvContent = 'data:text/csv;charset=utf-8,'
    let dataString = ''

    // Systems of this organization.
    let data = []
    this.getSystems().map((sys) => {
      let apps = this.getApplications(sys)
      if (!apps || apps.length < 1) {
        // We have list of systems only
        data.push({
          type: 'system',
          name: sys.identity.name,
          description: sys.identity.description,
          usage: sys.object.usage
        })
      } else {
        // We have applications in system
        apps.map((app) => {
          let objs = app.children
          //logger.info('SystemView:downloadCSV:APP', app, objs);
          if (!objs || objs.length < 1) {
            // No children objects in application
            data.push({
              sys_type: 'system',
              sys_name: sys.identity.name,
              sys_description: sys.identity.description,
              sys_usage: sys.object.usage,
              app_type: 'application',
              app_name: app.identity.name,
              app_description: app.identity.description,
              app_usage: app.object.usage
            })
          } else {
            // Application have children objects
            objs.map((obj) => {
              data.push({
                sys_type: 'system',
                sys_name: sys.identity.name,
                sys_description: sys.identity.description,
                sys_usage: sys.object.usage,
                app_type: 'application',
                app_name: app.identity.name,
                app_description: app.identity.description,
                app_usage: app.object.usage,
                obj_type: obj.entity.type ? obj.entity.type : obj.entity.object.type,
                obj_name: obj.entity.identity.name,
                obj_description: obj.entity.identity.description,
                obj_usage: obj.entity.object.usage
              })
            })
          }
        })
      }
    })

    logger.info('SystemView:downloadCSV', data)

    csv.stringify(data, function (err, data) {
      dataString += data
      csvContent += dataString
      //logger.info('SystemView:downloadCSV:TEXT', data, dataString, csvContent);

      // Create document and download it.
      let encodedUri = encodeURI(csvContent)
      let link = document.createElement('a')

      link.setAttribute('href', encodedUri)
      link.setAttribute(
        'download',
        (org && org.identity && org.identity.name ? org.identity.name.replace(/:/g, '%') : 'systems') + '.csv'
      )
      document.body.appendChild(link) // Required for FF
      link.click()

      // Cleanup the DOM
      document.body.removeChild(link)
    })
  }

  render() {
    const appState = this.props.appState
    let defaultOrg = null
    if (appState.organizations)
      appState.organizations.map((org, index) => {
        if (!localStorage.getItem('currentOrganization') && index === 0) {
          defaultOrg = org
        }
        if (localStorage.getItem('currentOrganization') === org.identity.id) {
          defaultOrg = org
        }
      })

    if (this.props.hidden) return null

    return (
      <div className="SystemsView">
        <br />

        <div className="row SystemsView__controls">
          <div className="col-xs-3">
            <AdaptiveImage
              className="SystemsView__control"
              src={this.state.view === 'tree' ? 'matrix-button' : 'matrix-button-activ'}
              onClick={this.viewMatrix.bind(this)}
            />
            <AdaptiveImage
              className="SystemsView__control"
              src={this.state.view === 'tree' ? 'tree-button-activ' : 'tree-button'}
              onClick={this.viewTree.bind(this)}
            />
            <a href="#" onClick={this.clearFilters}>
              Clear filters
            </a>
          </div>

          <div className="SystemsView__pdf col-xs-10">
            <div className="SystemsView__showPDF">
              <span onClick={this.downloadPDF(defaultOrg)}>&nbsp;PDF&nbsp;</span>
            </div>
            <br />
            <div className="SystemsView__showCSV">
              <span onClick={this.downloadCSV(defaultOrg)}>&nbsp;CSV&nbsp;</span>
            </div>
          </div>
        </div>

        {!this.state.loadFinished ? (
          <div className="SystemsView__loader">
            <Loader />
            <div id="SystemsView__loadingObjectCount"></div>
          </div>
        ) : (
          <div className="SystemsView__body">
            {this.state.view === 'tree' ? (
              <div className="row SystemsView__bodyRow">
                <div className="col-xs-12 row SystemsView__titles">
                  <div className="SystemsView__systemNameDiv col-xs-4">Systems</div>
                  <div className="SystemsView__systemNameDiv col-xs-4">Applications</div>
                  <div className="SystemsView__systemNameDiv col-xs-4">Datasets, Interfaces, Pipelines, Views</div>
                </div>

                <div className="row SystemsView__controlOut">
                  {[0, 1, 2].map((i, idx) => {
                    return (
                      <div key={idx} className="col-xs-4">
                        <div className="row">
                          <div className="col-xs-3">
                            <select onChange={this.setSort(i)}>
                              <option value="asc">Sort by A-Z</option>
                              <option value="desc">Sort by Z-A</option>
                            </select>
                          </div>
                          <div className="col-xs-3">
                            <input
                              className="SystemsView__searchInput"
                              type="text"
                              defaultValue=""
                              onChange={this.setSearch(i)}
                              placeholder="Search"
                            />
                          </div>
                          <div className="col-xs-6 SystemsView__controlOutButtons">
                            {this.state.fullCollapsed[i] ? (
                              <button onClick={this.showMore(i)} className="btn btn_light btn_align_left">
                                Show All
                              </button>
                            ) : (
                              <button onClick={this.showLess(i)} className="btn btn_light btn_align_left">
                                Hide All
                              </button>
                            )}
                            {this.state.fullExpanded[i] ? (
                              <button onClick={this.collapse(i)} className="btn btn_light">
                                Collapse all
                              </button>
                            ) : (
                              <button onClick={this.expand(i)} className="btn btn_light">
                                Expand All
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-6">
                            <KascodeSelect
                              onValuesChange={this.setUsageFilter(i)}
                              placeholder={
                                this.state.usageFilter[i].length
                                  ? this.state.usageFilter[i].reduce((p, c) => (p ? p + ', ' + c : c), '')
                                  : 'Usage'
                              }
                              options={this.getUsageOptions(i)}
                              multi
                            />
                          </div>
                          <div className="col-xs-6">
                            <KascodeSelect
                              onValuesChange={this.setTagFilter(i)}
                              placeholder={
                                this.state.tagFilter[i].length
                                  ? this.state.tagFilter[i].reduce((p, c) => (p ? p + ', ' + c : c), '')
                                  : 'Tag'
                              }
                              options={this.getTagOptions(i)}
                              multi
                            />
                          </div>
                        </div>
                        <div className="row">
                          {i === 2 ? (
                            <div className="col-xs-6">
                              <KascodeSelect
                                onValuesChange={this.setChildTypeFilter}
                                placeholder={
                                  this.state.childTypeFilter.length
                                    ? this.state.childTypeFilter.reduce((p, c) => (p ? p + ', ' + c : c), '')
                                    : 'Type'
                                }
                                options={['Datasets', 'Interfaces', 'Pipelines', 'Views'].map((value, index) => {
                                  return {
                                    id: index,
                                    value: value,
                                    label: value
                                  }
                                })}
                                multi
                              />
                            </div>
                          ) : null}
                          {i === 2 ? (
                            <div className="col-xs-6">
                              <KascodeSelect
                                onValuesChange={this.setChildStatusFilter}
                                placeholder={
                                  this.state.childStatusFilter.length
                                    ? this.state.childStatusFilter.reduce((p, c) => (p ? p + ', ' + c : c), '')
                                    : 'Version status'
                                }
                                options={['Draft', 'Finalized', 'Approved'].map((value, index) => {
                                  return {
                                    id: index,
                                    value: value,
                                    label: value
                                  }
                                })}
                                multi
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )
                  })}
                </div>

                <div className="col-xs-12 row SystemsView__mainRow">
                  <CollapsableList
                    loadFinished={this.state.loadFinished}
                    className="SystemList"
                    neverCollapse
                    fullCollapsed={this.state.fullCollapsed[0]}
                    sortOrder={this.hasSearchFilter() ? '' : this.state.sortOrder[0]}
                    isTopLevel
                    items={this.getSystems()
                      .map((system, index) => {
                        //console.log("System:", system);
                        let systemApps = this.getApplications(system)
                          .map((app) => {
                            if (this.state.usageFilter[2] || this.state.tagFilter[2] || this.state.search[2])
                              if (app.children.length === 0) return null

                            return {
                              identity: app.identity,
                              children: app.children,
                              title: app.identity.name,
                              label: (
                                <MajorObjectBlock
                                  objectData={app}
                                  type="applications"
                                  className="ApplicationBlock"
                                  showDescription={
                                    this.state.fullCollapsed[2] ||
                                    !app.datasets ||
                                    (app.datasets.length === 0 && app.interfaces.length === 0)
                                      ? 'right'
                                      : 'bottom'
                                  }
                                />
                              ),
                              body: (
                                <CollapsableList
                                  key={index}
                                  fullCollapsed={this.state.fullCollapsed[2]}
                                  neverCollapse={this.state.fullExpanded[2]}
                                  sortOrder={this.hasSearchFilter() ? '' : this.state.sortOrder[2]}
                                  items={app.children}
                                  loadFinished={this.state.loadFinished}
                                  className="DatasetList"
                                />
                              )
                            }
                          })
                          .filter((e) => e !== null)

                        if (
                          this.state.usageFilter[2] ||
                          this.state.tagFilter[2] ||
                          this.state.search[2] ||
                          this.state.usageFilter[1] ||
                          this.state.tagFilter[1] ||
                          this.state.search[1]
                        )
                          if (systemApps.length === 0) return null

                        return {
                          identity: system.identity,
                          children: systemApps,
                          title: system.identity.name,
                          label: (
                            <MajorObjectBlock
                              objectData={system}
                              type="systems"
                              className="SystemBlock"
                              showDescription={
                                this.state.fullCollapsed[1] || !system.applications || system.applications.length === 0
                                  ? 'right'
                                  : 'bottom'
                              }
                            />
                          ),
                          body: (
                            <CollapsableList
                              index={index}
                              fullCollapsed={this.state.fullCollapsed[1]}
                              neverCollapse={this.state.fullExpanded[1]}
                              sortOrder={this.hasSearchFilter() ? '' : this.state.sortOrder[1]}
                              className={
                                'ApplicationList ' +
                                (this.state.fullCollapsed[2] ? 'ApplicationList__descriptionRight' : '')
                              }
                              items={systemApps}
                              loadFinished={this.state.loadFinished}
                            />
                          )
                        }
                      })
                      .filter((e) => e)}
                  />
                </div>
              </div>
            ) : (
              <div className="col-xs-12 SystemsView__mainRowChild">
                {!this.props.appState.systems
                  ? null
                  : this.props.appState.systems
                      .filter((sys) => sys && sys.identity.name !== '')
                      .map((system, index) => (
                        <Matrix
                          loadFinished={this.state.loadFinished}
                          key={index}
                          text={system.identity.name}
                          headerLinkType="systems"
                          headerItem={system}
                          description={system.identity.description ? system.identity.description : ''}
                          showControls
                          fullCollapsable
                          initialVisibleItems={9}
                          parentClass="MatrixSystem"
                          childClass="col-xs-4"
                          items={
                            !system.applications
                              ? []
                              : system.applications.map((app_id, index) => {
                                  const app = this.getApplicationData(app_id)
                                  return {
                                    title: app.identity.name,
                                    body: (
                                      <Matrix
                                        loadFinished={this.state.loadFinished}
                                        text={app.identity.name}
                                        headerLinkType="applications"
                                        headerItem={app}
                                        key={app_id}
                                        index={index}
                                        description={
                                          app.identity.description ? app.identity.description[app.locale] : ''
                                        }
                                        showControls={false}
                                        fullCollapsable={false}
                                        initialVisibleItems={2}
                                        parentClass="MatrixApplication"
                                        childClass=""
                                        icon="dark"
                                        items={
                                          !app.datasets
                                            ? []
                                            : app.datasets.map((ds_id) => {
                                                const ds = this.getDatasetData(ds_id)
                                                return {
                                                  title: ds.identity.name,
                                                  body: (
                                                    <MajorObjectBlock
                                                      key={ds_id}
                                                      type="datasets"
                                                      objectData={ds}
                                                      className="DatasetBlock"
                                                      hasLinks
                                                    />
                                                  )
                                                }
                                              })
                                        }
                                      />
                                    )
                                  }
                                })
                          }
                        />
                      ))}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

SystemsView.propTypes = {
  appState: PropTypes.object,
  userState: PropTypes.object,
  actions: PropTypes.object
}

export default SystemsView
