/**
 * Created by Sasha Berger on 08.03.20.
 *
 * Component to edit reference to topology inside environment.
 * Component render reference to instances of the topology inside environment.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { deepCopy, editableState, getFullUri, pathByType, versionToStr } from '../../helpers/index'
import logger from '../../helpers/logger'
import { getElementMetadata } from '../../resources/lib/metadata'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './DatasetRefDialog.scss'

export class DatasetRefDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      isAsStructure: true
    })
    //logger.info("DatasetRefDialog", this.state, this.props);
  }

  getObjectType() {
    return 'datasetReference'
  }

  /**
   * Init dialog height
   * @param props - set of initialization properties
   */
  InitHeight(props) {
    // Don't need logic here.
    return 445
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/System/datasets/DatasetReference',
      '/organizations/Apdax/systems/DifHub/applications/System/views/DatasetRefDialog',
      null,
      (view) => {
        if (this.props.referenceState) {
          // Let's update element to have local state for instances.
          // For it we need to change metadata for view in instance element.
          let currView = deepCopy(view)
          let dataElement = getElementMetadata(currView, 'layouts')
          if (dataElement && dataElement.control && dataElement.control.value) {
            dataElement.control.value['referenceState'] = this.props.referenceState
          }
          return currView
        }
        return view
      }
    )

    //logger.info("DatasetRefDialog:open", this.state, this.props);
  }

  /**
   * Returns initial object for dialog
   */
  getInitial(props) {
    //logger.info("DatasetRefDialog:getInitial", props, this.state, this.props);
    props = props || this.props
    let datasetReference = props.datasetReference ? deepCopy(props.datasetReference) : {}

    // Compatability issue. Reference can be calculated if not here
    if (datasetReference.identity && datasetReference.identity.id && !datasetReference.reference) {
      const path = getFullUri(this.props.majorObject)
      const pathSplit = path.substring(1).split('/')

      let appPath = pathByType(pathSplit, 'applications')
      if (!appPath) {
        appPath = pathByType(pathSplit, 'systems')
      }

      datasetReference.reference =
        appPath + '/datasets/' + datasetReference.identity.name + '/versions/' + versionToStr(datasetReference.version)
    }

    //logger.info("DatasetRefDialog:getInitial", datasetReference, this.state, this.props);
    return datasetReference
  }

  checkInputErrors(datasetReference) {
    let inputError = ''
    if (!datasetReference.reference) {
      inputError += 'Please select dataset to reference!'
    }
    return inputError
  }

  /**
   * Process event of changes state of structure usage of dataset. When dataset used as
   * Structure it mean fields is empty as all fields used in this case.
   */
  onAsStructureChange(event) {
    console.log('DatasetPublication:onAsStructureChange', event.target.value)
    if (event.target.value) {
      let datasetReference = deepCopy(this.state.datasetReference)
      datasetReference.fieldNames = []

      this.setState(
        {
          isAsStructure: true
        },
        () => {
          this.Change(datasetReference)
        }
      )
    } else {
      this.setState({
        isAsStructure: false
      })
    }
  }

  /**
   * Validate value of changed field
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @return true - change approved
   */
  onValidateChange = (element, field, value, dataset, option) => {
    logger.info('DatasetRefDialog:onValidateChange', { element, field, value, dataset, option }, this.state, this.props)
    if (element === 'dataset' && dataset && option) {
      dataset.identity = option.object.identity
      dataset.version = option.object.version
    }
    return dataset
  }

  renderEditor = (editState) => {
    let datasetReference = this.state.datasetReference
    let isAsStructure =
      this.state.isAsStructure && (!datasetReference.fieldNames || datasetReference.fieldNames.length === 0)

    logger.info('DatasetRefDialog:renderEditor', { editState, datasetReference, isAsStructure }, this.state, this.props)
    //{ this.renderView(editState) }

    return (
      <div className="DatasetRefDialog" onClick={() => this.onCheckDialogHeight('.DatasetRefDialog')}>
        {this.renderElement(editState, 'dataset')}
        {this.renderElement(editableState.BROWSABLE, 'description')}

        <div className="DatasetRefDialog__row">
          {editState > editableState.EDITABLE ? (
            <div>
              <EditableEntity
                dataType={{ name: 'boolean' }}
                isEditable
                inEditMode
                data={isAsStructure}
                dataProps={{ onChange: this.onAsStructureChange.bind(this) }}
              />
              <div className="DatasetRefDialog__label">As Structure</div>
            </div>
          ) : (
            <div>{isAsStructure ? <div className="DatasetRefDialog__label">As Structure</div> : null}</div>
          )}
        </div>

        {isAsStructure ? null : this.renderElement(editState, 'layouts')}
        {isAsStructure ? null : this.renderElement(editState, 'fields')}
      </div>
    )
  }
}

DatasetRefDialog.propTypes = {
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  topologyReference: PropTypes.object,
  referenceState: PropTypes.string, // Owerride state of reference from metadaata.
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
