/**
 * Created by Sasha Berger on 05.02.19.
 *
 * Control to select subset of the options and create list of selected options by name.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { dequal } from 'dequal'

import { editableState, fieldState, getFullUriVersioned, nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import iLeft from '../../resources/images/left-arrow-2x.png'
import iRight from '../../resources/images/right-arrow-2x.png'
import { DataSelectorTable } from './DataSelectorTable'
import { DatasetViewer } from './DatasetViewer'
import './FieldSelector.scss'

const ITEM_HEIGHT = 50

export class FieldSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      srcFields: [], // source fields
      dstFields: [], // destination fields
      fieldsState: {} // need for set hidden or selected fields in dataset
    }

    this.dsttableRef = React.createRef()
  }

  componentDidMount() {
    // Find place needed.
    let newHeight = this.props.items ? (this.props.items.length > 8 ? 8 : this.props.items.length) * ITEM_HEIGHT : 0

    // Adjust space we need for data of dialog.
    if (this.props.onAdjust) {
      //logger.info("DataSelector:componentDidMount", newHeight, this.props);
      this.props.onAdjust(0, newHeight)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let change = dequal(nextProps, this.props) || !dequal(nextState, this.state)
     
    // Find place needed.
    let oldHeight = this.props.items ? (this.props.items.length > 8 ? 8 : this.props.items.length) * ITEM_HEIGHT : 0
    let newHeight = nextProps.items ? (nextProps.items.length > 8 ? 8 : nextProps.items.length) * ITEM_HEIGHT : 0

    // Adjust space we need for data of dialog.
    if (this.props.onAdjust && change) {
      //logger.info("DataSelector:shouldComponentUpdate", oldHeight, newHeight, nextProps, this.props);
      this.props.onAdjust(0, newHeight - oldHeight)
    }
    return change
  }

  onFieldClick = (editState, mode, object, path, field, cell, selected) => {
    // Ignore click on expend button as it is cell 0.
    if (cell === 0) {
      return
    }

    const newSelected = selected.map((item) => item.split('/')[1])
    this.setState({
      srcFields: newSelected
    })
    //logger.log("FieldSelector:onFieldClick", { editState, mode, object, path, field, cell, selected, newSelected}, this.state, this.props);
  }

  dstFieldSelected(values) {
    // logger.log("dstFieldSelected", values);
    this.setState({
      dstFields: this.props.items.filter((item) => values.indexOf(item.id) !== -1)
    })
  }

  onAddField(key) {
    if (this.state.srcFields.length === 0) {
      return
    }

    let newSelected = this.props.selected.map((item) => item.label)
    newSelected = newSelected.concat(this.state.srcFields).sort()

    let newSelectedFiltered = []
    //logger.info("DataSelector:onAddField", {key, newSelected}, this.state, this.props);

    let currRoot = ''
    let currList = []
    for (let item of newSelected) {
      // Split new item.
      let currPath = item.split('.')
      //logger.info("DataSelector:onAddField:PATH", { key, newSelected, newSelectedFiltered, currPath, currList }, this.state, this.props);

      // If we have new root, we clean current list and start over
      if (currPath[0] !== currRoot) {
        // Add collected items of current root.
        newSelectedFiltered = newSelectedFiltered.concat(currList)

        // Move to new root and list.
        currRoot = currPath[0]
        currList = [item]
      } else {
        // We in current root, let's check we don't have item
        // covered by current root list already
        let parent = false
        for (let i = currList.length - 1; i >= 0; i--) {
          // Remove duplicates
          if (nonCSCompare(item, currList[i])) {
            parent = true
            break
          }

          // We found item which parent of this item.
          if (item.startsWith(currList[i] + '.')) {
            parent = true
            break
          }
        }

        if (!parent) {
          currList.push(item)
        }
        //logger.info("DataSelector:onAddField:LIST", { key, newSelected, newSelectedFiltered, currList }, this.state, this.props);
      }
    }

    // need to add last one.
    newSelectedFiltered = newSelectedFiltered.concat(currList)
    //logger.info("DataSelector:onAddField:ENDLIST", { key, newSelected, newSelectedFiltered, currList }, this.state, this.props);

    const newSelectedItem = newSelectedFiltered.map((item, index) => {
      return {
        id: index,
        label: item,
        value: item
      }
    })
    logger.info(
      'DataSelector:onAddField:RESULT',
      { key, newSelected, newSelectedFiltered, newSelectedItem },
      this.state,
      this.props
    )

    this.setState(
      {
        selected: newSelectedItem
      },
      () => {
        if (this.props.onSelect) {
          this.props.onSelect(newSelectedItem)
        }
        this.setFieldsState(newSelectedFiltered, fieldState.HIDDEN)
        this.selectNone()
      }
    )
  }

  onDeleteField(key) {
    if (this.state.dstFields.length === 0) {
      return
    }

    let newSelected = this.props.selected.slice()

    const notSelected = newSelected
      .filter((item) => this.state.dstFields.findIndex((el) => item.id === el.id) !== -1)
      .map((field) => field.label)

    newSelected = newSelected.filter((item) => this.state.dstFields.findIndex((el) => item.id === el.id) === -1)

    // logger.info("DataSelector:onDeleteField", key, this.state.dstField, newSelected, notSelected);

    this.setState({ selected: newSelected }, () => {
      if (this.props.onSelect) {
        this.props.onSelect(newSelected)
      }
      this.setFieldsState(notSelected, 0)
      this.selectNone()
    })
  }

  setFieldsState = (fields, state) => {
    let newFieldState = {}
    fields.forEach((field) => {
      newFieldState[field] = state
    })
    logger.info('DataSelector:setFieldsState', { fields, state, newFieldState }, this.state, this.props)
    this.setState({ fieldsState: newFieldState })
  }

  selectNone = () => {
    if (this.dsttableRef.current) this.dsttableRef.current.selectNone()

    // Remove selected fields from lists.
    this.setState({
      srcFields: [],
      dstFields: []
    })
  }

  render() {
    let items = this.props.items || []
    let selected = this.props.selected || []

    let srcData = items.filter((item) => selected.findIndex((el) => nonCSCompare(item.label, el.label)) === -1)
    let dstData = selected

    let reference = getFullUriVersioned(this.props.dataset)
    let components = srcData.map((item) => {
      return item.label
    })
    let editState = this.props.editable ? editableState.EDITED : editableState.BROWSABLE
    //logger.info("FieldSelector:render", { items, selected, dstData, reference, components, editState, srcData }, this.state, this.props);

    return (
      <div className="DataSelector" tabIndex="0" onBlur={this.onBlur}>
        <div className="row">
          {this.props.editable ? (
            <div className="col-xs-5">
              <DatasetViewer
                mode="input"
                object={this.props.dataset}
                dataset={this.props.dataset}
                editState={editState}
                reference={reference}
                components={components}
                selectMode={'multiselect'}
                fieldsState={this.state.fieldsState}
                onAdjust={this.props.onAdjust}
                onFieldClick={this.onFieldClick}
              />
            </div>
          ) : null}
          {this.props.editable ? (
            <div className="col-xs-2 ">
              <div className="DataSelector__divButtons">
                <div className="DataSelector__arrowButton1" onClick={this.onAddField.bind(this)}>
                  <img src={iRight} className="DataSelector__arrow" alt="arrow icon" />
                  <div className="DataSelector__arrowButton1__Text">Add</div>
                </div>
                <div className="DataSelector__arrowButton2" onClick={this.onDeleteField.bind(this)}>
                  <img src={iLeft} className="DataSelector__arrow" alt="arrow icon" />
                  <div className="DataSelector__arrowButton2__Text">Remove</div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-xs-9">
            <DataSelectorTable
              ref={this.dsttableRef}
              captions={this.props.captions}
              columns={this.props.columns}
              data={dstData}
              onSelect={this.dstFieldSelected.bind(this)}
            />
          </div>
        </div>
      </div>
    )
  }
}

FieldSelector.propTypes = {
  captions: PropTypes.array, // Captions to show for columns in table
  columns: PropTypes.array, // Columns data to show in table
  editable: PropTypes.bool, // Is editing selection available
  majorObject: PropTypes.object,
  items: PropTypes.array, // Arrays of options to show
  dataset: PropTypes.array, // Arrays of datasets
  selected: PropTypes.array, // Arrays of ids to show as selected
  onSelect: PropTypes.func // Call function when item selected
}
