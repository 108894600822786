/**
 * Created by Sasha Berger on 05.02.19.
 *
 * Control to render table of selector options.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ScrollArea from 'react-scrollbar'
import { deepGet } from '../../helpers/index'
import { SimpleTooltip } from '../SimpleTooltip/SimpleTooltip'
import './DataSelectorTable.scss'

export class DataSelectorTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      values: [],
      prevClickedValue: false,
      search: '',
      searchVisible: false
    }
  }

  onSearch = (event) => {
    this.setState({
      search: event.target.value
    })
  }

  selectNone = () => {
    // logger.info("DataSelectorTable::selectNone");
    this.setState(
      {
        values: []
      },
      () => {
        if (this.props.onSelect) this.props.onSelect(this.state.values)
      }
    )
  }

  selectAll = () => {
    this.setState(
      {
        values: this.props.data
          .filter((row) => {
            //logger.info("DataSelectorTable:render:FILTER", row, this.state.search, (row.label + '').toLowerCase(), this.state.search.toLowerCase(), (row.label + '').toLowerCase().indexOf(this.state.search.toLowerCase()));
            return !this.state.search || (row.label + '').toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
          })
          .map((row, rowIndex) => row.id)
      },
      () => {
        if (this.props.onSelect) this.props.onSelect(this.state.values)
      }
    )
  }

  toggleSelectAll = () => {
    if (this.state.values.length === this.props.data.length) {
      this.selectNone()
    } else {
      this.selectAll()
    }
  }

  toggleSearchVisible = () => {
    this.setState({ searchVisible: !this.state.searchVisible })
  }

  select = (item) => (event) => {
    let newValues = null

    if (event.shiftKey && this.state.prevClickedValue !== false) {
      const rowIds = this.props.data.map((row) => row.id)
      const firstId = Math.min(rowIds.indexOf(item), rowIds.indexOf(this.state.prevClickedValue))
      const lastId = Math.max(rowIds.indexOf(item), rowIds.indexOf(this.state.prevClickedValue))
      let valuesToAdd = []
      for (let i = firstId; i <= lastId; i++) valuesToAdd.push(rowIds[i])

      if (this.props.isSingleSelect) {
        newValues = [valuesToAdd[0]]
      } else {
        newValues = this.state.values.filter((val) => valuesToAdd.indexOf(val) !== -1).concat(valuesToAdd)
      }
    } else {
      if (this.props.isSingleSelect) {
        newValues = [item]
      } else {
        if (this.state.values.indexOf(item) !== -1) {
          newValues = this.state.values.filter((val) => val !== item)
        } else {
          newValues = this.state.values.concat(item)
        }
      }
    }
    //logger.info("DataSelectorTableselect", event.target, newValues);

    this.setState(
      {
        values: newValues,
        prevClickedValue: item
      },
      () => {
        if (this.props.onSelect) this.props.onSelect(newValues, event)
      }
    )

    event.preventDefault()
    event.stopPropagation()
  }

  renderColumnHeader = (column, index) => {
    if (index === 1) {
      return (
        <div>
          <div className="DataSelectorTable__CellHeaderInner">{column}</div>
          <div
            className={
              'DataSelectorTable__search ' + (this.state.searchVisible ? 'DataSelectorTable__search__active' : '')
            }
            onClick={this.toggleSearchVisible}
          ></div>
          <div
            className={
              'DataSelectorTable__selectAll ' +
              (this.state.values.length > 0 && this.state.values.length === this.props.data.length
                ? 'DataSelectorTable__selectAll__active'
                : '')
            }
            onClick={this.toggleSelectAll}
          ></div>
        </div>
      )
    } else {
      return column
    }
  }

  renderColumnValue = (row, column, index) => {
    let value = ''
    if (!column) {
      // Default value for columns.
      value = index === 0 ? row.id + 1 : index === 1 ? row.label : ''
    } else if (typeof column === 'function') {
      // Use fanction to calculate column value.
      value = column(row)
    } else {
      // Get value from row structure by path.
      value = deepGet(row, column)
    }

    return (
      <div className={'DataSelectorTable__Cell DataSelectorTable__Cell__' + index} key={index}>
        {value}
      </div>
    )
  }

  render() {
    let captions = this.props.captions
    let columns = this.props.columns
    let values =
      this.state.values && this.state.values.length > 0
        ? this.state.values
        : this.props.selected && this.props.selected.length > 0
        ? this.props.selected
        : []
    let hvalues = this.props.highlighted && this.props.highlighted.length > 0 ? this.props.highlighted : []
    //logger.info("DataSelectorTable:render", columns, values, this.state, this.props);

    return (
      <div className="DataSelectorTable">
        <div className="DataSelectorTable__Column1"></div>
        <div className="DataSelectorTable__Column2"></div>
        {!captions ? null : (
          <div className="DataSelectorTableInner">
            <div className="DataSelectorTable__Row DataSelectorTable__RowHeader">
              {captions.map((column, index) => (
                <div
                  className={'DataSelectorTable__Cell DataSelectorTable__CellHeader DataSelectorTable__Cell__' + index}
                  key={index}
                >
                  {this.renderColumnHeader(column, index)}
                </div>
              ))}
            </div>
          </div>
        )}
        <ScrollArea ref="selector-table-scroll" horizontal={false}>
          {this.state.searchVisible ? (
            <div className="DataSelectorTable__buttonRow">
              <input type="text" value={this.state.search} onChange={this.onSearch} placeholder="Search" />
            </div>
          ) : null}
          <div className="DataSelectorTableInner">
            {this.props.data
              .filter((row) => {
                //logger.info("DataSelectorTable:render:FILTER", row, this.state.search, (row.label + '').toLowerCase(), this.state.search.toLowerCase(), (row.label + '').toLowerCase().indexOf(this.state.search.toLowerCase()));
                return (
                  !this.state.search || (row.label + '').toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
                )
              })
              .map((row, rowIndex) => {
                //logger.info("DataSelectorTable:render:ROW", row, rowIndex);
                let value = row.id
                let tip = row.description
                  ? row.description
                  : row.object && row.object.identity && row.object.identity.description
                  ? row.object.identity.description
                  : ''
                return (
                  <div
                    className={
                      'DataSelectorTable__Row' +
                      (values.indexOf(value) !== -1 ? ' DataSelectorTable__Row__Selected' : '') +
                      (hvalues.indexOf(value) !== -1 ? ' DataSelectorTable__Row__Highlighted' : '')
                    }
                    key={rowIndex}
                    onClick={this.select(value)}
                  >
                    {columns.map((column, index) => {
                      return this.renderColumnValue(row, column, index)
                    })}
                    <div className="DataSelectorTable__RowTip">
                      <SimpleTooltip title={tip}>
                        <div className="DataSelectorTable__infoIcon"></div>
                      </SimpleTooltip>
                    </div>
                  </div>
                )
              })}
          </div>
        </ScrollArea>
      </div>
    )
  }
}

DataSelectorTable.propTypes = {
  captions: PropTypes.array, // Captions to show for columns in table
  columns: PropTypes.array, // Columns data to show in table
  isSingleSelect: PropTypes.bool, // Mode when only one item can be selected
  data: PropTypes.array, // Arrays of options to show
  selected: PropTypes.array, // Arrays of ids to show as selected
  highlighted: PropTypes.array, // Arrays of ids to show as highlighted
  onSelect: PropTypes.func // Call function when item selected
}
