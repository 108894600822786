import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { MajorObjectLink } from '../MajorObjectLink/MajorObjectLink'
import { shortnames } from '../../constants/index'

import './HeaderPath.scss'
import { track } from '../../helpers/analytics'

import arrowLeft from '../../resources/images/arrow_blue_left-2х.png'

export class HeaderPath extends Component {
  constructor(props) {
    super(props)
    this.state = {
      over: false
    }
  }

  over() {
    this.setState({
      over: true
    })
  }

  out() {
    this.setState({
      over: false
    })
  }

  renderBack = () => {
    if (this.props.path.length > 1) {
      const backPath = '/view' + this.props.path[this.props.path.length - 2].path
      return (
        <Link to={backPath} onClick={() => track('', 'backpath')}>
          <img src={arrowLeft} alt="" />
        </Link>
      )
    }

    return null
  }

  onTrack = () => {
    //console.log("HeaderPath::onTrack");
    track('backpath')
  }

  render() {
    const path = this.props.path
    const hasBackButton = path.length > 1

    //<span>{elem.name} {i < path.length - 1 ? <img src={dotPicture} className="dot" /> : ""}</span>
    return (
      <div className="HeaderPath__outer" onMouseOver={this.over.bind(this)} onMouseOut={this.out.bind(this)}>
        <div className="row">
          <div
            className={
              'HeaderPath' + (this.state.over ? ' HeaderPath__show' : '') + (hasBackButton ? ' HeaderPath_hasBack' : '')
            }
          >
            <div className="HeaderPath__back">{this.renderBack()}</div>
            <div className="HeaderPath__uri">
              {path.map((elem, i) => [
                '/',
                shortnames[elem.type] ? shortnames[elem.type] : elem.type,
                '/',
                <MajorObjectLink onTrack={this.onTrack} type={elem.type} object={elem} key={i}>
                  {elem.name}
                </MajorObjectLink>
              ])}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

HeaderPath.propTypes = {
  path: PropTypes.array
}
