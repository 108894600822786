/* eslint-disable jsx-a11y/iframe-has-title */
/**
 * Created by sashab on 15.05.2020.
 *
 * Component to load external content and fill uri for content.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DropZone from 'react-dropzone'
import { fetchDataUrlImmediate } from '../../helpers/api'
import { checkResourceUrl, deepCopy, editableState, getFullUri, itemPosition, nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import iLoadDark from '../../resources/images/Load-pic-2x.png'
import iLoad from '../../resources/images/Load-pic-deep-blue-2x.png'
import './ContentLoader.scss'

export class ContentLoader extends Component {
  constructor(props) {
    super(props)

    let content = this.props.content || {}
    let image = this.getContent(content)

    // If we have image, let's add space
    if (image && this.props.onAdjust) {
      this.props.onAdjust(0, 300)
    }

    this.state = {
      contentId: new Date().getTime(),
      content: content,
      contentChanged: false, // Indicate content change in component
      contentPreview: image // Indicate we have file to preview
    }

    logger.info('ContentLoader:constructor', image, this.state, this.props)
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(newProps) {
    //let object = JSON.stringify(this.state.content);
    //let origin = JSON.stringify(newProps.content || {});

    let fileURI = this.state.content.fileURI
    let content = newProps.content
    let image = null

    if (content && fileURI !== content.fileURI) {
      logger.info('ContentLoader:componentWillReceiveProps', newProps, this.state, this.props)
      // Let's load data for preview.
      image = this.getContent(content)

      // If we have image and not have it before, let's add space
      if (!this.state.contentPreview && image && this.props.onAdjust) {
        this.props.onAdjust(0, 300)
      }

      // Update current content state.
      this.setState({
        content: content,
        contentChanged: false,
        contentPreview: image
      })
    }
  }

  /**
   * Get content document, image or link
   * @param files - list of files selected.
   * @return content image
   */
  getContent(content) {
    let image = null
    logger.info('ContentLoader:getContent', content, this.state, this.props)

    // No content no image.
    if (!content) {
      return null
    }

    // Drop image file into element
    if (content.fileURI) {
      if (content.fileData) {
        // We already have image
        image = URL.createObjectURL(content.fileData)
      } else if (content.fileURI.startsWith('http://') || content.fileURI.startsWith('https://') === 0) {
        // This is external URI.
        image = content.fileURI
      } else if (content.fileData !== null) {
        // This is real image
        let uri = content.fileURI
        if (this.props.contentUri) {
          // We have content URI we use as roor for all contents.
          if (this.props.contentUri.endsWith('/')) {
            if (uri.startsWith('/')) {
              uri = this.props.contentUri + uri.substring(1)
            } else {
              uri = this.props.contentUri + uri
            }
          } else {
            if (uri.startsWith('/')) {
              uri = this.props.contentUri + uri
            } else {
              uri = this.props.contentUri + '/' + uri
            }
          }
        }
        image = fetchDataUrlImmediate(checkResourceUrl(uri))
      }
    }
    return image
  }

  /**
   * Add content from dialog
   * @return
   */
  addContent = () => {
    let dialog = this.props.parentDialog
    let loader = null
    logger.info('ContentLoader:addContent', dialog, this.state, this.props)
    if (
      dialog &&
      dialog.refs &&
      dialog.refs.content_loader &&
      dialog.refs.content_loader.refs &&
      dialog.refs.content_loader.refs.dropzone
    ) {
      loader = dialog.refs.content_loader
    } else if (
      dialog &&
      dialog.refs &&
      dialog.refs[this.props.class] &&
      dialog.refs[this.props.class].refs &&
      dialog.refs[this.props.class].refs.content_loader
    ) {
      loader = dialog.refs[this.props.class].refs.content_loader
    }

    if (loader && loader.refs && loader.refs.dropzone) {
      loader.refs.dropzone.open()
    } else {
      logger.warn('ContentLoader:addContent', dialog, loader, this.state, this.props)
    }
  }

  /**
   * Remove content
   */
  deleteContent = () => {
    let content = deepCopy(this.props.content || {})
    content.fileData = null

    if (!content.identity || !content.identity.description || content.identity.description.startsWith('$')) {
      if (!content.identity) {
        content.identity = { description: '$' + this.state.contentId }
      } else if (!content.identity.description || content.identity.description.startsWith('$'))
        content.identity.description = '$' + this.state.contentId
    }

    if (this.props.onAdjust) {
      this.props.onAdjust(0, -300)
    }

    this.setState(
      {
        content: content,
        contentChanged: true,
        contentPreview: null
      },
      () => {
        //logger.info("ContentLoader:deleteContent", content, this.state);
        this.props.onChange(content)
      }
    )
  }

  /**
   * Select file from drop box
   * @param files - list of files selected.
   * @return
   */
  dropFile(files) {
    // Drop image file into element
    logger.info('ContentLoader:dropFile', files, this.state, this.props)
    if (!files || files.length < 0) {
      logger.warn('ContentLoader:dropFile no file selected', this.state, this.props)
      return 0
    }
    let content = deepCopy(this.props.content || {})
    let name =
      this.state.content && this.state.content.identity && this.state.content.identity.name
        ? this.state.content.identity.name
        : files[0].path
    let fileName =
      this.state.content && this.state.content.fileName ? this.state.content.fileName : name || this.state.contentId
    let fileURI =
      !this.props.contentUri && this.props.majorObject
        ? getFullUri(this.props.majorObject) + '/' + fileName
        : '/' + fileName

    // Update content
    content.fileURI = fileURI
    content.fileData = files[0]
    content.fileName = fileName

    if (!content.identity) {
      content.identity = { name: name }
    } else if (!content.identity.name) content.identity.name = name

    if (!content.identity || !content.identity.description || content.identity.description.startsWith('$')) {
      if (!content.identity) {
        content.identity = { description: '$' + this.state.contentId }
      } else if (!content.identity.description || content.identity.description.startsWith('$'))
        content.identity.description = '$' + this.state.contentId
    }

    if (!this.state.contentPreview && this.props.onAdjust) {
      this.props.onAdjust(0, 300)
    }

    this.setState(
      {
        content: content,
        contentChanged: true,
        contentPreview: URL.createObjectURL(files[0])
      },
      () => {
        //logger.info("ContentLoader:dropFile", content, files, this.state);
        this.props.onChange(content)
      }
    )
  }

  /**
   * Render control of the content loader
   * @param editState - state of editing.
   * @param content - current content.
   * @return
   */
  renderControl(editState, content) {
    // State of execution for component
    //logger.info("ContentLoader:renderControl", editState, content, this.state, this.props);
    // We show controls only when editable.
    if (editState < editableState.EDITING) {
      return <div className="ContentLoader__loadIcon"></div>
    }

    return (
      <div>
        {this.state.contentPreview ? (
          <div
            className={'ContentLoader__loadIcon ' + (this.props.dark ? 'ContentLoader__loadIconDark' : '')}
            onClick={this.deleteContent.bind(this)}
          >
            <img src={this.props.dark ? iLoadDark : iLoad} alt="icon" />
            Remove
          </div>
        ) : (
          <div
            className={'ContentLoader__loadIcon ' + (this.props.dark ? 'ContentLoader__loadIconDark' : '')}
            onClick={this.addContent.bind(this)}
          >
            <img src={this.props.dark ? iLoadDark : iLoad} alt="icon" />
            Load
          </div>
        )}
      </div>
    )
  }

  /**
   * Render content of the content loader
   * @param editState - state of editing.
   * @param content - current content.
   * @return
   */
  renderContent(editState, content) {
    // State of execution for component
    //logger.info("ContentLoader:renderContent", editState, content, this.state, this.props);
    let image = this.state.contentPreview
    return (
      <div onClick={this.addContent.bind(this)}>
        {!image ? (
          <div className="ContentLoader__emptyFrame">No content</div>
        ) : content && nonCSCompare(content.type, 'Image') ? (
          <img className="ContentLoader__previewFrame" src={image} alt="icon" />
        ) : (
          <iframe className="ContentLoader__previewFrame" src={image} frameBorder="0" allowFullScreen></iframe>
        )}
      </div>
    )
  }

  render() {
    // State of execution for component
    let isEditable = this.props.isEditable
    let content = this.state.content
    //logger.info("ContentLoader:render", isEditable, content, this.state, this.props);

    return (
      <div className="ContentLoader" id={'content_loader_' + this.state.contentId}>
        {this.props.controlPosition === itemPosition.TOP ? this.renderControl(isEditable, content) : null}
        {this.renderContent(isEditable, content)}
        {this.props.controlPosition === itemPosition.BOTTOM ? this.renderControl(isEditable, content) : null}

        {isEditable > editableState.EDITABLE ? (
          <div>
            <DropZone ref="dropzone" className="ContentLoader__DropZone" onDrop={this.dropFile.bind(this)}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div {...getRootProps()} className={`dropzone${isDragActive ? ' dropzone--isActive' : ''}`}>
                  <input {...getInputProps()} />
                  {isDragActive ? <p>...</p> : <p></p>}
                </div>
              )}
            </DropZone>
          </div>
        ) : null}
      </div>
    )
  }
}

ContentLoader.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  class: PropTypes.string, // Class information for data dialog editor components.
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  controlPosition: PropTypes.number, // Position of controls against content
  majorObject: PropTypes.object.isRequired, // Mojor object content belong too
  parentDialog: PropTypes.object, // Dialog handled content loading.
  content: PropTypes.object, // Data of content loaded.
  onChange: PropTypes.func, // This is called when data change in component
  onAdjust: PropTypes.func // Adjust parent dialog sige to feet changes.
}
