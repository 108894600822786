import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import { InviteDialog } from '../InviteDialog/InviteDialog'
import { RoleDialog } from '../RoleDialog/RoleDialog'
import { UserDialog } from '../RoleDialog/UserDialog'
import { Person } from '../Person/Person'
import { FilterableContent } from '../FilterableContent/FilterableContent'
//import Table from '../Table/Table';
import { TypedTable } from '../NewTable/TypedTable'
import { columnsToType, objectNameFromPathByType } from '../../helpers'
import { EditorDialogButton } from '../EditorDialog/EditorDialogButton'
import { API } from '../../helpers/api'
import { getUserPhotoUrl, datasetToObjectArray } from '../../helpers'
import { fetchDataUrlImmediate } from '../../helpers/api'
import { Loader } from '../Loader/Loader'
import { editableState } from '../../helpers/index'
import { EditorDialog } from '../EditorDialog/EditorDialog'

import '../../styles/ObjectUserList/ObjectUserList.scss'
import { getFullUri } from '../../helpers/index'
import logger from '../../helpers/logger'

import iArrowDownCloseDark from '../../resources/images/v-down-2x.png'
import iTeam from '../../resources/images/team-2x.png'
import iTeamBlue from '../../resources/images/team-blue-2x.png'
import iTeamDark from '../../resources/images/team-dark-blue-2x.png'
import iWarning from '../../resources/images/warning_2x.png'
import { getTailButtonLabel } from '../../helpers/helperComponents'

export class ObjectUserList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModal: false,
      inEditMode: false,
      showAll: false,
      showArrow: false,
      objectUsers: props.objectUsers.map((user) => {
        return {
          name: user.user.name,
          role: user.role,
          email: '',
          id: user.user.id
        }
      }),
      isDeleting: false,
      isAdding: false,
      isInviting: false,
      isChanged: false,
      deletingUser: false,
      email: '',
      message: '',
      roles: []
    }
    this.bindedUpdate = this.forceUpdate.bind(this)

    this.rootRef = React.createRef()
  }

  componentDidMount() {
    setTimeout(() => {
      this.checkWidth()
    }, 0)
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      objectUsers: props.objectUsers.map((user) => {
        return {
          name: user.user.name,
          role: user.role,
          email: '',
          id: user.user.id,
          profile: user.profile
        }
      })
    })
  }

  componentDidUpdate() {
    this.checkWidth()
  }

  edit = () => {
    logger.log(
      'ObjectUserList::mount',
      this.props.object,
      objectNameFromPathByType(getFullUri(this.props.object), 'organizations')
    )
    API.organizations(objectNameFromPathByType(getFullUri(this.props.object), 'organizations'))
      .datasets('Role')
      .get()
      .then((dataset) => {
        logger.log('ObjectUserList::get roles', dataset)
        this.setState({ roles: datasetToObjectArray(dataset) })
      })

    this.setState({
      isModal: true,
      editingIndex: -1,
      isDeleting: false,
      isAdding: false,
      isInviting: false,
      isChanged: false,
      deletingUser: false
    })
  }

  closeModal = () => {
    this.setState({
      isModal: false,
      role: '',
      objectUsers: this.props.objectUsers.map((user) => {
        return {
          name: user.user.name,
          role: user.role,
          email: '',
          id: user.user.id
        }
      })
    })
  }

  cancelAndClose = () => {
    this.setState({
      isModal: false,
      role: '',
      objectUsers: this.props.objectUsers,
      isChanged: false,
      inEditMode: false
    })
  }

  onAdd = () => {
    this.setState({
      isAdding: !this.state.isAdding,
      role: this.state.isAdding ? this.state.role : 'Analyst',
      selectedUser: this.state.isAdding ? this.state.selectedUser : null,
      editingUserName: ''
    })
    return false // cancel default Table adding procedure
  }

  onAddInvite = () => {
    this.setState({
      isInviting: !this.state.isInviting,
      role: 'Analyst',
      selectedUser: null,
      editingUserName: ''
    })
    return false // cancel default Table adding procedure
  }

  onDelete = (obj) => {
    this.setState({ deletingUser: obj })
  }

  onRealDelete = (obj) => {
    //console.log("onDelete",index,obj);
    this.props.onDeleteUser(obj.user)
    this.setState({ deletingUser: false })
  }

  onCloseAdd = () => {
    this.setState({
      isAdding: false,
      role: ''
    })
  }

  changeRole = (role, close, onSent) => {
    // Change user role.
    const newUser = {
      user: this.state.selectedUser.user,
      role: role,
      type: 'Org',
      profile: this.state.selectedUser.profile,
      history: {
        completion: []
      }
    }

    if (this.props.onUserRoleChange) this.props.onUserRoleChange(newUser, close, onSent)

    this.setState({
      isInviting: false
    })
  }

  addUser = (user, role, close, onSent) => {
    const newUser = {
      user: user,
      role: role,
      type: 'Org',
      profile: user.profile,
      history: {
        completion: []
      }
    }

    if (this.props.onAddUser) this.props.onAddUser(newUser, close, onSent)

    this.setState({
      isInviting: false
    })
  }

  inviteUser = () => {
    this.props.onInviteUser(this.state.email, this.state.message, this.state.role)
    this.setState({
      isAdding: false,
      isInviting: false,
      role: ''
    })
  }

  checkWidth() {
    try {
      if (this.state.showAll) return
      let myNode = this.rootRef.current
      if (!myNode) return

      let nodes = [].slice.call(myNode.getElementsByClassName('ObjectUserList__left')) // HTMLCollection to array convertion

      let showArrow = nodes.filter((node) => node.scrollWidth > node.clientWidth).length > 0

      if (this.state.showArrow !== showArrow) this.setState({ showArrow: showArrow })
    } catch (e) {
      // ignore
    }
  }

  saveChanges = (new_users) => {
    if (!new_users) new_users = this.state.objectUsers
    if (this.props.onSave) this.props.onSave(new_users)
    this.setState({
      objectUsers: new_users,
      isChanged: false,
      inEditMode: false /*, isEditing: false*/
    })
  }

  onTableEdit = (data) => {
    this.setState({
      objectUsers: data,
      isChanged: true
    })
    // eslint-disable-next-line array-callback-return
    data.map((user, index) => {
      const oldRole = this.props.objectUsers.filter((ou) => ou.profile.identity.id === user.profile.identity.id)[0].role
      // //console.log("new data", user, "oldRole", oldRole);
      if (user.role !== oldRole) {
        // //console.log("need to save user role for ", user);
        //idAPI.users(user.profile.identity.id).put({role: user.role});
        this.props.onUserRoleChange(user)
      }
    })
  }

  toggleEditMode() {
    this.setState({
      inEditMode: !this.state.inEditMode
    })
  }

  userSort = (a, b) => {
    if (a.profile.firstname + ' ' + a.profile.lastname > b.profile.firstname + ' ' + b.profile.lastname) return 1
    else if (a.profile.firstname + ' ' + a.profile.lastname < b.profile.firstname + ' ' + b.profile.lastname) return -1
    return 0
  }

  isOrganization = () => {
    return this.props.object.type === 'organization'
  }

  isRootOrganization = () => {
    //console.log("isRootOrg",this.props.object.type === "organization" && (!this.props.object.object.parent || !this.props.object.object.parent.id));
    // organization and not department
    return (
      this.props.object.type === 'organization' &&
      (!this.props.object.object.parent || !this.props.object.object.parent.id)
    )
  }

  onRowClick = (row) => {
    const id = row.id
    const selectedUser = this.props.objectUsers.filter((u) => u.user.id === id)[0]
    //console.log("onRowClick", id, column, type, value, selectedUser);
    this.setState({
      editingUserName: selectedUser.profile.firstname + ' ' + selectedUser.profile.lastname,
      selectedUser: selectedUser,
      isAdding: true,
      role: selectedUser.role
    })
  }

  getUsersData() {
    return this.props.objectUsers.sort(this.userSort).map((u) => {
      // User name, alias and email
      const resu = Object.assign({}, u)
      const strName =
        resu.profile && (resu.profile.firstname || resu.profile.lastname)
          ? resu.profile.firstname + ' ' + resu.profile.lastname
          : 'Anonymous'

      resu.alias = (
        <span>
          {strName}
          <br />
          <Person user={u} />
        </span>
      )

      resu.email = resu.profile.email ? resu.profile.email : resu.profile.identity.name

      // Define status of user access based on completion
      resu.status = 'Pending'
      let n = resu.history.completions ? resu.history.completions.length : 0
      //console.log("getUsersData", n, resu.history);
      for (let i = 0; i < n; i++) {
        const completion = resu.history.completions[i]
        if (completion.status === 'Approved' && completion.completed) {
          resu.status = 'Approved'
          break
        }
        if (completion.status === 'Finalized' && !completion.completed) {
          resu.status = 'Declined'
          break
        }
      }

      // User id
      resu.id = resu.profile.identity.id
      //resu.alias = {name: resu.alias, nameData: strName, picture: fetchDataUrlImmediate(getUserPhotoUrl(resu.id), this.forceUpdate.bind(this))};
      resu.alias = {
        name: resu.alias,
        nameData: strName,
        picture: !resu.profile.photo ? fetchDataUrlImmediate(getUserPhotoUrl(resu.id), this.bindedUpdate) : null
      }
      return resu
    })
  }

  renderDeleteConfirmWindow() {
    if (this.state.deletingUser) {
      logger.log(this.state.deletingUser)
      let inner = ''
      if (this.state.deletingUser) {
        inner =
          this.state.deletingUser.user && this.state.deletingUser.user.name ? (
            <div className="OrganizationList__deleteMessage">
              <p>
                <img src={iWarning} alt="icon" />
              </p>
              <p>
                Do you really want to delete {this.state.deletingUser.role + ' ' + this.state.deletingUser.user.name}?
              </p>
            </div>
          ) : (
            <Loader />
          )
      }

      return (
        <ModalWindow
          header={'Delete user'}
          canClose
          isOpen
          level={1}
          className="OrganizationList__deleteModal"
          onClose={() => this.setState({ deletingUser: false })}
          footer={
            <div className="OrganizationList__deleteButtons">
              <button className="btn_big" onClick={() => this.setState({ deletingUser: false })}>
                No
              </button>
              <button className="btn_big" onClick={() => this.onRealDelete(this.state.deletingUser)}>
                Yes
              </button>
            </div>
          }
        >
          {inner}
        </ModalWindow>
      )
    } else {
      return null
    }
  }

  renderEditor = (editState) => {
    let bottomButtons = []
    let topButtons = []
    if (editState >= editableState.EDITING) {
      if (this.isOrganization()) {
        // can invite
        bottomButtons.push({
          label: '+ Invite user',
          onClick: this.onAddInvite
        })
      }
      if (!this.isRootOrganization()) {
        // can add
        bottomButtons.push({ label: '+ Add user', onClick: this.onAdd })
        //topButtons.push({label: "+ Add", onClick: this.onAdd.bind(this)});
      }
    }

    return (
      <div>
        <div className="ObjectUserList__table">
          {this.renderDeleteConfirmWindow()}
          <FilterableContent
            dataFilters={[
              { name: '', width: 95 },
              { name: 'alias', width: 270 },
              //{name: "", width: 69},
              { name: 'status', width: 84 + 10 },
              { name: 'role', width: 84 + 5 }
            ]}
            data={this.getUsersData()}
            title="objectUserList"
            parentUri={getFullUri(this.props.object)}
          >
            <TypedTable
              columns={[
                {
                  name: 'alias',
                  type: columnsToType.getType('alias'),
                  displayName: 'Name & Alias',
                  width: 295,
                  sortable: true,
                  sortFunc: (a, b) => {
                    if (a.nameData > b.nameData) return 1
                    else if (a.nameData < b.nameData) return -1
                    else return 0
                  }
                },
                {
                  name: 'email',
                  type: columnsToType.getType('email'),
                  displayName: 'EMail & Login',
                  width: 250 + 30,
                  sortable: true
                },
                {
                  name: 'status',
                  type: columnsToType.getType('name'),
                  width: 84 + 10,
                  sortable: true
                },
                {
                  name: 'role',
                  type: {
                    name: 'enum',
                    options: [
                      { id: 1, label: 'Developer', value: 'Developer' },
                      { id: 2, label: 'Architect', value: 'Architect' },
                      { id: 3, label: 'Analyst', value: 'Analyst' }
                    ]
                  },
                  width: 84 + 10,
                  sortable: true
                }
              ]}
              tailButtons={
                !(editState >= editableState.EDITING)
                  ? []
                  : [
                      {
                        label: getTailButtonLabel('edit'),
                        onClick: this.onRowClick
                      },
                      {
                        label: getTailButtonLabel('delete'),
                        onClick: this.onDelete
                      }
                    ]
              }
              topButtons={topButtons}
              theme={this.props.className}
              inEditMode
              locale={this.props.className}
              bottomButtons={bottomButtons}
              additionalButtons={
                this.isOrganization() && editState >= editableState.EDITING ? (
                  <span className="btn_edit" onClick={this.onAddInvite}>
                    + Invite user
                  </span>
                ) : null
              }
            />
          </FilterableContent>
        </div>

        {this.state.isAdding && this.state.editingUserName ? (
          <RoleDialog
            modalTitle={'Edit role for ' + this.state.editingUserName}
            role={this.state.selectedUser.role}
            roles={this.state.roles}
            isEditable={editableState.EDITING}
            isVisible={this.state.isAdding}
            onSave={this.changeRole}
            onClose={() => this.setState({ isAdding: false })}
          />
        ) : null}

        {this.state.isAdding && !this.state.editingUserName ? (
          <UserDialog
            modalTitle={'Add user'}
            object={
              this.props.object.type === 'organization' && this.props.object.object.parent
                ? this.props.object.object.parent.name
                : getFullUri(this.props.object)
            }
            department={
              this.props.object.type === 'organization' && this.props.object.object.parent
                ? getFullUri(this.props.object)
                : ''
            }
            isEditable={editableState.EDITING}
            isVisible={this.state.isAdding}
            roles={this.state.roles}
            onSave={this.addUser}
            onClose={() => this.setState({ isAdding: false })}
          />
        ) : null}

        {this.state.isInviting ? (
          <InviteDialog
            action="InviteUser"
            close={() => this.setState({ isInviting: false })}
            organization={this.props.object}
            user={this.props.userState}
            roles={this.state.roles}
            actions={this.props.actions}
            onSuccess={this.inviteUser}
          />
        ) : null}
      </div>
    )
  }

  renderDialog() {
    return (
      <EditorDialog
        objectType="users"
        modalTitle={this.props.modalTitle}
        headerContent={(editState, content) => {
          return [
            editState >= editableState.EDITING ? (
              <div className="ModalWindow__headerEditButton">
                {!this.isRootOrganization() && !this.isOrganization() ? (
                  <EditorDialogButton className="EditorButton_noedit" onEdit={this.onAdd} editText="Add" />
                ) : null}
                {this.isOrganization() ? (
                  <EditorDialogButton className="EditorButton_noedit" onEdit={this.onAddInvite} editText="Invite" />
                ) : null}
              </div>
            ) : null,
            content
          ]
        }}
        confirmText={'Save'}
        editContent={this.renderEditor}
        isEditable={this.props.isEditable ? editableState.EDITABLE : editableState.BROWSABLE}
        isVisible
        onClose={this.closeModal}
        onSave={this.saveChanges}
        onEdit={this.edit}
      />
    )
  }

  render() {
    //const style = {'maxWidth' : this.props.maxWidth ? this.props.maxWidth : 'none'  };
    let style = {}
    if (this.props.maxWidth) {
      style['maxWidth'] = this.props.maxWidth
    } else {
      style['maxWidth'] = 'none'
    }
    if (this.state.showAll) {
      style['whiteSpace'] = 'normal'
      style['overflow'] = 'auto'
      style['text-overflow'] = 'clip'
    }

    // eslint-disable-next-line no-unused-vars
    let dark =
      this.props.className === 'dataset' ||
      this.props.className === 'layout' ||
      this.props.className === 'field' ||
      this.props.className === 'publication'

    //console.log("ObjectUserList:render", this.state);
    return (
      <div className="ObjectUserList" ref={this.rootRef}>
        {this.state.isModal ? this.renderDialog() : ''}

        <div className={'ObjectUserList__main ' + (this.props.dark ? 'ObjectUserList__lightText' : '')} style={style}>
          <div className="ObjectUserList__type ">
            <div className="ObjectUserList__left ">
              <div
                className={'ObjectUserList__userTitle' + (this.props.dark ? ' ObjectUserList__userTitle_light' : '')}
                onClick={this.edit}
              >
                <img
                  className="ObjectUserList__icon"
                  src={this.props.dark ? (this.props.greenColor ? iTeam : iTeamBlue) : iTeamDark}
                  alt="icon"
                />{' '}
                Team:&nbsp;
              </div>
              {this.props.objectUsers.length === 0
                ? this.props.emptyText
                : this.props.objectUsers.map((user, index) => (
                    <div className="ObjectUserList__user" key={index}>
                      <Person user={user} onClick={this.edit} />
                      {index < this.props.objectUsers.length - 1 ? <span>, </span> : ''}
                    </div>
                  ))}
            </div>
            <div className="ObjectUserList__right ">
              {this.state.showArrow && !this.state.showAll ? (
                <div className="ObjectUserList__arrow">
                  <img src={iArrowDownCloseDark} onClick={this.edit} alt="icon" />
                </div>
              ) : (
                ''
              )}
            </div>

            <div style={{ clear: 'both' }}></div>
          </div>
        </div>
      </div>
    )
  }
}

ObjectUserList.propTypes = {
  objectUsers: PropTypes.array.isRequired,
  types: PropTypes.array,
  className: PropTypes.string,
  onSave: PropTypes.func,
  maxWidth: PropTypes.number,
  modalTitle: PropTypes.string,
  onDeleteUser: PropTypes.func,
  onUserRoleChange: PropTypes.func,
  onAddUser: PropTypes.func,
  onInviteUser: PropTypes.func,
  onUserClick: PropTypes.func,
  object: PropTypes.object,
  dark: PropTypes.bool,
  greenColor: PropTypes.bool //  affects team icon
}
