import md from '../../helpers/md'
import { datasetToObjectArray } from '../../helpers/index'
require('brace/mode/mysql')

let fieldNames = null
let tableNames = null

export class CustomHighlightRules extends window.ace.acequire('ace/mode/text_highlight_rules').TextHighlightRules {
  constructor() {
    super()

    console.log('CustomHighlightRules ttqq', fieldNames, tableNames)

    const wordList = datasetToObjectArray(
      md.repo.metadata.apdax.systems.difhub.applications.organization.datasets.function
    ).map((func) => func.Function)
    let start = [
      {
        token: 'string',
        regex: '".+"'
      },
      {
        token: 'constant.numeric', // hex
        regex: '0[xX][0-9a-fA-F]+\\b'
      },
      {
        token: 'constant.numeric', // float
        regex: '[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b'
      },
      {
        token: 'keyword.operator',
        regex:
          '::|!|\\$|%|&|\\*|\\-\\-|\\-|\\+\\+|\\+|~|===|==|!=|!==|<=|>=|=>|<<=|>>=|>>>=|<>|<|>|\\.=|=|!|&&|\\|\\||\\?\\:|\\*=|/=|%=|\\+=|\\-=|&=|\\^=|\\b(?:in|instanceof|new|delete|typeof|void)'
      },
      {
        token: 'punctuation.operator',
        regex: /[,;]/
      },
      {
        token: 'paren.lparen',
        regex: '[[({]'
      },
      {
        token: 'paren.rparen',
        regex: '[\\])}]'
      }
    ]

    wordList.forEach((word) => {
      start.push({
        token: 'function',
        regex: word
      })
    })

    tableNames.forEach((word) => {
      start.push({
        token: 'entity.name',
        regex: word
      })
    })

    fieldNames.forEach((word) => {
      start.push({
        token: 'variable',
        regex: word
      })
    })

    console.log('CustomSqlMode::constructor', start)

    this.$rules = { start: start }
  }
}

export default class CustomSqlMode extends window.ace.acequire('ace/mode/mysql').Mode {
  constructor(fn, tn) {
    fieldNames = fn
    tableNames = tn
    console.log('CustomSqlMode constructor', fn, tn)
    super()
    this.HighlightRules = CustomHighlightRules
  }
}
