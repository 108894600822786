/**
 * Created by kascode on 26.06.17.
 */
import * as React from 'react'
// eslint-disable-next-line import/no-duplicates
import { Component } from 'react'
import logger from '../../helpers/logger'
import * as PropTypes from 'prop-types'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import ObjectItemSearch from '../ObjectSearch/ObjectItemSearch'
import { LinkedKeyTables } from '../LinkedKeyTables/LinkedKeyTables'
import { getObjectNew, idAPI, getRequestFromPath } from '../../helpers/api'
import {
  nonCSCompare,
  deepCopy,
  findById,
  findByName,
  findByIdentity,
  getParentUri,
  editableState,
  majorObjectSort,
  updateLocalObject,
  clearLocalObject,
  getFullUri
} from '../../helpers/index'

import './InterfaceSubscription.scss'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { renderRestore } from '../../helpers/helperComponents'

export class InterfaceSubscription extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      interfaceObject: null
    })
  }

  getObjectType() {
    return 'interfaceReference'
  }

  UNSAFE_componentWillReceiveProps() {}

  componentDidMount() {
    if (this.props.minorObject)
      this.getInterfaceData(this.props.minorObject.identity.id, this.props.minorObject.identity.name)
  }

  /**
   * Get initial state
   * @param {object} [props]
   * @returns {*}
   */
  getInitial(props) {
    props = props || this.props
    console.log('InrefacePublication:getInitialState', props.object, props.publication)
    if (props.minorObject) {
      return JSON.parse(JSON.stringify(props.minorObject))
    } else {
      return {
        identity: { name: '', description: '' },
        version: { major: 0, minor: 0, revision: 0 },
        layoutNames: [],
        operationNames: []
      }
      //createEmptyObject('interfacereference');
    }
  }

  /**
   * Change state of layout
   * @param {object} - new state of object
   * @returns {*} true - it is updated, false - no changes
   */
  ChangeInterfaceReference = (reference) => {
    return this.Change(reference)
  }

  /**
   * Get data for provided interface
   * @param {string} id - uid of interface
   * @param {string} name - interface name
   * @param {boolean} updateReference - whether we need to update reference
   */
  getInterfaceData = (id, name, updateReference) => {
    let publication = this.props.publication
    let interfc = findById(publication.interfaces, id)
    if (!interfc) return

    if (!this.props.minorObject || this.props.minorObject.identity.id !== id) {
      const interfaceReference = {
        identity: interfc.identity,
        version: interfc.version,
        layoutNames: [],
        operationNames: []
      }
      if (updateReference) this.ChangeInterfaceReference(interfaceReference)
    }

    getObjectNew(idAPI.interfaces(id), 'interface', this.props.actions).then(
      (obj) => {
        this.setState({
          height: this.state.height + (obj.operations ? obj.operations.length : 0) * 40,

          interfaceObject: obj
        })
      },
      (err) => {
        //console.log("InrefacePublication:getInterfaceData:ERROR", err);
      }
    )
  }

  checkInputErrors(interfaceReference) {
    let inputError = ''
    if (this.getLayoutOptions().length > 0 && this.state.interfaceReference.layoutNames.length < 1) {
      inputError += 'Please select layouts for subscription! '
    }
    if (this.state.interfaceReference.operationNames.length < 1) {
      inputError += 'Please select operations for subscription! '
    }
    return inputError
  }

  onSelectInterface(event) {
    // console.log("InterfaceSubscription:onSelectInterface", intrfc);
    let intrfc = event.target.value

    logger.info('IntefaceSubscription:onSelectInterface', intrfc, this.props, this.state)

    // Indicate save mode
    if (this.state.change && intrfc) {
      this.getInterfaceData(intrfc.identity.id, intrfc.identity.name, true)
    }
  }

  getInterfaceOptions() {
    //console.log("InterfaceSubscription:getInterfaceOptions", this.props.publication, this.state.interfaceReference);
    let subscribed =
      this.props.subscription && this.props.subscription.interfaces ? this.props.subscription.interfaces : []
    let published = this.props.publication && this.props.publication.interfaces ? this.props.publication.interfaces : []
    let interfaces = published.filter((ds) => subscribed.findIndex((dt) => ds.identity.id === dt.identity.id) === -1)

    return interfaces.map((element, index) => {
      return {
        id: index,
        label: element.identity.name,
        value: element
      }
    })
  }

  getLayoutOptions() {
    //console.log("InterfaceSubscription:getLayoutOptions", this.props.publication, this.state.interfaceReference);
    let interfc = findByIdentity(this.props.publication.interfaces, this.state.interfaceReference)
    if (!interfc || !interfc.layoutNames) return []

    return interfc.layoutNames.map((element, index) => {
      return {
        id: index,
        label: element,
        value: element
      }
    })
  }

  getOperationOptions() {
    //console.log("InterfaceSubscription:getOperationOptions", this.props, this.state.interfaceReference);
    let interfc = findByIdentity(this.props.publication.interfaces, this.state.interfaceReference)
    if (!interfc || !interfc.operationNames) return []

    let interfaceOperations = this.state.interfaceObject ? this.state.interfaceObject.operations : null
    if (interfaceOperations) {
      return interfc.operationNames.map((element, index) => {
        let intrfc = findByName(interfaceOperations, element)
        return [index, intrfc.identity.name, intrfc.action]
      })
    }
    return interfc.operationNames.map((element, index) => [index, element, ''])
  }

  onLayoutChange(e) {
    let layouts = e.target.value

    let interfaceReference = deepCopy(this.state.interfaceReference)
    interfaceReference.layoutNames = layouts.map((layout) => layout.value)

    this.ChangeInterfaceReference(interfaceReference)
  }

  onOperationChange(index) {
    return (newSelected) => {
      let interfaceReference = deepCopy(this.state.interfaceReference)
      let operationOptions = this.getOperationOptions()

      //console.log("InterfaceSubscription:onOperationChange", index, newSelected, interfaceReference, operationOptions);

      interfaceReference.operationNames = []
      for (let i in newSelected) {
        let f = operationOptions[newSelected[i]]
        interfaceReference.operationNames.push(f[1])
      }

      this.ChangeInterfaceReference(interfaceReference)
    }
  }

  /**
   * Filter string for item in the list
   * @return default filter string
   */
  itemFilter = (item, type, query) => {
    //console.log("InterfaceSubscription:itemFilter", item, type, query, this.props);
    // Check if interface exist in publication
    if (
      this.props.publication.interfaces.findIndex((inter) => nonCSCompare(item.identity.name, inter.identity.name)) ===
      -1
    )
      return false

    // Check if interface already used in subscription
    if (
      this.props.subscription.interfaces.findIndex((inter) => nonCSCompare(item.identity.name, inter.identity.name)) !==
      -1
    )
      return false

    if (!query || query.length === 0) {
      return true
    } else {
      return item.identity.name && item.identity.name.toLowerCase().startsWith(query)
    }
  }

  renderOperations(editState) {
    //console.log("InterfaceSubscription:renderOperations", this.state, this.props);
    let operationOptions = this.getOperationOptions()
    if (!operationOptions || operationOptions.length === 0) return null

    let operationNames =
      this.state.interfaceReference && this.state.interfaceReference.operationNames
        ? this.state.interfaceReference.operationNames
        : []
    let selectedOperations = operationNames.map((element, index) =>
      operationOptions.findIndex((op) => nonCSCompare(op[1], element))
    )

    //console.log("InterfaceSubscription:renderOperations", selectedOperations, operationOptions);
    return (
      <div>
        <div>
          <div className="row InterfaceSubscription__field">
            <div className="col-xs-1">
              <span className="InterfaceSubscription__label">Operations:</span>
            </div>
          </div>
          <div className="row InterfaceSubscription__row">
            <LinkedKeyTables
              columns={['NN', 'Name', 'Action']}
              selected={selectedOperations}
              items={operationOptions}
              onChange={this.onOperationChange(0).bind(this)}
              editable={editState > editableState.EDITABLE}
              onSrcFieldChange={() => {}}
              canAddField={(index) => {
                return true
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render layout for publication or subscription.
   */
  renderLayout = (editState) => {
    let layoutoptions = this.getLayoutOptions()
    if (!layoutoptions || layoutoptions.length === 0) return null

    let layoutNames =
      this.state.interfaceReference && this.state.interfaceReference.layoutNames
        ? this.state.interfaceReference.layoutNames
        : []

    //console.log("InterfaceSubscription:renderLayout", editState, layoutoptions, layoutNames, this.state);

    return (
      <div>
        <div className="row InterfaceSubscription__row">
          <div className="col-xs-1">
            <span className="InterfaceSubscription__label">Layouts:</span>
          </div>
          <div className="col-xs-9">
            {editState > editableState.EDITABLE && layoutoptions.length > 0 ? (
              <EditableEntity
                data={layoutNames.length > 0 ? layoutNames.toString() : 'Select Layouts'}
                dataType={{ name: 'enum', options: layoutoptions }}
                isEditable
                inEditMode
                dataProps={{
                  hideCheckBox: false,
                  multi: true,
                  onChange: this.onLayoutChange.bind(this),
                  activeOptions: layoutNames.map((name) =>
                    layoutoptions.findIndex((layout) => nonCSCompare(layout.value, name))
                  )
                }}
              />
            ) : (
              <div className="InterfaceSubscription__subjectValue">{layoutNames}</div>
            )}
          </div>
          <div className="col-xs-8">
            <div className="InterfaceSubscription__infoIcon" />
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render interface for publication.
   */
  renderInterface = (editState) => {
    let description =
      this.state.interfaceReference.identity &&
      this.state.interfaceReference.identity.description &&
      this.state.interfaceReference.identity.description.length > 0
        ? this.state.interfaceReference.identity.description
        : ''
    let name =
      this.state.interfaceReference.identity &&
      this.state.interfaceReference.identity.name &&
      this.state.interfaceReference.identity.name.length > 0
        ? this.state.interfaceReference.identity.name
        : 'Select to subscribe'

    let interfaces = this.getInterfaceOptions()

    const inEditMode = editState > editableState.EDITABLE
    return (
      <div>
        <div className={'NewSettingsBlock'}>
          <div className="row InterfaceSubscription__row">
            <div className="col-xs-1">
              <span className="InterfaceSubscription__label">Interface:</span>
            </div>
            <div className="col-xs-6">
              {inEditMode ? (
                <EditableEntity
                  data={name}
                  dataType={{
                    name: 'enum',
                    options: interfaces
                  }}
                  dataProps={{
                    placeholder: 'Select interface',
                    onChange: this.onSelectInterface.bind(this)
                  }}
                  isEditable={inEditMode}
                  inEditMode={inEditMode}
                />
              ) : (
                <EditableEntity
                  data={name}
                  dataType={{ name: 'string' }}
                  dataProps={{ placeholder: '' }}
                  isEditable={false}
                  inEditMode={false}
                />
              )}
            </div>
            <div className="col-xs-3">
              <span className="InterfaceSubscription__label">Version:</span>
            </div>
            <div className="col-xs-4">
              <div className="InterfaceSubscription__subjectVersion">
                {this.state.interfaceReference.version.major + '.*.*'}
              </div>
            </div>
            <div className="col-xs-8">
              <div className="InterfaceSubscription__infoIcon" />
            </div>
          </div>
        </div>
        <div className={'NewSettingsBlock'}>
          <div className="row InterfaceSubscription__row InterfaceSubscription__descriptionRow">
            <div className="col-xs-1">
              <div className="InterfaceSubscription__subjectLabel">Description:</div>
            </div>
            <div className="col-xs-7">
              <div className="InterfaceSubscription__subjectValue">{description}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render scrollable area of dialog
   * @return
   */
  renderData = (editState) => {
    //console.log("InterfaceSubscription:renderData", this.state, editState);

    return (
      <div className="InterfaceSubscription">
        {this.renderInterface(editState)}
        {this.renderLayout(editState)}
        {this.renderOperations(editState)}
      </div>
    )
  }

  render() {
    let caption = 'Interface to subscribe'

    //console.log("InterfaceSubscription:render", this.props, this.state);
    return (
      <div className="InterfaceSubscription">
        <EditorDialog
          objectType="data"
          modalTitle={caption}
          editContent={this.renderData.bind(this)}
          headerContent={renderRestore(this, () => {
            let appUri = getFullUri(this.props.publication)
            appUri = appUri.substr(0, appUri.indexOf('/publications'))
            getObjectNew(
              getRequestFromPath(appUri + '/interfaces/' + this.state.interfaceReference.identity.name)
            ).then((intf) => {
              this.getInterfaceData(intf.identity.id, intf.identity.name)
            })
          })}
          editHeight={this.state.height}
          isVisible={this.props.isVisible}
          isEditable={this.props.isEditable}
          onClose={this.props.onClose}
          onSave={this.save.bind(this)}
          onEdit={this.startEdit}
          onCancel={this.cancelEdit}
        />
      </div>
    )
  }
}

InterfaceSubscription.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  publication: PropTypes.object,
  subscription: PropTypes.object,
  object: PropTypes.object,
  minorObject: PropTypes.object,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}
