/**
 * Created by mailf on 22.08.2016.
 */
import React from 'react'
import { Link } from 'react-router-dom'

import {
  capitalize,
  columnsToType,
  getFullUri,
  majorObjectSort,
  nonCSCompare,
  objectNameFromPathByType,
  pluralTypeForms,
  tableForwardClick
} from 'helpers'
import { API, getObjectsWithConnected, getRequestFromPath, singularType } from 'helpers/api'
import {
  getEnums,
  getObjectByName,
  getPlatforms,
  getStructures,
  getSystems,
  getTypes,
  getUsages,
  loadPlatforms,
  loadTypes,
  loadUsages
} from 'helpers/data'
import { EditButton } from '../EditButton/EditButton'
import FilteredTabbedTable from '../FilteredTabbedTable/FilteredTabbedTable'
import { MajorObject } from '../MajorObject/MajorObject'
import { Messages } from '../Messages/Messages'
import ObjectCreator from '../ObjectCreator/ObjectCreator'
import { getTailButtonLabel } from '../../helpers/helperComponents'
import { Tab } from '../FilteredTabbedTable/TabType'

export enum OrganizationTabEnum {
  PLATFORMS = 'platforms',
  TYPES = 'types',
  ENUMS = 'enums',
  USAGES = 'usages',
  STRUCTURES = 'structures',
  SYSTEMS = 'systems'
}

export class Organization extends MajorObject {
  constructor(props) {
    super(props)

    // @ts-ignore
    this.state.objectType = 'organization'
    // @ts-ignore
    this.state.objectName = this.getObjectName(this.state.objectType)
  }

  UNSAFE_componentWillReceiveProps(props) {
    const objectType = this.getObjectType(props)
    if (!this.props.userState.profile && props.userState.profile) {
      this.loadUserRole(props)
    }
    if (objectType === this.state.objectType) return
    this.clearTabsContentLoaded(['systems']) // show Loader for object table
    getObjectsWithConnected(
      this.getDataRequestQuery(props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  componentDidMount() {
    const org = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    if (!localStorage.getItem("currentUserToken")) {
      this.props.history?.push('/cognitologin' + window.location.hash)
    }
    if (org && org.identity.id) {
      this.props.actions.updateMajorObject(org.identity.id, pluralTypeForms.get(this.state.objectType), {
        isFetching: true
      })
      localStorage.setItem('currentOrganization', org.identity.id)
    }

    localStorage.setItem('getPlatforms', 'false')
    localStorage.setItem('getTypes', 'false')
    localStorage.setItem('getUsages', 'false')

    this.clearTabsContentLoaded(['systems', 'platforms', 'types', 'usages', 'enums', 'structures']) // show Loader for object table
    getObjectsWithConnected(
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  getDataRequestQuery(props) {
    return {
      objectRequest: API.organizations(props.match.params.organizationName),
      objectRequestCallback: (obj, errorStatus) => {
        this.setState({ errorStatus: errorStatus })
        return true
      },
      connectedObjectsRequests: [
        {
          objectType: 'system',
          request: API.organizations(props.match.params.organizationName).systems(),
          connectedObjectsRequestCallback: this.onTabsContentLoaded(['systems'])
        },
        {
          objectType: 'dataset',
          request: API.organizations(props.match.params.organizationName).datasets,
          requestParameters: { status: 'Approved' },
          connectedObjectsRequestCallback: (org, datasets) => {
            this.onTabsContentLoaded(['enums', 'structures'])()

            //getObject(req.get(), 'dataset', platforms[0].identity.name, actions, req.url())
            loadPlatforms(this.props.appState, org, this.props.actions, true).then(() => {
              //console.log("loadPlatforms done");
              this.onTabsContentLoaded(['platforms'])()
            })
            loadTypes(this.props.appState, org, this.props.actions, true).then(() => {
              this.onTabsContentLoaded(['types'])()
            })
            loadUsages(this.props.appState, org, this.props.actions, true).then(() => {
              this.onTabsContentLoaded(['usages'])()
            })
          }
        },
        {
          objectType: 'user',
          majorObjectType: 'organization',
          isObjectProperty: true,
          propertyName: 'users',
          request: API.organizations(props.match.params.organizationName).users(),
          connectedObjectsRequestCallback: (org, users) => {
            //console.log("connectedObjectsRequestCallback", users);
            this.checkUserRole(props)(org, users)
            users.map((u) => {
              if (u.profile.identity.id === localStorage.currentUserId && !('phone' in u.profile)) {
                //console.log("User does not exist in organization - create profile");
                API.organizations(org.identity.name).profiles(u.profile.identity.name).patch({ phone: '' })
              }
            })
          }
        }
      ]
    }
  }

  getDatasetContextMenu = () => {
    return [
      {
        label: 'Copy dataset',
        data: { action: 'copy' },
        onClick: (e, data, t) => {
          const datasetPath = getFullUri(data.value === undefined ? data : data.value)

          //console.log("Copy dataset",data.value, datasetPath);

          const req = getRequestFromPath(datasetPath)

          req.get().then((resp) => {
            localStorage.clipboard = JSON.stringify(resp)
            localStorage.clipboardType = 'dataset'
          })
        }
      },
      {
        label: 'Paste dataset',
        data: { action: 'paste' },
        onClick: () => {
          //console.log("Paste dataset");

          if (!localStorage.clipboard) {
            alert('No dataset copied')
            return
          }
          if (localStorage.clipboardType !== 'dataset') {
            alert('No dataset copied')
            return
          }

          this.setState({
            pastingChild: true,
            pastingChildName: JSON.parse(localStorage.clipboard).identity.name
          })
        },
        showInBottom: true
      }
    ]
  }

  getDataTabWidth(field, dataset, totalWidth, defaultColumns) {
    defaultColumns.map((col) => {
      if (col.name === field.identity.name) return col.width
    })

    return totalWidth / dataset.structure.fields.length
  }

  getColumnsFromMetadata(datasetName, defaultColumns) {
    const org = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const id = org.identity.id

    const list = (this.props.appState.datasets || [])
      .filter((ds) => {
        // get only datasets of current organization
        // they can be from Apdax org so we can't rely on object.parent
        // so check _path
        // todo: do something
        if (!ds._path) return false
        //if (ds._path.indexOf(org._path) === -1)           // _path includes current organization - the dataset was loaded for current org
        //  return false;
        //if (ds._path.indexOf('/systems/') !== -1)         // _path does not include system - this is root level dataset
        //  return false;
        return true
      })
      .filter(
        (ds) =>
          ds.object &&
          ds.object.usage &&
          nonCSCompare(ds.object.usage, 'enum') &&
          nonCSCompare(ds.identity.name, datasetName)
      )

    //console.log("Organization::getColumnsFromMetadata", datasetName, defaultColumns, org, list);

    if (list.length > 0) {
      const dataset = list[0]
      //console.log("Organization::getColumnsFromMetadata Dataset", dataset);
      return dataset.structure && dataset.structure.fields
        ? dataset.structure.fields
          .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
          .map((f) => {
            if (!f.type) f.type = 'String' // remove this quick dirty fix
            return {
              name: f.identity.name,
              type: Object.assign(
                {},
                columnsToType.getType(f.type.toLowerCase()),
                f.reference ? { reference: f.reference } : {}
              ),
              width: this.getDataTabWidth(f, dataset, 1184, defaultColumns),
              showExpandButton: nonCSCompare(f.usage, 'description')
            }
          })
        : defaultColumns
    } else {
      return defaultColumns
    }
  }

  getDataFromMetadata(datasetName, row) {
    const columns = this.getColumnsFromMetadata(datasetName, [])

    let ret = {}

    columns.map((col, index) => {
      ret[col.name] = row[index]
    })

    return ret
  }

  getTabsProps = () => {
    let canDelete = this.state.currentUserRole === 'Architect'
    const org = this.getObject()

    const tabs: Tab[] = [
      {
        title: 'systems',
        filters: [
          { name: '', width: 100 },
          { name: 'search', width: 279 },
          { name: 'tags', width: 250 },
          { name: '', width: 150 },
          { name: 'locales', width: 110 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('objectName'),
            displayName: 'System name',
            width: 300
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 538
          },
          { name: 'usage', type: columnsToType.getType('usage'), width: 150 },
          { name: 'locale', type: columnsToType.getType('locale'), width: 110 },
          {
            name: 'pubsub',
            type: columnsToType.getType('pubsub'),
            displayName: 'Pub/Sub',
            width: 84
          }
        ],
        forward: tableForwardClick(this.props, org, 'systems'),
        //onEditRow: this.onChildObjectTableClick.bind(this),
        tailButtons: !canDelete
          ? [
            {
              label: getTailButtonLabel('Delete'),
              onClick: (obj) => this.showCannotDelete('Only Architect can delete system')
            }
          ]
          : [
            {
              label: getTailButtonLabel('Delete'),
              onClick: (obj) => this.deleteMajorObject('system', obj)
            }
          ],
        bottomButtons: [
          {
            label: '+ Add system',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'system',
                childObjectUsage: ''
              })
            }
          }
        ],
        topButtons: [
          {
            label: '+ Add',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'system',
                childObjectUsage: ''
              })
            }
          }
        ],
        emptyText: (
          <div className="MajorObject__emptyOuter">
            <div>
              No systems found
              <br />
            </div>
          </div>
        )
      },

      {
        title: 'platforms',
        filters: [
          { name: '', width: 34 },
          {
            name: 'search',
            width: 250,
            searchFields: ['Platform', 'Description']
          }
        ],
        columns: this.getColumnsFromMetadata('Platform', [
          {
            name: 'name',
            type: columnsToType.getType('objectName'),
            displayName: 'Platform name',
            width: 284
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 350
          },
          { name: 'id', type: columnsToType.getType('string'), width: 100 },
          {
            name: 'location',
            type: columnsToType.getType('string'),
            width: 200
          },
          {
            name: 'instance',
            type: columnsToType.getType('string'),
            width: 250
          }
        ]),
        onEditRow: this.onChildObjectTableClick.bind(this),
        //showAdd: true,

        bottomButtons: [
          /*{label: "+ Add platform", onClick: () => {
          this.setState({
            addingChild: true,
            childObjectType: 'platform',
            childObjectUsage: ''
          });
        }}*/
        ],
        topButtons: [
          /*{label: "+ Add", onClick: () => {
          this.setState({
            addingChild: true,
            childObjectType: 'platform',
            childObjectUsage: ''
          });
        }}*/
        ],
        tailButtons: [],
        tabContent: this.renderDatasetEditButton('platforms')
      },
      {
        title: 'types',
        filters: [
          { name: '', width: 56 },
          { name: 'search', width: 250, searchFields: ['Type', 'Description'] }
        ],
        columns: this.getColumnsFromMetadata('Type', [
          {
            name: 'name',
            type: columnsToType.getType('objectName'),
            displayName: 'Type name',
            width: 184
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 250
          },
          { name: 'code', type: columnsToType.getType('string'), width: 100 },
          { name: 'size', type: columnsToType.getType('string'), width: 100 },
          {
            name: 'attributes',
            type: columnsToType.getType('string'),
            width: 125
          },
          { name: 'MySQL', type: columnsToType.getType('string'), width: 200 },
          {
            name: 'SQLServer',
            type: columnsToType.getType('string'),
            width: 150
          },
          {
            name: 'RESTfull-API',
            type: columnsToType.getType('string'),
            width: 200
          }
        ]),
        tabContent: this.renderDatasetEditButton('types')
      },
      {
        title: 'usages',
        filters: [
          { name: '', width: 94 },
          { name: 'search', width: 250, searchFields: ['Usage', 'Description'] }
        ],
        columns: this.getColumnsFromMetadata('Usage', [
          {
            name: 'name',
            type: columnsToType.getType('objectName'),
            displayName: 'Usage name',
            width: 250
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 434
          },
          {
            name: 'settings',
            type: columnsToType.getType('string'),
            width: 250
          },
          { name: 'object', type: columnsToType.getType('string'), width: 250 }
        ]),
        tabContent: this.renderDatasetEditButton('usages')
      },
      {
        title: 'enums',
        filters: [
          { name: '', width: 45 },
          { name: 'search', width: 250, searchFields: ['Enum', 'Description'] }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('objectName'),
            displayName: 'Enum name',
            width: 250
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 617 - 100
          },
          {
            name: 'subscription',
            type: columnsToType.getType('string'),
            width: 100
          },
          { name: 'usage', type: columnsToType.getType('usage'), width: 100 },
          {
            name: 'version',
            type: columnsToType.getType('string'),
            width: 100
          },
          { name: 'rating', type: columnsToType.getType('rating'), width: 117 }
        ],
        tailButtons: [
          {
            label: getTailButtonLabel('Delete'),
            onClick: (obj) => this.deleteMajorObject('dataset', obj)
          }
        ],
        bottomButtons: [
          {
            label: '+ Add dataset',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'dataset',
                childObjectUsage: 'Enum'
              })
            }
          },
          {
            label: 'Upload dataset',
            onClick: () => this.selectFileForUploadChildObject('dataset')
          }
        ],
        topButtons: [
          {
            label: '+ Add',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'dataset',
                childObjectUsage: 'Enunm'
              })
            }
          }
        ],
        contextMenuButtons: this.getDatasetContextMenu()
      },
      {
        title: 'structures',
        filters: [
          { name: '', width: 42 },
          {
            name: 'search',
            width: 250,
            searchFields: ['Structure', 'Description']
          }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('objectName'),
            displayName: 'Structure name',
            width: 250
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 617
          },
          { name: 'usage', type: columnsToType.getType('string'), width: 100 },
          {
            name: 'version',
            type: columnsToType.getType('string'),
            width: 100
          },
          { name: 'rating', type: columnsToType.getType('rating'), width: 117 }
        ],
        tailButtons: [
          {
            label: getTailButtonLabel('Delete'),
            onClick: (obj) => this.deleteMajorObject('dataset', obj)
          }
        ],
        bottomButtons: [
          {
            label: '+ Add dataset',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'dataset',
                childObjectUsage: 'Structure'
              })
            }
          },
          {
            label: 'Upload dataset',
            onClick: () => this.selectFileForUploadChildObject('dataset')
          }
        ],
        topButtons: [
          {
            label: '+ Add',
            onClick: () => {
              this.setState({
                addingChild: true,
                childObjectType: 'dataset',
                childObjectUsage: 'Structure'
              })
            }
          }
        ],
        contextMenuButtons: this.getDatasetContextMenu()
      }
    ]
    return tabs
  }

  getData = () => {
    const org = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const id = org.identity.id

    return this.getTabsProps().map((block) => {
      let getObjs
      switch (block.title) {
        case OrganizationTabEnum.PLATFORMS:
          //console.log("Org:getData getObjs = getPlatforms");
          getObjs = getPlatforms
          break
        case OrganizationTabEnum.TYPES:
          getObjs = getTypes
          break
        case OrganizationTabEnum.ENUMS:
          getObjs = getEnums
          break
        case OrganizationTabEnum.USAGES:
          getObjs = getUsages
          break
        case OrganizationTabEnum.STRUCTURES:
          getObjs = getStructures
          break
        case OrganizationTabEnum.SYSTEMS:
          getObjs = getSystems
          break
        default:
          getObjs = () => []
      }

      const data = getObjs(this.props.appState, org, this.props.actions, true)
      //console.log("Organization data", block.title, data);
      // Fill data array
      if (this.props.childObjectType) {
        block.data = data.map((d) => {
          return Object.assign({}, d, {
            name: {
              name: d.identity.name,
              objectType: block.title,
              path: d
            },
            description: d.description
          })
        })
      } else {
        block.data = data.map((d) => {
          let newObject: any = {}
          if (!d.length)
            newObject = {
              name: {
                name: d.identity.name,
                objectType: block.title,
                path: getFullUri(d),
                picture: d.object.picture,
                usage: d.object.usage
              },
              description: d.identity.description,
              architects: d.userrole ? d.userrole.filter((el) => el.role === 'architect') : [],
              usage: d.object.usage,
              locale: d.locale,
              pubsub: !d.object
                ? {}
                : {
                  publications: d.object.publicationCount,
                  subscriptions: d.object.subscriptionCount
                }
            }
          if (d.type === 'dataset') {
            newObject.version = d.version.major + '.' + d.version.minor + '.' + d.version.revision
            newObject.usage = d.object && d.object.usage ? d.object.usage : ''
            newObject.subscription = objectNameFromPathByType(getFullUri(d), 'subscriptions') || 'Private'
          }
          if (block.title === 'platforms') {
            newObject = this.getDataFromMetadata('Platform', d)
            /*
            newObject.name = {
              name: d[0]
            };
            newObject.description = d[1];
            newObject.id = d[2];
            newObject.location = d[3];
            newObject.instance = d[4];
            */
          }
          if (block.title === 'types') {
            newObject = this.getDataFromMetadata('Type', d)

            /*
            newObject.name = {
              name: d[0]
            };
            newObject.description = d[1];
            newObject.code = d[2];
            newObject.size = d[3];
            newObject.attributes = d[4];
            newObject.SQLServer = d[5];
            newObject.MySQL = d[6];
            newObject["RESTfull-API"] = d[7];
            */
          }
          if (block.title === 'usages') {
            newObject = this.getDataFromMetadata('Usage', d)
            /*
            newObject.name = {
              name: d[0]
            };
            newObject.description = d[1];
            newObject.settings = d[2];
            newObject.object = d[3];
            */
          }
          return Object.assign({}, d, newObject)
        })
      }
      block.data.sort(majorObjectSort)
      return block
    })
  }

  // @ts-ignore
  renderAdvancedSettings() {
    return null
  }

  renderDatasetEditButton(tabName) {
    return (
      <div className="Organization__datasetEditButton">
        <EditButton
          onEdit={() => {
            this.props.history?.push(
              '/view' + getFullUri(this.props.majorObject) + '/datasets/' + capitalize(singularType(tabName)) + '#data'
            )
          }}
        />
      </div>
    )
  }

  /**
   * Overrides MajorObject::renderInfo to display longer properties in place of invisible advanced settings
   * @returns {XML}
   */
  renderInfo() {
    //console.log("MajorObject::renderInfo", this.state.errorStatus);

    if (this.state.errorStatus === 404) {
      return this.renderNotFound()
    }

    return (
      <div className={'MajorObjectView__info' + (this.state.collapsed ? ' MajorObjectView__info_collapsed' : '')}>
        <div className="row">
          <div className="col-xs-6">
            {this.renderDescription()}
            {this.renderTags()}
            {this.renderDocuments()}
          </div>
          <div className="col-xs-3">{this.renderProperties()}</div>
          <div className="col-xs-2"></div>
          <div className="col-xs-1">{this.renderRightMenu()}</div>
        </div>
        <div className="row">{this.renderCollapseButton()}</div>
      </div>
    )
  }

  renderRightMenu() {
    return (
      <React.Fragment>
        {this.renderShowJson()} <br /> {this.renderDownloadPDF()}
        <br />
        <div className="MajorObjectView__showChart">
          <span>
            <Link to={'/documentation'} target="_blank">
              &nbsp;DOC&nbsp;
            </Link>
          </span>
        </div>
      </React.Fragment>
    )
  }

  render() {
    const org = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    if (!org || org.isFetching) {
      // //console.log("organization: ", org);
      return this.renderLoading()
    } else {
      //console.log("organization: ", org);

      return (
        <div className="MajorObject__outer">
          <section className={'MajorObjectView MajorObjectView_' + this.state.objectType}>
            {this.renderHeader()}
            {this.renderInfo()}
            <div className="MajorObjectView__connections">
              <div className="row">
                <div className="col-xs-12">
                  <FilteredTabbedTable
                    /*
                    // @ts-ignore */
                    tablesData={this.getData()}
                    theme={this.state.objectType}
                    tabsLoaded={this.state.tabsLoaded}
                    parentUri={getFullUri(org)}
                  />
                </div>
              </div>
            </div>
            <ObjectCreator
              isOpen={this.state.addingChild}
              parentObjectType={this.state.objectType}
              objectChain={this.props.match.params}
              parentObject={org}
              childObjectType={this.state.childObjectType ? this.state.childObjectType : 'none'}
              childObjectUsage={this.state.childObjectUsage ? this.state.childObjectUsage : ''}
              onSuccess={(result) => {
                //console.log("onSuccess of child create", result);
                if (this.state.childObjectType !== 'field') this.props.history?.push('/view' + getFullUri(result))
              }}
              onCancel={() => {
                this.setState({
                  addingChild: false
                })
              }}
              actions={this.props.actions}
              appState={this.props.appState}
            />
          </section>
          {org && org.identity && org.identity.id ? (
            <Messages
              ref="messages"
              appState={this.props.appState}
              userState={this.props.userState}
              actions={this.props.actions}
              object={org}
              currentUserRole={this.state.currentUserRole}
            />
          ) : null}
        </div>
      )
    }
  }
}
