/**
 * Created by mailf on 22.08.2016.
 */
import React from 'react'

import logger from '../../helpers/logger'
import { ObjectHistoryTable } from '../ObjectHistoryTable/ObjectHistoryTable'
import { MajorObjectVersioned } from '../MajorObjectVersioned/MajorObjectVersioned'
import FilteredTabbedTable from '../FilteredTabbedTable/FilteredTabbedTable'
import { Messages } from '../Messages/Messages'
import { API, idAPI, getObjectNew, getObjectsWithConnected, sendObjectNew } from '../../helpers/api'
import { columnsToType, deepCopy, findById, getObjectById, majorObjectSort, pluralTypeForms } from '../../helpers'
import { getObjectByName } from '../../helpers/data'
import { editableState, pathByType, getFullUri } from '../../helpers/index'

import { DatasetSubscription } from './DatasetSubscription'
import { InterfaceSubscription } from './InterfaceSubscription'
import { TopologySubscription } from './TopologySubscription'
import { getTailButtonLabel } from '../../helpers/helperComponents'
import { Tab } from '../FilteredTabbedTable/TabType'

export class Subscription extends MajorObjectVersioned {
  constructor(props) {
    super(props)

    // @ts-ignore
    this.state = {
      editingChild: false,
      editingChildType: '',
      editingChildId: null,
      editingChildMode: false,

      datasetEditMode: false,

      objectType: 'subscription',
      objectName: this.getObjectName('subscription')
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const objectType = this.getObjectType(props)
    if (!this.props.userState.profile && props.userState.profile) {
      this.loadUserRole(props)
    }
    /*
    if (objectType === this.state.objectType)
      return;
    getObjectsWithConnected(this.getDataRequestQuery(props), this.state.objectType, this.state.objectName, this.props.actions, this.props.appState);
    */
    if (
      !props.majorObject &&
      this.props.majorObject &&
      this.props.majorObject.identity.id !== 0 &&
      props.location.pathname !== this.props.location?.pathname
    ) {
      const newState = {
        objectType: 'subscription',
        objectName: props.match.params[this.getObjectType(props) + 'Name'],
        recordsToDelete: null
      }

      this.setState(newState, () => {
        getObjectsWithConnected(
          this.getDataRequestQuery(props),
          this.state.objectType,
          this.state.objectName,
          this.props.actions
        )
      })
    }
  }

  componentDidMount() {
    const subscription = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    if (subscription && subscription.identity.id) {
      this.props.actions.updateMajorObject(subscription.identity.id, pluralTypeForms.get(this.state.objectType), {
        isFetching: true
      })
    }

    getObjectsWithConnected(
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  getSubscriptionApiEndpoint = (props = this.props) => {
    if (props.match.params.applicationName) {
      return API.organizations(props.match.params.organizationName)
        .systems(props.match.params.systemName)
        .applications(props.match.params.applicationName)
        .subscriptions(this.state.objectName)
    } else {
      return API.organizations(props.match.params.organizationName)
        .systems(props.match.params.systemName)
        .subscriptions(this.state.objectName)
    }
  }

  getDataRequestQuery(props, version?) {
    const subApiEndpoint = version
      ? this.getSubscriptionApiEndpoint(props).versions(version)
      : this.getSubscriptionApiEndpoint(props)

    const connectedObjectsEndpoint = version ? this.getSubscriptionApiEndpoint(props) : subApiEndpoint

    return {
      objectRequest: subApiEndpoint,
      endpoint: this.getSubscriptionApiEndpoint(props),
      objectRequestCallback: (subscription, errorStatus) => {
        this.setState({ errorStatus: errorStatus })
        if (subscription.publication && subscription.publication.identity)
          getObjectNew(
            idAPI.publications(subscription.publication.identity.id),
            'publication',
            this.props.actions
          ).then((pub) => {
            /*
          let datasetPromises = pub.datasets.map((ds) => getObjectNew(idAPI.datasets(ds.identity.id), 'dataset'));
          Promise.all(datasetPromises).then(datasets => {
            this.props.actions.receiveObjectList('dataset', datasets);
          });
          let interfacePromises = (pub.interfaces || []).map((ds) => getObjectNew(idAPI.interfaces(ds.identity.id), 'interface'));
          Promise.all(interfacePromises).then(interfaces => {
            this.props.actions.receiveObjectList('interface', interfaces);
          });
          */
            //pub.datasets.map((ds) => {
            // This is heavy operation which slpw down UI. SashaB
            //getObjectNew(idAPI.datasets(ds.identity.id), 'dataset', this.props.actions);
            //})
          })
        return true
      },
      connectedObjectsRequests: [
        {
          objectType: 'user',
          majorObjectType: 'subscription',
          isObjectProperty: true,
          propertyName: 'users',
          request: connectedObjectsEndpoint.users(),
          connectedObjectsRequestCallback: this.checkUserRole(props)
        },
        {
          objectType: 'version',
          isObjectProperty: true,
          majorObjectType: 'subscription',
          propertyName: 'versions',
          request: connectedObjectsEndpoint.versions(),
          connectedObjectsRequestCallback: () => {
            if (this.state.loadingCheckpoints) {
              this.requestCheckpoints().then(() => {
                this.setState({ loadingCheckpoints: false })
              })
            }
          }
        }
      ]
    }
  }

  interfacesChange(new_interfaces) {
    this.props.actions.updateInterfaces(this.state.id, pluralTypeForms.get(this.state.objectType), new_interfaces)
  }

  getTabsProps = () => {
    const subscription = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable = this.checkEditable()

    let pubPath =
      subscription.publication && subscription.publication.identity ? subscription.publication.identity.name : ''
    let appPath = ''
    if (pubPath) {
      const pathSplit = pubPath.substring(1).split('/')
      appPath = pathByType(pathSplit, 'applications')
    }
    logger.info('Subsciption:getTabsProps', { subscription, isEditable, pubPath, appPath }, this.state, this.props)

    let tabs: Tab[] = []

    // We can have dataset only if publication from application.
    if (this.props.match.params.applicationName || appPath) {
      tabs.push({
        title: 'datasets',
        filters: [
          { name: 'search', width: 300 },
          { name: 'layouts', width: 200 },
          { name: 'fields', width: 200 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('datasetName'),
            displayName: 'Dataset name',
            onCellClick: (value) => {
              this.openDatasetWindow(value.identity.id, false)
              return false
            },
            width: 200
          },
          {
            name: 'version',
            type: columnsToType.getType('string'),
            width: 100
          },
          {
            name: 'layouts',
            type: columnsToType.getType('layouts'),
            width: 200
          },
          { name: 'fields', type: columnsToType.getType('fields'), width: 640 },
          { name: '', type: columnsToType.getType('text'), width: 44 }
        ],
        onCancel: () => {
          this.setState({ datasetEditMode: false })
        },
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (value) => {
                  this.openDatasetWindow(value.identity.id, false)
                  return false
                }
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (value) => {
                  this.openDatasetWindow(value.identity.id, true)
                  return false
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (obj) => {
                  this.setState({
                    deleteChildObject: {
                      obj: obj,
                      delete: () => this.deleteSubscriptionDataset(obj)
                    }
                  })
                }
              },
              {
                label: getTailButtonLabel('Link'),
                onClick: (obj) => {
                  if (
                    subscription &&
                    subscription.publication &&
                    subscription.publication.identity &&
                    subscription.publication.identity.name
                  ) {
                    const publicationUri = subscription.publication.identity.name
                    const applicationUri = publicationUri.substr(0, publicationUri.indexOf('/publications'))
                    const datasetUri = applicationUri + '/datasets/' + obj.name
                    logger.info('Subsciption:getTabsProps:Link', obj, publicationUri, applicationUri, datasetUri)
                    this.props.history?.push('/view' + datasetUri)
                  }
                }
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add dataset',
                onClick: () => {
                  //return {name: "New dataset"};
                  this.setState({
                    editingDatasetSubscription: true,
                    editingDatasetSubscriptionId: 0
                  })
                  //this.setState({editingDatasetSubscriptionId: 0});
                  return false
                }
              }
            ],topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  //return {name: "New dataset"};
                  this.setState({
                    editingDatasetSubscription: true,
                    editingDatasetSubscriptionId: 0
                  })
                  //this.setState({editingDatasetSubscriptionId: 0});
                  return false
                }
              }
            ],
        emptyText: (
          <div className="MajorObject__emptyOuter">
            <div>
              No datasets found
              <br />
              Add datasets you want to subscribe
            </div>
          </div>
        )
      })
    }

    // We can have interface if we subscription of application.
    if (this.props.match.params.applicationName) {
      tabs.push({
        title: 'interfaces',
        filters: [
          { name: 'search', width: 300 },
          { name: 'layouts', width: 200 },
          { name: 'operations', width: 200 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('datasetName'),
            displayName: 'Interface name',
            onCellClick: (value) => {
              console.log('getTabsProps', value)
              this.openInterfaceWindow(value.identity.id, false)
              return false
            },
            width: 200
          },
          {
            name: 'version',
            type: columnsToType.getType('string'),
            width: 100
          },
          {
            name: 'layouts',
            type: columnsToType.getType('layouts'),
            width: 200
          },
          {
            name: 'operations',
            type: columnsToType.getType('fields'),
            displayName: 'Operations',
            width: 640
          },
          { name: '', type: columnsToType.getType('text'), width: 44 }
        ],
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (value) => {
                  this.openInterfaceWindow(value.identity.id, false)
                  return false
                }
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (value) => {
                  this.openInterfaceWindow(value.identity.id, true)
                  return false
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (obj) => {
                  this.setState({
                    deleteChildObject: {
                      obj: obj,
                      delete: () => this.deleteSubscriptionInterface(obj)
                    }
                  })
                }
              },
              {
                label: getTailButtonLabel('Link'),
                onClick: (obj) => {
                  if (
                    subscription &&
                    subscription.publication &&
                    subscription.publication.identity &&
                    subscription.publication.identity.name
                  ) {
                    const publicationUri = subscription.publication.identity.name
                    const applicationUri = publicationUri.substr(0, publicationUri.indexOf('/publications'))
                    const interfaceUri = applicationUri + '/interfaces/' + obj.name
                    logger.info('Publication:getTabsProps:Link', obj, publicationUri, applicationUri, interfaceUri)
                    this.props.history?.push('/view' + interfaceUri)
                  }
                }
              }
            ],

        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add interface',
                onClick: () => {
                  //return {name: "New dataset"};
                  this.setState({
                    editingInterfaceSubscription: true,
                    editingInterfaceSubscriptionId: 0
                  })
                  //this.setState({editingDatasetSubscriptionId: 0});
                  return false
                }
              }
            ],topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  //return {name: "New dataset"};
                  this.setState({
                    editingInterfaceSubscription: true,
                    editingInterfaceSubscriptionId: 0
                  })
                  //this.setState({editingDatasetSubscriptionId: 0});
                  return false
                }
              }
            ],
        emptyText: (
          <div className="MajorObject__emptyOuter">
            <div>
              No interfaces found
              <br />
              Add interfaces you want to subscribe
            </div>
          </div>
        )

        //onSave: this.interfacesChange.bind(this)
      })
    }

    // We can have topology only for system subsription and from system publication.
    if (!this.props.match.params.applicationName && !appPath) {
      tabs.push({
        title: 'topologies',
        filters: [
          { name: 'search', width: 300 },
          { name: 'instances', width: 200 }
        ],
        columns: [
          {
            name: 'name',
            type: columnsToType.getType('datasetName'),
            displayName: 'Topology name',
            onCellClick: (value) => {
              console.log('getTabsProps', value)
              this.openTopologyWindow(value.identity.id, false)
              return false
            },
            width: 200
          },
          {
            name: 'version',
            type: columnsToType.getType('string'),
            width: 100
          },
          {
            name: 'instances',
            type: columnsToType.getType('fields'),
            displayName: 'Instances',
            width: 840
          },
          { name: '', type: columnsToType.getType('text'), width: 44 }
        ],
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (value) => {
                  this.openTopologyWindow(value.identity.id, false)
                  return false
                }
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (value) => {
                  this.openTopologyWindow(value.identity.id, true)
                  return false
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (obj) => {
                  this.setState({
                    deleteChildObject: {
                      obj: obj,
                      delete: () => this.deleteSubscriptionTopology(obj)
                    }
                  })
                }
              },
              {
                label: getTailButtonLabel('Link'),
                onClick: (obj) => {
                  if (
                    subscription &&
                    subscription.publication &&
                    subscription.publication.identity &&
                    subscription.publication.identity.name
                  ) {
                    const publicationUri = subscription.publication.identity.name
                    const systemUri = publicationUri.substr(0, publicationUri.indexOf('/publications'))
                    const topologyUri = systemUri + '/topologies/' + obj.name
                    logger.info('Publication:getTabsProps:Link', obj, publicationUri, systemUri, topologyUri)
                    this.props.history?.push('/view' + topologyUri)
                  }
                }
              }
            ],

        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add topology',
                onClick: () => {
                  //return {name: "New dataset"};
                  this.setState({
                    editingTopologySubscription: true,
                    editingTopologySubscriptionId: 0
                  })
                  //this.setState({editingDatasetSubscriptionId: 0});
                  return false
                }
              }
            ],topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  //return {name: "New dataset"};
                  this.setState({
                    editingTopologySubscription: true,
                    editingTopologySubscriptionId: 0
                  })
                  //this.setState({editingDatasetSubscriptionId: 0});
                  return false
                }
              }
            ],
        emptyText: (
          <div className="MajorObject__emptyOuter">
            <div>
              No topologies found
              <br />
              Add topologies you want to subscribe
            </div>
          </div>
        )
        //onSave: this.interfacesChange.bind(this)
      })
    }

    tabs.push({
      title: 'history',
      filters: [],
      columns: [],
      tableComponent: ObjectHistoryTable,
      parentObject: subscription
    })

    return tabs
  }

  openInterfaceWindow(id, editMode) {
    console.log('Subscription:openInterfaceWindow', id, editMode)

    if (editMode)
      this.setState({
        editingInterfaceSubscription: true,
        editingInterfaceSubscriptionId: id
      })
    else
      this.setState({
        viewingInterfaceSubscription: true,
        viewingInterfaceSubscriptionId: id
      })
  }

  openTopologyWindow(id, editMode) {
    console.log('Subscription:openTopologyWindow', id, editMode)

    if (editMode)
      this.setState({
        editingTopologySubscription: true,
        editingTopologySubscriptionId: id
      })
    else
      this.setState({
        viewingTopologySubscription: true,
        viewingTopologySubscriptionId: id
      })
  }

  openDatasetWindow(id, editMode) {
    console.log('Subscription:openDatasetWindow', id, editMode)

    if (editMode)
      this.setState({
        editingDatasetSubscription: true,
        editingDatasetSubscriptionId: id
      })
    else
      this.setState({
        viewingDatasetSubscription: true,
        viewingDatasetSubscriptionId: id
      })
  }

  deleteSubscriptionInterface(interfaceToDelete) {
    const subscription = this.getObject()
    let subApiEndpoint = idAPI.subscriptions(subscription.identity.id)

    //console.log("deleteSubscriptionDataset", index, subscription.datasets);

    sendObjectNew(
      subApiEndpoint,
      'put',
      this.props.actions,
      Object.assign({}, subscription, {
        interfaces: subscription.interfaces.filter((ds, i) => ds.identity.id !== interfaceToDelete.identity.id)
      })
    ).then(() => {
      // this.props.actions.deleteSubscriptionDataset(subscription.identity.id, index);
      this.setState({ datasetEditMode: false, deleteChildObject: null })
    })
  }

  deleteSubscriptionDataset(datasetToDelete) {
    const subscription = this.getObject()
    let subApiEndpoint = idAPI.subscriptions(subscription.identity.id)

    //console.log("deleteSubscriptionDataset", index, subscription.datasets);

    sendObjectNew(
      subApiEndpoint,
      'put',
      this.props.actions,
      Object.assign({}, subscription, {
        datasets: subscription.datasets.filter((ds, i) => ds.identity.id !== datasetToDelete.identity.id)
      })
    ).then(() => {
      // this.props.actions.deleteSubscriptionDataset(subscription.identity.id, index);
      this.setState({ datasetEditMode: false, deleteChildObject: null })
    })
  }

  deleteSubscriptionTopology(topologyToDelete) {
    const subscription = this.getObject()
    let subApiEndpoint = idAPI.subscriptions(subscription.identity.id)

    //console.log("deleteSubscriptionDataset", index, subscription.datasets);

    sendObjectNew(
      subApiEndpoint,
      'put',
      this.props.actions,
      Object.assign({}, subscription, {
        topologies: subscription.topologies.filter((ds, i) => ds.identity.id !== topologyToDelete.identity.id)
      })
    ).then(() => {
      // this.props.actions.deleteSubscriptionDataset(subscription.identity.id, index);
      this.setState({ datasetEditMode: false, deleteChildObject: null })
    })
  }

  sendSubscription = (newSubscription, closeDialog, onSent) => {
    // console.log("Dataset:saveDataset", newDataset);

    return sendObjectNew(
      idAPI.subscriptions(newSubscription.identity.id),
      'put',
      this.props.actions,
      this.getObject(),
      newSubscription
    ).then(
      (result) => {
        if (onSent) onSent(closeDialog)
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  saveInterfaceSubscription(intf, close, onSent) {
    const subscription = this.getObject()
    const subApiEndpoint = idAPI.subscriptions(subscription.identity.id)

    let interfaceInPublication = deepCopy(intf)

    subApiEndpoint
      .patch({
        interfaces: subscription.interfaces.filter((ds) => ds.identity.id !== intf.identity.id).concat([intf])
      })
      .then(
        () => {
          // Misha: this call was causing Restore button to appear. When the dialog was closed externally (from parent component), it could not call setState(clearLocalObject)
          /*
      this.setState({
        editingInterfaceSubscription: false,
        viewingInterfaceSubscription: false
      });
      */
          getObjectNew(subApiEndpoint, this.state.objectType, this.props.actions, true)
          if (onSent) onSent(close, '')
        },
        (error) => {
          if (onSent) onSent(close, error)
        }
      )
    //this.setState({      interfaceEditMode: false});
  }

  saveDatasetSubscription(dataset, close, onSent) {
    const subscription = this.getObject()
    const subApiEndpoint = idAPI.subscriptions(subscription.identity.id)

    let datasetInPublication: any = {}
    datasetInPublication.identity = dataset.identity
    datasetInPublication.fieldnames = dataset.fieldnames || dataset.fieldNames
    datasetInPublication.layoutNames = dataset.layoutNames
    datasetInPublication.version = dataset.version

    console.log('saveDatasetSubscription', dataset, datasetInPublication)

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    subApiEndpoint
      .patch({
        datasets: subscription.datasets
          .filter((ds) => ds.identity.id !== dataset.identity.id)
          .concat([datasetInPublication])
      })
      .then(() => {
        /*
      this.setState({
        editingDatasetSubscription: false,
        viewingDatasetSubscription: false
      });
      */
        getObjectNew(subApiEndpoint, this.state.objectType, this.props.actions, true)
        if (onSent) onSent(close, '')
      }),
      (error) => {
        if (onSent) onSent(close, error)
      }
    this.setState({ datasetEditMode: false })
  }

  saveTopologySubscription(top, close, onSent) {
    const subscription = this.getObject()
    const subApiEndpoint = idAPI.subscriptions(subscription.identity.id)

    let topologyInPublication = deepCopy(top)

    subApiEndpoint
      .patch({
        topologies: subscription.topologies.filter((ds) => ds.identity.id !== top.identity.id).concat([top])
      })
      .then(
        () => {
          // Misha: this call was causing Restore button to appear. When the dialog was closed externally (from parent component), it could not call setState(clearLocalObject)
          /*
      this.setState({
        editingInterfaceSubscription: false,
        viewingInterfaceSubscription: false
      });
      */
          getObjectNew(subApiEndpoint, this.state.objectType, this.props.actions, true)
          if (onSent) onSent(close, '')
        },
        (error) => {
          if (onSent) onSent(close, error)
        }
      )
    //this.setState({      interfaceEditMode: false});
  }

  getData = () => {
    const subscription = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    let id = subscription.identity.id

    return this.getTabsProps().map((block) => {
      if (subscription[block.title]) {
        block.data = subscription[block.title]
          .map((ds) => {
            if (block.title === 'datasets') {
              return this.getPublishedDataset(this.props.appState, ds, id)
            } else if (block.title === 'interfaces') {
              return this.getPublishedInterface(this.props.appState, ds, id)
            } else if (block.title === 'topologies') {
              return this.getPublishedTopology(this.props.appState, ds, id)
            } else {
              let ret = getObjectById(this.props.appState, block.title, ds)
              if (ret.identity) {
                ret.id = ret.identity.id
                ret.name = ret.identity.name
                ret.description = ret.identity.description
              }
              return ret
            }
          })
          .filter((ret) => ret !== null)
      } else {
        block.data = []
      }

      if (block.title === 'history') {
        block.data = [
          {
            versions: subscription.versions,
            checkpoints: subscription.checkpoints,
            loadingCheckpoints: this.state.loadingCheckpoints,
            restore: this.restoreFromCheckpoint
          }
        ]
        return block
      }

      block.data.sort(majorObjectSort)

      return block
    })
  }

  getPublication = (subscription) => {
    return subscription.publication
      ? getObjectById(this.props.appState, 'publications', subscription.publication.identity.id)
      : {}
  }

  getPublishedDataset(appState, dataset, parentId) {
    let ret = deepCopy(dataset)
    let dsRef = this.props.majorObject.datasets.find((ds) => ds.identity.id === ret.identity.id)

    if (ret.identity) {
      ret.id = ret.identity.id
      ret.name = ret.identity.name
      ret.description = ret.identity.description
    }

    let fieldNames = ret.fieldnames || ret.fieldNames || []

    if (ret.schemaOnly || fieldNames.length === 0) ret.fields = ['As structure']
    else ret.fields = fieldNames

    ret.version = ret.version.major + '.*.*'
    ret.layouts = ret.layoutNames

    return ret
  }

  getPublishedInterface(appState, inter, parentId) {
    let ret = deepCopy(inter)
    if (ret.identity) {
      ret.id = ret.identity.id
      ret.name = ret.identity.name
      ret.description = ret.identity.description
    }
    ret.version = ret.version.major + '.*.*'
    ret.layouts = ret.layoutNames //(ret.layoutNames) ? ret.layoutNames.toString() : '';
    ret.operations = ret.operationNames //(ret.operationNames) ? ret.operationNames.toString() : '';

    //console.log("Subscription:getPublishedInterface", ret);
    return ret
  }

  getPublishedTopology(appState, top, parentId) {
    let ret = deepCopy(top)
    if (ret.identity) {
      ret.id = ret.identity.id
      ret.name = ret.identity.name
      ret.description = ret.identity.description
    }
    ret.version = ret.version.major + '.*.*'
    ret.instances = ret.instanceNames //(ret.operationNames) ? ret.operationNames.toString() : '';

    //console.log("Subscription:getPublishedTopology", ret);
    return ret
  }

  closeDatasetSubscription() {
    this.setState({
      editingDatasetSubscription: false,
      viewingDatasetSubscription: false,
      datasetEditMode: false
    })
  }

  closeInterfaceSubscription() {
    this.setState({
      editingInterfaceSubscription: false,
      interfaceEditMode: false
    })
  }

  closeTopologySubscription() {
    this.setState({
      editingTopologySubscription: false,
      topologyEditMode: false
    })
  }

  restoreFromCheckpoint = (data) => {
    this.sendSubscription(Object.assign({}, this.getObject(), data), null, (tmp, error) => {
      if (!error) alert('Subscription was restored!')
      else alert(error)
    })
  }

  render() {
    const subscription = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    logger.info('Subscription:render', subscription, this.state, this.props)

    if (!subscription || subscription.isFetching) {
      //console.log("subscription: ", subscription);
      return this.renderLoading()
    } else {
      let id = subscription.identity.id
      const publication = this.getPublication(subscription)
      //console.log("subscription: ", subscription);
      const isEditable = this.checkEditable()

      let editingRow: any = null
      if (this.state.editingDatasetSubscription) {
        let dataset = findById(subscription.datasets, this.state.editingDatasetSubscriptionId)
        editingRow = (
          <DatasetSubscription
            appState={this.props.appState}
            actions={this.props.actions}
            id={this.state.editingDatasetSubscriptionId}
            subscription={subscription}
            publication={publication}
            majorObject={subscription}
            minorObject={dataset}
            isVisible
            isEditable={editableState.EDITING}
            onClose={this.closeDatasetSubscription.bind(this)}
            onSave={this.saveDatasetSubscription.bind(this)}
          />
        )
      }

      if (this.state.viewingDatasetSubscription) {
        console.log('Subscription:render:viewingDatasetSubscription', this.state.viewingDatasetSubscriptionId)
        let dataset = findById(subscription.datasets, this.state.viewingDatasetSubscriptionId)
        editingRow = (
          <DatasetSubscription
            appState={this.props.appState}
            actions={this.props.actions}
            id={this.state.viewingDatasetSubscriptionId}
            publicationId={id}
            subscription={subscription}
            publication={publication}
            majorObject={subscription}
            isVisible
            isEditable={this.checkEditable() ? 1 : 0}
            minorObject={dataset}
            onClose={() => {
              this.setState({ viewingDatasetSubscription: false })
            }}
            onSave={this.saveDatasetSubscription.bind(this)}
          />
        )
      }

      if (this.state.editingInterfaceSubscription) {
        let intf = findById(subscription.interfaces, this.state.editingInterfaceSubscriptionId)
        console.log('editingInterfaceSubscription', intf)
        editingRow = (
          <InterfaceSubscription
            appState={this.props.appState}
            actions={this.props.actions}
            id={this.state.editingInterfaceSubscriptionId}
            subscription={subscription}
            publication={publication}
            majorObject={subscription}
            minorObject={intf}
            isVisible
            isEditable={editableState.EDITING}
            onClose={this.closeInterfaceSubscription.bind(this)}
            onSave={this.saveInterfaceSubscription.bind(this)}
          />
        )
      }

      if (this.state.viewingInterfaceSubscriptionId) {
        let intf = findById(subscription.interfaces, this.state.viewingInterfaceSubscriptionId)
        editingRow = (
          <InterfaceSubscription
            appState={this.props.appState}
            actions={this.props.actions}
            id={this.state.viewingInterfaceSubscriptionId}
            publicationId={id}
            subscription={subscription}
            publication={publication}
            majorObject={subscription}
            minorObject={intf}
            isVisible
            isEditable={this.checkEditable() ? 1 : 0}
            onClose={() => {
              this.setState({ viewingInterfaceSubscription: false })
            }}
            onSave={this.saveInterfaceSubscription.bind(this)}
          />
        )
      }

      if (this.state.editingTopologySubscription) {
        let top = findById(subscription.topologies, this.state.editingTopologySubscriptionId)
        console.log('editingTopologySubscription', top)
        editingRow = (
          <TopologySubscription
            appState={this.props.appState}
            actions={this.props.actions}
            id={this.state.editingTopologySubscriptionId}
            subscription={subscription}
            publication={publication}
            majorObject={subscription}
            minorObject={top}
            isVisible
            isEditable={editableState.EDITING}
            onClose={this.closeTopologySubscription.bind(this)}
            onSave={this.saveTopologySubscription.bind(this)}
          />
        )
      }

      if (this.state.viewingTopologySubscriptionId) {
        let top = findById(subscription.topologies, this.state.viewingTopologySubscriptionId)
        editingRow = (
          <TopologySubscription
            appState={this.props.appState}
            actions={this.props.actions}
            id={this.state.viewingTopologySubscriptionId}
            publicationId={id}
            subscription={subscription}
            publication={publication}
            majorObject={subscription}
            minorObject={top}
            isVisible
            isEditable={this.checkEditable() ? 1 : 0}
            onClose={() => {
              this.setState({ viewingTopologySubscription: false })
            }}
            onSave={this.saveTopologySubscription.bind(this)}
          />
        )
      }

      return (
        <div className="MajorObject__outer">
          <section className={'MajorObjectView MajorObjectView_' + this.state.objectType}>
            {this.renderHeader()}
            {this.renderInfo()}

            <div className="MajorObjectView__connections">
              {editingRow}
              <div className="row">
                <div className="col-xs-12">
                  {this.renderBackArrowTabs()}
                  <FilteredTabbedTable
                    /*
                    // @ts-ignore */
                    tablesData={this.getData()}
                    theme={this.state.objectType}
                    tabsLoaded={this.state.tabsLoaded}
                    parentUri={getFullUri(subscription)}
                    onTabChange={this.onTabChange}
                  />
                  {this.renderFinalizedMessage()}
                </div>
              </div>
            </div>
          </section>
          {subscription && subscription.identity && subscription.identity.id ? (
            <Messages
              ref="messages"
              appState={this.props.appState}
              userState={this.props.userState}
              actions={this.props.actions}
              object={subscription}
              objectType={this.state.objectType}
              objectReload={this.reload.bind(this)}
              objectChain={this.props.match.params}
              currentUserRole={this.state.currentUserRole}
            />
          ) : null}
        </div>
      )
    }
  }
}
