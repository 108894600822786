/**
 * Created by mailf on 22.08.2016.
 *
 * Represent dataset information. View of dataset.
 */
import React, { RefObject } from 'react'

import logger from '../../helpers/logger'
import {
  API,
  idAPI,
  getObjectNew,
  getObjectsWithConnected,
  getRequestFromPath,
  sendObjectNew,
  getApiResource
} from '../../helpers/api'
import {
  columnsToType,
  nonCSCompare,
  getFullUri,
  objectNameFromPathByType,
  formatForStructureTable,
  isDark,
  pluralTypeForms,
  deepCopy,
  orderSort
} from '../../helpers/index'
import { getObjectByName } from '../../helpers/data'
import { MajorObjectVersioned } from '../MajorObjectVersioned/MajorObjectVersioned'
import FilteredTabbedTable from '../FilteredTabbedTable/FilteredTabbedTable'
//import KeySettings from '../KeySettings/KeySettings';
import { FieldDialog } from '../FieldEditor/FieldDialog'
import { LayoutDialog } from '../LayoutDialog/LayoutDialog'
import { Messages } from '../Messages/Messages'
import { StructureTable } from '../NewTable/StructureTable'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import { ExampleDialog } from '../ExampleDialog/ExampleDialog'
import { ObjectHistoryTable } from '../ObjectHistoryTable/ObjectHistoryTable'
import { editableState } from '../../helpers/index'

import './style.scss'
import KeysDialog from './KeysDialog'
import { ImportDataDialog } from './ImportDataDialog'
import { DataDialogNew } from '../DataDialog/DataDialogNew'

import iKey from '../../resources/images/key-2x.png'
import { getBottomButtonLabel, getTailButtonLabel } from '../../helpers/helperComponents'
import { Tab } from '../FilteredTabbedTable/TabType'
const csv = require('csv')

export class Dataset extends MajorObjectVersioned {
  filteredtabbedtableRef: RefObject<any>
  messagesRef: RefObject<any>

  constructor(props) {
    super(props)

    // @ts-ignore
    this.state = {
      editingChild: false,
      keySettingsOpen: false,
      importingData: false,
      importSettings: {
        delimiter: ',',
        quote: '"',
        escape: '"',
        replaceData: false
      }
    }

    // @ts-ignore
    this.state.objectType = 'dataset'
    // @ts-ignore
    this.state.objectName = this.getObjectName(this.state.objectType)
    // @ts-ignore
    this.state.recordsToDelete = null

    this.filteredtabbedtableRef = React.createRef()
    this.messagesRef = React.createRef()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location?.pathname) {
      const newState = {
        objectType: 'dataset',
        objectName: this.props.match.params[this.getObjectType(this.props) + 'Name'],
        recordsToDelete: null
      }

      this.setState(newState, () => {
        getObjectsWithConnected(
          this.getDataRequestQuery(this.props),
          this.state.objectType,
          this.state.objectName,
          this.props.actions
        )
      })
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!this.props.userState.profile && props.userState.profile) {
      this.loadUserRole(props)
    }

    // transition between datasets has multiple steps: Clear app state, create zero object, receive object
    // so we need to perform request and update object name at correct moment: when old object is erased
    if (
      !props.majorObject &&
      this.props.majorObject &&
      this.props.majorObject.identity.id !== 0 &&
      props.location.pathname !== this.props.location?.pathname
    ) {
      const newState = {
        objectType: 'dataset',
        objectName: props.match.params[this.getObjectType(props) + 'Name'],
        recordsToDelete: null
      }

      // console.log("Dataset::componentWillReceiveProps will reload");
      //console.log("newState", newState);

      this.setState(newState, () => {
        getObjectsWithConnected(
          this.getDataRequestQuery(props),
          this.state.objectType,
          this.state.objectName,
          this.props.actions
        )
      })
    }
  }

  componentDidMount() {
    const dataset = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    if (dataset && dataset.identity.id) {
      this.props.actions.updateMajorObject(dataset.identity.id, pluralTypeForms.get(this.state.objectType), {
        isFetching: true
      })
    }

    getObjectsWithConnected(
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  /**
   * Delete field from dataset
   * @param fieldId
   */
  deleteField = (fieldId) => {
    logger.log('Dataset:deleteField', fieldId, this.getObject(), this.props)

    const newDataset = Object.assign({}, this.getObject())
    const fieldIndex = newDataset.structure.fields.findIndex((field) => field.identity.id === fieldId)

    newDataset.structure.fields = newDataset.structure.fields.filter((field) => field.identity.id !== fieldId)

    newDataset.data.records = newDataset.data.records.map((record) => {
      const newValues = record.values
      newValues.splice(fieldIndex, 1)

      return Object.assign(record, {
        values: newValues
      })
    })

    this.sendDataset(newDataset)
  }

  showDeleteField = (fieldId, fieldName) => {
    this.setState({
      deleteChildObject: {
        objectType: 'field',
        obj: { identity: { name: fieldName } },
        delete: () => {
          this.setState({ deleteChildObject: false })
          this.deleteField(fieldId)
        }
      }
    })
  }

  /**
   * Save interface with new or updated layout
   * @param {object} layout - new or updated layout
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   */
  saveLayout = (layout, closeDialog, onSent) => {
    // console.log("Interface:saveParameter", layout, closeDialog);

    const newDataset = Object.assign({}, { identity: this.getObject().identity, layouts: [layout] })

    this.saveDataset(newDataset, closeDialog, onSent)
  }

  /**
   * Save new set of records from import dialog
   * @param {array} records - new set of data records
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   */
  saveRecords = (records, closeDialog, onSent) => {
    const dataset = this.getObject()
    const newDataset = Object.assign({}, dataset, {
      data: { examples: dataset.data.examples, records: records }
    })

    this.sendDataset(newDataset, closeDialog, onSent)
  }

  /**
   * Save dataset new fields from import dialog
   * @param {array} fields - new list of fields
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   */
  saveFields = (fields, closeDialog, onSent) => {
    const dataset = this.getObject()
    const newDataset = Object.assign({}, dataset, {
      structure: { fields: fields, keys: dataset.structure.keys }
    })

    this.sendDataset(newDataset, closeDialog, onSent)
  }

  deleteRecord = (recordIndex) => {
    const dataset = this.getObject()
    const newDataset = Object.assign({}, dataset, {
      data: {
        examples: dataset.data.examples,
        records: dataset.data.records.filter((r) => r.index !== recordIndex)
      }
    })
    // delete so that data will be updated
    // in normal update reducer keeps data unchanged
    this.props.actions.deleteMajorObject('dataset', dataset.identity.id)
    this.sendDataset(newDataset)
  }

  /**
   * Save interface with new or updated example
   * @param {object} example - new or updated example
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   */
  saveExample = (example, closeDialog, onSent) => {
    // console.log("Dataset:saveExample", example, closeDialog);

    const newDataset = Object.assign({}, { identity: this.getObject().identity, data: { examples: [example] } })

    this.saveDataset(newDataset, closeDialog, onSent)
  }

  deleteExample = (exampleName) => {
    const dataset = this.getObject()
    //console.log("Dataset:deleteExample", exampleName, dataset.data);
    const newDataset = Object.assign({}, dataset, {
      data: {
        records: dataset.data.records,
        examples: dataset.data.examples.filter(
          (r) => (r.identity && r.identity.name !== exampleName) || (!r.identity && exampleName !== '')
        )
      }
    })
    // delete so that data will be updated
    // in normal update reducer keeps data unchanged
    this.props.actions.deleteMajorObject('dataset', dataset.identity.id)
    this.sendDataset(newDataset)
  }

  showDeleteExample = (exampleName) => {
    this.setState({
      deleteChildObject: {
        objectType: 'example',
        obj: { identity: { name: exampleName } },
        delete: () => {
          this.setState({ deleteChildObject: false })
          this.deleteExample(exampleName)
        }
      }
    })
  }

  /**
   * Delete layout from dataset
   * @param layoutId
   */
  deleteLayout = (layoutId) => {
    // console.log("Dataset:deleteLayout", layoutId);

    const newDataset = Object.assign({}, this.getObject())

    newDataset.layouts = newDataset.layouts.filter((layout) => layout.identity.id !== layoutId)

    this.sendDataset(newDataset)
  }

  /**
   * Save dataset update using patch action
   * @param {object} [newDataset] - new dataset with changes only
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   */
  saveDataset = (newDataset, closeDialog, onSent) => {
    // console.log("Dataset:saveDataset", newDataset);

    return sendObjectNew(
      idAPI.datasets(newDataset.identity.id),
      'patch',
      this.props.actions,
      this.getObject(),
      newDataset
    ).then(
      (result) => {
        if (onSent) {
          onSent(closeDialog)
        }
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) {
          onSent(closeDialog, error)
        }
      }
    )
  }

  /**
   * Send new state of dataset by using put action
   * @param {object} [newDataset] - new dataset with all data
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {function} [onSent] - call back function to report status of update
   */
  sendDataset = (newDataset, closeDialog?, onSent?) => {
    // console.log("Dataset:sendDataset", newDataset);

    // TODO: use patch for updating see onKeysChange
    return sendObjectNew(idAPI.datasets(newDataset.identity.id), 'put', this.props.actions, newDataset).then(
      (result) => {
        if (onSent) {
          onSent(closeDialog)
        }
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) {
          onSent(closeDialog, error)
        }
      }
    )
  }

  closeEditDialog = () => {
    this.setState(
      {
        editingChild: false,
        childObjectType: '',
        activeMinorObject: null
      },
      () => {}
    )
  }

  moveItemInArray = (arr, from, to) => {
    // get current element
    let element = arr[from]
    // remove him from array
    arr.splice(from, 1)
    // insert its in new position
    arr.splice(to, 0, element)
    return arr
  }

  /**
   * Save new state of data record after editing
   */
  saveEditDialog = (data, index, closeDialog, onSent) => {
    // Current dataset we update data record for
    const dataset = this.getObject()
    const newDataset = {
      identity: dataset.identity,
      structure: deepCopy(dataset.structure),
      data: deepCopy(dataset.data),
      type: 'dataset'
    }
    let record = null
    logger.info(
      'Dataset:saveEditDialog',
      data,
      index,
      dataset,
      newDataset,
      this.state.recordToEditId,
      this.state,
      this.props
    )

    if (data) {
      record = dataset.structure.fields
        .sort((a, b) => {
          const ao = parseInt(a.order)
          const bo = parseInt(b.order)

          if (ao > bo) {
            return 1
          } else if (bo > ao) {
            return -1
          }
          return 0
        })
        .map((field) => {
          let type = field.type ? field.type.toLowerCase() : 'string'
          let typeLowerCase = type.toLowerCase()
          let valueData = data ? data[field.identity.name] : null
          let isStructure =
            typeLowerCase === 'structure' ||
            typeLowerCase === 'reference' ||
            field.count === 0 ||
            field.count > 1 ||
            typeof valueData === 'object'
          //console.log("take from state.dataObject field",field.identity.name,valueData);
          //console.log("DataDialog::onSave", field, valueData);

          return valueData ? (isStructure ? JSON.stringify(valueData) : valueData) : ''
        })
    }

    // Update dataset based on information came from data dialog
    if (index <= dataset.data.records.length) {
      // Replace record or insert record inside dataset record list
      if (this.state.recordToEditId + 1 === index) {
        // We simply replace record with new data for the record
        newDataset.data.records = dataset.data.records.map((row, i) => {
          // logger.info("Dataset:saveEditDialog:REPLACE", row, i);
          return i === this.state.recordToEditId
            ? { values: record, index: i + 1 }
            : { values: row.values, index: i + 1 }
        })
      } else {
        // Save new data for editable record in dataset
        dataset.data.records[this.state.recordToEditId].values = record

        // We add this record in place it ask and push rest down.
        let list = this.moveItemInArray(dataset.data.records, this.state.recordToEditId, index - 1)

        // now update index for all items in array
        list.forEach((item, idx) => (item.index = idx + 1))

        // logger.info("Dataset:saveEditDialog:MOVE-RESULT", list);
        newDataset.data.records = list
      }
    } else {
      // Add new record to the record list.
      let count = dataset.data.records.length
      newDataset.data.records = dataset.data.records
        .map((row, i) => {
          //logger.info("Dataset:saveEditDialog:ADD", row, i);
          return { values: row.values, index: i + 1 }
        })
        .concat({ index: count + 1, values: record })
    }

    //logger.info("Dataset:saveEditDialog:DATASET", index, newDataset, closeDialog, onSent, this.state);

    // Send to service to update.
    this.sendDataset(newDataset, closeDialog, onSent)
  }

  getDatasetApiRequest = (props = this.props) => {
    if (props.match.params.applicationName) {
      return API.organizations(props.match.params.organizationName)
        .systems(props.match.params.systemName)
        .applications(props.match.params.applicationName)
        .datasets(props.match.params.datasetName)
    } else {
      return API.organizations(props.match.params.organizationName).datasets(props.match.params.datasetName)
    }
  }

  getDataRequestQuery(props, version?) {
    const dsApiEndpoint = version
      ? this.getDatasetApiRequest(props).versions(version)
      : this.getDatasetApiRequest(props)

    const connectedObjectsEndpoint = version ? this.getDatasetApiRequest(props) : dsApiEndpoint

    const dataRequestQuery = {
      endpoint: this.getDatasetApiRequest(props), // used for finalization, MajorObjectVersioned::onFinalize
      objectRequest: props.match.params.versionName
        ? dsApiEndpoint.versions(props.match.params.versionName)
        : dsApiEndpoint,
      objectRequestCallback: (obj, errorStatus) => {
        this.setState({ errorStatus: errorStatus })
        return true
      },
      connectedObjectsRequests: [
        {
          objectType: 'version',
          isObjectProperty: true,
          majorObjectType: 'dataset',
          propertyName: 'versions',
          request: connectedObjectsEndpoint.versions(),
          connectedObjectsRequestCallback: () => {
            if (this.state.loadingCheckpoints) {
              this.requestCheckpoints().then(() => {
                this.setState({ loadingCheckpoints: false })
              })
            }
          }
        },
        /*
        {
          'objectType': 'issue',
          clearList: true,
          request: connectedObjectsEndpoint.issues()

        },
        */
        {
          objectType: 'user',
          majorObjectType: 'dataset',
          isObjectProperty: true,
          propertyName: 'users',
          request: connectedObjectsEndpoint.users(),
          connectedObjectsRequestCallback: this.checkUserRole(props)
        }
      ]
    }

    //console.log("Dataset::getDataRequestQuery", dataRequestQuery);

    // organization's dataset
    if (!props.match.params.applicationName) {
      dataRequestQuery.objectRequestCallback = (dataset) => {
        // console.log("Dataset 2: objectRequestCallback", objectNameFromPathByType(dataset.object.parent.name, 'organizations'), props.match.params.organizationName);

        let isApdaxDataset =
          !dataset.object ||
          !dataset.object.parent ||
          objectNameFromPathByType(dataset.object.parent.name, 'organizations') !== props.match.params.organizationName

        console.log('Dataset::getDataRequestQuery isApdaxDataset', isApdaxDataset)
        if (isApdaxDataset) {
          console.log('Dataset::getDataRequestQuery objectRequestCallback dataset of organization')
          // dataset of organization has parent = organization
          getObjectNew(API.organizations(props.match.params.organizationName)).then((org) => {
            console.log('Dataset::getDataRequestQuery objectRequestCallback org', org)
            props.actions.updateMajorObject(dataset.identity.id, 'datasets', {
              object: Object.assign({}, dataset.object, {
                parent: {
                  id: org.identity.id,
                  name: getFullUri(org)
                }
              }),
              _path: '/organizations/' + org.identity.name + '/datasets/' + dataset.identity.name
            })
          })
        }

        // if dataset's parent organization is not equal current organization, it means that it is default Apdax's dataset
        // so we can't request it's users and issues, it must first be copied to current organization
        return !isApdaxDataset
      }
    }

    return dataRequestQuery
  }

  getActualData = () => {
    const dataset = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    let actualData = []
    if (dataset && dataset.data) {
      actualData = dataset.data.records
      //console.log("Dataset::getTabsProps actual data (dataset records)", actualData);
    }

    return actualData
  }

  pasteChild = (params, closeDialog, onSent) => {
    const ds = this.getObject()

    let newObject = JSON.parse(localStorage.clipboard)
    newObject.identity.name = params.objectName // this.state.pastingChildName;

    if (localStorage.clipboardType === 'field') {
      const newStructure = Object.assign({}, this.props.majorObject.structure)
      newStructure.fields.push(newObject)
      this.sendDataset(Object.assign({}, ds, { structure: newStructure }), closeDialog, onSent)
    } else if (localStorage.clipboardType === 'layout') {
      const newLayouts = this.props.majorObject.layouts.slice()
      newLayouts.push(newObject)
      this.sendDataset(Object.assign({}, ds, { layouts: newLayouts }), closeDialog, onSent)
    } else if (localStorage.clipboardType === 'example') {
      const newData = Object.assign({}, this.props.majorObject.data)
      newData.examples.push(newObject)
      this.sendDataset(Object.assign({}, ds, { data: newData }), closeDialog, onSent)
    } else if (localStorage.clipboardType === 'data') {
      const newData = Object.assign({}, this.props.majorObject.data)
      newData.records.push(newObject)
      this.sendDataset(Object.assign({}, ds, { data: newData }), closeDialog, onSent)
    }
  }

  getDataTabWidth = (field, dataset, width) => {
    let length = dataset.structure && dataset.structure.fields ? dataset.structure.fields.length : 1

    if (length === 1) {
      return width
    }

    let index =
      dataset.structure && dataset.structure.fields
        ? dataset.structure.fields.findIndex((field) => nonCSCompare(field.usage, 'description'))
        : -1
    let column = index === -1 ? ((width - 100) / length) | 0 : ((width - 100) / (length + 1)) | 0
    column = Math.max(100, column)

    if (!field) {
      // Index column
      return width - (index === -1 ? length * column : (length + 1) * column)
    } else if (nonCSCompare(field.usage, 'description')) {
      // We are calculating for description
      return 2 * column
    } else {
      // We are calculating for any other field
      return column
    }
  }

  expandAllStructure = () => {
    if (this.filteredtabbedtableRef.current && this.filteredtabbedtableRef.current.refs.table_structure) {
      this.filteredtabbedtableRef.current.refs.table_structure.toggleAllRows()
      setTimeout(() => {
        this.forceUpdate()
      }, 0)
    }
  }

  isExpandAllStructure = () => {
    if (this.filteredtabbedtableRef.current && this.filteredtabbedtableRef.current.refs.table_structure) {
      const rows = this.filteredtabbedtableRef.current.refs.table_structure.state.expandedRows
      //console.log("dataset::isExpandAllStructure", rows.length,this.filteredtabbedtableRef.current.refs.table_structure.props.data.length, rows.reduce((all,cur) => all && cur, true) );
      if (
        rows.length === this.filteredtabbedtableRef.current.refs.table_structure.props.data.length &&
        rows.reduce((all, cur) => all && cur, true)
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  toggleExpandDataDescriptions = (fieldName) => () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        ['expandDataDescriptions_' + fieldName]: !prevState['expandDataDescriptions_' + fieldName]
      }
    })
  }

  renderDataDescriptionColumnHeader(fieldName) {
    return (
      <span>
        {fieldName}&nbsp;
        <a
          className={
            'MajorObject__expandButton ' +
            (isDark(this.state.objectType) ? ' MajorObject__expandButton__dark ' : '') +
            (this.state['expandDataDescriptions_' + fieldName] ? 'MajorObject__expandButton__active' : '')
          }
          onClick={this.toggleExpandDataDescriptions(fieldName)}
        />
      </span>
    )
  }

  getDataDescriptionType = (fieldName) => {
    return {
      name: this.state['expandDataDescriptions_' + fieldName] ? 'text_expanded' : 'text'
    }
  }

  getTabsProps = (): Tab[] => {
    const dataset = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable = this.checkEditable()
    const isDataEditable =
      isEditable &&
      (!dataset.external || dataset.external === 'false' || (dataset.object && dataset.object.usage === 'Enum'))

    const actualData: any[] = this.getActualData()

    return [
      {
        tableComponent: StructureTable,
        objectPath: getFullUri(dataset),
        title: 'structure',
        // filters: ['tags', 'type', 'updated', 'updatedBy'],
        filters: [
          { name: '', width: 64 + 52 + 256 - 190 },
          { name: 'search', width: 300 },
          { name: '', width: 10 },
          { name: 'usage', width: 110 } /*, 'type'*/
        ],
        columns: [
          {
            displayName: (
              <button
                className={'btn_key ' + (!isEditable ? 'btn_key__disabled' : '')}
                onClick={() => this.setState({ keySettingsOpen: true })}
              >
                <img src={iKey} alt="" />
              </button>
            ),
            name: 'key',
            type: columnsToType.getType('key'),
            frozen: true,
            width: 59 + 5,
            hiddenInChildTable: true
          },
          {
            displayName: 'Ord.',
            name: 'order',
            type: columnsToType.getType('id'),
            frozen: true,
            width: 42,
            hiddenInChildTable: true,
            customDisplayInChildTable: true
          },
          {
            displayName: (
              <span>
                <a
                  className={
                    'MajorObject__expandStructureButton ' +
                    (isDark(this.state.objectType) ? ' MajorObject__expandStructureButton__dark ' : '') +
                    (this.isExpandAllStructure() ? 'MajorObject__expandStructureButton__active' : '')
                  }
                  onClick={this.expandAllStructure}
                ></a>
              </span>
            ),
            name: 'expandButton',
            type: { name: 'data' },
            frozen: true,
            width: 30
          },
          {
            name: 'name',
            displayName: 'Field name',
            type: columnsToType.getType('minorObjectName'),
            onCellClick: (value, rowIndex) => {
              // console.log("Field name onCellClick", value);
              let field = dataset.structure.fields.reduce(
                (p, f) => (f.identity.name === value.identity.name ? f : p),
                null
              )
              let editable = this.checkEditable()

              if (!field) {
                return
              }

              this.setState({
                editingChild: true,
                childObjectType: 'field',
                activeMinorObject: field,
                activeMinorObjectEditable: editable,
                childInEditMode: false
              })
            },
            width: 226 + 7 + 28 - 5 - 5
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 316
          },
          {
            name: 'usage',
            type: columnsToType.getType('string'),
            frozen: true,
            width: 100
          },
          {
            name: 'required',
            displayName: 'Req.',
            type: columnsToType.getType('required'),
            frozen: true,
            width: 40
          },
          {
            name: 'type',
            type: columnsToType.getType('typeReference'),
            frozen: true,
            width: 282 - 27 + 18
          },
          {
            name: 'subscriptions',
            type: columnsToType.getType('pubsub'),
            displayName: 'Pub/Sub',
            width: 70
          }
        ],
        contextMenuButtons: [
          {
            label: 'Copy field',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              // console.log("Dataset::Copy field", data);
              const field = dataset.structure.fields.reduce(
                (p, c) => (c.identity.name === data.identity.name ? c : p),
                null
              )
              // console.log("Found field", field);
              localStorage.clipboard = JSON.stringify(field)
              localStorage.clipboardType = 'field'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste field',
                  data: { action: 'paste' },
                  onClick: () => {
                    //console.log("Paste dataset");

                    if (!localStorage.clipboard) {
                      alert('No field copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'field') {
                      alert('No field copied')
                      return
                    }

                    this.setState({
                      pastingChild: true,
                      pastingChildName: JSON.parse(localStorage.clipboard).identity.name
                    })
                  },
                  // @ts-ignore
                  showInBottom: true
                },
                {
                  label: 'Reorder fields',
                  data: { action: 'reorder' },
                  onClick: () => {
                    const ds = this.getObject()
                    let newStructure = Object.assign({}, ds.structure, {
                      fields: ds.structure.fields
                        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
                        .map((field, index) => {
                          return Object.assign({}, field, { order: index + 1 })
                        })
                    })

                    //this.saveDataset({identity: ds.identity, structure: newStructure});
                    this.sendDataset(Object.assign({}, ds, { structure: newStructure }))
                  }
                },
                {
                  label: 'Reorder by 10 step',
                  data: { action: 'reorder' },
                  onClick: () => {
                    const ds = this.getObject()

                    let delta = 1
                    let newStructure = Object.assign({}, ds.structure, {
                      fields: ds.structure.fields
                        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
                        .map((field, index) => {
                          return Object.assign({}, field, {
                            order: index === 0 ? delta : (delta += 10)
                          })
                        })
                    })

                    //this.saveDataset({identity: ds.identity, structure: newStructure});
                    this.sendDataset(Object.assign({}, ds, { structure: newStructure }))
                  }
                }
              ]
        ),
        //@ts-ignore
        nextLvlTail:[
              {
              label: getTailButtonLabel('View'),
            onClick: (obj) => {
              const { expandButton, expandedBody, hasExpandedBody, ...newObj } = obj;
              newObj.type = obj.type.displayType;
              newObj.nextLevel=true
               this.setState({
                  activeMinorObject:newObj,
                  editingChild: true,
                  childObjectType: 'field',
                  activeMinorObjectEditable: false,
                  childInEditMode: false,
                })
              }
            }
        ],
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (obj) =>
                  this.setState({
                    editingChild: true,
                    childObjectType: 'field',
                    activeMinorObject: dataset.structure.fields.filter((f) => f.identity.name === obj.name.name)[0],
                    activeMinorObjectEditable: false,
                    childInEditMode: false
                  })
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (obj) =>
                  this.setState({
                    editingChild: true,
                    childObjectType: 'field',
                    activeMinorObject: dataset.structure.fields.filter((f) => f.identity.name === obj.name.name)[0],
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: true
                  })
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (obj) => this.showDeleteField(obj.id, obj.identity.name)
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add field',
                onClick: () => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'field',
                    activeMinorObjectEditable: true,
                    childInEditMode: true
                  })
                }
              },
              {
                label: getBottomButtonLabel('export', 'Export fields'),
                onClick: () => this.exportFieldsToCSV()
              },
              {
                label: getBottomButtonLabel('import', 'Import fields'),
                onClick: () =>
                  this.setState({
                    importingData: 'fields',
                    loadingDataError: ''
                  })
              }
            ],
        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'field',
                    activeMinorObjectEditable: true,
                    childInEditMode: true
                  })
                }
              }
            ]
      },
      {
        title: 'layouts',
        filters: [
          { name: '', width: 250 - 205 },
          { name: 'search', width: 290 },
          { name: '', width: 110 },
          { name: 'platform', width: 250 } /*, 'type'*/
        ],
        columns: [
          {
            name: 'name',
            displayName: 'Layout name',
            type: columnsToType.getType('minorObjectName'),
            onCellClick: (value, rowIndex) => {
              this.setState({
                editingChild: true,
                childObjectType: 'layout',
                activeMinorObject: dataset.layouts.find((f) => f.identity.name === value.identity.name),
                activeMinorObjectEditable: this.checkEditable(),
                childInEditMode: false
              })
            },
            width: 250
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 400
          },
          {
            name: 'platform',
            type: columnsToType.getType('string'),
            width: 250
          },
          { name: 'unit', type: columnsToType.getType('string'), width: 285 }
        ],
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (value) =>
                  this.setState({
                    editingChild: true,
                    childObjectType: 'layout',
                    activeMinorObject: dataset.layouts.find((f) => f.identity.name === value.identity.name),
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: false
                  })
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (value, rowIndex) => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'layout',
                    activeMinorObject: dataset.layouts.find((f) => f.identity.name === value.identity.name),
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: true
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (value) => this.deleteLayout(value.identity.id)
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add layout',
                onClick: () => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'layout',
                    activeMinorObjectEditable: true,
                    childInEditMode: true
                  })
                }
              }
            ],
        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'layout',
                    activeMinorObjectEditable: true,
                    childInEditMode: true
                  })
                }
              }
            ],
        contextMenuButtons: [
          {
            label: 'Copy layout',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              // console.log("Dataset::Copy field", data);
              const layout = dataset.layouts.reduce((p, c) => (c.identity.name === data.identity.name ? c : p), null)
              // console.log("Found field", field);
              localStorage.clipboard = JSON.stringify(layout)
              localStorage.clipboardType = 'layout'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste layout',
                  data: { action: 'paste' },
                  onClick: () => {
                    //console.log("Paste dataset");

                    if (!localStorage.clipboard) {
                      alert('No layout copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'layout') {
                      alert('No layout copied')
                      return
                    }

                    this.setState({
                      pastingChild: true,
                      pastingChildName: JSON.parse(localStorage.clipboard).identity.name
                    })
                  },
                  //@ts-ignore
                  showInBottom: true
                }
              ]
        )
      },
      {
        title: 'data',

        noSetWidth: true,
        filters: [
          { name: '', width: 10 },
          {
            name: 'search',
            width: 290,
            searchFields:
              dataset.structure && dataset.structure.fields
                ? dataset.structure.fields
                    .filter((f) => f.usage === 'Name' || f.usage === 'Description')
                    .map((f) => {
                      return f.identity.name
                    })
                : ['Description']
          },
          { name: '', width: 630 }
        ],
        columns: [
          {
            name: '_index',
            displayName: '#',
            type: { name: 'integer' },
            width: this.getDataTabWidth(null, dataset, 50)
          }
        ].concat(
          dataset.structure && dataset.structure.fields
            ? dataset.structure.fields
                .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
                .slice(0, 25)
                .map((f) => {
                  if (!f || !f.identity) return { name: '' }
                  let displayName = f.identity.name
                  let type: any = 'String' // remove this quick dirty fix
                  if (f.type)
                    type = Object.assign(
                      {},
                      columnsToType.getType(f.type.toLowerCase()),
                      f.reference ? { reference: f.reference } : {}
                    )
                  if (f.usage === 'Description') {
                    displayName = this.renderDataDescriptionColumnHeader(f.identity.name)
                    type = this.getDataDescriptionType(f.identity.name)
                  }
                  return {
                    name: f.identity.name,
                    displayName: displayName,
                    type: type,
                    width: this.getDataTabWidth(f, dataset, 1184)
                  }
                })
            : []
        ),
        tailButtons: !isDataEditable
          ? []
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (row) => {
                  const actualData = this.getActualData()
                  //console.log("Dataset::Data::onEditRow", row, actualData);
                  this.setState({
                    editingChild: true,
                    childObjectType: 'record',
                    recordToEdit: actualData.filter((r, i) => i === row.id),
                    recordToEditId: row.id
                  })
                }
              },
              {
                label: 'Delete',
                onClick: (obj) => {
                  // console.log("Dataset::Data delete", obj);
                  //this.deleteRecord(obj._index);
                  this.setState({ recordsToDelete: obj._index })
                  /*
        // todo: fix Delete
        console.log("Dataset:getTabsProps:DATA:DELETE", index);
        let records = this.state.recordsToDelete;
        this.setState({
          recordsToDelete: (!records) ? index : records + ',' + index
        }, ()=>{
          setTimeout(()=>{
            0;
          }, 0);
        });
        return true;
        */
                }
              }
            ],
        bottomButtons: !isDataEditable
          ? []
          : [
              {
                label: '+ Add Record',
                onClick: () => {
                  // todo: fix Add
                  let id = actualData.reduce((p, d) => Math.max(p, d.index), 0)
                  //console.log("Dataset::Data::onEditRow", id, actualData);
                  setTimeout(() => {
                    // open Data Dialog
                    this.setState({
                      editingChild: true,
                      childObjectType: 'record',
                      recordToEdit: actualData.filter((r, i) => i === id),
                      recordToEditId: id
                    })
                  }, 0)

                  return true
                }
              },
              {
                label: getBottomButtonLabel('export', 'Export records'),
                onClick: () => {
                  this.exportRecordsToCSV()
                }
              },
              {
                label: getBottomButtonLabel('import', 'Import records'),
                onClick: () => {
                  this.setState({
                    importingData: 'records',
                    loadingDataError: ''
                  })
                }
              }
            ],
        topButtons: !isDataEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  // todo: fix Add
                  let id = actualData.reduce((p, d) => Math.max(p, d.index), 0)
                  setTimeout(() => {
                    // open Data Dialog
                    this.setState({
                      editingChild: true,
                      childObjectType: 'record',
                      recordToEdit: actualData.filter((r, i) => i === id),
                      recordToEditId: id
                    })
                  }, 0)

                  return true
                }
              }
            ],
        onSave: (data) => {},
        contextMenuButtons: [
          {
            label: 'Copy data',
            data: { action: 'copy' },
            onClick: (tmp, obj) => {
              //console.log("Dataset::Copy data", a, b, c);
              //const row = (dataset.data && dataset.data.records) ? dataset.data.records.reduce((p,c) => c.identity && c.identity.name === data.identity.name ? c : p, null) : {};
              // console.log("Found field", field);

              let row: any = []
              dataset.structure.fields.forEach((field) => {
                row.push(obj[field.identity.name])
              })

              localStorage.clipboard = JSON.stringify(row)
              localStorage.clipboardType = 'data'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste data',
                  data: { action: 'paste' },
                  onClick: () => {
                    //console.log("Paste dataset");

                    if (!localStorage.clipboard) {
                      alert('No data copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'data') {
                      alert('No data copied')
                      return
                    }

                    const newData = Object.assign({}, this.getObject().data)
                    let maxIndex = 0
                    dataset.data.records.forEach((rec) => {
                      if (rec.index > maxIndex) maxIndex = rec.index
                    })
                    newData.records.push({
                      index: maxIndex + 1,
                      values: JSON.parse(localStorage.clipboard)
                    })
                    this.sendDataset(Object.assign({}, dataset, { data: newData }))
                  },
                  // @ts-ignore
                  showInBottom: true
                }
              ]
        )
      },
      {
        title: 'examples',
        filters: [
          { name: '', width: 250 - 205 },
          { name: 'search', width: 290 },
          { name: '', width: 60 },
          { name: 'format', width: 100 }
        ],
        columns: [
          {
            name: 'name',
            displayName: 'Example name',
            type: columnsToType.getType('minorObjectName'),
            onCellClick: (value, rowIndex) => {
              this.setState({
                editingChild: true,
                childObjectType: 'example',
                activeMinorObject:
                  dataset.data && dataset.data.examples
                    ? dataset.data.examples.find((f) => f.identity && f.identity.name === value.identity.name)
                    : {},
                activeMinorObjectEditable: this.checkEditable(),
                childInEditMode: false
              })
            },
            width: 250
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 350
          },
          { name: 'format', type: columnsToType.getType('string'), width: 100 },
          {
            name: 'text',
            type: columnsToType.getType('description'),
            width: 485
          }
        ],
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (value) =>
                  this.setState({
                    editingChild: true,
                    childObjectType: 'example',
                    activeMinorObject:
                      dataset.data && dataset.data.examples
                        ? dataset.data.examples.find((f) => f.identity && f.identity.name === value.identity.name)
                        : {},
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: false
                  })
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (value, rowIndex) => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'example',
                    activeMinorObject:
                      dataset.data && dataset.data.examples
                        ? dataset.data.examples.find((f) => f.identity && f.identity.name === value.identity.name)
                        : {},
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: true
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (value) => this.showDeleteExample(value.identity.name)
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add example',
                onClick: () => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'example',
                    activeMinorObjectEditable: true,
                    childInEditMode: true
                  })
                }
              }
            ],
        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'example',
                    activeMinorObjectEditable: true,
                    childInEditMode: true
                  })
                }
              }
            ],
        contextMenuButtons: [
          {
            label: 'Copy example',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              // console.log("Dataset::Copy field", data);
              const example =
                dataset.data && dataset.data.examples
                  ? dataset.data.examples.reduce(
                      (p, c) => (c.identity && c.identity.name === data.identity.name ? c : p),
                      null
                    )
                  : {}
              // console.log("Found field", field);
              localStorage.clipboard = JSON.stringify(example)
              localStorage.clipboardType = 'example'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste example',
                  data: { action: 'paste' },
                  onClick: () => {
                    //console.log("Paste dataset");

                    if (!localStorage.clipboard) {
                      alert('No example copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'example') {
                      alert('No example copied')
                      return
                    }

                    this.setState({
                      pastingChild: true,
                      pastingChildName: JSON.parse(localStorage.clipboard).identity.name
                    })
                  },
                  // @ts-ignore
                  showInBottom: true
                }
              ]
        )
      },
      {
        title: 'history',
        filters: [],
        columns: [],
        tableComponent: ObjectHistoryTable,
        parentObject: dataset
      }
    ]
  }

  getData = () => {
    const dataset = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    //console.log("Dataset:getData", dataset, this.state, this.props);

    return this.getTabsProps().map((block) => {
      switch (block.title) {
        case 'structure':
          block.data = formatForStructureTable(dataset)
          return block
        case 'layouts':
          block.data = dataset.layouts
            ? dataset.layouts.map((layout) => {
                const newName = {
                  name: layout.identity.name,
                  objectType: 'layout',
                  path: layout.path
                }
                layout.description = layout.identity.description
                layout = Object.assign({}, layout, { name: newName })

                return layout
              })
            : []
          return block
        case 'examples':
          block.data =
            dataset.data && dataset.data.examples
              ? dataset.data.examples.map((example) => {
                  const newName = {
                    name: example.identity ? example.identity.name : '',
                    objectType: 'example',
                    path: example.path
                  }
                  example.description = example.identity ? example.identity.description : ''
                  example = Object.assign({}, example, { name: newName })

                  return example
                })
              : []
          return block
        case 'data':
          block.data =
            dataset.data && dataset.data.records
              ? dataset.data.records
                  .sort((a, b) => (a.index > b.index ? 1 : a.index < b.index ? -1 : 0))
                  .map((record, index) => {
                    const res: any = {
                      id: index
                    }
                    for (let i = 0; i < record.values.length; i++) {
                      if (dataset.structure.fields[i] && dataset.structure.fields[i].identity) {
                        let field = dataset.structure.fields[i].identity.name
                        res[field] = record.values[i]
                      }
                    }
                    res._index = record.index
                    return res
                  })
              : []
          return block
        case 'history':
          block.data = [
            {
              versions: dataset.versions,
              checkpoints: dataset.checkpoints,
              loadingCheckpoints: this.state.loadingCheckpoints,
              restore: this.restoreFromCheckpoint
            }
          ]
          return block
        default:
          break
      }
      return block
    })
  }

  exportFieldsToCSV = () => {
    const dataset = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    let csvContent = 'data:text/csv;charset=utf-8,'
    let dataString = ''

    // Systems of this organization.
    let data: any[] = []
    if (dataset && dataset.structure && dataset.structure.fields) {
      dataset.structure.fields.forEach((field) => {
        data.push({
          order: field.order,
          name: field.identity.name,
          description: field.identity.description,
          usage: field.usage,
          count: field.count,
          type: field.type,
          reference: field.reference,
          subscription: field.subscription,
          size: field.size,
          precision: field.precision,
          scale: field.scale,
          optional: field.optional,
          value: field.value,
          format: field.format,
          locale: field.locale,
          access: field.access,
          keys: field.keys,
          privace: field.privace,
          rules: field.rules,
          properies: field.properties
        })
        data.sort(orderSort)
      })
    }

    logger.info('Dataset:exportFieldsToCSV', data)

    csv.stringify(
      data,
      {
        header: true,
        columns: [
          'order',
          'name',
          'description',
          'usage',
          'count',
          'type',
          'reference',
          'subscription',
          'size',
          'precision',
          'scale',
          'optional',
          'value',
          'format',
          'locale',
          'access',
          'keys',
          'privace',
          'rules',
          'properies'
        ]
      },
      function (err, data) {
        dataString += data
        csvContent += dataString
        //logger.info('Dataset:exportFieldsToCSV:TEXT', data, dataString, csvContent);

        // Create document and download it.
        let encodedUri = encodeURI(csvContent)
        let link = document.createElement('a')

        link.setAttribute('href', encodedUri)
        link.setAttribute(
          'download',
          (dataset && dataset.identity && dataset.identity.name ? dataset.identity.name.replace(/:/g, '%') : 'fields') +
            '.csv'
        )
        document.body.appendChild(link) // Required for FF
        link.click()

        // Cleanup the DOM
        document.body.removeChild(link)
      }
    )
  }

  exportRecordsToCSV = () => {
    const dataset = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    let csvContent = 'data:text/csv;charset=utf-8,'
    let dataString = ''

    // Systems of this organization.
    let data: any[] = []
    if (dataset && dataset.data && dataset.data.records) {
      dataset.data.records.forEach((record) => {
        data.push(record.values)
      })
    }

    logger.info('Dataset:exportRecordsToCSV', dataset, data)

    csv.stringify(
      data,
      {
        header: true,
        columns: dataset.structure.fields.map((field) => {
          return field.identity.name
        })
      },
      function (err, data) {
        dataString += data
        csvContent += dataString
        //logger.info('Dataset:exportFieldsToCSV:TEXT', data, dataString, csvContent);

        // Create document and download it.
        let encodedUri = encodeURI(csvContent)
        let link = document.createElement('a')

        link.setAttribute('href', encodedUri)
        link.setAttribute(
          'download',
          (dataset && dataset.identity && dataset.identity.name ? dataset.identity.name.replace(/:/g, '%') : 'fields') +
            '.csv'
        )
        document.body.appendChild(link) // Required for FF
        link.click()

        // Cleanup the DOM
        document.body.removeChild(link)
      }
    )
  }

  restoreFromCheckpoint = (data) => {
    this.sendDataset(Object.assign({}, this.getObject(), data), null, (tmp, error) => {
      if (!error) {
        alert('Dataset was restored!')
      } else {
        alert(error)
      }
    })
  }

  /**
   * Save edited docs, upload new and delete removed ones
   * @param {array} docs
   */
  onKeysChange(keys, closeDialog, onSent) {
    const majorObject = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    console.log('MajorObject:onKyesChange', keys, majorObject, this.state, this.props)

    // Check if we remove keys:
    let isDeleted = false
    if (majorObject.structure && majorObject.structure.keys && majorObject.structure.keys.length > 0) {
      majorObject.structure.keys.forEach((ckey) => {
        if (isDeleted || keys.findIndex((nkey) => nonCSCompare(ckey.identity.name, nkey.identity.name)) === -1) {
          isDeleted = true
        }
      })
    }

    // Prepare patch or put request to update tags
    //isDeleted = true;
    let method = isDeleted ? 'put' : 'patch'
    let new_obj = isDeleted
      ? deepCopy(majorObject)
      : {
          object: { history: { updated: majorObject.object.history.updated } },
          structure: {}
        }
    new_obj.structure.keys = keys

    // Send update to the service.
    console.log('MajorObject:onKeysChange:SEND', method, new_obj, majorObject)

    // Send update to the service.
    this.props.actions.updateMajorObject(majorObject.identity.id, pluralTypeForms.get(this.state.objectType), {
      structure: {
        fields: majorObject.structure.fields,
        keys: keys
      }
    })
    //this.props.actions.updateMajorObject(majorObject.identity.id, pluralTypeForms.get(this.state.objectType), new_obj.structure);
    sendObjectNew(
      idAPI[getApiResource(this.state.objectType)](majorObject.identity.id),
      method,
      this.props.actions,
      majorObject,
      new_obj
    ).then(
      (result) => {
        console.log('MajorObject:onKeysChange:RESULT', keys, result)
        if (onSent) {
          onSent(closeDialog)
        }
      },
      (error) => {
        console.log('MajorObject:onKeysChange:ERROR', keys, error)
        this.props.actions.setError(null)
        if (onSent) {
          onSent(closeDialog, error)
        }
      }
    )
  }

  saveField = (newField, close, done) => {
    const datasetRequest = getRequestFromPath(getFullUri(this.getObject()))
    const newStructure = Object.assign({}, this.getObject().structure)
    const datasetUpdate: any = {}
    let found = false
    let renamed = false
    let orderChanged = false
    let oldIndex
    let newIndex

    console.log('Dataset:saveField', newField, this.state, this.props)

    for (let i = 0; i < newStructure.fields.length; i++) {
      const field = newStructure.fields[i]

      if (field.identity.name === newField.identity.name) {
        found = true

        if (field.order !== newField.order) {
          orderChanged = true
          oldIndex = i
        }

        newStructure.fields[i] = newField
      } else if (field.identity.id !== '' && field.identity.id === newField.identity.id) {
        found = true
        renamed = true

        if (field.order !== newField.order) {
          orderChanged = true
          oldIndex = i
        }

        newStructure.fields[i] = newField
      }
    }

    if (orderChanged) {
      newStructure.fields = newStructure.fields.sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))

      newIndex = newStructure.fields.findIndex((field) => field.identity.name === newField.identity.name)
      //if (renamed)
      //  newIndex = newStructure.fields.findIndex(field => field.identity.id === newField.identity.id);

      // reorder records accordingly
      datasetUpdate.data = Object.assign({}, this.getObject().data)
      datasetUpdate.data.records = datasetUpdate.data.records.map((record) => {
        const newRecord = Object.assign({}, record)
        newRecord.values.splice(newIndex, 0, newRecord.values.splice(oldIndex, 1)[0])
        return newRecord
      })
    }

    if (renamed) {
      datasetUpdate.data = Object.assign({}, this.getObject().data)
    }

    if (!found) {
      newStructure.fields.push(newField)
    }

    datasetUpdate.structure = newStructure

    let req = renamed ? datasetRequest.put : datasetRequest.patch

    req(datasetUpdate).then(
      () => {
        if (done) done(close)
        getObjectNew(datasetRequest, 'dataset', this.props.actions, true)

        // getObjectsWithConnected(this.getDataRequestQuery(this.props), this.state.objectType, this.state.objectName, this.props.actions, this.props.appState);
        //this.state.activeMinorObject = JSON.parse(JSON.stringify(newField));
      },
      (err) => {
        if (done) {
          this.props.actions.setError(null)
          done(close, err)
        }
      }
    )
  }

  minorObjectChange = (minorObject) => {
    switch (this.state.childObjectType) {
      case 'field':
        this.fieldChange(minorObject)
        break
      default:
        break
    }

    this.setState({
      editingChild: false,
      activeMinorObject: null
    })
  }

  fieldChange = (newField) => {
    const parentObjectCopy = Object.assign(
      {},
      getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    )

    parentObjectCopy.structure.fields = newField.slice()
    // ////console.log("fieldChange", parentObjectCopy, newField);

    this.props.actions.updateMajorObject(
      parentObjectCopy.identity.id,
      pluralTypeForms.get(this.state.objectType),
      parentObjectCopy
    )
  }

  renderImportDataDialog() {
    const dataset = this.getObject()

    return (
      <ImportDataDialog
        appState={this.props.appState}
        actions={this.props.actions}
        isEditable={editableState.EDITING}
        isVisible={this.state.importingData}
        onSave={this.state.importingData === 'records' ? this.saveRecords : this.saveFields}
        onClose={() => this.setState({ importingData: false })}
        majorObject={dataset}
        dataType={this.state.importingData ? this.state.importingData : ''}
      />
    )
  }

  renderKeysDialog() {
    const majorObject = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable = this.checkEditable()

    let keys = majorObject && majorObject.structure && majorObject.structure.keys ? majorObject.structure.keys : []
    //logger.info("Dataset:renderKeysDialog", this, majorObject, keys, isEditable, this.state);

    return (
      <div>
        <KeysDialog
          appState={this.props.appState}
          actions={this.props.actions}
          modalTitle={isEditable ? 'Edit dataset keys' : 'Dataset keys'}
          isVisible
          isEditable={isEditable ? editableState.EDITING : editableState.BROWSABLE}
          majorObject={majorObject}
          onClose={() => this.setState({ keySettingsOpen: false })}
          onSave={this.onKeysChange.bind(this)}
        />
      </div>
    )
  }

  renderDataDialog() {
    const dataset = this.getObject()
    let dataObject = {}
    let dataValues = this.state.recordToEdit[0] ? this.state.recordToEdit[0].values : {}
    logger.info(
      'Dataset:renderDataDialog',
      dataset,
      dataValues,
      this.state.recordToEdit,
      this.state.recordToEditId,
      this.state,
      this.props
    )

    dataset.structure.fields
      .sort((a, b) => {
        const ao = parseInt(a.order)
        const bo = parseInt(b.order)

        if (ao > bo) return 1
        else if (bo > ao) return -1
        return 0
      })
      .forEach((field, fieldIndex) => {
        if (nonCSCompare(field.type, 'structure')) {
          try {
            dataObject[field.identity.name] = dataValues[fieldIndex] ? JSON.parse(dataValues[fieldIndex]) : {}
          } catch (e) {
            console.error(e)
            dataObject[field.identity.name] = {}
          }
        } else dataObject[field.identity.name] = dataValues[fieldIndex]
      })

    return (
      <DataDialogNew
        modalTitle={this.state.recordToEdit[0] ? 'Edit record' : 'Create record'}
        confirmText={this.state.recordToEdit[0] ? 'Update' : 'Create'}
        actions={this.props.actions}
        dataset={dataset}
        data={dataObject}
        index={this.state.recordToEditId + 1}
        isEditable={2}
        isVisible={this.state.editingChild}
        onClose={this.closeEditDialog}
        onSave={this.saveEditDialog}
        majorObject={dataset}
      />
    )
  }

  renderConfirmDeleteRecord() {
    if (this.state.recordsToDelete !== null) {
      return (
        <ModalWindow
          header={'Delete record'}
          canClose
          isOpen
          className="OrganizationList__deleteModal"
          onClose={() => this.setState({ recordsToDelete: null })}
          footer={
            <div className="OrganizationList__deleteButtons">
              <button className="btn_big" onClick={() => this.setState({ recordsToDelete: null })}>
                No
              </button>
              <button
                className="btn_big"
                onClick={() => {
                  this.deleteRecord(this.state.recordsToDelete)
                  this.setState({ recordsToDelete: null })
                }}
              >
                Yes
              </button>
            </div>
          }
        >
          <div className="OrganizationList__deleteMessage">
            <p>Do you really want to delete the record?</p>
          </div>
        </ModalWindow>
      )
    } else {
      return false
    }
  }

  render() {
    const dataset = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    if (!dataset || !dataset.object || dataset.isFetching) {
      return this.renderLoading()
    } else {
      const isEditable = this.checkEditable()
      //logger.info("Dataset:render", dataset.identity.name, this.state.explicitParentRole, { dataset, isEditable }, this.state, this.props);

      return (
        <div className="MajorObject__outer">
          <section className={'MajorObjectView MajorObjectView_' + this.state.objectType}>
            {this.renderHeader()}
            {this.renderInfo()}
            {this.renderConfirmDeleteRecord()}
            {this.state.keySettingsOpen ? this.renderKeysDialog() : null}
            {this.state.importingData ? this.renderImportDataDialog() : null}
            {this.state.editingChild && this.childIsMinor() ? (
              this.state.childObjectType === 'field' ? (
                <FieldDialog
                  appState={this.props.appState}
                  actions={this.props.actions}
                  isEditable={
                    this.state.childInEditMode
                      ? editableState.EDITING
                      : isEditable
                      ? editableState.EDITABLE
                      : editableState.BROWSABLE
                  }
                  isVisible={this.state.editingChild}
                  onSave={this.saveField}
                  onClose={this.closeEditDialog}
                  parentObjectType={this.state.objectType}
                  childObjectType={this.state.childObjectType ? this.state.childObjectType : 'none'}
                  field={this.state.activeMinorObject}
                  majorObject={dataset}
                />
              ) : (
                <LayoutDialog
                  appState={this.props.appState}
                  actions={this.props.actions}
                  modalTitle={this.state.childInEditMode ? 'Edit layout' : 'Layout'}
                  isEditable={
                    this.state.childInEditMode
                      ? editableState.EDITING
                      : isEditable
                      ? editableState.EDITABLE
                      : editableState.BROWSABLE
                  }
                  isVisible={this.state.editingChild}
                  isShowCounters
                  onSave={this.saveLayout}
                  onClose={this.closeEditDialog}
                  layout={this.state.activeMinorObject}
                  majorObject={dataset}
                />
              )
            ) : this.state.childObjectType === 'record' ? (
              this.renderDataDialog()
            ) : null}
            {this.state.childObjectType === 'example' ? (
              <ExampleDialog
                appState={this.props.appState}
                actions={this.props.actions}
                isEditable={this.state.childInEditMode ? 2 : this.checkEditable() ? 1 : 0}
                isVisible={this.state.editingChild}
                onSave={this.saveExample}
                onClose={this.closeEditDialog}
                example={this.state.activeMinorObject}
                majorObject={dataset}
              />
            ) : null}

            <div className="MajorObjectView__connections">
              <div className="row">
                <div className="col-xs-12">
                  {this.renderBackArrowTabs()}
                  {!this.state.explicitParentRole ? (
                    this.renderLoading()
                  ) : (
                    <FilteredTabbedTable
                      ref={this.filteredtabbedtableRef}
                      /*
                      // @ts-ignore */
                      tablesData={this.getData()}
                      theme={this.state.objectType}
                      tabsLoaded={this.state.tabsLoaded}
                      parentUri={getFullUri(dataset)}
                      onTabChange={this.onTabChange}
                    />
                  )}
                  {this.renderFinalizedMessage()}
                </div>
              </div>
            </div>
          </section>
          {dataset && dataset.identity && dataset.identity.id ? (
            <Messages
              appState={this.props.appState}
              userState={this.props.userState}
              actions={this.props.actions}
              ref={this.messagesRef}
              object={dataset}
              currentUserRole={this.state.currentUserRole}
              objectReload={this.reload.bind(this)}
              objectType={this.state.objectType}
              objectChain={this.props.match.params}
            />
          ) : null}
        </div>
      )
    }
  }
}
