/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * Created by kascode on 11.04.2019.
 */
import React from 'react'
import { API, getObjectNew, getObjectsWithConnected, idAPI, sendObjectNew } from '../../helpers/api'
import { getObjectByName } from '../../helpers/data'
import {
  columnsToType,
  editableState,
  getFullUri,
  nonCSCompare,
  orderSort,
  pluralTypeForms,
  versionSort,
  versionToStr
} from '../../helpers/index'
import logger from '../../helpers/logger'
import FilteredTabbedTable from '../FilteredTabbedTable/FilteredTabbedTable'
import { MajorObjectVersioned } from '../MajorObjectVersioned/MajorObjectVersioned'
import { Messages } from '../Messages/Messages'
import { ObjectHistoryTable } from '../ObjectHistoryTable/ObjectHistoryTable'
import { DeploymentChart } from './DeploymentChart'
import { PackageDialog } from './PackageDialog'
import { getTailButtonLabel } from '../../helpers/helperComponents'
import { Tab } from 'components/FilteredTabbedTable/TabType'

//const OLD_DEPLOYMENT = {"identity":{"id":"64a70bc9-b497-469f-900f-0f882f13795e","name":"d6"},"version":{"major":1,"minor":0,"revision":0},"object":{"parent":{"id":"231530ec-5c06-4673-b5e5-02a8b981c116","name":"/organizations/Test org/systems/TS"},"alias":"","picture":"","tags":[],"documents":[],"type":"Deployment","usage":"Resource","access":"External","contact":{"identity":{"id":"","name":"","description":"","translations":[]},"URL":"","EMail":""},"properties":[],"elements":[],"history":{"created":"2020-02-26T11:40:51.377","createdBy":"orzhan057@gmail.com","updated":"2020-02-26T11:40:51.173","updatedBy":"orzhan057@gmail.com","completions":[]},"publicationCount":0,"subscriptionCount":0},"packages":[{"identity":{"id":"d5ce9e98-82cc-433d-891b-905fda4ab0d2","name":"sad","description":"sadsdaas"},"order":1,"source":{"identity":{"name":"$$$$/TestLocation/v1"},"location":null,"format":null,"schedule":null,"schema":null,"policies":null,"rules":null},"metadata":{"identity":{"name":"$$$$/TestLocation/v1"},"location":null,"format":null,"schedule":null,"schema":null,"policies":null,"rules":null},"applications":[{"identity":{"id":"383db1bc-d703-4b5a-940e-a831b6bbfca0","name":"A1","version": {"major": 1, "minor": 0, "revision": 0}, "description":"123456789 1\n123456789 2\n123456789 3\n123456789 4\n123456789 5\n123456789 6\n123456789 7\n123456789 8\n123456789 9\n123456789 10\n123456789 11\n123456789 12\n123456789 13\n123456789 14\n123456789 15\n123456789 16\n123456789 17\n123456789 18\n123456789 19\n123456789 20\n"},"reference":"/organizations/Test org/systems/TS/applications/A1","datasets":[{"reference":"/organizations/Test org/systems/TS/applications/A1/datasets/PO","elements":null},{"reference":"/organizations/Test org/systems/TS/applications/A1/datasets/D3","elements":null},{"reference":"/organizations/Test org/systems/TS/applications/A1/datasets/RU","elements":null}]}]}]};

export class Deployment extends MajorObjectVersioned {
  constructor(props) {
    super(props)

    // @ts-ignore
    this.state = {
      editingChild: null,
      editingChildMode: editableState.EDITING,
      editingChildType: '',
      previousVersion: null,
      packageOld: null
    }

    // @ts-ignore
    this.state.objectType = 'deployment'
    // @ts-ignore
    this.state.objectName = this.getObjectName(this.state.objectType)
    // @ts-ignore
    this.state.showChart = false
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!this.props.userState.profile && props.userState.profile) {
      this.loadUserRole(props)
    }

    if (
      !props.majorObject &&
      this.props.majorObject &&
      this.props.majorObject.identity.id !== 0 &&
      props.location.pathname !== this.props.location?.pathname
    ) {
      //console.log("Publication::componentWillReceiveProps new request", props.majorObject, this.props.majorObject);

      const newState = {
        objectType: 'deployment',
        objectName: props.match.params[this.getObjectType(props) + 'Name'],
        recordsToDelete: null
      }

      //this.clearTabsContentLoaded(['packages']);            // show Loader for object table

      this.setState(newState, () => {
        getObjectsWithConnected(
          this.getDataRequestQuery(props),
          this.state.objectType,
          this.state.objectName,
          this.props.actions
        )
      })
    }
  }

  componentDidMount() {
    // const sys = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName);
    const sys = this.getObject()

    if (sys && sys.identity.id) {
      this.props.actions.updateMajorObject(sys.identity.id, pluralTypeForms.get(this.state.objectType), {
        isFetching: true
      })
    }

    // this.clearTabsContentLoaded(['packages']);          // show Loader for object table
    getObjectsWithConnected(
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  getApiRequest = (props = this.props) => {
    const { params } = props.match
    const { organizationName, systemName, deploymentName } = params

    return API.organizations(organizationName).systems(systemName).deployments(deploymentName)
  }

  getDataRequestQuery(props, version?) {
    const { params } = props.match
    const { versionName } = params

    const deploymentApiEndpoint = this.getApiRequest(props)

    const connectedObjectsEndpoint = this.getApiRequest(props)

    return {
      endpoint: this.getApiRequest(props),
      objectRequest: versionName ? deploymentApiEndpoint.versions(versionName) : deploymentApiEndpoint,
      objectRequestCallback: (obj, errorStatus) => {
        /*
        if (obj.object && obj.object.parent) {
          localStorage.setItem('currentOrganization', obj.object.parent.id);
        }
        */
        this.setState({ errorStatus: errorStatus })
        return true
      },
      connectedObjectsRequests: [
        {
          objectType: 'version',
          isObjectProperty: true,
          majorObjectType: 'deployment',
          propertyName: 'versions',
          request: connectedObjectsEndpoint.versions(),
          connectedObjectsRequestCallback: (majorObject, versionObjects) => {
            if (this.state.loadingCheckpoints) {
              this.requestCheckpoints().then(() => {
                this.setState({ loadingCheckpoints: false })
              })
            }

            const versions = versionObjects
              .sort((a, b) => versionSort(a.version, b.version))
              .map((v) => versionToStr(v.version))

            logger.log('Deployment:getDataRequestQuery:versions', versions, versionName)
            // find the last version:
            if (versions.length > 0) {
              const myVersion = versionName || versions[0]
              const prevVersion = versions.length > 1 ? versions[1] : ''
              logger.log('Deployment:getDataRequestQuery:version', myVersion, prevVersion)
              if (prevVersion) {
                getObjectNew(this.getApiRequest(props).versions(prevVersion), 'deployment').then((previousDeployment) =>
                  this.setState({ previousVersion: previousDeployment })
                )
              } else {
                this.setState({ previousVersion: null }) // todo: insert null here when Deployment versions are fixed in Backend/DB
              }
            } else {
              this.setState({ previousVersion: null })
            }
          }
        },
        {
          objectType: 'user',
          majorObjectType: 'deployment',
          isObjectProperty: true,
          propertyName: 'users',
          request: connectedObjectsEndpoint.users(),
          connectedObjectsRequestCallback: this.checkUserRole(props)
        }
        /*
        {
          'objectType': 'issue',
          clearList: true,
          request: API.organizations(props.match.params.organizationName).systems(props.match.params.systemName).issues()

        },
        */
      ]
    }
  }

  getTabsProps = (): Tab[] => {
    // eslint-disable-next-line no-unused-vars
    const deployment = this.getObject()
    const isEditable = this.checkEditable()

    return [
      {
        title: 'packages',
        filters: [
          { name: '', width: 65 },
          { name: 'search', width: 300 },
          { name: '', width: 225 },
          { name: 'platform', width: 120 }
        ],
        columns: [
          {
            displayName: 'Ord.',
            name: 'order',
            type: columnsToType.getType('id'),
            frozen: true,
            width: 42,
            hiddenInChildTable: true
          },
          {
            name: 'name',
            displayName: 'Package Name',
            type: columnsToType.getType('minorObjectName'),
            width: 226,
            onCellClick: (value) => {
              this.setState({
                editingChild: true,
                childObjectType: 'package',
                activeMinorObject: this.getPackage(value.identity.name),
                activeMinorObjectEditable: this.checkEditable(),
                childInEditMode: false,
                packageOld: this.getPackage(value.identity.name, this.state.previousVersion)
              })
            }
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 500
          },
          {
            name: 'applications',
            type: columnsToType.getType('string'),
            width: 420
          }
        ],
        tailButtons: !isEditable
          ? []
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (obj) => {
                  console.log('Deployment:getTabsProps:EDIT', obj)
                  this.setState({
                    editingChild: true,
                    childObjectType: 'package',
                    activeMinorObject: this.getPackage(obj.identity.name),
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: this.checkEditable(),
                    packageOld: this.getPackage(obj.identity.name, this.state.previousVersion)
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (obj) => {
                  //this.deleteElement(obj.identity.id)
                  console.log('Delete', obj)
                  this.showDeletePackage(obj.identity.id, obj.identity.name)
                }
              }
            ],
        contextMenuButtons: [
          {
            label: 'Copy package',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              // console.log("Dataset::Copy field", data);
              const packageData = this.getPackage(data.identity.name)
              localStorage.clipboard = JSON.stringify(packageData)
              localStorage.clipboardType = 'package'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste package',
                  data: { action: 'paste' },
                  onClick: () => {
                    if (!localStorage.clipboard) {
                      alert('No package copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'package') {
                      alert('No package copied')
                      return
                    }

                    this.setState({
                      pastingChild: true,
                      pastingChildName: JSON.parse(localStorage.clipboard).identity.name
                    })
                  },
                  //@ts-ignore
                  showInBottom: true
                },
                {
                  label: 'Reorder packages',
                  data: { action: 'reorder' },
                  onClick: () => {
                    const deployment = this.getObject()
                    let newDeployment = Object.assign({}, deployment, {
                      packages: deployment.packages.sort(orderSort).map((inst, index) => {
                        return Object.assign({}, inst, { order: index + 1 })
                      })
                    })
                    this.sendDeployment(newDeployment)
                  }
                },
                {
                  label: 'Reorder by 10 step',
                  data: { action: 'reorder' },
                  onClick: () => {
                    const deployment = this.getObject()

                    let delta = 1
                    let newDeployment = Object.assign({}, deployment, {
                      packages: deployment.packages.sort(orderSort).map((inst, index) => {
                        return Object.assign({}, inst, {
                          order: index === 0 ? delta : (delta += 10)
                        })
                      })
                    })
                    this.sendDeployment(newDeployment)
                  }
                }
              ]
        ),
        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () =>
                  this.setState({
                    editingChild: true,
                    activeMinorObject: this.createPackage(),
                    childObjectType: 'package',
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: true
                  })
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add package',
                onClick: () =>
                  this.setState({
                    editingChild: true,
                    activeMinorObject: this.createPackage(),
                    childObjectType: 'package',
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: true
                  })
              }
            ]
      },
      {
        title: 'history',
        filters: [],
        columns: [],
        tableComponent: ObjectHistoryTable,
        parentObject: this.getObject()
      }
    ]
  }

  getData = () => {
    const deployment = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    let getObjs

    return this.getTabsProps().map((block) => {
      switch (block.title) {
        case 'packages':
          block.data = deployment.packages
            ? deployment.packages.map((inst) => ({
                ...inst,
                name: {
                  name: inst.identity.name,
                  objectType: 'package',
                  path: inst.path
                },
                description: inst.identity.description,
                applications: inst.applications.map((app) => app.identity.name).join(', ')
              }))
            : []
          block.data.sort(orderSort)
          //console.log("Deployment:getData:PACKAGES", block);
          break
        case 'history':
          block.data = [
            {
              versions: deployment.versions,
              checkpoints: deployment.checkpoints,
              loadingCheckpoints: this.state.loadingCheckpoints,
              restore: this.restoreFromCheckpoint
            }
          ]
          break
        default:
          // eslint-disable-next-line no-unused-vars
          getObjs = () => []
      }

      return block
    })
  }

  /**
   * Get package by name from deployment
   * @param {string} packageName - name of the package we looking for
   * @param {object} deploymentObject - optional from which version take package
   * @return {object} package
   */
  getPackage(packageName, deploymentObject = null) {
    const deployment = deploymentObject || this.getObject()
    logger.log('Deployment::getPackage', packageName, deploymentObject, deployment)
    if (!deployment) return null

    return deployment.packages.find((f) => nonCSCompare(f.identity.name, packageName))
  }

  /**
   * Create new package for editing
   * @return {object} empty element
   */
  createPackage = () => {
    const deployment = this.getObject()
    let order = 0
    if (!order) {
      if (deployment.packages) {
        deployment.packages.forEach((f) => {
          order = order < f.order ? f.order : order
        })
      }
      order += 1
    }
    return { identity: { name: '' }, order: order, applications: [] }
  }

  /**
   * Delete package
   * @param {string} id - id of the package we looking for
   * @param {string} name - name of the package we looking for
   * @return {object} empty element
   */
  deletePackage = (id, name = '') => {
    const newDeployment = Object.assign({}, this.getObject())

    const oldPackages = this.getObject().packages
    if (id) {
      newDeployment.packages = oldPackages.filter((inst) => inst.identity.id !== id)
    } else if (name) {
      newDeployment.packages = oldPackages.filter((inst) => !nonCSCompare(inst.identity.name, name))
    }
    console.log('Deployment:deletePackage', id, name, oldPackages, newDeployment.packages)

    this.sendDeployment(newDeployment, null, null)
  }

  /**
   * Show package delete dialog
   * @param {string} id - id of the package we looking for
   * @param {string} elementName - name of the package we looking for
   * @return {object} package
   */
  showDeletePackage = (id, elementName) => {
    this.setState({
      deleteChildObject: {
        objectType: 'package',
        obj: { identity: { name: elementName } },
        delete: () => {
          this.setState({ deleteChildObject: false })
          this.deletePackage(id, elementName)
        }
      }
    })
  }

  /**
   * Close package  dialog
   * @return
   */
  closeEditDialog = () => {
    this.setState(
      {
        editingChild: false,
        childObjectType: '',
        activeMinorObject: null
      },
      () => {}
    )
  }

  /**
   * Save package in deployment
   * @param {object} package - package to save
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   * @return
   */
  savePackage = (packageData, closeDialog, onSent) => {
    const deployment = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    console.log('Deployment:savePackage', deployment, packageData, this.state)

    if (this.state.activeMinorObject && deployment.packages) {
      // Update existend package
      const newPackages = deployment.packages
        .filter((inst) => !nonCSCompare(inst.identity.name, packageData.identity.name))
        .concat(packageData)
      const newDeployment = { ...deployment, packages: newPackages }

      this.sendDeployment(newDeployment, closeDialog, onSent)
    } else {
      // Create new Package.
      const newDeployment = Object.assign({}, { identity: this.getObject().identity, packages: [packageData] })

      this.saveDeployment(newDeployment, closeDialog, onSent)
    }
  }

  /**
   * Save Deployment update using patch action
   * @param {object} [newDeployment] - new Deployment with changes only
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   * @return
   */
  saveDeployment = (newDeployment, closeDialog, onSent) => {
    console.log('Deployment:saveDeployment', newDeployment)

    return sendObjectNew(
      idAPI.deployments(newDeployment.identity.id),
      'patch',
      this.props.actions,
      this.getObject(),
      newDeployment
    ).then(
      (result) => {
        if (onSent) onSent(closeDialog)
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  /**
   * Send new state of Deployment by using put action
   * @param {object} [newDeployment] - new Deployment with all data
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {function} [onSent] - call back function to report status of update
   * @return
   */
  sendDeployment = (newDeployment, closeDialog?, onSent?) => {
    console.log('Deployment:sendDeployment', newDeployment)

    return sendObjectNew(idAPI.deployments(newDeployment.identity.id), 'put', this.props.actions, newDeployment).then(
      (result) => {
        if (onSent) onSent(closeDialog)
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  restoreFromCheckpoint = (data) => {
    this.sendDeployment(
      Object.assign({}, this.getObject(), data, {
        checkpoints: [],
        versions: []
      }),
      null,
      (tmp, error) => {
        if (!error) alert('Deployment was restored!')
        else alert('Error')
      }
    )
  }

  /**
   * render Chart view button and Chart view window
   * @returns {XML[]}
   */
  renderRightMenu() {
    const deployment = this.getObject()
    return (
      <React.Fragment>
        {this.renderShowJson()}
        <br />
        {this.renderDownloadPDF()}
        <br />
        <span>
          {deployment && deployment.object ? (
            <div className="MajorObjectView__showChart">
              <span>
                <a className="" onClick={() => this.setState({ showChart: true })}>
                  &nbsp;Chart&nbsp;
                </a>
              </span>
            </div>
          ) : null}
        </span>
      </React.Fragment>
    )
  }

  renderChart() {
    const deployment = this.getObject()
    return <DeploymentChart deployment={deployment} onClose={() => this.setState({ showChart: false })} />
  }

  render() {
    const deployment = this.getObject()

    if (!deployment || deployment.isFetching) {
      return this.renderLoading()
    } else {
      return (
        <div className="MajorObject__outer">
          <section className={'MajorObjectView MajorObjectView_' + this.state.objectType}>
            {this.renderHeader()}
            {this.renderInfo()}

            <div className="MajorObjectView__connections">
              <div className="row">
                <div className="col-xs-12">
                  {this.renderBackArrowTabs()}
                  <FilteredTabbedTable
                    /*
                    // @ts-ignore */
                    tablesData={this.getData()}
                    theme={this.state.objectType}
                    tabsLoaded={this.state.tabsLoaded}
                    parentUri={getFullUri(deployment)}
                  />
                  {this.renderFinalizedMessage()}
                </div>
              </div>
            </div>

            {this.state.editingChild && this.state.childObjectType === 'package' ? (
              <PackageDialog
                appState={this.props.appState}
                actions={this.props.actions}
                modalTitle={'Package deployment ' + versionToStr(this.props.majorObject.version)}
                isEditable={this.state.childInEditMode ? 2 : this.checkEditable() ? 1 : 0}
                isVisible={this.state.editingChild}
                majorObject={deployment}
                deploymentOld={this.state.previousVersion}
                package={this.state.activeMinorObject}
                packageOld={this.state.packageOld}
                onSave={this.savePackage}
                onClose={this.closeEditDialog}
              />
            ) : null}

            {this.state.showChart ? this.renderChart() : null}
          </section>
          {deployment && deployment.identity && deployment.identity.id ? (
            <Messages
              appState={this.props.appState}
              userState={this.props.userState}
              actions={this.props.actions}
              object={deployment}
              objectType={this.state.objectType}
              objectReload={this.reload.bind(this)}
              objectChain={this.props.match.params}
              currentUserRole={this.state.currentUserRole}
            />
          ) : null}
        </div>
      )
    }
  }
}
