/**
 * Created by kascode on 26.04.16.
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import DropZone from 'react-dropzone'
import { ObjectPicture } from '../ObjectPicture/ObjectPicture'

import iLoad from '../../resources/images/Load-pic-deep-blue-2x.png'
import iLoadDark from '../../resources/images/Load-pic-2x.png'

export class ObjectTitle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      inputValue: props.title
    }

    this.dropzoneRef = React.createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      inputValue: props.title
    })
  }

  edit() {
    this.setState({ isEditing: true })
  }

  onChange(event) {
    this.setState({
      inputValue: event.target.value
    })
  }

  save() {
    if (this.props.onSave) {
      this.props.onSave(this.state.inputValue)
    }

    this.setState({ isEditing: false })
  }

  cancel() {
    this.setState({
      inputValue: this.props.title,
      isEditing: false
    })
  }

  addPicture() {
    this.dropzoneRef.current.open()
  }

  drop(files) {
    if (files.length > 0) this.props.onChangePicture(files[0])
  }

  render() {
    const title = this.props.title
    const isEditing = this.state.isEditing
    const picture = this.props.picture

    return (
      <div className="ObjectTitle">
        <div className="row">
          <div className="col ObjectHeader__dropZoneOuter">
            <DropZone ref={this.dropzoneRef} onDrop={this.drop.bind(this)} className="ObjectHeader__DropZone">
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div {...getRootProps()} className={`dropzone${isDragActive ? ' dropzone--isActive' : ''}`}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>...</p>
                  ) : (
                    <ObjectPicture name={title} img={picture} className="ObjectHeader__ObjectPicture" />
                  )}
                </div>
              )}
            </DropZone>
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <div
                  className={'ObjectHeader__loadIcon ' + (this.props.dark ? 'ObjectHeader__loadIconDark' : '')}
                  onClick={this.addPicture.bind(this)}
                >
                  <img src={this.props.dark ? iLoadDark : iLoad} />
                  Load
                </div>
              </div>
              {/*<div className="col">*/}
              {/*{this.props.isEditable ? (<div className="ObjectTitle__control">*/}
              {/*<EditButton theme={this.props.dark ? 'dark' : ''} inEditMode={this.state.isEditing} onSave={this.save.bind(this)} onEdit={this.edit.bind(this)} onCancel={this.cancel.bind(this)} />*/}
              {/*</div>) : null}*/}
              {/*</div>*/}
            </div>
            <div className="ObjectTitle__content">
              <div className="row">
                <div className="col">
                  {isEditing ? (
                    <input
                      type="text"
                      className="TextInput ObjectTitle__input"
                      onChange={this.onChange.bind(this)}
                      value={this.state.inputValue}
                    />
                  ) : (
                    <h1 className="ObjectTitle__title" title={title}>
                      {title ? title : ''}
                    </h1>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ObjectTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  isEditable: PropTypes.bool,
  onChangePicture: PropTypes.func,
  picture: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dark: PropTypes.bool
}
