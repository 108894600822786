/* eslint-disable array-callback-return */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  API,
  getObjectListByUsage,
  getObjectNew,
  getObjectNewOrJson,
  getOrganizationDataset,
  getRequestFromPath
} from '../../helpers/api'
import {
  checkResourceUrl,
  clearLocalObject,
  datasetToEnumOptions,
  deepCopy,
  deepMerge,
  displayDatasetUri,
  editableState,
  getElementCount,
  getFullUri,
  getVersionStatus,
  identitySort,
  itemState,
  listOfMajorObjectsToEnumOptions,
  nonCSCompare,
  objectNameFromPathByType,
  orderSort,
  pathByType,
  propertyValueToString,
  referenceSort,
  reportType,
  updateLocalObject,
  versionCompare
} from '../../helpers/index'
import logger from '../../helpers/logger'
import iMoreData from '../../resources/images/ellipsis-2x-blue.png'
import {
  deepGet,
  deepSet,
  getDatasetMetadata,
  getElementMetadata,
  getFieldMetadata,
  getViewMetadata
} from '../../resources/lib/metadata'
import { DataEditor } from '../DataDialog/DataEditor'
import { PropertyEditor } from '../DataDialog/PropertyEditor'
import { ReferenceEditor } from '../DataDialog/ReferenceEditor'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditButton } from '../EditButton/EditButton'
import { EditorDialog } from './EditorDialog'
import { MultilineText } from '../MultilineText/MultilineText'

/**
 * Base class for all Editor Dialogs. Children must implement:
 * getObjectType()
 * getInitial()
 * renderEditor()
 *
 * Children can implement or not implement:
 * getMinHeight()
 * checkInputErrors()
 */
export class EditorDialogBase extends Component {
  constructor(props) {
    super(props)

    let object = this.getInitial(props)
    let height = this.InitHeight(props)

    const path = getFullUri(this.props.majorObject)
    const organization = objectNameFromPathByType(path, 'organizations')
    //logger.info("EditorDialogBase:constructor:PATH", path, organization, this.state, this.props);

    this.state = {
      organization: organization,
      path: path,

      change: null, // Method to report or cancel modification.
      origin: JSON.stringify(object), // toLowerCase()

      height: height,

      objectType: this.getObjectType(),
      [this.getObjectType()]: object,

      objectDataset: null, // Metadata of dataset presented in dialog
      objectView: null, // Metadata of view definig dialog
      objectForm: null, // Element from metadata to define dialog style
      objectBase: this.props.base, // Base items to provide backgraound for dialog.
      objectTypes: null,
      objectValues: null,
      objectOptions: null,

      objectVersion: props.version,
      objectStructure: null,

      childDialogs: null, // Render function for child content
      uploadContent: [], // List of content for upload
      editMode: false,
      editingChild: null
    }
    //logger.info("EditorDialogBase:constructor", this.state, this.props);

    this._loadingOptions = {} // prevent multiple calls of loadOptions which are slow
  }

  componentDidMount() {
    if (this.props.parentDialog) {
      logger.info('EditorDialogBase:componentDidMount', this.state, this.props)
      this.props.parentDialog.changeChildDialog(this.getObjectType(), this.renderDialog.bind(this))
    }
    this.open()
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const path = getFullUri(newProps.majorObject)
    const organization = objectNameFromPathByType(path, 'organizations')

    let object = this.getInitial(newProps)
    let origin = JSON.stringify(newProps[this.getObjectType()])
    //logger.info("EditorDialogBase:componentWillReceiveProps", path, organization, object, newProps, this.state, this.props);

    if (!versionCompare(newProps.version, this.state.objectVersion)) {
      //logger.info("EditorDialogBase:componentWillReceiveProps:VERSION", newProps, this.state, this.props);

      this.setState(
        {
          organization: organization,
          path: path,

          origin: origin,
          [this.getObjectType()]: object,

          objectVersion: newProps.version
        },
        () => {
          // Adjust dialog state.
          this.changeEditable(newProps.isEditable)
        }
      )
    } else if (!nonCSCompare(origin, this.state.origin) || path !== this.state.path) {
      //logger.info("EditorDialogBase:componentWillReceiveProps:ORIGIN", newProps, this.state, this.props);

      this.setState(
        {
          organization: organization,
          path: path,

          origin: origin,
          [this.getObjectType()]: object
        },
        () => {
          // Adjust dialog state.
          //logger.info("EditorDialogBase:componentWillReceiveProps:ORIGIN-CHANGE", newProps, this.state, this.props);
          this.changeEditable(newProps.isEditable)
        }
      )
    } else if (newProps.isEditable !== this.props.isEditable) {
      //logger.info("EditorDialogBase:componentWillReceiveProps:EDITABLE", newProps, this.state, this.props);
      this.changeEditable(newProps.isEditable)
    } else {
      //logger.info("EditorDialogBase:componentWillReceiveProps:NO-CHANGE", newProps, this.state, this.props);
    }
  }

  /**
   * Load platforms enumerator and set current platform
   * @param (String) platform - platform name.
   * @param (array) maps - list of additional maps to load.
   * @return
   */
  loadPlatform = (platform, maps) => {
    // Load platforms list from organization we are in.
    let organization = this.state.organization

    let platforms = this.state.platforms
    let types = this.state.types
    console.log(
      'EditorDialogBase:loadPlatform',
      this.getObjectType(),
      organization,
      platform,
      platforms,
      types,
      this.props,
      this.state
    )

    if (!platforms || platforms.length === 0) {
      // Need to load platforms
      getOrganizationDataset('Platform', organization).then(
        (platforms) => {
          // Define platforms and current platform types
          let platformOptions = datasetToEnumOptions(platforms, 'Name')
          console.log(
            'EditorDialogBase:loadPlatform:PLATFORMS',
            this.getObjectType(),
            platform,
            platforms,
            platformOptions
          )

          // Load maps for current platform
          if (platform && platform.length > 0) {
            this.loadPlatformMap(platform, platformOptions)
          }

          // Load more types if needed
          if (maps && maps.length > 0) {
            maps.map((type) => this.loadPlatformMap(type, platformOptions))
          }

          // Set platform and platforms
          this.setState({
            platform: platform, // Current platform
            platforms: platforms, // List of supported platforms

            platformOptions: platformOptions // Platform options
          })
        },
        (error) => {
          this.setState({ platform: platform, platformOptions: [] })
          logger.warn('EditorDialogBase:loadPlatformOptions:ERROR', error)
        }
      )
    } else if (platform && platform !== this.state.platform) {
      // Platform options alredy here
      // Load maps for current platform
      this.loadPlatformMap(platform)

      // Load more types if needed
      if (maps && maps.length > 0) {
        maps.map((type) => this.loadPlatformMap(type))
      }

      // Set platform and platforms
      this.setState({
        platform: platform // Current platform
      })
    }

    if (!types || types.length === 0) {
      // Load types from organization
      getOrganizationDataset('Type', organization).then(
        (types) => {
          console.log('EditorDialogBase:loadPlatform:TYPES', types, this.state)

          this.setState({
            types: types, // List of supported types

            typeOptions: datasetToEnumOptions(types) // Type options
          })
        },
        (error) => {
          this.setState({ typeOptions: [] })
          console.log('EditorDialogBase:loadPlatform:TYPES:ERROR', error)
        }
      )
    }
  }

  /**
   * Load map from and to specific platform
   * @param (String) platform - platform name.
   * @return
   */
  loadPlatformMap = (platform, platformOptions, mapTo = true, mapFrom = true) => {
    // Load platforms list from organization we are in.
    let organization = this.state.organization

    if (!platformOptions) {
      platformOptions = this.state.platformOptions
    }

    console.log('EditorDialogBase:loadPlatformMap', this.getObjectType(), organization, platform, platformOptions)

    if (!platform || !platformOptions || platformOptions.length === 0) return

    // Load platforms list from organization we are in.
    if (platform === 'RESTfull-API') {
      platform = 'RESTful-API'
    }
    let platformRecord = platformOptions.find((t) => nonCSCompare(t.label, platform))
    if (!platformRecord) {
      console.log('EditorDialogBase:LoadPlatformMap:PLATFORM-ERROR', platform)
      return
    }

    console.log(
      'EditorDialogBase:loadPlatformMap:FROM-TO',
      platform,
      platformRecord,
      platformRecord.object['From'],
      platformRecord.object['To']
    )

    // Map from DifHub to the platform
    if (mapFrom && platformRecord.object['From'] && !this.state[platform + 'From']) {
      getOrganizationDataset(platformRecord.object['From'], organization).then(
        (map) => {
          this.setState({ [platform + 'From']: datasetToEnumOptions(map) })
        },
        (error) => {
          this.setState({ [platform + 'From']: [] })
          console.log('EditorDialogBase:loadPlatformMap:FROM:ERROR', error)
        }
      )
    }

    // Map from the platform types to the DifHub.
    if (mapTo && platformRecord.object['To'] && !this.state[platform + 'To']) {
      getOrganizationDataset(platformRecord.object['To'], organization).then(
        (map) => {
          this.setState({ [platform + 'To']: datasetToEnumOptions(map) })
        },
        (error) => {
          this.setState({ [platform + 'To']: [] })
          console.log('EditorDialogBase:loadPlatformMap:TO:ERROR', error)
        }
      )
    }
  }

  /**
   * Load metadata for dialog
   * @param {string} dataset - dataset definision uri
   * @param {string} view - view definision uri
   * @param {string} types - object type metadat uri
   * @param {func} getView - method to update view before saving.
   */
  loadMetadata = (dataset, view, types, getView) => {
    // Load dialog metadata and options for import file types.
    let objectDataset = dataset && typeof dataset === 'string' ? this.getEntityMetadata('dataset', dataset) : null
    if (dataset && typeof dataset === 'object') objectDataset = dataset

    let objectView = view && typeof view === 'string' ? this.getEntityMetadata('view', view) : null
    if (view && typeof view === 'object') objectView = view

    let objectTypes = types && typeof types === 'string' ? this.getEntityMetadata('dataset', types) : null
    if (types && typeof types === 'object') objectTypes = types

    // Let's find object form element for view
    let objectForm = null
    if (objectView && objectView.definitions && objectView.definitions[0] && objectView.definitions[0].elements) {
      objectForm = objectView.definitions[0].elements.find(
        (element) =>
          element.control &&
          element.control.reference &&
          element.control.reference.startsWith(
            '/organizations/Apdax/systems/DifHub/applications/Resource/datasets/FormEditor'
          )
      )
    }
    logger.info(
      'EditorDialogBase:loadMetadata',
      this.getObjectType(),
      {
        dataset,
        view,
        types,
        objectDataset,
        objectView,
        objectTypes,
        objectForm
      },
      this.state,
      this.props
    )

    this.setState({
      objectDataset: objectDataset,
      objectView: getView ? getView(objectView) : objectView,
      objectForm: objectForm,

      objectTypes: objectTypes ? datasetToEnumOptions(objectTypes) : null
    })
  }

  getEntityMetadata = (type, entity) => {
    // We load data from metadata.
    let load = false
    if (!load) {
      //logger.info("EditorDialogBase:getEntityMetadata:METADATA", type, entity, getDatasetMetadata(entity));
      switch (type.toLowerCase()) {
        case 'dataset':
          return getDatasetMetadata(entity)
        case 'view':
          return getViewMetadata(entity)
        default:
          break
      }
    }

    // We ask it from service.
    getObjectNewOrJson(entity, type, null, false, '', true).then(
      (result) => {
        logger.info('EditorDialogBase:getEntityMetadata:LOAD', type, entity, result)
        return result
      },
      (error) => {
        logger.warn('EditorDialogBase:getEntityMetadata:ERROR', type, entity, error)
        return null
      }
    )
  }

  /**
   * Load data for options of the type
   * @param {string} type - type to load
   */
  loadTypeOptions = (type) => {
    //logger.info("EditorDialogBase:loadTypeOptions", type, this.state, this.props);

    // Setting types we support in dialog
    getOrganizationDataset(type, this.state.organization).then(
      (enumDataset) => {
        //logger.info("EditorDialogBase:loadTypeOptions:", result, getSpecRecords(result));
        this.setState({ objectTypes: datasetToEnumOptions(enumDataset) })
      },
      (error) => {
        this.setState({ objectTypes: [] })
        logger.warn('EditorDialogBase:loadTypeOptions:ERROR', error)
      }
    )
  }

  /**
   * Load data for options of the objects, excluding current
   * @param {string} object - object type we are looking for
   * @param {string} usage - object usage we are looking for
   * @param {string} filter - filter option with this name
   */
  loadObjectOptions = (object, usage, filter) => {
    //logger.info("EditorDialogBase:loadObjectOptions", object, usage, filter, this.state, this.props);

    // Load list of objects we need in dialog
    getObjectListByUsage(this.state.path, object, usage).then(
      (result) => {
        let options = listOfMajorObjectsToEnumOptions(result, this.props.majorObject, referenceSort)
        //logger.info("EditorDialogBase:loadObjectOptions:OBJECTS", result, options, this.props.majorObject);
        if (filter) {
          options = options.filter((el) => !el || !nonCSCompare(el.object.identity.name, filter))
        }

        this.setState({ objectOptions: options })
        //logger.info("EditorDialogBase:loadObjectOptions:OBJECTS", result, this.props.majorObject);
      },
      (error) => {
        this.setState({ objectOptions: [] })
        logger.warn('EditorDialogBase:loadObjectOptions:ERROR', error)
      }
    )
  }

  /**
   * Load limited set of option list of the objects
   * @param {string} name - object type we are looking for
   * @param {string} type - object type we are looking for
   * @param {string} usage - object list of usage we are looking for
   */
  loadOptions = (name, type, exclude) => {
    //logger.info("EditorDialogBase:loadOptions", name, type, exclude, this.state, this.props);
    if (this._loadingOptions[name + '_' + type + '_' + exclude]) return

    this._loadingOptions[name + '_' + type + '_' + exclude] = true

    // Load list of objects we need in dialog
    getObjectListByUsage(this.state.path, type).then(
      (result) => {
        //logger.info("EditorDialogBase:loadOptions:OBJECTS", result, this.props.majorObject);

        let state = {}
        let list = result
        if (exclude) {
          list = result.filter((el) => !exclude.includes(el.object.usage))
        }

        let option = list
          .map((el, i) => {
            return {
              id: i,
              value: el,
              label: displayDatasetUri(el)
            }
          })
          .sort(referenceSort)

        state[name] = option
        this.setState(state)
        this._loadingOptions[name + '_' + type + '_' + exclude] = false
      },
      (error) => {
        logger.warn('EditorDialogBase:loadOptions:ERROR', error)
        this._loadingOptions[name + '_' + type + '_' + exclude] = false
      }
    )
  }

  /**
   * Load data for options of the objects, excluding current
   * @param {string} path - path of object to load structure
   */
  loadObjectStructure = (path, type = 'dataset', height) => {
    //logger.info("EditorDialogBase:loadObjectStructure", path, this.state, this.props);

    // Load structure we need for dialog
    getObjectNew(getRequestFromPath(path), type).then(
      (result) => {
        //logger.info("EditorDialogBase:loadObjectStructure:STRUCTURE", result);
        this.setState({ objectStructure: result })
        if (type === 'dataset' && height && result.structure.fields) {
          this.onAdjust(0, height * result.structure.fields.length)
        }
      },
      (error) => {
        logger.warn('EditorDialogBase:loadObjectStructure:ERROR', error)
      }
    )
  }

  /**
   * Load data for options of the objects, excluding current
   * @param {string} path - path of object to load structure
   * @param {string} usage - usage of value requered in enum
   */
  loadObjectValues = (path, usage) => {
    //logger.info("EditorDialogBase:loadObjectValues", path, usage, this.state, this.props);

    // Load structure we need for dialog
    getObjectNew(getRequestFromPath(path), 'dataset').then(
      (result) => {
        //logger.info("EditorDialogBase:loadObjectValues:STRUCTURE", result);

        this.setState({
          objectValues: datasetToEnumOptions(result, usage)
        })
      },
      (error) => {
        logger.warn('EditorDialogBase:loadObjectValues:ERROR', error)
      }
    )
  }

  /**
   * Returns object type for dialog, for example "activity"
   */
  getObjectType() {
    //throw "EditDialogBase not defined getObjectType()";
    return 'undefined'
  }

  /**
   * Returns object type for dialog, for example "activity"
   */
  getDialogRef = () => {
    let ref = this.props.parentDialog ? 'dialog_' + this.state.objectType : 'dialog'

    // We have reference in current dialog.
    if (this.refs && this.refs[ref]) {
      return this.refs[ref]
    }

    // We return reference from parent dialog
    if (this.props.parentDialog) {
      let parentDialog = this.props.parentDialog.getDialogRef()
      //logger.info("EditorDialogBase:getDialogRef:PARENT", ref, parentDialog, this.state, this.props);
      if (parentDialog && parentDialog.refs) {
        return parentDialog.refs[ref]
      }
    }

    return null
  }

  /**
   * Returns initial object for dialog
   */
  getInitial(props) {
    //logger.info("EditorDialogBase:getInitial", props, this.state, this.props);
    props = props || this.props
    return deepCopy(props[this.getObjectType()]) || (this.props.isItems ? [] : {})
  }

  /**
   * Returns title for dialog
   */
  getTitle = () => {
    //logger.info("EditorDialogBase:getTitle", this.state, this.props);
    if (this.props.modalTitle) return this.props.modalTitle

    let type = this.props.type ? this.props.type : this.state.objectType
    let title = 'Edit ' + type
    let entity = this.props[this.state.objectType]
    let dialog = this.getDialogRef()

    let isEditable = dialog ? dialog.state.isEditable : this.props.isEditable

    if (isEditable < editableState.EDITING) {
      title = 'View ' + type
    } else if (!entity) {
      title = 'Create ' + type
    } else if (!this.props.isItems && entity && !entity.name && (!entity.identity || !entity.identity.name)) {
      title = 'Create ' + type
    } else if (this.props.isItems && entity.length === 0) {
      title = 'Create ' + type
    }
    //logger.info("EditorDialogBase:getTitle", isEditable, title, this.state, this.props, this.refs);
    return title
  }

  /**
   * Returns title for button
   */
  getButton() {
    if (this.props.buttonTitle) return this.props.buttonTitle

    let type = this.props.type ? this.props.type : this.state.objectType
    let title = 'Edit ' + type
    let entity = this.props[this.state.objectType]
    let dialog = this.getDialogRef()

    let isEditable = dialog ? dialog.state.isEditable : this.props.isEditable

    if (isEditable < editableState.EDITING) {
      title = 'View ' + type
    } else if (!entity) {
      title = 'Create ' + type
    } else if (!this.props.isItems && entity && !entity.name && (!entity.identity || !entity.identity.name)) {
      title = 'Create ' + type
    } else if (this.props.isItems && entity.length === 0) {
      title = 'Create ' + type
    }
    //logger.info("EditorDialogBase:getButton", isEditable, title, this.state, this.props, this.refs);
    return title
  }

  /**
   * Returns icon for button and dialog.
   */
  getIcon() {
    return null
  }

  getIconHtml() {
    if (this.getIcon()) {
      return <img className="EditorDialogItem__buttonImg" src={this.getIcon()} alt="icon" />
    }
    return null
  }

  /**
   * Returns text for confitmation
   */
  getText() {
    //logger.info("EditorDialogBase:getText", this.state, this.props);
    if (this.props.confirmText) return this.props.confirmText

    let title = 'Update'
    let entity = this.props[this.state.objectType]

    if (!entity) {
      title = 'Create'
    } else if (!this.props.isItems && entity && !entity.name && (!entity.identity || !entity.identity.name)) {
      title = 'Create'
    } else if (this.props.isItems && entity.length === 0) {
      title = 'Create'
    }
    //logger.info("EditorDialogBase:getText", type, title, entity, this.state, this.props);

    return title
  }

  /**
   * Returns minimum height for dialog
   */
  getMinHeight() {
    return 400
  }

  /**
   * Returns list of adjustments of height based on object elements.
   * Name - is a name of parameter to use,
   * Value - adjustment for element.
   */
  getElementsHeight() {
    return null
  }

  /**
   * Calculate height adjustment base on element list for adjustment.
   * @param {object} [newObject] new state of the object
   * @param {object} [newObject] new state of the object
   */
  getDeltaHeight(newObject, oldObject) {
    let elements = this.getElementsHeight()
    if (!elements || (!newObject && !oldObject)) return 0

    // Calculate state
    let _new = newObject
    let _old = oldObject
    let _base = this.state && this.state.objectBase ? this.state.objectBase : this.props.base

    if (_base) {
      _new = newObject ? deepMerge(_base, newObject) : null
      _old = oldObject ? deepMerge(_base, oldObject) : null
    }
    //logger.info("EditorDialogBase:getDeltaHeight", _base, _new, _old, elements, newObject, oldObject, this.state, this.props);

    // Calculate difference in element height for new and all object state.
    let deltaHeight = 0

    Object.keys(elements).forEach((element) => {
      if (typeof elements[element] === 'object') {
        // We add maximum value of second level properties
        let maxNewHeight = 0
        let maxOldHeight = 0

        Object.keys(elements[element]).forEach((element2) => {
          let elementNewCount = getElementCount(_new, element2)
          let elementOldCount = getElementCount(_old, element2)

          let elementNewHeight = elementNewCount * elements[element][element2]
          let elementOldHeight = elementOldCount * elements[element][element2]

          if (maxNewHeight < elementNewHeight) maxNewHeight = elementNewHeight
          if (maxOldHeight < elementOldHeight) maxOldHeight = elementOldHeight
          //logger.info("EditorDialogBase:getDeltaHeight:GROUP_ELEMENT", { element, element2, elementNewCount, elementOldCount, elementNewHeight, elementOldHeight, maxNewHeight, maxOldHeight });
        })

        // Max of delta
        deltaHeight += maxNewHeight - maxOldHeight
        //logger.info("EditorDialogBase:getDeltaHeight:GROUP", { element, deltaHeight, maxNewHeight, maxOldHeight });
      } else if (element === '__line') {
        // We add for every first level property, which is array.
        // Number of line in view already.
        let lineHeight = elements[element] && elements[element] > 0 ? elements[element] : 20
        let minCount = this.getMinHeight() / lineHeight
        //logger.info("EditorDialogBase:getDeltaHeight", element, lineHeight, minCount, this.getMinHeight());

        let elementNewCount = getElementCount(_new) > minCount ? getElementCount(_new) : minCount
        let elementOldCount = getElementCount(_old) > minCount ? getElementCount(_old) : minCount

        deltaHeight += (elementNewCount - elementOldCount) * lineHeight
        //logger.info("EditorDialogBase:getDeltaHeight:LINE", { element, deltaHeight });
      } else {
        // We add for every first level property
        let elementNewCount = getElementCount(_new, element)
        let elementOldCount = getElementCount(_old, element)

        deltaHeight += (elementNewCount - elementOldCount) * elements[element]
        //logger.info("EditorDialogBase:getDeltaHeight:ELEMENT", { element, deltaHeight, elementNewCount, elementOldCount });
      }
    })

    //logger.info("EditorDialogBase:getDeltaHeight:HEIGHT", elements, deltaHeight);
    return deltaHeight
  }

  /**
   * Init dialog height
   * @param props - set of initialization properties
   */
  InitHeight(props) {
    props = props || this.props

    // Calculate current height of window
    let height = this.getMinHeight()

    //if (height < (window.innerHeight - 248))
    //height = window.innerHeight - 248;

    // We add diffirence of height based on elements we use for height
    height += this.getDeltaHeight(props[this.getObjectType()])
    logger.info(
      'EditorDialogBase:InitHeight',
      this.getObjectType(),
      { height, props },
      this.getMinHeight(),
      window.innerHeight
    )

    return height
  }

  /**
   * Adjust size of scroll area in dialog. When selector specified it will do it base on selector
   * @param width - increase or reduction in control width.
   * @param height - increase or reduction in control height.
   * @param selector - name of dialog selector to control height.
   * @return
   */
  onAdjust = (width, height, selector) => {
    // Adjust based on selector if specified
    if (!width && !height && selector) {
      logger.info('EditorDialogBase:onAdjust', width, height, selector)
      this.onCheckDialogHeight(selector)
      return
    }

    // Adjust by width and height.
    this.setState((prevState) => {
      let min_height = this.getMinHeight()
      let new_height = prevState.height + height
      //logger.info("EditorDialogBase:onAdjust", width, height, prevState.width, prevState.height, this.state);

      return { height: new_height < min_height ? min_height : new_height }
    })
  }

  /**
   * Report changes to dialog
   * @param data - true  - data changed and can be saved
   *               false - data in original state
   *               null  - successful save of data
   *               error - error response
   * @param message - reporting info message in dialog
   * @param success - reporting success message in dialog.
   * @param error - reporting error message in dialog.
   * @return
   */
  onReport = (message, type) => {
    if (this.state.change) {
      // Report to dialog
      if (!message) {
        // Let's clean report from dialog and stop loading
        this.state.change(true, '', '', '', true)
      } else if (!type) {
        // Let's report waiting of operation
        this.state.change(false, message, '', '', true)
      } else {
        switch (type) {
          default: {
            // Completion of operation
            this.state.change(true, '', message, '', true)
            return
          }
          case reportType.WAIT: {
            // Let's report waiting of operation
            this.state.change(true, message, '', '', true)
            return
          }
          case reportType.COMPLETE: {
            // Completion of operation
            this.state.change(null, '', message, '', true)
            return
          }
          case reportType.ERROR: {
            if (typeof message === 'string') {
              // Error message
              this.state.change(false, '', '', message, true)
            } else {
              // Error object.
              this.state.change(message, '', '', '', true)
            }
            return
          }
        }
      }
    }
  }

  /**
   * When click on dialog happens this method will check if dialog need height adjustment.
   * JSON nodes in dialog are expanded, it should check height of inner components and expand itself if needed
   * @param selector - name of dialog selector to control.
   */
  onCheckDialogHeight = (selector) => {
    let element = document.querySelector(selector)
    let height = element ? element.offsetHeight : 0
    let delta = height - this.state.height
    //logger.info("EditorDialogBase:onCheckDialogHeight", this.getObjectType(), { selector, element, height, delta }, this.state, this.props);

    // We have required height bigger than current
    //if (delta > 0) {
    this.onAdjust(0, delta + 200)
    //}
  }

  /**
   * Execute activity when dialog openning
   * @param
   * @return
   */
  open() {
    //logger.info("EditorDialogBase:open", this.state, this.props);

    // Load data for properties.
    if (this.props.onOpen) this.props.onOpen()
  }

  /**
   * Set edit mode on
   */
  startEdit = (change) => {
    //logger.info("EditorDialogBase:startEdit", change, this.props);
    this.setState({
      change: change
    })
    this.onChangeEditable(editableState.EDITING)
    if (this.props.onEditableChange) {
      this.props.onEditableChange(editableState.EDITING)
    }
  }

  /**
   * Cancel changes
   * @param {boolean} [closeDialog] Do we need to close dialog
   */
  cancelEdit = (closeDialog) => {
    //logger.info("EditorDialogBase:cancelEdit", closeDialog, this.props);
    if (this.props.onCancel && this.props.onCancel(closeDialog)) return

    // Cancel save mode
    if (!closeDialog && this.state.change) this.state.change(false)

    //logger.info("EditorDialogBase:cancelEdit:CHANGE", closeDialog, this.props, this.refs);

    // Manage local object context and clean change callback.
    this.setState(clearLocalObject(this.getInitial(this.props), this.props.version))
  }

  /**
   * Execute activity when dialog closing
   * @param
   * @return
   */
  close = () => {
    //logger.info("EditorDialogBase:onClose", this.state, this.props);

    // Return initial state if we change version and try to close.
    if (this.props.version && versionCompare(this.props.version, this.state.version)) {
      this.Change(this.getInitial(this.props), this.props.version)
    }

    // Close dialog. It can go to settings mode.
    this.setState({ editMode: false })
    if (this.props.parentDialog) {
      this.props.parentDialog.changeChildDialog(this.getObjectType(), null)
    }

    if (this.props.onClose) this.props.onClose()
  }

  /**
   * Checks current state for input errors. Should return '' if no errors
   * and error description if there are errors
   */
  changeVersion(version, object) {
    // If we have function lets redirect.
    logger.info('EditorDialogBase:changeVersion', version, object, this.props)

    if (this.props.onVersionChange) {
      // Call base function to udjust version
      this.props.onVersionChange(version, object)
    } else {
      this.Change(object ? object : this.props.isItems ? [] : {}, version)
    }
  }

  /**
   * Override the method in child dialogs, to handle changeEditable() event: opening and closing the dialog
   */
  onChangeEditable = (isEditable) => {
    //logger.info("EditorDialogBase:onChangeEditable", isEditable, this.props);
  }

  /**
   * Adjust editable state of dialog. It will not increase state if it not allowed.
   * @param {number} state - state for dialog to setup
   */
  changeEditable = (state) => {
    //logger.log("EditorDialogBase::changeEditable", this.getObjectType(), state, this.refs);
    let dialog = this.getDialogRef()
    if (dialog) {
      let isEditable = state !== undefined ? state : dialog.state.isEditable
      if (this.props.version && isEditable > editableState.BROWSABLE) {
        let status = getVersionStatus(
          this.props.majorObject.settings ? this.props.majorObject.settings : this.props.majorObject
        )
        if (status !== 'draft') {
          isEditable = editableState.BROWSABLE
        }
      }

      //logger.log("EditorDialogBase::changeEditable:STATE", this.getObjectType(), isEditable, dialog.state.isEditable);

      if (dialog.state.isEditable < isEditable) {
        //logger.log("EditorDialogBase::changeEditable:CHANGE", this.getObjectType(), isEditable, dialog.state.isEditable);
        dialog.setState({
          isEditable: isEditable
        })
      }
      this.onChangeEditable(isEditable)
    } else {
      this.onChangeEditable(state)
    }
  }

  /**
   * On change editable status of child dialogs of list.
   */
  onEditableChange = (state) => {
    // Change status of dialog
    this.changeEditable(state)

    // Propogate event of change editable status of child dialog
    if (this.props.onEditableChange) {
      this.props.onEditableChange(state)
    }
  }

  /**
   * On change content loaded into dialog.
   */
  onContentChange = (content, element, field) => {
    // Change status of dialog
    if (element) {
      this.onValueChange(element, field, content.fileURI, null, content)
    }

    logger.info(
      'EditorDialogBase:onContentChange',
      this.getObjectType(),
      { content, element, field },
      this.state,
      this.props
    )

    // Propogate event of change content of child dialog
    if (this.props.onContentChange) {
      this.props.onContentChange(content)
    } else {
      // Update content of dialog.
      this.setState((prevState) => {
        return {
          uploadContent: (prevState.uploadContent || [])
            .filter((cont) => !nonCSCompare(cont.fileURI, content.fileURI))
            .concat(content)
        }
      })
    }
  }

  /**
   * Upload content.
   * @param {array} content - list of content objects
   * @param {string} contentUri - root path for content upload
   * @returns {Promise}
   */
  uploadContent = (content, contentUri) => {
    // Change status of dialog
    return new Promise((resolve, reject) => {
      if (!content || content.length > 0) {
        resolve()
      }

      let promises = []
      let root = contentUri ? (contentUri.endsWith('/') ? contentUri : contentUri + '/') : '/'
      logger.info(
        'EditorDialogBase:uploadContent',
        this.getObjectType(),
        { content, contentUri, root },
        this.state,
        this.props
      )

      content.map((file) => {
        let uri = file.fileURI ? (file.fileURI.startsWith('/') ? file.fileURI.substring(1) : file.fileURI) : ''
        logger.info(
          'EditorDialogBase:uploadContent:FILE',
          this.getObjectType(),
          { file, uri, root, content, contentUri },
          this.state,
          this.props
        )
        if (file.fileData) {
          let form = new FormData()
          form.append('file', file.fileData)

          promises.push(
            fetch(checkResourceUrl(API.resources.url() + '?uri=' + root + uri), {
              method: 'post',
              headers: {},
              body: form
            })
          )
        } else {
          promises.push(
            fetch(checkResourceUrl(API.resources.url() + '?uri=' + root + uri), {
              method: 'delete'
            })
          )
        }
      })

      Promise.all(promises).then(
        () => {
          logger.info('EditorDialogBase:uploadContent:SUCCESS', this.getObjectType(), this.state, this.props)
          resolve()
        },
        (error) => {
          logger.warn('EditorDialogBase:uploadContent:ERROR', this.getObjectType(), error, this.state, this.props)
          reject(error)
        }
      )
    })
  }

  /**
   * Execute activity when message send to service
   * @param {boolean} close - flag to close dialog
   * @param {*} error - return from service if any.
   * @return
   */
  onSent = (close, error) => {
    //logger.info("EditorDialogBase:onSent", close, error);

    // Report complete of save with error or not
    if (this.state.change) {
      this.state.change(error)
    }

    if (!error) {
      logger.info('EditorDialogBase:onSent:uploadContent', close, this.state.uploadContent, this.state, this.props)
      if (this.state.uploadContent && this.state.uploadContent.length > 0) {
        // Upload content for current dialog.
        this.uploadContent(this.state.uploadContent, this.props.contentUri).then(
          () => {
            this.setState(
              {
                uploadContent: []
              },
              () => {
                if (close) {
                  setTimeout(() => {
                    this.close()
                  }, 500)
                }
                this.setState(clearLocalObject(this.state[this.state.objectType], this.props.version))
              }
            )
          },
          (error) => {
            if (this.state.change) {
              this.state.change(error)
            }
          }
        )
      } else {
        // Change state before close.
        if (close) {
          setTimeout(() => {
            this.close()
          }, 500)
        }
        this.setState(clearLocalObject(this.state[this.state.objectType], this.props.version))
      }
    }
  }

  /**
   * Checks current state for input errors. Should return '' if no errors
   * and error description if there are errors
   */
  checkInputErrors(object) {
    return ''
  }

  /**
   * Check for errors and call props.onSave
   */

  save(closeDialog) {
    let inputError = ''

    const newItem = deepCopy(this.state[this.state.objectType])
    console.log(this.state[this.state.objectType].identity)
    if (this.state[this.state.objectType].identity) {
      console.log('e')
      newItem.identity.name = newItem.identity.name.trim()
      this.setState({
        [this.state.objectType]: newItem
      })
    }
    logger.log(
      'EditorDialogBase:save',
      this.state.objectType,
      this.state[this.state.objectType],
      this.state,
      this.props
    )

    // input checks go here
    inputError = this.state[this.state.objectType].identity
      ? this.checkInputErrors(newItem)
      : this.checkInputErrors(this.state[this.state.objectType])

    //logger.log("EditorDialogBase:save:ERRORS", this.state[this.state.objectType], inputError);

    if (inputError.length > 0) {
      if (this.state.change) {
        this.state.change(true, '', '', inputError)
      }
      return true
    }

    if (this.state.change) {
      this.state.change(true, 'Data sent to service...', '', '')
    }

    //logger.info("EditorDialogBase:save:onSave", this.state, this.props);
    return this.state[this.state.objectType].identity
      ? this.onSave(newItem, closeDialog, this.onSent.bind(this))
      : this.onSave(this.state[this.state.objectType], closeDialog, this.onSent.bind(this))
  }

  /**
   * Implementation of actual save event
   * @param {object} [object] actual object managed by dialog
   * @param {boolean} [closeDialog] Do we need to close dialog
   * @param {func} [onSent] callback after send
   */
  onSave(object, closeDialog, onSent) {
    logger.info('EditorDialogBase:onSave', object, this.state, this.props)
    if (this.props.onSave) {
      this.props.onSave(object, closeDialog, onSent)
      return true // not to do anything here.
    }
  }

  /**
   * Implementation of actual save event
   * @param {object} [object] actual object managed by dialog
   * @param {boolean} [closeDialog] Do we need to close dialog
   * @param {func} [onSent] callback after send
   */
  onError = (facility, code, error, params, element, field, view, entity) => {
    //logger.info("EditorDialogBase:onError", { facility, code, error, params, element, field, view, entity }, this.state, this.props);

    const pathSplit = this.state.path.substring(1).split('/')
    const sysPath = pathByType(pathSplit, 'systems')

    // Find metadata enumerator with error definisions
    let errorFacility = sysPath + '/applications/System/datasets/ErrorCode'
    if (facility) {
      errorFacility = sysPath + '/applications/' + facility + '/datasets/ErrorCode'
    }

    // Define field information for error.
    let errorField = ''
    if (element || field) {
      if (!errorField && element) {
        errorField = element.identity.name
      }
      if (!errorField && field) {
        errorField = field.identity.name
      }
    }

    // Define entity information for error.
    let errorEntity = this.state.path

    let errorObj = this.getError(errorFacility, code, error, params, errorEntity, errorField)
    if (!errorObj) {
      if (this.state.change) {
        this.state.change(false, '', '', '')
      }
      return
    }

    // Prepare message
    let errorText = errorObj.text
    if (errorObj.entity || errorObj.field) {
      errorText += ' ['
      if (errorObj.entity) {
        errorText += 'entity: ' + errorObj.entity
      }
      if (errorObj.field) {
        errorText += (errorObj.entity ? ', ' : '') + 'field: ' + errorObj.field
      }
      errorText += ']'
    }

    //logger.info("EditorDialogBase:onError", { errorObj, errorText }, this.state, this.props);
    if (this.state.change) {
      this.state.change(false, '', '', errorText)
    }
  }

  getError(facility, code, error, params, entity, field) {
    // Get metadata for error from facility.
    let errorList = this.getEntityMetadata('dataset', facility)
    let errorData = errorList.data.records.find((record) => parseInt(record.values[0]) === code)
    //logger.info("EditorDialogBase:getError", { facility, code, error, params, entity, field, errorList, errorData });
    if (!errorData) {
      // we don't have this error in metadata
      logger.warn('EditorDialogBase:getError no error found for code:', code, ' in facility:', facility)
    }

    let errorObj = {
      code: code,
      severety: errorData ? errorData.values[1] : 'Error'
    }

    // Error object
    let message = error.response ? JSON.parse(error.response) : null
    let messageText =
      message && message.error
        ? message.error.message
        : 'Unknown Error ' + (error.statusText ? '(' + error.statusText + ': ' + error.responseText + ')' : '')

    // Generate text string
    let errorText = ''
    if (errorData && errorData.values[2]) {
      const textSplit = errorData.values[2].split('$[')
      errorText = textSplit[0]
      for (let i = 1; i < textSplit.length; i++) {
        let param = textSplit[i].substring(0, textSplit[i].indexOf(']'))
        if (params[param]) {
          errorText += params[param]
        }
        errorText += textSplit[i].substring(textSplit[i].indexOf(']') + 1)
        //logger.info("EditorDialogBase:getError:SPLIT", i, textSplit[i], param, params[param], params, errorText);
      }
      //logger.info("EditorDialogBase:getError:TEXT", textSplit, params, errorText);
    }

    // Set text to the message
    if (errorText || messageText) {
      errorObj['text'] = errorText + (messageText ? '; ' + messageText : '')
    }

    // Set up description.
    if (errorData && errorData.values[3]) {
      errorObj['description'] = errorData.values[3]
    }

    // Entity error associated with
    if (entity) {
      errorObj['entity'] = entity
    }

    // Field error associated with
    if (field) {
      errorObj['field'] = field
    }

    // return error object.
    return errorObj
  }

  /**
   * Updates local state with new object
   */
  Change = (newObject, newVersion) => {
    // logger.log('EditorDialogBase::Change', newObject, newVersion, this.state, this.props)

    // Change object state
    if (newObject) {
      let oldObject = this.state[this.state.objectType]
      let oldVersion = this.state.objectVersion

      let curr = JSON.stringify(oldObject) //.toLowerCase();
      let next = JSON.stringify(newObject) //.toLowerCase();

      let change = this.state.origin !== next
      if (change && this.props.version) {
        // We can't make it EDITED if we not in draft node.
        let status = getVersionStatus(
          this.props.majorObject.settings ? this.props.majorObject.settings : this.props.majorObject
        )
        if (status !== 'draft') {
          change = false
        }
      }

      logger.info(
        'EditorDialogBase::Change:SAVE',
        this.state.origin !== next,
        curr !== next,
        change,
        newObject,
        newVersion,
        this.refs,
        this.state
      )

      // Clear status
      let dialog = this.getDialogRef()
      if (this.state.change) {
        // Change status of dialog
        this.state.change(change, '', '', '')
      } else if (change && dialog) {
        // Change status of dialog to edit and mark data as changed.
        //logger.log("EditorDialogBase::Change:EDIT", this.refs);
        dialog.onEdit(editableState.EDITED)
      }

      if (curr !== next || !versionCompare(oldVersion, newObject)) {
        // Adjust dialog size based on delta for new object state
        this.onAdjust(0, this.getDeltaHeight(newObject, oldObject))

        // Adjust current state of the object.
        this.setState(updateLocalObject(newObject, newVersion), () => {
          // Adjust dialog state.
          this.changeEditable()
        })
        return true
      }
    }
    return false
  }

  /**
   * Change render function for chield dialog
   */
  changeChildDialog = (type, render) => {
    //logger.log("EditorDialogBase:changeChildDialog", type, render, this.state, this.props);
    let dialogs = this.state.childDialogs || {}
    dialogs[type] = { render: render }
    this.setState({ childDialogs: dialogs })
  }

  /**
   * Show or hide dialog window
   */
  toggleEditMode(event) {
    event.stopPropagation()
    logger.log('EditorDialogBase:toggleEditMode', this.state, this.props)

    let height = this.InitHeight(this.props)
    let mode = this.state.editMode

    this.setState(
      {
        height: height,
        editMode: !mode
      },
      () => {
        // Adjust dialog state.
        this.changeEditable()
        if (this.props.parentDialog) {
          this.props.parentDialog.changeChildDialog(this.getObjectType(), mode ? null : this.renderDialog.bind(this))
        }
      }
    )
  }

  toggleViewMode(event) {
    event.stopPropagation()

    let height = this.InitHeight(this.props)

    this.setState(
      {
        height: height,
        editMode: !this.state.editMode
      },
      () => {
        // Adjust dialog state.
        this.changeEditable(editableState.BROWSABLE)
      }
    )
  }

  /**
   * Change value of property name of the object controled by dialog
   * @param {object} [event] event of property change
   */
  onNameChange = (event) => {
    let data = event.target.value

    let object = deepCopy(this.state[this.state.objectType])
    if (object.identity) {
      object.identity.name = data
    } else {
      object.identity = { name: data }
    }

    // Validate change
    if (!this.onValidateChange('name', 'identity.name', data, object)) return null

    this.Change(object)
    return data
  }

  /**
   * Change value of property description of the object controled by dialog
   * @param {object} [event] event of property change
   */
  onDescriptionChange = (event) => {
    let data = event.target.value

    let object = deepCopy(this.state[this.state.objectType])
    if (object.identity) {
      object.identity.description = data
    } else {
      object.identity = { description: data }
    }

    // Validate change
    if (!this.onValidateChange('description', 'identity.description', data, object)) return null

    this.Change(object)
    return data
  }

  /**
   * Change value of property order of the object controled by dialog
   * @param {object} [event] event of property change
   */
  onOrderChange = (event) => {
    let data = event.target.value

    let object = deepCopy(this.state[this.state.objectType])
    object['order'] = data

    // Validate change
    if (!this.onValidateChange('order', 'order', data, object)) return null

    this.Change(object)
    return data
  }

  /**
   * Change value of type of the object controled by dialog
   * @param {object} [event] event of property change
   */
  onTypeChange = (event) => {
    if (!event.target.value) return

    let data = event.target.value
    //logger.info("EditorDialogBase:onTypeChange", data, this.state, this.props);

    let object = deepCopy(this.state[this.state.objectType])
    object['type'] = data

    // Validate change
    if (!this.onValidateChange('type', 'type', data, object)) return null

    this.Change(object)
    return data
  }

  /**
   * Change value of property of the object controled by dialog
   * @param {string} [property] name of property to change
   * @param {object} [event] event of property change
   */
  onPropertyChange = (property, event) => {
    let data = event.target.value

    let object = deepCopy(this.state[this.state.objectType])
    deepSet(object, property, data)

    // Validate change
    if (!this.onValidateChange(property, property, data, object)) return null

    this.Change(object)
    return data
  }

  /**
   * Change value of field in the object by field path
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {string} [message] message reported on time of update of element value
   * @param {object} [option] option representing value
   * @param {object} [structure] option selected from
   */
  onValueChange = (element, field, value, message, option, structure) => {
    //logger.info("EditorDialogBase:onValueChange", { element, field, value, message, option, structure }, this.state);
    // Process message if reported by editor
    if (message) {
      if (this.state.change) this.state.change(null, message)
      return
    }

    // Prepare new value
    let object = deepCopy(this.state[this.state.objectType])
    if (field.trim()) {
      // We have field we need to update, this is regular case when we
      // change data of the field and we know what field to update.
      deepSet(object, field.trim(), value)
    } else {
      // We are updated object this is special case when value not specified
      // right now it is for properies where we update reference data directly
      // in object!!!
      object = value
    }

    // Validate change
    let result = this.onValidateChange(element, field, value, object, option, structure)

    // Change canceled.
    if (!result) {
      return null
    }

    // Change value,
    this.Change(result)
    return value
  }

  /**
   * Validate value of changed field
   * @param {string} [element] element of view change value
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @param {object} [option] selected option when  it was enum
   * @param {object} [structure] structure option generated from
   * @return true - change approved
   */
  onValidateChange = (element, field, value, data, option, structure) => {
    return data
  }

  /**
   * Filter data from options of editor if it have options
   * @param {string} [field] path of field in the dataset
   * @param {object} [options] options for this field in the element
   */
  onFilterOptions = (field, options) => {
    if (!options || options.length === 0) return options

    // Type of object.
    //let type = this.props.majorObject.object.type;
    //let result = options.filter(option => option.object['Objects'].find(o => nonCSCompare(o, type)));

    //logger.info("EditorDialogBase:onFilterOptions", type, field, options, result);
    return options
  }

  /**
   * By default, call handler from props
   */
  onApprove() {
    console.log('EditorDialogBase::onApprove')
    this.props.onApprove()
  }

  /**
   * Render data element
   * @param {bumber} editState - edit state for data editor
   * @param {string} element - name of element in the view
   * @param {object} field - field for element in the view
   * @param {object} data - data for element in the view
   * @param {func} getEnumOptions - function to get externl field options for editor
   * @return
   */
  renderElement = (editState, element, field, data, getEnumOptions) => {
    // Metadata for dialog
    const dataset = this.state.objectDataset
    const view = this.state.objectView
    if (!dataset || !view) {
      logger.warn('EditorDialogBase:renderElement:NO-DATA-VIEW', { dataset, view }, this.state, this.props)
      return null
    }

    // Get element from view which specify editor.
    let dataElement = getElementMetadata(view, element)
    if (!dataElement || !dataElement.control) {
      logger.warn('EditorDialogBase:renderElement:NO-ELEMENT', { view, element, dataElement }, this.state, this.props)
      return null
    }
    //logger.info("EditorDialogBase:renderElement", editState, view, element, dataElement, this.state, this.props);

    // Skip invisable element.
    if (!nonCSCompare(dataElement.visibility, 'Visible')) {
      logger.warn(
        'EditorDialogBase:renderElement:NOT-VISIBLE-ELEMENT',
        { view, element, dataElement },
        this.state,
        this.props
      )
      return null
    }

    let dataObject = this.state[this.state.objectType]
    let dataType = 'DataEditor'
    if (
      dataElement.control.reference.startsWith(
        '/organizations/Apdax/systems/DifHub/applications/Resource/datasets/DataEditor'
      )
    ) {
      dataType = 'DataEditor'
    } else if (
      dataElement.control.reference.startsWith(
        '/organizations/Apdax/systems/DifHub/applications/Resource/datasets/PropertyEditor'
      )
    ) {
      dataType = 'PropertyEditor'
    } else if (
      dataElement.control.reference.startsWith(
        '/organizations/Apdax/systems/DifHub/applications/Resource/datasets/ReferenceEditor'
      )
    ) {
      dataType = 'ReferenceEditor'
    } else if (
      dataElement.control.reference.startsWith(
        '/organizations/Apdax/systems/DifHub/applications/Resource/datasets/FormEditor'
      )
    ) {
      dataType = 'FormEditor'
      return null
    } else {
      logger.warn('EditorDialogBase:renderElement  Unsuported control Type', dataElement)
    }
    //logger.info("EditorDialogBase:renderElement:ELEMENT", { dataElement, dataType, dataObject });

    let fieldName =
      field && field.identity && field.identity.name ? field.identity.name : dataElement.control.value['dataField']
    let dataField = field ? field : getFieldMetadata(dataset, fieldName)
    if (!dataField && dataType !== 'ReferenceEditor') {
      logger.warn('EditorDialogBase:renderElement:NO-FIELD', {
        fieldName,
        element,
        dataElement,
        view,
        dataset
      })
      return null
    }

    // Process data value and value from base layer as value for place holder.
    let dataValue = data ? data : fieldName.trim() ? deepGet(dataObject, fieldName) : dataObject
    let baseValue = null
    if (this.props.base) {
      let baseName = dataElement.control.value['baseField']
      if (baseName) {
        baseValue = deepGet(this.props.base, baseName)
      }
    }

    // Load enumerator options
    let enumOptions = null
    let dataStructure = null
    if (editState > editableState.EDITABLE) {
      // we have enum options specified, it override any other options
      if (getEnumOptions) {
        if (dataType === 'DataEditor' || dataType === 'PropertyEditor' || dataType === 'ReferenceEditor') {
          enumOptions = getEnumOptions(fieldName)
          if (!enumOptions) {
            enumOptions = []
            editState = editableState.LOADING
          }
        }
      } else if (dataElement.control.value['dataOptions'] && dataElement.control.value['dataOptions'].trim()) {
        // We use options loaded in dialog state.
        enumOptions = this.state[dataElement.control.value['dataOptions']]
        if (!enumOptions) {
          enumOptions = []
          editState = editableState.LOADING
        }
      }

      // Object structure available for reference data to use and it not required to be loaded.
      if (this.state.objectStructure) {
        dataStructure = this.state.objectStructure
      }
      //logger.info("EditorDialogBase:renderElement:OPTIONS", { dataElement, enumOptions, dataStructure }, this.state);
    }
    //logger.info("EditorDialogBase:renderElement:FIELD", {data, dataObject, fieldName, dataField, dataValue}, this.state, this.props);

    return (
      <div>
        <div>
          {dataType !== 'DataEditor' ? null : (
            <DataEditor
              ref={element}
              appState={this.props.appState}
              actions={this.props.actions}
              class={element}
              options={{
                enumOptions: enumOptions,
                onFilter: (o) => {
                  return this.onFilterOptions(fieldName, o)
                },
                onChange: (v, m, o) => {
                  this.onValueChange(element, fieldName, v, m, o)
                }
              }}
              isEditable={editState}
              majorObject={this.props.majorObject}
              parentDialog={this}
              field={dataField}
              element={dataElement}
              data={dataValue}
              base={baseValue}
              dataset={dataObject}
              content={this.props.content ? this.props.content : this.state.uploadContent}
              contentUri={this.props.contentUri}
              onContentChange={(c) => this.onContentChange(c, element, fieldName)}
              onAdjust={this.onAdjust}
              onError={this.onError}
            />
          )}

          {dataType !== 'PropertyEditor' ? null : (
            <PropertyEditor
              ref={element}
              appState={this.props.appState}
              actions={this.props.actions}
              class={element}
              options={{
                enumOptions: enumOptions,
                onFilter: (o) => {
                  return this.onFilterOptions(fieldName, o)
                },
                onChange: (v, m, o) => {
                  this.onValueChange(element, fieldName, v, m, o)
                }
              }}
              isEditable={editState}
              majorObject={this.props.majorObject}
              field={dataField}
              element={dataElement}
              data={dataValue}
              base={baseValue}
              onAdjust={this.onAdjust}
              onError={this.onError}
            />
          )}

          {dataType !== 'ReferenceEditor' ? null : (
            <ReferenceEditor
              ref={element}
              appState={this.props.appState}
              actions={this.props.actions}
              class={element}
              options={{
                enumOptions: enumOptions,
                onFilter: (o) => {
                  return this.onFilterOptions(fieldName, o)
                },
                onChange: (v, m, o, s) => {
                  this.onValueChange(element, fieldName, v, m, o, s)
                }
              }}
              isEditable={editState}
              majorObject={this.props.majorObject}
              field={dataField}
              element={dataElement}
              data={dataValue}
              base={baseValue}
              dataset={dataStructure}
              onAdjust={this.onAdjust}
              onError={this.onError}
            />
          )}
        </div>
      </div>
    )
  }

  /**
   * Render content of editor dialog
   * @param {bumber} editState - edit state for data editor
   * @param {func} getElemetContent - function to get externl element content to render editor
   * @return
   */
  renderView = (editState, getElementContent) => {
    // Metadata for dialog
    const org = this.state.organization
    const path = this.state.path
    const object = this.props.majorObject
    if (!org || !path || !object) {
      logger.warn('EditorDialogBase:renderView:NO-MAJOR-OBJECT', { org, path, object }, this.state, this.props)
      return null
    }

    // Metadata for dialog
    const dataset = this.state.objectDataset
    const view = this.state.objectView
    if (!dataset || !view || !view.definitions) {
      logger.warn('EditorDialogBase:renderView:NO-DATA-VIEW', { dataset, view }, this.state, this.props)
      return null
    }
    //logger.info("EditorDialogBase:renderView", editState, { dataset, view }, this.state, this.props);

    return (
      <div className="EditorDialog">
        {view.definitions[0].elements.sort(orderSort).map((element) => {
          let content = getElementContent ? getElementContent(element.identity.name) : null
          if (content) {
            // Render external content for element.
            return content
          }

          // Render content of element from the view
          return this.renderElement(editState, element.identity.name)
        })}
      </div>
    )
  }

  /**
   * Render most common set of data in editor dialog
   */
  renderEditorBase = (editState, editName, editDesc, editOrder, editType) => {
    // State and props.
    const object = this.state[this.state.objectType]

    const typeOptions = this.state.objectTypes || []
    let type = editType ? typeOptions.find((el) => nonCSCompare(object.type, el.label)) : null
    //logger.info("EditorDialogBase:renderEditor", editState, object, type, this.state, this.props);

    return (
      <div className="EditorDialog">
        {!editName ? null : (
          <div className="EditorDialog__block_name clearfix">
            <label className="EditorDialog__label">Name:</label>
            <div className="EditorDialog__value_right">
              <EditableEntity
                data={object && object.identity && object.identity.name ? object.identity.name : ''}
                dataType={{ name: 'string' }}
                dataProps={{
                  placeholder: 'Enter name',
                  onChange: this.onNameChange
                }}
                isEditable={editState >= editableState.EDITABLE}
                inEditMode={editState > editableState.EDITABLE}
              />
            </div>
          </div>
        )}

        {!editDesc ? null : (
          <div className="EditorDialog__block_description clearfix">
            <label className="EditorDialog__label">Description:</label>
            <div className="EditorDialog__value_right">
              <EditableEntity
                data={object && object.identity && object.identity.description ? object.identity.description : ''}
                dataType={{ name: 'text_updatable' }}
                dataProps={{
                  placeholder: 'Enter description',
                  onChange: this.onDescriptionChange,
                  maxLineCount: 3
                }}
                isEditable={editState >= editableState.EDITABLE}
                inEditMode={editState > editableState.EDITABLE}
              />
            </div>
          </div>
        )}

        {!editOrder ? null : (
          <div className="EditorDialog__block_order clearfix">
            <label className="EditorDialog__label">Order:</label>
            <div className="EditorDialog__value_right_order">
              <EditableEntity
                data={object && object.order ? object.order : ''}
                dataType={{ name: 'integer' }}
                dataProps={{
                  placeholder: 'Enter order',
                  onChange: this.onOrderChange
                }}
                isEditable={editState >= editableState.EDITABLE}
                inEditMode={editState > editableState.EDITABLE}
              />
            </div>
          </div>
        )}

        {!editType ? null : (
          <div className="EditorDialog__block_type clearfix">
            <label className="EditorDialog__label">Type:</label>
            <div className="EditorDialog__value_right">
              <EditableEntity
                data={type ? type.label : ''}
                dataType={{ name: 'enum', options: typeOptions }}
                dataProps={{
                  placeholder: 'Select ' + this.state.objectType + ' type',
                  options: typeOptions,
                  onChange: this.onTypeChange
                }}
                isEditable={editState >= editableState.EDITABLE}
                inEditMode={editState > editableState.EDITABLE}
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  /**
   * Render actual dialog content for edit or review
   */
  renderEditor = (editState) => {
    this.renderEditorBase(editState, true, true)
  }

  /**
   * Render actual dialog footer
   */
  renderFooter = (editState) => {}

  /**
   * Render dialog content for next dialog
   */
  renderChildDialog = (editState) => {
    //logger.info("EditorDialogBase:renderChildDialog", this.getObjectType(), editState, this.state, this.props);
    let content = null
    if (this.state && this.state.childDialogs) {
      let dialogs = this.state.childDialogs
      for (let dialog in dialogs) {
        logger.info('EditorDialogBase:renderChildDialog:DIALOG', dialog, this.state, this.props)
        let dialogContent = dialogs[dialog] && dialogs[dialog].render ? dialogs[dialog].render(editState) : null
        if (dialogContent) {
          if (content) {
            content.push(dialogContent)
          } else {
            content = [dialogContent]
          }
        }
      }
    }
    return content
  }

  /**
   * Render one item in list of the items when dialog is closed
   */
  renderItem(item, index) {
    if (!item || !item.identity) return null

    //logger.info("EditorDialogBase:renderItem", item, index, this.state, this.props);

    return (
      <div className="EditorDialogItems row" key={index}>
        <div className="EditorDialogItems__name col">
          <MultilineText text={item.identity.name + ':'} defaultLineHeight={20} maxLineCount={1} />
        </div>
        <div className="EditorDialogItems__value col">
          <MultilineText text={propertyValueToString(item.type, item.value)} defaultLineHeight={20} maxLineCount={1} />
        </div>
      </div>
    )
  }

  /**
   * Render one item from base layer when dialog is closed
   */
  renderItemBase(item, index) {
    if (!item || !item.identity) return null

    //logger.info("EditorDialogBase:renderItem", item, index, this.state, this.props);

    return (
      <div className="EditorDialogItems row" key={index}>
        <div className="EditorDialogItems__name_base col">
          <MultilineText text={item.identity.name + ':'} defaultLineHeight={20} maxLineCount={1} />
        </div>
        <div className="EditorDialogItems__value_base col">
          <MultilineText text={propertyValueToString(item.type, item.value)} defaultLineHeight={20} maxLineCount={1} />
        </div>
      </div>
    )
  }

  /**
   * Render view of component in vertival lines when window is closed and
   * it show only settings inside dialog
   */
  getItemListVertical(items, base) {
    //logger.info("EditorDialogBase:getItemList", items, base, this.state, this.props);

    if (items || base) {
      let max = this.props.maxItems ? this.props.maxItems : items.length + (base ? base.length : 0)
      let objs = this.props.sortItems ? items.sort(identitySort) : items

      let itemList = []
      let itemCount = 0
      objs.map((s, i) => {
        let item = this.renderItem(s, i)
        if (item && itemCount++ < max) {
          itemList.push(item)
        }
        return item
      })
      if (base) {
        let bobjs = this.props.sortItems ? base.sort(identitySort) : base
        bobjs.map((s, i) => {
          let item = this.renderItemBase(s, i)
          if (item && itemCount++ < max) {
            if (items.findIndex((it) => nonCSCompare(it.identity.name, s.identity.name)) === -1) {
              itemList.push(item)
            }
          }
          return item
        })
      }
      //logger.info("EditorDialogBase:renderItems:MAX", max, objs, itemList, this.state, this.props);

      return (
        <div className="EditorDialogItems">
          {itemList}
          {itemCount > max ? (
            <div className="EditorDialogItems__more__visible" onClick={this.toggleViewMode.bind(this)}>
              <img src={iMoreData} alt="icon" />
            </div>
          ) : null}
        </div>
      )
    }
  }

  /**
   * Render view of component with horizontal strings, when window is closed and
   * it show only settings inside dialog
   */
  getItemListHorizontal(items, base) {
    //logger.info("EditorDialogBase:getItemListHorizontal", items, base, this.state, this.props);

    if (items || base) {
      let max = this.props.maxItems ? this.props.maxItems : 2
      let objs = this.props.sortItems ? items.sort((a, b) => nonCSCompare(a.name, b.name)) : items

      let itemList = []
      objs.map((s, i) => {
        let item = this.renderItem(s, i)
        if (item) {
          itemList.push(item)
        }
        return item
      })
      if (base) {
        let bobjs = this.props.sortItems ? base.sort((a, b) => nonCSCompare(a.name, b.name)) : base
        bobjs.map((s, i) => {
          let item = this.renderItemBase(s, i)
          if (item) {
            if (items.findIndex((it) => nonCSCompare(it.identity.name, s.identity.name)) === -1) {
              itemList.push(item)
            }
          }
          return item
        })
      }
      //logger.info("EditorDialogBase:getItemListHorizontal:MAX", max, objs, itemList, this.state, this.props);

      let id = this.props.className + '_horizontal_title'
      const heightOfRow = document.getElementById(id) ? document.getElementById(id).offsetHeight : 0
      let style = {}
      let arrow = false
      if (this.state.maxRows) {
        style = { 'max-height': 20 * max + 'px' }
        arrow = true
      } else if (heightOfRow > 20 * max) {
        this.setState({ maxRows: true })
      }

      return (
        <div>
          {arrow ? (
            <span className="EditorDialogItems__more__visible" onClick={this.toggleViewMode.bind(this)}>
              <img src={iMoreData} alt="icon" />
            </span>
          ) : null}
          <div className="ItemList_right" style={style} id={id}>
            {itemList}
          </div>
        </div>
      )
    }
  }

  /**
   * Render view of component when window is closed and
   * it show only settings inside dialog
   */
  renderItems() {
    if (this.props.isItems === itemState.VERTICAL) {
      return this.getItemListVertical(this.state[this.state.objectType], this.props.base)
    } else if (this.props.isItems === itemState.HORIZONTAL) {
      return this.getItemListHorizontal(this.state[this.state.objectType], this.props.base)
    }
  }

  /**
   * Render actual dialog
   */
  renderDialog = () => {
    // We don't show current dialog
    if (!(this.state.editMode || !this.props.isItems || this.props.isItems === itemState.NONE)) {
      return null
    }
    let height = this.state.height
    let ref = this.props.parentDialog ? 'dialog_' + this.state.objectType : 'dialog'
    //logger.info("EditorDialogBase:renderDialog", this.state.objectType, { ref, height }, this.state, this.props);

    return (
      <EditorDialog
        editingChild={this.props.editingChild}
        ref={ref}
        nextLevel={this.props.field && this.props.field.nextLevel ? this.props.field.nextLevel : null}
        level={this.props.level}
        appState={this.props.appState}
        actions={this.props.actions}
        objectType={this.props.objectType ? this.props.objectType : this.state.objectType}
        modalTitle={this.getTitle.bind(this)}
        modalFrame={this.state.objectForm ? this.state.objectForm.frame : null}
        confirmText={this.getText()}
        headerContent={this.renderHeader}
        editContent={this.renderEditor}
        footerContent={this.renderFooter}
        dialogContent={this.renderChildDialog}
        editHeight={height}
        isVisible={this.props.isVisible}
        isEditable={this.props.isEditable}
        isFullscreen={this.props.isFullscreen}
        majorObject={this.props.majorObject}
        dialogData={this}
        version={this.state.objectVersion}
        onChange={this.props.onChange}
        onEdit={this.startEdit.bind(this)}
        onClose={this.close.bind(this)}
        onCancel={this.cancelEdit.bind(this)}
        onVersionChange={this.changeVersion.bind(this)}
        onCreateDraft={this.props.onCreateDraft}
        onFinalize={this.props.onFinalize}
        onApprove={this.onApprove.bind(this)}
        onSave={this.save.bind(this)}
      />
    )
  }

  /**
   * Render control of items horizontally.
   */
  renderControlHorizontal(isEditable) {
    return (
      <div>
        {isEditable ? <EditButton className="ItemList__editButton" onEdit={this.toggleEditMode.bind(this)} /> : null}
        <span className={'ItemList__title ItemList__title_pointer'} onClick={this.toggleEditMode.bind(this)}>
          <div className="ItemList_left">{this.getButton()}</div>
        </span>
      </div>
    )
  }

  /**
   * Render control of items horizontally.
   */
  renderControlVertical(isEditable) {
    return (
      <div>
        <div
          className={
            'EditorDialogItems__button' +
            (isEditable < editableState.EDITABLE ? ' EditorDialogItems__button__viewing' : '')
          }
          onClick={this.toggleEditMode.bind(this)}
        >
          {this.getIconHtml()}
          {this.getButton()}
        </div>
      </div>
    )
  }

  /**
   * Render dialog in form of real dialog of list of setting in dialog when it closed
   * Componnet can be in dialog only mode or in data and dialog mode.
   * In Data mode you can see it inside component when dialog not show up and component will
   * maintain it visability and state inside.
   */
  render() {
    let dialog = this.getDialogRef()
    let isEditable = dialog ? dialog.state.isEditable : this.props.isEditable

    //logger.info("EditorDialogBase:render", isEditable, this.props.maxItems, this.state[this.state.objectType].length, this.state, this.props);
    return (
      <div className={this.props.className || ''}>
        <div className={'EditorDialogItems'}>
          {this.props.isItems === itemState.VERTICAL ? this.renderControlVertical(isEditable) : null}
          {this.props.isItems === itemState.HORIZONTAL ? this.renderControlHorizontal(isEditable) : null}

          <div className="EditorDialogItems__list">
            {this.state.editMode || !this.props.isItems || this.props.isItems === itemState.NONE
              ? this.props.parentDialog
                ? null
                : this.renderDialog()
              : this.renderItems()}
          </div>
        </div>
      </div>
    )
  }
}

EditorDialogBase.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object,
  modalTitle: PropTypes.string,
  buttonTitle: PropTypes.string,
  confirmText: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  isFullscreen: PropTypes.bool,
  isItems: PropTypes.number,
  sortItems: PropTypes.bool,
  maxItems: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  parentDialog: PropTypes.object, // Reference to parent dialog when exist. This force childs to use render from parent to avoid to much inheritance for styles.
  base: PropTypes.object, // Data of object from base layer. Can't change
  version: PropTypes.object,
  content: PropTypes.array, // List of content to upload.
  contentUri: PropTypes.string, // URI for content
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onEditableChange: PropTypes.func, // Editable status change for dialog
  onVersionChange: PropTypes.func,
  onContentChange: PropTypes.func, // Uploaded content change for dialog
  onCreateDraft: PropTypes.func,
  onFinalize: PropTypes.func,
  onApprove: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
}
