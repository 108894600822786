import PropTypes from 'prop-types'
import React from 'react'
import { nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './CounterDialog.scss'

export class CounterDialog extends EditorDialogBase {
  getObjectType() {
    return 'counter'
  }

  /**
   * Returns minimum height for dialog
   */
  getMinHeight() {
    return 300
  }

  /**
   * On initialize dialog load requered object
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/System/datasets/Counter',
      '/organizations/Apdax/systems/DifHub/applications/System/views/CounterDialog'
    )

    logger.info('CounterDialog:open', this.state, this.props)
  }

  /*
   * Check property before save
   * @param {object} counter - new state of object
   * @returns {string} - string with error text
   */
  checkInputErrors(counter) {
    let inputError = ''

    if (!counter.identity || !counter.identity.name) {
      inputError += 'Please specify counter name! '
    }
    if (!counter.type) {
      inputError += 'Please specify counter type! '
    }
    if (nonCSCompare(counter.type, 'Custom') && (!counter.configuration || !counter.configuration.reference)) {
      inputError += 'Please choose counter to configure! '
    }
    if (!nonCSCompare(counter.type, 'Custom') && !counter.expression) {
      inputError += 'Please specify counter expression! '
    }

    return inputError
  }

  renderEditor = (editState) => {
    // State and props.
    const counter = this.state.counter
    logger.info('CounterDialog:renderEditor', editState, counter, this.state, this.props)

    return (
      <div className="CounterDialog">
        {this.renderView(editState, (element) => {
          switch (element) {
            default:
              return null
            case 'configuration':
              return !counter.type || !nonCSCompare(counter.type, 'Custom') ? <div></div> : null
            case 'expression':
              return !counter.type || nonCSCompare(counter.type, 'Custom') ? <div></div> : null
          }
        })}
      </div>
    )
  }
}

CounterDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  counter: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
