import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { require_image } from '../../helpers'

/**
 * AdaptiveImage renders image with specified src for normal displays, and "src-2x" for retina displays
 */
export class AdaptiveImage extends Component {
  render() {
    const { src, alt, className, onClick, style } = this.props

    return (
      <img
        alt={alt}
        src={require_image(src).normal}
        className={className}
        onClick={onClick}
        style={style}
        srcSet={require_image(src).normal + ' 1x, ' + require_image(src).retina + ' 2x'}
      />
    )
  }
}

/**
 * all props are passed to <img> tag
 * */
AdaptiveImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
}
