/**
 * Created by Sasha Berger on 05.03.20.
 *
 * Component to edit reference to topology inside environment.
 * Component render reference to instances of the topology inside environment.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { deepCopy } from '../../helpers/index'
import { getElementMetadata } from '../../resources/lib/metadata'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './TopologyDialog.scss'

export class TopologyDialog extends EditorDialogBase {
  getObjectType() {
    return 'topologyReference'
  }

  /**
   * Init dialog height
   * @param props - set of initialization properties
   */
  InitHeight(props) {
    // Don't need logic here.
    return 445
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/System/datasets/TopologyReference',
      '/organizations/Apdax/systems/DifGate/applications/System/views/TopologyDialog',
      null,
      (view) => {
        if (this.props.referenceState) {
          // Let's update element to have local state for instances.
          // For it we need to change metadata for view in instance element.
          let currView = deepCopy(view)
          let dataElement = getElementMetadata(currView, 'instances')
          if (dataElement && dataElement.control && dataElement.control.value) {
            dataElement.control.value['referenceState'] = this.props.referenceState
          }
          return currView
        }
        return view
      }
    )

    //logger.info("TopologyDialog:open", this.state, this.props);
  }

  checkInputErrors(topologyReference) {
    let inputError = ''
    if (!topologyReference.reference) {
      inputError += 'Please select topology for environment!'
    } else if (!topologyReference.instanceNames) {
      inputError += 'Please select topology instances used in environment!'
    }
    return inputError
  }

  /**
   * Validate value of changed field
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @return true - change approved
   */
  onValidateChange = (element, field, value, topology, option) => {
    //logger.info("TopologyDialog:onValidateChange", { element, field, value, topology, option }, this.state, this.props);
    if (element === 'instances' && topology && option) {
      topology.identity = option.object.identity
      topology.version = option.object.version
    }
    return topology
  }

  renderEditor = (editState) => {
    //logger.info("TopologyDialog:renderEditor", { editState, topologyReference }, this.state, this.props);

    return (
      <div className="TopologyDialog" onClick={() => this.onCheckDialogHeight('.TopologyDialog')}>
        {this.renderView(editState)}
      </div>
    )
  }
}

TopologyDialog.propTypes = {
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  topologyReference: PropTypes.object,
  referenceState: PropTypes.string, // Owerride state of reference from metadaata.
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
