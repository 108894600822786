/* eslint-disable */

import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

async function askWritePermission() {
  try {
    // The clipboard-write permission is granted automatically to pages
    // when they are the active tab. So it's not required, but it's more safe.
    const { state } = await navigator.permissions.query({ name: 'clipboard-write' })
    return state === 'granted'
  } catch (error) {
    // Browser compatibility / Security error (ONLY HTTPS) ...
    return false
  }
}

// @params blob - The ClipboardItem takes an object with the MIME type as key, and the actual blob as the value.
// @return Promise<void>
const setToClipboard = async (blob) => {
  const data = [new ClipboardItem({ [blob.type]: blob })]
  await navigator.clipboard.write(data)
}

export function downloadSelectorAsPDF(selector, fileName, childSelector = '') {
  return new Promise((resolve, reject) => {
    html2canvas(selector).then(async (canvas) => {
      let imgData = canvas.toDataURL('image/png')

      // Can we copy a text or an image ?
      const canWriteToClipboard = await askWritePermission()

      // Copy a PNG image to clipboard
      if (canWriteToClipboard) {
        const response = await fetch(imgData)
        const blob = await response.blob()
        await setToClipboard(blob)
      }

      let doc = new jsPDF({ orientation: 'landscape', format: 'a4' })

      const parent = selector
      const children = childSelector ? parent.querySelectorAll(childSelector) : []
      console.log('downloadSelectorAsPDF', selector, childSelector)
      const parentTop = parent.getBoundingClientRect().top

      console.log('PDF parent', parent, 'children', children)

      const imgWidth = 295
      const pageHeight = 210 // a4
      const pxWidth = canvas.width

      const imgHeight = Math.floor((canvas.height * imgWidth) / canvas.width)

      let currentYPos = 0
      let remainingHeight = canvas.height

      for (let i = 0; i <= imgHeight / pageHeight; i++) {
        let currentPxHeight = Math.min(remainingHeight, Math.floor((canvas.width * 210) / 295))
        let initialCurrentPxHeight = currentPxHeight

        // check that none of child elements is split by page break. decrease height if needed
        let childrenNotSplit = false
        while (!childrenNotSplit) {
          let childrenSplit = false
          for (let j = 0; j < children.length; j++) {
            const childTop = children[j].getBoundingClientRect().top - parentTop
            const childBottom = children[j].getBoundingClientRect().bottom - parentTop
            //console.log("PDF child", children[j], "top", childTop, "b", childBottom, "position", currentYPos + currentPxHeight);

            if (childTop < currentYPos + currentPxHeight && childBottom > currentYPos + currentPxHeight) {
              console.log('PDF: child', children[j], 'offset', childTop)

              childrenSplit = true
              break
            }
          }
          console.log('PDF: for height ', currentPxHeight, 'top', currentYPos, 'result', childrenSplit)
          childrenNotSplit = !childrenSplit
          if (childrenSplit) {
            currentPxHeight -= 1
            if (currentPxHeight < initialCurrentPxHeight / 2) {
              console.log('PDF: cannot insert page break between child elements, returning to ', initialCurrentPxHeight)
              currentPxHeight = initialCurrentPxHeight
              break
            }
          }
        }

        let currentCmHeight = currentPxHeight * (295 / canvas.width)
        remainingHeight -= currentPxHeight
        let onePageCanvas = document.createElement('canvas')
        onePageCanvas.setAttribute('width', canvas.width)
        onePageCanvas.setAttribute('height', currentPxHeight)
        let ctx = onePageCanvas.getContext('2d')
        ctx.drawImage(
          canvas,
          0,
          /*i * pxHeight */ currentYPos,
          pxWidth,
          currentPxHeight,
          0,
          0,
          pxWidth,
          currentPxHeight
        )

        currentYPos += currentPxHeight

        let canvasDataURL = onePageCanvas.toDataURL('image/png')
        if (i > 0) {
          doc.addPage()
        }
        doc.setPage(i + 1)

        doc.addImage(canvasDataURL, 'PNG', 0, 0, imgWidth, currentCmHeight)
      }

      doc.save(fileName + '.pdf')
      resolve()
    })
  })
}
