import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Loader } from '../Loader/Loader'

import './LoginPageNew.scss'
import { authorize, getToken } from '../../helpers/auth'

export class LoginPageNew extends Component {
  constructor(props) {
    super(props)
    this.state = { error: '' }
  }

  componentDidMount() {
    if (window.AUTH_URL.indexOf('https://auth.metucat.com/core/') !== -1) {
      if (window.location.hash) {
        window.validateToken(
          () => {
            this.props.history.push(localStorage['loginReturnUrl'] ? localStorage['loginReturnUrl'] : '/select')
            localStorage['loginReturnUrl'] = ''
          },
          () => {
            this.props.history.push('/login')
            window.getToken()
          }
        )
      } else {
        if (!localStorage['currentUserToken']) {
          window.getToken()
        } else {
          this.props.history.push(localStorage['loginReturnUrl'] ? localStorage['loginReturnUrl'] : '/select')
          localStorage['loginReturnUrl'] = ''
        }
      }
    } else {
      if (window.location.hash) {
        getToken(
          () => {
            this.props.history.push(localStorage['loginReturnUrl'] ? localStorage['loginReturnUrl'] : '/select')
            localStorage['loginReturnUrl'] = ''
          },
          () => {
            this.props.history.push('/login')
          }
        )
      } else {
        if (!localStorage['currentUserToken']) {
          authorize()
        } else {
          this.props.history.push(localStorage['loginReturnUrl'] ? localStorage['loginReturnUrl'] : '/select')
          localStorage['loginReturnUrl'] = ''
        }
      }
    }
  }

  render() {
    return (
      <div className="LoginPageNew">
        <Loader />
      </div>
    )
  }
}

LoginPageNew.propTypes = {
  history: PropTypes.object
}
