/**
 * Created by sashaberger on 01.10.2017.
 *
 * Window for finilization of object version and cration of new version draft.
 *
 */
import PropTypes from 'prop-types'
import React from 'react'
import { deepCopy, editableState, getFullUri, getVersionOptions, nonCSCompare, versionToStr } from '../../helpers/index'
import logger from '../../helpers/logger'
import { getJsonSpecFields, getJsonSpecRecords } from '../../helpers/md'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditorDialogBase } from './EditorDialogBase'
import ObjectSearchMultistep from '../ObjectSearch/ObjectSearchMultistep'
import './DraftDialog.scss'
import './FinalizeDialog.scss'

const messageActions = getJsonSpecRecords('messageAction')
const messageActionFields = getJsonSpecFields('messageAction')

export class FinalizeDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    let actionName = ''
    let objectCaption = ''
    if (props.majorObject && props.majorObject.type !== undefined) {
      switch (props.majorObject.type) {
        case 'dataset':
          actionName = 'FinalizeVersion'
          objectCaption = 'dataset'
          break
        case 'interface':
          actionName = 'FinalizeVersion'
          objectCaption = 'interface'
          break
        case 'pipeline':
          actionName = 'FinalizeVersion'
          objectCaption = 'pipeline'
          break
        case 'view':
          actionName = 'FinalizeVersion'
          objectCaption = 'view'
          break
        case 'publication':
          actionName = 'FinalizePublication'
          objectCaption = 'publication'
          break
        case 'subscription':
          actionName = 'FinalizeSubscription'
          objectCaption = 'subscription'
          break
        case 'topology':
          actionName = 'FinalizeVersion'
          objectCaption = 'topology'
          break
        case 'deployment':
          actionName = 'FinalizeVersion'
          objectCaption = 'deployment'
          break
        case 'environment':
          actionName = 'FinalizeVersion'
          objectCaption = 'environment'
          break
        case 'settings':
          actionName = 'FinalizeVersion'
          objectCaption = 'settings'
          break
        case 'system':
          actionName = 'FinalizeVersion'
          objectCaption = 'settings of system'
          break
        case 'application':
          actionName = 'FinalizeVersion'
          objectCaption = 'settings for application'
          break
        default:
          break
      }
    }

    let messageAction = this.getMessageAction(actionName)

    this.state = Object.assign({}, this.state, {
      messageAction: messageAction,
      objectCaption: objectCaption,

      isSelectingIssue: false,
      selectedObjectPath: this.props.majorObject ? this.props.majorObject.object.parent.name : '',

      version: versionToStr(this.props.currentVersion),
      options: this.props.previousVersion ? getVersionOptions(this.props.previousVersion) : null
    })

    logger.info('FinalizeDialog', messageAction, this.state, props)
  }

  getObjectType() {
    return 'message'
  }

  /**
   * Returns title for dialog
   */
  getTitle() {
    let caption = this.getMessageActionValue('Caption')
    let button = caption ? caption.split(' ')[0] : ''
    let title =
      button +
      ' ' +
      this.state.objectCaption +
      ' "' +
      this.props.majorObject.identity.name +
      '" version: ' +
      this.state.version

    return title
  }

  /**
   * Returns text for confitmation
   */
  getText() {
    let caption = this.getMessageActionValue('Caption')
    let button = caption ? caption.split(' ')[0] : ''

    return button
  }

  /*
   * Check property before save
   * @param {object} settings - new state of object
   * @returns {string} - string with error text
   */
  checkInputErrors(message) {
    let inputError = ''
    if (!message.subject || message.subject.length === 0) {
      inputError += 'Please type issue subject or select message issue! '
    }
    if (!message.message || message.message.length === 0) {
      inputError += 'Please type message to architect with reson for approval!'
    }
    return inputError
  }

  /**
   * Get current action for execution
   * @param action to find
   * @return Action array of values
   */
  getMessageAction(action) {
    //logger.info("FinalizeDialog:getMessageAction", action, messageActions);

    return messageActions.find((item) => nonCSCompare(action, item.values[0]))
  }

  /**
   * Get current action value
   * @param name of field we need from current action
   * @param action - action value of which to get
   * @return value
   */
  getMessageActionValue(name, action = this.state.messageAction) {
    const nameIndex = messageActionFields.findIndex((field) => nonCSCompare(field.identity.name, name))
    //logger.info("FinalizeDialog:getMessageActionValue", name, nameIndex, action);

    return nameIndex > -1 && action.values ? action.values[nameIndex] : ''
  }

  /**
   * Execute activity when save button pushed in dialog
   * @param close - close dialog or not
   * @return
   */
  save = (close) => {
    let inputError = ''

    // input checks go here
    inputError = this.checkInputErrors(this.state.message)

    logger.log('FinalizeDialog:save', this.state.message, inputError)

    if (inputError.length > 0) {
      if (this.state.change) {
        this.state.change(true, '', '', inputError)
      }
      return true
    }

    if (this.state.change) {
      this.state.change(true, 'Data sent to service...', '', '')
    }

    // Issue selected
    let message = deepCopy(this.state.message)
    message['description'] = this.getMessageActionValue('Info')

    //console.log("FinalizeDialog:onFinalize", this.state.subjectText, this.state.selectedIssue, this.state.messageText);
    this.props.onFinalize(message, this.state.version, close, this.onSent.bind(this))

    // Delay to close on save.
    return true
  }

  onSelect = (obj, objectType) => {
    logger.info('FinalizeDialog:onSelect', obj, objectType, getFullUri(obj))

    if (nonCSCompare(objectType, 'issues') && obj && obj.identity) {
      // Issue selected
      let message = deepCopy(this.state.message)

      message['issue'] = obj
      message['subject'] = obj.identity.name + ': ' + obj.identity.description

      this.Change(message)

      this.setState({
        isSelectingIssue: !this.state.isSelectingIssue
      })
    } else {
      let message = this.state.message.message ? { message: this.state.message.message } : {}

      this.Change(message)

      if (obj) {
        this.setState({
          selectedObjectPath: getFullUri(obj)
        })
      }
    }
    return true
  }

  /**
   * Change subject of the message
   * @param e - event of data change.
   * @return
   */
  onSubjectChange(e) {
    let data = e.target.value
    //console.log("FinalizeDialog:onSubjectChange", e);

    let message = deepCopy(this.state.message)
    message['subject'] = data

    this.Change(message)
  }

  /**
   * Change text of the message
   * @param e - event of data change.
   * @return
   */
  onMessageChange(e) {
    let data = e.target.value
    //console.log("FinalizeDialog:editMessage", e);

    let message = deepCopy(this.state.message)
    message['message'] = data

    this.Change(message)
  }

  /**
   * Change text of the message
   * @param e - event of data change.
   * @return
   */
  toggleSelectIssue = () => {
    logger.info('FinalizeDialog:toggleSelectIssue', this.props, this.state)
    if (this.state.isSelectingIssue) {
      let message = this.state.message.message ? { message: this.state.message.message } : {}

      this.Change(message)

      this.setState({
        isSelectingIssue: true,
        selectedObjectPath: this.props.majorObject ? this.props.majorObject.object.parent.name : ''
      })
    } else {
      this.setState({ isSelectingIssue: false })
    }

    this.setState({ isSelectingIssue: !this.state.isSelectingIssue }, () => {
      if (this.state.isSelectingIssue) {
        let message = this.state.message.message ? { message: this.state.message.message } : {}

        this.Change(message)

        this.setState({
          selectedObjectPath: this.props.majorObject ? this.props.majorObject.object.parent.name : ''
        })
      }
    })
  }

  onVersionChange(version) {
    //console.log("FinalizeWindow:onVersionChange", version, this.state.isVersionSelecting);

    this.setState({
      version: version
    })
  }

  /**
   * Render scrollable area of dialog
   * @return
   */
  renderEditor = (editState) => {
    let info = this.getMessageActionValue('Info')
    let message = this.state.message
    let subject = message.subject ? message.subject : ''

    //logger.info("FinalizeDialog:renderEditor", message, this.state, this.props, editState);
    return (
      <div className="FinalizeDialog">
        <div className="row FinalizeDialog__subjectRow">
          <div className="col-xs-7">
            <button className="btn btn_blue FinalizeDialog__issue" onClick={this.toggleSelectIssue}>
              Select existing issue
            </button>
          </div>
          <div className="col-xs-9">
            {message.issue ? (
              <div className="FinalizeDialog__subjectText">{subject}</div>
            ) : (
              <div className="FinalizeDialog__subjectInput">
                <EditableEntity
                  data={subject}
                  dataType={{ name: 'string' }}
                  dataProps={{
                    placeholder: 'Type subject of the new issue here',
                    onChange: this.onSubjectChange.bind(this)
                  }}
                  isEditable
                  inEditMode={editState > editableState.EDITABLE}
                />
              </div>
            )}
          </div>
        </div>
        {this.state.isSelectingIssue ? (
          <div className="FinalizeDialog__issueSelectDiv">
            Select issue: <br />
            <ObjectSearchMultistep
              actions={this.props.actions}
              parentPath={this.state.selectedObjectPath}
              targetPath={this.state.path + '/issues'}
              targetType="issues"
              childrenByType={{
                organizations: ['systems', 'issues'],
                systems: ['applications', 'issues'],
                applications: [
                  'datasets',
                  'interfaces',
                  'pipelines',
                  'views',
                  'publications',
                  'subscriptions',
                  'issues'
                ],
                datasets: 'issues',
                interfaces: 'issues',
                pipelines: 'issues',
                views: 'issues',
                publications: 'issues',
                subscriptions: 'issues'
              }}
              onSelect={this.onSelect}
              onAdjust={this.onAdjust}
            />
          </div>
        ) : null}
        <div className="FinalizeDialog__info">{info}</div>
        <div className="FinalizeDialog__message FinalizeDialog__message__multiLine  clearfix">
          <EditableEntity
            className={'FinalizeDialog__message'}
            data={message.message ? message.message : ''}
            dataType={{ name: 'text' }}
            dataProps={{
              placeholder: 'Type message text',
              onChange: this.onMessageChange.bind(this)
            }}
            isEditable
            inEditMode={editState > editableState.EDITABLE}
          />
        </div>
        {this.state.options ? (
          <div>
            <div className="FinalizeDialog__version">Adjust version before finalizing:</div>
            <div className="DraftDialog__text">
              <div className="row ">
                {this.state.options.map((version, i) => (
                  <div className="col-xs-8" key={i}>
                    <label className="DraftDialog__name">{version.name + ':'}</label>
                    <input
                      type="radio"
                      className="Input_radio"
                      id={'version_' + version.label}
                      checked={this.state.version === version.label}
                      onClick={() => this.onVersionChange(version.label)}
                    />
                    <label htmlFor={'version_' + version.label}>{version.label}</label>
                    <div className="DraftDialog__description">{version.description}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

FinalizeDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  currentVersion: PropTypes.object,
  previousVersion: PropTypes.object,
  onClose: PropTypes.func,
  onFinalize: PropTypes.func
}
