/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-typos */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu'
import { fieldTypeToString, objectNameFromPathByType, usageIconUris } from '../../helpers'
import { getObjectNew, getRequestFromPath } from '../../helpers/api'
import { getFullUri } from '../../helpers/index'
import { Loader } from '../Loader/Loader'
import { SimpleTooltip } from '../SimpleTooltip/SimpleTooltip'
import './SQLDesigner.scss'

import iI from '../../resources/images/i-dark-blue-2x.png'
import { downloadSelectorAsPDF } from '../../helpers/pdfDownloader'

const borderColorList = [
  '#6CD2DC',
  '#007DC5',
  '#FFC107',
  '#4CAF6D',
  '#B63455',
  '#8F70CB',
  '#005588',
  '#04C1B4',
  '#9BE620',
  '#FF9717',
  '#BE569F',
  '#565CCD'
]

const types = [
  { id: 1, label: 'boolean', value: { name: 'boolean' } },
  { id: 2, label: 'small', value: { name: 'small' } },
  { id: 3, label: 'short', value: { name: 'short' } },
  { id: 4, label: 'integer', value: { name: 'integer' } },
  { id: 5, label: 'long', value: { name: 'long' } },
  { id: 6, label: 'huge', value: { name: 'huge' } },
  { id: 7, label: 'byte', value: { name: 'byte' } },
  { id: 8, label: 'unsigned short', value: { name: 'unsigned short' } },
  { id: 9, label: 'unsigned integer', value: { name: 'unsigned integer' } },
  { id: 10, label: 'unsigned long', value: { name: 'unsigned long' } },
  { id: 11, label: 'unsigned huge', value: { name: 'unsigned huge' } },
  { id: 12, label: 'float', value: { name: 'float' } },
  { id: 13, label: 'double', value: { name: 'double' } },
  { id: 14, label: 'decimal', value: { name: 'decimal' } },
  { id: 15, label: 'string', value: { name: 'string' } },
  { id: 16, label: 'text', value: { name: 'text' } },
  { id: 17, label: 'date', value: { name: 'date' } },
  { id: 18, label: 'time', value: { name: 'time' } },
  { id: 19, label: 'DateTime', value: { name: 'datetime' } },
  { id: 20, label: 'TimeSpan', value: { name: 'timespan' } },
  { id: 21, label: 'guid', value: { name: 'guid' } },
  { id: 22, label: 'enum', value: { name: 'enum' } },
  { id: 23, label: 'field', value: { name: 'field' } },
  { id: 24, label: 'structure', value: { name: 'structure' } }
]

const typesXML =
  '<datatypes db="mysql"><group color="rgb(238,238,170)">' +
  types.reduce(
    (prev, type) => prev + '<type label="' + type.label + '" quote="" sql="' + type.label + '" length="0" />',
    ''
  ) +
  '</group></datatypes>'

export class SQLDesigner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      loadedDatasetCount: 0,
      currentTableLinks: []
    }
    this.tableTimers = []
    this.tableMoves = []

    window.usageIcons = {}
    Object.keys(usageIconUris).forEach((key) => {
      window.usageIcons[key] = '/images/usage/24/' + usageIconUris[key] + '.png'
    })

    this.menuRef = React.createRef()
  }

  incrementLoadedDatasetCount(done) {
    this.setState({ loadedDatasetCount: this.state.loadedDatasetCount + 1 }, () => {
      //console.log("incrementLoadedDatasetCount ",(this.state.loadedDatasetCount + 1));
      /*
      if (done && (this.state.loadedDatasetCount + 1) % 5 == 0) {
        done();
      }
      */
    })
  }

  onTableMove(table) {
    //console.log("onTableMove", table);
    let name = table.data.title
    name = name.replace(/\/v(\d+)$/, '')
    let subName = table.data.title //.substr(table.data.title.indexOf('/') + 1);
    console.log('onTableMove', subName, table)
    let prevTimer = this.tableTimers[name]
    if (prevTimer) clearTimeout(prevTimer)
    this.tableTimers[name] = setTimeout(() => {
      //console.log("new position for table ",name,table.x,table.y,table.borderColor,table.newWidth, table.newHeight);
      if (!this.tableMoves[name]) this.tableMoves[name] = true
      else
        this.props.onTableMove(
          name,
          table.x,
          table.y,
          table.borderColor,
          table.links,
          table.path,
          table.newWidth,
          table.newHeight,
          table.links_onetomany,
          subName
        )
    }, 1000)
  }

  getFirstDatasetRow(datasetName, results) {
    let table = results.filter((res) => res.displayName === datasetName)[0]
    if (
      table &&
      table.structure &&
      table.structure.fields &&
      table.structure.fields.length &&
      table.structure.fields[0].identity
    ) {
      let firstFieldName = table.structure.fields[0].identity.name
      return firstFieldName
    }
    return null
  }

  fieldRelations(fld, results) {
    if (!fld) return ''

    if (fld.type === 'Structure' || fld.type === 'Enum' || fld.type === 'Reference') {
      const ref = fld.reference || fld.subscription || '/' + fld.identity.name
      let datasetName = objectNameFromPathByType(ref, 'datasets') || ref.replace('/', '')
      //console.log("Field structure: ", fld, datasetName);
      let found = false
      results.map((res) => (res.identity.name === datasetName ? (found = true) : null))
      //console.log("found: " + fld.reference, found);
      if (found) {
        let table = results.filter((res) => res.identity.name === datasetName)[0]
        if (
          table &&
          table.structure &&
          table.structure.fields &&
          table.structure.fields.length &&
          table.structure.fields[0].identity
        ) {
          //console.log("fieldRelations",table.displayName);
          let firstFieldName = table.structure.fields[0].identity.name
          return {
            table: table.displayName,
            row: firstFieldName,
            type: fld.type
          }
        }
      }
    }

    return ''
  }

  /**
   * Returns list of tables that are referenced by fields of specified table and that are present in the list
   * @param table
   * @param results
   * @returns {*}
   */
  tableFieldsRelations(table, results) {
    if (!table || !table.structure || !table.structure.fields) return []
    return table.structure.fields.reduce((prev, fld) => {
      let rel = this.fieldRelations(fld, results)
      return prev.concat(rel ? rel.table : [])
    }, [])
  }

  /**
   * Returns incoming and outcoming table relations
   * @param table
   * @param results
   */
  tableRelations(table, results) {
    //console.log("tableRelations", table, results);
    let allRelations = results.map((ds) => this.tableFieldsRelations(ds, results))
    let ret = allRelations[results.indexOf(table)] // own outcoming
    allRelations.forEach((rel, index) => {
      if (rel.indexOf(table.displayName) !== -1) {
        ret.push(results[index].displayName)
      }
    })
    ret = ret.filter((table, index) => ret.indexOf(table) === index) // unique
    //console.log("tableRelations for "+table.identity.name, ret);
    return ret
  }

  sortDatasets(list) {
    let ret = list.slice()
    let relCount = []

    for (let il = 0; il < list.length; il++) {
      //console.log("list[il]", list[il]);
      relCount[list[il].identity.name] = this.tableRelations(list[il], list).length
    }

    //console.log("relCount", relCount);

    list.sort((a, b) => relCount[a.identity.name] > relCount[b.identity.name])

    //console.log("sorted list", list);

    for (let rc = 0; rc < 3; rc++) {
      for (let il = 0; il < list.length; il++) {
        let name = list[il].identity.name
        let i = ret.map((ds) => ds.identity.name).indexOf(name)

        let rels = this.tableRelations(ret[i], list)
        //console.log("for " + ret[i].identity.name + " relations", rels);
        if (rels.length > 0) {
          let j = ret.map((ds) => ds.identity.name).indexOf(rels[0])
          if (j !== i && j + 1 !== i && j + 1 < list.length) {
            // move i-th ds to position j+1
            let ds = ret[i]
            for (let k = i; k < ret.length - 1; k++) ret[k] = ret[k + 1]
            for (let k = ret.length - 1; k > j + 1; k--) ret[k] = ret[k - 1]
            ret[j + 1] = ds
            //console.log("moved " + ds.identity.name + " from " + i + " to " + (j + 1));
          }
        }
      }
      list = ret
    }

    return ret
  }

  /**
   * Element name is view name or "view name:dataset", the function extracts view name
   * @param elName
   * @returns {*}
   */
  viewFromElement(elName) {
    if (elName.indexOf(':') === -1) return elName
    else {
      return elName.substr(0, elName.indexOf(':'))
    }
  }

  /**
   * the function extractss dataset name from element.identity.name
   * @param elName
   * @returns {string}
   */
  datasetNameFromElement(elName) {
    if (elName.indexOf(':') === -1) return ''
    else {
      return elName.substr(elName.indexOf(':') + 1)
    }
  }

  // we have to pass it as string to child component because it will be xml-coded
  renderType(fld, ds) {
    return JSON.stringify({
      displayType: fieldTypeToString(fld, ds),
      label: fld.type,
      datasetReference: fld.subscription || fld.reference
    })
    /*
    let elem = formatByType['typeReference']({displayType: fieldTypeToString(fld, ds), label: fld.type, datasetReference: fld.subscription || fld.reference});
    console.log(elem);
    if (typeof(elem) === "string")
      return elem;
    let div = document.createElement('div');
    console.log(div);
    ReactDOM.render(elem, div);
    return div.innerHTML;
    */
  }

  datasetDisplayName(ds) {
    return ds.identity.name + '/v' + ds.version.major
  }

  datasetDisplayNameNoVersion(ds) {
    return ds.identity.name
  }

  /**
   * load subscriptions, subscription datasets, create XML and show chart view
   * @param results  - list of full datasets
   */
  updateDisplay(results) {
    let xml = []
    let subscriptionPromises = []
    let subscriptionPaths = []

    //console.log("chart view prop subs", this.props.subscriptions);

    // load all Application's subscriptions

    let appSubscriptionPromises = this.props.subscriptions.map((sub) => {
      let uri = getFullUri(sub)
      //console.log("will load sub from '" + uri + "'", getRequestFromPath(uri));
      //return getRequestFromPath(uri).get();
      return getObjectNew(getRequestFromPath(uri), 'subscription', null)
    })

    Promise.all(appSubscriptionPromises).then((subResults) => {
      // add to Chart all datasets of Application's own subscriptions

      subResults.forEach((subData) => {
        subData.type = 'subscription'
        subData.datasets.map((ds) => subscriptionPaths.push(getFullUri(subData) + '/datasets/' + ds.identity.name))
        //console.log("chart view sub ds", subData.datasets);
      })

      //console.log("chart view all app sub ds", subscriptionPaths);

      // add to Chart all datasets from subscriptions, that are referenced from Application's datasets

      results.forEach((ds) => {
        if (ds.structure && ds.structure.fields) {
          ds.structure.fields.forEach((fld) => {
            //const ref = fld.subscription || fld.reference;
            let ref = fld.reference
            if (fld.subscription) {
              ref = fld.subscription // + '/datasets/' + objectNameFromPathByType(fld.reference, 'datasets');
            }
            if (ref) {
              if (ref.indexOf('/subscriptions/') !== -1) {
                //console.log("In dataset " + ds.identity.name + " found reference: ", ref);
                subscriptionPaths.push(ref)
              }
            }
          })
        }
        ds.displayName = this.datasetDisplayName(ds)
      })
      subscriptionPaths = subscriptionPaths.filter((path, index) => subscriptionPaths.indexOf(path) === index) // unique

      // load all subscriptions that are used in Application's datasets' fields

      console.log('subscriptionPaths', subscriptionPaths)

      console.log('subscriptionPaths len', subscriptionPaths.length)

      let subscriptionPathParts = subscriptionPaths.map((path, index) => path.substr(0, path.indexOf('/datasets/')))

      console.log('len (subscriptionPathParts)', subscriptionPathParts.length)

      let subscriptionPathPartsUnique = subscriptionPathParts
        .filter((path, index) => subscriptionPathParts.indexOf(path) === index)
        .filter((path) => path)

      console.log('subscriptionPathsPartsUnqie', subscriptionPathPartsUnique)

      //console.log("unique subscriptionPathParts", subscriptionPathPartsUnique);

      // get all subscriptions - elements are stored there (only unique)

      //subscriptionPromises.concat
      subscriptionPromises = subscriptionPathPartsUnique.map(
        (path, index) =>
          new Promise((resolve, reject) => {
            //getRequestFromPath(path).get().then((data)=>{
            console.log('get subscription from path', path, getRequestFromPath(path))
            getObjectNew(getRequestFromPath(path), 'subscription', null).then(
              (data) => {
                //data.type = 'subscription';
                //data._path = getFullUri(data);
                this.incrementLoadedDatasetCount()
                resolve(data)
              },
              () => resolve(null)
            )
          })
      )

      Promise.all(subscriptionPromises).then((subscriptionData) => {
        console.log('Promise all sub promises', subscriptionData)

        this.props.actions.receiveObjectList(
          'subscription',
          subscriptionData.filter((d) => d)
        )

        subscriptionPaths = subscriptionPaths.filter((path, index) => {
          let sub = subscriptionData.filter((sub) => sub && sub._path === subscriptionPathParts[index])[0]
          if (sub && sub.object) {
            let elements = sub.object.elements || []
            let displayName = sub.identity.name + '/' + objectNameFromPathByType(path, 'datasets')
            let defaultElement = elements.reduce(
              (p, c) =>
                this.viewFromElement(c.identity.name) === this.props.selectedView &&
                this.datasetNameFromElement(c.identity.name) === displayName
                  ? c
                  : p,
              null
            )
            //console.log("subscriptionPaths", path, sub, elements, displayName, defaultElement);
            if (defaultElement && defaultElement.frame.position.left < 0) {
              console.log('will not load', path)
              //return false;
              return true
            }
          }
          return true
        })

        console.log('subscriptionPaths filtered', subscriptionPaths)

        let subscriptionDatasetPromises = subscriptionPaths.map(
          (path, index) =>
            new Promise((resolve, reject) => {
              //getRequestFromPath(path).get().then((data)=>{
              //console.log("getRequestFromPath", path, getRequestFromPath(path).url());
              getObjectNew(getRequestFromPath(path), 'dataset', null).then(
                (data) => {
                  this.incrementLoadedDatasetCount()
                  resolve(data)
                },
                () => resolve(null)
              )
            })
        )

        Promise.all(subscriptionDatasetPromises).then((subscriptionDatasetData) => {
          console.log('All sub ds loaded', subscriptionDatasetData)

          this.setState({
            totalDatasetCount: this.state.totalDatasetCount + subscriptionDatasetPromises.length
          })

          results = results.concat(
            subscriptionDatasetData
              .map((ds, index) => {
                if (!ds || !ds.object) return null
                return Object.assign(ds, {
                  subscriptionData: subscriptionData.filter(
                    (sub) => sub && sub._path === subscriptionPathParts[index]
                  )[0],
                  displayName:
                    objectNameFromPathByType(subscriptionPaths[index], 'subscriptions') +
                    '/' +
                    this.datasetDisplayName(ds),
                  _path: subscriptionPaths[index] //ds.object.parent.name + "/datasets/" + ds.identity.name
                })
              })
              .filter((ds) => ds != null)
          )
          this.setState({ loading: false })

          //console.log("DisplayName:", results.map(ds=>ds.displayName));
          //console.log("Path:", results.map(ds=>ds));

          results = results.filter((d) => d)

          results = this.sortDatasets(results)

          this.setState({ datasets: results })

          //results.map(ds => this.tableTimers[ds.identity.name] = )

          //console.log("promise all then");

          xml = results
            .map((ds, index) => {
              //console.log("next ds", ds.identity.name, ds);

              //let x = (index % 6) * 130;
              //let y = Math.floor(index / 6) * 200;

              // 4 datasets -> 250
              // 6 datasets -> 300
              // 14 datasets -> 500 (max)
              let Range = Math.min(500, 250 + (this.props.datasets.length - 4) * 50)

              let angle = (index / this.props.datasets.length) * 2 * Math.PI
              let x = Math.cos(angle) * Range + Range
              let y = Math.sin(angle) * Range + Range
              let w = null
              let h = null
              //let h = ds && ds.structure && ds.structure.fields && ds.structure.fields.length ? Math.min(400, 20 * ds.structure.fields.length + 50) : null;

              let borderColor = borderColorList[index % borderColorList.length]
              let links = []
              let links_onetomany = []

              let o = ds.object

              if (ds.subscriptionData) {
                //console.log("full subscription object", ds.subscriptionData);
                o = ds.subscriptionData.object
                x = -1 // by default hide subscription datasets
              }

              if (o && o.properties) {
                o.properties.forEach((p) => {
                  if (!p.value) return
                  if (p.identity.name === 'x') x = p.value
                  if (p.identity.name === 'y') y = p.value
                  if (p.identity.name === 'color') borderColor = p.value
                  /*
                 if (p.identity.name === 'links')
                 links = p.value.split(",");
                 if (p.identity.name === 'links_onetomany')
                 links_onetomany = p.value.split(",");
                 */
                })
              }

              if (o && o.elements) {
                // position of normal datasets is stored in dataset.object.elements and element.identity.name == view
                let defaultElement = o.elements.reduce(
                  (p, c) => (this.viewFromElement(c.identity.name) === this.props.selectedView ? c : p),
                  null
                )

                // position of subscription datasets is stored in subscription.object.elements and element.identity.name == view:datasetName
                if (ds.subscriptionData) {
                  let dsSubName = ds.subscriptionData.identity.name + '/' + ds.identity.name
                  //console.log("looking for defaultElement with name",ds.displayName,"or",dsSubName);
                  o.elements.forEach((c) => {
                    //console.log("sub",ds.subscriptionData,"element",c,"view",this.viewFromElement(c.identity.name),this.props.selectedView,"ds name",this.datasetNameFromElement(c.identity.name),ds.displayName);
                  })
                  defaultElement = o.elements.reduce(
                    (p, c) =>
                      this.viewFromElement(c.identity.name) === this.props.selectedView &&
                      this.datasetNameFromElement(c.identity.name) === ds.displayName
                        ? c
                        : p,
                    null
                  )
                  if (!defaultElement) {
                    // if no element with "ds name + v. major" is found, then it is older subscription and we need to search for element "ds name"
                    defaultElement = o.elements.reduce(
                      (p, c) =>
                        this.viewFromElement(c.identity.name) === this.props.selectedView &&
                        this.datasetNameFromElement(c.identity.name) === dsSubName
                          ? c
                          : p,
                      null
                    )
                  }
                  console.log(
                    'searching for ',
                    ds.displayName,
                    'or',
                    dsSubName,
                    'in',
                    o.elements.map((c) => this.datasetNameFromElement(c.identity.name))
                  )
                  //console.log("defaultElement", defaultElement);
                }

                if (defaultElement) {
                  if (defaultElement.frame && defaultElement.frame.position) {
                    x = defaultElement.frame.position.left
                    y = defaultElement.frame.position.top
                    w = defaultElement.frame.position.width
                    h = defaultElement.frame.position.height
                  }
                  if (defaultElement.style && defaultElement.style.border && defaultElement.style.border.left)
                    borderColor = defaultElement.style.border.left.color
                  if (defaultElement.links) {
                    links = links.concat(
                      defaultElement.links.filter((link) => link.type === 'One-To-One').map((link) => link.element.name)
                    )
                    links_onetomany = links.concat(
                      defaultElement.links
                        .filter((link) => link.type === 'One-To-Many')
                        .map((link) => link.element.name)
                    )
                  }
                }
              }

              links = links.filter((link, index) => links.indexOf(link) === index) //unique
              links_onetomany = links_onetomany.filter(
                (link, index) => links_onetomany.indexOf(link) === index && links.indexOf(link) === -1
              ) //unique

              ds.x = x
              ds.y = y
              ds.borderColor = borderColor
              ds.links = links
              ds.links_onetomany = links_onetomany

              // if dataset was not fully loaded it is not rendered
              if (!ds.structure || !ds.structure.fields || !ds.object) return null

              //if (x < 0)
              //  return null;

              /*
            console.log('<table x="' + x + '" y="' + y + '" ' +
            'w="' + w + '" ' +
            'h="' + h + '" ' +
            'borderColor="' + borderColor + '" ' +
            'name="' + ds.displayName + '" ' );
*/

              return (
                '<table x="' +
                x +
                '" y="' +
                y +
                '" ' +
                'w="' +
                w +
                '" ' +
                'h="' +
                h +
                '" ' +
                'borderColor="' +
                borderColor +
                '" ' +
                'name="' +
                ds.displayName +
                '" ' +
                'usage="' +
                ds.object.usage +
                '" ' +
                'path="' +
                getFullUri(ds) +
                '" ' +
                'links_onetomany="' +
                ds.links_onetomany.join(',') +
                '" ' +
                'links="' +
                links.join(',') +
                '" >' +
                ds.structure.fields.map((fld, fldIndex) => {
                  let relationData = this.fieldRelations(fld, results)
                  //console.log("relationData for "+ds.displayName, relationData);
                  let relation = relationData
                    ? '<relation table="' +
                      relationData.table +
                      '" row="' +
                      relationData.row +
                      '" type="reference" referencetype="' +
                      relationData.type +
                      '" />'
                    : ''

                  if (fldIndex === 0) {
                    ds.links.forEach((link) => {
                      if (link)
                        relation +=
                          '<relation table="' +
                          link +
                          '" row="' +
                          this.getFirstDatasetRow(link, results) +
                          '" type="link" />'
                    })
                    ds.links_onetomany.forEach((link) => {
                      if (link)
                        relation +=
                          '<relation table="' +
                          link +
                          '" row="' +
                          this.getFirstDatasetRow(link, results) +
                          '" type="link_onetomany" />'
                    })
                  }

                  //console.error(ds.displayName + '/' + fld.identity.name);
                  ds.structure.keys.forEach((key) => {
                    if (key.fields && key.fields.includes(fld.identity.name)) {
                      if (key.constraint && key.constraint.reference) {
                        let keyDs = objectNameFromPathByType(key.constraint.reference, 'datasets')
                        //console.warn("constarint 0", key.constraint, this.getFirstDatasetRow(keyDs, results));
                        results.forEach((d) => {
                          //console.log("Test",d,key);
                          if (
                            d._path === key.constraint.reference ||
                            (d.object &&
                              d.object.parent &&
                              d.object.parent.name + '/datasets/' + d.identity.name === key.constraint.reference)
                          )
                            keyDs = d.displayName
                        })
                        //console.warn("constarint 1", keyDs, this.getFirstDatasetRow(keyDs, results));

                        /*
                     if (key.constraint.indexOf('/subscriptions/') !== -1) {
                     //console.log("Key constraint by subscription", key.constraint, keyDs);
                     results.map(d => {
                     //console.log("Test",d,key);
                     if (d._path == key.constraint)
                     keyDs = d.displayName;
                     });
                     }
                     */

                        //const keyDs = objectNameFromPathByType(key.constraint, 'datasets');
                        //console.warn("constarint getFirstDatasetRow", keyDs, this.getFirstDatasetRow(keyDs, results));

                        relation +=
                          '<relation table="' +
                          keyDs +
                          '" row="' +
                          this.getFirstDatasetRow(keyDs, results) +
                          '" type="constraint" cardinality="' +
                          (key.cardinality || 'One-To-Many') +
                          '" />'
                      }
                    }
                  })

                  let key = ds.structure.keys.reduce((prev, cur) => {
                    if (!cur.fields) return ''
                    if (cur.fields.indexOf(fld.identity.name) === -1) return prev
                    if (prev !== 'PRIMARY' && !cur.primary) return 'SECONDARY'
                    else return 'PRIMARY'
                  }, '')

                  /*
               let typeName = fld.type.toLowerCase();
               if ((typeName === 'structure' || typeName === 'enum' ) && fld.reference) {
               const ref = fld.subscription || fld.reference;
               if (ref.indexOf('/subscriptions/') !== -1)
               typeName += ": /" + objectNameFromPathByType(ref, 'subscriptions') + "/" + objectNameFromPathByType(ref, 'datasets');
               else {
               //console.log("compare '" + fld.reference.substr(0, fld.reference.indexOf('/datasets/')) + "' and '" + app_path + "'");
               if (ref.substr(0, ref.indexOf('/datasets/')) == app_path)
               typeName += ": " + objectNameFromPathByType(ref, 'datasets');
               else
               typeName += ": /" + objectNameFromPathByType(ref, 'datasets');
               }
               }
               */
                  let typeName = this.renderType(fld, ds)
                  let count = fld.count

                  return (
                    '<row name="' +
                    fld.identity.name +
                    '" key="' +
                    key +
                    '"><datatype>' +
                    typeName +
                    '</datatype><count>' +
                    count +
                    '</count><default>' +
                    fld.value +
                    '</default>' +
                    relation +
                    '</row>'
                  )
                }) +
                '</table>'
              )
            })
            .filter((r) => r !== null)

          // merge xml records and output them into component
          let xmlString = '<?xml version="1.0" encoding="utf-8" ?><sql>' + typesXML + xml.join('') + '</sql>'
          // window.savedChartXmlString = xmlString // it not update our component right - fix it in future
          //console.log(xmlString);

          document.getElementById('textarea').value = xmlString
          document.getElementById('clientload').click()
        })
      })
    })
  }

  /**
   * show loader, load datasets from props.datasets, call updateDisplay
   */
  update() {
    if (window.savedChartXmlString) {
      document.getElementById('textarea').value = window.savedChartXmlString
      document.getElementById('clientload').click()
      return
    }

    this.setState({
      loading: true,
      loadedDatasetCount: 0,
      totalDatasetCount: this.props.datasets.length
    })

    //console.log("update: datasets", this.props.datasets.slice());

    let datasetData = []

    let promises = this.props.datasets.map((ds, index) =>
      !ds.structure || !ds.structure.fields
        ? new Promise((resolve, reject) => {
            /*
        getObject(getRequestFromPath(getFullUri(ds)).get(), 'dataset', ds.identity.name,  this.props.actions, getFullUri(ds)).then((ret)=>{
          this.incrementLoadedDatasetCount(this.updateDisplay(false));
          resolve(ret);
        });
        */

            let needRequest = true

            if (ds && ds.object) {
              let o = ds.object
              let defaultElement = (o.elements || []).reduce(
                (p, c) => (this.viewFromElement(c.identity.name) === this.props.selectedView ? c : p),
                null
              )
              if (defaultElement) {
                let x = defaultElement.frame.position.left
                if (x < 0) {
                  ds._path = getFullUri(ds)
                  ds.type = 'dataset'
                  datasetData[index] = ds
                  this.incrementLoadedDatasetCount()
                  resolve(ds)
                  needRequest = false
                }
              }
            }

            if (needRequest) {
              //getRequestFromPath(getFullUri(ds)).get().then((data)=>{
              getObjectNew(getRequestFromPath(getFullUri(ds)), 'dataset', null).then((data) => {
                data._path = getFullUri(ds)
                data.type = 'dataset'
                datasetData[index] = data
                this.incrementLoadedDatasetCount()
                resolve(ds)
              })
            }
          })
        : new Promise((resolve, reject) => {
            this.incrementLoadedDatasetCount()
            //console.log("getObjectByPath()", getFullUri(ds));
            datasetData[index] = ds // getObjectByPath(this.props.appState, 'dataset', getFullUri(ds));
            resolve(ds)
          })
    )

    datasetData = datasetData.filter((d) => d) // remove nulls

    //console.log("datasetData", datasetData);

    Promise.all(promises).then(() => {
      // one action is called for multiple datasets to reduce render count
      this.props.actions.receiveObjectList('dataset', datasetData)
      this.updateDisplay(datasetData)
    })
  }

  /**
   * context menu click handler
   * @param e     - event
   * @param data  - context menu item data
   */
  handleClick = (e, data) => {
    //console.log("handleClick()", e, data, data[0] == "c");
    let tt = window.SQL.lastSelectedTable
    //console.log("tt", tt);
    if (!tt) return

    // color change
    if (data.action === 'color') {
      document.getElementById('colorpicker').value = tt.borderColor
      document.getElementById('colorpicker').oninput = document.getElementById('colorpicker').onchange = () => {
        //console.log("colorpicker onchange", document.getElementById('colorpicker').value, tt);
        tt.borderColor = document.getElementById('colorpicker').value
        tt.redraw()
        if (typeof window.SQL.onTableMove === 'function') window.SQL.onTableMove(tt)
        document.getElementById('colorpicker').oninput = document.getElementById('colorpicker').onchange = null
      }
      document.getElementById('colorpicker').click()
    }

    // disable table
    if (data.action === 'disable') {
      if (tt.x > 0) tt.x = -tt.x
      else tt.x = -1
      window.SQL.onTableMove(tt)
      tt.redraw()
      document.getElementById('area').click()
    }

    // start link
    if (data.action === 'linkfrom') {
      window.SQL.tableFrom = tt
    }

    // add link to table
    if (data.action === 'linkto' && window.SQL.tableFrom.data.title !== tt.data.title) {
      let relation = window.sqldesigner.addRelation(window.SQL.tableFrom.rows[0], tt.rows[0])
      relation.type = 'link'
      relation.redraw()
      tt.links += ',' + window.SQL.tableFrom.data.title
      window.SQL.onTableMove(tt)
    }

    // add 1-n link to table
    if (data.action === 'linkto_onetomany' && window.SQL.tableFrom.data.title !== tt.data.title) {
      let relation = window.sqldesigner.addRelation(window.SQL.tableFrom.rows[0], tt.rows[0])
      relation.type = 'link_onetomany'
      relation.redraw()
      tt.links_onetomany += ',' + window.SQL.tableFrom.data.title
      window.SQL.onTableMove(tt)
    }

    // delete link
    if (data.action === 'linkdelete') {
      let tt2 = window.sqldesigner.tables.filter((table) => table.data.title === data.link)[0]
      //console.log("linkdelete tt2=",tt2);
      let r = window.sqldesigner.relations.filter(
        (rel) =>
          (rel.row1 === tt.rows[0] && rel.row2 === tt2.rows[0]) || (rel.row1 === tt2.rows[0] && rel.row2 === tt.rows[0])
      )
      //console.log("linkdelete r=",r);
      if (!r) return
      r = r[0]
      window.sqldesigner.removeRelation(r)
      tt.links = (',' + tt.links).replace(',' + data.link, '')
      tt.links_onetomany = (',' + tt.links_onetomany).replace(',' + data.link, '')
      window.SQL.onTableMove(tt)
      console.log('linkdelete done', tt.links, tt.links_onetomany, data.link)
    }
  }

  /**
   * right-click on non-disabled table shows context menu at correct position
   * @param e
   * @returns {boolean}
   */
  onTableRightClick = (e) => {
    let tt = window.SQL.lastSelectedTable
    if (!tt) {
      return
    }
    if (tt.x < 0) {
      return
    }

    const x = e.clientX || (e.touches && e.touches[0].pageX)
    const y = e.clientY || (e.touches && e.touches[0].pageY)
    this.menuRef.current.handleShow(
      Object.assign(
        {},
        {
          detail: {
            id: this.menuRef.current.props.id,
            position: { x: x, y: y }
          }
        }
      )
    )
    //console.log("event dispatched");
    e.preventDefault()
    e.stopPropagation()

    const datasetNames = this.state.datasets.map((ds) => ds.displayName)

    //console.log("after prevent default");
    this.setState({
      currentTableLinks: []
        .concat(
          tt.links
            ? tt.links
                .split(',')
                .filter((s) => s)
                .filter((s) => datasetNames.indexOf(s) !== -1)
            : []
        )
        .concat(
          tt.links_onetomany
            ? tt.links_onetomany
                .split(',')
                .filter((s) => s)
                .filter((s) => datasetNames.indexOf(s) !== -1)
            : []
        )
    })
    return false
  }

  /**
   * Toggle table enabled/disabled. Disabled tables have x<0.
   * @param table
   */
  onTableEnable = (table) => {
    //console.log("onTableEnable", table);

    if (table.x < 0) table.x = -table.x
    else table.x = 1
    table.redraw()
    this.props.onTableMove(
      table.data.title,
      table.x,
      table.y,
      table.borderColor,
      table.links,
      table.path,
      table.newWidth,
      table.newHeight,
      table.links_onetomany
    )
  }

  /**
   * initialization of SQLWebDesigner component and event handlers
   */
  componentDidMount() {
    window.sqldesigner = new window.SQL.DesignerConstructor()
    window.SQL.onTableMove = this.onTableMove.bind(this)
    window.SQL.onTableRightClick = this.onTableRightClick.bind(this)
    window.SQL.onTableEnable = this.onTableEnable.bind(this)
    window.SQL.chartModeDataFlow = false
    window.SQL.onChangeFilter = () => {
      // redraw all tables
      window.sqldesigner.tables.map((table) => table.redraw())
    }
    window.SQL.filterTable = (table) => {
      let name = table.data.title
      //let datasets = this.props.datasets.filter(ds => ds.identity.name == name);
      let datasets = this.props.datasets.filter((ds) => ds._path === table.path)
      if (datasets.length === 0) {
        //console.log("filterTable l=0",table,true);
        return true
      }
      let isVisible = true
      window.SQL.filters.forEach((filter) => {
        if (filter.name === 'usage') {
          if (filter.value.length > 0 && !filter.value.includes(datasets[0].object.usage)) isVisible = false
        }
        if (filter.value.length > 0 && filter.value[0].length > 0 && filter.name === 'search') {
          if (name.toLowerCase().indexOf(filter.value[0].toLowerCase()) !== 0) isVisible = false
        }
        if (filter.value.length > 0 && filter.name === 'tags') {
          let found = false
          datasets[0].object.tags.map((tag) => (filter.value.includes(tag) ? (found = true) : null))
          if (!found) isVisible = false
        }
      })
      //console.log("filterTable",table,isVisible);

      if (!isVisible && table.x > 0) {
        //this.onTableEnable(table);
        //console.log();
        table.x = -table.x
        this.props.onTableMove(
          table.data.title,
          table.x,
          table.y,
          table.borderColor,
          table.links,
          table.path,
          table.newWidth,
          table.newHeight,
          table.links_onetomany
        )
      }

      return isVisible
    }

    this.update()
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    /*
    if (this.state.loading)
      return;

    if (newProps.data.length != this.props.data.length) {
      document.getElementById('area').innerHTML = '';
      document.getElementById('bottom_area').innerHTML = '';
      this.update();
    }
    */
  }

  /**
   * Render HTML structure required by SQLWebDesigner library
   * @returns {XML}
   */
  renderArea() {
    return (
      <div className="SQLDesigner__root">
        {!this.props.isEditing && !this.props.isEditingViews ? <div id="SQLDesigner__areaBlocker"></div> : null}
        <div id="area"></div>
        <div id="controls">
          <div id="bar">
            <div id="toggle"></div>
            <input type="button" id="saveload" />

            <hr />

            <input type="button" id="addtable" />
            <input type="button" id="edittable" />
            <input type="button" id="tablekeys" />
            <input type="button" id="removetable" />
            <input type="button" id="aligntables" />
            <input type="button" id="cleartables" />

            <hr />

            <input type="button" id="addrow" />
            <input type="button" id="editrow" />
            <input type="button" id="uprow" className="small" />
            <input type="button" id="downrow" className="small" />
            <input type="button" id="foreigncreate" />
            <input type="button" id="foreignconnect" />
            <input type="button" id="foreigndisconnect" />
            <input type="button" id="removerow" />

            <hr />

            <input type="button" id="options" />
            <a href="https://github.com/ondras/wwwsqldesigner/wiki" target="_blank" rel="noreferrer">
              <input type="button" id="docs" value="" />
            </a>
          </div>

          <div id="rubberband"></div>

          <div id="minimap"></div>

          <div id="background"></div>

          <div id="window">
            <div id="windowtitle">
              <img id="throbber" src="images/throbber.gif" alt="" title="" />
            </div>
            <div id="windowcontent"></div>
            <input type="button" id="windowok" />
            <input type="button" id="windowcancel" />
          </div>
        </div>

        <div id="opts">
          <table>
            <tbody>
              <tr>
                <td>
                  * <label id="language" htmlFor="optionlocale"></label>
                </td>
                <td>
                  <select id="optionlocale">
                    <option></option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  * <label id="db" htmlFor="optiondb"></label>
                </td>
                <td>
                  <select id="optiondb">
                    <option></option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <label id="snap" htmlFor="optionsnap"></label>
                </td>
                <td>
                  <input type="text" size="4" id="optionsnap" />
                  <span className="small" id="optionsnapnotice"></span>
                </td>
              </tr>
              <tr>
                <td>
                  <label id="pattern" htmlFor="optionpattern"></label>
                </td>
                <td>
                  <input type="text" size="6" id="optionpattern" />
                  <span className="small" id="optionpatternnotice"></span>
                </td>
              </tr>
              <tr>
                <td>
                  <label id="hide" htmlFor="optionhide"></label>
                </td>
                <td>
                  <input type="checkbox" id="optionhide" />
                </td>
              </tr>
              <tr>
                <td>
                  * <label id="vector" htmlFor="optionvector"></label>
                </td>
                <td>
                  <input type="checkbox" id="optionvector" />
                </td>
              </tr>
              <tr>
                <td>
                  * <label id="showsize" htmlFor="optionshowsize"></label>
                </td>
                <td>
                  <input type="checkbox" id="optionshowsize" />
                </td>
              </tr>
              <tr>
                <td>
                  * <label id="showtype" htmlFor="optionshowtype"></label>
                </td>
                <td>
                  <input type="checkbox" id="optionshowtype" />
                </td>
              </tr>
            </tbody>
          </table>
          <hr />* <span className="small" id="optionsnotice"></span>
        </div>

        <textarea id="textarea" rows="1" cols="1"></textarea>
        <input type="button" id="clientload" />

        <div id="io">
          <table>
            <tbody>
              <tr>
                <td style={{ width: '60%' }}>
                  <fieldset>
                    <legend id="client"></legend>
                    <div id="singlerow">
                      <input type="button" id="clientsave" />
                      <input type="button" id="clientload" />
                    </div>
                    <div id="singlerow">
                      <input type="button" id="clientlocalsave" />
                      <input type="button" id="clientlocalload" />
                      <input type="button" id="clientlocallist" />
                    </div>
                    <div id="singlerow">
                      <input type="button" id="dropboxsave" />
                      <input type="button" id="dropboxload" />
                      <input type="button" id="dropboxlist" />
                    </div>
                    <hr />
                    <input type="button" id="clientsql" />
                  </fieldset>
                </td>
                <td style={{ width: '40%' }}>
                  <fieldset>
                    <legend id="server"></legend>
                    <label htmlFor="backend" id="backendlabel"></label>{' '}
                    <select id="backend">
                      <option></option>
                    </select>
                    <hr />
                    <input type="button" id="serversave" />
                    <input type="button" id="quicksave" />
                    <input type="button" id="serverload" />
                    <input type="button" id="serverlist" />
                    <input type="button" id="serverimport" />
                  </fieldset>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <fieldset>
                    <legend id="output"></legend>
                    <textarea id="textarea" rows="1" cols="1"></textarea>
                  </fieldset>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="keys">
          <fieldset>
            <legend id="keyslistlabel"></legend>
            <select id="keyslist">
              <option></option>
            </select>
            <input type="button" id="keyadd" />
            <input type="button" id="keyremove" />
          </fieldset>
          <fieldset>
            <legend id="keyedit"></legend>
            <table>
              <tbody>
                <tr>
                  <td>
                    <label htmlFor="keytype" id="keytypelabel"></label>
                    <select id="keytype">
                      <option></option>
                    </select>
                  </td>
                  <td></td>
                  <td>
                    <label htmlFor="keyname" id="keynamelabel"></label>
                    <input type="text" id="keyname" size="10" />
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <hr />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label htmlFor="keyfields" id="keyfieldslabel"></label>
                    <br />
                    <select id="keyfields" size="5" multiple="multiple">
                      <option></option>
                    </select>
                  </td>
                  <td>
                    <input type="button" id="keyleft" value="&lt;&lt;" />
                    <br />
                    <input type="button" id="keyright" value="&gt;&gt;" />
                    <br />
                  </td>
                  <td>
                    <label htmlFor="keyavail" id="keyavaillabel"></label>
                    <br />
                    <select id="keyavail" size="5" multiple="multiple">
                      <option></option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </div>

        <div id="table">
          <table>
            <tbody>
              <tr>
                <td>
                  <label id="tablenamelabel" htmlFor="tablename"></label>
                </td>
                <td>
                  <input id="tablename" type="text" />
                </td>
              </tr>
              <tr>
                <td>
                  <label id="tablecommentlabel" htmlFor="tablecomment"></label>
                </td>
                <td>
                  <textarea rows="5" cols="40" id="tablecomment"></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  renderAreaDebug() {
    return JSON.stringify(this.props.datasets.map((ds) => ds.identity.name))
  }

  render() {
    return (
      <div className="SQLDesigner__outerRoot">
        <div className="row SQLDesigner__topRow">
          <div className="col-xs-2">{this.props.filters['search']}</div>
          <div className="col-xs-3">{this.props.filters['tags']}</div>
          <div className="col-xs-3">{this.props.filters['usage']}</div>
          <div className="col-xs-4">
            <a className="SQLDesigner__link" onClick={this.props.onModeChange}>
              <span className={!this.state.fullScreen ? 'Button_FullScreen' : 'Button_Window'}></span>
              <span>
                &nbsp;
                {this.props.mode !== 'fullSize' ? 'Full screen' : 'Window'}
              </span>
            </a>

            <a className="SQLDesigner__link" onClick={this.props.onManageViews}>
              Manage&nbsp;views
            </a>

            <a
              className="SQLDesigner__pdf"
              onClick={() => {
                downloadSelectorAsPDF(
                  document.querySelector('#area'),
                  this.props.application.identity.name + ' Chart',
                  'div.table'
                )
              }}
            >
              <span>PDF</span>
            </a>

            {this.state.loading ? null : (
              <div className="SQLDesigner__hint ">
                <SimpleTooltip title="Hint: Drag datasets to move, right-click dataset title to open menu">
                  <img className="SQLDesigner__icon" src={iI} alt="icon sql" />
                </SimpleTooltip>
              </div>
            )}
          </div>
        </div>
        <input type="color" id="colorpicker" style={{ display: 'none' }} value="" />

        {this.state.loading ? (
          <div>
            <Loader />
            <br />
            {this.state.loadedDatasetCount <= this.state.totalDatasetCount ? (
              <span>
                Loaded {this.state.loadedDatasetCount} datasets of {this.state.totalDatasetCount}
              </span>
            ) : null}
          </div>
        ) : null}

        <ContextMenuTrigger id="some_unique_identifier">
          <div className="well"></div>
        </ContextMenuTrigger>

        <ContextMenu id="some_unique_identifier" ref={this.menuRef}>
          <MenuItem data={{ action: 'disable' }} onClick={this.handleClick}>
            Disable
          </MenuItem>
          <MenuItem data={{ action: 'color' }} onClick={this.handleClick}>
            Change color
          </MenuItem>
          <MenuItem data={{ action: 'linkto' }} onClick={this.handleClick}>
            Create one-to-one link to
          </MenuItem>
          <MenuItem data={{ action: 'linkto_onetomany' }} onClick={this.handleClick}>
            Create one-to-many link to
          </MenuItem>
          <MenuItem divider />
          {(this.state.currentTableLinks || []).map((link, idx) => (
            <MenuItem key={idx} data={{ action: 'linkdelete', link: link }} onClick={this.handleClick}>
              Delete link to {link}
            </MenuItem>
          ))}
        </ContextMenu>

        {this.renderArea()}
      </div>
    )
  }
}

// "data" property is not imported, because SQLDesigner has own filtering function
SQLDesigner.propTypes = {
  application: PropTypes.object,
  datasets: PropTypes.array,
  actions: PropTypes.object,
  onTableMove: PropTypes.function,
  onModeChange: PropTypes.function,
  isEditing: PropTypes.bool,
  selectedView: PropTypes.string,
  onManageViews: PropTypes.function,
  isEditingViews: PropTypes.bool
}
