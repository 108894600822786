/* eslint-disable array-callback-return */
/**
 * Created by kascode on 15.05.17.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { getObjectListNew, getObjectNew, getRequestFromPath } from '../../helpers/api'
import {
  columnsToType,
  createEmptyObject,
  datasetToEnumOptions,
  datasetToObjectArray,
  displayDatasetUri,
  displayFieldUri,
  getFullUri,
  hasInArrayItem,
  isDatasetField,
  nonCSCompare,
  objectJsons,
  pathByType,
  referenceSort,
  storageDatasetUri,
  storageSubscriptionUri
} from '../../helpers/index'
import logger from '../../helpers/logger'
import {
  castValueToType,
  getJsonSpecFieldOrdinal,
  getJsonSpecFields,
  getJsonSpecRecords,
  isUsageForType
} from '../../helpers/md'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { PropertyCard } from './PropertyCard'

const fieldProps = getJsonSpecFields('field').filter((f) => f.identity.name !== 'Identity')
const usages = getJsonSpecRecords('usage')
  .filter((record) => {
    return isUsageForType(record, 'field')
  })
  .filter((record) => record.values[0] !== 'Internal')
const types = getJsonSpecRecords('type')
const locales = getJsonSpecRecords('locale')
const functions = getJsonSpecRecords('function')
const permissions = getJsonSpecRecords('permission')
const privacies = getJsonSpecRecords('privacy')

const propGroups = [
  ['usage'],
  ['order'],
  ['count'],
  ['type', 'size', 'precision', 'scale', 'locale', 'reference', 'keys'],
  ['optional'],
  ['value', 'format'],
  ['aggregate'],
  ['access', 'privacy']
]

function iFieldToIfieldUI(field) {
  let f = Object.assign({}, field)
  let type = {
    name: 'string'
  }

  if (!f.type) {
    type = {
      name: 'string'
    }
  } else if (nonCSCompare(f.identity.name, 'access')) {
    type = {
      name: 'enum', //'bitmask',
      options: !permissions
        ? null
        : permissions.map((element, index) => {
            return {
              id: index,
              label: element.values[0],
              value: element.values[0]
            }
          })
    }
  } else if (nonCSCompare(f.type, 'enum')) {
    let optionSource = []
    switch (f.identity.name ? f.identity.name.toLowerCase() : '') {
      case 'usage':
        optionSource = usages
        break
      case 'type':
        optionSource = types
        break
      case 'locale':
        optionSource = locales
        break
      case 'aggregate':
        optionSource = functions
        break
      case 'access':
        optionSource = permissions
        break
      case 'privacy':
        optionSource = privacies
        break
      default:
        break
    }

    // console.log("FieldDialog:propTypes:ENUM", f.identity.name, optionSource);

    // map to format described in KascodeSelect propTypes
    type = {
      name: 'enum',
      options: !optionSource
        ? undefined
        : optionSource.map((element, index) => {
            return {
              id: index,
              label: element.values[0],
              value: element.values[0]
            }
          })
    }
  } else {
    type = {
      name: f.type && typeof f.type === 'string' ? f.type.toLowerCase() : 'string'
    }
  }

  return {
    ...f,
    type: type
  }
}

/**
 * List of properties in field Returns object with props from list and default values
 * @return Array of properties
 */
const properties = fieldProps.map(iFieldToIfieldUI)

export class FieldEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inEditMode: props.inEditMode,
      editingBlock: -1,
      referenceLoading: false,
      enumOptions: {}
    }
  }

  componentDidMount() {
    //console.log("FieldEditor:componentDidMount");
    if (this.props.field.reference) this.loadEnumOptions(this.props.field.reference)
  }

  /**
   * Get initial value of field
   * @param {object} [props] - if called from constructor
   * @returns {object}
   */
  getInitialField = (props) => {
    props = props || this.props
    return props.field ? props.field : createEmptyObject('field')
  }

  /**
   * Returns value for access base on selected access text
   * @param access text representation
   * @return access value
   */
  getAccessValue = (access) => {
    let val = 0
    let text = access.toLowerCase()
    permissions.map((element, index) => {
      if (text.indexOf(element.values[0].toLowerCase()) !== -1) {
        val |= parseInt(element.values[2])
      }
    })
    return val
  }

  /**
   * Returns text for access base on selected access value
   * @param value access value
   * @return access text representation
   */
  getAccessText = (value) => {
    let text = ''
    permissions.map((element, index) => {
      if ((value & parseInt(element.values[2])) !== 0) {
        text += text.length > 0 ? ', ' + element.values[0] : element.values[0]
      }
    })
    return text
  }

  getAccessSelectedIds = (value) => {
    let ids = []
    permissions.map((element, index) => {
      if ((value & parseInt(element.values[2])) !== 0) {
        ids.push(index)
      }
    })
    return ids
  }

  /**
   * Returns list of properties for given usage and type
   * @return Object
   */
  getPropsToShow = () => {
    let field = Object.assign({}, this.state ? this.props.field : {})

    // internal usage contains list of properties every field have.
    let props = []

    // index for type and usage in data
    const typeIndex = getJsonSpecFieldOrdinal('type', 'Attributes')
    const usageIndex = getJsonSpecFieldOrdinal('usage', 'Settings')

    // console.log("FieldEditor:getPropsToShow:Index", typeIndex, usageIndex, fieldProperties);

    // Based on selected usage we add properties required for this usage.
    if (field.usage) {
      const selectedUsage = usages.find((u) => {
        return nonCSCompare(u.values[0], field.usage)
      })

      if (!selectedUsage) throw new Error('Invalid usage')

      let selectedProps = selectedUsage.values[usageIndex].split(', ')
      let changed = 0

      // Update properties if we have it changed based on usage.
      selectedProps.map((t) => {
        let val = t.split('=')

        // We have property with fixed value and have to set it
        if (val && val.length === 2) {
          let type = val[0].toLowerCase()
          // console.log("FieldEditor:getPropsToShow:VALUE", type, fieldProperties[type], val[1] );

          if (type === 'optional' || type === 'keys') {
            if (val[1] === 'false' && field[type] !== false) {
              field[type] = false
              changed++
            } else if (val[1] === 'true' && field[type] !== true) {
              field[type] = true
              changed++
            }
          } else if (type === 'reference') {
            let objectUri = getFullUri(this.props.majorObject)
            const pathSplit = objectUri.substring(1).split('/')

            // if property has default reference it is relative to org
            const path = pathByType(pathSplit, 'organizations') + '/datasets' + val[1]

            // if current property value is different set it to calculated
            if (field[type] !== path) {
              field[type] = path
              changed++
            }
          } else if (field[type] !== val[1]) {
            //console.log("FieldEditor:forcedProp", type, val);
            field[type] = val[1]
            changed++
          }

          // add property to props to show array
          props = props.concat(t)
        } else if (hasInArrayItem(t, props) === false) {
          // we have property without value
          props = props.concat(t) // add it to array of props to show
        }
      })

      // if field was changed set it to new value
      if (changed > 0) {
        // this.setState(state, () => {
        this.props.onChange(field)
        // });
      }
    }

    // Add internal properties if not here yet
    const internalUsageRecord = getJsonSpecRecords('usage').find((r) => {
      // find internal usage
      return nonCSCompare(r.values[0], 'internal')
    })

    if (internalUsageRecord) {
      internalUsageRecord.values[usageIndex]
        .split(', ')
        .filter((prop) => {
          // filter out rules and properties
          return !(nonCSCompare(prop, 'rules') || nonCSCompare(prop, 'properties'))
        })
        .map((t) => {
          // if property not in list add it
          if (hasInArrayItem(t, props) === false) {
            props = props.concat(t)
          }
        })
    }

    // We have type requirement for usage
    const showType = !!hasInArrayItem('type', props)

    // We add all properties required for the type
    if (showType && field.type) {
      let selectedType = types.filter((t) => {
        return nonCSCompare(t.values[0], field.type)
      })
      if (selectedType.length) {
        selectedType[0].values[typeIndex].split(', ').map((p) => {
          if (hasInArrayItem(p, props) === false) {
            props = props.concat(p)
          }
        })
      }
    }

    if (
      this.props.inEditMode &&
      hasInArrayItem('reference', props) !== false &&
      (nonCSCompare(field.type, 'enum') ||
        nonCSCompare(field.type, 'structure') ||
        nonCSCompare(field.type, 'reference'))
    ) {
      // Load list of objects for this usage
      this.loadDatasets(field.type)
    }

    return props
  }

  /**
   * Load dataset of specific usage for selection of references. Work asynchronously
   * @param type - usage for dataset selection
   * @return asynchronously add datasets to state
   */
  loadDatasets(type) {
    // Already loaded or we loading it
    if (this.state[type === 'Enum' ? 'enums' : 'structures'] || this.state.referenceLoading === true) return

    // Mark it as loading begin
    this.setState({
      referenceLoading: true
    })

    // Load if we in dataset
    if (this.props.majorObject.type === 'dataset' || this.props.majorObject.type === 'interface') {
      const obj = this.props.majorObject

      const pathSplit = getFullUri(obj).substring(1).split('/')
      const appPath = pathByType(pathSplit, 'applications')
      const orgPath = pathByType(pathSplit, 'organizations')
      const appRequest = appPath ? getRequestFromPath(appPath) : null
      const orgRequest = getRequestFromPath(orgPath)
      const appQueryParams = { subscriptions: true }
      const orgQueryParams = { subscriptions: false }

      if (type !== 'Structure' && type !== 'Reference') {
        appQueryParams.usage = type
        orgQueryParams.usage = type
      }

      logger.info('FieldEditor:loadDatasets', type, appPath, orgPath)

      Promise.all([
        appPath
          ? getObjectListNew(appRequest.datasets, 'dataset', null, false, appQueryParams)
          : new Promise((resolve, reject) => {
              resolve([])
            }),
        getObjectListNew(orgRequest.datasets, 'dataset', null, false)
      ]).then((results) => {
        // Merge datasets from organization and application
        const res = results[0].concat(results[1]).map((el) => {
          el.type = 'dataset'
          return el
        })

        // Mark as loaded successfully.
        if (type === 'Enum') {
          this.setState({
            enums: res,
            referenceLoading: false
          })
        } else if (type === 'Structure' || type === 'Reference') {
          this.setState({
            structures: res,
            referenceLoading: false
          })
        }

        logger.info('FieldEditor:loadDatasets:RESULT', res)
      })
    } else {
      console.error('Cannot load enums for ' + this.props.majorObject.type) // eslint-disable-line no-console
    }
  }

  loadEnumOptions = (reference) => {
    getObjectNew(getRequestFromPath(reference), 'dataset').then((dataset) => {
      const options = datasetToEnumOptions(dataset)
      //console.log("LOaded options for enum", field.reference, options);
      this.setState({
        enumOptions: Object.assign({}, this.state.enumOptions, {
          [reference]: options
        })
      })
    })
  }

  onNameChange = (e) => {
    const field = Object.assign({}, this.props.field)
    field.identity.name = e.target.value

    this.props.onChange(field, this.cancelEditingField)
  }

  onDescriptionChange = (e) => {
    const field = Object.assign({}, this.props.field)
    field.identity.description = e.target.value

    this.props.onChange(field, this.cancelEditingField)
  }

  onPropertyChange = (propertyName) => {
    return (event) => {
      let field = Object.assign({}, this.props.field)
      let val = ''

      // Need low case for property names in the field.
      propertyName = propertyName.toLowerCase()

      logger.info(
        'FieldEditor:onPropertiesChange',
        propertyName,
        event.target.value,
        columnsToType.getType(propertyName).name,
        this.props
      )

      if (columnsToType.getType(propertyName).name === 'boolean') {
        val = event.target.value
      } else if (propertyName === 'reference') {
        field.reference = storageDatasetUri(event.target.value, this.props.majorObject)
        field.subscription = storageSubscriptionUri(event.target.value, this.props.majorObject)
        if (nonCSCompare(field.type, 'enum')) {
          this.loadEnumOptions(field.reference)
        }
        this.props.onChange(field)
        return
      } else if (propertyName === 'access' && Array.isArray(event.target.value)) {
        let text = event.target.value.reduce((prev, cur) => {
          return prev === '' ? prev + cur.label : prev + ', ' + cur.label
        }, '')
        val = this.getAccessValue(text)
      } else {
        val = event.target.value
      }

      field[propertyName] = val

      // logger.info(
      //   'FieldEditor:onPropertiesChange:FIELD',
      //   propertyName,
      //   val,
      //   field
      // )

      this.props.onChange(field)
    }
  }

  /**
   * Set focus on property card with index
   * @param {number} index
   */
  onPropertyCardSelect = (index) => {
    this.setState({
      editingBlock: index
    })
  }

  /**
   * Remove focus from property card
   */
  onPropertyCardBlur = () => {
    this.setState({
      editingBlock: -1
    })
  }

  /**
   * Set block with index as activly editing
   * @param {number} index
   */
  startEditingBlock = (index) => {
    this.setState({
      editingBlock: index
    })
  }

  stopEditingBlock = () => {
    this.setState({
      editingBlock: -1
    })
  }

  /**
   * Cancel editing of field data
   * @param {number} field
   */
  cancelEditingField = (field) => {
    // TODO: check if this is really a number
    // logger.log("FieldEditor:cancelEditingField", this.state, field);

    this.setState({
      field: field
    })
  }

  renderObjectSettings = () => {
    let passedField = castValueToType(iFieldToIfieldUI(this.props.field))
    const suppressEdit = !this.props.inEditMode
    const propsToShow = this.getPropsToShow()

    // this line is needed because for Boolean fields, value was lost in castValueToType(iFieldToIfieldUI())
    passedField.value = this.props.field.value

    // Render all blocks into the "blocks" array
    //console.log("FieldEditor:renderObjectSettings", {propsToShow, propTypes, thisProperties});

    let fieldType = passedField['type'] || 'string'
    //console.log("renderObjectSettings, fieldType", fieldType, passedField, this.props.field);

    let blocks = properties
      // Filter only props that should be shown
      .filter((property) => hasInArrayItem(property.identity.name, propsToShow) !== false)
      .map((property, index) => {
        property = castValueToType(property)
        const passedFieldName = property.identity.name ? property.identity.name.toLowerCase() : ''
        // $FlowFixMe
        let rawVal

        if (passedFieldName === 'type')
          // $FlowFixMe
          rawVal =
            passedField[passedFieldName] !== undefined
              ? passedField[passedFieldName].name
              : property.value !== ''
              ? property.value
              : ''
        // $FlowFixMe
        else
          rawVal =
            passedField[passedFieldName] !== undefined
              ? passedField[passedFieldName]
              : property.value !== ''
              ? property.value
              : ''
        //logger.info("FieldEditor:passedField", property, passedField, passedFieldName, rawVal);
        let val = rawVal !== null && rawVal !== undefined ? rawVal : ''
        let editable = true // 'size' block is grayed for some types
        let loading = false

        //logger.info("FieldEditor:PROP", property.identity.name, rawVal, val);

        if (suppressEdit) {
          editable = false
        } else {
          // We look for value in property. If we have it - we have uneditable value.
          let iprop = hasInArrayItem((property.identity.name ? property.identity.name : '') + '=', propsToShow)
          let prop = []
          if (iprop !== false) {
            prop = propsToShow[iprop - 1].split('=')
            if (prop.length === 2) {
              editable = false
            }
          }
          //logger.info("FieldEditor:renderObjectSettings:PROPERTY", prop, editable, val);
        }

        // Drops for structures and enums
        let dprops = {}

        //logger.info("FieldEditor:VALUE", property.identity.name, rawVal, val);

        if (nonCSCompare(property.identity.name, 'type')) {
          // Handle type property of the field
          if (nonCSCompare(val, 'enum')) {
            dprops['structName'] = this.state.enumName
          } else if (nonCSCompare(val, 'structure')) {
            dprops['structName'] = this.state.structureName
          } else if (nonCSCompare(val, 'reference')) {
            dprops['structName'] = this.state.structureName
          }
        } else if (nonCSCompare(property.identity.name, 'access')) {
          // Handle access property of the field
          val = parseInt(val)
          const selectedAccessIds = this.getAccessSelectedIds(val)
          val = this.getAccessText(val)

          let text = val.toLowerCase()

          property.type.name = 'enum'
          property.type.options = permissions.map((el, i) => {
            // console.log("fill options", text + " - " + el[0], text.indexOf(el[0].toLowerCase()), text.indexOf(el[0].toLowerCase()) !== -1);
            return {
              id: i,
              label: el.values[0],
              selected: text.indexOf(el.values[0].toLowerCase()) !== -1,
              value: el.values[0]
            }
          })
          dprops.activeOptions = selectedAccessIds
        } else if (nonCSCompare(property.identity.name, 'reference')) {
          // Handle reference property of the field
          let childObjectType = passedField.type ? passedField.type.name.toLowerCase() : ''
          const refSource = this.state[childObjectType === 'enum' ? 'enums' : 'structures']
            ? this.state[childObjectType === 'enum' ? 'enums' : 'structures']
            : []

          //logger.info("FieldEditor:renderObjectSettings:TYPE", childObjectType, refSource, passedField, val, this.state);
          // For reference we can allow self reference.
          property.type.name = 'enum'
          property.type.options = refSource
            .filter(
              (el) =>
                el.identity.name &&
                (childObjectType === 'reference' || el.identity.id !== this.props.majorObject.identity.id)
            )
            .map((el, i) => {
              return {
                id: i,
                value: el,
                label: displayDatasetUri(el)
              }
            })
            .sort(referenceSort)

          if (childObjectType !== 'enum') {
            const usageOptions = datasetToObjectArray(objectJsons.usage)
              .filter((u) => u['Object'] === 'Dataset')
              .map((usage, usageIndex) => {
                return {
                  id: property.type.options.length + usageIndex,
                  value: {
                    identity: {
                      name: '?Usage=' + usage.Usage
                    }
                  },
                  label: '?Usage=' + usage.Usage
                }
              })
            property.type.options = property.type.options.concat(usageOptions)
            //console.log("FieldEditor::renderObjectSettings", usageOptions, property.type.options, objectJsons.usage, datasetToObjectArray(objectJsons.usage));
          }

          if (typeof val !== 'number') {
            //logger.info("FieldDialog:renderObjectSettings:REFERENCE", val, property.type.options);

            if (this.props.inEditMode > 0 && property.type.options.length > 0) {
              // Check if we have valid reference.
              let selected = property.type.options.find((item) => {
                //console.log("FieldEditor:renderObjectSettings:SELECT", storageDatasetUri(item.value, this.props.majorObject), val);
                return isDatasetField(item.value, this.props.majorObject, passedField)
              })
              if (selected) {
                val = selected.label
              } else {
                val = '?? -> ' + displayFieldUri(passedField, this.props.majorObject)
              }
            } else {
              val = displayFieldUri(passedField, this.props.majorObject)
            }
            //logger.info("FieldEditor:renderObjectSettings:SELECTED", val, ptype.type.options);
          }

          loading = this.state.referenceLoading
        } else if (nonCSCompare(property.identity.name, 'value')) {
          property.type = fieldType
          //console.log("Value, type", property.type);
          if (nonCSCompare(property.type.name, 'enum')) {
            property.type.options = this.state.enumOptions[passedField.reference] || []
          }
          if (!property?.type?.options) property.type.options = []
        }

        if (val.name) console.warn('VAL', val, property, passedField)

        //logger.info("FieldEditor:PropertyCard", property.identity.name, rawVal, val, property.type);

        return {
          ptype: property,
          html: (
            <PropertyCard
              editable={editable}
              canEdit={this.props.inEditMode}
              inEditMode={this.props.inEditMode > 0 && !loading}
              description={property.identity.description}
              name={property.identity.name}
              value={val}
              type={property.type}
              options={dprops}
              onSelect={this.onPropertyCardSelect}
              onBlur={this.onPropertyCardBlur}
              onChange={this.onPropertyChange}
              index={index}
              enumName={this.state.enumName}
              structureName={this.state.structureName}
              loading={loading}
            />
          )
        }
      })

    //logger.info("FieldDialog:renderObjectSettings:BLOCKS", blocks);

    // Let's pick blocks in right order
    let html = []
    let cols = []

    // Go across list of groups and all properties in group
    for (let i = 0; i < propGroups.length; i++) {
      for (let j = 0; j < propGroups[i].length; j++) {
        // Push real block if it presented
        let property = propGroups[i][j]
        let block = blocks.filter((item) => {
          return nonCSCompare(item.ptype.identity.name, property)
        })

        if (block.length > 0) {
          cols.push(block[0].html)
        }
      }

      if (cols.length > 0) {
        // Push columns to render, if we pick any columns from list of blocks
        html.push(<tr className="row">{cols}</tr>)
        cols = []
      }
    }

    return html
  }

  render() {
    return (
      <div data-iscontainer>
        <div className="FieldCreator__identity">
          <div className="FieldCreator__inputBlock clearfix">
            <label className="FieldCreator__label FieldCreator__label_left label_left">{'Name:'}</label>
            <div className="value_right">
              <EditableEntity
                data={this.props.field.identity.name}
                dataType={{ name: 'string' }}
                dataProps={{
                  placeholder: 'Enter name',
                  onChange: this.onNameChange
                }}
                isEditable
                inEditMode={this.props.inEditMode > 0}
              />
            </div>
          </div>
          <div className="FieldCreator__inputBlock__multiLine clearfix">
            <label className="FieldCreator__label FieldCreator__label_left label_left">{'Description:'}</label>
            <div className="value_right">
              <EditableEntity
                data={this.props.field.identity.description}
                dataType={{ name: 'text_updatable' }}
                dataProps={{
                  placeholder: 'Enter description',
                  onChange: this.onDescriptionChange
                }}
                isEditable
                inEditMode={this.props.inEditMode > 0}
              />
            </div>
          </div>
        </div>
        <div className="FieldCreator__inputBlock">
          <label className="FieldCreator__title FieldCreator__label">{'Properties:'}</label>
          <div className="FieldView" id="FieldView">
            {this.renderObjectSettings()}
          </div>
        </div>
      </div>
    )
  }
}

FieldEditor.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  inEditMode: PropTypes.bool,
  field: PropTypes.object,
  onChange: PropTypes.func,
  majorObject: PropTypes.object
}
