import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class EnvironmentLinesDrawer extends Component {
  /**
   * Renders SVG line between two points
   * @param p1
   * @param p2
   * @returns {string}
   */
  renderLineCoords = (p1, p2) => {
    const half = Math.floor((p2[0] - p1[0]) / 2)
    return (
      'M ' +
      p1[0] +
      ' ' +
      (p1[1] - 0) +
      ' C ' +
      (p1[0] + half) +
      ' ' +
      (p1[1] - 0) +
      ' ' +
      (p2[0] - half) +
      ' ' +
      (p2[1] - 0) +
      ' ' +
      p2[0] +
      ' ' +
      (p2[1] - 0)
    )
  }

  onArrowClick = (editState) => {
    if (this.props.onArrowClick) {
      this.props.onArrowClick(editState)
    }
  }

  render() {
    const { lines, editState } = this.props

    const leftShiftRight = -30
    const titleHeight = 0
    const rowHeight = 0

    const svg = document.querySelector('svg')
    if (!svg) {
      return null
    }
    const svgRect = svg.getBoundingClientRect()

    // Iterate over all lines
    return lines.map((line) => {
      const { topology, deployment, instance, packageData, unit, appRef, type, childRef } = line

      const color = '#ff0000'

      let inputId =
        'deployment_' +
        deployment.identity.name +
        '_package_' +
        packageData.identity.name +
        '_' +
        appRef.identity.name +
        '_' +
        type +
        '_' +
        childRef.identity.name

      let inputElement = document.getElementById(inputId)

      if (!inputElement) {
        inputId =
          'deployment_' +
          deployment.identity.name +
          '_package_' +
          packageData.identity.name +
          '_' +
          appRef.identity.name
        inputElement = document.getElementById(inputId)
      }

      const outputId = 'topology_' + topology.identity.name + '_instance_' + instance.identity.name + '_unit_' + unit
      const outputElement = document.getElementById(outputId)

      if (!outputElement || !inputElement) {
        return null
      }

      let baseElement = document.getElementById('EnvironmentChart_row')
      let base = baseElement.getBoundingClientRect()

      let inputTable = inputElement
      while (
        inputTable !== null &&
        !(inputTable.tagName.toLowerCase() === 'div' && inputTable.classList.contains('table'))
      ) {
        inputTable = inputTable.parentNode
      }

      let outputTable = outputElement
      while (
        outputTable !== null &&
        !(outputTable.tagName.toLowerCase() === 'div' && outputTable.classList.contains('table'))
      ) {
        outputTable = outputTable.parentNode
      }

      if (!inputTable || !outputTable) {
        return null
      }

      const inputRect = inputTable.getBoundingClientRect()
      const outputRect = outputTable.getBoundingClientRect()
      let tableWidth = inputTable.parentNode.clientWidth

      let x0
      let y0
      if (inputElement) {
        // if element is visible, arrow coordinates are based on its coordinates
        const r0 = inputElement.getBoundingClientRect()
        y0 = r0.top - base.top + titleHeight + rowHeight / 2
      } else {
        // if element is not visible, arrow coordinates are based on its parent table
        y0 = inputRect.top - base.top + inputRect.height + titleHeight - 5
      }

      x0 = inputTable.getBoundingClientRect().left + tableWidth - base.left - 15
      // y is limited by table's top and top+height
      y0 = Math.min(inputRect.top + inputRect.height - base.top + titleHeight, y0)
      y0 = Math.max(y0, inputRect.top - base.top + titleHeight)

      let x1
      let y1
      if (outputElement) {
        const r1 = outputElement.getBoundingClientRect()
        x1 = r1.left - base.left + leftShiftRight
        y1 = r1.top - base.top + titleHeight + rowHeight / 2
      } else {
        x1 = outputRect.left - base.left + leftShiftRight + 35
        y1 = outputRect.top - base.top + outputRect.height + titleHeight - 5
      }

      y1 = Math.min(outputRect.top + outputRect.height - base.top + titleHeight, y1)
      y1 = Math.max(y1, outputRect.top - base.top + titleHeight)

      // svgRect is placed in center of the window, so we need to correct arrow coordinates relative to base
      x0 -= svgRect.left - base.left
      x1 -= svgRect.left - base.left - 25

      // circles are placed at the fields, first path is visible mapping, second transparent path is used to intercept clicks (visible path is too small to click)
      return [
        <React.Fragment key={line}>
          <circle cx={x0} cy={y0} fill={color} r={2} />
          <path stroke={color} fill="none" d={this.renderLineCoords([x0, y0], [x1, y1])} style={{ display: 'block' }} />
          <path
            stroke="transparent"
            strokeWidth="10"
            fill="none"
            d={this.renderLineCoords([x0, y0], [x1, y1])}
            style={{ display: 'block' }}
            onClick={() => this.onArrowClick(editState)}
          />
          <circle cx={x1} cy={y1} fill={color} r={2} />
        </React.Fragment>
      ]
    })
  }
}

EnvironmentLinesDrawer.propTypes = {
  lines: PropTypes.array,
  editState: PropTypes.number,
  onArrowClick: PropTypes.func
}
