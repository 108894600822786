/**
 * Created by sashaberger on 06.18.2017.
 *
 * Dialog to add user to team.
 *
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import UserSearch from '../UserSearch/UserSearch'

import './UserDialog.scss'

export class UserDialog extends Component {
  constructor(props) {
    super(props)

    //console.log("UserDialog:constructor", props);

    this.state = {
      change: null, // Method to report or cancel modification.

      user: null,
      role: 'Analyst'
    }

    this.editordialogRef = React.createRef()
  }

  /**
   * Execute activity when dialog closing
   * @return
   */
  onClose = () => {
    if (this.props.onClose) this.props.onClose()
  }

  /**
   * Execute edit in the dialog
   * @param
   * @return
   */
  onEdit = (change) => {
    //console.log("UserDialog:onEdit", this.state.isEditable);

    this.setState({
      change: change
    })
  }

  /**
   * Execute activity when message send to service
   * @param error return from service if any.
   * @return
   */
  onSent = (close, error) => {
    //console.log("UserDialog:onSent", close, error);

    // Report complete of save with error or not
    if (this.state.change) {
      this.state.change(error)
    }

    if (close && !error) {
      setTimeout(() => {
        this.onClose()
      }, 1000)
    }
  }

  /**
   * Execute activity when save button pushed in dialog
   * @param error return from service if any.
   * @return
   */
  onSave = (close) => {
    //console.log("UserDialog:onSave", close);

    if (this.state.change) {
      this.state.change(true, 'Data send to the service.', '', '')
    }

    if (this.props.onSave) {
      this.props.onSave(this.state.user, this.state.role, close, this.onSent)
      return true
    }

    // Delay to close on save.
    return true
  }

  /**
   * Cancel editing in the dialog
   * @param close - cancel execution and close dialog.
   * @return
   */
  onCancel = (close) => {
    //console.log("UserDialog:onCancel", close);

    // Cancel save mode
    if (this.state.change) this.state.change(false)

    this.setState({
      change: null,
      user: null,
      role: 'Analyst'
    })
  }

  /**
   * Change of role by selected check box
   * @param role - updated role
   */
  onRoleChange = (role) => {
    //console.log("UserDialog:onRoleChange", this.state.role, role);

    // Indicate save mode
    if (this.state.user && this.state.change) this.state.change(true, '', '', '')

    this.setState({
      role: role
    })
  }

  /**
   * Change of selected user
   * @param user - updated user
   */
  onUserChange = (user) => {
    //console.log("UserDialog:onUserChange", this.state.user, user);

    // Indicate save mode
    if (this.state.change) this.state.change(user !== null, '', '', '')

    this.setState({
      user: user
    })

    this.editordialogRef.current.refs.modal.refs.scroll.setState({
      topPosition: 0
    })
  }

  /**
   * Render actual body of dialog
   * @return
   */
  renderData = (editState) => {
    //console.log("UserDialog:renderData", this.state, editState);
    return (
      <div className="UserDialog">
        <div className="UserDialog__label">Select role:</div>
        <div className="row ">
          {this.props.roles.map((role, i) => (
            <div className="col-xs-4 RoleDialog__role" key={i}>
              <input
                type="radio"
                className="Input_radio"
                id={'role_' + role.Role}
                checked={this.state.role === role.Role}
                onClick={() => this.onRoleChange(role.Role)}
              />
              <label htmlFor={'role_' + role.Role}>
                <EditableEntity
                  dataType={{ name: 'propertyName' }}
                  data={{ name: role.Role, description: role.Description }}
                  dataProps={{}}
                />
              </label>
            </div>
          ))}
        </div>
        <div className="UserDialog__userSearch">
          <div className="UserDialog__label">Select user:</div>
          <UserSearch
            editMode
            placeholder="User name"
            objectPath={this.props.object}
            department={this.props.department}
            onSelect={(user) => this.onUserChange(user)}
            selectedUser={this.state.user ? this.state.user.user.id : ''}
          />
        </div>
      </div>
    )
  }

  /**
   *
   * Render function of dialog
   * @return
   */
  render() {
    //console.log("UserDialog:render", this.props, this.state);

    return (
      <div className="UserDialog">
        <EditorDialog
          ref={this.editordialogRef}
          objectType="data"
          modalTitle={this.props.modalTitle}
          editContent={this.renderData.bind(this)}
          isVisible={this.props.isVisible}
          isEditable={this.props.isEditable}
          onClose={this.onClose.bind(this)}
          onSave={this.onSave.bind(this)}
          onEdit={this.onEdit.bind(this)}
          onCancel={this.onCancel.bind(this)}
          hideFullscreenButton
        />
      </div>
    )
  }
}

UserDialog.propTypes = {
  appState: PropTypes.object,
  modalTitle: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  actions: PropTypes.object.isRequired,
  object: PropTypes.string, // object of the team we add user to.
  roles: PropTypes.array,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  department: PropTypes.string // for department we need to filter users that are already in department;
  // in object we pass parent org's path
}

UserDialog.defaultProps = {}
