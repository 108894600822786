/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * Created by kascode on 03.05.17.
 */
import PropTypes from 'prop-types'
import React from 'react'
import toJsonSchema from 'to-json-schema'
import { deepCopy, nonCSCompare, waitFor } from '../../helpers/index'
import logger from '../../helpers/logger'
import { deepSet } from '../../resources/lib/metadata'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './ImportSchemaDialog.scss'

export class ImportSchemaDialog extends EditorDialogBase {
  getObjectType() {
    return 'importParams'
  }

  /**
   * Returns minimum height for dialog
   */
  getMinHeight() {
    return 400
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load platforms list from organization we are in.
    this.loadPlatform()

    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/Difhub/applications/Schema/datasets/importConfiguration',
      '/organizations/Apdax/systems/Difhub/applications/Schema/views/ImportSchemaDialog',
      '/organizations/Apdax/systems/Difhub/applications/Schema/datasets/schemaSource'
    )

    logger.info('ImportSchemaDialog:open', this.state, this.props)
  }

  importDataset = (params, closeDialog, onSent) => {
    // Generate dataset feom import properties
    let dataset = {
      identity: {
        name: params.datasetName,
        description: params.datasetDescription
      },
      object: { type: 'Dataset' }
    }
    logger.info('ImportSchemaDialog:importDataset', params, dataset, this.state, this.props)

    let fr = new FileReader()
    fr.onload = (str) => {
      try {
        //console.log("MajorObject::uploadChildObject", fr.result);
        let parsed = ''
        if (params.schemaType === 'Object') {
          // We convert input object to schema
          parsed = JSON.parse(fr.result)
          if (parsed) {
            parsed = toJsonSchema(parsed)
            console.log('ImportSchemaDialog:importDataset:OBJECT', parsed)

            dataset.object.usage = 'Resource'
            this.props.onSave(dataset, parsed, null, closeDialog, onSent)
          }
        } else if (params.schemaType === 'Schema') {
          // We have schema as input, do nothing
          parsed = JSON.parse(fr.result)
          if (parsed) {
            console.log('ImportSchemaDialog:importDataset:SCHEMA', parsed)

            dataset.object.usage = 'Resource'
            this.props.onSave(dataset, parsed, null, closeDialog, onSent)
          }
        } else if (params.schemaType === 'Avro') {
          // We have schema as input, do nothing
          parsed = JSON.parse(fr.result)
          if (parsed) {
            parsed = this.toJSONSchemaFromAvroSchema(parsed)
            console.log('ImportSchemaDialog:importDataset:SCHEMA', parsed)

            dataset.object.usage = 'Structure'
            this.props.onSave(dataset, parsed, null, closeDialog, onSent)
          }
        } else {
          // We get DDL and need convert it to JSON
          parsed = fr.result
          if (parsed) {
            parsed = this.toStructureFromDDL(parsed)
            console.log('ImportSchemaDialog:importDataset:DDL', parsed)

            dataset.object.usage = 'Structure'
            dataset.structure = parsed

            this.props.onSave(dataset, null, null, closeDialog, onSent)
          }
        }

        // after processing, close dialog:
        this.setState({ importingDataset: false })
      } catch (e) {
        onSent(false, e)
      }
    }
    fr.onerror = (event) => {
      logger.error(
        'ImportSchemaDialog:importDataset  File could not be read! Code ' + event.target.error.code,
        event.target.error
      )
    }

    fr.readAsText(this.state['schemaFileInfo'])
  }

  /**
   * Convert Table DDL into dataset structure
   * @param (String) text - text with DDL definision of the table
   * @return (Object) structure of dataset build from DDL
   */
  toStructureFromDDL = (text) => {
    // Conversion for type
    const platformName = this.state.importParams.schemaPlatform
    const platformMap = this.state[platformName + 'From']
    console.log('ImportSchemaDialog:toStructureFromDDL', platformName, platformMap, this.state)

    // Get list of fields
    let list = this.parseTable(text)

    let fields = []
    let keys = []
    let order = 0

    for (let element in list) {
      let field = { identity: {} }
      let element_text = list[element].replace(/\s+/g, ' ').trim()
      let element_list = this.parseField(element_text) //element_text.split(' ').map( item => this.trimBrackets(item) );
      console.log('ImportSchemaDialog:toStructureFromDDL:COLUMN', element_text, element_list)

      // Check for constrain. Generate key if it is constraint.
      if (this.toKeyFromDDL(element_list, keys, field)) {
        continue
      }

      // Field structure
      field.identity.name = this.trimBrackets(element_list[0])
      field.identity.description = element_text
      field.usage = 'Column'
      field.count = 1
      field.order = ++order

      // Extract type from DDL
      this.toTypeFromDDL(element_list, field, platformMap, platformName)

      // Check for optiona (NOT NULL)
      let textNOT = element_list.indexOf('NOT')
      let textNULL = element_list.indexOf('NULL')

      field.optional = !(textNOT > 1 && textNOT === textNULL - 1)

      // Check for Auto increment
      let textID = element_list.indexOf('AUTO_INCREMENT') > -1 || element_list.indexOf('IDENTITY') > -1
      if (textID) {
        field.usage = 'Key'
        field.type = 'Integer'
        field.optional = false
      }

      // Check if it have default
      let textDefault = element_list.indexOf('DEFAULT')
      if (textDefault > 1) {
        field.value = element_list[textDefault + 1]
      }

      // Check comment
      let textComment = element_list.indexOf('COMMENT')
      if (textComment > 1) {
        field.identity.description = this.trimBrackets(element_list[textComment + 1])
      }

      fields.push(field)
      console.log('ImportSchemaDialog:toStructureFromDDL:FIELD', field)
    }

    return { fields: fields, keys: keys }
  }

  trimBrackets = (str) => {
    if (str.length > 2) {
      if (str[0] === "'" && str[str.length - 1] === "'") return str.substr(1, str.length - 2)

      if (str[0] === '`' && str[str.length - 1] === '`') return str.substr(1, str.length - 2)

      if (str[0] === '"' && str[str.length - 1] === '"') return str.substr(1, str.length - 2)
    }
    return str
  }

  /**
   * Generate list of columns based on coma separation with ignore commas inside brackets
   * @param [String] text - text to parse
   */
  parseTable = (text) => {
    let result = [],
      item = '',
      depth = 0,
      quoteDepth = 0,
      quoteChar = ''
    console.log('ImportSchemaDialog:parseTable', text)

    for (let i = 0, c = text.length; i < c; i++) {
      let char = text[i]

      // Open brackets.
      if (char === '(') {
        depth++
        // We are open first bracket - we skip it and continue
        if (depth === 1) {
          continue
        }
      }

      if (char === ')') {
        depth--
        // We are close last bracket of data.
        if (!depth && item) {
          result.push(item.trim())
          break
        }
      }

      if (quoteDepth === 0 && (char === "'" || char === '`' || char === '"')) {
        quoteChar = char
        quoteDepth = 1
      } else if (quoteDepth === 1 && char === quoteChar) {
        quoteDepth = 0
      }

      // We are still before first bracket.
      if (!depth) {
        continue
      }

      // We are on coma for first level of brakets.
      if (depth === 1 && char === ',' && quoteDepth === 0) {
        console.log('ImportSchemaDialog:parseTable:ITEM', item, depth, quoteDepth)
        if (item) {
          result.push(item.trim())
          item = ''
        }
      } else {
        item += char
      }
    }
    return result && result.length > 0 ? result : null
  }

  /**
   * Generate list of columns based on coma separation with ignore commas inside brackets
   * @param [String] text - text to parse
   */
  parseField = (text) => {
    let result = [],
      item = '',
      quoteDepth = 0,
      quoteChar = ''
    console.log('ImportSchemaDialog:parseField', text)

    for (let i = 0, c = text.length; i < c; i++) {
      let char = text[i]

      if (quoteDepth === 0 && (char === "'" || char === '`' || char === '"')) {
        quoteChar = char
        quoteDepth = 1
      } else if (quoteDepth === 1 && char === quoteChar) {
        quoteDepth = 0
      }

      // We are on coma for first level of brakets.
      if (char === ' ' && quoteDepth === 0) {
        console.log('ImportSchemaDialog:parseField:ITEM', item, quoteDepth)
        if (item) {
          result.push(item.trim())
          item = ''
        }
      } else {
        item += char
      }
    }

    // Last item.
    result.push(item.trim())

    return result && result.length > 0 ? result : null
  }

  /**
   * Generate type from column DDL
   * @param [String] list - list of strings for table column specification
   * @param [Object] field - field to define type for
   */
  toTypeFromDDL = (list, field, platformMap, platformName) => {
    // Type of the field.
    let textType = list[1]
    console.log('ImportSchemaDialog:toTypeFromDDL', textType, platformMap, platformName)

    // Unsigned attributed
    let textUnsigned = list.indexOf('UNSIGNED') > -1

    // Text of the type name
    let start = textType.indexOf('(')
    let textName = start > 0 ? textType.substr(0, start) : textType

    // Convert to platform type
    if (platformMap) {
      // Type for property from map.
      let record = platformMap.find(
        (t) => nonCSCompare(t.label, textName) && (!textUnsigned || t.object['Attribute'] === 'UNSIGNED')
      )
      if (record && record.object) {
        // Type from map.
        field.type = record.object['Type']

        // Length and format if exist.
        if (record.object['Length']) field.size = record.object['Length']
        if (record.object['Format']) field.format = record.object['Format']
        console.log('ImportSchemaDialog:toTypeFromDDL:TYPE', textType, record, field)
      }
    } else {
      // Type for property from types.
      let record = this.state.typeOptions.find((t) => nonCSCompare(t.object[platformName], textType))
      if (record && record.object) {
        field.type = record.object['Type']
      }
    }

    // look for length and pressision
    let textList = start > 0 ? this.parseTable(list.slice(1).join(' ')) : null

    if (textList) {
      if (textList.length > 1) {
        field.precision = parseInt(textList[0])
        field.scale = parseInt(textList[1])
      } else if (nonCSCompare(field.type, 'decimal')) {
        field.precision = parseInt(textList[0])
        field.scale = 0
      } else {
        field.size = parseInt(textList[0])
      }
    }

    // Locale for strings. Need conversion.
    let iCollation = list.indexOf('COLLATE')
    if (iCollation > -1) {
      field.locale = list[iCollation + 1]
    }

    console.log('ImportSchemaDialog:toTypeFromDDL:FIELD', field)
  }

  /**
   * Generate keys from constraint
   * @param [String] list - list of strings for table column specification
   * @param [Object] keys - list of kyes already defined for dataset
   * @return (Object) key stracture if text represent constraint
   */
  toKeyFromDDL = (list, keys, field) => {
    if (list.indexOf('CHECK') === 0) {
      // This is check specification, We ignore check statement for now.
      return true
    }

    const keyList = ['PRIMARY', 'FOREIGN', 'UNIQUE', 'FULLTEXT', 'SPATIAL', 'KEY', 'INDEX', 'REFERENCES']

    function isAttributeKey(text) {
      let keyText = text.trim().toUpperCase()
      console.log('ImportSchemaDialog:toKeyFromDDL:ATTRIBUTE', keyText, keyList.indexOf(keyText))
      return keyList.indexOf(keyText) > -1
    }

    // Text and keys
    let text = list.join(' ')
    let count = keys.length
    console.log('ImportSchemaDialog:toKeyFromDDL', list, keys, text, count)

    // Find key in statement
    let iKey = list.findIndex((text) => isAttributeKey(text))
    if (iKey < 0) {
      // We have no key information in specification. It is column.
      return false
    }

    let iConstraint = list.indexOf('CONSTRAINT')

    let key = { identity: { name: 'key' + count, description: text } }
    let isColumn = !(iConstraint === 0 || iKey === 0)

    // Check if we have constraint with name. Use it as key name.
    if (iConstraint > -1 && iConstraint + 1 < iKey) {
      key.identity.name = list[iConstraint + 1]
    }

    // we have key, let's see what type of key we have
    let keyText = list[iKey].trim().toUpperCase()
    console.log('ImportSchemaDialog:toKeyFromDDL:CONSTRAIN', iConstraint, iKey, keyText, isColumn)

    // Key Type
    if (keyText === 'PRIMARY' || keyText === 'KEY') {
      // We have primary key.
      key.primary = true
      key.cardinality = 'One-To-One'
    } else if (keyText === 'UNIQUE') {
      // We have secondary unique key.
      key.primary = false
      key.cardinality = 'One-To-One'
    } else if (keyText === 'FULLTEXT' || keyText === 'SPATIAL') {
      // We have secondary text key.
      key.primary = false
      key.cardinality = 'Many-To-One'
    } else if (keyText === 'FOREIGN' || keyText === 'REFERENCES') {
      // Foreign secondary key.
      key.primary = false
      key.cardinality = 'Many-To-One'

      // Find reference.
      let iReference = list.indexOf('REFERENCES')

      // List reference.
      let iRefs = list.slice(iReference).findIndex((part) => part.indexOf('(') > -1)
      console.log('ImportSchemaDialog:toKeyFromDDL:REF', iRefs, iReference, list)
      if (iRefs > -1) {
        if (iReference > -1) {
          let textConstraint = list[iReference + 1]
          let iStart = textConstraint.indexOf('(')

          key.constraint = iStart > -1 ? '?' + textConstraint.substr(0, iStart) : '?' + textConstraint
          key.references = this.parseTable(list.slice(iReference).join(' '))
        } else {
          key.constraint = '?'
        }
      } else {
        key.constraint = iReference > -1 ? '?' + list[iReference + 1] : '?'
      }
    }

    // Find key column list
    if (isColumn) {
      field.usage = 'Key'
      key.fields = [list[0]]
    } else {
      let iParts = list.findIndex((part) => part.startsWith('('))
      if (iParts > -1) {
        key.fields = this.parseTable(list.slice(iParts).join(' '))
      }
      console.log('ImportSchemaDialog:toKeyFromDDL:PART', iParts)
    }

    // Add key to list of keys.
    keys.push(key)
    console.log('ImportSchemaDialog:toKeyFromDDL:KEY', key)

    return !isColumn
  }

  /**
   * Convert Avro Schema into JSONd schema
   * @param (String) record - data in avro JSON object
   * @param (Object) schema - current schema to generate
   * @return (String) JSON schema for same objectL
   */
  toJSONSchemaFromAvroSchema = (record, schema) => {
    // Conversion for type
    const platformName = 'Avro'
    const platformMap = this.state[platformName + 'From']
    console.log('ImportSchemaDialog:toJSONSchemaFromAvroSchema', record, schema, platformMap, platformName, this.state)

    // Schema of the object
    const dsSchema = schema ? schema : {}

    if (record.type === 'record') {
      dsSchema['title'] = record.name
      dsSchema['type'] = 'object'

      if (record.doc) {
        dsSchema['description'] = record.doc
      }
      if (record.aliases) {
        dsSchema['x-aliases'] = record.aliases
      }
      if (record.namespace) {
        dsSchema['x-path'] = record.namespace
      }
      dsSchema['properties'] = {}
      dsSchema['required'] = []
      //console.log("ImportSchemaDialog:toJSONSchemaFromAvroSchema:DATASET", dsSchema);
    }

    // Get list of properties
    for (let ifield in record.fields) {
      // Schema for array of items of current object.
      let field = record.fields[ifield]
      let item = (dsSchema['properties'][field.name] = {})
      let req = dsSchema['required']

      if (field.doc) {
        item['description'] = field.doc
      }
      if (field.aliases) {
        item['x-aliases'] = field.aliases
      }
      if (field.namespace) {
        item['x-path'] = field.namespace
      }

      // Extract type from Avro
      this.toItemFromAvroField(item, req, field, platformMap, platformName)
      console.log('ImportSchemaDialog:toJSONSchemaFromAvroSchema:ITEM', item, record.fields, dsSchema)
    }

    return dsSchema
  }

  /**
   * Generate type from column DDL
   * @param [Object] item - item of the JSON properties
   * @param [Object] field - field to define type for
   */
  toItemFromAvroField = (item, req, field, platformMap, platformName) => {
    // Type of the field.
    console.log('ImportSchemaDialog:toItemFromAvroField', field.name, item, field, platformMap, platformName)

    // Get type from field.
    let itemField = field
    while (typeof itemField.type === 'object' && Object.prototype.toString.call(itemField.type) !== '[object Array]')
      itemField = itemField.type

    //  Type of the field
    this.toTypeFromAvroType(item, itemField.type, itemField, platformMap)

    // Setup regular field custom schema properties
    if (field.default) {
      item['default'] = field.default
    }
    if (field.order) {
      item['x-order'] = field.order
    }

    switch (itemField.type) {
      case 'null':
        break
      case 'boolean':
      case 'int':
      case 'long':
      case 'float':
      case 'double':
        break
      case 'bytes':
      case 'string':
        break
      case 'fixed': {
        // Fixed string
        item['x-size'] = itemField.size
        break
      }
      case 'record': {
        // Item is a record.
        console.log('ImportSchemaDialog:toItemFromAvroField:RECORD', itemField, item)
        item['title'] = itemField.name

        this.toJSONSchemaFromAvroSchema(itemField, item)
        break
      }
      case 'array': {
        // Item is an array.
        console.log('ImportSchemaDialog:toItemFromAvroField:ARRAY', itemField, item)
        let items = (item['items'] = {})

        if (typeof itemField.items === 'object') {
          this.toJSONSchemaFromAvroSchema(itemField.items, items)
        } else {
          // For items we need to convert type.
          this.toTypeFromAvroType(items, itemField.items, itemField, platformMap)
        }
        break
      }
      case 'enum': {
        // Item is an enumerator.
        console.log('ImportSchemaDialog:toItemFromAvroField:ENUM', itemField, item)
        item['title'] = itemField.name
        item['enum'] = itemField.symbols
        break
      }
      case 'map': {
        break
      }
      default: {
        // We are on complex object type
        console.log(
          'ImportSchemaDialog:toItemFromAvroField:DEFAULT',
          itemField,
          Object.prototype.toString.call(itemField.type)
        )
        if (typeof itemField.type === 'object') {
          if (Object.prototype.toString.call(itemField.type) === '[object Array]') {
            console.log('ImportSchemaDialog:toItemFromAvroField:UNION', itemField, item)
            if (itemField.type.length === 2 && itemField.type[0] === 'null') {
              // We have optional value of the defined type.
              this.toTypeFromAvroType(item, itemField.type[1], itemField, platformMap)
            } else {
              // We are on real union of the types.
            }
            return
          } else {
            // Item is an object
            console.log('ImportSchemaDialog:toItemFromAvroField:OBJECT', itemField, item)
            item['title'] = itemField.name
            item['type'] = 'object'

            this.toJSONSchemaFromAvroSchema(itemField.type, item)
            break
          }
        }
        return
      }
    }

    req.push(field.name)
    console.log('ImportSchemaDialog:toItemFromAvroField:FIELD', item, req)
  }

  /**
   * Generate type from column DDL
   * @param [Object] item - item of the JSON properties
   * @param [string] type - avro type
   * @param [string] logicalType - avro logical type
   * @param [Object] field - item of the JSON properties
   */
  toTypeFromAvroType = (item, type, field, platformMap) => {
    //  Type of the field
    let mapType = platformMap.find(
      (pl) => nonCSCompare(pl.label, type) && nonCSCompare(pl.object['Attribute'], field.logicalType)
    )
    if (mapType) {
      item['type'] = mapType.object['Schema']
      if (mapType.object['Type']) {
        item['x-data-type'] = mapType.object['Type']
        if (item['x-data-type'] === 'Decimal') {
          if (field.scale) item['x-data-scale'] = field.scale
          if (field.scale) item['x-data-precision'] = field.precision
        }
      }
    }

    console.log('ImportSchemaDialog:toTypeFromAvroType', field.name, mapType, item)
  }

  /**
   * Change value of field in the object by field path
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {string} [message] message reported on time of update of element value
   */
  onValueChange = (element, field, value, message, option) => {
    logger.info('ImportSchemaDialog:onValueChange', element, field, value, message, option, this.state)
    if (message) {
      if (this.state.change) this.state.change(null, message)
      return
    }

    let object = deepCopy(this.state.importParams)
    deepSet(object, field, value)

    // Load platform we need for import
    if (field === 'schemaType') {
      object.schemaPlatform = ''
      if (object.schemaType && object.schemaType.length > 0) {
        if (object.schemaType === 'Object' || object.schemaType === 'Schema') {
          object.schemaPlatform = ''
        } else {
          object.schemaPlatform = object.schemaType
        }
        this.loadPlatformMap(object.schemaPlatform)
      }
    }

    this.Change(object)
    return value
  }

  /**
   * On file selected for import
   * @param [event] event of file selection
   */
  onFileSelected = (event) => {
    // Do we have file selected
    let files = []
    if (event.dataTransfer) {
      files = event.dataTransfer.files
    } else if (event.target) {
      files = event.target.files
    }
    files = [].slice.call(files)
    if (!files[0]) return

    let importParams = deepCopy(this.state.importParams)
    importParams.schemaFile = files[0].name
    this.state['schemaFileInfo'] = files[0]

    // Try to understand type from extention or type of file
    let name = files[0].name
    let type = files[0].type
    let ext = name.substring(name.lastIndexOf('.'))
    logger.info('ImportSchemaDialog:onFileSelected', name, type, ext, this.state)

    // Type extention
    importParams.schemaType = 'Schema'
    importParams.schemaPlatform = 'RESTFul-API'
    if (nonCSCompare(ext, '.json')) {
      // Check yupe
      if (!type || nonCSCompare(type, 'application/schema+json')) {
        // Will consider it json schema
        importParams.schemaType = 'Schema'
        importParams.schemaPlatform = ''
      } else if (nonCSCompare(type, 'application/json')) {
        // Will consider it json object
        importParams.schemaType = 'Object'
        importParams.schemaPlatform = ''
      }
    } else if (nonCSCompare(ext, '.sql') || nonCSCompare(ext, '.txt')) {
      // Will consider it ddl
      importParams.schemaType = 'SQLServer'
      importParams.schemaPlatform = 'SQLServer'
    } else if (nonCSCompare(ext, '.avsc')) {
      // Will consider it ddl
      importParams.schemaType = 'Avro'
      importParams.schemaPlatform = 'Avro'
    }

    // Load platform we need for import
    if (importParams.schemaPlatform) {
      this.loadPlatformMap(importParams.schemaPlatform)
    }

    this.Change(importParams)
  }

  checkInputErrors(importParams) {
    let inputError = ''
    if (!importParams.datasetName || importParams.datasetName.length === 0) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify dataset name!'
    }
    if (!importParams.schemaFile) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify file to load!'
    }
    if (!importParams.schemaType) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify import type!'
    }
    if (importParams.schemaPlatform && !this.state[importParams.schemaPlatform + 'From']) {
      inputError +=
        (inputError.length > 0 ? '\n' : '') + 'Please wait for ' + importParams.schemaPlatform + ' map to load!'
    }
    return inputError
  }

  /**
   * Implementation of actual save event
   * @param {object} [object] actual object managed by dialog
   * @param {boolean} [closeDialog] Do we need to close dialog
   * @param {func} [onSent] callback after send
   */
  onSave(object, closeDialog, onSent) {
    if (this.props.onSave) {
      console.log('PropertyDialog:onSave', object, closeDialog)
      waitFor(() => !object.schemaPlatform || this.state[object.schemaPlatform + 'From']).then(() =>
        this.importDataset(object, closeDialog, onSent)
      )
      return true // not to do anything here.
    }
  }

  // when JSON nodes in dialog are expanded, it should check height of inner components and expand itself if needed
  checkDialogHeight = () => {
    //console.log("ExampleDialog::checkDialogHeight");
    if (document.querySelector('.ImportSchemaDialog').offsetHeight > 700)
      this.onAdjust(0, document.querySelector('.ImportSchemaDialog').offsetHeight - this.state.height + 100)
  }

  /**
   * Render content of dataset editor dialog
   */
  renderEditor = (editState) => {
    //logger.info("ImportSchemaDialog::renderEditor", this.state, this.props);

    return (
      <div className="ImportSchemaDialog" onClick={this.checkDialogHeight}>
        {this.renderView(editState, (element) => {
          switch (element) {
            default:
              return null
            case 'schemaFile':
              return (
                <div className="ImportSchemaDialog__block_file clearfix">
                  <label className="ImportSchemaDialog__label">{'Import File: '}</label>
                  <div className="value_right">
                    <label className="ImportSchemaDialog__fileInputLabel" htmlFor="ImportSchemaDialog_fileInput">
                      <a className="btn btn_blue">Select file</a>
                    </label>
                    <div className="ImportSchemaDialog__selectedFileNameLabel">
                      {this.state.importParams.schemaFile || ''}
                    </div>
                    <input type="file" id="ImportSchemaDialog_fileInput" onChange={(e) => this.onFileSelected(e)} />
                  </div>
                </div>
              )
          }
        })}
      </div>
    )
  }
}

ImportSchemaDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  dataset: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
