/**
 * Created by kascode on 14.04.16.
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'

import { ObjectTitle } from '../ObjectTitle/ObjectTitle'

import { Person } from '../Person/Person'
import { ObjectUserList } from '../ObjectUserList/ObjectUserList'
import { EditorVersion } from '../EditorDialog/EditorVersion'
import { versionToStr, isDark } from '../../helpers'
import { editableState } from '../../helpers/index'

import './ObjectHeader.scss'
import { versionSort, getObjectBackgroundColor, getObjectTextColor } from '../../helpers/index'

export class ObjectHeader extends Component {
  constructor(props) {
    super(props)
    this.child = React.createRef()
    this.state = {
      isFinalizing: false,
      isDraft: props.isDraft
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.title) document.title = 'MetUCat: ' + newProps.title
  }

  onRenderPerson(item) {
    return <Person user={item} isExtended />
  }

  onEditPerson(item) {
    return item.name
  }

  getVersionStatus() {
    if (this.props.objectStatus === 'draft') return 'Finalize'
    if (this.props.objectStatus === 'finalized') return 'Approve'
    if (this.props.objectStatus === 'approved') return 'Create draft'
  }

  getVersions() {
    //console.log("ObjectHeader:getVersions", this.props.versions);
    if (!this.props.versions) return []
    else
      return this.props.versions.map(
        (v, index) =>
          v.version.major +
          '.' +
          v.version.minor +
          '.' +
          v.version.revision +
          ' ' +
          (index > 0
            ? ' approved'
            : versionSort(v.version, v.object.lastApprovedVersion) !== 0
            ? v.object.history.completions &&
              v.object.history.completions.length > 0 &&
              v.object.history.completions[v.object.history.completions.length - 1].completed
              ? ' ' + v.object.history.completions[v.object.history.completions.length - 1].status.toLowerCase()
              : ' draft'
            : ' approved')
      )
  }

  getSelectedVersion() {
    //console.log("ObjectHeader:getSelectedVersion", this.props.version, this.props.versions);
    if (this.props.version) {
      let index = this.props.versions.findIndex(
        (v) =>
          v.version.major === this.props.version.major &&
          v.version.minor === this.props.version.minor &&
          v.version.revision === this.props.version.revision
      )
      //console.log("ObjectHeader:getSelectedVersion:RETURN", index);
      return index > -1 ? this.getVersions()[index] : ''
    }
  }

  isPreviousVersion() {
    if (this.props.version) {
      let ret = ''
      this.props.versions.map((v, index) =>
        v.version.major === this.props.version.major &&
        v.version.minor === this.props.version.minor &&
        v.version.revision === this.props.version.revision
          ? (ret = index > 0)
          : null
      )
      return ret
    }
  }

  toggleFinalize = () => {
    this.setState({
      isFinalizing: !this.state.isFinalizing
    })
  }

  closeFinalize = () => {
    this.setState({
      isFinalizing: false
    })
  }

  versionChange(e) {
    let v = e.target.value
    //console.log('versionChange',v,versions,e);
    this.setState({
      selectedVersion: v,
      isDraft: v.toLowerCase().indexOf('draft') !== -1
    })
  }

  versionAction = () => {
    if (this.props.objectStatus === 'draft') this.toggleFinalize()
    if (this.props.objectStatus === 'finalized') this.props.showApproveVersionIssue()
    if (this.props.objectStatus === 'approved') this.child.current.versionAction('Create draft', true)
  }

  render() {
    // Check if object in good state to be rendered.
    if (!this.props.object || !this.props.object.object) {
      return null
    }
    let { title, picture, deployment, onTitleSave, objectUsers, version, className, isEditable, renderLocale } =
      this.props

    return (
      <div className={'ObjectHeader' + (className ? ' ObjectHeader_' + className : '')}>
        <div className="row middle-xs ObjectHeader__row">
          <div
            className={'ObjectHeader__objectName ' + (className ? ' ObjectHeader__objectName_' + className : '')}
            style={{
              backgroundColor: getObjectBackgroundColor(className),
              color: getObjectTextColor(className)
            }}
          >
            {className}
          </div>
          <div className="ObjectHeader__objectNameAfter"></div>
          <div className="col-xs-4">
            <div className="row">
              <div className="col">
                <ObjectTitle
                  title={title}
                  picture={picture}
                  onSave={onTitleSave}
                  onChangePicture={this.props.onChangePicture}
                  isEditable
                />
              </div>
            </div>
          </div>

          {this.props.version ? (
            <div className="col-xs-3 ObjectHeader__selectVersion">
              <EditorVersion
                ref={this.child}
                appState={this.props.appState}
                actions={this.props.actions}
                isEditable={isEditable ? editableState.EDITABLE : editableState.BROWSABLE}
                object={this.props.object}
                version={version}
                versions={this.props.versions}
                onVersionChange={(version) =>
                  this.props.onVersionChange(typeof version === 'object' ? versionToStr(version) : version)
                }
                onCreateDraft={this.props.onCreateDraft}
                onFinalize={this.props.onFinalize}
                onApprove={this.props.showApproveVersionIssue}
              />
            </div>
          ) : (
            <div className="col-xs-3"></div>
          )}
          <div className="col-xs-2">
            {deployment ? (
              <div>
                <strong>Deployment:&nbsp;</strong>
                {deployment}
              </div>
            ) : (
              ''
            )}
            {renderLocale ? renderLocale() : ''}
          </div>
          <div className="col-xs-3">
            {objectUsers ? (
              <ObjectUserList
                userState={this.props.userState}
                actions={this.props.actions}
                objectUsers={objectUsers}
                className={className}
                modalTitle="Users"
                object={this.props.object}
                maxWidth={284}
                onDeleteUser={this.props.onDeleteUser}
                onAddUser={this.props.onAddUser}
                onUserRoleChange={this.props.onUserRoleChange}
                onInviteUser={this.props.onInviteUser}
                emptyText="No users"
                onSave={this.props.onObjectUsersChange}
                dark={isDark(className)}
                greenColor={className === 'system'}
                isEditable={this.props.isEditable}
              />
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

ObjectHeader.propTypes = {
  appState: PropTypes.object,
  userState: PropTypes.object,
  actions: PropTypes.object,
  object: PropTypes.object,
  title: PropTypes.string,
  className: PropTypes.string,
  picture: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  deployment: PropTypes.string,
  architects: PropTypes.array,
  developers: PropTypes.array,
  versions: PropTypes.array,
  version: PropTypes.object,
  onVersionChange: PropTypes.func,
  isDraft: PropTypes.bool,
  objectStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  objectUsers: PropTypes.array,
  onTitleSave: PropTypes.func,
  onArchitectsChange: PropTypes.func,
  onDevelopersChange: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onAddUser: PropTypes.func,
  onUserRoleChange: PropTypes.func,
  onInviteUser: PropTypes.func,
  onObjectUsersChange: PropTypes.func,
  onFinalize: PropTypes.func,
  showApproveVersionIssue: PropTypes.func,
  onLocaleChange: PropTypes.func,
  onChangePicture: PropTypes.func,
  isEditable: PropTypes.bool
}
