import React, { Component } from 'react'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { FilterableContent } from '../FilterableContent/FilterableContent'
import { InstanceViewer } from './InstanceViewer.tsx'
import './TopologyChart.scss'
import { downloadSelectorAsPDF } from '../../helpers/pdfDownloader'

const testItems = [
  {
    identity: {
      id: 1,
      name: 'Test instance',
      description: 'Test description'
    },
    platform: 'Git',
    units: ['unit1', 'unit2', 'unit3']
  },
  {
    identity: {
      id: 2,
      name: 'Test instance2',
      description: 'Test long very long long very long long very long long very long long very long description'
    },
    platform: 'MySQL',
    units: ['unit1']
  }
]

export class TopologyChart extends Component {
  constructor(props) {
    super(props)

    let height = this.InitHeight(props)

    this.state = {
      change: null, // Method to report or cancel modification.
      //origin: JSON.stringify(props.activity),

      height: height,
      editMode: false,
      selectedInstance: null
    }
  }

  componentDidMount() {}

  /**
   * Init dialog height
   * @param user - selected user
   */
  InitHeight = (props) => {
    props = props || this.props

    let height = 600

    if (height < window.innerHeight - 248) {
      height = window.innerHeight - 248
    }

    return height
  }

  /**
   * close dialog
   */
  onClose() {
    this.setState({
      editMode: false
    })
    if (this.props.onClose) this.props.onClose()
  }

  /**
   * Adjust size of scroll area in dialog
   * @param width - increase or reduction in control width.
   * @param height - increase or reduction in control height.
   * @return
   */
  onAdjust = (width, height) => {
    this.setState((prevState) => {
      return { height: prevState.height + height }
    })
  }

  /**
   * Set edit mode on
   */
  startEdit = (change) => {
    this.setState({
      change: change
    })
    window.SQL.preventTableMove = false
  }

  /**
   * Execute activity when message send to service
   * @param {boolean} close - flag to close dialog
   * @param {*} error - return from service if any.
   * @return
   */
  onSent = (close, error) => {
    // Report complete of save with error or not
    if (this.state.change) {
      this.state.change(error)
    }

    if (close && !error) {
      setTimeout(() => {
        this.onClose()
      }, 1000)
    }
  }

  /**
   * Cancel changes
   * @param {boolean} [closeDialog] Do we need to close dialog
   */
  cancelEdit = (closeDialog) => {
    // Cancel save mode
    if (this.state.change) this.state.change(false)

    this.setState({
      change: null,
      inputs: this.props.inputs || []
    })

    window.SQL.preventTableMove = true
  }

  /**
   * show or hide main window
   */
  toggleEditMode() {
    this.setState({
      editMode: !this.state.editMode,
      isEdited: false
    })
  }

  saveTopology = (topology, closeDialog, onSent) => {
    const newTopology = Object.assign({}, { identity: this.props.topology.identity }, {})
    this.props.onSave(newTopology, closeDialog, onSent)

    return true
  }

  downloadPDF = () => {
    const area =
      document.querySelector('.EditorDialog_topology_chart .scrollarea-content ') ||
      document.querySelector('.EditorDialog_topology_chart_fullscreen .scrollarea-content ')
    downloadSelectorAsPDF(area, this.props.topology.identity.name + ' Chart', 'div.table')
  }

  toggleFullScreen = () => {
    this.setState({ fullScreen: !this.state.fullScreen })
  }

  renderInstance = (instance) => {
    //logger.log("renderInstance", instance);
    return (
      <InstanceViewer
        selectedInstance={this.state.selectedInstance}
        instance={instance}
        onInstanceClick={(instance) =>
          this.setState({
            selectedInstance: instance
          })
        }
      />
    )
  }

  renderItems = (props) => {
    let { data, filters } = props
    const filterLabels = ['search', 'instance', 'platform', 'configuration']

    // logger.log("renderItems ", data, filters);

    return (
      <div>
        <div className="row TopologyChart__filters">
          {filterLabels.map((key) => (
            <div key={key} className="col-xs-3">
              {filters[key]}
            </div>
          ))}
        </div>
        <div className="row">
          {data.map((instance) => (
            <div className="col-xs-4" key={instance.identity.id}>
              {this.renderInstance(instance)}
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderEditor = (editState) => {
    let items = this.props.topology.instances || []

    return (
      <div className="TopologyEditor">
        <div className="EditorDialog_topology_chart__additionalButtons">
          <div className="EditorDialog_topology_chart__headerControlPdf">
            <a className="" onClick={this.downloadPDF}>
              <span>{'{PDF}'}</span>
            </a>
          </div>
        </div>

        <FilterableContent dataFilters={[{ name: 'search' }, { name: 'instance' }, { name: 'platform' }]} data={items}>
          <this.renderItems />
        </FilterableContent>
      </div>
    )
  }

  renderFooter = (editState) => {
    if (this.state.selectedInstance) {
      let instance = this.state.selectedInstance

      return <div className="TransformationChart__footer">{JSON.stringify(instance)}</div>
    }

    return null
  }

  render() {
    // logger.log('TopologyChart::render', this.state, this.props);

    return (
      <EditorDialog
        level="0"
        ref="dialog"
        objectType={this.state.fullScreen ? 'topology_chart_fullscreen' : 'topology_chart'}
        modalTitle={'Topology diagram: ' + (this.props.topology ? this.props.topology.identity.name : '')}
        confirmText={this.props.topology ? 'Update' : 'Create'}
        editContent={this.renderEditor}
        footerContent={this.renderFooter}
        editHeight={this.state.height}
        isEditable={this.props.isEditable}
        isVisible
        onClose={this.onClose.bind(this)}
        onSave={this.saveTopology}
        onEdit={this.startEdit}
        onCancel={this.cancelEdit}
      />
    )
  }
}
