import { isPlainObject, isString } from 'lodash'

export const pluralTypeForms = new Map([
  ['application', 'applications'],
  ['dataset', 'datasets'],
  ['deployment', 'deployments'],
  ['environment', 'environments'],
  ['field', 'fields'],
  ['interface', 'interfaces'],
  ['issue', 'issues'],
  ['layout', 'layouts'],
  ['message', 'messages'],
  ['organization', 'organizations'],
  ['pipeline', 'pipelines'],
  ['publication', 'publications'],
  ['subscription', 'subscriptions'],
  ['system', 'systems'],
  ['topology', 'topologies'],
  ['user', 'users'],
  ['settings', 'settings'],
  ['version', 'versions'],
  ['view', 'views']
])

export const shortByType = {
  organizations: 'org',
  systems: 'sys',
  applications: 'app',
  datasets: 'ds',
  interfaces: 'in',
  pipelines: 'pl',
  views: 'vi',
  publications: 'pub',
  subscriptions: 'sub',
  versions: 'v',
  topologies: 'top',
  deployments: 'dep',
  environments: 'env'
}

/**
 * Returns full object uri
 * @param obj
 * @returns {string}
 */
export const getFullUri = (obj) => {
  //console.log("getFullUri", deepCopy(obj));

  if (obj && obj._path) return obj._path
  if (!obj || !obj.object) return ''

  const objType = (obj.type || obj.object.type || '').toLowerCase()

  if (objType === 'organization' || objType === 'Org') {
    return '/organizations/' + obj.identity.name
  }

  // datasets of organization have path '/org/OrgName/sys//app//ds/DsName' and we need to remove empty names from this path to make it /org/OrgName/ds/DsName
  const parts = obj.object.parent.name.split('/')
  let parentUri = ''
  for (let i = 1; i < parts.length / 2; i++) {
    if (parts[i * 2]) {
      parentUri += '/' + parts[i * 2 - 1] + '/' + parts[i * 2]
    }
  }

  //console.log("getFullUri:RESULT", parentUri, objType, obj);

  return (
    parentUri + '/' + pluralTypeForms.get(objType) + (obj.identity && obj.identity.name ? '/' + obj.identity.name : '')
  )
}

/**
 * Returns path of particular type rebuild from split path
 * @param splitPath {Array} - array of path elements generated by split without leading and ending slash
 * @param targetType {String} - string representation of type we want path for
 * @param short {Boolean} - do we need short or long version of path
 * @param from {Boolean} - path only start from particular type
 * @returns {String} generated path
 */
export const pathByType = (splitPath: string[], targetType: string, short = false, from = false) => {
  if (!splitPath || splitPath.length == 0) return ''

  let path = ''
  let target = false

  for (let i = 1; i < splitPath.length; i += 2) {
    // Type and short type for current part of path.
    let type = splitPath[i - 1].toLowerCase()
    let stype = shortByType[type]

    if (!stype) {
      // we have something wrong with path.
      return ''
    }

    if (from) {
      if (target || type == targetType) {
        // we reach target type and can return result now.
        path = path.concat('/' + (short ? stype : type) + '/' + splitPath[i])
        target = true
      }
    } else {
      // Add type to path.
      path = path.concat('/' + (short ? stype : type) + '/' + splitPath[i])
      if (type == targetType) {
        // we reach target type and can return result now.
        target = true
        break
      }
    }
  }
  return target ? path : ''
}

export type Version = {
  major: number
  minor: number
  revision: number
}

export const versionToStr = (version: Version): string => {
  if (!version) return ''
  return `${version.major}.${version.minor}.${version.revision}`
}

export const strToVersion = (str: string | Version): Version => {
  if (isPlainObject(str) && !isString(str) && str.major && str.minor && str.revision) return str
  const strNumbers = ('' + str).split('.')
  return { major: Number(strNumbers[0]), minor: Number(strNumbers[1]), revision: Number(strNumbers[2]) }
}

export const capitalize = (s) => {
  if (!s) return ''
  if (s.length < 1) return s
  return s[0].toUpperCase() + s.slice(1)
}

/**
 * Updates dialog's state and saves the object to LocalStorage
 * call: this.setState(updateLocalObject({changes}))
 * key in LocalStorage is "EditorDialog" + objectType + parent's path
 * @param object - new state of the object
 * @param version - version of the object
 * @returns {Function}
 */
export const updateLocalObject = (object: any, version?) => {
  //console.log("updateLocalObject:OUTER", object, version);
  return (state, props) => {
    //console.log("updateLocalObject:INNER", state, props);
    const objectType = state.objectType
    if (!objectType) console.warn('updateLocalObject: no objectType set in state')
    const newObject = object
    let id = 'EditorDialog_' + objectType
    const majorObject = props.majorObject || props.parentObject || props.publication
    if (majorObject) id += '_' + getFullUri(majorObject)
    localStorage.setItem(id, JSON.stringify(newObject))
    //console.log("updateLocalObject:SET", object, newObject, objectType, id);

    //if (state.change)
    //  state.change(true, '', '', '');

    return version ? { [objectType]: newObject, objectVersion: version } : { [objectType]: newObject }
  }
}
