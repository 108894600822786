/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-did-mount-set-state */
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import React, { Component } from 'react'
import { API } from '../../helpers/api'
import { initCognitoRefresh, poolData } from '../../helpers/auth-cognito'
import logger from '../../helpers/logger'
import md from '../../helpers/md'
import iLamp from '../../resources/images/lamp-2x.png'
import { AdaptiveImage } from '../AdaptiveImage/AdaptiveImage'
import { Loader } from '../Loader/Loader'
import './CognitoAuthPage.scss'
import { Footer } from './Footer'
import { Header } from './Header'
import { Right } from './Right'

const FORM_MODES = {
  changePassword: 'changePassword',
  forgotPassword: 'forgotPassword',
  forgotPasswordVerify: 'forgotPasswordVerify',
  login: 'login',
  register: 'register',
  verifyEmail: 'verifyEmail'
}

export class CognitoAuthPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      login: '',
      signup: '',
      formMode: FORM_MODES.login,
      sugnup: '',
      password: '',
      error: '',
      message: '',
      tips: [],
      loader: false
    }
  }

  componentDidMount() {
    const tips = md.repo.metadata.apdax.systems.difhub.applications.legal.datasets.logintips
    const selected_records = this.shuffle(tips.data.records).slice(0, 2)
    this.setState({ tips: selected_records })
    if (window.location.hash && window.location.hash.indexOf('login') !== -1) {
      const parts = window.location.hash.split('=')
      const password = parts[1]
      const login = parts[2]
      logger.log('CognitoAuthPage:componentDidMount', login, this.props)
      this.setState(
        {
          password: password,
          login: login
        },
        () => {
          this.onLogin()
        }
      )
    }
  }

  onLoginSuccess = (result, cognitoUser) => {
    logger.log('CognitoAuthPage:onLoginSuccess:RESULT', result)

    const idToken = result.idToken.jwtToken

    //window.alert("accessToken " + accessToken + " " + idToken);

    localStorage.setItem('currentUserToken', idToken)
    localStorage.setItem('currentUserTokenTime', new Date().getTime())

    logger.log('CognitoAuthPage:onLoginSuccess:REFRESH', result.refreshToken)
    localStorage.setItem('currentUserRefreshToken', result.refreshToken.token)

    // We are come here after sign up and know user.
    // But we can be in state this user nor registred yet in service. We will try to register.
    if (cognitoUser && (this.state.login === this.state.signup || this.state.formMode === 'verifyEmail')) {
      API.register
        .post({
          name: this.state.login,
          description: window.authOrganizationId
        })
        .then(
          (user) => {
            // We registred users.
            logger.log('CognitoAuthPage:onRegisterSuccess:REGISTER', user)

            API.getcurrentuser()
              .get()
              .then((userData) => {
                // Refresh for user.
                this.setState({ loader: false })

                logger.log('CognitoAuthPage:onLoginSuccess:USER', userData, this.state.login)

                localStorage.setItem('currentUserId', userData.id)
                localStorage.setItem('currentUserLogin', this.state.login.toLowerCase())

                initCognitoRefresh()

                if (localStorage['currentUserRefreshLocation']) {
                  // We try to safe original path for refresh in LoginBlock.
                  // for refresh location on login and use it once.
                  window.location.href = localStorage['currentUserRefreshLocation']
                  localStorage['currentUserRefreshLocation'] = ''
                } else {
                  window.location.href = '/'
                }
              })
          },
          (error) => {
            logger.log('CognitoAuthPage:onLoginSuccess:ERROR', cognitoUser, error)
            this.setState({ error: error.message, loader: false })
          }
        )
    } else {
      // We are on regular login and need simply get current user.
      API.getcurrentuser()
        .get()
        .then(
          (userData) => {
            // Refresh for user.
            this.setState({ loader: false })

            logger.log('CognitoAuthPage:onLoginSuccess:USER', userData, this.state.login)

            localStorage.setItem('currentUserId', userData.id)
            localStorage.setItem('currentUserLogin', this.state.login.toLowerCase())

            initCognitoRefresh()

            if (localStorage['currentUserRefreshLocation']) {
              // We try to safe original path for refresh in LoginBlock.
              // for refresh location on login and use it once.
              window.location.href = localStorage['currentUserRefreshLocation']
              localStorage['currentUserRefreshLocation'] = ''
            } else {
              window.location.href = '/'
            }
          },
          (error) => {
            logger.log('CognitoAuthPage:onLoginSuccess:ERROR', cognitoUser, error)
            this.setState({ error: error.message, loader: false })
          }
        )
    }

    //localStorage.setItem('currentUserId', );
  }

  onLogin = () => {
    //logger.log("CognitoAuthPage:onLogin", this.state.login, poolData.UserPoolId, this.props);
    if (!this.state.login) {
      this.setState({ error: 'User not specified', loader: false })
      return
    }
    const authenticationData = {
      Username: this.state.login.toLowerCase(),
      Password: this.state.password
    }
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData)

    this.setState({ message: '', error: '', loader: true })

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
    const userData = {
      Username: this.state.login.toLowerCase(),
      Pool: userPool
    }
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => this.onLoginSuccess(result, cognitoUser),
      onFailure: (err) => {
        //alert(err);
        logger.error(err)
        if (err.code === 'PasswordResetRequiredException') {
          //this.setState({formMode: FORM_MODES.changePassword, password: '', cognitoUser: cognitoUser, loader: false});
          this.setState({ error: '', loader: false }, this.onForgotPassword)
        } else if (err.code === 'UserNotConfirmedException') {
          this.setState({
            error: err.message,
            loader: false,
            formMode: FORM_MODES.register
          })
        }
        this.setState({ error: err.message, loader: false })
      },
      newPasswordRequired: (result) => {
        logger.log('CognitoAuthPage:onLogin:newPasswordRequired', result)
        //this.setState({error: "New password required"})
        this.setState({
          formMode: FORM_MODES.changePassword,
          password: '',
          cognitoUser: cognitoUser,
          loader: false
        })
      }
    })
  }

  onRegisterSuccess = (result) => {
    logger.log('CognitoAuthPage:onRegisterSuccess:RESULT', result)

    let cognitoUser = result.user

    this.setState({
      signup: cognitoUser.username,
      formMode: FORM_MODES.verifyEmail,
      cognitoUser: cognitoUser,
      message: '',
      error: '',
      loader: false
    })
  }

  onRegister = () => {
    let attribute = {
      Name: 'email',
      Value: this.state.login.toLowerCase()
    }

    let attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(attribute)
    let attributeList = []

    this.setState({ message: '', error: '', loader: true })

    attributeList.push(attributeEmail)
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)

    userPool.signUp(this.state.login.toLowerCase(), this.state.password, attributeList, null, (err, result) => {
      logger.log('SignUp', err, result)
      if (err) {
        this.setState({
          error: err.message,
          message: '',
          loader: false
        })
        return
      }
      this.onRegisterSuccess(result)
    })
  }

  onVerifyEmail = () => {
    this.setState({ message: '', error: '', loader: true })

    this.state.cognitoUser.confirmRegistration(this.state.code, true, (err, result) => {
      if (err) {
        this.setState({
          error: err.message,
          message: '',
          loader: false
        })
        return
      }
      //alert(result);
      this.setState(
        {
          message: 'Successfully verified email. You can now sign in',
          error: '',
          loader: false
        },
        () => this.onLogin()
      )
    })
  }

  onForgotPassword = () => {
    this.setState({ message: '', error: '', loader: true })

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
    const userData = {
      Username: this.state.login.toLowerCase(),
      Pool: userPool
    }
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        this.setState({ message: result, error: '', loader: false })
      },
      onFailure: (err) => {
        this.setState({ error: err.message, message: '', loader: false })
      },
      inputVerificationCode: () => {
        this.setState({
          cognitoUser: cognitoUser,
          formMode: FORM_MODES.forgotPasswordVerify,
          loader: false
        })
      }
    })
  }

  onVerifyPassword = () => {
    this.setState({ message: '', error: '', loader: true })

    const verificationCode = this.state.code
    const newPassword = this.state.password
    const cognitoUser = this.state.cognitoUser

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: (result) => {
        this.setState({ message: result, error: '', loader: false })
        this.onLogin()
      },
      onFailure: (err) => {
        this.setState({ error: err.message, message: '', loader: false })
      }
    })
  }

  onResend = () => {
    this.setState({ message: '', error: '', loader: true })

    if (!this.state.login) this.setState({ error: 'Please enter email', loader: false })

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
    const userData = {
      Username: this.state.login.toLowerCase(),
      Pool: userPool
    }
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        this.setState({ error: err.message, message: '', loader: false })
        return
      }
      this.setState({
        error: '',
        message: '',
        formMode: FORM_MODES.verifyEmail,
        cognitoUser: cognitoUser,
        loader: false
      })
    })
  }

  onChangePassword = () => {
    this.setState({ message: '', error: '', loader: true })

    this.state.cognitoUser.completeNewPasswordChallenge(
      this.state.password,
      {},
      {
        onSuccess: this.onLoginSuccess,
        onFailure: (err) => {
          this.setState({ error: err.message, message: '', loader: false })
        }
      }
    )
  }

  getRenderers = () => ({
    [FORM_MODES.changePassword]: this.renderChangePassword,
    [FORM_MODES.forgotPassword]: this.renderForgotPassword,
    [FORM_MODES.forgotPasswordVerify]: this.renderForgotPasswordVerify,
    [FORM_MODES.login]: this.renderLogin,
    [FORM_MODES.register]: this.renderRegister,
    [FORM_MODES.verifyEmail]: this.renderVerifyEmail
  })

  renderLogin = () => {
    return (
      <div className="loginBlock__form">
        <form
          onSubmit={(event) => event.preventDefault()}
          onKeyUp={(e) => {
            if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
              this.onLogin()
            }
          }}
        >
          <input
            type="text"
            name="login"
            className="loginBlock__input"
            onChange={(e) => this.setState({ login: e.target.value })}
            placeholder="Login"
            value={this.state.login}
          />
          <input
            type="password"
            name="password"
            className="loginBlock__input"
            onChange={(e) => this.setState({ password: e.target.value })}
            placeholder="Password"
            value={this.state.password}
          />

          <div className="loginBlock__buttonBlock">
            <button onClick={this.onLogin}>Sign In</button>
          </div>

          <div className="loginBlock__buttonBlock">
            <a
              onClick={() =>
                this.setState({
                  formMode: FORM_MODES.register,
                  message: '',
                  error: ''
                })
              }
            >
              Sign up
            </a>
          </div>

          <div className="loginBlock__buttonBlock">
            <a
              onClick={() =>
                this.setState({
                  formMode: FORM_MODES.forgotPassword,
                  message: '',
                  error: ''
                })
              }
            >
              Forgot password
            </a>
          </div>
        </form>
      </div>
    )
  }

  renderRegister = () => {
    return (
      <div className="loginBlock__form">
        <form
          onSubmit={(event) => event.preventDefault()}
          onKeyUp={(e) => {
            if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
              this.onRegister()
            }
          }}
        >
          <input
            type="text"
            name="login"
            className="loginBlock__input"
            onChange={(e) => this.setState({ login: e.target.value })}
            placeholder="Login"
            value={this.state.login}
          />
          <input
            type="password"
            name="password"
            className="loginBlock__input"
            onChange={(e) => this.setState({ password: e.target.value })}
            placeholder="Password"
            value={this.state.password}
          />

          <div className="loginBlock__buttonBlock">
            <button onClick={this.onRegister}>Sign up</button>
          </div>

          <div className="loginBlock__buttonBlock">
            <a onClick={this.onResend}>Resend confirmation code</a>
          </div>

          <div className="loginBlock__buttonBlock">
            <a
              onClick={() =>
                this.setState({
                  formMode: FORM_MODES.login,
                  message: '',
                  error: ''
                })
              }
            >
              Sign in
            </a>
          </div>
        </form>
      </div>
    )
  }

  renderForgotPasswordVerify = () => {
    // readonly and onfocus are for Chrome. Chrome ignores autocomplete=off
    // https://stackoverflow.com/questions/15738259/disabling-chrome-autofill

    return (
      <div className="loginBlock__form">
        <form
          onSubmit={(event) => event.preventDefault()}
          onKeyUp={(e) => {
            if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
              this.onVerifyPassword()
            }
          }}
        >
          <span className="loginBlock__bold">
            Verification code was sent to your email! <br />
            Please enter the code and new password:
          </span>
          <input
            type="text"
            name="code"
            className="loginBlock__input loginBlock__code"
            onChange={(e) => this.setState({ code: e.target.value })}
            placeholder="Verification code"
            value={this.state.code}
            autoComplete="off"
            readOnly
            autoFocus
            onFocus={(e) => {
              if (e.target.hasAttribute('readonly')) {
                e.target.removeAttribute('readonly')
                // fix for mobile safari to show virtual keyboard
                e.target.blur()
                e.target.focus()
              }
            }}
          />

          <input
            type="password"
            name="password"
            className="loginBlock__input"
            onChange={(e) => this.setState({ password: e.target.value })}
            placeholder="New Password"
            value={this.state.password}
            autoComplete="off"
            readOnly
            onFocus={(e) => {
              if (e.target.hasAttribute('readonly')) {
                e.target.removeAttribute('readonly')
                // fix for mobile safari to show virtual keyboard
                e.target.blur()
                e.target.focus()
              }
            }}
          />

          <div className="loginBlock__buttonBlock">
            <button onClick={this.onVerifyPassword}>Verify</button>
          </div>

          <div className="loginBlock__buttonBlock">
            <a
              onClick={() =>
                this.setState({
                  formMode: FORM_MODES.login,
                  message: '',
                  error: ''
                })
              }
            >
              Sign in
            </a>
          </div>
        </form>
      </div>
    )
  }

  renderForgotPassword = () => {
    return (
      <div className="loginBlock__form">
        <form
          onSubmit={(event) => event.preventDefault()}
          onKeyUp={(e) => {
            if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
              this.onForgotPassword()
            }
          }}
        >
          <input
            type="text"
            name="login"
            className="loginBlock__input"
            onChange={(e) => this.setState({ login: e.target.value })}
            placeholder="Login"
            value={this.state.login}
          />
          <div className="loginBlock__buttonBlock">
            <button onClick={this.onForgotPassword}>Recover password</button>
          </div>

          <div className="loginBlock__buttonBlock">
            <a
              onClick={() =>
                this.setState({
                  formMode: FORM_MODES.login,
                  message: '',
                  error: '',
                  password: ''
                })
              }
            >
              Sign in
            </a>
          </div>
        </form>
      </div>
    )
  }

  renderVerifyEmail = () => {
    return (
      <div className="loginBlock__form">
        <form
          onSubmit={(event) => event.preventDefault()}
          onKeyUp={(e) => {
            if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
              this.onVerifyEmail()
            }
          }}
        >
          <span className="loginBlock__bold">
            Verification code was sent to your email! <br />
            Please enter the code here:
          </span>
          <input
            type="text"
            name="code"
            className="loginBlock__input loginBlock__code"
            onChange={(e) => this.setState({ code: e.target.value })}
            placeholder="Verification code"
            value={this.state.code}
            autoComplete="new-password"
            autoFocus
          />
          <div className="loginBlock__buttonBlock">
            <button onClick={this.onVerifyEmail}>Verify</button>
          </div>
          <div className="loginBlock__buttonBlock">
            <a
              onClick={() =>
                this.setState({
                  formMode: FORM_MODES.login,
                  message: '',
                  error: ''
                })
              }
            >
              Sign in
            </a>
          </div>
        </form>
      </div>
    )
  }

  renderChangePassword = () => {
    return (
      <div className="loginBlock__form">
        <form
          onSubmit={(event) => event.preventDefault()}
          onKeyUp={(e) => {
            if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
              this.onChangePassword()
            }
          }}
        >
          You are required to change password. Please enter new password:
          <input
            type="password"
            name="password"
            className="loginBlock__input"
            onChange={(e) => this.setState({ password: e.target.value })}
            placeholder="New Password"
            value={this.state.password}
          />
          <div className="loginBlock__buttonBlock">
            <button onClick={this.onChangePassword}>Change and sign in</button>
          </div>
          <div className="loginBlock__buttonBlock">
            <a
              onClick={() =>
                this.setState({
                  formMode: FORM_MODES.login,
                  message: '',
                  error: ''
                })
              }
            >
              Back to Sign in
            </a>
          </div>
        </form>
      </div>
    )
  }

  renderLoginFormContent = (mode) => {
    const Renderer = this.getRenderers()[mode]

    return <Renderer />
  }

  shuffle = (a) => {
    let j, x, i
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1))
      x = a[i]
      a[i] = a[j]
      a[j] = x
    }
    return a
  }

  renderLeft() {
    return (
      <div className="col-xs-4 auth_left_col">
        {this.state.tips.map((row, idx) => {
          return (
            <div className="auth_left row" key={idx}>
              <div className="col-xs-2">
                <img className="auth_lamp" src={iLamp} alt="auth lamp" />
              </div>
              <div className="col-xs-10">
                <h2>{row.values[0]}</h2>
                <br />
                {row.values[2] ? (
                  <a href={row.values[2]} target="_blank" rel="noreferrer">
                    <button>{row.values[1]}</button>
                  </a>
                ) : (
                  row.values[1]
                )}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    const { formMode } = this.state
    //<Header title="DataHub" userState={this.props.userState}  appState={this.props.appState}  actions={this.props.actions} noApiCalls />
    return (
      <div className="CognitoAuthPage">
        <Header />

        <div className="App">
          <div className="row"></div>

          {this.renderLeft()}

          <div className="col-xs-4">
            <section className="loginBlock">
              <AdaptiveImage src={'logo-main'} />

              {this.renderLoginFormContent(formMode)}

              {this.state.loader ? <Loader /> : null}

              <div className="CognitoAuthPage__error">{this.state.error}</div>

              <div className="CognitoAuthPage__message">{this.state.message}</div>
            </section>
          </div>

          <Right />
        </div>

        <Footer />
      </div>
    )
  }
}
