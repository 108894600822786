/**
 * Created by mailf on 17.05.2016.
 */
import * as types from '../constants/ActionTypes'

export function updateDatasetData(id, data) {
  return {
    type: types.UPDATE_DATASET_DATA,
    id,
    payload: data
  }
}

export type DatasetActions = {
  updateDatasetData
}
