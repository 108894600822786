/**
 * Created by mailf on 26.09.2016.
 */
import React, { RefObject } from 'react'

import { columnsToType, isDark } from '../../helpers'
import { API, getObjectsWithConnected, idAPI, sendObjectNew } from '../../helpers/api'
import { getObjectByName } from '../../helpers/data'
import { nonCSCompare, orderSort, pluralTypeForms } from '../../helpers/index'
import FilteredTabbedTable from '../FilteredTabbedTable/FilteredTabbedTable'
import { LayoutDialog } from '../LayoutDialog/LayoutDialog'
import { MajorObjectVersioned } from '../MajorObjectVersioned/MajorObjectVersioned'
import { Messages } from '../Messages/Messages'
import { NestedTable } from '../NewTable/NestedTable'
import { ObjectHistoryTable } from '../ObjectHistoryTable/ObjectHistoryTable'
import { ActivityDialog } from './ActivityDialog'
import { TransformationChartDialog } from './TransformationChartDialog'
import { WorkflowChartDialog } from './WorkflowChartDialog'
import { getTailButtonLabel } from '../../helpers/helperComponents'
import { Tab } from '../FilteredTabbedTable/TabType'

export class Pipeline extends MajorObjectVersioned {
  filteredtabbedtableRef: RefObject<any>

  constructor(props) {
    super(props)

    // @ts-ignore
    this.state.objectType = 'pipeline'
    // @ts-ignore
    this.state.objectName = this.getObjectName(this.state.objectType)
    // @ts-ignore
    this.state.showChart = false

    this.filteredtabbedtableRef = React.createRef()
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!this.props.userState.profile && props.userState.profile) {
      this.loadUserRole(props)
    }

    if (
      !props.majorObject &&
      this.props.majorObject &&
      this.props.majorObject.identity.id !== 0 &&
      props.location.pathname !== this.props.location?.pathname
    ) {
      //console.log("Publication::componentWillReceiveProps new request", props.majorObject, this.props.majorObject);

      const newState: any = {
        objectType: 'pipeline',
        objectName: props.match.params[this.getObjectType(props) + 'Name'],
        recordsToDelete: null
      }

      //this.clearTabsContentLoaded(['activities', 'layouts']);          // show Loader for object table

      this.setState(newState, () => {
        getObjectsWithConnected(
          this.getDataRequestQuery(props),
          this.state.objectType,
          this.state.objectName,
          this.props.actions
        )
      })
    }
  }

  componentDidMount() {
    const pipeline = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    if (pipeline && pipeline.identity.id) {
      this.props.actions.updateMajorObject(pipeline.identity.id, pluralTypeForms.get(this.state.objectType), {
        isFetching: true
      })
    }

    getObjectsWithConnected(
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  getPipelineApiRequest = (props = this.props) => {
    return API.organizations(props.match.params.organizationName)
      .systems(props.match.params.systemName)
      .applications(props.match.params.applicationName)
      .pipelines(props.match.params.pipelineName)
  }

  getDataRequestQuery(props, version?) {
    const pipelineApiEndpoint = version
      ? this.getPipelineApiRequest(props).versions(version)
      : this.getPipelineApiRequest(props)

    const connectedObjectsEndpoint = version ? this.getPipelineApiRequest(props) : pipelineApiEndpoint

    return {
      endpoint: this.getPipelineApiRequest(props),
      objectRequest: props.match.params.versionName
        ? pipelineApiEndpoint.versions(props.match.params.versionName)
        : pipelineApiEndpoint,
      objectRequestCallback: (obj, errorStatus) => {
        this.setState({ errorStatus: errorStatus })
        return true
      },
      connectedObjectsRequests: [
        {
          objectType: 'version',
          isObjectProperty: true,
          majorObjectType: 'pipeline',
          propertyName: 'versions',
          request: connectedObjectsEndpoint.versions(),
          connectedObjectsRequestCallback: () => {
            if (this.state.loadingCheckpoints) {
              this.requestCheckpoints().then(() => {
                this.setState({ loadingCheckpoints: false })
              })
            }
          }
        },
        {
          objectType: 'user',
          majorObjectType: 'pipeline',
          isObjectProperty: true,
          propertyName: 'users',
          request: connectedObjectsEndpoint.users(),
          connectedObjectsRequestCallback: this.checkUserRole(props)
        }
      ]
    }
  }

  getDataRequestQueryOld(props) {
    return {
      endpoint: API.organizations(props.match.params.organizationName)
        .systems(props.match.params.systemName)
        .applications(props.match.params.applicationName)
        .pipelines(props.match.params.pipelineName),
      objectRequest: API.organizations(props.match.params.organizationName)
        .systems(props.match.params.systemName)
        .applications(props.match.params.applicationName)
        .pipelines(props.match.params.pipelineName),
      objectRequestCallback: (obj, errorStatus) => {
        this.setState({ errorStatus: errorStatus })
        return true
      },
      connectedObjectsRequests: [
        {
          objectType: 'version',
          isObjectProperty: true,
          majorObjectType: 'pipeline',
          propertyName: 'versions',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .applications(props.match.params.applicationName)
            .pipelines(props.match.params.pipelineName)
            .versions(),
          connectedObjectsRequestCallback: () => {
            if (this.state.loadingCheckpoints) {
              this.requestCheckpoints().then(() => {
                this.setState({ loadingCheckpoints: false })
              })
            }
          }
        },
        /*
        {
          'objectType': 'issue',
          request: API
            .organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .applications(props.match.params.applicationName)
            .pipelines(props.match.params.pipelineName)
            .issues()
        },
        */
        {
          objectType: 'user',
          majorObjectType: 'pipeline',
          isObjectProperty: true,
          propertyName: 'users',
          request: API.organizations(props.match.params.organizationName)
            .systems(props.match.params.systemName)
            .applications(props.match.params.applicationName)
            .pipelines(props.match.params.pipelineName)
            .users()
        }
      ]
    }
  }

  expandAllStructure = () => {
    if (this.filteredtabbedtableRef.current && this.filteredtabbedtableRef.current.refs.table_definitions) {
      this.filteredtabbedtableRef.current.refs.table_definitions.toggleAllRows()
      setTimeout(() => {
        this.forceUpdate()
      }, 0)
    }
  }

  isExpandAllStructure = () => {
    if (this.filteredtabbedtableRef.current && this.filteredtabbedtableRef.current.refs.table_definitions) {
      const rows = this.filteredtabbedtableRef.current.refs.table_definitions.state.expandedRows
      //console.log("Dataset:isExpandAllStructure", rows.length, this.filteredtabbedtableRef.current.refs.table_definitions.props.data.length, rows.reduce((all,cur) => all && cur, true) );
      if (
        rows.length === this.filteredtabbedtableRef.current.refs.table_definitions.props.data.length &&
        rows.reduce((all, cur) => all && cur, true)
      )
        return true
      else return false
    } else return false
  }

  toggleExpandTexts = () => {
    this.setState({ expandTexts: !this.state.expandTexts })
  }

  renderTextColumnHeader() {
    return (
      <span>
        Text&nbsp;
        <a
          className={
            'MajorObject__expandButton ' +
            (isDark(this.state.objectType) ? ' MajorObject__expandButton__dark ' : '') +
            (this.state.expandTexts ? 'MajorObject__expandButton__active' : '')
          }
          onClick={this.toggleExpandTexts}
        ></a>
      </span>
    )
  }

  getTextType = () => {
    return { name: this.state.expandTexts ? 'text_expanded' : 'text' }
  }

  getObjectStatus() {
    const pipeline = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const objectHistory = pipeline.object ? pipeline.object.history : false

    if (!pipeline.object || !objectHistory) return false

    if (objectHistory.approved) return 'approved'
    else if (objectHistory.finalized) return 'finalized'
    else return 'draft'
  }
  /*
  getTabsProps = () => {
    const pipeline = this.getObject();
    return [{
      title: 'activities',
      // filters: ['tags', 'type', 'updated', 'updatedBy'],
      filters: [],
      columns: [
        {
          name: 'name',
          type: columnsToType.getType('minorObjectName'),
          onCellClick: (value) => {
            this.setState({
              addingChild: true,
              childObjectType: 'activity',
              activeMinorObject: pipeline.activities.filter(f => f.identity.name === value.name)[0]
            });
          },
          width: 200
        },
        {name: 'description', type: columnsToType.getType('description'), width: 586},
        {name: 'input', type: columnsToType.getType('input'), width: 200 },
        {name: 'output', type: columnsToType.getType('output'), width: 200 }
      ],
      tailButtons: [{label: "Delete", onClick: (obj)=>{
        // TODO: implement activity delete
      }}],
      bottomButtons: [{label: "Add activity", onClick: () => {
        this.setState({
          editingChild: true,
          childObjectType: 'activity',
          activeMinorObjectEditable: true,
          childInEditMode: true
        });
      }}]
    }, {
      title: 'layouts',
      //filters: ['updatedBy'],
      //title: 'layouts',
      filters: [{name: '', width: 250-200}, {name: 'tags', width: 250}, {name: '', width: 150}, {name: 'platform', width: 250}],
      columns: [
          {
            name: 'name',
            displayName: 'Layout name',
            type: columnsToType.getType('minorObjectName'),
            onCellClick: (value, rowIndex) => {
              this.setState({
                editingChild: true,
                childObjectType: 'layout',
                activeMinorObject: pipeline.layouts.find(f => f.identity.name === value.identity.name),
                activeMinorObjectEditable: this.checkEditable(),
                childInEditMode: false
              });
            }, width: 250
          },
          {name: 'description', displayName: this.renderDescriptionColumnHeader(), type: this.getDescriptionType(),width: 400},
          {name: 'platform', type: columnsToType.getType('string'), width: 250},
          {name: 'unit', type: columnsToType.getType('string'), width: 285}
      ],
      bottomButtons: [
        {label: "Add layout", onClick: () => {
          this.setState({
            addingChild: true,
            childObjectType: 'layout'
          });
        }}
      ]
    }];
  };
*/

  pasteChild = (params, closeDialog, onSent) => {
    const pipeline = this.getObject()

    let newObject = JSON.parse(localStorage.clipboard)
    newObject.identity.name = params.objectName
    newObject.identity.id = ''

    if (localStorage.clipboardType === 'activity') {
      const newPipeline = Object.assign(
        {},
        {
          identity: pipeline.identity,
          activities: pipeline.activities.concat(newObject)
        }
      )
      this.savePipeline(newPipeline, closeDialog, onSent)
    } else if (localStorage.clipboardType === 'layout') {
      const newPipeline = Object.assign(
        {},
        {
          identity: pipeline.identity,
          layouts: pipeline.layouts.concat(newObject)
        }
      )
      this.savePipeline(newPipeline, closeDialog, onSent)
    }
  }

  getTabsProps = (): Tab[] => {
    const pipeline = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    const isEditable = this.checkEditable()

    return [
      {
        title: 'activities',
        tableComponent: NestedTable,
        filters: [
          { name: '', width: 97 },
          { name: 'search', width: 300 },
          { name: '', width: 10 }
        ],
        columns: [
          {
            displayName: 'Ord.',
            name: 'order',
            type: columnsToType.getType('id'),
            frozen: true,
            width: 42,
            hiddenInChildTable: true
          },
          {
            name: 'expandButton',
            displayName: (
              <span>
                <a
                  className={
                    'MajorObject__expandStructureButton ' +
                    (isDark(this.state.objectType) ? ' MajorObject__expandStructureButton__dark ' : '') +
                    (this.isExpandAllStructure() ? 'MajorObject__expandStructureButton__active' : '')
                  }
                  onClick={this.expandAllStructure}
                ></a>
              </span>
            ),
            type: { name: 'data' },
            frozen: true,
            width: 32
          },
          {
            name: 'name',
            displayName: 'Activity Name',
            type: columnsToType.getType('minorObjectName'),
            width: 226,
            onCellClick: (value) => {
              this.setState({
                editingChild: true,
                childObjectType: 'activity',
                activeMinorObject: this.getActivity(value.identity.name),
                activeMinorObjectEditable: this.checkEditable(),
                childInEditMode: false
              })
            }
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 535
          },
          {
            name: 'usage',
            displayName: 'Type',
            type: columnsToType.getType('activityType'),
            width: 50
          },
          { name: 'input', type: columnsToType.getType('input'), width: 150 },
          { name: 'output', type: columnsToType.getType('output'), width: 150 }
        ],
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (obj) => {
                  console.log('Pipeline:getTabsProps:View', obj)
                  this.setState({
                    editingChild: true,
                    childObjectType: 'activity',
                    activeMinorObject: this.getActivity(obj.identity.name),
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: this.checkEditable()
                  })
                }
              }
            ]
          : [
              {
                label: '+ Add',
                onClick: (obj) => {
                  console.log('tailButtons onClick', obj)
                  this.setState({
                    editingChild: true,
                    activeMinorObject: this.createChildActivity(obj),
                    childObjectType: 'activity',
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: this.checkEditable()
                  })
                }
              },
              {
                label: getTailButtonLabel('Edit'),
                onClick: (obj) => {
                  console.log('Pipeline:getTabsProps:EDIT', obj)
                  this.setState({
                    editingChild: true,
                    childObjectType: 'activity',
                    activeMinorObject: this.getActivity(obj.identity.name),
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: this.checkEditable()
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (obj) => {
                  //this.deleteElement(obj.identity.id)
                  console.log('Delete', obj)
                  this.showDeleteActivity(obj.identity.id, obj.identity.name)
                }
              }
            ],
        contextMenuButtons: [
          {
            label: 'Copy activity',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              // console.log("Dataset::Copy field", data);
              const activity = this.getActivity(data.identity.name)
              localStorage.clipboard = JSON.stringify(activity)
              localStorage.clipboardType = 'activity'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste activity',
                  data: { action: 'paste' },
                  onClick: () => {
                    if (!localStorage.clipboard) {
                      alert('No activity copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'activity') {
                      alert('No activity copied')
                      return
                    }

                    this.setState({
                      pastingChild: true,
                      pastingChildName: JSON.parse(localStorage.clipboard).identity.name
                    })
                  },
                  // @ts-ignore
                  showInBottom: true
                },
                {
                  label: 'Reorder activities',
                  data: { action: 'reorder' },
                  onClick: () => {
                    const pipeline = this.getObject()
                    let newPipeline = Object.assign({}, pipeline, {
                      activities: pipeline.activities.sort(orderSort).map((activitiy, index) => {
                        return Object.assign({}, activitiy, {
                          order: index + 1
                        })
                      })
                    })

                    //this.saveDataset({identity: ds.identity, structure: newStructure});
                    this.sendPipeline(newPipeline)
                  }
                },
                {
                  label: 'Reorder by 10 step',
                  data: { action: 'reorder' },
                  onClick: () => {
                    const pipeline = this.getObject()

                    let delta = 1
                    let newPipeline = Object.assign({}, pipeline, {
                      activities: pipeline.activities.sort(orderSort).map((activitiy, index) => {
                        return Object.assign({}, activitiy, {
                          order: index === 0 ? delta : (delta += 10)
                        })
                      })
                    })

                    //this.saveDataset({identity: ds.identity, structure: newStructure});
                    this.sendPipeline(newPipeline)
                  }
                }
              ]
        ),
        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () =>
                  this.setState({
                    editingChild: true,
                    activeMinorObject: this.createActivity(),
                    childObjectType: 'activity',
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: true
                  })
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add activity',
                onClick: () =>
                  this.setState({
                    editingChild: true,
                    activeMinorObject: this.createActivity(),
                    childObjectType: 'activity',
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: true
                  })
              }
            ]
      },
      {
        title: 'layouts',
        filters: [
          { name: '', width: 250 - 200 },
          { name: 'tags', width: 250 },
          { name: '', width: 150 },
          { name: 'platform', width: 250 }
        ],
        columns: [
          {
            name: 'name',
            displayName: 'Layout name',
            type: columnsToType.getType('minorObjectName'),
            onCellClick: (value, rowIndex) => {
              this.setState({
                editingChild: true,
                childObjectType: 'layout',
                activeMinorObject: pipeline.layouts.find((f) => f.identity.name === value.identity.name),
                activeMinorObjectEditable: this.checkEditable(),
                childInEditMode: false
              })
            },
            width: 250
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 400
          },
          {
            name: 'platform',
            type: columnsToType.getType('string'),
            width: 250
          },
          { name: 'unit', type: columnsToType.getType('string'), width: 285 }
        ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add layout',
                onClick: () => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'layout',
                    activeMinorObjectEditable: true,
                    childInEditMode: true
                  })
                }
              }
            ],
        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'layout',
                    activeMinorObjectEditable: true,
                    childInEditMode: true
                  })
                }
              }
            ],
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (value, rowIndex) => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'layout',
                    activeMinorObject: pipeline.layouts.find((f) => f.identity.name === value.identity.name),
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: this.checkEditable()
                  })
                }
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (value, rowIndex) => {
                  this.setState({
                    editingChild: true,
                    childObjectType: 'layout',
                    activeMinorObject: pipeline.layouts.find((f) => f.identity.name === value.identity.name),
                    activeMinorObjectEditable: this.checkEditable(),
                    childInEditMode: this.checkEditable()
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (value) => this.deleteLayout(value.identity.id)
              }
            ]
      },
      {
        title: 'history',
        filters: [],
        columns: [],
        tableComponent: ObjectHistoryTable,
        parentObject: pipeline
      }
    ]
  }

  getData = () => {
    const pipeline = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)
    //console.log("Pipeline:getData", pipeline, this.props);

    return this.getTabsProps().map((block) => {
      switch (block.title) {
        case 'activities':
          block.data = pipeline.activities
            ? pipeline.activities
                .map((act) => {
                  const newName = {
                    name: act.identity.name,
                    objectType: 'activity',
                    path: act.path // do not forget to remove usage of .path when implementing activity
                  }
                  act = Object.assign({}, act, { name: newName })
                  act.description = act.identity.description
                  act.input = act.inputs
                    ? act.inputs.map((input) => {
                        return input.identity.name
                      })
                    : ''
                  act.output = act.outputs
                    ? act.outputs.map((output) => {
                        return output.identity.name
                      })
                    : ''
                  act.usage = nonCSCompare(act.type, 'operation') ? 'OPR' : 'TRN'

                  return act
                })
                .sort(orderSort)
            : []
          //console.log("Pipeline:getData:ACTIVITIES", block);
          return block
        case 'layouts':
          block.data = this.formatLayoutForTable(pipeline.layouts || [])
          return block
        case 'history':
          block.data = [
            {
              versions: pipeline.versions,
              checkpoints: pipeline.checkpoints,
              loadingCheckpoints: this.state.loadingCheckpoints,
              // @ts-ignore
              restore: this.restoreFromCheckpoint
            }
          ]
          return block

        default:
          return block
      }
    })
  }

  formatLayoutForTable = (layouts) =>
    layouts.map((layout) => {
      const newName = {
        name: layout.identity.name,
        objectType: 'layout',
        path: layout.path
      }
      layout.description = layout.identity.description
      layout = Object.assign({}, layout, {
        name: newName,
        description: layout.identity.description
      })

      return layout
    })

  minorObjectChange = (minorObject) => {
    // TODO: implement minor object change
    // switch (this.state.childObjectType) {
    //   case 'field':
    //     this.fieldChange(minorObject);
    // }
    //
    // this.setState({
    //   addingChild: false,
    //   activeMinorObject: null
    // });
  }

  closeEditDialog = () => {
    this.setState(
      {
        editingChild: false,
        childObjectType: '',
        activeMinorObject: null
      },
      () => {}
    )
  }

  /**
   * Get activity by name
   * @return {object} activity
   */
  getActivity(activityName) {
    const pipeline = this.getObject()

    return pipeline.activities.find((f) => nonCSCompare(f.identity.name, activityName))
  }

  /**
   * Create new activity for editing
   * @return {object} empty element
   */
  createActivity = () => {
    const pipeline = this.getObject()
    let order = 0
    if (!order) {
      pipeline.activities.forEach((f) => {
        order = order < f.order ? f.order : order
      })
      order += 1
    }
    return { identity: { name: '' }, type: 'Transformation', order: order }
  }

  createChildActivity = (obj) => {
    let activity: any = this.createActivity()
    activity.parent = {
      id: obj.identity.id,
      name: obj.identity.name
    }
    return activity
  }

  showDeleteActivity = (id, elementName) => {
    this.setState({
      deleteChildObject: {
        objectType: 'activity',
        obj: { identity: { name: elementName } },
        delete: () => {
          this.setState({ deleteChildObject: false })
          this.deleteActivity(id, elementName)
        }
      }
    })
  }

  saveActivity = (activity, closeDialog, onSent) => {
    const newPipeline = Object.assign({}, { identity: this.getObject().identity, activities: [activity] })

    this.savePipeline(newPipeline, closeDialog, onSent)
  }

  deleteActivity = (id, name = '') => {
    const newPipeline = Object.assign({}, this.getObject())

    const oldActivities = this.getObject().activities
    if (id) {
      newPipeline.activities = oldActivities.filter((act) => act.identity.id !== id)
    } else if (name) {
      newPipeline.activities = oldActivities.filter((act) => !nonCSCompare(act.identity.name, name))
    }
    console.log('Pipeline:deleteActivity', id, name, oldActivities, newPipeline.activities)

    this.sendPipeline(newPipeline, null, null)
  }

  /**
   * Save  updated layout
   * @param {object} layout
   * @param {boolean} [closeDialog]
   * @param {function} [onSent]
   */
  saveLayout = (layout, closeDialog, onSent) => {
    const newPipeline = Object.assign(
      {},
      {
        identity: this.props.majorObject.identity,
        layouts: [layout]
      }
    )

    this.savePipeline(newPipeline, closeDialog, onSent)
  }

  deleteLayout = (layoutId) => {
    //console.log("Interface::deleteLayout ", layoutId);
    const newPipeline = Object.assign({}, this.props.majorObject)

    this.setState({
      deleteChildObject: {
        obj: newPipeline.layouts.find((lt) => lt.identity.id === layoutId),
        delete: () => {
          newPipeline.layouts = newPipeline.layouts.filter((lt) => lt.identity.id !== layoutId)
          //console.log("Interface::deleteLayout new", newInterface.layouts);

          this.sendPipeline(newPipeline)
          this.setState({ deleteChildObject: null })
        }
      }
    })
  }

  /**
   * Save Pipeline update using patch action
   * @param {object} [newPipeline] - new Pipeline with changes only
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   */
  savePipeline = (newPipeline, closeDialog, onSent) => {
    console.log('Pipeline:savePipeline', newPipeline)

    return sendObjectNew(
      idAPI.pipelines(newPipeline.identity.id),
      'patch',
      this.props.actions,
      this.getObject(),
      newPipeline
    ).then(
      (result) => {
        if (onSent) onSent(closeDialog)
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  savePipelinePositions = (positions, closeDialog, onSent) => {
    const pipeline = this.getObject()

    let newElements = positions.map((position) => {
      return {
        identity: { name: position.table },
        frame: {
          positions: {
            left: position.x,
            top: position.y,
            width: position.w,
            height: position.h
          }
        },
        style: {
          border: {
            left: {
              color: position.color
            }
          }
        }
      }
    })

    let newPipeline = Object.assign({}, pipeline, {
      object: Object.assign({}, pipeline.object, { elements: newElements })
    })

    console.log('Pipeline:savePipeline', newPipeline)

    return sendObjectNew(
      idAPI.pipelines(pipeline.identity.id),
      'patch',
      this.props.actions,
      this.getObject(),
      newPipeline
    ).then(
      (result) => {
        if (onSent) onSent(closeDialog)
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  /**
   * Send new state of Pipeline by using put action
   * @param {object} [newPipeline] - new Pipeline with all data
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {function} [onSent] - call back function to report status of update
   */
  sendPipeline = (newPipeline, closeDialog?, onSent?) => {
    console.log('Pipeline:sendPipeline', newPipeline)

    return sendObjectNew(idAPI.pipelines(newPipeline.identity.id), 'put', this.props.actions, newPipeline).then(
      (result) => {
        if (onSent) onSent(closeDialog)
      },
      (error) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, error)
      }
    )
  }

  /**
   * render Chart view button and Chart view window
   * @returns {XML[]}
   */
  renderRightMenu() {
    const pipeline = this.getObject()
    return (
      <React.Fragment>
        {this.renderShowJson()}
        <br />
        {this.renderDownloadPDF()}
        <br />
        <span>
          {pipeline && pipeline.object ? (
            <div className="MajorObjectView__showChart">
              <span>
                <a className="" onClick={() => this.setState({ showChart: true })}>
                  &nbsp;Chart&nbsp;
                </a>
              </span>
            </div>
          ) : null}
        </span>
      </React.Fragment>
    )
  }

  renderChart() {
    const pipeline = this.getObject()

    console.log('renderChart::renderChart', pipeline.object.usage)

    if (pipeline.object.usage === 'Work Flow') {
      return <WorkflowChartDialog pipeline={pipeline} onClose={() => this.setState({ showChart: false })} />
    } else {
      //if (pipeline.object.usage === 'Data Flow')
      return (
        <TransformationChartDialog
          pipeline={pipeline}
          onClose={() => this.setState({ showChart: false })}
          onSave={this.savePipelinePositions}
          onSavePipeline={this.savePipeline}
          isEditable
        />
      )
    }
  }

  render() {
    const pipeline = getObjectByName(this.props.appState, this.state.objectType, this.state.objectName)

    if (!pipeline || pipeline.isFetching) {
      return this.renderLoading()
    } else {
      //console.log("Pipeline:render:ACTIVITY", this.state.activeMinorObject, pipeline);

      return (
        <div className="MajorObject__outer">
          <section className={'MajorObjectView MajorObjectView_' + this.state.objectType}>
            {this.renderHeader()}
            {this.renderInfo()}
            <div className="MajorObjectView__connections">
              <div className="row">
                <div className="col-xs-12">
                  {this.renderBackArrowTabs()}
                  <FilteredTabbedTable
                    ref={this.filteredtabbedtableRef}
                    /*
                    // @ts-ignore */
                    tablesData={this.getData()}
                    theme={this.state.objectType}
                    tabsLoaded={this.state.tabsLoaded}
                    onTabChange={this.onTabChange}
                  />
                  {this.renderFinalizedMessage()}
                </div>
              </div>
            </div>

            {this.state.editingChild && this.state.childObjectType === 'activity' ? (
              <ActivityDialog
                appState={this.props.appState}
                actions={this.props.actions}
                isEditable={this.state.childInEditMode ? 2 : this.checkEditable() ? 1 : 0}
                isVisible={this.state.editingChild}
                onSave={this.saveActivity}
                onClose={this.closeEditDialog}
                parentObjectType={this.state.objectType}
                childObjectType={this.state.childObjectType ? this.state.childObjectType : 'none'}
                activity={this.state.activeMinorObject}
                majorObject={pipeline}
              />
            ) : null}

            {this.state.editingChild && this.state.childObjectType === 'layout' ? (
              <LayoutDialog
                appState={this.props.appState}
                actions={this.props.actions}
                isVisible
                isEditable={this.state.childInEditMode ? 2 : this.checkEditable() ? 1 : 0}
                majorObject={pipeline}
                layout={this.state.activeMinorObject}
                onSave={this.saveLayout}
                onClose={this.closeEditDialog}
                isSkipSchema
              />
            ) : null}

            {this.state.showChart ? this.renderChart() : null}
          </section>
          {pipeline && pipeline.identity && pipeline.identity.id ? (
            <Messages
              appState={this.props.appState}
              userState={this.props.userState}
              actions={this.props.actions}
              object={pipeline}
              objectType={this.state.objectType}
              currentUserRole={this.state.currentUserRole}
            />
          ) : null}
        </div>
      )
    }
  }
}
