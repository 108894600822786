/**
 * Created
 * Modified by sashaberger on 20.07.2017.
 *
 * Componet help to specify URI for object supported by DifHub.
 *
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { API, getApiResource, getObjectListNew, getRequestFromPath, singularType } from '../../helpers/api'
import { childrenByType, versionToStr, versionSort, issueSort, getFullUri } from '../../helpers/index'

import './ObjectPathEditor.scss'

function isInteger(value) {
  return value.match(/^[0-9]+$/)
}

class ObjectPathEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      path: '',
      pathIndex: 0,
      pathType: '',
      editText: '',
      editItems: [],
      editHeight: 0,
      editActive: false,
      editFocused: false
    }

    this.inputRef = React.createRef()
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.startObjectPath !== nextProps.startObjectPath) {
      //logger.info("ObjectEditPath:componentWillReceiveProps", nextProps.startObjectPath, this.state.path);
      this.getState(nextProps.startObjectPath + '/').then((newState) => {
        this.setState(newState)
      })
    }
  }

  /**
   * Get state from new path
   * @return
   */
  getState = async (path, active, focused) => {
    if (!path || path.length < 1) return false

    const splitPath = path.substring(1).split('/')
    const pathIndex = splitPath.length - 1
    const pathData = path.slice(0, path.length - splitPath[pathIndex].length - 1)

    let state = Object.assign({}, this.state)

    state.path = pathData
    state.pathIndex = pathIndex
    state.pathType = pathIndex & 1 ? splitPath[pathIndex - 1] : ''
    state.editText = splitPath[pathIndex]
    state.editItems = await this.getItems(path, pathIndex)
    state.editHeight = state.editItems ? state.editItems.length * 45 : 0
    state.editActive = active !== null && active !== undefined ? active : this.state.editActive
    state.editFocused = focused !== null && focused !== undefined ? focused : this.state.editFocused

    //logger.info("ObjectPathEditor:getState", { path, splitPath, pathIndex, pathData, state }, Error().stack, this.state, this.props);

    // Adjust size for new lines in list.
    if (this.props.onAdjust) {
      this.props.onAdjust(
        0,
        (state.editActive ? state.editHeight : 0) - (this.state.editActive ? this.state.editHeight : 0)
      )
    }
    return state
  }

  /**
   * Get objects for current path
   * @return
   */
  getList = async (path) => {
    if (!path || path.length < 1) return false

    //logger.info("ObjectEditPath:getList", path, this.state, this.props);

    let splitPath = path.substring(1).split('/')
    let pathIndex = splitPath.length - 1
    let pathData = splitPath[pathIndex]
    let pathType = splitPath[pathIndex - 1]
    let parentPath = path.slice(0, path.length - pathType.length - pathData.length - 2)

    // If we don't on items we move to we skip load
    if (!(pathIndex & 1)) {
      return false
    }

    //logger.info("ObjectEditPath:getList:REQEST", { path, splitPath, pathIndex, pathData, pathType, parentPath },  singularType(pathType), this.state, this.props);

    if (!pathType) {
      return null
    } else if (pathType === 'organizations') {
      return await getObjectListNew(API.organizations, 'organization')
    } else {
      let req = getRequestFromPath(parentPath)
      return await getObjectListNew(req[getApiResource(pathType)], singularType(pathType))
    }
  }

  /**
   * List of items from path
   * @return
   */
  getItems = async (path, index) => {
    let ret = []

    const splitTarget = this.props.targetObjectPath ? this.props.targetObjectPath.substring(1).split('/') : []
    const splitPath = path.substring(1).split('/')

    let pathIndex = index
    let filterText = ''

    if (
      pathIndex >= 0 &&
      pathIndex < splitTarget.length &&
      splitTarget[pathIndex].length > 0 &&
      splitTarget[pathIndex] !== '*'
    ) {
      // We have this type as target. We will filter list by it.
      filterText = splitTarget[pathIndex].toLowerCase()
    } else {
      filterText = splitPath[pathIndex].toLowerCase()
    }

    //logger.info("ObjectPathEditor:getItems", { path, index, splitTarget, splitPath, pathIndex, filterText}, this.state, this.props);

    if (pathIndex & 1) {
      // We are on object and will look for object match.
      if (pathIndex > 0) {
        // We find object matching object name in edit
        let type = splitPath[pathIndex - 1]
        let list = await this.getList(path)

        //logger.info("ObjectPathEditor:getItems:MAP", type, list);

        if (list) {
          // List of items we have
          let filter = this.props.filter ? this.props.filter : this.defaultFilter
          let parent = path.slice(0, path.length - filterText.length - 1)

          let filtered = list.filter((item) => getFullUri(item).startsWith(parent) && filter(item, type, filterText))
          let sorted = this.props.sortFunction
            ? this.props.sortFunction(type, filtered)
            : this.defaultSort(type, filtered)

          ret = sorted
          //logger.info("ObjectPathEditor:getItems:OBJECT", path, splitPath, pathIndex, filterText, ret);
        }
      }
    } else {
      // We are on type and will look for posible types.
      if (pathIndex > 1) {
        if (splitTarget.length === 0 || pathIndex < splitTarget.length) {
          // We find types matching typed in edit
          let children = this.props.childrenByType ? this.props.childrenByType : childrenByType
          let type = children[splitPath[pathIndex - 2]]
          let types = typeof type === 'object' ? type : type ? [type] : []

          if (filterText === '') {
            ret = types
          } else {
            ret = types.filter((item) => {
              return item.toLowerCase().indexOf(filterText) > -1
            })
          }

          //logger.info("ObjectPathEditor:getItems:TYPE", path, splitPath, pathIndex, filterText, children[splitPath[pathIndex - 2]], ret);
        }
      } else {
        ret = ['organizations']
      }
    }
    return ret
  }

  /**
   * Get current URI
   * @return current state of path selected
   */
  getPathText = () => {
    return this.state.path ? this.state.path + '/' + this.state.editText : this.props.startObjectPath + '/'
  }

  /**
   * Start path editing when focus set to the input
   * @return
   */
  onEditPath = async () => {
    // Set new state and focus.
    let state = await this.getState(this.getPathText(), true, true)

    //logger.info("ObjectPathEditor:onEditPath", state);
    this.setState(state)
  }

  /**
   * Close path editing when we done with path selection
   * @return
   */
  onListClose = async () => {
    // Set new state and focus.
    let state = await this.getState(this.getPathText(), false)

    //logger.info("ObjectPathEditor:onListClose", state);
    this.setState(state)
  }

  /**
   * Change of path from editing
   * @return
   */
  onChangePath = async (event) => {
    let path = event.target.value
    //logger.info("ObjectPathEditor:onChangePath", event.target, path, this.state.editText);
    let state = await this.getState(path)

    //logger.info("ObjectPathEditor:onChangePath:CHANGE", event, path, state);
    this.setState(state)
  }

  /**
   * Select specific item in list of item and update path
   * @return
   */
  onSelectItem = async (item) => {
    // Set new path base on selected item and return focus to editor
    let path = this.state.path + '/' + this.itemFormat(item) + '/'
    let state = await this.getState(path)

    //logger.info("ObjectPathEditor:onSelectItem", item, path, state);

    this.setState(state, () => {
      // Report selected item
      if (this.props.onSelect && state.pathType.length === 0) this.props.onSelect(item, item.type)

      //setTimeout(()=>{this.inputRef.current.focus();}, 200);
    })
  }

  /**
   * Try to update path and list of relevant items when key presed
   * @return
   */
  keyPress = async (event) => {
    let code = null

    if (event.keyCode !== undefined) {
      code = event.keyCode
    }

    // //logger.info("ObjectEditPath:keyPress", this.state.path, this.state.pathIndex, this.state.editText);

    // //logger.info("keyPress", code);
    if (code === 8) {
      // BACKSPACE
      let path = this.state.path
      let pathIndex = this.state.pathIndex

      const editLength = this.state.editText.length
      const splitPath = path.substring(1).split('/')
      //logger.info("ObjectPathEditor:keyPress:BACKSPACE", { path, pathIndex, editLength, splitPath }, this.state, this.props);

      // We remove simbol by simbol.
      if (pathIndex > 0) {
        if (editLength === 0) {
          let prevIndex = pathIndex - 1
          let prevType = splitPath[prevIndex]
          let prevPath = path.slice(0, path.length - prevType.length - 1)

          // We on update of path
          if (pathIndex & 1 && this.props.onSelect) {
            // We update object type
            this.setState(await this.getState(prevPath + '/'))
            //logger.info("ObjectPathEditor:keyPress:BACKSPACE-TYPE", { path, pathIndex, editLength, splitPath, prevIndex, prevType, prevPath }, this.state, this.props);

            if (this.props.onSelect) {
              // Report un-selected item
              this.props.onSelect({ _path: prevPath }, prevType)
            }
          } else {
            // We updating name of object
            //this.setState(await this.getState(path));
            //logger.info("ObjectPathEditor:keyPress:BACKSPACE-NAME", { path, pathIndex, editLength, splitPath, prevIndex, prevType, prevPath }, this.state, this.props);
            return
          }
        } else {
          // This is normal path update give it onChangeEdit to handle
          //this.setState(await this.getState(path + '/' + this.state.editText.slice(0, editLength - 1)));
          //logger.info("ObjectPathEditor:keyPress:BACKSPACE-TEXT", { path, pathIndex, editLength, splitPath }, this.state.editText.slice(0, editLength - 1), this.state, this.props);
          return
        }
      }
      event.preventDefault()
    } else if (code === 13 || code === 191) {
      // ENTER or /
      // we try to finish search for current object
      let ret = this.state.editItems && this.state.editItems.length > 0 ? this.state.editItems[0] : null

      //logger.info("ObjectPathEditor:keyPress:ENTER", ret, this.state);

      if (ret) {
        this.onSelectItem(ret)
      }
      event.preventDefault()
    }
  }

  /**
   * Format item information to be represented in path
   * @return item as a string
   */
  itemFormat = (item, type = this.state.pathType) => {
    if (!type || type.length === 0) {
      return item
    } else if (type === 'versions') {
      return this.versionFormat(item.version)
    } else {
      return item.identity.name
    }
  }

  /**
   * Format version information to be represented in path
   * @return version as a string
   */
  versionFormat = (version) => {
    return !version ? '' : version.major + '.' + version.minor + '.' + version.revision
  }

  /**
   * Filter string for item
   * @return default filter string
   */
  defaultFilter = (item, type, query) => {
    //logger.info("ObjectItemSearch:defaultFilter", item, type, query);
    if (!query || query.length === 0) {
      return type !== 'issues' || item.status !== 'Closed'
    } else if (type === 'issues') {
      return (
        ((isInteger(query) && item.identity.name && item.identity.name.startsWith(query)) ||
          (item.identity.description && item.identity.description.toLowerCase().indexOf(query) !== -1)) &&
        item.status !== 'Closed'
      )
    } else if (type === 'versions') {
      return item.version && versionToStr(item.version).startsWith(query)
    } else {
      return item.identity.name && item.identity.name.toLowerCase().startsWith(query)
    }
  }

  /**
   * Sort string for item
   * @return default sorted list
   */
  defaultSort = (type, list) => {
    //logger.info("ObjectItemSearch:defaultSort", type, list);
    if (type === 'issues') {
      return list.sort((a, b) => issueSort(a, b))
    } else if (type === 'versions') {
      return list.sort((a, b) => versionSort(a.version, b.version))
    } else {
      return list
    }
  }

  render() {
    const res = this.state.editActive && !this.props.isClosed ? this.state.editItems : []
    const txt = this.getPathText()
    //logger.info("ObjectPathEditor:render", res, txt, this.props, this.state);

    return (
      <div className="ObjectPathEditor">
        <input
          className="TextInput"
          type="text"
          ref={this.inputRef}
          value={txt}
          onFocus={this.onEditPath.bind(this)}
          onBlur={() => {
            this.setState({ editFocused: false })
          }}
          onChange={this.onChangePath.bind(this)}
          onKeyDown={this.keyPress.bind(this)}
        />
        {res.length > 0 ? (
          <div className="ObjectPathEditor__Close" onClick={this.onListClose.bind(this)}>
            Close
          </div>
        ) : (
          ''
        )}
        {res.length > 0 ? (
          <div className="ObjectPathEditor__results">
            {res.map((result, i) => (
              <div
                className="ObjectPathEditor__result"
                onClick={() => {
                  this.onSelectItem(result)
                }}
                key={i}
              >
                {this.itemFormat(result)}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    )
  }
}

ObjectPathEditor.propTypes = {
  appState: PropTypes.object.isRequired,
  actions: PropTypes.object,
  filter: PropTypes.func,
  startObjectPath: PropTypes.string,
  targetObjectPath: PropTypes.string,
  isClosed: PropTypes.bool,
  onSelect: PropTypes.func,
  onAdjust: PropTypes.func
}

export default ObjectPathEditor
