import PropTypes from 'prop-types'

import React, { Component } from 'react'

import { TypedTable } from './TypedTable'

/**
 * EnumTable receives a dataset and can automatically set column widths and display a table
 * EnumTable is not editable
 */
export class EnumTable extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { dataset, colWidthsAuto, maxLineCount } = this.props
    let { colWidths } = this.props

    let sortedFields = dataset.structure.fields.slice()
    sortedFields.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0))

    if (!colWidthsAuto) sortedFields = sortedFields.filter((field) => colWidths[field.identity.name] > 0)
    else {
      colWidths = []
      sortedFields.map((field, index) => (colWidths[field.identity.name] = colWidthsAuto / sortedFields.length))
    }

    //console.log("DatasetTable colWidthsAuto="+colWidthsAuto, colWidths);

    return (
      <TypedTable
        columns={sortedFields.map((field, index) => {
          return {
            name: field.identity.name,
            displayName: field.identity.name + ' (' + field.usage + ')',
            type: { name: field.type.toLowerCase() },
            width: colWidths[field.identity.name]
          }
        })}
        data={dataset.data.records
          .slice()
          .sort((a, b) => (a.index < b.index ? -1 : a.index > b.index ? 1 : 0))
          .map((record) => {
            let ret = {}
            dataset.structure.fields.map((field, fieldIndex) => (ret[field.identity.name] = record.values[fieldIndex]))
            return ret
          })
          .slice(0, maxLineCount ? maxLineCount : dataset.data.records.length)}
        onChange={this.onChange}
      />
    )
  }
}

EnumTable.propTypes = {
  dataset: PropTypes.object.isRequired,
  colWidths: PropTypes.object, // key is field name
  colWidthsAuto: PropTypes.bool,
  filters: PropTypes.array,
  maxLineCount: PropTypes.number
}
