import PropTypes from 'prop-types'
import React from 'react'
import { nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './RuleDialog.scss'

export class RuleDialog extends EditorDialogBase {
  getObjectType() {
    return 'rule'
  }

  /**
   * Returns minimum height for dialog
   */
  getMinHeight() {
    return 300
  }

  /**
   * On initialize dialog load requered object
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/System/datasets/Rule',
      '/organizations/Apdax/systems/DifHub/applications/System/views/RuleDialog'
    )

    logger.info('RuleDialog:open', this.state, this.props)
  }

  /*
   * Check property before save
   * @param {object} rule - new state of object
   * @returns {string} - string with error text
   */
  checkInputErrors(rule) {
    let inputError = ''

    if (!rule.identity || !rule.identity.name) {
      inputError += 'Please specify rule name! '
    }
    if (!rule.type) {
      inputError += 'Please specify rule type! '
    }
    if (nonCSCompare(rule.type, 'Custom') && (!rule.configuration || !rule.configuration.reference)) {
      inputError += 'Please choose rule to configure! '
    }
    if (!nonCSCompare(rule.type, 'Custom') && !rule.expression) {
      inputError += 'Please specify rule expression! '
    }

    return inputError
  }

  renderEditor = (editState) => {
    // State and props.
    const rule = this.state.rule
    logger.info('RuleDialog:renderEditor', editState, rule, this.state, this.props)

    return (
      <div className="RuleDialog">
        {this.renderView(editState, (element) => {
          switch (element) {
            default:
              return null
            case 'configuration':
              return !rule.type || !nonCSCompare(rule.type, 'Custom') ? <div></div> : null
            case 'expression':
              return !rule.type || nonCSCompare(rule.type, 'Custom') ? <div></div> : null
          }
        })}
      </div>
    )
  }
  /*
  renderEditor = (editState) => {
    // State and props.
    const rule = this.state.rule;
    const type = rule.type;
    //logger.info("RuleDialog:renderEditor", editState, rule, type, this.state, this.props);

    return (
      <div className="RuleDialog">
        {this.renderEditorBase(editState, true, true, true, true)}

        {!type ? null : <div>
        <div className="RuleDialog__block_description clearfix">
          <label className="RuleDialog__label">Expression:</label>
          <div className="value_right">
            <EditableEntity
              data={rule.expression ? rule.expression : ''}
              dataType={{ name: "text_updatable" }}
              dataProps={{
                placeholder: "Enter expression",
                onChange: this.onExpressionChange,
                maxLineCount: 3
              }}
              isEditable={editState >= editableState.EDITABLE}
              inEditMode={editState > editableState.EDITABLE}
            />
          </div>
        </div>

        <div className="RuleDialog__block_description clearfix">
          <label className="RuleDialog__label">Message:</label>
          <div className="value_right">
            <EditableEntity
              data={rule.message ? rule.message : ''}
              dataType={{ name: "text_updatable" }}
              dataProps={{
                placeholder: "Enter message",
                onChange: this.onMessageChange,
                maxLineCount: 3
              }}
              isEditable={editState >= editableState.EDITABLE}
              inEditMode={editState > editableState.EDITABLE}
            />
          </div>
        </div></div>}

        {!type || !nonCSCompare(type, 'custom') ? null : <div>
        <div className="RuleDialog__block_text clearfix">
          <label className="RuleDialog__label">Configuration:</label>
          {!rule || !rule.configuration || !this.state.objectStructure ?
          <div className="value_right">
            <EditableEntity
              data={rule && rule.configuration && rule.configuration.identity ? rule.configuration.identity.name : ''}
              dataType={{ name: "enum", options: this.state.objectOptions || []}}
              dataProps={{
                placeholder: "Select custom rule",
                options: this.state.objectOptions || [],
                onChange: this.onConfigChange
              }}
              isEditable={editState >= editableState.EDITABLE}
              inEditMode={editState > editableState.EDITABLE}
            />
          </div> :
          <div className="value_right">
            <ObjectEditor
              appState={this.props.appState}
              actions={this.props.actions}
              header={(rule.configuration.identity && rule.configuration.identity.name) ? rule.configuration.identity.name : ''}
              modalTitle={'Configuration for rule ' + (rule.identity ? rule.identity.name : '')}
              noSchema
              isVisible
              isEditable={editState > editableState.EDITABLE ? editState : editableState.BROWSABLE}
              majorObject={this.props.majorObject}
              dataset={this.state.objectStructure}
              data={rule.configuration.value}
              onChange={this.onConfigPropertiesChange}
            />
          </div>}
        </div></div>}
      </div>
    )
  };
  */
}

RuleDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  rule: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
