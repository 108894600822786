import { createStore, compose } from 'redux'
import rootReducer from '../reducers'

export default function configureStore() {
  if (process.env.NODE_ENV === 'production') {
    return createStore(rootReducer)
  } else {
    let store = createStore(
      rootReducer,
      compose(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
    )

    return store
  }
}
