/* eslint-disable array-callback-return */
/**
 * Created by sashaberger on 04.08.2017.
 * Modified by sashaberger on 12.02.2019.
 *
 * Dialog to edit data of dataset. It allows edit data of one object.
 *
 */
import PropTypes from 'prop-types'
import React from 'react'
import { checkFieldPrivacy, deepCopy, editableState, nonCSCompare, strToStars } from '../../helpers/index'
import logger from '../../helpers/logger'
import propIconNew from '../../resources/images/properties_2x.png'
import propIconDark from '../../resources/images/properties_icon-blue-2x.png'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { DataEditorTable } from '../NewTable/DataEditorTable'
import './DataDialog.scss'

const PROP_HEIGHT = 50

export class DataDialogNew extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.rootRef = React.createRef()
  }

  getObjectType() {
    return 'data'
  }

  getMinHeight() {
    return 600
  }

  /**
   * Returns icon for button and dialog.
   */
  getIcon() {
    return this.props.dark ? propIconDark : propIconNew
  }

  /**
   * Returns list of adjustments of height based on object elements.
   * Name - is a name of parameter to use,
   * Value - adjustment for element.
   */
  getElementsHeight() {
    // We have lines of settings on settings array
    return { __line: PROP_HEIGHT }
  }

  /**
   * The method is called by EditorDialogBase when dialog is opened
   */
  onChangeEditable = () => {
    setTimeout(this.onToggleExpanded, 0)
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open() {
    //logger.info("DataDialog:open", this.state, this.props);

    this.setState({ objectStructure: this.props.dataset })
  }

  /**
   * If any value has been edited, indicate change
   */
  onValueChange = (newValue) => {
    // logger.info('DataDialog::onValueChange', newValue, this.state, this.props)

    if (newValue) {
      let data = deepCopy(this.state.data)
      data[newValue.name] = newValue.value

      this.setState({
        encryptedData: Object.assign({}, this.state.encryptedData || this.state.data, {
          [newValue.name]: newValue.encryptedValue
        })
      })
      this.Change(data)
    }
  }

  /**
   * Adjusts dialog height based on child DIV's real height
   */
  onToggleExpanded = () => {
    //logger.log("DataDialog::onToggleExpanded");
    setTimeout(() => {
      const myNode = this.rootRef.current
      if (!myNode) {
        return
      }

      const height = Math.max(myNode ? myNode.clientHeight : 0, this.getMinHeight())
      //console.log("DataDialog height", height);
      this.setState({ height: height })
    }, 0)
  }

  /**
   * Checks that all required properties are entered
   * @param {*} object
   */
  checkInputErrors(object, dataset = null) {
    let error = ''
    const fields = (dataset || this.props.dataset).structure.fields.filter((field) => field.identity)
    //logger.log("DataDialogNew::checkInputErrors", fields, object);

    fields
      .filter((field) => !field.optional)
      .map((field) => {
        //      logger.log("DataDialogNew::checkInputErrors", field, (!(field.identity.name in object)) ? "not found" : "found", object[field.identity.name] === '' ? "empty" : "not empty");
        if (!(field.identity.name in object) || object[field.identity.name] === '') {
          logger.log('Field is required')
          error = 'Field ' + field.identity.name + ' is required!'
        }
        if (nonCSCompare(field.type, 'structure')) {
          // check fields of child structure
          /*
        const childError = this.checkInputErrors(object[field.identity.name], childDataset);
        if (childError)
          error = childError;
        */
        }
      })

    //logger.log("error", error);

    return error
  }

  /**
   * Implementation of actual save event
   * @param {object} [object] actual object managed by dialog
   * @param {boolean} [closeDialog] Do we need to close dialog
   * @param {func} [onSent] callback after send
   */
  onSave(object, closeDialog, onSent) {
    if (this.props.onSave) {
      if (this.props.index === undefined || this.props.index === null || this.props.index === false) {
        this.props.onSave(this.state.encryptedData || this.state.data, closeDialog, onSent)
        return true // not to do anything here.
      }

      logger.log('DataDialogNew:save', this.state[this.state.objectType])
      let index =
        this.state.newIndex === undefined || this.state.newIndex === null || this.state.newIndex === false
          ? this.props.index
          : this.state.newIndex
      this.props.onSave(this.state.encryptedData || this.state.data, index, closeDialog, onSent)
      return true // not to do anything here.
    }
  }

  /**
   * Render actual body of dialog
   * @return
   */
  renderEditor = (editState) => {
    //logger.info("DataDialog:renderData", editState, this.state, this.props);
    return (
      <div className="DataDialog" ref={this.rootRef}>
        {this.props.index === undefined || this.props.index === null || this.props.index === false ? null : (
          <div className="row DataDialog__editIndexRow">
            <div className="col">Edit index:</div>
            <div className="col">
              <EditableEntity
                data={
                  this.state.newIndex === undefined || this.state.newIndex === null || this.state.newIndex === false
                    ? this.props.index
                    : this.state.newIndex
                }
                dataType={{ name: 'integer' }}
                isEditable
                inEditMode
                dataProps={{
                  onChange: (e) => {
                    this.setState({ newIndex: e.target.value })
                    if (this.state.change) this.state.change(true, '', '', '')
                  }
                }}
              />
            </div>
          </div>
        )}
        <DataEditorTable
          isEditable={this.props.isEditable > editableState.BROWSABLE}
          majorObject={this.props.majorObject}
          colWidths={[32, 198, 216, 306]}
          fields={this.props.dataset.structure.fields.filter((field) => field.identity)}
          values={this.state.data}
          encryptedValues={this.state.encryptedData || this.state.data}
          onChange={this.onValueChange}
          onToggleExpanded={this.onToggleExpanded}
        />
      </div>
    )
  }

  /**
   * Render view of component when window is closed
   * @returns {*}
   */
  renderItem(s, i) {
    // render one property.
    const dataField = s

    let fieldName = dataField.identity ? dataField.identity.name : ''
    let fieldType = dataField.type ? dataField.type.toLowerCase() : 'string'
    let fieldValue = this.state.data[s.identity.name]

    if (nonCSCompare(fieldType, 'structure') || nonCSCompare(fieldType, 'reference')) {
      return null
    } else if (!fieldValue || fieldValue === '' || fieldValue === '{}' || fieldValue === '""') {
      return null
    } else if (nonCSCompare(fieldType, 'boolean')) {
      fieldValue = fieldValue ? 'True' : 'False'
    }

    //logger.info('DataDialog:renderData', i, dataField, fieldName, fieldValue, this.state, this.props)

    return (
      <div className="EditorDialogItem row" key={i}>
        <div className="EditorDialogItem__name col">{fieldName + ': '}</div>
        <div className="EditorDialogItem__value col">
          {checkFieldPrivacy(dataField) ? strToStars(fieldValue) : fieldValue}
        </div>
      </div>
    )
  }

  /**
   * Render view of component when window is closed and
   * it show only settings inside dialog
   */
  renderItems() {
    return this.getItemListVertical(this.props.dataset.structure.fields)
  }
}

DataDialogNew.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string, // Title of data dialog when data edited by it.
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  structureEditor: PropTypes.bool, // for Edit Parameters: Data Dialog will hide root level structure and expand children
  majorObject: PropTypes.object, // Major object we handle data for
  dataset: PropTypes.object, // Dataset object which specify structure of data we introduce
  data: PropTypes.array, // Data of the dataset we are editing
  index: PropTypes.number, // Index of data record for list of data elements
  onClose: PropTypes.func,
  onSave: PropTypes.func // replace sending dataset with custom function
}
