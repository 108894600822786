/**
 * Created by sashab on 11.01.20.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { isArray } from 'lodash'

import { clearLocalObject, nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './PasteDialog.scss'

const getObjectName = (newObject) => {
  if (isArray(newObject)) {
    // from view
    return newObject[0].element?.identity.name
  } else {
    return newObject && newObject.identity
      ? newObject.identity.name
      : newObject && newObject.field && newObject.field.identity
      ? newObject.field.identity.name
      : ''
  }
}

export class PasteDialog extends EditorDialogBase {
  getObjectType() {
    return 'pasteParams'
  }

  /**
   * Returns minimum height for dialog
   */
  getMinHeight() {
    return 400
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/Difhub/applications/Schema/datasets/pasteConfiguration',
      '/organizations/Apdax/systems/Difhub/applications/Schema/views/PasteDialog'
    )

    logger.info('PasteDialog:open', this.state, this.props)
  }

  /**
   * Returns initial object for dialog
   */
  getInitial(props) {
    logger.info('EditorDialogBase:getInitial', props, this.state, this.props)
    const newObject = JSON.parse(localStorage.clipboard)

    props = props || this.props
    return {
      objectName: getObjectName(newObject)
    }
  }
  /**
   * Execute activity when message send to service
   * @param {boolean} close - flag to close dialog
   * @param {*} error - return from service if any.
   * @return
   */
  onSent = (close, error) => {
    //logger.info("PasteDialog:onSent", close, error);
    // This is special case to update dialog when multiple sends
    if (close === 'progress') {
      this.setState({ pasteParams: error })
      return
    }

    // Report complete of save with error or not
    if (this.state.change) {
      this.state.change(error)
    }

    if (!error) {
      logger.info('EditorDialogBase:onSent:uploadContent', close, this.state.uploadContent, this.state, this.props)
      if (this.state.uploadContent && this.state.uploadContent.length > 0) {
        // Upload content for current dialog.
        this.uploadContent(this.state.uploadContent, this.props.contentUri).then(
          () => {
            this.setState(
              {
                uploadContent: []
              },
              () => {
                if (close) {
                  setTimeout(() => {
                    this.close()
                  }, 500)
                }
                this.setState(clearLocalObject(this.state[this.state.objectType], this.props.version))
              }
            )
          },
          (error) => {
            if (this.state.change) {
              this.state.change(error)
            }
          }
        )
      } else {
        // Change state before close.
        if (close) {
          setTimeout(() => {
            this.close()
          }, 500)
        }
        this.setState(clearLocalObject(this.state[this.state.objectType], this.props.version))
      }
    }
  }

  checkInputErrors(pasteParams) {
    let inputError = ''
    let examplesPath
    const condition = this.props.majorObject
    if (!pasteParams.renameObject && !pasteParams.objectName) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify new name!'
    }
    if (condition && pasteParams.objectName) {
      switch (this.props.modalTitle) {
        case 'Paste layout':
          const checkLayoutName = condition.layouts.find((e) => nonCSCompare(e.identity.name, pasteParams.objectName))
          if (checkLayoutName) {
            inputError += 'Layout name already exist'
          }
          break
        case 'Paste field':
          const checkFieldName = condition.structure.fields.find((e) =>
            nonCSCompare(e.identity.name, pasteParams.objectName)
          )
          if (checkFieldName) {
            inputError += 'Field name already exist'
          }
          break
        case 'Paste example':
          condition.data ? (examplesPath = condition.data.examples) : (examplesPath = condition.examples)
          const checkExampleName = examplesPath.find((e) => nonCSCompare(e.identity.name, pasteParams.objectName))
          if (checkExampleName) {
            inputError += 'Example name already exist'
          }
          break
        case 'Paste instance':
          const checkDatasetName = condition.instances.find((e) =>
            nonCSCompare(e.identity.name, pasteParams.objectName)
          )
          if (checkDatasetName) {
            inputError += 'Instance name already exist'
          }
          break
        case 'Paste activity':
          const checkActivityName = condition.activities.find((e) =>
            nonCSCompare(e.identity.name, pasteParams.objectName)
          )
          if (checkActivityName) {
            inputError += 'Activity name already exist'
          }
          break
        case 'Paste operation':
          const checkOperationName = condition.operations.find((e) =>
            nonCSCompare(e.identity.name, pasteParams.objectName)
          )
          if (checkOperationName) {
            inputError += 'Operation name already exist'
          }
          break
        case 'Paste parameter':
          const checkParameterName = condition.parameters.find((e) =>
            nonCSCompare(e.field.identity.name, pasteParams.objectName)
          )
          if (checkParameterName) {
            inputError += 'Parameter name already exist'
          }
          break
        case 'Paste response':
          const checkResponseName = condition.responses.find((e) =>
            nonCSCompare(e.field.identity.name, pasteParams.objectName)
          )
          if (checkResponseName) {
            inputError += 'Response name already exist'
          }
          break
        default:
          break
      }
    }
    return inputError
  }

  /**
   * Render content of dataset editor dialog
   */
  renderEditor = (editState) => {
    let isApp = this.props.majorObject && this.props.majorObject.type === 'application'
    let isSys =
      this.props.majorObject && this.props.majorObject.type === 'system' && localStorage.clipboardType === 'application'
    logger.info('PasteDialog::renderEditor', this.state, this.props)

    return (
      <div className="PasteDialog1">
        {this.renderView(editState, (element) => {
          switch (element) {
            default:
              return null
            case 'renameObject':
              return isApp ? null : <div></div>
            case 'restoreObject':
              return isApp ? null : <div></div>
            case 'pasteObjects':
              return isSys ? null : <div></div>
          }
        })}
      </div>
    )
  }
}

PasteDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  dataset: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
