/**
 * Created by  on 12.05.17.
 */

import PropTypes from 'prop-types'
import React from 'react'
import { getFullUri, objectNameFromPathByType } from '../../helpers'
import { getOrganizationDataset } from '../../helpers/api'
import {
  createDataObject,
  createEmptyObject,
  deepCopy,
  editableState,
  getSpecFieldOrdinal,
  getSpecRecords,
  nonCSCompare
} from '../../helpers/index'
import logger from '../../helpers/logger'
import { ObjectEditor } from '../DataDialog/ObjectEditor'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './ExampleDialog.scss'

export class ExampleDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      editingData: false,
      formats: [],
      types: {},
      typeJson: -1,
      typeSystem: -1,
      jsonMode: false
    })
  }

  getObjectType() {
    return 'example'
  }

  componentDidMount() {
    // Load platforms list from organization we are in.
    const organization = objectNameFromPathByType(getFullUri(this.props.majorObject), 'organizations')
    getOrganizationDataset('FormatType', organization).then((result) => {
      this.setState({
        formats: getSpecRecords(result).map((row) => row.values[0])
      })
    })
    getOrganizationDataset('Type', organization).then((result) => {
      this.setState({
        types: result,
        typeSystem: getSpecFieldOrdinal(result, 'Type'),
        typeJson: getSpecFieldOrdinal(result, 'RESTfull-API')
      })
    })
  }

  /**
   * Get initial value of parameter
   * @param {object} [props]
   * @returns {*}
   */
  getInitial = (props) => {
    props = props || this.props
    return props.example && props.example !== true ? props.example : this.createEmptyExample(props)
  }

  /**
   * Create empty object for our object
   * @param {object} [props]
   * @returns {*}
   */
  createEmptyExample = (props) => {
    let example = createEmptyObject('example')
    let data = createDataObject('', props.majorObject)
    example.format = 'JSON'
    example.text = data ? JSON.stringify(data) : JSON.stringify(createEmptyObject('', props.majorObject))
    logger.info('ExampleDialog:createEmptyExample', props, example)
    return example
  }

  /**
   * Change state of example
   * @param {object} example - new state of object
   * @returns {boolean} true - it is updated, false - no changes
   */
  ChangeExample = (example) => {
    return this.Change(example)
  }

  onNameChange = (event) => {
    let data = event.target.value
    //console.log("ExampleDialog:onNameChange", data, this.state.example);

    let example = deepCopy(this.state.example)
    if (example.identity) {
      example.identity.name = data
    } else {
      example.identity = { name: data }
    }

    this.ChangeExample(example)
  }

  onDescriptionChange = (event) => {
    let data = event.target.value
    //console.log("ExampleDialog:onDescriptionChange", data, this.state.example);

    let example = deepCopy(this.state.example)
    if (example.identity) {
      example.identity.description = data
    } else {
      example.identity = { description: data }
    }

    this.ChangeExample(example)
  }

  onTextChange = (obj) => {
    let example = deepCopy(this.state.example)

    example.text = typeof obj === 'object' ? JSON.stringify(obj) : obj

    this.ChangeExample(example)
  }

  setFormat = (event) => {
    let data = event.target.value
    //console.log("ExampleDialog:setFormat", data, this.state.example);

    let example = deepCopy(this.state.example)
    example.format = data

    this.ChangeExample(example)
  }

  /**
   * Object from text of JSON
   * @param {string} [text] text to format
   */
  formatJSON(text) {
    try {
      const obj = JSON.parse(text)
      if (!obj) {
        return null
      }
      return obj
    } catch (e) {
      return null
    }
  }

  checkInputErrors(example) {
    let inputError = ''
    if (!example.identity || !example.identity.name || example.identity.name.length === 0) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify example name!'
    }
    if (
      (!this.props.example || this.props.example.identity.name !== example.identity.name) &&
      this.props.majorObject.data &&
      this.props.majorObject.data.examples &&
      this.props.majorObject.data.examples.findIndex(
        (ex) => ex.identity && nonCSCompare(ex.identity.name, example.identity.name)
      ) > -1
    ) {
      inputError +=
        (inputError.length > 0 ? '\n' : '') + 'Example with name "' + example.identity.name + '" already exist!'
    }
    if (!example.format || example.format.length === 0) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify example format!'
    }
    if (!this.formatJSON(example.text)) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'JSON of example is incorrect. Enter valid JSON.'
    }
    return inputError
  }

  // when JSON nodes in dialog are expanded, it should check height of inner components and expand itself if needed
  checkDialogHeight = () => {
    //console.log("ExampleDialog::checkDialogHeight");
    if (document.querySelector('.ExampleDialog').offsetHeight > 700)
      this.onAdjust(0, document.querySelector('.ExampleDialog').offsetHeight - this.state.height + 100)
  }

  /**
   * Render content of example editor dialog
   */
  renderEditor = (editState) => {
    // get formats names
    const formats = this.state.formats.map((format, index) => {
      return { id: index, label: format, value: format }
    })
    const format = formats.find((l) => l.value === this.state.example.format)

    return (
      <div className="ExampleDialog" onClick={this.checkDialogHeight}>
        <div className="ExampleDialog__block clearfix">
          <label className="ExampleDialog__label">Name:</label>
          <div className="value_right">
            <EditableEntity
              data={this.state.example.identity ? this.state.example.identity.name : ''}
              dataType={{ name: 'string' }}
              dataProps={{
                placeholder: 'Enter name',
                onChange: this.onNameChange
              }}
              isEditable
              inEditMode={editState > editableState.EDITABLE}
            />
          </div>
        </div>
        <div className="ExampleDialog__block_description clearfix">
          <label className="ExampleDialog__label">Description:</label>
          <div className="value_right">
            <EditableEntity
              data={this.state.example.identity ? this.state.example.identity.description : ''}
              dataType={{ name: 'text_updatable' }}
              dataProps={{
                placeholder: 'Enter description',
                onChange: this.onDescriptionChange
              }}
              isEditable
              inEditMode={editState > editableState.EDITABLE}
            />
          </div>
        </div>
        <div className="ExampleDialog__block_platform clearfix">
          <label className="ExampleDialog__label">Format:</label>
          <div className="value_right">
            <EditableEntity
              data={this.state.example.format}
              dataType={{ name: 'enum', options: formats }}
              dataProps={Object.assign({
                onChange: this.setFormat.bind(this),
                activeOptions: format ? format.id : null,
                placeholder: 'Choose format',
                hideCheckBox: true,
                multi: false
              })}
              isEditable
              inEditMode={editState > editableState.EDITABLE}
            />
          </div>
        </div>
        <ObjectEditor
          header="Example"
          isEditable={editState}
          majorObject={this.props.majorObject}
          dataset={this.props.majorObject}
          data={this.state.example.text || {}}
          onChange={this.onTextChange}
          noSchema
          isVisible
        />
      </div>
    )
  }
}

ExampleDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  example: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
