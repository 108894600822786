/**
 * Created by sashab on 15.05.2020.
 *
 * Component to manage input of pipeline activity.
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import logger from '../../helpers/logger'
import {
  deepCopy,
  nonCSCompare,
  referenceSort,
  findByIdentity,
  displayReferenceUri,
  storageDatasetUri,
  storageSubscriptionUri,
  editableState,
  datasetToEnumOptions,
  listOfMinorObjectsToEnumOptions
} from '../../helpers/index'
import { getObjectNew, getRequestFromPath } from '../../helpers/api'
import md from '../../helpers/md'

import './InputsDialog.scss'
import './InputDialog.scss'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'

export class InputDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    //logger.info("InputDialog", this.state, this.props);
  }

  getObjectType() {
    return 'input'
  }

  /**
   * Returns minimum height for dialog
   */
  getMinHeight() {
    return 500
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/Pipeline/datasets/Input',
      '/organizations/Apdax/systems/DifHub/applications/Pipeline/views/InputDialog'
    )

    logger.info('InputDialog:open', this.state, this.props)

    // Let's get types and remove operations from transform activities
    let objectTypes = this.getEntityMetadata(
      'dataset',
      '/organizations/Apdax/systems/DifHub/applications/Pipeline/datasets/InputMode'
    )
    this.setState({
      objectTypes: nonCSCompare(this.props.activity.type, 'operation')
        ? datasetToEnumOptions(objectTypes)
        : datasetToEnumOptions(objectTypes).filter((op) => !nonCSCompare(op.label, 'operation'))
    })

    // Lets load common options for edit.
    if (this.props.isEditable > editableState.BROWSABLE) {
      this.loadStructure(this.state.input)
      //this.onAdjust(0, 565);
    }
  }

  /**
   * Load object stracture
   */
  loadStructure = (input) => {
    // Load current object
    logger.info('InputDialog:loadStructure', input, this.state, this.props)

    // Generate options for output and operation case
    if (input && nonCSCompare(input.inputMode, 'output')) {
      let options = this.state.objectOptions
      if (this.props.majorObject && this.props.majorObject.activities) {
        let activities = this.props.activity
          ? this.props.majorObject.activities.filter((act) => act.identity.name !== this.props.activity.identity.name)
          : this.props.majorObject.activities

        options = listOfMinorObjectsToEnumOptions(activities).sort(referenceSort)
        this.setState({
          objectOptions: options
        })
      }
      if (input.component && input.component.reference && options) {
        let activity = options.find((act) => act.value === input.component.reference)
        this.setState({
          objectStructure: activity.object
        })
      }
    }

    // Generate options for operation case. Here always only one operation to select.
    if (input && nonCSCompare(input.inputMode, 'operation')) {
      let options = this.state.objectOptions
      if (this.props.majorObject && this.props.majorObject.activities) {
        let activities = this.props.activity
          ? this.props.majorObject.activities.filter((act) => act.identity.name !== this.props.activity.identity.name)
          : this.props.majorObject.activities

        options = listOfMinorObjectsToEnumOptions(activities).sort(referenceSort)
        this.setState({
          objectOptions: options
        })
      }
      if (input.component && input.component.reference && options) {
        let activity = options.find((act) => act.value === input.component.reference)
        let name = ''
        if (activity && activity.object && activity.object.operation && activity.object.operation.components) {
          name = activity.object.operation.components[0]
        }
        //logger.info("InputDialog:loadStructure:OPERATION", activity, input, this.state, this.props);
        this.setState({
          objectStructure: { operation: [{ identity: { name: name } }] }
        })
      }
    }
  }

  /**
   * Get initial value of parameter
   * @param {object} [props]
   * @returns {*}
   */
  getInitial(props) {
    props = props || this.props
    //logger.info("InputDialog:getInitialInput", props);
    if (!props.input) {
      return {
        identity: {},
        inputMode: nonCSCompare(this.props.activity.type, 'operation') ? 'Operation' : 'Dataset',
        component: {}
      } //createEmptyObject('input');
    }
    return deepCopy(props.input || {})
  }

  checkInputErrors = (input) => {
    let inputError = ''
    if (!input.identity || !input.identity.name) {
      inputError += 'Please specify input name!'
    } else if (
      this.props.activity.inputs &&
      !this.props.input &&
      this.props.activity.inputs.find((el) => nonCSCompare(el.identity ? el.identity.name : '', input.identity.name))
    ) {
      inputError += 'Input name must be unique inside activity!'
    } else if (!input || !input.inputMode) {
      inputError += 'Please select input mode!'
    } else if (!input.component || !input.component.reference) {
      inputError += 'Please select input!'
    } else if (!nonCSCompare(input.inputMode, 'operation') && !input.component.components) {
      inputError += 'Please select input components!'
    }
    return inputError
  }

  /**
   * Validate value of changed field
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @return true - change approved
   */
  onValidateChange = (element, field, value, input, option) => {
    logger.info('InputDialog:onValidateChange', { element, field, value, input, option }, this.state, this.props)

    if (element === 'mode' && value) {
      input['component'] = { reference: '', subscription: '', components: [] }
      this.loadStructure(input)
    } else if (element === 'layout' && value) {
      // This is change of reference of major object we can delete components
      if (input.component && input.component.components && option && option.object.object) {
        delete input.component.components
      }
      // We need to convert it to array.
      if (input.component && input.component.layouts && !Array.isArray(input.component.layouts)) {
        input.component.layouts = [input.component.layouts]
      }
    } else if (element === 'output' && value) {
      if (input.component && input.component.components && !Array.isArray(input.component.components)) {
        input.component.components = [input.component.components]
      }
      this.loadStructure(input)
    } else if (element === 'operation' && value) {
      if (input.component && input.component.components && !Array.isArray(input.component.components)) {
        input.component.components = [input.component.components]
      }
      this.loadStructure(input)
    } else if (element === 'fields' && value) {
      if (input.component && input.component.components && !Array.isArray(input.component.components)) {
        input.component.components = [input.component.components]
      }
    }
    return input
  }

  renderEditor = (editState) => {
    let input = this.state.input
    let isDataset = input.inputMode && nonCSCompare(input.inputMode, 'Dataset')
    let isOutput = input.inputMode && nonCSCompare(input.inputMode, 'Output')
    let isOperation = input.inputMode && nonCSCompare(input.inputMode, 'Operation')
    //logger.info("InputDialog:renderEditor", { editState, input, isDataset, isOutput }, this.state, this.props);

    return (
      <div className="InputDialog" onClick={() => this.onCheckDialogHeight('.InputDialog')}>
        {this.renderView(editState, (element) => {
          switch (element) {
            default:
              return null
            case 'layout':
              return !isDataset ? <div></div> : null
            case 'output':
              return !isOutput ? <div></div> : null
            case 'operation':
              return !isOperation ? <div></div> : null
            case 'filter':
              return !isDataset ? <div></div> : null
            case 'start':
              return !isDataset ? <div></div> : null
            case 'end':
              return !isDataset ? <div></div> : null
            case 'fields':
              return !isDataset ? <div></div> : null
            //case 'operation':      return !input.type || !nonCSCompare(input.type, 'Output') ? (<div></div>) : null;
          }
        })}
      </div>
    )
  }
}

InputDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  activity: PropTypes.object,
  input: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
