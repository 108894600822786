import React, { Component, RefObject } from 'react'
import calculateSize from 'calculate-size'

import { ModalWindow } from '../ModalWindow/ModalWindow'
import '../../styles/MultilineText/MultilineText.scss'
import { nl2br } from '../../helpers/nl2br'

type MultilineTextProps = {
  text: string
  maxLineCount: number
  defaultLineHeight: number
  className: string
  title: string
  hideMore: boolean
}

type MultilineTextState = {
  style: any
  isOpen: boolean
  maxHeight: number
  containerId: any
  width: number
}

export class MultilineText extends Component<MultilineTextProps, MultilineTextState> {
  rootRef: RefObject<any>
  canvas: any

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      maxHeight: 0,
      containerId: null,
      style: null,
      width: 300
    }
    this.rootRef = React.createRef()
  }

  componentDidMount() {
    setTimeout(() => {
      this.checkHeight()
    }, 0)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // //console.log("MultilineText new props", newProps);
    this.setState({ isOpen: false, maxHeight: 0 }, function () {
      //this.forceUpdate();
      // @ts-ignore
      this.checkHeight()
    })
  }

  componentDidUpdate() {
    //this.checkHeight();
  }

  checkHeight() {
    // //console.log("checkHeight()", this.state);
    try {
      let myNode = this.rootRef.current
      if (!myNode) return
      myNode = myNode.getElementsByTagName('div')[0]
      if (!myNode) return
      this.setState({
        style: getComputedStyle(myNode),
        width: myNode.parentNode.parentNode.parentNode.offsetWidth || 300
      })
    } catch (e) {
      ////console.log('exception in checkHeight');
    }
  }

  open(event) {
    this.setState({ isOpen: true })
    event.stopPropagation()
  }

  close() {
    this.setState({ isOpen: false })
  }

  getTextWidth(text, font) {
    // re-use canvas object for better performance
    const canvas = this.canvas || (this.canvas = document.createElement('canvas'))
    const context = canvas.getContext('2d')
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
  }

  render() {
    /*
    let style = {maxHeight: "none", overflow: "hidden"};
    if (this.state.maxHeight !== 0) {
      style.maxHeight = this.state.maxHeight;
      style["textAlignLast"] = "justify";
    }
    */
    const options = !this.state.style
      ? {}
      : {
          font: this.state.style['font-family'],
          fontSize: this.state.style.fontSize,
          width: this.state.width + 'px'
        }
    const size = calculateSize(this.props.text, options)
    let maxHeight =
      size.height > (this.props.maxLineCount || 2) * this.props.defaultLineHeight
        ? (this.props.maxLineCount || 2) * this.props.defaultLineHeight
        : 0
    const realWidth = this.getTextWidth(
      this.props.text,
      !this.state.style ? '' : this.state.style.fontSize + ' ' + this.state.style['font-family']
    )
    if (realWidth > this.state.width) {
      maxHeight = (this.props.maxLineCount || 2) * this.props.defaultLineHeight
    }
    let style: any = { maxHeight: 'none', overflow: 'hidden' }

    // This is control last alignment and more button. Let's make hideMore=true property disable it.
    if (maxHeight !== 0 && !this.props.hideMore) {
      style.maxHeight = maxHeight + 'px'
      style.maxWidth = this.state.width + 'px'
      style['textAlignLast'] = 'justify'
    }

    //  if (this.props.text && this.props.text.substr(0,1) === '1')
    //logger.log("MultilineText", (this.props.text || '').substr(0, 30), size, this.state.style, this.state.width, maxHeight, options, style);

    return (
      <div className="MultilineText" ref={this.rootRef}>
        <div className={'MultilineText__text ' + this.props.className} style={style}>
          {nl2br(this.props.text)}
        </div>
        <div
          className={
            'MultilineText__more ' + (maxHeight > 0 && !this.props.hideMore ? 'MultilineText__more__visible' : '')
          }
          onClick={this.open.bind(this)}
        ></div>
        <ModalWindow
          className="MultilineText__window"
          isOpen={this.state.isOpen}
          header={this.props.title || 'Full text'}
          onClose={this.close.bind(this)}
          canClose
          footer={
            <button className="btn_big" onClick={this.close.bind(this)}>
              Close
            </button>
          }
        >
          <div className="MultilineText__full">{nl2br(this.props.text)}</div>
        </ModalWindow>
      </div>
    )
  }
}
