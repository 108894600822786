/* eslint-disable array-callback-return */
import PropTypes from 'prop-types'
import React from 'react'
import { getObjectListNew, getRequestFromPath } from '../../helpers/api'
import {
  deepCopy,
  editableState,
  getFullUri,
  getVersionStatus,
  listOfMajorVersionsToEnumOptions,
  nonCSCompare,
  pathByType,
  versionSort,
  versionToStr
} from '../../helpers/index'
import logger from '../../helpers/logger'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { ApplicationReferenceList } from './ApplicationReferenceList'
import './PackageDialog.scss'

const STRUCTURE_HEIGHT = 60
const APP_HEIGHT = 70

export class PackageDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state.applicationReference = null
    this.state.selectedApplicationReference = null
    this.state.applications = []

    //logger.info("PackageDialog", props);
  }

  getObjectType() {
    return 'package'
  }

  getElementsHeight() {
    // We have apps list
    return {
      source: STRUCTURE_HEIGHT,
      metadata: STRUCTURE_HEIGHT,
      __group: { applications: APP_HEIGHT }
    }
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifGate/applications/System/datasets/Package',
      '/organizations/Apdax/systems/DifGate/applications/System/views/PackageDialog'
    )

    // Lets load common options for edit.
    if (this.props.isEditable > editableState.EDITABLE) {
      //this.loadDataOptions();
    }

    //logger.info("PackageDialog:open", this.state, this.props);
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  loadDataOptions = () => {
    // Check if we already have it
    if (this.state.applications && this.state.applications.length > 0) {
      return
    }

    const pathSplit = this.state.path.substring(1).split('/')
    let sysPath = pathByType(pathSplit, 'systems')

    this.onReport('Request applications from service...')

    getObjectListNew(getRequestFromPath(sysPath).applications, 'application').then(
      (apps) => {
        //logger.info("PackageDialog:loadDataOptions", sysPath, this.state, this.props);
        let newApps = deepCopy(apps)
        let promises = []

        let currApps = this.state.package ? this.state.package.applications || [] : []
        let oldApps = this.props.packageOld ? this.props.packageOld.applications || [] : []

        newApps.map((app) => {
          let currApp = currApps.find((item) => nonCSCompare(item.identity.name, app.identity.name))
          if (!currApp) {
            return
          }

          promises.push(
            getObjectListNew(getRequestFromPath(getFullUri(app)).settings.versions, 'setting').then(
              (result) => {
                // Version options
                let oldApp = oldApps.find((item) => nonCSCompare(item.identity.name, app.identity.name))
                let oldVersion = oldApp ? oldApp.version : { major: 0, minor: 0, revision: 0 }

                let options = listOfMajorVersionsToEnumOptions(
                  result,
                  (obj) => versionSort(obj.version, oldVersion) < 0 && getVersionStatus(obj) === 'approved',
                  versionSort
                )
                //logger.info("PackageDialog:loadDataOptions:options", app.identity.name, { result, options, app, newApps }, this.state, this.props);

                app['versionOptions'] = options
                return true
              },
              (error) => {
                logger.error('PackageDialog:open:VERSIONS:ERROR', app, error)
                return false
              }
            )
          )
        })

        //logger.info("PackageDialog:loadDataOptions", sysPath, promises, this.state, this.props);

        Promise.all(promises).then(
          () => {
            //logger.info("PackageDialog:loadDataOptions:APPS", sysPath, newApps, this.state, this.props);
            this.setState({ applications: newApps })
            this.onReport()
          },
          (error) => {
            logger.error('PackageDialog:open:VERSIONS:ERROR', error)
            this.onReport(error)
          }
        )
      },
      (error) => {
        logger.error('PackageDialog:open:APPS:ERROR', error)
        this.props.onReport(error)
      }
    )

    //logger.info("PackageDialog:loadDataOptions", sysPath, this.state, this.props);
  }

  /**
   * Override the method in child dialogs, to handle changeEditable() event: opening and closing the dialog
   */
  onChangeEditable = (isEditable) => {
    // Lets load common options for edit.
    if (isEditable > editableState.EDITABLE) {
      this.loadDataOptions()
    }
  }

  checkInputErrors(instance) {
    let inputError = ''
    const condition = this.props.majorObject.packages
    if (!instance.identity || !instance.identity.name) {
      inputError += 'Please specify package name! '
    } else if (condition && instance.identity && instance.identity.name) {
      const checkPackageName = condition.find(
        (e) =>
          nonCSCompare(e.identity.name, instance.identity.name) && !nonCSCompare(e.identity.id, instance.identity.id)
      )
      if (checkPackageName) {
        inputError += 'Element name already exist'
      }
    }
    return inputError
  }

  /**
   * Validate value of changed field
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @return true - change approved
   */
  onValidateChange = (element, field, value, package0, option) => {
    //logger.info("PackageDialog:onValidateChange", { element, field, value, package0, option }, this.state, this.props);
    return package0
  }

  /**
   * Update application array of the package
   * @param {array} [apps] new array of applucatiobns
   * @param {boolean} [closeDialog] close dialog if opened
   * @param {func} [onSent] call back on update complete
   * @return true - change approved
   */
  updateApplications = (apps, closeDialog, onSent) => {
    let newPackage = deepCopy(this.state.package)
    newPackage.applications = apps
    logger.log('PackageDialog:updateApplications', apps, this.state, this.props)

    this.Change(newPackage)
    if (onSent) onSent(closeDialog)
  }

  renderEditor = (editState) => {
    // State and props.
    const packageData = this.state.package
    const packageOld = this.props.packageOld

    const data =
      packageOld && this.props.deploymentOld
        ? 'Previous version: ' + versionToStr(this.props.deploymentOld.version)
        : 'New package: no previous version found'
    //logger.info("PackageDialog:renderEditor", {editState, packageData, packageOld}, this.state, this.props);

    return (
      <div className="PackageDialog" onClick={() => this.onCheckDialogHeight('.PackageDialog')}>
        {this.renderView(editState, (element) => {
          switch (element) {
            default:
              return null
            case 'applications': {
              //logger.info("PackageDialog:renderEditor:APPLICATIONS", { element, viewElement }, this.state, this.props);

              return (
                <div>
                  {this.renderElement(editableState.BROWSABLE, element, null, data)}
                  <div className="PackageDialog_borderGray"></div>
                  <ApplicationReferenceList
                    className="ApplicationReferenceList"
                    appState={this.props.appState}
                    actions={this.props.actions}
                    isVisible
                    isEditable={editState >= editableState.EDITABLE ? editState : editableState.BROWSABLE}
                    majorObject={this.props.majorObject}
                    oldDeployment={this.props.deploymentOld}
                    type={'application'}
                    items={packageData.applications}
                    base={this.state.applications}
                    oldItems={packageOld ? packageOld.applications : []}
                    onReport={this.onReport}
                    onAdjust={this.onAdjust}
                    onClose={() => this.setState({ activeResource: false })}
                    onSave={this.updateApplications}
                  />
                </div>
              )
            }
          }
        })}
      </div>
    )
  }
}

PackageDialog.propTypes = {
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  package: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  packageOld: PropTypes.object
}
