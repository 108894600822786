/**
 * Created by kascode on 08.04.16.
 */
import * as types from '../constants/ActionTypes'

export function setError(payload) {
  return { type: types.SET_ERROR, payload }
}

export function clearAppState() {
  return { type: types.CLEAR_APP_STATE }
}

export function clearError() {
  return { type: types.CLEAR_ERROR }
}

export type GlobalActions = {
  setError
  clearAppState
  clearError
}
