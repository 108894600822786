/* eslint-disable no-restricted-globals */
import PropTypes from 'prop-types'
import React from 'react'
import { deepCopy, editableState, fieldState, nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import { DatasetViewer } from '../DataDialog/DatasetViewer'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { LinesDrawer } from './LinesDrawer'
import { MapDialogFooter } from './MapDialogFooter.tsx'
import './MapDialogNew.scss'

const OUTPUT_HEIGHT = 50

export class MapDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      datasets: {}, // loaded child datasets. key=reference, value=dataset
      selectedInputPath: false,
      selectedOutputPath: false,
      fieldsState: {}, // Allows set hidden or selected fields in dataset
      clickOnLine: {}, // Determine which input and output are mapped with this line
      pageOutputData: {}, // Keep data about pages presented for outputs.
      pageInputData: {}, // Keep data about pages presented for inputs.
      lastUpdateTime: null // Id for update line render when coordination need update
    })
  }

  getObjectType() {
    return 'outputs'
  }

  getIconHtml() {
    return <div className="EditorDialogItem__buttonImg MapDialog__icon" />
  }

  /**
   * Returns list of adjustments of height based on object elements.
   * Name - is a name of parameter to use,
   * Value - adjustment for element.
   */
  getElementsHeight() {
    // We have lines of settings on settings array
    return { __line: OUTPUT_HEIGHT }
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Let's check if we need to adjust size and show scroll.
    setTimeout(() => {
      this.onCheckDialogHeight('.MapDialog')
    }, 7000)
  }

  /**
   * Process click on dataset field. For output fields, changes selected field and create empty Map for Output if needed
   * For input fields, adds input to Map or deletes
   * @param editState
   * @param mode mode for click (input/output)
   * @param object - actual object for mode.
   * @param path
   * @param field
   * @param cell
   */
  onFieldClick = (editState, mode, object, path, field, cell) => {
    // logger.info("MapDialog:onFieldClick", { editState, mode, object, path, field, cell }, this.state, this.props);
    // Ignore click on expend button as it is cell 0.
    if (cell === 0) {
      return
    }

    if (mode === 'output') {
      path = path.substr(7)
      //logger.info("MapDialog:onFieldClick:OUTPUT", path, this.state.selectedOutputPath, this.state.selectedInputPath);
      if (path === this.state.selectedOutputPath) {
        // Unselect output field
        this.setState({ selectedOutputPath: false, selectedInputPath: false })
      } else {
        // Select new output field path.
        this.setState({ selectedOutputPath: path, selectedInputPath: false })

        // calculate number of incoming arrows
        let arrowCount = 0
        let outputName = path.substr(0, path.indexOf('/'))
        let outputField = path.substring(path.indexOf('/') + 1)
        //console.log("outputName", outputName, "outputField", outputField, "act",  this.state);
        this.state.outputs.map((output) => {
          // find output we updating map for
          if (nonCSCompare(output.identity.name, outputName)) {
            let foundOutput = (output.map || []).find((item) => nonCSCompare(item.output, outputField))
            //console.log("foundOutput", foundOutput);
            if (foundOutput) {
              if (output.map && output.map.length > 0) {
                arrowCount++
                //console.log("for output", outputName, "found arrow", output.map);
              }
            }
          }
        })

        // if output has no arrows, display help (Click input to create mapping)
        if (document.getElementById('MapDialog_selectInput')) {
          //console.log("for output path", path, "arrow count", arrowCount);
          if (editState > editableState.EDITABLE) {
            let baseElement = document.getElementById('MapDialog_row')
            let base = baseElement.getBoundingClientRect()
            document.getElementById('MapDialog_selectInput').style.left =
              event.target.getBoundingClientRect().left - base.left - 155 + 'px'
            document.getElementById('MapDialog_selectInput').style.top =
              event.target.getBoundingClientRect().top - base.top + 63 + 'px'
            document.getElementById('MapDialog_selectInput').style.display = 'block'
          } else {
            document.getElementById('MapDialog_selectInput').style.display = 'none'
            // if output has arrows, hide help
          }
        }
      }
    } else if (mode === 'input') {
      path = path.substr(6)
      //console.log("MapDialog:fieldClick:INPUT", path, this.state.selectedOutputPath, this.state.selectedInputPath);
      document.getElementById('MapDialog_selectInput').style.display = 'none'
      if (path === this.state.selectedInputPath) {
        // Unselect output field
        this.setState({ selectedInputPath: false })
      } else {
        // Select new output field path.
        this.setState({ selectedInputPath: path })
      }

      if (!(editState > editableState.EDITABLE)) {
        return
      }

      if (this.state.selectedOutputPath) {
        // find Output Dataset name
        let outputName = this.state.selectedOutputPath.substr(0, this.state.selectedOutputPath.indexOf('/'))
        let outputField = this.state.selectedOutputPath.substring(this.state.selectedOutputPath.indexOf('/') + 1)

        let newOutputs = deepCopy(this.state.outputs)

        newOutputs.map((output) => {
          // find output we updating map for
          if (nonCSCompare(output.identity.name, outputName)) {
            let foundOutput = (output.map || []).find((item) => nonCSCompare(item.output, outputField))
            if (!foundOutput) {
              // Need to introduce map for output or add field to it.
              if (output.map && output.map.length > 0) {
                output.map.push({
                  output: outputField,
                  inputs: [],
                  formula: ''
                })
              } else {
                output.map = [{ output: outputField, inputs: [], formula: '' }]
              }

              // Look again.
              foundOutput = (output.map || []).find((item) => nonCSCompare(item.output, outputField))
            }

            // Add or remove input
            if (foundOutput.inputs.indexOf(path) === -1) {
              foundOutput.inputs.push(path)
            } else {
              foundOutput.inputs = foundOutput.inputs.filter((input) => input !== path)
            }
          }
        })

        //console.log("MapDialog:fieldClick:ACTIVITY", newOutputs, this.state);
        this.Change(newOutputs)
      }
    }
  }

  /**
   * Handles arrow click, finds corresponding output element and passes the click event
   * @param editState
   * @param output
   * @param map
   * @param input
   * @param mode
   */
  onArrowClick = (editState, output, map, input, mode) => {
    let path = mode + '_' + output.identity.name + '/' + map.output
    logger.info('MapDialog:onArrowClick', { editState, output, map, input, path }, this.state, this.props)

    // find field's page in StructureTable
    this.setState({ clickOnLine: { input: input, output: output, map: map } })

    // set fieldsState for singe fields, other make unselected
    this.setState((prev) => {
      return {
        ...prev,
        fieldsState: {
          [output.identity.name]: {
            [map.output]: fieldState.SELECTED
          }
        }
      }
    })
    // this need for highlight line, witch we clicked
    this.onFieldClick(editState, 'output', output, path)
  }

  onFooterBtnClick = (path, mode) => {
    const outputName = this.state.selectedOutputPath.substr(0, this.state.selectedOutputPath.indexOf('/'))
    const outputField = this.state.selectedOutputPath.substring(this.state.selectedOutputPath.indexOf('/') + 1)
    const selectedOutput = (this.state.outputs || []).find((oo) => nonCSCompare(oo.identity.name, outputName))

    let nextOutput = 0

    selectedOutput.map.forEach((item, idx) => {
      if (item.output === outputField) {
        nextOutput = idx
      }
    })

    let nextItem = null

    if (mode === 'prev') {
      nextItem = nextOutput === 0 ? selectedOutput.map.length - 1 : nextOutput - 1
    } else if (mode === 'next') {
      nextItem = nextOutput === selectedOutput.map.length - 1 ? 0 : nextOutput + 1
    }

    const newSelectedMap = selectedOutput.map[nextItem]

    // set fieldsState for singe fields, other make unselected
    this.setState((prev) => {
      return {
        ...prev,
        fieldsState: {
          [selectedOutput.identity.name]: {
            [newSelectedMap.output]: fieldState.SELECTED
          }
        }
      }
    })

    const pathForClick = 'output_' + selectedOutput.identity.name + '/' + newSelectedMap.output
    this.onFieldClick(this.props.editState, 'output', selectedOutput, pathForClick)
  }

  onArrowOver = (editState, output, map, input) => {}

  onArrowOut = (editState, output, map, input) => {}

  updateLinesRender = (withTimeout, timeout) => {
    if (withTimeout) {
      // We need to redraw as only after page redraw we will have right coordinates for lines.
      setTimeout(() => {
        this.setState((prev) => {
          return {
            ...prev,
            lastUpdateTime: new Date()
          }
        })
      }, timeout)
    } else {
      this.setState((prev) => {
        return {
          ...prev,
          lastUpdateTime: new Date()
        }
      })
    }
  }

  /**
   * Report change of page in the content of dataset viewer
   * @returns {*}
   * @param mode
   * @param object
   * @param pageIndex
   * @param divId
   * @param height
   */
  onPageExpand = (mode, object, pageIndex, divId, height) => {
    //logger.info("MapDialog:onPageExpand", { mode, object, pageIndex, divId }, this.state, this.props);
    this.updateLinesRender(true, 0)
  }

  /**
   * Report change of page in the content of dataset viewer
   * @returns {*}
   * @param mode
   * @param object
   * @param pageIndex
   * @param divId
   * @param height
   */
  onPageLoad = (mode, object, pageIndex, divId, height) => {
    //logger.info("MapDialog:onPageLoad", { mode, object, pageIndex, divId }, this.state, this.props);
    this.updateLinesRender(true, 0)
  }

  /**
   * Report change of page position inside dataset viewer
   * @returns {*}
   * @param mode
   * @param object
   * @param pageIndex
   * @param divId
   * @param height
   */
  onPageScroll = (mode, object, pageIndex, divId, height) => {
    //logger.info("MapDialog:onPageScroll", { mode, object, pageIndex, divId }, this.state, this.props);
    this.updateLinesRender(false)
  }

  /**
   * Report change of page in the content of dataset viewer
   * @returns {*}
   * @param mode
   * @param object
   * @param pageIndex
   * @param visiblePages
   * @param beforePages
   * @param afterPages
   * @param divId
   */
  onPageChange = (mode, object, pageIndex, visiblePages, beforePages, afterPages, divId) => {
    //logger.info("MapDialog:onPageChange", { mode, object, pageIndex, visiblePages, beforePages, afterPages, divId }, this.state, this.props);

    // Change can be in input or output, we need to change current state to
    // know how to redraw lines between inputs and outputs
    if (mode === 'output') {
      // We remove click and save new output state
      this.setState({
        clickOnLine: {},
        pageOutputData: Object.assign({}, this.state.pageOutputData, {
          [object.identity.name]: {
            pageIndex,
            visiblePages,
            beforePages,
            afterPages
          }
        })
      })
    } else {
      // We remove click and save new input state
      this.setState({
        clickOnLine: {},
        pageInputData: Object.assign({}, this.state.pageInputData, {
          [object.identity.name]: {
            pageIndex,
            visiblePages,
            beforePages,
            afterPages
          }
        })
      })
    }

    // We need to redraw as only after page redraw we will have right coordinates for lines.
    this.updateLinesRender(10)

    // Let's update size as we get new dataset in.
    setTimeout(() => {
      this.onCheckDialogHeight('.MapDialog')
    }, 1000)
  }

  /**
   * Returns count of displayed lines (field mappings)
   * @returns {number}
   */
  lineCount = () => {
    const outputs = this.state.outputs || []
    let count = 0
    outputs.map((output) => {
      if (!output.map) {
        return null
      }

      return output.map.map((map) => {
        return (map.inputs || []).map((input) => {
          count++
        })
      })
    })
    return count
  }

  /**
   * Clear all maps in activity (this is the only way to remove map records for deleted inputs or outputs)
   */
  onClearMap = () => {
    let newOutputs = this.state.outputs.map((output) => Object.assign({}, output, { map: [] }))
    this.Change(newOutputs)
  }

  getMapForOutput = (output) => {
    const fields = output.component.components
    let map = []
    //logger.log("getMapForOutput", output);
    fields.map((field) => {
      //logger.log("getMapForOutput field", field);
      this.props.activity.inputs.map((input) => {
        //logger.log("getMapForOutput input", input, input.identity.name);
        input.component.components.map((inputField) => {
          //logger.log("getMapForOutput inputField", inputField);
          if (inputField === field) {
            logger.log('map push', {
              inputs: [input.identity.name + '/' + inputField],
              output: field,
              formula: ''
            })
            map.push({
              inputs: [input.identity.name + '/' + inputField],
              output: field,
              formula: ''
            })
          }
        })
      })
    })
    //logger.log("getMapForOutput return map", map);
    return map
  }

  onAutoMap = () => {
    let newOutputs = this.state.outputs.map((output) =>
      Object.assign({}, output, { map: this.getMapForOutput(output) })
    )
    logger.log('onAutoMap newOutputs', newOutputs)
    this.Change(newOutputs)
  }

  /**
   * Handles editing of formula and saves it in the activity object in local state
   * @param e
   * @returns {null}
   */
  onEditFormula = (e) => {
    let data = e.target.value

    if (!this.state.selectedOutputPath) {
      return null
    }

    let newOutputs = deepCopy(this.state.outputs)

    let outputName = this.state.selectedOutputPath.substr(0, this.state.selectedOutputPath.indexOf('/'))
    let outputField = this.state.selectedOutputPath.substring(this.state.selectedOutputPath.indexOf('/') + 1)
    let selectedOutput = newOutputs.find((oo) => nonCSCompare(oo.identity.name, outputName))
    let selectedMap = (selectedOutput.map || []).find((mm) => mm.output === outputField)

    // Update formula
    selectedMap.formula = data
    logger.log('MapDialog:onEditFormula', data, newOutputs)

    this.Change(newOutputs)
  }

  /**
   * Displays dataset (StructureTable with onClick handler), and requests dataset if it is not found
   * @param editState
   * @param reference
   * @param components
   * @param mode
   * @param object
   * @param level
   * @param path
   * @returns {*}
   */
  renderDataset = (editState, reference, components, mode, object, level = 0, path = '') => {
    let presentField = ''
    // We are rendering dataset we have click on as output.
    if (
      mode === 'output' &&
      this.state.clickOnLine.output &&
      nonCSCompare(object.identity.name, this.state.clickOnLine.output.identity.name)
    ) {
      presentField = this.state.clickOnLine.map ? this.state.clickOnLine.map.output : ''
    } else if (this.state.clickOnLine.input) {
      let input = this.state.clickOnLine.input.split('/')
      if (input[0] && nonCSCompare(object.identity.name, input[0])) {
        presentField = input[1]
      }
    }
    //logger.info("MapDialog:renderDataset", { editState, reference, components, mode, object, level, path, presentField }, this.state, this.props);

    return (
      <DatasetViewer
        path={path}
        mode={mode}
        level={level}
        object={object}
        dataset={this.props.dataset}
        editState={editState}
        reference={reference}
        components={components}
        selectMode={'singleselect'}
        fieldsState={this.state.fieldsState[object.identity.name] || {}}
        presentField={presentField}
        onAdjust={() => {
          /* this.onAdjust - No adjustment here for now as it creates loops*/
        }}
        onFieldClick={this.onFieldClick}
        onPageLoad={this.onPageLoad}
        onPageChange={this.onPageChange}
        onPageScroll={this.onPageScroll}
        onPageExpand={this.onPageExpand}
      />
    )
  }

  /**
   * Renders input dataaset (using renderDataset) method for from output of previous activity
   * @param editState
   * @param reference
   * @param components
   * @param mode
   * @param input
   * @returns {*}
   */
  renderOutput = (editState, reference, components, mode, input) => {
    // parent activity
    let outputParent = this.props.activity
    if (!outputParent.outputs) {
      return null
    }

    // find specified output in that activity
    let output = outputParent.outputs.find((output) => output.identity.name === components[0])
    return this.renderDataset(editState, output.component.reference, output.component.components, 'input', input)
  }

  /**
   * Renders SVG lines between input & output fields
   * @returns {*}
   */
  renderLines = (editState) => {
    return (
      <LinesDrawer
        editState={editState}
        outputs={this.state.outputs}
        lastUpdateTime={this.state.lastUpdateTime}
        pageInputData={this.state.pageInputData}
        pageOutputData={this.state.pageOutputData}
        selectedOutputPath={this.state.selectedOutputPath}
        selectedInputPath={this.state.selectedInputPath}
        onClick={this.onArrowClick}
        onMouseOver={this.onArrowOver}
        onMouseOut={this.onArrowOut}
      />
    )
  }

  /**
   * Renders formula editor and list of input fields for selected output
   * @returns {*}
   */
  renderFooter = (editState) => {
    //console.log("MapDialog:renderFormula", this.state.selectedOutputPath, this.state);
    if (!this.state.selectedOutputPath) {
      return null
    }

    //let selectedOutput = this.state.outputs.filter(output => output.map && output.map[0].output === this.state.selectedOutputPath)[0];

    // find Map for selected Output Field
    let outputName = this.state.selectedOutputPath.substr(0, this.state.selectedOutputPath.indexOf('/'))
    let outputField = this.state.selectedOutputPath.substring(this.state.selectedOutputPath.indexOf('/') + 1)
    let selectedOutput = (this.state.outputs || []).find((oo) => nonCSCompare(oo.identity.name, outputName))
    if (!selectedOutput) {
      return null
    }

    let selectedMap = (selectedOutput.map || []).find((mm) => mm.output === outputField)

    //logger.log("MapDialog:renderFormula:MAP", selectedMap);
    if (!selectedMap) {
      return null
    }

    let inputs = selectedMap.inputs || []
    let output = selectedMap.output || []
    let formula = selectedMap.formula && selectedMap.formula.length > 0 ? selectedMap.formula : ''
    //logger.log("MapDialog:renderFormula:FORMULA", output, inputs, formula);

    let tableNames = []
    let fieldNames = []

    inputs.map((input) => {
      if (input.indexOf('/') !== -1) {
        let inputParts = input.split('/')
        tableNames.push(inputParts[0])
        fieldNames.push(inputParts[1])
      } else {
        fieldNames.push(input)
      }
    })
    fieldNames.push(output)

    tableNames = tableNames.filter((name, index) => tableNames.indexOf(name) === index) //unique
    fieldNames = fieldNames.filter((name, index) => fieldNames.indexOf(name) === index)

    //console.log("FormulaEditor tableNames", tableNames, "fieldNames", fieldNames);

    return (
      <MapDialogFooter
        path={this.state.selectedOutputPath}
        inputs={inputs}
        output={output}
        formula={formula}
        tableNames={tableNames}
        fieldNames={fieldNames}
        onEditFormula={this.onEditFormula}
        onBtnClick={this.onFooterBtnClick}
      />
    )
  }

  renderEditor = (editState) => {
    const { activity } = this.props
    //logger.info("MapDialog:renderEditor", { editState, activity }, this.props, this.state);

    return (
      <div className="MapDialog SQLDesigner__root" onClick={() => this.onCheckDialogHeight('.MapDialog')}>
        <div className="MapDialog__clearButtonRow">
          <div className="MapDialog__colHeader">Transformation</div>
        </div>

        <div className="row">
          <div className="col-xs-5">
            <div className="MapDialog__colHeader">Input datasets</div>
          </div>
          <div className="col-xs-2">
            <div className="MapDialog__mapButtons">
              {editState > editableState.EDITABLE ? (
                <div className="MapDialog__clearbutton btn_blue" onClick={this.onClearMap}>
                  Clear map
                </div>
              ) : null}
              {editState > editableState.EDITABLE ? (
                <div className="MapDialog__clearbutton btn_blue" onClick={this.onAutoMap}>
                  Auto map
                </div>
              ) : null}
              {this.lineCount() === 0 && editState > editableState.EDITABLE ? (
                <div className="MapDialog__noLines">
                  Click output field and then click input fields to create mapping. Click input again to clear mapping
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-xs-5">
            <div className="MapDialog__colHeader">Output datasets</div>
          </div>
        </div>

        {this.renderLines(editState)}

        <div className="row" id="MapDialog_row">
          <div id="MapDialog_selectInput">
            Select input
            <svg>
              <circle cx={34} cy={10} fill="#238A96" r={2} />
              <path stroke="#238A96" fill="none" d="M 34 10 L 5 10 L 9 5 " style={{ display: 'block' }} />
              ,
              <path stroke="#238A96" fill="none" d="M 5 10 L 9 15 " style={{ display: 'block' }} />,
            </svg>
          </div>
          <div className="col-xs-5">
            {(activity.inputs || [])
              .filter((input) => input.inputMode === 'Dataset')
              .map((input) =>
                this.renderDataset(editState, input.component.reference, input.component.components, 'input', input)
              )}
            {(activity.inputs || [])
              .filter((input) => input.inputMode === 'Output')
              .map((input) =>
                this.renderOutput(editState, input.component.reference, input.component.components, 'input', input)
              )}
          </div>
          <div className="col-xs-2" />
          <div className="col-xs-5">
            {(activity.outputs || [])
              .filter((output) => output.outputMode === 'Dataset')
              .map((output) =>
                this.renderDataset(editState, output.component.reference, output.component.components, 'output', output)
              )}
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render view of component when window is closed
   * @returns {*}
   */
  renderItem(output, i) {
    // render one rule.
    return output.map
      ? output.map.map((field, j) =>
          field.output ? (
            <div className="ActivityDialog row" key={i + 1 + '.' + (j + 1)}>
              <div className="ActivityDialog__map__name col">{output.identity.name + '/' + field.output}</div>
              <div className="ActivityDialog__map__value col">
                {'= ' + (field.formula ? field.formula + '[' + field.inputs + ']' : field.inputs)}
              </div>
            </div>
          ) : null
        )
      : null
  }
}

MapDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  outputs: PropTypes.object,
  activity: PropTypes.object,
  datasets: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
