import PropTypes from 'prop-types'
import React from 'react'
import { editableState, nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './DocDialog.scss'

export class DocDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      documentChanged: false
    })
  }

  getObjectType() {
    return 'doc'
  }

  /**
   * Init dialog height
   * @param props - set of initialization properties
   */
  InitHeight(props) {
    props = props || this.props

    // Calculate current height of window
    let height = this.getMinHeight()

    // We add diffirence of height based on elements we use for height
    height += this.state && this.state.doc && this.state.doc.type && nonCSCompare(this.state.doc.type, 'link') ? 100 : 0
    logger.info(
      'EditorDialogBase:InitHeight',
      height,
      props,
      this.getMinHeight(),
      window.innerHeight,
      this.getObjectType(),
      this.state
    )

    return height
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/System/datasets/Document',
      '/organizations/Apdax/systems/DifHub/applications/System/views/DocDialog'
    )

    logger.info('DocDialog:open', this.state, this.props)
  }

  /*
   * Check property before save
   * @param {object} settings - new state of object
   * @returns {string} - string with error text
   */
  checkInputErrors(doc) {
    let inputError = ''
    //logger.info("DocDialog:checkInputErrors", doc, this.state, this.props);

    if (!doc || !doc.identity || !doc.identity.name) {
      inputError += 'Please specify document name! '
    } else if (!doc || !doc.uri) {
      inputError += 'Please provide document URI by loading or referencing! '
    }

    return inputError
  }

  /**
   * Validate value of changed field
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @return true - change approved
   */
  onValidateChange = (element, field, value, doc, option) => {
    logger.info('DocDialog:onValidateChange', { element, field, value, doc, option }, this.state, this.props)
    if (field === 'type' && value) {
      if (doc.identity && doc.identity.name && doc.uri && doc.uri.endsWith(doc.identity.name)) {
        doc.identity.name = ''
      }
      doc.uri = ''
    } else if (field === 'uri' && value) {
      if ((!doc.identity || !doc.identity.name) && option && option.fileName) {
        if (!doc.identity) doc.identity = { name: option.fileName }
        else doc.identity.name = option.fileName
      }
      if (
        (!doc.identity || !doc.identity.description || doc.identity.description.startsWith('$')) &&
        option &&
        option.identity &&
        option.identity.description
      ) {
        if (!doc.identity) doc.identity = { description: option.identity.description }
        else doc.identity.description = option.identity.description
      }
    }
    return doc
  }

  renderEditor = (editState) => {
    const doc = this.state.doc
    if (!doc) {
      return null
    }
    //logger.info("DocDialog:renderEditor", editState, doc, this.state, this.props);

    return (
      <div className="DocDialog" onClick={() => this.onCheckDialogHeight('.DocDialog')}>
        {this.renderView(editState, (element) => {
          switch (element) {
            default:
              return null
            case 'uri':
              return !doc.type || !nonCSCompare(doc.type, 'Link')
                ? this.renderElement(editableState.BROWSABLE, element)
                : null
            case 'content':
              return doc.type && !nonCSCompare(doc.type, 'Link')
                ? this.renderElement(editState, element)
                : this.renderElement(editableState.BROWSABLE, element)
          }
        })}
      </div>
    )
  }
}

DocDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  doc: PropTypes.object,
  content: PropTypes.array, // List of content to upload.
  contentUri: PropTypes.string, // URI for content
  onContentChange: PropTypes.func, // Uploaded content change for dialog
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
