import React, { Component, RefObject } from 'react'
import { dequal } from 'dequal'
import moment from 'moment'
import { toNumber, uniq } from 'lodash'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'

import { IssueCard } from '../IssueCard/IssueCard'
import { Issue } from '../Issue/Issue'
import { ScrollArea } from '../ModalWindow/ScrollArea'
import { KascodeSelect } from '../KascodeSelect/KascodeSelect'
import { IssueMessage } from '../IssueMessage/IssueMessage'
import { idAPI, getObjectNew } from '../../helpers/api'
import {
  issuePropList,
  issuePriorityLabel,
  issueResolutionIcon,
  getIssueAbsoluteDeadline,
  objectNameFromPathByType,
  getStatusIcon,
  resolutions
} from '../../helpers'

import { Loader } from '../Loader/Loader'
import { IssueNewMessage } from '../IssueNewMessage/IssueNewMessage'
import { disableScroll, enableScroll } from '../../helpers/scroll'

import './IssuesView.scss'
import logger from '../../helpers/logger'

import searchPic from '../../resources/images/search-2x.png'
import arrowDown from '../../resources/images/arrow-down-close-2x.png'
import arrowUp from '../../resources/images/arrow-up-close-2x.png'
import resolutionGray from '../../resources/images/alarm-grey-2x.png'
import { issuePriorityIcon } from '../../helpers/helperComponents'
import { Actions } from '../../actions'
let updateTimer: any = null // issue cards are automatically refreshes every minute
let filterOpenTime = 0 // prevent close of More Filters by blur

function messageSort(a, b) {
  return a.time < b.time ? 1 : -1
}

type IssuesViewProps = {
  issues: any[]
  allIssues: any[]
  loadingMessages: boolean
  loadIssue: Function
  onTabChange: Function
  loadMessages: Function
  handleFiltersChange: Function
  filters: any
  userState: any
  users: any[]
  messageTabs: any[]
  isFixed: boolean
  recentlyEditedIssues: number[]
  isHashIssue: Function
  filterIssue: Function
  messages: any[]
  checkObject: Function
  onSendMessage: Function
  onIssueEdit: any
  onConfirm: Function
  objectRoles: any
  actions: Actions
  currentUserRole: any
  pageChange: Function
  createIssue: any
  object: any
  loadingIssues: boolean
  pageNumber: number

  handleIssueCollapseButton: any
}

type IssuesViewState = {
  filtersVisible: boolean
  oneIssueLoaded: boolean
  messageTabs: any[]
  selectedIssueId: number
  selectedMessageIndex: number
  messageScrollVisible: boolean
  messageIndexFromHash: boolean
  openAllMessages: boolean
  loadingAssignedUser: boolean
  users: any[]
  buttonsDisabled: any[]
  fullScreenMessages: boolean
  currentAssignedUser: any
  messageUsers: any[]
  current: any
  delayedIssueId?: number
  deadlineMouseOver: any
}

export class IssuesView extends Component<IssuesViewProps, IssuesViewState> {
  _loadingMessagesFor: number | boolean
  _loadingMessages: boolean
  rootRef: RefObject<any>
  issueRef: RefObject<any>
  scrollRef: RefObject<any>
  messageScrollRef: RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      filtersVisible: false,
      oneIssueLoaded: false,
      messageTabs: [],
      selectedIssueId: parseInt(window.location.hash.replace('#issues/', '')) || 0,
      selectedMessageIndex: 0,
      messageScrollVisible: false,
      messageIndexFromHash: false,
      openAllMessages: false,
      loadingAssignedUser: false,
      users: [],
      buttonsDisabled: [],
      fullScreenMessages: false,
      currentAssignedUser: null,
      messageUsers: (props.users || []).map((user, index) => {
        return { id: user.user.id, display: user.profile.alias }
      }),
      current: undefined,
      delayedIssueId: undefined,
      deadlineMouseOver: undefined
    }

    this._loadingMessagesFor = false
    this._loadingMessages = false

    this.rootRef = React.createRef()
    this.issueRef = React.createRef()
    this.scrollRef = React.createRef()
    this.messageScrollRef = React.createRef()
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      messageUsers: (newProps.users || []).map((user, index) => {
        return { id: user.user.id, display: user.profile.alias }
      })
    })
  }

  componentDidMount() {
    if (updateTimer) {
      clearInterval(updateTimer)
    }
    updateTimer = setInterval(() => {
      //console.log('update1')
      this.forceUpdate()
    }, 60000)
    this.updateMessageScroll()
  }

  /**
   * loads users for current issue and updates currentAssignedUser
   * @param {*} nextProps
   * @param {*} nextState
   */
  componentDidUpdate(nextProps, nextState) {
    if (this.props.issues.length !== nextProps.issues.length && !this.props.loadingMessages) {
      const hash = window.location.hash.replace('#issues/', '')
      const split = hash.split('/')
      const name = split[0]
      const messageNumber = parseInt(split[split.length - 1]) - 1
      const messageIndex = messageNumber && split.length !== 1 ? messageNumber : 0
      if (name) {
        const issue = this.props.issues.find((issue) => issue.identity.name === name)
        // TODO: useRef https://www.robinwieruch.de/react-scroll-to-item
        const card = this.rootRef.current.getElementsByClassName('IssueCard__' + name)
        card[0]?.scrollIntoView()
        if (issue) {
          this.setState(
            {
              selectedIssueId: issue.identity.id,
              selectedMessageIndex: messageIndex,
              openAllMessages: false,
              messageIndexFromHash: false
            },
            () => {
              this.props.loadIssue(issue.identity.id, name)
            }
          )
        }
      }
    }

    if (nextState.current !== this.state.current) {
      return
    }
    const current = this.getCurrentIssue(nextProps)
    // console.log("componentDidUpdate current:", deepCopy(current));
    if (!current && this.state.current) {
      this.setState({ current: current })
      return
    }
    if (
      !this.state.current ||
      this.state.current.identity.id !== current.identity.id ||
      this.state.currentAssignedUser.id !== current.assigned.id
    ) {
      // console.log("componentDidUpdate current changed");
      this.setState({
        current: current,
        currentAssignedUser: {}
      })
      if (current && current.assigned && !this.state.loadingAssignedUser) {
        if (
          this.state.users
            .filter((user) => user)
            .map((user) => user.id)
            .indexOf(current.assigned.id) === -1
        ) {
          //console.log("IssuesView didUpdate, not found",current.assigned.id,'in',this.state.users);
          this.setState({ loadingAssignedUser: true }, () => {
            //idAPI.users(current.assigned.id).get().then((user)=> {

            getObjectNew(idAPI.users(current.assigned.id), 'user').then((user) => {
              const userData = {
                id: user.identity.id,
                name: user.profile && user.profile.alias ? user.profile.alias : user.firstname + ' ' + user.lastname
              }
              this.setState({
                loadingAssignedUser: false,
                currentAssignedUser: userData,
                users: this.state.users.concat(userData)
              })
            })
          })
        } else {
          this.setState({
            currentAssignedUser: this.state.users.reduce((p, c) => (c.id === current.assigned.id ? c : p), null)
          })
        }

        // console.log("will request user", current.assigned.id);
      }
    }
  }

  /**
   * selects new active issue by click, by hash
   * @param {*} newProps
   * @param {*} newState
   */
  UNSAFE_componentWillUpdate(newProps, newState) {
    // console.log('IssuesView willReceiveProps:', this._loadingMessages)
    const newCurrentIssue = this.getCurrentIssue(
      newProps,
      newState.selectedIssueId ? newState.selectedIssueId : this.state.selectedIssueId
    )

    let issueId = 0
    let updatedState: any = {}
    let setStateCallback

    // if prev current issue is reloading (the moment when GetObject already has called CREATE_MAJOR_OBJECT for issue
    // and yet has not received it and put back in appState
    // we should not change current issue, because previous selected issue is just about to come back
    // so we detect this situation and do not switch to other issue if previous issue is being loaded

    //logger.debug("IssuesView:componentWillUpdate", this._loadingMessages, newCurrentIssue ? newCurrentIssue.identity.id : null, this._loadingMessagesFor  );

    if (!this._loadingMessages || (newCurrentIssue && newCurrentIssue.identity.id !== this._loadingMessagesFor)) {
      if (
        this.props.allIssues.length === 0 &&
        newProps.allIssues.length > 0 &&
        window.location.hash.indexOf('#issues/') === -1
      ) {
        //logger.info("IssuesView::componentWillUpdate:cwu:1", newProps, this.state, this.props);

        if (newCurrentIssue) issueId = newCurrentIssue.identity.id
        this.updateMessageScroll()
      } else if (window.location.hash.indexOf('#issues/') !== -1 && !this.state.selectedIssueId) {
        //logger.info("IssuesView::componentWillUpdate:cwu:2", newProps, this.state, this.props);
        const hashName = window.location.hash.replace('#issues/', '')
        let messageIndex = -1
        if (window.location.hash.indexOf('/messages/') !== -1) {
          messageIndex = parseInt(window.location.hash.substr(window.location.hash.lastIndexOf('/') + 1))
        }

        // Find issue with name from hash
        let find: any = []

        if (hashName === 'approve') {
          find = [
            newProps.allIssues.reduce(
              (prev, issue) => (!prev || issue.identity.name > prev.identity.name ? issue : prev),
              null
            )
          ].filter((issue) => issue)
        } else {
          find = newProps.allIssues.filter((issue) => issue.identity.name === parseInt(hashName))
        }

        //console.log("issueFromHash", find);

        if (find.length > 0) {
          const hashId = find[0].identity.id
          updatedState = Object.assign(updatedState, {
            selectedIssueId: hashId,
            selectedMessageIndex: messageIndex,
            messageIndexFromHash: true
            //filters: Object.assign(this.props.filters, find[0].assigned.id !== localStorage.currentUserId ? {assigned: [true]} : {})
          })

          if (hashName === 'approve') {
            updatedState.selectedMessageIndex = 0 // expand the message that has Approve/Reject buttons
          }

          setStateCallback = () => {
            const card = this.rootRef.current.getElementsByClassName('IssueCard__' + find[0].identity.name)
            //console.log("found card with class IssueCard__"+find[0].identity.name, card);
            if (card.length > 0) {
              //card[0].scrollIntoView();     // scroll the card into view
              setTimeout(() => {
                //console.log("scrolling to page bottom");
                let footerHeight = 0
                let footers = document.getElementsByClassName('Footer') as HTMLCollectionOf<HTMLElement>
                if (footers.length) footerHeight = footers[0].offsetHeight

                let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

                window.scrollTo(0, document.body.scrollHeight - height - footerHeight)
              }, 200)

              setTimeout(() => {
                // console.log("will do onTabChange");
                this.props.onTabChange() // scroll the page so tabs are at the page top
              }, 100)
            }
          }

          issueId = hashId
        }
      } else if (
        newCurrentIssue &&
        (newCurrentIssue.identity.id !== this.state.selectedIssueId ||
          newCurrentIssue.identity.id === this.state.delayedIssueId)
      ) {
        //logger.info("IssuesView::componentWillUpdate:cwu:3", newProps, newCurrentIssue, this.state, this.props);
        updatedState = Object.assign(updatedState, {
          selectedIssueId: newCurrentIssue.identity.id
        })
        issueId = newCurrentIssue.identity.id
      }
    }

    if (
      issueId !== 0 &&
      (!this._loadingMessages || (newCurrentIssue && newCurrentIssue.identity.id !== this._loadingMessagesFor))
    ) {
      this._loadingMessages = true
      this._loadingMessagesFor = issueId

      const loadMessagesReturn = this.props.loadMessages(issueId)
      //logger.info("IssuesView::componentWillUpdate:cwu:4", { loadMessagesReturn, issueId, newProps, newCurrentIssue }, this.state, this.props);

      // if messages are still loading will receive undefined
      // no, undefined is returned if issue is not found (more not existing or hidden by filters)
      // and we should not block further message loading in this case

      // Set new state
      this.setState(updatedState, setStateCallback ? setStateCallback : undefined)

      if (typeof loadMessagesReturn === 'object') {
        loadMessagesReturn.then(
          (result) => {
            //logger.info("IssuesView::componentWillUpdate:LOADMESSAGES", { result, issueId, newProps, newCurrentIssue }, this.state, this.props);
            // Set we already loaded messages
            this.setState({ delayedIssueId: 0 }, () => {
              this._loadingMessages = false
              this._loadingMessagesFor = false
            })
          },
          (error) => {
            if (error === null) {
              // This is case when load was delayed as issues was not visibale.
              this.setState({ delayedIssueId: newCurrentIssue.identity.id }, () => {
                this._loadingMessages = false
                this._loadingMessagesFor = false
              })
            } else {
              // Set we already loaded messages
              this.setState({ delayedIssueId: 0 }, () => {
                this._loadingMessages = false
                this._loadingMessagesFor = false
              })
              logger.warn(
                'IssuesView::componentWillUpdate:LOADMESSAGES:ERROR',
                { error, newProps, newCurrentIssue },
                this.state,
                this.props
              )
            }
          }
        )
      }
    }

    // when there is only one issue, the following statement throws mysterious AddEventListener error
    // if (newProps.issues.length <= 1)
    //   return;

    // if (newCurrentIssue && newCurrentIssue.identity.id !== this.props.messagesLoadedFor) {
    //   this.props.loadMessages(newCurrentIssue.identity.id);
    // }

    // there is scenario when an Issue is only one visible
    // and buttons in Issue's messages do not show until it is refreshed
    // for some reason objectRoles from IssuesView is not passed to IssueMessage, so buttons can't be displayed.
    // this is a workaround.
    if (newProps.issues.length === 1 && !this.state.oneIssueLoaded) {
      console.log('only one issue')
      this.setState({ oneIssueLoaded: true })

      setTimeout(() => {
        this.forceUpdate()
      }, 1000)
      setTimeout(() => {
        this.forceUpdate()
      }, 4000)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !dequal(nextProps, this.props) || !dequal(nextState, this.state)
  }

  componentWillUnmount() {
    if (updateTimer) clearInterval(updateTimer)
    updateTimer = null
  }

  /**
   * Shows or hides message scroll bar
   */
  updateMessageScroll = () => {
    const node = this.rootRef.current
    if (!node) return
    const messageDiv = node.getElementsByClassName('IssuesView__scrollAreaInner')[0]
    if (!messageDiv) return
    this.setState({
      messageScrollVisible: messageDiv.scrollHeight > messageDiv.clientHeight
    })
  }

  setSelectedIssueId = (id, issue) => {
    //console.log("setSelectedIssueId", id);
    this.setState(
      {
        selectedIssueId: id
      },
      () => {
        setTimeout(() => {
          this.props.loadMessages(id)
        }, 100)
      }
    )
  }

  getIssueTypes() {
    return uniq(this.props.allIssues.map((issue) => issue.fullType.type))
  }

  showAllIssues = () => {
    //logger.info('IssuesView:showAllIssues', this.state, this.props);
    this.props.handleFiltersChange({
      assigned: true,
      priority: [],
      type: [],
      deadline: [],
      status: []
    })
  }

  clearFilters = () => {
    this.props.handleFiltersChange({
      assigned: this.props.filters.assigned,
      priority: [],
      type: [],
      deadline: [],
      status: []
    })
  }

  /**
   * If filter is set, converts list of filter values to string (to display)
   * @param {*} param
   */
  getFilterValueStr(param) {
    return this.props.filters && this.props.filters[param] && this.props.filters[param].length > 0
      ? this.props.filters[param].reduce(
          (str, item) => (str ? str + ', ' : str) + (typeof item === 'object' ? item.value : item),
          ''
        )
      : ''
  }

  setFilterMultiKascode = (event, param) => {
    const values = event.target.value
    const filters = Object.assign(this.props.filters, {
      [param]: values.map((v) => v.value)
    })

    this.props.handleFiltersChange(filters).then(() => {
      // this.checkCurrentIssueChange(prevCurrentIssue);
    })
  }

  setFilterMulti = (event, param, value: number | string | null = null) => {
    let filters = Object.assign({}, this.props.filters)
    let val = value === null ? event.target.value : value
    if (filters[param].indexOf(val) !== -1) filters[param] = filters[param].filter((v) => v !== val)
    else filters[param].push(val)
    // console.log("old filters", this.state.filters, " new filters", filters, "value", val);

    this.props.handleFiltersChange(filters).then(() => {
      // this.checkCurrentIssueChange(prevCurrentIssue);
    })
  }

  setFilter = (event, param, value: boolean | null = null, toggle = false) => {
    let filters = Object.assign({}, this.props.filters)
    let val = value === null ? event.target.value : value
    if (toggle && filters[param] === val) {
      delete filters[param]
    } else {
      filters[param] = val
    }

    //logger.info("IssuesView:setFilter", event.target.value, this.state.filters, { param, value, toggle, filters, val }, this.state, this.props);

    this.props.handleFiltersChange(filters).then(() => {
      // this.checkCurrentIssueChange(prevCurrentIssue);
    })
  }

  checkCurrentIssueChange(prevCurrentIssue) {
    let newCurrentIssue = this.getCurrentIssue(this.props)
    let prevIssueId = prevCurrentIssue ? prevCurrentIssue.identity.id : 0
    let newIssueId = newCurrentIssue ? newCurrentIssue.identity.id : 0

    // console.log("prev issue="+prevIssueId+", new issue="+newIssueId);
    if (prevIssueId !== newIssueId && newIssueId) {
      this.setState({
        selectedIssueId: newIssueId,
        selectedMessageIndex: 0,
        openAllMessages: false,
        messageIndexFromHash: false
      })
    }
  }

  cardClick(id) {
    const issueName = this.props.issues.find((issue) => issue.identity.id === id).identity.name
    this.setState(
      {
        selectedIssueId: id,
        selectedMessageIndex: 0,
        openAllMessages: false,
        messageIndexFromHash: false
      },
      () => {
        window.history.replaceState(null, '', '#issues/' + issueName)
        // this.props.loadMessages(id);
        this.props.loadIssue(id, issueName)
      }
    )
  }

  getIssueTags() {
    let tags = this.props.allIssues
      .reduce((tags, issue) => tags.concat(issue.object.tags), [])
      .map((tag) => tag.name)
      .concat((this.props.filters.tag || []).map((tag) => tag.value))
    //console.log("getIssueTags", tags);
    return tags.filter((tag, index) => tags.indexOf(tag) === index)
  }

  sortIssue = (a, b) => {
    if (this.props.filters.sort === 'priority') {
      //console.log("sortIssue",a.fullPriority, b.fullPriority);
      if (a.fullPriority.code < b.fullPriority.code) {
        return -1
      } else if (a.fullPriority.code > b.fullPriority.code) {
        return 1
      } else {
        //return 0;
      }
    }

    const aNumber = parseInt(a.identity.name)
    const bNumber = parseInt(b.identity.name)
    if (aNumber > bNumber) {
      return -1
    } else if (aNumber < bNumber) {
      return 1
    } else {
      return 0
    }
  }

  getIssueCards() {
    if (!this.props.userState || !this.props.userState.identity) return []
    if (this.props.isFixed) return []
    //logger.info('IssuesView:getIssueCards', this.state, this.props);

    let cards = this.props.issues.sort(this.sortIssue).map((issue) => {
      let ret: any = {
        id: issue.identity.id,
        name: issue.identity.name,
        description: issue.identity.description || '',
        attached: issue.attached,
        systemName: objectNameFromPathByType(issue.attached.name, 'systems'),
        applicationName: objectNameFromPathByType(issue.attached.name, 'applications'),
        datasetName: objectNameFromPathByType(issue.attached.name, 'datasets'),
        //deadline: issue.deadline,
        priority: issue.fullPriority,
        date: moment(issue.time).format('DD.MM.YY HH:mm:ss'),
        time: issue.time,
        isRecentlyEdited:
          (this.props.recentlyEditedIssues.includes(issue.identity.id) || this.props.isHashIssue(issue)) &&
          this.props.filterIssue(issue, true) !== '',
        isTracked: (issue.trackers || []).reduce(
          (p, c) => (c.id === this.props.userState.identity.id ? true : p),
          false
        )
      }
      if (this.props.recentlyEditedIssues.includes(issue.identity.id)) {
        //console.log("recently edited", issue);
        //console.log("filter", this.props.filterIssue(issue));
      }
      ret.deadline = getIssueAbsoluteDeadline(issue)
      return ret
    })
    let cardIds = cards.map((card) => card.id)
    cards = cards.filter((card, index) => cardIds.indexOf(card.id) === index) // keep unique card ids
    // this is needed because we had bug with duplicate keys of IssueCard's components
    // which caused duplicate cards displayed and multiple selection of cards
    return cards
  }

  messageTabSelect = (tab, index) => {
    let newMessageTabs = this.state.messageTabs
    newMessageTabs[index] = tab
    this.setState({ messageTabs: newMessageTabs }, () => {
      /*console.log("update3"); */ this.forceUpdate()
    })
  }

  selectMessage = (index) => {
    this.setState({ selectedMessageIndex: index, messageIndexFromHash: false }, () => {
      let message = this.props.messages.sort(messageSort)[index]
      if (message && message.action) {
        this.props.checkObject(message.attached)
      }
    })
  }

  /**
   * Enables or disables scrolling
   */
  canScroll = (refName, can) => {
    return () => {
      if (!can) {
        let node: any = null

        switch (refName) {
          case 'scroll':
            node = this.scrollRef.current
            break
          case 'issue':
            node = this.issueRef.current
            break
          case 'messageScroll':
            node = this.messageScrollRef.current
            break
          default:
            break
        }

        if (!node) return
        //console.log("canScroll item=",this.refs[refName],",node=",node,"sh=",node.scrollHeight,"ch=",node.clientHeight);
        if (node.scrollHeight <= node.clientHeight) {
          return
        }
        disableScroll()
      } else {
        enableScroll()
      }
    }
  }

  onSendMessage = (message, issue, done) => {
    this.props.onSendMessage(message, issue, () => {
      this.setState({
        selectedMessageIndex: 0,
        messageIndexFromHash: false
      })
      done()
    })
  }

  onDiscardMessage = () => this.setState({ selectedMessageIndex: 0, messageIndexFromHash: false })

  onOpenAllMessages = () => {
    this.setState({ openAllMessages: !this.state.openAllMessages })
  }

  onFullScreenMessages = () => this.setState({ fullScreenMessages: !this.state.fullScreenMessages })

  renderNewMessage = () => {
    const currentIssue = this.getCurrentIssue(this.props)
    return (
      <IssueNewMessage
        issue={currentIssue}
        onSendMessage={this.onSendMessage}
        onEditIssue={this.props.onIssueEdit(currentIssue)}
        onDiscard={this.onDiscardMessage}
        onSelectNewMessage={() => this.selectMessage(-1)}
        onOpenMessages={this.onOpenAllMessages}
        openMessagesButtonText={this.state.openAllMessages ? 'Collapse all messages' : 'Expand all messages'}
        onFullScreenMessages={this.onFullScreenMessages}
        fullScreenMessagesButtonText={this.state.fullScreenMessages ? 'Close messages' : 'Full screen messages'}
        selected={this.state.selectedMessageIndex === -1}
        users={this.state.messageUsers}
      />
    )
  }

  getCurrentIssue(props = this.props, issueId = this.state.selectedIssueId) {
    let issueFound = false
    let ret = props.issues.sort(this.sortIssue).reduce((p, c, i) => {
      // If previous set keep it
      if (p) return p

      // Check if current element is one we search for
      if (c.identity.id === issueId) {
        issueFound = true
      }

      // If we found issue check if current issue can be shown
      if (issueFound) {
        // note that c here can be other issue than we found by id
        // if found issue is filtered out we will take next unfiltered
        if (!props.filterIssue(c) || this.props.recentlyEditedIssues.includes(c.identity.id)) {
          return c
        }
      }
      return p
    }, null)

    if (!ret && props.issues.length > 0)
      //ret = props.issues.reduce((p,c,i)=>c, null);   // last issue
      ret = props.issues[0] // latest issue

    // logger.info('IssuesView::getCurrentIssue', { props, issueId, ret }, this.state, this.props)

    return ret
  }

  issueTypesToOptions = (issueType, index) => ({
    id: index,
    label: issueType,
    value: issueType
  })

  tagsToOptions = (tag, index) => ({
    id: index,
    label: tag,
    value: tag
  })

  renderFullScreen() {
    return <div className="IssuesView__fullScreen">{this.renderMessages()}</div>
  }

  onCloseFullScreenMessages = () => {
    this.setState({ fullScreenMessages: false })
  }

  onReloadMessages = () => {
    this.props.loadMessages(this.getCurrentIssue())
  }

  onDisableButtons = (messageId) => () => {
    this.setState({
      buttonsDisabled: this.state.buttonsDisabled.concat(messageId)
    })
  }

  renderMessages() {
    const currentIssue = this.getCurrentIssue(this.props)
    // logger.info('IssuesView:renderMessages', currentIssue, this.state, this.props)

    return (
      <div className="IssuesView__messages">
        {this.props.loadingMessages ? (
          <div className="IssuesView__loader">
            <Loader />
          </div>
        ) : null}
        {this.props.loadingMessages || this.props.messages.length === 0 ? null : (
          <div
            className="IssuesView__messagesInner"
            onMouseOver={this.canScroll('messageScroll', false)}
            onMouseOut={this.canScroll('messageScroll', true)}
          >
            {this.state.fullScreenMessages ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="NewIssueMessage__expand boldOnHover" onClick={this.onCloseFullScreenMessages}>
                Exit full screen
              </a>
            ) : null}
            <ScrollArea ref={this.messageScrollRef} horizontal={false}>
              <div
                className={
                  'IssuesView__scrollAreaInner ' +
                  (this.state.messageScrollVisible ? '' : 'IssuesView__scrollAreaInner__noScroll')
                }
              >
                {this.props.messages.sort(messageSort).map((message, index, arr) => (
                  <IssueMessage
                    message={message}
                    key={index}
                    messageIndex={arr.length - index}
                    messageNumber={arr.length}
                    active={
                      this.state.openAllMessages ||
                      (this.state.messageIndexFromHash
                        ? arr.length - index === this.state.selectedMessageIndex
                        : index === this.state.selectedMessageIndex)
                    }
                    activeTab={index in this.state.messageTabs ? this.state.messageTabs[index] : message.name ? 0 : 1}
                    onTabSelect={(tab) => this.messageTabSelect(tab, index)}
                    onSelect={() => this.selectMessage(index)}
                    onConfirm={this.props.onConfirm}
                    issue={currentIssue}
                    userState={this.props.userState}
                    role={this.props.objectRoles[message.attached]}
                    actions={this.props.actions}
                    currentUserRole={this.props.currentUserRole}
                    reloadMessages={this.onReloadMessages}
                    disableButtons={this.onDisableButtons(message.identity.id)}
                    buttonsDisabled={this.state.buttonsDisabled.indexOf(message.identity.id) !== -1}
                  />
                ))}
              </div>
            </ScrollArea>
          </div>
        )}
      </div>
    )
  }

  sortByDateOptions = [
    { id: '1', label: 'Sort by: Date', value: 'date' },
    { id: '2', label: 'Sort by: Priority', value: 'priority' }
  ]

  onMore = () => {
    if (new Date().getTime() - filterOpenTime < 500) {
      // prevent close by blur and open by button click
      return
    }
    filterOpenTime = new Date().getTime()
    this.setState({ filtersVisible: true }, () => {
      if (this.state.filtersVisible) {
        document.querySelector<HTMLElement>('#IssuesFilterPopup')?.focus()
      }
    })
  }

  handlePaginationChange = (value) => {
    this.props.pageChange(value)
  }

  render() {
    const cards = this.getIssueCards()
    const currentIssue = this.getCurrentIssue(this.props)
    // logger.info('IssuesView:render', { currentIssue, cards }, this.state, this.props)

    return (
      <div className="IssuesView" ref={this.rootRef}>
        {this.state.fullScreenMessages ? this.renderFullScreen() : null}
        <div className="IssuesView__filters">
          <div className="alt-col-xs-3">
            <span
              onClick={(e) => this.setFilter(e, 'assigned', true)}
              className={
                'IssuesView__assignedFilter ' +
                (this.props.filters.assigned ? 'IssuesView__assignedFilter__selected' : '')
              }
            >
              My Issues
            </span>
            <span
              onClick={(e) => this.setFilter(e, 'assigned', false)}
              className={
                'IssuesView__assignedFilter ' +
                (!this.props.filters.assigned ? 'IssuesView__assignedFilter__selected' : '')
              }
            >
              All Issues
            </span>
            <button className="btn btn_blue AddIssue" onClick={this.props.createIssue}>
              Add issue
            </button>
          </div>
          <div className="alt-col-xs-2 ">
            <KascodeSelect
              options={this.sortByDateOptions}
              placeholder="Sort by: Date"
              onValuesChange={(e) => this.setFilter(e, 'sort')}
            />
          </div>
          <div className="alt-col-xs-2 ">
            <KascodeSelect
              multi
              options={this.getIssueTypes().map(this.issueTypesToOptions)}
              placeholder={this.getFilterValueStr('type') || 'Type'}
              onValuesChange={(e) => this.setFilterMultiKascode(e, 'type')}
              className={this.props.filters.type && this.props.filters.type.length > 0 ? 'selectedFilter' : ''}
            />
          </div>
          <div className="alt-col-xs-1 ">
            <KascodeSelect
              multi
              options={this.getIssueTags().map(this.tagsToOptions)}
              placeholder={this.getFilterValueStr('tag') || 'Tag'}
              onValuesChange={(e) => this.setFilter(e, 'tag')}
              className={this.props.filters.tag && this.props.filters.tag.length > 0 ? 'selectedFilter' : ''}
            />
          </div>

          <div className="alt-col-xs-1 ">
            <button className="btn btn_blue IssuesView__btnMore" onClick={this.onMore}>
              More
              <img src={!this.state.filtersVisible ? arrowDown : arrowUp} alt="arrow icon" />
            </button>
            {this.state.filtersVisible ? (
              <div
                className="IssuesView__filterPopup"
                tabIndex={0}
                id="IssuesFilterPopup"
                onBlur={() => {
                  filterOpenTime = new Date().getTime()
                  this.setState({ filtersVisible: false })
                }}
              >
                <div className="">
                  <div className="alt-col-xs-4">
                    <div className="IssuesView__filterColTitle">Priority</div>
                    {[0, 1, 2, 3, 4].map((priority, i) => (
                      <div
                        onClick={(e) => this.setFilterMulti(e, 'priority', priority)}
                        className={
                          'IssuesView__priority ' +
                          (this.props.filters.priority.indexOf(priority) !== -1 ? 'IssuesView__activeLeftFilter' : '')
                        }
                        key={i}
                      >
                        {issuePriorityIcon(priority)}
                        {issuePriorityLabel(priority)}
                      </div>
                    ))}
                  </div>
                  <div className="alt-col-xs-4">
                    <div className="IssuesView__filterColTitle">Status</div>
                    {issuePropList['status'].source.data.records.map((status, i) => {
                      let statusName = status.values[0]
                      const statusIcon = getStatusIcon(statusName)
                      return (
                        <div
                          onClick={(e) => this.setFilterMulti(e, 'status', statusName)}
                          className={
                            'IssuesView__statusFilter ' +
                            ('IssuesView__statusFilter__' + statusName + ' ') +
                            (this.props.filters.status.indexOf(statusName) !== -1
                              ? 'IssuesView__statusFilter__active'
                              : '')
                          }
                          key={i}
                        >
                          <img src={statusIcon} alt="status icon" />
                          {statusName}
                        </div>
                      )
                    })}
                  </div>
                  <div className="alt-col-xs-4">
                    <div className="IssuesView__filterColTitle">Deadline</div>
                    {resolutions.map((resolution, index) => (
                      <div
                        className={
                          'IssuesView__deadlineFilter ' +
                          (this.props.filters.deadline.indexOf(resolution.color) !== -1
                            ? 'IssuesView__deadlineFilter__on'
                            : '')
                        }
                        key={index}
                      >
                        <div
                          className=""
                          onClick={(e) => {
                            this.setFilterMulti(e, 'deadline', resolution.color)
                          }}
                        >
                          <div className="clear">
                            <div className="alt-col">
                              <img
                                src={
                                  this.props.filters.deadline.indexOf(resolution.color) !== -1 ||
                                  this.state.deadlineMouseOver === resolution.color
                                    ? issueResolutionIcon(index + 1)
                                    : resolutionGray
                                }
                                alt="resolution icon"
                              />
                              {/*onMouseOver={()=>{this.setState({deadlineMouseOver: resolution.color})}}
                             onMouseOut={()=>{this.setState({deadlineMouseOver: ""})}}*/}
                            </div>
                            <div className="alt-col IssuesView__deadlineLabel">{resolution.label}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="alt-col-xs-1 IssuesView__clearFilter">
            {!this.props.filters.assigned ||
            this.props.filters.search ||
            (this.props.filters.deadline && this.props.filters.deadline.length > 0) ||
            (this.props.filters.priority && this.props.filters.priority.length > 0) ||
            (this.props.filters.status && this.props.filters.status.length > 0) ||
            (this.props.filters.tag && this.props.filters.tag.length > 0) ||
            (this.props.filters.type && this.props.filters.type.length > 0) ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="IssuesView__clearFilterButton boldOnHover" onClick={this.clearFilters}>
                Clear filter
              </a>
            ) : null}
          </div>
          <div className="alt-col-xs-2 ">
            <div className="SearchInput">
              <input
                type="text"
                placeholder="Search"
                className={'TextInput IssuesView__search ' + (this.props.filters.search ? 'selectedFilter' : '')}
                value={this.props.filters.search ? this.props.filters.search : ''}
                onChange={(e) => this.setFilter(e, 'search')}
              />
              <img className="SearchInput__pic" src={searchPic} alt="search icon" />
            </div>
          </div>
        </div>
        <div className="IssuesView__container">
          <div
            className="alt-col-xs-3 IssuesList"
            onMouseOver={this.canScroll('scroll', false)}
            onMouseOut={this.canScroll('scroll', true)}
          >
            <ScrollArea ref={this.scrollRef} horizontal={false} isEditing>
              <div className="IssuesView__cards">
                {this.props.loadingIssues
                  ? null
                  : cards.map((card, index) => (
                      <IssueCard
                        card={card}
                        key={card.id}
                        object={this.props.object}
                        onClick={() => this.cardClick(card.id)}
                        selected={card.id === currentIssue.identity.id}
                      />
                    ))}
              </div>
            </ScrollArea>
            <div className="self-center">
              {this.props.pageNumber > 1 && (
                <IconButton onClick={() => this.handlePaginationChange(this.props.pageNumber - 1)}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
              )}
              <IconButton>
                <KeyboardArrowRightIcon onClick={() => this.handlePaginationChange(this.props.pageNumber + 1)} />
              </IconButton>
            </div>
          </div>
          {this.props.loadingIssues ? (
            <div className="IssuesView__loaderDiv alt-col-xs-9">
              <Loader />
            </div>
          ) : this.props.issues.length > 0 ? (
            <div className="alt-col-xs-9 IssuesView__main">
              <Issue
                ref={this.issueRef}
                majorObject={Object.assign({}, currentIssue, {
                  assigned: this.state.currentAssignedUser ? this.state.currentAssignedUser : { id: '' }
                })}
                actions={this.props.actions}
                onEdit={this.props.onIssueEdit}
                handleIssueCollapseButton={this.props.handleIssueCollapseButton}
              />
              {this.renderNewMessage()}
              {this.renderMessages()}
            </div>
          ) : this.props.loadingIssues ? (
            <div className="IssuesView__loaderDiv alt-col-xs-9">
              <Loader />
            </div>
          ) : (
            <div className="IssuesView__notFound alt-col-xs-9">
              <div>
                <div className="IssuesView__notFoundHeader">No issues found</div>
                <br />
                <div>
                  You can{' '}
                  <div className="IssuesView__linkButton" onClick={this.props.createIssue}>
                    Create Issue
                  </div>{' '}
                  or view{' '}
                  <div className="IssuesView__linkButton" onClick={this.showAllIssues}>
                    All issues
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}
