import PropTypes from 'prop-types'
import React from 'react'
import { authDownload } from '../../helpers/api'
import { editableState, getFullResourceUri, nonCSCompare } from '../../helpers/index'
import logger from '../../helpers/logger'
import { Badge } from '../Badge/Badge'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { DocList } from './DocList'
import './DocsDialog.scss'

import iDownload from '../../resources/images/download-2x.png'
import iOpen from '../../resources/images/open-2x.png'

const DOC_HEIGHT = 60

export class DocsDialog extends EditorDialogBase {
  /**
   * Returns object type for dialog, for example "activity"
   */
  getObjectType() {
    return 'docs'
  }

  /**
   * Returns minimum height for dialog
   */
  getMinHeight() {
    return 300
  }

  /**
   * Returns list of adjustments of height based on object elements.
   * Name - is a name of parameter to use,
   * Value - adjustment for element.
   */
  getElementsHeight() {
    // We have lines of settings on settings array
    return { __line: DOC_HEIGHT }
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/system/datasets/object',
      '/organizations/Apdax/systems/DifHub/applications/System/views/DocsDialog'
    )

    logger.info('DocsDialog:open', this.state, this.props)
  }

  /**
   * Override the method in child dialogs, to handle changeEditable() event: opening and closing the dialog
   */
  onChangeEditable = (isEditable) => {
    //logger.info("EditorDialogBase:onChangeEditable", isEditable, this.props);
  }

  saveDocs = (docs, closeDialog, onSent) => {
    onSent(closeDialog)
    logger.info('DocsDialog:saveDocs', docs, this.state)

    this.Change(docs)
  }

  /**
   * Opens document in new tab to view
   */
  onViewDocument = (doc, index) => {
    let contentUri = this.props.contentUri
    const fileURI = doc.uri

    // Check for compatability mode of issues:
    // Ignore root for uri start from organizations
    if (contentUri && fileURI.startsWith('/organizations/')) {
      contentUri = null
    }

    let root = contentUri ? (contentUri.endsWith('/') ? contentUri : contentUri + '/') : '/'
    let uri = fileURI ? (fileURI.startsWith('/') ? fileURI.substring(1) : fileURI) : ''

    let fullUri = root + uri
    let docContent = (this.state.uploadContent || []).find((cont) => nonCSCompare(cont.fileURI, fileURI))
    if (!nonCSCompare(doc.type, 'Link')) {
      fullUri = getFullResourceUri(fullUri)
    }

    if (docContent) {
      let image = URL.createObjectURL(docContent.fileData)
      // eslint-disable-next-line no-unused-vars
      let newWindow = window.open(image)
      //logger.info("DocList:onViewDocument:CONTENT", {docContent, fullUri, image}, this.props, this.state);
    } else if (fullUri) {
      //logger.info("DocList:onViewDocument", doc, fullUri, this.props, this.state);
      window.open(fullUri)
    } else {
      window.alert('The document has not been uploaded yet.')
    }
  }

  /**
   * Downloads document
   */
  onDownloadDocument = (doc, index) => {
    let contentUri = this.props.contentUri
    const fileURI = doc.uri

    // Check for compatability mode of issues:
    // Ignore root for uri start from organizations
    if (contentUri && fileURI.startsWith('/organizations/')) {
      contentUri = null
    }

    let root = contentUri ? (contentUri.endsWith('/') ? contentUri : contentUri + '/') : '/'
    let uri = fileURI ? (fileURI.startsWith('/') ? fileURI.substring(1) : fileURI) : ''

    const fullUri = getFullResourceUri(root + uri)
    let docContent = (this.state.uploadContent || []).find((cont) => nonCSCompare(cont.fileURI, fileURI))
    if (nonCSCompare(doc.type, 'Link')) {
      return
    }

    if (docContent) {
      let image = URL.createObjectURL(docContent.fileData)
      authDownload(doc.identity.name, image)
    } else if (fullUri) {
      //logger.info("DocList:onDownloadDocument", doc, fullUri, this.props, this.state);
      authDownload(doc.identity.name, fullUri)
    } else {
      window.alert('The document has not been uploaded yet.')
    }
  }

  /**
   * Render one item in list of the items when dialog is closed
   */
  renderItem(item, index) {
    let doc = item
    if (!doc) {
      return null
    }

    //console.log("DocsDialog:renderItem", this.state, this.props);
    return (
      <Badge
        key={doc.identity.name}
        color="clear"
        className="DocsDialog__listItem"
        onClick={() => {
          this.onDownloadDocument(doc)
        }}
      >
        {doc.identity.name}
        <img
          src={iDownload}
          alt=""
          onClick={(e) => {
            this.onDownloadDocument(doc)
            e.stopPropagation()
          }}
        />
        <img
          src={iOpen}
          alt=""
          onClick={(e) => {
            this.onViewDocument(doc)
            e.stopPropagation()
          }}
        />
      </Badge>
    )
  }

  renderEditor = (editState) => {
    let dialog = this.getDialogRef()
    //console.log("DocsDialog:renderEditor", this.state, this.props);
    return (
      <div className="DocsDialog">
        <DocList
          ref="doclist_content"
          appState={this.props.appState}
          actions={this.props.actions}
          isVisible
          isEditable={editState >= editableState.EDITABLE ? editState : editableState.BROWSABLE}
          majorObject={this.props.majorObject}
          docsDialog={this.getDialogRef()}
          type={'document'}
          items={this.state.docs}
          content={this.props.content ? this.props.content : this.state.uploadContent}
          contentUri={this.props.contentUri}
          onContentChange={this.onContentChange}
          onSave={this.saveDocs}
          onViewDocument={this.onViewDocument}
          onDownloadDocument={this.onDownloadDocument}
          isFullscreen={dialog && dialog.state ? dialog.state.isFullscreen : false}
        />
      </div>
    )
  }
}

DocsDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  isFullscreen: PropTypes.bool,
  isViewingOnly: PropTypes.bool,
  isItems: PropTypes.number,
  maxItems: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  docs: PropTypes.object,
  base: PropTypes.object,
  version: PropTypes.object,
  content: PropTypes.array, // List of content to upload.
  contentUri: PropTypes.string, // URI for content
  onContentChange: PropTypes.func, // Uploaded content change for dialog
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
