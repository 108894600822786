import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { HeaderOld } from './Header/HeaderOld'
import { HeaderPath } from './HeaderPath/HeaderPath'
import { formatByType, buildPathDetailed, getFullUri } from '../helpers'

import i404 from '../resources/images/404.png'

export class NotFoundPage extends Component {
  render() {
    let { userState, actions } = this.props

    const appState = this.props.appState
    //const defaultOrg = appState.organizations && appState.organizations.length > 0 ? appState.organizations. : null;
    let defaultOrg = null
    let noOrg = true
    if (appState.organizations) {
      noOrg = false
      appState.organizations.map((org, index) => {
        if (!localStorage.getItem('currentOrganization') && index === 0) {
          defaultOrg = org
        }
        if (localStorage.getItem('currentOrganization') === org.identity.id) {
          defaultOrg = org
        }
      })
    }

    return (
      <div>
        {defaultOrg ? <HeaderPath path={buildPathDetailed(getFullUri(defaultOrg))} /> : null}
        {defaultOrg ? this.renderUserRole(defaultOrg) : null}
        <HeaderOld userState={userState} actions={actions} />
        <img className="notFoundImage" src={i404} alt="404 Not Found" />
      </div>
    )
  }
}
