import logger from './logger'

// network event count is sent to analytics every 5 minutes
const ANALYTICS_INTERVAL = 60000 * 5

// previous count of network events and timer for network events
let prevRequestCount = { network: 0, cached: 0 }
let trackTimer: any = null

/**
 * Check that application is running on prod
 */
function trackCheckDomain() {
  return window.location.hostname === 'metucat.com'
}

/**
 * Set up google analytics configuration and start timer for network event monitoring
 */
export function trackInit() {
  initTrackingLog()

  if (!trackCheckDomain()) return
  //console.log("track_init");

  window.gtag('config', 'GA_MEASUREMENT_ID', {
    custom_map: {
      dimension1: 'role',
      dimension2: 'userid',
      dimension3: 'objectType'
    }
  })

  if (trackTimer) window.clearInterval(trackTimer)

  trackTimer = window.setInterval(() => {
    const organizationName = localStorage.currentOrganizationName

    // calculate number of events that happened since previous function call
    let deltaCount = {
      network: window.requestCount.network - prevRequestCount.network,
      cached: window.requestCount.cached - prevRequestCount.cached
    }

    //console.log("deltaCOunt", deltaCount, prevRequestCount, window.requestCount);

    if (deltaCount.network > 0)
      window.gtag('event', 'api_requests', {
        event_category: 'network',
        event_label: organizationName,
        value: deltaCount.network
      })

    if (deltaCount.cached > 0)
      window.gtag('event', 'api_requests', {
        event_category: 'cached',
        event_label: organizationName,
        value: deltaCount.cached
      })

    prevRequestCount = {
      network: window.requestCount.network,
      cached: window.requestCount.cached
    }
  }, ANALYTICS_INTERVAL)
}

const MESSAGE_LIST = 'messageList'

/**
 * Save message in log in local storage
 * @param {*} message
 */
function storeTrackingLog(message: string) {
  if (!message) return
  try {
    if (message.length > 2048) message = message.substr(0, 2048)
    let messageList: string[] = []
    const list = localStorage.getItem(MESSAGE_LIST)
    if (list) {
      messageList = JSON.parse(list)
    }
    messageList = messageList.slice(-100)
    messageList.push(message)
    localStorage.setItem(MESSAGE_LIST, JSON.stringify(messageList))
  } catch (e) {
    //console.error(e);
  }
}

// TODO: when called?
function logItemToStr(o) {
  const getCircularReplacer = () => {
    const seen = new WeakSet()
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return
        }
        seen.add(value)
      }
      return value
    }
  }

  if (typeof o === 'object') {
    const s = JSON.stringify(o, getCircularReplacer())

    if (s.length < 512) return s
    else return s.substr(0, 512)
  } else return o
}

let init_tracking_log_done = false
/**
 * Sets console.log to push messages into tracking log; activated only on on-prem installations
 * set
 */
function initTrackingLog() {
  if (init_tracking_log_done) return
  init_tracking_log_done = true
  setTimeout(() => {
    //console.warn("init_tracking_log", window.localInstallation);
    if (window.localInstallation) {
      const oldLog = console.log
      console.log = function () {
        //console.warn("new console.log", arguments);
        if (arguments.length > 0) {
          let message = new Date().toString() + ' '
          for (let i = 0; i < arguments.length; i++) {
            message += logItemToStr(arguments[i]) + ' '
          }
          storeTrackingLog(message)
        }
        // @ts-ignore
        oldLog.apply(console, arguments)
      }

      const oldWarn = console.warn
      console.warn = function () {
        //console.warn("new console.log", arguments);
        if (arguments.length > 0) {
          let message = new Date().toString() + ' '
          for (let i = 0; i < arguments.length; i++) {
            message += logItemToStr(arguments[i]) + ' '
          }
          storeTrackingLog('Warning: ' + message)
        }
        // @ts-ignore
        oldWarn.apply(console, arguments)
      }

      const oldError = console.error
      console.error = function () {
        //console.warn("new console.log", arguments);
        if (arguments.length > 0) {
          let message = new Date().toString() + ' '
          for (let i = 0; i < arguments.length; i++) {
            message += logItemToStr(arguments[i]) + ' '
          }
          storeTrackingLog('Error: ' + message)
        }
        // @ts-ignore
        oldError.apply(console, arguments)
      }
    }
  }, 0)
}

/**
 * Log event in google analytics. Also logs organization name, user role, user id and object type
 * @param {*} objectName name of object or dialog (parameter)
 * @param {*} categoryName name of category (for example "dialog_edit")
 */
export function track(objectName: string, categoryName: string) {
  const organizationName = localStorage.currentOrganizationName

  const message = `${new Date().toString()} track (object: ${objectName} category: ${categoryName} org: ${organizationName} role: ${localStorage.getItem(
    'role'
  )} userid: ${localStorage.getItem('userid')})`

  storeTrackingLog(message)

  if (!trackCheckDomain()) return true

  logger.log('track', objectName, {
    event_category: categoryName,
    event_label: organizationName,
    role: localStorage.role || '',
    userid: localStorage.userid || '',
    objectType: localStorage.objectType || ''
  })

  window.gtag('event', objectName, {
    event_category: categoryName,
    event_label: organizationName,
    role: localStorage.role || '',
    userid: localStorage.userid || '',
    objectType: localStorage.objectType || ''
  })

  return true
}

/**
 * Store user id and role for track() function
 */
export function trackUserSet(userid, role) {
  if (role) localStorage.role = role
  if (userid) localStorage.userid = userid
  if (!trackCheckDomain()) return

  window.gtag('set', 'dimension1', role)
  window.gtag('set', 'dimension2', userid)
}

/**
 * Store objectType for track() function
 */
export function trackObjectSet(objectType) {
  if (objectType) localStorage.objectType = objectType
  if (!trackCheckDomain()) return

  window.gtag('set', 'dimension3', objectType)
}
