import * as React from 'react'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { FormulaEditor } from '../FormulaEditor/FormulaEditor'
import logger from '../../helpers/logger'

interface IMapDialogFooter {
  path: string
  inputs: [string]
  output: string
  formula: string
  tableNames: [string]
  fieldNames: [string]
  onEditFormula: (any: any) => void
  onBtnClick: (idx: string, mode: string) => void
}

export const MapDialogFooter: React.FC<IMapDialogFooter> = ({
  path,
  inputs,
  output,
  formula,
  tableNames,
  fieldNames,
  onEditFormula,
  onBtnClick
}) => {
  return (
    <div className="MapDialog pl-30">
      <div className="row">
        <div className="col-xs-6">
          <div className="d-flex">
            <div className="mr-5">
              <label className="font-bold text-base">Inputs:</label>
            </div>

            <div className="self-end">
              <EditableEntity
                data={inputs.join(', ')}
                dataType={{ name: 'string' }}
                dataProps={{
                  placeholder: 'Enter name',
                  onChange: () => logger.log('test 1')
                }}
                isEditable={false}
                inEditMode={false}
                className=""
              />
            </div>
          </div>

          <div className="d-flex">
            <div className="mr-5">
              <label className="font-bold text-base">Output:</label>
            </div>
            <div className="self-end">
              <EditableEntity
                data={output}
                dataType={{ name: 'string' }}
                dataProps={{
                  placeholder: 'Enter name',
                  onChange: () => logger.log('test 2')
                }}
                isEditable={false}
                inEditMode={false}
                className=""
              />
            </div>
          </div>

          <div className="d-flex">
            <div className="mr-5">
              <label className="font-bold text-base">Formula:</label>
            </div>
            <div className="self-end">
              <FormulaEditor
                value={formula}
                onChange={(value: any) => onEditFormula({ target: { value: value } })}
                tableNames={tableNames}
                fieldNames={fieldNames}
              />
            </div>
          </div>
        </div>

        <div className="col-xs-6">
          <div className="MapDialog__mapButtons MapDialog__mapButtons--footer">
            <div className="MapDialog__clearbutton btn_blue" onClick={() => onBtnClick(path, 'prev')}>
              Prev
            </div>
            <div className="MapDialog__clearbutton btn_blue" onClick={() => onBtnClick(path, 'next')}>
              Next
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
