import React from 'react'
import moment from 'moment'

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'

import iconTrack from '../../resources/images/track-2x.png'
import { issueResolutionIcon, renderDateOrTime } from '../../helpers'

import './KanbanCard.scss'
import { issuePriorityIcon } from '../../helpers/helperComponents'

export const KanbanCard = ({ card, isSelected }) => {
  return (
    <div className={`KanbanCard KanbanCard__${card.identity.name} ${isSelected ? 'KanbanCard__selected' : ''}`}>
      <div className=" KanbanCard__name" title={card.identity.name + '. ' + card.identity.description}>
        {card.identity.name + '. ' + card.identity.description}
      </div>

      <div className="KanbanCard__deadline">
        <span className="KanbanCard__label">Deadline:</span>{' '}
        {!card || renderDateOrTime(moment(card.deadline).format('MMM DD, YYYY HH:MM'))}
      </div>

      <div className="KanbanCard__priority">
        {card.isTracked ? (
          <img
            className="KanbanCard__trackIcon"
            src={iconTrack}
            title="You track this issue"
            alt="You track this issue"
          />
        ) : (
          ''
        )}

        {issuePriorityIcon(card.fullPriority.code)}
        <img src={issueResolutionIcon(card.deadline)} className="KanbanCard__resolutionIcon" alt="resolition icon" />
      </div>
      {card?.assigned?.name && (
        <div className="KanbanCard__assigned">
          <AssignmentIndIcon style={{ fontSize: '1rem', color: '#6e49cb' }} /> <span>{card.assigned.name}</span>
        </div>
      )}
    </div>
  )
}
