/**
 * Created by kascode on 26.06.17.
 */
import * as React from 'react'
// eslint-disable-next-line import/no-duplicates
import { Component } from 'react'
import logger from '../../helpers/logger'
import * as PropTypes from 'prop-types'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import ObjectItemSearch from '../ObjectSearch/ObjectItemSearch'
import { LinkedKeyTables } from '../LinkedKeyTables/LinkedKeyTables'
import { getObjectNew, idAPI, getRequestFromPath } from '../../helpers/api'
import {
  nonCSCompare,
  deepCopy,
  findById,
  findByName,
  findByIdentity,
  getParentUri,
  editableState,
  majorObjectSort,
  updateLocalObject,
  clearLocalObject,
  getFullUri
} from '../../helpers/index'

import './DatasetSubscription.scss'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { renderRestore } from '../../helpers/helperComponents'

export class DatasetSubscription extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      datasetObject: null
    })
  }

  getObjectType() {
    return 'datasetReference'
  }

  UNSAFE_componentWillReceiveProps() {}

  componentDidMount() {
    if (this.props.minorObject)
      this.getDatasetData(this.props.minorObject.identity.id, this.props.minorObject.identity.name)
  }

  /**
   * Get initial state
   * @param {object} [props]
   * @returns {*}
   */
  getInitial(props) {
    props = props || this.props
    //console.log("DatasetSubscription:getInitialState", props.minorObject, props.publication);
    if (props.minorObject) {
      return JSON.parse(JSON.stringify(props.minorObject))
    } else {
      return {
        identity: { name: '', description: '' },
        version: { major: 0, minor: 0, revision: 0 },
        layoutNames: [],
        fieldNames: []
      }
      //createEmptyObject('datasetreference');
    }
  }

  /**
   * Change state of layout
   * @param {object} - new state of object
   * @returns {*} true - it is updated, false - no changes
   */
  ChangeDatasetReference = (reference) => {
    return this.Change(reference)
  }

  /**
   * Get data for provided interface
   * @param {string} id - uid of interface
   * @param {string} name - interface name
   * @param {boolean} updateReference - whether we need to update reference
   */
  getDatasetData = (id, name, updateReference) => {
    let publication = this.props.publication
    let dataset = findById(publication.datasets, id)
    if (!dataset) return

    if (!this.props.minorObject || this.props.minorObject.identity.id !== id) {
      const datasetReference = {
        identity: dataset.identity,
        version: dataset.version,
        layoutNames: [],
        fieldNames: []
      }
      if (updateReference) this.ChangeDatasetReference(datasetReference)
    }

    getObjectNew(idAPI.datasets(id), 'dataset', this.props.actions).then(
      (obj) => {
        this.setState({
          height: this.state.height + (obj.operations ? obj.operations.length : 0) * 40,

          datasetObject: obj
        })
      },
      (err) => {
        //console.log("DatasetSubscription:getDatasetData:ERROR", err);
      }
    )
  }

  checkInputErrors(datasetReference) {
    let inputError = ''
    if (this.getLayoutOptions().length > 0 && datasetReference.layoutNames.length < 1) {
      inputError += 'Please select layouts for subscription! '
    }
    if (this.getFieldOptions().length > 0 && datasetReference.fieldNames.length < 1) {
      inputError += 'Please select fields for subscription! '
    }
    return inputError
  }

  onSelectDataset(event) {
    let dataset = event.target.value

    logger.info('DatasetSubscription:onSelectDataset', dataset, this.props, this.state)

    // Indicate save mode
    if (this.state.change && dataset) {
      this.getDatasetData(dataset.identity.id, dataset.identity.name, true)
    }
  }

  getDatasetOptions() {
    //console.log("DatasetSubscription:getDatasetOptions", this.props, this.state.datasetReference);
    let subscribed = this.props.subscription && this.props.subscription.datasets ? this.props.subscription.datasets : []
    let published = this.props.publication && this.props.publication.datasets ? this.props.publication.datasets : []
    let datasets = published.filter((ds) => subscribed.findIndex((dt) => ds.identity.id === dt.identity.id) === -1)

    return datasets.map((element, index) => {
      return {
        id: index,
        label: element.identity.name,
        value: element
      }
    })
  }

  getLayoutOptions() {
    //console.log("DatasetSubscription:getLayoutOptions", this.props.publication, this.state.datasetReference);
    let dataset = findByIdentity(this.props.publication.datasets, this.state.datasetReference)
    if (!dataset || !dataset.layoutNames) return []

    return dataset.layoutNames.map((element, index) => {
      return {
        id: index,
        label: element,
        value: element
      }
    })
  }

  getFieldOptions() {
    //console.log("DatasetSubscription:getFieldOptions", this.props, this.state.datasetReference, this.state.datasetObject, dataset);
    let dataset = findByIdentity(this.props.publication.datasets, this.state.datasetReference)
    if (!dataset || !dataset.fieldNames) return []

    let datasetFields = this.state.datasetObject ? this.state.datasetObject.structure.fields : null
    if (datasetFields) {
      //dataset.fieldNames = datasetFields.map(field => { return field.identity.name });
      return dataset.fieldNames.map((element, index) => {
        let field = findByName(datasetFields, element)
        return [index, field.identity.name, field.type]
      })
    }
    return dataset.fieldNames.map((element, index) => [index, element, ''])
  }

  onLayoutChange(e) {
    let layouts = e.target.value

    let datasetReference = deepCopy(this.state.datasetReference)
    datasetReference.layoutNames = layouts.map((layout) => layout.value)

    this.ChangeDatasetReference(datasetReference)
  }

  onFieldChange(index) {
    return (newSelected) => {
      let datasetReference = deepCopy(this.state.datasetReference)
      let fieldOptions = this.getFieldOptions()

      //console.log("DatasetSubscription:onFieldChange", index, newSelected, datasetReference, fieldOptions);

      datasetReference.fieldNames = []
      for (let i in newSelected) {
        let f = fieldOptions[newSelected[i]]
        datasetReference.fieldNames.push(f[1])
      }

      this.ChangeDatasetReference(datasetReference)
    }
  }

  /**
   * Filter string for item in the list
   * @return default filter string
   */
  itemFilter = (item, type, query) => {
    //console.log("DatasetSubscription:itemFilter", item, type, query, this.props);
    // Check if interface exist in publication
    if (this.props.publication.datasets.findIndex((ds) => nonCSCompare(item.identity.name, ds.identity.name)) === -1)
      return false

    // Check if interface already used in subscription
    if (this.props.subscription.datasets.findIndex((ds) => nonCSCompare(item.identity.name, ds.identity.name)) !== -1)
      return false

    if (!query || query.length === 0) {
      return true
    } else {
      return item.identity.name && item.identity.name.toLowerCase().startsWith(query)
    }
  }

  renderFields(editState) {
    let fieldOptions = this.getFieldOptions()
    if (!fieldOptions || fieldOptions.length === 0) return null

    let fieldNames =
      this.state.datasetReference && this.state.datasetReference.fieldNames
        ? this.state.datasetReference.fieldNames
        : []
    let selectedFields = fieldNames.map((element, index) =>
      fieldOptions.findIndex((op) => nonCSCompare(op[1], element))
    )

    //console.log("DatasetSubscription:renderFields", fieldOptions, selectedFields, this.state.datasetReference);
    return (
      <div>
        <div>
          <div className="row DatasetSubscription__field">
            <div className="col-xs-1">Fields:</div>
          </div>
          <div className="row DatasetSubscription__row">
            <LinkedKeyTables
              columns={['NN', 'Name', 'Type']}
              selected={selectedFields}
              items={fieldOptions}
              onChange={this.onFieldChange(0).bind(this)}
              editable={editState > editableState.EDITABLE}
              onSrcFieldChange={() => {}}
              canAddField={(index) => {
                return true
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render layout for publication or subscription.
   */
  renderLayout = (editState) => {
    let layoutoptions = this.getLayoutOptions()
    if (!layoutoptions || layoutoptions.length === 0) return null

    let layoutNames =
      this.state.datasetReference && this.state.datasetReference.layoutNames
        ? this.state.datasetReference.layoutNames
        : []

    //console.log("DatasetSubscription:renderLayout", editState, layoutoptions, layoutNames, this.state);

    return (
      <div>
        <div className="row DatasetSubscription__row">
          <div className="col-xs-1">
            <span className="DatasetSubscription__label">Layouts:</span>
          </div>
          <div className="col-xs-9">
            {editState > editableState.EDITABLE && layoutoptions.length > 0 ? (
              <EditableEntity
                data={layoutNames.length > 0 ? layoutNames.toString() : 'Select Layouts'}
                dataType={{ name: 'enum', options: layoutoptions }}
                isEditable
                inEditMode
                dataProps={{
                  hideCheckBox: false,
                  multi: true,
                  onChange: this.onLayoutChange.bind(this),
                  activeOptions: layoutNames.map((name) =>
                    layoutoptions.findIndex((layout) => nonCSCompare(layout.value, name))
                  )
                }}
              />
            ) : (
              <div className="DatasetSubscription__subjectValue">{layoutNames}</div>
            )}
          </div>
          <div className="col-xs-8">
            <div className="DatasetSubscription__infoIcon" />
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render interface for publication.
   */
  renderDataset = (editState) => {
    let fieldOptions = this.getFieldOptions()

    let description =
      this.state.datasetReference.identity &&
      this.state.datasetReference.identity.description &&
      this.state.datasetReference.identity.description.length > 0
        ? this.state.datasetReference.identity.description
        : ''
    let name =
      this.state.datasetReference.identity &&
      this.state.datasetReference.identity.name &&
      this.state.datasetReference.identity.name.length > 0
        ? this.state.datasetReference.identity.name
        : 'Select to subscribe'

    let datasets = this.getDatasetOptions()

    const inEditMode = editState > editableState.EDITABLE
    return (
      <div>
        <div className={'NewSettingsBlock'}>
          <div className="row DatasetSubscription__row">
            <div className="col-xs-1">
              <span className="DatasetSubscription__label">Dataset:</span>
            </div>
            <div className="col-xs-6">
              {inEditMode ? (
                <EditableEntity
                  data={name}
                  dataType={{
                    name: 'enum',
                    options: datasets
                  }}
                  dataProps={{
                    placeholder: 'Select dataset',
                    onChange: this.onSelectDataset.bind(this)
                  }}
                  isEditable={inEditMode}
                  inEditMode={inEditMode}
                />
              ) : (
                <EditableEntity
                  data={name}
                  dataType={{ name: 'string' }}
                  dataProps={{ placeholder: '' }}
                  isEditable={false}
                  inEditMode={false}
                />
              )}
            </div>
            <div className="col-xs-3">
              <span className="DatasetSubscription__label">Version:</span>
            </div>
            <div className="col-xs-4">
              <div className="DatasetSubscription__subjectVersion">
                {this.state.datasetReference.version.major + '.*.*'}
              </div>
            </div>
            <div className="col-xs-8">
              <div className="DatasetSubscription__infoIcon" />
            </div>
          </div>
        </div>
        <div className={'NewSettingsBlock'}>
          <div className="row DatasetSubscription__row DatasetSubscription__descriptionRow">
            <div className="col-xs-1">
              <div className="DatasetSubscription__subjectLabel">Description:</div>
            </div>
            <div className="col-xs-7">
              <div className="DatasetSubscription__subjectValue">{description}</div>
            </div>
          </div>
        </div>
        <div className="row DatasetSubscription__row">
          <div className="col-xs-2">
            {fieldOptions.length === 0 ? <div className="DatasetSubscription__subjectLabel">As Structure</div> : null}
          </div>
        </div>
      </div>
    )
  }

  /**
   * Render scrollable area of dialog
   * @return
   */
  renderData = (editState) => {
    //console.log("DatasetSubscription:renderData", this.state, editState);
    let structure = this.getFieldOptions().length === 0

    return (
      <div className="DatasetSubscription">
        {this.renderDataset(editState)}
        {structure ? null : this.renderLayout(editState)}
        {structure ? null : this.renderFields(editState)}
      </div>
    )
  }

  render() {
    let caption = 'Dataset to subscribe'

    //console.log("DatasetSubscription:render", this.props, this.state);
    return (
      <div className="DatasetSubscription">
        <EditorDialog
          objectType="data"
          modalTitle={caption}
          editContent={this.renderData.bind(this)}
          headerContent={renderRestore(this, () => {
            this.setState({
              isAsStructure: (this.state.datasetReference.fieldNames || []).length === 0
            })
            let appUri = getFullUri(this.props.publication)
            appUri = appUri.substr(0, appUri.indexOf('/publications'))
            getObjectNew(getRequestFromPath(appUri + '/datasets/' + this.state.datasetReference.identity.name)).then(
              (ds) => {
                //this.onSelectDataset(ds);
                this.getDatasetData(ds.identity.id, ds.identity.name)
              }
            )
          })}
          editHeight={this.state.height}
          isVisible={this.props.isVisible}
          isEditable={this.props.isEditable}
          onClose={this.props.onClose}
          onSave={this.save.bind(this)}
          onEdit={this.startEdit}
          onCancel={this.cancelEdit}
        />
      </div>
    )
  }
}

DatasetSubscription.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  publication: PropTypes.object,
  subscription: PropTypes.object,
  object: PropTypes.object,
  minorObject: PropTypes.object,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}
