import * as userActions from './userActions'

import * as datasetActions from './datasetActions'
import * as majorObjectActions from './majorObjectActions'
import * as messageActions from './messageActions'
import * as globalActions from './globalActions'
import * as uiActions from './uiActions'
import { DatasetActions } from './datasetActions'
import { GlobalActions } from './globalActions'
import { MajorObjectActions } from './majorObjectActions'
import { MessageActions } from './messageActions'
import { UiActions } from './uiActions'
import { UserActions } from './userActions'

const actions: Actions = Object.assign(
  {},
  userActions,
  datasetActions,
  majorObjectActions,
  messageActions,
  globalActions,
  uiActions
)

export type Actions = DatasetActions & GlobalActions & MajorObjectActions & MessageActions & UiActions & UserActions

export default actions
