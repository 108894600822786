/**
 * Created by sashaberger on 06.18.2017.
 *
 * Dialog to edit user role.
 *
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { EditableEntity } from '../EditableEntity/EditableEntity'

import './RoleDialog.scss'

export class RoleDialog extends Component {
  constructor(props) {
    super(props)

    //console.log("RoleDialog:constructor", props);

    this.state = {
      change: null, // Method to report or cancel modification.

      role: props.role
    }
  }

  componentDidMount() {
    //roleNames
  }

  /**
   * Execute activity when dialog closing
   * @return
   */
  onClose = () => {
    if (this.props.onClose) this.props.onClose()
  }

  /**
   * Execute edit in the dialog
   * @param
   * @return
   */
  onEdit = (change) => {
    //console.log("RoleDialog:onEdit", this.state.isEditable);

    this.setState({
      change: change
    })
  }

  /**
   * Execute activity when message send to service
   * @param error return from service if any.
   * @return
   */
  onSent = (close, error) => {
    //console.log("RoleDialog:onSent", close, error);

    // Report complete of save with error or not
    if (this.state.change) {
      this.state.change(error)
    }

    if (close && !error) {
      setTimeout(() => {
        this.onClose()
      }, 1000)
    }
  }

  /**
   * Execute activity when save button pushed in dialog
   * @param error return from service if any.
   * @return
   */
  onSave = (close) => {
    //console.log("RoleDialog:onSave", close);
    window.apiCache.store={}
    if (this.state.change) {
      this.state.change(true, 'Data send to the service.', '', '')
    }

    if (this.props.onSave) {
      this.props.onSave(this.state.role, close, this.onSent)
      return true
    }

    // Delay to close on save.
    return true
  }

  /**
   * Cancel editing in the dialog
   * @param close - cancel execution and close dialog.
   * @return
   */
  onCancel = (close) => {
    //console.log("RoleDialog:onCancel", close);

    // Cancel save mode
    if (this.state.change) this.state.change(false)

    this.setState({
      change: null,
      role: this.props.role
    })
  }

  /**
   * Change of role by selected check box
   * @param role - updated role
   */
  onRoleChange = (role) => {
    //console.log("RoleDialog:onRoleChange", this.state.role, role);

    // Indicate save mode
    if (this.state.change) this.state.change(true, '', '', '')

    this.setState({
      role: role
    })
  }

  /**
   * Render actual body of dialog
   * @return
   */
  renderData = (editState) => {
    let roles_priority = this.props.roles.map((a) => {
      return a.Permissions.replace(/"/g, '').length
    })
    console.log('RoleDialog:renderData', editState, roles_priority, this.state, this.props)
    let roles = this.props.roles.sort((a, b) => {
      let ao = a.Permissions.length
      let bo = b.Permissions.length
      return ao > bo ? -1 : bo > ao ? 1 : 0
    })
    console.log('RoleDialog:renderData', editState, roles, this.state, this.props)

    return (
      <div className="RoleDialog">
        <div className="RoleDialog__label">Select user role:</div>
        <div className="row ">
          {roles.map((role, i) => (
            <div className="col-xs-4 RoleDialog__role" key={i}>
              <input
                type="radio"
                className="Input_radio"
                id={'role_' + role.Role}
                checked={this.state.role === role.Role}
                onClick={() => this.onRoleChange(role.Role)}
              />
              <label htmlFor={'role_' + role.Role}>
                <EditableEntity
                  dataType={{ name: 'propertyName' }}
                  data={{
                    name: role.Role,
                    description: role.Description + '   Permissions: ' + role.Permissions.replace(/"/g, '')
                  }}
                  dataProps={{}}
                />
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  }

  /**
   *
   * Render function of dialog
   * @return
   */
  render() {
    //console.log("RoleDialog:render", this.props, this.state);

    return (
      <div className="RoleDialog">
        <EditorDialog
          objectType="data"
          modalTitle={this.props.modalTitle}
          editContent={this.renderData.bind(this)}
          isVisible={this.props.isVisible}
          isEditable={this.props.isEditable}
          onClose={this.onClose.bind(this)}
          onSave={this.onSave.bind(this)}
          onEdit={this.onEdit.bind(this)}
          onCancel={this.onCancel.bind(this)}
          hideFullscreenButton
        />
      </div>
    )
  }
}

RoleDialog.propTypes = {
  appState: PropTypes.object,
  modalTitle: PropTypes.string.isRequired,
  objectType: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  actions: PropTypes.object.isRequired,
  role: PropTypes.string, // role to change
  roles: PropTypes.array, // roles in current organization
  onClose: PropTypes.func,
  onSave: PropTypes.func
}

RoleDialog.defaultProps = {}
