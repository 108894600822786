/**
 * Created by mailf on 08.06.2016.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { columnsToType, editableState } from '../../helpers/index'
import { EditorListBase } from '../EditorDialog/EditorListBase'
import { TagDialog } from './TagDialog'
import './TagList.scss'
import { getTailButtonLabel } from '../../helpers/helperComponents'

export class TagList extends EditorListBase {
  /**
   * Create new Item for editing
   * @return {object} empty element
   */
  createItem = (name) => {
    return { name: name || '' }
  }

  /**
   * Return filters requered for item list
   */
  getFilters = () => {
    return [
      { name: '', width: 'calc(40% - 200px)' },
      { name: 'search', width: 260 },
      { name: '', width: 'calc(60% - 260px - 40px)' }
    ]
  }

  /**
   * Return columns requered for item list
   */
  getColumns = () => {
    return [
      {
        name: 'icon',
        displayName: ' ',
        type: columnsToType.getType('data'),
        frozen: true,
        width: 30
      },
      {
        name: 'name',
        displayName: 'Tag Name',
        type: columnsToType.getType('minorObjectName'),
        width: 'calc(40% - 30px)',
        onCellClick: (value) => {
          this.onViewItem(value)
        }
      },
      {
        name: 'description',
        displayName: this.renderDescriptionColumnHeader(),
        type: this.getDescriptionType(),
        width: 'calc(60%)'
      }
    ]
  }

  /**
   * Define rendering of tail buttons
   * @param {number} editState - edit state on dialog
   * @return - array of rendered buttons
   */
  getTailButtons(editState) {
    const canAdd = editState > editableState.EDITABLE
    if (!canAdd) {
      return [
        {
          label: getTailButtonLabel('View'),
          onClick: (obj, index) => this.onViewItem(obj, index)
        },
        { label: '', onClick: (obj, index) => this.onViewItem(obj, index) }
      ]
    }

    return (obj) => {
      let buttons = []
      //logger.info("TagList:getTailButtons", obj, this.props, this.state);
      if (!obj || !obj._item) {
        return buttons
      }

      if (!obj._item.obj && obj._item.base) {
        buttons.push({
          label: getTailButtonLabel('Select'),
          onClick: (obj, index) => this.onSelectItem(obj, index)
        })
        buttons.push({
          label: getTailButtonLabel('View'),
          onClick: (obj, index) => this.onEditItem(obj, index)
        })
      } else if (obj._item.obj && obj._item.base) {
        buttons.push({
          label: getTailButtonLabel('Unselect'),
          onClick: (obj, index) => this.onSelectItem(obj, index)
        })
        buttons.push({
          label: getTailButtonLabel('View'),
          onClick: (obj, index) => this.onEditItem(obj, index)
        })
      } else {
        buttons.push({
          label: getTailButtonLabel('Edit'),
          onClick: (obj, index) => this.onEditItem(obj, index)
        })
        buttons.push({
          label: getTailButtonLabel('Delete'),
          onClick: (obj, index) => this.onDeleteItem(obj, index)
        })
      }
      return buttons
    }
  }

  /**
   * Define rendering of bottom buttons
   * @param {number} editState - edit state on dialog
   * @return - array of rendered buttons
   */
  getBottomButtons(editState) {
    const canAdd = editState > editableState.EDITABLE
    const type = this.props.type

    return canAdd
      ? [
          {
            label: '+ Add ' + type,
            onClick: () => this.onAddItem()
          },
          {
            label: (this.state.selectItem ? '-' : '+') + ' Select ' + type,
            onClick: () => this.onSelectItems()
          }
        ]
      : []
  }

  /**
   * Render dialog for item entry
   */
  renderItemDialog(editState, item, base, isEditable) {
    //logger.info("TagList:renderItemDialog", editState, item, base, isEditable, this.props, this.state);

    return (
      <TagDialog
        appState={this.props.appState}
        actions={this.props.actions}
        level={0.1}
        isVisible
        isEditable={base ? editableState.BROWSABLE : isEditable}
        tag={item.name ? item : base}
        base={null}
        majorObject={this.props.majorObject}
        parentDialog={this.props.parentDialog}
        onClose={this.closeItem}
        onSave={(obj, closeDialog, onSent) => this.saveItem(obj, base, closeDialog, onSent)}
      />
    )
  }
}

TagList.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isEditable: PropTypes.number, // State of editing in dialog
  majorObject: PropTypes.object.isRequired, // Major object we work against
  type: PropTypes.string.isRequired, // Type of object
  items: PropTypes.object.isRequired, // Resource object array
  base: PropTypes.object // Resource object array
}
