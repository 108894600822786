import React, { Component } from 'react'
import { HeaderOld } from '../Header/HeaderOld'
import { Footer } from '../Footer/Footer'

import './style.scss'

export class PrivacyPolicyPage extends Component {
  render() {
    return (
      <div className="PrivacyPolicyPage">
        <HeaderOld
          title="DataHub"
          userState={this.props.userState}
          appState={this.props.appState}
          actions={this.props.actions}
          noAuth
        />

        <div className="PrivacyPolicyPage__content">
          <h1>Privacy Policy</h1>

          <p>
            Our Privacy Policy sets out how we collect, use and disclose "personal information" and other "non-personal
            information" we have gathered about you about you in relation to our “Software”: software product Metucat
            (metucat.com), all Metucat Integrations and `apdaxco.com website. By using our Software, you acknowledge and
            consent to the terms of this privacy policy.
          </p>
          <p>This policy will let you know:</p>
          <ul>
            <li>What information (often referred to as 'data') is collected from you;</li>
            <li>How your information is used;</li>
            <li>With whom your information may be shared;</li>
            <li>How you can access, update, or delete your information.</li>
          </ul>
          <p>
            We will comply with all relevant and applicable legislative requirements, and, in the event of any
            inconsistency, the legislative requirements will override the provisions of this document.
          </p>

          <h2>What data do we collect?</h2>

          <p>We collect data in the following ways:</p>
          <ul>
            <li>Data collected automatically; and</li>
            <li>Data you provide us directly.</li>
          </ul>

          <h2>Data collected automatically</h2>
          <p>
            Our Software does not collect any data automatically. However, the website for the Software does use Google
            Analytics which may collect certain non-personal data. Details of that third-party service can be found
            below under 'Collection of Non-Personal Information' below.
          </p>
          <h2>Data you provide us</h2>
          <p>
            We collect and store your email when you sign up for our Software. We will also keep your contact details if
            you contact us for a particular purpose.
          </p>

          <h2>What is Personal Information?</h2>
          <p>'Personal Information' is information that identifies you as an individual.</p>
          <p>We do not collect this type of information unless you submit it to us directly.</p>
          <p>
            If you contact us with a comment or query, We keep a copy of any personal information you provide. This is
            so that we can respond to your comments. If you contact us directly for any reason, we also keep a copy of
            that correspondence.
          </p>

          <h2>What is Sensitive Information?</h2>
          <p>
            Sensitive information includes information about such things as an individual's racial or ethnic origin,
            political opinions, membership of a political association, religious or philosophical beliefs, membership of
            a professional body, criminal records or health information.
          </p>
          <p>We will not attempt to obtain nor record any sensitive information.</p>

          <h2>Collection of other 'Non-Personal' Information</h2>
          <p>
            Non-personal information is general, non-identifiable and anonymous information that is gathered for
            statistical, analytical and research purposes. Some non-personal information may be gathered while you use
            Our Software's website (found at <a href="https://metucat.com">https://metucat.com</a>).
          </p>
          <p>
            Though we do not use cookies in order to track your movement around the web, Our website uses Google
            Analytics to help analyse how users use these sites. The tool uses cookies to collect Internet log
            information and general visitor behaviour information in an anonymous form. The information generated by the
            cookie about your use of the website, including your IP address, is transmitted to Google. This information
            is then used to evaluate use of our website and to compile statistical reports on website activity.
          </p>
          <p>The Google Analytics privacy policy can be found here: </p>
          <p>
            Google Analytics: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
          </p>

          <h2>Access to your data, and permanent deletion of your data</h2>
          <p>
            We will retain your data only as necessary for our legitimate business interests, such as to comply with our
            legal obligations, resolve disputes, and enforce our agreements.
          </p>
          <p>
            No matter how we come in contact with your data, you have the right to access the data We hold about you and
            to update and/or correct it, subject to certain exceptions.
          </p>
          <h2>Data Subject Requests</h2>
          <p>
            In the event that you send a data subject request to Us, We will respond to that request in accordance with
            any applicable legislation.
          </p>
          <p>
            If you wish to know what data we hold about you, if any, please make out your request to us in writing at
            the address listed below. Please be aware that in order to protect the information in Our database, We will
            require identification from you before releasing any information.
          </p>

          <h2>Disclosure, and Security of Your Information</h2>
          <p>
            We will not sell, trade, rent or disclose any of the personal information you provide to us for any reason,
            without your prior consent. Otherwise, your Personal Information will only be disclosed where required or
            allowable by law.
          </p>
          <p>
            Your Personal Information is stored offsite in a manner that reasonably protects it from misuse, loss,
            unauthorized access, modification or disclosure.
          </p>

          <h2>Policy Updates</h2>
          <p>
            This Policy may change from time to time. The most current version of our Privacy Policy can be found at{' '}
            <a href="http://apdaxco.com/privacy.html">http://apdaxco.com/privacy.html</a>
          </p>
          <p>
            We will always keep this up to date so that you know what information we will collect from you, how we may
            use it, and when we may disclose it.
          </p>

          <h2>Complaints</h2>
          <p>You can lodge a complaint with us about any breach of our Privacy Policy at the address below.</p>

          <h2>Privacy Policy Contact Information</h2>
          <p>
            If you have any questions about our Privacy Policy, please contact us at:{' '}
            <a href="mailto:info@apdaxco.com">info@apdaxco.com</a>
          </p>
          <p>Privacy policy last updated 09 August 2019</p>
        </div>

        <Footer />
      </div>
    )
  }
}
