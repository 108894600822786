import { Box, Avatar, Chip, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import MailIcon from '@material-ui/icons/Mail'
import PeopleIcon from '@material-ui/icons/People'
import LabelImportantIcon from '@material-ui/icons/LabelImportant'
import PaymentIcon from '@material-ui/icons/Payment'

import { OrganizationCardInterface } from './OrganizationsTypes'

import './OrganizationCard.scss'

export const OrganizationCard: React.FC<OrganizationCardInterface> = ({ organization, onAction, cardActions }) => {
  const [roleClass, setRoleClass] = useState('OrgCard__role')

  useEffect(() => {
    organization.status === 'Approved' ? setRoleClass('OrgCard__role') : setRoleClass('OrgCard__role--pending')
  }, [organization.status])

  return (
    <div className="OrgCard mb-20">
      <div className="OrgCard__header">
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Avatar>{organization.name.trim().charAt(0)}</Avatar>
          </Box>
          <a href={`/view/organizations/${organization.name}`}>
            <h2 className="OrgCard__title">{organization.name}</h2> <span>{organization.default}</span>
          </a>
        </Box>

        <div className="OrgCard__group">
          {cardActions.map((action, idx) => {
            return (
              <Button
                key={idx}
                variant="outlined"
                startIcon={action.icon}
                onClick={() => onAction && onAction({ action: action.action, organization })}
              >
                {action.title}
              </Button>
            )
          })}
        </div>
      </div>

      <p className="OrgCard__p">{organization.description}</p>

      <div className="OrgCard__bottom">
        {organization.customProfile && (
          <Chip label={organization.customProfile} icon={<MailIcon />} variant="outlined" size="small" />
        )}
        {organization?.users?.length && (
          <Chip label={organization.users.length} icon={<PeopleIcon />} variant="outlined" size="small" />
        )}
        {organization.role && (
          <Chip label={organization.role} icon={<LabelImportantIcon />} variant="outlined" size="small" />
        )}
        {organization.subStatus && (
          <Chip label={organization.subStatus} icon={<PaymentIcon />} variant="outlined" size="small" />
        )}
        {organization.status && <Chip label={organization.status} className={roleClass} size="small" />}
      </div>
    </div>
  )
}
