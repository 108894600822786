/**
 * Created by mailf on 08.06.2016.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { columnsToType } from '../../helpers/index'
import { EditorListBase } from '../EditorDialog/EditorListBase'
import { CounterDialog } from './CounterDialog'
import './CounterList.scss'

export class CounterList extends EditorListBase {
  /**
   * Create new counter for editing
   * @return {object} empty element
   */
  createItem = (name) => {
    let objs = this.props.items || []

    let order = 0
    if (objs) {
      objs.forEach((f) => {
        order = order < f.order ? f.order : order
      })
    }
    order += 1
    return {
      identity: { name: name || '' },
      order: order,
      type: '',
      configuration: {}
    }
  }

  /**
   * Return filters requered for item list
   */
  getFilters = () => {
    return [
      { name: '', width: 10 },
      { name: 'search', width: 290 },
      { name: 'type', width: 100 }
    ]
  }

  /**
   * Return columns requered for item list
   */
  getColumns = () => {
    return [
      {
        displayName: '#',
        name: 'order',
        type: columnsToType.getType('id'),
        frozen: true,
        width: 40,
        hiddenInChildTable: true
      },
      {
        name: 'name',
        displayName: 'Counter Name',
        type: columnsToType.getType('minorObjectName'),
        width: 180,
        onCellClick: (value) => {
          this.onViewItem(value)
        }
      },
      {
        name: 'description',
        displayName: this.renderDescriptionColumnHeader(),
        type: this.getDescriptionType(),
        width: 290
      },
      {
        name: 'type',
        type: columnsToType.getType('string'),
        frozen: true,
        width: 80
      },
      {
        name: 'reference',
        type: columnsToType.getType('string'),
        frozen: true,
        width: 175
      }
    ]
  }

  /**
   * Return item data to render in table
   * @param {object} obj - item to render
   */
  getItem = (obj, base) => {
    //logger.info("EditorListBase:renderResoueceList:DATA", type, objs);
    return Object.assign({}, obj, {
      oder: obj.order,
      name: {
        name: obj.identity.name,
        objectType: 'counter'
      },
      type: obj.type,
      description: obj.identity.description,
      reference:
        obj.type === 'Custom' && obj.configuration
          ? this.getPropertyName(obj.configuration)
          : obj.expression
          ? obj.expression
          : ''
    })
  }

  /**
   * Render dialog for item entry
   */
  renderItemDialog(editState, item, base, isEditable) {
    //logger.info("CounterList:renderItemDialog", editState, item, base, isEditable, this.props, this.state);

    return (
      <CounterDialog
        appState={this.props.appState}
        actions={this.props.actions}
        isVisible
        isEditable={isEditable}
        counter={item}
        majorObject={this.props.majorObject}
        onClose={this.closeItem}
        onSave={(obj, closeDialog, onSent) => this.saveItem(obj, base, closeDialog, onSent)}
      />
    )
  }
}

CounterList.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isEditable: PropTypes.number, // State of editing in dialog
  majorObject: PropTypes.object.isRequired, // Major object we work against
  type: PropTypes.string.isRequired, // Type of object
  resource: PropTypes.object.isRequired // Resource object array
}
