import PropTypes from 'prop-types'

import React, { Component } from 'react'

import { EditableEntity } from '../EditableEntity/EditableEntity'

export class TypedTableCell extends Component {
  constructor(props) {
    super(props)
  }

  // pure stateless component
  shouldComponentUpdate(newProps) {
    for (let key in newProps) {
      if (this.props[key] !== newProps[key]) {
        return true
      }
    }

    return false
  }

  render() {
    const {
      value,
      type,
      editable,
      editMode,
      rowData,
      colIndex,
      rowIndex,
      col,
      row,
      onChange,
      onChangeRow,
      onBlur,
      firstRowIndex,
      transEnum,
      transView
    } = this.props
    return (
      <EditableEntity
        //link only for 0 colIndex(bottom btn translation)
        link={colIndex === 0 && (transEnum || transView) ? rowData.structurePath : null}
        data={value}
        colIndex={colIndex}
        enumData={transEnum && colIndex === 3 ? JSON.parse(value) : null}
        viewData={transView && (colIndex === 2 || colIndex === 3) ? JSON.parse(value) : null}
        dataType={type}
        isEditable={editable}
        inEditMode={editable && editMode}
        dataProps={{
          onChange: (e) => {
            if (onChange) onChange(colIndex, rowIndex, col, value, e.target.value)
            if (onChangeRow) {
              const newRow = Object.assign({}, rowData, {
                [col.name]: e.target.value
              })
              onChangeRow(rowIndex + (firstRowIndex || 0), rowData, newRow)
            }
          },
          onBlur: (e) => {
            this.props.onDeselect()
            if (this.props.onBlur) this.props.onBlur(colIndex, rowIndex, value)
          }
        }}
        onEditStart={() => this.props.setEditingField({ colIndex: colIndex, rowIndex: rowIndex })}
        onEntityClick={editable || !col.onCellClick ? null : () => col.onCellClick(row, rowIndex)}
      />
    )
  }
}

TypedTableCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  type: PropTypes.object,
  editable: PropTypes.bool,
  editMode: PropTypes.bool,
  rowData: PropTypes.object,
  colIndex: PropTypes.number,
  rowIndex: PropTypes.number,
  col: PropTypes.object,
  row: PropTypes.object,
  onChange: PropTypes.func,
  onChangeRow: PropTypes.func,
  onBlur: PropTypes.func,
  onDeselect: PropTypes.func,
  setEditingField: PropTypes.func,
  firstRowIndex: PropTypes.number
}
