import React from 'react'
import './ScrollArea.scss'

interface IScrollArea {
  children: React.ReactNode
  horizontal?: boolean
  onScroll?: () => void
  isEditing?: boolean
  ref: any
}

export const ScrollArea: React.FC<IScrollArea> = ({ children, horizontal = false, onScroll, isEditing = false }) => {
  const onElScroll = () => {
    onScroll && onScroll()
  }

  const classNames = `ScrollArea ${horizontal ? 'ScrollArea--horizontal' : ''} ${
    isEditing ? 'ScrollArea--editing' : ''
  }`

  return (
    <div className={classNames} onScroll={onElScroll}>
      <div className="ScrollArea__container">{children}</div>
    </div>
  )
}
