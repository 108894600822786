import PropTypes from 'prop-types'
import React from 'react'

import '../../styles/SimpleTooltip/SimpleTooltip.scss'

type SimpleTooltipProps = {
  title: string
  children: JSX.Element
  className?: string
  tipClassName?: string
}

export const SimpleTooltip = ({ title, children, className, tipClassName }: SimpleTooltipProps) => {
  return (
    <div className={'SimpleTooltip__root' + (tipClassName ? tipClassName : '') + (className ? ' ' + className : '')}>
      {title ? (
        <div className={'SimpleTooltip__parent' + (className ? ' ' + className : '')}>
          {children}
          <div className="SimpleTooltip__tooltip">{title}</div>
        </div>
      ) : (
        <div className={className}>{children}</div>
      )}
    </div>
  )
}

SimpleTooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string
}
