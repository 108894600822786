/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../actions/index'
import { getUserPhotoUrl } from '../../helpers'
import { getRequestFromPath } from '../../helpers/api'
import { fetchDataUrlImmediate } from '../../helpers/api'
import { Loader } from '../Loader/Loader'
import './UserSearch.scss'

import defaultUserPicture from '../../resources/images/face-2x.png'

class UserSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      orgUsers: [],
      objectUsers: [],
      focused: false,
      editMode: this.props.editMode,
      loaded: false
    }

    this.queryRef = React.createRef()
  }

  componentDidMount() {
    this.updateUserList()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.objectPath !== this.props.objectPath) {
      this.updateUserList()
    }

    if (prevProps.selectedUser !== this.props.selectedUser && this.state.loaded) {
      this.setSelectedUserName()
    }
  }

  setSelectedUserName() {
    this.setState({
      name: this.getSelectedUser(this.props.selectedUser).profile.alias
    })
  }

  /**
   * requests all users of current object
   */
  updateUserList = (path = this.props.objectPath) => {
    const splitPath = path.split('/')

    const userRequests = []

    if (splitPath.indexOf('organizations') < splitPath.length - 2 || this.props.department)
      userRequests.push(this.getObjectUsers())

    userRequests.push(this.getOrganizationUsers())
    Promise.all(userRequests).then(() => {
      if (this.props.selectedUser) {
        this.setState({
          name:
            typeof this.props.selectedUser === 'string'
              ? this.getSelectedUser(this.props.selectedUser).profile.alias
              : this.props.selectedUser.profile.alias
        })
      }
      this.setState({ loaded: true })
    })
  }

  /**
   * requests list of users of object
   */
  getObjectUsers = () => {
    const req = getRequestFromPath(this.props.department || this.props.objectPath)

    return new Promise((resolve, reject) => {
      req
        .users()
        .get()
        .then((users) => {
          this.setState(
            {
              objectUsers: users
            },
            () => resolve()
          )
        })
    })
  }

  /**
   * requests list of users of object's parent organization
   */
  getOrganizationUsers = () => {
    const splitPath = this.props.objectPath.split('/')
    const pathToOrg = splitPath.slice(0, 3).join('/')
    const req = getRequestFromPath(pathToOrg)

    return new Promise((resolve, reject) => {
      req
        .users()
        .get()
        .then((users) => {
          this.setState(
            {
              orgUsers: users
            },
            () => resolve()
          )
        })
    })
  }

  /**
   * get selected user info from user list by id
   */
  getSelectedUser = (id) => {
    if (typeof id === 'object') {
      return id
    }

    const allUsers = (this.state.objectUsers || []).concat(this.state.orgUsers || [])
    if (this.props.firstOption) {
      allUsers.unshift(this.props.firstOption)
    }

    for (let i = 0; i < allUsers.length; i++) {
      const u = allUsers[i]
      if (u.profile.identity.id.toLowerCase() === id) {
        return u
      }
    }

    // if user with id is not found, return firstOption
    if (this.props.firstOption) {
      return this.props.firstOption
    }

    return false
  }

  /**
   *  check that user is not in object users list
   **/
  notInObjectUsers = (user) => {
    for (let i = 0; i < this.state.objectUsers.length; i++) {
      const u = this.state.objectUsers[i]

      if (user.user.id === u.user.id) {
        return false
      }
    }

    return true
  }

  hasInAlias = (str) => {
    return (u) => {
      return u.profile.alias && u.profile.alias.indexOf(str) >= 0
    }
  }

  /**
   * Generates function that check that user has case-insensitive substring in first name, last name or alias
   *
   */
  hasInAliasOrName = (str) => {
    if (!str) return (u) => true

    str = str.toLowerCase()

    return (u) => {
      return (
        u.profile.identity.name.toLowerCase().indexOf(str) >= 0 ||
        (u.profile.alias && u.profile.alias.toLowerCase().indexOf(str) >= 0) ||
        (u.profile.firstname && u.profile.firstname.toLowerCase().indexOf(str) >= 0) ||
        (u.profile.lastname && u.profile.lastname.toLowerCase().indexOf(str) >= 0)
      )
    }
  }

  applyFilters = (users, ...filters) => {
    return users.filter((u) => {
      for (let i = 0; i < filters.length; i++) {
        const f = filters[i]

        if (!f(u)) {
          return false
        }
      }

      return true
    })
  }

  toggleEditMode = () => {
    //console.log("UserSearch:toggleEditMode", this.state.editMode);
    if (this.props.onAdjust) {
      this.props.onAdjust(0, (this.state.editMode ? -1 : 1) * (5 + this.state.orgUsers.length * 48))
    }

    this.setState(
      {
        editMode: !this.state.editMode
      },
      () => {
        if (this.state.editMode) {
          this.queryRef.current.focus()
        }
      }
    )
  }

  /**
   * Sorts users by first name and last name
   */
  userSort = (a, b) => {
    if (a.profile.firstname + ' ' + a.profile.lastname > b.profile.firstname + ' ' + b.profile.lastname) {
      return 1
    } else if (a.profile.firstname + ' ' + a.profile.lastname < b.profile.firstname + ' ' + b.profile.lastname) {
      return -1
    }
    return 0
  }

  renderSuggestionUser = (user) => {
    let style = {
      backgroundImage:
        'url(' +
        fetchDataUrlImmediate(getUserPhotoUrl(user.profile.identity.id), this.forceUpdate.bind(this)) +
        '), url(' +
        defaultUserPicture +
        ')'
    }

    //console.log("UserSearch:renderSuggestionUser", user);
    return (
      <li
        className="UserSearch__suggestedUser"
        onMouseDown={() => {
          //this.setState({
          //focused: false
          //});

          //this.toggleEditMode();

          //console.log("UserSearch:renderSuggestionUser:CLICK", user);
          if (this.props.onAdjust) {
            this.props.onAdjust(0, (this.state.editMode ? -1 : 1) * (5 + this.state.orgUsers.length * 48))
          }
          this.setState({
            focused: false,
            editMode: !this.state.editMode
          })

          if (this.props.onSelect) {
            this.props.onSelect(user)
          }
        }}
      >
        <div className="UserSearch__suggestedPicture" style={style}></div>
        <div className="UserSearchResult__content">
          <div className="UserSearchResult__col1">
            <div className="UserSearchResult__name">
              {user.profile.firstname
                ? user.profile.firstname + (user.profile.lastname ? ' ' + user.profile.lastname : '')
                : 'Anonymous'}
            </div>
            <div className="UserSearchResult__alias">
              {user.profile.alias ? user.profile.alias : user.profile.identity.name}
            </div>
          </div>
          <div className="UserSearchResult__col2">
            <div className="UserSearchResult__email">
              {user.profile.alias && user.profile.alias !== user.profile.email
                ? user.profile.email
                : user.profile.identity.name}
            </div>
          </div>
        </div>
      </li>
    )
  }

  renderSuggestionsList = (users) => {
    return <ul className="UserSearch__suggestionsList">{users.map(this.renderSuggestionUser)}</ul>
  }

  renderSelectedUser = (user) => {
    if (!user)
      return (
        <div className="UserSearch__user" onClick={this.toggleEditMode}>
          Click to edit
        </div>
      )
    let style = {
      backgroundImage:
        'url(' +
        fetchDataUrlImmediate(getUserPhotoUrl(user.profile.identity.id), this.forceUpdate.bind(this)) +
        '), url(' +
        defaultUserPicture +
        ')'
    }
    return (
      <div className="UserSearch__user" onClick={this.toggleEditMode}>
        {/*Picture, Alias+occupancy, e-mail, role*/}
        <div className="UserSearch__userPicture">
          <div className="UserSearch__suggestedPicture" style={style}></div>
        </div>
        <div className="UserSearch__userIdentity">
          <div className="UserSearch__userName">
            {user.profile.firstname
              ? user.profile.firstname + (user.profile.lastname ? ' ' + user.profile.lastname : '')
              : 'Anonymous'}
          </div>
          <div>{user.profile.alias ? user.profile.alias : user.profile.identity.name}</div>
        </div>
        <div className="UserSearch__userEmail">
          <span>{user.profile.alias && user.profile.alias !== user.profile.email ? user.profile.email : ''}</span>
        </div>
        <span className="UserSearch__userChange">Change</span>
      </div>
    )
  }

  render() {
    const objResults = []
    const orgResults = this.applyFilters(
      this.state.orgUsers,
      this.props.allowObjectUsers ? () => true : this.notInObjectUsers,
      this.hasInAliasOrName(this.state.name)
    ).sort(this.userSort)
    let user = false
    if (this.props.firstOption) {
      orgResults.unshift(this.props.firstOption)
    }

    if (this.props.selectedUser !== undefined) {
      user =
        typeof this.props.selectedUser === 'string'
          ? this.getSelectedUser(this.props.selectedUser)
          : this.props.selectedUser
    }

    //console.log("UserSearch:render", user, this.state);
    return (
      <div>
        {this.state.loaded ? (
          <div className="UserSearch clearfix">
            {this.state.editMode ? (
              <div className="UserSearch__inputBlock">
                <input
                  type="text"
                  placeholder={this.props.placeholder}
                  className="TextInput UserSearch__TextInput"
                  ref={this.queryRef}
                  value={this.state.name}
                  onChange={(event) => {
                    this.setState({ name: event.target.value }, () => {
                      // eslint-disable-next-line no-unused-expressions
                      this.props.onChange ? this.props.onChange(this.state.name) : null
                    })
                  }}
                  onFocus={() => {
                    this.setState({
                      focused: true,
                      name: ''
                    })
                  }}
                />

                {this.state.focused ? (
                  <div
                    className="UserSearch__close"
                    onClick={() => {
                      if (this.state.focused) {
                        //console.log("UserSearch:render:BLUR", user, this.state);
                        if (this.props.onAdjust) {
                          this.props.onAdjust(0, (this.state.editMode ? -1 : 1) * (5 + this.state.orgUsers.length * 48))
                        }
                        this.setState({
                          focused: false,
                          editMode: !this.state.editMode
                        })
                        if (this.props.onSelect) this.props.onSelect(user)
                      }
                    }}
                  >
                    <a className="">Close</a>
                  </div>
                ) : null}
              </div>
            ) : (
              this.renderSelectedUser(user)
            )}
            {this.state.focused && (objResults.length || orgResults.length) ? (
              <div className="UserSearch__suggestions">
                <div className="UserSearch__suggestionsBlock">
                  <div className="UserSearch__suggestionsTitle">Organization users</div>
                  {this.renderSuggestionsList(orgResults)}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="UserSearch">
            <Loader />
          </div>
        )}
      </div>
    )
  }
}

UserSearch.propTypes = {
  objectPath: PropTypes.string.isRequired,
  actions: PropTypes.object,
  appState: PropTypes.object,
  selectedUser: PropTypes.string, // Id of selected user
  allowObjectUsers: PropTypes.bool,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  onAdjust: PropTypes.func,
  editMode: PropTypes.bool,
  placeholder: PropTypes.string,
  firstOption: PropTypes.object, // Option that should be pinned on top of list
  department: PropTypes.string
}

function mapStateToProps(state) {
  return {
    appState: state.appState
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSearch)
