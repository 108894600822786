import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { cloneDeep, get } from 'lodash'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { useParams } from 'react-router-dom'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DoneIcon from '@material-ui/icons/Done'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'

import { API, getObjectListNew, getRequestFromPath, idAPI } from '../../helpers/api'
import { updateUserState } from '../../actions/userActions'
import { WithSideBar } from '../../layouts/WithSideBar/WithSideBar'
import KanbanBoard from './KanbanBoard'
import {
  getFullUri,
  getIssueAbsoluteDeadline,
  getIssuePropByName,
  objectNameFromPathByType,
  objectTypeIndex
} from '../../helpers'
import { clearObjectList, receiveObjectList, receiveObjectListIntoProperty } from '../../actions/majorObjectActions'
import { IRootState } from '../../reducers/rootState'
import { KanbanHeader } from './KanbanHeader'
import { Issue } from '../../components/Issue/Issue'
import { useKeyPress } from '../../hooks/useKeyPress'
import './KanbanBoardPage.scss'
import { clearIssue, prepareMessage } from '../../components/IssueDialog/issueDialogHelper'
import { message } from './kanbanHelper'
import { httpClient } from '../../helpers/httpClient'

export const KanbanBoardPage: React.FC<any> = (props) => {
  const dispatch = useDispatch()
  const [loadingIssues, setLoadingIssues] = useState<boolean>(false)
  const [issues, setIssues] = useState<any>([])
  const [editingIssue, setEditingIssue] = useState<any>()
  const { organizationName } = useParams<{ organizationName: string }>()
  const [defaultIssueText, setDefaultIssueText] = useState<string>('')
  const [editingIssueParent, setEditingIssueParent] = useState<any>(null)
  const [editingIssueParentType, setEditingIssueParentType] = useState<any>()
  const [orgs, setOrgs] = useState<any[]>([])
  const [selectedOrg, setSelectedOrg] = useState<string>(localStorage['currentOrganizationName'] ?? '')

  const [currentAssignedUser, setCurrentAssignedUser] = useState<any>(null)

  const [loading, setLoading] = useState(false)
  const { organizations } = useSelector((state: IRootState) => state.appState)
  const [showMy, setShowMy] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [selectedIssues, setSelectedIssues] = useState<any[]>([])
  const [mappedIssues, setMappedIssues] = useState([])
  const [filteredMappedIssues, setFilteredMappedIssues] = useState([])
  const { userAppState } = useSelector((state: IRootState) => state)
  const metaPress = useKeyPress('Meta')

  const [issueFilters, setIssueFilters] = useState<any>({
    assigned: false,
    priority: [0, 1, 2, 3, 4],
    type: [],
    deadline: ['red', 'yellow', 'green'],
    status: ['Opened', 'Assigned', 'Investigated', 'Triaged', 'Resolved'],
    tag: []
  })

  const getCurrentUser = useCallback(async () => {
    const user = await idAPI.users(localStorage.currentUserId).get()
    dispatch(updateUserState(user))
  }, [dispatch])

  useEffect(() => {
    getCurrentUser()
  }, [getCurrentUser])

  useEffect(() => {
    setSelectedOrg(organizationName)
  }, [organizationName])

  const getOrganizations = useCallback(
    async (forceReload = false) => {
      setLoading(true)
      dispatch(clearObjectList('organization'))

      const orgList = await getObjectListNew(API.organizations, 'organization', props.actions, forceReload)
      dispatch(receiveObjectList('organization', orgList))

      orgList.forEach((org) => {
        if (!org.userRoleApproved) {
          getObjectListNew(API.organizations(org.identity.name).users, 'user', null).then((users) =>
            dispatch(receiveObjectListIntoProperty(users, org, 'organization', 'users'))
          )
        }
      })

      setLoading(false)
    },
    [dispatch, props.actions]
  )

  useEffect(() => {
    getOrganizations()
  }, [getOrganizations])

  useEffect(() => {
    setOrgs(organizations)
  }, [organizations])

  const convertIssue = (rawIssue) => {
    if (!rawIssue.object) return null
    let fullType = getIssuePropByName(rawIssue.type, 'type')
    return Object.assign(rawIssue, {
      fullType: fullType
        ? fullType
        : {
            type: 'Discussion',
            description: 'Discussion of object design.',
            code: 0,
            period: 1.0
          },
      fullPriority: getIssuePropByName(rawIssue.priority, 'priority'),
      fullStatus: getIssuePropByName(rawIssue.status, 'status'),
      time: rawIssue.object.history.created,
      assigned: rawIssue.assigned,
      object: {
        parent: rawIssue.object && rawIssue.object.parent ? rawIssue.object.parent : {},
        tags: rawIssue.object && rawIssue.object.tags ? rawIssue.object.tags : [],
        documents: rawIssue.object && rawIssue.object.documents ? rawIssue.object.documents : [],
        properties: rawIssue.object && rawIssue.object.properties ? rawIssue.object.properties : [],
        history: rawIssue.object ? rawIssue.object.history : {}
      }
    })
  }

  const getIssueCards = (issues) => {
    if (!props.userState || !props.userState.identity) return []
    if (props.isFixed) return []

    return issues.map((issue) => ({
      ...issue,
      id: issue.identity.id,
      name: issue.identity.name,
      description: issue.identity.description || '',
      attached: issue.attached,
      systemName: objectNameFromPathByType(issue.attached.name, 'systems'),
      applicationName: objectNameFromPathByType(issue.attached.name, 'applications'),
      datasetName: objectNameFromPathByType(issue.attached.name, 'datasets'),
      deadline: getIssueAbsoluteDeadline(issue),
      priority: issue.fullPriority,
      date: moment(issue.time).format('DD.MM.YY HH:mm:ss'),
      time: issue.time,
      isTracked: (issue.trackers || []).reduce((p, c) => (c.id === props.userState.identity.id ? true : p), false)
    }))
  }

  const requestIssues = useCallback(async (selectedOrg) => {
    const objUri = `/organizations/${selectedOrg}`
    const req = getRequestFromPath(objUri ? objUri : getFullUri(props.object)).issues

    props.actions.clearObjectList('issue')

    const params: any = {}

    if (issueFilters.assigned) params.assignedid = localStorage['currentUserId']
    if (issueFilters.status.length) {
      params.status = {
        value: issueFilters.status.reduce((prev, cur) => prev + '&status=' + cur),
        noEncode: true
      }
    }

    setLoadingIssues(true)
    const issues = await getObjectListNew(req, 'issue', null, false, params)

    const attachedIssues = issues.filter((issue) => issue?.attached?.name)
    let convertedIssues = attachedIssues.map(convertIssue).filter((issue) => issue !== null)

    await setIssues(convertedIssues)
    await setLoadingIssues(false)
  }, [])

  useEffect(() => {
    const cards = getIssueCards(issues)
    setMappedIssues(cards)
    setFilteredMappedIssues(cards)
  }, [issues])

  useEffect(() => {
    if (selectedOrg) {
      requestIssues(selectedOrg)
    }
  }, [selectedOrg])

  const handleClickCardOpen = (item) => {
    if (metaPress) {
      const issueIndex = selectedIssues.findIndex((issue) => issue.identity.id === item.identity.id)
      if (issueIndex === -1) {
        setSelectedIssues([...selectedIssues, item])
      } else {
        const newIssues = [...selectedIssues]
        newIssues.splice(issueIndex, 1)
        setSelectedIssues(newIssues)
      }
    } else {
      setOpen(true)
      setSelectedItem(item)
    }
  }

  const handleCardClose = () => {
    setOpen(false)
  }

  const editIssue = (issue) => {
    return () => {
      const issuePathSplit = issue._path.split('/')

      const issueParentTypePlural =
        issuePathSplit[
          objectTypeIndex(issue._path, [
            'Initiatives',
            'issues',
            'Features',
            'Discussions',
            'WorkItems',
            'Support Requests',
            'Design Suggestions',
            'Design Problems',
            'Code Defects'
          ]) - 2
        ]
      const issueParentType = issueParentTypePlural
        ? issueParentTypePlural.slice(0, issueParentTypePlural.length - 1)
        : 'organization'

      setEditingIssue(issue)
      setDefaultIssueText('')
      setEditingIssueParent({
        identity: { id: issue.attached.id },
        _path: issue.attached.name
      })
      setEditingIssueParentType(issueParentType)
    }
  }

  const handleFilterChange = (filters: any[]) => {
    let newIssues = cloneDeep(mappedIssues)
    for (const filter of filters) {
      if (filter.value.length) {
        newIssues = newIssues.filter((issue: any) => filter.value.includes(get(issue, filter.path)))
      }
    }
    setFilteredMappedIssues(newIssues)
  }

  const handleDone = () => {
    const requests = selectedIssues.map((selectedIssue) => {
      const issueToSend = clearIssue(selectedIssue)
      issueToSend.status = 'Closed'

      const objectToSend = prepareMessage(issueToSend, selectedIssue, userAppState, message)

      return httpClient.post(`/organizations/${selectedOrg}/messages`, objectToSend, {})
    })
    const removedIds = selectedIssues.map((issue) => issue.identity.id)
    Promise.all(requests).then(() => {
      const newIssues = filteredMappedIssues.filter((issue: any) => !removedIds.includes(issue.identity.id))
      setMappedIssues(newIssues)
      setFilteredMappedIssues(newIssues)
    })
  }

  return (
    <WithSideBar {...props}>
      <Box display="flex" minHeight="100vh">
        <Grid item xs>
          <KanbanHeader
            orgs={orgs}
            issues={mappedIssues}
            selectedOrg={selectedOrg}
            showMy={showMy}
            setShowMy={setShowMy}
            onFiltersChange={handleFilterChange}
          />

          <KanbanBoard
            issues={filteredMappedIssues}
            selectedOrg={selectedOrg}
            selectedIssues={selectedIssues}
            columns={issueFilters.status}
            {...props}
            onCardClick={handleClickCardOpen}
          />

          {selectedItem && (
            <Dialog
              fullWidth={true}
              maxWidth={'lg'}
              open={open}
              onClose={handleCardClose}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogTitle id="max-width-dialog-title">Issue: </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Issue
                    majorObject={Object.assign({}, selectedItem, {
                      assigned: currentAssignedUser ?? { id: '' }
                    })}
                    actions={props.actions}
                    onEdit={editIssue}
                    handleIssueCollapseButton={() => {}}
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCardClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}

          {Boolean(selectedIssues.length) && (
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <div className="toolbar">
                <div style={{ paddingLeft: '25px', paddingRight: '25px', display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '24px' }}>
                    <div style={{ fontSize: '14px', fontWeight: 600 }}>{selectedIssues.length} selected issues</div>
                  </div>
                  <div>
                    <IconButton onClick={handleDone}>
                      <DoneIcon fontSize="small" style={{ fill: 'white' }} />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Grid>
      </Box>
    </WithSideBar>
  )
}
