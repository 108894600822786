/**
 * Created by mailf on 03.06.2016.
 */

const initialState = {
  account: {
    accountName: 'ClientCompany',
    userrole: [
      { id: 1, name: 'Alexis Sanchez', role: 'developer' },
      { id: 2, name: 'Antoine Griezmann', role: 'developer' },
      {
        id: 1,
        name: 'Jack Wilshere',
        role: 'architect'
      },
      {
        id: 2,
        name: 'Aaron Ramsey',
        role: 'architect'
      },
      {
        id: 3,
        name: 'Saul',
        role: 'architect'
      },
      {
        id: 4,
        name: 'Walter White',
        role: 'architect'
      },
      {
        id: 5,
        name: 'Jessie Pinkman',
        role: 'architect'
      },
      {
        id: 6,
        name: 'John Snow',
        role: 'architect'
      }
    ]
  },
  applications: [
    {
      identity: {
        id: 21,
        name: 'Some Application',
        description: 'Long almost neverending text that describes application in every detail'
      },
      rating: {
        good: 15,
        bad: 3
      },
      history: {
        created: '05/17/2016',
        createdBy: 'sashaberger@hotmail.com'
      },
      tags: ['Data set', 'Very long tag with many words', 'Short'],
      version: '1.25',
      deployment: 1460117435,
      userrole: [
        {
          id: 11,
          name: 'Team1 Developer',
          alias: '',
          email: 'developer-asa@email.com',
          role: 'developer',
          linkedin: 'http://linkedin.com/',
          linkedinText: 'John Some',
          phone: '+7373737373737',
          organization: 'Some Organization'
        },
        {
          id: 12,
          name: 'Team2 Developer',
          alias: 'Test long dev',
          email: 'team2@email.com',
          role: 'developer'
        },
        {
          id: 13,
          name: 'Team3 Developer',
          alias: 'Test a longer dev 3',
          email: 'team3@email.com',
          role: 'developer'
        },
        {
          id: 14,
          name: 'Team4 Developer',
          alias: 'Test long long dev 4',
          email: 'team4@email.com',
          role: 'developer'
        },
        {
          id: 15,
          name: 'Some Person',
          alias: '',
          email: 'some@email.com',
          role: 'architect'
        },
        {
          id: 16,
          name: 'Another One',
          alias: '',
          email: 'another@email.com',
          role: 'architect'
        }
      ],
      locale: 'ru_RU',
      locales: ['en_US', 'ru_RU', 'fr_FR'],
      docs: ['Some document title', 'AnotherDoc', 'Some document title', 'Some document title', 'AnotherDoc'],
      /*[
      {
        name: 'Some document title',
        url: '/assets/doc.doc'
      }, {
        name: 'AnotherDoc',
        url: '/assets/doc.doc'
      }, {
        name: 'Some document title',
        url: '/assets/doc.doc'
      }, {
        name: 'AnotherDoc',
        url: '/assets/doc.doc'
      }, {
        name: 'Some document title',
        url: '/assets/doc.doc'
      }, {
        name: 'AnotherDoc',
        url: '/assets/doc.doc'
      }
    ],
    */
      datasets: [1, 2, 3, 10],
      pipelines: [1],
      interfaces: [2, 3],
      publications: [3, 4, 5, 6],
      properties: [
        {
          id: 1,
          name: 'e-mail',
          value: 'name@someorg.com'
        },
        {
          id: 2,
          name: 'Phone',
          value: '+79874563654'
        },
        {
          id: 3,
          name: 'URL',
          value: 'someorg.com/api/somepath'
        }
      ],
      settings: {
        version: {
          major: 1,
          minor: 2,
          revision: 5
        },
        versions: [
          { major: 1, minor: 2, revision: 4 },
          { major: 1, minor: 2, revision: 3 },
          { major: 1, minor: 2, revision: 2 },
          { major: 1, minor: 2, revision: 1 },
          { major: 1, minor: 2, revision: 0 },
          { major: 1, minor: 1, revision: 0 },
          { major: 0, minor: 1, revision: 0 }
        ],
        data: [
          {
            id: 1,
            name: 'Some setting',
            type: { name: 'string' },
            value: 'Some value'
          },
          {
            id: 2,
            name: 'Other one',
            type: {
              name: 'enum',
              options: [
                { label: 'Some value', value: 'Some value' },
                { label: 'Next value', value: 'Next value' }
              ]
            },
            value: 'Some value'
          }
        ]
      },
      reviews: [
        {
          date: '05.05.2015',
          name: 'Johh Smith',
          text:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '06.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '07.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '08.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '09.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '10.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '11.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '12.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '13.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '14.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
      ],
      messages: [
        {
          date: '05.05.2015',
          name: 'Johh Smith',
          text:
            'I ads asdsd aLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '06.05.2015',
          name: 'Johh Smith',
          text:
            'Ye skald sadklasda asdasdsa asdasdas Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '06.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '07.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '08.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '09.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '10.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '11.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '12.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        },
        {
          date: '13.05.2015',
          name: 'Johh Smith',
          text:
            'Sadas sadas Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
        }
      ]
    },
    {
      identity: {
        id: 22,
        name: 'Other application',
        description: ''
      },
      tags: ['Data set', 'Very long tag with many words', 'Short'],
      version: '1.25',
      deployment: 1460117435,
      userrole: [],
      locale: 'en_US',
      locales: ['en_US', 'ru_RU'],
      docs: [],
      datasets: [2, 3],
      pipeline: 1,
      interfaces: [2],
      publications: [5, 6],
      properties: [],
      settings: []
    },
    {
      identity: {
        id: 23,
        name: 'Other application II',
        description: 'Long almost neverending text that describes application in every detail'
      },
      tags: ['Data set', 'Very long tag with many words', 'Short'],
      version: '1.25',
      deployment: 1460117435,
      userrole: [],
      locale: 'ru_RU',
      locales: ['ru_RU', 'fr_FR'],
      docs: [],
      datasets: [2, 3],
      pipeline: 1,
      interfaces: [2],
      publications: [3, 6],
      properties: [],
      settings: []
    },
    {
      identity: {
        id: 24,
        name: 'Other application III',
        description: 'Long almost neverending text that describes application in every detail'
      },
      tags: ['Data set', 'Very long tag with many words', 'Short'],
      version: '1.25',
      deployment: 1460117435,
      userrole: [],
      docs: [],
      datasets: [2, 3],
      pipeline: 1,
      interfaces: [2],
      publications: [3, 4, 5, 6],
      properties: [],
      settings: []
    },
    {
      identity: {
        id: 25,
        name: 'A Other application 4',
        description: 'Long almost neverending text that describes application in every detail'
      },
      tags: ['Data set', 'Very long tag with many words', 'Short'],
      version: '1.25',
      deployment: 1460117435,
      userrole: [],
      docs: [],
      datasets: [2, 3],
      pipeline: 1,
      interfaces: [2],
      publications: [3, 4, 6],
      properties: [],
      settings: []
    },
    {
      identity: {
        id: 26,
        name: 'B Other application',
        description: 'Long almost neverending text that describes application in every detail'
      },
      tags: ['Data set', 'Very long tag with many words', 'Short'],
      version: '1.25',
      deployment: 1460117435,
      userrole: [],
      docs: [],
      datasets: [2, 3],
      pipeline: 1,
      interfaces: [2],
      publications: [],
      properties: [],
      settings: []
    },
    {
      identity: {
        id: 27,
        name: 'Cool Other application',
        description: 'Long almost neverending text that describes application in every detail'
      },
      tags: ['Data set', 'Very long tag with many words', 'Short'],
      version: '1.25',
      deployment: 1460117435,
      userrole: [],
      docs: [],
      datasets: [],
      pipeline: 1,
      interfaces: [],
      publications: [6],
      properties: [],
      settings: []
    },
    {
      identity: {
        id: 28,
        name: 'D Other application',
        description: 'Long almost neverending text that describes application in every detail'
      },
      tags: ['Data set', 'Very long tag with many words', 'Short'],
      version: '1.25',
      deployment: 1460117435,
      userrole: [],
      docs: [],
      datasets: [],
      pipeline: 1,
      interfaces: [],
      publications: [3, 4, 5, 6],
      properties: [],
      settings: [],
      policies: [1, 2]
    },
    {
      identity: {
        id: 29,
        name: 'Extra Other application',
        description: 'Long almost neverending text that describes application in every detail'
      },
      tags: ['Data set', 'Very long tag with many words', 'Short'],
      version: '1.25',
      deployment: 1460117435,
      userrole: [],
      docs: [],
      datasets: [],
      pipeline: 1,
      interfaces: [],
      publications: [6, 4, 3],
      properties: [],
      settings: []
    },
    {
      identity: {
        id: 30,
        name: 'F Other application',
        description: 'Long almost neverending text that describes application in every detail'
      },
      tags: ['Data set', 'Very long tag with many words', 'Short'],
      version: '1.25',
      deployment: 1460117435,
      userrole: [],
      docs: [],
      datasets: [],
      pipeline: 1,
      interfaces: [],
      publications: [6],
      properties: [],
      settings: []
    },
    {
      identity: {
        id: 31,
        name: 'G Other application',
        description: 'Long almost neverending text that describes application in every detail'
      },
      tags: ['Data set', 'Very long tag with many words', 'Short'],
      version: '1.25',
      deployment: 1460117435,
      userrole: [],
      docs: [],
      datasets: [],
      pipeline: 1,
      interfaces: [],
      publications: [3, 6],
      properties: [],
      settings: []
    }
  ],
  datasets: [
    {
      identity: {
        id: 1,
        name: 'First dataset name',
        description:
          'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia.'
      },
      version: {
        major: 2,
        minor: 2,
        revision: 1
      },
      // deployment: 1460117548,
      userrole: [
        {
          id: 117,
          name: 'John Boggle',
          email: 'john@boogle.com',
          alias: 'test sadas sadas',
          role: 'developer'
        },
        {
          id: 17,
          name: 'John Boggle',
          email: 'john@boogle.com',
          alias: 'test sadas sadas',
          role: 'architect'
        },
        {
          id: 18,
          name: 'John Boogle',
          email: 'j@test.com',
          alias: '',
          role: 'architect'
        },
        {
          id: 19,
          name: 'John Boogle II',
          email: 'j@test.com',
          alias: '',
          role: 'architect'
        }
      ],
      locale: 'en_US',
      locales: ['en_US', 'ru_RU', 'fr_FR'],
      rating: {
        good: 5,
        bad: 3
      },
      settings: {
        version: {
          major: 1,
          minor: 2,
          revision: 5
        },
        data: []
      },
      subscriptions: [23, 543],
      tags: ['one', 'two', 'customers'],
      structure: {
        fields: [
          {
            key: 'PRIMARY',
            id: 1,
            name: 'Country name',
            description: 'Full name of country',
            type: 'STRING',
            size: '256b',
            updated: 1462879008428,
            updatedBy: 'John Boggle',
            tags: ['TagOne', 'TagThree']
          },
          {
            key: 'SECONDARY',
            id: 2,
            name: 'Linked Structure',
            reference: [
              {
                id: 1,
                name: 'Some name',
                description: 'Description of value that used in connected structure',
                type: 'ENUM',
                size: '256b',
                updated: 1455656844,
                updatedBy: 'Mick Jagger',
                tags: ['Inserted']
              },
              {
                id: 2,
                name: 'Filed name',
                description: 'Description of field that used in connected structure',
                type: 'STRING',
                size: '256b',
                updated: 14556565854,
                updatedBy: 'Brian May',
                tags: ['Inserted']
              },
              {
                id: 3,
                name: 'Second linked',
                reference: [
                  {
                    id: 1,
                    name: 'Second linked name',
                    description: 'Second linked description',
                    type: 'text',
                    size: '255b',
                    updated: 54637281,
                    updatedBy: 'Tyler Durden',
                    tags: []
                  },
                  {
                    id: 2,
                    name: 'Second linked name',
                    description: 'Second linked description',
                    type: 'text',
                    size: '255b',
                    updated: 54637281,
                    updatedBy: 'Tyler Durden',
                    tags: []
                  }
                ],
                description: 'Description of value that used in connected structure',
                type: 'STRING',
                size: '256b',
                updated: 145565658544,
                updatedBy: 'Mike Wazowski',
                tags: ['Inserted']
              }
            ],
            description: 'Country main language',
            type: 'STRING',
            size: '256b',
            updated: 1462879008428,
            updatedBy: 'John Boggle',
            tags: ['TagOne', 'TagTwo']
          },
          {
            key: 'NO',
            id: 3,
            name: 'Description',
            description: 'Short description of country taken from Wikipedia',
            type: 'STRING',
            size: '2kb',
            updated: 1462879008428,
            updatedBy: 'John Boggle',
            tags: ['TagTwo', 'TagThree']
          }
        ],
        keys: [
          {
            id: 1,
            key: 'PRIMARY',
            name: 'Some primary key',
            fields: [1]
          },
          {
            id: 2,
            key: 'SECONDARY',
            name: 'Some secondary key',
            fields: [2, 3]
          }
        ]
      },
      layouts: [1, 2],
      data: [
        {
          id: 1,
          countryName: 'USA',
          language: 'US English',
          description:
            'The United States of America (USA), commonly referred to as the United States (U.S.) or America, is a federal republic composed of 50 states, the federal district of Washington, D.C., five major territories, and various possessions.'
        },
        {
          id: 2,
          countryName: 'Russia',
          language: 'Russian',
          description:
            "Russia - a sovereign state in northern Eurasia. It is a federal semi-presidential republic. At 17,125,200 square kilometres (6,612,100 sq mi), Russia is the largest country in the world, covering more than one-eighth of the Earth's inhabited land area."
        },
        {
          id: 3,
          countryName: 'USA',
          language: 'US English',
          description:
            'The United States of America (USA), commonly referred to as the United States (U.S.) or America, is a federal republic composed of 50 states, the federal district of Washington, D.C., five major territories, and various possessions.'
        },
        {
          id: 4,
          countryName: 'Russia',
          language: 'Russian',
          description:
            "Russia - a sovereign state in northern Eurasia. It is a federal semi-presidential republic. At 17,125,200 square kilometres (6,612,100 sq mi), Russia is the largest country in the world, covering more than one-eighth of the Earth's inhabited land area."
        },
        {
          id: 5,
          countryName: 'USA',
          language: 'US English',
          description:
            'The United States of America (USA), commonly referred to as the United States (U.S.) or America, is a federal republic composed of 50 states, the federal district of Washington, D.C., five major territories, and various possessions.'
        },
        {
          id: 6,
          countryName: 'Russia',
          language: 'Russian',
          description:
            "Russia - a sovereign state in northern Eurasia. It is a federal semi-presidential republic. At 17,125,200 square kilometres (6,612,100 sq mi), Russia is the largest country in the world, covering more than one-eighth of the Earth's inhabited land area."
        },
        {
          id: 7,
          countryName: 'USA',
          language: 'US English',
          description:
            'The United States of America (USA), commonly referred to as the United States (U.S.) or America, is a federal republic composed of 50 states, the federal district of Washington, D.C., five major territories, and various possessions.'
        },
        {
          id: 8,
          countryName: 'Russia',
          language: 'Russian',
          description:
            "Russia - a sovereign state in northern Eurasia. It is a federal semi-presidential republic. At 17,125,200 square kilometres (6,612,100 sq mi), Russia is the largest country in the world, covering more than one-eighth of the Earth's inhabited land area."
        },
        {
          id: 9,
          countryName: 'USA',
          language: 'US English',
          description:
            'The United States of America (USA), commonly referred to as the United States (U.S.) or America, is a federal republic composed of 50 states, the federal district of Washington, D.C., five major territories, and various possessions.'
        },
        {
          id: 10,
          countryName: 'Russia',
          language: 'Russian',
          description:
            "Russia - a sovereign state in northern Eurasia. It is a federal semi-presidential republic. At 17,125,200 square kilometres (6,612,100 sq mi), Russia is the largest country in the world, covering more than one-eighth of the Earth's inhabited land area."
        },
        {
          id: 11,
          countryName: 'USA',
          language: 'US English',
          description:
            'The United States of America (USA), commonly referred to as the United States (U.S.) or America, is a federal republic composed of 50 states, the federal district of Washington, D.C., five major territories, and various possessions.'
        },
        {
          id: 12,
          countryName: 'Russia',
          language: 'Russian',
          description:
            "Russia - a sovereign state in northern Eurasia. It is a federal semi-presidential republic. At 17,125,200 square kilometres (6,612,100 sq mi), Russia is the largest country in the world, covering more than one-eighth of the Earth's inhabited land area."
        }
      ],
      properties: []
    },
    {
      identity: {
        id: 10,
        name: 'Second dataset name which is very long so that text will not fit into one line',
        description:
          'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia.'
      },
      version: '2.21',
      deployment: 1460117548,
      userrole: [
        {
          id: 20,
          name: 'John Boggle',
          email: 'john@boogle.com',
          alias: 'test',
          role: 'architect'
        }
      ],
      locale: 'en_US',
      locales: ['en_US', 'ru_RU', 'fr_FR'],
      rating: {
        good: 8,
        bad: 3
      },
      subscriptions: [23, 543],
      tags: ['one', 'two', 'customers'],
      structure: {
        fields: [
          {
            key: 'PRIMARY',
            id: 1,
            name: 'Country name',
            description: 'Full name of country',
            type: 'STRING',
            size: '256b',
            updated: 1462879008428,
            updatedBy: 'John Boggle',
            tags: ['TagOne', 'TagThree']
          },
          {
            key: 'SECONDARY',
            id: 2,
            name: 'Language',
            description: 'Country main language',
            type: 'STRING',
            size: '256b',
            updated: 1462879008428,
            updatedBy: 'John Boggle',
            tags: ['TagOne', 'TagTwo']
          },
          {
            key: 'NO',
            id: 3,
            name: 'Description',
            description: 'Short description of country taken from Wikipedia',
            type: 'STRING',
            size: '2kb',
            updated: 1462879008428,
            updatedBy: 'John Boggle',
            tags: ['TagTwo', 'TagThree']
          }
        ],
        keys: []
      },
      layouts: [1, 2],
      data: [
        {
          countryName: 'USA',
          language: 'US English',
          description:
            'The United States of America (USA), commonly referred to as the United States (U.S.) or America, is a federal republic composed of 50 states, the federal district of Washington, D.C., five major territories, and various possessions.'
        },
        {
          countryName: 'Russia',
          language: 'Russian',
          description:
            "Russia - a sovereign state in northern Eurasia. It is a federal semi-presidential republic. At 17,125,200 square kilometres (6,612,100 sq mi), Russia is the largest country in the world, covering more than one-eighth of the Earth's inhabited land area."
        },
        {
          countryName: 'Canada',
          language: 'US English',
          description:
            'The United States of America (USA), commonly referred to as the United States (U.S.) or America, is a federal republic composed of 50 states, the federal district of Washington, D.C., five major territories, and various possessions.'
        },
        {
          countryName: 'Russia',
          language: 'Russian',
          description:
            "Russia - a sovereign state in northern Eurasia. It is a federal semi-presidential republic. At 17,125,200 square kilometres (6,612,100 sq mi), Russia is the largest country in the world, covering more than one-eighth of the Earth's inhabited land area."
        },
        {
          countryName: 'USA',
          language: 'US English',
          description:
            'The United States of America (USA), commonly referred to as the United States (U.S.) or America, is a federal republic composed of 50 states, the federal district of Washington, D.C., five major territories, and various possessions.'
        },
        {
          countryName: 'Russia',
          language: 'Russian',
          description:
            "Russia - a sovereign state in northern Eurasia. It is a federal semi-presidential republic. At 17,125,200 square kilometres (6,612,100 sq mi), Russia is the largest country in the world, covering more than one-eighth of the Earth's inhabited land area."
        },
        {
          countryName: 'USA',
          language: 'US English',
          description:
            'The United States of America (USA), commonly referred to as the United States (U.S.) or America, is a federal republic composed of 50 states, the federal district of Washington, D.C., five major territories, and various possessions.'
        },
        {
          countryName: 'Russia',
          language: 'Russian',
          description:
            "Russia - a sovereign state in northern Eurasia. It is a federal semi-presidential republic. At 17,125,200 square kilometres (6,612,100 sq mi), Russia is the largest country in the world, covering more than one-eighth of the Earth's inhabited land area."
        },
        {
          countryName: 'USA',
          language: 'US English',
          description:
            'The United States of America (USA), commonly referred to as the United States (U.S.) or America, is a federal republic composed of 50 states, the federal district of Washington, D.C., five major territories, and various possessions.'
        },
        {
          countryName: 'Russia',
          language: 'Russian',
          description:
            "Russia - a sovereign state in northern Eurasia. It is a federal semi-presidential republic. At 17,125,200 square kilometres (6,612,100 sq mi), Russia is the largest country in the world, covering more than one-eighth of the Earth's inhabited land area."
        }
      ],
      properties: [],
      settings: []
    },
    {
      identity: {
        id: 2,
        name: 'Languages',
        description: "Short dataset's description"
      },
      version: '2.1',
      deployment: 1460117748,
      userrole: [
        {
          id: 21,
          name: 'John Boggle',
          email: 'john@boogle.com',
          alias: 'test',
          role: 'architect'
        }
      ],
      locale: 'en_US',
      layouts: [],
      locales: ['en_US', 'ru_RU', 'fr_FR'],
      rating: {
        good: 13,
        bad: 6
      },
      subscriptions: [23, 543],
      properties: [
        {
          name: 'usage',
          value: 'enum'
        }
      ],
      settings: [],
      data: [
        {
          id: 0,
          value: 'French'
        },
        {
          id: 1,
          value: 'Dutch'
        },
        {
          id: 2,
          value: 'English'
        },
        {
          id: 3,
          value: 'Russian'
        }
      ]
    },
    {
      identity: {
        id: 3,
        name: 'Other dataset name',
        description: "Other dataset's description"
      },
      version: '2.1',
      deployment: 1460117748,
      userrole: [
        {
          id: 23,
          name: 'John Some',
          email: 'john@some.com',
          alias: 'some test',
          role: 'architect',
          linkedin: 'http://linkedin.com/',
          linkedinText: 'John Some',
          phone: '+7373737373737'
        }
      ],
      locales: ['en_US', 'ru_RU'],
      rating: {
        good: 5,
        bad: 12
      },
      layouts: [2],
      subscriptions: [],
      tags: ['three', 'two'],
      properties: [],
      settings: []
    }
  ],
  pipelines: [
    {
      identity: {
        id: 1,
        name: 'Some pipeline',
        description: 'Pipeline description'
      },
      version: '2.3',
      deployment: '23.12.15',
      userrole: [
        {
          id: 22,
          name: 'John Some',
          email: 'john@some.com',
          alias: 'some test',
          role: 'architect'
        }
      ],
      rating: {
        good: 10,
        bad: 3
      }
    }
  ],
  interfaces: [
    {
      //id: 1,
      identity: {
        id: 2,
        name: 'Some interface',
        description: 'Some interface description'
      },
      version: '2.1',
      deployment: '15.02.16',
      userrole: [
        {
          id: 24,
          name: 'John Some',
          email: 'john@some.com',
          alias: 'some test',
          role: 'architect'
        }
      ],
      rating: {
        good: 13,
        bad: 4
      },
      functions: {
        input: [{ id: 1, name: 'Function 01' }],
        output: []
      }
    },
    {
      //id: 2,
      identity: {
        id: 3,
        name: 'Other interface',
        description: 'Other interface description'
      },
      version: '2.3',
      deployment: '18.03.16',
      userrole: [
        {
          id: 25,
          name: 'John Some',
          email: 'john@some.com',
          alias: 'some test',
          role: 'architect'
        }
      ],
      rating: {
        good: 12,
        bad: 8
      },
      functions: {
        input: [],
        output: []
      }
    }
  ],
  publications: [
    {
      identity: {
        id: 3,
        name: 'Some publication 3',
        description: 'Some publication description'
      },
      version: '2.3',
      deployment: '18.03.16',
      datasets: [
        {
          identity: {
            id: 1,
            name: 'First dataset name',
            description:
              'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia.'
          },
          tags: ['one', 'two', 'customers'],
          version: '2.1',
          layouts: [
            {
              identity: {
                id: 1,
                name: 'Layout One',
                description: 'Layout one description'
              },
              fields: [
                {
                  id: 2,
                  name: 'Field number ad asa 02'
                },
                {
                  id: 3,
                  name: 'Fieldsd asda number 03'
                }
              ]
            }
          ],
          properties: [],
          settings: {
            version: {
              major: 1,
              minor: 2,
              revision: 5
            }
          }
        },
        {
          identity: {
            id: 10,
            name: 'Second dataset name which is very long so that text will not fit into one line',
            description:
              'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia.'
          },
          version: '2.21',
          locale: 'en_US',
          tags: ['one', 'two', 'customers'],
          structure: {
            fields: [
              {
                key: 'PRIMARY',
                id: 1,
                name: 'Country name',
                description: 'Full name of country',
                type: 'STRING',
                size: '256b',
                updated: 1462879008428,
                updatedBy: 'John Boggle',
                tags: ['TagOne', 'TagThree']
              },
              {
                key: 'SECONDARY',
                id: 2,
                name: 'Language',
                description: 'Country main language',
                type: 'STRING',
                size: '256b',
                updated: 1462879008428,
                updatedBy: 'John Boggle',
                tags: ['TagOne', 'TagTwo']
              },
              {
                key: 'NO',
                id: 3,
                name: 'Description',
                description: 'Short description of country taken from Wikipedia',
                type: 'STRING',
                size: '2kb',
                updated: 1462879008428,
                updatedBy: 'John Boggle',
                tags: ['TagTwo', 'TagThree']
              }
            ],
            keys: []
          },
          layouts: [],
          schemeOnly: true,
          settings: {
            version: {
              major: 1,
              minor: 2,
              revision: 5
            }
          }
        }
      ],

      interfaces: [2, 3],
      pipelines: [],
      userrole: [
        {
          id: 26,
          name: 'John Some',
          email: 'john@some.com',
          alias: 'some test',
          role: 'architect'
        }
      ],
      rating: {
        good: 12,
        bad: 8
      },
      history: {
        created: '05/16/2016',
        createdBy: 'sashaberger@hotmail.com'
      },
      settings: {
        version: {
          major: 1,
          minor: 2,
          revision: 5
        },
        data: []
      },
      properties: [],
      locales: []
    },
    {
      identity: {
        id: 4,
        name: 'Other publication',
        description: ''
      },
      version: '2.4',
      deployment: '12.02.16',
      userrole: [
        {
          id: 27,
          name: 'John Smith',
          email: 'john@smith.com',
          alias: 'smith test',
          role: 'architect'
        }
      ],
      datasets: [],
      interfaces: [],
      rating: {
        good: 15,
        bad: 10
      }
    },
    {
      identity: {
        id: 5,
        name: 'Other publication',
        description: 'Other publication description'
      },
      version: '2.4',
      deployment: '12.02.16',
      userrole: [
        {
          id: 28,
          name: 'John Smith',
          email: 'john@smith.com',
          alias: 'smith test',
          role: 'architect'
        }
      ],
      datasets: [],
      interfaces: [],
      rating: {
        good: 15,
        bad: 10
      }
    },
    {
      identity: {
        id: 6,
        name: 'Some publication 6',
        description: 'Other publication description'
      },
      version: '2.4',
      deployment: '12.02.16',
      userrole: [
        {
          id: 29,
          name: 'John Smith',
          email: 'john@smith.com',
          alias: 'smith test',
          role: 'architect'
        }
      ],
      datasets: [],
      interfaces: [],
      rating: {
        good: 15,
        bad: 10
      }
    }
  ],
  organizations: [
    {
      identity: {
        id: 1,
        name: 'Some Organization',
        description:
          'One organization description. Russia - a sovereign state in northern Eurasia. It is a federal semi-presidential republic.'
      },
      rating: {
        good: 15,
        bad: 3
      },
      locale: 'en_US',
      locales: ['en_US', 'ru_RU', 'fr_FR'],
      settings: {
        version: {
          major: 1,
          minor: 2,
          revision: 5
        },
        data: []
      },
      properties: [],
      systems: [1, 2],
      userrole: [
        {
          id: 29,
          name: 'John Smith',
          email: 'john@smith.com',
          alias: 'smith test',
          role: 'architect'
        },
        {
          id: 228,
          name: 'Other Smith',
          email: 'other-join@smith.com',
          alias: 'other smith test',
          role: 'developer'
        }
      ],
      platforms: [],
      types: [],
      usages: [],
      enums: [],
      structures: []
    }
  ],
  systems: [
    {
      identity: {
        id: 1,
        name: 'One system',
        description: 'one system description'
      },
      rating: {
        good: 15,
        bad: 3
      },
      history: {
        created: '05/16/2016',
        createdBy: 'sashaberger@hotmail.com'
      },
      settings: {
        version: {
          major: 1,
          minor: 2,
          revision: 5
        },
        data: []
      },
      properties: [],
      locales: ['en_US', 'ru_RU', 'fr_FR'],
      userrole: [
        {
          id: 29,
          name: 'sashaberger@hotmail.com',
          role: 'architect',
          granted: '05/16/2016',
          grantedBy: 'sashaberger@hotmail.com'
        }
      ],
      applications: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
      subscriptions: []
    },
    {
      identity: {
        id: 2,
        name: 'Second system',
        description: 'second system description',
        history: {
          created: '05/18/2016',
          createdBy: 'sashaberger@hotmail.com'
        }
      },
      userrole: [
        {
          user: 'sashaberger@hotmail.com',
          role: 'Architect',
          granted: '05/18/2016',
          grantedBy: 'sashaberger@hotmail.com'
        },
        {
          user: 'nik.kasyanov@gmail.com',
          role: 'Developer',
          granted: '05/18/2016',
          grantedBy: 'sashaberger@hotmail.com'
        }
      ],
      locales: [],
      subscriptions: [],
      applications: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
    }
  ],
  fields: [
    {
      identity: {
        id: 1,
        name: 'One field',
        description: 'one field description'
      },
      rating: {
        good: 15,
        bad: 3
      },
      history: {
        created: '05/16/2016',
        createdBy: 'sashaberger@hotmail.com'
      },
      settings: {
        version: {
          major: 1,
          minor: 2,
          revision: 5
        },
        data: []
      },
      properties: [],
      locales: [],
      userrole: [
        {
          user: 'sashaberger@hotmail.com',
          role: 'Architect',
          granted: '05/16/2016',
          grantedBy: 'sashaberger@hotmail.com'
        }
      ]
    }
  ],
  layouts: [
    {
      identity: {
        id: 1,
        name: 'Layout One',
        description: 'Layout one description'
      },
      locale: 'en_US',
      locales: ['en_US', 'ru_RU'],
      userrole: [
        {
          user: 'sashaberger@hotmail.com',
          role: 'Architect',
          granted: '05/16/2016',
          grantedBy: 'sashaberger@hotmail.com'
        }
      ],
      fields: [
        {
          id: 1,
          name: 'Field 01'
        },
        {
          id: 2,
          name: 'Field number ad asa 02'
        },
        {
          id: 3,
          name: 'Fieldsd asda number 03'
        },
        {
          id: 4,
          name: 'Field sdasda number04'
        },
        {
          id: 5,
          name: 'Field number05'
        },
        {
          id: 6,
          name: 'Field sdas asdas sadsa number06'
        },
        {
          id: 7,
          name: 'Field number07'
        }
      ]
    },
    {
      identity: {
        id: 2,
        name: 'Layout Two',
        description: 'Layout two description'
      },
      locale: 'en_US',
      locales: ['en_US', 'ru_RU'],
      userrole: [
        {
          user: 'sashaberger@hotmail.com',
          role: 'Architect',
          granted: '05/16/2016',
          grantedBy: 'sashaberger@hotmail.com'
        }
      ],
      fields: [
        {
          id: 1,
          name: 'Field 01'
        },
        {
          id: 2,
          name: 'Field number ad asa 02'
        }
      ]
    }
  ],
  policies: [
    {
      identity: {
        id: 1,
        name: 'Some policy',
        description: 'Some description'
      },
      data: [
        {
          id: 1,
          name: 'Policy prop one',
          description: 'property description',
          type: 'string',
          value: ''
        }
      ]
    },
    {
      identity: {
        id: 2,
        name: 'Other policy',
        description: 'Some other description'
      },
      data: [
        {
          id: 1,
          name: 'Policy prop two',
          description: 'property description',
          type: 'string',
          value: ''
        }
      ]
    }
  ],
  formats: [
    {
      identity: {
        name: 'Format 1'
      },
      data: [
        {
          id: 1,
          name: 'Format prop one',
          description: 'property description',
          type: 'string',
          value: ''
        },
        {
          id: 2,
          name: 'Format prop one',
          description: 'property description',
          type: 'string',
          value: ''
        },
        {
          id: 3,
          name: 'Format prop one',
          description: 'property description',
          type: 'string',
          value: ''
        }
      ]
    },
    {
      identity: {
        name: 'Format 2'
      },
      data: [
        {
          id: 1,
          name: 'Format prop two',
          description: 'property description',
          type: 'string',
          value: ''
        },
        {
          id: 2,
          name: 'Format prop one',
          description: 'property description',
          type: 'string',
          value: ''
        },
        {
          id: 3,
          name: 'Format prop one',
          description: 'property description',
          type: 'string',
          value: ''
        }
      ]
    }
  ],
  schedules: [
    {
      identity: {
        name: 'Schedule 1'
      },
      data: [
        {
          name: 'Schedule prop one',
          description: 'property description',
          type: 'string',
          value: ''
        },
        {
          name: 'Schedule prop one',
          description: 'property description',
          type: 'string',
          value: ''
        },
        {
          name: 'Schedule prop one',
          description: 'property description',
          type: 'string',
          value: ''
        }
      ]
    },
    {
      identity: {
        name: 'Schedule 2'
      },
      data: [
        {
          name: 'Schedule prop two',
          description: 'property description',
          type: 'string',
          value: ''
        },
        {
          name: 'Schedule prop one',
          description: 'property description',
          type: 'string',
          value: ''
        },
        {
          name: 'Schedule prop one',
          description: 'property description',
          type: 'string',
          value: ''
        }
      ]
    }
  ],
  subscriptions: [
    {
      identity: {
        id: 3,
        name: 'Some subscription 3',
        description: 'Some subscription description'
      },
      version: '2.3',
      deployment: '18.03.16',
      datasets: [
        {
          identity: {
            id: 1,
            name: 'First dataset name',
            description:
              'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia.'
          },
          tags: ['one', 'two', 'customers'],
          version: '2.1',
          layouts: [
            {
              identity: {
                id: 1,
                name: 'Layout One',
                description: 'Layout one description'
              },
              fields: [
                {
                  id: 2,
                  name: 'Field number ad asa 02'
                },
                {
                  id: 3,
                  name: 'Fieldsd asda number 03'
                }
              ]
            }
          ],
          properties: [],
          settings: {
            version: {
              major: 1,
              minor: 2,
              revision: 5
            }
          }
        },
        {
          identity: {
            id: 10,
            name: 'Second dataset name which is very long so that text will not fit into one line',
            description:
              'Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean. A small river named Duden flows by their place and supplies it with the necessary regelialia.'
          },
          version: '2.21',
          locale: 'en_US',
          tags: ['one', 'two', 'customers'],
          structure: {
            fields: [
              {
                key: 'PRIMARY',
                id: 1,
                name: 'Country name',
                description: 'Full name of country',
                type: 'STRING',
                size: '256b',
                updated: 1462879008428,
                updatedBy: 'John Boggle',
                tags: ['TagOne', 'TagThree']
              },
              {
                key: 'SECONDARY',
                id: 2,
                name: 'Language',
                description: 'Country main language',
                type: 'STRING',
                size: '256b',
                updated: 1462879008428,
                updatedBy: 'John Boggle',
                tags: ['TagOne', 'TagTwo']
              },
              {
                key: 'NO',
                id: 3,
                name: 'Description',
                description: 'Short description of country taken from Wikipedia',
                type: 'STRING',
                size: '2kb',
                updated: 1462879008428,
                updatedBy: 'John Boggle',
                tags: ['TagTwo', 'TagThree']
              }
            ],
            keys: []
          },
          layouts: [],
          schemeOnly: true,
          settings: {
            version: {
              major: 1,
              minor: 2,
              revision: 5
            }
          }
        }
      ],

      interfaces: [2, 3],
      pipelines: [],
      userrole: [
        {
          id: 26,
          name: 'John Some',
          email: 'john@some.com',
          alias: 'some test',
          role: 'architect'
        }
      ],
      rating: {
        good: 12,
        bad: 8
      },
      history: {
        created: '05/16/2016',
        createdBy: 'sashaberger@hotmail.com'
      },
      settings: {
        version: {
          major: 1,
          minor: 2,
          revision: 5
        },
        data: []
      },
      properties: [
        {
          id: 1,
          name: 'e-mail',
          value: 'name@someorg.com'
        },
        {
          id: 2,
          name: 'Phone',
          value: '+79874563654'
        },
        {
          id: 3,
          name: 'URL',
          value: 'someorg.com/api/somepath'
        },
        {
          id: 4,
          name: 'Publication name',
          value: 'Some publication 3'
        }
      ],
      locales: []
    }
  ]
}

export default initialState
