import React, { Component } from 'react'
import '../../styles/Loader/Loader.scss'

export class Loader extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.timer = null
  }

  componentDidMount() {
    this.init()
  }

  UNSAFE_componentWillReceiveProps() {
    //this.init();
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }

  init() {
    this.setState({ isDisplaying: false })
    if (!this.timer)
      this.timer = setTimeout(() => {
        this.timer = null
        this.setState({ isDisplaying: true })
      }, 200)
  }

  render() {
    return (
      <div className="Loader">
        {!this.state.isDisplaying ? null : (
          <div className="Loader__picture">
            <div className="ball-cell">
              <div className="ball"></div>
            </div>
            <div className="ball-cell">
              <div className="ball"></div>
            </div>
            <div className="ball-cell">
              <div className="ball"></div>
            </div>
            <div className="ball-cell">
              <div className="ball"></div>
            </div>
            <div className="ball-cell">
              <div className="ball"></div>
            </div>
          </div>
        )}
        <div className="Loader__background"></div>
      </div>
    )
  }
}

Loader.propTypes = {}
