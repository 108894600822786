import React from 'react'
import { connect } from 'react-redux'
import { displayReferenceUri, editableState } from '../../helpers/index'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { RuleList } from './RuleList'
import './RulesDialog.scss'

const RULE_HEIGHT = 50

class RulesDialog extends EditorDialogBase {
  /**
   * Returns object type for dialog, for example "activity"
   */
  getObjectType() {
    return 'rules'
  }

  /**
   * Returns list of adjustments of height based on object elements.
   * Name - is a name of parameter to use,
   * Value - adjustment for element.
   */
  getElementsHeight() {
    // We have lines of settings on settings array
    return { __line: RULE_HEIGHT }
  }

  saveRules = (rules, closeDialog, onSent) => {
    onSent(closeDialog)
    console.log('RulesDialog:saveRules', rules)

    this.Change(rules)
  }

  renderEditor = (editState) => {
    //console.log("RulesDialog:renderEditor", this.state);
    return (
      <div className="RulesDialog">
        <RuleList
          appState={this.props.appState}
          actions={this.props.actions}
          isVisible
          isEditable={editState >= editableState.EDITABLE ? editState : editableState.BROWSABLE}
          majorObject={this.props.majorObject}
          type={'rule'}
          items={this.state.rules}
          onSave={this.saveRules}
        />
      </div>
    )
  }

  /**
   * Render view of component when window is closed
   * @returns {*}
   */
  renderItem(s, i) {
    // render one rule.
    return (
      <div className="EditorDialogItem row" key={i}>
        <div className="EditorDialogItem__name col">{s.type + ': '}</div>
        <div className="EditorDialogItem__value col">
          {s.configuration && s.configuration.reference
            ? displayReferenceUri(s.configuration.reference, s.configuration.subscription)
            : s.expression}
        </div>
      </div>
    )
  }
}

RulesDialog.propTypes = {}

function mapStateToProps(state) {
  return {
    actions: state.actions,
    appState: state.appState
  }
}

export default connect(mapStateToProps, null)(RulesDialog)
