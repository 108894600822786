/**
 * Created by mailf on 20.06.2016.
 */
import { API, getApiResource, getObjectNew } from './api'
import { nonCSCompare, pluralTypeForms } from './index'
import { isChildOf } from './dataNew'

export function loadPlatforms(appState, parent, actions, allowEmptyParent = false) {
  const datasets = appState.datasets

  if (!datasets || !datasets.length)
    return new Promise((resolve, reject) => {
      resolve([])
    })

  const platforms = datasets.filter(
    (ds) =>
      ds.object &&
      ds.object.usage &&
      nonCSCompare(ds.object.usage, 'enum') &&
      nonCSCompare(ds.identity.name, 'Platform') &&
      (isChildOf(ds, parent.identity.id) || allowEmptyParent)
  )

  if (platforms.length) {
    const req = API[getApiResource(parent.type)](parent.identity.name)[getApiResource('datasets')](
      platforms[0].identity.name
    )
    return getObjectNew(req, 'dataset', actions)
  } else {
    return new Promise((resolve, reject) => {
      resolve([])
    })
  }
}

/**
 * Get all platform datasets from appState
 * @param appState
 * @param parent Object of which platforms requested
 * @param actions Redux actions
 * @param {Boolean} allowEmptyParent
 * @returns {Array}
 */
export function getPlatforms(appState, parent, actions, allowEmptyParent = false) {
  const datasets = appState.datasets

  if (!datasets || !datasets.length) return []

  const platforms = datasets.filter(
    (ds) =>
      ds.object &&
      ds.object.usage &&
      nonCSCompare(ds.object.usage, 'enum') &&
      nonCSCompare(ds.identity.name, 'Platform') &&
      (isChildOf(ds, parent.identity.id) || allowEmptyParent)
  )

  return platforms.length && platforms[0].data ? platforms[0].data.records.map((r) => r.values) : []
}

export function loadTypes(appState, parent, actions, allowEmptyParent = false) {
  //console.log("getTypes");
  const datasets = appState.datasets

  if (!datasets || !datasets.length)
    return new Promise((resolve, reject) => {
      resolve([])
    })

  const types = datasets.filter(
    (ds) =>
      ds.object &&
      ds.object.usage &&
      ds.object.usage.toLowerCase() === 'enum' &&
      ds.identity.name === 'Type' &&
      (isChildOf(ds, parent.identity.id) || allowEmptyParent)
  )

  if (types.length) {
    const req = API[getApiResource(parent.type)](parent.identity.name)[getApiResource('datasets')](
      types[0].identity.name
    )
    return getObjectNew(req, 'dataset', actions)
  } else {
    return new Promise((resolve, reject) => {
      resolve([])
    })
  }
}

/**
 * Get all types datasets from appState
 * @param appState
 * @param parent Object of which platforms requested
 * @param actions Redux actions
 * @param {Boolean} allowEmptyParent
 * @returns {Array}
 */
export function getTypes(appState, parent, actions, allowEmptyParent = false) {
  //console.log("getTypes");
  const datasets = appState.datasets

  if (!datasets || !datasets.length) return []

  const types = datasets.filter(
    (ds) =>
      ds.object &&
      ds.object.usage &&
      ds.object.usage.toLowerCase() === 'enum' &&
      ds.identity.name === 'Type' &&
      (isChildOf(ds, parent.identity.id) || allowEmptyParent)
  )

  return types.length && types[0].data ? types[0].data.records.map((r) => r.values) : []
}

export function loadUsages(appState, parent, actions, allowEmptyParent = false) {
  const datasets = appState.datasets

  if (!datasets || !datasets.length)
    return new Promise((resolve, reject) => {
      resolve([])
    })

  const usages = datasets.filter(
    (ds) =>
      ds.object &&
      ds.object.usage &&
      ds.object.usage.toLowerCase() === 'enum' &&
      ds.identity.name === 'Usage' &&
      (isChildOf(ds, parent.identity.id) || allowEmptyParent)
  )

  if (usages.length) {
    const req = API[getApiResource(parent.type)](parent.identity.name)[getApiResource('datasets')](
      usages[0].identity.name
    )
    return getObjectNew(req, 'dataset', actions)
  } else {
    return new Promise((resolve, reject) => {
      resolve([])
    })
  }
}
/**
 * Get all usage datasets from appState
 * @param appState
 * @param parent Object of which usages requested
 * @param actions Redux actions
 * @param {Boolean} allowEmptyParent
 * @returns {Array}
 */
export function getUsages(appState, parent, actions, allowEmptyParent = false) {
  // //console.log("getUsages");
  const datasets = appState.datasets

  if (!datasets || !datasets.length) return []

  const usages = datasets.filter(
    (ds) =>
      ds.object &&
      ds.object.usage &&
      ds.object.usage.toLowerCase() === 'enum' &&
      ds.identity.name === 'Usage' &&
      (isChildOf(ds, parent.identity.id) || allowEmptyParent)
  )

  return usages.length && usages[0].data ? usages[0].data.records.map((r) => r.values) : []
}

/**
 * Get all enumerator datasets from appState
 * @param appState
 * @param parent Object of which enums requested
 * @returns {Array}
 */
export function getEnums(appState, parent, actions, allowEmptyParent = false) {
  // //console.log("getEnums");
  const datasets = appState.datasets

  if (!datasets || !datasets.length) return []

  return datasets.filter(
    (ds) =>
      ds.object &&
      ds.object.usage &&
      ds.object.usage.toLowerCase() === 'enum' &&
      ds.identity.name !== 'Usage' &&
      ds.identity.name !== 'Type' &&
      ds.identity.name !== 'Platform' &&
      (isChildOf(ds, parent.identity.id) || allowEmptyParent)
  )
}

/**
 * Get all structure datasets from appState
 * @param appState
 * @param parent Object of which structures requested
 * @returns {Array}
 */
export function getStructures(appState, parent, actions, allowEmptyParent = false) {
  // //console.log("getStructures");
  const datasets = appState.datasets

  if (!datasets || !datasets.length) return []

  return datasets.filter(
    (ds) =>
      ds.object &&
      ds.object.usage &&
      ds.object.usage.toLowerCase() !== 'enum' &&
      (isChildOf(ds, parent.identity.id) || allowEmptyParent)
  )
}

/**
 * Get all structure applications from appState
 * @param appState
 * @param parentId
 * @returns {Array}
 */
export function getTopologies(appState, parentId) {
  const { topologies } = appState
  return topologies
    ? topologies.filter(
        (topology) => topology.identity.name.length > 0
        // && topology.object
        // && topology.object.parent
        // && isChildOf(topology, parentId)
      )
    : []
  // return appState.applications ? appState.applications.filter(s => s.identity.name.length > 0) : [];
}

export function getObjects(appState, tabName: string, parentId?: string) {
  return appState[tabName]
    ? //
      appState[tabName].filter((d) => d.identity.name.length > 0 && d.object?.parent && isChildOf(d, parentId))
    : []
}

/**
 * Get object from state by name
 * @param appState State of application
 * @param objectType Type of object
 * @param objectName Name of object
 * @returns {Object|boolean}
 */
export function getObjectByName(appState, objectType, objectName) {
  objectType = pluralTypeForms.get(objectType)

  if (!appState || !appState[objectType]) return false

  const res = appState[objectType].find((obj) => nonCSCompare(obj.identity.name, objectName))
  return res ? Object.assign({}, res) : false
}

/**
 * Get structure systems by id from appState
 * @param appState
 * @param parent
 * @returns {Array}
 */
export function getSystems(appState, parent) {
  return appState.systems
    ? appState.systems.filter((s) => s.identity.name.length > 0 && isChildOf(s, parent.identity.id))
    : []
  // return appState.systems ? appState.systems.filter(s => s.identity.name.length > 0) : [];
}

/**
 * Get object from state by path (used for references)
 * @param appState State of application
 * @param objectType Type of object
 * @param objectPath Path of object
 * @returns {*} {Object|boolean}
 */
export function getObjectByPath(appState, objectType, objectPath) {
  //console.log("getObjectByPath", appState, objectType, objectPath);

  let res = null
  objectType = pluralTypeForms.get(objectType)

  if (!appState || !appState[objectType]) return false

  for (let i = 0; i < appState[objectType].length; i++) {
    if (appState[objectType][i]._path === objectPath) {
      res = appState[objectType][i]
      break
    }
  }

  return res ? Object.assign({}, res) : false
}

/**
 * Get object from state by id
 * @param appState State of application
 * @param objectType Type of object
 * @param objectPath Path of object
 * @returns {*} {Object|boolean}
 */
export function getObjectById(appState, objectType, objectId) {
  //console.log("getObjectByPath", appState, objectType, objectPath);

  let res = null
  objectType = pluralTypeForms.get(objectType)

  if (!appState || !appState[objectType]) return false

  for (let i = 0; i < appState[objectType].length; i++) {
    if (appState[objectType][i].identity && appState[objectType][i].identity.id === objectId) {
      res = appState[objectType][i]
      break
    }
  }

  return res ? Object.assign({}, res) : false
}

export { isChildOf }
