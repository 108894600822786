/**
 * Created by Sasha Berger on 05.02.19.
 *
 * Control to select subset of the options and create list of selected options by name.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { dequal } from 'dequal'

import logger from '../../helpers/logger'
import iLeft from '../../resources/images/left-arrow-2x.png'
import iRight from '../../resources/images/right-arrow-2x.png'
import './DataSelector.scss'
import { DataSelectorTable } from './DataSelectorTable'

const ITEM_HEIGHT = 50

export class DataSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      srcFields: [],
      dstFields: []
    }

    this.srctableRef = React.createRef()
    this.dsttableRef = React.createRef()
  }

  componentDidMount() {
    // Find place needed.
    let newHeight = this.props.items ? (this.props.items.length > 8 ? 8 : this.props.items.length) * ITEM_HEIGHT : 0

    // Adjust space we need for data of dialog.
    if (this.props.onAdjust) {
      //logger.info("DataSelector:componentDidMount", newHeight, this.props);
      this.props.onAdjust(0, newHeight)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let change = dequal(nextProps, this.props) || !dequal(nextState, this.state)
  
    // Find place needed.
    let oldHeight = this.props.items ? (this.props.items.length > 8 ? 8 : this.props.items.length) * ITEM_HEIGHT : 0
    let newHeight = nextProps.items ? (nextProps.items.length > 8 ? 8 : nextProps.items.length) * ITEM_HEIGHT : 0

    // Adjust space we need for data of dialog.
    if (this.props.onAdjust && change) {
      //logger.info("DataSelector:shouldComponentUpdate", oldHeight, newHeight, nextProps, this.props);
      this.props.onAdjust(0, newHeight - oldHeight)
    }
    return change
  }

  srcFieldSelected(values) {
    //logger.log("srcFieldSelected", values);
    this.setState(
      {
        srcFields: this.props.items.filter((item) => values.indexOf(item.id) !== -1)
      },
      () => {
        // onSrcFieldChange is never used
        //if (this.props.onSrcFieldChange)
        //  this.props.onSrcFieldChange(values);
      }
    )
  }

  dstFieldSelected(values) {
    console.log('dstFieldSelected', values)
    this.setState({
      dstFields: this.props.items.filter((item) => values.indexOf(item.id) !== -1)
    })
  }

  onAddField(key) {
    if (this.state.srcFields.length === 0) return

    let newSelected = this.props.selected.filter(
      (item) => this.state.srcFields.findIndex((el) => el.id === item.id) === -1
    )
    newSelected = newSelected.concat(this.state.srcFields)
    logger.info('DataSelector:onAddField', key, newSelected, this.state, this.props)

    this.setState(
      {
        selected: newSelected
      },
      () => {
        if (this.props.onSelect) {
          this.props.onSelect(newSelected)
        }
        this.selectNone()
      }
    )
  }

  onDeleteField(key) {
    if (this.state.dstFields.length === 0) return

    let newSelected = this.props.selected.slice()
    newSelected = newSelected.filter((item) => this.state.dstFields.findIndex((el) => item.id === el.id) === -1)
    logger.info('DataSelector:onDeleteField', key, this.state.dstField, newSelected)

    this.setState({ selected: newSelected }, () => {
      if (this.props.onSelect) {
        this.props.onSelect(newSelected)
      }
      this.selectNone()
    })
  }

  selectNone = () => {
    if (this.srctableRef.current) {
      this.srctableRef.current.selectNone()
    }
    if (this.dsttableRef.current) {
      this.dsttableRef.current.selectNone()
    }

    // Remove selected fields from lists.
    this.setState({
      srcFields: [],
      dstFields: []
    })
  }

  render() {
    let items = this.props.items || []
    let selected = this.props.selected || []

    let srcData = items.filter((item) => selected.findIndex((el) => item.id === el.id) === -1)
    let dstData = items.filter((item) => selected.findIndex((el) => item.id === el.id) !== -1)
    //logger.info("DataSelector:render", srcData, dstData, this.state, this.props);

    return (
      <div className="DataSelector" tabIndex="0" onBlur={this.onBlur}>
        <div className="row">
          {this.props.editable ? (
            <div className="col-xs-5">
              <DataSelectorTable
                ref={this.srctableRef}
                captions={this.props.captions}
                columns={this.props.columns}
                data={srcData}
                onSelect={this.srcFieldSelected.bind(this)}
              />
            </div>
          ) : null}
          {this.props.editable ? (
            <div className="col-xs-2 ">
              <div className="DataSelector__divButtons">
                <div className="DataSelector__arrowButton1" onClick={this.onAddField.bind(this)}>
                  <img src={iRight} className="DataSelector__arrow" alt="arrow icon" />
                  <div className="DataSelector__arrowButton1__Text">Add</div>
                </div>
                <div className="DataSelector__arrowButton2" onClick={this.onDeleteField.bind(this)}>
                  <img src={iLeft} className="DataSelector__arrow" alt="arrow icon" />
                  <div className="DataSelector__arrowButton2__Text">Remove</div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-xs-9">
            <DataSelectorTable
              ref={this.dsttableRef}
              captions={this.props.captions}
              columns={this.props.columns}
              data={dstData}
              onSelect={this.dstFieldSelected.bind(this)}
            />
          </div>
        </div>
      </div>
    )
  }
}

DataSelector.propTypes = {
  captions: PropTypes.array, // Captions to show for columns in table
  columns: PropTypes.array, // Columns data to show in table
  editable: PropTypes.bool, // Is editing selection available
  items: PropTypes.array, // Arrays of options to show
  selected: PropTypes.array, // Arrays of ids to show as selected
  onSelect: PropTypes.func // Call function when item selected
}
