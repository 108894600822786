/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { deploymentChildTypes } from '../../helpers'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import './DeploymentChart.scss'
import { downloadSelectorAsPDF } from '../../helpers/pdfDownloader'

export class DeploymentChart extends Component {
  constructor(props) {
    super(props)

    let height = this.InitHeight(props)

    this.state = {
      change: null, // Method to report or cancel modification.
      //origin: JSON.stringify(props.activity),

      height: height,
      editMode: false,
      selectedPackage: null,
      selectedPackageIndex: -1,
      selectedApplication: null
    }
  }

  componentDidMount() {}

  /**
   * Init dialog height
   * @param user - selected user
   */
  InitHeight = (props) => {
    props = props || this.props

    let height = 600

    if (height < window.innerHeight - 248) height = window.innerHeight - 248

    return height
  }

  /**
   * close dialog
   */
  onClose() {
    this.setState({
      editMode: false
    })
    if (this.props.onClose) this.props.onClose()
  }

  /**
   * Adjust size of scroll area in dialog
   * @param width - increase or reduction in control width.
   * @param height - increase or reduction in control height.
   * @return
   */
  onAdjust = (width, height) => {
    this.setState((prevState) => {
      return { height: prevState.height + height }
    })
  }

  /**
   * Set edit mode on
   */
  startEdit = (change) => {
    this.setState({
      change: change
    })
    window.SQL.preventTableMove = false
  }

  /**
   * Execute activity when message send to service
   * @param {boolean} close - flag to close dialog
   * @param {*} error - return from service if any.
   * @return
   */
  onSent = (close, error) => {
    // Report complete of save with error or not
    if (this.state.change) {
      this.state.change(error)
    }

    if (close && !error) {
      setTimeout(() => {
        this.onClose()
      }, 1000)
    }
  }

  /**
   * Cancel changes
   * @param {boolean} [closeDialog] Do we need to close dialog
   */
  cancelEdit = (closeDialog) => {
    // Cancel save mode
    if (this.state.change) this.state.change(false)

    this.setState({
      change: null,
      inputs: this.props.inputs || []
    })

    window.SQL.preventTableMove = true
  }

  /**
   * show or hide main window
   */
  toggleEditMode() {
    this.setState({
      editMode: !this.state.editMode,
      isEdited: false
    })
  }

  saveTopology = (topology, closeDialog, onSent) => {
    const newTopology = Object.assign({}, { identity: this.props.topology.identity }, {})

    this.props.onSave(newTopology, closeDialog, onSent)
    return true
  }

  downloadPDF = () => {
    const area =
      document.querySelector('.EditorDialog_deployment_chart .scrollarea-content ') ||
      document.querySelector('.EditorDialog_deployment_chart_fullscreen .scrollarea-content ')
    downloadSelectorAsPDF(area, this.props.topology.identity.name + ' Chart', 'div.table')
  }

  selectPackage = (packageData, index) => () => {
    this.setState({
      selectPackage: packageData,
      selectedApplication: null,
      selectedPackageIndex: index
    })
  }

  selectApplication = (appRef, index) => () => {
    this.setState({
      selectedApplication: appRef,
      selectedPackageIndex: index
    })
  }

  getAllChildren = (appRef) => {
    return deploymentChildTypes.reduce((array, typeName) => array.concat(appRef[typeName] || []), [])
  }

  renderEditor = (editState) => {
    return (
      <div className="DeploymentChart">
        <div className="EditorDialog_deployment_chart__additionalButtons">
          <div className="EditorDialog_deployment_chart__headerControlPdf">
            <a className="" onClick={this.downloadPDF}>
              <span>{'{PDF}'}</span>
            </a>
          </div>
        </div>

        <div className="DeploymentChart__deploymentRow">
          <div className="DeploymentChart__deploymentBlock">{this.props.deployment.identity.name}</div>
        </div>

        <div className="DeploymentChart__packageRow">
          {this.props.deployment.packages.map((packageData, index) => (
            <div
              key={index}
              className={
                'DeploymentChart__packageBlock ' +
                (this.state.selectedPackage && this.state.selectedPackage.identity.id === packageData.identity.id
                  ? 'DeploymentChart__packageBlock__selected'
                  : '')
              }
            >
              <div className="DeploymentChart__packageBlockTitle" onClick={this.selectPackage(packageData, index)}>
                {packageData.identity.name}
              </div>
              <div className="DeploymentChart__packageBlockBody">
                {packageData.applications.map((appRef, idx) => (
                  <div
                    key={idx}
                    className={
                      'DeploymentChart__packageApplicationRow ' +
                      (this.state.selectedApplication &&
                      this.state.selectedApplication.identity.id === appRef.identity.id
                        ? 'DeploymentChart__packageApplicationRow__selected'
                        : '')
                    }
                    onClick={this.selectApplication(appRef, index)}
                  >
                    {appRef.identity.name}
                  </div>
                ))}
              </div>
            </div>
          ))}{' '}
          <div className="DeploymentChart__packageRowClear"></div>
        </div>

        {this.state.selectedApplication ? (
          <div className="DeploymentChart__applicationRow">
            {this.props.deployment.packages.map((packageData, index) => {
              return index === this.state.selectedPackageIndex ? (
                <div className="DeploymentChart__applicationRowPackage__full DeploymentChart__applicationBlock">
                  <div className="DeploymentChart__applicationBlockTitle">
                    {this.state.selectedApplication.identity.name}
                  </div>
                  <div className="DeploymentChart__applicationBlockBody">
                    {this.getAllChildren(this.state.selectedApplication).map((childRef, idx) => (
                      <div key={idx} className={'DeploymentChart__applicationChildRow '}>
                        {childRef.identity.name}
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="DeploymentChart__applicationRowPackage__empty"></div>
              )
            })}
          </div>
        ) : null}
      </div>
    )
  }

  renderFooter = (editState) => {
    return null
  }

  render() {
    return (
      <EditorDialog
        level="0"
        ref="dialog"
        objectType={this.state.fullScreen ? 'deployment_chart_fullscreen' : 'deployment_chart'}
        modalTitle={'Deployment diagram: ' + (this.props.deployment ? this.props.deployment.identity.name : '')}
        confirmText={this.props.deployment ? 'Update' : 'Create'}
        editContent={this.renderEditor}
        footerContent={this.renderFooter}
        editHeight={this.state.height}
        isEditable={this.props.isEditable}
        isVisible
        onClose={this.onClose.bind(this)}
        onSave={this.saveTopology}
        onEdit={this.startEdit}
        onCancel={this.cancelEdit}
      />
    )
  }
}
