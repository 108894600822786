/* eslint-disable array-callback-return */
import React, { Component } from 'react'
import AceEditor from 'react-ace'
import CustomSqlMode from './CustomSqlMode'

import md from '../../helpers/md'
import { datasetToObjectArray } from '../../helpers/index'

import './style.scss'

import 'brace/ext/language_tools'
import 'brace/theme/github'

export class FormulaEditor extends Component {
  constructor(props) {
    super(props)

    this.aceEditorRef = React.createRef()
  }

  componentDidMount() {
    console.log('FormulaEditor::componentDidMount', this.props)

    // this enables custom highlighting but disables built-in mysql autocomplete:

    const customMode = new CustomSqlMode(this.props.fieldNames || [], this.props.tableNames || [])
    this.aceEditorRef.current.editor.getSession().setMode(customMode)

    const staticWordCompleter = {
      getCompletions: (editor, session, pos, prefix, callback) => {
        //var wordList = ["foo", "bar", "baz"];
        let completions = []
        // eslint-disable-next-line no-unused-vars
        const wordList = datasetToObjectArray(
          md.repo.metadata.apdax.systems.difhub.applications.organization.datasets.function
        )
          .map((func) => func.Function)
          .map((word) => {
            completions.push({
              caption: 'Function: ' + word,
              value: word,
              meta: 'function'
            })
          })
        // eslint-disable-next-line no-unused-vars
        const fieldNames = (this.props.fieldNames || []).map((word) => {
          completions.push({
            caption: 'Field: ' + word,
            value: word,
            meta: 'variable'
          })
        })
        // eslint-disable-next-line no-unused-vars
        const tableNames = (this.props.tableNames || []).map((word) => {
          completions.push({
            caption: 'Table: ' + word,
            value: word,
            meta: 'entity.name'
          })
        })
        callback(null, completions)
      }
    }
    this.aceEditorRef.current.editor.completers.push(staticWordCompleter)
  }

  render() {
    return (
      <div className="FormulaEditor">
        {' '}
        <AceEditor
          ref={this.aceEditorRef}
          placeholder="Enter formula"
          mode="mysql"
          theme="github"
          name={this.props.name}
          value={this.props.value}
          fontSize={14}
          height={18}
          highlightActiveLine={false}
          enableBasicAutocompletion
          enableLiveAutocompletion
          enableSnippets
          showGutter={false}
          showPrintMargin={false}
          onChange={(value) => this.props.onChange(value)}
        />
      </div>
    )
  }
}
