/* eslint-disable */
/**
 * Created by kascode on 26.04.16.
 */
import moment from 'moment'
import { API, apiURL, getApiResource, getRequestFromPath, singularType } from './api'
import md from './md'
import { shortnames } from '../constants/index'
import { metadata } from '../resources/lib/metadata'
import {
  createEmptyObject,
  deepCopy,
  getDefaultValue,
  objectNameFromPathByType,
  hasInArrayItem,
  buildPathDetailed,
  parentByType,
  getDatasetPathFromReference
} from './objectHelper'
import {
  deadlineCalculation,
  calcDeadline,
  getIssueDeadline,
  issuePriorityLabel,
  issuePropList,
  issueResolutionIcon,
  getIssueAbsoluteDeadline,
  resolutions,
  issueResolutionColor,
  getIssuePropByName
} from './issueHelper'

import DifHubTeamPhoto from '../resources/images/difhub-ava-2x.png'

import logger from './logger'
import { objectJsons } from './objectJsons'
import { nonCSCompare } from './nonCSCompare'
import {
  pluralTypeForms,
  getFullUri,
  pathByType,
  shortByType,
  versionToStr,
  strToVersion,
  capitalize,
  updateLocalObject
} from './indexNew'

export var editableState = {
  BROWSABLE: 0, // Data for view only
  EDITABLE: 1, // Data editable but not yet in edit mode
  EDITING: 2, // Data can be edited
  EDITED: 3, // Data already changed
  LOADING: 4, // Data in process to be loaded
  LOADED: 5 // Data already loaded
}

export var fieldState = {
  HIDDEN: 1, // Field not visible in the table
  DISABLED: 2, // Field visible but can't be selected
  HILIGHTED: 4, // Field highlighted in the table
  SELECTED: 8, // Field selected in the table
  VISIBALE: 16, // Field visible in the table
  ENABLED: 32 // Field visible and can be selected
}

export var itemState = {
  NONE: -1, // No items in dialog
  VERTICAL: 1, // Vertival items in dialog
  HORIZONTAL: 2 // Horizontal items in dialog
}

export var itemPosition = {
  NONE: 0, // No items in dialog
  TOP: 1, // Item in the top
  BOTTOM: 2 // Iten in the bottom
}

export var reportType = {
  COMPLETE: 0, // Operation succesfully completed
  WAIT: 1, // Wait for operation to complete
  ERROR: 2 // Error of operation execution
}

export const columnsToType = {
  rating: { name: 'rating' },
  subscriptions: { name: 'counter' },
  pubsub: { name: 'pubSubCounter' },
  locales: { name: 'array' },
  locale: { name: 'string' },
  key: { name: 'key' },
  name: { name: 'string' },
  objectName: { name: 'objectBlock' },
  minorObjectName: { name: 'minorObjectBlock' },
  minorObjectNameNoMultiline: { name: 'minorObjectNameNoMultiline' },
  description: { name: 'text' },
  architects: { name: 'person_list' },
  developers: { name: 'person_list' },
  person: { name: 'person' },
  language: { name: 'string' },
  countryName: { name: 'string' },
  value: { name: 'string' },
  status: { name: 'data' },
  your_status: { name: 'data' },
  role: { name: 'enum' },
  functions: { name: 'functions' },
  type: { name: 'type' },
  layouts: { name: 'layouts' },
  fields: { name: 'array' },
  isDefault: { name: 'boolean' },
  subscription_status: { name: 'subscription_status' },
  data: { name: 'data' },
  custom_profile: { name: 'custom_profile' },
  version: { name: 'version' },
  size: { name: 'integer' },
  count: { name: 'integer' },
  optional: { name: 'boolean' },
  required: { name: 'booleanLight' },
  visibility: { name: 'booleanLight' },
  keys: { name: 'boolean' },
  alias: { name: 'alias' },
  taskName: { name: 'taskName' },
  datasetName: { name: 'datasetName' },
  typeReference: { name: 'typeReference' },
  structure: { name: 'structure' },
  department: { name: 'department' },
  nameAndDescription: { name: 'nameAndDescription' },
  propertyName: { name: 'propertyName' },
  code: { name: 'code' },
  list: { name: 'list' },
  path: { name: 'uri' },
  httpMethod: { name: 'httpMethod' },
  path_link: { name: 'pathLink' },
  department_icon: { name: 'department_icon' },
  style_frame: { name: 'style_frame' },
  elementName: { name: 'elementName' },
  preview: { name: 'preview' },
  exampleExecute: { name: 'exampleExecute' },
  platform: { name: 'string' },
  configuration: { name: 'string' },
  activityType: { name: 'activityType' },
  getType: function (column) {
    return Object.keys(this).includes(column) ? this[column] : { name: 'string' }
  }
}

export function checkFieldPrivacy(type) {
  if (!type || !type.privacy) return false

  //console.log("checkFieldPrivacy", type);

  let privacy = type.privacy.toLowerCase()
  // todo: remove checking of identity name, when Privacy=high is fixed in integration environment metadata
  return (
    privacy === 'normal' ||
    privacy === 'medium' ||
    privacy === 'high' ||
    (type.identity && nonCSCompare(type.identity.name, 'Password'))
  )
}

export function strToStars(data) {
  return '****'
}

export function isMultilineType(typeName) {
  return ['text', 'array', 'text_expanded', 'text_updatable', 'structure'].indexOf(typeName) !== -1
}

export function datasetToObjectArray(ds) {
  let sorted = ds.data.records.slice()
  sorted.sort((a, b) => (a.index > b.index ? 1 : a.index < b.index ? -1 : 0))

  //console.log("datasetToObjectArray s", sorted);

  let ret = sorted.map((row) => {
    let obj = {}

    //    console.log("datasetToObjectArray r", row);

    ds.structure.fields.map((field, index) => {
      obj[field.identity.name] = row.values[index]
    })

    return obj
  })
  //console.log("datasetToObjectArray", ds, ret);

  return ret
}

export const childrenByType = {
  organizations: ['systems', 'datasets'],
  systems: ['applications', 'topologies', 'deployments', 'environments', 'publications', 'subscriptions'],
  applications: ['datasets', 'interfaces', 'pipelines', 'views', 'publications', 'subscriptions'],
  datasets: 'versions',
  interfaces: 'versions',
  pipelines: 'versions',
  views: 'versions',
  publications: 'versions',
  subscriptions: 'versions'
}

/**
 * Returns name of particular type from split path
 * @param splitPath {Array} - array of path elements generated by split without leading and ending slash
 * @param targetType {String} - string representation of type we want path for
 * @returns {String} name of type
 */
export function nameByType(splitPath, targetType) {
  if (!splitPath || splitPath.length == 0) return null

  for (let i = 1; i < splitPath.length; i += 2) {
    // Type and short type for current part of path.
    let type = splitPath[i - 1].toLowerCase()
    let stype = shortByType[type]

    if (!stype) {
      // we have something wrong with path.
      return null
    }

    if (type == targetType) {
      // we reach target type and can return result now.
      return splitPath[i]
    }
  }
  return null
}

/**
 * Returns path for major version. Analyze uri and build path for major version of an object.
 * @param path
 * @returns {String} generated path
 */
export function pathForMajorVersion(uri) {
  if (!uri || uri.length == 0) return ''

  let splitPath = uri.substring(1).split('/')
  let path = ''

  for (let i = 1; i < splitPath.length; i += 2) {
    // Type and short type for current part of path.
    let type = splitPath[i - 1].toLowerCase()
    let stype = shortByType[type]

    //console.log("pathForMajorVersion:TYPE", type, path);

    if (type === 'versions') {
      // we have version need to remove after dots and concatinate.
      let version = splitPath[i]
      let verSplit = version.split('.')

      path = path.concat('/' + type + (verSplit.length > 0 ? '/' + verSplit[0] : ''))
      //console.log("pathForMajorVersion:VERSIONS", path);
      break
    }

    if (!stype) {
      // we have something wrong with path.
      return ''
    }

    // Add type to path.
    path = path.concat('/' + type + '/' + splitPath[i])
  }
  return path
}

/**
 * Returns path for major object. Analyze uri and build path for major object.
 * When path is a path to major object, we return path, if path is to version or settings, we retturn
 * parrent path of major object.
 * @param path
 * @returns {String} generated path
 */
export function pathForMajorObject(uri) {
  if (!uri || uri.length == 0) return ''

  let splitPath = uri.substring(1).split('/')
  let path = ''

  for (let i = 1; i < splitPath.length; i += 2) {
    // Type and short type for current part of path.
    let type = splitPath[i - 1].toLowerCase()
    let stype = shortByType[type]

    //console.log("pathForMajorVersion:TYPE", type, stype, path);

    if (!stype) {
      // we have no object in list of major objects.
      break
    }

    // Add type to path.
    path = path.concat('/' + type + '/' + splitPath[i])
  }
  return path
}

/**
 * Get value from object by path
 * @param object - this is actual object to navigate
 * @param path - this is path in format a.b.c[0].d or /a/b/c[0]/d
 * @returns - object found in the location.
 */
export function deepGet(object, path) {
  try {
    const elements =
      path.charAt(0) === '/'
        ? path.substring(1).replace(/\[/g, '.').replace(/\]/g, '').split('/')
        : path.replace(/\[/g, '.').replace(/\]/g, '').split('.')

    //console.log("deepGet", object, path, elements);
    return elements.reduce(function (obj, property) {
      return obj[property]
    }, object)
  } catch (err) {
    return undefined
  }
}

/**
 * Set value into object by path
 * @param o - this is actual object to navigate
 * @param path - this is path in format a.b.c[0].d or /a/b/c[0]/d
 * @param value - this is value to set
 * @returns - object new state.
 */
export function deepSet(object, path, value) {
  try {
    const elements =
      path.charAt(0) === '/'
        ? path.substring(1).replace(/\[/g, '.[').replace(/\]/g, '').split('/')
        : path.replace(/\[/g, '.[').replace(/\]/g, '').split('.')

    let prev_obj = object
    let prev_name = undefined
    //console.log("deepSet", object, path, value, elements);

    for (var element of elements) {
      let isArray = element.charAt(0) === '['
      let name = isArray ? element.substring(1) : element

      if (prev_name === undefined) {
        // Parent object defined.
        prev_name = name
      } else {
        // Parent object undefined.
        prev_obj[prev_name] = isArray ? [] : {}

        prev_obj = prev_obj[prev_name]
        prev_name = name
      }
    }

    if (prev_name === undefined) {
      // Current object can change value
      prev_obj = value
    } else {
      // Previous object can change value
      prev_obj[prev_name] = value
    }
    return object
  } catch (err) {
    return undefined
  }
}

/**
 * Count element of the object on first level
 * @param o - this is actual object to copy
 * @param e - element we want count for
 * @returns - count.
 */
export function getElementCount(o, e) {
  var obj = o,
    k
  if (!obj) return 0
  if (e) {
    obj = o[e]
    if (!obj) return 0
  }

  // This is erray we can get length
  if (Array.isArray(obj)) {
    //console.log("getElementCount:ARRAY", obj, obj.length);
    return obj.length
  }

  // This is object we can count
  if (typeof obj === 'object') {
    //console.log("getElementCount:OBJECT", obj, Object.keys(obj).length);
    return Object.keys(obj).length
  }

  // This is simple element
  //console.log("getElementCount:SIMPLE", obj, 1);
  return 1
}

/**
 * Merge object properties into existing object.
 * @param b - object to merge data into
 * @param o - object to copy data from
 * @returns - complete merge of base object with source object.
 */
export function deepMerge(b, o) {
  var copy = {},
    k
  //console.log("deepMerger", b, o, copy);

  // Merge of two arrays complex. For now we add one to another
  if ((b && Array.isArray(b)) || (o && Array.isArray(o))) {
    //console.log("deepMerger:B-ARRAY:O-ARRAY", b, o);
    return (b && Array.isArray(b) ? b : []).concat(o && Array.isArray(o) ? o : [])
  }

  if (b !== 'undefined' && b !== null) {
    if (typeof b === 'object') {
      for (k in b) {
        if (o && o[k] !== undefined && o[k] !== null) {
          // We have this property in source.
          if (typeof o[k] === 'object') {
            //console.log("deepMerger:B-OBJECT:O-OBJECT", o, k, o[k]);
            copy[k] = deepMerge(b[k], o[k])
          } else {
            copy[k] = o[k] ? o[k] : deepCopy(b[k])
            //console.log("deepMerger:B-OBJECT:O-PROPERTY", o, k, o[k], copy);
          }
        } else {
          copy[k] = deepCopy(b[k])
          //console.log("deepMerger:B-OBJECT:O-NULL", k, b[k], copy);
        }
      }
    } else {
      if (o) {
        copy = deepCopy(o)
        //console.log("deepMerger:B-PROPERTY:O-ANY", o, k, b[k], copy);
      } else {
        //console.log("deepMerger:B-PROPERTY:O-NULL", k, b[k], copy);
        copy = b
      }
    }
  }

  if (o) {
    if (typeof o === 'object') {
      for (k in o) {
        if (b && b[k] !== undefined && b[k] !== null) {
          // This case must be already processed in b branch.
        } else {
          copy[k] = deepCopy(o[k])
          //console.log("deepMerger:O-OBJECT:B-NULL", k, o[k], copy);
        }
      }
    } else {
      if (b !== undefined && b !== null) {
        // This case must be already processed in b branch.
      } else {
        //console.log("deepMerger:O-PROPERTY:B-NULL", o);
        copy = o
      }
    }
  }
  return copy
}

/**
 * Find element from array of objects with identity by id
 * @param list - list of element with identity
 * @param id - id of the element to find
 * @returns {Object|*}
 */
export function findById(list, id) {
  return (list || []).find((element) => element.identity.id === id)
}

/**
 * Find element from array of objects with identity by name
 * @param list - list of element with identity
 * @param name - name of the element to find
 * @returns {Object|*}
 */
export function findByName(list, name) {
  return (list || []).find((element) => nonCSCompare(element.identity.name, name))
}

/**
 * Find element from array of objects with object identity by id or name of object identity
 * @param list - list of element with identity
 * @param identity - identity of the element to find
 * @returns {Object|*}
 */
export function findByIdentity(list, object) {
  return object.identity.id
    ? (list || []).find((element) => element.identity.id === object.identity.id)
    : (list || []).find((element) => nonCSCompare(element.identity.name, object.identity.name))
}

/**
 * Gets element from state
 * @param appState
 * @param objectName object name in plural form
 * @param id
 * @returns {Object|*}
 */
export function getObjectById(appState, objectName, id) {
  ////console.log(objectName, appState, appState[objectName]);
  let res = null

  if (!appState || !appState[objectName]) return false

  for (let i = 0; i < appState[objectName].length; i++) {
    if (appState[objectName][i].identity.id === id) {
      res = appState[objectName][i]
      break
    }
  }

  return Object.assign({}, res)
}

// pseudo random value [0,1] which depends on object name
export function demoRandom(name) {
  let sum = 1
  for (let i = 0; i < name.length; i++) {
    sum += name.charCodeAt(i)
  }
  return ((sum * 731) % 93) / 93
}

export function demoRatingByName(name) {
  let rating = { good: 0, bad: 0 }
  /*
  for (let i = 0; i < name.length; i++) {
    if (name[i] > 'g')
      rating.bad++;
    else
      rating.good++;
  }
  */
  return rating
}

//console.log("METADATA", Repo, Jsons);

export function getJsonSpecFields(objectType) {
  if (!objectType || !objectJsons[objectType]) return []

  return objectJsons[objectType].structure.fields
    ? objectJsons[objectType].structure.fields
    : objectJsons[objectType].structure.field
}

export function getJsonSpecRecords(objectType) {
  if (!objectType || !objectJsons[objectType]) return []

  return objectJsons[objectType].data.records
    ? objectJsons[objectType].data.records
    : objectJsons[objectType].data.record
}

export function getJsonSpecFieldOrdinal(objectType, fieldName) {
  if (!objectType || !objectJsons[objectType]) return -1

  const fields = objectJsons[objectType].structure.fields
    ? objectJsons[objectType].structure.fields
    : objectJsons[objectType].structure.field
  const fieldIndex = fields.findIndex((f) => nonCSCompare(f.identity.name.trim(), fieldName.trim()))
  if (fields && fields[fieldIndex] && fields[fieldIndex].ordinal) {
    return fields[fieldIndex].ordinal
  }
  return fieldIndex
}

/**
 * Get records from dataset
 * @param {Dataset} ds
 * @return {Array}
 */
export function getSpecRecords(ds) {
  return ds && ds.data.records ? ds.data.records : []
}

/**
 * Get fields of dataset
 * @param {Dataset} ds
 * @return {Array}
 */
export function getSpecFields(ds) {
  return ds && ds.structure.fields ? ds.structure.fields : []
}

export function getSpecFieldOrdinal(ds, fieldName) {
  if (!ds) return -1

  const fields = getSpecFields(ds)

  if (fields.length === 0) return -1

  const fieldIndex = fields.findIndex((field) => field.identity.name === fieldName)

  if (fields[fieldIndex] && fields[fieldIndex].ordinal) return fields[fieldIndex].ordinal

  return fieldIndex
}

export function getSpecFieldOrdinalByUsage(ds, usage) {
  if (!ds) return -1

  const fields = getSpecFields(ds)
  if (!fields || fields.length === 0) return -1

  const fieldIndex = fields.findIndex((field) => nonCSCompare(field.usage, usage))
  if (fields[fieldIndex] && fields[fieldIndex].ordinal) return fields[fieldIndex].ordinal

  return fieldIndex
}

// get only properties of object that are displayed in Properties component, and their types
// output: [{property1: type1}, {property2: type2}]

function objectTypesProperties(objectType, sourceObject) {
  let ret = []
  let json = objectJsons[objectType]
  //console.log('objectPropertyTypes json',objectType, json);
  if (!json) {
    return []
  }

  for (let j in json.structure.fields) {
    const field = json.structure.fields[j]
    if (!field || !field.identity) continue
    let name = field.identity.name
    if (!name && field.reference) name = field.reference
    if (!name) continue
    let displayName = name
    name = name.toLowerCase()
    let type = Object.assign({ name: field.type }, !field.reference ? {} : { reference: field.reference })

    //console.log("objectTypesProperties", sourceObject, name, Object.keys(sourceObject).map(key => key.toLowerCase()));

    ret.push(field)

    if (
      Object.keys(sourceObject)
        .map((key) => key.toLowerCase())
        .includes(name) &&
      objectPropertyCI(sourceObject, name, null) !== null
    ) {
      //ret.push({name: displayName, description: field.identity.description, type: type, count: field.count, value: objectPropertyCI(sourceObject,name,null),
      //  permission: field.permision, access: field.access, privacy: field.privacy, defaultValue: field.value, usage: field.usage});
    } else {
      //ret.push({name: displayName, description: field.identity.description, type: type, count: field.count, value: field.value || defaultValue(type),
      //  permission: field.permision, access: field.access, privacy: field.privacy, defaultValue: field.value, usage: field.usage});
    }
  }

  // Hardcoded organization integration property
  // if (objectType === 'organization') {
  //   ret.push({
  //     name: 'integration',
  //     description: 'Information for integration with external source control system',
  //     type: {
  //       name: 'structure',
  //       reference: '/organizations/Apdax/systems/DifHub/applications/System/datasets/Integration/versions/1.0.0'
  //     }
  //   })
  // }

  //  console.log("objectTypesProperties "+objectType, ret);

  return ret.map((el) => {
    if (objectType === 'object') {
      el.fromObject = true
    }
    return el
  })
}

export function createDataObject(objectType, objectJSON) {
  let ret = {}
  let json = objectType ? objectJsons[objectType] : objectJSON
  //console.log("createEmptyObject:JSON", objectType, json);

  if (
    !json ||
    !json.structure ||
    !json.structure.fields ||
    json.structure.fields.length < 1 ||
    !json.data ||
    !json.data.records ||
    json.data.records.length < 1
  ) {
    return null
  }

  // We need fields array in order of fields.
  let fields = json.structure.fields.sort((a, b) => {
    const ao = parseInt(a.order)
    const bo = parseInt(b.order)

    if (ao > bo) return 1
    else if (bo > ao) return -1
    return 0
  })

  // We need to iterate all fields of structure and create fields.
  for (let j in fields) {
    const field = fields[j]

    // Get name from identity or from field reference.
    let name = field.identity.name
    let value = field.value

    if (!name) continue

    // If value not specified make it from type.
    if (
      !json.data.records[0].values ||
      json.data.records[0].values[j] === null ||
      json.data.records[0].values[j] === '' ||
      json.data.records[0].values[j] === undefined
    ) {
      if (!value || value === null) value = getDefaultValue(field.type)
    } else {
      //logger.info("createDataObject:VALUE", value, json.data.records[0].values[j], json);
      value = json.data.records[0].values[j]
    }

    // We create data for this field.
    ret[name] = value
  }

  logger.info('createDataObject:RESULT', objectType, json, ret)
  return ret
}

export function majorObjectTypesProperties(objectType, sourceObject) {
  //console.log("majorObjectTypesProperties", deepCopy(sourceObject));

  let ret = objectTypesProperties(objectType, sourceObject)
  if (sourceObject.object) {
    ret = ret.concat(objectTypesProperties('object', sourceObject.object))
  }
  return ret.sort((a, b) => b - a)
}

export function isMinorObject(objectType) {
  return objectType === 'field' || objectType === 'layout'
}

export function buttonsByAction(typeName) {
  const messageTypeDataset = md.repo.metadata.apdax.systems.difhub.applications.messaging.datasets.messagetype
  const messageActionDataset = md.repo.metadata.apdax.systems.difhub.applications.messaging.datasets.messageaction

  let ret = []
  let actions = []

  messageTypeDataset.data.records.map((messageType) => {
    if (messageType.values[0] === typeName) {
      actions = messageType.values[7].split(', ')
    }
  })
  //console.log("buttonsByAction", typeName, actions, messageTypeDataset);
  if (actions.length > 0) {
    ret = actions.map((action) => {
      let res = null
      messageActionDataset.data.records.map((messageAction) => {
        if (messageAction.values[0] == action)
          res = {
            action: messageAction.values[0],
            caption: messageAction.values[3],
            role: messageAction.values[5]
          }
      })
      return res
    })
    //console.log("buttonsByAction res", ret);
    return ret.filter((button) => button != null)
  }
  return []
}

/**
 * Decide what buttons need to be visualized based on user role
 * @param {object} [button] button ewevwant to decide
 * @param {string} [role] user role
 * @return {boolean} true if visualize
 */
export function buttonVisibleByRole(button, role) {
  //console.log("buttonVisibleByRole", button, role);

  if ((role === 'Architect'||role === 'Product') && (button.role === 'Architect' || button.role === 'Developer' || button.role === 'Analyst'))
    return true

  if ((role === 'Developer' || role === 'Business')&& (button.role === 'Developer' || button.role === 'Analyst')) return true

  if ((role === 'Analyst'||role === 'Operations') && button.role === 'Analyst') return true

  return false
}

// Date/time
export function getLocalMoment(dateString) {
  const d = new Date()
  const localOffset = d.getTimezoneOffset()

  return moment(dateString.toString()).subtract(localOffset, 'm')
}

export function getLocalDate(dateString) {
  return getLocalMoment(dateString).format('MM/DD/YY')
}

export function getLocalTime(dateString) {
  return getLocalMoment(dateString).format('hh:mm A')
}

export function getUTCTime() {
  const d = new Date()
  return d.getTime() - d.getTimezoneOffset() * 60 * 1000
}

export function renderDateOrTime(dateString) {
  const d = new Date()
  const now = moment(d.getTime())
  const notNow = moment(dateString)
  const diff = now.diff(notNow)
  const duration = moment.duration(diff)

  if (Math.abs(duration.asHours()) > 24) return getLocalDate(dateString)
  else return getLocalTime(dateString)
}

export function shortAttached(path) {
  if (!path) return ''

  let ret = path
  for (let j in shortnames) {
    ret = ret.replace(j, shortnames[j])
  }
  return ret
  //return path.replace('/organizations/','/org/').replace('/systems/','/sys/');
}

// /Date/time

// Version
/**
 * Return version description from metadata
 * @return version description
 */
export function getVersionDescription(name) {
  const versionData = md.repo.metadata.apdax.systems.difhub.applications.organization.datasets.version.structure.fields
  let ret = null
  versionData.map((field) => {
    if (field.identity.name === name) ret = field.identity.description
  })
  //console.log("getVersionDescription", name, versionData, ret);
  return ret
}

/**
 * Get options of next version for datatset
 * @return version options
 */
export function getVersionOptions(version) {
  //console.log("getVersionOptions", version);
  return [
    {
      id: 1,
      name: 'Revision',
      label: version.major + '.' + version.minor + '.' + (version.revision + 1),
      value: {
        major: version.major,
        minor: version.minor,
        revision: version.revision + 1
      }
    },
    {
      id: 2,
      name: 'Minor',
      label: version.major + '.' + (version.minor + 1) + '.' + 0,
      value: { major: version.major, minor: version.minor + 1, revision: 0 }
    },
    {
      id: 3,
      name: 'Major',
      label: version.major + 1 + '.' + 0 + '.' + 0,
      value: { major: version.major + 1, minor: 0, revision: 0 }
    }
  ].map((option) => {
    return Object.assign(option, {
      description: getVersionDescription(option.name)
    })
  })
}

export function checkLog(log, fieldName) {
  return log && log.field ? log.field.reduce((p, c) => (c.name == fieldName ? true : p), false) : false
}

/**
 * Non case sensitive string compare
 * @param {String} str1
 * @param {String} str2
 */
const __strCollator = new Intl.Collator('en-us', {
  sensitivity: 'base',
  numeric: true
})

/**
 * Object compare
 * @param {Object} obj1
 * @param {Object} obj2
 */
export function objCompare(obj1, obj2) {
  if ((!obj1 && !obj2) || (!obj1.identity && !obj2.identity)) {
    // This is two empty objects case, we consider it equal
    return true
  } else if (!obj1 || !obj2 || !obj1.identity || !obj2.identity) {
    // We are compare null with object
    return false
  } else if (obj1.identity.id !== obj2.identity.id) {
    // We have different objects
    return false
  } else if (obj1.identity.id !== obj2.identity.id) {
    // We have different objects
    return false
  } else {
    return true
  }
}

/**
 * Object version compare
 * @param {Object} obj1
 * @param {Object} obj2
 */
export function verCompare(obj1, obj2) {
  if (!obj1 && !obj2) {
    // This is two empty objects case, we consider it equal
    return true
  } else if (!obj1 || !obj2) {
    // We are compare null with object
    return false
  } else if (!obj1.identity || !obj2.identity) {
    return false
  } else if (obj1.identity.id !== obj2.identity.id) {
    // We have different objects
    return false
  } else if (obj1.identity.id !== obj2.identity.id) {
    // We have different objects
    return false
  } else if (!obj1.version || !obj2.version) {
    return false
  } else if (
    obj1.version.major !== obj2.version.major ||
    obj1.version.minor !== obj2.version.minor ||
    obj1.version.revision !== obj2.version.revision
  ) {
    return false
  } else if (!obj1.object || !obj1.object.history || !obj2.object || !obj2.object.history) {
    return false
  } else if (obj1.object.history.updated !== obj2.object.history.updated) {
    return false
  } else {
    return true
  }
}

/**
 * Version coompare
 * @param {Object} ver1
 * @param {Object} ver2
 */
export function versionCompare(ver1, ver2) {
  if (!ver1 && !ver2) {
    // This is two empty objects case, we consider it equal
    return true
  } else if (!ver1 || !ver2) {
    // We are compare null with object
    return false
  } else if (ver1.major !== ver2.major || ver1.minor !== ver2.minor || ver1.revision !== ver2.revision) {
    return false
  } else if (!nonCSCompare(ver1.label, ver2.label)) {
    return false
  } else {
    return true
  }
}

/**
 * Wait for condition function to satisfy
 */
export function waitFor(conditionFunction) {
  const poll = (resolve) => {
    if (conditionFunction()) resolve()
    else setTimeout((_) => poll(resolve), 100)
  }

  return new Promise(poll)
}

/**
 * Sort for version based on version structure
 */
export function versionSort(a, b) {
  if (!b) return -1
  if (!a) return 1
  if (a.major > b.major) return -1
  else if (a.major < b.major) return 1
  else if (a.minor > b.minor) return -1
  else if (a.minor < b.minor) return 1
  else if (a.revision > b.revision) return -1
  else if (a.revision < b.revision) return 1
  else return 0
}

/**
 * Sort for issues based on isue name
 */
export function issueSort(a, b) {
  const aNumber = parseInt(a.identity.name)
  const bNumber = parseInt(b.identity.name)
  if (aNumber > bNumber) {
    return -1
  } else if (aNumber < bNumber) {
    return 1
  } else {
    return 0
  }
}

/**
 * Sort for elements with name and order
 */
export function elementSort(a, b) {
  if (!a && !b) {
    return 0
  } else if (a && !b) {
    return -1
  } else if (b && !a) {
    return 1
  }

  if (!a.order && !b.order) {
    return identitySort(a, b)
  }

  return orderSort(a, b)
}

/**
 * Sort for elements with order
 */
export function orderSort(a, b) {
  if (a.order && !b.order) {
    return -1
  } else if (!a.order && b.order) {
    return 1
  } else if (!a.order && !b.order) {
    return 0
  }

  let orderA = parseInt(a.order)
  let orderB = parseInt(b.order)

  if (orderA > orderB) {
    return 1
  } else if (orderA < orderB) {
    return -1
  }
  return 0
}

/**
 * Sort for elements with name
 */
export function identitySort(a, b) {
  if (a.identity && !b.identity) {
    return -1
  } else if (b.identity && !a.identity) {
    return 1
  } else if (!a.identity && !b.identity) {
    return 0
  }

  let nameA = a.identity.name.toLowerCase()
  let nameB = b.identity.name.toLowerCase()

  if (nameA > nameB) {
    return 1
  } else if (nameA < nameB) {
    return -1
  }
  return 0
}

/**
 * Sort function for reference options
 * @param a first option
 * @param b second option
 * @returns {number}
 */
export function referenceSort(a, b) {
  /*
    (a, b) => (a.label.charAt(0) === '/' && b.label.charAt(0) !== '/') ?
     1 : ((a.label.charAt(0) !== '/' && b.label.charAt(0) === '/') ?
     -1 : (a.label > b.label ? 1 : (a.label === b.label ? 0 : -1)))
     */

  const aIsFromOrg = a.label.charAt(0) === '/'
  const bIsFromOrg = b.label.charAt(0) === '/'
  const aIsFromSub = a.label.split('/').length > 2
  const bIsFromSub = b.label.split('/').length > 2

  if (aIsFromOrg && !bIsFromOrg) return 1
  if (bIsFromOrg && !aIsFromOrg) return -1
  if (aIsFromSub && !bIsFromSub) return 1
  if (bIsFromSub && !aIsFromSub) return -1
  if (a.label.toLowerCase() > b.label.toLowerCase()) return 1
  else if (b.label.toLowerCase() > a.label.toLowerCase()) return -1
  else return 0
}

/**
 * Sort function for enum options
 * @param a first option
 * @param b second option
 * @returns {number}
 */
export function optionSort(a, b) {
  if (a.label.toLowerCase() > b.label.toLowerCase()) return 1
  else if (b.label.toLowerCase() > a.label.toLowerCase()) return -1
  else return 0
}

export function majorObjectSort(a, b) {
  if (!a && !b) return 0
  if (a && !b) return -1
  if (b && !a) return 1

  if (!a.identity && !b.identity) {
    if (a.Code && b.Code) {
      if (parseInt(a.Code) > parseInt(b.Code)) return 1
      else if (parseInt(a.Code) < parseInt(b.Code)) return -1
      else return 0
    }
  }

  if (!a.identity && !b.identity) return 0
  if (a.identity && !b.identity) return -1
  if (b.identity && !a.identity) return 1

  if (!a.identity.name && !b.identity.name) return 0
  if (a.identity.name && !b.identity.name) return -1
  if (b.identity.name && !a.identity.name) return 1

  if (a.identity.name.indexOf(':') === -1 && b.identity.name.indexOf(':') !== -1) return -1
  if (a.identity.name.indexOf(':') !== -1 && b.identity.name.indexOf(':') === -1) return 1

  if (a.identity.name.toLowerCase() > b.identity.name.toLowerCase()) return 1
  else if (a.identity.name.toLowerCase() < b.identity.name.toLowerCase()) return -1
  else return 0
}

export function getUserPhotoUrl(id) {
  if (!id) {
    return DifHubTeamPhoto
  }

  return apiURL + API.resources.url() + '?uri=users/' + id + '/' + 'photo'
}

export function checkResourceUrl(url) {
  return getFullResourceUri(url)
}

/**
 * Returns full uri of resource
 * @param {string} resourceUri
 * @returns {string} - uri
 */
export function getFullResourceUri(resourceUri) {
  // Check if we have uri.
  if (!resourceUri) return ''

  // Backward compatability for URI
  if (resourceUri.startsWith('https://bias-metadata-service.difhub.com/')) {
    return resourceUri.replace('https://bias-metadata-service.difhub.com/', apiURL)
  }

  // If it is full uri use it directly.
  if (resourceUri.startsWith('http://') || resourceUri.startsWith('https://') === 0) return resourceUri

  // If it already resource URI use it with service.
  if (resourceUri.startsWith(API.resources.url() + '?uri=')) {
    return apiURL + resourceUri
  }

  // Create uri for file.
  return apiURL + API.resources.url() + '?uri=' + resourceUri
}

/**
 * Returns display version for reference URI
 * @param uri - full uri of reference object
 * @param ds - dataset, which contains reference object
 * @param appUri - uri for application which contains reference object
 * @return displayURI - display version of reference URI
 */
export function displayUri(uri, ds, appUri) {
  let returnDisplayUri = ''

  if (!uri || uri.length == 0 || !appUri || appUri.length == 0) {
    return returnDisplayUri
  }

  const appSplit = appUri.substring(1).split('/')
  const pathSplit = uri.substring(1).split('/')

  const appURI = pathByType(appSplit, 'applications')
  const subURI = pathByType(pathSplit, 'subscriptions')
  const dsURI = pathByType(pathSplit, 'datasets', true, true)

  if (ds && ds.subscription && ds.subscription.identity && ds.subscription.identity.name) {
    // We have uri for data set of subscription. We need to form right subscription in path
    returnDisplayUri = '..' + '/sub/' + ds.subscription.identity.name + dsURI
  } else if (subURI && subURI.length > 0) {
    // We have child of other application: we have subscription in path
    returnDisplayUri = '..' + pathByType(pathSplit, 'subscriptions', true, true)
  } else if (uri.startsWith(appURI)) {
    // This is dataset from same application: wee simply remove dataset and slashes
    returnDisplayUri = dsURI.substring(4)
  } else {
    // We have organization child: we keep slash before name
    returnDisplayUri = dsURI.substring(3)
  }
  //returnDisplayUri = returnDisplayUri || uri;
  console.log('displayUri', uri, ds, appURI, subURI, dsURI, returnDisplayUri)

  return returnDisplayUri
}

/**
 * Returns display version of uri for dataset. It have 3 forms -
 * data set from subscription, application or organization.
 * @param ds - dataset for which we wantt URI to be presented
 * @return displayURI - display version of reference URI
 */
export function displayDatasetUri(ds) {
  let returnDisplayUri = ''

  if (!ds) {
    return returnDisplayUri
  }

  if (ds.subscription && ds.subscription.identity && ds.subscription.identity.name) {
    // We have uri for dataset from subscription of the application.
    // We don't need subscription version as version of dataset define subscription it is from.
    returnDisplayUri = ds.subscription.identity.name + '/' + ds.identity.name + displayVersion(ds)
  } else if (ds.object.parent) {
    // We have uri for regular object of the application.
    returnDisplayUri = ds.identity.name + displayVersion(ds)
  } else {
    // We have uri of dataset from organization.
    returnDisplayUri = '/' + ds.identity.name + displayVersion(ds)
  }
  return returnDisplayUri
}

/**
 * Return formated version for dataset.
 * @param ds - dataset for which we wantt URI to be presented
 * @return displayVersion - display version of reference URI
 */
export function displayVersion(ds) {
  let returnDisplayVersion = ''

  if (!ds || !ds.version) {
    return returnDisplayVersion
  }

  returnDisplayVersion = '/v' + ds.version.major

  return returnDisplayVersion
}

/**
 * Returns display format of URI by reference and subscription
 * Object from same application it is in formate "name/version",
 * when it from subscription "subscription/name/version",
 * and from organization it "/name/version"
 * @param {string} ref - reference uri to actual object
 * @param {string} sub - subscripton uri used for access to object
 * @return {string} displayURI - display version of reference URI
 */
export function displayReferenceUri(ref, sub) {
  //console.log("displayReferenceUri", ref, sub);
  let displayURI = ''

  if (!ref || ref.length < 1) {
    return displayURI
  }

  // It is not global URI we return it as is.
  if (!ref.startsWith('/')) {
    return ref
  }

  // split reference URI and extract dataset URI.
  const refSplit = ref.substring(1).split('/')
  let appName = nameByType(refSplit, 'applications')
  let sysName = nameByType(refSplit, 'systems')
  let verName = nameByType(refSplit, 'versions')
  let objType = ''
  let objName = ''

  if (appName) {
    // Application object name.
    objType = refSplit[6]
    objName = refSplit[7]
  } else if (sysName) {
    // System object name
    objType = refSplit[4]
    objName = refSplit[5]
  } else {
    // Organization object name
    objType = refSplit[2]
    objName = refSplit[3]
  }

  verName = verName ? '/v' + verName.split('.')[0] : ''

  if (appName || sysName) {
    // We have uri for regular object of the application.
    if (sub && sub.length > 0) {
      const subSplit = sub.substring(1).split('/')
      const subName = nameByType(subSplit, 'subscriptions')

      displayUri = (subName ? subName + '/' : '') + objName + verName
    } else {
      displayUri = objName + verName
    }
  } else {
    // We have uri of dataset from organization. Reference can be only for organization datasets
    displayUri = '/' + objName + verName
  }
  return displayUri
}

/**
 * Returns display version of uri for field of dataset. It have 3 forms -
 * data set from subscription, application or organization.
 * @param fld - field of dataset we want display URI for
 * @param ds - dataset which contains the field
 * @return displayURI - display version of reference URI
 */
export function displayFieldUri(fld, ds) {
  let displayFieldUri = ''

  if (!ds || !fld || !fld.reference || fld.reference.length == 0) {
    return displayFieldUri
  }

  //logger.info("displayFieldUri", fld, ds);

  // split reference URI and extract dataset URI.
  const refSplit = fld.reference.substring(1).split('/')
  let dsName = nameByType(refSplit, 'datasets')
  let verName = nameByType(refSplit, 'versions')

  if (!dsName) {
    if (refSplit.length === 1) dsName = refSplit[0]
  }

  verName = verName ? '/v' + verName.split('.')[0] : ''

  if (ds.subscription && ds.subscription.identity && ds.subscription.identity.name) {
    // We have uri for dataset from subscription of the application.
    return displayFieldUri
  } else if (ds.object.parent) {
    // We have uri for regular object of the application.
    // Let's manage new way to specify object and keep backward compatability
    if (fld.subscription && fld.subscription.length > 0) {
      const subSplit = fld.subscription.substring(1).split('/')
      const subName = nameByType(subSplit, 'subscriptions')

      if (fld.subscription.startsWith(ds.object.parent.name)) {
        // This is dataset from subscription of same application.
        displayFieldUri = (subName ? subName + '/' : '') + dsName + verName
      } else {
        // This is dataset from subscription of other application.
        displayFieldUri = '??/' + dsName + verName
      }
    } else {
      const subName = nameByType(refSplit, 'subscriptions')

      if (subName) {
        // We in backward compatability mode and have subscription here
        displayFieldUri = subName + '/' + dsName + verName
      } else if (fld.reference.startsWith(ds.object.parent.name)) {
        // This is dataset from same application.
        displayFieldUri = dsName + verName
      } else {
        // This is dataset from organization or ather application.
        const appName = nameByType(refSplit, 'applications')

        if (appName && appName.length > 0) {
          // This is dataset from unspecified subscription.
          if (fld.reference.startsWith('/organizations/Apdax')) {
            // For Apdax, we expect it to be from organization.
            displayFieldUri = '/' + dsName + verName
          } else {
            // This is dataset from some other application without subscription.
            displayFieldUri = '??/' + dsName + verName
          }
        } else {
          // This is dataset from organization
          displayFieldUri = '/' + dsName + verName
        }
      }
    }
  } else {
    // We have uri of dataset from organization. Reference can be only for organization datasets
    displayFieldUri = '/' + dsName + verName
  }
  //console.log("displayFieldUri", fld, ds, dsName, verName, displayFieldUri);
  return displayFieldUri
}

/**
 * Check if field represents dataset.
 * @param ds - dataset for which we compare
 * @param app - object which contains application URI
 * @param fld - field with data we compare
 * @param version - up to what versionobject which contains application URI
 * @return true if it sane
 */
export function isDatasetField(ds, app, fld, version) {
  if (!ds || !app || !fld || !fld.reference) {
    return false
  }

  // field has reference ?Usage=SomeUsage
  if (fld.reference.indexOf('?') !== -1) return false

  const refSplit = fld.reference.substring(1).split('/')
  let dsName = nameByType(refSplit, 'datasets')

  // Check name of datasets match
  if (!nonCSCompare(dsName, ds.identity.name)) return false

  //console.log("isDatasetField:NAME", ds, app, fld, version);

  // Check version if specified
  if (version) {
    let verName = nameByType(refSplit, 'versions')

    if (verName) {
      // We have version to  compare.
      let versions = verName.split('.')

      // Check if major version specified and match
      if (versions[0] && parseInt(versions[0]) !== ds.version.major) return false

      // Check if minor version specified and match
      if (version === minor || version === revision) {
        if (versions[1] && parseInt(versions[1]) !== ds.version.minor) return false
      }

      // Check if revision version specified and match
      if (version === revision) {
        if (versions[2] && parseInt(versions[2]) !== ds.version.revision) return false
      }
    }
  }

  let subName
  let subPath

  // Let's find out subscription name
  if (fld.subscription && fld.subscription.length > 0) {
    const subSplit = fld.subscription.substring(1).split('/')

    subName = nameByType(subSplit, 'subscriptions')
    subPath = subName ? pathByType(subSplit, 'applications') : ''
  } else {
    subName = nameByType(refSplit, 'subscriptions')
    subPath = subName ? pathByType(refSplit, 'applications') : ''
  }

  //console.log("isDatasetField:SUB", subName, subPath, ds);

  if (subName) {
    // We have backward compatable situation and need simply to check for application URI.
    if (
      !ds.subscription ||
      !ds.subscription.identity ||
      !ds.subscription.identity.name ||
      !nonCSCompare(subName, ds.subscription.identity.name)
    )
      return false

    let appPath = pathByType(getFullUri(app).substring(1).split('/'), 'applications')
    if (!nonCSCompare(appPath, subPath)) return false

    return true
  }

  if (ds.subscription && ds.subscription.identity && ds.subscription.identity.name) {
    // We have uri for dataset from subscription of the application.
    return false
  } else if (ds.object.parent) {
    // We have uri for regular object of the application.
    let appPath = pathByType(refSplit, 'applications')

    if (!ds.object.parent.name || !nonCSCompare(ds.object.parent.name, appPath)) return false
  } else {
    // We have uri of dataset from organization.
    let dsPath = pathByType(refSplit, 'datasets')
    let refPath = pathByType(getFullUri(app).substring(1).split('/'), 'organizations') + '/datasets/' + ds.identity.name

    if (!nonCSCompare(dsPath, refPath)) return false
  }
  return true
}

/**
 * Returns storage version of uri for dataset. It have 3 forms -
 * data set from subscription, application or organization.
 * @param ds - dataset for which we want URI to be presented.
 * @param app - object which contains application URI in his full URI.
 * @return storageURI - storage version of reference URI
 */
export function storageSubscriptionUri(ds, app) {
  let returnStorageUri = ''

  if (!ds || !app) {
    return returnStorageUri
  }

  // form ?Usage=SomeUsage is stored as is
  if (ds.identity && ds.identity.name.indexOf('?') === 0) {
    return ''
  }

  //logger.info("storageSubscriptionUri", ds, app);

  if (ds.subscription && ds.subscription.identity && ds.subscription.identity.name) {
    // We have uri for dataset from subscription of the application.
    returnStorageUri =
      pathByType(getFullUri(app).substring(1).split('/'), 'applications') +
      '/subscriptions/' +
      ds.subscription.identity.name +
      storageVersion(ds.subscription)
  } else if (ds.object && ds.object.parent) {
    // We have uri for regular object of the application.
    returnStorageUri = ''
  } else {
    // We have uri of dataset from organization.
    returnStorageUri = ''
  }
  return returnStorageUri
}

/**
 * Returns storage version of uri for dataset. It have 3 forms -
 * dataset from subscription, application or organization.
 * @param ds - dataset for which we wantt URI to be presented
 * @param app - object which contains application URI
 * @return storageURI - storage version of reference URI
 */
export function storageDatasetUri(ds, app) {
  let returnStorageUri = ''

  if (!ds || !app) {
    return returnStorageUri
  }

  // form ?Usage=SomeUsage is stored as is
  if (ds.identity && ds.identity.name.indexOf('?') === 0) {
    return ds.identity.name
  }

  if (ds.subscription && ds.subscription.identity && ds.subscription.identity.name) {
    // We have uri for dataset from subscription of the application.
    returnStorageUri = ds.object.parent.name + '/datasets/' + ds.identity.name + storageVersion(ds)
    //console.log("storageDatasetUri", ds, app, "We have uri for dataset from subscription of the application", returnStorageUri) ;
  } else if (ds.object && ds.object.parent) {
    // We have uri for regular object of the application.
    let path = getFullUri(ds)
    let version = storageVersion(ds)
    returnStorageUri = path.endsWith(version) ? path : path + version
    //console.log("storageDatasetUri", ds, app, "We have uri for regular object of the application", returnStorageUri) ;
  } else if (ds.identity) {
    // We have uri of dataset from organization.
    returnStorageUri =
      pathByType(getFullUri(app).substring(1).split('/'), 'organizations') +
      '/datasets/' +
      ds.identity.name +
      storageVersion(ds)
    //console.log("storageDatasetUri", ds, app, "We have uri of dataset from organization.", returnStorageUri) ;
  }
  return returnStorageUri
}

/**
 * Returns storage version of uri for interface. It have 3 forms -
 * interface from subscription, application or organization.
 * @param intr - dataset for which we wantt URI to be presented
 * @param app - object which contains application URI
 * @return storageURI - storage version of reference URI
 */
export function storageInterfaceUri(intr, app) {
  let storageInterfaceUri = ''

  if (!intr || !app) {
    return storageInterfaceUri
  }

  if (intr.subscription && intr.subscription.identity && intr.subscription.identity.name) {
    // We have uri for dataset from subscription of the application.
    storageInterfaceUri = intr.object.parent.name + '/interfaces/' + intr.identity.name + storageVersion(intr)
    //console.log("storageInterfaceUri", intr, app, "We have uri for interface from subscription of the application", storageInterfaceUri);
  } else if (intr.object.parent) {
    // We have uri for regular object of the application.
    storageInterfaceUri = getFullUri(intr) + storageVersion(intr)
    //console.log("storageInterfaceUri", intr, app, "We have uri for regular object of the application", storageInterfaceUri);
  } else {
    // We have uri of dataset from organization.
    storageInterfaceUri =
      pathByType(getFullUri(app).substring(1).split('/'), 'organizations') +
      '/interfaces/' +
      intr.identity.name +
      storageVersion(intr)
    //console.log("storageInterfaceUri", intr, app, "We have uri of dataset from organization.", storageInterfaceUri);
  }
  return storageInterfaceUri
}

/**
 * Returns storage version of uri for any object. It have 4 forms -
 * object from subscription, application, system or organization.
 * @param obj - dataset for which we want URI to be presented
 * @param app - object which contains application URI
 * @return storageURI - storage version of reference URI
 */
export function storageObjectUri(obj, app) {
  let returnStorageUri = ''

  if (!obj || !app) {
    return returnStorageUri
  }

  // form ?Usage=SomeUsage is stored as is
  if (obj.identity && obj.identity.name.indexOf('?') === 0) {
    return obj.identity.name
  }

  let version = storageVersion(obj)

  if (obj.subscription && obj.subscription.identity && obj.subscription.identity.name) {
    // We have uri for dataset from subscription of the application.
    let path = getFullUri(obj)
    returnStorageUri = path.endsWith(version) ? path : path + version
    //console.log("storageObjectUri", obj, app, "We have uri for dataset from subscription of the application", returnStorageUri) ;
  } else if (obj.object && obj.object.parent) {
    // We have uri for regular object of the application.
    let path = getFullUri(obj)
    returnStorageUri = path.endsWith(version) ? path : path + version
    //console.log("storageObjectUri", obj, app, "We have uri for regular object of the application", returnStorageUri) ;
  } else if (obj.identity) {
    // We have uri of dataset from organization.
    returnStorageUri =
      pathByType(getFullUri(app).substring(1).split('/'), 'organizations') + '/datasets/' + obj.identity.name + version
    //console.log("storageObjectUri", obj, app, "We have uri of dataset from organization.", returnStorageUri) ;
  }
  return returnStorageUri
}

/**
 * Object version compare
 * @param {Object} obj
 * @return version of the object
 */
export function getFullVersion(obj) {
  let version = {}
  if (obj && obj.version) {
    version.major = obj.version.major
    version.minor = obj.version.minor
    version.revision = obj.version.revision
    if (obj.version.label) version.label = obj.version.label
  }
  if (!version.label && obj && obj.object && obj.object.history && obj.object.history.updated) {
    version.label = obj.object.history.updated
  }
  return version
}

/**
 * Returns storage dataset version.
 * @param obj - object for which we wantt URI to be presented
 * @return storageVersion - storage version of dataset
 */
export function storageVersion(obj) {
  let returnStorageVersion = ''

  if (!obj || !obj.version) {
    return returnStorageVersion
  }

  returnStorageVersion = '/versions/' + obj.version.major + '.' + obj.version.minor + '.' + obj.version.revision

  return returnStorageVersion
}

/**
 * Calculate status of the version
 * @param {object} version - object with version history information
 * @return {string} versio status
 */
export function getVersionStatus(version) {
  // SEfalt status is draft
  let status = 'draft'
  if (!version || !version.object || !version.object.history || !version.object.history.completions) return status

  let completions = version.object.history.completions

  // Analize completion to have version status
  if (completions) {
    if (completions.reduce((p, c, i) => (nonCSCompare(c.status, 'Approved') ? true : p), false)) status = 'approved'
    else if (completions.reduce((p, c, i) => (nonCSCompare(c.status, 'Finalized') && c.completed ? true : p), false))
      status = 'finalized'
  }
  return status
}

/**
 * Return object type taken from object uri
 * @param {string} uri
 * @returns {string} singular type of the object.
 */
export function typeFromUri(uri) {
  if (!uri) return ''

  const splitUri = uri.substr(1).split('/')

  for (let i = 0; i < splitUri.length; i += 2) {
    if (splitUri[i] === 'versions') {
      return singularType(splitUri[i - 2])
    }
  }
  return singularType(splitUri[i - 2])
}

/**
 * Returns full object uri with version
 * @param obj
 * @returns {string}
 */
export function getFullUriVersioned(obj) {
  //console.log("getFullUriVersioned", deepCopy(obj));

  let uri = getFullUri(obj)
  if (uri && obj.version) {
    uri = uri + '/versions/' + versionToStr(obj.version)
  }
  return uri
}

export function getFullUriFromShort(shortUri) {
  if (!shortUri || typeof shortUri !== 'string') return ''

  //logger.info("getFullUriFromShort", shortUri);
  //logger.info("getFullUriFromShort:TRACE", Error().stack);

  return shortUri
    .split('/')
    .map((el, i) => {
      if (i % 2 === 0) {
        return el
      }

      const shortTypes = Object.values(shortByType)
      const fullTypes = Object.keys(shortByType)

      const typeIndex = shortTypes.findIndex((st) => st === el)

      //console.log("getFullUriFromShort", el, shortTypes, fullTypes, typeIndex, fullTypes[typeIndex]);

      return fullTypes[typeIndex]
    })
    .join('/')
}

/**
 * Returns parent uri of type or closest parent uri
 * @param obj
 * @param parentType
 * @returns {string}
 */
export function getParentUri(obj, parentType) {
  if (!parentType) return obj.object.parent.name

  const splitParentUri = obj.object.parent.name.substr(1).split('/')
  let parentTypeIndex = -1
  for (let i = 0; i < splitParentUri.length; i += 2)
    if (splitParentUri[i] === pluralTypeForms.get(parentType)) parentTypeIndex = i

  return '/' + splitParentUri.slice(0, parentTypeIndex + 2).join('/')
}

/**
 * Returns name of parent with specified type of name of closest parent
 * @param obj
 * @param parentType
 * @returns {*}
 */
export function getParentName(obj, parentType) {
  const splitParentUri = obj.object.parent.name.substr(1).split('/')

  if (!parentType) return splitParentUri[splitParentUri.length - 1]

  //const parentTypeIndex = splitParentUri.indexOf(parentType+'s');
  let parentTypeIndex = -1
  for (let i = 0; i < splitParentUri.length; i += 2)
    if (splitParentUri[i] === pluralTypeForms.get(parentType)) parentTypeIndex = i

  return splitParentUri[parentTypeIndex + 1]
}

export function objectTypeIndex(path, objectType) {
  const parts = path.split('/')
  const firstTypeIndex = path.substr(0, 1) == '/' ? 1 : 0

  if (Array.isArray(objectType)) {
    for (let t = 0; t < objectType.length; t++) {
      for (let i = firstTypeIndex; i < parts.length - 1; i = i + 2) {
        if (parts[i] === objectType[t]) return i
      }
    }
  } else {
    for (let i = firstTypeIndex; i < parts.length - 1; i = i + 2) {
      if (parts[i] === objectType) return i
    }
  }
  return -1
}

export function formatAlias(alias) {
  if (alias.indexOf('@') > 0) return alias.split('@')[0]

  return alias
}

/**
 * Constructs request from object reference
 * @param {string} ref - reference to dataset
 * @param {string} objPath - path to object that contains structure with ref
 * @return {object} return object that is either request to API or immediately resolved request from library
 */
export function getDatasetRequestFromReference(ref, objPath) {
  // logger.info("getDatasetRequestFromReference", ref, objPath);

  if (ref.indexOf('datasets') === -1) logger.error('getDatasetRequestFromReference::Datasets only!', ref)

  if (ref.indexOf('$:') !== -1) {
    // return imitation of api call
    // logger.warn("we're local", ref);
    return {
      get: () =>
        new Promise((resolve, reject) => {
          const obj = md.repo.findByPath(ref.split('$:')[1])

          // logger.info("getDatasetRequestFromReference", ref, obj);

          resolve(convertDatasetReferencesToLocal(obj))
        })
    }
  }

  return getRequestFromPath(getDatasetPathFromReference(ref, objPath))
}

/**
 * Converts fields references to local
 * @param {Dataset} obj - dataset which references needs to be converted
 * @return {Dataset} - dataset with field references converted to local
 */
export const convertDatasetReferencesToLocal = (obj) => {
  // logger.info("convertDatasetReferencesToLocal", obj);
  const newObj = deepCopy(obj)

  newObj.structure.fields = obj.structure.fields.map(convertFieldReferenceToLocal)
  return newObj
}

function convertFieldReferenceToLocal(field) {
  // logger.info("convertFieldReferenceToLocal", field);

  if (field.reference) {
    // special case for Identity
    const newReference =
      field.reference === '/organizations/Apdax/datasets/Identity'
        ? '$:/organizations/Apdax/systems/DifHub/applications/Organization/datasets/Identity'
        : '$:' + field.reference

    return field.reference.indexOf('$:') !== 0
      ? Object.assign({}, field, {
          reference: newReference
        })
      : field
  }

  return field
}

/**
 * Field is array or single element
 * @param {object} field - field object
 * @returns {boolean}
 */
export function objectFieldIsArray(field) {
  //console.log("objectFieldIsArray", field);

  return field.count === 0 || field.count === '0' || (field.count !== 1 && field.count !== '1')
}

/**
 * Constructs string representation of field type
 * @param {object} field - field object
 * @param {object} dataset - field parent dataset for fields with references
 * @returns {string}
 */
export function fieldTypeToString(field, object) {
  //console.log("fieldTypeToString", field, object);
  return propertyTypeToString(
    field.type,
    field.reference,
    field.count,
    field.size,
    field.precision,
    field.scale,
    field.subscription
  )
}

/**
 * Constructs string representation of property type
 * @param {string} type - field/property type we need to represent
 * @param {string} reference - uri to object we use for type representation
 * @param {integer} count - number of elements in array
 * @param {integer} size - size of type when required
 * @param {integer} precision - presision for decimal type
 * @param {integer} scale - scale for decimal type
 * @returns {string}
 */
export function propertyTypeToString(type, reference, count = 1, size = 0, precision = 0, scale = 0, sub = null) {
  //console.log("propertyTypeToString", type, reference, count, size, precision, scale);
  let res = type

  // Addition based on type
  if (nonCSCompare(type, 'string') || nonCSCompare(type, 'text')) {
    if (size > 0) {
      res += ':' + size
    }
  } else if (nonCSCompare(type, 'decimal')) {
    if (precision > 0) {
      res += ':' + precision + (scale > 0 ? ',' + scale : '')
    }
  }

  // Addition for array
  if (count === 0 || count === '0') {
    res += '[]'
  } else if (count !== 1 && count !== '1') {
    res += '[' + count + ']'
  }

  // Complex type support
  if (reference && (nonCSCompare(type, 'structure') || nonCSCompare(type, 'enum') || nonCSCompare(type, 'reference'))) {
    res += ': ' + displayReferenceUri(reference, sub)
  }

  return res
}

/**
 * Render typed value
 * @param {string} [type] type of value
 * @param {string} [value] value to render
 */
export function propertyValueToString(type, value) {
  let text = ''
  if (nonCSCompare(type, 'structure') && typeof value === 'object') {
    text = JSON.stringify(value)
  } else {
    text = value
  }
  //console.log("propertyValueToString", type, value, text);

  return text
}

export function checkObjectName(name) {
  if (name.match(/[^A-Za-z0-9 _:$@&-]/)) {
    return 'characters'
  }
  let found = false

  // now type names are OK, so do not check
  /*
  ['organizations','systems','applications','datasets','publications','subscriptions','pipelines','issues','messages','users','versions'].map(word => {
    if (word == name.toLowerCase())
      found = true;
  });
  */
  if (found) return 'reserved'
  else return false
}

export const monokaiJsonTheme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633'
}

export function objectPictureUrl(path, picture) {
  return picture ? apiURL + API.resources.url() + '?uri=' + path + '/' + picture : ''
}

export function getPublicationReference(publication) {
  return {
    identity: {
      id: publication.identity.id,
      name: getFullUri(publication)
    },
    version: Object.assign({}, publication.version)
  }
}

// separate file names are needed by Chart view
export const usageIconUris = {
  attribute: 'attribute',
  dataset: 'dataset',
  enum: 'enum',
  event: 'event',
  locale: 'locale',
  resource: 'resource',
  schedule: 'schedule',
  table: 'table',
  hierarchy: 'hierarchy',
  fact: 'fact',
  'rest api': 'api',
  structure: 'structure',
  generic: 'generic',
  database: 'database',
  service: 'service',
  schema: 'schema',
  cube: 'cube',
  dimension: 'dimension',
  union: 'union',
  'work flow': 'workflow',
  'data flow': 'transformation',
  transform: 'transformation',
  settings: 'settings',
  policy: 'policy',
  instance: 'instance',
  control: 'control',
  location: 'location',
  format: 'format'
}
// all React-style modules will use usageIcons as before
//export const usageIcons = requireUsageIcons(usageIconUris);

// for webpack, we have to require path as constant string expression
export function require_image(name) {
  let ret = {}

  ret.normal = '/images/' + name + '.png'
  ret.retina = '/images/' + name + '-2x' + '.png'

  return ret
}

// for webpack, we have to require path as constant string expression
export function require_usage_image(name) {
  let ret = {}

  ret.normal = '/images/usage/34/' + name + '.png'
  ret.retina = '/images/usage/34/' + name + '-2x' + '.png'

  return ret
}

export function getTabDescriptionFromJsons(title) {
  if (!title) return ''

  let objectType = singularType(title)

  let json = objectJsons[objectType]

  //console.log("getTabDescriptionFromJsons", title, objectType, json, objectJsons, md);

  if (!json) {
    return ''
  }

  return json.identity.description
}

export function isCapitalLetter(s) {
  return s == s.toUpperCase()
}

export function objectTypeFromPath(path) {
  if (!path) return null
  const parts = path.split('/')
  const firstTypeIndex = path.substr(0, 1) == '/' ? 1 : 0
  let ret = ''
  for (let i = firstTypeIndex; i < parts.length - 1; i = i + 2) {
    if (parts[i] != 'versions') ret = parts[i]
  }
  return ret
}

/**
 * If reference has version, keep only major version and dicard minor version and revision
 * @param reference
 */
function formatReferenceVersion(reference) {
  if (!reference) return ''

  if (reference.indexOf('/versions/') === -1) return reference

  /*
  const objectPath = reference.substr(0, reference.indexOf('/versions/'));
  const version = objectNameFromPathByType(reference, 'versions');
  const majorVersion = strToVersion(version).major;

  return objectPath + '/versions/' + majorVersion;
  */

  const parts = reference.split('/')
  let ret = parts[0]
  for (let i = 1; i < parts.length; i++) {
    if (parts[i - 1] === 'versions') ret += '/' + strToVersion(parts[i]).major
    else ret += '/' + parts[i]
  }
  return ret
}

export function formatForStructureTable(dataset) {
  return dataset.structure && dataset.structure.fields
    ? dataset.structure.fields
        .map((srcField) => {
          if (!srcField.identity) return { identity: {}, type: {} }

          let field = deepCopy(srcField)
          //console.log("Dataset:formatForStructureTable", field, srcField, dataset);

          if (!field.type) field.type = 'String'
          const newName = {
            name: field.identity.name,
            objectType: 'field',
            path: field.path
          }
          field.description = field.identity.description
          field = Object.assign({}, field, { name: newName })

          for (let prop in field) {
            if (typeof field[prop] === 'number') field[prop] = field[prop] + ''
          }

          let type = field.type.toLowerCase()
          if (type === 'structure' || type === 'enum' || type === 'reference') {
            //console.log("Dataset:formatForStructureTable:URI", field.reference, dataset, dataset.path);
          }

          if (type === 'reference') {
            const appSplit = dataset.object.parent.name.substring(1).split('/')
            const orgURI = pathByType(appSplit, 'organizations')

            // reference displaying Identity is handled in TableRow
            //field.reference = orgURI + '/datasets/Identity';
            //console.log("Dataset:formatForStructureTable:REFERENCE", field.reference);
          }

          field.type = {
            displayType: fieldTypeToString(field, dataset),
            label: field.type,
            count: field.count,
            precision: field.type === 'Decimal' ? field.precision : undefined,
            scale: field.type === 'Decimal' ? field.scale : undefined,
            size: field.type === 'String' ? field.size : undefined,
            datasetReference:
              type === 'structure' || type === 'reference' || type === 'enum'
                ? formatReferenceVersion(field.reference)
                : undefined
          }
          field.id = field.identity.id

          field.key = dataset.structure.keys.reduce((prev, cur) => {
            if (!cur.fields) return ''
            if (cur.fields.indexOf(field.identity.name) === -1) return prev
            if (prev !== 'PRIMARY' && !cur.primary) return 'SECONDARY'
            else return 'PRIMARY'
          }, '')

          // todo: use actual subscription count, when it is returned from backend
          field.subscriptions = { publications: 0, subscriptions: 0 }

          field.required = !field.optional

          return field
        })
        .sort((a, b) => {
          const ao = parseInt(a.order)
          const bo = parseInt(b.order)

          if (ao > bo) return 1
          else if (bo > ao) return -1
          return 0
        })
    : []
}

/**
 *  Case-insensitive object[propertyName]. Returns default value for type, if object does not have property
 */
export function objectPropertyCI(object, propertyName, type) {
  if (!object || !propertyName) return false

  //console.log("objectPropertyCI", object, propertyName);
  for (let prop in object) {
    //console.log("objectPropertyCI compare", prop.toLowerCase(), propertyName);
    if (nonCSCompare(prop, propertyName)) {
      return object[prop]
    }
  }
  return getDefaultValue(type)
}

/**
 *  Case-insensitive object[propertyName]. Returns default value, if object does not have property
 */
export function objectPropertyCIWithDefault(object, propertyName, defaultValue) {
  if (!object || !propertyName) return false
  //console.log("objectPropertyCI", object, propertyName);
  propertyName = propertyName.toLowerCase()
  for (let prop in object) {
    //console.log("objectPropertyCI compare", prop.toLowerCase(), propertyName);
    if (prop.toLowerCase() == propertyName) {
      return object[prop]
    }
  }
  return defaultValue
}

/**
 * Case-insensitive object[propertyName]=propertyValue; creates object and property if they do not exist
 */
export function setObjectPropertyCI(object, propertyName, propertyValue) {
  //console.log("PropertyTable::setObjectPropertyCI", object, propertyName, propertyValue);
  if (!propertyName) return false

  // create the object
  if (!object) return { [propertyName]: propertyValue }

  let newObject = Object.assign({}, object)

  for (let prop in newObject) {
    if (prop.toLowerCase() == propertyName.toLowerCase()) {
      newObject[prop] = propertyValue
      return newObject
    }
  }

  // if property was not found, create it
  newObject[propertyName] = propertyValue
  return newObject
}

export function renderURI(uri) {
  //logger.info("renderURI", uri);
  return uri ? uri.replace(/\//g, '/\u200B').replace(/ /g, '\u00A0') : ''
}

export function isDark(className) {
  //return className === 'dataset' || className === 'field' || className === 'layout' || className === 'interface' || className === 'publication' || className === 'subscription' ||
  //  className === 'system' || className === 'view' || className === 'pipeline';
  return false
}

/**
 * Convert record value to enumerator data into new list of options for drop box control.
 * @param {object} fields - list of fields defined of record in values order.
 * @param {object} record - record of the dataset data.
 * @returns {object} - object record represents
 */
export function recordToObject(fields, record) {
  //console.log('recordToObject', fields, record);
  let object = {}
  if (!fields || fields.length === 0) return object

  fields.map((field, index) => {
    let value = record.values[index]

    let type = field.type ? field.type.toLowerCase() : 'string'
    let isStructure = type === 'structure' || type === 'reference' || field.count == 0 || field.count > 1

    object[field.identity.name] = isStructure ? JSON.parse(value) : value
  })
}

/**
 * Convert enumerator data into new list of options for drop box control.
 * @param {object} datset - dataset to convert to enumerator.
 * @param {string} usage - usage for value in options.
 * @returns {object} - list of options
 */
export function datasetToEnumOptions(dataset, usage = 'Value') {
  //console.log('datasetToEnumOptions', dataset, usage);

  const fields = getSpecFields(dataset).sort(orderSort)
  if (!fields || fields.length === 0) return []

  //console.log('datasetToEnumOptions', dataset, usage, fields);
  return dataset.data.records.map((record, id) => {
    let object = {}
    let objectUsage = {}
    let option = { id: id, object: object, usage: objectUsage, description: '' }
    fields.map((field, index) => {
      let value = record.values[index]

      // Option elements
      if (field.usage === 'Name') {
        option['label'] = value
      } else if (field.usage === 'Description') {
        option['description'] = value
      }

      // For Value.
      if (field.usage === usage) {
        option['value'] = value
      }

      let type = field.type ? field.type.toLowerCase() : 'string'
      let isStructure = type === 'structure' || type === 'reference' || field.count == 0 || field.count > 1

      let data = isStructure ? (value ? JSON.parse(value) : '') : value

      object[field.identity.name] = data
      objectUsage[field.usage] = data
    })

    // Property usage means we use name value.
    option.usage['Property'] = option.usage['Name']

    // If no value, we use name for it.
    if (!option['value']) {
      option['value'] = option['label']
    }

    //console.log('datasetToEnumOptions:ELEMENT', id, option);
    return option
  })
}

/**
 * Convert major object list into list of options for drop box control.
 * @param {object} list - list of objects.
 * @param {object} folder - folder object of list.
 * @param {object} sort - sort function to sort list if required.
 * @returns {object} - list of options
 */
export function listOfMajorObjectsToEnumOptions(list, folder, sort) {
  //console.log('listOfMajorObjectsToEnumOptions', list);
  if (!list) return []

  let res = list
    .filter((object) => object)
    .map((record, id) => {
      let option = { id: id, object: record, description: '' }

      // For object we reference by URI
      option.label = displayDatasetUri(record)
      option.value = storageObjectUri(record, folder)

      // For subscription we can initialize subscription.
      if (record['subscription']) {
        option.subscription = storageSubscriptionUri(record, folder)
      }

      option.status = getVersionStatus(record)

      if (record.identity) {
        option.description = (record.identity.description || '') + '  Status: ' + option.status
      }
      //console.log('listOfMajorObjectsToEnumOptions:ELEMENT', id, option);
      return option
    })

  // Sort and remap options list if sorting function specified
  if (res && res.length > 1 && sort) {
    let sorted = res.sort(sort).map((op, index) => {
      op.id = index
      return op
    })
    return sorted
  }
  return res
}

/**
 * Convert major object versionlist into list of options for drop box control.
 * @param {object} list - list of objects.
 * @param {func} filter - filter function for the list.
 * @param {object} sort - sort function to sort list if required.
 * @returns {object} - list of options
 */
export function listOfMajorVersionsToEnumOptions(list, filter, sort) {
  //console.log('listOfMajorObjectsToEnumOptions', list, filter, sort);
  if (!list) return []

  let res = list
    .filter((object) => object && filter(object))
    .map((record, id) => {
      let option = { id: id, object: record, description: '' }

      // For object we reference by URI
      option.label = versionToStr(record.version)
      option.value = record.version

      option.status = getVersionStatus(record)

      if (record.identity) {
        option.description = (record.identity.description || '') + '  Status: ' + option.status
      }
      //console.log('listOfMajorVersionsToEnumOptions:ELEMENT', id, option);
      return option
    })

  // Sort and remap options list if sorting function specified
  if (res && res.length > 1 && sort) {
    let sorted = res
      .sort((a, b) => sort(a.value.version, b.value.version))
      .map((op, index) => {
        op.id = index
        return op
      })
    return sorted
  }
  return res
}

/**
 * Convert minor object list into list of options for drop box control.
 * @param {object} list - list of objects.
 * @returns {object} - list of options
 */
export function listOfMinorObjectsToEnumOptions(list) {
  //console.log('listOfMinorObjectsToEnumOptions', list);
  if (!list) return []
  if (!Array.isArray(list)) {
    list = [list]
  }

  return list
    .filter((object) => object)
    .map((record, id) => {
      let option = {
        id: id,
        object: record,
        description: record.identity ? record.identity.description : ''
      }

      option.label = record.identity.name
      option.value = record.identity.id ? record.identity.id : record.identity.name

      //console.log('listOfMinorObjectsToEnumOptions:ELEMENT', id, option);
      return option
    })
}

/**
 * Convert property list into list of options for drop box control.
 * @param {object} list - list of objects.
 * @returns {object} - list of options
 */
export function listOfPropertiesToEnumOptions(list) {
  //console.log('listOfPropertiesToEnumOptions', list);
  if (!list) return []

  return list
    .filter((object) => object)
    .map((record, id) => {
      let option = {
        id: id,
        object: record,
        description: record.identity ? record.identity.description : ''
      }

      option.label = record.identity.name
      option.value = record.reference

      //console.log('listOfPropertiesToEnumOptions:ELEMENT', id, option);
      return option
    })
}

/**
 * Updates dialog's state and deletes object from LocalStorage
 * @param object
 * @param version - version of the object
 */
export const clearLocalObject = (object, version) => {
  //console.log("clearLocalObject:OUTER", object, version);
  return (state, props) => {
    //console.log("clearLocalObject:INNER", state, props);
    const objectType = state.objectType
    if (!objectType) console.warn('clearLocalObject: no objectType set in state')
    const newObject = deepCopy(object)
    let id = 'EditorDialog_' + objectType
    const majorObject = props.majorObject || props.parentObject || props.publication
    if (majorObject) id += '_' + getFullUri(majorObject)
    localStorage.removeItem(id)
    //console.log("clearLocalObject:REMOVE", object, newObject, objectType, id);
    // We stay in edit mode or move to brousable
    let stateObject = { [objectType]: newObject }
    if (props.isEditable < editableState.EDITING) {
      stateObject['change'] = null
    }
    if (version) {
      stateObject['objectVersion'] = version
    }
    return stateObject
  }
}

/**
 * Retrieves object from LocalStorage
 * @param dialog
 * @returns {*}
 */
export const getLocalObject = (dialog) => {
  const majorObject = dialog.props.majorObject || dialog.props.parentObject || dialog.props.publication
  const objectType = dialog.state.objectType
  const currentObject = dialog.state[objectType]
  let id = 'EditorDialog_' + objectType
  if (majorObject) id += '_' + getFullUri(majorObject)
  const json = localStorage.getItem(id)
  //console.log("getLocalObject", dialog, id, json, currentObject, localStorage);
  if (json && json.length > 0) {
    if (JSON.stringify(currentObject) === json) {
      return ''
    } else {
      return JSON.parse(json)
    }
  } else {
    return ''
  }
}

/**
 * Restores saved object for LocalStorage into dialog's state
 * @param dialog
 */
export const restoreLocalObject = (dialog) => {
  const oldObject = getLocalObject(dialog)
  console.log('restoreLocalObject', dialog, oldObject)
  if (!dialog.Change(oldObject)) {
    console.log('restoreLocalObject1', dialog, oldObject)
    clearLocalObject(oldObject)
  }
}

// extracts params from path like /some/path?usage=Some as an object {usage: Some}
export function extractParamsFromPath(path) {
  if (path.indexOf('?') === -1) return false

  path = path.substr(path.indexOf('?') + 1)
  const fieldName = path.substr(0, path.indexOf('='))
  const fieldValue = path.substr(path.indexOf('=') + 1)
  return {
    [fieldName]: fieldValue
  }
}

function viewNameByObjectType(objectType) {
  let name = objectType
  // todo: remove when View names are fixed
  /*
  if (name === 'pipeline')
    name = 'pipline';
  if (name === 'environment')
    name = 'envirement';
    */

  return name + ' color'
}

let reportedViewErrors = {}

function reportViewError(objectType, action) {
  if (!reportedViewErrors[objectType]) console.warn('Could not ' + action + ' view for ', objectType)
  reportedViewErrors[objectType] = true
}

export function getObjectBackgroundColor(objectType) {
  try {
    objectType = objectType.toLowerCase()
    const app = md.repo.metadata.apdax.systems.difhub.applications.interface
    const view = app.views[viewNameByObjectType(objectType)]
    const element = view.definitions[0].elements.reduce(
      (found, cur) =>
        nonCSCompare(cur.identity.name, 'Color') || nonCSCompare(cur.identity.name, objectType + ' color')
          ? cur
          : found,
      null
    )
    const description = element.identity.description
    const color = description.match(/^(#[a-zA-Z0-9]{6})/)
    if (color) return color[1]
    reportViewError(objectType, 'parse')
    return false
  } catch (e) {
    reportViewError(objectType, 'find')
    return false
  }
}

export function getParentObjectType(objectType) {
  objectType = objectType.toLowerCase()
  const parentTypePlural = parentByType[getApiResource(objectType)]
  const parentType = singularType(parentTypePlural)
  if (!parentType) {
    reportViewError(objectType, 'parent type')
    return false
  }
  return parentType
}

export function getObjectTextColor(objectType) {
  if (objectType === 'application') return '#6C6C6C'
  return null
}

export function getParentLeftArrowImage(objectType, bold = false) {
  const arrowLeft = '/images/arrow_blue_left-2х.png'

  try {
    objectType = objectType.toLowerCase()
    const parentTypePlural = parentByType[getApiResource(objectType)]
    const parentType = singularType(parentTypePlural)
    if (!parentType) {
      reportViewError(objectType, 'parse left arrow')
      return arrowLeft
    }
    const app = md.repo.metadata.apdax.systems.difhub.applications.interface
    const view = app.views[viewNameByObjectType(parentType)]
    const element_name = 'Arrow left 2x bold'
    const element = view.definitions[0].elements.reduce(
      (found, cur) => (nonCSCompare(cur.identity.name, element_name) ? cur : found),
      null
    )
    const image = element.image
    if (image) return checkMetadataResourceUrl(image)
    else {
      reportViewError(objectType, 'parse left arrow')
      return arrowLeft
    }
  } catch (e) {
    reportViewError(objectType, 'find')
    return arrowLeft
  }
}

export function getObjectRightArrowImage(objectType, bold = false) {
  try {
    objectType = objectType.toLowerCase()
    const parentType = singularType(objectType)
    if (!parentType) {
      reportViewError(objectType, 'parse right arrow')
      return arrowLeft
    }
    const app = md.repo.metadata.apdax.systems.difhub.applications.interface
    const view = app.views[viewNameByObjectType(parentType)]
    const element_name = 'Arrow right 2x bold'
    const element = view.definitions[0].elements.reduce(
      (found, cur) => (nonCSCompare(cur.identity.name, element_name) ? cur : found),
      null
    )
    const image = element.image
    if (image) return checkMetadataResourceUrl(image)
    else {
      reportViewError(objectType, 'parse right arrow')
      return null
    }
  } catch (e) {
    reportViewError(objectType, 'find')
    return null
  }
}

export function storeChildObjectLink(obj, objectType) {
  localStorage.setItem('recent_' + objectType, obj.identity.id)
}

export function tableForwardClick(props, parent, childType) {
  const children = (props.appState[getApiResource(childType)] || []).filter(
    (item) => item && item.object && item.object.parent && item.object.parent.id === parent.identity.id
  )

  //console.log("tableForwardClick", parent, childType, children, localStorage.getItem("recent_" + singularType(childType)));

  const id = localStorage.getItem('recent_' + singularType(childType))
  for (let index in children) {
    const child = children[index]
    if (child && child.identity && child.identity.id === id)
      return () => {
        //console.log("tablrForwardClick handler", "/view" + getFullUri(child));
        props.history.push('/view' + getFullUri(child))
      }
  }

  //not found
  if (children.length > 0) {
    const child = children.sort(majorObjectSort)[0]
    return () => {
      //console.log("tablrForwardClick handler", "/view" + getFullUri(child));
      props.history.push('/view' + getFullUri(child))
    }
  }

  return false
}

export function getChildObjectLink(children, objectType) {
  if (!children || !objectType) return null
  const id = localStorage.getItem('recent_' + objectType)
  for (let index in children) {
    const child = children[index]
    if (child && child.identity && child.identity.id === id) return '/view' + getFullUri(child)
  }
  return null
}

export function getStatusIcon(statusName) {
  if (statusName == 'Opened') statusName = 'Open'

  //console.log(" getStatusIcon", md.repo.metadata.apdax.systems.difhub.applications.messaging.views);
  const statusIconView = md.repo.metadata.apdax.systems.difhub.applications.messaging.views['issue% status icon']
  if (!statusIconView) return ''
  const element = statusIconView.definitions[0].elements.reduce(
    (found, current) => (nonCSCompare(current.identity.name, statusName) ? current : found),
    null
  )
  if (!element) return ''
  //console.log("getStatusIcon", statusName, statusIconView, element);

  return checkMetadataResourceUrl(element.image)
}

export function PromiseAllOrFail(promises) {
  return new Promise((resolve, reject) => {
    /*
    Promise.all(promises.map(p => p.catch(e => e)))
    .then(results => resolve(results.filter(res => res)))
    .catch(e => reject(e));
    */
    Promise.all(
      promises.map((promise) => {
        return new Promise((oneResolve, oneReject) => {
          promise.then((val) => oneResolve(val)).catch((e) => oneResolve(null))
        })
      })
    ).then((results) => resolve(results))
  })
}

export function downloadFile(data, fileName) {
  const csvData = data
  const blob = new Blob([csvData], {
    type: 'application/csv;charset=utf-8;'
  })

  if (window.navigator.msSaveBlob) {
    // FOR IE BROWSER
    navigator.msSaveBlob(blob, fileName)
  } else {
    // FOR OTHER BROWSERS
    let link = document.createElement('a')
    let csvUrl = URL.createObjectURL(blob)
    link.href = csvUrl
    link.style = 'visibility:hidden'
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

function authFetch(url) {
  let headers = new Headers()
  headers.append('Authorization', 'Bearer ' + localStorage['currentUserToken'])
  return fetch(url, { headers: headers })
}

export function findByPathLocal(path) {
  if (!path) return null

  if (window.grabberServiceUri) {
    let res = null
    console.log('findByPathLocal', path)
    /*
    getObjectNew(path, objectTypeFromPath(path)).then(ret => {
      res = ret;
    });
    */
    authFetch(window.grabberServiceUri + path).then((ret) => {
      res = ret
    })
    while (!res) {
      //
    }
    console.log('findByPathLocal return', res)
    return res
  }

  // remove versions from path
  const clearedPath = path.replace(/\/versions\/\d\.\d\.\d/g, '').replace(/:/g, '%')

  let splitPath = clearedPath.split('/').map((el) => el.toLowerCase())
  let res

  while (splitPath.length) {
    var step = splitPath.shift()

    if (!res) res = metadata[step]
    else res = res[step]
  }
  console.log('findByPathLocal return from md', res)

  return res
}

export function getDatasetMetadataLocal(path) {
  try {
    if (!path) return null
    const dspath = path.replace(/:/g, '%')

    console.log('getDatasetMetadataLocal:find ', dspath)

    let ds = findByPathLocal(dspath)
    if (!ds || ds.object.type.toLowerCase() !== 'dataset') return null

    return ds
  } catch (e) {
    console.warn('Error in getDatasetMetadata: ' + path, e)
  }
  return null
}

export function checkMetadataResourceUrl(url) {
  const fileName = url.replace(/[^A-Za-z_0-9-]+/g, '_')
  //return '/resources/lib/resources/' + fileName;
  return '/resources/' + fileName + '.png'
}

export const singularTypeForms = new Map([
  ['applications', 'application'],
  ['datasets', 'dataset'],
  ['fields', 'field'],
  ['interfaces', 'interface'],
  ['issues', 'issue'],
  ['layouts', 'layout'],
  ['messages', 'message'],
  ['organizations', 'organization'],
  ['pipelines', 'pipeline'],
  ['publications', 'publication'],
  ['subscriptions', 'subscription'],
  ['systems', 'system'],
  ['topologies', 'topology'],
  ['users', 'user'],
  ['versions', 'version'],
  ['views', 'view'],
  ['environments', 'environment'],
  ['deployments', 'deployment']
])

export const deploymentChildTypes = ['datasets', 'interfaces', 'pipelines', 'views', 'publications', 'subscriptions']

export {
  createEmptyObject,
  deepCopy,
  getDefaultValue,
  nonCSCompare,
  objectJsons,
  objectNameFromPathByType,
  pluralTypeForms,
  getFullUri,
  pathByType,
  shortByType,
  deadlineCalculation,
  calcDeadline,
  getIssueDeadline,
  issuePriorityLabel,
  issuePropList,
  issueResolutionIcon,
  issueResolutionColor,
  getIssueAbsoluteDeadline,
  resolutions,
  getIssuePropByName,
  versionToStr,
  strToVersion,
  capitalize,
  hasInArrayItem,
  buildPathDetailed,
  parentByType,
  getDatasetPathFromReference,
  updateLocalObject
}
