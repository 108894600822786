import PropTypes from 'prop-types'
import { dequal } from 'dequal'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { versionToStr } from '../../helpers/index'
import '../../styles/ObjectSearch/ObjectSearch.scss'

import iClose from '../../resources/images/close-2x.png'

//const OPTION_HEIGHT = 26.4;
const OPTION_HEIGHT = 40
const MAX_RESULT_LINES = 20

function isInteger(value) {
  return value.match(/^[0-9]+$/)
}

class ObjectSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      hasFocus: false,
      found: [],
      direction: 'down',
      maxOptionsHeight: 1000
    }

    this.rootRef = React.createRef()
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      query: '',
      found: [],
      hasFocus: false
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !dequal(nextProps, this.props) || !dequal(nextState, this.state)
  }

  defaultFilter(item, type, query) {
    // console.log("ObjectSearch:defaultFilter", item, type, query);
    if (!query || query.length === 0) {
      return type !== 'issues' || item.status !== 'Closed'
    } else if (type === 'issues') {
      return (
        ((isInteger(query) && item.identity.name && item.identity.name.startsWith(query)) ||
          (item.identity.description && item.identity.description.toLowerCase().indexOf(query) !== -1)) &&
        item.status !== 'Closed'
      )
    } else if (type === 'versions') {
      return item.version && versionToStr(item.version).startsWith(query)
    } else {
      return item.identity.name && item.identity.name.toLowerCase().startsWith(query.toLowerCase())
    }
  }

  search() {
    //console.log("ObjectSearch:search");
    let items = []

    if (this.props.options) items = this.props.options.slice()
    else
      this.props.types.map((type) =>
        this.props.appState[type].map((item) => items.push(Object.assign({}, item, { type: type })))
      )
    let filterFunction = this.props.filter ? this.props.filter : this.defaultFilter
    this.setState({
      found: items.filter((item) => {
        if (filterFunction(item, item.type, this.state.query)) {
          return this.props.keepObjectsWithoutPath || item.path !== ''
        } else {
          return false
        }
      })
    })
  }

  handleChange(event) {
    // console.log("ObjectSearch:handleChange", event, this.state.query);
    this.setState({ query: event.target.value.toLowerCase() }, () => {
      this.search()
      if (this.props.onChange) this.props.onChange()
    })
  }

  handleKey(event) {
    // console.log("ObjectSearch:handleKey", event, this.state.found[0]);
    let obj = this.state.found && this.state.found.length > 0 ? this.state.found[0] : null

    if (event.charCode === 13 && obj) {
      this.setState({
        query: obj.identity.name,
        found: [],
        hasFocus: false
      })

      if (this.props.onSelect) {
        this.props.onSelect(obj)
      }
    }
  }

  handleClick(obj) {
    // console.log("ObjectSearch:handleClick", obj);
    this.setState({
      query: obj.identity.name,
      found: [],
      hasFocus: false
    })

    if (this.props.onSelect) {
      this.props.onSelect(obj)
    }
  }

  onFocus() {
    //console.log("ObjectSearch:onFocus", this.state.query);
    setTimeout(() => {
      this.setState(
        {
          query: '',
          hasFocus: true
        },
        () => {
          this.checkDirection()
          this.search()
        }
      )
    }, 100) // todo: remove hack
  }

  onBlur() {
    // console.log("ObjectSearch:onBlur");
    if (this.props.clearOnBlur) {
      setTimeout(() => {
        this.setState({ query: '', hasFocus: false }, this.search)
      }, 100) // todo: remove hack
    }

    if (this.props.onBlur) this.props.onBlur()
  }

  handleMouseOver(obj) {
    ////console.log("mouse over", obj);
    if (this.props.onMouseOver) this.props.onMouseOver(obj)
  }

  checkDirection = () => {
    const myNode = this.rootRef.current
    if (!myNode) {
      return
    }
    let node = myNode

    // find parent in chain that has data-isContainer=true
    do {
      node = node.parentNode
    } while (node && (!node.dataset || !node.dataset.iscontainer))

    //let bodyRect = document.body.getBoundingClientRect();
    let myRect = myNode.getBoundingClientRect()
    let myOptionListHeight = this.props.options ? this.props.options.length * OPTION_HEIGHT : OPTION_HEIGHT
    let h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

    if (!node) {
      if (h - myRect.bottom - myOptionListHeight < 0 && myRect.top > myOptionListHeight) {
        console.log('di up h', h, 'bottom', myRect.bottom, 'optlistheight', myOptionListHeight, 'top', myRect.top)
        this.setState({ direction: 'up', maxOptionsHeight: myRect.top })
        return
      } else {
        this.setState({
          direction: 'down',
          maxOptionsHeight: h - myRect.bottom
        })
        return
      }
    }

    //console.log("bottom", myRect.bottom, "list height", myOptionListHeight);
    let parentRect = node.getBoundingClientRect()

    //console.log("my", myRect, "parent", parentRect);
    if (
      -myRect.bottom + parentRect.bottom < myOptionListHeight &&
      -myRect.bottom + parentRect.bottom < myRect.top - parentRect.top
    ) {
      console.log(
        'direction: up, parent top',
        parentRect.top,
        'my top',
        myRect.top,
        'max height: ',
        -parentRect.top + myRect.top
      )
      this.setState({
        direction: 'up',
        maxOptionsHeight: -parentRect.top + myRect.top
      })
    } else {
      this.setState({
        direction: 'down',
        maxOptionsHeight: parentRect.bottom - myRect.bottom
      })
    }
  }

  resetState() {
    //console.log("resetState");
    this.setState({ query: '', hasFocus: false, found: [] })
  }

  renderItemName(item) {
    // console.log("ObjectSearch:renderItemName", item);
    if (item.type === 'issues') {
      return item.identity.name + ': ' + item.identity.description
    } else if (item.type === 'versions') {
      return versionToStr(item.version)
    } else if (item.type === 'locale') {
      return (
        <span>
          {item.identity.name}
          <div>
            <span className="ObjectSearch__localeId">{item.code}</span>
            {item.code !== item.identity.description ? (
              <span className="ObjectSearch__localeCode">{item.identity.description}</span>
            ) : null}
          </div>
        </span>
      )
    } else {
      return item.displayName || item.identity.name
    }
  }

  render() {
    const style = {}
    const mh =
      this.state.maxOptionsHeight > OPTION_HEIGHT * MAX_RESULT_LINES
        ? OPTION_HEIGHT * MAX_RESULT_LINES
        : this.state.maxOptionsHeight
    if (this.state.direction === 'up') style.top = -mh
    style.maxHeight = mh
    // style.maxHeight = 400;
    style.overflow = 'auto'

    // console.log("ObjectSearch:render", this.state, this.props);
    return (
      <div className={'ObjectSearch ' + this.props.className} ref={this.rootRef}>
        <div className="ObjectSearch__textInput">
          <input
            type="text"
            className="TextInput"
            value={this.state.query}
            placeholder={this.props.placeholder}
            onChange={this.handleChange.bind(this)}
            onFocus={this.onFocus.bind(this)}
            onKeyPress={this.handleKey.bind(this)}
            onBlur={this.onBlur.bind(this)}
            onClick={
              (e) => {
                e.stopPropagation()
                e.preventDefault()
                return false
              } /*
                  this line is bugfix for the scenario when mouse is pressed down, and after the options have shown mouse is released
                   (and for case when some operations were longer than 100ms timeout in onFocus)
                  which caused onClick event for the current Key in KeySettings and triggered keySettings rerender
                  so that search results were disappearing from ObjectSearch
                  */
            }
          />
        </div>
        {this.state.hasFocus && this.state.query.length >= 0 ? (
          <div className="ObjectSearch__results" style={style}>
            <div
              className="ObjectSearch__close"
              onClick={() => this.setState({ query: '', hasFocus: false }, this.search)}
            >
              <img src={iClose} alt="icon" />
            </div>
            {this.state.found.length > 0 ? (
              this.state.found.map((item, i) => (
                <div
                  className="ObjectSearch__result"
                  key={i}
                  onClick={this.handleClick.bind(this, item)}
                  onMouseOver={this.handleMouseOver.bind(this, item)}
                >
                  <div className="ObjectSearch__resultHeader">{this.renderItemName(item)}</div>
                  {this.props.renderObjectsPath ? <div className="ObjectSearch__resultPath">{item.path}</div> : null}
                </div>
              ))
            ) : (
              <div className="ObjectSearch__result">
                <div className="ObjectSearch__resultHeader">No results found</div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}

ObjectSearch.propTypes = {
  types: PropTypes.array,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  renderObjectsPath: PropTypes.bool, // to not render path
  keepObjectsWithoutPath: PropTypes.bool, // usually objects with no path are not displayed in search results
  // but organizations never have path, so this should be turned on for them
  options: PropTypes.array, // optional list of object through which to search
  filter: PropTypes.func,
  onChange: PropTypes.func,
  clearOnBlur: PropTypes.bool,
  onMouseOver: PropTypes.func,
  className: PropTypes.string
}

//export default ObjectSearch;

function mapStateToProps(state) {
  return {
    appState: state.appState
  }
}

export default connect(mapStateToProps, null)(ObjectSearch)
