import React, { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'

import { Tabs, TabPanel } from '../ReactSimpletabs'
import { FilterableContent } from '../FilterableContent/FilterableContent'
import { TypedTable } from '../NewTable/TypedTable'
import {
  childrenByType,
  getTabDescriptionFromJsons,
  getObjectBackgroundColor,
  getObjectRightArrowImage,
  getObjectTextColor,
  capitalize
} from '../../helpers/index'
import { singularType } from '../../helpers/api'
import { Loader } from '../Loader/Loader'
import { SimpleTooltip } from '../SimpleTooltip/SimpleTooltip'
import { pluralTypeForms } from '../../helpers/index'
import { track } from '../../helpers/analytics'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import { history } from '../../index'
import { getUrl } from './FilteredTabbedTableHelper'
import actions, { Actions } from '../../actions'
import { withRouterAndRef, withRouterForwardRef } from '../../hocs/withRouterAndRef'
import { nl2br } from '../../helpers/nl2br'

// type FilteredTabbedTableRouteProps = {}
//
// type FilteredTabbedTableState = {
//   showTabInfoModal: any
// }
//
// type FilteredTabbedTableProps = {
//   onTabChange?: Function
//   theme: string
//   tabsLoaded: boolean
//   onChangeFilter?: Function
//   parentUri: string
//   actions?: Actions
//   tablesData: any
// }

// type PropsType = RouteComponentProps<FilteredTabbedTableRouteProps> & FilteredTabbedTableProps

// class FilteredTabbedTable extends Component<PropsType, FilteredTabbedTableState> {

// TODO: fix types

class FilteredTabbedTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showTabInfoModal: false
    }
  }

  componentDidMount() {
    this.clearChildrenTabs()

    if (this.props.onTabChange && sessionStorage[this.props.theme + '_tab'] !== undefined) {
      this.props.onTabChange(parseInt(sessionStorage[this.props.theme + '_tab']))
    }
  }

  getTabTitles() {
    return this.props.tablesData.map((table) => table.title)
  }

  showTabInfoModal = (title, desc) => () => {
    this.setState({
      showTabInfoModal: {
        title: title,
        desc: desc
      }
    })
  }

  renderTabPanel(title, tableData, key, isActive) {
    //return <TabPanel key={key}>{this.renderTable(tableData)}</TabPanel>;

    let TableComponent = TypedTable
    if (tableData.tableComponent) {
      // Use content defined in the table.
      TableComponent = tableData.tableComponent
    }

    let tabPanelStyle = {}
    let color = getObjectBackgroundColor(singularType(title))
    if (color) tabPanelStyle.color = color

    let rightArrow = getObjectRightArrowImage(title)
    //          <div className="MajorObject__forwardArrowTabsBackground" style={{backgroundColor: getObjectBackgroundColor(singularType(this.props.theme))}}></div>

    let emptyText = tableData.emptyText || (
      <div className="MajorObject__emptyOuter">
        <div>
          No {title} found
          <br />
        </div>
      </div>
    )
    //logger.info("FilteredTabbedTable:renderTabPanel", title, { tableData, key, isActive, TableComponent }, this.state, this.props);

    return (
      <TabPanel
        title={
          <span style={tabPanelStyle}>
            {title}
            <SimpleTooltip title={getTabDescriptionFromJsons(title)}>
              <div
                className="MajorObjectView__tabInfo"
                onClick={this.showTabInfoModal(title, getTabDescriptionFromJsons(title))}
              ></div>
            </SimpleTooltip>
          </span>
        }
        key={key}
      >
        {rightArrow && tableData.forward ? (
          <div
            className="MajorObject__forwardArrowTabsOuter"
            onClick={() => {
              tableData.forward()
              track(title, 'forwardarrow')
            }}
          >
            <div className="MajorObject__forwardArrowTabsWhite"></div>
            <div className="MajorObject__forwardArrowTabs">
              <div className="MajorObject__forwardArrowTabsHover" style={{ borderColor: color }}></div>
              <img className="MajorObject__forwardArrowImageNormal" src={rightArrow} alt="" />
              <img className="MajorObject__forwardArrowImageHover" src={getObjectRightArrowImage(title, true)} alt="" />
            </div>
          </div>
        ) : null}
        {tableData.tabContent}
        {this.props.tabsLoaded && this.props.tabsLoaded[title] === false ? (
          <div className="TableLoader">
            <Loader />
          </div>
        ) : (
          <FilterableContent
            ref={'filterablecontent_' + title}
            dataFilters={tableData.filters}
            transEnum={tableData.transEnum ? tableData.transEnum : false}
            transView={tableData.transView ? tableData.transView : false}
            data={tableData.data || []}
            key={key}
            onChangeFilter={this.props.onChangeFilter}
            parentUri={this.props.parentUri}
            title={title}
          >
            <TableComponent
              ref={'table_' + title}
              transEnum={tableData.transEnum ? tableData.transEnum : false}
              transView={tableData.transView ? tableData.transView : false}
              columns={tableData.columns}
              className={title}
              theme={this.props.theme}
              isEditable={tableData.editable}
              getType={tableData.getType}
              onChangeRow={tableData.onChangeRow}
              onSave={tableData.onSave}
              maxHeight={tableData.maxHeight}
              inEditMode={isActive}
              errorText={tableData.errorText}
              contextMenuButtons={tableData.contextMenuButtons}
              tailButtons={tableData.tailButtons}
              bottomButtons={tableData.bottomButtons}
              objectPath={tableData.objectPath}
              parentObject={tableData.parentObject}
              topButtons={tableData.topButtons}
              setMinWidth={tableData.setMinWidth}
              noSetWidth={tableData.noSetWidth}
              headBgColor={getObjectBackgroundColor(singularType(this.props.theme))}
              headFontColor={getObjectTextColor(singularType(this.props.theme))}
              firstRowIndex={0}
              nextLvlTail={tableData.nextLvlTail}
            />
          </FilterableContent>
        )}
        {tableData.data.length === 0 && emptyText ? <div className="EmptyTableText">{emptyText}</div> : null}
      </TabPanel>
    )
  }

  renderTabPanels(titles, tablesData, tabActive) {
    let i = 0
    return tablesData.map((tableData, index) => {
      return this.renderTabPanel(titles[i++], tableData, index, index + 1 === tabActive)
    })
  }

  getTabActive = () => {
    return sessionStorage[this.props.theme + '_tab'] ? parseInt(sessionStorage[this.props.theme + '_tab']) : 0
  }

  clearChildrenTabs = () => {
    let children = childrenByType[pluralTypeForms.get(this.props.theme)]
    if (typeof children === 'string') children = [children]
    if (children) children.map((child) => (sessionStorage[child.substr(0, child.length - 1) + '_tab'] = ''))
  }

  tabChange = (index) => {
    const titles = this.getTabTitles()
    history.push(`${getUrl(this.props.match.params)}/${titles[index - 1]}`)
    this.props.actions?.setTabName({
      tabName: titles[index - 1],
      theme: this.props.theme
    })
    sessionStorage[this.props.theme + '_tab'] = index
    this.clearChildrenTabs()
    if (this.props.onTabChange) this.props.onTabChange(index)
    setTimeout(() => {
      let visibleTable = this.refs['table_' + titles[index - 1]]
      track(this.props.theme + '/' + titles[index - 1], 'tab_change')
      //console.log("tabChange",index,this.getTabTitles()[index-1],visibleTable);
      if (visibleTable) visibleTable.forceUpdate()
    }, 0)
  }

  renderTabInfoModal() {
    return (
      <ModalWindow
        isOpen={this.state.showTabInfoModal}
        header={capitalize(this.state.showTabInfoModal.title)}
        onClose={() => this.setState({ showTabInfoModal: false })}
        canClose
        footer={
          <button className="btn_big" onClick={() => this.setState({ showTabInfoModal: false })}>
            Close
          </button>
        }
      >
        <div className="MultilineText__full">{nl2br(this.state.showTabInfoModal.desc)}</div>
      </ModalWindow>
    )
  }

  render() {
    // //console.log("Tables data:", this.props.tablesData);
    let currActive = this.getTabActive()
    let tabTitles = this.getTabTitles()
    let tabActive = 1 + tabTitles.indexOf(window.location.hash.replace('#', '')) || currActive // indexes start from 1
    //logger.info("FilteredTabbedTable:render", { currActive, tabTitles, tabActive, location, sessionStorage }, this.state, this.props);

    return (
      <div>
        {this.state.showTabInfoModal ? this.renderTabInfoModal() : null}
        {this.props.tablesData ? (
          <Tabs
            className="tabs-list"
            ref="tabs"
            tabActive={tabActive > 0 ? tabActive : 1}
            onAfterChange={this.tabChange}
            noInfo={this.props.noInfo}
          >
            {this.renderTabPanels(this.getTabTitles(), this.props.tablesData, tabActive)}
          </Tabs>
        ) : null}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

// interface PassedProps extends React.Props<any> {
//   propToPass: any
// }

// export default withRouterForwardRef(connect(null, mapDispatchToProps)(FilteredTabbedTable))
//
// export default withRouterForwardRef(FilteredTabbedTable)
// export default withRouter(connect(null, null, null, { forwardRef: true })(FilteredTabbedTable))

const enhance = compose(withRouterForwardRef, connect(null, mapDispatchToProps, null, { forwardRef: true }))
export default enhance(FilteredTabbedTable)

// export default connect(
//   connect<{}, {}, FilteredTabbedTableProps>(null, mapDispatchToProps)(FilteredTabbedTable)
// )

// export default connect(null, mapDispatchToProps)(withRouterAndRef(FilteredTabbedTable))
