import { createMuiTheme } from '@material-ui/core/styles'

const userTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#6e49cb'
    }
  },
  overrides: {}
})

export default userTheme
