/**
 * Created by
 * Created by sashaberger on 01.10.2017.
 *
 * Window for creation of new draft for object.
 *
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { getVersionOptions } from '../../helpers'
import '../../styles/DraftWindow/DraftWindow.scss'
import '../ActionWindow/ActionWindow.scss'
import { Loader } from '../Loader/Loader'
import { ModalWindow } from '../ModalWindow/ModalWindow'

export class DraftWindow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      messageSent: false,
      isVersionSelecting: false,
      version: this.props.version.major + '.' + this.props.version.minor + '.' + this.props.version.revision,
      versions: getVersionOptions(this.props.version),
      selectedObjectPath: this.props.object ? this.props.object.path : ''
    }
  }

  onClose() {
    //console.log("DraftWindow:onClose");
    this.props.onClose()
  }

  onSent(error) {
    //console.log("DraftWindow:onSent", error, this.state.messageSent);

    this.setState({
      messageSent: false,
      errorText: error && error.response ? JSON.parse(error.response).error.message : ''
    })

    if (!error) {
      //console.log("DraftWindow: no error, close");
      this.props.onClose()
    }
  }

  onCreate() {
    //console.log("DraftWindow:onCreate", this.state.version, this.state.isVersionSelecting);
    let inputError = ''
    if (!this.state.isVersionSelecting) {
      inputError += 'Please select version to create!'
    }
    this.setState({ errorText: inputError })
    if (inputError.length > 0) {
      return
    }

    // Mark it and send the message
    this.setState({ messageSent: true })
    this.props.onCreate(this.state.version, this.onSent.bind(this))
  }

  onVersionChange(version) {
    //console.log("DraftWindow:onVersionChange", version, this.state.isVersionSelecting);

    this.setState({
      isVersionSelecting: true,
      errorText: '',
      version: version
    })
  }

  render() {
    let button = 'Create'
    let disabled = this.state.messageSent ? ' disabled' : ''

    //console.log("DraftWindow:render", this.props.object, this.props.version, this.state);
    return (
      <ModalWindow
        level={1}
        className="DraftWindow"
        isOpen
        onClose={this.onClose.bind(this)}
        header={button + ' new version for ' + this.props.object.type + ' "' + this.props.object.identity.name + '"'}
        footer={
          <div>
            {this.state.messageSent ? <Loader /> : <div className="ActionWindow__error">{this.state.errorText}</div>}
            <button className="btn_big ActionWindow__button" onClick={this.onClose.bind(this)}>
              Close
            </button>
            <button className={'btn_big' + disabled + ' ActionWindow__button'} onClick={this.onCreate.bind(this)}>
              {button}
            </button>
          </div>
        }
      >
        <div className="DraftWindow__text">
          <div className="DraftWindow__label">Select version to create:</div>
          <div className="row ">
            {this.state.versions.map((version, i) => (
              <div className="col-xs-4" key={i}>
                <div className="DraftWindow__name">{version.name + ':'}</div>
                <input
                  type="radio"
                  className="Input_radio"
                  id={'version_' + version.label}
                  checked={this.state.version === version.label}
                  onClick={() => this.onVersionChange(version.label)}
                />
                <label htmlFor={'version_' + version.label}>{version.label}</label>
                <div className="DraftWindow__description">{version.description}</div>
              </div>
            ))}
          </div>
        </div>
      </ModalWindow>
    )
  }
}

DraftWindow.propTypes = {
  object: PropTypes.object,
  version: PropTypes.object,
  onCreate: PropTypes.func,
  onClose: PropTypes.func
}
