/**
 * Created by kascode on 07.04.16.
 */
import React, { Component } from 'react'
import { ErrorWindow } from '../components/ErrorWindow/ErrorWindow'
import logger from '../helpers/logger'
import { MainLayout } from '../layouts/MainLayout/MainLayout'

import { getObjectByName } from '../helpers/data'
import { track, trackInit } from '../helpers/analytics'
import { RouteComponentProps } from 'react-router-dom'
import { Actions } from '../actions'
import { getObjectType } from './ApplicationPageHelper'

type ApplicationPageProps = {}

type ApplicationPageState = {
  hasError: boolean
}

type PropsType = RouteComponentProps<ApplicationPageProps> & {
  appState: any
  userState: any
  children: JSX.Element
  actions: Actions
}

export class ApplicationPage extends Component<PropsType, ApplicationPageState> {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false
    }
  }

  componentDidMount() {
    trackInit()

    window.addEventListener('error', function (e) {
      logger.error('Javascript error', e)
      track(e.error.message, 'js_error')
    })
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    //console.error("react error", error, JSON.stringify(info));
    logger.error('React error', error, info)
    track(error, 'react_error')
  }

  /**
   * If there's no objectName in state it sets it. Always returns object name
   * @returns {*}
   */
  getObjectName = (objectType) => {
    return this.props.match.params[objectType + 'Name']
  }

  render() {
    const appState = this.props.appState
    const children = this.props.children
    const objectType = getObjectType(this.props.location.pathname)
    const majorObject = getObjectByName(appState, objectType, this.getObjectName(objectType))
    //console.log('appliationpage rdner');

    if (this.state.hasError) {
      // You can render any custom fallback UI
      // return <h1>Something went wrong, please reload the page</h1>;
      if (window.location.hostname !== 'metucat.com' && !window.localInstallation) {
        // not prod
        return <h1>React error. Please reload the page</h1>
      }
    }

    return (
      <div className="AppContainer">
        <MainLayout userState={this.props.userState} appState={this.props.appState} actions={this.props.actions}>
          <ErrorWindow actions={this.props.actions} history={this.props.history} />
          <div className="AppChildren">
            {React.cloneElement(children, {
              appState: appState,
              userState: this.props.userState,
              actions: this.props.actions,
              history: this.props.history,
              majorObject: majorObject
            })}
          </div>
        </MainLayout>
      </div>
    )
  }
}
