import React, { Component } from 'react'

import logo from '../../resources/images/logo-apdax-2x.png'
import productLogo from '../../resources/images/logo-difhub-white-2x.png'
import { AddIssueInstruction } from './AddIssueInstruction'
import './Footer.scss'

type FooterProps = {
  backendVersion: string
}

type FooterState = {
  isOpen: boolean
  commentsNode: number
}

export class Footer extends Component<FooterProps, FooterState> {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      commentsNode: 8
    }
  }

  getComments = (elem: Node, commNode) => {
    const children = elem.childNodes
    let comments: ChildNode[] = []

    children.forEach((item) => {
      if (item.nodeType === commNode) {
        comments.push(item)
      }
    })

    return comments
  }

  addIssueInstructionsOnClick() {
    this.setState({ isOpen: true })
  }

  closeIssueInstructionsOnClick() {
    this.setState({ isOpen: false })
  }

  render() {
    let comments = this.getComments(document.head, this.state.commentsNode)
    let versionInfo = comments[0] && comments[0].nodeValue
    const backendVersion = this.props.backendVersion

    return (
      <div className="Footer">
        <div className="row">
          <div className="col-xs-1">
            <a href="http://apdaxco.com">
              <img src={logo} width="90" alt="Apdaxco logo" />
            </a>
          </div>
          <div className="col-xs-2">
            &copy; 2016-{new Date().getFullYear()} <br />
            All rights reserved
          </div>
          <div className="col-xs-3">
            <div className="Footer__caption">Contacts</div>
            Phone: <a href="tel://+14255051506">+1&nbsp;(425)&nbsp;505-1506</a>
            <br />
            E-mail:{' '}
            <a href="mailto:info@apdaxco.com" title="Apdax e-mail">
              info@apdaxco.com
            </a>
            <br />
            Location:&nbsp;
            <a href="https://goo.gl/maps/cCAydFZbZgkjdQ2D8" target="_blank">
              17703 NE 26<sup>th</sup> St,
              <br />
              Redmond,
              <br />
              WA 98052, United States
            </a>
          </div>
          <div className="col-xs-2">
            <div className="Footer__caption">Support</div>
            <div>FAQ</div>
            <a onClick={this.addIssueInstructionsOnClick.bind(this)}>Add Issue</a>
            <br />
            <a href="http://apdaxco.com/contacts.html" className="footer_el" target="_blank">
              Support Request
            </a>
          </div>
          <div className="col-xs-4">
            <div className="Footer__caption">Product</div>
            <img src={productLogo} width="90" alt="icon" />
            <br />
            <br />
            <br />
            <a href="http://apdaxco.com/getting_started.html" className="footer__el" target="_blank">
              Getting Started
            </a>
            <br />
            <a href="https://github.com/DifHub/dh-metadata-grubber" className="footer__el" target="_blank">
              Metadata compiler
            </a>
          </div>
          {isDevEnv() ? (
            <div className="col-xs-12">
              {/* <abbr title={BUILD_DATA.gitHash + ' - ' + BUILD_DATA.gitMessage}>
                {BUILD_DATA.buildTime}
              </abbr> */}
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-xs-12">
            {window.requestCount.network} requests, {window.requestCount.cached} from cache. <br />
            {versionInfo && (
              <>
                {versionInfo} <br />
              </>
            )}
            {backendVersion ? 'Back-end: ' + backendVersion : ''}
            <br />
            <a href="http://apdaxco.com/privacy.html" className="footer_el">
              Privacy
            </a>
          </div>
        </div>
        <div>
          {this.state.isOpen ? (
            <AddIssueInstruction
              isVisible={this.state.isOpen}
              text="In order to create a ticket for support, please go to your organization page in MetUCat and click the “Add Issue”
            link in the right-bottom of the page. Enter the subject and detailed error description. You may also attach a screenshot.
            In the Assigned To dropdown, select the MetUCat Team and click “Create”."
              title="Submitting an Issue to MetUCat Support"
              onClose={this.closeIssueInstructionsOnClick.bind(this)}
            />
          ) : null}
        </div>
      </div>
    )
  }
}

const isDevEnv = () => {
  return window.location.href.indexOf('integration') > 0 || window.location.href.indexOf('localhost') > 0
}
