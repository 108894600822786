import PropTypes from 'prop-types'
import React, { Component } from 'react'
import logger from '../../helpers/logger'
import EditableEntity from '../EditableEntity/EditableEntity'
import DataEditor from '../DataDialog/DataEditor'
import {
  getDatasetMetadata,
  getDatasetData,
  getFieldMetadata,
  deepGet,
  deepSet,
  getViewMetadata,
  getElementMetadata,
  getElementLabel,
  getElementProperty
} from '../../resources/lib/metadata'
import {
  deepCopy,
  nonCSCompare,
  editableState,
  displayDatasetUri,
  storageDatasetUri,
  displayReferenceUri,
  storageSubscriptionUri
} from '../../helpers/index'

import './SettingDialog.scss'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'

export class SettingDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    //console.log("SettingsDialog", props);
  }

  getObjectType() {
    return 'setting'
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/System/datasets/Property',
      '/organizations/Apdax/systems/DifHub/applications/System/views/SettingDialog',
      null,
      this.props.usage ? this.getUpdatedView : null
    )

    logger.info('SettingDialog:open', this.state, this.props)
  }

  /**
   * Returns initial object for dialog
   */
  getInitial(props) {
    //logger.info("EditorDialogBase:getInitial", props, this.state, this.props);
    props = props || this.props
    let obj = deepCopy(props[this.getObjectType()]) || {}
    if (this.props.usage && !obj.type) {
      obj.type = 'Structure'
      obj.reference = ''
    }
    return obj
  }

  /**
   * Modify view based on usage
   */
  getUpdatedView = (view) => {
    //logger.info("EditorDialogBase:getUpdatedView", view, this.state, this.props);
    let dataView = deepCopy(view)
    let dataElement = getElementMetadata(dataView, 'dataset')
    dataElement.control.value.dataUsage = this.props.usage
    dataElement.control.value.selectTip = 'Select ' + this.props.usage.toLowerCase() + ' structure'

    // Modify tips for name and description
    let dataName = getElementMetadata(dataView, 'name')
    dataName.control.value.editTip = 'Enter ' + this.props.usage.toLowerCase() + ' name'

    let dataDesc = getElementMetadata(dataView, 'description')
    dataDesc.control.value.editTip = 'Enter ' + this.props.usage.toLowerCase() + ' description'
    return dataView
  }

  /*
   * Check property before save
   * @param {object} settings - new state of object
   * @returns {string} - string with error text
   */
  checkInputErrors(setting) {
    let inputError = ''

    if (!setting.identity || !setting.identity.name) {
      inputError += 'Please specify property name! '
    }
    if (!setting.type) {
      inputError += 'Please specify property type! '
    }
    if ((nonCSCompare(setting.type, 'Structure') || nonCSCompare(setting.type, 'Enum')) && !setting.reference) {
      inputError += 'Please choose reference! '
    }

    return inputError
  }

  /**
   * Validate value of changed field
   * @param {string} [field] path of field in the dataset
   * @param {object} [value] value to set in the field
   * @param {object} [data] new state of the data object
   * @return true - change approved
   */
  onValidateChange = (element, field, value, setting, option) => {
    logger.info('SettingDialog:onValidateChange', { element, field, value, setting }, this.state, this.props)
    if (field === 'type' && value) {
      // For operation we prepare structure to keep info about interface.
      if (nonCSCompare(value, 'structure')) {
        setting['reference'] = ''
      } else if (nonCSCompare(value, 'enum')) {
        setting['reference'] = ''
      } else {
        delete setting.reference
      }
      delete setting.value
    } else if (!field.trim() && nonCSCompare(setting.type, 'Structure')) {
      // This is special case of dataset we have
      let obj = setting.value
      setting.value = typeof obj === 'object' ? JSON.stringify(obj) : obj
    } else if (element === 'enum' && field === 'value' && value) {
      setting['reference'] = value.reference
      setting['value'] = value.value
    }
    return setting
  }

  renderEditor = (editState) => {
    // State and props.
    const setting = this.state.setting
    const base = this.props.base
    const type = setting.type

    let refType = type && (nonCSCompare(type, 'structure') || nonCSCompare(type, 'enum'))

    let field = {
      identity: { name: '' }, // Name is empty indicate we will get name from element.
      type: type,
      reference: setting.reference,
      subscription: setting.subscription
    }

    //logger.info("SettingDialog:renderEditor", editState, setting, field, this.state, this.props);

    return (
      <div className="SettingDialog" onClick={() => this.onCheckDialogHeight('.SettingDialog')}>
        {this.renderView(editState, (element) => {
          switch (element) {
            default:
              return null
            case 'name':
              return base ? this.renderElement(editableState.BROWSABLE, element) : null
            case 'type':
              return this.props.usage ? <div></div> : null
            case 'value':
              return refType ? <div></div> : this.renderElement(editState, element, field)
            case 'enum':
              return !refType || !nonCSCompare(type, 'enum') ? (
                <div></div>
              ) : (
                this.renderElement(editState, element, field, setting)
              )
            case 'dataset':
              return !refType || !nonCSCompare(type, 'structure') ? (
                <div></div>
              ) : (
                this.renderElement(editState, element, field, setting)
              )
          }
        })}
      </div>
    )
  }
}

SettingDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  type: PropTypes.string, // Type of object
  usage: PropTypes.string, // Usage for propery predefined
  setting: PropTypes.object,
  base: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
