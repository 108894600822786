import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './theme'

import App from './components/App'
import { Application } from './components/Application/Application'
import { ApplicationView } from './components/ApplicationView/ApplicationView'
import { AuthPage } from './components/AuthPage/AuthPage'
import { AuthPageLocal } from './components/AuthPageLocal/AuthPageLocal'
import { CognitoAuthPage } from './components/CognitoAuthPage/CognitoAuthPage'
import { Dataset } from './components/Dataset/Dataset'
import { Deployment } from './components/Deployment/Deployment'
import { DocumentationView } from './components/DocumentationView/DocumentationView'
import { Environment } from './components/Environment/Environment'
import { AuthErrorPage } from './components/ErrorPage/ErrorPage'
import { Interface } from './components/Interface/Interface'
import { Loader } from './components/Loader/Loader'
import { LoginPageNew } from './components/LoginPageNew/LoginPageNew'
import { NotFoundPage } from './components/NotFoundPage'
import { Organization } from './components/Organization/Organization'
import { OrganizationList } from './components/OrganizationList/OrganizationList'
import { Pipeline } from './components/Pipeline/Pipeline'
import PoliciesStructureView from './components/PoliciesStructureView/PoliciesStructureView'
import { PrivacyPolicyPage } from './components/PrivacyPolicyPage/PrivacyPolicyPage'
import { Publication } from './components/Publication/Publication'
import { PublicationView } from './components/PublicationView/PublicationView'
import SearchView from './components/SearchView/SearchView'
import { SelectView } from './components/SelectView/SelectView'
import { Subscription } from './components/Subscription/Subscription'
import { System } from './components/System/System'
import { SystemsViewPage } from './components/SystemsView/SystemsViewPage'
import { Topology } from './components/Topology/Topology'
import { View } from './components/View/View'
import { ApplicationPage } from './containers/ApplicationPage'
import { Organizations } from 'features/Organizations/Organizations'
import { KanbanBoardPage } from './features/KanbanBoard/KanbanBoardPage'

window.AUTH_URL = process.env.REACT_APP_AUTH

export const Routes = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />

      <Switch>
        <Redirect exact from="/" to="select" />
        <Route
          name="Organizations"
          path="/new/organizations"
          exact
          render={(props) => (
            <App>
              <Organizations {...props} />
            </App>
          )}
        />

        <Route
          name="Organizations"
          path="/kanban"
          exact
          render={(props) => (
            <App>
              <KanbanBoardPage {...props} />
            </App>
          )}
        />
        <Route
          name="Organizations"
          path="/kanban/:organizationName"
          exact
          render={(props) => (
            <App>
              <KanbanBoardPage {...props} />
            </App>
          )}
        />

        <Route
          name="selectView"
          path="/select"
          exact
          render={(props) => (
            <App>
              <SelectView {...props} />
            </App>
          )}
        />
        <Route name="appView" path="/view">
          <Switch>
            <Route
              name="organizationDetails"
              path="/view/organizations/:organizationName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Organization {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="organizationDetails"
              path="/view/organizations/:organizationName/:tabName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Organization {...props} />
                  </ApplicationPage>
                </App>
              )}
            />

            <Route
              name="systemDetails"
              path="/view/organizations/:organizationName/systems/:systemName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <System {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="systemDetails"
              path="/view/organizations/:organizationName/systems/:systemName/:tabName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <System {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="datasetDetails"
              path="/view/organizations/:organizationName/datasets/:datasetName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Dataset {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="datasetVersionDetails"
              path="/view/organizations/:organizationName/datasets/:datasetName/versions/:versionName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Dataset {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="appDetails"
              path="/view/organizations/:organizationName/systems/:systemName/applications/:applicationName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Application {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="appDetails"
              path="/view/organizations/:organizationName/systems/:systemName/applications/:applicationName/:tabName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Application {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="topDetails"
              path="/view/organizations/:organizationName/systems/:systemName/topologies/:topologyName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Topology {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="topVersionDetails"
              path="/view/organizations/:organizationName/systems/:systemName/topologies/:topologyName/versions/:versionName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Topology {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="datasetDetails"
              path="/view/organizations/:organizationName/systems/:systemName/applications/:applicationName/datasets/:datasetName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Dataset {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="datasetVersionDetails"
              path="/view/organizations/:organizationName/systems/:systemName/applications/:applicationName/datasets/:datasetName/versions/:versionName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Dataset {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="pipelineDetails"
              path="/view/organizations/:organizationName/systems/:systemName/applications/:applicationName/pipelines/:pipelineName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Pipeline {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="publicationDetails"
              path="/view/organizations/:organizationName/systems/:systemName/applications/:applicationName/publications/:publicationName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Publication {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="subscriptionDetails"
              path="/view/organizations/:organizationName/systems/:systemName/applications/:applicationName/subscriptions/:subscriptionName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Subscription {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="policiesstructure"
              path="/policiesstructure"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <PoliciesStructureView {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="interfaceDetails"
              path="/view/organizations/:organizationName/systems/:systemName/applications/:applicationName/interfaces/:interfaceName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Interface {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="viewDetails"
              path="/view/organizations/:organizationName/systems/:systemName/applications/:applicationName/views/:viewName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <View {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="envDetails"
              path="/view/organizations/:organizationName/systems/:systemName/environments/:environmentName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Environment {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="envVersionDetails"
              path="/view/organizations/:organizationName/systems/:systemName/environments/:environmentName/versions/:versionName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Environment {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="deplDetails"
              path="/view/organizations/:organizationName/systems/:systemName/deployments/:deploymentName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Deployment {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="deplVersionDetails"
              path="/view/organizations/:organizationName/systems/:systemName/deployments/:deploymentName/versions/:versionName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Deployment {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="sysPublicationDetails"
              path="/view/organizations/:organizationName/systems/:systemName/publications/:publicationName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Publication {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
            <Route
              name="sysSubscriptionDetails"
              path="/view/organizations/:organizationName/systems/:systemName/subscriptions/:subscriptionName"
              exact
              render={(props) => (
                <App>
                  <ApplicationPage {...props}>
                    <Subscription {...props} />
                  </ApplicationPage>
                </App>
              )}
            />
          </Switch>
        </Route>
        <Route
          name="login"
          path="/login"
          render={(props) => (
            <App>
              <LoginPageNew {...props} />
            </App>
          )}
        />
        <Route
          name="auth"
          path="/auth"
          render={(props) => (
            <App>
              <AuthPage {...props} />
            </App>
          )}
        />
        <Route
          name="authlocal"
          path="/authlocal"
          render={(props) => (
            <App>
              <AuthPageLocal {...props} />
            </App>
          )}
        />
        <Route
          name="authError"
          path="/autherror"
          render={(props) => (
            <App>
              <AuthErrorPage {...props} />
            </App>
          )}
        />
        <Route
          name="systems"
          path="/systems"
          render={(props) => (
            <App>
              <SystemsViewPage {...props} />
            </App>
          )}
        />
        <Route
          name="applications"
          path="/applications"
          render={(props) => (
            <App>
              <ApplicationView {...props} />
            </App>
          )}
        />
        <Route
          name="publications"
          path="/publications"
          render={(props) => (
            <App>
              <PublicationView {...props} />
            </App>
          )}
        />
        <Route
          name="searchview"
          path="/searchview"
          render={(props) => (
            <App>
              <SearchView {...props} />
            </App>
          )}
        />
        <Route
          name="organizationlist"
          path="/organizations"
          render={(props) => (
            <App>
              <OrganizationList {...props} />
            </App>
          )}
        />
        <Route
          name="resetpassword"
          path="/resetpassword/:hash"
          render={(props) => (
            <App>
              <Loader {...props} />
            </App>
          )}
        />
        <Route
          name="documentation"
          path="/documentation"
          render={(props) => (
            <App>
              <DocumentationView {...props} />
            </App>
          )}
        />
        <Route
          name="documentationSystem"
          path="/documentation/:systemName"
          render={(props) => (
            <App>
              <DocumentationView {...props} />
            </App>
          )}
        />
        <Route
          name="documentationApplication"
          path="/documentation/:systemName/:applicationName"
          render={(props) => (
            <App>
              <DocumentationView {...props} />
            </App>
          )}
        />

        <Route
          name="privacypolicy"
          path="/privacy"
          render={(props) => (
            <App>
              <PrivacyPolicyPage {...props} />
            </App>
          )}
        />
        <Route
          name="privacypolicy"
          path="/privacy.*"
          render={(props) => (
            <App>
              <PrivacyPolicyPage {...props} />
            </App>
          )}
        />
        <Route
          name="cognitologin"
          path="/cognitologin"
          render={(props) => (
            <App>
              <CognitoAuthPage {...props} />
            </App>
          )}
        />

        <Route
          path="*"
          render={(props) => (
            <App>
              <NotFoundPage {...props} />
            </App>
          )}
        />
      </Switch>
    </MuiThemeProvider>
  )
}
