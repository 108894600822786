import React, { forwardRef } from 'react'
import { withRouter } from 'react-router-dom'

// export const withRouterAndRef = (Wrapped) => {
export const withRouterAndRef = (Wrapped) => {
  const WithRouter = withRouter(({ forwardRef, ...otherProps }) => <Wrapped ref={forwardRef} {...otherProps} />)
  const WithRouterAndRef = React.forwardRef((props, ref) => <WithRouter {...props} forwardRef={ref} />)
  const name = Wrapped.displayName || Wrapped.name
  WithRouterAndRef.displayName = `withRouterAndRef(${name})`
  return WithRouterAndRef
}

export const withRouterForwardRef = (Component) => {
  const WithRouter = withRouter(({ forwardedRef, ...props }) => <Component ref={forwardedRef} {...props} />)

  return forwardRef((props, ref) => <WithRouter {...props} forwardedRef={ref} />)
}
