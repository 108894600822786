import PropTypes from 'prop-types'
import React from 'react'
import logger from '../../helpers/logger'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './TagDialog.scss'

export class TagDialog extends EditorDialogBase {
  getObjectType() {
    return 'tag'
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    // Load dialog metadata and options for import file types.
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/Organization/datasets/Identity',
      '/organizations/Apdax/systems/DifHub/applications/System/views/TagDialog'
    )

    logger.info('TagDialog:open', this.state, this.props)
  }

  /*
   * Check property before save
   * @param {object} settings - new state of object
   * @returns {string} - string with error text
   */
  checkInputErrors(tag) {
    let inputError = ''

    if (!tag || !tag.name) {
      inputError += 'Please specify tag name! '
    }

    return inputError
  }

  renderEditor = (editState) => {
    // State and props.
    //logger.info("TagDialog:renderEditor", editState, this.state, this.props);

    return <div className="TagDialog">{this.renderView(editState)}</div>
  }
}

TagDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  tag: PropTypes.object,
  base: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
