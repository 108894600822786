import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'

import IconButton from '@material-ui/core/IconButton'
import DoneIcon from '@material-ui/icons/Done'

import { fieldState, getFullUri, pathByType, versionToStr } from '../../helpers/index'
import logger from '../../helpers/logger'
import { getPayload } from '../MajorObjectVersioned/majorObjectVersionedHelper'
import { defaultMessage, getObjectMessageApiEndpoint, isFinalizable } from './simpleTableHelper'
import { httpClient } from '../../helpers/httpClient'
import './NewTable.scss'

const DELIMITER_ROW_COUNT = 10

/**
 * SimpleTable renders a table, every cell is string or react component
 * Context menu is supported
 * SimpleTable is externally controlled
 */
class SimpleTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      controlsClosed: [], // click row to hide tail buttons
      selected: []
    }
  }

  /**
   * render context menu bodies (one for table rows and one for bottom buttons row)
   * @returns {XML[]}
   */
  renderContextMenu() {
    const { contextMenuButtons, tableId } = this.props
    if (contextMenuButtons) {
      return [
        <ContextMenu id={'contextmenu_' + tableId} key="menu">
          {contextMenuButtons.map((item, index) => (
            <MenuItem key={index} data={item.data} onClick={item.onClick}>
              {item.label}
            </MenuItem>
          ))}
        </ContextMenu>,
        <ContextMenu id={'contextmenubottom_' + tableId} key="menubottom">
          {contextMenuButtons
            .filter((item) => item.showInBottom)
            .map((item, index) => (
              <MenuItem key={index} data={item.data} onClick={item.onClick}>
                {item.label}
              </MenuItem>
            ))}
        </ContextMenu>
      ]
    }
  }

  /**
   * render thead with filters and column headers
   * @returns {XML}
   */
  renderHead() {
    const {
      filters,
      upperTail,
      colHeaders,
      colWidths,
      contextMenuButtons,
      tableId,
      contextMenuCollect,
      setMinWidth,
      noSetWidth,
      headBgColor,
      headFontColor
    } = this.props

    if (!colHeaders || colHeaders.length === 0) return null

    //logger.info("SimpleTable:renderHead", colHeaders, this.state, this.props);

    let thStyle = {}
    if (headBgColor) thStyle = { backgroundColor: headBgColor }
    if (headFontColor) thStyle.color = headFontColor

    let headRow = (
      <tr>
        {upperTail}
        {colHeaders.map((header, index) => (
          <th
            key={index}
            className={
              'SimpleTable__headerCell ' +
              'SimpleTable__headerCell_' +
              header +
              ' SimpleTable__headerCell_index_' +
              index
            }
            style={Object.assign({}, thStyle, {
              width: colWidths[index],
              maxWidth: colWidths[index]
            })}
          >
            {header}
          </th>
        ))}
      </tr>
    )

    if (contextMenuButtons) {
      headRow = (
        <ContextMenuTrigger
          id={'contextmenubottom_' + tableId}
          index={-1}
          collect={() => contextMenuCollect(-1)}
          renderTag="tr"
          attributes={{ className: '' }}
        >
          {upperTail}
          {colHeaders.map((header, index) => {
            let style = { width: colWidths[index], maxWidth: colWidths[index] }
            if (setMinWidth) style.minWidth = colWidths[index]
            if (noSetWidth) style = {}
            if (headBgColor) style.backgroundColor = headBgColor
            if (headFontColor) style.color = headFontColor
            return (
              <th
                key={index}
                className={
                  'SimpleTable__headerCell ' +
                  'SimpleTable__headerCell_' +
                  header +
                  ' SimpleTable__headerCell_index_' +
                  index
                }
                style={style}
              >
                {header}
              </th>
            )
          })}
        </ContextMenuTrigger>
      )
    }

    return (
      <thead>
        {filters || null}
        {headRow}
      </thead>
    )
  }

  /**
   * render table row and its tail
   * @param tail
   * @param row
   * @returns {*[]}
   */
  renderRow(tail, row, rowIndex, rowPath, rowData) {
    const { colWidths, colHeaders } = this.props
    // logger.info(
    //   'SimpleTable:renderRow',
    //   rowIndex,
    //   { tail, row, colWidths, colHeaders, rowPath, rowData },
    //   this.state,
    //   this.props
    // )
    return [
      this.state.controlsClosed.indexOf(rowIndex) !== -1
        ? tail && tail.length > 0 && this.props.zeroTails[rowIndex][0]
          ? React.cloneElement(this.props.zeroTails[rowIndex][0], {
              onClick: (e) => {
                //console.log("zero tail click", rowIndex, this.state.controlsClosed);
                e.stopPropagation()
                //this.rowLeave(rowIndex);
                this.setState({ controlsClosed: [] })
              }
            })
          : null
        : (tail || []).filter((t) => t).map((t) => t) || null,
      row.map((cell, cellIndex) => (
        <td
          key={cellIndex}
          onClick={this.cellClick(rowIndex, rowPath, rowData, cellIndex)}
          className={'SimpleTable__cell SimpleTable__cell_' + colHeaders[cellIndex] + ' SimpleTable__cell_' + cellIndex}
          style={{
            width: colWidths[cellIndex],
            maxWidth: colWidths[cellIndex],
            backgroundColor: this.state.selected.includes(rowIndex) && '#ffc107'
          }}
        >
          {cell}
        </td>
      ))
    ]
  }

  rowClick = (index, path, data) => (event) => {
    logger.info('SimpleTable:rowClick', { index, path, data, event }, this.state, this.props)
    if (event.metaKey && isFinalizable(this.props.match.params)) {
      if (this.state.selected.includes(index)) {
        const newSelected = [...this.state.selected]
        newSelected.splice(this.state.selected.indexOf(index), 1)
        this.setState({
          controlsClosed: this.state.controlsClosed.concat(index),
          selected: newSelected
        })
      } else {
        this.setState({
          controlsClosed: this.state.controlsClosed.concat(index),
          selected: [...this.state.selected, index]
        })
      }
    } else {
      this.setState({ controlsClosed: this.state.controlsClosed.concat(index) })
    }
  }

  cellClick = (index, path, data, cell) => (event) => {
    //logger.info("SimpleTable:cellClick", { index, path, data, cell, event }, this.state, this.props);

    if (this.props.onFieldClick) {
      this.props.onFieldClick(path, data, cell)
    }
  }

  rowLeave = (index) => () => {
    const controlsClosed = this.state.controlsClosed.filter((i) => i !== index)
    if (controlsClosed.length !== this.state.controlsClosed.length) {
      this.setState({
        controlsClosed
      })
    }
  }

  /**
   * render table body with rows and context menu triggers
   * @returns {XML}
   */
  renderBody() {
    const { cellValues, tails, contextMenuButtons, contextMenuCollect, tableId, firstRowIndex, rowClassNames } =
      this.props
    //logger.info("SimpleTable:renderBody", cellValues, this.state, this.props);

    return (
      <tbody>
        {cellValues.map((row, index) => {
          let className = 'SimpleTable__row'
          if (((firstRowIndex || 0) + index) % DELIMITER_ROW_COUNT === DELIMITER_ROW_COUNT - 1)
            className += ' SimpleTable__row_delimiter'
          if (rowClassNames && rowClassNames.length && rowClassNames[index]) className += ' ' + rowClassNames[index]

          if (contextMenuButtons) {
            return (
              <ContextMenuTrigger
                holdToDisplay={-1}
                id={'contextmenu_' + tableId}
                index={index}
                collect={() => contextMenuCollect(index)}
                renderTag="tr"
                attributes={{
                  className: className,
                  onClick: this.rowClick(index),
                  onMouseLeave: this.rowLeave(index)
                }}
                key={index}
              >
                {this.renderRow(tails ? tails[index] : [], row, index)}
              </ContextMenuTrigger>
            )
          } else {
            return (
              <tr key={index} onClick={this.rowClick(index)} onMouseLeave={this.rowLeave(index)} className={className}>
                {this.renderRow(tails ? tails[index] : [], row, index)}
              </tr>
            )
          }
        })}
      </tbody>
    )
  }

  renderold() {
    //logger.info("SimpleTable:render", this.state, this.props);

    return (
      <table className={'SimpleTable ' + (this.props.className || '')}>
        {this.renderContextMenu()}
        {this.renderHead()}
        {this.renderBody()}
      </table>
    )
  }

  /**
   * render table body with rows and context menu triggers
   * @returns {XML}
   */
  renderNew() {
    const {
      colHeaders,
      cellValues,
      tails,
      contextMenuButtons,
      contextMenuCollect,
      tableId,
      firstRowIndex,
      rowClassNames
    } = this.props
    logger.info('SimpleTable:renderBody', cellValues, this.state, this.props)

    return cellValues.map((row, index) => {
      let className = 'SimpleTable__row'
      if (((firstRowIndex || 0) + index) % DELIMITER_ROW_COUNT === DELIMITER_ROW_COUNT - 1) {
        className += ' SimpleTable__row_delimiter'
      }
      if (rowClassNames && rowClassNames.length && rowClassNames[index]) {
        className += ' ' + rowClassNames[index]
      }

      let bottom = row && row[0] && row[0].props && row[0].props.rowData ? row[0].props.rowData.expandedBody : null
      return (
        <div key={className + '_' + tableId + '_' + index}>
          <table className={'SimpleTable ' + (this.props.className || '')}>
            {index === 0 ? this.renderContextMenu() : null}
            {index === 0 ? this.renderHead() : null}
            <tbody>
              {contextMenuButtons ? (
                <ContextMenuTrigger
                  holdToDisplay={-1}
                  id={'contextmenu_' + tableId}
                  index={index}
                  collect={() => contextMenuCollect(index)}
                  renderTag="tr"
                  attributes={{
                    className: className,
                    onClick: this.rowClick(index),
                    onMouseLeave: this.rowLeave(index)
                  }}
                  key={index}
                >
                  {this.renderRow(tails ? tails[index] : [], row, index)}
                </ContextMenuTrigger>
              ) : (
                <tr
                  key={index}
                  onClick={this.rowClick(index)}
                  onMouseLeave={this.rowLeave(index)}
                  className={className}
                >
                  {this.renderRow(tails ? tails[index] : [], row, index)}
                </tr>
              )}
            </tbody>
          </table>
          <div className="TypedTable">{bottom ? <div className="TypedTable__bottom">{bottom}</div> : null}</div>
        </div>
      )
    })
  }

  finalizeSelected() {
    const selected = this.state.selected.map((index) => this.props.cellValues[index][0].props.rowData)

    for (const selectedElement of selected) {
      const versionedObjectUri = getFullUri(selectedElement)
      const paths = versionedObjectUri.substring(1).split('/')
      const app = pathByType(paths, 'applications') // Issue must be attached to application.
      const sys = pathByType(paths, 'systems') // Issue must be attached to system if no app here.

      const attached = app ? app : sys

      const payload = getPayload(
        defaultMessage,
        attached,
        [],
        versionedObjectUri,
        versionToStr(selectedElement.version.last),
        this.state.objectType,
        this.props.userState.profile.alias
      )

      const url = getObjectMessageApiEndpoint(
        this.props.match.params,
        this.props.ui.theme,
        this.props.ui.tabName,
        selectedElement.identity.name
      )
      httpClient.post(url, payload).then(({ data }) => {
        window.apiCache.deleteObject(app + '/issues')
      })
    }
  }

  /**
   * render table body with rows and context menu triggers
   * @returns {XML}
   */
  renderTable(index, rows, bottom) {
    //logger.info("SimpleTable:renderTable", { index, rows, bottom }, this.state, this.props);

    // We create table for rows specified with bottom if requered
    return (
      <div>
        <table className={'SimpleTable ' + (this.props.className || '')} key={index}>
          {index === 0 ? this.renderContextMenu() : null}
          {index === 0 ? this.renderHead() : null}
          <tbody>{rows}</tbody>
        </table>
        <div className="TypedTable">{bottom ? <div className="TypedTable__bottom">{bottom}</div> : null}</div>

        {Boolean(this.state.selected.length) && isFinalizable(this.props.match.params) && (
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <div className="toolbar" style={{ position: 'sticky', width: '250px', alignSelf: 'flex-start' }}>
              <div style={{ paddingLeft: '25px', paddingRight: '25px', display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: '24px' }}>
                  <div style={{ fontSize: '14px', fontWeight: 600 }}>{this.state.selected.length} selected objects</div>
                </div>
                <div>
                  <IconButton onClick={() => this.finalizeSelected()} title="Finalize">
                    <DoneIcon fontSize="small" style={{ fill: 'white' }} />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  /**
   * Render table body with rows and context menu triggers
   * @returns {XML}
   */
  render() {
    const {
      cellValues,
      tails,
      contextMenuButtons,
      contextMenuCollect,
      tableId,
      tablePath,
      firstRowIndex,
      rowClassNames
    } = this.props

    let modeField = this.props.fieldMode ? this.props.fieldMode : null
    let stateField = this.props.fieldState ? this.props.fieldState : {}

    let rootLength = tablePath ? tablePath.length : 0
    let rootPath =
      rootLength > 0
        ? tablePath[rootLength - 1] !== '/' && tablePath[rootLength - 1] !== '.'
          ? tablePath + '.'
          : tablePath
        : ''
    //logger.info("SimpleTable:render", { cellValues, rootLength, rootPath, modeField, stateField }, this.state, this.props);

    let tables = [] // Tables we generate
    let rows = [] // Rows for the table
    cellValues.map((row, index) => {
      let className = 'SimpleTable__row'

      let rowData = row.length > 0 && row[0].props ? row[0].props.rowData : null
      let rowPath =
        rootPath +
        (rowData && rowData.identity
          ? rowData.identity.name
          : rowData && rowData.name && rowData.name.props && rowData.name.props.id
          ? rowData.name.props.id
          : index)

      // When mode presented, it must be valid state to show field.
      if (modeField && !modeField[rowPath]) {
        //logger.info("SimpleTable:render:MODE", modeField[rowPath], { row, index, rootPath, rowPath, rowData, className, modeField }, this.state, this.props);
        return
      }

      // Check if this row have state.
      if (stateField[rowPath]) {
        // We skip row if it marked as hidden.
        if (stateField[rowPath] === fieldState.HIDDEN) {
          return
        }

        // Select right style for row based on it state
        if (stateField[rowPath] === fieldState.SELECTED) {
          className += ' SimpleTable__selectedRow'
        }
      }

      if (((firstRowIndex || 0) + index) % DELIMITER_ROW_COUNT === DELIMITER_ROW_COUNT - 1)
        className += ' SimpleTable__row_delimiter'
      if (rowClassNames && rowClassNames.length && rowClassNames[index]) className += ' ' + rowClassNames[index]
      //logger.info("SimpleTable:render:ROW", { row, index, rootPath, rowPath, rowData, className }, this.state, this.props);

      if (contextMenuButtons) {
        rows.push(
          <ContextMenuTrigger
            holdToDisplay={-1}
            id={'contextmenu_' + tableId}
            index={index}
            collect={() => contextMenuCollect(index)}
            renderTag="tr"
            attributes={{
              index: index,
              key: rowPath,
              id: rowPath,
              className: className,
              onClick: this.rowClick(index, rowPath, rowData),
              onMouseLeave: this.rowLeave(index)
            }}
            key={index}
          >
            {this.renderRow(tails ? tails[index] : [], row, index, rowPath, rowData)}
          </ContextMenuTrigger>
        )
      } else {
        rows.push(
          <tr
            index={index}
            key={rowPath}
            id={rowPath}
            onClick={this.rowClick(index, rowPath, rowData)}
            onMouseLeave={this.rowLeave(index)}
            className={className}
          >
            {this.renderRow(tails ? tails[index] : [], row, index, rowPath, rowData)}
          </tr>
        )
      }

      let bottom = rowData ? rowData.expandedBody : null
      if (bottom) {
        // Generate table for rows we alredy included with bottom in the end.
        tables.push(this.renderTable(tables.length, rows, bottom))
        rows = []
      }
    })

    // Generate last table if we still have rows
    if (rows.length > 0 || tables.length === 0) {
      tables.push(this.renderTable(tables.length, rows))
    }

    // we are done with tables.
    return tables
  }
}

SimpleTable.propTypes = {
  colHeaders: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired, // array of string/component
  cellValues: PropTypes.arrayOf(PropTypes.array).isRequired, // array of array of string/component
  colWidths: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired, // with "px" if needed
  className: PropTypes.string,
  rowClassNames: PropTypes.array, // optional
  tails: PropTypes.array, // array of string/component
  upperTail: PropTypes.object, // string/component to display as header row tail
  zeroTails: PropTypes.array, // empty tail to display when row is clicked
  filters: PropTypes.object, // string/component to display in beginning of thead
  contextMenuButtons: PropTypes.array, // array of {label, onClick}
  contextMenuCollect: PropTypes.func, // function to provide data for context menu buttons onclick. (index)=>data
  tableId: PropTypes.string, // optional, used for context menu unique ids
  tablePath: PropTypes.string, // Path to the table data, it is used before name of fields for ids
  fieldMode: PropTypes.object, // Map of fields mode. When presented it show only field in defined state.
  fieldState: PropTypes.object, // Map of fields with special state
  firstRowIndex: PropTypes.number, // optional, default 0. used to highlight every 10th row of table; parent table may be split into several SimpleTables, and
  // in this case, second SimpleTable will have firstRowIndex not equal to 0
  setMinWidth: PropTypes.bool, // TH's will have not only width and maxWidth but also minWidth
  noSetWidth: PropTypes.bool, // width is not set
  headBgColor: PropTypes.string,
  headFontColor: PropTypes.string,

  onFieldClick: PropTypes.func // Callback for field click. Used for structure tables.
}

function mapStateToProps(state) {
  return {
    userState: state.userAppState,
    ui: state.ui
  }
}

export default connect(mapStateToProps, null)(withRouter(SimpleTable))
