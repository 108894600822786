import React from 'react'

interface IInstance {
  configuration: {
    identity: {
      name: string
    }
    reference: string
  }
  identity: {
    description: string
    id: string
    name: string
  }
  order: number
  platform: string
  type: string
  units: string
}

interface IInstanceViewerProps {
  selectedInstance: IInstance | null
  instance: IInstance
  onInstanceClick: (instance: IInstance) => void
}

export const InstanceViewer: React.FC<IInstanceViewerProps> = ({ selectedInstance, instance, onInstanceClick }) => {
  // logger.log("InstanceViewer::render", { selectedInstance, instance });

  return (
    <div
      className={
        'TopologyChart__instance ' +
        (selectedInstance && instance.identity.id === selectedInstance.identity.id
          ? 'TopologyChart__instance__selected'
          : '')
      }
      onClick={() => onInstanceClick(instance)}
    >
      <div className="TopologyChart__header">{instance.identity.name}</div>
      <div className="TopologyChart__row row">
        <div className="TopologyChart__label col-xs-3">Units:</div>
        <div className="col-xs-9">{instance.units}</div>
      </div>
      <div className="TopologyChart__row row">
        <div className="TopologyChart__label col-xs-3">Platform:</div>
        <div className="col-xs-9">{instance.platform}</div>
      </div>
    </div>
  )
}
