/* eslint-disable */

function getEnv() {
  if (window.location.href.indexOf('localhost') >= 0) {
    return 'development'
  } else if (window.location.href.indexOf('integration') >= 0) {
    return 'integration'
  } else {
    return 'production'
  }
}

window.client_id = (function () {
  //console.log("clientId", getEnv());
  return getEnv()
})()

window.authorityBaseUrl = window.AUTH_URL || 'https://auth.metucat.com/core/'

window.access_token = null
window.id_token = null

window.show = function (data) {
  console.log(data)
}

window.clear = function () {}

window.showError = function (error) {
  console.error(error)
}

window.rand = function () {
  return (Date.now() + '' + Math.random()).replace('.', '')
}

window.showTokenResponse = function () {
  var hash = window.location.hash.substr(1)
  var result = hash.split('&').reduce(function (result, item) {
    var parts = item.split('=')
    result[parts[0]] = parts[1]
    return result
  }, {})

  show('authorize response')
  //show(result);

  if (result.id_token || result.access_token) {
    //	document.querySelector("body").className = "token";
  }
}

window.getToken = function () {
  if (
    window.authorityBaseUrl === 'disabled' ||
    window.authorityBaseUrl === '/cognitologin'
  )
    return

  var authorizationUrl = authorityBaseUrl + 'connect/authorize'
  console.log('getToken', client_id)
  var redirect_uri =
    'http' +
    (client_id === 'production' ? 's' : '') +
    '://' +
    window.location.host +
    '/login'
  var response_type = 'id_token token'
  var scope = 'openid email read idcheck roles protectedData'

  var state = rand()
  var nonce = rand()

  localStorage['state'] = state
  localStorage['nonce'] = nonce

  // custom code -------------------------------
  var autologin = ''

  if (window.location.hash.indexOf('login') !== -1) {
    const autologinTicket = window.location.hash.split('=')[1]

    console.log('Login ticket', autologinTicket)

    if (autologinTicket !== '') autologin = autologinTicket
  }

  if (window.location.href.indexOf('resetpassword/') !== -1) {
    autologin = window.location.href.substr(
      window.location.href.indexOf('resetpassword/') + 'resetpassword/'.length
    )
    console.log('Login ticket', autologin)
  }

  var url =
    authorizationUrl +
    '?' +
    'client_id=' +
    encodeURI(client_id) +
    '&' +
    'redirect_uri=' +
    (autologin
      ? encodeURI(
          'http' +
            (client_id === 'development' ? '' : 's') +
            '://' +
            window.location.host +
            '/organizations'
        )
      : encodeURI(redirect_uri)) +
    '&' +
    'response_type=' +
    encodeURI(response_type) +
    '&' +
    'scope=' +
    encodeURI(scope) +
    '&' +
    'state=' +
    encodeURI(state) +
    '&' +
    'nonce=' +
    encodeURI(nonce)

  if (autologin) {
    url += '&autologin=' + autologin
  }

  console.log('Auth token url', url)
  window.location = url
}

window.validateToken = function (success, error) {
  clear()

  var hash = window.location.hash.substr(1)
  //console.log(hash);
  var result = hash.split('&').reduce(function (result, item) {
    var parts = item.split('=')
    result[parts[0]] = parts[1]
    return result
  }, {})
  //console.log(result);

  var access_token = result.access_token

  if (result.error) {
    show(result)
    error()
    return false
  }

  if (result.state !== localStorage['state']) {
    show('invalid state')
    error()
    return false
  }

  localStorage.removeItem('state')

  id_token = result.id_token
  if (!id_token) {
    show('no id_token')
    error()
    return false
  }

  var metadata_url = authorityBaseUrl + '.well-known/openid-configuration'

  getJson(metadata_url).then(function (metadata) {
    getJson(metadata.jwks_uri).then(function (jwks) {
      var cert = jwks.keys[0].x5c[0]

      var jws = new KJUR.jws.JWS()
      if (jws.verifyJWSByPemX509Cert(id_token, cert)) {
        var id_token_contents = JSON.parse(jws.parsedJWS.payloadS)

        if (localStorage['nonce'] !== id_token_contents.nonce) {
          //console.log("Invalid nonce");

          error()
          return
        }

        if (id_token_contents.iss !== metadata.issuer) {
          error()
          return
        }

        if (id_token_contents.aud !== client_id) {
          error()
          return
        }

        var now = parseInt(Date.now() / 1000)

        // accept tokens issues up to 5 mins ago
        var diff = now - id_token_contents.iat
        if (diff > 5 * 60) {
          error()
          return
        }

        if (id_token_contents.exp < now) {
          error()
          return
        }

        show('id_token_contents')
        show(id_token_contents)

        if (result.access_token) {
          if (!id_token_contents.at_hash) {
            show('No at_hash in id_token')
            error()
            return
          }

          var hash = KJUR.crypto.Util.sha256(result.access_token)
          var left = hash.substr(0, hash.length / 2)
          var left_b64u = hextob64u(left)

          if (left_b64u !== id_token_contents.at_hash) {
            show('at_hash failed to validate')
            error()
            return
          }

          var new_access_token = result.access_token

          show(access_token)

          getJson(metadata.userinfo_endpoint, result.access_token).then(
            function (result) {
              show('userinfo endpoint results')
              show(result)
              localStorage['currentUserId'] = result.sub
              localStorage['currentUserToken'] = access_token
              localStorage['currentUserIdToken'] = id_token

              if (typeof localStorage['unauthorizedCount'] === undefined)
                localStorage['unauthorizedCount'] = 0
              if (id_token_contents.temp_password)
                sessionStorage['temp_password'] =
                  id_token_contents.temp_password
              sessionStorage.isLoggingOut = ''

              success()
            },
            showError
          )
        }
      }
    }, showError)
  }, showError)

  return true
}

window.releaseToken = function () {
  var endSessionUrl =
    authorityBaseUrl +
    'connect/endsession?' +
    'post_logout_redirect_uri=' +
    encodeURIComponent('https:' + '//' + window.location.host + '/login') +
    '&id_token_hint=' +
    localStorage.currentUserIdToken

  //console.log("Logout:releaseToken", endSessionUrl);

  localStorage.removeItem('currentUserToken')
  localStorage.removeItem('currentUserIdToken')
  localStorage.removeItem('currentUserId')
  localStorage.removeItem('lastApplications')
  sessionStorage.isLoggingOut = true

  location.href = endSessionUrl
}

window.logout = function () {
  //window.location = authorityBaseUrl + "connect/endsession?post_logout_redirect_uri=" + encodeURIComponent(window.location.protocol + "//" + window.location.host + "/index.html") + "&id_token_hint=" + encodeURIComponent(id_token);
  window.location =
    authorityBaseUrl +
    'connect/endsession?post_logout_redirect_uri=' +
    encodeURIComponent('https://' + window.location.host + '/index.html') +
    '&id_token_hint=' +
    encodeURIComponent(id_token)
}

window.getJson = function (url, token) {
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest()

    xhr.onload = function () {
      try {
        if (xhr.status === 200) {
          var response = xhr.response
          if (typeof response === 'string') {
            response = JSON.parse(response)
          }
          resolve(response)
        } else {
          reject(Error(xhr.statusText + '(' + xhr.status + ')'))
        }
      } catch (err) {
        reject(err)
      }
    }

    xhr.onerror = function () {
      reject(Error('Network Error'))
    }
    xhr.open('GET', url)
    xhr.responseType = 'json'

    xhr.setRequestHeader('Access-Control-Allow-Origin', '*')

    if (token) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + token)
    }

    xhr.send()
  })
}

if (window.location.hash) {
  window.showTokenResponse()
} else {
  if (!localStorage['currentUserToken']) {
    window.getToken()
  }
}
