import React, { Component } from 'react'
import { clearLocalObject, getFullUri, objectNameFromPathByType, updateLocalObject } from '../../helpers'
import { getOrganizationDataset } from '../../helpers/api'
import {
  createEmptyObject,
  deepCopy,
  editableState,
  fieldTypeToString,
  getSpecFieldOrdinal,
  nonCSCompare
} from '../../helpers/index'
import logger from '../../helpers/logger'
import { DataEditor } from '../DataDialog/DataEditor'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { renderRestore } from '../../helpers/helperComponents'

export class InterfaceExampleDialog extends Component {
  constructor(props) {
    super(props)

    let example = this.getInitialExample(props)
    let height = this.initHeight(props, example)

    this.state = {
      change: null, // Method to report or cancel modification.
      origin: JSON.stringify(example).toLowerCase(),

      example: example,
      height: height,

      userSetName: false,
      types: {},
      typeJson: -1,
      typeSystem: -1,
      objectType: 'example'
    }
  }

  componentDidMount() {
    // Load platforms list from organization we are in.
    const organization = objectNameFromPathByType(getFullUri(this.props.majorObject), 'organizations')
    getOrganizationDataset('Type', organization).then((result) => {
      this.setState({
        types: result,
        typeSystem: getSpecFieldOrdinal(result, 'Type'),
        typeJson: getSpecFieldOrdinal(result, 'RESTfull-API')
      })
    })
  }

  /**
   * Get initial value of parameter
   * @param {object} [props]
   * @returns {*}
   */
  getInitialExample = (props) => {
    props = props || this.props
    return props.example && props.example !== true ? props.example : this.createEmptyExample(props)
  }

  /**
   * Create empty object for our object
   * @param {object} [props]
   * @returns {*}
   */
  createEmptyExample = (props) => {
    let example = createEmptyObject('interfaceExample')
    example.format = 'JSON'
    logger.info('ExampleDialog:createEmptyExample', props, example)
    return example
  }

  componentWillReceiveProps() {}

  /**
   * Init dialog height
   */
  initHeight = (props, example) => {
    props = props || this.props

    let height = 300

    if (height < window.innerHeight - 248) height = window.innerHeight - 248

    height += example && example.parameters && example.parameters.length > 0 ? example.parameters.length * 170 : 0

    //console.log("InterfaceExampleDialog:InitHeight", props, example, window.innerHeight, height);

    return height
  }

  /**
   * Change state of example
   * @param {object} example - new state of object
   * @returns {boolean} true - it is updated, false - no changes
   */
  ChangeExample = (example) => {
    if (this.state.change) {
      let curr = JSON.stringify(this.state.example).toLowerCase()
      let next = JSON.stringify(example).toLowerCase()

      if (this.state.change) {
        this.state.change(this.state.origin !== next, '', '', '')
      }

      if (curr !== next) {
        let height =
          this.state.height +
          (example.parameters && example.parameters.length > 0 ? example.parameters.length * 170 : 0) -
          (this.state.example.parameters && this.state.example.parameters.length > 0
            ? this.state.example.parameters.length * 170
            : 0)

        console.log('InterfaceExampleDialog:ChangeExample', example, this.state, height)

        //this.setState({ example: example, height: height });
        this.setState({ height: height }, () => {
          this.setState(updateLocalObject(example))
        })
        return true
      }
    }
    return false
  }

  /**
   * Adjust size of scroll area in dialog
   * @param width - increase or reduction in control width.
   * @param height - increase or reduction in control height.
   * @return
   */
  onAdjust = (width, height, source) => {
    this.setState((prevState) => {
      return { height: prevState.height + height }
    })
  }

  /**
   * Set edit mode on
   */
  startEdit = (change) => {
    this.setState({
      change: change
    })
  }

  /**
   * Cancel changes
   */
  cancelEdit = () => {
    // Cancel save mode
    if (this.state.change) this.state.change(false)

    this.setState({
      change: null
      //example: this.getInitialExample(),
    })
    this.setState(clearLocalObject(this.getInitialExample()))
  }

  // Name generated from interface name plus operation name
  onNameChange = (e) => {
    let data = e.target.value
    //console.log("ExampleDialog:onNameChange", data, this.state.example);

    let example = deepCopy(this.state.example)
    if (example.identity) {
      example.identity.name = data
    } else {
      example.identity = { name: data }
    }

    this.ChangeExample(example)
  }

  onDescriptionChange = (e) => {
    let data = e.target.value
    //console.log("ExampleDialog:onDescriptionChange", data, this.state.example);

    let example = deepCopy(this.state.example)
    if (example.identity) {
      example.identity.description = data
    } else {
      example.identity = { description: data }
    }

    this.ChangeExample(example)
  }

  onOperationChange = (e) => {
    const operation = e.target.value
    if (!operation) return

    console.log('onOperationChange', e.target.value)

    let example = deepCopy(this.state.example)
    if (example.operation) {
      example.operation.id = operation.identity.id
      example.operation.name = operation.identity.name
    } else {
      example.operation = {
        id: operation.identity.id,
        name: operation.identity.name
      }
    }

    example.parameters = operation.parameters.map((param) => {
      let parameter = this.props.parameters.find((p) => p.field.identity.name === param.name)
      let object = {
        identity: { name: parameter.field.identity.name },
        type: parameter.field.type
      }
      if (parameter.field.reference && parameter.field.reference.length > 0) {
        object.reference = parameter.field.reference
      }
      object.value = ''
      return object
    })

    this.ChangeExample(example)
  }

  onResponseChange = (e) => {
    const response = e.target.value
    if (!response) return

    console.log('onResponseChange', e.target.value)

    let resp = this.props.majorObject.responses.find((p) => p.field.identity.name === response.name)
    let object = {
      identity: { name: resp.field.identity.name },
      type: resp.field.type
    }
    if (resp.field.reference && resp.field.reference.length > 0) {
      object.reference = resp.field.reference
    }
    object.value = ''

    let example = deepCopy(this.state.example)
    example.response = object

    this.ChangeExample(example)
  }

  onParameterValueChange = (name, value) => {
    //const value = e.target.value;
    //const name = id;

    console.log('onParameterValueChange', value, name)
    if (!name) return

    let example = deepCopy(this.state.example)
    value = JSON.stringify(value)
    if (!example.parameters) {
      const interfaceParameter = this.findParameterByName(this.props.majorObject.parameters, name)

      example.parameters = [
        {
          identity: { name: name },
          type: interfaceParameter.field.type,
          reference: interfaceParameter.field.reference,
          value: value
        }
      ]
    } else {
      let parameter = example.parameters.find((param) => param.identity.name === name)
      if (parameter) {
        parameter.value = value
      }
    }
    console.log('onParameterValueChange', example)

    this.ChangeExample(example)
  }

  onResponseValueChange = (value) => {
    //const value = e.target.value;
    //if (!value) return;

    console.log('onResponseValyeChange', value)

    let example = deepCopy(this.state.example)
    if (!example.response) {
      example.response = {}
    }
    example.response.value = JSON.stringify(value)

    this.ChangeExample(example)
  }

  /**
   * Execute activity when message send to service
   * @param error return from service if any.
   * @return
   */
  onSent = (close, error) => {
    console.log('InterfaceExampleDialog:onSent', close, error)

    // Report complete of save with error or not
    if (this.state.change) {
      this.state.change(error)
    }

    if (close && !error) {
      setTimeout(() => {
        this.props.onClose()
      }, 1000)
      this.setState(clearLocalObject(this.getInitialExample()))
    }
  }

  saveExample = (closeDialog) => {
    let inputError = ''
    const condition = this.props.majorObject
    if (condition && this.state.example.identity && this.state.example.identity.name) {
      const checkExamplesName = condition.examples.find(
        (e) =>
          nonCSCompare(e.identity.name, this.state.example.identity.name) &&
          !nonCSCompare(e.identity.id, this.state.example.identity.id)
      )
      if (checkExamplesName) {
        inputError += 'Example name already exist'
      }
    }
    if (!this.state.example.identity || !this.state.example.identity.name) {
      inputError += 'Please specify example name!'
    }

    if (!this.state.example.operation || !this.state.example.operation.id) {
      inputError += (inputError.length > 0 ? '\n' : '') + 'Please specify which operation is example for.'
    }

    if (inputError.length > 0) {
      if (this.state.change) {
        this.state.change(true, '', '', inputError)
      }
      return true
    }

    if (this.state.change) {
      this.state.change(true, 'Data send to the service...', '', '')
    }

    const example = this.state.example

    if (this.props.onSave) {
      this.props.onSave(example, closeDialog, this.onSent.bind(this))
      return true
    }
  }

  operationsToInputOptions = (operations) =>
    operations.map((op, i) => {
      return {
        id: i,
        label: op.identity.name || '',
        value: op
      }
    })

  responsesToInputOptions = (responses) =>
    responses.map((res, i) => {
      return {
        id: i,
        label: res.name || '',
        value: res
      }
    })

  findParameterByName = (array, name) => array.find((el) => el.field.identity.name === name)
  findResponseByName = (array, name) => array.find((el) => el.field.identity.name === name)
  findOperationByName = (array, name) => array.find((el) => el.identity.name === name)

  nameDataType = { name: 'string' }

  nameDataProps = {
    placeholder: 'Example name',
    onChange: this.onNameChange
  }

  renderParameters = (parameters, editState) => {
    /*
  
        {param.type === 'Structure' ?
          <JsonObjectEditor
            header=""
            excludeRoot
            example={{text: param.value}}
            change={this.state.change}
            data={this.state}
            editState={editableState.EDITING}
            majorObject={this.props.majorObject}
            dataObject={{type: 'dataset', _path: '', identity: {name: 'temp dataset'}, object: {parent: null}, structure: {fields: [field]}}}
            onChange={(paramObject)=>this.onParameterValueChange(param.identity.name, {target: {value: paramObject.text}})}
            noSchema
          /> : <EditableEntity
        data={param.value}
        dataType={{name: "text_updatable"}}
        dataProps={{
          placeholder: "Parameter value",
          onChange: this.onParameterValueChange.bind(this, param.identity.name)
        }}
        isEditable
        inEditMode={editState > editableState.EDITABLE}
        />}

  */
    return parameters.map((param, index) => {
      const interfaceObject = this.props.majorObject
      const interfaceParameter = param
        ? this.findParameterByName(this.props.majorObject.parameters, param.identity.name)
        : null
      const fieldParameter = param ? this.findParameterByName(this.props.parameters, param.identity.name) : null
      let field = fieldParameter ? deepCopy(fieldParameter).field : null
      if (field) field.optional = false

      console.log('renderParameters', param, interfaceParameter, fieldParameter)
      return (
        <div key={param.identity.name} className="EditorDialog__block clearfix">
          <div>
            <label className="ExampleDialog__label">Parameter: </label>
            <text className="ExampleDialog__name">{param.identity.name}</text>
          </div>
          <div>
            <label className="ExampleDialog__label">Type: </label>
            <text className="ExampleDialog__type">
              {interfaceParameter ? fieldTypeToString(interfaceParameter.field, interfaceObject) : ''}
            </text>
          </div>
          <div>
            <DataEditor
              field={interfaceParameter.field}
              data={param.value ? JSON.parse(param.value) : ''}
              majorObject={this.props.majorObject}
              change={this.state.change}
              options={{
                onChange: this.onParameterValueChange.bind(this, param.identity.name)
              }}
              isEditable={editState}
            />
          </div>
        </div>
      )
    })
  }

  renderEditor = (editState) => {
    const operation = this.state.example.operation
    const response = this.state.example.response
    const interfaceObject = this.props.majorObject
    const interfaceOperation = operation
      ? this.findOperationByName(this.props.majorObject.operations, operation.name)
      : null
    const interfaceResponse = response
      ? this.findResponseByName(this.props.majorObject.responses, response.identity.name)
      : null
    let interfaceResponseField = interfaceResponse ? deepCopy(interfaceResponse).field : null
    if (interfaceResponseField) interfaceResponseField.optional = false

    return (
      <div className="ExampleDialog">
        <div className="EditorDialog__block clearfix">
          <label className="ExampleDialog__label">Name:</label>
          <div className="value_right">
            <EditableEntity
              data={this.state.example.identity ? this.state.example.identity.name : ''}
              dataType={this.nameDataType}
              dataProps={this.nameDataProps}
              inEditMode={editState > editableState.EDITABLE}
              isEditable
            />
          </div>
        </div>
        <div className="EditorDialog__block clearfix">
          <label className="ExampleDialog__label">Description:</label>
          <div className="value_right">
            <EditableEntity
              data={this.state.example.identity ? this.state.example.identity.description : ''}
              dataType={{ name: 'text_updatable' }}
              dataProps={{
                placeholder: 'Example description',
                onChange: this.onDescriptionChange
              }}
              isEditable
              inEditMode={editState > editableState.EDITABLE}
            />
          </div>
        </div>
        <div className="EditorDialog__block clearfix">
          <label className="ExampleDialog__label">Operation:</label>
          <div className="value_right">
            {this.props.operations.length ? (
              <EditableEntity
                data={operation ? operation.name : ''}
                dataType={{
                  name: 'enum',
                  options: this.operationsToInputOptions(this.props.operations)
                }}
                dataProps={{
                  placeholder: 'Choose operation',
                  onChange: this.onOperationChange
                }}
                isEditable
                inEditMode={editState > editableState.EDITABLE}
              />
            ) : (
              'You have no operations in the Interface. You can create example only with operation'
            )}
          </div>
        </div>
        {operation && operation.id && this.state.example.parameters && this.state.example.parameters.length > 0
          ? this.renderParameters(this.state.example.parameters, editState)
          : null}
        {operation && operation.id ? (
          <div className="EditorDialog__block clearfix">
            <label className="ExampleDialog__label">Response:</label>
            <div className="value_right">
              {interfaceOperation && interfaceOperation.responses && interfaceOperation.responses.length > 0 ? (
                <EditableEntity
                  data={response ? response.identity.name : ''}
                  dataType={{
                    name: 'enum',
                    options: this.responsesToInputOptions(interfaceOperation.responses)
                  }}
                  dataProps={{
                    placeholder: 'Choose response',
                    onChange: this.onResponseChange
                  }}
                  isEditable
                  inEditMode={editState > editableState.EDITABLE}
                />
              ) : (
                'You have no responses in the Interface opertion. You can create example only with response'
              )}
            </div>
            {response && response.identity.name && interfaceResponse ? (
              <div className="EditorDialog__block clearfix">
                <div>
                  <label className="ExampleDialog__label">Type: </label>
                  <text className="ExampleDialog__type">
                    {fieldTypeToString(interfaceResponse.field, interfaceObject)}
                  </text>
                </div>
                <div>
                  <DataEditor
                    field={interfaceResponse.field}
                    data={response.value ? JSON.parse(response.value) : ''}
                    majorObject={this.props.majorObject}
                    change={this.state.change}
                    options={{ onChange: this.onResponseValueChange }}
                    isEditable={editState}
                  />
                  {/*
              {interfaceResponse.field.type === 'Structure' ?
                <JsonObjectEditor
                  header=""
                  excludeRoot
                  example={{text: response.value}}
                  change={this.state.change}
                  data={this.state}
                  editState={editableState.EDITING}
                  majorObject={this.props.majorObject}
                  dataObject={{type: 'dataset', _path: '', identity: {name: 'temp dataset'}, object: {parent: null}, structure: {fields: [interfaceResponseField]}}}
                  onChange={(paramObject)=>this.onResponseValueChange({target: {value: paramObject.text}})}
                  noSchema
                /> :
                <EditableEntity
                  data={response.value}
                  dataType={{name: "text_updatable"}}
                  dataProps={{
                    placeholder: "Response value",
                    onChange: this.onResponseValueChange
                  }}
                  isEditable
                  inEditMode={editState > editableState.EDITABLE}
                />}
                */}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    )
  }

  render() {
    console.log('InterfaceExampleDialog:render', this.state, this.props)
    return (
      <EditorDialog
        confirmText={this.props.example ? 'Update' : 'Create'}
        isVisible={this.props.isVisible}
        onSave={this.saveExample}
        modalTitle={
          this.props.example
            ? this.props.isEditable > editableState.EDITABLE
              ? 'Edit example'
              : 'View example'
            : 'Create example'
        }
        editContent={this.renderEditor}
        headerContent={renderRestore(this)}
        editHeight={this.state.height}
        objectType="example"
        isEditable={this.props.isEditable}
        onEdit={this.startEdit}
        onCancel={this.cancelEdit}
        onClose={this.props.onClose}
      />
    )
  }
}
