/* eslint-disable array-callback-return */
/**
 * Created by mailf on 08.06.2016.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { getObjectListNew, getRequestFromPath } from '../../helpers/api'
import {
  columnsToType,
  deepCopy,
  deepMerge,
  editableState,
  getFullUri,
  getVersionStatus,
  identitySort,
  listOfMajorVersionsToEnumOptions,
  nonCSCompare,
  singularTypeForms,
  versionCompare,
  versionSort,
  versionToStr
} from '../../helpers/index'
import logger from '../../helpers/logger'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { EditorListBase } from '../EditorDialog/EditorListBase'
import { VersionedObjectReferenceDialog } from './VersionedObjectReferenceDialog'
import './VersionedObjectReferenceList.scss'
import { getTailButtonLabel } from '../../helpers/helperComponents'

export class VersionedObjectReferenceList extends EditorListBase {
  constructor(props) {
    super(props)

    this.state.objects = null
    this.state.selectingObjects = false
    this.state.selectedVersions = {}
    //logger.info("VersionedObjectReferenceList:constructor", this.props, this.state);
  }

  /**
   * Add Item when add button pushed
   */
  onAddItem = () => {
    //logger.info("VersionedObjectReferenceList:onAddItem", this.props, this.state);
    this.setState(
      {
        selectingObjects: !this.state.selectingObjects
      },
      () => {
        if (this.state.selectingObjects) {
          this.loadDataOptions()
        }
        if (this.props.onAdjust) {
          this.props.onAdjust(0, 0)
        }
      }
    )
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  loadDataOptions = () => {
    // Check if we already have it
    if (this.state.objects && this.state.objects.length > 0) {
      return
    }

    let newObjs = this.props.base || []
    let promises = []

    const stype = singularTypeForms.get(this.props.type)

    if (this.props.onReport) {
      this.props.onReport('Request ' + this.props.type + ' from service...')
    }

    newObjs.map((tobj) => {
      if (tobj.versionOptions) {
        // We already loaded versions here
        return
      }
      //logger.info("VersionedObjectReferenceList:loadDataOptions:VERSIONS", tobj.identity.name, { tobj }, this.state, this.props);

      promises.push(
        getObjectListNew(getRequestFromPath(getFullUri(tobj)).versions, stype).then(
          (result) => {
            // Version options
            let options = listOfMajorVersionsToEnumOptions(
              result,
              (obj) => getVersionStatus(obj) === 'approved',
              versionSort
            )

            //logger.info("VersionedObjectReferenceList:loadDataOptions:options", tobj.identity.name, { result, options, tobj, newObjs }, this.state, this.props);

            tobj['versionOptions'] = options
            return true
          },
          (error) => {
            logger.error('VersionedObjectReferenceList:open:VERSIONS:ERROR', tobj, error)
            return false
          }
        )
      )
    })

    //logger.info("VersionedObjectReferenceList:loadDataOptions", promises, this.state, this.props);

    Promise.all(promises).then(
      () => {
        //logger.info("VersionedObjectReferenceList:loadDataOptions:APPS", { type, appUri, newObjs }, this.state, this.props);
        if (this.props.onReport) {
          this.props.onReport()
        }
        this.setState({ objects: newObjs }, () => {
          if (this.props.onAdjust) {
            this.props.onAdjust(0, 0)
          }
        })
      },
      (error) => {
        logger.info('VersionedObjectReferenceList:open:VERSIONS:ERROR', error)
        if (this.props.onReport) {
          this.props.onReport(error)
        }
      }
    )

    //logger.info("VersionedObjectReferenceList:loadDataOptions", appUri, this.state, this.props);
  }

  /**
   * Save Item in layout
   * @param {object} obj - item to save
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   * @return
   */
  saveItem = (obj, base, closeDialog, onSent) => {
    let item = base ? deepMerge(base, obj) : obj
    let objs = this.props.items || []
    console.log('VersionedObjectReferenceList:saveItem', obj, base, item, objs, this.state, this.props)

    let items = objs.filter((tobj) => !nonCSCompare(this.getItemName(tobj), this.getItemName(item)))
    if (item.deployed) {
      // We add application or change it version.
      items.push(item)
    } else {
      // We removing application from package. If it was here before, we mark
      // it as removed by removing version. if it new here we simply delete.
      let oldItem = (this.props.oldItems || []).find((tobj) =>
        nonCSCompare(this.getItemName(tobj), this.getItemName(item))
      )
      if (oldItem) {
        items.push(oldItem)
      }
    }

    if (this.props.onSave) {
      this.props.onSave(items, closeDialog, onSent)
    }
  }

  /**
   * Remove all items from list of selected items
   * @return
   */
  onRemoveAll = () => {
    let objs = this.props.items || []
    console.log('VersionedObjectReferenceList:onRemoveAll', objs, this.state, this.props)

    let items = []

    objs.map((item) => {
      // We removing application from package. If it was here before, we mark
      // it as removed by removing version. if it new here we simply delete.
      let oldItem = (this.props.oldItems || []).find((tobj) =>
        nonCSCompare(this.getItemName(tobj), this.getItemName(item))
      )
      if (oldItem) {
        items.push(oldItem)
      }
    })

    if (this.props.onSave) {
      this.props.onSave(items)
    }
  }

  /**
   * Return filters requered for item list
   */
  getFilters = () => {
    return [
      { name: '', width: 40 },
      { name: 'search', width: 260 }
    ]
  }

  /**
   * Return columns requered for item list
   */
  getColumns = () => {
    // If we are in Instance mode, we only allowed to browse this list as it is defined
    // in deployment with modification allowed only in layout instance options.
    if (this.props.isInstance) {
      return [
        {
          name: 'icon',
          displayName: ' ',
          type: columnsToType.getType('data'),
          frozen: true,
          width: 28
        },
        {
          name: 'name',
          displayName: this.props.type + ' Name',
          type: columnsToType.getType('minorObjectName'),
          width: 240 - 24,
          onCellClick: (value) => {
            this.onViewItem(value)
          }
        },
        {
          name: 'description',
          displayName: this.renderDescriptionColumnHeader(),
          type: this.getDescriptionType(),
          width: 242
        },
        {
          name: 'version',
          displayName: 'Version',
          type: columnsToType.getType('data'),
          frozen: true,
          width: 73
        },
        {
          name: 'deployed_in',
          displayName: 'Deployed',
          type: columnsToType.getType('data'),
          frozen: true,
          width: 73
        },
        {
          name: 'mapped',
          displayName: 'Map',
          type: columnsToType.getType('visibility'),
          frozen: true,
          width: 40
        },
        {
          name: 'layouts',
          displayName: 'Layouts',
          type: columnsToType.getType('string'),
          frozen: true,
          width: 193
        }
      ]
    }

    return [
      {
        name: 'icon',
        displayName: ' ',
        type: columnsToType.getType('data'),
        frozen: true,
        width: 28
      },
      {
        name: 'name',
        displayName: this.props.type + ' Name',
        type: columnsToType.getType('minorObjectName'),
        width: 240 - 24,
        onCellClick: (value) => {
          this.onViewItem(value)
        }
      },
      {
        name: 'description',
        displayName: this.renderDescriptionColumnHeader(),
        type: this.getDescriptionType(),
        width: 252
      },
      {
        name: 'version',
        displayName: 'Version',
        type: columnsToType.getType('data'),
        frozen: true,
        width: 123
      },
      {
        name: 'deployed_in',
        displayName: 'Deployed in',
        type: columnsToType.getType('data'),
        frozen: true,
        width: 93
      },
      {
        name: 'layouts',
        displayName: 'Layouts',
        type: columnsToType.getType('string'),
        frozen: true,
        width: 153
      }
    ]
  }

  /**
   * Return item data to render in table
   * @param {object} obj - item to render
   * @param {object} base - item from base layer to render
   * @param {number} editState - edit state on dialog
   */
  getItem = (obj, base, editState) => {
    let currObj = obj ? obj : base
    if (!currObj) {
      return {}
    }

    let oldObj = (this.props.oldItems || []).find((item) => nonCSCompare(item.identity.name, currObj.identity.name))
    let currBase = base

    let versionDeployed = oldObj && oldObj.version ? versionToStr(oldObj.version) : ''
    let versionSelected = currObj && currObj.version ? versionToStr(currObj.version) : ''

    let deployedDeployment = oldObj && oldObj.deployed ? versionToStr(oldObj.deployed) : ''
    let selectedDeployment = currObj && currObj.deployed ? versionToStr(currObj.deployed) : ''

    let currentDeployment = versionToStr(this.props.majorObject.version)
    if (versionDeployed && !deployedDeployment) {
      // This is strange situation but let's handle it,
      deployedDeployment = this.props.oldDeployment ? versionToStr(this.props.oldDeployment.version) : '1.0.0'
    }

    // For old data of deployment we remove selected data
    if (selectedDeployment === deployedDeployment) {
      versionSelected = ''
      selectedDeployment = ''
    }

    // Options for version to select.
    let versionOptions = currBase && currBase.versionOptions ? currBase.versionOptions : []
    if (oldObj && oldObj.version && versionOptions.length > 0) {
      versionOptions = versionOptions.filter((op) => versionSort(op.value, oldObj.version) < 0)
    }

    // If we are in Instance mode, we only allowed to browse this list as it is defined
    // in deployment with modification allowed only in layout instance options.
    if (this.props.isInstance) {
      editState = editableState.BROWSABLE
      versionDeployed = versionSelected
      deployedDeployment = selectedDeployment
    }

    logger.info(
      'VersionedObjectReferenceList:getItem',
      currObj.identity ? currObj.identity.name : '',
      {
        obj,
        base,
        editState,
        currObj,
        oldObj,
        currBase,
        versionOptions,
        versionDeployed,
        versionSelected,
        deployedDeployment,
        selectedDeployment
      },
      this.state,
      this.props
    )

    let versionAvailable = ''
    if (versionSelected && versionSelected !== versionDeployed && editState < editableState.EDITING) {
      // We have selected new version for current deployment.
      versionAvailable = <div className="ApplicationReferenceList__versionAvailable">{versionSelected}</div>
    } else if (versionOptions.length > 0) {
      // Current selected version.
      let data = ''
      if (this.state.selectedVersions[currObj.identity.name]) {
        data = versionToStr(this.state.selectedVersions[currObj.identity.name])
      } else if (versionSelected) {
        data = versionSelected
      }

      // We need ability to select version
      versionAvailable = (
        <div className="ApplicationReferenceList__versionAvailable">
          <EditableEntity
            dataProps={Object.assign({
              onChange: (e) => {
                //logger.info(ApplicationReferenceList:getItem:CHANGE, e.target.value);
                this.setState(
                  {
                    selectedVersions: Object.assign({}, this.state.selectedVersions, {
                      [currObj.identity.name]: e.target.value
                    })
                  },
                  () => {
                    if (currentDeployment === selectedDeployment) {
                      // We update version for currObject need to safe it
                      let newObj = Object.assign({}, currObj, {
                        version: e.target.value,
                        reference:
                          getFullUri(currBase) + (e.target.value ? '/versions/' + versionToStr(e.target.value) : '')
                      })
                      this.saveItem(newObj, null, false, null)
                    }
                  }
                )
              },
              placeholder: 'Version',
              hideCheckBox: true,
              multi: false
            })}
            dataType={{ name: 'enum', options: versionOptions }}
            data={data}
            isEditable
            inEditMode
          />
        </div>
      )
    } else {
      // nothing to report.
      versionAvailable = <div className="ApplicationReferenceList__versionAvailable">{''}</div>
    }

    let iconName = 'not_included'
    if ((selectedDeployment || deployedDeployment) && !currObj.reference) {
      iconName = 'excluded'
    } else if (selectedDeployment || deployedDeployment) {
      iconName = 'included'
    }

    //We want to show second line.
    // We can select this application first time
    // We alredy selected deployment and version
    // We have version to select for current deployment.
    let secondLine =
      (!deployedDeployment && editState > editableState.EDITABLE) ||
      selectedDeployment === currentDeployment ||
      (deployedDeployment && versionOptions.length > 0 && editState > editableState.EDITABLE)

    // Prepare object data
    return Object.assign({}, currObj, {
      icon: <span className={'ApplicationReferenceList__icon ApplicationReferenceList__icon_' + iconName}></span>,
      name: {
        name: currObj.identity.name,
        objectType: iconName !== 'not_included' ? 'appRef' : 'appRefGray'
      },
      version: (
        <div
          className={
            'ApplicationReferenceList__version ' +
            (versionDeployed && secondLine ? 'ApplicationReferenceList__versionTwoLines' : '')
          }
        >
          {versionDeployed !== '0.0.0' ? versionDeployed : ''}
          {secondLine ? versionAvailable : null}
        </div>
      ),
      deployed_in: (
        <div
          className={
            'ApplicationReferenceList__deployed ' +
            (versionDeployed && secondLine ? 'ApplicationReferenceList__deployedTwoLines' : '')
          }
        >
          <div className="ApplicationReferenceList__deploymentVersionPrev">{deployedDeployment}</div>
          {!secondLine ? null : (
            <div className="ApplicationReferenceList__deploymentVersion">
              {this.getCheckbox(currObj, versionOptions.length > 0 ? editState : editableState.BROWSABLE)}
              {currentDeployment}
            </div>
          )}
        </div>
      ),
      description: currObj.identity.description,
      mapped: this.isItemMapped(currObj),
      layouts: currObj.layoutNames ? currObj.layoutNames.join(', ') : ''
    })
  }

  /**
   * Drow check box inside record for deployment of application
   */
  getCheckbox = (currObj, editState) => {
    //logger.info("VersionedObjectReferenceList:getCheckbox", currObj, editState, this.state, this.props)
    return (
      <EditableEntity
        dataProps={{
          onChange: (event) => this.onDeploymentChange(event.target.value, currObj, editState)
        }}
        dataType={{ name: 'boolean' }}
        data={versionCompare(currObj.deployed, this.props.majorObject.version)}
        isEditable={editState > editableState.EDITABLE}
        inEditMode={editState > editableState.EDITABLE}
      />
    )
  }

  // Check if application properly mapped
  isItemMapped = (currObj) => {
    // Relevant only for instance.
    if (!this.props.isInstance) return false

    // Current object reference.
    let child = currObj
    if (!child.layoutNames || child.layoutNames.length === 0) return true

    // No instances, nothing maped
    if (!child.layoutInstances) {
      return false
    }

    // Let's check we have all maped.
    for (let name of child.layoutNames) {
      let instance = child.layoutInstances.find((inst) => inst.layoutName === name)
      if (!instance || !instance.reference) {
        return false
      }
    }
    return true
  }

  /**
   * Change deployment state. Application can go from be deployed in previous deployments
   * to be deployed in current deployment. When status on, it will be deployed in current
   * deployment if off: we return to deployment state it was in previous deployment.
   * If application wasn't deploy before, off state will remove application from package
   */
  onDeploymentChange = (state, currObj, editState) => {
    //logger.info("VersionedObjectReferenceList:onDeploymentChange", state, currObj, editState, this.state, this.props)
    let newObj = null
    if (state) {
      // Find old object
      let currBase = (this.props.base || []).find((item) => nonCSCompare(item.identity.name, currObj.identity.name))
      let version = this.state.selectedVersions[currObj.identity.name]
      if (!version) {
        let oldObj = (this.props.oldItems || []).find((item) => nonCSCompare(item.identity.name, currObj.identity.name))
        //logger.info("VersionedObjectReferenceList:onDeploymentChange:SELECT", state, currObj, oldObj, editState, this.state, this.props)

        // We need version from list.
        if (oldObj && !versionCompare(currObj.version, oldObj.version)) {
          // Keep version we already have
          version = currObj.version
        } else {
          // Options for version to select.
          let versionOptions = currBase && currBase.versionOptions ? currBase.versionOptions : []
          if (oldObj && oldObj.version && versionOptions.length > 0) {
            versionOptions = versionOptions.filter((op) => versionSort(op.value, oldObj.version) < 0)
          }

          if (versionOptions && versionOptions.length > 0) {
            // we choose highest avalable.
            version = versionOptions[0].value
          }
        }
      }

      newObj = Object.assign({}, currObj, {
        version: version,
        reference: getFullUri(currBase) + (version ? '/versions/' + versionToStr(version) : ''),
        deployed: this.props.majorObject.version
      })
    } else {
      // Find old object
      let oldObj = (this.props.oldItems || []).find((item) => nonCSCompare(item.identity.name, currObj.identity.name))

      if (oldObj) {
        newObj = deepCopy(oldObj)
      } else {
        newObj = Object.assign({}, currObj, {
          version: null,
          reference: '',
          deployed: null
        })
      }
    }
    this.saveItem(newObj, null, false, null)
  }

  /**
   * Remove application from package. THis is happens whrn we don't want this application
   * to be deployed by this package. THis can happens only to applications was
   * deployed before and now will be not deployed by this package. To mark as canceled we will
   * remove reference from application specification.
   */
  onDeploymentCancel = (currObj, editState) => {
    //logger.info("VersionedObjectReferenceList:onDeploymentCancel", currObj, editState, this.state, this.props)

    let newObj = Object.assign({}, currObj, {
      reference: '',
      deployed: this.props.majorObject.version
    })
    this.saveItem(newObj, null, false, null)
  }

  /**
   * Return items list to render in table
   * @param {object} items - items to render
   * @param {object} base - items from base layer to render
   * @param {number} editState - edit state on dialog
   */
  getItems = (items, base, editState) => {
    //logger.info("VersionedObjectReferenceList:getItems", { items, base, editState }, this.state, this.props);
    let objs = items.map((item) => {
      let baseObj = base ? base.find((obj) => nonCSCompare(obj.identity.name, item.identity.name)) : null
      return this._getItem(item, baseObj, editState)
    })

    if (base && this.state.selectingObjects && this.state.objects) {
      let baseObjs = this.state.objects
        .filter((obj) => items.findIndex((item) => nonCSCompare(obj.identity.name, item.identity.name)) === -1)
        .map((obj) => {
          return this._getItem(null, obj, editState)
        })
      objs = objs.concat(baseObjs)
      //logger.info("VersionedObjectReferenceList:getItems:BASE", baseObjs, objs);
    }
    //logger.info("VersionedObjectReferenceList:getItems", { items, base, editState, objs }, this.state, this.props);
    return objs.sort(identitySort)
  }

  /**
   * Define rendering of tail buttons
   * @param {number} editState - edit state on dialog
   * @return - array of rendered buttons
   */
  getTailButtons(editState) {
    const canAdd = editState > editableState.EDITABLE
    if (!canAdd) {
      return [
        {
          label: getTailButtonLabel('View'),
          onClick: (obj, index) => this.onViewItem(obj, index)
        },
        { label: '', onClick: (obj, index) => this.onViewItem(obj, index) }
      ]
    }

    return (obj) => {
      let buttons = []
      //logger.info("VersionedObjectReferenceList:getTailButtons", obj, this.props, this.state);
      if (!obj || !obj._item) {
        return buttons
      }

      // If we are in Instance mode, we only allowed to browse this list as it is defined
      // in deployment with modification allowed only in layout instance options.
      if (this.props.isInstance) {
        buttons.push({
          label: getTailButtonLabel('Edit'),
          onClick: (obj, index) => this.onEditItem(obj, index)
        })
        return buttons
      }

      let currentDeployment = this.props.majorObject.version
      if (obj.deployed && obj.reference && versionCompare(obj.deployed, currentDeployment)) {
        // We do select to deploy this application in this deployment already.
        buttons.push({
          label: getTailButtonLabel('Unselect'),
          onClick: (obj, index) =>
            this.onDeploymentChange(false, obj._item.obj ? obj._item.obj : obj._item.base, editState)
        })
        buttons.push({
          label: getTailButtonLabel('Edit'),
          onClick: (obj, index) => this.onEditItem(obj, index)
        })
      } else if (obj.deployed && obj.reference) {
        // Object deployed in previous deployment.
        buttons.push({
          label: getTailButtonLabel('Delete'),
          onClick: (obj, index) => this.onDeploymentCancel(obj._item.obj ? obj._item.obj : obj._item.base, editState)
        })
        buttons.push({
          label: getTailButtonLabel('Edit'),
          onClick: (obj, index) => this.onEditItem(obj, index)
        })
      } else if (obj.deployed && !obj.reference && versionCompare(obj.deployed, currentDeployment)) {
        // Object deployed as unselected but not selected for current deployment.
        buttons.push({
          label: getTailButtonLabel('Unselect'),
          onClick: (obj, index) =>
            this.onDeploymentChange(false, obj._item.obj ? obj._item.obj : obj._item.base, editState)
        })
        buttons.push({
          label: getTailButtonLabel('View'),
          onClick: (obj, index) => this.onViewItem(obj, index)
        })
      } else if (obj._item.base.versionOptions && obj._item.base.versionOptions.length > 0) {
        // New application to select for package deployment or removed application.
        buttons.push({
          label: getTailButtonLabel('Select'),
          onClick: (obj, index) =>
            this.onDeploymentChange(true, obj._item.obj ? obj._item.obj : obj._item.base, editState)
        })
        buttons.push({
          label: getTailButtonLabel('View'),
          onClick: (obj, index) => this.onViewItem(obj, index)
        })
      }
      return buttons
    }
  }

  /**
   * Define rendering of top buttons
   * @param {number} editState - edit state on dialog
   * @return - array of rendered buttons
   */
  getTopButtons(editState) {
    let canAdd = editState > editableState.EDITABLE

    // If we are in Instance mode, we only allowed to browse this list as it is defined
    // in deployment with modification allowed only in layout instance options.
    if (this.props.isInstance) {
      canAdd = false
    }

    return canAdd
      ? [
          {
            label: (this.state.selectingObjects ? '-' : '+') + ' Select',
            onClick: () => this.onAddItem()
          }
        ]
      : []
  }

  /**
   * Define rendering of bottom buttons
   * @param {number} editState - edit state on dialog
   * @return - array of rendered buttons
   */
  getBottomButtons(editState) {
    let canAdd = editState > editableState.EDITABLE
    let type = this.props.type

    // If we are in Instance mode, we only allowed to browse this list as it is defined
    // in deployment with modification allowed only in layout instance options.
    if (this.props.isInstance) {
      canAdd = false
    }

    return canAdd
      ? [
          {
            label: (this.state.selectingObjects ? '-' : '+') + ' Select ' + type,
            onClick: () => this.onAddItem()
          },
          {
            label: '- Remove all',
            onClick: () => this.onRemoveAll()
          }
        ]
      : []
  }

  /**
   * Render dialog for item entry
   */
  renderItemDialog(editState, item, base, isEditable) {
    const stype = singularTypeForms.get(this.props.type)
    logger.info(
      'VersionedObjectReferenceList:renderItemDialog',
      editState,
      item,
      base,
      isEditable,
      this.props,
      this.state
    )
    return (
      <VersionedObjectReferenceDialog
        appState={this.props.appState}
        actions={this.props.actions}
        modalTitle={(isEditable > editableState.EDITABLE ? 'Edit ' : 'View ') + stype + ' reference'}
        isEditable={isEditable}
        isVisible
        isInstance={this.props.isInstance}
        majorObject={this.props.majorObject}
        objectReference={item}
        objectType={stype}
        onClose={this.closeItem}
        onSave={(obj, closeDialog, onSent) => this.saveItem(obj, base, closeDialog, onSent)}
      />
    )
  }
}

VersionedObjectReferenceList.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isEditable: PropTypes.number, // State of editing in dialog
  isInstance: PropTypes.boolean, // Mark of editing only layout instance from environment
  majorObject: PropTypes.object.isRequired, // Major object we work against
  type: PropTypes.string.isRequired, // Type of object
  items: PropTypes.object.isRequired, // Resource object array
  base: PropTypes.object // Resource object array
}
