/**
 * Created by kascode on 28.04.17.
 */
import PropTypes from 'prop-types'
import * as React from 'react'
import './Badge.scss'

export const Badge = ({ color, className, onClick, children }) => {
  return (
    <span className={'Badge' + (color ? ' Badge_' + color : '') + (className ? ' ' + className : '')} onClick={onClick}>
      {children}
    </span>
  )
}

Badge.propTypes = {
  color: PropTypes.oneOf(['green', 'red', 'blue', 'yellow', 'violet', 'gray_outline', 'clear']),
  className: PropTypes.string
}
