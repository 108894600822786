/**
 * Created by
 * Created by sashaberger on 01.10.2017.
 *
 * Window for creation of new draft for object.
 *
 */
import PropTypes from 'prop-types'
import React from 'react'
import { getVersionOptions } from '../../helpers/index'
import logger from '../../helpers/logger'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './DraftDialog.scss'

export class DraftDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      versions: getVersionOptions(this.props.currentVersion)
    })
  }

  getObjectType() {
    return 'version'
  }

  /**
   * Returns title for dialog
   */
  getTitle() {
    let button = 'Create'
    let title =
      button +
      ' new version for ' +
      (this.props.majorObject.settings ? 'settings of ' : '') +
      this.props.majorObject.type +
      ' "' +
      this.props.majorObject.identity.name +
      '"'

    return title
  }

  /**
   * Returns text for confitmation
   */
  getText() {
    return 'Create'
  }

  /**
   * Returns minimum height for dialog
   */
  getMinHeight() {
    return 300
  }

  /*
   * Check property before save
   * @param {object} settings - new state of object
   * @returns {string} - string with error text
   */
  checkInputErrors(message) {
    let inputError = ''
    if (!this.state.version.version) {
      inputError += 'Please select version to create!'
    }
    return inputError
  }

  onVersionChange(version) {
    //console.log("DraftDialog:onVersionChange", version);
    this.Change({ version: version })
  }

  /**
   * Render scrollable area of dialog
   * @return
   */
  renderEditor = (editState) => {
    logger.info('DraftDialog:renderData', this.state, this.props, editState)
    return (
      <div className="DraftDialog">
        <div className="DraftDialog__text">
          <div className="DraftDialog__label">Select version to create:</div>
          <div className="row ">
            {this.state.versions.map((version, i) => (
              <div className="col-xs-8" key={i}>
                <label className="DraftDialog__name">{version.name + ':'}</label>
                <input
                  type="radio"
                  className="Input_radio"
                  id={'version_' + version.label}
                  checked={this.state.version.version === version.label}
                  onClick={() => this.onVersionChange(version.label)}
                />
                <label htmlFor={'version_' + version.label}>{version.label}</label>
                <div className="DraftDialog__description">{version.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

DraftDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  currentVersion: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
