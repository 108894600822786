/* eslint-disable array-callback-return */
/**
 * Created by mailf on 22.08.2016.
 */
import React from 'react'
import { dequal } from 'dequal'

import { API, getObjectsWithConnected, getRequestFromPath, idAPI, sendObjectNew } from '../../helpers/api'
import {
  columnsToType,
  editableState,
  fieldTypeToString,
  getFullUri,
  pathByType,
  pluralTypeForms
} from '../../helpers/index'
import FilteredTabbedTable from '../FilteredTabbedTable/FilteredTabbedTable'
import { LayoutDialog } from '../LayoutDialog/LayoutDialog'
import { MajorObjectVersioned } from '../MajorObjectVersioned/MajorObjectVersioned'
import { Messages } from '../Messages/Messages'
import { StructureTable } from '../NewTable/StructureTable'
import { ObjectHistoryTable } from '../ObjectHistoryTable/ObjectHistoryTable'
import { InterfaceExampleDialog } from './ExampleDialog'
import './interface.scss'
import { OperationDialog } from './OperationDialog'
import { ParameterDialog } from './ParameterDialog'
import { ResponseDialog } from './ResponseDialog'
import { ThButton } from './ThButton'
import { getTailButtonLabel } from '../../helpers/helperComponents'
import { Tab } from '../FilteredTabbedTable/TabType'

export class Interface extends MajorObjectVersioned {
  constructor(props) {
    super(props)

    // @ts-ignore
    this.state = {
      editingChild: null,
      editingChildMode: editableState.EDITING,
      editingChildType: '',
      selectedExamples: {},
      examplesRunResults: {}
    }

    // @ts-ignore
    this.state.objectType = 'interface'
    // @ts-ignore
    this.state.objectName = this.getObjectName(this.state.objectType)
  }

  componentWillReceiveProps(props) {
    if (!this.props.userState.profile && props.userState.profile) {
      this.loadUserRole(props)
    }

    //console.log("Interface:componentWillReceiveProps", objectType, props, this.props);

    if (
      !props.majorObject &&
      this.props.majorObject &&
      this.props.majorObject.identity.id !== 0 &&
      props.location.pathname !== this.props.location?.pathname
    ) {
      const newState = {
        objectType: 'interface',
        objectName: props.match.params[this.getObjectType(props) + 'Name'],
        recordsToDelete: null
      }

      this.setState(newState, () => {
        getObjectsWithConnected(
          this.getDataRequestQuery(props),
          this.state.objectType,
          this.state.objectName,
          this.props.actions
        )
      })
    }
  }

  componentDidMount() {
    const inter = this.getObject()

    //console.log("Interface:componentDidMount", inter, this.props);

    if (inter && inter.identity.id) {
      this.props.actions.updateMajorObject(inter.identity.id, pluralTypeForms.get(this.state.objectType), {
        isFetching: true
      })
    }

    //this.clearTabsContentLoaded(['parameters']);          // show Loader for object table
    getObjectsWithConnected(
      this.getDataRequestQuery(this.props),
      this.state.objectType,
      this.state.objectName,
      this.props.actions
    )
  }

  /**
   * Save interface with new or updated parameter
   * @param {object} parameter - new or updated parameter
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {funcion} [onSent] - call back function to report status of update
   */

  //? added changes in operations
  saveParameter = (parameter, closeDialog, onSent) => {
    //console.log("Interface:saveParameter", parameter, closeDialog);
    const newInterface = Object.assign({}, { identity: this.getObject().identity, parameters: [parameter],operations:this.getObject().operations })
    const paramIdentity = parameter.field.identity

    newInterface.operations.map(oper => {
      oper.parameters.map(element => {
        if (element.id === paramIdentity.id) {
          element.name = paramIdentity.name
          element.description = paramIdentity.description
        }
      })
    });
    this.patchInterface(newInterface, closeDialog, onSent)
  }

  /**
   * Delete  parameter from interface
   * @param {id} parameter - parameter to delete
   */
  //? added changes in operations
  deleteParameter = ({ name, id }) => {
    //console.log("Interface:deleteParameter", parameterId);
    const newInterface = Object.assign({}, this.getObject())
    this.setState({
      deleteChildObject: {
        obj: newInterface.parameters.filter((param) => param.field.identity.id === id || param.field.identity.name === name.name)[0].field,
        delete: () => {
          newInterface.parameters = newInterface.parameters.filter((param) => param.field.identity.id !== id || param.field.identity.name !== name.name)
          newInterface.operations = newInterface.operations.map((oper) => {
            oper.parameters = oper.parameters
              ? oper.parameters.filter((param) => param.id !== id || param.name !== name.name)
              : oper.parameters
            return oper
          })
          this.putInterface(newInterface)
          this.setState({ deleteChildObject: null })
        }
      }
    })
  }

  /**
   * Save interface with new or updated response
   * @param {object} response - new or updated response
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {function} [onSent] - call back function to report status of update
   */
  //? added changes in operations
  saveResponse = (response, closeDialog, onSent) => {
    //console.log("Interface:saveResponse", response,  closeDialog);
    const respIdentity = response.field.identity
    const newInterface = Object.assign({}, { identity: this.getObject().identity, responses: [response],operations:this.getObject().operations })
    newInterface.operations.map(oper => {
      oper.responses.map(element => {
        if (element.id === respIdentity.id) {
          element.name = respIdentity.name
          element.description = respIdentity.description
        }
      })
    });

    this.patchInterface(newInterface, closeDialog, onSent)
  }

  /**
   * Delete  response from interface
   * @param {id} response - response to delete
   */

  // ? added check by name
  deleteResponse = ({id,name}) => {
    //console.log("Interface:deleteResponse", responseId);
    const newInterface = Object.assign({}, this.getObject())
    this.setState({
      deleteChildObject: {
        obj: newInterface.responses.filter((resp) => resp.field.identity.id === id || resp.field.identity.name === name.name)[0].field,
        delete: () => {
          newInterface.responses = newInterface.responses.filter((resp) => resp.field.identity.id !== id || resp.field.identity.name !== name.name)
          newInterface.operations = newInterface.operations.map((oper) => {
            oper.responses = oper.responses ? oper.responses.filter((resp) => resp.id !== id||resp.name !== name.name) : oper.responses
            return oper
          })

          this.putInterface(newInterface)
          this.setState({ deleteChildObject: null })
        }
      }
    })
  }

  /**
   * Save interface with new or updated layout
   * @param {object} layout
   * @param {boolean} [closeDialog]
   * @param {function} [onSent]
   */
  saveLayout = (layout, closeDialog, onSent) => {
    const newInterface = Object.assign(
      {},
      {
        identity: this.props.majorObject.identity,
        layouts: [layout]
      }
    )

    this.patchInterface(newInterface, closeDialog, onSent)
  }

  deleteLayout = (layoutId) => {
    //console.log("Interface::deleteLayout ", layoutId);
    const newInterface = Object.assign({}, this.props.majorObject)

    this.setState({
      deleteChildObject: {
        obj: newInterface.layouts.filter((lt) => lt.identity.id === layoutId)[0],
        delete: () => {
          newInterface.layouts = newInterface.layouts.filter((lt) => lt.identity.id !== layoutId)
          //console.log("Interface::deleteLayout new", newInterface.layouts);

          this.putInterface(newInterface)
          this.setState({ deleteChildObject: null })
        }
      }
    })
  }

  /**
   * Save interface with new or updated operation
   * @param {object} operation - new or updated operation
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {function} [onSent] - call back function to report status of update
   */
  saveOperation = (operation, closeDialog, onSent) => {
    //console.log("Interface:saveOperation", operation, closeDialog);

    const newInterface = Object.assign({}, { identity: this.getObject().identity, operations: [operation] })

    this.patchInterface(newInterface, closeDialog, onSent)
  }

  /**
   * Delete operation from interface
   * @param {id} operationId - operation to delete
   */
  deleteOperation = (operationId) => {
    //console.log("Interface:deleteOperation", operationId);

    const newInterface = Object.assign({}, this.getObject())

    this.setState({
      deleteChildObject: {
        obj: newInterface.operations.filter((oper) => oper.identity.id === operationId)[0],
        delete: () => {
          newInterface.operations = newInterface.operations.filter((oper) => oper.identity.id !== operationId)

          this.putInterface(newInterface)
          this.setState({ deleteChildObject: null })
        }
      }
    })
  }

  saveExample = (example, closeDialog, onSent) => {
    const newInterface = Object.assign(
      {},
      {
        identity: this.getObject().identity,
        examples: [example]
      }
    )

    this.patchInterface(newInterface, closeDialog, onSent)
  }

  deleteExample = (exampleName) => {
    const inter = this.getObject()
    //console.log("Dataset:deleteExample", exampleName, dataset.data);
    const newInter = Object.assign({}, inter, {
      examples: inter.examples.filter(
        (r) => (r.identity && r.identity.name !== exampleName) || (!r.identity && exampleName !== '')
      )
    })
    this.putInterface(newInter)
  }

  showDeleteExample = (exampleName) => {
    this.setState({
      deleteChildObject: {
        objectType: 'example',
        obj: { identity: { name: exampleName } },
        delete: () => {
          this.setState({ deleteChildObject: false })
          this.deleteExample(exampleName)
        }
      }
    })
  }

  /**
   * Save interface update using patch action
   * @param {object} newInterface - new interface with changes only
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {function} [onSent] - call back function to report status of update
   */
  patchInterface = (newInterface, closeDialog?, onSent?) => {
    return sendObjectNew(
      idAPI.interfaces(newInterface.identity.id),
      'patch',
      this.props.actions,
      this.getObject(),
      newInterface
    ).then(
      () => {
        if (onSent) onSent(closeDialog)
      },
      (err) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, err)
      }
    )
  }

  /**
   * Send new state of interface by using put action
   * @param {object} newInterface - new interface with all data
   * @param {boolean} [closeDialog] - do we need to close dialog
   * @param {function} [onSent] - call back function to report status of update
   */
  putInterface = (newInterface, closeDialog?, onSent?) => {
    //console.log("Interface:sendInterface", newInterface);

    return sendObjectNew(idAPI.interfaces(newInterface.identity.id), 'put', this.props.actions, newInterface).then(
      () => {
        if (onSent) onSent(closeDialog)
      },
      (err) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, err)
      }
    )
  }

  sendInterface = (newInterface, closeDialog, onSent) => {
    return sendObjectNew(
      idAPI.interfaces(newInterface.identity.id),
      'put',
      this.props.actions,
      this.getObject(),
      newInterface
    ).then(
      () => {
        if (onSent) onSent(closeDialog)
      },
      (err) => {
        this.props.actions.setError(null)
        if (onSent) onSent(closeDialog, err)
      }
    )
  }

  runSelectedExamples = () => {
    const selectedExamples = this.getObject().examples.filter(
      (example) => this.state.selectedExamples[example.identity.id]
    )

    console.log('selectedExamples', selectedExamples)

    const requestObjects = this.runExamples(selectedExamples)

    requestObjects.map((requestObject) => {
      requestObject.runner
        .then((response) => {
          return response.json()
        })
        .then((jsonBody) => {
          const example = selectedExamples.find((example) => example.identity.id === requestObject.id)
          const response = example.response

          this.setState({
            ...this.state,
            examplesRunResults: {
              ...this.state.examplesRunResults,
              [requestObject.id]: {
                status: dequal(jsonBody, response.body) ? 'success' : 'failure',
                body: jsonBody,
                showDiff: false
              }
            }
          })
        })
    })
  }

  runExamples = (examples) =>
    examples.map((example) => {
      return {
        id: example.identity.id,
        runner: this.runExample(example)
      }
    })

  runExample = (example) => {
    const intfc = this.getObject()
    const method = example.operation.action
    const headers = new Headers()
    let query = '?'
    let exampleTemplateString = intfc.path // string contains variable placeholders in format '%varname%'
    const body = {}

    if (example.parameters.length) {
      const parameters = example.parameters.map((parameter) => {
        const fullParameter = intfc.parameters.find(
          (iParameter) => iParameter.field.identity.name === parameter.identity.name
        )
        fullParameter.field.value = parameter.value
        return fullParameter
      })
      parameters.forEach((param) => {
        let header

        switch (param.location) {
          case 'Path':
            exampleTemplateString = exampleTemplateString
              .toLowerCase()
              .replace(`%${param.field.identity.name.toLowerCase()}%`, param.field.value)
            break
          case 'Header':
            // If parameter location is Header then we expect header type and header body to be separated by '\n'
            header = param.field.value.split('\n')
            if (header.length === 2) {
              headers.append(header[0], header[1])
            }
            break
          case 'Query':
            query += `${param.field.identity.name}=${param.field.value}&`
            break
          case 'Body':
            body[param.field.identity.name] = param.field.value
            break
          default:
            console.warn('Example parameter with wrong location', param)
        }
      })
    }

    const fetchParams = {
      method,
      headers,
      mode: 'cors'
    }

    // @ts-ignore
    return fetch(exampleTemplateString + (query !== '?' ? query : ''), fetchParams)
  }

  closeEditDialog = () => {
    this.setState({
      editingChild: null,
      editingChildMode: editableState.EDITING
    })
  }

  getInterfaceApiRequest = (props = this.props) => {
    return API.organizations(props.match.params.organizationName)
      .systems(props.match.params.systemName)
      .applications(props.match.params.applicationName)
      .interfaces(props.match.params.interfaceName)
  }

  getDataRequestQuery(props, version?) {
    const intrfcApiEndpoint = version
      ? this.getInterfaceApiRequest(props).versions(version)
      : this.getInterfaceApiRequest(props)

    const connectedObjectsEndpoint = version ? this.getInterfaceApiRequest(props) : intrfcApiEndpoint

    return {
      endpoint: this.getInterfaceApiRequest(props),
      objectRequest: props.match.params.versionName
        ? intrfcApiEndpoint.versions(props.match.params.versionName)
        : intrfcApiEndpoint,
      objectRequestCallback: (obj, errorStatus) => {
        this.setState({ errorStatus: errorStatus })
        return true
      },
      connectedObjectsRequests: [
        {
          objectType: 'version',
          isObjectProperty: true,
          majorObjectType: 'interface',
          propertyName: 'versions',
          request: connectedObjectsEndpoint.versions(),
          connectedObjectsRequestCallback: () => {
            if (this.state.loadingCheckpoints) {
              this.requestCheckpoints().then(() => {
                this.setState({ loadingCheckpoints: false })
              })
            }
          }
        },
        {
          objectType: 'user',
          majorObjectType: 'interface',
          isObjectProperty: true,
          propertyName: 'users',
          request: connectedObjectsEndpoint.users(),
          connectedObjectsRequestCallback: this.checkUserRole(props)
        }
      ]
    }
  }

  toggleExampleSelection = (exampleId) => {
    this.setState({
      ...this.state,
      selectedExamples: Object.assign({}, this.state.selectedExamples, {
        [exampleId]: !this.state.selectedExamples[exampleId]
      })
    })
  }

  toggleExampleResult = (exampleId) => {
    console.log('toggleExampleResult', exampleId)
    this.setState({
      ...this.state,
      examplesRunResults: {
        ...this.state.examplesRunResults,
        [exampleId]: {
          ...this.state.examplesRunResults[exampleId],
          showDiff: !this.state.examplesRunResults[exampleId].showDiff
        }
      }
    })
  }

  pasteChild = (params, closeDialog, onSent) => {
    const inter = this.getObject()

    let newName = params.objectName
    let newObject = JSON.parse(localStorage.clipboard)

    // eslint-disable-next-line no-unused-vars
    const req = getRequestFromPath(getFullUri(this.getObject()))

    if (localStorage.clipboardType === 'parameter') {
      newObject.field.identity.name = newName
      newObject.field.identity.id = ''

      const newParams = this.getObject().parameters.slice()
      newParams.push(newObject)

      this.patchInterface({ identity: inter.identity, parameters: newParams }, closeDialog, onSent)
    } else if (localStorage.clipboardType === 'response') {
      newObject.field.identity.name = newName
      newObject.field.identity.id = ''

      const newResponses = this.getObject().responses.slice()
      newResponses.push(newObject)

      this.patchInterface({ identity: inter.identity, responses: newResponses }, closeDialog, onSent)
    } else if (localStorage.clipboardType === 'operation') {
      newObject.identity.name = newName
      newObject.identity.id = ''

      const newOperations = this.getObject().operations.slice()
      newOperations.push(newObject)

      this.patchInterface({ identity: inter.identity, operations: newOperations }, closeDialog, onSent)
    } else if (localStorage.clipboardType === 'layout') {
      newObject.identity.name = newName
      newObject.identity.id = ''

      const newLayouts = this.getObject().layouts.slice()
      newLayouts.push(newObject)

      this.patchInterface({ identity: inter.identity, layouts: newLayouts }, closeDialog, onSent)
    }
  }

  getTabsProps = (): Tab[] => {
    const inter = this.getObject()
    const isEditable = this.checkEditable()
    //console.log("Interface:getTabsProps", inter);

    return [
      {
        tableComponent: StructureTable,
        title: 'parameters',
        filters: [
          { name: '', width: 42 + 28 + 200 - 210 },
          { name: 'search', width: 350 },
          { name: '', width: 75 },
          { name: 'location', width: 110 } /*, 'type'*/
        ],
        columns: [
          {
            displayName: 'Ord.',
            name: 'order',
            type: columnsToType.getType('id'),
            frozen: true,
            width: 32 + 28 - 25,
            hiddenInChildTable: true
          },
          {
            displayName: ' ',
            name: 'expandButton',
            type: { name: 'data' },
            frozen: true,
            width: 25
          },
          {
            name: 'name',
            displayName: 'Parameter name',
            type: columnsToType.getType('minorObjectName'),
            onCellClick: (value, rowIndex) => {
              this.setState({
                editingChild: inter.parameters.find(
                  (parameters) => parameters.field.identity.name === value.identity.name
                ),
                editingChildMode: editableState.EDITABLE,
                editingChildType: 'parameter'
              })
            },
            width: 200
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 606 - 20
          },
          {
            name: 'location',
            type: columnsToType.getType('string'),
            width: 80
          },
          {
            name: 'required',
            displayName: 'Req.',
            type: columnsToType.getType('required'),
            frozen: true,
            width: 40
          },
          {
            name: 'type',
            type: columnsToType.getType('typeReference'),
            frozen: true,
            width: 236
          },

          //!we don't understand what it's for
          // {
          //   name: 'examples',
          //   type: columnsToType.getType('list'),
          //   width: 150
          // }
        ],
        //@ts-ignore
        nextLvlTail:[
              {
              label: getTailButtonLabel('View'),
            onClick: (obj) => {
              const { expandButton, expandedBody, hasExpandedBody,location,...newObj } = obj;
              newObj.type = newObj.type.displayType;
              const res = Object.assign({}, { field: newObj }, { body: location })
              this.setState({
                    //@ts-ignore
                    editingChild: res,
                    editingChildType: 'parameter',
                    editingChildMode: editableState.BROWSABLE
                  })
              }
            }
        ],
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (value) => {
                  this.setState({
                    editingChild: inter.parameters.reduce(
                      (p, c) => (c.field.identity.name === value.identity.name ? c : p),
                      null
                    ),
                    editingChildType: 'parameter',
                    editingChildMode: editableState.BROWSABLE
                  })
                }
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (value) => {
                  this.setState({
                    editingChild: inter.parameters.reduce(
                      (p, c) => (c.field.identity.name === value.identity.name ? c : p),
                      null
                    ),
                    editingChildType: 'parameter',
                    editingChildMode: editableState.EDITING
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (row) => this.deleteParameter(row)
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add parameter',
                onClick: () => {
                  this.setState({
                    editingChild: false,
                    editingChildType: 'parameter'
                  })
                }
              }
            ],

        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  this.setState({
                    editingChild: false,
                    editingChildType: 'parameter'
                  })
                }
              }
            ],

        contextMenuButtons: [
          {
            label: 'Copy parameter',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              //console.log("Interface::Copy parameter", data);
              const param = inter.parameters.reduce(
                (p, c) => (c.field.identity.name === data.identity.name ? c : p),
                null
              )
              //console.log("Found parameter", param);
              localStorage.clipboard = JSON.stringify(param)
              localStorage.clipboardType = 'parameter'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste parameter',
                  data: { action: 'paste' },
                  onClick: () => {
                    //console.log("Paste dataset");

                    if (!localStorage.clipboard) {
                      alert('No parameter copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'parameter') {
                      alert('No parameter copied')
                      return
                    }

                    this.setState({
                      pastingChild: true,
                      pastingChildName: JSON.parse(localStorage.clipboard).field.identity.name
                    })
                  },
                  // @ts-ignore
                  showInBottom: true
                },
                {
                  label: 'Reorder parameters',
                  data: { action: 'reorder' },
                  onClick: () => {
                    const inter = this.getObject()
                    let newParameters = inter.parameters
                      .sort((a, b) => (a.field.order > b.field.order ? 1 : b.field.order > a.field.order ? -1 : 0))
                      .map((param, index) => {
                        return Object.assign({}, param, {
                          field: Object.assign({}, param.field, {
                            order: index + 1
                          })
                        })
                      })
                    this.patchInterface({
                      identity: inter.identity,
                      parameters: newParameters
                    })
                  }
                },
                {
                  label: 'Reorder by 10 step',
                  data: { action: 'reorder' },
                  onClick: () => {
                    const inter = this.getObject()

                    let delta = 1
                    let newParameters = inter.parameters
                      .sort((a, b) => (a.field.order > b.field.order ? 1 : b.field.order > a.field.order ? -1 : 0))
                      .map((param, index) => {
                        return Object.assign({}, param, {
                          field: Object.assign({}, param.field, {
                            order: index === 0 ? delta : (delta += 10)
                          })
                        })
                      })
                    this.patchInterface({
                      identity: inter.identity,
                      parameters: newParameters
                    })
                  }
                }
              ]
        )
      },
      {
        tableComponent: StructureTable,
        title: 'responses',
        filters: [
          { name: '', width: 42 + 28 + 200 - 210 },
          { name: 'search', width: 350 },
          { name: '', width: 70 },
          { name: 'code', width: 95 }
        ],
        columns: [
          {
            displayName: 'Ord.',
            name: 'order',
            type: columnsToType.getType('id'),
            frozen: true,
            width: 32
          },
          {
            displayName: ' ',
            name: 'expandButton',
            type: { name: 'data' },
            frozen: true,
            width: 28
          },
          {
            name: 'name',
            displayName: 'Response name',
            type: columnsToType.getType('minorObjectName'),
            onCellClick: (value, rowIndex) => {
              this.setState({
                editingChild: inter.responses.find((resp) => resp.field.identity.name === value.identity.name),
                editingChildMode: editableState.EDITABLE,
                editingChildType: 'response'
              })
            },
            width: 200
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 606
          },
          {
            name: 'code',
            type: columnsToType.getType('string'),
            width: 80
          },
          {
            name: 'type',
            type: columnsToType.getType('type'),
            frozen: true,
            width: 236
          },
                    //!we don't understand what it's for
          // {
          //   name: 'examples',
          //   type: columnsToType.getType('list'),
          //   width: 170
          // }
        ],
        //@ts-ignore
        nextLvlTail:[
              {
              label: getTailButtonLabel('View'),
            onClick: (obj) => {
              const { expandButton, expandedBody, hasExpandedBody,code,...newObj } = obj;
              newObj.type = newObj.type.displayType;
              const res = Object.assign({}, { field: newObj }, { code: code })
              this.setState({
                    //@ts-ignore
                    editingChild: res,
                   editingChildMode: editableState.BROWSABLE,
                    editingChildType: 'response'
                  })
              }
            }
        ],

        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (value) => {
                  this.setState({
                    editingChild: inter.responses.find((resp) => resp.field.identity.name === value.identity.name),
                    editingChildMode: editableState.BROWSABLE,
                    editingChildType: 'response'
                  })
                }
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (value) => {
                  this.setState({
                    editingChild: inter.responses.find((resp) => resp.field.identity.name === value.identity.name),
                    editingChildMode: editableState.EDITING,
                    editingChildType: 'response'
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (value) => {
                  this.deleteResponse(value)
                }
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add response',
                onClick: () => {
                  this.setState({
                    editingChild: false,
                    editingChildType: 'response'
                  })
                }
              }
            ],

        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  this.setState({
                    editingChild: false,
                    editingChildType: 'response'
                  })
                }
              }
            ],

        contextMenuButtons: [
          {
            label: 'Copy response',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              //console.log("Interface::Copy response", data);
              const param = inter.responses.reduce(
                (p, c) => (c.field.identity.name === data.identity.name ? c : p),
                null
              )
              //console.log("Found response", param);
              localStorage.clipboard = JSON.stringify(param)
              localStorage.clipboardType = 'response'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste response',
                  data: { action: 'paste' },
                  onClick: () => {
                    //console.log("Paste dataset");

                    if (!localStorage.clipboard) {
                      alert('No response copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'response') {
                      alert('No response copied')
                      return
                    }

                    this.setState({
                      pastingChild: true,
                      pastingChildName: JSON.parse(localStorage.clipboard).field.identity.name
                    })
                  },
                  // @ts-ignore
                  showInBottom: true
                },
                {
                  label: 'Reorder responses',
                  data: { action: 'reorder' },
                  onClick: () => {
                    const inter = this.getObject()
                    let newResponses = inter.responses
                      .sort((a, b) => (a.field.order > b.field.order ? 1 : b.field.order > a.field.order ? -1 : 0))
                      .map((resp, index) => {
                        return Object.assign({}, resp, {
                          field: Object.assign({}, resp.field, {
                            order: index + 1
                          })
                        })
                      })

                    this.patchInterface({
                      identity: inter.identity,
                      responses: newResponses
                    })
                  }
                },
                {
                  label: 'Reorder by 10 step',
                  data: { action: 'reorder' },
                  onClick: () => {
                    const inter = this.getObject()

                    let delta = 1
                    let newResponses = inter.responses
                      .sort((a, b) => (a.field.order > b.field.order ? 1 : b.field.order > a.field.order ? -1 : 0))
                      .map((resp, index) => {
                        return Object.assign({}, resp, {
                          field: Object.assign({}, resp.field, {
                            order: index === 0 ? delta : (delta += 10)
                          })
                        })
                      })

                    this.patchInterface({
                      identity: inter.identity,
                      responses: newResponses
                    })
                  }
                }
              ]
        )
      },
      {
        tableComponent: StructureTable,
        title: 'operations',
        filters: [
          { name: '', width: 200 - 200 },
          { name: 'search', width: 300 },
          { name: '', width: 50 },
          { name: 'dep', width: 70 },
          { name: 'action', width: 80 }
        ],
        columns: [
          {
            name: 'name',
            displayName: 'Operation name',
            type: columnsToType.getType('minorObjectName'),
            onCellClick: (value, rowIndex) => {
              this.setState({
                editingChild: inter.operations.find((op) => op.identity.name === value.identity.name),
                editingChildMode: editableState.EDITABLE,
                editingChildType: 'operation'
              })
            },
            width: 205
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 364
          },
          {
            name: 'dep',
            displayName: 'dep',
            type: columnsToType.getType('boolean'),
            width: 60
          },
          {
            name: 'action',
            type: columnsToType.getType('httpMethod'),
            width: 80
          },
          {
            name: 'parameters',
            type: columnsToType.getType('list'),
            width: 237
          },
          {
            name: 'responses',
            type: columnsToType.getType('list'),
            width: 237
          }
        ],
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (value) => {
                  this.setState({
                    editingChild: inter.operations.find((op) => op.identity.name === value.identity.name),
                    editingChildMode: editableState.BROWSABLE,
                    editingChildType: 'operation'
                  })
                }
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (value) => {
                  this.setState({
                    editingChild: inter.operations.find((op) => op.identity.name === value.identity.name),
                    editingChildMode: editableState.EDITING,
                    editingChildType: 'operation'
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (value) => this.deleteOperation(value.id)
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add operation',
                onClick: () => {
                  this.setState({
                    editingChild: false,
                    editingChildType: 'operation'
                  })
                }
              }
            ],
        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  this.setState({
                    editingChild: false,
                    editingChildType: 'operation'
                  })
                }
              }
            ],

        contextMenuButtons: [
          {
            label: 'Copy operation',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              //console.log("Interface::Copy operation", data);
              const param = inter.operations.reduce((p, c) => (c.identity.name === data.identity.name ? c : p), null)
              //console.log("Found operation", param);
              localStorage.clipboard = JSON.stringify(param)
              localStorage.clipboardType = 'operation'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste operation',
                  data: { action: 'paste' },
                  onClick: () => {
                    //console.log("Paste dataset");

                    if (!localStorage.clipboard) {
                      alert('No operation copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'operation') {
                      alert('No operation copied')
                      return
                    }

                    this.setState({
                      pastingChild: true,
                      pastingChildName: JSON.parse(localStorage.clipboard).identity.name
                    })
                  },
                  // @ts-ignore
                  showInBottom: true
                }
              ]
        )
      },
      {
        title: 'layouts',
        filters: [
          { name: '', width: 250 - 205 },
          { name: 'search', width: 290 },
          { name: '', width: 110 },
          { name: 'platform', width: 250 } /*, 'type'*/
        ],
        columns: [
          {
            name: 'name',
            displayName: 'Layout name',
            type: columnsToType.getType('minorObjectName'),
            onCellClick: (value, rowIndex) => {
              this.setState({
                editingChild: inter.layouts.find((layout) => layout.identity.name === value.identity.name),
                editingChildMode: editableState.EDITABLE,
                editingChildType: 'layout'
              })
            },
            width: 250
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 400
          },
          {
            name: 'platform',
            type: columnsToType.getType('string'),
            width: 250
          },
          { name: 'unit', type: columnsToType.getType('string'), width: 285 }
        ],
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (value, rowIndex) => {
                  this.setState({
                    editingChild: inter.layouts.find((layout) => layout.identity.name === value.identity.name),
                    editingChildMode: editableState.BROWSABLE,
                    editingChildType: 'layout'
                  })
                }
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (value, rowIndex) => {
                  this.setState({
                    editingChild: inter.layouts.find((layout) => layout.identity.name === value.identity.name),
                    editingChildMode: editableState.EDITING,
                    editingChildType: 'layout'
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (value) => this.deleteLayout(value.identity.id)
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add layout',
                onClick: () => {
                  this.setState({
                    editingChild: false,
                    editingChildMode: editableState.EDITING,
                    editingChildType: 'layout'
                  })
                }
              }
            ],
        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  this.setState({
                    editingChild: false,
                    editingChildType: 'layout'
                  })
                }
              }
            ],
        contextMenuButtons: [
          {
            label: 'Copy layout',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              // console.log("Dataset::Copy field", data);
              const layout = inter.layouts.reduce((p, c) => (c.identity.name === data.identity.name ? c : p), null)
              // console.log("Found field", field);
              localStorage.clipboard = JSON.stringify(layout)
              localStorage.clipboardType = 'layout'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste layout',
                  data: { action: 'paste' },
                  onClick: () => {
                    //console.log("Paste dataset");

                    if (!localStorage.clipboard) {
                      alert('No layout copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'layout') {
                      alert('No layout copied')
                      return
                    }

                    this.setState({
                      pastingChild: true,
                      pastingChildName: JSON.parse(localStorage.clipboard).identity.name
                    })
                  },
                  //@ts-ignore
                  showInBottom: true
                }
              ]
        )
      },
      {
        title: 'examples',
        filters: [
          { name: '', width: 250 - 205 + 100 },
          { name: 'search', width: 290 },
          { name: '', width: 60 },
          { name: 'format', width: 100 }
        ],
        columns: [
          {
            name: 'execute',
            displayName:
              inter.examples && inter.examples.length ? (
                <ThButton onClick={this.runSelectedExamples}>Execute</ThButton>
              ) : null,
            type: columnsToType.getType('exampleExecute'),
            width: 100
          },
          {
            name: 'name',
            displayName: 'Example name',
            type: columnsToType.getType('minorObjectName'),
            onCellClick: (value, rowIndex) => {
              this.setState({
                editingChild: inter.examples
                  ? inter.examples.find((f) => f.identity && f.identity.name === value.identity.name)
                  : null,
                editingChildType: 'example',
                editingChildMode: editableState.EDITING
              })
            },
            width: 250
          },
          {
            name: 'description',
            displayName: this.renderDescriptionColumnHeader(),
            type: this.getDescriptionType(),
            width: 350
          },
          { name: 'format', type: columnsToType.getType('string'), width: 100 },
          {
            name: 'text',
            type: columnsToType.getType('description'),
            width: 385
          }
        ],
        tailButtons: !isEditable
          ? [
              {
                label: getTailButtonLabel('View'),
                onClick: (value, rowIndex) => {
                  this.setState({
                    editingChild: inter.examples
                      ? inter.examples.find((f) => f.identity && f.identity.name === value.identity.name)
                      : null,
                    editingChildType: 'example',
                    editingChildMode: editableState.BROWSABLE
                  })
                }
              }
            ]
          : [
              {
                label: getTailButtonLabel('Edit'),
                onClick: (value, rowIndex) => {
                  this.setState({
                    editingChild: inter.examples
                      ? inter.examples.find((f) => f.identity && f.identity.name === value.identity.name)
                      : null,
                    editingChildType: 'example',
                    editingChildMode: editableState.EDITING
                  })
                }
              },
              {
                label: getTailButtonLabel('Delete'),
                onClick: (value) => this.showDeleteExample(value.identity.name)
              }
            ],
        bottomButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add example',
                onClick: () => {
                  this.setState({
                    editingChild: false,
                    editingChildMode: editableState.EDITING,
                    editingChildType: 'example'
                  })
                }
              }
            ],
        topButtons: !isEditable
          ? []
          : [
              {
                label: '+ Add',
                onClick: () => {
                  this.setState({
                    editingChild: false,
                    editingChildType: 'example'
                  })
                }
              }
            ],
        contextMenuButtons: [
          {
            label: 'Copy example',
            data: { action: 'copy' },
            onClick: (e, data, t) => {
              // console.log("Dataset::Copy field", data);
              const example = inter.examples
                ? inter.examples.reduce((p, c) => (c.identity && c.identity.name === data.identity.name ? c : p), null)
                : {}
              // console.log("Found field", field);
              localStorage.clipboard = JSON.stringify(example)
              localStorage.clipboardType = 'example'
            }
          }
        ].concat(
          !isEditable
            ? []
            : [
                {
                  label: 'Paste example',
                  data: { action: 'paste' },
                  onClick: () => {
                    //console.log("Paste dataset");

                    if (!localStorage.clipboard) {
                      alert('No example copied')
                      return
                    }
                    if (localStorage.clipboardType !== 'example') {
                      alert('No example copied')
                      return
                    }

                    this.setState({
                      pastingChild: true,
                      pastingChildName: JSON.parse(localStorage.clipboard).identity.name
                    })
                  },
                  // @ts-ignore
                  showInBottom: true
                }
              ]
        )
      },
      {
        title: 'history',
        filters: [],
        columns: [],
        tableComponent: ObjectHistoryTable,
        parentObject: inter
      }
    ]
  }

  formatForFieldTable = (inter, list) => {
    //console.log("Interface:formatForFieldTable", inter, list);
    return list
      ? list
          .map((object) => {
            let field = object.field
            if (field) {
              if (!field.type) field.type = 'String'
              if (!field.identity) return null
              const newName = {
                name: field.identity.name,
                objectType: 'field',
                path: field.path
              }
              field = Object.assign({}, field, { name: newName })
              field.description = field.identity.description
            } else {
              if (!object.identity) return null
              const newName = {
                name: object.identity.name,
                objectType: 'field',
                path: getFullUri(object)
              }
              field = Object.assign({}, { name: newName })
              field.type = 'String'
              field.identity = object.identity
              field.description = object.identity.description
            }

            for (let prop in field) {
              if (typeof field[prop] === 'number') field[prop] = field[prop] + ''
            }

            let type = field.type.toLowerCase()
            // if (type === 'structure' || type === 'enum' || type === 'reference') {
            //   //console.log("Dataset:formatForStructureTable:URI", field.reference, dataset, dataset.path);
            // }

            if (type === 'reference') {
              const appSplit = inter.object.parent.name.substring(1).split('/')
              const orgURI = pathByType(appSplit, 'organizations')

              field.reference = orgURI + '/datasets/Identity'
              //console.log("Dataset:formatForStructureTable:REFERENCE", field.reference);
            }

            field.type = {
              displayType: fieldTypeToString(field, inter),
              label: field.type,
              datasetReference: type === 'structure' || type === 'reference' ? field.reference : undefined
            }
            field.id = field.identity.id
            field.object = object
            field.location = object.location
            field.code = object.code
            field.dep = object.deprecated ? 'Yes' : ''
            field.action = object.action
            field.headers = field.headers ? field.headers.map((h) => h.identity.name) : []
            field.parameters = object.parameters ? object.parameters.map((parameter) => parameter.name) : []
            field.responses = object.responses ? object.responses.map((response) => response.name) : []
            field.examples = object.examples ? object.examples.map((ex) => ex.identity.name) : []
            field.required = !field.optional

            return field
          })
          .filter((field) => field)
          .sort((a, b) => {
            const ao = parseInt(a.order)
            const bo = parseInt(b.order)

            if (ao > bo) return 1
            else if (bo > ao) return -1

            const an = a.identity ? a.identity.name : ''
            const bn = b.identity ? b.identity.name : ''
            if (an > bn) return 1
            else if (bn > an) return -1
            return 0
          })
      : []
  }

  formatLayoutForTable = (layouts) =>
    layouts.map((layout) => {
      const newName = {
        name: layout.identity.name,
        objectType: 'layout',
        path: layout.path
      }
      layout.description = layout.identity.description
      layout = Object.assign({}, layout, {
        name: newName,
        description: layout.identity.description
      })

      return layout
    })

  formatExampleForTable = (examples) =>
    examples.map((example) => {
      const newName = {
        name: example.identity.name,
        objectType: 'example',
        path: example.path
      }
      example.description = example.identity.description
      example = Object.assign({}, example, {
        name: newName,
        description: example.identity.description,
        execute: {
          exampleId: example.identity.id,
          onClick: this.toggleExampleSelection,
          toggleDiff: this.toggleExampleResult,
          status: this.state.examplesRunResults[example.identity.id]
            ? this.state.examplesRunResults[example.identity.id].status
            : null,
          expectedJson: example.response ? example.response.body : '',
          responseJson: this.state.examplesRunResults[example.identity.id]
            ? this.state.examplesRunResults[example.identity.id].body
            : null,
          showDiff: this.state.examplesRunResults[example.identity.id]
            ? this.state.examplesRunResults[example.identity.id].showDiff
            : false
        }
      })

      return example
    })

  getData = () => {
    const inter = this.getObject()

    return this.getTabsProps().map((block) => {
      switch (block.title) {
        case 'parameters':
          block.data = this.formatForFieldTable(inter, inter.parameters)
          //console.log("Interface:getData:PARAMETERS", block.data, inter, inter.parameters);
          break
        case 'responses':
          block.data = this.formatForFieldTable(inter, inter.responses)
          //console.log("Interface:getData:RESPONSES", block.data, inter, inter.responses);
          break
        case 'operations':
          block.data = this.formatForFieldTable(inter, inter.operations)
          //console.log("Interface:getData:OPERATIONS", block.data, inter, inter.operations);
          break
        case 'layouts':
          block.data = this.formatLayoutForTable(inter.layouts || [])
          break
        case 'examples':
          block.data = this.formatExampleForTable(inter.examples || [])
          break
        case 'history':
          block.data = [
            {
              versions: inter.versions,
              checkpoints: inter.checkpoints,
              loadingCheckpoints: this.state.loadingCheckpoints,
              restore: this.restoreFromCheckpoint
            }
          ]
          return block
        default:
          block.data = []
      }
      return block
    })
  }

  restoreFromCheckpoint = (data) => {
    this.sendInterface(
      Object.assign({}, this.getObject(), data, {
        checkpoints: [],
        versions: []
      }),
      null,
      (tmp, error) => {
        if (!error) alert('Interface was restored!')
        else alert('Error')
      }
    )
  }

  renderChildDialog = () => {
    const inter = this.getObject()

    switch (this.state.editingChildType) {
      case 'parameter':
        return (
          <ParameterDialog
            modalTitle="Parameter"
            isVisible
            isEditable={this.state.editingChildMode}
            onSave={this.saveParameter}
            onClose={this.closeEditDialog}
            majorObject={inter}
            parameter={this.state.editingChild}
            actions={this.props.actions}
            editingChild={this.state.editingChild}
          />
        )
      case 'response':
        return (
          <ResponseDialog
            modalTitle="Response"
            isVisible
            isEditable={this.state.editingChildMode}
            onSave={this.saveResponse}
            onClose={this.closeEditDialog}
            majorObject={inter}
            response={this.state.editingChild}
            actions={this.props.actions}
            editingChild={this.state.editingChild}
          />
        )
      case 'operation':
        return (
          <OperationDialog
            modalTitle="Operation"
            isVisible
            isEditable={this.state.editingChildMode}
            onClose={this.closeEditDialog}
            majorObject={inter}
            operation={this.state.editingChild}
            parameters={inter.parameters}
            responses={inter.responses}
            actions={this.props.actions}
            onSave={this.saveOperation}
          />
        )
      case 'layout':
        return (
          <LayoutDialog
            appState={this.props.appState}
            actions={this.props.actions}
            isVisible
            isEditable={this.checkEditable() ? this.state.editingChildMode : 0}
            majorObject={inter}
            layout={this.state.editingChild}
            onSave={this.saveLayout}
            onClose={this.closeEditDialog}
          />
        )
      // case 'example':
      //   return (
      //     <ExampleDialog
      //       appState={this.props.appState}
      //       actions={this.props.actions}
      //       isEditable={this.state.childInEditMode ? 2 : (this.checkEditable() ? 1 : 0)}
      //       isVisible={this.state.editingChild}
      //       onSave={this.saveExample}
      //       onClose={this.closeEditDialog}
      //       example={this.state.activeMinorObject}
      //       majorObject={inter}
      //     />
      //   );
      case 'example':
        return (
          <InterfaceExampleDialog
            actions={this.props.actions}
            modalTitle="Example"
            majorObject={inter}
            isVisible
            isEditable={this.checkEditable() ? this.state.editingChildMode : 0}
            onClose={this.closeEditDialog}
            onSave={this.saveExample}
            example={this.state.editingChild}
            operations={inter.operations}
            parameters={inter.parameters}
            responses={inter.responses}
          />
        )
      default:
        return null
    }
  }

  render() {
    const inter = this.getObject()
    //console.log("Interface:render", inter, this.state, this.props);

    if (!inter || inter.isFetching) {
      return this.renderLoading()
    } else {
      return (
        <div className="MajorObject__outer">
          <section className={'MajorObjectView MajorObjectView_' + this.state.objectType}>
            {this.renderHeader()}
            {this.renderInfo()}

            <div className="MajorObjectView__connections">
              <div className="row">
                <div className="col-xs-12">
                  {this.renderBackArrowTabs()}
                  <FilteredTabbedTable
                    /*
                    // @ts-ignore */
                    tablesData={this.getData()}
                    theme={this.state.objectType}
                    tabsLoaded={this.state.tabsLoaded}
                    parentUri={getFullUri(inter)}
                    onTabChange={this.onTabChange}
                  />
                  {this.renderFinalizedMessage()}
                </div>
              </div>
            </div>
          </section>
          {this.state.editingChild !== null ? this.renderChildDialog() : null}
          {inter && inter.identity && inter.identity.id ? (
            <Messages
              ref="messages"
              appState={this.props.appState}
              userState={this.props.userState}
              actions={this.props.actions}
              object={inter}
              objectType={this.state.objectType}
              objectChain={this.props.match.params}
              currentUserRole={this.state.currentUserRole}
            />
          ) : null}
        </div>
      )
    }
  }
}

export const getInterfaceExampleRequestData = (intfc, example) => {
  const res = {
    uri: intfc.path,
    method: example.operation.action,
    headers: new Headers(),
    body: {}
  }
  let query = '?'

  if (example.parameters.length) {
    example.parameters.forEach((param) => {
      let header

      switch (param.location) {
        case 'Path':
          res.uri += `/${param.field.value}`
          break
        case 'Header':
          // If parameter location is Header then we expect header type and header body to be separated by '\n'
          header = param.field.value.split('\n')
          if (header.length === 2) {
            res.headers.append(header[0], header[1])
          }
          break
        case 'Query':
          // eslint-disable-next-line no-unused-vars
          query += `${param.field.identity.name}=${param.field.value}`
          break
        case 'Body':
          res.body[param.field.identity.name] = param.field.value
          break
        default:
          console.warn('Example parameter with wrong location', param)
      }
    })
  }

  return res
}
