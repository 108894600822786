/* eslint-disable array-callback-return */
/**
 * Created by sashaberger on 01.01.2017.
 *
 * Window for execution of message action.
 *
 */
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { getUTCTime, hasInArrayItem, nonCSCompare, pathByType } from '../../helpers/index'
import { getJsonSpecFields, getJsonSpecRecords, getPropertyOptions } from '../../helpers/md'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { Loader } from '../Loader/Loader'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import './ActionWindow.scss'

// Disable loging
//console.log = function() {}

const messageActions = getJsonSpecRecords('messageAction')
const messageActionFields = getJsonSpecFields('messageAction')
const messagePropertyFields = getJsonSpecFields('messageProperty')

export class ActionWindow extends Component {
  constructor(props) {
    super(props)
    let messageAction = this.getMessageAction(this.props.action)
    let actionProperties = this.getMessageActionValue('Properties', messageAction).split(', ')

    let messageProperties = {}
    actionProperties.map((property) => {
      messageProperties[property] = this.getMessagePropertyValue(property)
    })

    this.state = {
      issueClose: this.props.closeDefault || this.props.action === 'GrantAccess' || this.props.action === 'DenyAccess',
      issueApply: this.props.applyAllDefault || false,
      messageSent: false,
      messageAction: messageAction,
      messageText: '',
      messageProps: messageProperties,
      errorText: ''
    }
  }

  /**
   * Get current action for execution
   * @param action to find
   * @return Action array of values
   */
  getMessageAction(action) {
    let messageActionIndex = messageActions.findIndex((item) => nonCSCompare(action, item.values[0]))
    //console.log("ActionWindow:getMessageAction", action, messageActions);

    if (messageActionIndex > -1) {
      return messageActions[messageActionIndex]
    }
    return {
      index: -1,
      values: []
    }
  }

  /**
   * Get property field by name
   * @param name of the field
   * @return IField of property
   */
  getMessageProperty(name) {
    let messagePropertyIndex = messagePropertyFields.findIndex((field) => nonCSCompare(name, field.identity.name))
    //console.log("ActionWindow:getMessageProperty", name, messagePropertyFields, messagePropertyIndex);

    if (messagePropertyIndex > -1) {
      return messagePropertyFields[messagePropertyIndex]
    }
    return null
  }

  /**
   * Get current action value
   * @param name of field we need from current action
   * @return value
   */
  getMessageActionValue(name, action = this.state.messageAction) {
    const nameIndex = messageActionFields.findIndex((field) => nonCSCompare(field.identity.name, name))
    //console.log("ActionWindow:getMessageActionValue", name, nameIndex);

    return nameIndex > -1 && action.values ? action.values[nameIndex] : ''
  }

  /**
   * Get current property value
   * @param property name
   * @return current value for property
   */
  getMessagePropertyValue(name) {
    //console.log("ActionWindow:getMessagePropertyValue", name);
    if (this.props.message.properties && this.props.message.properties.length > 0) {
      // We have value in previous message
      const nameIndex = this.props.message.properties.findIndex((property) =>
        nonCSCompare(property.identity.name, name)
      )
      //console.log("ActionWindow:getMessagePropertyValue:REQUEST", this.props.message.properties, nameIndex);

      if (nameIndex > -1) return this.props.message.properties[nameIndex].value
    }

    // let's use defalt value if we have
    let field = this.getMessageProperty(name)
    if (field && field.value) return field.value

    // We have no value for property.
    return ''
  }

  componentDidMount() {
    //console.log("ActionWindow:componentDidMount", this.state.messageAction);
  }

  onClose() {
    //console.log("ActionWindow:onClose");
    this.props.close()
  }

  onSent(error) {
    console.log('ActionWindow:onSent', error, this.state.messageSent)

    this.setState({
      messageSent: false,
      errorText: error && error.response ? JSON.parse(error.response).error.message : ''
    })

    if (!error) {
      this.props.close()
    }
  }

  onMessageEdit(e) {
    //console.log("ActionWindow:onMessageEdit", e, this.state);
    this.setState({
      messageText: e.target.value,
      errorText: ''
    })
  }

  onIssueCheckbox(event) {
    //console.log("ActionWindow:onIssueCheckbox", event, this.state);
    this.setState({
      issueClose: event.target.value ? true : false,
      errorText: ''
    })
  }

  onApplyCheckbox(event) {
    //console.log("ActionWindow:onApplayCheckbox", event, this.state);
    this.setState({
      issueApply: event.target.value ? true : false,
      errorText: ''
    })
  }

  onPropertyEdit = (propertyName) => {
    //console.log("ActionWindow:onPropertyEdit", propertyName);
    return (event) => {
      let messageProperties = Object.assign({}, this.state.messageProps)
      let state = Object.assign({}, this.state)
      let value = event.target.value

      messageProperties[propertyName] = value ? value : ''
      state.messageProps = messageProperties
      state.errorText = ''

      //console.log("ActionWindow:onPropertyEdit:VALUE", propertyName, value, messageProperties);
      this.setState(state)
    }
  }

  onAction() {
    let inputError = ''
    let actionProperties = this.getMessageActionValue('Properties').split(', ')
    //console.log("ActionWindow:onAction", actionProperties, this.getMessageActionValue('Info') + "\n" + this.state.messageText);

    actionProperties.map((name) => {
      let field = this.getMessageProperty(name)
      if (
        field &&
        field.optional === false &&
        (!this.state.messageProps[name] || this.state.messageProps[name].length === 0)
      ) {
        inputError += 'Please enter ' + name.toLowerCase() + ' name! \n'
      }
      //console.log("ActionWindow:onAction:PROPERTY", name, field, this.state.messageProps[name]);
    })
    this.setState({ errorText: inputError })
    if (inputError.length > 0) {
      return
    }

    // Generate issue for action message
    let actionIssue = {
      identity: {
        id: this.props.issue.identity.id
      },
      object: {
        history: {
          updated: this.props.issue.object.history.updated
        }
      },
      status: undefined,
      resolution: undefined
    }

    // Mark issue as closed if it requested and provide resolution
    if (this.state.issueClose) {
      actionIssue.status = 'Closed'
      actionIssue.resolution = 'Fixed'

      if (this.getMessageActionValue('Status') === 'Approved') {
        if (this.getMessageActionValue('Completion') === 'True') {
          actionIssue.resolution = 'Approved'
        }
      } else if (this.getMessageActionValue('Status') === 'Finalized') {
        if (this.getMessageActionValue('Completion') === 'False') {
          actionIssue.resolution = 'Declined'
        }
      }
    }

    //console.log("ActionWindow:onAction:ISSUE", actionIssue);

    // Generate action message
    const actionMessage = {
      class: 'Message',
      type: 'Regular',
      issue: actionIssue,
      attached: this.props.message.attached,
      sender: {
        id: localStorage.currentUserId ? localStorage.currentUserId : null,
        description: this.props.userState.profile.alias
      },
      text: this.getMessageActionValue('Info') + '\n' + this.state.messageText,
      receivers: [{ identity: { id: this.props.user.profile.id } }],
      log: {}, // todo: use message.log
      time: moment(getUTCTime()).format('YYYY-MM-DDTHH:mm:ss'),
      action: this.props.action,
      properties: []
    }

    // Add properties to message
    actionProperties.map((name) => {
      let field = this.getMessageProperty(name)
      if (field && this.state.messageProps[name] && this.state.messageProps[name].length > 0) {
        actionMessage.properties.push({
          identity: { name: name },
          type: field.type ? field.type : 'String',
          reference: field.reference,
          value: this.state.messageProps[name]
        })
      }
      return null
    })

    // Add properties we inherit from original message
    this.props.message.properties
      .filter((item) => hasInArrayItem(item.identity.name, actionProperties) === false) // Filter only props that should be shown
      .map((prop) => {
        actionMessage.properties.push(prop)
      })

    // Add property for apply to all messages
    if (this.state.issueApply) {
      actionMessage.properties.push({
        identity: { name: 'ApplyToAll' },
        type: 'Bollean',
        value: true
      })
    }

    //console.log("ActionWindow:onAction:MESSAGE", actionMessage);

    // Mark it and send the message
    this.setState({ messageSent: actionMessage })
    this.props.confirm(this.props.action, this.props.issue, actionMessage, this.onSent.bind(this), null)
  }

  renderProperties = () => {
    // Properties required by action.
    let actionProperties = this.getMessageActionValue('Properties').split(', ')

    //console.log("ActionWindow:renderProperties", actionProperties, this.state);
    if (actionProperties[0].length === 0) return

    return (
      <div>
        {actionProperties.map((name, index) => {
          let field = this.getMessageProperty(name)
          if (!field) return ''

          let type = field.type ? field.type.toLowerCase() : 'string'
          let options = getPropertyOptions(name)
          let value = this.state.messageProps[name]
          let holder = 'Type ' + name.toLowerCase() + ' name'

          //console.log("ActionWindow:renderProperties:PRPPERTY", name, field, value, options);

          return (
            <div className="row ActionWindow__propertyRow" key={index}>
              <div className="col-xs-3">{name + ':'}</div>
              <div className="col-xs-9">
                <EditableEntity
                  dataProps={Object.assign({
                    onChange: this.onPropertyEdit(name),
                    placeholder: holder,
                    hideCheckBox: true,
                    multi: false
                  })}
                  dataType={{ name: type, options: options }}
                  data={value}
                  isEditable
                  inEditMode
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    let caption = this.getMessageActionValue('Caption')
    let button = caption.split(' ')[0]
    let info = this.getMessageActionValue('Info')
    let profile = this.props.user.profile
    let user = profile.firstname || profile.lastname ? profile.firstname + ' ' + profile.lastname : profile.alias
    let uri = pathByType(this.props.message.attached.name.substring(1).split('/'), 'organizations', true, true)

    let disabled = this.state.messageSent || this.state.messageText.length === 0 ? ' disabled' : ''

    //console.log("ActionWindow:render", this.props.message, this.props.issue, this.state.messageAction, user, uri);
    return (
      <ModalWindow
        level={1}
        className="ActionWindow"
        isOpen
        onClose={this.onClose.bind(this)}
        header={caption}
        footer={
          <div>
            {this.state.messageSent ? <Loader /> : <div className="ActionWindow__error">{this.state.errorText}</div>}
            <button className="btn_big ActionWindow__button" onClick={this.onClose.bind(this)}>
              Close
            </button>
            <button className={'btn_big' + disabled + ' ActionWindow__button'} onClick={this.onAction.bind(this)}>
              {button}
            </button>
          </div>
        }
      >
        <div className="ActionWindow">
          <div className="ActionWindow__subject">
            <div className="row ActionWindow__subjectRow">
              <div className="col-xs-3">Subject:</div>
              <div className="col-xs-9">
                <div className="ActionWindow__subjectValue">{this.props.issue.identity.description}</div>
              </div>
            </div>
            <div className="row ActionWindow__subjectRow">
              <div className="col-xs-3">Request:</div>
              <div className="col-xs-9">
                <textarea
                  readOnly
                  className="TextArea"
                  name="message"
                  defaultValue={this.props.message.text}
                  onKeyUp={(event) => event.stopPropagation()}
                  onKeyPress={(event) => event.stopPropagation()}
                  onKeyDown={(event) => event.stopPropagation()}
                />
              </div>
            </div>
            <div className="row ActionWindow__subjectRow">
              <div className="col-xs-3">User:</div>
              <div className="col-xs-9">
                <div className="ActionWindow__subjectValue">{user}</div>
              </div>
            </div>
            <div className="row ActionWindow__subjectRow">
              <div className="col-xs-3">URI:</div>
              <div className="col-xs-9">
                <div className="ActionWindow__subjectValue">{uri}</div>
              </div>
            </div>
          </div>
          <div className="ActionWindow__properties">{this.renderProperties()}</div>
          <div className="ActionWindow__label">{info}</div>
          <div className="row ActionWindow__subjectRow">
            <div className="ActionWindow__text ActionWindow__text_col">
              <textarea
                className="TextArea"
                name="text"
                defaultValue={this.state.messageText}
                placeholder="Type message text"
                onChange={this.onMessageEdit.bind(this)}
                onKeyUp={(event) => event.stopPropagation()}
                onKeyPress={(event) => event.stopPropagation()}
                onKeyDown={(event) => event.stopPropagation()}
              />
            </div>
          </div>
          <div className="row ActionWindow__subjectRow">
            <div className="col-xs-6">
              {/*<div className="row">*/}
              <div className="ActionWindow__issue">
                <input
                  type="checkbox"
                  data-fieldname="CloseIssue"
                  value="1"
                  className="ActionWindow__check"
                  id="close_issue"
                  onChange={this.onIssueCheckbox.bind(this)}
                  defaultChecked={this.state.issueClose}
                />
                <label htmlFor="close_issue">Close issue</label>
              </div>
              <div className="ActionWindow__apply">
                <input
                  type="checkbox"
                  data-fieldname="ApplyIssue"
                  value="1"
                  className="ActionWindow__check"
                  id="apply_issue"
                  onChange={this.onApplyCheckbox.bind(this)}
                  defaultChecked={this.state.issueApply}
                />
                <label htmlFor="apply_issue">Apply to all</label>
              </div>
              {/*</div>*/}
            </div>
          </div>
        </div>
      </ModalWindow>
    )
  }
}

ActionWindow.propTypes = {
  user: PropTypes.object, // User or sender
  action: PropTypes.string, // Action to execute
  issue: PropTypes.object, // Issue to work on
  message: PropTypes.object, // Message user send to request the aproval
  close: PropTypes.func,
  confirm: PropTypes.func,
  userState: PropTypes.object,
  actions: PropTypes.object,
  closeDefault: PropTypes.bool,
  applyAllDefault: PropTypes.bool
}
