import React from 'react'
import { useSelector } from 'react-redux'
import { Header } from '../../components/Header/Header'
import { HeaderOld } from '../../components/Header/HeaderOld'

import { IRootState } from '../../reducers/rootState'

import DashboardIcon from '@material-ui/icons/Dashboard'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import AppsIcon from '@material-ui/icons/Apps'
import PublishIcon from '@material-ui/icons/Publish'
import GetAppIcon from '@material-ui/icons/GetApp'

import { Side } from 'layouts/WithSideBar/Sidebar'

import './MainLayout.scss'

const mainListItems = [
  {
    route: '/',
    title: 'Organizations',
    icon: <DashboardIcon />
  },
  {
    route: '/systems',
    title: 'Systems',
    icon: <SettingsApplicationsIcon />
  },
  {
    route: '/applications',
    title: 'Applications',
    icon: <AppsIcon />
  },
  {
    route: '/publications',
    title: 'Publications',
    icon: <PublishIcon />
  },
  {
    route: '/subscriptions',
    title: 'Subscriptions',
    icon: <GetAppIcon />
  }
]

export const MainLayout = ({ children, ...rest }) => {
  const { sidebarCollapse, experimentalDesign } = useSelector((state: IRootState) => state.ui)

  return (
    <>
      {!experimentalDesign ? (
        <>
          <HeaderOld {...rest} />
          {children}
        </>
      ) : (
        <>
          <Header {...rest} />
          <div className="MainLayout">
            <Side {...rest} mainListItems={mainListItems} />
            <div className={`MainLayout__container ${sidebarCollapse ? 'collapse' : ''}`}>{children}</div>
          </div>
        </>
      )}
    </>
  )
}
