import React, { Component } from 'react'
import { Mention, MentionsInput } from 'react-mentions'

import { shortAttached } from '../../helpers'
import discardIcon from '../../resources/images/discard-2x.png'
import editIcon from '../../resources/images/edit-white-2x.png'
import newMessageIcon from '../../resources/images/message-white-2x.png'
import { IssueMessageTextarea } from '../IssueMessageTextarea/IssueMessageTextarea'

type IssueNewMessageProps = {
  issue: any
  onSelectNewMessage?: () => void
  onOpenMessages?: () => void
  selected: boolean
  onSendMessage?: Function
  onDiscard?: Function
  onEditIssue?: () => void
  users: any[]
  openMessagesButtonText?: string
  disableSend?: boolean
  messageUpdateListener?: Function
  onFullScreenMessages?: () => void
  fullScreenMessagesButtonText?: string
}

type IssueNewMessageState = {
  showFullAttached: boolean
  expanded: boolean
  tabIndex: number
  newMessage: {
    text: string
    log: string
    receivers?: any
  }
  prevMessageMentions: any[]
  issue: any
  sendToText: undefined
}

export class IssueNewMessage extends Component<IssueNewMessageProps, IssueNewMessageState> {
  constructor(props) {
    super(props)
    this.state = {
      showFullAttached: false,
      expanded: false,
      tabIndex: 0,
      newMessage: {
        text: '',
        log: '',
        receivers: this.getInitialReceivers(props)
      },
      prevMessageMentions: [],
      issue: props.issue,
      sendToText: undefined
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    //console.log("IssueNewMessage::componentWillReceiveProps", this.state.issue.id, );
    if (
      newProps.issue.identity &&
      this.state.issue.identity &&
      this.state.issue.identity.id !== newProps.issue.identity.id
    ) {
      this.setState({
        issue: newProps.issue,
        sendToText: undefined,
        prevMessageMentions: [],
        newMessage: {
          text: '',
          log: '',
          receivers: this.getInitialReceivers(newProps)
        }
      })
    }
    if ((!this.props.users || this.props.users.length === 0) && newProps.users.length > 0) {
      this.setState({
        issue: newProps.issue,
        sendToText: undefined,
        prevMessageMentions: [],
        newMessage: {
          text: '',
          log: '',
          receivers: this.getInitialReceivers(newProps)
        }
      })
    }
  }

  getInitialReceivers(props) {
    let assignedList: any[] = []
    if ((props.issue.trackers || []).map((tracker) => tracker.id).indexOf(props.issue.assigned.id) === -1)
      assignedList = [this.userAliasById(props.issue.assigned.id)]

    let list = assignedList.concat((props.issue.trackers || []).map((tracker) => tracker.name))
    return list
  }

  sendMessage() {
    if (this.state.newMessage) {
      // //console.log("sendMessage current state", JSON.stringify(this.state));
      this.props.onSendMessage &&
        this.props.onSendMessage(
          Object.assign({}, this.state.newMessage, {
            receivers: this.state.newMessage.receivers.map((r) => {
              return { id: this.userIdByAlias(r) }
            })
          }),
          this.props.issue,
          () => {
            this.setState({ newMessage: { text: '', log: '', receivers: [] } })
          }
        )
    }
  }

  cancelMessage() {
    this.setState({
      expanded: false,
      newMessage: {
        text: '',
        log: this.state.newMessage.log,
        receivers: this.getInitialReceivers(this.props)
      },
      sendToText: undefined
    })
    this.props.onDiscard && this.props.onDiscard()
  }

  messageChange(event, newVal, newValPlain, mentions) {
    // //console.log("messageChange", mentions);
    ////console.log("receivers before", JSON.stringify(this.state.newMessage.receivers));
    /*
     let rr = this.state.newMessage.receivers.concat(mentions.map((mention) => {
     return {id: mention.id};
     }));
     rr = rr.map(r=>r.id);
     rr = rr.filter((r, i) => rr.indexOf(r) === i);     // unique list
     rr = rr.map(r => {
     return {id: r};
     });
     this.setState({newMessage: {text: newValPlain, log: this.state.newMessage.log,
     receivers: rr
     }}, ()=>{
     ////console.log("receivers after", JSON.stringify(this.state.newMessage.receivers));
     });
     */

    //console.log("old mentions", this.state.prevMessageMentions);
    //console.log("old receivers", this.state.newMessage.receivers);
    //console.log("new mentions", mentions.map(mention => this.userAliasById(mention.id)));

    const added = mentions
      .filter((mention) => this.state.prevMessageMentions.indexOf(mention.id) === -1)
      .map((mention) => this.userAliasById(mention.id))
    // console.log("added", added);
    const newMentionUsers = mentions.map((mention) => this.userAliasById(mention.id))
    const deleted: any[] = [] //this.state.prevMessageMentions.filter(mention => newMentionUsers.indexOf(mention) === -1);
    //    console.log("deleted", deleted);

    let newReceivers = this.state.newMessage.receivers
      .filter((mention) => {
        return deleted.indexOf(mention) === -1
      })
      .concat(added)

    newReceivers = newReceivers.filter((r, i) => newReceivers.indexOf(r) === i) // unique

    //console.log("new receivers", newReceivers.slice());
    //console.log("new message users", newMentionUsers.slice());

    this.setState(
      {
        prevMessageMentions: newMentionUsers,
        newMessage: Object.assign({}, this.state.newMessage, {
          text: newValPlain
        })
      },
      () => {
        this.sendToChange(
          { target: { value: '@' + newReceivers.join(' @') } },
          '',
          '',
          newReceivers.map((r) => {
            return { id: this.userIdByAlias(r) }
          })
        )
      }
    )
  }

  updateMessage = (update) => {
    this.setState((prevState, props) => {
      const newMessage = Object.assign({}, prevState.newMessage, update)
      if (this.props.messageUpdateListener) this.props.messageUpdateListener(newMessage)

      return {
        newMessage: newMessage
      }
    })
  }

  sendToChange = (e, newVal, newValPlain, mentions) => {
    //console.log("sendToChange in receivers", mentions.map((mention) => mention.id));
    let receivers = this.state.newMessage.receivers.concat(mentions.map((mention) => this.userAliasById(mention.id)))
    receivers = receivers.filter((r, i) => receivers.indexOf(r) === i).filter((r) => r) // unique, not empty
    receivers = receivers.filter((r) => e.target.value.indexOf(r) !== -1)
    //console.log("sendToChange save receivers", receivers);
    this.setState({
      sendToText: e.target.value
    })

    this.updateMessage({ receivers: receivers })
  }

  messageLog(log) {
    this.setState({
      newMessage: {
        text: this.state.newMessage.text,
        log: this.state.newMessage.log + '\n' + log
      }
    })
  }

  clickTab(val) {
    return (event) => {
      //this.props.onTabSelect(val);
      this.setState({ tabIndex: val })
      event.stopPropagation()
      event.preventDefault()
    }
  }

  userAliasById(id) {
    let alias = ''
    this.props.users.forEach((user) => {
      if (user.id === id) alias = user.display
    })
    //console.log("userAliasById",id,alias,this.props.users);
    return alias
  }

  userIdByAlias(alias) {
    //console.log("userIdByAlias", alias);
    let id = ''
    this.props.users.forEach((user) => {
      if (user.display === alias) id = user.id
    })
    //console.log("userIdByAlias result", id);
    return id
  }

  getSendTo() {
    if (typeof this.state.sendToText !== 'undefined') return this.state.sendToText

    let assignedList: any[] = []

    if (this.props.issue.assigned) {
      if ((this.props.issue.trackers || []).map((tracker) => tracker.id).indexOf(this.props.issue.assigned.id) === -1)
        assignedList = [this.userAliasById(this.props.issue.assigned.id)]
    }

    /*
    console.log("getSendTo tracker ids", (this.props.issue.trackers || []).map(tracker => tracker.id));
    console.log("getSendTo assignedList", assignedList);
    console.log("getSendTo receivers", this.state.newMessage.receivers.slice());
    */

    let list = assignedList
      .concat((this.props.issue.trackers || []).map((tracker) => tracker.name))
      .concat(this.state.newMessage.receivers) //.map(rec => this.userAliasById(rec.id)) || []);
    list = list.filter((name, index) => list.indexOf(name) === index) // unique

    let ret =
      list
        .filter((name) => name)
        .map((name) => '@' + name)
        .join(' ') + ' '

    //console.log("getSendTo ret", ret);

    return ret // not empty
  }

  renderAttached() {
    return this.props.issue.attached ? (
      <span
        onMouseOver={() => this.setState({ showFullAttached: true })}
        onMouseOut={() => this.setState({ showFullAttached: false })}
      >
        <b>URL: </b>
        {this.state.showFullAttached
          ? this.props.issue.attached && this.props.issue.attached.name
          : shortAttached(this.props.issue.attached.name)}
      </span>
    ) : null
  }

  renderExpanded() {
    /*        <span className={"IssueMessage__tab"+(this.state.tabIndex !== 0 ? " IssueMessage__tab__active" : "")} onClick={this.clickTab(1).bind(this)}>Log</span>
     */

    //<button className="btn NewIssueMessage__settings"><img src={adsetIconDark}/>Message settings</button>
    return (
      <div>
        <div className="row IssueMessage__urlRow">
          <div className="col-xs-9 col IssueMessage__url">{this.renderAttached()}</div>
          <div className="col-xs-7 col IssueMessage__tab">
            <span
              className={'IssueMessage__tab' + (this.state.tabIndex === 0 ? ' IssueMessage__tab__active' : '')}
              onClick={this.clickTab(0).bind(this)}
            >
              Message
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 NewIssueMessage__secondRow">
            <div className="NewIssueMessage__meta">
              <div className="NewIssueMessage__metaButtons">
                <button
                  className={'btn btn_blue NewIssueMessage__send' + (this.props.disableSend ? ' disabled' : '')}
                  onClick={this.sendMessage.bind(this)}
                >
                  <img className="NewIssueMessage__btnImage" src={newMessageIcon} alt="icon" />
                  Send To:
                </button>
                <MentionsInput
                  className="NewIssueMessage__sendToInput"
                  type="text_updatable"
                  value={this.getSendTo()}
                  onChange={this.sendToChange}
                  displayTransform={(id, display, type) => '@' + display + ' '}
                >
                  <Mention
                    trigger="@"
                    data={this.props.users.map((user) => {
                      return { id: user.id + '', display: user.display + '' }
                    })}
                  />
                </MentionsInput>

                <button className="btn NewIssueMessage__cancel" onClick={this.cancelMessage.bind(this)}>
                  <img className="NewIssueMessage__btnImage" src={discardIcon} alt="icon" />
                  Discard
                </button>
              </div>
            </div>
            <div className="NewIssueMessage__text">
              <IssueMessageTextarea
                className="TextArea"
                onChange={this.messageChange.bind(this)}
                value={this.state.newMessage && this.state.newMessage.text ? this.state.newMessage.text : ''}
                users={this.props.users}
                onSendMessage={this.sendMessage.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderCompact() {
    return (
      <div className="row">
        <div className="col NewIssueMessage__newDiv">
          <button className="btn btn_blue NewIssueMessage__new" onClick={this.props.onSelectNewMessage}>
            <img className="NewIssueMessage__btnImage" src={newMessageIcon} alt="icon" />
            New message
          </button>
          &nbsp;&nbsp;
          <button className="btn btn_blue NewIssueMessage__new" onClick={this.props.onEditIssue}>
            <img className="NewIssueMessage__btnImage" src={editIcon} alt="icon" />
            Edit issue
          </button>
          <a className="NewIssueMessage__expand boldOnHover" onClick={this.props.onOpenMessages}>
            {this.props.openMessagesButtonText}
          </a>
          <a className="NewIssueMessage__expand boldOnHover" onClick={this.props.onFullScreenMessages}>
            {this.props.fullScreenMessagesButtonText}
          </a>
        </div>
      </div>
    )
  }

  renderInactive() {
    return (
      <div>
        <div className="row">
          <div className="col-xs-12 NewIssueMessage__secondRow">
            <div className="NewIssueMessage__meta NewIssueMessage__meta_inactive">
              <div className="NewIssueMessage__metaButtons">
                <button className="btn btn_blue NewIssueMessage__send" onClick={this.sendMessage.bind(this)}>
                  Send
                </button>
              </div>
            </div>
            <div
              className="NewIssueMessage__text NewIssueMessage__text_inactive"
              onClick={this.props.onSelectNewMessage}
            >
              {this.state.newMessage.text}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="row Issue__newMessage NewIssueMessage">
        {this.props.selected
          ? this.renderExpanded()
          : this.state.newMessage && this.state.newMessage.text
          ? this.renderInactive()
          : this.renderCompact()}
      </div>
    )
  }
}
