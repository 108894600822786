import * as types from '../constants/ActionTypes'

export const sidebarCollapseAction = (payload) => {
  localStorage.setItem('collapseSidebar', JSON.stringify(payload))

  return { type: types.SIDEBAR_COLLAPSE }
}

export const experimentalDesignAction = (payload) => {
  localStorage.setItem('experimentalDesign', JSON.stringify(payload))

  return { type: types.EXPERIMENTAL_DESIGN }
}

export const setTabName = (payload) => {
  return { type: types.TAB_NAME, payload }
}

export type UiActions = {
  sidebarCollapseAction
  experimentalDesignAction,
  setTabName
}
