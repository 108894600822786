import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ScrollArea from 'react-scrollbar'

import '../../styles/KeyTable/KeyTable.scss'

class KeyTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      values: [],
      prevClickedValue: false,
      search: '',
      searchVisible: false
    }
  }

  onBlur = () => {
    /*
    console.log("KeyTable::onBlur");
    setTimeout(() => {
      this.selectNone();

    }, 500);
    */
  }

  onSearch = (event) => {
    this.setState({
      search: event.target.value
    })
  }

  selectNone = () => {
    this.setState(
      {
        values: []
      },
      () => {
        if (this.props.onSelect) this.props.onSelect(this.state.values)
      }
    )
  }

  selectAll = () => {
    this.setState(
      {
        values: this.props.data.map((row, rowIndex) => row[0])
      },
      () => {
        if (this.props.onSelect) this.props.onSelect(this.state.values)
      }
    )
  }

  toggleSelectAll = () => {
    if (this.state.values.length === this.props.data.length) this.selectNone()
    else this.selectAll()
  }

  toggleSearchVisible = () => {
    this.setState({ searchVisible: !this.state.searchVisible })
  }

  select = (item) => (event) => {
    ////console.log("KeyTable select", event.target);

    let newValues = null

    if (event.shiftKey && this.state.prevClickedValue !== false) {
      const rowIds = this.props.data.map((row) => row[0])
      const firstId = Math.min(rowIds.indexOf(item), rowIds.indexOf(this.state.prevClickedValue))
      const lastId = Math.max(rowIds.indexOf(item), rowIds.indexOf(this.state.prevClickedValue))
      let valuesToAdd = []
      for (let i = firstId; i <= lastId; i++) valuesToAdd.push(rowIds[i])
      newValues = this.state.values.filter((val) => valuesToAdd.indexOf(val) !== -1).concat(valuesToAdd)
    } else {
      if (this.state.values.indexOf(item) !== -1) newValues = this.state.values.filter((val) => val !== item)
      else newValues = this.state.values.concat(item)
    }

    this.setState(
      {
        values: newValues,
        prevClickedValue: item
      },
      () => {
        if (this.props.onSelect) this.props.onSelect(newValues)
      }
    )

    event.preventDefault()
    event.stopPropagation()
  }

  renderColumnHeader = (column, index) => {
    if (index === 1) {
      return (
        <div>
          <div className="KeyTable__CellHeaderInner">{column}</div>
          <div
            className={'KeyTable__search ' + (this.state.searchVisible ? 'KeyTable__search__active' : '')}
            onClick={this.toggleSearchVisible}
          ></div>
          <div
            className={
              'KeyTable__selectAll ' +
              (this.state.values.length > 0 && this.state.values.length === this.props.data.length
                ? 'KeyTable__selectAll__active'
                : '')
            }
            onClick={this.toggleSelectAll}
          ></div>
        </div>
      )
    } else {
      return column
    }
  }

  render() {
    /*<div className="KeyTable__buttonRow">
     <a onClick={this.selectAll}>Select all</a>
     </div>*/

    return (
      <div className="KeyTable">
        <ScrollArea>
          <div className="KeyTableInner">
            <div className="KeyTable__Row KeyTable__RowHeader">
              {this.props.columns.map((column, index) => (
                <div className="KeyTable__Cell KeyTable__CellHeader" key={index}>
                  {this.renderColumnHeader(column, index)}
                </div>
              ))}
            </div>
          </div>
          {this.state.searchVisible ? (
            <div className="KeyTable__buttonRow">
              <input type="text" value={this.state.search} onChange={this.onSearch} placeholder="Search" />
            </div>
          ) : null}
          <div className="KeyTableInner">
            {this.props.data
              .filter((row) => {
                //console.log("Filter: ", row, this.state.search, (row[1] + '').toLowerCase(), this.state.search.toLowerCase(), (row[1] + '').toLowerCase().indexOf(this.state.search.toLowerCase()));
                return !this.state.search || (row[1] + '').toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
              })
              .map((row, rowIndex) => {
                ////console.log("KeyTable render()");
                ////console.log(row);
                let value = row[0]
                return (
                  <div
                    className={
                      'KeyTable__Row ' + (this.state.values.indexOf(value) !== -1 ? 'KeyTable__Row__Selected' : '')
                    }
                    key={rowIndex}
                    onMouseUp={this.select(value)}
                  >
                    {row.map((cell, index) => (
                      <div className="KeyTable__Cell" key={index}>
                        {cell}
                      </div>
                    ))}
                  </div>
                )
              })}
          </div>
        </ScrollArea>
      </div>
    )
  }
}

KeyTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  onSelect: PropTypes.func
}

export default KeyTable
