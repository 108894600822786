import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { dequal } from 'dequal'

import { issueResolutionIcon, renderDateOrTime, getFullUri } from '../../helpers/index'
import { shortnames } from '../../constants/index'

import './IssueCard.scss'

import iconTrack from '../../resources/images/track-2x.png'
import { issuePriorityIcon } from '../../helpers/helperComponents'

type IssueCardProps = {
  card: any
  object: any
  selected: boolean
  onClick: () => void
}

type IssueCardState = {}

export class IssueCard extends Component<IssueCardProps, IssueCardState> {
  shouldComponentUpdate(nextProps, nextState) {
    return !dequal(nextProps, this.props) || !dequal(nextState, this.state)
  }

  getIssueOrgName(obj) {
    return getFullUri(obj).split('/')[2]
  }

  render() {
    //{renderDateOrTime(this.props.card.time)}
    ////console.log("Resolution", moment.duration(moment(this.props.card.time).add(this.props.card.deadline).diff(moment())));

    //console.log(this.props.card,"datetime",moment(this.props.card.deadline).format("MMM DD, YYYY HH:MM"));

    const links = [
      this.props.card.systemName &&
      this.props.object.type === 'organization' &&
      (!this.props.card.applicationName || !this.props.card.datasetName)
        ? '/sys/' + this.props.card.systemName
        : '',
      this.props.card.applicationName &&
      (this.props.object.type === 'organization' || this.props.object.type === 'system')
        ? '/app/' + this.props.card.applicationName
        : '',
      this.props.card.datasetName &&
      (this.props.object.type === 'organization' ||
        this.props.object.type === 'system' ||
        this.props.object.type === 'application')
        ? '/ds/' + this.props.card.datasetName
        : ''
    ].filter((link) => link !== '')

    let objectLink =
      '/view/organizations/' +
      this.getIssueOrgName(this.props.object) +
      (this.props.card.systemName ? '/systems/' + this.props.card.systemName : '') +
      (this.props.card.applicationName ? '/applications/' + this.props.card.applicationName : '') +
      (this.props.card.datasetName ? '/datasets/' + this.props.card.datasetName : '')

    if (links.length === 0)
      links.push('/' + shortnames[this.props.object.type + 's'] + '/' + this.props.object.identity.name)

    let className = 'IssueCard '
    if (this.props.selected) className += 'IssueCard__selected '
    className += ' IssueCard__' + this.props.card.name + ' '
    if (this.props.card.isRecentlyEdited) className += ' IssueCard__edited '
    if (this.props.card.isTracked) className += ' IssueCard__tracked '

    return (
      <div className={className} onClick={this.props.onClick}>
        <div className="row">
          <div className="col col-xs-12 IssueCard__name">
            {this.props.card.name + '. ' + this.props.card.description}
          </div>
        </div>
        <div className="IssueCard__link">
          <div className="row">
            <div className="col col-xs-9">
              <Link to={objectLink}>
                {links.slice(-2).map((link, i) => {
                  return [
                    <div className="IssueCard__linkPart" key={i}>
                      <div className="IssueCard__linkPart">
                        <span>
                          <span className="IssueCard__label"></span>
                          {link}
                        </span>
                      </div>
                    </div>,
                    <br key={i + 10 * 6} />
                  ]
                })}
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-xs-6 IssueCard__deadline">
            <span className="IssueCard__label">Deadline:</span>{' '}
            {!this.props.card || renderDateOrTime(moment(this.props.card.deadline).format('MMM DD, YYYY HH:MM'))}
          </div>
          <div className="col col-xs-6 IssueCard__priority">
            {this.props.card.isTracked && (
              <img
                className="IssueCard__trackIcon"
                src={iconTrack}
                title="You track this issue"
                alt="You track this issue"
              />
            )}
            {issuePriorityIcon(this.props.card.priority.code)}
            <img
              src={issueResolutionIcon(this.props.card.deadline)}
              className="IssueCard__resolutionIcon"
              alt="resolition icon"
            />
          </div>
        </div>
      </div>
    )
  }
}
