/**
 * Created by mailf on 05.05.2016.
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'

import './style.scss'

import vDown from '../../resources/images/v-down-2x.png'

export class LocaleSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: false
    }
  }

  onClick = (event) => {
    if (this.props.autoClose) {
      this.close()
    }
    this.props.onClick(event)
  }

  toggleOpened() {
    this.setState({
      opened: !this.state.opened
    })
  }
  close() {
    this.setState({
      opened: false
    })
  }

  renderOptions(options, selected, empty, onClick) {
    return (
      <div className="LocaleSelect__options">
        {options.map((el, index) => {
          let className = 'LocaleSelect__option'
          if (selected === el) className += ' LocaleSelect__option_selected'
          if (empty.indexOf(el) > -1) className += ' LocaleSelect__option_empty'
          return (
            <div className={className} key={index} onClick={onClick.bind(this, el)}>
              {el}
              {empty.indexOf(el) > -1 ? <span className="LocaleSelectOption__status">Not created</span> : null}
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    return (
      <div
        className={'LocaleSelect' + (this.props.theme ? ' LocaleSelect_' + this.props.theme : '')}
        tabIndex="0"
        onBlur={this.close.bind(this)}
      >
        <div
          className={
            'LocaleSelect__input' +
            (this.props.currentOption.replace(' draft', '').length <= 6 ? ' LocaleSelect__input_short' : '')
          }
          onClick={this.toggleOpened.bind(this)}
        >
          <div className="LocaleSelect__value">{this.props.currentOption}</div>
          <div className={'LocaleSelect__toggle' + (this.state.opened ? ' LocaleSelect__toggle_rotated' : '')}>
            <img src={vDown} alt="" />
          </div>
        </div>
        <div className={'LocaleSelect__list' + (this.state.opened ? ' LocaleSelect__list_opened' : '')}>
          {this.renderOptions(this.props.options, this.props.currentOption, this.props.emptyOptions, this.onClick)}
        </div>
      </div>
    )
  }
}

LocaleSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentOption: PropTypes.string.isRequired,
  emptyOptions: PropTypes.array,
  placeholder: PropTypes.string,
  multi: PropTypes.bool,
  theme: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  autoClose: PropTypes.bool
}

LocaleSelect.defaultProps = {
  emptyOptions: []
}
