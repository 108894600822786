import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../actions/index'
import { capitalize, getUserPhotoUrl } from '../../helpers'
import { API } from '../../helpers/api'
import { fetchDataUrlImmediate } from '../../helpers/api'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import { Profile } from '../Profile/Profile'
import './style.scss'

import defaultUserPicture from '../../resources/images/face-2x.png'

class LocalProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      profile: {}
    }
  }

  save(u) {
    return () => {
      const userName = u.profile.identity.name
      //console.log("save userName=", userName);
      API.organizations(this.props.organization.identity.name)
        .profiles(userName)
        .patch(Object.assign({}, this.state.profile))
        .then(() => {
          this.setState({ isOpen: false })
        })
    }
  }

  renderModal(u) {
    let style = {}
    style.backgroundImage =
      'url(' +
      fetchDataUrlImmediate(getUserPhotoUrl(localStorage.currentUserId), this.forceUpdate.bind(this)) +
      '), url(' +
      defaultUserPicture +
      ')'
    return (
      <ModalWindow
        isOpen
        header="Custom Profile"
        onClose={() => this.setState({ isOpen: false })}
        canClose
        className="LocalProfile__modal"
        footer={
          <div>
            <button
              className="btn_big"
              onClick={() => {
                this.setState({ isOpen: false })
              }}
            >
              Close
            </button>
            <button className="btn_big" onClick={this.save(u)}>
              Save
            </button>
          </div>
        }
      >
        {['firstname', 'lastname'].map((fieldName, idx) => {
          return (
            <div key={idx}>
              <div>{capitalize(fieldName)}</div>
              <div>
                <input
                  type="text"
                  className="TextInput disabled"
                  value={this.state.profile[fieldName] || u.profile[fieldName]}
                  onChange={(e) =>
                    this.setState({
                      profile: Object.assign({}, this.state.profile, {
                        [fieldName]: e.target.value
                      })
                    })
                  }
                  readOnly
                />
              </div>
            </div>
          )
        })}
        {['alias', 'position', 'email', 'phone'].map((fieldName, idx) => {
          return (
            <div key={idx}>
              <div>{capitalize(fieldName)}</div>
              <div>
                <input
                  type="text"
                  className="TextInput"
                  value={this.state.profile[fieldName] || u.profile[fieldName]}
                  onChange={(e) =>
                    this.setState({
                      profile: Object.assign({}, this.state.profile, {
                        [fieldName]: e.target.value
                      })
                    })
                  }
                />
              </div>
            </div>
          )
        })}
      </ModalWindow>
    )
  }

  render() {
    let { organization } = this.props
    return (
      <div className="LocalProfile">
        {organization && organization.users
          ? organization.users.map((u) =>
              u && u.profile && u.profile.identity && u.profile.identity.id === localStorage.currentUserId ? (
                <div>
                  {this.state.isOpen ? (
                    <Profile
                      isOpen
                      actions={this.props.actions}
                      userState={this.props.userState}
                      localUser={u}
                      isLocalProfile
                      onClose={() => this.setState({ isOpen: false })}
                      organization={organization}
                    />
                  ) : null}
                  <div className="LocalProfile__alias" onClick={() => this.setState({ isOpen: true })}>
                    {u.profile.alias}
                  </div>
                </div>
              ) : null
            )
          : null}
      </div>
    )
  }
}

LocalProfile.propTypes = {}

function mapStateToProps(state) {
  return {
    appState: state.appState,
    userState: state.userAppState
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalProfile)

//export default TableRow;
