import React from 'react'

export function renderAgreement() {
  return (
    <div className="Agreement">
      <h1>A. Definitions</h1>
      <ol>
        <li>
          The “Agreement” refers, collectively, to all the terms, conditions, notices contained or referenced in this
          document (the “Terms of Service” or the "Terms") and all other operating rules, policies and procedures that
          we may publish from time to time on the Website.{' '}
        </li>
        <li>The “Service” refers to the applications, software, products, and services provided by Apdax.</li>
        <li>
          The “Website” refers to Apdaxco’s websites located at apdaxco.com and metucat.com, and all content, services,
          and products provided by Apdaxco at or through the Website. It also refers to Apdaxco-owned subdomains of
          apdaxco.com and metucat.com.
        </li>
        <li>
          “The User,” “You,” and “Your” refer to the individual person, company, or organization that has visited or is
          using the Website or Service; that accesses or uses any part of the account; or that directs the use of the
          account in the performance of its functions. A User must be at least 13 years of age. Special terms may apply
          for business or government accounts.
        </li>
        <li>
          “Content” refers to content featured or displayed through the Website, including without limitation text,
          data, articles, images, photographs, graphics, software, applications, designs, features, and other materials
          that are available on the Website or otherwise available through the Service. "Content" also includes
          Services. “User-Generated Content” is Content, written or otherwise, created or uploaded by our Users. “Paid
          Content” is Content only available to Users who are participating in a payment plan, including private
          repositories.
        </li>
      </ol>
      <h1>B. Account Terms</h1>
      <h2>1. Required Information</h2>
      You must provide a valid email address in order to complete the signup process. Any other information requested,
      such as your real name, is optional, unless you are accepting these terms on behalf of a legal entity (in which
      case we need more information about the legal entity) or if you opt for a paid account, in which case additional
      information will be necessary for billing purposes.
      <h2>2. Account Requirements</h2>
      We have a few simple rules for accounts on Apdax's Service.
      <ul>
        <li>
          You must be a human to create an account. Accounts registered by "bots" or other automated methods are not
          permitted. We do permit machine accounts:
        </li>
        <li>
          A machine account is an account set up by an individual human who accepts the Terms on behalf of the account,
          provides a valid email address, and is responsible for its actions. A machine account is used exclusively for
          performing automated tasks. Multiple users may direct the actions of a machine account, but the owner of the
          account is ultimately responsible for the machine's actions. You may maintain no more than one free machine
          account in addition to your free personal account.
        </li>
        <li>
          One person or legal entity may maintain no more than one free account (if you choose to control a machine
          account as well, that's fine, but it can only be used for running a machine).
        </li>
        <li>
          You must be age 13 or older. Apdax does not target our Service to children under 13, and we do not permit any
          Users under 13 on our Service. If we learn of any User under the age of 13, we will terminate that User’s
          account immediately. If you are a resident of a country outside the United States, your country’s minimum age
          may be older; in such a case, you are responsible for complying with your country’s laws.
        </li>
        <li>
          Your login may only be used by one person — i.e., a single login may not be shared by multiple people. A paid
          organization account may create separate logins for as many users as its subscription allows.
        </li>
        <li>Overall, the number of Users must not exceed the number of accounts you've ordered from us.</li>
      </ul>
      <h2>3. User Account Security</h2>
      You are responsible for keeping your account secure while you use our Service. We offer tools to help you maintain
      your account's security, but the content of your account and its security are up to you.
      <ul>
        <li>
          You are responsible for all content posted and activity that occurs under your account (even when content is
          posted by others who have accounts under your account).
        </li>
        <li>
          You are responsible for maintaining the security of your account and password. Apdax cannot and will not be
          liable for any loss or damage from your failure to comply with this security obligation.
        </li>
        <li>
          You will promptly notify us if you become aware of any unauthorized use of, or access to, our Service through
          your account, including any unauthorized use of your password or account.
        </li>
      </ul>
      <h2>4. Additional Terms</h2>
      In some situations, third parties' terms may apply to your use of Apdaxco. For example, you may be a member of an
      organization on metucat.com with its own terms or license agreements; you may download an application that
      integrates with metucat.com; or you may use metucat.com to authenticate to another service. Please be aware that
      while these Terms are our full agreement with you, other parties' terms govern their relationships with you. If
      you are an owner or administrator of an Organization on metucat.com, additional terms may govern your relationship
      with us and supersede this Agreement.
      <h1>C. Acceptable Use</h1>
      <h2>1. Compliance with Laws and Regulations</h2>
      Your use of the Website and Service must not violate any applicable laws, including copyright or trademark laws,
      export control laws, or other laws in your jurisdiction. You are responsible for making sure that your use of the
      Service is in compliance with laws and any applicable regulations.
      <h2>2. Content Restrictions</h2>
      You agree that you will not under any circumstances upload, post, host, or transmit any content that:
      <ul>
        <li>is unlawful or promotes unlawful activities;</li>
        <li>is or contains sexually obscene content;</li>
        <li>is libelous, defamatory, or fraudulent;</li>
        <li>is discriminatory or abusive toward any individual or group;</li>
        <li>
          contains or installs any active malware or exploits, or uses our platform for exploit delivery (such as part
          of a command and control system); or
        </li>
        <li>
          infringes on any proprietary right of any party, including patent, trademark, trade secret, copyright, right
          of publicity, or other rights.
        </li>
      </ul>
      <h2>3. Conduct Restrictions</h2>
      While using metucat.com you agree that you will not under any circumstances:
      <ul>
        <li>
          harass, abuse, threaten, or incite violence towards any individual or group, including Apdaxco employees,
          officers, and agents, or other metucat.com Users;
        </li>
        <li>
          use our servers for any form of excessive automated bulk activity (for example, spamming), or relay any other
          form of unsolicited advertising or solicitation through our servers, such as get-rich-quick schemes;
        </li>
        <li>
          attempt to disrupt or tamper with Apdaxco's servers in ways that could harm our Website or Service, to place
          undue burden on Apdaxco's servers through automated means, or to access Apdaxco's Service in ways that exceed
          your;
        </li>
        <li>
          impersonate any person or entity, including any of our employees or representatives, including through false
          association with Apdaxco, or by fraudulently misrepresenting your identity or site's purpose; or
        </li>
        <li>
          violate the privacy of any third party, such as by posting another person's personal information without
          consent.
        </li>
      </ul>
      <h2>4. Services Usage Limits</h2>
      You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the
      Service, or access to the Service without Apdaxco's express written permission.
      <h2>5. Privacy</h2>
      Misuse of metucat.com Users' Personal Information is prohibited. Any person, entity, or service collecting data
      from metucat.com must comply with the Apdax Privacy Statement, particularly in regards to the collection of our
      Users' Personal Information. If you collect any metucat.com User's Personal Information from metucat.com, you
      agree that you will only use the Personal Information you gather for the purpose for which our User has authorized
      it. You agree that you will reasonably secure any Personal Information you have gathered from metucat.com, and you
      will respond promptly to complaints, removal requests, and "do not contact" requests from metucat.com or
      metucat.com Users.
      <h2>6. Excessive Bandwidth Use</h2>
      If we determine your bandwidth usage to be significantly excessive in relation to other metucat.com customers, we
      reserve the right to suspend your account or throttle your file hosting until you can reduce your bandwidth
      consumption.
      <h2>7. User Protection</h2>
      You agree not to engage in activity that significantly harms our Users. We will resolve disputes in favor of
      protecting our Users as a whole.
      <h1>D. User-Generated Content</h1>
      <h2>1. Responsibility for User-Generated Content</h2>
      You may create User-Generated Content while using the Service. You are solely responsible for the content of, and
      any harm resulting from, any User-Generated Content that you post, upload, link to or otherwise make available via
      the Service, regardless of the form of that Content. We are not responsible for any public display or misuse of
      your User-Generated Content.
      <h2>2. Ownership of Content and Right to Post</h2>
      You retain ownership of and responsibility for Content you create. If you're posting anything you did not create
      yourself, you agree that you will only submit Content that you have the right to post, and you will fully comply
      with any third party licenses relating to Content you post.
      <h2>3. Apdax May Remove Content</h2>
      We do not pre-screen User-Generated Content, but we have the right (though not the obligation) to refuse or remove
      any User-Generated Content that, in our sole discretion, violates any Apdax terms or policies.
      <h2>4. License Grant to Us</h2>
      Your Content belongs to you, and you are responsible for Content you post even if it does not belong to you.
      However, we need the legal right to do things like host it, publish it, and share it. You grant us and our legal
      successors the right to store and display your Content and make incidental copies as necessary to render the
      Website and provide the Service. That means you're giving us the right to do things like reproduce your content
      (so we can do things like copy it to our database and make backups); display it (so we can do things like show it
      to you and other users); modify it (so our server can do things like parse it into a search index); distribute it
      (so we can do things like share it with other users); and perform it (in case your content is something like music
      or video). This license does not grant Apdax the right to sell your Content or otherwise distribute it outside of
      our Service.
      <h2>5. Moral Rights</h2>
      You retain all moral rights to Content you upload, publish, or submit to any part of the Service, including the
      rights of integrity and attribution. However, you waive these rights and agree not to assert them against us, to
      enable us to reasonably exercise the rights, but not otherwise. You understand that you will not receive any
      payment for any of the rights granted in this Section. To the extent such an agreement is not enforceable by
      applicable law, you grant Apdax a nonexclusive, revocable, worldwide, royalty-free right to (1) use the Content
      without attribution strictly as necessary to render the Website and provide the Service; and (2) make reasonable
      adaptations of the Content as provided in this Section. We need these rights to allow basic functions like search
      to work.
      <h1>E. Intellectual Property Notice</h1>
      Apdax and our licensors, vendors, agents, and/or our content providers retain ownership of all intellectual
      property rights of any kind related to the Website and Service. We reserve all rights that are not expressly
      granted to you under this Agreement or by law. The look and feel of the Website and Service is copyright © Apdax,
      Co. All rights reserved. You may not duplicate, copy, or reuse any portion of the HTML/CSS, Javascript, or visual
      design elements or concepts without express written permission from Apdax.
      <h1>F. Payment</h1>
      You are responsible for any fees associated with your use of MetUCat. We are responsible for communicating those
      fees to you clearly and accurately, and letting you know well in advance if those prices change.
      <h2>1. Pricing</h2>
      If you agree to a subscription price, that will remain your price for the duration of the payment term; however,
      prices are subject to change at the end of a payment term.
      <h2>2. Upgrades</h2>
      <ul>
        <li>We will immediately bill you when you upgrade from the free plan to any paying plan.</li>
        <li>If you upgrade to a higher level of service, we will bill you for the upgraded plan immediately.</li>
      </ul>
      <h2>3. Billing Schedule; No Refunds</h2>
      <ul>
        <li>
          Service is billed in advance on a monthly or yearly basis respectively and is non-refundable. There will be no
          refunds or credits for partial months of service, downgrade refunds, or refunds for months unused with an open
          account; however, the service will remain active for the length of the paid billing period.
        </li>
        <li>In order to treat everyone equally, no exceptions will be made.</li>
      </ul>
      <h2>4. Authorization</h2>
      By agreeing to these Terms, you are giving us permission to charge your on-file credit card, PayPal account, or
      other approved methods of payment for fees that you authorize for Apdax.
      <h2>5. Responsibility for Payment</h2>
      You are responsible for all fees, including taxes, associated with your use of the Service. By using the Service,
      you agree to pay Apdax any charge incurred in connection with your use of the Service. If you dispute the matter,
      contact Apdax. You are responsible for providing us with a valid means of payment for paid accounts. Free accounts
      are not required to provide payment information.
      <h1>G. Cancellation and Termination</h1>
      You may close your account at any time. If you do, we'll treat your information responsibly.
      <h2>1. Account Cancellation</h2>
      It is your responsibility to properly cancel your account with Apdax. We are able to cancel accounts in response
      to an email or phone request.
      <h2>2. Upon Cancellation</h2>
      We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and
      enforce our agreements, but barring legal requirements, we will delete your full profile and the Content of your
      repositories within 90 days of cancellation or termination (though some information may remain in encrypted
      backups). This information can not be recovered once your account is cancelled.
      <h2>3. Apdax May Terminate</h2>
      Apdax has the right to suspend or terminate your access to all or any part of the Website at any time, with or
      without cause, with or without notice, effective immediately. Apdax reserves the right to refuse service to anyone
      for any reason at any time.
      <h2>4. Survival</h2>
      All provisions of this Agreement which by their nature should survive termination will survive termination,
      including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of
      liability.
      <h1>H. Communications with Apdax</h1>
      We use email and other electronic means to stay in touch with our users.
      <h2>1. Electronic Communication Required</h2>
      For contractual purposes, you (1) consent to receive communications from us in an electronic form via the email
      address you have submitted or via the Service; and (2) agree that all Terms of Service, agreements, notices,
      disclosures, and other communications that we provide to you electronically satisfy any legal requirement that
      those communications would satisfy if they were on paper. This section does not affect your non-waivable rights.
      <h2>2. Legal Notice to Apdax Must Be in Writing</h2>
      Communications made through email or Apdax Support's messaging system will not constitute legal notice to Apdax or
      any of its officers, employees, agents or representatives in any situation where notice to Apdax is required by
      contract or any law or regulation.
      <h2>3. No Phone Support</h2>
      Apdax only offers support via email. We do not offer telephone support.
      <h1>I. Disclaimer of Warranties</h1>
      We provide our service as is, and we make no promises or guarantees about this service. Please read this section
      carefully; you should understand what to expect. Apdax provides the Website and the Service “as is” and “as
      available,” without warranty of any kind. Without limiting this, we expressly disclaim all warranties, whether
      express, implied or statutory, regarding the Website and the Service including without limitation any warranty of
      merchantability, fitness for a particular purpose, title, security, accuracy and non-infringement. Apdax does not
      warrant that the Service will meet your requirements; that the Service will be uninterrupted, timely, secure, or
      error-free; that the information provided through the Service is accurate, reliable or correct; that any defects
      or errors will be corrected; that the Service will be available at any particular time or location; or that the
      Service is free of viruses or other harmful components. You assume full responsibility and risk of loss resulting
      from your downloading and/or use of files, information, content or other material obtained from the Service.
      <h1>J. Limitation of Liability</h1>
      We will not be liable for damages or losses arising from your use or inability to use the service or otherwise
      arising under this agreement. Please read this section carefully; it limits our obligations to you. You understand
      and agree that we will not be liable to you or any third party for any loss of profits, use, goodwill, or data, or
      for any incidental, indirect, special, consequential or exemplary damages, however arising, that result from
      <ul>
        <li>the use, disclosure, or display of your User-Generated Content;</li>
        <li>your use or inability to use the Service;</li>
        <li>any modification, price change, suspension or discontinuance of the Service;</li>
        <li>the Service generally or the software or systems that make the Service available;</li>
        <li>unauthorized access to or alterations of your transmissions or data;</li>
        <li>statements or conduct of any third party on the Service;</li>
        <li>any other user interactions that you input or receive through your use of the Service; or</li>
        <li>
          any other matter relating to the Service. Our liability is limited whether or not we have been informed of the
          possibility of such damages, and even if a remedy set forth in this Agreement is found to have failed of its
          essential purpose. We will have no liability for any failure or delay due to matters beyond our reasonable
          control.
        </li>
      </ul>
      <h1>K. Release and Indemnification</h1>
      You are responsible for your use of the service. If you harm someone else or get into a dispute with someone else,
      we will not be involved. If you have a dispute with one or more Users, you agree to release Apdax from any and all
      claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of
      or in any way connected with such disputes. You agree to indemnify us, defend us, and hold us harmless from and
      against any and all claims, liabilities, and expenses, including attorneys’ fees, arising out of your use of the
      Website and the Service, including but not limited to your violation of this Agreement, provided that Apdax (1)
      promptly gives you written notice of the claim, demand, suit or proceeding; (2) gives you sole control of the
      defense and settlement of the claim, demand, suit or proceeding (provided that you may not settle any claim,
      demand, suit or proceeding unless the settlement unconditionally releases Apdax of all liability); and (3)
      provides to you all reasonable assistance, at your expense.
      <h1>L. Changes to These Terms</h1>
      We reserve the right, at our sole discretion, to amend these Terms of Service at any time and will update these
      Terms of Service in the event of any such amendments. We will notify our Users of material changes to this
      Agreement, such as price changes, at least 30 days prior to the change taking effect by posting a notice on our
      Website. For non-material modifications, your continued use of the Website constitutes agreement to our revisions
      of these Terms of Service. We reserve the right at any time and from time to time to modify or discontinue,
      temporarily or permanently, the Website (or any part of it) with or without notice.
      <h1>M. Miscellaneous</h1>
      <h2>1. Governing Law</h2>
      Except to the extent applicable law provides otherwise, this Agreement between you and Apdax and any access to or
      use of the Website or the Service are governed by the federal laws of the United States of America and the laws of
      the State of California, without regard to conflict of law provisions. You and Apdax agree to submit to the
      exclusive jurisdiction and venue of the courts located in the City and County of San Francisco, California.
      <h2>2. Non-Assignabsility</h2>
      Apdax may assign or delegate these Terms of Service, in whole or in part, to any person or entity at any time with
      or without your consent. You may not assign or delegate any rights or obligations under the Terms of Service or
      Privacy Statement without our prior written consent, and any unauthorized assignment and delegation by you is
      void.
      <h2>3. Severability, No Waiver, and Survival</h2>
      If any part of this Agreement is held invalid or unenforceable, that portion of the Agreement will be construed to
      reflect the parties’ original intent. The remaining portions will remain in full force and effect. Any failure on
      the part of Apdax to enforce any provision of this Agreement will not be considered a waiver of our right to
      enforce such provision. Our rights under this Agreement will survive any termination of this Agreement.
      <h2>4. Complete Agreement</h2>
      This Agreement supersedes any proposal or prior agreement oral or written, and any other communications between
      you and Apdax relating to the subject matter of these terms.
    </div>
  )
}
