/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import '../../styles/MajorObjectLink/MajorObjectLink.scss'
import { getFullUri } from '../../helpers/index'
import { track } from '../../helpers/analytics'

export const MajorObjectLink = ({ object, type, children, className, onClick, onTrack }) => {
  if (onClick) {
    return (
      <a
        onClick={() => {
          onClick(object.identity.id)
        }}
      >
        {children}
      </a>
    )
  } else {
    //console.log("MajorObjectLink::render", onTrack);
    if ((object.object && object.object.parent) || (object.object && object.type === 'organization'))
      return (
        <Link
          className="MajorObjectLink"
          to={'/view' + getFullUri(object)}
          onClick={() => {
            window.scrollTo(0, 0);
            onTrack ? onTrack() : track(type, 'majorobjectlink')
          }}
        >
          {children}
        </Link>
      )
    else if (object.path)
      return (
        <Link
          className="MajorObjectLink"
          to={'/view' + object.path}
          onClick={() => {
            window.scrollTo(0, 0);
            onTrack ? onTrack() : track(type, 'majorobjectlink')
          }}
        >
          {children}
        </Link>
      )
    else return children
  }
}

MajorObjectLink.propTypes = {
  object: PropTypes.object,
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  onTrack: PropTypes.func
}
