import * as log from 'loglevel'

const logger = log.noConflict()

const getLogLevel = () => {
  // Let's set level of logging for application.
  let level = logger.levels.ERROR

  // Set up base on local storage settings
  if (localStorage.loglevel === 'DEBUG') {
    level = logger.levels.DEBUG
  } else if (localStorage.loglevel === 'WARN') {
    level = logger.levels.WARN
  } else {
    // Set based on what we run
    if (window.location.href.indexOf('int') > 0) level = logger.levels.WARN
    if (window.location.href.indexOf('localhost') > 0 && !window.localInstallation) level = logger.levels.DEBUG
  }

  console.log('Logger:getLogLevel ===============>', level, localStorage.loglevel, window.location.href)
  return level
}

logger.setLevel(getLogLevel())

export default logger
