import PropTypes from 'prop-types'

import React, { Component } from 'react'
import logger from '../../helpers/logger'
import { EditorDialog } from '../EditorDialog/EditorDialog'
import { ScrollArea } from '../ModalWindow/ScrollArea.tsx'
import { ActivityDialog } from './ActivityDialog'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import {
  API,
  idAPI,
  apiURL,
  getObjectListByUsage,
  getObjectNew,
  getObjectListNew,
  getRequestFromPath,
  prepareObjectToStore
} from '../../helpers/api'
import { objectNameFromPathByType } from '../../helpers'
import { nonCSCompare, displayReferenceUri, getFullUri, editableState } from '../../helpers/index'

import './WorkflowChartDialog.scss'
import { downloadSelectorAsPDF } from '../../helpers/pdfDownloader'

export class WorkflowChartDialog extends Component {
  constructor(props) {
    super(props)

    let height = this.InitHeight(props)

    this.state = {
      change: null, // Method to report or cancel modification.
      //origin: JSON.stringify(props.activity),

      height: height,
      editMode: false,
      application: null,
      interfaces: {},
      selectedActivity: null,
      selectedArrow: null,
      fullScreen: false
    }
  }

  removeVersionFromUri = (uri) => {
    if (uri.indexOf('/versions/') !== -1) {
      return uri.substr(0, uri.indexOf('/versions/'))
    }
    return uri
  }

  componentDidMount() {
    getObjectNew(getRequestFromPath(this.props.pipeline.object.parent.name)).then((app) => {
      this.setState({ application: app })
    })
    let promises = []
    this.props.pipeline.activities.map((activity, activityIndex) => {
      if (!(activity && activity.operation && activity.operation.reference)) {
        return
      }
      const req = getRequestFromPath(activity.operation.reference)
      logger.log('WorkflowChartDialog::componentDidMount', activity, activity.operation.reference, req)
      if (req) {
        promises.push(getObjectNew(req))
      }
    })
    logger.log('WorkflowChartDialog::componentDidMount prom', promises)
    Promise.all(promises).then((interfaces) => {
      let intfObj = {}
      interfaces.map((intf) => {
        logger.log('WorkflowChartDialog::componentDidMount intf', intf)
        intfObj[getFullUri(intf)] = intf
      })
      this.setState({ interfaces: intfObj })
    })
  }

  /**
   * Init dialog height
   * @param props
   */
  InitHeight = (props) => {
    props = props || this.props

    let height = 550

    if (height < window.innerHeight - 248) height = window.innerHeight - 248

    return height
  }

  /**
   * close dialog
   */
  onClose() {
    this.setState({
      editMode: false
    })
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  /**
   * Adjust size of scroll area in dialog
   * @param width - increase or reduction in control width.
   * @param height - increase or reduction in control height.
   * @return
   */
  onAdjust = (width, height) => {
    this.setState((prevState) => {
      return { height: prevState.height + height }
    })
  }

  /**
   * Set edit mode on
   */
  startEdit = (change) => {
    this.setState({
      change: change
    })
  }

  /**
   * Execute activity when message send to service
   * @param {boolean} close - flag to close dialog
   * @param {*} error - return from service if any.
   * @return
   */
  onSent = (close, error) => {
    // Report complete of save with error or not
    if (this.state.change) {
      this.state.change(error)
    }

    if (close && !error) {
      setTimeout(() => {
        this.onClose()
      }, 1000)
    }
  }

  /**
   * Cancel changes
   * @param {boolean} [closeDialog] Do we need to close dialog
   */
  cancelEdit = (closeDialog) => {
    // Cancel save mode
    if (this.state.change) {
      this.state.change(false)
    }

    this.setState({
      change: null,
      inputs: this.props.inputs || []
    })
  }

  saveActivity = () => {
    logger.log('WorkflowChartDialog::saveActivity()')
  }

  downloadPDF = () => {
    const area =
      document.querySelector('.EditorDialog_pipeline_chart .ScrollArea__container ') ||
      document.querySelector('.EditorDialog_pipeline_chart_fullscreen .ScrollArea__container ')

    downloadSelectorAsPDF(area, this.props.pipeline.identity.name + ' Chart', 'div.table')
  }

  /**
   * show or hide main window
   */
  toggleEditMode() {
    this.setState({
      editMode: !this.state.editMode,
      isEdited: false
    })
  }

  inputForOutput = (input, output, outputActivity) => {
    if (!input || !input.component || !output || !output.component) {
      return false
    }

    if (input.inputMode === 'Operation') {
      //logger.log(outputActivity.identity.name, input.component.reference, output.identity.name , input.component.components[0], outputActivity.identity.name === input.component.reference, output.identity.name === input.component.components[0]);
      return outputActivity.identity.name === input.component.reference //&& output.identity.name === input.component.components[0]
    } else {
      return input.component.reference === output.component.reference
    }
  }

  /**
   * Checks if activity has at least one input that matches the output parameter
   * @param output
   * @param activity
   * @param outputActivity
   * @returns {*}
   */
  outputForActivity = (output, activity, outputActivity) => {
    return (activity.inputs || []).reduce(
      (found, input) => (this.inputForOutput(input, output, outputActivity) ? input : found),
      false
    )
  }

  /**
   * Checks if activity has at least one output that matches the input parameter
   * @param input
   * @param activity
   * @returns {*}
   */
  inputForActivity = (input, activity) => {
    return (activity.outputs || []).reduce(
      (found, output) => (this.inputForOutput(input, output, activity) ? output : found),
      false
    )
  }

  findResponse = (arrow) => {
    return {
      input: arrow.outputActivity && arrow.outputActivity.outputs.length > 0 ? arrow.outputActivity.outputs[0] : null,
      output: arrow.inputActivity && arrow.inputActivity.inputs.length > 0 ? arrow.inputActivity.inputs[0] : null
    }
    /*
    let inp = null;
    let out = null;
    const inputActivity = arrow.inputActivity;
    const outputActivity = arrow.outputActivity;

    logger.log("findResponse", inputActivity, outputActivity, arrow);

    if (!inputActivity || !outputActivity) {
      return {input: null, output: null};
    }

    inputActivity.outputs.map(output => {

      outputActivity.inputs.map(input => {

        if (input.component && output.component && input.component.reference === output.component.reference) {
          inp = input;
          out = output;
        }

      });


    });

    return {input: inp, output: out};
*/
  }

  renderStructure() {
    const application = this.state.application
    const pipeline = this.props.pipeline

    if (!pipeline || !application) {
      return null
    }

    let structure = {
      items: [
        {
          name: application.identity.name,
          type: 'application',
          object: application,
          arrowsIn: [],
          arrowsOut: []
        }
      ],
      arrows: []
    }

    let activities = (pipeline.activities || []).map((activity) => {
      if (!activity.inputs) {
        activity.inputs = []
      }
      if (!activity.outputs) {
        activity.outputs = []
      }
      return activity
    })
    let used = activities.map((a) => 0)
    let sorted = []

    /**
     * Sort activities by dependency
     */
    const dfs = (activity, index) => {
      used[index] = true
      activity.outputs.map((output) => {
        activities.map((nextActivity, nextIndex) => {
          if (!used[nextIndex] && this.outputForActivity(output, nextActivity, activity)) {
            dfs(nextActivity, nextIndex)
          }
        })
      })
      sorted.push(activity)
    }

    activities.map((activity, index) => {
      if (!used[index]) {
        dfs(activity, index)
      }
    })

    sorted = sorted.reverse()

    // Create arrows between activities
    sorted.map((activity, index) => {
      // logger.log('activity', activity, activity.operation);
      structure.items.push({
        name: activity.identity.name,
        type: 'activity',
        object: activity,
        parent: activity.operation.subscription
          ? objectNameFromPathByType(activity.operation.subscription, 'subscriptions')
          : objectNameFromPathByType(activity.operation.reference, 'applications'),
        arrowsIn: [],
        arrowsOut: []
      })
      // arrows from App to activity
      activity.inputs.map((input) => {
        if (!input.component) {
          return
        }

        let inputFound = false
        sorted.map((nextActivity, nextIndex) => {
          if (this.inputForActivity(input, nextActivity)) {
            inputFound = true
          }
        })
        if (!inputFound) {
          structure.arrows.push({
            toCol: 1 + index,
            fromCol: 0,
            text: input.identity.name,
            output: input,
            input: 'application',
            outputActivity: activity
          })
        }
      })
      // arrows between activities and from activity  to App
      activity.outputs.map((output) => {
        if (!output.component) return

        let inputFound = false
        sorted.map((nextActivity, nextIndex) => {
          let input = this.outputForActivity(output, nextActivity, activity)
          if (input) {
            inputFound = true
            structure.arrows.push({
              toCol: 1 + nextIndex,
              fromCol: 1 + index,
              text: output.identity.name,
              output: input,
              input: output,
              outputActivity: nextActivity,
              inputActivity: activity
            })
          }
        })
        /*
        if (!inputFound) {
          structure.arrows.push({
            toCol: 0,
            fromCol: 1 + index,
            text: output.identity.name,
            output: 'application',
            input: output,
            inputActivity: activity
          });
        }
        */
      })
    })

    /*
    // set arrowsIn and arrowsOut for items
    structure.arrows.map((arrow, index) => {
      structure.items[arrow.fromCol].arrowsOut.push(arrow);
      structure.items[arrow.toCol].arrowsIn.push(arrow);
      arrow.index = index;
    });

    // Response goes to activity which did Operation using this Interface
    // Update Response arrows:
    structure.arrows.filter(arrow => arrow.toCol === 0).map(arrow => {
      const col = structure.items[arrow.fromCol];
      const activity = arrow.inputActivity;
      col.arrowsIn.map(arrowIn => {
        if (arrowIn.outputActivity && arrowIn.outputActivity.operation && arrow.inputActivity && arrow.inputActivity.operation &&
          arrowIn.outputActivity.operation.reference === arrow.inputActivity.operation.reference) {
          logger.log("checked that arrowIn", arrowIn, "has same interface that", arrow);
          arrow.toCol = arrowIn.fromCol;
        }
      });

    });
    */

    structure.arrows
      .slice()
      .reverse()
      .map((arrow, index) => {
        const resp = this.findResponse(arrow)
        structure.arrows.push(
          Object.assign({}, arrow, {
            fromCol: arrow.toCol,
            toCol: arrow.fromCol,
            input: resp.input,
            output: resp.output,
            text: resp.input ? resp.input.identity.name : arrow.text
          })
        )
      })

    // logger.log('arrows', structure.arrows);

    // set arrowsIn and arrowsOut again, after changing Responses
    structure.items.map((col) => {
      col.arrowsOut = []
      col.arrowsIn = []
    })
    structure.arrows.map((arrow, index) => {
      structure.items[arrow.fromCol].arrowsOut.push(arrow)
      structure.items[arrow.toCol].arrowsIn.push(arrow)
      arrow.index = index
    })

    /*
      structure.items = [];
      structure.arrows = [];
    */

    structure.items.map((col, index) => {
      col.index = index
    })

    // logger.log('structure', structure);

    // get column titles
    let leftTitles = []

    structure.items.map((col, index) => {
      if (col.type === 'application') {
        leftTitles[0] = application.identity.name
        col.left = 0
      } else {
        col.left = -1
        let maxLeftSameName = -1
        // find Application/Subscription for activity
        for (let left = 0; left < leftTitles.length; left++) {
          if (leftTitles[left] === col.parent) {
            maxLeftSameName = Math.max(maxLeftSameName, left)
            // if activity depends on others, it must be positioned to the right of all dependencies
            let maxDependencyLeft = 0
            col.arrowsIn.map((arrow) => {
              maxDependencyLeft = Math.max(maxDependencyLeft, structure.items[arrow.fromCol].left)
            })
            //logger.log("col", col.name, "max dep left", maxDependencyLeft);
            if (maxDependencyLeft < left) {
              col.left = left
            }
          }
        }
        // if appropriate column is not found, create new column
        if (col.left === -1) {
          col.left = leftTitles.length

          // check if we have subscription
          if (col.object.operation.subscription) {
            leftTitles.push(`Subs/${col.parent}`)
          } else {
            leftTitles.push(col.parent)
          }
        }
      }
      //logger.log("col get left", col, col.left);
    })
    // logger.log('leftTitles', leftTitles);

    // calculate positions of the arrows
    let currentTop = 0
    for (let left = 0; left < leftTitles.length; left++) {
      structure.items
        .filter((col) => col.left === left)
        .map((col) => {
          col.arrowsOut.map((arrow) => {
            if (structure.items[arrow.toCol].left > structure.items[arrow.fromCol].left) {
              arrow.top = currentTop
            } else {
              arrow.top = 100 - currentTop
            }
            const colTo = structure.items[arrow.toCol]
            arrow.width = Math.abs(colTo.left - col.left)
            currentTop++
            //logger.log("arrow set top", arrow, arrow.top);
            //logger.log("arrow", arrow.text, "from", col.name, "to", colTo.name, "top", arrow.top, "width", arrow.width);
          })
        })
    }

    /*
    structure.items.map((col, index) => {
      col.inArrowCount = 0;
    });
    */

    // Sort arrows and set their top
    let sortedArrows = structure.arrows.sort((a, b) => {
      if (a.top > b.top) {
        return 1
      } else if (a.top < b.top) {
        return -1
      }
      return 0
    })

    let maxArrowTop = 0
    sortedArrows.map((arrow, index) => {
      arrow.top = index * 2 + 4
      maxArrowTop = Math.max(maxArrowTop, arrow.top)
    })
    // logger.log('sortedArrows', sortedArrows);

    // for each item, calculate their positions and heights
    structure.items.map((col, index) => {
      let minTop = Infinity
      let maxTop = 0
      //filter(arrow => arrow.fromCol !== 0).
      col.arrowsIn.concat(col.arrowsOut).map((arrow) => {
        //logger.log("checking arrow in",col,arrow, minTop, maxTop, Math.min(minTop, arrow.top));
        minTop = Math.min(minTop, arrow.top)
        maxTop = Math.max(maxTop, arrow.top)
      })
      // item has minimum top = 1
      if (minTop === maxTop) {
        //logger.log("col max=min", col.name, col.minTop, col.maxTop, col.left);
        maxTop += 1
      }
      col.minTop = minTop
      col.maxTop = maxTop
      //logger.log("col", col.name, col.minTop, col.maxTop, col.left);
    })

    // calculate top position for each arrows, outgoing from column[0] (application)
    structure.items[0].arrowsOut.map((arrow) => {
      const col = structure.items[arrow.toCol]
      const colFrom = structure.items[arrow.fromCol]
      //arrow.top = col.minTop;
      //col.inArrowCount++;
      //logger.log("arrow", "arrow", arrow.text, "from", colFrom.name, "to", col.name, "top", arrow.top, "width", arrow.width);
    })

    // calculate height and position for column[0] (application)
    let col = structure.items[0]
    let minTop = Infinity
    let maxTop = 0
    col.arrowsIn.concat(col.arrowsOut).map((arrow) => {
      //logger.log("checking arrow in",col,arrow, minTop, maxTop, Math.min(minTop, arrow.top));
      minTop = Math.min(minTop, arrow.top)
      maxTop = Math.max(maxTop, arrow.top)
    })
    col.minTop = minTop
    col.maxTop = maxTop
    //logger.log("col", col.name, col.minTop, col.maxTop, col.left);

    const COL_WIDTH = 340
    const ARROW_HEIGHT = 20

    // logger.log('WorkflowChartDialog::render', { pipeline, application, structure, leftTitles, sortedArrows, sorted });

    return (
      <div className="PipelineEditor__outer">
        <div className="EditorDialog_pipeline_chart__additionalButtons">
          <div className="EditorDialog_pipeline_chart__headerControlAdditional" onClick={this.toggleFullScreen}>
            <span className={!this.state.fullScreen ? 'Button_FullScreen' : 'Button_Window'} />
            {this.state.fullScreen ? 'Window mode' : 'Full screen'}
          </div>
          <div className="EditorDialog_pipeline_chart__headerControlPdf">
            <a className="" onClick={this.downloadPDF}>
              <span>{'{PDF}'}</span>
            </a>
          </div>
        </div>

        <ScrollArea>
          <div>
            <div className="PipelineEditor__names" style={{ width: COL_WIDTH * (1 + leftTitles.length) + 'px' }}>
              {leftTitles.map((path, idx) => (
                <div className="PipelineEditor__name" key={`${idx}_${path}`}>
                  <div className="PipelineEditor__nameInner">{path}</div>
                </div>
              ))}
            </div>
            <div
              className="PipelineEditor__verts"
              style={{
                width: COL_WIDTH * (1 + leftTitles.length) + 'px',
                height: (maxArrowTop + 1) * ARROW_HEIGHT
              }}
            >
              {leftTitles.map((title, left) => {
                // render columns
                return (
                  <div className="PipelineEditor__vert" key={`${title}_${left}`}>
                    <div className="PipelineEditor__vertLine" />
                    {structure.items
                      .filter((col) => col.left === left)
                      .map((col, idx) => {
                        return (
                          <div
                            key={`${col}_${idx}`}
                            className={
                              'PipelineEditor__vertBody ' +
                              (left === 0 ? ' PipelineEditor__vertBodyApplication ' : '') +
                              (this.state.selectedActivity && col.index === this.state.selectedActivity.index
                                ? ' PipelineEditor__active'
                                : '')
                            }
                            style={{
                              top: (col.minTop - 1) * ARROW_HEIGHT + 'px',
                              height: (col.maxTop - col.minTop + 1) * ARROW_HEIGHT + 'px'
                            }}
                            onClick={() =>
                              this.setState({
                                selectedArrow: null,
                                selectedActivity: col
                              })
                            }
                          >
                            <div className="PipelineEditor__vertBodyTitle">{col.name}</div>
                          </div>
                        )
                      })}
                  </div>
                )
              })}

              {structure.arrows.map((arrow, idx) => {
                // render arrow
                const arrowDirection =
                  structure.items[arrow.toCol].left > structure.items[arrow.fromCol].left ? 'right' : 'left'
                let arrowClass = arrowDirection === 'right' ? 'PipelineEditor__hor__right' : 'PipelineEditor__hor__left'
                if (arrow.fromCol === arrow.toCol) {
                  arrowClass = 'PipelineEditor__hor__return'
                }
                let left = Math.min(structure.items[arrow.toCol].left, structure.items[arrow.fromCol].left)
                let width = Math.abs(structure.items[arrow.toCol].left - structure.items[arrow.fromCol].left)
                let arrowMarginLeft = width > 1 ? (arrowDirection === 'left' ? -COL_WIDTH / 2 : COL_WIDTH / 2) : 0

                return [
                  <React.Fragment key={`${arrow}_${idx}`}>
                    <div
                      style={{
                        top: arrow.top * ARROW_HEIGHT - ARROW_HEIGHT / 2 + 'px',
                        left: left * COL_WIDTH + 16 + 'px',
                        width: width * COL_WIDTH - 24 + 'px'
                      }}
                      className={
                        'PipelineEditor__hor ' +
                        arrowClass +
                        (this.state.selectedArrow && arrow.index === this.state.selectedArrow.index
                          ? ' PipelineEditor__active'
                          : '')
                      }
                    >
                      <div className="PipelineEditor__horInner" style={{ marginLeft: arrowMarginLeft + 'px' }}>
                        {arrow.text}
                      </div>
                    </div>

                    <div
                      className="PipelineEditor__horClicker"
                      style={{
                        top: arrow.top * ARROW_HEIGHT - ARROW_HEIGHT / 2 - 15 + 'px',
                        left: left * COL_WIDTH + 'px',
                        width: (width - 0.5) * COL_WIDTH + 'px',
                        height: '30px'
                      }}
                      onClick={() => {
                        logger.log('Arrow click', arrow)
                        this.setState({
                          selectedArrow: arrow,
                          selectedArrowMode: arrowDirection === 'left' ? 'input' : 'output',
                          selectedActivity: null
                        })
                      }}
                    />

                    <div
                      className="PipelineEditor__horClicker"
                      style={{
                        top: arrow.top * ARROW_HEIGHT - ARROW_HEIGHT / 2 - 15 + 'px',
                        left: left * COL_WIDTH + (width * COL_WIDTH) / 2 + 'px',
                        width: (width - 0.5) * COL_WIDTH + 'px',
                        height: '30px'
                      }}
                      onClick={() => {
                        logger.log('Arrow click', arrow)
                        this.setState({
                          selectedArrow: arrow,
                          selectedArrowMode: arrowDirection === 'right' ? 'input' : 'output',
                          selectedActivity: null
                        })
                      }}
                    />
                  </React.Fragment>
                ]
              })}
            </div>
          </div>
        </ScrollArea>
      </div>
    )
  }

  renderEditor = (editState) => {
    return <div className="PipelineEditor">{this.renderStructure()}</div>
  }

  /**
   * initiate activity dialog
   * @param
   */
  activityEdit = () => {
    this.setState({ activityEdit: true })
  }

  toggleFullScreen = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        fullScreen: !prevState.fullScreen
      }
    })
  }

  renderHeader = (editState, editButtonContent) => {
    return editButtonContent
  }

  renderFooter = (editState) => {
    // Render selected activity
    let pipeline = this.props.pipeline
    // logger.info('WorkflowChart:renderFooter', editState, this.state, this.props);

    if (this.state.selectedActivity) {
      let activity = this.state.selectedActivity.object
      let name = displayReferenceUri(
        activity && activity.operation ? activity.operation.reference : '',
        activity && activity.operation ? activity.operation.subscription : ''
      )
      // logger.info('WorkflowChart:renderFooter:ACTIVITY', activity, name);

      return (
        <div className="TransformationChart__footer">
          {this.state.activityEdit ? (
            <ActivityDialog
              appState={this.props.appState}
              actions={this.props.actions}
              isEditable={this.state.activityEdit && editState >= editableState.EDITING ? 2 : 1}
              isVisible={this.state.activityEdit}
              onSave={this.saveActivity}
              onClose={() => this.setState({ activityEdit: false })}
              parentObjectType={'pipeline'}
              childObjectType={'activity'}
              activity={activity}
              majorObject={pipeline}
            />
          ) : (
            <div className="ActivityDialog__editBlock clearfix">
              <button
                className="btn btn_blue ActivityDialog__editActivityButton"
                onClick={this.activityEdit.bind(this)}
              >
                {editState >= editableState.EDITING ? 'Edit Activity' : 'View Activity'}
              </button>
            </div>
          )}

          <div className="ActivityDialog__block clearfix">
            <label className="ActivityDialog__label">Activity name:</label>
            <div className="value_right">
              <EditableEntity
                data={activity && activity.identity && activity.identity.name ? activity.identity.name : ''}
                dataType={{ name: 'string' }}
                dataProps={{
                  placeholder: '',
                  onChange: () => {}
                }}
                isEditable={false}
              />
            </div>
          </div>

          <div className="ActivityDialog__block_description clearfix">
            <label className="ActivityDialog__label">Description:</label>
            <div className="value_right">
              <EditableEntity
                data={
                  activity && activity.identity && activity.identity.description ? activity.identity.description : ''
                }
                dataType={{ name: 'text_updatable' }}
                dataProps={{
                  placeholder: '',
                  onChange: () => {},
                  maxLineCount: 3
                }}
                isEditable={false}
              />
            </div>
          </div>

          {nonCSCompare(activity.type, 'operation') ? (
            <div className="ActivityDialog__block clearfix">
              <label className="ActivityDialog__label">Interface:</label>
              <div className="value_right">
                <EditableEntity
                  data={name}
                  dataType={{ name: 'enum', options: [] }}
                  dataProps={{
                    placeholder: '',
                    onChange: () => {},
                    maxLineCount: 3
                  }}
                  isEditable={false}
                />
              </div>
            </div>
          ) : null}
        </div>
      )
    }

    if (this.state.selectedArrow) {
      let inputResponse = null
      let inputInterface = null
      if (this.state.selectedArrow.outputActivity) {
        let intf =
          this.state.interfaces[this.removeVersionFromUri(this.state.selectedArrow.outputActivity.operation.reference)]
        if (intf) {
          logger.log('Selected', this.state.selectedArrow.input)
          if (this.state.selectedArrow.input.component && this.state.selectedArrow.input.component.reference) {
            logger.log(
              'in',
              intf.responses.map((resp) => resp.field.identity.name),
              'looking for',
              this.state.selectedArrow.input.component.reference
            )
            inputResponse = intf.responses.find(
              (resp) => resp.field.identity.name === this.state.selectedArrow.input.component.reference
            )
            inputInterface = intf
          }
        }
      }

      let outputOperation = null
      let outputInterface = null
      if (this.state.selectedArrow.inputActivity) {
        let intf =
          this.state.interfaces[this.removeVersionFromUri(this.state.selectedArrow.inputActivity.operation.reference)]
        if (intf) {
          logger.log('Selected oper', this.state.selectedArrow.output)
          if (this.state.selectedArrow.output.component && this.state.selectedArrow.output.component.reference) {
            logger.log(
              'in oper',
              intf.operations.map((oper) => oper.identity.name),
              'looking for',
              this.state.selectedArrow.output.component.reference
            )
            outputOperation = intf.operations.find(
              (oper) => oper.identity.name === this.state.selectedArrow.output.component.reference
            )
            outputInterface = intf
          }
        }
      }

      let input = this.state.selectedArrow.input
      let output = this.state.selectedArrow.output
      let name = displayReferenceUri(
        input && input.component ? input.component.reference : '',
        input && input.component ? input.component.subscription : ''
      )
      //      let layoutNames = (input && input.component && input.component.layouts) ? input.component.layouts : [];
      logger.info('TransformationChart:renderFooter:INPUT', input, name)

      if (this.state.selectedArrowMode === 'input') {
        return !input ? null : (
          <div className="TransformationChart__footer">
            <div className="InputDialog__block clearfix">
              <label className="InputDialog__label">Input name:</label>
              <div className="value_right">
                <EditableEntity
                  data={input && input.identity && input.identity.name ? input.identity.name : ''}
                  dataType={{ name: 'string' }}
                  dataProps={{
                    placeholder: '',
                    onChange: () => {}
                  }}
                  isEditable={false}
                />
              </div>
            </div>

            <div className="InputDialog__block_description clearfix">
              <label className="InputDialog__label">Description:</label>
              <div className="value_right">
                <EditableEntity
                  data={input && input.identity && input.identity.description ? input.identity.description : ''}
                  dataType={{ name: 'text_updatable' }}
                  dataProps={{
                    placeholder: '',
                    onChange: () => {},
                    maxLineCount: 3
                  }}
                  isEditable={false}
                />
              </div>
            </div>

            <div className="InputDialog__block clearfix">
              <label className="InputDialog__label">Mode:</label>
              <div className="value_right">
                <EditableEntity
                  data={input.inputMode}
                  dataType={{ name: 'enum', options: [] }}
                  dataProps={{
                    placeholder: '',
                    onChange: () => {}
                  }}
                  isEditable={false}
                />
              </div>
            </div>

            {nonCSCompare(input.inputMode, 'dataset') ? (
              <div>
                <div className="InputDialog__block clearfix">
                  <label className="InputDialog__label">Dataset:</label>
                  <div className="value_right">
                    <EditableEntity
                      data={name}
                      dataType={{ name: 'enum', options: [] }}
                      dataProps={{
                        placeholder: '',
                        onChange: () => {}
                      }}
                      isEditable={false}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {nonCSCompare(input.inputMode, 'output') && input.component && input.component.reference ? (
              <div className="InputDialog__block clearfix">
                <label className="InputDialog__label">Output:</label>
                <div className="value_right">
                  <EditableEntity
                    data={input.component.components ? input.component.components[0] : ''}
                    dataType={{ name: 'enum', options: [] }}
                    dataProps={{
                      placeholder: '',
                      onChange: () => {}
                    }}
                    isEditable={false}
                  />
                </div>
              </div>
            ) : null}
          </div>
        )
      }
      if (this.state.selectedArrowMode === 'output') {
        return !output ? null : (
          <div className="TransformationChart__footer">
            <div className="InputDialog__block clearfix">
              <label className="InputDialog__label">Output name:</label>
              <div className="value_right">
                <EditableEntity
                  data={output && output.identity && output.identity.name ? output.identity.name : ''}
                  dataType={{ name: 'string' }}
                  dataProps={{
                    placeholder: '',
                    onChange: () => {}
                  }}
                  isEditable={false}
                />
              </div>
            </div>
          </div>
        )
      }
    }

    /*
     Input: {JSON.stringify(this.state.selectedArrow.input)} <br />
     Output: {JSON.stringify(this.state.selectedArrow.output)} <br />
     */
    /*
     return <div style={{textAlign: 'left'}}>
     Name: {this.state.selectedArrow.text} <br />
     {inputResponse ? <span>Input Response:<a onClick={()=>{
     this.setState({viewingResponse: inputResponse , viewingInterface: inputInterface});
     }} > {inputResponse.field.identity.name}</a></span> : null}
     <br />
     {outputOperation ? <span>Output Operation: <a onClick={()=>{
     this.setState({viewingOperation: outputOperation, viewingInterface: outputInterface});
     }}>{outputOperation.identity.name}</a></span> : null}

     {this.state.viewingResponse ?
     <ResponseDialog
     response={this.state.viewingResponse}
     onClose={()=>this.setState({viewingResponse: null})}
     isEditable={0}
     isVisible
     majorObject={this.state.viewingInterface}
     /> : null}

     {this.state.viewingOperation ?
     <OperationDialog
     operation={this.state.viewingOperation}
     onClose={()=>this.setState({viewingOperation: null})}
     isEditable={0}
     isVisible
     majorObject={this.state.viewingInterface}
     parameters={[]}
     responses={[]}
     /> : null}
     </div>;
     }
     */
  }

  render() {
    return (
      <EditorDialog
        hideFullscreenButton
        level={0}
        ref="dialog"
        objectType={this.state.fullScreen ? 'pipeline_chart_fullscreen' : 'pipeline_chart'}
        modalTitle={this.props.pipeline ? 'Pipeline chart for ' + this.props.pipeline.identity.name : ''}
        confirmText={this.props.activity ? 'Update' : 'Create'}
        editContent={this.renderEditor}
        footerContent={this.renderFooter}
        headerContent={this.renderHeader}
        editHeight={this.state.height}
        isEditable={this.props.isEditable}
        isVisible
        onClose={this.onClose.bind(this)}
        onSave={this.saveActivity}
        onEdit={this.startEdit}
        onCancel={this.cancelEdit}
      />
    )
  }
}
