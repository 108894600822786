/**
 * Created by kascode on 13.04.16.
 */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu'
import { columnsToType } from '../../helpers'
import { Loader } from '../Loader/Loader'
import { ModalWindow } from '../ModalWindow/ModalWindow'
import './style.scss'
import TableRow from './TableRow'

import iWarning from '../../resources/images/warning_2x.png'

class Table extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tableId: Math.random().toString(16).substr(2, 8),
      newData: [],
      editingRowIndex: -1,
      inEditMode: props.inEditMode ? props.inEditMode : false,
      isDeleting: props.isDeleting ? props.isDeleting : false,
      deletingObject: false,
      dirty: false,
      expandedRowIndexes: [],
      mouseOverRow: -1
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.inEditMode !== this.state.inEditMode)
      this.setState({
        inEditMode: props.inEditMode
      })
    if (props.isDeleting !== this.state.isDeleting)
      this.setState({
        isDeleting: props.isDeleting
      })
  }

  toggleEditMode() {
    if (this.props.onToggleEditMode) {
      let canEdit = this.props.onToggleEditMode(!this.state.inEditMode)
      if (!canEdit) return
    }
    this.setState({
      inEditMode: !this.state.inEditMode,
      isDeleting: false
    })
  }

  toggleDeleting() {
    this.setState({
      isDeleting: !this.state.isDeleting
    })
  }

  toggleExpandedRow(rowIndex, rowReference) {
    console.log('Table:toggleExpandedRow', rowIndex, rowReference, this.state.expandedRowIndexes)

    if (this.state.expandedRowIndexes.includes(rowIndex)) {
      this.setState({
        expandedRowIndexes: this.state.expandedRowIndexes.filter((index) => index !== rowIndex)
      })
    } else {
      if (this.props.onExpandRow) {
        this.props.onExpandRow(rowIndex, rowReference)
      }
      this.setState({
        expandedRowIndexes: this.state.expandedRowIndexes.concat(rowIndex)
      })
    }
  }

  add() {
    const dataSource = this.state.dirty ? this.state.newData : this.props.data
    const id = dataSource.reduce((prev, item) => Math.max(prev, item.id), 0) + 1

    let onAddRes = null
    if (this.props.onAdd) {
      onAddRes = this.props.onAdd(id)
      if (!onAddRes) return
    }

    let newItem = { id: id }
    this.props.columns.map((col) => {
      newItem[col.name] = col.type.name === 'enum' ? col.type : ''
    })
    if (onAddRes && typeof onAddRes === 'object') newItem = Object.assign(newItem, onAddRes)

    // //console.log("Table add new item", newItem);

    this.setState({
      editingRowIndex: id,
      editingField: {
        rowId: id,
        columnName: 'type',
        type: 'type',
        value: ''
      },
      newData: dataSource.concat(newItem),
      dirty: true
    })
    // //console.log("table add: ", newItem);
  }

  /**
   * Handles upload event, file is passed to onUpload
   * @param e
   */
  upload = (e) => {
    if (this.props.onUpload) {
      let files = []

      if (e.dataTransfer) {
        files = e.dataTransfer.files
      } else if (e.target) {
        files = e.target.files
      }
      files = [].slice.call(files)

      this.props.onUpload(files.length ? files[0] : [])
    }

    document.getElementById('objectLoad').value = ''
  }

  /**
   * Deletes row which has id==index
   * @param index
   * @param obj
   */
  realDelete(index, obj) {
    this.setState({ deletingObject: null })

    if (this.props.onDelete) {
      if (!this.props.onDelete(index, obj)) {
        return
      }
    }

    const dataSource = this.state.dirty ? this.state.newData : this.props.data
    this.setState({
      newData: dataSource.filter((element, i) => element.id !== index),
      dirty: true
    })
  }

  /**
   * if immediateDelete, deletes row, else displays delete confirmation window
   * @param index
   * @param obj
   * @returns {Function}
   */
  delete(index, obj) {
    return () => {
      console.log('table delete ' + index, obj)
      if (this.props.immediateDelete) {
        this.realDelete(index, obj)
      } else {
        this.setState({ deletingObject: { index: index, obj: obj } })
      }

      /*
       */
    }
  }

  /**
   * Returns array of props values from object
   * @param {object} obj
   * @param {string[]} props
   * @returns {string[]}
   */
  filterProps(obj, props) {
    let res = []

    for (let i = 0; i < props.length; i++) {
      const val = obj[props[i].name]
      if (val) res.push(val)
      else res.push('')
    }

    return res
  }

  /**
   * Partial function that accepts name of property to change and returns function that accepts value and changes it
   * accordingly
   * @param label
   * @returns {function()}
   */
  onValueChange(label) {
    const dataSource = this.state.newData.length ? this.state.newData : this.props.data

    return (event) => {
      const value = event.target.value
      console.log('Table:onValueChange', label, value, this.props.onValueChange)
      const updatedProp = {}
      updatedProp[label] = value
      this.setState(
        {
          editingField: Object.assign({}, this.state.editingField, {
            value: value
          })
        },
        () => {
          if (this.props.onValueChange) {
            let newData = dataSource.map((dataRow) => {
              if (this.state.editingField && dataRow.id === this.state.editingField.rowId) {
                const newRow = {}
                newRow[this.state.editingField.columnName] = this.state.editingField.value
                //console.log("change row"+dataRow.id +" columns " + this.state.editingField.columnName + " to ",this.state.editingField.value);
                return Object.assign({}, dataRow, newRow)
              } else {
                return dataRow
              }
            })

            this.props.onValueChange(newData, this.props.dataset, this.props.dataPath)
          }
        }
      )
    }
  }

  editField(id, column, type, value) {
    // //console.log("editField", "id:", id, "column:", column, "type:", type, "value:", value);
    if (this.props.isEditableByRow) {
      if (this.state.inEditMode) {
        this.props.onEditRow(id, column, type, value)
      }
      return
    }
    ////console.log("editField", id, column, type, value);
    if (id === undefined) {
      console.error('EDITING TABLE WITH ROW ID UNDEFINED!')
    }
    // if (this.state.inEditMode || true) {
    if (this.state.editingRowIndex === id && this.state.editingField && this.state.editingField.columnName === column) {
      return
    }

    this.setState({
      editingRowIndex: id,
      editingField: {
        rowId: id,
        columnName: column,
        type,
        value
      }
    })
    // }
  }

  applyEdit(done = null) {
    const dataSource = this.state.newData.length ? this.state.newData : this.props.data
    if (this.props.externalControl) {
      let newData = dataSource.map((dataRow) => {
        if (dataRow.id === this.state.editingField.rowId) {
          const newRow = {}
          newRow[this.state.editingField.columnName] = this.state.editingField.value
          //console.log("change row"+dataRow.id +" columns " + this.state.editingField.columnName + " to ",this.state.editingField.value);
          return Object.assign({}, dataRow, newRow)
        } else {
          return dataRow
        }
      })

      if (this.props.onEdit) {
        this.props.onEdit(newData, this.props.dataset, this.props.dataPath)
      }
      this.setState(
        {
          dirty: false,
          editingField: null
        },
        () => {
          if (!this.props.showSave) this.saveChanges()
          if (done && typeof done === 'function') done()
        }
      )
    } else {
      const newData = dataSource.map((dataRow) => {
        //console.log("Table:applyEdit", dataSource, dataRow, deepCopy(this.state.editingField));
        if (dataRow.id === this.state.editingField.rowId) {
          const newRow = {}
          newRow[this.state.editingField.columnName] = this.state.editingField.value
          return Object.assign({}, dataRow, newRow)
        }
        return dataRow
      })
      //console.log("newData", newData);
      this.setState(
        {
          newData: newData,
          editingField: null,
          dirty: true
        },
        () => {
          if (this.props.onEdit) this.props.onEdit(newData)
          if (done && typeof done === 'function') done()
          if (!this.props.showSave) this.saveChanges()
        }
      )
    }
  }

  /**
   * fires onSave event and clears dirty state
   */
  saveChanges() {
    ////console.log("Table saveChanges",this.state.newData.length ? this.state.newData : this.props.data)
    this.props.onSave(this.state.dirty ? this.state.newData : this.props.data)
    this.setState({
      newData: [],
      inEditMode: /*false*/ this.props.externalControl && this.props.inEditMode,
      dirty: false
    })
  }

  /**
   * cancel changes, clear dirty state, fire onCancel event
   */
  cancelChanges() {
    this.setState({
      newData: [],
      inEditMode: false,
      isDeleting: false,
      dirty: false
    })
    if (this.props.onCancel) this.props.onCancel()
  }

  clearAllFilters(e) {
    e.preventDefault()
    this.props.onClearAllFilters()
  }

  /**
   * process Enter key and apply edit if needed
   * @param event
   */
  keyPress = (event) => {
    ////console.log("keyyPRess",event,event.keyCode);
    const charCode = event.which || event.charCode || event.keyCode || 0

    if (charCode === 13 && this.props.isEditable && this.props.showAdd) {
      this.applyEdit(() => {
        for (let i in this.props.columns) {
          let column = this.props.columns[i]
          if (!column.frozen) {
            //console.log("Edit row index=", this.state.editingRowIndex);
            //console.log("Found first column", column);
            this.add()
            this.editField(this.state.editingRowIndex + 1, column.name, column.type, '')
            break
          }
        }
      })
      //this.setState({editingRowIndex: this.state.editingRowIndex + 1});
    }
  }

  onCopyRow(rowId, row) {
    const dataSource = this.state.dirty ? this.state.newData : this.props.data
    const id = dataSource.reduce((prev, item) => Math.max(prev, item.id), 0) + 1

    if (this.props.onCopy) {
      if (!this.props.onCopy(rowId, row, id)) {
        return
      }
    }

    let newItem = { id: id }
    this.props.columns.map((col, index) => {
      newItem[col.name] = row[col.name]
    })

    this.setState({
      editingRowIndex: id,
      editingField: {
        rowId: id,
        columnName: 'type',
        type: 'type',
        value: ''
      },
      newData: dataSource.concat(newItem),
      dirty: true
    })
  }

  /**
   * renders delete confirmation window for row, if needed
   * @returns {*}
   */
  renderDeleteConfirmWindow() {
    if (this.state.deletingObject) {
      return (
        <ModalWindow
          header={'Delete'}
          canClose
          isOpen
          className="OrganizationList__deleteModal Table__deleteModal"
          onClose={() => this.setState({ deletingObject: null })}
          footer={
            <div className="OrganizationList__deleteButtons">
              <button className="btn_big" onClick={() => this.setState({ deletingObject: null })}>
                No
              </button>
              <button
                className="btn_big"
                onClick={() => this.realDelete(this.state.deletingObject.index, this.state.deletingObject.obj)}
              >
                Yes
              </button>
            </div>
          }
        >
          {this.state.deletingObject.obj ? (
            <div className="OrganizationList__deleteMessage">
              <p>
                <img src={iWarning} />
              </p>
              <p>
                Do you really want to delete{' '}
                {this.state.deletingObject && this.state.deletingObject.obj && this.state.deletingObject.obj.identity
                  ? this.state.deletingObject.obj.identity.name
                  : 'this item'}
                ?
              </p>
            </div>
          ) : (
            <Loader />
          )}
        </ModalWindow>
      )
    } else {
      return null
    }
  }

  /**
   * @param {Number} rowId
   * @param {*[]} cellValues
   * @param {String[]} columns
   * @param index
   * @param {String} valueType
   * @returns {XML} Editable table row
   */
  renderTableEditableRow(rowId, cellValues, columns, index, valueType) {
    const dataSource = this.state.dirty ? this.state.newData : this.props.data
    let data = dataSource[index]
    let path =
      this.props.dataPath === null
        ? null
        : this.props.dataPath + (this.props.dataPath.length > 0 ? '.' : '') + data.identity.name
    let object = path === null || this.props.dataObject === null ? null : this.props.dataObject[data.identity.name]
    let tableId = this.state.tableId

    //console.log("Table:renderTableEditableRow", this.props, data, path, object);
    let row = (
      <TableRow
        tableId={tableId}
        rowId={rowId}
        key={index}
        index={index}
        value={data}
        columns={columns}
        cellValues={cellValues}
        dataPath={path}
        dataObject={object}
        onEdit={this.props.dataPath !== null ? this.props.onEdit : null}
        valueType={valueType}
        setMouseOverRow={(index) => this.setState({ mouseOverRow: index })}
        isMouseOverRow={this.state.mouseOverRow === index}
        isLast={index === dataSource.length - 1}
        onExpandData={this.props.onExpandData}
        onExpandRow={this.props.onExpandRow}
        expandedRowIndexes={this.state.expandedRowIndexes}
        applyEdit={this.applyEdit.bind(this)}
        editField={this.editField.bind(this)}
        onValueChange={this.onValueChange.bind(this)}
        toggleExpandedRow={this.toggleExpandedRow.bind(this)}
        delete={this.delete.bind(this)}
        editingField={this.state.editingField}
        showDelete={this.props.showDelete}
        isEditable={this.props.isEditable && this.state.inEditMode}
        isEditableByRow={this.props.isEditableByRow}
        onEditClick={() => this.props.onEditRow(rowId, '', '', dataSource[index])}
        showFullTail={this.props.showFullTail}
        showCopy={this.props.showCopy}
        onCopyClick={() => this.onCopyRow(rowId, dataSource[index])}
        onTableValueChange={this.props.onValueChange}
      />
    )

    if (this.props.contextMenu) {
      return (
        <ContextMenuTrigger
          id={'contextmenu_' + tableId}
          index={index}
          rowId={rowId}
          collect={() => {
            return { index: index, value: data }
          }}
        >
          {row}
        </ContextMenuTrigger>
      )
    } else {
      return row
    }
  }

  renderFiltersCustom() {
    const clearFiltersWidth = 200
    const edgeWidthFix = 5 // fix last cell going to next line in Edge
    const { columns, filters, dataFilters } = this.props
    const fullWidth = columns.reduce((p, col) => p + col.width, 0) - clearFiltersWidth
    const filtersWidth = dataFilters.reduce((p, col) => p + col.width, 0)

    return (
      <tr className="Table__filters">
        <th colSpan="100">
          <div className="Table__customFilterCell" style={{ width: clearFiltersWidth + 'px' }}>
            Filters: &nbsp;&nbsp;&nbsp;&nbsp;
            <a className="Table__clearFilter" onClick={this.clearAllFilters.bind(this)}>
              Clear
            </a>
          </div>
          {dataFilters.map((filter, index) => {
            const width = filter.width
            const style = { width: width + 'px' }

            return (
              <div className="Table__customFilterCell" key={index} style={style}>
                {filters[filter.name] || '\u00a0'}
              </div>
            )
          })}
          {filtersWidth < fullWidth &&
          (this.props.isEditable || this.props.isEditableByRow) &&
          !this.props.externalControl ? (
            <div
              className="Table__customFilterCell"
              key={filters.length + 1}
              style={{ width: fullWidth - filtersWidth - edgeWidthFix + 'px' }}
            >
              <div className="Table__edit-control">
                {this.props.showAdd ? (
                  <span className="btn_edit" onClick={this.add.bind(this)}>
                    + {'Add'}
                  </span>
                ) : null}
              </div>
            </div>
          ) : null}
        </th>
      </tr>
    )
  }

  renderFilters() {
    const { columns, filters, dataFilters } = this.props

    //console.log("renderFilters",dataFilters);

    if (dataFilters && dataFilters.length && typeof dataFilters[0] === 'object') {
      //console.log("1st filter",dataFilters[0],typeof(dataFilters[0]));
      return this.renderFiltersCustom()
    }

    let unused_filters = []
    if (filters)
      unused_filters = Object.keys(filters).filter((filterName) => {
        for (let col of columns) {
          if (col.name === filterName) return false
        }
        return true
      })

    return (filters && Object.keys(filters).length) ||
      (this.props.isEditable && !this.props.externalControl) ||
      this.props.showAdd ? (
      <tr className="Table__filters">
        {columns.map((column, index) => {
          let style = this.props.columns[index].width
            ? {
                width: this.props.columns[index].width,
                boxSizing: 'border-box'
              }
            : {}
          // last column
          if (
            index === columns.length - 1 &&
            (this.props.isEditable || this.props.isEditableByRow) &&
            !this.props.externalControl
          ) {
            return (
              <th key={index}>
                <div className="Table__edit-control">
                  {this.props.showAdd ? (
                    <span className="btn_edit" onClick={this.add.bind(this)}>
                      + {'Add'}
                    </span>
                  ) : null}
                </div>
              </th>
            )
          }
          // if column name equals on of filter names
          else if (filters && column.name in filters) {
            return (
              <th key={index} style={style}>
                {filters[column.name]}
              </th>
            )
          } else if (filters) {
            if (index === 0)
              if (Object.keys(filters).length)
                return (
                  <th key={index} style={style}>
                    Filters: &nbsp;&nbsp;&nbsp;&nbsp;
                    <a className="Table__clearFilter" onClick={this.clearAllFilters.bind(this)}>
                      Clear
                    </a>
                  </th>
                )
              else return <th key={index}></th>
            else if (index > 1 || column.name === 'description') {
              if (unused_filters.length > 0 && column.width > 40) {
                // do not put filters in too small columns
                return (
                  <th key={index} style={style}>
                    {filters[unused_filters.shift()]}
                  </th>
                )
              } else {
                return <th style={style}></th>
              }
            } else {
              return <th style={style}></th>
            }
          } else {
            return <th style={style}></th>
          }
        })}
      </tr>
    ) : null
  }

  renderContextMenu() {
    if (this.props.contextMenu) {
      return [
        <React.Fragment key={this.props.contextMenu.length}>
          <ContextMenu id={'contextmenu_' + this.state.tableId}>
            {this.props.contextMenu.map((item, idx) => (
              <MenuItem key={idx} data={item.data} onClick={item.onClick}>
                {item.label}
              </MenuItem>
            ))}
          </ContextMenu>
          <ContextMenu id={'contextmenubottom_' + this.state.tableId}>
            {this.props.contextMenu
              .filter((item) => item.showInBottom)
              .map((item, idx) => (
                <MenuItem key={idx} data={item.data} onClick={item.onClick}>
                  {item.label}
                </MenuItem>
              ))}
          </ContextMenu>
        </React.Fragment>
      ]
    }
  }

  render() {
    const { columns } = this.props

    const data = this.props.externalControl ? this.props.data : this.state.dirty ? this.state.newData : this.props.data
    const filters = this.props.filters
    let tableId = this.state.tableId

    //console.log("Table:render", this.state.tableId, this.props);

    return (
      <div className="Table__container" style={this.props.style}>
        {this.renderDeleteConfirmWindow()}

        {this.renderContextMenu()}

        <table
          cellPadding="5"
          className={
            'Table' +
            (this.props.theme ? ' Table_' + this.props.theme : '') +
            (this.props.isEditable ? ' Table_editable' : '') +
            (this.props.className ? ' Table_' + this.props.className : '')
          }
          onKeyPress={this.keyPress}
        >
          {!this.props.hideHeader ? (
            <thead>
              {this.renderFilters()}
              <tr>
                {columns.map((column, index) => {
                  if (this.state.expandedColumns && this.state.expandedColumns[index]) {
                    if (column.type.name === 'text') {
                      column.type.name = 'text_expanded'
                    }
                  } else {
                    if (column.type.name === 'text_expanded') {
                      column.type.name = 'text'
                    }
                  }
                  /* to make column expandable, add to it showExpandButton:true, columns are not automatically made expandable
                   * state is stored in Table.state.expandedColumns, which is initially undefined and then initialized by array of zeros
                   * when column is expanded, it's type is changed to 'text_expanded' if it was 'text', this type is defined in helpers and has alternate maxLineCount (number of lines visible by default)
                   * if expanded column has any other types, it is unchanged
                   * columns are expanded individually
                   * */

                  let style = this.props.columns[index].width
                    ? {
                        width: this.props.columns[index].width,
                        boxSizing: 'border-box'
                      }
                    : {}
                  return (
                    <th key={index} style={style} className={'TableHeaderCell TableHeaderCell_' + column.name}>
                      {column.displayName ? column.displayName : column.name}
                      {column.showExpandButton ? (
                        <button
                          className="TableHeaderCellExpander TableRefExpander__btn"
                          onClick={() =>
                            this.setState({
                              expandedColumns: (this.state.expandedColumns || columns.map((c) => 0)).map((c, i) =>
                                i === index ? !c : c
                              )
                            })
                          }
                        >
                          <div className="TableRefExpander">
                            <div className="TableRefExpander__btn">
                              <span>+</span>
                            </div>
                          </div>
                        </button>
                      ) : null}
                    </th>
                  )
                })}
              </tr>
            </thead>
          ) : null}
          <tbody
            style={{
              maxHeight: this.props.maxHeight ? this.props.maxHeight : 'none'
            }}
          >
            {data.map((el, index) => {
              // If row contains columns which type determined by value of other column
              // pass this value as valueType
              let selfCol = false
              for (let i = 0; i < columns.length; i++) {
                let col = columns[i]
                if (col.type.name === 'self') selfCol = i
              }
              const valueType = selfCol >= 0 ? columnsToType.getType(el.type) : false
              return this.renderTableEditableRow(el.id, this.filterProps(el, columns), columns, index, valueType)
            })}
          </tbody>
        </table>
        {this.props.showAdd ||
        this.props.showDelete ||
        this.props.showUpload ||
        this.props.showSave ||
        this.props.errorText /*&& this.state.inEditMode */ ? (
          <ContextMenuTrigger
            id={'contextmenubottom_' + tableId}
            collect={() => {
              return {}
            }}
            disable={!this.props.contextMenu}
          >
            <div className="Table__add">
              {this.props.additionalButtons ? this.props.additionalButtons : null}
              {this.props.showAdd ? (
                <span className="btn_edit" onClick={this.add.bind(this)}>
                  + {this.props.addDeleteText ? this.props.addDeleteText.add : 'Add'}
                </span>
              ) : null}
              {this.props.showUpload ? (
                <span>
                  <input type="file" id="objectLoad" className="FileInput_hidden" onChange={this.upload} />
                  <label className="btn_edit" htmlFor="objectLoad">
                    + {this.props.addDeleteText ? this.props.addDeleteText.upload : 'Upload'}
                  </label>
                </span>
              ) : (
                ''
              )}
              {this.props.showSave && this.state.dirty ? (
                <span className="btn_edit btn_save" onClick={this.saveChanges.bind(this)}>
                  Save
                </span>
              ) : null}
              {this.props.showSave && this.state.dirty ? (
                <span className="btn_edit" onClick={this.cancelChanges.bind(this)}>
                  Cancel
                </span>
              ) : null}
              {this.props.errorText ? <span className="error">{'Error: ' + this.props.errorText}</span> : null}
            </div>
          </ContextMenuTrigger>
        ) : (
          ''
        )}
      </div>
    )
  }
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      type: PropTypes.shape({
        name: PropTypes.string.isRequired,
        options: PropTypes.array
      })
    })
  ).isRequired,
  dataset: PropTypes.object,
  dataPath: PropTypes.string,
  dataObject: PropTypes.object,
  filters: PropTypes.object,
  theme: PropTypes.string,
  hideHeader: PropTypes.bool,
  isEditable: PropTypes.bool,
  isEditableByRow: PropTypes.bool, // uses event onEditRow instead of normal editing
  isDeletable: PropTypes.bool,
  editIndex: PropTypes.number,
  //  widths: PropTypes.arrayOf(PropTypes.number),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func, // returns true if default delete operations is needed
  onEditRow: PropTypes.func,
  locale: PropTypes.string,
  maxHeight: PropTypes.number,
  externalControl: PropTypes.bool,
  showAdd: PropTypes.bool,
  showDelete: PropTypes.bool,
  showSave: PropTypes.bool,
  showUpload: PropTypes.bool,
  showCopy: PropTypes.bool,
  additionalButtons: PropTypes.string, // HTML to display before Add/Delete buttons
  addDeleteText: PropTypes.shape({
    add: PropTypes.string,
    delete: PropTypes.string,
    deleteCancel: PropTypes.string,
    upload: PropTypes.string
  }),
  inEditMode: PropTypes.bool,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  style: PropTypes.object,
  onToggleEditMode: PropTypes.func,
  isDeleting: PropTypes.bool,
  onUpload: PropTypes.func,
  onClearAllFilters: PropTypes.func,
  className: PropTypes.string,
  onExpandData: PropTypes.func,
  onExpandRow: PropTypes.func,
  showFullTail: PropTypes.bool,
  errorText: PropTypes.string,
  onCopy: PropTypes.func,
  contextMenu: PropTypes.array /* for items */,
  contextMenuBottom: PropTypes.array /* for bottom line with no items, to allow Paste to empty table */,
  onValueChange:
    // eslint-disable-next-line react/no-typos
    PropTypes.function /* called for each key press or other change in table-not blur only */
}

export default Table
