import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import logger from '../../helpers/logger'
import { displayReferenceUri, editableState } from '../../helpers/index'

import './OutputsDialog.scss'
import { OutputList } from './OutputList'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'

const OUTPUT_HEIGHT = 50

class OutputsDialog extends EditorDialogBase {
  constructor(props) {
    super(props)
    console.log('OutputsDialog::constructor', this.state)
  }

  getObjectType() {
    return 'outputs'
  }

  /**
   * Returns list of adjustments of height based on object elements.
   * Name - is a name of parameter to use,
   * Value - adjustment for element.
   */
  getElementsHeight() {
    // We have lines of settings on settings array
    return { __line: OUTPUT_HEIGHT }
  }

  getIconHtml() {
    return <div className="EditorDialogItem__buttonImg OutputsDialog__icon"></div>
  }

  saveOutputs = (outputs, closeDialog, onSent) => {
    onSent(closeDialog)
    //logger.info("OutputsDialog:saveOutputs", outputs);

    this.Change(outputs)
  }

  renderEditor = (editState) => {
    //logger.info("OutputsDialog:renderEditor", this.state);
    return (
      <div className="OutputsDialog" onClick={() => this.onCheckDialogHeight('.OutputsDialog')}>
        <OutputList
          appState={this.props.appState}
          actions={this.props.actions}
          isVisible
          isEditable={editState >= editableState.EDITABLE ? editState : editableState.BROWSABLE}
          majorObject={this.props.majorObject}
          type={'output'}
          items={this.state.outputs}
          activity={this.props.activity}
          activities={this.props.activities}
          datasets={this.props.datasets}
          interfaces={this.props.interfaces}
          onEditableChange={this.onEditableChange}
          onSave={this.saveOutputs}
        />
      </div>
    )
  }

  /**
   * Render view of component when window is closed
   * @returns {*}
   */
  renderItem(s, i) {
    // render one rule.
    return (
      <div className="EditorDialogItem row" key={i}>
        <div className="EditorDialogItem__name col">{s.identity.name + ': '}</div>
        <div className="EditorDialogItem__value col">
          {s.component ? displayReferenceUri(s.component.reference, s.component.subscription) : ''}
        </div>
      </div>
    )
  }
}

OutputsDialog.propTypes = {}

//export default OutputDialog;

function mapStateToProps(state) {
  return {
    actions: state.actions,
    appState: state.appState
  }
}

export default connect(mapStateToProps, null)(OutputsDialog)
