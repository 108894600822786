/**
 * Created by Sasha Berger on 05.03.20.
 *
 * Component to edit reference to deployment inside environment.
 * Component render reference to packages of the deployment inside environment.
 */
import PropTypes from 'prop-types'
import React from 'react'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import './DeploymentDialog.scss'

export class DeploymentDialog extends EditorDialogBase {
  getObjectType() {
    return 'deploymentReference'
  }

  /**
   * Init dialog height
   * @param props - set of initialization properties
   */
  InitHeight(props) {
    // Don't need logic here.
    return 445
  }

  /**
   * On open dialog initialize array of controls available for Get initial value of parameter
   */
  open = () => {
    this.loadMetadata(
      '/organizations/Apdax/systems/DifHub/applications/System/datasets/DeploymentReference',
      '/organizations/Apdax/systems/DifGate/applications/System/views/DeploymentDialog'
    )

    //logger.info("DeploymentDialog:open", this.state, this.props);
  }

  checkInputErrors(deploymentReference) {
    let inputError = ''
    if (!deploymentReference.reference) {
      inputError += 'Please select deployment for environment!'
    } else if (!deploymentReference.packageNames) {
      inputError += 'Please select deployment packages used in environment!'
    }
    return inputError
  }

  renderEditor = (editState) => {
    //logger.info("DeploymentDialog:renderEditor", { editState, deploymentReference }, this.state, this.props);

    return <div className="DeploymentDialog">{this.renderView(editState)}</div>
  }
}

DeploymentDialog.propTypes = {
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  deploymentReference: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func
}
