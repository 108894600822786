/**
 * Created by mailf on 25.05.2016.
 */
import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import ObjectSearch from '../ObjectSearch/ObjectSearch'
import { getFullUri } from '../../helpers'

class TypeSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentType: false,
      inEditMode: true,
      structName: false,
      enumOptions: false,
      enumsLoaded: false
    }
  }

  toggleEditMode() {
    this.setState({
      inEditMode: !this.state.inEditMode
    })
  }

  onTypeSelect(e) {
    this.setState(
      {
        currentType: e.target.value.name
      },
      () => {
        this.props.onSelect(e)
      }
    )
  }

  onSelectBlur() {
    if (
      (this.state.currentType && this.state.currentType !== 'enum' && this.state.currentType !== 'structure') ||
      ((this.state.currentType === 'enum' || this.state.currentType === 'structure') && this.state.structName)
    )
      this.onSelect()
  }

  onSelect() {
    let t = {
      name: this.state.currentType
    }

    if ((this.state.currentType === 'enum' || this.state.currentType === 'structure') && this.state.structName) {
      t['structName'] = this.state.structName
      t['options'] = this.state.enumOptions
    }

    if (this.props.onBlur)
      this.props.onBlur({
        target: {
          value: t
        }
      })
  }

  onDatasetSelect(dataset) {
    this.setState(
      {
        structName: dataset.identity.name
      },
      () => {
        if (this.state.structName /* && this.state.enumOptions*/) {
          this.props.onSelect({
            target: {
              value: {
                name: this.state.currentType,
                options: this.state.enumOptions,
                structName: this.state.structName,
                reference: dataset.object.parent ? getFullUri(dataset) : '/' + dataset.identity.name
              }
            }
          })
          setTimeout(() => {
            //this.onSelect();
          }, 100)
        }
      }
    )
  }

  render() {
    return (
      <div className="TypeSelector">
        <EditableEntity
          dataType={{ name: 'enum', options: this.props.options }}
          dataProps={{
            onBlur: this.onSelectBlur.bind(this),
            onChange: this.onTypeSelect.bind(this),
            autofocus: this.props.autoFocus
          }}
          isEditable
          inEditMode={this.state.inEditMode}
          data={this.state.currentType ? this.state.currentType : 'Select type'}
        />
        {this.state.currentType === 'enum' || this.state.currentType === 'structure' ? (
          <ObjectSearch
            types={['datasets']}
            appState={this.props.appState}
            filter={(item, itemType, query) => {
              /*
           //console.log("item", item);
           const usage = item.object.properties.filter(el => el.name === 'usage')[0];
           return item.identity.name &&
           item.identity.name.toLowerCase().indexOf(query) > -1 &&
           item.object.properties &&
           usage &&
           usage.value === 'enum';
           */
              return true
            }}
            placeholder={this.state.structName}
            onSelect={this.onDatasetSelect.bind(this)}
          />
        ) : null}
      </div>
    )
  }
}

TypeSelector.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object])
    })
  ).isRequired,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  appState: PropTypes.object,
  structName: PropTypes.string,
  autoFocus: PropTypes.bool
}

function mapStateToProps(state) {
  return {
    appState: state.appState
  }
}

export default connect(mapStateToProps, null)(TypeSelector)
