/**
 * Created by sashab on 05.12.2020.
 *
 * Managing list of keys for dataset structure.
 */
import React from 'react'
import { connect } from 'react-redux'
import { editableState } from '../../helpers/index'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'
import { KeyList } from './KeyList'
import './KeysDialog.scss'

const KEY_HEIGHT = 60

class KeysDialog extends EditorDialogBase {
  getObjectType() {
    return 'keys'
  }

  /**
   * Returns list of adjustments of height based on object elements.
   * Name - is a name of parameter to use,
   * Value - adjustment for element.
   */
  // @ts-ignore
  getElementsHeight() {
    // We have lines of settings on settings array
    return { __line: KEY_HEIGHT }
  }

  saveInputs = (keys, closeDialog, onSent) => {
    if (onSent) onSent(closeDialog)
    //console.log("KeysDialog:saveRules", inputs);

    this.Change(keys)
  }

  renderEditor = (editState) => {
    //console.log("KeysDialog:renderEditor", this.state);
    return (
      <div className="KeysDialog" onClick={() => this.onCheckDialogHeight('.KeysDialog')}>
        <KeyList
          appState={this.props.appState}
          actions={this.props.actions}
          isVisible
          isEditable={editState >= editableState.EDITABLE ? editState : editableState.BROWSABLE}
          majorObject={this.props.majorObject}
          type={'key'}
          items={this.state.keys}
          onEditableChange={this.onEditableChange}
          onSave={this.saveInputs}
        />
      </div>
    )
  }
}

//export default InputDialog;

function mapStateToProps(state) {
  return {
    actions: state.actions,
    appState: state.appState
  }
}

export default connect(mapStateToProps, null)(KeysDialog)
