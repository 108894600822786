import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { uniq, cloneDeep } from 'lodash'
import { useSelector } from 'react-redux'
import axios from 'axios'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import { Avatar, Checkbox, Input, ListItemAvatar, ListItemText } from '@material-ui/core'

import { getUserPhotoUrl, issuePriorityLabel } from '../../helpers'
import { IRootState } from '../../reducers/rootState'
import { fetchDataUrlImmediate } from '../../helpers/api'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16)
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}))

export const KanbanHeader: React.FC<any> = ({ orgs, selectedOrg, issues, onFiltersChange }) => {
  const classes = useStyles()
  const [types, setTypes] = useState<string[]>([])
  const [selectedType, setSelectedType] = useState<string[]>([])
  const [selectedUsers, setSelectedUsers] = useState<any[]>([])
  const { identity } = useSelector((state: IRootState) => state.userAppState)
  const [selectedPriority, setSelectedPriority] = useState<string[]>([])
  const [showMy, setShowMy] = useState(false)
  const { organizationName } = useParams<{ organizationName: string }>()
  const [organizationUsers, setOrganizationUsers] = useState<any>([])
  const { push } = useHistory()

  useEffect(() => {
    if (issues.length) {
      setTypes(uniq(issues.map((issue) => issue.type)))
    }
  }, [issues.length])

  useEffect(() => {
    if (organizationName && identity) {
      axios.get(`${process.env.REACT_APP_API}/organizations/${organizationName}/users`).then(({ data }) => {
        const mainUserIndex = data.findIndex((user) => user.profile.identity.id === identity.id)
        const mainUser = cloneDeep(data[mainUserIndex])
        data.splice(mainUserIndex, 1)
        data.unshift(mainUser)
        setOrganizationUsers(data)
      })
    }
  }, [organizationName, identity])

  useEffect(() => {
    onFiltersChange([
      {
        path: 'fullType.type',
        value: selectedType
      },
      {
        path: 'fullPriority.code',
        value: selectedPriority
      },
      {
        path: 'assigned.id',
        value: showMy ? identity.id : ''
      },
      {
        path: 'assigned.id',
        value: selectedUsers.map((user) => user.profile.identity.id)
      }
    ])
  }, [selectedPriority, selectedType, showMy, selectedUsers])

  const handleOrganizationChange = (event) => {
    push(`/kanban/${event.target.value}`)
  }

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedType(event.target.value as string[])
  }

  const handleUserChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedUsers(event.target.value as string[])
  }

  const handlePriorityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedPriority(event.target.value as string[])
  }

  const handleShowMyChange = () => {
    setShowMy(!showMy)
  }

  return (
    <Paper elevation={0} style={{ display: 'grid', gridAutoFlow: 'column', gridGap: '1rem', alignItems: 'center' }}>
      <FormControl variant="outlined" size="small" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Organization:</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={selectedOrg}
          onChange={handleOrganizationChange}
          label="Organization"
        >
          {orgs?.map((org) => (
            <MenuItem key={org.identity.id} value={org.identity.name}>
              {org?.identity?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography component="div">
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>All</Grid>
          <Grid item>
            <Switch checked={showMy} onChange={handleShowMyChange} />
          </Grid>
          <Grid item>My</Grid>
        </Grid>
      </Typography>

      <FormControl variant="outlined" size="small" className={classes.formControl}>
        <InputLabel id="multiple-checkbox-label">Type</InputLabel>
        <Select
          variant="outlined"
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={selectedType}
          label={'Type'}
          onChange={handleTypeChange}
          renderValue={(selected) => (selected as string[]).join(', ')}
        >
          {types.map((type) => (
            <MenuItem key={type} value={type}>
              <Checkbox size="small" checked={selectedType.indexOf(type) > -1} />
              <ListItemText primary={type} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" size="small" className={classes.formControl}>
        <InputLabel id="multiple-checkbox-label">User</InputLabel>
        <Select
          variant="outlined"
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={selectedUsers}
          label={'User'}
          onChange={handleUserChange}
          renderValue={(selected: any) => (selected.map((user) => user.profile.alias) as string[]).join(', ')}
        >
          {organizationUsers.map((user) => (
            <MenuItem key={user.profile.alias} value={user}>
              <Checkbox
                size="small"
                checked={selectedUsers.some((selectedUser) => user.profile.alias === selectedUser.profile.alias)}
              />
              <ListItemAvatar>
                <Avatar
                  alt={user.profile.alias}
                  src={fetchDataUrlImmediate(getUserPhotoUrl(user.profile.identity.id))}
                />
              </ListItemAvatar>
              <ListItemText primary={user.profile.alias} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" size="small" className={classes.formControl}>
        <InputLabel id="multiple-checkbox-label">Priority</InputLabel>
        <Select
          variant="outlined"
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={selectedPriority}
          label={'Priority'}
          onChange={handlePriorityChange}
          renderValue={(selected) => (selected as string[]).map((priority) => issuePriorityLabel(priority)).join(', ')}
        >
          {['0', '1', '2', '3', '4'].map((priority) => (
            <MenuItem key={priority} value={priority}>
              <Checkbox size="small" checked={selectedPriority.includes(priority)} />
              <ListItemText primary={issuePriorityLabel(priority)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Paper>
  )
}
