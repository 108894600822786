import PropTypes from 'prop-types'

import React, { Component } from 'react'
import { connect } from 'react-redux'
import logger from '../../helpers/logger'
import { SettingList } from '../SettingDialog/SettingList'
import { editableState } from '../../helpers/index'

import './SettingsDialog.scss'
import { EditorDialogBase } from '../EditorDialog/EditorDialogBase'

import adsetIcon from '../../resources/images/advanced_settings_2x.png'
import adsetIconDark from '../../resources/images/advanced_settings_active_2x.png'

const SETTING_HEIGHT = 60

export class SettingsDialog extends EditorDialogBase {
  constructor(props) {
    super(props)

    //console.log("SettingsDialog", props);
  }

  /**
   * Returns object type for dialog, for example "activity"
   */
  getObjectType() {
    return 'settings'
  }

  /**
   * Returns icon for button and dialog.
   */
  getIcon() {
    //console.log("SettingsDialog:getIcon", this.state, this.props);
    return this.props.dark ? adsetIconDark : adsetIcon
  }

  getIconHtml() {
    return <div className="EditorDialogItem__buttonImg SettingsDialog__buttonIcon"></div>
  }

  /**
   * Returns minimum height for dialog
   */
  getMinHeight() {
    return 400
  }

  /**
   * Returns list of adjustments of height based on object elements.
   * Name - is a name of parameter to use,
   * Value - adjustment for element.
   */
  getElementsHeight() {
    // We have lines of settings on settings array
    return { __line: SETTING_HEIGHT }
  }

  saveSettings = (settings, closeDialog, onSent) => {
    onSent(closeDialog)
    console.log('SettingsDialog:saveSettings', settings, this.state.settings)

    this.Change(settings)
  }

  onApprove() {
    console.log('SettingsDialog::onApprove')
    this.setState({
      editMode: false
    })
    this.props.onApprove()
  }

  renderEditor = (editState) => {
    let dialog = this.getDialogRef()
    //console.log("SettingsDialog:renderEditor", this.state, this.props);
    return (
      <div className="SettingsDialog">
        <SettingList
          appState={this.props.appState}
          actions={this.props.actions}
          level={0.33}
          isVisible
          isEditable={editState >= editableState.EDITABLE ? editState : editableState.BROWSABLE}
          majorObject={this.props.majorObject}
          type={'setting'}
          items={this.state.settings}
          base={this.props.base}
          onSave={this.saveSettings}
          isFullscreen={dialog && dialog.state ? dialog.state.isFullscreen : false}
        />
      </div>
    )
  }
}

SettingsDialog.propTypes = {
  appState: PropTypes.object,
  actions: PropTypes.object.isRequired,
  modalTitle: PropTypes.string,
  isVisible: PropTypes.bool,
  isEditable: PropTypes.number,
  isFullscreen: PropTypes.bool,
  isViewingOnly: PropTypes.bool,
  isItems: PropTypes.number,
  maxItems: PropTypes.number,
  majorObject: PropTypes.object.isRequired,
  settings: PropTypes.object,
  base: PropTypes.object,
  version: PropTypes.object,
  onClose: PropTypes.func,
  onVersionChange: PropTypes.func,
  onCreateDraft: PropTypes.func,
  onFinalize: PropTypes.func,
  onApprove: PropTypes.func,
  onSave: PropTypes.func
}
