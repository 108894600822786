export const shortnames = {
  applications: 'app',
  datasets: 'ds',
  deployments: 'dep',
  interfaces: 'intf',
  organizations: 'org',
  pipelines: 'pip',
  publications: 'pub',
  subscriptions: 'sub',
  systems: 'sys',
  topologies: 'top',
  views: 'vi',
  environments: 'env'
}
