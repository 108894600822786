/**
 * Created by kascode on 15.05.17.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Loader } from '../Loader/Loader'
import { EditableEntity } from '../EditableEntity/EditableEntity'
import { SimpleTooltip } from '../SimpleTooltip/SimpleTooltip'

import iI from '../../resources/images/i-small-2x.png'

export const PropertyCard = ({
  editable,
  inEditMode,
  description,
  onSelect,
  index,
  name,
  loading,
  onBlur,
  onChange,
  options,
  type,
  value,
  enumName,
  structureName
}) => {
  return (
    <div
      className={
        'FieldView__block FieldView__inlineBlock' +
        (!inEditMode ? ' FieldView__gray' : '') +
        (description ? ' FieldView__blockWithDescription' : '') +
        ' ' +
        'FieldBlock_' +
        name
      }
      // onClick={editable ? onSelect.bind(this, index) : null}
      key={name}
    >
      {description ? (
        <div className="FieldView__info">
          <SimpleTooltip title={description}>
            <img src={iI} alt="info" />
          </SimpleTooltip>
        </div>
      ) : null}
      <div className="FieldView__blockHeader">{name}:</div>
      <div className="FieldView__blockBody">
        {loading ? (
          <Loader />
        ) : (
          <EditableEntity
            dataProps={Object.assign({}, options, {
              onBlur: onBlur,
              onChange: onChange(name),
              hideCheckBox: name.toLowerCase() !== 'access',
              multi: name.toLowerCase() === 'access'
            })}
            dataType={type}
            data={value}
            isEditable={editable}
            inEditMode={inEditMode || type.name === 'boolean'}
          />
        )}
        {name === 'type' && value && value.name === 'enum' && !inEditMode ? <div>{enumName}</div> : null}
        {name === 'type' &&
        ((value && value.name === 'structure') || (value && value.name === 'reference')) &&
        !inEditMode ? (
          <div>{structureName}</div>
        ) : null}
      </div>
    </div>
  )
}

PropertyCard.propTypes = {
  editable: PropTypes.bool, // can this card turn to edit mode
  inEditMode: PropTypes.bool, // is this card in edit mode
  description: PropTypes.string, // description of card
  onSelect: PropTypes.func, // callback that is called when non-edit-mode card is clicked
  index: PropTypes.number.isRequired, // index for react key
  name: PropTypes.string, // card name
  loading: PropTypes.bool, // is data for card loading
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
  type: PropTypes.shape({
    name: PropTypes.string.isRequired,
    options: PropTypes.array
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
}
