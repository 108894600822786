/**
 * Created by kascode on 08.04.16.
 */

import * as types from '../constants/ActionTypes'
import hardcodedState from './hardcodedState'
import { deepCopy, createEmptyObject, pluralTypeForms } from '../helpers'
import deepMerge from 'deep-merge'

const ignoredFields = ['users', /*'structure',*/ 'versions' /*, 'data'*/ /*, 'tasks'*/] // list of fields that are ignored in received objects and are kept from existing objects
// so these fields cannot be overwritten in this receiver

const merge = deepMerge((source, target, key) => {
  if (typeof source === 'object' && source.constructor === Array) {
    return [].concat(source, target)
  } else {
    return target
  }
})

// arrow function cannot use "arguments" pseudoarray, so objectAssignCaseInsensitive must be normal function
function objectAssignCaseInsensitive(target, firstSource) {
  //console.log("objectAssignCaseInsensitive real args", target, firstSource);
  //console.log("objectAssignCaseInsensitive args", arguments);

  let to = Object(target)
  for (let i = 1; i < arguments.length; i++) {
    let nextSource = arguments[i]
    if (nextSource === undefined || nextSource === null) {
      continue
    }
    //if (typeof(nextSource) !== 'object')
    //  continue;

    let keysArray = Object.keys(Object(nextSource))
    let toKeys = Object.keys(Object(to))
    //console.log("next source", nextSource, typeof(nextSource));

    for (let j = 0; j < keysArray.length; j++) {
      let found = false
      for (let k = 0; k < toKeys.length; k++) {
        if (
          typeof keysArray[j] === 'string' &&
          typeof toKeys[k] === 'string' &&
          keysArray[j].toLowerCase() === toKeys[k].toLowerCase() &&
          nextSource[keysArray[j]] !== undefined
        ) {
          found = true
          to[toKeys[k]] = nextSource[keysArray[j]]
          break
        }
      }
      if (!found) to[keysArray[j]] = nextSource[keysArray[j]]
    }

    keysArray.map((key) =>
      toKeys.map((toKey) =>
        typeof key === 'string' &&
        typeof toKey === 'string' &&
        key.toLowerCase() === toKey.toLowerCase() &&
        nextSource[key] !== undefined
          ? (to[toKey] = nextSource[key])
          : null
      )
    )
  }
  //console.log("objectAssignCaseInsensitive result", to);
  return to

  //return Object.assign.bind()
}

export default function appAppState(
  state = localStorage.getItem('dataSource') === 'browser' ? hardcodedState : { p: 'imgsmIFH' },
  action
) {
  if (majorObjectReducer[action.type]) return Object.assign({}, state, majorObjectReducer[action.type](state, action))

  if (datasetsReducer[action.type])
    return Object.assign({}, state, {
      datasets: datasetsReducer[action.type](state.datasets, action)
    })

  return state
}

const majorObjectReducer = {
  [types.CREATE_MAJOR_OBJECT]: (state = [], action) => {
    const pluralType = pluralTypeForms.get(action.objectType)
    const objectList = state[pluralType] ? state[pluralType] : []
    let ret = {}
    ret[pluralType] = [].concat(objectList ? objectList : [], {
      identity: {
        id: 0,
        name: action.name ? action.name : 'New object'
      }
    })
    return ret
  },
  [types.UPDATE_MAJOR_OBJECT]: (state = [], action) => {
    const objectList = state[action.objectType]
    // //console.log("UPDATE_MAJOR_OBJECT", action, objectList);
    let ret = {}
    ret[action.objectType] = objectList.map((el) => {
      if (el.identity.id === action.id) {
        return Object.assign({}, el, action.payload)
      } else {
        return el
      }
    })
    // //console.log(ret);
    return ret
  },
  [types.UPDATE_MAJOR_OBJECT_NAME]: (state = [], action) => {
    const objectList = state[action.objectType]
    let ret = {}
    ret[action.objectType] = objectList.map((el) => {
      if (el.identity.id === action.id) {
        const identity = Object.assign({}, el.identity)
        const changedProps = el.changedProps ? el.changedProps.concat('name') : ['name']
        identity.name = action.name
        return Object.assign({}, el, { identity, changedProps })
      } else {
        return el
      }
    })
    return ret
  },
  [types.UPDATE_MAJOR_OBJECT_DESCRIPTION]: (state = [], action) => {
    const objectList = state[action.objectType]
    let ret = {}
    ret[action.objectType] = objectList.map((el) => {
      if (el.identity.id === action.id) {
        let identity = Object.assign({}, el.identity)
        identity.description = action.text
        return Object.assign({}, el, { identity })
      } else {
        return el
      }
    })
    return ret
  },
  [types.UPDATE_MAJOR_OBJECT_USERS]: (state = [], action) => {
    const objectList = state[action.objectType]
    let ret = {}
    // //console.log("UPDATE_MAJOR_OBJECT_USERS", action);
    ret[action.objectType] = objectList.map((el) => {
      if (el.identity.id === action.id) {
        // //console.log("found el=",el);
        let newApp = Object.assign({}, el)
        newApp.users = action.users.slice()
        // //console.log("new users=", newApp.users);
        return newApp
      } else {
        return el
      }
    })
    return ret
  },
  [types.UPDATE_MAJOR_OBJECT_PROPERTIES]: (state = [], action) => {
    const objectList = state[action.objectType]
    let ret = {}
    ret[action.objectType] = objectList.map((el) => {
      if (el.identity.id === action.id) {
        let newApp = Object.assign({}, el)
        newApp.properties = action.properties.slice()
        return newApp
      } else {
        return el
      }
    })
    return ret
  },
  [types.UPDATE_MAJOR_OBJECT_ADVANCED_SETTINGS]: (state = [], action) => {
    const pluralType = pluralTypeForms.get(action.objectType)
    const objectList = state[pluralType]
    let ret = {}
    ret[pluralType] = objectList.map((el) => {
      if (el.identity.id === action.id) {
        let newApp = Object.assign({}, el)
        /*
        if (newApp.settings && newApp.settings.object && newApp.settings.object.properties) {
          newApp.settings.object.properties =  action.settings.slice();
        }
        else {
          newApp.settings = {object: {properties: action.settings.slice()}};
        }
        */
        newApp.settings = action.settings

        return newApp
      } else {
        return el
      }
    })
    return ret
  },
  [types.UPDATE_MAJOR_OBJECT_TAGS]: (state = [], action) => {
    const pluralType = pluralTypeForms.get(action.objectType)
    const objectList = state[pluralType]
    let ret = {}
    ret[pluralType] = objectList.map((el) => {
      if (el.identity.id === action.id) {
        let newApp = Object.assign({}, el)
        newApp.object.tags = action.tags
        return newApp
      } else {
        return el
      }
    })
    return ret
  },
  [types.UPDATE_ISSUE_TASKS]: (state = [], action) => {
    const objectList = state['issues']
    let ret = {}
    ret['issues'] = objectList.map((el) => {
      if (el.identity.id === action.id) {
        let newApp = Object.assign({}, el)
        newApp.tasks = action.tasks
        return newApp
      } else {
        return el
      }
    })
    return ret
  },
  [types.UPDATE_MAJOR_OBJECT_DOCUMENTS]: (state = [], action) => {
    const pluralType = pluralTypeForms.get(action.objectType)
    const objectList = state[pluralType]
    let ret = {}
    ret[pluralType] = objectList.map((el) => {
      if (el.identity.id === action.id) {
        let newApp = Object.assign({}, el)
        newApp.object.documents = action.docs
        return newApp
      } else {
        return el
      }
    })
    return ret
  },
  [types.UPDATE_OBJECT_PARENT]: (state = [], action) => {
    const pluralType = pluralTypeForms(action.objectType)
    const objectList = state[pluralType]
    let ret = {}

    ret[pluralType] = objectList.map((obj) => {
      if (obj.identity.name === action.objectName) {
        let objectProp = obj.object
          ? Object.assign({}, obj.object, {
              parent: Object.assign({}, action.parentIdentity)
            })
          : { parent: action.parentIdentity }
        return Object.assign({}, obj, { object: objectProp })
      } else {
        return obj
      }
    })

    return ret
  },
  [types.REQUEST_OBJECT]: (state = [], action) => {
    const pluralType = pluralTypeForms.get(action.objType)
    if (state[pluralType] && state[pluralType].length) {
      let oldObjs = state[pluralType]
      if (oldObjs.filter((obj) => obj.identity.id === action.id).length) {
        const res = {}
        res[pluralType] = oldObjs.map((obj) => {
          if (obj.identity.id === action.id) {
            return Object.assign({}, obj, { isFetching: true })
          } else {
            return obj
          }
        })
        return Object.assign({}, state, res)
      } else {
        const res = {}
        res[pluralType] = oldObjs.concat({
          identity: {
            id: parseInt(action.id)
          },
          isFetching: true
        })
        return Object.assign({}, state, res)
      }
    } else {
      const res = {}
      res[pluralType] = [
        {
          identity: {
            id: parseInt(action.id)
          },
          isFetching: true
        }
      ]
      return Object.assign({}, state, res)
    }
  },
  [types.RECEIVE_OBJECT_MERGED]: (state = [], action) => {
    const pluralType = pluralTypeForms.get(action.objType)
    const objectList = state[pluralType]

    if (objectList && objectList.length) {
      const res = {}
      const oldObject = objectList.reduce((p, c) => (c.identity.id === action.obj.identity.id ? c : p), null)
      res[pluralType] = objectList.filter((obj) => {
        return obj.identity.id !== action.obj.identity.id && obj.identity.id !== -1 && obj.identity.id !== 0
      })

      res[pluralType] = res[pluralType].concat(Object.assign(merge(oldObject || {}, action.obj), { isFetching: false }))

      return Object.assign({}, state, res)
    } else {
      const res = {}
      res[pluralType] = [action.obj]
      return Object.assign({}, state, res)
    }
  },
  [types.RECEIVE_OBJECT]: (state = [], action) => {
    const pluralType = pluralTypeForms.get(action.objType)
    const objectList = state[pluralType]

    // //console.log("RECEIVE_OBJECT", action, objectList);
    if (objectList) {
      const res = {}
      let oldObject = objectList.find(
        (obj) => obj.identity.id === action.obj.identity.id && obj.identity.id !== undefined
      )
      res[pluralType] = objectList.filter((obj) => {
        return obj.identity.id !== action.obj.identity.id && obj.identity.id !== -1 && obj.identity.id !== 0
      })
      let savedFields = {}

      if (oldObject) {
        savedFields = ignoredFields.reduce((prev, cur) => {
          if (oldObject[cur]) prev[cur] = oldObject[cur]

          return prev
        }, {})
        //console.log("RECEIVE_OBJECT oldObject found", oldObject);
      } else {
        oldObject = createEmptyObject(action.objType)
        //console.log("RECEIVE_OBJECT empty object created", oldObject);
      }

      res[pluralType] = res[pluralType].concat(
        objectAssignCaseInsensitive(
          {},
          oldObject || {},
          action.obj,
          savedFields,
          {
            object: objectAssignCaseInsensitive({}, oldObject ? oldObject.object : {}, action.obj.object)
          },
          {
            isFetching: false
          }
        )
      )

      return Object.assign({}, state, res)
    } else {
      const res = {}
      res[pluralType] = [action.obj]
      return Object.assign({}, state, res)
    }
  },
  [types.CLEAR_OBJECT_LIST]: (state = [], action) => {
    const pluralType = pluralTypeForms.get(action.objectType)
    return { [pluralType]: null }
  },
  [types.RECEIVE_OBJECT_LIST]: (state = [], action) => {
    const pluralType = pluralTypeForms.get(action.objType)
    const objectList = state[pluralType]

    // //console.log("RECEIVE_OBJECT_LIST", objectList);
    if (objectList && objectList.length) {
      const objectIds = action.objectList.map((obj) => obj.identity.id)
      //console.log("RECEIVE_OBJECT_LIST new", objectIds);
      const res = {}
      let savedFields = {}
      res[pluralType] = objectList.filter((obj) => {
        // save fields
        ignoredFields.forEach((fieldName) => {
          if (!savedFields[obj.identity.id]) savedFields[obj.identity.id] = {}
          savedFields[obj.identity.id][fieldName] = obj[fieldName]
        })

        return !objectIds.includes(obj.identity.id) /*&& obj.identity.id != "00000000-0000-0000-0000-000000000000"*/
      })

      res[pluralType] = res[pluralType].concat(
        action.objectList.map((obj) => {
          // get saved values of fields that should be ignored
          ignoredFields.forEach((fieldName) => {
            // previous version of the line was:
            //>if (savedFields[obj.identity.id])
            // I updated it so that empty saved fields do not overwrite received object's fields if object has them
            // this was done for ChartView's dataset list loading

            if (savedFields[obj.identity.id] && !obj[fieldName])
              obj[fieldName] = savedFields[obj.identity.id][fieldName]
          })
          return obj
        })
      )

      return Object.assign({}, state, res)
    } else {
      const res = {}
      res[pluralType] = action.objectList
      return Object.assign({}, state, res)
    }
  },
  [types.RECEIVE_OBJECT_LIST_INTO_PROPERTY]: (state = [], action) => {
    const pluralType = pluralTypeForms.get(action.objType)
    const objectList = state[pluralType]
    let ret = {}
    if (objectList && objectList.length) {
      ret[pluralType] = objectList.map((el) => {
        if (el.identity.id === action.majorObject.identity.id) {
          const propChain = action.propertyName.split('.')
          let newObj = Object.assign({}, el)
          let curObj = newObj
          propChain.forEach((propName, index) => {
            if (!(propName in curObj)) curObj[propName] = {}
            if (index === propChain.length - 1) curObj[propName] = action.objectList
            curObj = curObj[propName]
          })
          return newObj
        } else {
          return el
        }
      })
    }
    return ret
  },
  [types.RECEIVE_SETTINGS_INTO_PROPERTY]: (state = [], action) => {
    const pluralType = pluralTypeForms.get(action.objType)
    const objectList = state[pluralType]
    let ret = {}
    if (objectList && objectList.length) {
      ret[pluralType] = objectList.map((el) => {
        if (el.identity.id === action.majorObject.identity.id) {
          return Object.assign(el, { [action.propertyName]: action.value })
        } else {
          return el
        }
      })
    }
    return ret
  },
  [types.MAJOR_OBJECT_ADD_MESSAGE]: (state = [], action) => {
    const objectList = state[action.objectType]
    let ret = {}
    ret[action.objectType] = objectList.map((el) => {
      if (el.identity.id === action.id) {
        let newApp = Object.assign({}, el)
        let d = new Date()
        let new_date = d.getDay() + '.' + (1 + d.getMonth()) + '.' + d.getFullYear()
        newApp.messages = [{ date: new_date, name: action.user, text: action.message }].concat(newApp.messages)
        return newApp
      } else {
        return el
      }
    })
    return ret
  },
  [types.RECEIVE_ERROR_OBJECT]: (state = [], action) => {
    const pluralType = pluralTypeForms.get(action.objType)
    const objectList = state[pluralType]
    let ret = {}
    // //console.log("RECEIVE_ERROR", objectList);
    ret[pluralType] = objectList.map((obj) => {
      if (obj.identity.id === action.id)
        return {
          id: obj.identity.id,
          isFetching: false,
          error: action.error
        }
      else return obj
    })
    return ret
  },
  [types.UPDATE_FIELD]: (state = [], action) => {
    const pluralType = pluralTypeForms.get(action.objType)
    const objectList = state[action.objectType]
    let ret = {}
    ret[pluralType] = objectList.map((obj) => {
      if (obj.identity.id === action.id) {
        return Object.assign(obj, { properties: action.new_props })
      } else return obj
    })
    return ret
  },
  [types.UPDATE_PUBLICATION_INTERFACES]: (state = [], action) => {
    let ret = {}

    ret.interfaces = deepCopy(state.interfaces)
    let numAdd = 1
    action.payload.forEach((interf) => {
      let jj = state.interfaces.filter((inter) => inter.identity.id === interf.identity.id)
      let j = 0
      if (jj.length === 0) {
        j = state.interfaces.length + numAdd
        numAdd++
      } else {
        j = state.interfaces.indexOf(jj[0])
      }
      ret.interfaces[j] = interf
    })
    return ret
  },
  [types.UPDATE_PUBLICATION_INTERFACE]: (state = [], action) => {
    const objectList = state['publications']
    let ret = {}
    ret['publications'] = objectList.map((obj) => {
      if (obj.identity.id === action.id) {
        let found = 0
        obj.interfaces = obj.interfaces.map((ds) => {
          if (ds.identity.id === action.payload.identity.id) {
            found = 1
            return Object.assign(ds, action.payload)
          } else {
            return ds
          }
        })
        if (!found) {
          obj.interfaces.push(Object.assign({}, action.payload))
        }
        return obj
      } else return obj
    })
    return ret
  },
  [types.UPDATE_PUBLICATION_DATASET]: (state = [], action) => {
    const objectList = state['publications']
    let ret = {}
    ret['publications'] = objectList.map((obj) => {
      if (obj.identity.id === action.id) {
        let found = 0
        obj.datasets = obj.datasets.map((ds) => {
          if (ds.identity.id === action.payload.identity.id) {
            found = 1
            return Object.assign(ds, action.payload)
          } else {
            return ds
          }
        })
        if (!found) {
          obj.datasets.push(Object.assign({}, action.payload))
        }
        return obj
      } else return obj
    })
    return ret
  },
  [types.UPDATE_SUBSCRIPTION_DATASET]: (state = [], action) => {
    const objectList = state['subscriptions']
    let ret = {}
    ret['subscriptions'] = objectList.map((obj) => {
      if (obj.identity.id === action.id) {
        let found = 0
        obj.datasets = obj.datasets.map((ds) => {
          if (ds.identity.id === action.payload.identity.id) {
            found = 1
            return Object.assign(ds, action.payload)
          } else {
            return ds
          }
        })
        if (!found) {
          obj.datasets.push(Object.assign({}, action.payload))
        }
        return obj
      } else return obj
    })
    return ret
  },
  [types.DELETE_MAJOR_OBJECT]: (state = [], action) => {
    const pluralType = pluralTypeForms.get(action.objectType)
    const objectList = state[pluralType]
    let ret = {}
    // //console.log("RECEIVE_ERROR", objectList);
    ret[pluralType] = objectList.filter((obj) => {
      return obj.identity.id !== action.objectId
    })
    return ret
  },
  [types.DELETE_PUBLICATION_DATASET]: (state = [], action) => {
    const objectList = state['publications']
    let ret = {}
    ret['publications'] = objectList.map((obj) => {
      if (obj.identity.id === action.id) {
        obj.datasets.splice(action.payload, 1)
        return obj
      } else return obj
    })
    return ret
  },
  [types.DELETE_PUBLICATION_INTERFACE]: (state = [], action) => {
    const objectList = state['publications']
    let ret = {}
    ret['publications'] = objectList.map((obj) => {
      if (obj.identity.id === action.id) {
        obj.interfaces.splice(action.payload, 1)
        return obj
      } else return obj
    })
    return ret
  },
  [types.DELETE_SUBSCRIPTION_DATASET]: (state = [], action) => {
    const objectList = state['subscriptions']
    let ret = {}
    ret['subscriptions'] = objectList.filter((obj, index) => {
      return obj.identity.id !== action.id
      /*
      if (obj.identity.id === action.id) {
        //obj.datasets.splice(index, 1);
        return obj;
      }
      else
        return obj;
        */
    })
    return ret
  },
  [types.DELETE_OBJECT]: (state = [], action) => {
    ////console.log('state',state);
    ////console.log('action',action);
    ////console.log('objectList',objectList);
    const objectList = state[action.objectType]
    let ret = {}
    ret[action.objectType] = objectList.filter((el) => {
      //return false;
      return el.identity.name !== action.name
    })
    return ret
  },
  [types.SET_ERROR]: (state = [], action) => {
    if (action.payload) {
      return {
        error: (state.error || []).filter((error) => error.request !== action.payload.request).concat(action.payload)
      }
    }
  },
  [types.CLEAR_ERROR]: (state = [], action) => {
    return { error: [] }
  },
  [types.CLEAR_APP_STATE]: (state = [], action) => {
    let ret = {}
    Object.keys(state).map((key) => (key !== 'error' ? (ret[key] = []) : null))
    return ret
  }
}

const datasetsReducer = {
  [types.UPDATE_DATASET_DATA]: (state = [], action) => {
    // //console.log("datasetsReducer ", state);
    return state.map((dataset) => {
      if (dataset.identity.id === action.id) {
        return Object.assign({}, dataset, { data: { records: action.payload } })
      }
      return dataset
    })
  }
}
